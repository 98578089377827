import { ReactNode } from "react";
import {
  PROJECT_WORKFLOW_SIDE_NAV_STICKY_BUTTON_CONTAINER_ID,
  PROJECT_WORKFLOW_STICKY_FOOTER_CONTENT_CLASS,
  useBottomTabBarOverlayView,
} from "../Navigation/BottomNav/useBottomTabBarOverlayView";

interface MainProjectWorkflowButtonRowProps {
  children: ReactNode;
}

export const MainProjectWorkflowButtonRow = ({
  children,
}: MainProjectWorkflowButtonRowProps) => {
  return useBottomTabBarOverlayView(
    // Since we want it to appear on all screen, `isMobile` should be true all the time
    true,
    children,
    PROJECT_WORKFLOW_STICKY_FOOTER_CONTENT_CLASS,
    PROJECT_WORKFLOW_SIDE_NAV_STICKY_BUTTON_CONTAINER_ID,
  );
};
