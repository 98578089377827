import { Box } from "@mui/material";
import * as Popover from "@radix-ui/react-popover";
import { useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { localServiceCoverPhotoAtom } from "../../../../atoms/profileScreenEdit";
import { QUERY_KEYS } from "../../../../constants/queryKeys";
import { useEngineeringServiceForm } from "../../../../hooks/profileScreenHooks/useEngineeringServiceForm";
import { useGetAffiliatedStudiosForUser } from "../../../../hooks/profileScreenHooks/useGetAffiliatedStudiosForUser";
import { useGetServiceOriginalRate } from "../../../../hooks/profileScreenHooks/useGetServiceOriginalRate";
import { useRecordingServiceForm } from "../../../../hooks/profileScreenHooks/useRecordingServiceForm";
import {
  useGetServicePhotoSignedUrl,
  useUploadServicePhoto,
} from "../../../../hooks/profileScreenHooks/useUploadServicePhoto";
import { useDeleteEngineerService } from "../../../../hooks/useDeleteEngineeringService";
import { useDeleteRecordingService } from "../../../../hooks/useDeleteRecordingService";
import useModal from "../../../../hooks/useModal";
import { SCREENS } from "../../../../routes";
import {
  createRecordingServiceParams,
  getDefaultRecordingServiceFormData,
  getRecordingServiceFormDataFromResponse,
} from "../../../../store/actions/recording";
import { useAppSelector } from "../../../../store/hooks";
import Engineer from "../../../../store/models/engineer";
import {
  ProjectType,
  projectTypeReadableName,
} from "../../../../store/models/project";
import {
  isRecordingService,
  RecordingService,
} from "../../../../store/models/recording";
import Service, {
  APIService,
  getDefaultServiceFormData,
  isEngineeringServiceFormData,
} from "../../../../store/models/service";
import User from "../../../../store/models/user";
import {
  emitAnalyticsTrackingEvent,
  getDebugEventUserIdPrefix,
} from "../../../../utils/analyticsUtils";
import { AddRecordingService } from "../../../components/AddRecordingService/AddRecordingService";
import { EditServiceCard } from "../../../components/EditServiceCard/EditServiceCard";
import { ManageEngineeringService } from "../../../components/ManageEngineeringService/ManageEngineeringService";
import { SelectWorkingHoursComponent } from "../../../components/SelectWorkingHoursComponent/SelectWorkingHoursComponent";
import { SurveyForm } from "../../../components/SurveyForm/SurveyForm";
import { BasePopover } from "../../../core-ui/components/BasePopover/BasePopover";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { FixedMinWidthButton } from "../../../core-ui/components/Button/FixedMinWidthButton";
import { Text } from "../../../core-ui/components/Text/Text";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";
import { FormType } from "../constants";
import { EETab, EETabs, TabsBottomGreyBar } from "../ProfileScreen.styles";
import { a11yProps } from "../utils";
import { ServiceDescriptionTab } from "./ServiceDescriptionTab";
import { ServicesFormModalFooter } from "./ServicesFormModalFooter";
import { ModalTab, ServiceTabLabel, TabPanel } from "./ServicesFormModalTab";
import { CenteredModalBody } from "./ServicesTab.styles";

interface ServicesFormModalContentProps {
  engineer: Engineer;
  onChangeService: (selectedProjectType: OptionType) => void;
  selectedServiceType: ProjectType;
  existingServices: (Service | RecordingService)[];
  formType: FormType;
  userData: User;
  onCancel: () => void;
  canHostServices: boolean;
}

export const ServicesFormModalContent = ({
  engineer,
  onChangeService,
  selectedServiceType,
  existingServices,
  formType,
  userData,
  onCancel,
  canHostServices,
}: ServicesFormModalContentProps) => {
  const queryClient = useQueryClient();
  const [coverPhotoToBeCreated, setCoverPhotoToBeCreated] = useAtom(
    localServiceCoverPhotoAtom,
  );
  const isCreatingNewService = formType === FormType.CREATE;
  const existingService = useMemo(() => {
    return existingServices.find(
      (service) => service.service_type === selectedServiceType,
    );
  }, [existingServices, selectedServiceType]);
  const isOnRecordingServiceForm =
    selectedServiceType === ProjectType.RECORDING;

  const [selectedTab, setSelectedTab] = useState(ModalTab.ServiceDetails);
  const { originalRate } = useGetServiceOriginalRate(existingService, true);
  const [previewPrice, setPreviewPrice] = useState(originalRate);

  // The state for form data
  const [pendingService, setPendingService] = useState<
    createRecordingServiceParams | Service
  >(() => {
    if (existingService) {
      if (isRecordingService(existingService)) {
        return getRecordingServiceFormDataFromResponse(existingService);
      } else {
        return existingService;
      }
    }

    if (selectedServiceType === ProjectType.RECORDING) {
      return getDefaultRecordingServiceFormData();
    }

    return getDefaultServiceFormData(
      userData,
      engineer.id,
      selectedServiceType,
    );
  });

  const { mutate: deleteEngineerService } = useDeleteEngineerService({
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES, engineer?.id],
      });
    },
  });

  const { mutate: deleteRecordingService } = useDeleteRecordingService({
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES, engineer?.id],
      });
    },
  });

  const {
    discountRates,
    endAMPM,
    endTime,
    handleCreateRecordingService,
    handleCreateWorkingHours,
    hasWorkingHoursBeenFetched,
    isSubmitting: isSubmittingRecordingService,
    recordingLocation,
    setDiscountRate,
    setEndAMPM,
    setEndTime,
    setRecordingLocation,
    setStartAMPM,
    setStartTime,
    setWeeklyWorkDates,
    startAMPM,
    startTime,
    weeklyWorkDates,
  } = useRecordingServiceForm({
    existingService:
      existingService && isRecordingService(existingService)
        ? existingService
        : undefined,
    isCreatingNewService,
    pendingFormData: !isEngineeringServiceFormData(pendingService)
      ? pendingService
      : undefined,
    userData,
  });

  const {
    enableRushFees,
    handleSavingSchedulingPreferences,
    hasSchedulingSurveyAnswersBeenFetched,
    isSubmitting: isSubmittingEngineeringService,
    setEnableRushFees,
    setSurveyAnswers,
    surveyAnswers,
    upsertEngineeringService,
  } = useEngineeringServiceForm({
    selectedServiceType,
    isCreatingNewService,
    pendingFormData: isEngineeringServiceFormData(pendingService)
      ? pendingService
      : undefined,
    userData,
  });

  // Clean Up after the modal is closed
  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [QUERY_KEYS.GET_SCHEDULING_SURVEY_ANSWERS],
      });
      queryClient.removeQueries({
        queryKey: [QUERY_KEYS.GET_WORKING_HOURS_USER],
      });
      setCoverPhotoToBeCreated(undefined);
    };
  }, [queryClient, setCoverPhotoToBeCreated]);

  const {
    data: affiliatedStudios,
    isSuccess: isAffiliatedStudiosFetchingSuccess,
    isLoading: isInitialLoadingAffiliatedStudios,
  } = useGetAffiliatedStudiosForUser({ userId: userData.id });

  const {
    isOpen: isPopconfirmOpen,
    setIsOpen: setIsPopconfirmOpen,
    closeModal: closePopconfirm,
    openModal: openPopconfirm,
  } = useModal();

  // For uploading cover photo
  const { mutateAsync: getSignedUrl, isPending: isGetSignedUrlLoading } =
    useGetServicePhotoSignedUrl();
  const { mutateAsync: uploadPhoto, isPending: isUploadPhotoLoading } =
    useUploadServicePhoto();

  const isDetailsTabReady = useMemo(() => {
    if (isEngineeringServiceFormData(pendingService)) return true;

    return recordingLocation || pendingService.recording_location;
  }, [pendingService, recordingLocation]);

  const isSchedulingPreferencesTabReady = useMemo(() => {
    if (
      !isCreatingNewService &&
      !hasSchedulingSurveyAnswersBeenFetched &&
      !hasWorkingHoursBeenFetched
    ) {
      return true;
    }
    if (isOnRecordingServiceForm) {
      return weeklyWorkDates.length !== 0;
    }

    if (!surveyAnswers) return false;

    return (
      surveyAnswers.songs_per_day &&
      surveyAnswers.workdays &&
      surveyAnswers.number_of_days_notice &&
      (!enableRushFees || surveyAnswers.per_day_rush_fee_percentage)
    );
  }, [
    enableRushFees,
    hasSchedulingSurveyAnswersBeenFetched,
    hasWorkingHoursBeenFetched,
    isCreatingNewService,
    isOnRecordingServiceForm,
    surveyAnswers,
    weeklyWorkDates.length,
  ]);

  const isDescriptionTabReady = useMemo(() => {
    return pendingService.description !== "";
  }, [pendingService.description]);

  const savingButtonDisabledText = useMemo(() => {
    if (
      !isEngineeringServiceFormData(pendingService) &&
      !recordingLocation &&
      !pendingService.recording_location
    ) {
      return "Please select a location!";
    }

    return undefined;
  }, [pendingService, recordingLocation]);

  const isLoading = useMemo(() => {
    if (isGetSignedUrlLoading || isUploadPhotoLoading) {
      return true;
    }

    if (isOnRecordingServiceForm) {
      return isSubmittingRecordingService;
    }

    return isSubmittingEngineeringService;
  }, [
    isGetSignedUrlLoading,
    isOnRecordingServiceForm,
    isSubmittingEngineeringService,
    isSubmittingRecordingService,
    isUploadPhotoLoading,
  ]);

  const handleUpdateDescription = (description: string) => {
    setPendingService((prevState) => ({ ...prevState, description }));
  };

  const handleSavingService = async () => {
    try {
      const promises: [
        Promise<RecordingService | APIService | undefined> | undefined,
        Promise<void> | undefined,
      ] = [undefined, undefined];

      if (isOnRecordingServiceForm) {
        promises[0] = handleCreateRecordingService();
        if (hasWorkingHoursBeenFetched) {
          promises[1] = handleCreateWorkingHours();
        }
      } else {
        promises[0] = upsertEngineeringService();
        if (hasSchedulingSurveyAnswersBeenFetched) {
          promises[1] = handleSavingSchedulingPreferences();
        }
      }

      const [serviceResponse] = await Promise.all(promises);
      if (serviceResponse && coverPhotoToBeCreated) {
        const { signed_url } = await getSignedUrl({
          service_id: serviceResponse.id,
          service_type: serviceResponse.service_type,
          content_type: coverPhotoToBeCreated.type,
          file_size: coverPhotoToBeCreated.size,
        });

        await uploadPhoto({ url: signed_url, file: coverPhotoToBeCreated });
      }

      if (isCreatingNewService) {
        toast.success("The service has been successfully created!");
      } else {
        toast.success("The service has been successfully updated!");
      }

      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES, engineer.id],
      });
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_ONBOARDING_PROGRESS, engineer.user_id],
      });

      onCancel();
    } catch (error) {
      // The error has already been handled
    }
  };

  const handleDeleteClick = (serviceId: number, serviceType: ProjectType) => {
    if (formType !== FormType.EDIT) return;
    emitAnalyticsTrackingEvent("delete_service_clicked", {
      serviceId: serviceId,
      serviceType: projectTypeReadableName.get(serviceType),
    });
    if (serviceType === ProjectType.RECORDING) {
      deleteRecordingService({ recording_service_id: serviceId });
    } else {
      deleteEngineerService({ id: serviceId });
    }
    onCancel();
  };

  const tabs = [
    {
      value: ModalTab.ServiceDetails,
      label: (
        <ServiceTabLabel
          isTabReady={Boolean(isDetailsTabReady && isCreatingNewService)}
          label="Service Details"
        />
      ),
      content: (
        <EditServiceCard
          serviceType={selectedServiceType}
          service={existingService}
          engineerId={engineer.id}
          previewPrice={previewPrice}
          isUpdating={formType === FormType.EDIT}
          onChangeService={onChangeService}
        >
          {selectedServiceType === ProjectType.RECORDING ? (
            <AddRecordingService
              onUpdatePrice={setPreviewPrice}
              discountRates={discountRates}
              setDiscountRate={setDiscountRate}
              pendingRecordingService={
                pendingService as createRecordingServiceParams
              }
              setPendingRecordingService={
                setPendingService as Dispatch<
                  SetStateAction<createRecordingServiceParams>
                >
              }
              recordingLocation={recordingLocation}
              setRecordingLocation={setRecordingLocation}
              isEditingRecordingService={formType === FormType.EDIT}
              handleDeleteClick={handleDeleteClick}
              closeModal={onCancel}
              engineerId={engineer.id}
            />
          ) : (
            <ManageEngineeringService
              key={selectedServiceType}
              service={existingService as Service}
              selectedServiceType={selectedServiceType}
              isUpdating={formType === FormType.EDIT}
              onUpdatePrice={setPreviewPrice}
              pendingService={pendingService as Service}
              setPendingService={
                setPendingService as Dispatch<SetStateAction<Service>>
              }
              handleDeleteClick={handleDeleteClick}
            />
          )}
        </EditServiceCard>
      ),
      disabled: isLoading,
    },
    {
      value: ModalTab.SchedulingPreferences,
      label: (
        <ServiceTabLabel
          isTabReady={Boolean(
            isSchedulingPreferencesTabReady && isCreatingNewService,
          )}
          label="Scheduling Preferences"
        />
      ),
      content:
        selectedServiceType === ProjectType.RECORDING ? (
          <SelectWorkingHoursComponent
            weeklyWorkDates={weeklyWorkDates}
            setWeeklyWorkDates={setWeeklyWorkDates}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            startAMPM={startAMPM}
            setStartAMPM={setStartAMPM}
            endAMPM={endAMPM}
            setEndAMPM={setEndAMPM}
            userData={userData}
          />
        ) : (
          <SurveyForm
            serviceType={selectedServiceType}
            surveyAnswers={surveyAnswers}
            setSurveyAnswers={setSurveyAnswers}
            enableRushFees={enableRushFees}
            setEnableRushFees={setEnableRushFees}
            userData={userData}
          />
        ),
      disabled: isLoading,
    },
    {
      value: ModalTab.ServiceDescription,
      label: (
        <ServiceTabLabel
          isTabReady={isDescriptionTabReady && isCreatingNewService}
          label="Service description"
        />
      ),
      content: (
        <ServiceDescriptionTab
          description={pendingService.description || ""}
          setDescription={handleUpdateDescription}
          selectedServiceType={selectedServiceType}
        />
      ),
      disabled: isLoading,
    },
  ];

  if (!canHostServices) {
    return (
      <CenteredModalBody>
        <Text>
          Offering services is currently not enabled for your profile. You are
          on our waitlist! Be on the lookout for an update soon.
        </Text>
      </CenteredModalBody>
    );
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          position: "relative",
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        })}
      >
        <TabsBottomGreyBar />
        <EETabs
          value={selectedTab}
          onChange={(_, val: ModalTab) => {
            setSelectedTab(val);
          }}
          aria-label="Services Form Modal Tabs"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {tabs.map((tab, index) => (
            <EETab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              disabled={Boolean(tab.disabled)}
              {...a11yProps(index)}
            />
          ))}
        </EETabs>
      </Box>
      <Box
        sx={{
          overflowY: "auto",
          padding: "24px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {tabs.map((tab) => (
          <TabPanel value={tab.value} index={selectedTab} key={tab.value}>
            {tab.content}
          </TabPanel>
        ))}
      </Box>
      <ServicesFormModalFooter>
        <FixedMinWidthButton
          variant={ButtonVariant.OUTLINED}
          onClick={onCancel}
        >
          Cancel
        </FixedMinWidthButton>
        <BasePopover
          side={"top"}
          isOpen={isPopconfirmOpen}
          setIsPopoverOpen={setIsPopconfirmOpen}
          closePopover={closePopconfirm}
          title="Are you sure?"
          description='You need to enable "Will go to artist" so that your service is visible to artists.'
          onConfirm={handleSavingService}
          onCancel={closePopconfirm}
        >
          <Popover.Anchor>
            <FixedMinWidthButton
              disabled={Boolean(
                !isSchedulingPreferencesTabReady ||
                  !isDescriptionTabReady ||
                  isLoading ||
                  isInitialLoadingAffiliatedStudios ||
                  !isDetailsTabReady,
              )}
              disableText={savingButtonDisabledText}
              loading={isLoading}
              onClick={() => {
                if (
                  !isEngineeringServiceFormData(pendingService) &&
                  !pendingService.will_come_to_you &&
                  isAffiliatedStudiosFetchingSuccess &&
                  (!affiliatedStudios || affiliatedStudios.length === 0)
                ) {
                  openPopconfirm();
                  return;
                }
                void handleSavingService();
              }}
              variant={ButtonVariant.PRIMARY}
            >
              Save
            </FixedMinWidthButton>
          </Popover.Anchor>
        </BasePopover>
      </ServicesFormModalFooter>
    </>
  );
};
