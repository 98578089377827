import styled from "styled-components";

export const StyledPlaylistContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledPlaylistText = styled.p`
  color: ${({ theme }) => theme.colorPalette.White};
  font-size: ${({ theme }) => theme.textSizeMd};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  line-height: 18px;
  padding-bottom: 8px;
`;
