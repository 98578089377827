import { createDraftSafeSelector, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import {
  AtmosProjectSteps,
  MasteringProjectSteps,
  MixingProjectSteps,
  Project,
} from "../models/project";
import { getCurrentDate } from "../utils/dateTimeUtils";
import { sortProjectData } from "../utils/localProjectSort";

const selectScheduledProjectsStore = (state: RootState) =>
  state.scheduledProjectsStore;

export const getNumberOfUpcomingDeadlines = () =>
  createDraftSafeSelector(
    selectScheduledProjectsStore,
    (scheduledProjectsStore) => {
      const numUpcoming: number =
        scheduledProjectsStore.completeTodayMixingProjects.length +
        scheduledProjectsStore.completeTodayMasteringProjects.length +
        scheduledProjectsStore.completeTodayMasteringAdminTasks.length +
        scheduledProjectsStore.completeTodayMixingAdminTasks.length;
      return numUpcoming;
    },
  );

export const getCompleteTodayMixingAndMasteringProjects = () =>
  createDraftSafeSelector(
    selectScheduledProjectsStore,
    (scheduledProjectsStore) => {
      const today = getCurrentDate();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      const localSortBy = scheduledProjectsStore.localScheduledProjectsSortBy;

      const completeTodayProjects: Project[] = [];

      // First, aggregate all the project IDs for which a mix, master, or revision is due by tomorrow.
      const projectIdsDueByTomorrow = new Set<number>();
      scheduledProjectsStore.completeTodayScheduledProjects.forEach(
        (scheduledProject) => {
          scheduledProject.projects.forEach((project) => {
            const firstPassDate = new Date(project.first_pass_date!);
            firstPassDate.setHours(0, 0, 0, 0);
            if (firstPassDate.getTime() <= tomorrow.getTime()) {
              projectIdsDueByTomorrow.add(project.id);
            }
          });

          scheduledProject.scheduled_revisions.forEach((revision) => {
            const revisionDate = new Date(revision.revision_date!);
            revisionDate.setHours(0, 0, 0, 0);
            if (revisionDate.getTime() <= tomorrow.getTime()) {
              projectIdsDueByTomorrow.add(revision.project_id);
            }
          });
        },
      );

      // For now, we add all mixes / masters and their revisions into the complete
      // today bucket if we're past the first_pass_date.
      scheduledProjectsStore.completeTodayMixingProjects.forEach(
        (mixingProject) => {
          if (projectIdsDueByTomorrow.has(mixingProject.id)) {
            completeTodayProjects.push(mixingProject);
          } else if (
            mixingProject.step === AtmosProjectSteps.IN_ATMOS_MIX ||
            mixingProject.step === AtmosProjectSteps.IN_MIX ||
            mixingProject.step === AtmosProjectSteps.MIX_REVISION ||
            mixingProject.step === AtmosProjectSteps.ATMOS_MIX_REVISION ||
            mixingProject.step === MixingProjectSteps.IN_MIX ||
            mixingProject.step === MixingProjectSteps.MIX_REVISION
          ) {
            const firstPassDate = new Date(mixingProject.first_pass_date!);
            firstPassDate.setHours(0, 0, 0, 0);
            if (firstPassDate.getTime() <= today.getTime()) {
              completeTodayProjects.push(mixingProject);
            }
          }
        },
      );
      scheduledProjectsStore.completeTodayMasteringProjects.forEach(
        (masteringProject) => {
          if (projectIdsDueByTomorrow.has(masteringProject.id)) {
            completeTodayProjects.push(masteringProject);
          } else if (
            masteringProject.step === MasteringProjectSteps.IN_MASTER ||
            masteringProject.step === MasteringProjectSteps.MASTER_REVISION
          ) {
            const firstPassDate = new Date(masteringProject.first_pass_date!);
            firstPassDate.setHours(0, 0, 0, 0);
            if (firstPassDate.getTime() <= today.getTime()) {
              completeTodayProjects.push(masteringProject);
            }
          }
        },
      );

      // Add projects with admin tasks due today to completed projects if it does not exist.
      scheduledProjectsStore.completeTodayMixingAdminTasks.forEach(
        (mixingProject) => {
          if (!projectIdsDueByTomorrow.has(mixingProject.id)) {
            completeTodayProjects.push(mixingProject);
          }
        },
      );

      scheduledProjectsStore.completeTodayMasteringAdminTasks.forEach(
        (masteringProject) => {
          if (!projectIdsDueByTomorrow.has(masteringProject.id)) {
            completeTodayProjects.push(masteringProject);
          }
        },
      );
      return sortProjectData(completeTodayProjects, localSortBy) as Project[];
    },
  );

const selectScheduledProjectUpdates = (state: RootState) =>
  state.scheduledProjectUpdateStore;

export const selectPendingScheduledProjectUpdates = createSelector(
  selectScheduledProjectUpdates,
  ({ scheduledProjects, projects }) => {
    return (
      Object.entries(scheduledProjects).length + Object.entries(projects).length
    );
  },
);

export const isMixMasterBundle = (projectId: number) =>
  createDraftSafeSelector(
    selectScheduledProjectsStore,
    (scheduledProjectsStore) => {
      if (!scheduledProjectsStore?.scheduledProject) {
        return true;
      }
      if (
        scheduledProjectsStore?.scheduledProject?.mastering_projects.length > 0
      ) {
        return scheduledProjectsStore?.scheduledProject?.mastering_projects.some(
          (project) => project.project?.prereq_project_id === projectId,
        );
      } else {
        return false;
      }
    },
  );
