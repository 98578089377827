import { Project } from "../../store/models/project";
import User from "../../store/models/user";
import { useMemo } from "react";

export const useIsSecondaryEngineer = (
  user: User | undefined,
  project: Project,
) =>
  useMemo(() => {
    if (!user || !project) return false;
    const userIsAnEngineer = Boolean(user.engineer?.id);
    const userIsNotPrimaryEngineer = user.id !== project.engineer?.user_id;
    const userIsNotAnAdmin = !user.admin;
    const userIsACollaborator = project.users.some(
      ({ id: userId }) => userId === user.id,
    );
    return (
      userIsAnEngineer &&
      userIsNotAnAdmin &&
      userIsNotPrimaryEngineer &&
      userIsACollaborator
    );
  }, [user, project]);
