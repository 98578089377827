import { atom } from "jotai";

const chatModalDisplayAtom = atom(false);
chatModalDisplayAtom.debugLabel = "Chat Modal Display";

const chatModalChannelIdAtom = atom("");
chatModalChannelIdAtom.debugLabel = "Chat Modal Channel ID";

const openChatModalAtom = atom(null, (_get, set, channelId: string) => {
  set(chatModalDisplayAtom, true);
  set(chatModalChannelIdAtom, channelId);
});

openChatModalAtom.debugPrivate = true;

const closeChatModalAtom = atom(null, (_get, set) => {
  set(chatModalDisplayAtom, false);
  set(chatModalChannelIdAtom, "");
});

closeChatModalAtom.debugPrivate = true;

export {
  chatModalDisplayAtom,
  chatModalChannelIdAtom,
  closeChatModalAtom,
  openChatModalAtom,
};
