import { ProjectUserType } from "../store/models/project";
import { RecordingSession } from "../store/models/recordingSession";
import User from "../store/models/user";
import { SelectEngineerOption } from "../stories/components/SelectAvailableTrackingEngineer/SelectAvailableTrackingEngineer";

export const getArtistFromRecordingSession = (
  recordingSession: RecordingSession | undefined | null,
) => {
  if (!recordingSession) return null;

  const artist = recordingSession?.users.find(
    (user) => user.artist?.id === recordingSession.artist.id,
  );

  return artist;
};

export const getEngineerFromRecordingSession = (
  recordingSession: RecordingSession | undefined | null,
) => {
  if (!recordingSession) return null;

  const engineer = recordingSession?.users.find(
    (user) => user.engineer?.id === recordingSession?.engineer?.id,
  );

  return engineer;
};

export const getUserTypeFromRecordingSession = (
  user: User | undefined,
  recordingSession: RecordingSession | undefined | null,
) => {
  if (!user || !recordingSession) return null;

  if (user.artist?.id === recordingSession.artist.id) {
    return ProjectUserType.ARTIST;
  }

  if (user.engineer?.id === recordingSession.engineer_id) {
    return ProjectUserType.ENGINEER;
  }

  return null;
};

export const getCollaboratorsFromRecordingSession = (
  recordingSession?: RecordingSession,
  currentUser?: User,
) => {
  if (!recordingSession) return null;
  return recordingSession.project.users.filter((user) =>
    currentUser ? user.id !== currentUser.id : true,
  );
};

/**
 * Get the SelectEngineerOption that was least recently booked from a list of SelectEngineerOption
 * By comparing the `last_completed_project` Datetime property of the engineer
 * @param trackingEngineers a list of SelectEngineerOption
 * @returns the SelectEngineerOption for the engineer that was least recently booked
 */
export const getLeastRecentlyBookedEngineer = (
  trackingEngineers: SelectEngineerOption[],
) => {
  if (trackingEngineers.length === 0) {
    return null;
  }

  return trackingEngineers.reduce(
    (leastRecentlyBookedEngineer, currentEngineer) => {
      if (!leastRecentlyBookedEngineer.engineer || !currentEngineer.engineer) {
        return leastRecentlyBookedEngineer;
      }

      const {
        last_completed_project: leastRecentlyBookedEngineerLastCompletedDate,
      } = leastRecentlyBookedEngineer.engineer;
      const { last_completed_project: currentEngineerLastCompletedDate } =
        currentEngineer.engineer;

      if (!leastRecentlyBookedEngineerLastCompletedDate) {
        return leastRecentlyBookedEngineer;
      }

      if (!currentEngineerLastCompletedDate) {
        return currentEngineer;
      }

      const leastRecentlyBookedEngineerBookedDate = new Date(
        leastRecentlyBookedEngineerLastCompletedDate,
      );
      const currentEngineerLastBookedDate = new Date(
        currentEngineerLastCompletedDate,
      );
      return currentEngineerLastBookedDate <
        leastRecentlyBookedEngineerBookedDate
        ? currentEngineer
        : leastRecentlyBookedEngineer;
    },
  );
};
