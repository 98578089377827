import { useMemo } from "react";
import { StudioWorkingHoursOptionType } from "../useGeneralWorkingHoursStringForDay";

export const useFilterTimeOptions = (
  allTimeOptions: StudioWorkingHoursOptionType[],
  hasAvailableTimeOptions: boolean,
  userIsEngineer: boolean,
  userIsStudioManager: boolean,
  recordingSessionWithoutStudio: boolean,
  generalWorkingHoursOptions?: StudioWorkingHoursOptionType[],
) => {
  const timeOptions = useMemo(() => {
    // if date has been selected, return available hours for that date
    // otherwise, return general availability as a placeholder until actual availability can be confirmed
    const options =
      !hasAvailableTimeOptions && generalWorkingHoursOptions?.length
        ? generalWorkingHoursOptions
        : allTimeOptions;

    // if no studio is selected, then the engineer can book outside their working hours
    // if engineer is generating a booking link for a studio, they can book outside their working hours but not outside studio hours
    if (userIsEngineer && !userIsStudioManager) {
      return recordingSessionWithoutStudio
        ? options
        : options.filter((option) => option.isStudioHour);
    }

    // if studio manager is generating a booking link, then they can view times outside studio hours
    // otherwise, user can only view available working hours
    return userIsStudioManager
      ? options
      : options.filter((option) => option.isWorkingHour);
  }, [
    hasAvailableTimeOptions,
    generalWorkingHoursOptions,
    allTimeOptions,
    userIsEngineer,
    userIsStudioManager,
    recordingSessionWithoutStudio,
  ]);

  return timeOptions;
};
