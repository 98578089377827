import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import { useProjectFromProjectMap } from "../../../../../store/hooks";
import {
  FileStorageLocation,
  ProjectType,
  ProjectUserType,
  ProjectWorkflowSteps,
} from "../../../../../store/models/project";
import { SessionWorkflowSteps } from "../../../../../store/models/recordingSession";
import { ButtonVariant } from "../../../../core-ui/components/Button/Button";
import { Select } from "../../../../core-ui/components/Select/Select";
import { Text } from "../../../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../../../core-ui/components/Text/TextUtils";
import { DropdownMenuItem } from "../../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";
import {
  engineerInstructionMap,
  engineerOption,
  engineerUmgInstructions,
  umgEngineerOption,
  uploadInstructionMap,
  uploadInstructionOptions,
} from "../../../UploadInstructionsDropDown/UploadInstructionDropDown";
import { ProjectWorkflowPanelVariant } from "../../ProjectWorkflowPanel";
import { ProjectExportInstructionsButton } from "./ProjectWorkflowTasksRow.styles";

export interface ProjectWorkflowExportInstructionsProps {
  projectId?: number | null;
  projectType: ProjectType;
  workflowStep: ProjectWorkflowSteps | SessionWorkflowSteps | null | undefined;
  userType?: ProjectUserType | null;
  variant: ProjectWorkflowPanelVariant | undefined;
}

export const ProjectWorkflowExportInstructions = ({
  projectId,
  projectType,
  workflowStep,
  userType,
  variant,
}: ProjectWorkflowExportInstructionsProps) => {
  const project = useProjectFromProjectMap(projectId ?? -1);
  const isEngineer = userType === ProjectUserType.ENGINEER;
  const isArtist = userType === ProjectUserType.ARTIST;

  const isArtistFileTransfer =
    workflowStep === ProjectWorkflowSteps.FILE_TRANSFER && isArtist;
  const isEngineerMixFinished =
    workflowStep === ProjectWorkflowSteps.MIX_FINISHED && isEngineer;

  const showExportInstructions =
    (isArtistFileTransfer || isEngineerMixFinished) &&
    projectType !== ProjectType.RECORDING &&
    projectType !== ProjectType.MASTERING &&
    projectType !== ProjectType.NO_TYPE &&
    variant !== ProjectWorkflowPanelVariant.BOOKING_REQUESTED;

  const isUMG = project?.file_storage_location === FileStorageLocation.UMG;

  // determine which engineer instructions to use (UMG or standard)
  const fileUploadInstructionOptionsForEngineer = isUMG
    ? umgEngineerOption
    : engineerOption;

  const engineerInstructionsMap = isUMG
    ? engineerUmgInstructions
    : engineerInstructionMap;

  // determine which instructions to use (engineer or artist)
  const instructionOptions = isEngineer
    ? fileUploadInstructionOptionsForEngineer
    : uploadInstructionOptions;

  const instructionMap = isEngineer
    ? engineerInstructionsMap
    : uploadInstructionMap;

  const theme = useTheme();

  if (!showExportInstructions) return null;
  return (
    <Select
      dropdownZIndex={theme.zIndexes.popconfirmContent}
      triggerButton={
        <ProjectExportInstructionsButton
          labelIcon={
            <FontAwesomeIcon
              color={theme.textSecondaryColor}
              icon={faChevronDown}
              size="xs"
            />
          }
          variant={ButtonVariant.UNSTYLED}
        >
          <Text color={TextColor.SECONDARY} variant={TextStyleVariant.P3}>
            {isEngineer
              ? "File Delivery Instructions"
              : "DAW Export Instructions"}
          </Text>
        </ProjectExportInstructionsButton>
      }
    >
      {instructionOptions.map((option) => {
        const url = instructionMap.get(projectType)?.get(option.value);
        return (
          <Link
            key={`upload-instruction-${option.value}`}
            target="_blank"
            to={{ pathname: url }}
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <DropdownMenuItem>{option.label}</DropdownMenuItem>
          </Link>
        );
      })}
    </Select>
  );
};
