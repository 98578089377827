import { ReactElement, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";
import { uploadPhoto } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getResizeURL } from "../../../store/utils";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import addOne from "../../assets/add-on-button.svg";
import defaultUser from "../../assets/defaultuser.jpg";
import { ColorPalette } from "../../theme";
import { SoundWaveLoader } from "../SoundWaveLoader/SoundWaveLoader";
import "./UserProfileImage.css";
import { SaveProfileButton } from "../../components/FavoriteHeart/FavoriteHeart";
import {
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const accept = [".jpg", ".jpeg", ".png"];
export const maxFiles = 1;

export interface UserProfileImageProps {
  source?: string;
  height?: number;
  width?: number;
  isCircle?: boolean;
  isEntityPhoto?: boolean;
  hideBorder?: boolean;
  editMode?: boolean;
  noUserImage?: string;
  borderWidth?: "thick" | "thin";
  username?: string;
  showFavoriting?: boolean;
  userId?: number;
}

export const UserProfileImage = ({
  source,
  height = 206,
  width = 214,
  isCircle = false,
  isEntityPhoto = false,
  hideBorder = true,
  editMode = false,
  borderWidth = "thick",
  noUserImage = defaultUser,
  username,
  showFavoriting = false,
  userId,
}: UserProfileImageProps): ReactElement => {
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const loggedInUserUsername = loggedInUser?.username;
  const history = useHistory();
  const [localSource, setLocalSource] = useState(source);
  const dispatch = useAppDispatch();
  const [localIsLoading, setLocalIsLoading] = useState<boolean>(false);
  const user = useAppSelector((state) => state.accountInfo.user);
  const { isDesktop } = useMediaQueryBreakpoint();

  useEffect(() => {
    setLocalSource(source);
  }, [source]);

  useEffect(() => {
    if (isEntityPhoto) {
      editMode = false;
    }
  }, [isEntityPhoto]);

  const handleLoaded = useCallback(() => {
    if (localIsLoading) setLocalIsLoading(false);
  }, [localIsLoading]);

  const handleStartLoad = useCallback(() => {
    if (!localIsLoading) setLocalIsLoading(true);
  }, [localIsLoading]);

  const onDrop = useCallback(
    async (acceptedFiles: Array<File>) => {
      if (acceptedFiles.length > 1 || acceptedFiles.length < 1) {
        return alert("upload a single image");
      }
      if (!username) return;
      try {
        setLocalIsLoading(true);
        const base64Data = (await getBase64(acceptedFiles[0])) as string;
        const data = base64Data.split("base64,")[1];
        window.analytics.track(getDebugEventPrefix + "upload_photo", {
          user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
        });
        await dispatch(uploadPhoto({ data, username }));
        setLocalIsLoading(false);
      } catch (error) {
        setLocalIsLoading(false);
      }
    },
    [dispatch, user?.id, username],
  );
  const image = !localSource
    ? noUserImage
    : getResizeURL(isEntityPhoto, localSource);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
  });

  const handleOnClick = useCallback(() => {
    if (!username) return;
    if (editMode) return;
    if (isEntityPhoto) {
      history.push(getStudioScreenRoute(username));
      return;
    }
    history.push(getProfileScreenRoute(username));
  }, [username, history, editMode, isEntityPhoto]);

  return (
    <div
      onClick={handleOnClick}
      className={"user-profile-image ".concat(
        isCircle ? "user-profile-circle" : "",
      )}
      style={{
        width,
        height,
      }}
    >
      <div
        className={"over-lay-container ".concat(
          isCircle ? "user-profile-circle" : "square-radius",
        )}
        style={{
          height: height,
          width: width,
          border:
            hideBorder && !localIsLoading
              ? "none"
              : "solid "
                  .concat(borderWidth)
                  .concat(` ${ColorPalette.TimbreTan}`),
        }}
      >
        <img
          id="bottom"
          className={"user-profile-image ".concat(
            isCircle ? "user-profile-circle" : "",
          )}
          style={{
            border: "none",
            height: height,
            width: width,
          }}
          onLoadStart={handleStartLoad}
          onLoad={handleLoaded}
          onError={() => {
            setLocalIsLoading(false);
            setLocalSource(undefined);
          }}
          src={image}
          alt="profile"
        />
        {editMode && (
          <div
            {...getRootProps()}
            id="top"
            className={"over-lay-container ".concat(
              isCircle ? "user-profile-circle" : "",
            )}
            style={{
              height: height,
              width: width,
              opacity: localIsLoading ? 1 : 0.5,
              backgroundColor: localIsLoading
                ? "white"
                : ColorPalette.LightGray,
            }}
          >
            {localIsLoading && <SoundWaveLoader width={80} height={80} />}
            {!localIsLoading && (
              <div className="d-flex flex-column align-items-center justify-content-center over-lay-text-container">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className="b3">Drop the files here ...</p>
                ) : (
                  <>
                    <img alt="" width={30} height={30} src={addOne} />
                    {height > 100 && width > 100 && (
                      <p className="upload-text label-button">
                        Upload Profile Picture
                      </p>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {!isDesktop &&
        showFavoriting &&
        loggedInUserUsername &&
        loggedInUserUsername !== username && (
          <SaveProfileButton liked_user={userId} />
        )}
    </div>
  );
};
