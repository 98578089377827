import { faCheck, faBan } from "@fortawesome/pro-solid-svg-icons";
import { TEXT_SIZE } from "../../../../core-ui/components/Text/Text";
import {
  ProjectWorkflowStageIcon,
  ProjectWorkflowStageContainer,
  ProjectWorkflowStageSeparator,
  ProjectWorkflowStageText,
  STAGE_CONTAINER_WIDTH,
} from "./ProjectWorkflowStepper.styles";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../../../atoms/user/darkModeAtom";

export enum ProjectWorkflowStageVariant {
  NEXT = "next",
  CURRENT = "current",
  COMPLETE = "completed",
  INCOMPLETE = "incomplete",
}

interface ProjectWorkflowStageProps {
  hideSeparator?: boolean;
  text: string;
  variant: ProjectWorkflowStageVariant;
  width?: number;
  isCancelledSession: boolean;
  modificationPending?: boolean;
}

export const ProjectWorkflowStage = ({
  hideSeparator = false,
  text,
  variant,
  width = STAGE_CONTAINER_WIDTH,
  isCancelledSession,
  modificationPending = false,
}: ProjectWorkflowStageProps) => {
  const isDarkMode = useAtomValue(darkModeAtom);
  return (
    <>
      <ProjectWorkflowStageContainer
        $isDarkMode={isDarkMode}
        $variant={variant}
        $width={width}
        $isCancelledSession={isCancelledSession}
        $modificationPending={modificationPending}
      >
        <ProjectWorkflowStageText
          $isDarkMode={isDarkMode}
          size={TEXT_SIZE.XS}
          $variant={variant}
          $modificationPending={modificationPending}
        >
          {text}
        </ProjectWorkflowStageText>
        {(variant === ProjectWorkflowStageVariant.COMPLETE ||
          variant === ProjectWorkflowStageVariant.INCOMPLETE) && (
          <ProjectWorkflowStageIcon
            icon={
              variant === ProjectWorkflowStageVariant.COMPLETE ? faCheck : faBan
            }
            $isCancelledSession={isCancelledSession}
          />
        )}
      </ProjectWorkflowStageContainer>
      {!hideSeparator && (
        <ProjectWorkflowStageSeparator
          $variant={variant}
          $modificationPending={modificationPending}
          $isCancelledSession={isCancelledSession}
        />
      )}
    </>
  );
};
