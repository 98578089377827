import { useGetFooterPlayerRef } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { setIndex } from "../../../store/actions/abPlayerStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { StyledPlaylistContainer, StyledPlaylistText } from "./Playlist.styles";
import { PlaylistElement } from "./PlaylistElement";

export interface PlaylistProps {
  onClick: () => void;
}

export const Playlist = ({ onClick }: PlaylistProps) => {
  const { playlist, currentTrackIndex, playlistId } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const dispatch = useAppDispatch();
  const footerPlayer = useGetFooterPlayerRef();
  const handleOnClickOfPlaylistElement = (index: number) => {
    emitAnalyticsTrackingEvent("footer_player_looping", {
      index: index,
      playlist_id: playlistId,
    });
    footerPlayer.current?.pause();
    dispatch(setIndex(index));
    onClick();
  };

  return (
    <StyledPlaylistContainer>
      <StyledPlaylistText>Now Playing</StyledPlaylistText>
      <PlaylistElement index={currentTrackIndex} />
      <StyledPlaylistText>Next Up</StyledPlaylistText>

      {Boolean(playlist.length) &&
        playlist.map((_track, index) => {
          if (index <= currentTrackIndex) {
            return;
          }
          return (
            <PlaylistElement
              key={index}
              index={index}
              onClick={() => handleOnClickOfPlaylistElement(index)}
            />
          );
        })}

      {playlist.map((_, index) => {
        if (index >= currentTrackIndex) {
          return;
        }
        return (
          <PlaylistElement
            key={index}
            index={index}
            onClick={() => handleOnClickOfPlaylistElement(index)}
          />
        );
      })}
    </StyledPlaylistContainer>
  );
};
