import { AnchorHTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";
import {
  TEXT_COLOR,
  TEXT_LINE_HEIGHT,
  TEXT_SIZE,
  TEXT_WEIGHT,
  Text,
} from "../../../../../core-ui/components/Text/Text";

type AnnouncementHeaderLinkProps = {
  text: ReactNode;
  path: string;
} & Pick<AnchorHTMLAttributes<HTMLAnchorElement>, "target" | "rel">;

export const AnnouncementHeaderLink: React.FC<AnnouncementHeaderLinkProps> = ({
  target,
  text,
  path,
  rel,
}: AnnouncementHeaderLinkProps) => {
  return (
    <Link
      className="announcement-header-link"
      to={{ pathname: path }}
      target={target}
      rel={rel}
      style={{ marginLeft: "1rem" }}
    >
      <Text
        color={TEXT_COLOR.INVERT}
        lineHeight={TEXT_LINE_HEIGHT.SHORT}
        size={TEXT_SIZE.SMALL}
        weight={TEXT_WEIGHT.BOLD}
      >
        {text}
      </Text>
    </Link>
  );
};
