import { Box, styled, TextField } from "@mui/material";

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 334px;
  gap: 16px;

  @media screen and (max-width: 768px) {
    min-width: unset;
    width: 100%;
  }
`;

export const FieldContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 16px;
  margin-bottom: 16px;
`;

export const FieldInput = styled(TextField)`
  border-radius: 8px;

  & .MuiOutlinedInput-root {
    & fieldset {
      border-radius: 8px;
    }
  }
`;

export const UsernameChecksContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const UsernameRequirement = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
