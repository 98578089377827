import styled from "styled-components";

export const UserIconLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
`;

export const UserIconLabelIcon = styled.img`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

export const UserIconLabelText = styled.p`
  font-size: ${({ theme }) => theme.textSizeMd};
  color: ${({ theme }) => theme.textSecondaryColor};
  text-overflow: ellipsis;
  overflow-x: hidden;
`;
