import { useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useUserProfileImageURL } from "../../../../hooks/useUserProfileImage";
import User, { UserLite } from "../../../../store/models/user";
import defaultUser from "../../../../stories/assets/defaultuser.jpg";
import {
  InfoBadge,
  InfoBadgeType,
} from "../../../core-ui/components/InfoBadge/InfoBadge";
import {
  Text,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../../core-ui/components/Text/Text";
import {
  ProjectPanelArtistImage,
  ProjectPanelHeaderContainer,
  ProjectPanelTitle,
  ProjectPanelTitleContainer,
} from "../ProjectWorkflowPanel.styles";
import { Link } from "react-router-dom";
import { getProfileScreenRoute } from "../../../../store/utils/routeGetters";
import {
  ProjectUserType,
  ScheduledProjectPaywallTypes,
} from "../../../../store/models/project";

interface ProjectPanelHeaderProps {
  albumTitle: string;
  artist: User | UserLite | undefined | null;
  userType?: ProjectUserType | null;
  isLoading?: boolean;
  outstandingBalance?: number;
  isInProgressProject?: boolean;
  paywallOption?: ScheduledProjectPaywallTypes;
}

export const ProjectPanelHeader = ({
  albumTitle,
  artist,
  userType,
  isLoading = false,
  outstandingBalance = 0,
  isInProgressProject = false,
  paywallOption,
}: ProjectPanelHeaderProps) => {
  const [loadFailedImage, setLoadFailedImage] = useState(false);
  const imagePath = useUserProfileImageURL(artist);
  const isPartiallyPaid = outstandingBalance > 0;
  const profileUrl = useMemo(() => {
    return getProfileScreenRoute(artist?.username || "");
  }, [artist?.username]);

  const imageComponent = useMemo(() => {
    return (
      <ProjectPanelArtistImage
        src={loadFailedImage ? defaultUser : imagePath}
        alt="artist for the project"
        onError={() => setLoadFailedImage(true)}
      />
    );
  }, [loadFailedImage, imagePath]);
  return !artist && isLoading ? (
    <Skeleton height={35} width={130} />
  ) : (
    <ProjectPanelHeaderContainer>
      {profileUrl ? (
        <Link to={profileUrl}>{imageComponent}</Link>
      ) : (
        imageComponent
      )}
      <ProjectPanelTitleContainer>
        {artist && (
          <Link to={profileUrl}>
            <Text size={TEXT_SIZE.SMALL} weight={TEXT_WEIGHT.BOLD}>
              {artist.username}
            </Text>
          </Link>
        )}
        <ProjectPanelTitle>
          <Text size={TEXT_SIZE.SMALL}>{albumTitle}</Text>
          {(isInProgressProject || isPartiallyPaid) && (
            <div style={{ marginLeft: "8px" }}>
              <InfoBadge
                type={
                  isInProgressProject
                    ? InfoBadgeType.NOT_FUNDED
                    : InfoBadgeType.PARTIALLY_PAID
                }
                outstandingBalance={outstandingBalance}
                userIsEngineerOnProject={userType === ProjectUserType.ENGINEER}
                paywallOption={paywallOption}
                showToolTip
              />
            </div>
          )}
        </ProjectPanelTitle>
      </ProjectPanelTitleContainer>
    </ProjectPanelHeaderContainer>
  );
};
