import Skeleton from "react-loading-skeleton";

export interface CreditTablerRowSkeletonLoaderParam {
  isMobile?: boolean;
  width?: number;
  height?: number;
}
export const CreditTablerRowSkeletonLoader = ({
  width,
  height,
  isMobile,
}: CreditTablerRowSkeletonLoaderParam) => {
  return (
    <tr
      style={{
        display: "flex",
      }}
    >
      <td
        style={{
          flex: 2,
        }}
      >
        <Skeleton width={"100%"} height={14} />
      </td>
      <td
        style={{
          flex: 2,
        }}
      >
        <Skeleton width={"100%"} height={14} />
      </td>
      {!isMobile && (
        <td
          style={{
            flex: 2,
          }}
        >
          <Skeleton width={"100%"} height={14} />
        </td>
      )}
      {!isMobile && (
        <td
          style={{
            flex: 2,
          }}
        >
          <Skeleton width={"100%"} height={14} />
        </td>
      )}
      {!isMobile && (
        <td
          style={{
            flex: 2,
          }}
        >
          <Skeleton width={"100%"} height={14} />
        </td>
      )}
    </tr>
  );
};
