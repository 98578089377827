import { useAppSelector } from "../../../store/hooks";
import { TransactionStatus } from "../../../store/models/transaction";

export const useProjectOverViewPurchaseOrder = (
  scheduledProjectId: number | undefined,
) => {
  const purchaseOrders = useAppSelector(
    (state) =>
      state.scheduledProjectPurchaseOrdersSlice[scheduledProjectId ?? -1],
  );
  return purchaseOrders?.find(
    (purchaseOrder) =>
      purchaseOrder.purchase_order_status ===
      TransactionStatus.PURCHASE_ORDER_REQUIRED,
  );
};
