import { Studio } from "../../store/models/studio";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { GET_MY_STUDIOS } from "../../store/utils/routes";

export const fetchMyStudios = async () => {
  const result = await makeBackendGetCallWithJsonResponse<Studio[]>(
    GET_MY_STUDIOS,
    "",
  );
  if (result.success) {
    return result.resultJson;
  }
  return Promise.reject(result);
};
