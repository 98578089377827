import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { ReactNode } from "react";
import { useTheme } from "styled-components";
import {
  ProjectType,
  ProjectUserType,
  ProjectWorkflowSteps,
} from "../../../../../store/models/project";
import { SessionWorkflowSteps } from "../../../../../store/models/recordingSession";
import { ButtonVariant } from "../../../../core-ui/components/Button/Button";
import { Select } from "../../../../core-ui/components/Select/Select";
import { ProjectActionDownloadFiles } from "./ProjectWorkflowActions/ProjectActionDownloadFiles";
import { ProjectActionRejectProjectFiles } from "./ProjectWorkflowActions/ProjectActionRejectProjectFiles";
import { ProjectActionReportUser } from "./ProjectWorkflowActions/ProjectActionReportUser";
import { ProjectActionShareListenLink } from "./ProjectWorkflowActions/ProjectActionShareListenLink";
import { ProjectActionShareRecordingSessionLink } from "./ProjectWorkflowActions/ProjectActionShareRecordingSessionLink";
import { ProjectActionShareUploadLink } from "./ProjectWorkflowActions/ProjectActionShareUploadLink";
import { ProjectActionSkipFileUpload } from "./ProjectWorkflowActions/ProjectActionSkipFileUpload";
import { ProjectActionUploadProjectFiles } from "./ProjectWorkflowActions/ProjectActionUploadProjectFiles";
import { ProjectActionViewBookingDetails } from "./ProjectWorkflowActions/ProjectActionViewBookingDetails";
import { ProjectActionViewSessionDetails } from "./ProjectWorkflowActions/ProjectActionViewSessionDetails";
import { ProjectWorkflowTaskMessageProps } from "./ProjectWorkflowTaskMessage";
import {
  ProjectActionsDropdownButton,
  ProjectTaskIcon,
} from "./ProjectWorkflowTasksRow.styles";
import { SessionActionCompletePayment } from "./SessionWorkflowActions/SessionActionCompletePayment";

export const ProjectWorkflowActionsDropdown = ({
  projectId,
  projectType,
  userType,
  // variant, TODO - use this prop to show a different set of options for Booking Requested
  workflowStep,
  isNotFunded,
  showEngineerActionDropdown,
  isSecondaryEngOrAdmin,
}: ProjectWorkflowTaskMessageProps) => {
  const theme = useTheme();

  const UploadProjectFilesAction = (
    <ProjectActionUploadProjectFiles
      key="project-action-upload-project-files-engineer"
      projectId={projectId}
      projectType={projectType}
      workflowStep={workflowStep}
    />
  );

  const DownloadFilesAction = (
    <ProjectActionDownloadFiles key="project-action-download-files" />
  );

  const ViewBookingDetails = isNotFunded ? null : (
    <ProjectActionViewBookingDetails key="project-action-view-booking-details" />
  );

  const ReportUserAction = (
    <ProjectActionReportUser key="project-action-report-user" />
  );

  const ShareUploadPageAction = (
    <ProjectActionShareUploadLink
      key="project-action-share-upload-link"
      projectId={projectId ?? null}
    />
  );

  const SharePlaybackPageAction = isNotFunded ? null : (
    <ProjectActionShareListenLink
      key="project-action-share-listen-link"
      projectId={projectId ?? null}
    />
  );

  const ShareRecordingSessionAction = (
    <ProjectActionShareRecordingSessionLink
      key="project-action-share-listen-link"
      projectId={projectId ?? null}
      projectType={projectType}
    />
  );

  const ViewSessionDetails = isNotFunded ? null : (
    <ProjectActionViewSessionDetails key="project-action-view-session-details" />
  );

  const RequestReuploadAction = (
    <ProjectActionRejectProjectFiles
      key="project-action-reject-project-files-engineer"
      projectId={projectId}
    />
  );

  /**
   * Options for different project types, user types, and workflow steps
   * Contains arrays of components that will be rendered as dropdown items
   * @TODO - add more options for different project types
   * potentially refactor this if we find a better method for handling different actions
   */
  const mixingActionOptions = {
    [ProjectUserType.ARTIST]: {
      [ProjectWorkflowSteps.FILE_TRANSFER]: [
        ShareUploadPageAction,
        ViewBookingDetails,
        ReportUserAction,
      ],
      [ProjectWorkflowSteps.IN_MIX]: [
        DownloadFilesAction,
        UploadProjectFilesAction,
        ViewBookingDetails,
        ReportUserAction,
      ],
      [ProjectWorkflowSteps.MIX_REVIEW]: [
        DownloadFilesAction,
        SharePlaybackPageAction,
        ViewBookingDetails,
        ReportUserAction,
      ],
      [ProjectWorkflowSteps.MIX_FINISHED]: [
        ViewBookingDetails,
        ReportUserAction,
      ],
    },
    [ProjectUserType.ENGINEER]: {
      [ProjectWorkflowSteps.FILE_TRANSFER]: [
        <ProjectActionSkipFileUpload
          key="project-action-already-have-files-engineer"
          projectId={projectId}
          projectType={projectType}
        />,
        UploadProjectFilesAction,
        ShareUploadPageAction,
        ViewBookingDetails,
        ReportUserAction,
      ],
      [ProjectWorkflowSteps.IN_MIX]: [
        RequestReuploadAction,
        UploadProjectFilesAction,
        DownloadFilesAction,
        ShareUploadPageAction,
        ViewBookingDetails,
        ReportUserAction,
      ],
      [ProjectWorkflowSteps.MIX_REVIEW]: [
        RequestReuploadAction,
        UploadProjectFilesAction,
        DownloadFilesAction,
        SharePlaybackPageAction,
        ViewBookingDetails,
        ReportUserAction,
      ],
      [ProjectWorkflowSteps.MIX_FINISHED]: [
        ViewBookingDetails,
        ReportUserAction,
      ],
    },
    [ProjectUserType.SECONDARY_ENG_OR_ADMIN]: {
      [ProjectWorkflowSteps.FILE_TRANSFER]: [],
      [ProjectWorkflowSteps.IN_MIX]: [DownloadFilesAction],
      [ProjectWorkflowSteps.MIX_REVIEW]: [],
      [ProjectWorkflowSteps.MIX_FINISHED]: [],
    },
  };

  const SessionActionCompletePaymentMenuItem = getSessionActionCompletePayment({
    projectId,
    isNotFunded,
  });

  const recordingActionOptions = {
    [ProjectUserType.ARTIST]: {
      [SessionWorkflowSteps.SESSION_UPCOMING]: [
        SessionActionCompletePaymentMenuItem,
        ShareRecordingSessionAction,
        ViewSessionDetails,
      ],
      [SessionWorkflowSteps.SESSION_IN_PROGRESS]: [
        SessionActionCompletePaymentMenuItem,
        ShareRecordingSessionAction,
        ViewSessionDetails,
      ],
      [SessionWorkflowSteps.SESSION_COMPLETE]: [
        SessionActionCompletePaymentMenuItem,
        ShareRecordingSessionAction,
        ViewSessionDetails,
      ],
    },
    [ProjectUserType.ENGINEER]: {
      [SessionWorkflowSteps.SESSION_UPCOMING]: [
        ShareRecordingSessionAction,
        ViewSessionDetails,
      ],
      [SessionWorkflowSteps.SESSION_IN_PROGRESS]: [
        ShareRecordingSessionAction,
        ViewSessionDetails,
      ],
      [SessionWorkflowSteps.SESSION_COMPLETE]: [
        ShareRecordingSessionAction,
        ViewSessionDetails,
      ],
    },
    // This should never be used.
    [ProjectUserType.SECONDARY_ENG_OR_ADMIN]: {
      [SessionWorkflowSteps.SESSION_UPCOMING]: [
        ShareRecordingSessionAction,
        ViewSessionDetails,
      ],
      [SessionWorkflowSteps.SESSION_IN_PROGRESS]: [
        ShareRecordingSessionAction,
        ViewSessionDetails,
      ],
      [SessionWorkflowSteps.SESSION_COMPLETE]: [
        ShareRecordingSessionAction,
        ViewSessionDetails,
      ],
    },
  };

  const allActionOptions: Record<
    ProjectType,
    Record<ProjectUserType, Record<number, ReactNode[]>>
  > = {
    [ProjectType.NO_TYPE]: mixingActionOptions,
    [ProjectType.RECORDING]: recordingActionOptions,
    [ProjectType.MIXING]: mixingActionOptions,
    [ProjectType.MASTERING]: mixingActionOptions,
    [ProjectType.TWO_TRACK_MIXING]: mixingActionOptions,
    [ProjectType.ATMOS_MIXING]: mixingActionOptions,
  };

  let dropdownActionsUserType = userType;
  if (isSecondaryEngOrAdmin) {
    dropdownActionsUserType = ProjectUserType.SECONDARY_ENG_OR_ADMIN;
  }
  if (showEngineerActionDropdown) {
    dropdownActionsUserType = ProjectUserType.ENGINEER;
  }

  const actionOptions = dropdownActionsUserType
    ? allActionOptions[projectType][dropdownActionsUserType][
        workflowStep
      ].filter((opt) => Boolean(opt))
    : [];

  if (actionOptions.length === 0) {
    return null;
  }

  return (
    <Select
      dropdownZIndex={theme.zIndexes.projectSidePanelContent}
      triggerButton={
        <ProjectActionsDropdownButton variant={ButtonVariant.UNSTYLED}>
          <ProjectTaskIcon icon={faEllipsisVertical} />
        </ProjectActionsDropdownButton>
      }
    >
      {actionOptions}
    </Select>
  );
};

const getSessionActionCompletePayment = ({
  projectId,
  isNotFunded,
}: Pick<ProjectWorkflowTaskMessageProps, "projectId" | "isNotFunded">) => {
  if (!projectId || !isNotFunded) {
    return null;
  }

  return (
    <SessionActionCompletePayment
      key="session-action-complete-payment"
      projectId={projectId}
    />
  );
};
