import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { editMode } from "../../../atoms/profileScreenEdit";
import { FeaturedTrackTable } from "../../components/FeaturedTrackTable/FeaturedTrackTable";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { useGetFeaturedTracks } from "../../../hooks/profileScreenHooks/useGetFeaturedTracks";
import { useState } from "react";

export const FeaturedTrackTableWrapper = () => {
  const { username } = useParams<{ username: string }>();
  const [isEditMode] = useAtom(editMode);
  const { data: userData } = useGetUserProfile(username);
  const [page, setPage] = useState(1);
  const {
    data: getFeaturedTrackResponse,
    isPending,
    isError,
    isPlaceholderData,
  } = useGetFeaturedTracks({
    userId: userData!.id!,
    page,
  });

  if (isError && !isEditMode) {
    return null;
  }

  return (
    <FeaturedTrackTable
      portfolioFeatureDataArr={getFeaturedTrackResponse?.data || []}
      currentPage={page}
      totalPages={getFeaturedTrackResponse?.total_pages || 1}
      setCurrentPage={setPage}
      loading={isPending || isPlaceholderData}
      isEditMode={isEditMode}
    />
  );
};
