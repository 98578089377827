import { useContext } from "react";
import {
  useLatestUploadedDeliverableFile,
  useUploadedFiles,
} from "../../../../hooks/useProjectFilesUploaded";
import {
  ProjectById,
  projectTypeReadableFileName,
} from "../../../../store/models/project";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { MainProjectWorkflowButtonRow } from "../../MainProjectWorkflowPanel/MainProjectWorkflowButtonRow";
import { MainProjectWorkflowContext } from "../../MainProjectWorkflowPanel/MainProjectWorkflowPanelContext";
import { InProgressTransitionViewIndex } from "../InProgressTransitionView";
import { InProgressButtonRow } from "../InProgressTransitionView.styles";
import { SendForReviewButton } from "./SendForReviewButton";

interface InProgressEngineerButtonRowProps {
  isInProgressStep: boolean;
  project: ProjectById;
  handleViewTransitionNavigation: () => void;
}

/**
 * Displays the buttons for the engineer in the Mix/Master In Progress step and the Mix/Master Review step.
 */
export const InProgressEngineerButtonRow = ({
  isInProgressStep,
  project,
  handleViewTransitionNavigation,
}: InProgressEngineerButtonRowProps) => {
  const uploadedFiles = useUploadedFiles(project.id);
  const mainProjectWorkflowContext = useContext(MainProjectWorkflowContext);
  InProgressTransitionViewIndex.TRACKS;
  const setViewIndex = mainProjectWorkflowContext?.setInProgressViewIndex;
  const deliverableFile = useLatestUploadedDeliverableFile(project);
  const areFilesUploaded = Boolean(deliverableFile);
  const readableProjectType = projectTypeReadableFileName.get(
    project.service_type,
  );

  const uploadNewMixButton = (
    <Button
      onClick={handleViewTransitionNavigation}
      fullWidth
      variant={ButtonVariant.PRIMARY}
    >
      Upload new {readableProjectType} version
    </Button>
  );

  if (!isInProgressStep) {
    return null;
  }

  return (
    <MainProjectWorkflowButtonRow>
      <InProgressButtonRow>
        <Button
          onClick={() => setViewIndex?.(InProgressTransitionViewIndex.FILES)}
          fullWidth
          variant={ButtonVariant.OUTLINED}
          disabled={!uploadedFiles.length}
        >
          Download project files
        </Button>
        {areFilesUploaded ? (
          <SendForReviewButton
            areFilesUploaded={areFilesUploaded}
            project={project}
          />
        ) : (
          uploadNewMixButton
        )}
      </InProgressButtonRow>
    </MainProjectWorkflowButtonRow>
  );
};
