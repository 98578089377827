import classNames from "classnames";
import { isSameDay } from "date-fns";
import { useCallback } from "react";
import Engineer from "../../store/models/engineer";
import { getCurrentDate } from "../../store/utils/dateTimeUtils";

interface useOverWriteTileClassParams {
  allowsBookingWithoutEngineer?: boolean;
  disablePastDates?: boolean;
  enableAddDate?: boolean;
  isBookable?: boolean;
  isCurrentUserSameAsSelectedEngineer: boolean;
  isCurrentUserSameAsStudioManager: boolean;
  isStudioBooking: boolean;
  recordingBookingDates?: Date[];
  selectedBookingDate: Date | undefined | null;
  trackingEngineer: Engineer | undefined;
  getEngineerAvailability: (date: Date) => Date[];
  getStudioAvailability: (date: Date) => Date[];
  getOverlappingAvailability: (date: Date) => Date[];
}

export const useOverWriteTileClass = ({
  allowsBookingWithoutEngineer = false,
  disablePastDates = false,
  enableAddDate = true,
  isBookable = true,
  isCurrentUserSameAsSelectedEngineer,
  isCurrentUserSameAsStudioManager,
  isStudioBooking,
  recordingBookingDates,
  selectedBookingDate,
  trackingEngineer,
  getEngineerAvailability,
  getStudioAvailability,
  getOverlappingAvailability,
}: useOverWriteTileClassParams) =>
  useCallback(
    (date: Date) => {
      // Past Dates should be selectable by Studio Managers and Engineers when generating a booking
      // Past Dates should not be enabled when rescheduling a Recording Session
      const isFutureDate = date.getTime() > getCurrentDate().getTime();
      const studioAvailabilities = getStudioAvailability(date);

      // If there is no tracking engineer, there is no need to find overlapping availabilities
      // we can simply use the studio availability
      const overlappingAvailabilities =
        allowsBookingWithoutEngineer && !trackingEngineer
          ? studioAvailabilities
          : getOverlappingAvailability(date);

      // Studio Managers can select any date
      // if date is outside Studio and Engineer availability, then date is invalid but can be selected
      const isStudioManagerAndCanSelectInvalidDate =
        isCurrentUserSameAsStudioManager &&
        overlappingAvailabilities.length === 0;

      // Affiliated Engineers can only book dates within Studio Working hours
      // If an Engineer is booked without a Studio, then this is simply marked true
      const isStudioAvailable = isStudioBooking
        ? studioAvailabilities.length !== 0
        : true;

      // Affiliated Engineers can select dates within Studio Availability, but outside their own availablity
      // Past Dates are considered invalid, but selectable
      const isEngineerUnavailable =
        (isStudioAvailable && getEngineerAvailability(date).length === 0) ||
        !isFutureDate;

      // Affiliated Engineers can only select dates within Studio Availability or in the past
      // if date is outside Engineer availability, then date is invalid but can be selected
      const isAffiliatedEngineerAndCanSelectInvalidDate =
        isCurrentUserSameAsSelectedEngineer && isEngineerUnavailable;

      const dateIsInvalidButCanBeClicked =
        isStudioManagerAndCanSelectInvalidDate ||
        isAffiliatedEngineerAndCanSelectInvalidDate;

      const classNamesArray: string[] = [];

      if (
        selectedBookingDate &&
        isBookable &&
        isSameDay(selectedBookingDate, date)
      ) {
        return "completed-session-date";
      }

      if (!isFutureDate) {
        return dateIsInvalidButCanBeClicked && !disablePastDates
          ? "invalid-session-date"
          : "";
      }

      // is the date part of one the sessions created by the user
      if (
        recordingBookingDates &&
        selectedBookingDate &&
        isSameDay(selectedBookingDate, date)
      ) {
        if (enableAddDate) {
          return dateIsInvalidButCanBeClicked
            ? "invalid-session-date--unselected"
            : "not-currently-selected-date";
        }

        return "selected-tiles-calendar";
      }

      // only needed for the calendar displayed on Recording Booking/Engineer Booking page
      if (recordingBookingDates?.some((d) => isSameDay(d, date))) {
        return dateIsInvalidButCanBeClicked
          ? "invalid-session-date--unselected"
          : "not-currently-selected-date";
      }

      // catch all for unavailable dates that can be selected
      if (dateIsInvalidButCanBeClicked) {
        classNamesArray.push("invalid-session-date");

        if (!enableAddDate) {
          classNamesArray.push("recording-booking-calendar--unselectable");
        }

        return classNames([classNamesArray]);
      }

      // if calendar is not enabled for adding dates, then all dates are unselectable
      if (!enableAddDate) {
        return "recording-booking-calendar--unselectable";
      }

      return "";
    },
    [
      isBookable,
      isCurrentUserSameAsSelectedEngineer,
      isCurrentUserSameAsStudioManager,
      isStudioBooking,
      selectedBookingDate,
      getEngineerAvailability,
      getStudioAvailability,
      getOverlappingAvailability,
    ],
  );
