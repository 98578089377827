import { Box, Skeleton } from "@mui/material";
import { useReleaseDetails } from "../../../api/releases/hooks/useFetchReleaseDetails";
import { Release } from "../../../store/models/release";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { Button } from "../../core-ui/components/Button/Button";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { ExpandableTextArea } from "../../elements/ExpandableTextArea/ExpandableTextArea";
import { ReleaseTabViewSectionProps } from "../ArtistReleaseTabView/ArtistReleaseTabView";
import { ReleaseCollaborator } from "../ReleaseCollaborator/ReleaseCollaborator";
import { ReleaseListItemCoverPhoto } from "../ReleaseList/ReleaseListItemCoverPhoto";
import { ReleasePlayPauseButton } from "../ReleasePlayPauseButton/ReleasePlayPauseButton";
import { FeaturedAristReleaseHeader } from "./FeaturedAristReleaseHeader";
import { FeatureReleasePill } from "./FeaturedReleasePill";
import { MadeOnEngineers } from "./MadeOnEngineers";
import { Product } from "../../../api/products/models";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { Dispatch, SetStateAction } from "react";

interface FeaturedArtistReleaseProps extends ReleaseTabViewSectionProps {
  release?: Release | null;
  isReleaseLoading: boolean;
  onClick?: (releaseId?: number) => void;
  ctaText?: string;
  setSelectedProductIndex?: Dispatch<SetStateAction<number>>;
  selectedProductIndex?: number;
  products?: Product[];
}

export const FeaturedArtistRelease = ({
  size,
  shouldShowDesktop,
  release,
  isReleaseLoading,
  onClick,
  ctaText = "Play Project",
  setSelectedProductIndex,
  selectedProductIndex,
  products = [],
}: FeaturedArtistReleaseProps) => {
  const featured = Boolean(release?.featured);
  const {
    recordingEngineerCollaborators,
    mixingEngineerCollaborators,
    dolbyAtmosMixingEngineerCollaborators,
    masteringEngineerCollaborators,
    // Temporarily hide play count
    // playCount,
    isReleaseDetailsInitialLoading,
  } = useReleaseDetails(release?.id);

  const featuredArtistRelease = release ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: shouldShowDesktop ? "row" : "column",
        gap: "16px",
        alignItems: shouldShowDesktop ? "flex-start" : "center",
        justifyContent: shouldShowDesktop ? "center" : "flex-start",
        height: "fit-content",
      }}
    >
      <ReleaseListItemCoverPhoto
        width={size}
        height={size}
        releaseCover={release.release_cover}
      >
        <Box
          sx={{
            position: "absolute",
            right: 16,
            bottom: 16,
          }}
        >
          <ReleasePlayPauseButton size={42} releaseId={release.id} />
        </Box>
      </ReleaseListItemCoverPhoto>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: "24px",
          width: size,
          height: "fit-content",
        }}
      >
        <FeaturedAristReleaseHeader
          featured={featured}
          title={release.title}
          shouldShowDesktop={shouldShowDesktop}
          artistDisplayName={release.artist_display_name}
          artistUsername={release.artist_user?.username ?? ""}
          releaseType={release.release_type}
          createdAt={release.created_at}
        />
        <ExpandableTextArea
          isGreyVariant
          minHeight={5}
          body={release.description ?? ""}
        />
        <Box>
          <ReleaseCollaborator
            title={"Recording engineers:"}
            collaborators={recordingEngineerCollaborators}
            isLoadingCollaborators={isReleaseDetailsInitialLoading}
          />
          <ReleaseCollaborator
            title={"Mixing engineers:"}
            collaborators={mixingEngineerCollaborators}
            isLoadingCollaborators={isReleaseDetailsInitialLoading}
          />
          <ReleaseCollaborator
            title={"Dolby Atmos mixing engineers:"}
            collaborators={dolbyAtmosMixingEngineerCollaborators}
            isLoadingCollaborators={isReleaseDetailsInitialLoading}
          />
          <ReleaseCollaborator
            title={"Mastering engineers:"}
            collaborators={masteringEngineerCollaborators}
            isLoadingCollaborators={isReleaseDetailsInitialLoading}
          />
        </Box>
        <Box
          sx={{
            marginTop: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            gap: "8px",
          }}
        >
          {/* Placeholder dropdown */}
          {products?.length > 0 && (
            <select
              onChange={(e) => {
                if (setSelectedProductIndex) {
                  setSelectedProductIndex(Number(e.target.value));
                }
              }}
              name="products"
              id="product-select"
            >
              {products.map((product, index) => (
                <option
                  selected={selectedProductIndex === index}
                  key={product.id}
                  value={index}
                >
                  {`${product.name} - ${PennyDollarFormatter().format(Number(product.total_price))}`}
                </option>
              ))}
            </select>
          )}
          {/* Temporarily hide play count */}
          {/* {isReleaseDetailsInitialLoading ? (
            <Skeleton width={"10%"} />
          ) : (
            <Text bold variant={TextStyleVariant.P2}>
              {`${playCount} plays`}
            </Text>
          )} */}
          {onClick && (
            <Button
              variant={ButtonVariant.PRIMARY}
              onClick={() => {
                onClick(release.id);
              }}
            >
              {ctaText}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  ) : null;

  const featuredArtistReleaseLoader = (
    <Box
      sx={{
        display: "flex",
        flexDirection: shouldShowDesktop ? "row" : "column",
        gap: "16px",
        alignItems: shouldShowDesktop ? "flex-start" : "center",
        justifyContent: shouldShowDesktop ? "center" : "flex-start",
        height: "fit-content",
      }}
    >
      <Skeleton variant={"rounded"} width={size} height={size} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: "24px",
          width: size,
          height: size,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "24px",
            flexDirection: shouldShowDesktop ? "row" : "column-reverse",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Skeleton height={10} width={200} />
            <Skeleton height={10} width={200} />
            <Skeleton height={10} width={200} />
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <MadeOnEngineers />
            <FeatureReleasePill />
          </Box>
        </Box>
        <Skeleton height={10} />
        <Skeleton height={10} />
        <Box
          sx={{
            marginTop: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Skeleton width={"10%"} />
          <Button disabled variant={ButtonVariant.PRIMARY}>
            Play Project
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return isReleaseLoading ? featuredArtistReleaseLoader : featuredArtistRelease;
};
