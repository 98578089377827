import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  usePendingFiles,
  useUploadedFileSinceLastTransition,
} from "../../../../hooks/useProjectFilesUploaded";
import { useProjectFromProjectMap } from "../../../../store/hooks";
import { UploadTypeOptions } from "../../../../store/models/alts";
import { FileType } from "../../../../store/models/fileVersion";
import {
  ProjectType,
  projectTypeReadableFileName,
} from "../../../../store/models/project";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { Text } from "../../../core-ui/components/Text/Text";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";
import { InProgressButtonRow } from "../../InProgressTransitionView/InProgressTransitionView.styles";
import { Uploader } from "../../Uploader/Uploader";
import { UploadedFileList } from "../UploadedFileList/UploadedFileList";
import useOnDrop from "../useOnDrop";

export interface UploadSettings {
  reference: boolean;
  uploadType: FileType[];
}

export interface InProgressUploaderProps {
  uploadSettings?: UploadSettings;
  projectId: number;
  isEngineerOnProject: boolean;
  code: string | null;
  handleBackButton?: () => void;
  isInReview?: boolean;
  isInFileTransfer?: boolean;
}

export const InProgressUploader = ({
  uploadSettings,
  projectId,
  isEngineerOnProject,
  code,
  handleBackButton,
  isInReview,
  isInFileTransfer,
}: InProgressUploaderProps) => {
  const [uploadAlt, setUploadType] = useState<OptionType>(UploadTypeOptions[0]);
  const project = useProjectFromProjectMap(projectId);
  const pendingFiles = usePendingFiles(project ?? undefined);
  const uploadedFiles = useUploadedFileSinceLastTransition(
    project ?? undefined,
  );
  const isAtmos = project?.service_type === ProjectType.ATMOS_MIXING;
  const filesToUse = [...uploadedFiles, ...pendingFiles];
  const [onDrop, { progress }] = useOnDrop({
    code: code,
    isCurrentProjectEngineer: isEngineerOnProject,
    engineerIsUploadingOnBehalfOfArtist: Boolean(
      uploadSettings?.reference && isEngineerOnProject,
    ),
    projectId: projectId,
    uploadAltValue: uploadAlt.value,
  });

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        if (handleBackButton) {
          handleBackButton();
        }
      }, 1000);
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  if (!uploadSettings) {
    return null;
  }
  if (!project) {
    return null;
  }
  return (
    <>
      <Uploader
        additionalPopoverContent={
          isInReview ? (
            <Text>
              {`This does not count as a revision and will not charge the client.
              The client will be notified that a new ${uploadSettings?.reference ? "reference file" : "mix file"} has
              been uploaded.`}
            </Text>
          ) : undefined
        }
        project={project}
        uploadAlt={uploadAlt}
        setUploadType={setUploadType}
        uploaderDisabled={progress > 0}
        headerText={
          isInFileTransfer
            ? "Already started the project?"
            : `Upload ${uploadSettings.reference ? "reference" : "main"} ${uploadSettings.uploadType.join(" and ")} file`
        }
        instructionText={
          isAtmos && !uploadSettings.reference
            ? "For this Dolby Atmos mix, please upload your mix both as a binaural render .wav and an .mp4"
            : `Drag and drop or click to upload your ${uploadSettings.reference ? "reference" : "main"} ${projectTypeReadableFileName.get(project?.service_type)} ${uploadSettings.uploadType.join(" and ")} file`
        }
        onDrop={onDrop}
        acceptedFiles={uploadSettings.uploadType}
        isEngineer={isEngineerOnProject}
      />
      {filesToUse.length > 0 && (
        <div style={{ marginTop: "2rem" }}>
          <UploadedFileList
            projectId={projectId}
            uploadedFiles={filesToUse.filter(
              (file) => file.reference === uploadSettings.reference,
            )}
            progress={progress}
          />
        </div>
      )}
      {handleBackButton && (
        <InProgressButtonRow>
          <Button variant={ButtonVariant.UNSTYLED} onClick={handleBackButton}>
            <FontAwesomeIcon
              icon={faAngleLeft}
              style={{ marginRight: "0.5rem" }}
            />
            Back to track table
          </Button>
        </InProgressButtonRow>
      )}
    </>
  );
};
