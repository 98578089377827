import { useMediaQuery, useTheme } from "@mui/material";
import { atom, useAtom, useAtomValue } from "jotai";
import { UIEvent, useCallback, useMemo, useRef } from "react";

export const isCoverPhotoStickyAtom = atom(false);
export const coverPhotoContainerAtom = atom(null as HTMLDivElement | null);

const DESKTOP_STICKY_COVER_PHOTO_HEIGHT = 100;
const MOBILE_STICKY_COVER_PHOTO_HEIGHT = 84;

export const useStickyCoverPhotoHeight = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return useMemo(() => {
    if (isMobile) return MOBILE_STICKY_COVER_PHOTO_HEIGHT;
    return DESKTOP_STICKY_COVER_PHOTO_HEIGHT;
  }, [isMobile]);
};

export const useStickyCoverPhoto = () => {
  // This value is the reference point that helps determine if we're scrolling up or down
  const previouslySavedScrollRef = useRef(0);
  const [isCoverPhotoSticky, setIsCoverPhotoSticky] = useAtom(
    isCoverPhotoStickyAtom,
  );
  const coverPhotoContainer = useAtomValue(coverPhotoContainerAtom);
  const stickyCoverPhotoHeight = useStickyCoverPhotoHeight();

  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const currentScrollTop = e.currentTarget.scrollTop;

      if (coverPhotoContainer) {
        const distanceToBeSticky =
          coverPhotoContainer.clientHeight - stickyCoverPhotoHeight;
        if (
          currentScrollTop >= distanceToBeSticky &&
          currentScrollTop >= previouslySavedScrollRef.current && // We're scrolling down now
          !isCoverPhotoSticky
        ) {
          previouslySavedScrollRef.current = currentScrollTop; // Update the reference point
          setIsCoverPhotoSticky(true);
        } else if (
          currentScrollTop < distanceToBeSticky &&
          currentScrollTop < previouslySavedScrollRef.current && // We're scrolling up now
          isCoverPhotoSticky
        ) {
          previouslySavedScrollRef.current = currentScrollTop; // Update the reference point
          setIsCoverPhotoSticky(false);
        }
      }
    },
    [
      coverPhotoContainer,
      isCoverPhotoSticky,
      setIsCoverPhotoSticky,
      stickyCoverPhotoHeight,
    ],
  );

  return handleScroll;
};
