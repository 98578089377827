import { useCallback, useMemo } from "react";
import { differenceInMinutes } from "date-fns";
import humanizeDuration from "humanize-duration";
import { convertLocalDateToUTCDate } from "../../../../../store/utils/dateTimeUtils";
import { convertMinutesToMilliseconds } from "../../../../../store/utils/serviceUtils";

const useProjectDuration = (created: string, completed: string | null) => {
  const calculateProjectDuration = useCallback(() => {
    const now = convertLocalDateToUTCDate(new Date());
    const createdDate = new Date(created);
    const completedDate = completed ? new Date(completed) : now;

    const durationInMinutes = differenceInMinutes(completedDate, createdDate);

    return humanizeDuration(convertMinutesToMilliseconds(durationInMinutes), {
      largest: 2,
      round: true,
      delimiter: " ",
    });
  }, [created, completed]);

  return useMemo(calculateProjectDuration, [calculateProjectDuration]);
};

export default useProjectDuration;
