import { useAtom, useAtomValue } from "jotai";
import { RESET } from "jotai/utils";
import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import ViewSlider, { ViewProps } from "react-view-slider";
import { sidePanelBottomDrawerContentAtom } from "../../../atoms/sidePanelAtoms";
import useInvalidateOnboardingProgress from "../../../hooks/onboardingHooks/useInvalidateOnboardingProgress";
import {
  OnboardingChecklistItemEnum,
  useUpdateOnboardingProgressMutation,
} from "../../../hooks/onboardingHooks/useUpdateOnboardingProgressMutation";
import { useGetSessionModificationDetails } from "../../../hooks/recordingSessionHooks/useGetSessionModificationDetails";
import useModal from "../../../hooks/useModal";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { useSessionUserRoles } from "../../../hooks/useSessionUsers";
import {
  clearRecordingSession,
  getRecordingSession,
} from "../../../store/actions/recording";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType, ProjectUserType } from "../../../store/models/project";
import { RecordingSessionQueryParams } from "../../../store/models/recording";
import { getWorkflowStep } from "../../../store/utils/recordingUtils";
import {
  getArtistFromRecordingSession,
  getEngineerFromRecordingSession,
  getUserTypeFromRecordingSession,
} from "../../../utils/recordingUtils";
import { TaskMessageType } from "../ProjectWorkflowPanel/components/ProjectWorkflowTasksRow/ProjectWorkflowTaskMessage";
import {
  ProjectWorkflowPanel,
  ProjectWorkflowPanelVariant,
} from "../ProjectWorkflowPanel/ProjectWorkflowPanel";
import { SidePanelBottomDrawer } from "../SidePanel/SidePanelBottomDrawer";
import { SessionDetailsPanelView, sessionDetailsPanelViewAtom } from "./atoms";
import { SessionDetailsPanelBody } from "./SessionDetailsPanelBody";
import { SessionDetailsPanelBookingDetailsView } from "./SessionDetailsPanelBookingDetailsView";

interface SessionDetailsPanelProps {
  noSidePanel?: boolean;
  customProjectId?: string;
}

export const SessionDetailsPanel = ({
  noSidePanel = false,
  customProjectId,
}: SessionDetailsPanelProps) => {
  const dispatch = useAppDispatch();
  const { recordingSession, recordingSessionLoading: loading } = useAppSelector(
    (state) => state.recordingSessionReducer,
  );
  const sessionModificationDetails =
    useGetSessionModificationDetails(recordingSession);

  const currentUser = useAppSelector((state) => state.accountInfo.user);
  const {
    closeModal: closeSessionDetailsPanel,
    isOpen: isSessionDetailsPanelOpen,
    openModal: openSessionDetailsPanel,
  } = useModal();
  const projectQuery = useQueryParam(RecordingSessionQueryParams.PROJECT_ID);
  const projectId = customProjectId ?? projectQuery.get();

  const artist = getArtistFromRecordingSession(recordingSession);
  const engineer = getEngineerFromRecordingSession(recordingSession);
  const { isArtist, isEngineer, isManager } =
    useSessionUserRoles(recordingSession);
  const userType = getUserTypeFromRecordingSession(
    currentUser,
    recordingSession,
  );
  const isLoadingData = loading || !recordingSession;
  const workflowStep = getWorkflowStep(recordingSession);
  const [bottomDrawerContent, setBottomDrawerContent] = useAtom(
    sidePanelBottomDrawerContentAtom,
  );
  const { mutateAsync: updateSessionDetailsChecklistItem } =
    useUpdateOnboardingProgressMutation({
      userId: currentUser?.id,
    });
  const { invalidateOnboardingProgress } = useInvalidateOnboardingProgress();

  const handleCloseSessionDetailsPanel = useCallback(() => {
    // only clear the session redux store and close the panel if the content is in the side panel
    // these actions cause issues on the logged out session details page (no side panel)
    if (noSidePanel) return;
    projectQuery.remove();
    dispatch(clearRecordingSession());
    closeSessionDetailsPanel();
  }, [closeSessionDetailsPanel, dispatch, projectQuery, noSidePanel]);

  const handleOpenSessionDetailsPanel = useCallback(
    (projectId: string) => {
      dispatch(getRecordingSession(projectId))
        .unwrap()
        .then((recordingSession) => {
          if (recordingSession.pending_my_acceptance_or_rejection) {
            toast.error(
              "Please approve/reject the booking before seeing the session details",
            );
            handleCloseSessionDetailsPanel();
          }
        })
        .catch(() => handleCloseSessionDetailsPanel())
        .finally(async () => {
          await updateSessionDetailsChecklistItem(
            OnboardingChecklistItemEnum.REVIEW_SESSION_LOCATION,
          );
          await invalidateOnboardingProgress();
        });
      openSessionDetailsPanel();
    },
    [
      dispatch,
      handleCloseSessionDetailsPanel,
      openSessionDetailsPanel,
      updateSessionDetailsChecklistItem,
      invalidateOnboardingProgress,
    ],
  );

  useEffect(() => {
    if (projectId) {
      handleOpenSessionDetailsPanel(projectId);
    } else {
      handleCloseSessionDetailsPanel();
    }
  }, [
    handleOpenSessionDetailsPanel,
    projectId,
    handleCloseSessionDetailsPanel,
  ]);

  const getOverrideTaskMessage = (): TaskMessageType | undefined => {
    const {
      userHasPendingRequest,
      sessionHasPendingModificationRequest,
      sessionExtensionRequest,
      sessionRescheduledRequest,
    } = sessionModificationDetails;
    const isCancelledSession = recordingSession?.refunded;

    if (isCancelledSession) {
      return {
        message: "Rebook a new session.",
        tooltipText: "",
      };
    }

    if (userHasPendingRequest && sessionExtensionRequest) {
      return {
        message:
          "A user has requested an extension for this session. Please accept or reject the request.",
        tooltipText: "",
      };
    }
    if (userHasPendingRequest && sessionRescheduledRequest) {
      return {
        message:
          "A user has requested a new date and time for this session. Please accept or reject the request.",
        tooltipText: "",
      };
    }
    if (sessionHasPendingModificationRequest) {
      return {
        message:
          "Please wait for all parties to confirm the new session date and time.",
        tooltipText: "",
      };
    }

    return undefined;
  };

  const bookingRequestId =
    recordingSession?.recording_session_request_id ?? null;
  const sessionDetailsView = useAtomValue(sessionDetailsPanelViewAtom);

  const renderContent = () => {
    if (isLoadingData || !isSessionDetailsPanelOpen) {
      return null;
    }

    return (
      <SessionDetailsPanelBody
        recordingSession={recordingSession}
        workflowStep={workflowStep}
        sessionModificationDetails={sessionModificationDetails}
        currentUser={currentUser}
        currentUserType={userType}
        isAccessedViaShareLink={Boolean(customProjectId)}
        isArtist={isArtist}
        isEngineer={isEngineer}
        isManager={isManager}
      />
    );
  };

  const renderView = ({ index }: ViewProps) => {
    if (index === SessionDetailsPanelView.MAIN) {
      return renderContent();
    }
    if (index === SessionDetailsPanelView.SESSION_REQUESTED_DETAILS) {
      return (
        <SessionDetailsPanelBookingDetailsView
          bookingRequestId={bookingRequestId}
        />
      );
    }
  };

  return (
    <ProjectWorkflowPanel
      isOpen={isSessionDetailsPanelOpen}
      workflowStep={workflowStep}
      projectType={ProjectType.RECORDING}
      variant={ProjectWorkflowPanelVariant.SESSION_MAIN_FLOW}
      noSidePanel={noSidePanel}
      onClose={handleCloseSessionDetailsPanel}
      collaborator={userType === ProjectUserType.ARTIST ? engineer : artist}
      isLoading={isLoadingData}
      userType={userType}
      projectId={recordingSession?.project.id}
      outstandingBalance={recordingSession?.outstanding_balance}
      overrideTaskMessage={getOverrideTaskMessage()}
      recordingSession={recordingSession}
      isCancelledSession={Boolean(recordingSession?.refunded)}
      // A studio manager should have all actions available to an engineer
      showEngineerActionDropdown={isManager}
    >
      <ViewSlider
        keepViewsMounted={!noSidePanel}
        renderView={renderView}
        numViews={2}
        activeView={sessionDetailsView}
        animateHeight
        style={{ overflow: "visible" }} // allows TrackTableOptions to be visible
      />
      <SidePanelBottomDrawer
        isOpen={Boolean(bottomDrawerContent)}
        onClose={() => setBottomDrawerContent(RESET)}
      >
        {bottomDrawerContent}
      </SidePanelBottomDrawer>
    </ProjectWorkflowPanel>
  );
};
