import { useMemo } from "react";
import { useAppSelector } from "../../store/hooks";
import { RecordingSession } from "../../store/models/recordingSession";

export const useUserHasPendingSessionModificationRequest = (
  recordingSession?: RecordingSession | null,
) => {
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  return useMemo(() => {
    if (!loggedInUser) return false;
    if (!recordingSession) {
      return false;
    }
    if (!recordingSession.session_modification) {
      return false;
    }
    return recordingSession.session_modification.required_acceptances.some(
      (acceptance) => {
        return (
          acceptance.user_id === loggedInUser.id &&
          acceptance.accepted === null &&
          acceptance.deleted === null
        );
      },
    );
  }, [loggedInUser, recordingSession]);
};
