import { useState, FC, useMemo, useEffect } from "react";
import { Alt } from "../../../store/models/alts";
import { projectTypeReadableName } from "../../../store/models/project";
import Service from "../../../store/models/service";
import User from "../../../store/models/user";
import leftArrowButton from "../../assets/left-arrow-button.svg";
import { CheckoutAltsForm } from "../../components/CheckoutAltsForm/CheckoutAltsForm";
import { CheckoutScreenHeader } from "../../components/CheckoutScreenHeader/CheckoutScreenHeader";
import { PrimaryButton } from "../../elements/Button/button.stories";
import { Card } from "../../elements/Card/Card";
import { Textfield } from "../../elements/Textfield1/Textfield1";
import { ColorPalette } from "../../theme";
import "./TitleSongsScreen.css";
import { ProjectHandoffProps } from "../../components/ProjectHandoff/ProjectHandoff";
import { useIsAandR } from "../../../hooks/useIsAandR";
import { useAppSelector } from "../../../store/hooks";
import { PrevButton } from "../../elements/PrevButton/PrevButton";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

interface ArrowButtonProps {
  onClick: () => void;
  isLeftArrowButton?: boolean;
  label: string;
}

export const ArrowButton: FC<ArrowButtonProps> = ({
  onClick,
  isLeftArrowButton = false,
  label,
}) => (
  <div
    className="d-flex align-items-center left-arrow-button"
    onClick={onClick}
  >
    <img
      src={leftArrowButton}
      alt={isLeftArrowButton ? "left arrow" : "right arrow"}
    />
    <label>{label}</label>
  </div>
);

export interface TitleSongsScreenProps extends ProjectHandoffProps {
  numberOfSongs: number;
  projectTitle: string;
  artistName: string;
  usingLabelPrice: boolean;
  service: Service;
  engineerUser: User;
  alts: Set<Alt>;
  songsList: string[];
  setSongsList: (list: string[]) => void;
  setProjectTitle: (title: string) => void;
  setArtistName: (title: string) => void;
  onClickNextStep: () => void;
  onClickPreviousStep: () => void;
  songTitle: string;
}

export const TitleSongsScreen = ({
  numberOfSongs,
  projectTitle,
  artistName,
  usingLabelPrice = false,
  service,
  engineerUser,
  alts,
  songsList,
  setSongsList,
  setProjectTitle,
  setArtistName,
  onClickNextStep,
  onClickPreviousStep,
  scheduledProjectMetaData,
  songTitle,
}: TitleSongsScreenProps) => {
  const [refresh, setRefresh] = useState(0);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const isUserAnr = useIsAandR(loggedInUser);
  const showAandRFlow = isUserAnr;
  const darkMode = useAtomValue(darkModeAtom);
  const onArtistNameChange = (e: any) => setArtistName(e.target.value);
  const onProjectNameChange = (e: any) => setProjectTitle(e.target.value);

  const onSongTitleChange = (e: any, i: number) => {
    const songsListCopy = songsList;
    songsListCopy[i] = e.target.value;
    setSongsList(songsListCopy);
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    const songsListCopy = songsList;
    songsListCopy[0] = songTitle;
    setSongsList(songsListCopy);
    setRefresh(refresh + 1);
  }, [songTitle]);

  const goToCheckout = () => {
    const songsSet = new Set();
    const songsListCopy = songsList.map((song, i) => {
      let newSongName = song;
      if (songsSet.has(song)) {
        newSongName = song + " (Track #" + (i + 1) + ")";
      } else if (song === "") {
        newSongName = "Untitled #" + (i + 1);
      }
      songsSet.add(newSongName);
      return newSongName;
    });
    setSongsList(songsListCopy);
    if (numberOfSongs <= 1) {
      setProjectTitle(songsListCopy[0]);
    }
    onClickNextStep();
  };

  const getCheckoutDisabled = useMemo(() => {
    if (showAandRFlow) {
      return artistName.length === 0;
    }
    return (
      (projectTitle === "" && numberOfSongs > 1) ||
      (numberOfSongs <= 1 && songsList[0] === "")
    );
  }, [
    projectTitle,
    numberOfSongs,
    songsList,
    showAandRFlow,
    artistName,
    refresh,
  ]);

  return (
    <div data-theme={darkMode ? "dark" : ""} className="checkout-screen">
      <CheckoutScreenHeader
        engineerUser={engineerUser}
        service={service}
        step="Title"
      >
        <PrevButton onClickPreviousStep={onClickPreviousStep} />
      </CheckoutScreenHeader>
      <div className="checkout-card-row-container">
        <Card customClassName={"checkout-card"}>
          <h1 className="card-header" style={{ paddingLeft: "30px" }}>
            {projectTypeReadableName.get(service.service_type)} Project
            <span style={{ color: ColorPalette.BoomyOrange400 }}>
              {" "}
              x{numberOfSongs}
            </span>{" "}
          </h1>
          <CheckoutAltsForm disabled={true} alts={alts} service={service} />
        </Card>
        <Card customClassName={"checkout-card"}>
          <>
            {(showAandRFlow || usingLabelPrice) && (
              <div className="card-header-row">
                <h1 className="card-header">{"Artist Name"} *</h1>
                <Textfield
                  required={showAandRFlow}
                  value={artistName}
                  onChange={onArtistNameChange}
                  label="Artist Name"
                />
              </div>
            )}
          </>
          {numberOfSongs > 1 ? (
            <div className="card-header-row">
              <p className="b1-semi-bold">Project Title *</p>
              <form>
                <Textfield
                  required={true}
                  value={projectTitle}
                  onChange={onProjectNameChange}
                  label="Untitled Project"
                />
              </form>
            </div>
          ) : (
            <div className="song-field">
              <h1 className="card-header mt-3">
                {scheduledProjectMetaData
                  ? scheduledProjectMetaData.title
                  : "Title Your Project"}
              </h1>
            </div>
          )}
          <form className="add-alts-form scroll-overflow">
            <table className="project-titles-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Track Title</th>
                </tr>
              </thead>
              <tbody>
                {songsList.map((track, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <Textfield
                        onChange={(e) => onSongTitleChange(e, i)}
                        label={"Untitled #" + (i + 1)}
                        value={songsList[i] ? songsList[i] : ""}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </form>
        </Card>
      </div>
      <div className="checkout-card-row-container">
        <PrimaryButton
          customClassName="checkout-footer-next-button"
          onClick={goToCheckout}
          label="Choose Dates"
          primary={true}
          disabled={getCheckoutDisabled}
        />
      </div>
    </div>
  );
};
