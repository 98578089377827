import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { CSSProperties, HTMLAttributes } from "react";
import {
  ChipButton,
  ChipCloseIcon,
  ChipContainer,
  ChipContainerOutlined,
} from "./Chip.styles";
import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../Text/TextUtils";

export interface ChipProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onClick"> {
  text: string;
  upperCase?: boolean;
  onDelete?: () => void;
  style?: CSSProperties;
  variant?: ChipVariant;
  loading?: boolean;
}

export enum ChipVariant {
  OUTLINED = "outlined",
  DEFAULT = "default",
}

const ChipVariantMap: Record<ChipVariant, typeof ChipContainer> = {
  [ChipVariant.OUTLINED]: ChipContainerOutlined,
  [ChipVariant.DEFAULT]: ChipContainer,
};

export const Chip = ({
  text,
  upperCase = false,
  onDelete,
  style,
  variant = ChipVariant.DEFAULT,
  children,
  loading,
  ...props
}: ChipProps) => {
  const ChipContainerWithVariant = ChipVariantMap[variant];
  return (
    <ChipContainerWithVariant
      style={style}
      $onDelete={Boolean(onDelete)}
      $loading={loading}
      {...props}
    >
      <Text style={{ lineHeight: "normal" }} variant={TextStyleVariant.P2}>
        {upperCase ? text.toUpperCase() : text}
      </Text>
      {children}
      {onDelete && (
        <ChipButton style={style} onClick={onDelete} disabled={loading}>
          <ChipCloseIcon icon={faXmark} onClick={onDelete} />
        </ChipButton>
      )}
    </ChipContainerWithVariant>
  );
};
