import { atom } from "jotai";
import { SlimActiveStudio } from "../../store/models/studio";

type ActiveUserStudiosState = SlimActiveStudio[];
const _activeUserStudiosAtom = atom([] as ActiveUserStudiosState);

// This buffer atom exists so we can create side effects
// We know these will run when a developer interacts with the active user
export const activeUserStudiosAtom = atom(
  (get) => {
    return get(_activeUserStudiosAtom);
  },
  (
    _,
    set,
    studios:
      | ActiveUserStudiosState
      | ((oldState: ActiveUserStudiosState) => ActiveUserStudiosState),
  ) => {
    let result: ActiveUserStudiosState = [];
    // This is a helper function to capture the result from the set state action
    set(_activeUserStudiosAtom, (oldState) => {
      if (typeof studios === "function") {
        result = studios(oldState);
      } else {
        result = studios;
      }
      return result;
    });
  },
);
