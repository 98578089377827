import { ReactNode, useMemo } from "react";
import { NotificationDotWrapper } from "./ChannelUnreadMessagesDot.styles";
import { BoxProps } from "@mui/material";
import { useAtomValue } from "jotai";
import { channelUnreadMessagesCountAtom } from "../../../hooks/chatHooks/atoms";

export interface ChannelUnreadMessagesDotProps extends BoxProps {
  channelId?: string;
  children?: ReactNode;
}

export const ChannelUnreadMessagesDot = ({
  children,
  channelId,
  ...props
}: ChannelUnreadMessagesDotProps) => {
  const channelUnreadMessagesCount = useAtomValue(
    channelUnreadMessagesCountAtom,
  );

  const showNotificationDot = useMemo(() => {
    if (!channelId) return false;
    if (!channelUnreadMessagesCount[channelId]) return false;
    return channelUnreadMessagesCount[channelId] > 0;
  }, [channelUnreadMessagesCount, channelId]);
  return (
    <NotificationDotWrapper {...props}>
      {showNotificationDot && <div className={"notification"} />}
      {children}
    </NotificationDotWrapper>
  );
};
