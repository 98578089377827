import classNames from "classnames";
import { Fragment, useMemo } from "react";
import { isMobile } from "react-device-detect";
import {
  MixMasterOrderSummary,
  MixMasterProjectOrderSummaryData,
  toggleApplyLabelRate,
  toggleMixMasterProviderAssumesFees,
} from "../../../store/actions/mixMasterCartsStore";
import {
  RecordingFee,
  RecordingFees,
  RecordingOrderSummary,
  RecordingProviderType,
  toggleRecordingProviderAssumesFees,
  toggleShareArtistContactInfo,
} from "../../../store/actions/recordingCartsStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Alt, altToDisplayString } from "../../../store/models/alts";
import {
  ProjectType,
  projectTypeReadableName,
} from "../../../store/models/project";
import {
  DollarFormatter,
  PennyDollarFormatter,
} from "../../../store/utils/formatUtils";
import {
  Text,
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import {
  TOGGLE_SWITCH_SIZE,
  ToggleSwitch,
} from "../../elements/ToggleSwitch/ToggleSwitch";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import "./OrderBreakdown.css";

export interface OrderBreakdownProps {
  orderSummary: MixMasterOrderSummary | RecordingOrderSummary | null;
  showHeader?: boolean;
  isLoading: boolean;
  showLabelToggle: boolean;
  emptyStateText?: string;
  showAssumeFeesToggle?: boolean;
  showShareArtistContactInfoToggle?: boolean;
  hideNonEngineerFees?: boolean;
  hideNonStudioFees?: boolean;
}

const updatedUnitPriceComponent = (
  originalPrice: number,
  updatedPrice: number,
) => {
  return (
    <span>
      <Text
        style={{
          textDecoration: "line-through",
          display: "inline",
        }}
      >
        {PennyDollarFormatter().format(originalPrice)}
      </Text>
      <Text
        style={{
          display: "inline",
          marginLeft: "4px",
        }}
        weight={TEXT_WEIGHT.SEMI_BOLD}
      >
        {PennyDollarFormatter().format(updatedPrice)}
      </Text>
    </span>
  );
};

export const OrderBreakdown = ({
  orderSummary,
  showHeader = true,
  isLoading,
  showLabelToggle,
  emptyStateText,
  showAssumeFeesToggle = false,
  showShareArtistContactInfoToggle = false,
  hideNonEngineerFees = false,
  hideNonStudioFees = false,
}: OrderBreakdownProps) => {
  const dispatch = useAppDispatch();
  const { activeServiceType } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const isRecordingSummary = Boolean(
    (orderSummary as RecordingOrderSummary)?.recordingFees,
  );
  const {
    applyLabelRate,
    addMaster,
    addAtmos,
    providerAssumesFees: mixMasterProviderAssumesFees,
  } = useAppSelector((state) => state.mixMasterCartsStore.cart);
  const {
    providerAssumesFees: recordingProviderAssumesFees,
    shareArtistContactInfo,
  } = useAppSelector((state) => state.recordingCartsStore.cart);
  const isRecordingSession = activeServiceType === ProjectType.RECORDING;
  const providerAssumesFees = isRecordingSession
    ? recordingProviderAssumesFees
    : mixMasterProviderAssumesFees;
  const toggleProviderAssumesFees = isRecordingSession
    ? toggleRecordingProviderAssumesFees
    : toggleMixMasterProviderAssumesFees;

  // Determines which total price to show depending on the Project Type and User Type
  const orderTotalPrice = useMemo(() => {
    if (!orderSummary) return 0;

    // If hiding non-engineer fees on a Recording Session, only add up the engineer fees
    if (isRecordingSummary && hideNonEngineerFees) {
      const { recordingFees } = orderSummary as RecordingOrderSummary;
      const recordingFeesArray = Object.values(recordingFees) as RecordingFee[];
      const totalEngineerFees = recordingFeesArray.reduce((total, service) => {
        const isEngineerFee =
          service.providerType == RecordingProviderType.ENGINEER;
        return isEngineerFee ? total + service.providerTotalPrice : total;
      }, 0);
      return totalEngineerFees;
    }

    // For all other projects, show the total price with or without the EE fees
    const showFees = !hideNonEngineerFees && !hideNonStudioFees;
    return showFees
      ? orderSummary.totalPrice
      : orderSummary.totalPrice - orderSummary.fees;
  }, [
    orderSummary,
    isRecordingSummary,
    hideNonEngineerFees,
    hideNonStudioFees,
  ]);

  const getAltTooltipText = (alts: Alt[]) => {
    return alts
      .reduce<string[]>((acc, alt) => {
        return [...acc, altToDisplayString[alt]];
      }, [])
      .join(", ");
  };

  const getAltTotalPrice = (
    alts: Alt[],
    altUnitPrice: number,
    activeServiceType: ProjectType | undefined,
    projectQuantity: number,
  ) => {
    return (
      alts.filter((alt) => {
        if (alt === Alt.MAIN) {
          return false;
        }

        // Mastering service types do not include the CLEAN alt by default
        // We only charge for CLEAN on a mastering project when not part of a mix/master bundle
        if (alt === Alt.CLEAN) {
          return activeServiceType === ProjectType.MASTERING;
        }

        return true;
      }).length *
      altUnitPrice *
      projectQuantity
    );
  };

  const getMixMasterItemRows = (
    projects: MixMasterProjectOrderSummaryData[],
  ) => {
    return projects.map((project) => {
      const {
        serviceType,
        updatedUnitPrice,
        serviceUnitPrice,
        serviceTotalPrice,
        alts,
        quantity,
      } = project;
      const unitPriceComponent = updatedUnitPrice
        ? updatedUnitPriceComponent(serviceUnitPrice, updatedUnitPrice)
        : PennyDollarFormatter().format(serviceUnitPrice);
      return (
        <Fragment key={serviceType}>
          <tr className="order-breakdown-item-row">
            <td>{projectTypeReadableName.get(serviceType)} fee</td>
            <td>{quantity}</td>
            <td>{unitPriceComponent}</td>
            <td>{PennyDollarFormatter().format(serviceTotalPrice)}</td>
          </tr>
          {alts.altList.length > 0 && (
            <tr className="order-breakdown-item-row tooltip-row">
              <td>
                <Text
                  color={TEXT_COLOR.SECONDARY}
                  style={{
                    margin: "0 4px 0 12px",
                    display: "inline",
                  }}
                >
                  Alts&nbsp;
                  <ToolTipTextArea
                    text={getAltTooltipText(alts.altList) || ""}
                  />
                </Text>
              </td>
              <td>{alts.altList.length * quantity}</td>
              <td>$0 - {PennyDollarFormatter().format(alts.altUnitPrice)}</td>
              <td>
                {PennyDollarFormatter().format(
                  getAltTotalPrice(
                    alts.altList,
                    alts.altUnitPrice,
                    activeServiceType,
                    quantity,
                  ),
                )}
              </td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  const getRecordingItemRows = (recordingFees: RecordingFees) => {
    return Object.entries(recordingFees).map(
      ([recordingServiceId, serviceProvider]) => {
        const {
          providerType,
          providerTotalDuration,
          providerUnitPrice,
          providerUsername,
          providerDiscountedUnitPrice,
          providerTotalPrice,
        } = serviceProvider as RecordingFee;

        if (
          providerType !== RecordingProviderType.ENGINEER &&
          hideNonEngineerFees
        ) {
          return null;
        }
        const totalDuration = providerTotalDuration / 60;
        const totalPrice = isMobile
          ? DollarFormatter().format(providerTotalPrice)
          : PennyDollarFormatter().format(providerTotalPrice);
        const unitPrice = isMobile
          ? DollarFormatter().format(providerUnitPrice)
          : PennyDollarFormatter().format(providerUnitPrice);
        const unitPriceComponent =
          providerDiscountedUnitPrice &&
          providerDiscountedUnitPrice < providerUnitPrice
            ? updatedUnitPriceComponent(
                providerUnitPrice,
                providerDiscountedUnitPrice,
              )
            : unitPrice;
        const itemString =
          providerType === RecordingProviderType.STUDIO
            ? `${providerUsername} - studio fee`
            : "Recording engineer fee";
        return (
          <Fragment key={recordingServiceId}>
            <tr className="order-breakdown-item-row tooltip-row">
              <td>
                <Text style={{ margin: "0 4px 0 12px", display: "inline" }}>
                  {itemString}&nbsp;
                  <ToolTipTextArea
                    text={
                      providerType === RecordingProviderType.STUDIO
                        ? "The cost associated with time booked in this studio room."
                        : "The cost associated with booking this recording engineer for their time."
                    }
                    tooltipTextStyle={{
                      textAlign: "left",
                      fontWeight: "normal",
                    }}
                  />
                </Text>
              </td>
              <td>{totalDuration}</td>
              <td>{unitPriceComponent}</td>
              <td>{totalPrice}</td>
            </tr>
            {providerType === RecordingProviderType.ENGINEER && (
              <tr className="order-breakdown-item-row">
                <td className="order-breakdown-recording-eng">
                  @{providerUsername} ({unitPrice}/hr)
                </td>
                <td />
                <td />
                <td />
              </tr>
            )}
          </Fragment>
        );
      },
    );
  };

  const getDiscountOrAdditionalFeesRow = (
    discountValue: number,
    discountPercentage: number,
    isRecordingSummary: boolean,
  ) => {
    const discountTooltipText =
      showLabelToggle && (addMaster || addAtmos)
        ? "A discount may apply for added mastering or atmos, based on your package preferences"
        : "Discounts apply for bulk and bundled packages";
    const tooltipText =
      discountPercentage < 0
        ? discountTooltipText
        : isRecordingSummary
          ? "Added fees may apply for incidental charges"
          : "Rush fees apply for this service provider when selecting an expedited date";
    return (
      <tr className="order-breakdown-bold-row tooltip-row">
        <td>
          <Text
            style={{
              marginRight: "4px",
              display: "inline",
            }}
            weight={TEXT_WEIGHT.BOLD}
          >
            {discountPercentage < 0
              ? "Discount Applied ("
              : isRecordingSummary
                ? "Added Fees ("
                : "Rush Fees ("}
            {discountPercentage.toFixed(1)}%) &nbsp;
            <ToolTipTextArea
              text={tooltipText}
              tooltipTextStyle={{
                textAlign: "left",
                fontWeight: "normal",
              }}
            />
          </Text>
        </td>
        <td></td>
        <td></td>
        <td>{PennyDollarFormatter().format(discountValue)}</td>
      </tr>
    );
  };

  return (
    <div className="order-breakdown-container">
      <div className="order-breakdown-header-container">
        {showHeader && <Text variant={TextStyleVariant.H6}>Order Summary</Text>}
        <div className="order-breakdown-header-switches">
          {showAssumeFeesToggle && (
            <>
              <ToggleSwitch
                label={
                  <Text color={TEXT_COLOR.SECONDARY} size={TEXT_SIZE.SMALL}>
                    Take on client payment fees
                  </Text>
                }
                checked={providerAssumesFees}
                onChange={() => dispatch(toggleProviderAssumesFees())}
                disabled={isLoading}
                size={TOGGLE_SWITCH_SIZE.SMALL}
                updateCheckedLocally={false}
              />
              <ToolTipTextArea
                text="Price is reduced so that payment fees are taken from service provider instead of client"
                tooltipTextStyle={{
                  textAlign: "left",
                }}
              />
            </>
          )}
          {!isRecordingSummary && showLabelToggle && (
            <>
              <ToggleSwitch
                label={
                  <Text color={TEXT_COLOR.SECONDARY} size={TEXT_SIZE.SMALL}>
                    Label rates
                  </Text>
                }
                checked={applyLabelRate}
                onChange={() => dispatch(toggleApplyLabelRate())}
                disabled={isLoading}
                size={TOGGLE_SWITCH_SIZE.SMALL}
                updateCheckedLocally={false}
              />
              <ToolTipTextArea
                text="Charge the label rate you specified in your service package setup"
                tooltipTextStyle={{
                  textAlign: "left",
                }}
              />
            </>
          )}
          {showShareArtistContactInfoToggle && (
            <>
              <ToggleSwitch
                label={
                  <Text color={TEXT_COLOR.SECONDARY} size={TEXT_SIZE.SMALL}>
                    Share contact info
                  </Text>
                }
                checked={shareArtistContactInfo}
                onChange={() => dispatch(toggleShareArtistContactInfo())}
                disabled={isLoading}
                size={TOGGLE_SWITCH_SIZE.SMALL}
                updateCheckedLocally={false}
              />
              <ToolTipTextArea
                text="Share your name and phone number with recording service providers"
                tooltipTextStyle={{
                  textAlign: "left",
                }}
              />
            </>
          )}
        </div>
      </div>
      <table className="order-breakdown-table">
        {isLoading && (
          <div className="order-breakdown-loader mt-3">
            <SoundWaveLoader
              width={100}
              height={100}
              styles={{ color: "var(--text-primary-color)" }}
            />
          </div>
        )}
        <thead>
          <tr>
            <th>Item</th>
            <th>{isRecordingSummary ? "Qty (hrs)" : "Qty"}</th>
            <th>Unit Price</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody
          className={classNames({
            "order-breakdown-table-body-loading": isLoading,
          })}
        >
          {!isLoading && !orderSummary && emptyStateText && (
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                <Text>{emptyStateText}</Text>
              </td>
            </tr>
          )}
          {orderSummary && (
            <>
              {(orderSummary as MixMasterOrderSummary).projects &&
                getMixMasterItemRows(
                  (orderSummary as MixMasterOrderSummary).projects,
                )}
              {(orderSummary as RecordingOrderSummary).recordingFees &&
                getRecordingItemRows(
                  (orderSummary as RecordingOrderSummary).recordingFees,
                )}
              {!hideNonEngineerFees && !hideNonStudioFees && (
                <tr className="order-breakdown-spacer">
                  <td>
                    {orderSummary.discountOrSurchargeValue > 0
                      ? "Full Price"
                      : "Subtotal"}
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    {PennyDollarFormatter().format(orderSummary.subtotal)}
                  </td>
                </tr>
              )}
              {orderSummary.discountOrSurchargeValue !== 0 &&
                orderSummary.discountOrSurchargePercentage !== 0 &&
                getDiscountOrAdditionalFeesRow(
                  orderSummary.discountOrSurchargeValue,
                  orderSummary.discountOrSurchargePercentage,
                  isRecordingSummary,
                )}
              <>
                {!hideNonEngineerFees && !hideNonStudioFees && (
                  <tr className="tooltip-row">
                    <td>
                      <Text
                        style={{ margin: "0 4px 0 12px", display: "inline" }}
                      >
                        Service Fee&nbsp;
                        <ToolTipTextArea
                          text="Platform charge to cover transaction fees and file storage costs."
                          tooltipTextStyle={{
                            textAlign: "left",
                            fontWeight: "normal",
                          }}
                        />
                      </Text>
                    </td>
                    <td></td>
                    <td></td>
                    <td>{PennyDollarFormatter().format(orderSummary.fees)}</td>
                  </tr>
                )}
                <tr className="order-breakdown-table-footer order-breakdown-bold-row">
                  <td>Order Total</td>
                  <td />
                  <td></td>
                  <td>{PennyDollarFormatter().format(orderTotalPrice)}</td>
                </tr>
              </>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};
