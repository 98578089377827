import { useCallback, useEffect, useState } from "react";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { EditableTextField } from "../../elements/EditableTextField/EditableTextField";
import "./PromoCodeField.css";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { applyTransactionPromoCode } from "../../../store/actions/transactions";

export interface PromoCodeFieldProps {
  transactionCode: string;
  userId?: number;
  studioId?: number;
  autoApplyPromo?: boolean;
  promoCodeStr?: string;
  skipApply?: boolean;
  textFieldClassName?: string;
  onApplyPromo?: (promoCode: string) => void;
}

export const PromoCodeField = ({
  transactionCode,
  userId,
  studioId,
  autoApplyPromo = true,
  promoCodeStr,
  skipApply,
  textFieldClassName = "",
  onApplyPromo,
}: PromoCodeFieldProps) => {
  const [codeStr, setCodeStr] = useState<string>(promoCodeStr ?? "");
  const [loading, setLoading] = useState<boolean>(false);
  const appliedPromoCode = useAppSelector(
    (state) => state.marketingDataStore.appliedPromoCode,
  );
  const updating = useAppSelector((state) => state.transactionStore.updating);

  const dispatch = useAppDispatch();

  const applyPromo = useCallback(() => {
    if (skipApply && onApplyPromo) {
      return onApplyPromo(codeStr);
    }
    if (!codeStr || (skipApply && !onApplyPromo)) {
      return;
    }
    setLoading(true);
    dispatch(
      applyTransactionPromoCode({
        promocode: codeStr,
        transactionCode,
        promocode_studio_id: studioId,
        promocode_user_id: userId,
      }),
    )
      .unwrap()
      .then(({ code }) => {
        if (onApplyPromo) {
          onApplyPromo(code);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [
    codeStr,
    dispatch,
    onApplyPromo,
    userId,
    studioId,
    transactionCode,
    skipApply,
  ]);

  useEffect(() => {
    if (!promoCodeStr || !autoApplyPromo) return;
    applyPromo();
  }, [applyPromo, promoCodeStr, autoApplyPromo]);

  return (
    <div className="promo-code">
      <EditableTextField
        label="promocode"
        initialValue={appliedPromoCode?.code ?? codeStr}
        onChange={(text) => setCodeStr(text.toUpperCase())}
        editMode={true}
        className={textFieldClassName}
      />
      <div className="apply-promo-code-button">
        <Button
          onClick={applyPromo}
          loading={loading || updating}
          variant={ButtonVariant.PRIMARY}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
