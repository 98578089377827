import styled from "styled-components";

export const StyledSessionsOverviewContainer = styled.div`
  padding: 12px 0px 16px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;
export const StyledSessionsOverviewBoldText = styled.p`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-family: Roobert;
  font-size: ${({ theme }) => theme.textSizeSm};
  font-style: normal;
  font-weight: 600;
`;

export const StyledSessionsOverviewDefaultText = styled(
  StyledSessionsOverviewBoldText,
)`
  font-weight: normal;
`;

export const StyledSessionsOverviewRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
