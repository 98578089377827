import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "rc-pagination";
import { useEffect, useMemo, useState } from "react";
import { useGetPaginatedAdminActionItems } from "../../../hooks/adminDashboard/useGetPaginatedAdminActionItems";
import { useFetchPaginatedScheduledProject } from "../../../hooks/scheduledProjectHooks/useFetchPaginatedScheduledProjects";
import { useAppSelector } from "../../../store/hooks";
import { PaginatedScheduledProject } from "../../../store/models/scheduledproject";
import { ActionItemRow } from "../../elements/ActionItemRow/ActionItemRow";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { BookingRequestedPanel } from "../BookingRequestedPanel/BookingRequestedPanel";
import { useBookingRequestedPanel } from "../BookingRequestedPanel/utils";
import { DashboardCardHeader } from "../DashboardCardHeader/DashboardCardHeader";
import "./ActionItems.css";
import { useSetAtom } from "jotai";
import { adminSidePanelAtom } from "../../screens/AdminDashboardScreen/atoms";

export interface ActionItemsProps {
  onSidePanel?: boolean;
  onClick?: () => void;
  pageSize?: number;
}

const PAGE_SIZE = 4;

export const ActionItems = ({
  onSidePanel = false,
  onClick,
  pageSize = PAGE_SIZE,
}: ActionItemsProps) => {
  const [isPendingTab, setIsPendingTab] = useState<boolean>(true);
  const setAdminSidePanelOpen = useSetAtom(adminSidePanelAtom);
  // defines page for paginated AdminActionItems
  const [page, setPage] = useState(1);

  // fetch scheduled projects
  const { isLoading: isLoadingScheduledProjects } =
    useFetchPaginatedScheduledProject({});

  // grab scheduled projects from redux store to determine if any
  // ScheduledProjects need to be accepted or assigned (to another engineer)
  const { data: paginatedScheduledProjects } = useAppSelector(
    (state) => state.paginatedScheduledProjects,
  );

  const scheduledProjects = useMemo(() => {
    // store scheduled projects by id
    const scheduledProjects: Record<number, PaginatedScheduledProject> = {};

    // iterate over each page in paginatedScheduledProjects and add to scheduledProjects
    for (const page in paginatedScheduledProjects) {
      const scheduledProjectPage = paginatedScheduledProjects[page];
      scheduledProjectPage.forEach((scheduledProject) => {
        scheduledProjects[scheduledProject.id] = scheduledProject;
      });
    }

    return scheduledProjects;
  }, [paginatedScheduledProjects]);

  const {
    isLoading,
    data: actionItemData,
    refetch: refetchAdminActionItems,
  } = useGetPaginatedAdminActionItems({
    completed: !isPendingTab,
    page,
  });
  const paginatedAdminActions = actionItemData || {
    data: [],
    num_pages: 1,
    count: 0,
  };

  const {
    isBookingRequestedPanelOpen,
    handleOpenBookingRequestedPanel,
    handleCloseBookingRequestedPanel,
    selectedScheduledProjectId,
    selectedScheduledProject,
  } = useBookingRequestedPanel();

  const handleCloseRequestPanel = async () => {
    handleCloseBookingRequestedPanel();
    // this task can be done in the background after the panel is closed
    await refetchAdminActionItems();
  };

  // Open BookingRequestedPanel if query param exists
  useEffect(() => {
    // grab the scheduled project ID from the location state or the URL query param
    if (!selectedScheduledProjectId || isLoadingScheduledProjects) return;
    const scheduledProject = scheduledProjects[selectedScheduledProjectId];

    if (scheduledProject) {
      if (onSidePanel) {
        setAdminSidePanelOpen(false);
      }
      handleOpenBookingRequestedPanel(scheduledProject);
    }
  }, [
    handleOpenBookingRequestedPanel,
    isLoadingScheduledProjects,
    selectedScheduledProjectId,
    scheduledProjects,
    onSidePanel,
    setAdminSidePanelOpen,
  ]);

  // Open BookingRequestedPanel if query param exists
  useEffect(() => {
    if (!selectedScheduledProjectId) return;
    const scheduledProject = scheduledProjects[selectedScheduledProjectId];

    if (scheduledProject) {
      if (onSidePanel) {
        setAdminSidePanelOpen(false);
      }
      handleOpenBookingRequestedPanel(scheduledProject);
    }
  }, [
    handleOpenBookingRequestedPanel,
    selectedScheduledProjectId,
    scheduledProjects,
    onSidePanel,
    setAdminSidePanelOpen,
  ]);

  return (
    <div className="action-items-container">
      {onSidePanel && (
        <div className="action-items-header-container ">
          <p
            onClick={() => setIsPendingTab(true)}
            className={"dashboard-card-header__title ".concat(
              isPendingTab ? "active" : "",
            )}
            style={{ cursor: "pointer" }}
          >
            Your Action Items
          </p>
          <p
            onClick={() => setIsPendingTab(false)}
            style={{ cursor: "pointer" }}
            className={"dashboard-card-header__title ".concat(
              !isPendingTab ? "active" : "",
            )}
          >
            Completed Action Items
          </p>
        </div>
      )}
      {!onSidePanel && (
        <DashboardCardHeader
          title={isPendingTab ? "Your Action Items" : "Completed Action Items"}
          actionButton={
            onSidePanel ? null : (
              <FontAwesomeIcon
                onClick={onClick}
                icon={faChevronRight}
                color={"var(--medium-grey)"}
                style={{ cursor: "pointer" }}
              />
            )
          }
        />
      )}
      <div className="action-items-column-container">
        {!isLoading && !paginatedAdminActions.data.length && (
          <div className="action-items-empty-list-container">
            <p
              style={{
                color: "var(--text-secondary-color)",
                width: "fit-content",
                whiteSpace: "normal",
              }}
              className="b1 empty-text"
            >
              You don&apos;t have any Action Items currently. When someone on
              your team needs your help, you will see your Action Items here.{" "}
            </p>
          </div>
        )}
        {paginatedAdminActions.data.length !== 0 &&
          paginatedAdminActions.data.map((actionItem) => (
            <ActionItemRow
              key={actionItem.id}
              purchaseOrderData={actionItem}
              completedOverride={!isPendingTab}
              handleOpenBookingRequestedPanel={(p) => {
                if (onSidePanel) {
                  setAdminSidePanelOpen(false);
                }
                handleOpenBookingRequestedPanel(p);
              }}
              // refetch is passed down to preserve the current state of the query (page, pageSize, completed, etc)
              refetchAdminActionItems={refetchAdminActionItems}
              scheduledProject={actionItem.scheduled_project}
            />
          ))}
        {isLoading && <SoundWaveLoader height={100} width={100} />}
      </div>
      <Pagination
        current={page}
        total={paginatedAdminActions.count}
        pageSize={pageSize}
        onChange={(nextPage) => {
          if (paginatedAdminActions.num_pages < nextPage) {
            return;
          }
          setPage(nextPage);
        }}
      />
      {!onSidePanel && (
        <BookingRequestedPanel
          isOpen={isBookingRequestedPanelOpen}
          onClose={handleCloseRequestPanel}
          noRedirect
          scheduledProject={selectedScheduledProject}
        />
      )}
    </div>
  );
};
