import {
  faEllipsisVertical,
  faEnvelope,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useClickOutside from "../../../hooks/useClickOutside";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useUserProfileImageURL } from "../../../hooks/useUserProfileImage";
import { SCREENS } from "../../../routes";
import { useAppSelector } from "../../../store/hooks";
import { TEAM_ROLES, TeamRole } from "../../../store/models/admins";
import User from "../../../store/models/user";
import { ToolTipTextArea } from "../../components/ToolTipTextArea/ToolTipTextArea";
import { SoundWaveLoader } from "../SoundWaveLoader/SoundWaveLoader";
import "./TeamMember.css";
import { ChatButton } from "../../components/ChatButton/ChatButton";
import { CHANNEL_ID_PREFIX } from "../../../constants/chat";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { OnlineIconDiv } from "./TeamMember.styles";

export interface TeamMemberOptionsProps {
  userId: number;
  setIsOpen: (isOpen: boolean) => void;
  isPending: boolean;
  isOpen: boolean;
  onClick: () => void;
}

const TeamMemberOptions = ({
  setIsOpen,
  isPending = false,
  isOpen,
  onClick,
  userId,
}: TeamMemberOptionsProps) => {
  const myRef = useRef<HTMLDivElement>(null);

  const onClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useClickOutside(myRef, onClickOutside);

  return (
    <div ref={myRef} className="team-member-menu-container">
      <div className="team-member-menu-item">
        <ChatButton
          buttonVariant={ButtonVariant.GHOST}
          buttonText={"Message"}
          prefix={CHANNEL_ID_PREFIX.DIRECT_MESSAGE}
          relatedId={userId}
          onButtonClick={() => {
            setIsOpen(false);
          }}
          customButtonStyle={{
            padding: 0,
            minHeight: "unset",
            color: "var(--black)",
          }}
          className="b2-semi-bold"
        />
      </div>
      <div onClick={onClick} className="team-member-menu-item">
        <p className="b2-semi-bold">{isPending ? "Revoke" : "Remove"}</p>
      </div>
    </div>
  );
};

export interface TeamMemberProps {
  user?: User;
  role: TeamRole;
  email?: string;
  isPending?: boolean;
  onClick: () => void;
}
export const TeamMember = ({
  user,
  role,
  email,
  isPending = false,
  onClick,
}: TeamMemberProps) => {
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const { isDesktop } = useMediaQueryBreakpoint();
  const url = useUserProfileImageURL(user);
  const history = useHistory();
  const { updating } = useAppSelector((state) => state.teamState);
  const roleString =
    TEAM_ROLES.find((teamRole) => teamRole.value === role)?.label ??
    TEAM_ROLES[TeamRole.NO_ROLE].label;

  const redirectToProfile = (username: string) => {
    history.push(SCREENS.PROFILE_SCREEN.replace(":username", username));
  };

  // Acts a ternary - true (online) | false (offline) | undefined (never signed up)
  const isOnline: boolean | undefined = useMemo(() => {
    if (!user) {
      return undefined;
    }
    return !user.last_online;
  }, [user]);
  const isOnlineMessage = useMemo(() => {
    if (typeof isOnline === "undefined") {
      return "Invited to Sign Up";
    }
    if (isOnline) {
      return "Online Now";
    }
    return "Currently Offline";
  }, [isOnline]);

  const handleRevokeOrRemove = () => {
    onClick();
    setShowDropDown(false);
  };

  if (updating) {
    return (
      <div className="team-member-container">
        <SoundWaveLoader height={30} width={100} />
      </div>
    );
  }

  return (
    <div className="team-member-container">
      {isDesktop && (
        <ToolTipTextArea text={isOnlineMessage}>
          <OnlineIconDiv
            $isOnline={isOnline}
            style={{
              marginRight: "34px",
            }}
            className="member-online-icon"
          />
        </ToolTipTextArea>
      )}
      <div
        onClick={user ? () => redirectToProfile(user.username) : undefined}
        style={{ cursor: user ? "pointer" : "default" }}
        className="team-member-info-container"
      >
        <img height={34} width={34} src={url} className="team-member-image" />
        {user && (
          <p className="b2-semi-bold">{`${user.first_name}  ${user.last_name}`}</p>
        )}
        {email && <p className="b2-semi-bold">{email}</p>}
        {isDesktop && (
          <p style={{ color: "var(--text-secondary-color)" }} className="b2">
            {user ? `@${user.username}` : " "}
          </p>
        )}
      </div>
      <p className="b2-semi-bold">{roleString}</p>
      <div className="team-member-icons-container">
        {showDropDown && (
          <TeamMemberOptions
            isPending={isPending}
            setIsOpen={() => setShowDropDown(false)}
            isOpen={showDropDown}
            onClick={handleRevokeOrRemove}
            userId={user?.id || 0}
          />
        )}
        {user && loggedInUser?.id !== user?.id && (
          <Button
            onClick={() => setShowDropDown(true)}
            variant={ButtonVariant.GHOST}
            style={{ padding: "5px" }}
          >
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              fixedWidth
              className="team-member-icon"
            />
          </Button>
        )}
        {isDesktop && user && loggedInUser?.id !== user?.id && (
          <ChatButton
            prefix={CHANNEL_ID_PREFIX.DIRECT_MESSAGE}
            relatedId={user.id}
            buttonText={
              <FontAwesomeIcon
                fixedWidth
                icon={faEnvelope}
                className="team-member-icon"
              />
            }
            buttonVariant={ButtonVariant.GHOST}
            customButtonStyle={{ padding: "5px" }}
          />
        )}
        {email && (
          <Button
            onClick={onClick}
            variant={ButtonVariant.GHOST}
            style={{ padding: "5px" }}
          >
            <FontAwesomeIcon
              icon={faX}
              className="team-member-icon"
              fixedWidth
            />
          </Button>
        )}
      </div>{" "}
    </div>
  );
};

export default TeamMember;
