import { useMemo } from "react";
import { useAppSelector } from "../store/hooks";
import { FileVersion } from "../store/models/fileVersion";

export const useIsFileSelected = (file?: FileVersion) => {
  const selectedFile = useAppSelector(
    (state) => state.abPlayerStore.selectedTrack,
  );
  return useMemo(() => {
    if (!file) return false;
    if (!selectedFile) return false;
    return file.id === selectedFile.id;
  }, [selectedFile, file]);
};
