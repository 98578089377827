import { isProd } from "../store/utils";

export const msalConfig = {
  auth: {
    clientId: isProd
      ? "0584c4ae-6956-4b3b-9476-b209148583ca"
      : "fc7eef58-0753-4931-90ac-a2a7fc477449",
    authority: isProd
      ? "https://login.microsoftonline.com/bbcb6b2f-8c7c-4e24-86e4-6c36fed00b78/"
      : "https://login.microsoftonline.com/bbcb6b2f-8c7c-4e24-86e4-6c36fed00b78/", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.protocol + "//" + window.location.host,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
