import { useCallback, useState } from "react";
import { Textfield } from "../../elements/Textfield1/Textfield1";
import { useAppDispatch } from "../../../store/hooks";
import { updateName } from "../../../store/actions/unauthenticatedUser";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";
import useModal from "../../../hooks/useModal";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { PopoverTrigger } from "@radix-ui/react-popover";

export interface RegisterUnauthenticatedUserNameProps {
  triggerComponent: React.ReactNode;
  postCommentAfterNameUpdate?: (name: string) => void;
}

export const RegisterUnauthenticatedUserName = ({
  triggerComponent,
  postCommentAfterNameUpdate,
}: RegisterUnauthenticatedUserNameProps) => {
  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  const [name, setName] = useState<string>("");
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(() => {
    dispatch(updateName(name));
    if (postCommentAfterNameUpdate) {
      postCommentAfterNameUpdate(name);
    }
    closePopover();
  }, [name, dispatch, closePopover, postCommentAfterNameUpdate]);

  const popoverContent = (
    <Textfield
      label={"Enter your name"}
      value={name}
      onChange={(event) => {
        setName(event.target.value);
      }}
    />
  );

  return (
    <BasePopover
      side="top"
      isOpen={isPopoverOpen}
      onConfirm={handleSubmit}
      onCancel={closePopover}
      closePopover={closePopover}
      setIsPopoverOpen={setIsPopoverOpen}
      title={"Guest Name"}
      description={"Enter your name to continue as a guest."}
      wrapperElement={containerElement}
      additionalContent={popoverContent}
      okButtonProps={{ disabled: !name }}
    >
      <PopoverTrigger asChild>{triggerComponent}</PopoverTrigger>
    </BasePopover>
  );
};
