import { MusoAuthButtons } from "./MusoAuthButtons";
import "./MusoCard.css";

export const MusoCard = () => {
  return (
    <div className="muso-card-container">
      <p
        className="b3"
        style={{
          textAlign: "center",
          color: "var(--gray-400)",
          paddingBottom: "28px",
        }}
      >
        Link your Muso.AI account to your profile.
      </p>

      <MusoAuthButtons />
    </div>
  );
};
