import { ProjectType } from "./project";

import { OptionType } from "../../stories/elements/DropDownSelector/DropdownSelector";

export enum Alt {
  ALL = -2,
  MAIN = -1,
  CLEAN,
  INSTRUMENTAL,
  A_CAPPELLA,
  PERFORMANCE,
  CLEAN_A_CAPPELLA,
  CLEAN_PERFORMANCE,
  MP4,
  CLEAN_MP4,
  VINYL_MASTERING,
}

export const UploadTypeOptions: OptionType[] = [
  {
    value: Alt.MAIN,
    label: "Main upload",
  },
  {
    value: Alt.CLEAN,
    label: "Clean upload",
  },
];

export const SelectAMPMOptions: OptionType[] = [
  {
    value: 0,
    label: "AM",
  },
  {
    value: 1,
    label: "PM",
  },
];

export const SelectHourInDayOptions: OptionType[] = [
  {
    value: 0,
    label: "12:00",
  },
  {
    value: 1,
    label: "01:00",
  },
  {
    value: 2,
    label: "02:00",
  },
  {
    value: 3,
    label: "03:00",
  },
  {
    value: 4,
    label: "04:00",
  },
  { value: 5, label: "05:00" },
  { value: 6, label: "06:00" },
  { value: 7, label: "07:00" },
  { value: 8, label: "08:00" },
  { value: 9, label: "09:00" },
  { value: 10, label: "10:00" },
  { value: 11, label: "11:00" },
];

export interface AltItem {
  alt: Alt;
  alt_price: string;
  deleted: null | string;
  id: number;
  project_id: number;
}

export const projectTypeToAltList: Record<ProjectType, Alt[]> = {
  [ProjectType.ATMOS_MIXING]: [Alt.MAIN, Alt.CLEAN],
  [ProjectType.MASTERING]: [
    Alt.MAIN,
    Alt.CLEAN,
    Alt.INSTRUMENTAL,
    Alt.A_CAPPELLA,
    Alt.PERFORMANCE,
    Alt.CLEAN_A_CAPPELLA,
    Alt.CLEAN_PERFORMANCE,
    Alt.VINYL_MASTERING,
  ],
  [ProjectType.TWO_TRACK_MIXING]: [
    Alt.MAIN,
    Alt.CLEAN,
    Alt.INSTRUMENTAL,
    Alt.A_CAPPELLA,
    Alt.PERFORMANCE,
    Alt.CLEAN_A_CAPPELLA,
    Alt.CLEAN_PERFORMANCE,
  ],
  [ProjectType.MIXING]: [
    Alt.MAIN,
    Alt.CLEAN,
    Alt.INSTRUMENTAL,
    Alt.A_CAPPELLA,
    Alt.PERFORMANCE,
    Alt.CLEAN_A_CAPPELLA,
    Alt.CLEAN_PERFORMANCE,
  ],
  [ProjectType.NO_TYPE]: [],
  [ProjectType.RECORDING]: [],
};

const ALL_DISPLAY_STRING = "All";
const MAIN_DISPLAY_STRING = "Main";
const CLEAN_DISPLAY_STRING = "Clean";
const INSTRUMENTAL_DISPLAY_STRING = "Instrumental";
const A_CAPPELLA_DISPLAY_STRING = "A cappella";
const PERFORMANCE_DISPLAY_STRING = "Performance";
const CLEAN_A_CAPPELLA_DISPLAY_STRING = "Clean A cappella";
const CLEAN_PERFORMANCE_DISPLAY_STRING = "Clean Performance";
const VINYL_MASTERING_DISPLAY_STRING = "Vinyl mastering";
const MP4_DISPLAY_STRING = "MP4";
const CLEAN_MP4_DISPLAY_STRING = "Clean MP4";

export const altToDisplayString: Record<Alt, string> = {
  [Alt.ALL]: ALL_DISPLAY_STRING,
  [Alt.MAIN]: MAIN_DISPLAY_STRING,
  [Alt.CLEAN]: CLEAN_DISPLAY_STRING,
  [Alt.INSTRUMENTAL]: INSTRUMENTAL_DISPLAY_STRING,
  [Alt.A_CAPPELLA]: A_CAPPELLA_DISPLAY_STRING,
  [Alt.PERFORMANCE]: PERFORMANCE_DISPLAY_STRING,
  [Alt.CLEAN_A_CAPPELLA]: CLEAN_A_CAPPELLA_DISPLAY_STRING,
  [Alt.CLEAN_PERFORMANCE]: CLEAN_PERFORMANCE_DISPLAY_STRING,
  [Alt.VINYL_MASTERING]: VINYL_MASTERING_DISPLAY_STRING,
  [Alt.MP4]: MP4_DISPLAY_STRING,
  [Alt.CLEAN_MP4]: CLEAN_MP4_DISPLAY_STRING,
};

export const AtmosAltStringToAltEnumMap = new Map([
  [CLEAN_DISPLAY_STRING, Alt.CLEAN],
]);

export const AltStringToAltEnumMap = new Map([
  [CLEAN_DISPLAY_STRING, Alt.CLEAN],
  [INSTRUMENTAL_DISPLAY_STRING, Alt.INSTRUMENTAL],
  [A_CAPPELLA_DISPLAY_STRING, Alt.A_CAPPELLA],
  [PERFORMANCE_DISPLAY_STRING, Alt.PERFORMANCE],
  [CLEAN_A_CAPPELLA_DISPLAY_STRING, Alt.CLEAN_A_CAPPELLA],
  [CLEAN_PERFORMANCE_DISPLAY_STRING, Alt.CLEAN_PERFORMANCE],
]);

export const MasteringAltStringToAltEnumMap = new Map([
  [CLEAN_DISPLAY_STRING, Alt.CLEAN],
  [INSTRUMENTAL_DISPLAY_STRING, Alt.INSTRUMENTAL],
  [A_CAPPELLA_DISPLAY_STRING, Alt.A_CAPPELLA],
  [PERFORMANCE_DISPLAY_STRING, Alt.PERFORMANCE],
  [CLEAN_A_CAPPELLA_DISPLAY_STRING, Alt.CLEAN_A_CAPPELLA],
  [CLEAN_PERFORMANCE_DISPLAY_STRING, Alt.CLEAN_PERFORMANCE],
  [VINYL_MASTERING_DISPLAY_STRING, Alt.VINYL_MASTERING],
]);

export const DefaultAltEnumListMixing: Set<Alt> = new Set([Alt.CLEAN]);

export const DefaultAltEnumListMastering: Set<Alt> = new Set([]);

export const ProjectTypeToDefaultAltsMap = new Map([
  [ProjectType.MIXING, DefaultAltEnumListMixing],
  [ProjectType.TWO_TRACK_MIXING, DefaultAltEnumListMixing],
  [ProjectType.MASTERING, DefaultAltEnumListMastering],
  [ProjectType.ATMOS_MIXING, DefaultAltEnumListMixing],
]);

export const ReviewTypeOptions: OptionType[] = [
  {
    value: Alt.ALL,
    label: altToDisplayString[Alt.ALL],
  },
  {
    value: Alt.MAIN,
    label: altToDisplayString[Alt.MAIN],
  },
  {
    value: Alt.CLEAN,
    label: altToDisplayString[Alt.CLEAN],
  },
];
