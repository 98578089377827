import { useEffect, useMemo, useState } from "react";
import { useGetAvailabilityOnThisDate } from "../../../hooks/recordingSessionHooks/useGetAvailabilityOnThisDate";
import { useGetContinuousBlockFromTime } from "../../../hooks/recordingSessionHooks/useGetContinuousBlockFromTime";
import {
  RecordingSessionCase,
  useGetTotalPriceForExtension,
} from "../../../hooks/recordingSessionHooks/useGetTotalPriceForExtension";
import { useGetStudioRoomFromRecordingSession } from "../../../hooks/recordingSessionHooks/useGetUsersFromRecordingSession";
import {
  RecordingSessionAvailability,
  useAvailabilitiesForDatesMap,
} from "../../../hooks/recordingSessionHooks/useGetWorkHoursMap";
import { getRecordingServiceAvailability } from "../../../store/actions/recording";
import { loadEngineerServices } from "../../../store/actions/services";
import { CheckoutRecordingSessionExtensionParams } from "../../../store/actions/transactions";
import { useAppDispatch } from "../../../store/hooks";
import { RecordingService } from "../../../store/models/recording";
import { RecordingSession } from "../../../store/models/recordingSession";
import { getCurrentDate } from "../../../store/utils/dateTimeUtils";
import { convertDatesToOptionType } from "../../../store/utils/serviceUtils";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { InvoiceTotal } from "../../elements/InvoiceTotal/InvoiceTotal";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import "./ExtendSessionModal.css";

export interface DurationSelectionProps {
  recordingSession: RecordingSession;
  originalDuration: number;
  originalSessionEndTime: Date;
  durationSelected: OptionType | undefined;
  setDurationSelected: (duration: OptionType) => void;
  setExtensionDuration: (duration: number) => void;
  params: CheckoutRecordingSessionExtensionParams;
  setParams: React.Dispatch<
    React.SetStateAction<CheckoutRecordingSessionExtensionParams>
  >;
  pricesLoading: boolean;
}

export const DurationSelection = ({
  recordingSession,
  originalDuration,
  originalSessionEndTime,
  durationSelected,
  setDurationSelected,
  setExtensionDuration,
  params,
  setParams,
  pricesLoading,
}: DurationSelectionProps) => {
  const dispatch = useAppDispatch();
  const [availabilitiesLoading, setAvailabilitiesLoading] =
    useState<boolean>(false);
  const studioRoom = useGetStudioRoomFromRecordingSession(recordingSession);
  const engineer = recordingSession.engineer;
  const engineerId = engineer?.id;
  const serviceFeeRate = 0.05;
  const [engineerService, setEngineerService] = useState<RecordingService>();
  const [maxEngineerDuration, setMaxEngineerDuration] = useState<number>(0);
  const [overallMaxDuration, setOverallMaxDuration] = useState<number>(0);
  const studioRoomService = recordingSession.studio_room?.recording_service;
  const maxStudioRoomDuration =
    studioRoomService?.maximum_session_time_minutes ?? 0;

  // Figure out the users associated with the recording session
  const recordingSessionCase: RecordingSessionCase = useMemo(() => {
    if (recordingSession.studio_room && recordingSession.engineer) {
      return RecordingSessionCase.BOTH_EXIST;
    } else if (recordingSession.engineer) {
      return RecordingSessionCase.ONLY_ENGINEER;
    } else if (recordingSession.studio_room) {
      return RecordingSessionCase.ONLY_STUDIO_ROOM;
    }
    return RecordingSessionCase.BOTH_EXIST;
  }, [recordingSession]);

  // Fetch the availabilities of the users when the component mounts
  useEffect(() => {
    setAvailabilitiesLoading(true);
    if (studioRoom) {
      void dispatch(
        getRecordingServiceAvailability({
          timezoneShiftMinutes: new Date().getTimezoneOffset(),
          studioRoomId: recordingSession.studio_room?.id,
          startDate: getCurrentDate(),
        }),
      ).unwrap();
    }
    if (engineer) {
      void dispatch(
        getRecordingServiceAvailability({
          timezoneShiftMinutes: new Date().getTimezoneOffset(),
          userId: engineer.user_id,
          startDate: getCurrentDate(),
        }),
      )
        .unwrap()
        .then(async () => {
          if (!engineerId) return;
          const response = await dispatch(
            loadEngineerServices({
              engineer_id: engineerId,
            }),
          ).unwrap();
          const engineerService = response.recording_services.find(
            (service) => service.engineer && service.engineer.id === engineerId,
          );
          setEngineerService(engineerService);
          if (engineerService) {
            setMaxEngineerDuration(
              engineerService.maximum_session_time_minutes,
            );
          }
          setAvailabilitiesLoading(false);
        });
    } else {
      setAvailabilitiesLoading(false);
    }
  }, []);

  // Calculate the available hours once we have the availabilities
  useEffect(() => {
    switch (recordingSessionCase) {
      case RecordingSessionCase.BOTH_EXIST:
        setOverallMaxDuration(
          Math.min(maxStudioRoomDuration, maxEngineerDuration),
        );
        break;
      case RecordingSessionCase.ONLY_ENGINEER:
        setOverallMaxDuration(maxEngineerDuration);
        break;
      case RecordingSessionCase.ONLY_STUDIO_ROOM:
        setOverallMaxDuration(maxStudioRoomDuration);
        break;
      default:
        break;
    }
  }, [recordingSessionCase, maxStudioRoomDuration, maxEngineerDuration]);

  const studioDateMap = useAvailabilitiesForDatesMap(
    studioRoom?.id,
    RecordingSessionAvailability.STUDIO,
  );
  const engineerDateMap = useAvailabilitiesForDatesMap(
    engineer?.user_id,
    RecordingSessionAvailability.ENGINEER,
  );

  const {
    getOverlappingAvailability,
    getEngineerAvailability,
    getStudioAvailability,
  } = useGetAvailabilityOnThisDate(studioDateMap, engineerDateMap, 60, 4);

  let availabilities: Date[] = [];
  switch (recordingSessionCase) {
    case RecordingSessionCase.BOTH_EXIST:
      availabilities = getOverlappingAvailability(originalSessionEndTime) || [];
      break;
    case RecordingSessionCase.ONLY_ENGINEER:
      availabilities = getEngineerAvailability(originalSessionEndTime) || [];
      break;
    case RecordingSessionCase.ONLY_STUDIO_ROOM:
      availabilities = getStudioAvailability(originalSessionEndTime) || [];
      break;
    default:
      break;
  }

  const continuousBlock = useGetContinuousBlockFromTime(
    availabilities,
    originalSessionEndTime,
  );

  const durationOptions = convertDatesToOptionType(continuousBlock, 1);

  const updatedDurationOptions = durationOptions.map((option, index) => {
    const hours = index + 1;
    return {
      ...option,
      label: `${hours} hour${hours > 1 ? "s" : ""}`,
      value: hours * 60,
    };
  });

  const filteredDurationOptions = updatedDurationOptions.filter(
    (option) => option.value <= overallMaxDuration - originalDuration,
  );

  // Calculate the pricing once we have the duration selected
  const { engineerCost, studioCost, totalCost } = useGetTotalPriceForExtension(
    durationSelected,
    availabilitiesLoading,
    studioRoomService,
    recordingSessionCase,
    params,
    engineerService,
    engineer,
    setParams,
  );

  // Handle the change on dropdown
  const handleDurationSelected = (duration: OptionType) => {
    setDurationSelected(duration);
    setExtensionDuration(duration.value);
  };

  if (!availabilitiesLoading && filteredDurationOptions.length === 0) {
    return (
      <div className="extend-session-modal-body">
        <p className="card-subtitle b2">
          There are no available hours to extend your recording session.
        </p>
      </div>
    );
  }

  return availabilitiesLoading ? (
    <SoundWaveLoader width={100} height={100} />
  ) : (
    <div className="extend-session-modal-body">
      <p className="card-subtitle b2">
        Select how many hours you want to extend your recording session. Once
        selected, all parties will be notified.
      </p>
      <div className="extend-session-modal-selector-container">
        <DropdownSelector
          options={filteredDurationOptions}
          onChange={(option) => {
            handleDurationSelected(option);
          }}
          value={durationSelected ? durationSelected : null}
        />
        <br />
        {Boolean(durationSelected && !pricesLoading) && (
          <InvoiceTotal
            serviceFee={(engineerCost + studioCost) * serviceFeeRate}
            subtotal={totalCost}
            engineerTotal={engineerCost}
            studioRoomTotal={studioCost}
          />
        )}
      </div>
    </div>
  );
};
