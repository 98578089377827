import { useAtomValue, useSetAtom } from "jotai";
import { FC, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CHANNEL_ID_PREFIX } from "../../../constants/chat";
import { connectionEstablishedAtom } from "../../../hooks/chatHooks/atoms";
import useChannelFactory from "../../../hooks/chatHooks/useChannelFactory";
import useDirectMessageRequestMutation from "../../../hooks/chatHooks/useDirectMessageRequestMutation";
import useQueryChannel from "../../../hooks/chatHooks/useQueryChannel";
import useStudioDirectMessageRequestMutation from "../../../hooks/chatHooks/useStudioDirectMessageRequestMutation";
import { useGetTeams } from "../../../hooks/useTeam";
import { useAppSelector } from "../../../store/hooks";
import { getMessagesRoute } from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { openChatModalAtom } from "../ChatModal/atoms";
import {
  DirectMessageButton,
  DirectMessageButtonProps,
  DirectMessageButtonState,
} from "./DirectMessageButton";
import "./DirectMessageButton.css";

type DirectMessageButtonControllerProps = {
  profileUserId?: number;
  studioId?: number;
} & Pick<
  DirectMessageButtonProps,
  "showIcon" | "customClassName" | "customLabel" | "triggerButtonVariant"
>;

export const DirectMessageButtonController: FC<
  DirectMessageButtonControllerProps
> = ({
  profileUserId,
  studioId,
  customLabel,
  showIcon,
  customClassName,
  triggerButtonVariant,
}) => {
  const prefix = useMemo(() => {
    if (studioId) {
      return CHANNEL_ID_PREFIX.STUDIO_DIRECT_MESSAGE;
    }
    return CHANNEL_ID_PREFIX.DIRECT_MESSAGE;
  }, [studioId]);
  const setModalChannelId = useSetAtom(openChatModalAtom);
  const history = useHistory();
  const user = useAppSelector((state) => state.accountInfo.user);
  const {
    mutateAsync: sendDmRequest,
    isPending: isLoadingDm,
    isSuccess: isSuccessDm,
  } = useDirectMessageRequestMutation();
  const {
    mutateAsync: sendStudioDmRequest,
    isPending: isLoadingStudioDm,
    isSuccess: isSuccessStudioDm,
  } = useStudioDirectMessageRequestMutation();

  const { data: channel, isLoading: isLoadingChannel } = useQueryChannel({
    creatorId: user?.id,
    prefix,
    relatedId:
      prefix === CHANNEL_ID_PREFIX.STUDIO_DIRECT_MESSAGE
        ? studioId
        : profileUserId,
    extraMembers: profileUserId ? [profileUserId] : [],
  });
  const getChannel = useChannelFactory({
    creatorId: user?.id,
    prefix,
    relatedId:
      prefix === CHANNEL_ID_PREFIX.STUDIO_DIRECT_MESSAGE
        ? studioId
        : profileUserId,
    extraMembers: profileUserId ? [profileUserId] : [],
  });
  const { associatedTeams, loading, updating } = useGetTeams();

  const isRecipientTeamMember = useMemo(() => {
    return associatedTeams.some((v) => {
      return (
        v.members.some((m) => m.id === profileUserId) ||
        v.managers.some((m) => m.id === profileUserId)
      );
    });
  }, [profileUserId, associatedTeams]);

  const localUTMParams = useAppSelector(
    (state) => state.accountInfo.localUTMParams,
  );
  const connectionEstablished = useAtomValue(connectionEstablishedAtom);

  const isOnOwnProfile = useMemo(() => {
    if (!user) return false;
    if (studioId) return false;
    return user?.id === profileUserId;
  }, [user, profileUserId, studioId]);

  const buttonState = useMemo(() => {
    if (!user) return DirectMessageButtonState.SEND_MESSAGE_REQUEST;
    if (isOnOwnProfile || !connectionEstablished) return null;
    if (isSuccessDm || isSuccessStudioDm)
      return DirectMessageButtonState.MESSAGE_REQUEST_PENDING;
    if (
      loading ||
      updating ||
      isLoadingDm ||
      isLoadingChannel ||
      isLoadingStudioDm
    )
      return DirectMessageButtonState.LOADING;
    if (channel?.initialized) {
      const members = Object.keys(channel.state.members).map(
        (key) => channel.state.members[key],
      );
      if (members.length === 2 && !members.some((member) => member.invited)) {
        return DirectMessageButtonState.MESSAGE;
      }
      const channelCreator = members.find((member) => member.role === "owner");
      if (channelCreator?.user_id === user.id.toString()) {
        const otherMember = members.find((member) => member.invited);
        if (otherMember?.invite_accepted_at !== undefined) {
          return DirectMessageButtonState.MESSAGE;
        } else {
          return DirectMessageButtonState.MESSAGE_REQUEST_PENDING;
        }
      } else {
        const membership = channel?.state?.membership;
        if ("invite_accepted_at" in membership) {
          return DirectMessageButtonState.MESSAGE;
        } else if ("invite_rejected_at" in membership) {
          return DirectMessageButtonState.MESSAGE_REQUEST_REJECTED;
        } else {
          // @ts-ignore
          if ("invited" in membership && membership.invited) {
            return DirectMessageButtonState.ACCEPT_MESSAGE_REQUEST;
          }
        }
      }
    } else {
      if (isRecipientTeamMember) {
        return DirectMessageButtonState.MESSAGE;
      } else {
        return DirectMessageButtonState.SEND_MESSAGE_REQUEST;
      }
    }
  }, [
    channel,
    isLoadingChannel,
    user,
    isOnOwnProfile,
    connectionEstablished,
    loading,
    updating,
    isRecipientTeamMember,
    isLoadingDm,
    isSuccessDm,
    isLoadingStudioDm,
    isSuccessStudioDm,
  ]);

  const handleClick = useCallback(
    async (message: string | undefined) => {
      switch (buttonState) {
        case DirectMessageButtonState.MESSAGE:
          if (channel) {
            setModalChannelId(channel.id!);
          } else {
            const newChannel = getChannel();
            if (newChannel) {
              await newChannel.create();
              setModalChannelId(newChannel.id!);
            }
          }
          return;
        case DirectMessageButtonState.ACCEPT_MESSAGE_REQUEST:
          return history.push(getMessagesRoute({ status: true }));
        case DirectMessageButtonState.SEND_MESSAGE_REQUEST:
          if (!message || (!profileUserId && !studioId)) {
            return toast.error(
              "Error submitting message request. Please reach out to customer support",
            );
          }
          emitAnalyticsTrackingEvent(
            "message_request_sent",
            localUTMParams,
            user?.id,
          );
          if (profileUserId) {
            const payload = await sendDmRequest({
              other_user_id: profileUserId,
              message: message,
            });
            if (payload.success) {
              toast.success("Message request sent.");
            } else if (!payload.success) {
              return toast.error(
                "Something went wrong. Please contact customer support.",
              );
            }
            return;
          } else if (studioId) {
            const payload = await sendStudioDmRequest({
              studio_id: studioId,
              message: message,
            });
            if (payload.success) {
              toast.success("Message request sent.");
            } else if (!payload.success) {
              return toast.error(
                "Something went wrong. Please contact customer support.",
              );
            }
            return;
          }
          return;
        default:
      }
    },
    [
      channel,
      getChannel,
      setModalChannelId,
      profileUserId,
      studioId,
      buttonState,
      history,
      user?.id,
      localUTMParams,
      sendStudioDmRequest,
      sendDmRequest,
    ],
  );

  if (
    !buttonState ||
    buttonState === DirectMessageButtonState.MESSAGE_REQUEST_REJECTED
  )
    return null;

  return (
    <DirectMessageButton
      handleOnClick={handleClick}
      buttonState={buttonState}
      customLabel={customLabel}
      showIcon={showIcon}
      channelId={channel?.cid}
      customClassName={customClassName}
      triggerButtonVariant={triggerButtonVariant}
    />
  );
};
