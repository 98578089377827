import { Text } from "../../../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../../../core-ui/components/Text/TextUtils";
import { Box, useTheme } from "@mui/material";
import { MembershipFaqList } from "../../../../../store/models/subscription";
import { BaseAccordion } from "../../../../core-ui/components/BaseAccordion/BaseAccordion";
import { useSetAtom } from "jotai";
import { selectSubscriptionStickyButtons } from "../../atoms";
import { useEffect, useRef } from "react";

export const MembershipFaq = () => {
  const theme = useTheme();
  const setIsShowingStickyButtons = useSetAtom(selectSubscriptionStickyButtons);
  const faqListRef = useRef<HTMLButtonElement>(null);

  // Used to hide the sticky buttons once the faq list goes in view
  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsShowingStickyButtons(!entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    const currentRef = faqListRef.current;
    if (currentRef) observer.observe(currentRef);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [faqListRef]);

  return (
    <Box
      ref={faqListRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        alignItems: "center",
      }}
    >
      <Text variant={TextStyleVariant.S2}>Frequently asked questions</Text>
      <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "915px" }}>
        {MembershipFaqList.map((item) => (
          <BaseAccordion
            key={item.question}
            renderHeader={() => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: `${theme.spacing(1.5)} 0`,
                  }}
                >
                  <Text bold>{item.question}</Text>
                </Box>
              );
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: `${theme.spacing(1)} 0 ${theme.spacing(2.5)} 0`,
              }}
            >
              <Text color={TextColor.SECONDARY}>{item.answer}</Text>
            </Box>
          </BaseAccordion>
        ))}
      </Box>
    </Box>
  );
};
