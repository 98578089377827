import AccountTypeBase from "./base";

export default interface Producer extends AccountTypeBase {}

export const MockProducer: Producer = {
  id: 35,
  user_id: 60,
  created: "2021-11-16T22:28:38.351243",
  deleted: null,
  is_primary_type: "2021-11-08T22:00:44.331927",
};
