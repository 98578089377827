import User from "../../../../store/models/user";
import { NavProfileContent } from "../NavProfileContent/NavProfileContent";
import "./NavProfileDropDown.css";

export interface NavProfileDropDownProps {
  user: User | undefined;
  showDropDown: boolean;
  onClose: () => void;
}

export const NavProfileDropDown = ({
  user,
  showDropDown,
  onClose,
}: NavProfileDropDownProps) => {
  return (
    <div
      className={`profile-drop-down ${showDropDown ? "drop-down-enabled" : ""}`}
    >
      <div
        className={`drop-down-list ${showDropDown ? "drop-down-enabled" : ""}`}
      >
        <NavProfileContent user={user} onClose={onClose} />
      </div>
    </div>
  );
};
