import { useAppSelector } from "../store/hooks";
import { useMemo } from "react";

export const useMusoSearchStateOffsets = () => {
  const { data, currentOffset, total } = useAppSelector(
    (state) => state.searchMusoCredits,
  );
  const previousOffset = useMemo(() => {
    const keys = Object.keys(data).map((key) => parseInt(key));
    if (keys.length === 0) {
      return 0;
    }
    const previousOffset = keys
      .sort((a, b) => b - a)
      .find((key) => key < currentOffset);
    return previousOffset ?? 0;
  }, [data, currentOffset]);

  return useMemo(() => {
    const keys = Object.keys(data);
    const totalFetched = keys.reduce((acc, key) => {
      const arr = data[parseInt(key)] ?? [];
      if (arr.length === 0) {
        return acc;
      }
      const size = arr.length;
      return acc + size;
    }, 0);
    const nextOffset = totalFetched;

    const hasMore = totalFetched < total;
    return {
      currentOffset,
      nextOffset,
      previousOffset,
      hasMore,
    };
  }, [data, currentOffset, total]);
};
