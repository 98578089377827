import { useQuery } from "@tanstack/react-query";
import fetchAutocomplete from "../../requests/autocomplete/fetchAutocomplete";
import fetchMapAutocomplete from "../../requests/autocomplete/fetchMapAutocomplete";

interface Params {
  term: string;
  enabled?: boolean;
  mapView?: boolean;
}

const useAutocompleteSuggestions = ({
  term,
  enabled = false,
  mapView = false,
}: Params) => {
  return useQuery({
    queryKey: ["autocomplete", term, `${mapView}:mapview`],
    queryFn: async () => {
      const func = mapView ? fetchMapAutocomplete : fetchAutocomplete;
      return func(term);
    },
    enabled: Boolean(term) && enabled,
  });
};
export default useAutocompleteSuggestions;
