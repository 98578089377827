import { detect } from "detect-browser";
import { useMemo, useCallback } from "react";

//https://developer.dolby.com/platforms/html5/browser-support/
export const useIsDolbySupported = () => {
  const browser = detect();
  const getVersion = useCallback(
    (version?: string | null): number | undefined => {
      if (!version) return undefined;
      const lastIndex = version.lastIndexOf(".");
      if (lastIndex < 0) return undefined;
      return +version.substring(0, lastIndex);
    },
    [],
  );
  return useMemo(() => {
    // TODO: Once we switch to something other than the binaural render of the
    // Atmos wav, then we this browser check will be necessary.
    return true;
    // const version = getVersion(browser?.version);
    // if (!version) return false;
    // switch (browser && browser.os) {
    //   case "Windows 10":
    //     return browser?.name === "edge";
    //   case "iOS":
    //     return browser?.name === "ios" && version >= 9;
    //   case "Mac OS":
    //     return browser?.name === "safari" && version >= 10.12;
    //   default:
    //     return false;
    // }
  }, [browser, getVersion]);
};
