import {
  useSubscriptionMonthlyPrice,
  useSubscriptionName,
} from "../../../hooks/useSubscriptionTrophy";
import {
  getSubsPlanFeatureList,
  SUBSCRIPTION_PLAN,
} from "../../../store/models/subscription";
import {
  SubscriptionComparisonTableColumn,
  SubscriptionFeatureItem,
  SubscriptionFeatureItemDivider,
  SubscriptionFeatureList,
} from "../../components/SubscriptionComparisonTable/SubscriptionComparisonTable.styles";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { Button } from "../../core-ui/components/Button/Button";
import { useAppSelector } from "../../../store/hooks";
import { useSetAtom } from "jotai";
import {
  authModalOpenAtom,
  currentFlowAtom,
} from "../../components/Auth/atoms";
import { SCREENS } from "../../../routes";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useHistory } from "react-router-dom";
import { AuthFlows } from "../../../constants/authSteps";

export interface SubsPlanFeatureListProps {
  plan: SUBSCRIPTION_PLAN;
  refundFees?: boolean;
  onSubscriptionSelected: (selected: SUBSCRIPTION_PLAN) => void;
}
export const SubsPlanFeatureList = ({
  plan,
  refundFees,
  onSubscriptionSelected,
}: SubsPlanFeatureListProps) => {
  const history = useHistory();
  const theme = useTheme();
  const featureIncluded = getSubsPlanFeatureList(plan);
  const price = useSubscriptionMonthlyPrice(plan);
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const user = useAppSelector((state) => state.accountInfo.user);
  const isPlatinum =
    plan === SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY ||
    plan === SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY ||
    plan === SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY;
  const { isMobile } = useMediaQueryBreakpoint();
  const setFlow = useSetAtom(currentFlowAtom);

  const buttonText = () => {
    if (isPlatinum) {
      if (user?.engineer?.stripe_subscription_id || refundFees) {
        return "Select plan";
      }
      return "Start trial";
    }
    return "Get started";
  };

  return (
    <SubscriptionComparisonTableColumn
      $isRoundedCorner={plan === SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY}
    >
      <SubscriptionFeatureList>
        <SubscriptionFeatureItem
          sx={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Text variant={TextStyleVariant.S2}>{useSubscriptionName(plan)}</Text>
        </SubscriptionFeatureItem>
        <SubscriptionFeatureItemDivider />
        {featureIncluded.map((feature, idx) => {
          return (
            <>
              <SubscriptionFeatureItem
                key={idx}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  gap: "8px",
                }}
              >
                {feature.icon ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color={
                      feature.disabledColor
                        ? theme.palette.customColor.buttonColorHover
                        : theme.palette.standardColor.BoomyOrange[500]
                    }
                  />
                ) : null}
                {feature.text ? (
                  <Text
                    variant={TextStyleVariant.P1}
                    style={{
                      color: feature.disabledColor
                        ? theme.palette.customColor.unselectedTextPrimaryColor
                        : theme.palette.standardColor.BoomyOrange[500],
                    }}
                  >
                    {feature.text}
                  </Text>
                ) : null}
                {feature.subtext ? (
                  <Text
                    variant={TextStyleVariant.P2}
                    style={{
                      color:
                        theme.palette.customColor.unselectedTextPrimaryColor,
                    }}
                  >
                    {feature.subtext}
                  </Text>
                ) : null}
              </SubscriptionFeatureItem>
              <SubscriptionFeatureItemDivider />
            </>
          );
        })}
        <SubscriptionFeatureItem
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            gap: "16px",
          }}
        >
          <Text variant={TextStyleVariant.H5} style={{ fontWeight: "400" }}>
            ${price}
            <Text variant={TextStyleVariant.P1} style={{ display: "inline" }}>
              /mo
            </Text>
          </Text>
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => {
              if (!isAuthenticated) {
                if (isMobile) history.push(SCREENS.AUTH_SCREEN);
                setFlow(AuthFlows.START_SIGNUP);
                setAuthModalOpen(true);
                return;
              }
              onSubscriptionSelected(plan);
            }}
            fullWidth
          >
            {buttonText()}
          </Button>
        </SubscriptionFeatureItem>
      </SubscriptionFeatureList>
    </SubscriptionComparisonTableColumn>
  );
};
