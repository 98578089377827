import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";

import { useState } from "react";
import useUpdateReleaseTrack from "../../../api/releases/hooks/useUpdateReleaseTrack";

interface EditReleaseTrackInputProps {
  title: string;
  releaseTrackId?: number;
}

const EditReleaseTrackInput = ({
  title,
  releaseTrackId = 0,
}: EditReleaseTrackInputProps) => {
  const { mutate: updateReleaseTrack } = useUpdateReleaseTrack(releaseTrackId);
  const [controlledTitle, setControlledTitle] = useState<string>(title);

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      <FormControl
        variant="standard"
        onKeyDown={(e) => {
          if (e.key === "Enter" && controlledTitle !== title) {
            updateReleaseTrack(controlledTitle);
          }
        }}
      >
        <Input
          sx={{
            width: "100%",
          }}
          onBlur={() => {
            if (controlledTitle !== title) updateReleaseTrack(controlledTitle);
          }}
          onChange={(e) => setControlledTitle(e.target.value)}
          value={controlledTitle}
        />
      </FormControl>
    </Box>
  );
};

export default EditReleaseTrackInput;
