import React from "react";
import { useAppSelector } from "../../../store/hooks";
import "./Textfield1.css";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

export interface TextFieldProps {
  id?: string;
  label?: string;
  custom?: boolean;
  className?: string;
  value?: string | number;
  defaultValue?: string | number;
  type?: string;
  required?: boolean;
  autoComplete?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

// TODO: Rename Textfield1 folder back to Textfield after this gets merged to master.

// TODO: Add styling to when textfield disabled
export const Textfield = ({
  label = "",
  custom,
  value,
  defaultValue,
  type = "text",
  autoComplete,
  disabled = false,
  ...props
}: TextFieldProps) => {
  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);
  const darkMode = useAtomValue(darkModeAtom);

  if (custom) {
    return (
      <div className="custom-textfield-container">
        <label className="custom-textfield-label">{label}</label>
        <input
          id="textfield"
          name="text"
          type={type}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          className="custom-textfield"
          placeholder={label[0] + label.slice(1).toLowerCase()}
          autoComplete={autoComplete}
          style={{
            marginTop: "10px",
            height: "40px",
            background: darkMode && isAuthenticated ? "black" : "white",
            border: "none",
            color: darkMode && isAuthenticated ? "white" : "black",
          }}
          {...props}
        />
      </div>
    );
  }

  return (
    <input
      onChange={props.onChange}
      className={" textfield ".concat(props.className ?? "")}
      name="text"
      autoComplete={autoComplete}
      type={type}
      disabled={disabled}
      value={value}
      defaultValue={defaultValue}
      style={{
        background: darkMode && isAuthenticated ? "black" : "white",
        color: darkMode && isAuthenticated ? "white" : "black",
      }}
      placeholder={label.concat(props.required ? "*" : "")}
    />
  );
};
