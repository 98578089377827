import YoutubeLivestreamScreen from "./YoutubeLivestreamScreen";
import LivestreamScreen from "./LivestreamScreen";

const VIDEO_ID = "";

export const LivestreamSelectorScreen = () => {
  // TODO: Link to launchdarkly
  const videoIdFlagData: string = VIDEO_ID;

  if (videoIdFlagData) {
    return <YoutubeLivestreamScreen videoId={videoIdFlagData} />;
  }

  return <LivestreamScreen />;
};
