import { HTMLAttributes } from "react";
import styled from "styled-components";

export const InvisibleButton = styled.button<InvisibleButtonProps>`
  border: none;
  background: none;
  padding: 0;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
  * {
    ${({ $disabled = false, disabled }) =>
      $disabled || disabled ? "pointer-events: none;" : ""}
  }
`;

export interface InvisibleButtonProps
  extends Omit<HTMLAttributes<HTMLButtonElement>, "onClick"> {
  $disabled?: boolean;
  disabled?: boolean;
}
