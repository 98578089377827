import { useRef } from "react";
import { useGetFooterPlayerRef } from "./useGetFooterPlayerRef";
import { DEFAULT_TIME } from "./waveformHooks";
import { convertWaveformDurationToReadableTime } from "./waveformUtils";

export const useGetCurrentSeekTime = (isSameProject: boolean) => {
  const currentSeekTime = useRef<string>(DEFAULT_TIME);
  const footerPlayerRef = useGetFooterPlayerRef();
  if (footerPlayerRef.current === null) return;
  if (!isSameProject) {
    currentSeekTime.current = DEFAULT_TIME;
  } else {
    currentSeekTime.current = convertWaveformDurationToReadableTime(
      footerPlayerRef.current.getCurrentTime(),
    );
  }
  return currentSeekTime;
};
