import { REGISTER } from "../../store/utils/routes";
import { AuthResponse } from "../../store/actions/accountInfo";
import {
  AUTH_FAILED,
  INVALID_USERNAME_ON_SIGNUP,
} from "../../store/models/exceptions";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { EngineEarsError } from "../../store/actions/errorStore";

export interface SignUpParams {
  username: string;
  password: string;
  email: string;
  isEngineer?: boolean;
}

export const postSignUp = async (
  params: SignUpParams,
): Promise<AuthResponse | EngineEarsError> => {
  const body = {
    username: params.username.toLowerCase().trim(),
    password: params.password,
    email: params.email.toLowerCase().trim(),
  };
  return makeBackendPostCallWithJsonResponse<AuthResponse>(REGISTER, body).then(
    (res) => {
      if (res.success) {
        return res.resultJson;
      }
      if (res.resultJson.code === AUTH_FAILED) {
        res.resultJson.code = INVALID_USERNAME_ON_SIGNUP;
      }
      return res.resultJson;
    },
  );
};
