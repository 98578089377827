import React, { useState } from "react";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import {
  AddOnButton,
  SocialMediaButton,
} from "../../elements/SocialMediaButton/SocialMediaButton";
import { Textfield } from "../../elements/Textfield1/Textfield1";
import {
  SocialMediaListEnum,
  SocialMediaMap,
  SocialMediaSelectorOptions,
} from "../../types/types";
import "./SocialMediaButtonsList";
import "./SocialMediaButtonsList.css";
import { ColorPalette } from "../../theme";
import { useAppSelector } from "../../../store/hooks";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

export interface SocialMediaButtonsListProps {
  editMode: boolean;
  onChange?: (changes: Map<SocialMediaListEnum, string>) => void;
  usernamesMap: Map<SocialMediaListEnum, string>;
  isCurrentUserPage: boolean;
}

export const SocialMediaButtonsList = ({
  editMode,
  onChange,
  usernamesMap,
  isCurrentUserPage,
}: SocialMediaButtonsListProps) => {
  const [showSelect, setShowSelect] = useState(false);
  const [username, setUsername] = useState(
    usernamesMap.get(SocialMediaListEnum.soundcloud),
  );
  const [selectedOption, setSelectedOption] = useState<OptionType>();
  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);
  const darkMode = useAtomValue(darkModeAtom);

  const editModeClick = (enumerator: SocialMediaListEnum) => {
    if ((selectedOption?.value as SocialMediaListEnum) === enumerator) {
      setShowSelect(!showSelect);
    } else {
      if (!showSelect) setShowSelect(true);
      setSelectedOption(SocialMediaSelectorOptions[enumerator]);
      setUsername(usernamesMap.get(enumerator));
    }
  };

  const removeIconClick = (enumerator: SocialMediaListEnum) => {
    if ((selectedOption?.value as SocialMediaListEnum) !== enumerator)
      setSelectedOption(SocialMediaSelectorOptions[enumerator]);
    usernamesMap.delete(enumerator);
    setUsername("");

    onChange?.(usernamesMap);
  };

  const getSocialMediaButtonProps = (
    k: SocialMediaListEnum,
    username: string,
  ) => {
    return {
      enumerator: k,
      username: username,
      editMode: editMode,
      onEditModeClick: editModeClick,
      onDelete: removeIconClick,
    };
  };

  const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    usernamesMap.set(
      selectedOption?.value as SocialMediaListEnum,
      e.target.value,
    );
  };

  const onDropdownFieldChange = (option: OptionType) => {
    const enumerator = option.value as SocialMediaListEnum;
    setSelectedOption(SocialMediaSelectorOptions[enumerator]);
    if (usernamesMap.has(enumerator)) {
      setUsername(usernamesMap.get(enumerator));
    } else {
      setUsername("");
    }
  };

  const onClickSaveButton = () => {
    setShowSelect(false);
    onChange?.(usernamesMap);
  };

  const socialMediaButtonsList = (
    <div className="social-media-buttons-list">
      {Array.from(SocialMediaMap.keys()).map((socialMediaEnum, i) =>
        usernamesMap.get(socialMediaEnum) ? (
          <SocialMediaButton
            useWhiteIcon={Boolean(darkMode) && isAuthenticated}
            key={i}
            {...getSocialMediaButtonProps(
              socialMediaEnum as SocialMediaListEnum,
              usernamesMap.get(socialMediaEnum)!,
            )}
          />
        ) : null,
      )}
      {usernamesMap.size === 0 && !editMode && isCurrentUserPage && (
        <p className="label" style={{ color: ColorPalette.LightGray }}>
          add social media links in edit mode
        </p>
      )}
      {((editMode && usernamesMap.size < SocialMediaMap.size) ||
        (usernamesMap.size === 0 && editMode)) && (
        <div className="add-on-button-container">
          <AddOnButton
            onClickHandler={() => {
              setShowSelect(!showSelect);
            }}
          />
          {usernamesMap.size === 0 && (
            <p className="label-semi-bold">ADD SOCIAL MEDIA</p>
          )}
        </div>
      )}
    </div>
  );

  const editModeForm = (
    <form className="add-on-menu">
      <label>Social Media</label>
      <DropdownSelector
        value={selectedOption}
        options={SocialMediaSelectorOptions}
        onChange={onDropdownFieldChange}
      />
      <div className="add-handle-field">
        <Textfield
          label="Social Media Handle"
          value={username}
          onChange={onTextFieldChange}
        />
        <button onClick={onClickSaveButton}>Save</button>
      </div>
    </form>
  );

  if (editMode)
    return (
      <div className="edit-social-media-button-list">
        {socialMediaButtonsList}
        {showSelect && editModeForm}
      </div>
    );

  return socialMediaButtonsList;
};
