import { styled } from "styled-components";

export const SimpleTableRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const SimpleTableRowCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
`;
export const SimpleTableRowText = styled.p`
  font-size: ${({ theme }) => theme.textSizeMd};
  color: ${({ theme }) => theme.textSecondaryColor};
  text-overflow: ellipsis;
  overflow-x: hidden;
`;
