import ViewSlider, { ViewProps } from "react-view-slider";
import { FeaturedArtistRelease } from "../FeaturedArtistRelease/FeaturedArtistRelease";
import { Box } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useDebounceCallback, useResizeObserver } from "usehooks-ts";
import { useParams } from "react-router-dom";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { ReleaseDetailView } from "./ReleaseDetailView";
import { ReleaseOverview } from "./ReleaseOverview";
import { AddReleaseView } from "./AddReleaseView";
import { useFetchFeaturedArtistReleaseQuery } from "../../../api/releases/hooks/useFetchFeaturedReleaseQuery";

export const RELATIVE_DESKTOP_BREAKPOINT = 616.75;

export interface ReleaseTabViewSectionProps {
  size?: number;
  shouldShowDesktop: boolean;
}

enum ReleasesTabView {
  overview,
  detail,
}

export const ArtistReleaseTabView = () => {
  const [currentView, setView] = useState<ReleasesTabView>(
    ReleasesTabView.overview,
  );
  const [selectedReleaseId, setSelectedReleaseId] = useState<number | null>();
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const { data: release, isPending: isReleaseLoading } =
    useFetchFeaturedArtistReleaseQuery(userData?.id);
  const ref = useRef<HTMLDivElement>(null);
  const [{ width }, setSize] = useState<{
    width: number | undefined;
  }>({
    width: undefined,
  });
  const shouldShowDesktop = Boolean(
    width && width > RELATIVE_DESKTOP_BREAKPOINT,
  );
  const onResize = useDebounceCallback(setSize, 50);
  const viewportWidth = window.innerWidth;
  const dynamicWidth = (width || viewportWidth) - 32;
  const desktopWidth = 300;

  const onReleaseClick = useCallback((releaseId?: number) => {
    setSelectedReleaseId(releaseId);
    setView(ReleasesTabView.detail);
  }, []);

  useResizeObserver({
    ref: ref,
    onResize,
  });

  const renderView = ({ index }: ViewProps) => {
    switch (index) {
      case ReleasesTabView.overview:
        return (
          <ReleaseOverview
            shouldShowDesktop={shouldShowDesktop}
            onReleaseClick={onReleaseClick}
          >
            <FeaturedArtistRelease
              release={release}
              onClick={() => {
                onReleaseClick(release?.id);
              }}
              isReleaseLoading={isReleaseLoading}
              shouldShowDesktop={shouldShowDesktop}
              size={shouldShowDesktop ? desktopWidth : dynamicWidth}
            />
          </ReleaseOverview>
        );

      case ReleasesTabView.detail:
        if (!selectedReleaseId) {
          return <AddReleaseView />;
        }
        return (
          <ReleaseDetailView
            navigateBack={() => {
              setView(ReleasesTabView.overview);
            }}
            size={shouldShowDesktop ? desktopWidth : dynamicWidth}
            shouldShowDesktop={shouldShowDesktop}
            releaseId={selectedReleaseId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box minHeight={1050} ref={ref}>
      <ViewSlider
        onSlideTransitionEnd={() => {
          if (currentView === ReleasesTabView.overview) {
            setSelectedReleaseId(null);
          }
        }}
        numViews={2}
        keepViewsMounted
        renderView={renderView}
        animateHeight
        activeView={currentView}
      />
    </Box>
  );
};
