import { useEffect, useState } from "react";
import { MusoCredit } from "../../../store/models/muso";
import "./CreditTableRow.css";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
export interface CreditTableRowProps {
  creditData: MusoCredit;
  disabledCheckMarks: boolean;
  visibleCheckMarks: boolean;
  checked?: boolean;
  onChecked?: (data: MusoCredit) => void;
  selectable?: boolean;
  checkedList?: string[];
}

export const CreditTableRow = ({
  creditData,
  disabledCheckMarks,
  visibleCheckMarks,
  checked,
  onChecked,
  selectable,
  checkedList,
}: CreditTableRowProps) => {
  const [isChecked, setIsChecked] = useState<boolean | undefined>(checked);
  const { isDesktop } = useMediaQueryBreakpoint();

  useEffect(() => {
    const key = creditData.muso_credit_id + creditData.track_title;
    if (checkedList && checkedList.includes(key)) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [checkedList]);

  const handleChecked = () => {
    setIsChecked(!isChecked);
    if (onChecked) onChecked(creditData);
  };

  const desktopTableContents = (
    <>
      <td className="muso-credit-text">{creditData.album_title}</td>
      <td className="muso-credit-text">{creditData.role}</td>
      <td className="muso-credit-text">{creditData.release_date}</td>
    </>
  );

  return (
    <>
      <td>
        <div className="credits-table-row-track-title-container">
          {visibleCheckMarks && (
            <div>
              <input
                checked={isChecked}
                onChange={handleChecked}
                type="checkbox"
                id="credit-checkbox"
                disabled={!selectable ? true : !isChecked && disabledCheckMarks}
              />
              <label htmlFor="credit-checkbox"></label>
            </div>
          )}
          <img
            src={creditData.album_art}
            alt="Album art"
            width="32"
            height="32"
            style={{
              borderRadius: "4px",
              backgroundColor: "unset",
            }}
          />
          <Text variant={TextStyleVariant.P1} className="muso-credit-text">
            {creditData.track_title}
          </Text>
        </div>
      </td>
      {!isDesktop && (
        <td className="muso-credit-text muso-credit-role">{creditData.role}</td>
      )}
      {isDesktop && desktopTableContents}
    </>
  );
};
