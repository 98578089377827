import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { useAppSelector } from "../hooks";
import { RootState } from "../index";

export const selectTeamState = (state: RootState) => state.teamState;
export const selectTeamFields = createDraftSafeSelector(
  selectTeamState,
  (teamState) => {
    const { associatedTeams, selectedTeamId, loading, updating } = teamState;
    const selectedTeam = associatedTeams.find(
      (team) => team.id === selectedTeamId,
    );
    return {
      associatedTeams,
      selectedTeam,
      loading,
      updating,
    };
  },
);

export const useManagerCanEditStudio = (studioId: number | undefined) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const studio = useAppSelector((state) => state.studiosSlice[studioId ?? -1]);
  if (!studio || !user) {
    return false;
  }

  return studio.studio_team.managers.some((manager) => manager?.id === user.id);
};
