import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useQueryParam } from "../../../hooks/useQueryParam";
import {
  selectFile,
  setCurrentPosition,
  setSeekTo,
} from "../../../store/actions/abPlayerStore";
import {
  createFileComment,
  fetchFileComments,
  setPage,
} from "../../../store/actions/fileVersionComments";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  FileVersion,
  FileVersionComment,
} from "../../../store/models/fileVersion";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { getTrackVersionText } from "../../../store/utils/trackComments";
import { convertSecondsToTimeFormat } from "../../../store/utils/utils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { formatDistanceToNowShort } from "../../../utils/formatDateToShort";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { UserProfileImage } from "../../elements/UserProfileImage/UserProfileImage";
import {
  FileCommentChatBubble,
  FileCommentChatContainer,
  FileCommentChatMessage,
  FileCommentChatMessageButton,
  FileCommentChatMessageCollaborator,
  FileCommentChatMessageCreatedAt,
  FileCommentChatMessageHeader,
  FileCommentChatMessageOptions,
  FileCommentChatMessageReplyButton,
  FileCommentChatMessageReplyContainer,
  FileCommentChatMessageReplyInput,
  FileCommentChatMessageTimeStamp,
  FileCommentChatMessageVersion,
  FileCommentChatRow,
} from "./TrackTableComments.styles";
import { TrackTableDeleteCommentButton } from "./TrackTableDeleteCommentButton";
import { TrackTableResolveCommentButton } from "./TrackTableResolveCommentButton";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { Alt, altToDisplayString } from "../../../store/models/alts";
import WaveSurferRef from "wavesurfer.js";
import { StyledPagination } from "../StyledPagination/StyledPagination";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { convertUTCDateToLocalDate } from "../../../store/utils/dateTimeUtils";
import { Text, TEXT_COLOR } from "../../core-ui/components/Text/Text";
import { TrackTableReplyButton } from "./TrackTableReplyButton";
import { ProjectType } from "../../../store/models/project";

export interface TrackTableCommentChatProps {
  projectId: number;
  onServiceTypeSelection: (option: OptionType) => void;
  footerPlayerRef: React.MutableRefObject<WaveSurferRef | null>;
  comments: FileVersionComment[];
  projectType?: ProjectType;
  setSelectedRef: (File: FileVersion) => void;
  setSelectedMain: (File: FileVersion) => void;
}

export const TrackTableCommentChat = ({
  projectId,
  onServiceTypeSelection,
  footerPlayerRef,
  comments,
  projectType,
  setSelectedRef,
  setSelectedMain,
}: TrackTableCommentChatProps) => {
  const DEFAULT_PAGE_SIZE = 10;
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyToComment, setReplyToComment] =
    useState<FileVersionComment | null>(null);
  const [replyCommentPosting, setReplyCommentPosting] = useState(false);
  const [commentReplyText, setCommentReplyText] = useState("");

  const dispatch = useAppDispatch();
  const { isMobile } = useMediaQueryBreakpoint();
  const query = useQueryParam("code");
  const code = query.get();
  const { page, count, loading } = useAppSelector(
    (state) => state.fileVersionCommentsSlice,
  );
  const unauthenticatedUserName = useAppSelector(
    (state) => state.unauthenticatedUserStateSlice.name,
  );
  const user = useAppSelector((state) => state.accountInfo.user);
  const { focusedTrack } = useAppSelector((state) => state.abPlayerStore);
  const focusedTrackRef = useRef(focusedTrack);

  useEffect(() => {
    focusedTrackRef.current = focusedTrack;
  }, [focusedTrack]);

  const handleReplyCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setCommentReplyText(event.target.value);
  };

  const handleClick = useCallback(
    (comment: FileVersionComment, timestamp: number) => {
      emitAnalyticsTrackingEvent(
        "clicked_file_comment",
        { comment_id: `${comment.id}` },
        user?.id,
      );
      onServiceTypeSelection({
        label: altToDisplayString[Alt.ALL],
        value: Alt.ALL,
      });
      // TODO: Hacky way to switch file and play at timestamp by pausing
      //  first. The issue without this is that it will switch file
      //  but not play at timestamp.
      if (comment.file_version.id !== focusedTrackRef.current?.id) {
        footerPlayerRef?.current?.pause();
        dispatch(setCurrentPosition(timestamp));
        if (comment.file_version.reference) {
          setSelectedRef(comment.file_version);
        } else {
          setSelectedMain(comment.file_version);
        }
        dispatch(
          selectFile({
            track: comment.file_version,
            isFocused: true,
            playOnLoad: true,
          }),
        );
      } else {
        dispatch(setSeekTo(timestamp));
        if (!footerPlayerRef?.current?.isPlaying()) {
          void footerPlayerRef?.current?.playPause();
        }
      }
    },
    [dispatch, focusedTrackRef.current],
  );

  const handleReply = useCallback(() => {
    if (!replyToComment) return;
    if (!commentReplyText) return;
    emitAnalyticsTrackingEvent(
      "clicked_file_comment_reply",
      { comment_id: `${replyToComment.id}` },
      user?.id,
    );
    setReplyCommentPosting(true);
    void dispatch(
      createFileComment({
        comment: commentReplyText,
        file_version_id: replyToComment?.file_version_id,
        author_name: unauthenticatedUserName,
        code: code ? code : undefined,
        reply_to: replyToComment.reply_to ?? replyToComment.id,
        project_id: projectId,
      }),
    )
      .unwrap()
      .then(() => {
        void dispatch(fetchFileComments({ projectId, code }));
      })
      .finally(() => {
        setReplyCommentPosting(false);
        setCommentReplyText("");
        setShowReplyInput(false);
      });
  }, [
    replyToComment,
    commentReplyText,
    user?.id,
    dispatch,
    unauthenticatedUserName,
    code,
  ]);

  const renderComment = (comment: FileVersionComment) => {
    return (
      <Fragment key={comment.id}>
        <FileCommentChatRow $isReply={Boolean(comment.reply_to)}>
          <FileCommentChatBubble
            $isAuthor={comment.author_user?.id === user?.id}
          >
            {!isMobile && (
              <UserProfileImage
                isCircle={true}
                width={58}
                height={58}
                source={comment.author_user?.photo?.path}
              />
            )}
            <FileCommentChatMessage>
              {(comment.author_name || comment.author_user) && (
                <FileCommentChatMessageHeader>
                  <FileCommentChatMessageCollaborator>
                    {getDisplayableNameForUser(comment?.author_user) ||
                      comment.author_name}
                  </FileCommentChatMessageCollaborator>
                  {(comment.start_timestamp_in_seconds &&
                    comment.end_timestamp_in_seconds) != null ? (
                    <>
                      <FileCommentChatMessageTimeStamp
                        onClick={() =>
                          handleClick(
                            comment,
                            comment.start_timestamp_in_seconds!,
                          )
                        }
                      >
                        {convertSecondsToTimeFormat(
                          comment.start_timestamp_in_seconds!,
                        )}
                      </FileCommentChatMessageTimeStamp>
                      -
                      <FileCommentChatMessageTimeStamp
                        onClick={() =>
                          handleClick(
                            comment,
                            comment.end_timestamp_in_seconds!,
                          )
                        }
                      >
                        {convertSecondsToTimeFormat(
                          comment.end_timestamp_in_seconds!,
                        )}
                      </FileCommentChatMessageTimeStamp>
                    </>
                  ) : (
                    <FileCommentChatMessageTimeStamp>
                      {""}
                    </FileCommentChatMessageTimeStamp>
                  )}
                  <FileCommentChatMessageCreatedAt>
                    {formatDistanceToNowShort(
                      convertUTCDateToLocalDate(new Date(comment.created)),
                    )}
                  </FileCommentChatMessageCreatedAt>
                </FileCommentChatMessageHeader>
              )}
              {comment.start_timestamp_in_seconds != null && (
                <FileCommentChatMessageVersion>
                  Version:{" "}
                  {getTrackVersionText(comment.file_version, projectType)}
                </FileCommentChatMessageVersion>
              )}
              {comment?.comment ?? ""}
              <FileCommentChatMessageOptions>
                <FileCommentChatMessageButton
                  onClick={() => {
                    setShowReplyInput(true);
                    setReplyToComment(comment);
                  }}
                  variant={ButtonVariant.GHOST}
                >
                  Reply
                </FileCommentChatMessageButton>
                <TrackTableResolveCommentButton
                  code={code ?? undefined}
                  comment={comment}
                />
                <TrackTableDeleteCommentButton
                  comment={comment}
                  code={code ?? undefined}
                  projectId={projectId}
                />
              </FileCommentChatMessageOptions>
            </FileCommentChatMessage>
          </FileCommentChatBubble>
        </FileCommentChatRow>
        {showReplyInput && replyToComment?.id === comment.id && (
          <FileCommentChatRow key={comment.id + "reply"} $isReply={false}>
            <FileCommentChatMessageReplyContainer>
              <FileCommentChatMessageReplyInput
                placeholder={"Reply to message"}
                onChange={handleReplyCommentChange}
                value={commentReplyText}
              />
              <FileCommentChatMessageReplyButton>
                <Button
                  onClick={() => {
                    setShowReplyInput(false);
                    setReplyToComment(null);
                    setCommentReplyText("");
                  }}
                  variant={ButtonVariant.OUTLINED}
                >
                  Cancel
                </Button>
                <TrackTableReplyButton
                  isLoading={replyCommentPosting}
                  isDisabled={commentReplyText.length === 0 || !replyToComment}
                  handleReply={handleReply}
                />
              </FileCommentChatMessageReplyButton>
            </FileCommentChatMessageReplyContainer>
          </FileCommentChatRow>
        )}
      </Fragment>
    );
  };

  if (loading) {
    return <SoundWaveLoader width={100} height={100} />;
  }

  if (!comments.length) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          height: "50px",
        }}
      >
        <Text color={TEXT_COLOR.TERTIARY}>No comments available</Text>
      </div>
    );
  }

  return (
    <FileCommentChatContainer>
      {comments.map((comment: FileVersionComment) => {
        return (
          <React.Fragment key={comment.id}>
            {renderComment(comment)}
            {comment.replies.map((reply: FileVersionComment) =>
              renderComment(reply),
            )}
          </React.Fragment>
        );
      })}
      <StyledPagination
        pageSize={DEFAULT_PAGE_SIZE}
        current={page}
        total={count}
        disabled={loading}
        onChange={(nextPage) => dispatch(setPage(nextPage))}
      />
    </FileCommentChatContainer>
  );
};
