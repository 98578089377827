import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { useSetAtom } from "jotai";
import { fetchActiveStudios } from "../../api/recording/studio/fetchActiveStudios";
import { activeUserStudiosAtom } from "../../atoms/user/activeUserStudiosAtom";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../api/helpers";
import { EngineEarsError } from "../../store/actions/errorStore";

const useActiveStudiosMutation = () => {
  const setActiveUserStudios = useSetAtom(activeUserStudiosAtom);
  return useMutation({
    mutationKey: [QUERY_KEYS.LOAD_STUDIOS],
    mutationFn: async () => {
      return fetchActiveStudios();
    },
    onSuccess: (activeStudios) => {
      if (assertEngineEarsErrorType(activeStudios)) {
        return Promise.reject(activeStudios);
      }
      setActiveUserStudios(activeStudios);
      return activeStudios;
    },
    onError: (e: EngineEarsError) => {
      displayEngineEarsError(e);
      setActiveUserStudios([]);
    },
  });
};

export default useActiveStudiosMutation;
