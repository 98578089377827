import styled from "styled-components";

export const FileLinkRowContainer = styled.div`
  display: flex;
  width: calc(100% - 32px);
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 36px;
  border-top: thin solid ${({ theme }) => theme.separatorColor};
`;

export const AnchorTag = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colorPalette.DeepBlue500};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
  &:hover {
    color: ${({ theme }) => theme.colorPalette.DeepBlue300};
  }
`;
