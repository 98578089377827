import { useMemo } from "react";
import { AvailabilityForDate } from "../../store/actions/availability";
import { useAppSelector } from "../../store/hooks";
import { selectAvailability } from "../../store/selectors/availabilitySelector";
import { convertAvailabilityForDateToMap } from "../../store/utils/utils";

export enum RecordingSessionAvailability {
  ENGINEER = "engineer",
  STUDIO = "studio",
}

export const useAvailabilitiesForDatesMap = (
  id: number | undefined,
  availabilityType: RecordingSessionAvailability,
) => {
  const availability = useAppSelector(selectAvailability);
  return useMemo(() => {
    if (!id) return new Map<string, string>();
    let availabilitiesForDates: AvailabilityForDate | undefined = undefined;
    if (availabilityType === RecordingSessionAvailability.STUDIO) {
      availabilitiesForDates = availability.studio_room[id];
    } else {
      availabilitiesForDates = availability.engineer_user[id];
    }

    return convertAvailabilityForDateToMap(availabilitiesForDates);
  }, [id, availability]);
};
