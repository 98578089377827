import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import User from "../../../store/models/user";
import { imagePathPrefix } from "../../../store/utils";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import defaultUser from "../../../stories/assets/defaultuser.jpg";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { VerifiedIcon } from "../../elements/VerifiedIcon/VerifiedIcon";
import { BaseModal } from "../BaseModal/BaseModal";
import "./StudioTeammatesModal.css";

export interface StudioTeammatesModalProps {
  showModal: boolean;
  closeModal: () => void;
  managerUsers: User[];
  engineerUsers: User[];
  setIsSidePanelOpen: (isOpen: boolean) => void;
}

export const StudioTeammatesModal = ({
  showModal,
  closeModal,
  managerUsers,
  engineerUsers,
  setIsSidePanelOpen,
}: StudioTeammatesModalProps) => {
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const isManager = managerUsers.some(
    (manager) => manager.id === loggedInUser?.id,
  );

  return (
    <BaseModal
      hideCloseButton={true}
      modalIsOpen={showModal}
      label={"studio teammates modal"}
    >
      <div className="studio-teammates-modal-container">
        <FontAwesomeIcon
          width={30}
          height={30}
          icon={faTimes}
          color={"var(--black)"}
          size={"xl"}
          className={"studio-teammates-close-modal"}
          onClick={closeModal}
        />
        <div className="studio-teammates-header-content">
          <h3 className="h3 mb-2">Our Teammates & Affiliates</h3>
          <h6 className="h7">Our recommended affiliates and team members.</h6>
        </div>
        <div className="studio-teammates-modal-body">
          <div className="studio-teammates-modal-section">
            <div className="b2-semi-bold studio-teammates-modal-section-title">
              Admins & Managers
            </div>
            {managerUsers.map((user) => (
              <StudioTeammatesDisplay key={user.id} user={user} />
            ))}
          </div>
          <div className="studio-teammates-modal-section">
            <div className="b2-semi-bold studio-teammates-modal-section-title">
              Affiliated Engineers
            </div>
            {engineerUsers.map((user) => (
              <StudioTeammatesDisplay key={user.id} user={user} />
            ))}
          </div>
          {isManager && (
            <Button
              variant={ButtonVariant.OUTLINED}
              style={{ marginTop: "12px", marginLeft: "auto", marginRight: 0 }}
              onClick={() => {
                closeModal();
                setIsSidePanelOpen(true);
              }}
            >
              Manage Teammates
            </Button>
          )}
        </div>
      </div>
    </BaseModal>
  );
};

const StudioTeammatesDisplay = ({ user }: { user: User }) => {
  const history = useHistory();

  const redirectToUserProfile = () => {
    history.push(`/${user.username}`);
  };

  return (
    <div
      className="studio-teammates-display-container"
      onClick={() => redirectToUserProfile()}
    >
      <img
        src={
          user.photo?.path ? imagePathPrefix + user.photo?.path : defaultUser
        }
        alt="user avatar"
        onClick={() => redirectToUserProfile()}
      />
      <div
        className="studio-teammates-display-name h7-semi-bold"
        onClick={() => redirectToUserProfile()}
      >
        {getDisplayableNameForUser(user)}
      </div>
      {user.engineer?.verified && (
        <VerifiedIcon verified={Boolean(user.engineer?.verified)} />
      )}
    </div>
  );
};
