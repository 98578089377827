import {
  Box,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  useTheme,
} from "@mui/material";
import React from "react";

export interface ProgressBarProps {
  totalSteps: number;
  completedSteps: number;
}

const BarRoot = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "7px",
  backgroundColor: theme.palette.standardColor.BoomyOrange[200],
}));

const BarIcon = (props: StepIconProps) => {
  const theme = useTheme();
  const { completed } = props;
  return (
    <BarRoot
      {...(completed && {
        sx: { backgroundColor: theme.palette.standardColor.BoomyOrange[400] },
      })}
    />
  );
};

const EmptyConnector = styled(StepConnector)({
  display: "none",
});
const EmpyConnectorReactElement = React.createElement(EmptyConnector);

const ProgressBar = ({ totalSteps, completedSteps }: ProgressBarProps) => {
  const totalBars = new Array(totalSteps).fill(true);
  return (
    <Stepper activeStep={completedSteps} connector={EmpyConnectorReactElement}>
      {totalBars.map((_, index) => (
        <Step
          sx={{ width: "100%", paddingLeft: 0, paddingRight: "2px" }}
          key={index}
        >
          <StepLabel
            sx={{
              width: "100%",
              "& .MuiStepLabel-iconContainer": {
                width: "100%",
                paddingRight: 0,
              },
            }}
            StepIconComponent={BarIcon}
          />
        </Step>
      ))}
    </Stepper>
  );
};

export default ProgressBar;
