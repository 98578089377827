import { PostPurchaseOrderArgs } from "../../../store/actions/transactions";
import {
  TextValidation,
  ValidatedTextfield,
} from "../../elements/ValidatedTextfield/ValidatedTextfield";

export interface BillingInfoFormProps {
  postPurchaseArgs: PostPurchaseOrderArgs;
  setPostPurchaseArgs: (args: PostPurchaseOrderArgs) => void;
}

export const BillingInfoForm = ({
  postPurchaseArgs,
  setPostPurchaseArgs,
}: BillingInfoFormProps) => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div className="mt-3 mb-2">
        <ValidatedTextfield
          value={postPurchaseArgs.cost_center}
          label="Cost Center"
          required={false}
          onChange={(e) => {
            const correctCount = e.target.value.length === 8;
            const validationResult: TextValidation = {
              validated: correctCount,
              errorString: !correctCount ? "Must be 8 characters" : undefined,
            };
            setPostPurchaseArgs({
              ...postPurchaseArgs,
              cost_center: e.target.value,
            });
            return validationResult;
          }}
        />
      </div>
      <div className="mt-2 mb-2">
        <ValidatedTextfield
          value={postPurchaseArgs.work_breakdown_structure}
          label="WBS"
          required={false}
          onChange={(e) => {
            const correctCount = e.target.value.length === 21;
            const validationResult: TextValidation = {
              validated: correctCount,
              errorString: !correctCount ? "Must be 21 characters" : undefined,
            };
            setPostPurchaseArgs({
              ...postPurchaseArgs,
              work_breakdown_structure: e.target.value,
            });
            return validationResult;
          }}
        />
      </div>
      <div className="mt-2 mb-4">
        <ValidatedTextfield
          value={postPurchaseArgs.general_ledger}
          label="GL"
          required={false}
          onChange={(e) => {
            const correctCount = e.target.value.length === 6;
            const validationResult: TextValidation = {
              validated: correctCount,
              errorString: !correctCount ? "Must be 6 characters" : undefined,
            };
            setPostPurchaseArgs({
              ...postPurchaseArgs,
              general_ledger: e.target.value,
            });
            return validationResult;
          }}
        />
      </div>
    </div>
  );
};
