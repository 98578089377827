import { MutableRefObject, useEffect, useRef } from "react";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";

export const useSetupEnableRegionDrag = (
  regionRef: MutableRefObject<RegionsPlugin | null>,
  allowed: boolean,
) => {
  // Wavesurfer.Js has a bug using the drag select.
  // This is a workaround to disable the drag select when we enable drag creation
  // We first disable the listener if available, then reenable.
  // We do this to avoid multiple region creation on a single drag event.
  // https://github.com/katspaugh/wavesurfer.js/pull/1828 Here is the alleged PR that fixes it.

  const disableDragRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (!regionRef.current || !allowed) {
      disableDragRef.current?.();
      return;
    }
    disableDragRef.current?.();

    disableDragRef.current = regionRef.current.enableDragSelection({});
  }, [allowed, regionRef.current]);
};
