import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { receiveErrors } from "../../store/actions/errorStore";
import { useAppDispatch } from "../../store/hooks";
import { FrequentlyAskedQuestion } from "../../store/models/frequentlyAskedQuestion";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { FREQUENTLY_ASKED_QUESTION } from "../../store/utils/routes";

export const useGetFrequentlyAskedQuestions = (userId?: number) => {
  const dispatch = useAppDispatch();
  return useQuery({
    queryKey: [QUERY_KEYS.GET_FREQUENTLY_ASKED_QUESTIONS, userId],
    enabled: Boolean(userId),
    queryFn: async () => {
      if (!userId) return [];
      const params = queryString.stringify({
        user_id: userId,
      });
      const response = await makeBackendGetCallWithJsonResponse<
        FrequentlyAskedQuestion[]
      >(FREQUENTLY_ASKED_QUESTION, `?${params}`);

      if (response.success) {
        return response.resultJson;
      }

      const errors = { errors: response.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(response.resultJson);
    },
    initialData: [],
  });
};
