import queryString from "query-string";

import { ProjectType } from "../models/project";
import { QueryParamsType, RootState } from "../index";
import { Genre } from "../models/genres";
import {
  ArtistSuggestion,
  AutoCompleteTypeEnum,
  AutocompleteSuggestion,
} from "../models/autocomplete";
import { MAP_STUDIO_SEARCH, MAP_USER_SEARCH } from "../utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { MapStudio } from "../models/studio";
import {
  removeAutoCompleteSuggestions,
  setAutoCompleteSuggestions,
} from "./userSearch";
import { receiveErrors } from "./errorStore";
import User from "../models/user";
import { isLocationInView } from "../../utils/mapUtils";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";

// Uses Los Angeles as default map location.
export const DEFAULT_LATITUDE = 34.0549076;
export const DEFAULT_LONGITUDE = -118.242643;
export const DEFAULT_ZOOM = 11;
export const MAP_SEARCH_MAX_RESULTS = 40;

export enum MapQuadrantEnum {
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
}

export enum MapSearchTypeEnum {
  EXPLORE = "Explore",
  USER = "User",
  STUDIO = "Studio",
}

export interface MapBounds {
  minLatitude: number;
  maxLatitude: number;
  minLongitude: number;
  maxLongitude: number;
}

interface MapSearchState {
  mapLatLng: google.maps.LatLngLiteral | undefined;
  mapBounds: MapBounds | undefined;
  currentMapBounds: MapBounds | undefined;
  currentMapZoom: number;
  studioResults: { count: number; studios: MapStudio[]; inViewCount: number };
  engineerResults: { count: number; engineers: User[]; inViewCount: number };
  loadingStudios: boolean;
  loadingEngineers: boolean;
  initialLoad: boolean;
}

interface SearchMapUsersResponse {
  data: User[];
  count: number;
}

export const searchMapUsers = createAsyncThunk(
  MAP_USER_SEARCH,
  async (
    { minLatitude, maxLatitude, minLongitude, maxLongitude }: MapBounds,
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;
    const {
      maxRate,
      minRate,
      simpleBudgetSelected,
      serviceTypes,
      genres,
      autocompleteSuggestions,
    } = state.userSearch;

    const queryParamObj: QueryParamsType = {};
    queryParamObj.min_latitude = minLatitude;
    queryParamObj.max_latitude = maxLatitude;
    queryParamObj.min_longitude = minLongitude;
    queryParamObj.max_longitude = maxLongitude;

    if (maxRate > 0) {
      queryParamObj.min_rate = minRate;
      queryParamObj.max_rate = maxRate;
    } else if (simpleBudgetSelected.length > 0) {
      queryParamObj.simple_budget_selected = simpleBudgetSelected;
    }
    if (
      !serviceTypes.includes(ProjectType.NO_TYPE) &&
      serviceTypes.length > 0
    ) {
      queryParamObj.service_types = serviceTypes;
    }
    if (!genres.includes(Genre.NO_GENRE) && genres.length > 0) {
      queryParamObj.genres = genres;
    }
    const checkIfArtistCredit = autocompleteSuggestions.find(
      (suggestion: AutocompleteSuggestion) =>
        suggestion.type === AutoCompleteTypeEnum.ARTIST_CREDIT,
    );
    if (checkIfArtistCredit) {
      const artistCredit = checkIfArtistCredit as ArtistSuggestion;
      queryParamObj.artist_credit = artistCredit.label;
    }

    const params = `?${queryString.stringify(queryParamObj, {
      arrayFormat: "comma",
    })}`;

    emitAnalyticsTrackingEvent("user_map_search", {
      query_params: params,
    });
    const response =
      await makeBackendGetCallWithJsonResponse<SearchMapUsersResponse>(
        MAP_USER_SEARCH,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface SearchMapStudiosResponse {
  data: MapStudio[];
  count: number;
}

export const searchMapStudios = createAsyncThunk(
  MAP_STUDIO_SEARCH,
  async (
    { minLatitude, maxLatitude, minLongitude, maxLongitude }: MapBounds,
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;
    const {
      maxRate,
      minRate,
      simpleBudgetSelected,
      daysAvailable,
      durationMinutes,
      allowNoEngineer,
      selectedAmenities,
    } = state.studioRoomSearch;

    const queryParamObj: QueryParamsType = {};
    queryParamObj.min_latitude = minLatitude;
    queryParamObj.max_latitude = maxLatitude;
    queryParamObj.min_longitude = minLongitude;
    queryParamObj.max_longitude = maxLongitude;

    if (maxRate > 0) {
      queryParamObj.min_rate = minRate;
      queryParamObj.max_rate = maxRate;
    } else if (simpleBudgetSelected.length > 0) {
      queryParamObj.simple_budget_selected = simpleBudgetSelected;
    }
    if (daysAvailable.length > 0) {
      queryParamObj.days_available = daysAvailable.map((day) => day);
    }
    if (durationMinutes) {
      queryParamObj.duration = durationMinutes;
    }
    if (allowNoEngineer) {
      queryParamObj.allow_no_engineer = true;
    }
    if (selectedAmenities.length > 0) {
      queryParamObj.amenities = selectedAmenities.map((amenity) => amenity);
    }

    const params = `?${queryString.stringify(queryParamObj, {
      arrayFormat: "comma",
    })}`;

    emitAnalyticsTrackingEvent("studio_map_search", {
      query_params: params,
    });
    const response =
      await makeBackendGetCallWithJsonResponse<SearchMapStudiosResponse>(
        MAP_STUDIO_SEARCH,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

const initialState: MapSearchState = {
  mapLatLng: undefined,
  mapBounds: undefined,
  currentMapBounds: undefined,
  currentMapZoom: DEFAULT_ZOOM,
  studioResults: { count: 0, studios: [], inViewCount: 0 },
  engineerResults: { count: 0, engineers: [], inViewCount: 0 },
  loadingStudios: false,
  loadingEngineers: false,
  initialLoad: false,
};

const mapSearchSlice = createSlice({
  name: "mapSearch",
  initialState,
  reducers: {
    setMapLatLng: (state, action: PayloadAction<google.maps.LatLngLiteral>) => {
      state.mapLatLng = action.payload;
    },
    setMapBounds: (state, action: PayloadAction<MapBounds>) => {
      state.mapBounds = action.payload;
      state.currentMapBounds = action.payload;
    },
    setCurrentMapBounds: (state, action: PayloadAction<MapBounds>) => {
      state.currentMapBounds = action.payload;
      const engineerInViewCount = state.engineerResults.engineers.reduce(
        (count, engineer) => {
          if (isLocationInView(engineer.location, action.payload)) {
            return count + 1;
          }
          return count;
        },
        0,
      );
      const studioInViewCount = state.studioResults.studios.reduce(
        (count, studio) => {
          if (isLocationInView(studio.location, action.payload)) {
            return count + 1;
          }
          return count;
        },
        0,
      );
      state.engineerResults = {
        ...state.engineerResults,
        inViewCount: engineerInViewCount,
      };
      state.studioResults = {
        ...state.studioResults,
        inViewCount: studioInViewCount,
      };
    },
    setMapZoom: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        currentMapZoom: action.payload,
      };
    },
    setMapLatLngFromCurrentMapBounds: (state) => {
      if (!state.currentMapBounds) return;
      const latitude =
        (state.currentMapBounds.maxLatitude +
          state.currentMapBounds.minLatitude) /
        2;
      const longitude =
        (state.currentMapBounds.maxLongitude +
          state.currentMapBounds.minLongitude) /
        2;
      state.mapLatLng = { lat: latitude, lng: longitude };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setAutoCompleteSuggestions, (state, action) => {
      if (action.payload.type === AutoCompleteTypeEnum.LOCATION) {
        const locationSuggestion = action.payload;
        const latLng = {
          lat: parseFloat(locationSuggestion.latitude),
          lng: parseFloat(locationSuggestion.longitude),
        };
        if (
          !state.mapLatLng ||
          state.mapLatLng.lat !== latLng.lat ||
          state.mapLatLng.lng !== latLng.lng
        ) {
          state.mapLatLng = latLng;
        } else {
          state.mapLatLng = undefined;
        }
      }
    });
    builder.addCase(removeAutoCompleteSuggestions, (state, action) => {
      if (action.payload.type === AutoCompleteTypeEnum.LOCATION) {
        state.mapLatLng = undefined;
      }
    });
    builder.addCase(searchMapUsers.fulfilled, (state, action) => {
      state.engineerResults = {
        count: action.payload.count,
        engineers: action.payload.data,
        inViewCount: action.payload.count,
      };
      state.loadingEngineers = false;
      state.initialLoad = true;
    });
    builder.addCase(searchMapStudios.fulfilled, (state, action) => {
      state.studioResults = {
        count: action.payload.count,
        studios: action.payload.data,
        inViewCount: action.payload.count,
      };
      state.loadingStudios = false;
      state.initialLoad = true;
    });
    builder.addCase(searchMapStudios.pending, (state) => {
      state.loadingStudios = true;
    });
    builder.addCase(searchMapUsers.pending, (state) => {
      state.loadingEngineers = true;
      state.initialLoad = true;
    });
    builder.addMatcher(
      isAnyOf(searchMapUsers.rejected, searchMapStudios.rejected),
      (state) => {
        state.loadingStudios = false;
        state.loadingEngineers = false;
      },
    );
  },
});

export default mapSearchSlice.reducer;
export const {
  setMapLatLng,
  setMapBounds,
  setMapLatLngFromCurrentMapBounds,
  setCurrentMapBounds,
  setMapZoom,
} = mapSearchSlice.actions;
