import { MusoCreditsTableWrapper } from "../MusoCreditsTable/MusoCreditsTableWrapper";
import { PortfolioTabColumnContainer } from "./PortfolioTab.styles";
import { FeaturedTrackTableWrapper } from "../FeaturedTrackTable/FeaturedTrackTableWrapper";
export const PortfolioTab = () => {
  return (
    <PortfolioTabColumnContainer>
      <FeaturedTrackTableWrapper />
      <MusoCreditsTableWrapper />
    </PortfolioTabColumnContainer>
  );
};
