import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DevLinkProvider, EngineEarsFooter2024 } from "../../../devlink";
import { useTransactionRedirect } from "../../../hooks/bookingHooks/useTransactionRedirect";
import {
  useGetProjectTypeToProjectTypeMetadata,
  useGetReadableServices,
} from "../../../hooks/checkoutHooks/useGetProjectTypeToProjectTypeMetadata";
import { usePurchaseOrderBudgetManagers } from "../../../hooks/purchaseOrderWithTransactionHooks";
import { useGetItemizedTransaction } from "../../../hooks/scheduledProjectHooks/useGetItemizedTransaction";
import {
  HeaderPosition,
  useGetTransactionOverviewTitle,
} from "../../../hooks/transactionOverviewScreenHooks";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import {
  getPurchaseOrder,
  getTransaction,
} from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { DetailedPurchaseOrder } from "../../../store/models/project";
import { TransactionStatus } from "../../../store/models/transaction";
import User from "../../../store/models/user";
import {
  getDisplayableNameForStudio,
  getDisplayableNameForStudioRoom,
  getDisplayableNameForUser,
} from "../../../store/utils/entityUtils";
import { DetailHeader } from "../../components/DetailHeader/DetailHeader";
import { LabelSummaryAssociatedUsersOverview } from "../../components/LabelSummaryAssociatedUsersOverview/LabelSummaryAssociatedUsersOverview";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { OrderBreakdown } from "../../components/OrderBreakdown/OrderBreakdown";
import { PaymentGatewayModule } from "../../components/PaymentGatewayModule/PaymentGatewayModule";
import { TransactionBreakdown } from "../../components/TransactionBreakdown/TransactionBreakdown";
import { SuccessLottie } from "../../elements/SuccessLottie/SuccessLottie";
import {
  TransactionOverviewCol,
  TransactionOverviewCols,
  TransactionOverviewContentContainer,
  TransactionOverviewHeader,
  TransactionOverviewStackedDiv,
  TransactionOverviewStackedDivs,
  TransactionOverviewTextHeader,
} from "./TransactionOverviewScreen.styles";

const TransactionOverviewScreen = () => {
  const location = useLocation();
  const params = useParams<{
    transaction_id: string | undefined;
  }>();
  const transactionId = Number(params.transaction_id);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const { search } = location;
  const [purchaseOrder, setPurchaseOrder] = useState<DetailedPurchaseOrder>();
  const { transactionData: transaction } = useAppSelector(
    (state) => state.transactionStore,
  );

  // fetch itemized transaction data
  const { data: itemizedTransaction, isFetched } = useGetItemizedTransaction({
    getPurchaseOrder: true,
    transactionCode: transaction?.code ?? "",
    scheduledProjectId: undefined,
  });

  useSetPageTitle("Transaction Overview");
  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const { isDesktop } = useMediaQueryBreakpoint();
  const budgetManagers = usePurchaseOrderBudgetManagers(purchaseOrder);
  const query = new URLSearchParams(search);
  const code: null | string = query.get("code");
  const transactionCode: null | string = query.get("transaction_code");
  const dispatch = useAppDispatch();
  const [isPurchaseOrderLoading, setIsPurchaseOrderLoading] = useState(false);
  const isTransactionPending =
    transaction?.transaction_status === TransactionStatus.PENDING;
  // Determine if recordingtransaction or mixmaster transaction
  // if recording transaction, check if studio is involved.
  const displayableNameForArtist = getDisplayableNameForUser(
    isTransactionPending ? loggedInUser : transaction?.user,
  );
  const [engineerUser, setEngineerUser] = useState<User>();
  const projectTypeToProjectTypeMetadata =
    useGetProjectTypeToProjectTypeMetadata(transaction);
  const readableServices = useGetReadableServices(
    projectTypeToProjectTypeMetadata,
  );
  const overviewTitle = useGetTransactionOverviewTitle(purchaseOrder);
  const getHeaderTitle = useCallback(
    (position: HeaderPosition) => {
      if (!transaction) return "";
      const { recording_sessions } = transaction;
      const isRecordingServiceWithStudio = Boolean(
        recording_sessions?.[0]?.studio_room,
      );
      switch (position) {
        case HeaderPosition.Second:
          return isRecordingServiceWithStudio
            ? "Studio Facility"
            : "With Engineers";
        case HeaderPosition.Third:
          return isRecordingServiceWithStudio ? "Studio Room" : "For Services";
        default:
          return "";
      }
    },
    [transaction],
  );

  const transactionStatus = transaction?.transaction_status;
  const transactionHasNoPurchaseOrder = !(
    transactionStatus === TransactionStatus.PURCHASE_ORDER_REQUIRED ||
    transactionStatus === TransactionStatus.PURCHASE_ORDER_SUBMITTED
  );

  useEffect(() => {
    if (!transaction) return;
    const firstProject = transaction.items[0]?.project;
    const usersOnProject = firstProject?.users;
    const engineerUser = isTransactionPending
      ? usersOnProject?.[0]
      : usersOnProject?.find((user: User) => user.id !== transaction.user?.id);
    setEngineerUser(engineerUser);
  }, [transaction]);

  const getHeaderSubtitle = useCallback(
    (position: HeaderPosition) => {
      if (!transaction) return "";
      const { recording_sessions } = transaction;
      const isRecordingServiceWithStudio = Boolean(
        recording_sessions?.[0]?.studio_room,
      );
      const firstProject = transaction.items[0]?.project;
      const usersOnProject = firstProject?.users;
      const engineerUser = isTransactionPending
        ? usersOnProject?.[0]
        : usersOnProject?.find((user) => user.id !== transaction.user?.id);
      switch (position) {
        case HeaderPosition.Second:
          return isRecordingServiceWithStudio
            ? getDisplayableNameForStudio(
                recording_sessions?.[0]?.studio_room?.studio,
              )
            : getDisplayableNameForUser(engineerUser);
        case HeaderPosition.Third:
          return isRecordingServiceWithStudio
            ? getDisplayableNameForStudioRoom(
                recording_sessions?.[0]?.studio_room,
              )
            : readableServices;
        default:
          return "";
      }
    },
    [transaction, projectTypeToProjectTypeMetadata, readableServices],
  );

  const {
    orderSummary: recordingOrderSummary,
    isLoading: recordingOrderSummaryIsLoading,
  } = useAppSelector((state) => state.recordingCartsStore);
  const {
    orderSummary: mixMasterOrderSummary,
    isLoading: mixMasterOrderSummaryIsLoading,
  } = useAppSelector((state) => state.mixMasterCartsStore);
  const redirect = useTransactionRedirect(
    Boolean(recordingOrderSummary),
    transaction,
  );

  useEffect(() => {
    setIsTransactionLoading(true);
    void dispatch(
      getTransaction({
        transactionId: Number(transactionId),
        transactionCode: transactionCode ?? undefined,
        budgetManagerCode: code ?? undefined,
      }),
    ).finally(() => {
      setIsTransactionLoading(false);
    });
  }, [dispatch, transactionId, code]);

  // Load in the transaction data.
  useEffect(() => {
    if (!code) return;
    setIsPurchaseOrderLoading(true);
    dispatch(
      getPurchaseOrder({
        code: code,
      }),
    )
      .unwrap()
      .then((result) => {
        setPurchaseOrder(result);
        setIsPurchaseOrderLoading(false);
      })
      .catch(() => {
        setIsPurchaseOrderLoading(false);
      });
  }, [code, dispatch]);

  if (isPurchaseOrderLoading || isTransactionLoading) {
    return (
      <LoadingScreen>
        <div className="container-fluid centered-screen">
          <p className="h7">Loading booking details...</p>
        </div>
      </LoadingScreen>
    );
  }
  if (!transaction) {
    return (
      <div className="container-fluid centered-screen">
        <p className="b2">Invalid Link</p>
      </div>
    );
  }
  if (!transaction.items.length) {
    return (
      <div className="container-fluid centered-screen">
        <p className="h7">Nothing has been added to this transaction.</p>
      </div>
    );
  }

  return (
    <>
      <TransactionOverviewContentContainer>
        <TransactionOverviewStackedDivs>
          <TransactionOverviewStackedDiv>
            <TransactionOverviewTextHeader>
              {overviewTitle}
            </TransactionOverviewTextHeader>
          </TransactionOverviewStackedDiv>
          <TransactionOverviewHeader>
            <DetailHeader
              title={"Booking For"}
              subtitle={displayableNameForArtist}
            />
            <DetailHeader
              title={getHeaderTitle(HeaderPosition.Second)}
              subtitle={getHeaderSubtitle(HeaderPosition.Second)}
            />
            <DetailHeader
              title={getHeaderTitle(HeaderPosition.Third)}
              subtitle={getHeaderSubtitle(HeaderPosition.Third)}
            />
          </TransactionOverviewHeader>
        </TransactionOverviewStackedDivs>
        <TransactionOverviewCols>
          <TransactionOverviewCol>
            <TransactionBreakdown
              transaction={transaction}
              engineerUser={engineerUser}
            />
          </TransactionOverviewCol>
          <TransactionOverviewCol>
            <OrderBreakdown
              showLabelToggle={false}
              isLoading={
                mixMasterOrderSummaryIsLoading || recordingOrderSummaryIsLoading
              }
              orderSummary={recordingOrderSummary ?? mixMasterOrderSummary}
            />
            {transaction.transaction_status !== TransactionStatus.PENDING &&
              isFetched && (
                <LabelSummaryAssociatedUsersOverview
                  budgetManagers={budgetManagers}
                  purchaseOrder={purchaseOrder}
                  purchaseOrders={itemizedTransaction?.purchase_orders ?? []}
                  setPurchaseOrder={setPurchaseOrder}
                  code={code}
                  transaction={transaction}
                />
              )}
            {/* if AnR project (with Purchase Order), hide the check out button */}
            {transactionHasNoPurchaseOrder && (
              <PaymentGatewayModule
                redirectPath={redirect}
                isMobile={!isDesktop}
                transaction={transaction}
                minimumDepositPercentage={
                  transaction.deposit_percentage_required
                }
                serviceType={transaction.items[0]?.project?.service_type}
                forceEnablePayment
              >
                <SuccessLottie />
              </PaymentGatewayModule>
            )}
          </TransactionOverviewCol>
        </TransactionOverviewCols>
      </TransactionOverviewContentContainer>
      <DevLinkProvider>
        <EngineEarsFooter2024 />
      </DevLinkProvider>
    </>
  );
};

export default TransactionOverviewScreen;
