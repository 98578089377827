import styled from "styled-components";

export const TooltipHelperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
`;

export const TooltipText = styled.span`
  visibility: hidden;
  background-color: ${({ theme }) => theme.midgroundColor};
  border: 2px solid ${({ theme }) => theme.textTertiaryColor};
  color: ${({ theme }) => theme.textPrimaryColor};
  flex-wrap: nowrap;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  position: absolute;
  transform: translateY(5px);
  z-index: var(--tooltip-text-z-index);
  cursor: auto;
  padding: 8px 12px;
  min-width: 100px;
  width: max-content;
  box-sizing: border-box;
`;
