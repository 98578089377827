import React from "react";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { removeSessionAtIndex } from "../../../store/actions/shoppingCart";
import { useAppDispatch } from "../../../store/hooks";

interface DeleteSessionConfirmationModalProps {
  showDeleteSessionConfirmationModal: boolean;
  setShowDeleteSessionConfirmationModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  index?: number;
}

export const DeleteSessionConfirmationModal: React.FC<
  DeleteSessionConfirmationModalProps
> = ({
  showDeleteSessionConfirmationModal,
  setShowDeleteSessionConfirmationModal,
  index,
}) => {
  const dispatch = useAppDispatch();

  const onConfirm = () => {
    if (index === undefined) return;
    dispatch(removeSessionAtIndex(index));
    setShowDeleteSessionConfirmationModal(false);
  };

  return (
    <BaseModal
      open={showDeleteSessionConfirmationModal}
      setOpen={setShowDeleteSessionConfirmationModal}
      header={"Delete Session"}
      onConfirm={onConfirm}
      onCancel={() => {
        setShowDeleteSessionConfirmationModal(false);
      }}
      confirmText="Delete"
      showModalFooter
    >
      Are you sure you&apos;d like to delete this session?
    </BaseModal>
  );
};
