import { ReactNode, CSSProperties } from "react";
import {
  ContactInfoRequiredButton,
  ContactInfoRequiredButtonProps,
} from "./ContactInfoRequiredButton";
import {
  LoginRequiredButton,
  LoginRequiredButtonProps,
} from "./LoginRequiredButton";

export interface PrerequisiteButtonsProps {
  loginRequiredButtonProps?: Partial<LoginRequiredButtonProps>;
  contactInfoRequiredButtonprops?: Partial<ContactInfoRequiredButtonProps>;
  children?: ReactNode;
  needsContactInfo?: boolean;
  disabled?: boolean;
  customStyle?: CSSProperties;
}

export const PrerequisiteButtons = ({
  children,
  loginRequiredButtonProps = {},
  contactInfoRequiredButtonprops = {},
  needsContactInfo = true,
  customStyle = {},
  disabled,
}: PrerequisiteButtonsProps) => {
  const { buttonProps: loginButtonProps } = loginRequiredButtonProps;
  const { buttonProps: contactInfoButtonProps } =
    contactInfoRequiredButtonprops;
  return (
    <LoginRequiredButton
      buttonProps={{
        disabled,
        style: { ...customStyle, ...loginButtonProps?.style },
        ...loginButtonProps,
      }}
      {...loginRequiredButtonProps}
    >
      {needsContactInfo ? (
        <ContactInfoRequiredButton
          buttonProps={{
            disabled,
            style: { ...customStyle, ...contactInfoButtonProps?.style },
            ...contactInfoButtonProps,
          }}
          {...contactInfoRequiredButtonprops}
        >
          {children}
        </ContactInfoRequiredButton>
      ) : (
        children
      )}
    </LoginRequiredButton>
  );
};
