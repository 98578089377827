import User from "../store/models/user";
import { useMemo } from "react";

export const useIsAandR = (user?: User) => {
  return useMemo(() => {
    if (!user) return false;
    return Boolean(user.aandr) && Boolean(user?.aandr?.deleted === null);
  }, [user]);
};

export const useIsUMGUser = (user?: User) => {
  return useMemo(() => {
    if (!user) return false;
    if (!user.email) return false;
    return (
      (user.email.includes("@umusic.com") ||
        user.email.includes("@umgconsult.com") ||
        user.email.includes("@engineears.com")) &&
      Boolean(user.email_verified)
    );
  }, [user]);
};

export const useIsUMGAandR = (user?: User) => {
  const isUmgUser = useIsUMGUser(user);
  const isAandR = useIsAandR(user);

  return useMemo(() => {
    return isUmgUser && isAandR;
  }, [user]);
};

export const useOnboardingStepForUmgUser = (user?: User): number => {
  return useMemo(() => {
    if (!user) return -1;
    if (!user.email) return -1;
    if (!user.aandr || user.aandr.label?.length === 0) return 0; // Step 1, choose a label.
    // TODO: Add back phone number verification requirement when SMS throughput is fixed.
    if (!user.phone_number) return 1; // Step 2, add phone number.
    return -1;
  }, [user]);
};
