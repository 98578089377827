import { makeBackendPostCallWithJsonResponse } from "../../../store/utils/fetch";
import { CREATE_DIRECT_MESSAGE_REQUEST } from "../../../store/utils/routes";
import { StreamSuccessResponse } from "./types";

export interface DirectMessageParams {
  other_user_id: number;
  message: string;
  accept?: boolean;
}

export const postDirectMessageRequest = async (args: DirectMessageParams) => {
  const response =
    await makeBackendPostCallWithJsonResponse<StreamSuccessResponse>(
      CREATE_DIRECT_MESSAGE_REQUEST,
      args,
    );

  if (response.success) {
    return response.resultJson;
  }
  return Promise.reject(response.resultJson);
};

export default postDirectMessageRequest;
