import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { SCREENS } from "../../../routes";
import { storeAnonymousId } from "../../../store/actions/accountInfo";
import { applyPromoCode } from "../../../store/actions/marketing";
import {
  searchStudioRooms,
  setFetchingStudioRooms,
} from "../../../store/actions/studioRoomSearch";
import {
  SearchOrigin,
  searchUsers,
  setFetchingUsers,
} from "../../../store/actions/userSearch";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { AutoCompleteTypeEnum } from "../../../store/models/autocomplete";
import { isOnLandingPageRoute } from "../../../store/utils/routeTesters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { SearchFilterContainer } from "../../components/SearchFilters/SearchFilters";
import { SearchResultsHOCScreen } from "../SearchResultHOCScreen/SearchResultsHOCScreen";
import useSearch from "../../../hooks/searchHooks/useSearch";

export interface SearchScreenProps {
  disableUserSearch: boolean;
  disableStudioSearch: boolean;
  children?: ReactNode;
  showQuickFilters?: boolean;
  mapView?: boolean;
  updateSearchView?: () => void;
  showResultsViewOptions?: boolean;
}

export const SearchScreen = ({
  children,
  disableStudioSearch,
  disableUserSearch,
  showQuickFilters = true,
  mapView = false,
  updateSearchView,
  showResultsViewOptions = true,
}: SearchScreenProps) => {
  const { debouncedSearchTerm, autocompleteSuggestions } = useSearch({
    enabled: true,
    mapView,
  });
  const { pathname, search } = useLocation();
  const { anonymousId } = useAppSelector((state) => state.accountInfo);
  const fetchUserAbortControllerRef = useRef<AbortController | null>(null);
  const fetchStudioRoomAbortControllerRef = useRef<AbortController | null>(
    null,
  );

  const [isSearchFilterVisible, setIsSearchFilterVisible] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    serviceTypes,
    longitude,
    latitude,
    genres,
    page,
    simpleBudgetSelected,
    minRate,
    maxRate,
    maxDistance,
  } = useAppSelector((state) => state.userSearch);

  const {
    latitude: studioRoomLatitude,
    longitude: studioRoomLongitude,
    page: studioRoomPage,
    maxRate: studioRoomMaxRate,
    selectedAmenities,
    daysAvailable,
    durationMinutes,
  } = useAppSelector((state) => state.studioRoomSearch);

  const trackAnonymousId = useCallback(() => {
    if (window.analytics.user) {
      const segmentAnonymousId = window.analytics.user().anonymousId();
      dispatch(storeAnonymousId(segmentAnonymousId));
    }
  }, [dispatch]);

  const openFilterOptions = () => {
    emitAnalyticsTrackingEvent("open_search_filters", {});
    setIsSearchFilterVisible(true);
  };

  useEffect(() => {
    if (anonymousId) return;
    trackAnonymousId();
  }, [anonymousId, trackAnonymousId, dispatch]);

  const promoCode = useMemo(() => {
    return new URLSearchParams(search).get("promocode");
  }, [search]);

  const paymentDeposits = useMemo(() => {
    return new URLSearchParams(search).get("payment_deposits");
  }, [search]);

  useEffect(() => {
    if (mapView || isOnLandingPageRoute(pathname)) {
      return;
    }
    if (disableUserSearch) return;

    if (fetchUserAbortControllerRef.current) {
      fetchUserAbortControllerRef.current.abort();
      fetchUserAbortControllerRef.current = null;
    }
    const fetchUserAbortController = new AbortController();
    fetchUserAbortControllerRef.current = fetchUserAbortController;
    const fetchUser = () => {
      const searchOrigin =
        pathname === SCREENS.SEARCH
          ? SearchOrigin.EXPLORE_SCREEN
          : SearchOrigin.ENGINEER_SEARCH_SCREEN;
      void dispatch(
        searchUsers({
          search: debouncedSearchTerm,
          searchOrigin,
          promoCode,
          paymentDeposits,
          abortController: fetchUserAbortController,
        }),
      );
    };

    fetchUser();

    return () => {
      if (fetchUserAbortControllerRef.current) {
        fetchUserAbortControllerRef.current.abort();
        fetchUserAbortControllerRef.current = null;
      }
      dispatch(setFetchingUsers(false));
    };
  }, [
    debouncedSearchTerm,
    dispatch,
    longitude,
    latitude,
    page,
    genres.length,
    serviceTypes.length,
    disableStudioSearch,
    autocompleteSuggestions.length,
    simpleBudgetSelected,
    minRate,
    maxRate,
    maxDistance,
    trackAnonymousId,
    anonymousId,
    disableUserSearch,
    mapView,
    promoCode,
    pathname,
  ]);

  useEffect(() => {
    if (mapView || isOnLandingPageRoute(pathname)) {
      return;
    }
    if (disableStudioSearch) return;

    if (fetchStudioRoomAbortControllerRef.current) {
      fetchStudioRoomAbortControllerRef.current.abort();
      fetchStudioRoomAbortControllerRef.current = null;
    }
    const fetchStudioRoomAbortController = new AbortController();
    fetchStudioRoomAbortControllerRef.current = fetchStudioRoomAbortController;
    const fetchStudioRooms = () => {
      const searchOrigin =
        pathname === SCREENS.SEARCH
          ? SearchOrigin.EXPLORE_SCREEN
          : SearchOrigin.STUDIO_ROOM_SEARCH_SCREEN;
      void dispatch(
        searchStudioRooms({
          search: debouncedSearchTerm,
          searchOrigin,
          promoCode,
          paymentDeposits,
          abortController: fetchStudioRoomAbortController,
        }),
      );
    };
    fetchStudioRooms();

    return () => {
      if (fetchStudioRoomAbortControllerRef.current) {
        fetchStudioRoomAbortControllerRef.current.abort();
        fetchStudioRoomAbortControllerRef.current = null;
      }
      dispatch(setFetchingStudioRooms(false));
    };
  }, [
    mapView,
    debouncedSearchTerm,
    dispatch,
    disableUserSearch,
    studioRoomLatitude,
    studioRoomLongitude,
    studioRoomMaxRate,
    studioRoomPage,
    simpleBudgetSelected,
    autocompleteSuggestions.length,
    minRate,
    maxRate,
    maxDistance,
    selectedAmenities,
    daysAvailable,
    durationMinutes,
    anonymousId,
    disableStudioSearch,
    trackAnonymousId,
    pathname,
    promoCode,
  ]);

  useEffect(() => {
    emitAnalyticsTrackingEvent("search", {});
  }, []);

  useEffect(() => {
    if (promoCode) {
      void dispatch(
        applyPromoCode({
          promocode: promoCode,
        }),
      );
    }
  }, [dispatch, promoCode]);

  return (
    <>
      <SearchResultsHOCScreen
        placeholder={
          isMobile
            ? ""
            : !disableUserSearch && !disableStudioSearch
              ? "Search by name, location, verified credits and more"
              : !disableStudioSearch && disableUserSearch
                ? "Search by name, location, equipment and more"
                : "Search by name, location, verified credits and more"
        }
        autoCompleteTypeFilter={
          !disableUserSearch && disableStudioSearch
            ? AutoCompleteTypeEnum.USER
            : !disableStudioSearch && disableUserSearch
              ? AutoCompleteTypeEnum.STUDIO
              : undefined
        }
        showQuickFilters={showQuickFilters}
        toggleFilters={openFilterOptions}
        updateSearchView={updateSearchView}
        mapView={mapView}
        showResultsViewOptions={showResultsViewOptions}
      >
        {children}
      </SearchResultsHOCScreen>
      <SearchFilterContainer
        showStudioFilterOptions={!disableStudioSearch}
        showEngineerFilterOptions={!disableUserSearch}
        isVisible={isSearchFilterVisible}
        onClose={() => setIsSearchFilterVisible(false)}
        mapView={mapView}
      />
    </>
  );
};
