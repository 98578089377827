import { useEffect, useMemo } from "react";
import { getRecordingServiceAvailability } from "../../store/actions/recording";
import { useAppDispatch } from "../../store/hooks";
import Engineer from "../../store/models/engineer";
import { getCurrentDate } from "../../store/utils/dateTimeUtils";
import { getStartTimesForContiguousAvailableBlocks } from "../../store/utils/recordingUtils";
import { convertDatesToOptionType } from "../../store/utils/serviceUtils";
import { hoursFromMinutes } from "../../store/utils/shoppingCartUtils";

export const useAvailableDateTimeOptions = (
  dateTime: Date | undefined,
  durationMinutes: number,
  engineer: Engineer | undefined,
  studioDateMap: Map<string, string>,
  engineerDateMap: Map<string, string>,
  selectOnlyEngineerDateTimeOptions?: boolean,
  timezone?: string,
) => {
  const dispatch = useAppDispatch();

  // Get availability options for engineer and mark them as working hours, but not necessarily studio hours
  const engineerAvailabilityOptions = useMemo(
    () =>
      getAvailabilityOptions(
        dateTime,
        durationMinutes,
        engineerDateMap,
        timezone,
      ).map((option) => ({
        ...option,
        isStudioHour: false,
        isWorkingHour: true,
      })),
    [dateTime, engineerDateMap, durationMinutes, timezone],
  );

  // Get availability options for studio and mark them as working hours and studio hours
  const studioAvailabilityOptions = useMemo(
    () =>
      getAvailabilityOptions(
        dateTime,
        durationMinutes,
        studioDateMap,
        timezone,
      ).map((option) => ({
        ...option,
        isStudioHour: true,
        isWorkingHour: true,
      })),
    [dateTime, studioDateMap, durationMinutes, timezone],
  );

  // Mark which studio hours overlap with engineer hours
  const overlappingAvailabilityOptions = useMemo(() => {
    // if no engineer, then studio hours are working hours
    if (!engineer) {
      return studioAvailabilityOptions;
    }

    // if engineer has no availability, then studio hours are not working hours
    if (!engineerAvailabilityOptions.length) {
      return studioAvailabilityOptions.map((studioOption) => ({
        ...studioOption,
        isWorkingHour: false,
      }));
    }

    return studioAvailabilityOptions.map((studioOption) => {
      const isOverlapping = engineerAvailabilityOptions.some(
        (engineerOption) => {
          return studioOption.label === engineerOption.label;
        },
      );
      return { ...studioOption, isWorkingHour: isOverlapping };
    });
  }, [studioAvailabilityOptions, engineer, engineerAvailabilityOptions]);

  // Support availability for more than one engineer.
  useEffect(() => {
    if (!engineerDateMap.size && engineer) {
      void dispatch(
        getRecordingServiceAvailability({
          userId: engineer.user_id,
          timezoneShiftMinutes: new Date().getTimezoneOffset(),
          startDate: getCurrentDate(),
        }),
      ).unwrap();
    }
  }, [engineerDateMap, engineer, dispatch]);

  // return either Engineer or Studio availability options
  return useMemo(
    () =>
      selectOnlyEngineerDateTimeOptions
        ? engineerAvailabilityOptions
        : overlappingAvailabilityOptions,
    [
      selectOnlyEngineerDateTimeOptions,
      engineerAvailabilityOptions,
      overlappingAvailabilityOptions,
    ],
  );
};

export const getStartTimesForContiguousAvailableBlocksForAvailabilityMap = (
  dateTime: Date,
  duration: number,
  availabilityMap: Map<string, string>,
) => {
  const localDateTimeClone = new Date(dateTime);
  localDateTimeClone.setHours(0, 0, 0, 0);
  return getStartTimesForContiguousAvailableBlocks(
    localDateTimeClone,
    availabilityMap,
    duration,
  );
};

/**
 * Converts an availability map to an array of OptionType objects.
 * @param dateTime
 * @param duration
 * @param availabilityMap
 * @returns OptionType[]
 */
const getAvailabilityOptions = (
  dateTime: Date | undefined,
  duration: number,
  availabilityMap: Map<string, string>,
  timezone?: string,
) => {
  if (!availabilityMap.size || !dateTime) {
    return [];
  }
  const localDateTimeClone = new Date(dateTime);

  const startTimes =
    getStartTimesForContiguousAvailableBlocksForAvailabilityMap(
      localDateTimeClone,
      duration,
      availabilityMap,
    );
  return convertDatesToOptionType(
    startTimes,
    hoursFromMinutes(duration),
    timezone,
  );
};
