import { Box, styled } from "@mui/material";

export const AdminDashboardMobileScreenContainer = styled(Box)`
  height: 100%;
  width: 100%;
`;

export const AdminDashboardHeaderContainer = styled(Box)(({ theme }) => ({
  alignItems: "center",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  height: "64px",
  justifyContent: "space-between",
  width: "100%",

  [theme.breakpoints.down("md")]: {
    padding: "0 16px",
  },
}));
