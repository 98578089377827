import { useCallback, useRef } from "react";
import { TwitchEmbed, TwitchEmbedInstance } from "react-twitch-embed";
import { useAppSelector } from "../../../store/hooks";
import { LivestreamOverlay, LivestreamWrapper } from "./Livestream.styles";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

import { UnauthenticatedModal } from "../../components/UnauthenticatedModal/UnauthenticatedModal";

export const LivestreamScreen = () => {
  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);
  const darkMode = useAtomValue(darkModeAtom);
  const embed = useRef<TwitchEmbedInstance>(); // We use a ref instead of state to avoid rerenders.

  const handleReady = useCallback(
    (e: TwitchEmbedInstance) => {
      embed.current = e;
    },
    [embed],
  );
  return (
    <LivestreamWrapper>
      {!isAuthenticated && (
        <>
          <LivestreamOverlay />
          <UnauthenticatedModal
            showModal={true}
            closeModal={() => {}}
            showCloseButton={false}
            message={"Please sign in to join the stream"}
          />
        </>
      )}
      <TwitchEmbed
        channel="engineears_official"
        autoplay
        withChat
        width={"100%"}
        height={"100%"}
        darkMode={darkMode ? true : false}
        onVideoReady={handleReady}
      />
    </LivestreamWrapper>
  );
};

export default LivestreamScreen;
