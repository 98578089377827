// Contains methods to determine whether the current location pathname
// is on a certain page.
import { pathToRegexp } from "path-to-regexp";

import { SCREENS } from "../../routes";

const isOnSignedOutHomeScreen = (path: string) =>
  Boolean(pathToRegexp(SCREENS.SIGNED_OUT_HOME_SCREEN).exec(path));

const isOnLegacyForArtistsScreen = (path: string) =>
  Boolean(pathToRegexp(SCREENS.LEGACY_FOR_ARTISTS).exec(path));

const isOnLegacyForEngineerScreen = (path: string) =>
  Boolean(pathToRegexp(SCREENS.LEGACY_FOR_ENGINEERS).exec(path));

export const isOnLandingPageRoute = (path: string) => {
  return (
    isOnSignedOutHomeScreen(path) ||
    isOnLegacyForArtistsScreen(path) ||
    isOnLegacyForEngineerScreen(path)
  );
};
