import {
  Stack,
  Pagination,
  PaginationItem,
  PaginationRenderItemParams,
  useTheme,
} from "@mui/material";

interface PaginationProps {
  loading: boolean;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const PaginationComponent = ({
  loading,
  currentPage,
  totalPages,
  onPageChange,
}: PaginationProps) => {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={2}>
      <Pagination
        page={currentPage}
        disabled={loading}
        count={totalPages}
        shape="rounded"
        renderItem={(item: PaginationRenderItemParams) => {
          return (
            <PaginationItem
              {...item}
              sx={
                item.page
                  ? {
                      "&.Mui-selected": {
                        backgroundColor:
                          theme.palette.standardColor.BoomyOrange[400],
                      },
                      color: item.selected
                        ? theme.palette.standardColor.White[0]
                        : theme.palette.grey[300],
                    }
                  : {}
              }
            />
          );
        }}
        onChange={(_event: React.ChangeEvent<unknown>, page: number) => {
          if (page < 1 || page > totalPages) return;
          onPageChange(page);
        }}
      />
    </Stack>
  );
};
