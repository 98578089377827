import { FC } from "react";
import { RecordingSession } from "../../../store/models/recordingSession";
import { ScheduledProject } from "../../../store/models/scheduledproject";
import "../ChatRoom/ChatRoom.css";
import { ProjectChatButton } from "../ChatModal/ProjectChatButton";

export interface ProjectChatPanelProps {
  scheduledProject?: ScheduledProject;
  recordingSession?: RecordingSession;
  loading?: boolean;
}

export const ProjectChatPanel: FC<ProjectChatPanelProps> = ({
  scheduledProject,
  recordingSession,
}) => {
  return (
    <ProjectChatButton
      scheduledProject={scheduledProject}
      recordingSession={recordingSession}
    />
  );
};
