import { FC, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { PromoCode } from "../../../store/models/promoCode";
import "./PromoList.css";
import { DefaultSoundWaveLoader } from "../../elements/DefaultSoundWaveLoader/DefaultSoundWaveLoader";
import { ColorPalette } from "../../theme";
import {
  deletePromoCode,
  getProfilePromoCodes,
  PromoCodeUpdates,
} from "../../../store/actions/marketing";
import { CheckBox } from "../../elements/CheckBox/CheckBox";
import { formatDateToShort } from "../../../utils/formatDateToShort";
import delete_icon from "../../lotties/delete-trash-can.json";
import Lottie from "react-lottie";
import { faLink as linkIcon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import copyToClipboard from "copy-to-clipboard";
import { toast } from "react-toastify";
import { host } from "../../../store/utils/utils";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import {
  getStudioScreenRoute,
  getProfileScreenRoute,
} from "../../../store/utils/routeGetters";
interface PromoListItemProps {
  promoCode: PromoCode;
}

export const options = {
  loop: false,
  autoplay: true,
  duration: 1000,
  animationData: delete_icon,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const PromoListItem: FC<PromoListItemProps> = ({ promoCode }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const { isDesktop } = useMediaQueryBreakpoint();
  const selectedStudioProfile = useAppSelector(
    (state) => state.selectedProfileSlice.studio,
  );
  const handleDelete = useCallback(() => {
    window.analytics.track(getDebugEventPrefix + "delete_custom_promo_code", {
      user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
    });
    void dispatch(
      deletePromoCode({
        code: promoCode.code,
        action: PromoCodeUpdates.delete,
        studio_id: selectedStudioProfile?.id,
      }),
    ).unwrap();
  }, [dispatch, promoCode.code, selectedStudioProfile?.id, user?.id]);
  return (
    <div className="engineer-promo-list-item">
      <div className="engineer-promo-list-item-column">
        <p className={"b1"}>
          {promoCode.expires_at
            ? formatDateToShort(new Date(promoCode.expires_at))
            : null}
        </p>
      </div>
      <div className="engineer-promo-list-item-column promo-code-name-container">
        <p className="label2-semi-bold">{promoCode.code}</p>
      </div>
      <div className="engineer-promo-list-item-column">
        <p className={"b1"}>
          {`${Math.floor(+promoCode.discount_percentage * 100)}%`}
        </p>
      </div>
      {isDesktop && (
        <div className="engineer-promo-list-item-column promo-check-box-column">
          <CheckBox label={""} isSelected={!promoCode.owner} />
        </div>
      )}
      <div className="engineer-promo-list-item-column promo-check-box-column">
        <FontAwesomeIcon
          onClick={() => {
            if (promoCode.studio) {
              copyToClipboard(
                `${host}${getStudioScreenRoute(selectedStudioProfile?.username ?? "")}?promocode=${promoCode.code}`,
              );
            } else {
              copyToClipboard(
                `${host}${getProfileScreenRoute(user?.username ?? "")}/?promocode=${promoCode.code}`,
              );
            }

            toast.success("Copied promocode share link to clipboard");
          }}
          className="promo-code-link-icon"
          icon={linkIcon}
          style={{
            color: ColorPalette.DarkGrey,
          }}
          size="lg"
        />
      </div>
      <div
        className="engineer-promo-list-item-column delete-promocode-button"
        title="delete promo code"
      >
        {(promoCode.owner !== null || promoCode.studio) && (
          <button onClick={handleDelete}>
            <Lottie options={options} height={25} width={25} />
          </button>
        )}
      </div>
    </div>
  );
};

export const PromoList = () => {
  const dispatch = useAppDispatch();
  const {
    promoCodes,
    loading,
    campaignsOptedIn,
    competitionsOptedIn,
    optedIntoUnverifiedEngineerPromo,
  } = useAppSelector((state) => state.marketingDataStore);
  // add promo code
  const selectedStudioProfile = useAppSelector(
    (state) => state.selectedProfileSlice.studio,
  );

  const { isDesktop } = useMediaQueryBreakpoint();

  useEffect(() => {
    void dispatch(
      getProfilePromoCodes({
        studio_id: selectedStudioProfile?.id,
      }),
    ).unwrap();
  }, [
    campaignsOptedIn,
    competitionsOptedIn,
    optedIntoUnverifiedEngineerPromo,
    dispatch,
    selectedStudioProfile,
  ]);

  return (
    <div className="engineer-promo-list-container">
      <div className="engineer-promo-list-item">
        <div className="engineer-promo-list-item-column">
          <p className="b1-semi-bold">Expires on</p>
        </div>
        <div className="engineer-promo-list-item-column">
          <p className="b1-semi-bold">Name</p>
        </div>
        <div className="engineer-promo-list-item-column">
          <p className="b1-semi-bold">Discount</p>
        </div>
        {isDesktop && (
          <div className="engineer-promo-list-item-column">
            <p className="b1-semi-bold">Platform Promotion</p>
          </div>
        )}
        <div className="engineer-promo-list-item-column">
          <p className="b1-semi-bold">Share</p>
        </div>
        <div className="engineer-promo-list-item-column"></div>
      </div>
      <div className="engineer-promo-list-container scrollable">
        {!loading &&
          promoCodes.map((promoCode) => {
            return <PromoListItem key={promoCode.code} promoCode={promoCode} />;
          })}
        {loading && (
          <div className="no-promo-code-view">
            <DefaultSoundWaveLoader />
          </div>
        )}
        {!loading && !promoCodes.length && (
          <div className="no-promo-code-view">
            <div>No active promo codes</div>
          </div>
        )}
      </div>
    </div>
  );
};
