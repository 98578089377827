import { useAppSelector } from "../../../store/hooks";
import { FileCommentAddButton } from "./TrackTableComments.styles";
import { RegisterUnauthenticatedUserName } from "../RegisterUnauthenticatedUserName/RegisterUnauthenticatedUserName";
import { getTrackComments } from "../../../store/selectors/trackComments";

export interface TrackTableAddButtonProps {
  isLoading: boolean;
  isDisabled: boolean;
  handleCreate: () => void;
  handleUpdate: () => void;
  projectId: number;
}

export const TrackTableAddButton = ({
  isLoading,
  isDisabled,
  handleCreate,
  handleUpdate,
  projectId,
}: TrackTableAddButtonProps) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const unauthenticatedUserName = useAppSelector(
    (state) => state.unauthenticatedUserStateSlice.name,
  );
  const selectedRegionComment = useAppSelector(
    (state) => state.selectedComment,
  );
  const { page } = useAppSelector((state) => state.fileVersionCommentsSlice);
  const comments = useAppSelector(getTrackComments(page, projectId));
  const isAuthorOfSelectedComment =
    selectedRegionComment.isUpdating &&
    user &&
    comments.some(
      (comment) =>
        comment?.unique_css_identifier ===
          selectedRegionComment.commentIdentifier &&
        comment?.author_user?.id === user.id,
    );

  if (!user && !unauthenticatedUserName) {
    return (
      <RegisterUnauthenticatedUserName
        triggerComponent={
          <FileCommentAddButton disabled={isDisabled}>
            {selectedRegionComment.isUpdating && isAuthorOfSelectedComment
              ? "Update"
              : "Add"}
          </FileCommentAddButton>
        }
        postCommentAfterNameUpdate={handleCreate}
      />
    );
  } else {
    return (
      <FileCommentAddButton
        loading={isLoading}
        onClick={
          selectedRegionComment.isUpdating && isAuthorOfSelectedComment
            ? handleUpdate
            : handleCreate
        }
        disabled={isDisabled}
      >
        {selectedRegionComment.isUpdating && isAuthorOfSelectedComment
          ? "Update"
          : "Add"}
      </FileCommentAddButton>
    );
  }
};
