import { PopoverTrigger } from "@radix-ui/react-popover";
import { useCallback, useContext, useState } from "react";
import useModal from "../../../../../../hooks/useModal";
import {
  engMasteringTransition,
  engMixTransition,
} from "../../../../../../store/actions/projects";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  MasteringTransitions,
  MixingTransitions,
  ProjectType,
} from "../../../../../../store/models/project";
import { emitAnalyticsTrackingEvent } from "../../../../../../utils/analyticsUtils";
import { BasePopover } from "../../../../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../../../core-ui/components/BasePopover/PopoverContainerContext";
import { DropdownContext } from "../../../../../core-ui/components/Select/Select";
import { Text } from "../../../../../core-ui/components/Text/Text";
import { EditableTextArea } from "../../../../../elements/EditableTextArea/EditableTextArea";
import { DropdownMenuItem } from "../../../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";

interface ProjectActionRejectProjectFilesProps {
  projectId: number | null | undefined;
}

/**
 * Updated version of the `EngineerAcceptRejectFiles` component.
 * Updated version of the `EngineerRejectFileModal` component.
 */
export const ProjectActionRejectProjectFiles = ({
  projectId,
}: ProjectActionRejectProjectFilesProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const user = useAppSelector((state) => state.accountInfo.user);
  const project = useAppSelector(
    (state) => state.projectsMapStore.projects[projectId ?? -1] ?? null,
  );

  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();
  const dropdownContext = useContext(DropdownContext);
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );
  const projectType = project.service_type;

  const handleRejectProjectFiles = useCallback(async () => {
    emitAnalyticsTrackingEvent(
      "engineer_reject_files",
      { project_id: `${project.id}` },
      user?.id,
    );
    const transitionParams = {
      project_id: project.id.toString(),
      engineer_file_notes: comment,
    };
    setIsLoading(true);
    if (projectType === ProjectType.MASTERING) {
      await dispatch(
        engMasteringTransition({
          ...transitionParams,
          transition: MasteringTransitions.ENGINEER_REJECT_FILES,
        }),
      )
        .unwrap()
        .finally(() => {
          setIsLoading(false);
          dropdownContext?.closeDropdownMenu();
        });
    } else {
      await dispatch(
        engMixTransition({
          ...transitionParams,
          transition: MixingTransitions.ENGINEER_REJECT_FILES,
        }),
      )
        .unwrap()
        .finally(() => {
          setIsLoading(false);
          dropdownContext?.closeDropdownMenu();
        });
    }
  }, [comment, dispatch, project.id, projectType, user?.id, dropdownContext]);

  const popoverContent = () => (
    <div>
      <Text>Please provide a reason for rejecting these files</Text>
      <Text style={{ marginBottom: "12px" }}>
        What are you missing to be able to complete this project?
      </Text>
      <EditableTextArea
        placeholder="Note for artist (required)"
        initialValue={comment}
        editMode={true}
        onChange={setComment}
      />
    </div>
  );

  return (
    <BasePopover
      isOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      closePopover={closePopover}
      disableAutoClose
      side="top"
      title="Enter request notes"
      description=""
      okButtonProps={{ disabled: isLoading || !comment, loading: isLoading }}
      additionalContent={popoverContent()}
      onConfirm={handleRejectProjectFiles}
      wrapperElement={containerElement}
    >
      <PopoverTrigger asChild>
        <DropdownMenuItem onSelect={(e: Event) => e.preventDefault()}>
          Request reupload
        </DropdownMenuItem>
      </PopoverTrigger>
    </BasePopover>
  );
};
