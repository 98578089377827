import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { UPDATE_PROFILE } from "../../store/utils/routes";
import { updateProfileParams } from "../../store/actions/accountInfo";
import User from "../../store/models/user";
import { EngineEarsError, receiveErrors } from "../../store/actions/errorStore";
import { useAppDispatch } from "../../store/hooks";
import { assertEngineEarsErrorType } from "../../api/helpers";
import { useAtomValue } from "jotai";
import { tokenAtom } from "../../atoms/auth/tokenAtom";

interface UseUpdateUserProfileProps {
  userId?: number;
}

export const useUpdateUserProfile = ({
  userId,
}: UseUpdateUserProfileProps = {}) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const token = useAtomValue(tokenAtom);
  return useMutation({
    mutationKey: [QUERY_KEYS.UPDATE_USER_PROFILE],
    mutationFn: async (params: updateProfileParams) => {
      if (params.username) {
        params = {
          ...params,
          username: params.username?.toLowerCase(),
        };
      }
      if (params.email) {
        params = {
          ...params,
          email: params.email?.toLowerCase(),
        };
      }
      if (params.phone_number) {
        params = {
          ...params,
          phone_number: params.phone_number?.replace(/\s/g, "").trim(),
        };
      }

      const response = await makeBackendPostCallWithJsonResponse<User>(
        UPDATE_PROFILE,
        params,
      );
      if (response.success) {
        return response.resultJson;
      }

      const errors = { errors: response.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(response.resultJson);
    },
    onSuccess: async (user) => {
      if (assertEngineEarsErrorType(user)) {
        return Promise.reject(user);
      }
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.UPDATE_USER_PROFILE, userId],
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.LOAD_USER, token],
      });
      return user;
    },
    onError: (error: EngineEarsError) => {
      return { errors: error };
    },
  });
};
