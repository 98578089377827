import Pagination from "rc-pagination";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DevLinkProvider } from "../../../devlink/";
import { EngineEarsFooter2024 } from "../../../devlink/EngineEarsFooter2024";
import { useSearchQuery } from "../../../hooks/useSearchQuery";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { MapSearchTypeEnum } from "../../../store/actions/mapSearch";
import { updateStudioRoomSearchPage } from "../../../store/actions/studioRoomSearch";
import { updateUserSearchPage } from "../../../store/actions/userSearch";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getProfileScreenRoute,
  getStudioRoomScreenRoute,
} from "../../../store/utils/routeGetters";
import {
  getStudioRoomProfileCard,
  getUserProfileCard,
} from "../../components/ProfileCard/ProfileCard";
import { RequestStudio } from "../../components/RequestStudio/RequestStudio";
import { FullWidthHeader } from "../../elements/FullWidthHeader/FullWidthHeader";
import { MapSearchDisplay } from "../../elements/MapSearchDisplay/MapSearchDisplay";
import { ColorPalette } from "../../theme";
import {
  LoadingProfileCard,
  SearchLocationState,
} from "../ExploreScreen/ExploreScreen";
import "../ExploreScreen/ExploreScreen.css";
import { SearchScreen } from "../SearchScreen/SearchScreen";
import "./PaginatedVerifiedEntityScreen.css";
import { useAtomValue } from "jotai";
import { searchTermAtom } from "../../../atoms/searchQueryAtom";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

export const PaginatedVerifiedEntityScreen = () => {
  const { page, fetching, count } = useAppSelector((state) => state.userSearch);
  const searchTerm = useAtomValue(searchTermAtom);
  const history = useHistory();
  const location = useLocation<SearchLocationState>();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const paginatedEngineerUsers = useAppSelector(
    (state) => state.userSearch.paginatedUsers,
  );

  useSetPageTitle(undefined);

  const engineerUsers = paginatedEngineerUsers[page] || [];

  const navigateToPage = useCallback(
    (page: number) => {
      dispatch(updateUserSearchPage(page));
    },
    [dispatch],
  );
  const darkMode = useAtomValue(darkModeAtom);
  const [mapView, setMapView] = useState<boolean>(false);
  const updateSearchView = () => {
    history.replace({ ...location, state: { mapView: !mapView } });
    setMapView(!mapView);
  };

  useEffect(() => {
    if (location.state?.mapView) {
      setMapView(true);
    }
  }, [location.state]);

  return (
    <>
      <FullWidthHeader
        title=""
        color="blue"
        heading="Explore Profiles"
        subheading="Instantly book world class audio engineers"
        backgroundColor={
          darkMode && isAuthenticated
            ? ColorPalette.SmoothIvoryDark
            : ColorPalette.SmoothIvory
        }
      />
      <SearchScreen
        disableStudioSearch={true}
        disableUserSearch={false}
        mapView={mapView}
        updateSearchView={updateSearchView}
      >
        <div style={{ height: 15 }} />
        {!mapView && (
          <div className="container">
            <header
              style={{
                border: "none",
                padding: "0px",
              }}
              className="explore-header-container"
            >
              <h3
                className={"label"}
                style={{
                  color: "var(--medium-grey)",
                }}
              >
                {`${
                  engineerUsers.length > 0
                    ? searchTerm
                      ? `${count} Results for ${searchTerm}`
                      : `${count} Results`
                    : "Verified Engineers"
                }`}
              </h3>
            </header>
            <div className="verified-list-container">
              {fetching &&
                Array.from({ length: 12 }).map((_, index) => (
                  <LoadingProfileCard key={index} />
                ))}
              {!fetching &&
                Array.isArray(engineerUsers) &&
                engineerUsers.map((user, index) => {
                  const searchParams = new URLSearchParams(location.search);
                  const promoCode = searchParams.get("promocode");
                  const path = getProfileScreenRoute(
                    user.username ?? "",
                  ).concat(promoCode ? `?promocode=${promoCode}` : "");
                  return getUserProfileCard(user, index, path);
                })}
              {engineerUsers.length === 0 && !fetching && (
                <div className="no-results-container">
                  <p className="no-results-text">No results</p>
                </div>
              )}
            </div>
            <Pagination
              className={"m-4"}
              onChange={navigateToPage}
              current={page}
              total={count}
              pageSize={12}
            />
          </div>
        )}
        {mapView && <MapSearchDisplay searchType={MapSearchTypeEnum.USER} />}
        <DevLinkProvider>
          <EngineEarsFooter2024 />
        </DevLinkProvider>
      </SearchScreen>
    </>
  );
};

export const PaginatedVerifiedStudioRoomsScreen = () => {
  const history = useHistory();
  const location = useLocation<SearchLocationState>();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const {
    page,
    num_pages,
    fetching: loading,
    count,
  } = useAppSelector((state) => state.studioRoomSearch);
  const searchTerm = useAtomValue(searchTermAtom);
  const paginatedStudioRooms = useAppSelector(
    (state) => state.studioRoomSearch.paginatedStudioRooms,
  );
  const darkMode = useAtomValue(darkModeAtom);
  const studioRooms = paginatedStudioRooms[page] ?? [];
  const localStudiosPresent = useAppSelector(
    (state) => state.studioRoomSearch.localStudiosPresent,
  );
  const appliedPromoCode = useAppSelector(
    (state) => state.marketingDataStore.appliedPromoCode,
  );

  useSetPageTitle(undefined);

  const navigateToPage = useCallback(
    (page: number) => {
      dispatch(updateStudioRoomSearchPage(page));
    },
    [dispatch],
  );
  const [mapView, setMapView] = useState<boolean>(false);
  const updateSearchView = () => {
    history.replace({ ...location, state: { mapView: !mapView } });
    setMapView(!mapView);
  };

  useEffect(() => {
    if (location.state?.mapView) {
      setMapView(true);
    }
  }, [location.state]);

  return (
    <>
      <FullWidthHeader
        title=""
        color="blue"
        heading="Explore Studios"
        subheading="Instantly book world class recording studios"
        backgroundColor={
          darkMode && isAuthenticated
            ? ColorPalette.SmoothIvoryDark
            : ColorPalette.SmoothIvory
        }
      />
      <SearchScreen
        disableStudioSearch={false}
        disableUserSearch={true}
        updateSearchView={updateSearchView}
        mapView={mapView}
      >
        <div style={{ height: 15 }} />
        {!mapView && (
          <div className="container">
            <header
              style={{
                border: "none",
                padding: "0px",
              }}
              className="explore-header-container"
            >
              <h3
                className={"label"}
                style={{
                  color: "var(--medium-grey)",
                }}
              >
                {`${
                  num_pages > 1 && studioRooms.length > 0
                    ? searchTerm
                      ? `${count} Results for ${searchTerm}`
                      : `${count} Results`
                    : "Verified Recording Studios"
                }`}
              </h3>
            </header>
            {!loading && !localStudiosPresent && Boolean(searchTerm) && (
              <RequestStudio />
            )}
            <div className="verified-list-container">
              {loading &&
                Array.from({ length: 12 }).map((_, index) => (
                  <LoadingProfileCard key={index} />
                ))}
              {!loading &&
                Array.isArray(studioRooms) &&
                studioRooms.map((studioRoom, index) => {
                  const location = history.location;
                  const searchParams = new URLSearchParams(location.search);
                  const promoCode = searchParams.get("promocode");
                  const path = getStudioRoomScreenRoute(
                    studioRoom.studio?.username ?? "",
                    studioRoom.id,
                  ).concat(promoCode ? `?promocode=${promoCode}` : "");
                  return getStudioRoomProfileCard(
                    studioRoom,
                    index,
                    path,
                    appliedPromoCode,
                  );
                })}
              {studioRooms.length === 0 && !loading && (
                <div className="no-results-container">
                  <p className="no-results-text">No results</p>
                </div>
              )}
            </div>
            <Pagination
              className={"m-4"}
              onChange={navigateToPage}
              current={page}
              total={count}
              pageSize={12}
            />
            {!loading && localStudiosPresent && !searchTerm && (
              <RequestStudio />
            )}
          </div>
        )}
        {mapView && <MapSearchDisplay searchType={MapSearchTypeEnum.STUDIO} />}
        <DevLinkProvider>
          <EngineEarsFooter2024 />
        </DevLinkProvider>
      </SearchScreen>
    </>
  );
};
