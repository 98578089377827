import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isSameDay } from "date-fns";
import { OptionType } from "../../stories/elements/DropDownSelector/DropdownSelector";
import Engineer from "../models/engineer";
import { RecordingService } from "../models/recording";
import { RecordingSession } from "../models/recordingSession";

export interface PendingSessionData {
  duration?: number;

  // date + time
  selectedDateOption?: OptionType;
  trackingEngineer?: Engineer;

  // date string
  selectedISODate?: string;
  generalWorkingHourIntervalLabel?: string;
  hasNoTrackingEngineer?: boolean;
  modifiedByUser?: boolean;
  location?: google.maps.places.PlaceResult;
  // Date string that needs to be converted to Date OptionType if present.
  // Indicates a pending session as part of an unpaid Transaction.
  preselectedDateTime?: number;

  // These 2 properties are needed for the `Any tracking engineer` feature
  autoSelectedTrackingEngineer?: Engineer;
  selectAnyEngineer?: boolean;
}

export interface SetUpCartPayload {
  pendingSessionData?: PendingSessionData[];
}

interface ShoppingCartState {
  pendingSessionData?: PendingSessionData[];
  selectedIndex?: number;
  sessionData: RecordingSession[];
}

const initialState: ShoppingCartState = {
  sessionData: [],
};

export const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    clearCartState: (state) => {
      state.pendingSessionData = undefined;
      state.selectedIndex = undefined;
      state.sessionData = [];
    },
    resetSessionData: (state) => {
      state.pendingSessionData = [];
      state.selectedIndex = undefined;
      state.sessionData = [];
    },
    clearSessionAtIndex: (
      state,
      action: PayloadAction<{
        index: number;
        options?: {
          skipEngineer?: boolean;
          recordingService?: RecordingService | null;
        };
      }>,
    ) => {
      if (state.pendingSessionData) {
        state.pendingSessionData[action.payload.index] = {
          duration:
            action.payload.options?.recordingService
              ?.minimum_session_time_minutes,
          location: state?.pendingSessionData[action.payload.index]?.location,
          trackingEngineer: action.payload?.options?.skipEngineer
            ? state?.pendingSessionData[action.payload.index]?.trackingEngineer
            : undefined,
        };
      }
    },
    addPendingSession: (
      state,
      action: PayloadAction<{
        duration: number;
        generalWorkingHourIntervalLabel?: string;
        engineer?: Engineer;
        location?: google.maps.places.PlaceResult;
      }>,
    ) => {
      const { duration, generalWorkingHourIntervalLabel, engineer, location } =
        action.payload;

      const newPendingSessionData = [
        ...(state.pendingSessionData ?? []),
        {
          duration,
          generalWorkingHourIntervalLabel,
          trackingEngineer: engineer,
          location: location,
        },
      ];
      state.pendingSessionData = newPendingSessionData;
      state.selectedIndex = newPendingSessionData.length - 1;
    },
    setGeneralWorkingHourIntervalLabelAtIndex: (
      state,
      action: PayloadAction<{
        index: number;
        selectedDateOption?: OptionType;
        skipPrefill?: boolean;
      }>,
    ) => {
      if (!state.pendingSessionData) return;
      const { index, selectedDateOption, skipPrefill } = action.payload;
      const label = selectedDateOption?.label;

      state.pendingSessionData[index].generalWorkingHourIntervalLabel = label;
      if (!state.pendingSessionData[index].modifiedByUser && !skipPrefill) {
        state.pendingSessionData[index].modifiedByUser = true;
      }
      const sessionsWithoutDateOptionOrLabel = state.pendingSessionData.filter(
        (curr, currIndex) => {
          return (
            index !== currIndex &&
            curr.generalWorkingHourIntervalLabel === undefined &&
            curr.selectedDateOption === undefined
          );
        },
      );
      sessionsWithoutDateOptionOrLabel.forEach((session) => {
        session.generalWorkingHourIntervalLabel = label;
      });
      // If date has been selected, then update the Date object to match the time.
      const currentISODate = state.pendingSessionData[index].selectedISODate;
      if (currentISODate && selectedDateOption?.value) {
        const date = new Date(currentISODate);
        const newTime = new Date(selectedDateOption.value);
        date.setHours(newTime.getHours(), newTime.getMinutes());
        state.pendingSessionData[index].selectedISODate = date.toISOString();
        state.pendingSessionData[index].selectedDateOption = {
          ...selectedDateOption,
          value: date.getTime(),
        };
      }
    },
    removeSessionAtIndex: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      if (state.pendingSessionData) {
        state.pendingSessionData.splice(index, 1);
      }
    },
    setIsoDateAtIndex: (
      state,
      action: PayloadAction<{ index: number; isoDate: string }>,
    ) => {
      if (!state.pendingSessionData) return;
      const { index, isoDate } = action.payload;

      state.pendingSessionData[index].selectedISODate = isoDate;
      if (!state.pendingSessionData[index].modifiedByUser) {
        state.pendingSessionData[index].modifiedByUser = true;
      }
      const optionDate = state.pendingSessionData[index]?.selectedDateOption;
      const selectedTime = optionDate?.value && new Date(optionDate.value);
      const newDate = new Date(isoDate);
      // update datetime option if new day is selected and doesn't match the time.
      if (selectedTime && !isSameDay(selectedTime, newDate)) {
        newDate.setHours(selectedTime.getHours(), selectedTime.getMinutes());
        state.pendingSessionData[index].selectedDateOption = {
          ...optionDate,
          value: newDate.getTime(),
        };
      }
    },
    setSelectedIndex: (state, action: PayloadAction<number>) => {
      state.selectedIndex = action.payload;
    },
    setUpCart: (state, action: PayloadAction<SetUpCartPayload>) => {
      const { pendingSessionData } = action.payload;

      // If the pendingSessionData is not set, set it to the passed in value.
      if (!state.pendingSessionData) {
        state.pendingSessionData = pendingSessionData;
      }
      // If the pendingSessionData is set, and the passed in value is set, update the pendingSessionData.
      if (
        state.pendingSessionData &&
        pendingSessionData &&
        pendingSessionData.length > 0
      ) {
        state.pendingSessionData = pendingSessionData;
      }
      if (pendingSessionData) {
        state.selectedIndex = 0;
      }
    },
    updateDurationAtIndex: (
      state: ShoppingCartState,
      action: PayloadAction<{ index: number; duration: number }>,
    ) => {
      const { index, duration } = action.payload;
      if (duration && state.pendingSessionData) {
        if (!state.pendingSessionData[index].modifiedByUser) {
          state.pendingSessionData[index].modifiedByUser = true;
        }
        state.pendingSessionData[index].duration = duration;
      }
    },
    updateSelectedDateOptionAtIndex: (
      state: ShoppingCartState,
      action: PayloadAction<{
        index: number;
        selectedDateOption?: OptionType;
        skipPrefill?: boolean;
      }>,
    ) => {
      const { index, selectedDateOption } = action.payload;
      if (!state.pendingSessionData) {
        return;
      }
      state.pendingSessionData[index].preselectedDateTime = undefined;
      state.pendingSessionData[index].selectedDateOption = selectedDateOption;
      if (!selectedDateOption) {
        return;
      }
      state.pendingSessionData[index].selectedISODate = new Date(
        selectedDateOption?.value,
      ).toISOString();
      if (
        !action.payload.skipPrefill &&
        !state.pendingSessionData[index].modifiedByUser
      ) {
        state.pendingSessionData[index].modifiedByUser = true;
      }

      if (action.payload.skipPrefill) {
        return;
      }
      const sessionsWithoutDateOptionOrLabel = state.pendingSessionData.filter(
        (curr, currIndex) => {
          return (
            index !== currIndex &&
            curr.generalWorkingHourIntervalLabel === undefined &&
            curr.selectedDateOption === undefined
          );
        },
      );
      sessionsWithoutDateOptionOrLabel.forEach((session) => {
        session.generalWorkingHourIntervalLabel = selectedDateOption.label;
      });
    },
    updateEngineerAtIndex: (
      state: ShoppingCartState,
      action: PayloadAction<{
        index: number;
        engineer?: Engineer;
        autoSelectedTrackingEngineer?: Engineer;
        skipPrefill?: boolean;
        markNoTrackingEngineer?: boolean;
        markAnyTrackingEngineer?: boolean;
      }>,
    ) => {
      const {
        index,
        engineer,
        skipPrefill,
        autoSelectedTrackingEngineer,
        markAnyTrackingEngineer,
      } = action.payload;
      if (state.pendingSessionData) {
        if (action.payload.markNoTrackingEngineer) {
          state.pendingSessionData[index].hasNoTrackingEngineer = true;
          state.pendingSessionData[index].trackingEngineer = undefined;
          state.pendingSessionData[index].autoSelectedTrackingEngineer =
            undefined;
          state.pendingSessionData[index].selectAnyEngineer = false;
          // This updates the engineer that is automatically assigned if the user chooses `Any tracking engineer`
        } else if (autoSelectedTrackingEngineer) {
          state.pendingSessionData[index].autoSelectedTrackingEngineer =
            autoSelectedTrackingEngineer;
          state.pendingSessionData[index].hasNoTrackingEngineer = false;
          state.pendingSessionData[index].trackingEngineer = undefined;
          state.pendingSessionData[index].selectAnyEngineer = true;
          // When the user select the `Any tracking engineer` option, we mark `selectAnyEngineer` as true
          // So that in the useEffect, we automatically assign an available engineer to the session
        } else if (markAnyTrackingEngineer) {
          state.pendingSessionData[index].selectAnyEngineer = true;
          state.pendingSessionData[index].trackingEngineer = undefined;
          state.pendingSessionData[index].hasNoTrackingEngineer = false;
        } else {
          state.pendingSessionData[index].trackingEngineer = engineer;
          if (engineer) {
            state.pendingSessionData[index].hasNoTrackingEngineer = false;
          }
          state.pendingSessionData[index].autoSelectedTrackingEngineer =
            undefined;
          state.pendingSessionData[index].selectAnyEngineer = false;
        }
        if (!skipPrefill && !state.pendingSessionData[index].modifiedByUser) {
          state.pendingSessionData[index].modifiedByUser = true;
        }
        if (skipPrefill) {
          return;
        }
        const sessionsWithoutEngineer = state.pendingSessionData.filter(
          (curr, currIndex) => {
            return index !== currIndex && curr.trackingEngineer === undefined;
          },
        );
        sessionsWithoutEngineer.forEach((session) => {
          session.trackingEngineer = engineer;
        });
      }
    },
  },
});

export const {
  setUpCart,
  updateDurationAtIndex,
  updateSelectedDateOptionAtIndex,
  updateEngineerAtIndex,
  setSelectedIndex,
  removeSessionAtIndex,
  setIsoDateAtIndex,
  setGeneralWorkingHourIntervalLabelAtIndex,
  addPendingSession,
  clearSessionAtIndex,
  resetSessionData,
  clearCartState,
} = shoppingCartSlice.actions;

export default shoppingCartSlice.reducer;
