import React from "react";
import {
  ProjectType,
  projectTypeReadableName,
} from "../../../store/models/project";
import { ScheduledProject } from "../../../store/models/scheduledproject";
import { FirstPassDate } from "../ProjectBookingCalendar/ProjectBookingCalendar";
import "./ProjectBookingRescheduler.css";

export interface ProjectBookingReschedulerProps {
  scheduledProject: ScheduledProject;
  rescheduleDates: Map<string, FirstPassDate>;
  rescheduleMode: boolean;
  children?: React.ReactNode;
  selectionMap: Map<string, FirstPassDate>;
}

export const ProjectBookingRescheduler = ({
  scheduledProject,
  rescheduleDates,
  rescheduleMode,
  children,
  selectionMap,
}: ProjectBookingReschedulerProps) => {
  const firstProject = scheduledProject.projects[0];
  const projectTitle = scheduledProject.title;
  const songsList = scheduledProject?.projects.map((project) => project.title);
  const projectType = firstProject.service_type;

  const getFirstPassDate = (song: string) =>
    rescheduleMode || rescheduleDates.size === selectionMap.size
      ? rescheduleDates.get(song)?.date.toDateString()
      : selectionMap.get(song)?.date.toDateString();

  return (
    <div className="cart-container">
      <table className="project-alts-cart-table">
        <thead>
          <tr style={{ display: "flex" }}>
            <th style={{ width: "60%" }}>
              {projectTitle} ({projectTypeReadableName.get(projectType)})
            </th>
            <th style={{ flexGrow: "1" }}>
              {projectType === ProjectType.MASTERING
                ? "Booking Date"
                : "Booking Date"}
            </th>
          </tr>
        </thead>
        <tbody>
          {songsList.map((song, i) => (
            <tr style={{ display: "flex" }} key={i}>
              <td
                style={{ width: "50%", textAlign: "left" }}
                className={"song-line-item"}
              >
                {song}
              </td>
              <td style={{ flexGrow: "1" }}>{getFirstPassDate(song)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {children}
    </div>
  );
};
