import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { loadTrophies, loadTrophiesParams } from "../store/actions/trophies";
import {
  selectStudioTrophies,
  selectStudioRoomTrophies,
  selectUserTrophies,
} from "../store/selectors/trophySelector";

export const useFetchTrophies = ({
  userId,
  studioId,
  studioRoomId,
  // set to true if you'd like to force refetch trophy data
  refetchData = false,
}: loadTrophiesParams & {
  refetchData?: boolean;
}) => {
  const userTrophies = useAppSelector(selectUserTrophies(userId));
  const studioTrophies = useAppSelector(selectStudioTrophies(studioId));
  const studioRoomTrophies = useAppSelector(
    selectStudioRoomTrophies(studioRoomId),
  );
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userId && (!userTrophies || refetchData)) {
      setLoading(true);
      void dispatch(loadTrophies({ userId })).finally(() => setLoading(false));
    } else if (studioRoomId && (!studioRoomTrophies || refetchData)) {
      setLoading(true);
      void dispatch(loadTrophies({ studioRoomId })).finally(() =>
        setLoading(false),
      );
    } else if (studioId && (!studioTrophies || refetchData)) {
      setLoading(true);
      void dispatch(loadTrophies({ studioId })).finally(() =>
        setLoading(false),
      );
    }
  }, [
    userId,
    dispatch,
    studioId,
    studioRoomId,
    userTrophies,
    studioTrophies,
    studioRoomTrophies,
    refetchData,
  ]);

  return {
    loading,
    userTrophies,
    studioTrophies,
    studioRoomTrophies,
  };
};
