import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { getSessionUserRoles } from "../../store/actions/recording";
import { useAppDispatch } from "../../store/hooks";

interface UseGetSessionUserRolesParams {
  sessionId: number | null;
}

export const useGetSessionUserRoles = ({
  sessionId,
}: UseGetSessionUserRolesParams) => {
  const dispatch = useAppDispatch();
  return useQuery({
    queryKey: [QUERY_KEYS.GET_SESSION_USER_ROLES, sessionId],
    queryFn: async () => {
      if (!sessionId) return null;
      return dispatch(getSessionUserRoles({ sessionId })).unwrap();
    },
    enabled: Boolean(sessionId),
  });
};
