import communityManagement from "../../stories/assets/community-management.svg";
import credits from "../../stories/assets/credits.svg";
import memberships from "../../stories/assets/memberships.svg";
import projects from "../../stories/assets/projects.svg";

export enum CUSTOMER_PORTAL_SCREENS {
  MEMBERSHIPS,
  PROJECTS,
  CREDITS,
  COMMUNITY_MANAGEMENT,
}

export interface CUSTOMER_PORTAL_SCREEN_DETAILS {
  title: string;
  endpoint: string;
  icon: string;
}

export const getAppsList = new Map<
  CUSTOMER_PORTAL_SCREENS,
  CUSTOMER_PORTAL_SCREEN_DETAILS
>([
  [
    CUSTOMER_PORTAL_SCREENS.MEMBERSHIPS,
    {
      title: "Memberships",
      endpoint: "memberships",
      icon: memberships,
    },
  ],
  [
    CUSTOMER_PORTAL_SCREENS.PROJECTS,
    {
      title: "Projects",
      endpoint: "projects",
      icon: projects,
    },
  ],
  [
    CUSTOMER_PORTAL_SCREENS.CREDITS,
    {
      title: "Credits",
      endpoint: "credits",
      icon: credits,
    },
  ],
  [
    CUSTOMER_PORTAL_SCREENS.COMMUNITY_MANAGEMENT,
    {
      title: "Community Management",
      endpoint: "community-management",
      icon: communityManagement,
    },
  ],
]);
