import {
  faFileArrowDown,
  faFileZipper,
} from "@fortawesome/pro-regular-svg-icons";
import { faWaveform } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { downloadTrack } from "../../../../store/actions/audioService";
import { useAppDispatch } from "../../../../store/hooks";
import {
  FileVersion,
  ZIP_UPLOAD_TYPE,
} from "../../../../store/models/fileVersion";
import { downloadFile } from "../../../../store/utils/utils";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { Text, TEXT_SIZE } from "../../../core-ui/components/Text/Text";
import {
  FileVersionButtonContainer,
  FileVersionRowContainer,
} from "./FileVersionRow.styles";

export interface FileVersionRowProps {
  fileVersion: FileVersion;
  disabled: boolean;
  disabledText?: string;
  code?: string;
}

export const FileVersionRow = ({
  fileVersion,
  code,
  disabled,
  disabledText,
}: FileVersionRowProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const handleDownload = () => {
    void dispatch(
      downloadTrack({
        fileVersionId: fileVersion.id,
        code,
      }),
    )
      .unwrap()
      .then((response) => {
        downloadFile(response, fileVersion?.file_name ?? "no file name");
      })
      .catch(() => {
        toast.error("Failed to download file");
      });
  };

  return (
    <FileVersionRowContainer>
      <Text
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          width: "100%",
          display: "inline-block",
        }}
        size={TEXT_SIZE.MEDIUM}
      >
        {fileVersion?.file_name ?? "No Name"}
      </Text>
      <FileVersionButtonContainer>
        <Button
          disabled={disabled}
          disableText={disabledText}
          onClick={handleDownload}
          variant={ButtonVariant.UNSTYLED}
        >
          <FontAwesomeIcon width={32} icon={faFileArrowDown} />
        </Button>
        <FontAwesomeIcon
          height={32}
          width={32}
          color={theme.textPrimaryColor}
          icon={
            fileVersion.upload_type === ZIP_UPLOAD_TYPE
              ? faFileZipper
              : faWaveform
          }
        />
      </FileVersionButtonContainer>
    </FileVersionRowContainer>
  );
};
