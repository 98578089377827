import { useMutation } from "@tanstack/react-query";
import { displayEngineEarsError } from "../../api/helpers";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError } from "../../store/actions/errorStore";
import { createRecordingServiceParams } from "../../store/actions/recording";
import { RecordingService } from "../../store/models/recording";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { RECORDING_SERVICE_API } from "../../store/utils/routes";

export const useCreateRecordingService = () => {
  return useMutation({
    mutationKey: [QUERY_KEYS.CREATE_RECORDING_SERVICE],
    mutationFn: async (args: createRecordingServiceParams) => {
      const result =
        await makeBackendPostCallWithJsonResponse<RecordingService>(
          RECORDING_SERVICE_API,
          args,
        );
      if (result.success) {
        return result.resultJson;
      }

      throw result.resultJson;
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      return error;
    },
  });
};
