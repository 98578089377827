import verified from "../../stories/assets/badgeIcons/verified.svg";
import dolby from "../../stories/assets/badgeIcons/dolby.svg";
import booked from "../../stories/assets/badgeIcons/booked.svg";
import may22mixathonFirstPlace from "../../stories/assets/badgeIcons/Mix-a-thon-Badges-May-2022-1st-Place.svg";
import may22mixathonSecondPlace from "../../stories/assets/badgeIcons/Mix-a-thon-Badges-May-2022-2nd-Place.svg";
import may22mixathonThirdPlace from "../../stories/assets/badgeIcons/Mix-a-thon-Badges-May-2022-3rd-Place.svg";
import may22mixathonWinner from "../../stories/assets/badgeIcons/Mix-a-thon-Badges-May-2022-Winning-Team.svg";
import sept22mixathonFirstPlace from "../../stories/assets/badgeIcons/Mix-a-thon-Badges-Sept-2022-1st-Place.svg";
import sept22mixathonSecondPlace from "../../stories/assets/badgeIcons/Mix-a-thon-Badges-Sept-2022-2nd-Place.svg";
import sept22mixathonThirdPlace from "../../stories/assets/badgeIcons/Mix-a-thon-Badges-Sept-2022-3rd-Place.svg";
import sept22mixathonWinner from "../../stories/assets/badgeIcons/Mix-a-thon-Badges-Sept-2022-Winning-Team.svg";
import twenty_five_projects_completed from "../../stories/assets/badgeIcons/Milestone Badges - 25.svg";
import fifty_projects_completed from "../../stories/assets/badgeIcons/Milestone Badges - 50.svg";
import one_hundred_projects_completed from "../../stories/assets/badgeIcons/Milestone Badges - 100.svg";
import two_hundred_fifty_projects_completed from "../../stories/assets/badgeIcons/Milestone Badges - 250.svg";
import five_hundred_projects_completed from "../../stories/assets/badgeIcons/Milestone Badges - 500.svg";
import one_thousand_projects_completed from "../../stories/assets/badgeIcons/Milestone Badges - 1000.svg";
import umg_classics from "../../stories/assets/umg-classics.svg";
import capitol_records from "../../stories/assets/CapitolRecords.svg";
import ccmg from "../../stories/assets/CCMG.svg";
import centricity from "../../stories/assets/centricity-black.svg";
import defJam from "../../stories/assets/DefJam.svg";
import gaither from "../../stories/assets/Gaither.svg";
import iga from "../../stories/assets/IGA.svg";
import island from "../../stories/assets/Island.svg";
import motown from "../../stories/assets/Motown.svg";
import republic from "../../stories/assets/RepublicRecords.svg";
import ume from "../../stories/assets/UMe.svg";
import nashville from "../../stories/assets/UMG_Nashville.svg";
import umle from "../../stories/assets/UMLE_Black.svg";
import verve from "../../stories/assets/Verve.svg";
import virginBlack from "../../stories/assets/Virgin_Black.svg";
import deccaRecords from "../../stories/assets/Decca_Records.svg";
import polydor from "../../stories/assets/POLYDOR_LOGO.svg";
import emiRecords from "../../stories/assets/EMI_Records_logo.svg";
import defJam0207 from "../../stories/assets/0207_def_jam.svg";
import islandRecordsUK from "../../stories/assets/island_records_uk.svg";
import fictionRecords from "../../stories/assets/fiction_records.svg";
import umg_logo from "../../stories/assets/UMG Logo_Black.svg";
import n_sessions_booked from "../../stories/assets/badgeIcons/n_sessions_booked.svg";
import trending_badge from "../../stories/assets/badgeIcons/trending_badge.svg";

export interface Trophy {
  badgeEnum: BadgeEnum;
  text: string;
  textStatus?: string;
  info?: string;
  progress?: number;
  publicProfileTitle?: string;
  isCompleted?: boolean;
}

export enum BadgeEnum {
  NO_AWARD_TYPE,
  VERIFIED,
  DOLBY_ATMOS_CERTIFIED,
  N_SONGS_COMPLETED,
  MAY_22_MIXATHON_FIRST_PLACE,
  MAY_22_MIXATHON_SECOND_PLACE,
  MAY_22_MIXATHON_THIRD_PLACE,
  MAY_22_MIXATHON_WINNER,
  SEPT_22_MIXATHON_FIRST_PLACE,
  SEPT_22_MIXATHON_SECOND_PLACE,
  SEPT_22_MIXATHON_THIRD_PLACE,
  SEPT_22_MIXATHON_WINNER,
  TWENTY_FIVE_PROJECTS_COMPLETED,
  FIFTY_PROJECTS_COMPLETED,
  ONE_HUNDRED_PROJECTS_COMPLETED,
  TWO_HUNDRED_FIFTY_PROJECTS_COMPLETED,
  FIVE_HUNDRED_PROJECTS_COMPLETED,
  ONE_THOUSAND_PROJECTS_COMPLETED,
  DOLBY_ENABLED,
  UMG_UNKNOWN,
  CAPITOL,
  CCMG,
  CENTRICITY,
  CLASSICS,
  DEF_JAM,
  GAITHER,
  IGA,
  ISLAND,
  MOTOWN,
  NASHVILLE,
  REPUBLIC,
  UME,
  UMLE,
  VERVE,
  VIRGIN,
  N_SESSIONS_BOOKED,
  TRENDING,
  DECCA,
  POLYDOR,
  EMI,
  UNIVERSAL_MUSIC_RECORDINGS,
  DEF_JAM_0207,
  ISLAND_RECORDS_UK,
  FICTION_RECORDS,
}

export const FullWidthBadges: BadgeEnum[] = [
  BadgeEnum.MAY_22_MIXATHON_FIRST_PLACE,
  BadgeEnum.MAY_22_MIXATHON_SECOND_PLACE,
  BadgeEnum.MAY_22_MIXATHON_THIRD_PLACE,
  BadgeEnum.MAY_22_MIXATHON_WINNER,
  BadgeEnum.SEPT_22_MIXATHON_FIRST_PLACE,
  BadgeEnum.SEPT_22_MIXATHON_SECOND_PLACE,
  BadgeEnum.SEPT_22_MIXATHON_THIRD_PLACE,
  BadgeEnum.SEPT_22_MIXATHON_WINNER,
];

export const badgeListMap = [
  "",
  verified,
  dolby,
  booked,
  may22mixathonFirstPlace,
  may22mixathonSecondPlace,
  may22mixathonThirdPlace,
  may22mixathonWinner,
  sept22mixathonFirstPlace,
  sept22mixathonSecondPlace,
  sept22mixathonThirdPlace,
  sept22mixathonWinner,
  twenty_five_projects_completed,
  fifty_projects_completed,
  one_hundred_projects_completed,
  two_hundred_fifty_projects_completed,
  five_hundred_projects_completed,
  one_thousand_projects_completed,
  dolby,
  umg_logo,
  capitol_records,
  ccmg,
  centricity,
  umg_classics,
  defJam,
  gaither,
  iga,
  island,
  motown,
  nashville,
  republic,
  ume,
  umle,
  verve,
  virginBlack,
  n_sessions_booked,
  trending_badge,
  deccaRecords,
  polydor,
  emiRecords,
  umg_logo,
  defJam0207,
  islandRecordsUK,
  fictionRecords,
];

export enum MajorLabelEnum {
  UNAFFILIATED,
  UMG,
}

export const MAJOR_LABEL_LIST = ["", ""];

export enum UMGSubLabelEnum {
  UMG_UNKNOWN,
  CAPITOL,
  CCMG,
  CENTRICITY,
  CLASSICS,
  DEF_JAM,
  GAITHER,
  IGA,
  ISLAND,
  MOTOWN,
  NASHVILLE,
  REPUBLIC,
  UME,
  UMLE,
  VERVE,
  VIRGIN,
  DECCA,
  POLYDOR,
  EMI,
  UNIVERSAL_MUSIC_RECORDINGS,
  DEF_JAM_0207,
  ISLAND_RECORDS_UK,
  FICTION_RECORDS,
}

export const UMGSubLabelList = [
  UMGSubLabelEnum.UMG_UNKNOWN,
  UMGSubLabelEnum.REPUBLIC,
  UMGSubLabelEnum.DEF_JAM,
  UMGSubLabelEnum.ISLAND,
  UMGSubLabelEnum.VERVE,
  UMGSubLabelEnum.CAPITOL,
  UMGSubLabelEnum.CCMG,
  UMGSubLabelEnum.CENTRICITY,
  UMGSubLabelEnum.CLASSICS,
  UMGSubLabelEnum.GAITHER,
  UMGSubLabelEnum.IGA,
  UMGSubLabelEnum.MOTOWN,
  UMGSubLabelEnum.NASHVILLE,
  UMGSubLabelEnum.UME,
  UMGSubLabelEnum.UMLE,
  UMGSubLabelEnum.VIRGIN,
  UMGSubLabelEnum.DECCA,
  UMGSubLabelEnum.POLYDOR,
  UMGSubLabelEnum.EMI,
  UMGSubLabelEnum.UNIVERSAL_MUSIC_RECORDINGS,
  UMGSubLabelEnum.DEF_JAM_0207,
  UMGSubLabelEnum.ISLAND_RECORDS_UK,
  UMGSubLabelEnum.FICTION_RECORDS,
];
interface SubLabel {
  source: string;
  text: string;
}

export const UMGSubLabelMap = new Map<UMGSubLabelEnum, SubLabel>([
  [UMGSubLabelEnum.UMG_UNKNOWN, { source: umg_logo, text: "Not Listed" }],
  [UMGSubLabelEnum.CAPITOL, { source: capitol_records, text: "Capitol" }],
  [UMGSubLabelEnum.CCMG, { source: ccmg, text: "CCMG" }],
  [UMGSubLabelEnum.CENTRICITY, { source: centricity, text: "Centricity" }],
  [UMGSubLabelEnum.CLASSICS, { source: umg_classics, text: "Classics" }],
  [UMGSubLabelEnum.DEF_JAM, { source: defJam, text: "Def Jam Recordings" }],
  [UMGSubLabelEnum.GAITHER, { source: gaither, text: "Gaither" }],
  [UMGSubLabelEnum.IGA, { source: iga, text: "IGA" }],
  [UMGSubLabelEnum.ISLAND, { source: island, text: "Island Records" }],
  [UMGSubLabelEnum.MOTOWN, { source: motown, text: "Motown" }],
  [UMGSubLabelEnum.NASHVILLE, { source: nashville, text: "Nashville" }],
  [UMGSubLabelEnum.REPUBLIC, { source: republic, text: "Republic" }],
  [UMGSubLabelEnum.UME, { source: ume, text: "UMe" }],
  [UMGSubLabelEnum.UMLE, { source: umle, text: "UMLE" }],
  [UMGSubLabelEnum.VERVE, { source: verve, text: "Verve" }],
  [UMGSubLabelEnum.VIRGIN, { source: virginBlack, text: "Virgin Music" }],
  [UMGSubLabelEnum.DECCA, { source: deccaRecords, text: "Decca Records" }],
  [UMGSubLabelEnum.POLYDOR, { source: polydor, text: "Polydor Records" }],
  [UMGSubLabelEnum.EMI, { source: emiRecords, text: "EMI Records" }],
  [
    UMGSubLabelEnum.UNIVERSAL_MUSIC_RECORDINGS,
    { source: umg_logo, text: "Universal Music Recordings" },
  ],
  [UMGSubLabelEnum.DEF_JAM_0207, { source: defJam0207, text: "0207 Def Jam" }],
  [
    UMGSubLabelEnum.ISLAND_RECORDS_UK,
    { source: islandRecordsUK, text: "Island Records UK" },
  ],
  [
    UMGSubLabelEnum.FICTION_RECORDS,
    { source: fictionRecords, text: "Fiction Records" },
  ],
]);

export const MajorLabelToSubLabelEnumMap = [[], UMGSubLabelList];

export const getImageSourceAndText = (
  option: UMGSubLabelEnum,
  majorLabel: MajorLabelEnum,
) => {
  if (majorLabel === MajorLabelEnum.UMG) {
    const value = UMGSubLabelMap.get(option);
    if (!value) {
      return {
        source: "",
        text: "",
      };
    }
    return {
      source: value.source,
      text: value.text,
    };
  }

  return {
    source: "",
    text: "",
  };
};
