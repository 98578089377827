import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { ErrorsAction } from "../../store/actions/errorStore";
import {
  engineerServiceCrud,
  ServiceOperations,
} from "../../store/actions/services";
import { useAppDispatch } from "../../store/hooks";
import Service, { APIService } from "../../store/models/service";

interface mutateFunctionProps {
  updatedService: Service;
  operation: ServiceOperations;
}

type useEngineerServiceCrudProps = Omit<
  UseMutationOptions<APIService, ErrorsAction, mutateFunctionProps>,
  "mutationFn"
>;

export const useEngineerServiceCrud = ({
  ...extraProps
}: useEngineerServiceCrudProps) => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: async ({ updatedService, operation }: mutateFunctionProps) => {
      return dispatch(
        engineerServiceCrud({
          ...updatedService,
          operation: operation,
        }),
      ).unwrap();
    },
    ...extraProps,
  });
};
