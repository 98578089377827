const getMonthsString = (months: number) => {
  if (months < 1) {
    return "< 1 month on EngineEars";
  }
  if (months < 2) {
    return "1 month on EngineEars";
  }
  return `${months} months on EngineEars`;
};

const getYearString = (months: number) => {
  if (months < 18) {
    return "1 year on EngineEars";
  }
  const years = months / 12;
  const roundedYears = (Math.round(years * 2) / 2).toFixed(1);
  return `${Number.parseFloat(roundedYears)} years on EngineEars`;
};

export const getUserTimeOnPlatform = (dateString: string) => {
  const joinDate = new Date(dateString);
  const currentDate = new Date();

  const months =
    (currentDate.getFullYear() - joinDate.getFullYear()) * 12 +
    (currentDate.getMonth() - joinDate.getMonth());

  if (months < 12) {
    if (months < 1) {
      return "< 1 month on EngineEars";
    } else {
      return getMonthsString(months);
    }
  }
  return getYearString(months);
};
