import { PayPalScriptOptions } from "@paypal/paypal-js/types/script-options";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { loadStripe } from "@stripe/stripe-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "stream-chat-react/dist/css/index.css";
import { ThemeProvider as StyledComponentThemeProvider } from "styled-components";
import "./App.css";
import { darkModeAtom } from "./atoms/user/darkModeAtom";
import store from "./store";
import {
  DEVELOPMENT_STRIPE,
  getPaypalClientId,
  GOOGLE_OAUTH_CLIENT_ID,
  isProd,
  PRODUCTION_STRIPE,
} from "./store/utils";
import AppState from "./stories/components/AppState/AppState";
import LoadingScreen from "./stories/components/LoadingScreen/LoadingScreen";
import { PopoverContainerContextProvider } from "./stories/core-ui/components/BasePopover/PopoverContainerContext";
import { ThemeDiv } from "./stories/screens/ThemeDiv/ThemeDiv";
import { darkTheme, GlobalStyles, lightTheme } from "./stories/theme";
import { MuiThemeProvider } from "./styles/muiTheme/MuiTheme";
import "./styles/stream-chat-react.css";

const paypalScriptOptions: PayPalScriptOptions = {
  "client-id": getPaypalClientId,
};

const queryClient = new QueryClient();
const persistor = persistStore(store);

const App = () => {
  const darkMode = useAtomValue(darkModeAtom);

  useEffect(() => {
    loadStripe(isProd ? PRODUCTION_STRIPE : DEVELOPMENT_STRIPE, {
      betas: ["klarna_pm_beta_1"],
    })
      .then(() => {})
      .catch(() => {});
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider darkMode={Boolean(darkMode)}>
        <StyledComponentThemeProvider theme={darkMode ? darkTheme : lightTheme}>
          <GlobalStyles theme={darkMode ? darkTheme : lightTheme} />
          <ThemeDiv darkMode={darkMode ? "dark" : ""}>
            <PopoverContainerContextProvider>
              <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                <PayPalScriptProvider options={paypalScriptOptions}>
                  <PersistGate
                    loading={<LoadingScreen />}
                    persistor={persistor}
                  >
                    <ToastContainer
                      position="top-center"
                      autoClose={5000}
                      closeOnClick
                      hideProgressBar={false}
                      pauseOnHover
                    />
                    <AppState />
                    <ReactQueryDevtools initialIsOpen={false} />
                  </PersistGate>
                </PayPalScriptProvider>
              </GoogleOAuthProvider>
            </PopoverContainerContextProvider>
          </ThemeDiv>
        </StyledComponentThemeProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
