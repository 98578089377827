import { ManagePaymentsSettings } from "../../ManagePaymentsSettings/ManagePaymentsSettings";
import { ManageSubscriptionCard } from "../../ManageSubscriptionCard/ManageSubscriptionCard";
import { PaymentAndBillingContainer } from "../AccountSettingsModal.styles";

interface PaymentAndBillingProps {
  onClose: () => void;
}

export const PaymentAndBilling = ({ onClose }: PaymentAndBillingProps) => {
  return (
    <PaymentAndBillingContainer>
      <ManagePaymentsSettings />
      <ManageSubscriptionCard onClose={onClose} />
    </PaymentAndBillingContainer>
  );
};
