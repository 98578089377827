import { styled, Box } from "@mui/material";

export const FeaturedTrackColumnContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  gap: theme.spacing(3),
  backgroundColor: "transparent",
}));

export const FeaturedTrackEditContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0 22px",
  height: "88px",
  alignSelf: "stretch",
  borderRadius: theme.border.radius.md,
  backgroundColor: theme.palette.customColor.midgroundColor,
}));

export const FeaturedTrackEmptyContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "294px",
  alignSelf: "stretch",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.customColor.midgroundColor,
  gap: theme.spacing(4),
}));
