import styled from "styled-components";

export const EntityPhotosScreenHeader = styled.div<{
  $navHeight: number;
  $empty?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: ${({ $empty }) => ($empty ? "flex-start" : "center")};
  justify-content: space-between;
  height: ${({ $navHeight, $empty }) =>
    `${$empty ? "100%" : `${$navHeight}px`}`};
  position: sticky;
`;

export const EntityPhotosScreenContent = styled.div<{ $navHeight: number }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ $navHeight }) => $navHeight}px;
`;
