import { KeyboardEventHandler, useEffect, useState } from "react";
import "./EditableTextField.css";
import { useParams } from "react-router-dom";

export interface EditableTextFieldProps {
  initialValue: string;
  label: string;
  onChange: (newVal: string) => void;
  editMode: boolean;
  className?: string;
  isSecureText?: boolean;
  onKeyPressed?: KeyboardEventHandler<HTMLInputElement>;
  filter?: (val: string) => string;
}

export const EditableTextField = ({
  editMode,
  onChange,
  label,
  initialValue,
  className = "",
  isSecureText = false,
  onKeyPressed = () => {},
  filter,
}: EditableTextFieldProps) => {
  const [value, setValue] = useState<string>(initialValue);
  const { username } = useParams<{ username: string | undefined }>();

  useEffect(() => {
    setValue(initialValue);
  }, [username, initialValue]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <input
      onKeyDown={onKeyPressed}
      className={className.concat(" editable-text-field ")}
      type={isSecureText ? "password" : "text"}
      autoComplete="off"
      value={value}
      placeholder={label}
      id={label}
      disabled={!editMode}
      onChange={(e) => {
        const text = e.target.value;
        if (filter) {
          setValue(filter(text));
        } else {
          setValue(text);
        }
      }}
    />
  );
};
