import Pagination from "rc-pagination";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useMediaQuery,
  useMediaQueryBreakpoint,
} from "../../../hooks/useMediaQuery";
import {
  getAllTransactions,
  setTransactionsPage,
} from "../../../store/actions/transactions";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  Transaction,
  TransactionStatus,
  TransactionType,
  TransactionTypeOptions,
} from "../../../store/models/transaction";
import { TransactionRow } from "../TransactionRow/TransactionRow";
import DatePicker from "react-datepicker";
import "./TransactionsTable.css";
import { OrderArrows } from "../../elements/OrderArrows/OrderArrows";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { addDays, format, isSameDay } from "date-fns";
import debounce from "lodash.debounce";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import {
  convertLocalDateToUTCDate,
  convertUTCDateToLocalDate,
} from "../../../store/utils/dateTimeUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { EditableTextField } from "../../elements/EditableTextField/EditableTextField";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  isEngineerOnboardedSelector,
  isOnboardedEngineerOrStudioManagerSelector,
} from "../../../store/selectors/userInfoSelectors";

interface TransactionsTableProps {
  startDate?: Date;
  endDate?: Date;
  setStartDate?: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate?: React.Dispatch<React.SetStateAction<Date>>;
}

const currentDate = new Date();
const TransactionsTable = ({
  startDate: initialStartDate,
  endDate: initialEndDate,
  setStartDate: initialSetStartDate,
  setEndDate: initialSetEndDate,
}: TransactionsTableProps) => {
  const currentLoggedInUser = useAppSelector((state) => state.accountInfo.user);
  const onboardedEngineerOrStudioManager = useAppSelector(
    isOnboardedEngineerOrStudioManagerSelector,
  );
  const onboarded = useAppSelector(isEngineerOnboardedSelector);
  const isOnboarded = onboarded && onboardedEngineerOrStudioManager;
  const localSearchQuery = useRef("");
  const { currentPage, totalPages, count, isLoading } = useAppSelector(
    (state) => state.transactionStore,
  );
  const transactions = useAppSelector(
    (state) => state.transactionStore.transactions,
  );
  const dateJoined = useAppSelector((state) =>
    state.selectedProfileSlice?.user?.date_joined
      ? convertUTCDateToLocalDate(
          new Date(state.selectedProfileSlice?.user?.date_joined),
        )
      : state.selectedProfileSlice?.studio?.created
        ? convertUTCDateToLocalDate(
            new Date(state.selectedProfileSlice?.studio?.created),
          )
        : new Date(),
  );
  const minStartDate = useMemo(() => {
    return new Date(dateJoined!);
  }, [dateJoined]);
  const dispatch = useAppDispatch();
  const { isDesktop } = useMediaQueryBreakpoint();
  const [localOrderBy, setLocalOrderBy] = useState<string>("-created");
  const [startDate, setStartDate] = useState<Date>(
    initialStartDate || minStartDate,
  );
  const [endDate, setEndDate] = useState<Date>(initialEndDate || currentDate);
  const handleSetStartDate = initialSetStartDate || setStartDate;
  const handleSetEndDate = initialSetEndDate || setEndDate;
  const DEFAULT_PAGE_SIZE = 12;
  const [searchTerm, setSearchTerm] = useState("");
  const [transactionType, setTransactionType] = useState<OptionType>({
    label: "All",
    value: TransactionType.UNKNOWN_TRANSACTION_TYPE,
  });
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const isLaptop = useMediaQuery("(max-width: 1024px)");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchTerm(localSearchQuery.current);
    }, 3000);
    return () => clearTimeout(delayDebounceFn);
  }, [localSearchQuery]);

  const changeHandler = (e: string) => {
    setSearchTerm(e);
  };

  useEffect(() => {
    if (isSameDay(minStartDate, endDate)) {
      handleSetEndDate(addDays(endDate, 1));
    }
  }, [minStartDate, endDate]);

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 3000),
    [],
  );

  const startDateChangeHandler = (e: Date | null) => {
    if (!e) {
      handleSetStartDate(minStartDate);
      return;
    }
    handleSetStartDate(e);
  };

  const endDateChangeHandler = (e: Date | null) => {
    if (!e) {
      handleSetEndDate(currentDate);
      return;
    }
    handleSetEndDate(e);
  };

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  useEffect(() => {
    if (initialStartDate) {
      setStartDate(initialStartDate);
    }
    if (initialEndDate) {
      setEndDate(initialEndDate);
    }
  }, [initialStartDate, initialEndDate]);

  const getTransactions = useCallback(
    (page: number, downloadCsv: boolean) => {
      if (!currentLoggedInUser) return;
      const params = {
        transactionStatus: TransactionStatus.PAID,
        page: page,
        orderBy: localOrderBy,
        transactionType: transactionType.value,
        startDate: format(convertLocalDateToUTCDate(startDate), "yyyy-MM-dd"),
        endDate: format(convertLocalDateToUTCDate(endDate), "yyyy-MM-dd"),
        searchQuery: searchTerm,
        downloadCsv: downloadCsv,
        studioId: selectedProfile.studio
          ? selectedProfile.studio.id
          : undefined,
      };
      dispatch(getAllTransactions({ ...params }));
    },
    [
      currentLoggedInUser,
      currentPage,
      localOrderBy,
      transactionType,
      searchTerm,
      startDate,
      endDate,
      selectedProfile,
    ],
  );

  useEffect(() => {
    getTransactions(1, false);
  }, [localOrderBy, transactionType, searchTerm, startDate, endDate]);

  return (
    <div className="transactions-table">
      <Button
        className="download-csv-text label-semi-bold"
        onClick={() => {
          // Note that Page is no-op here because we download all transactions that match the filter.
          getTransactions(1, true);
        }}
        variant={ButtonVariant.OUTLINED}
      >
        {isDesktop && (
          <FontAwesomeIcon
            icon={faFileArrowDown}
            className="csv-download-icon"
          />
        )}
        Download CSV
      </Button>
      <table className="transactions-table">
        <tr className="table-headers">
          <th className="filter-header b2-semi-bold">
            <DropdownSelector
              value={transactionType}
              onChange={(type) => setTransactionType(type)}
              options={TransactionTypeOptions}
              isDisabled={isLoading}
            />
          </th>
          {isDesktop && (
            <th className="filter-header b2-semi-bold">
              <DatePicker
                dateFormat={"yyyy-MM-dd"}
                showYearDropdown
                showMonthDropdown
                onChange={(date: Date) => startDateChangeHandler(date)}
                placeholderText="Enter start date"
                disabled={isLoading}
                selected={startDate}
                withPortal={true}
                disabledKeyboardNavigation={true}
                dropdownMode="select"
                minDate={minStartDate}
                maxDate={endDate}
                className="date-picker b2-semi-bold"
              />
              to
              <DatePicker
                dateFormat={"yyyy-MM-dd"}
                showYearDropdown
                showMonthDropdown
                onChange={(date: Date) => endDateChangeHandler(date)}
                disabled={isLoading}
                selected={endDate}
                placeholderText="Enter end date"
                disabledKeyboardNavigation={true}
                withPortal={true}
                maxDate={currentDate}
                dropdownMode="select"
                minDate={startDate}
                className="date-picker b2-semi-bold"
              />
            </th>
          )}
          <th className="filter-header">
            <EditableTextField
              initialValue={""}
              label={"Search by Name"}
              onChange={debouncedChangeHandler}
              className="search-field b2-semi-bold"
              editMode={!isLoading}
            />
          </th>
        </tr>
        <tr className="table-headers-sort-options">
          <th className="b2-semi-bold">
            Date
            <OrderArrows
              colHeader="created"
              orderBy={localOrderBy}
              onClick={setLocalOrderBy}
            />
          </th>
          <th className="b2-semi-bold">
            {!isDesktop ? "Type" : "Transaction Type"}
          </th>
          <th className="b2-semi-bold">
            Amount
            <OrderArrows
              colHeader="total_price"
              orderBy={localOrderBy}
              onClick={setLocalOrderBy}
            />
          </th>
          <th className="b2-semi-bold">
            {!isDesktop ? "Fees" : "Service Fees"}
          </th>
          {!isLaptop && <th className="b2-semi-bold">Name</th>}
          <th className="b2-semi-bold">Invoice</th>
        </tr>
      </table>

      {isLoading && <SoundWaveLoader width={100} height={100} />}
      {!isLoading && !isOnboarded && (
        <div className="centered-empty-state-message">
          <Text variant={TextStyleVariant.S3}>
            You currently don&apos;t have any transactions
          </Text>
        </div>
      )}
      {!isLoading && transactions[currentPage]?.length === 0 && isOnboarded && (
        <div className="centered-empty-state-message">
          <Text variant={TextStyleVariant.S3}>
            No transaction history for the current selection
          </Text>
        </div>
      )}
      {!isLoading && (
        <>
          {transactions[currentPage]?.map(
            (transaction: Transaction, index: number) => {
              return (
                <TransactionRow
                  key={index}
                  transaction={transaction}
                  rowIndex={index}
                />
              );
            },
          )}
        </>
      )}
      <div className="paginator-container-transactions">
        <Pagination
          current={currentPage}
          total={count}
          pageSize={DEFAULT_PAGE_SIZE}
          showSizeChanger={true}
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            marginLeft: 20,
          }}
          onChange={(page) => {
            if (page < 1 || page > totalPages) return;
            dispatch(setTransactionsPage(page));
            getTransactions(page, false);
          }}
        />
      </div>
    </div>
  );
};
export default TransactionsTable;
