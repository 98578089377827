import { UserProfileImage } from "../../elements/UserProfileImage/UserProfileImage";
import anonUserImage from "../../assets/anon-user.svg";
import User from "../../../store/models/user";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import "./UserProfile.css";
import { getUserTimeOnPlatform } from "../../../store/utils/userUtils";

export interface UserProfileProps {
  user: User;
}

export const UserProfile = ({ user }: UserProfileProps) => {
  const displayName = getDisplayableNameForUser(user);

  return (
    <div className="user-profile-container">
      <UserProfileImage
        source={user.photo?.path}
        noUserImage={anonUserImage}
        isCircle
        hideBorder
        width={42}
        height={42}
        username={user.username}
      />
      <div className="user-profile-data">
        <div className="user-profile-display-name">{displayName}</div>
        <div className="user-profile-time-on-platform">
          {getUserTimeOnPlatform(user.date_joined)}
        </div>
      </div>
    </div>
  );
};
