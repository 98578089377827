import styled from "styled-components";
import { Button } from "../../core-ui/components/Button/Button";

export const TeamPanelInviteBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

export const TeamPanelInviteItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const TeamInviteFieldHeader = styled.p`
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

export const TeamInviteButton = styled(Button)`
  white-space: nowrap;
  margin-left: auto;
  align-self: center;
  text-decoration: underline;
  margin-bottom: 10px;
  border: unset;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
`;
