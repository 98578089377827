import { useCallback, useEffect, useMemo, useState } from "react";
import { Theme, useMediaQuery as useMuiMediaQuery } from "@mui/material";

const addMediaMatchListener = (
  mediaMatch: MediaQueryList,
  handler: (event: MediaQueryListEvent) => void,
) => {
  if (mediaMatch.addEventListener !== undefined) {
    mediaMatch.addEventListener("change", handler);
    return;
  }

  // support for older browsers (https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#instance_methods)
  mediaMatch.addListener(handler);
};

const removeMediaMatchListener = (
  mediaMatch: MediaQueryList,
  handler: (event: MediaQueryListEvent) => void,
) => {
  if (mediaMatch.removeEventListener !== undefined) {
    mediaMatch.removeEventListener("change", handler);
    return;
  }

  // support for older browsers (https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#instance_methods)
  mediaMatch.removeListener(handler);
};

export const useMediaQuery = (query: string) => {
  const mediaMatch = useMemo(() => {
    return window.matchMedia(query);
  }, [query]);
  const [matches, setMatches] = useState(mediaMatch.matches);
  const handler = useCallback((event: MediaQueryListEvent) => {
    setMatches(event.matches);
  }, []);

  useEffect(() => {
    addMediaMatchListener(mediaMatch, handler);
    return () => {
      removeMediaMatchListener(mediaMatch, handler);
    };
  }, [mediaMatch, handler]);
  return useMemo(() => {
    return matches;
  }, [matches]);
};

export const useMediaQueryBreakpoint = () => {
  const isMobile = useMuiMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const isTablet = useMuiMediaQuery((theme: Theme) =>
    theme.breakpoints.between("sm", "md"),
  );
  const isDesktop = useMuiMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md"),
  );

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
