import { FC, useEffect } from "react";
import {
  getAvailableEngineersDateTimeMapKey,
  useAvailableTrackingEngineers,
} from "../../../../../hooks/bookingHooks/useAvailableTrackingEngineers";
import { useIsCurrentUserSameAsSelectedEngineer } from "../../../../../hooks/bookingHooks/useStudioEngineerUser";
import {
  useIsAffiliatedEngineerWithStudio,
  useIsAffiliatedManagerWithStudio,
} from "../../../../../hooks/studioHooks";
import { useGetEngineer } from "../../../../../hooks/useGetEngineer";
import { toggleSelectEngineerModal } from "../../../../../store/actions/recordingBookingMobileState";
import {
  setSelectedIndex,
  updateEngineerAtIndex,
} from "../../../../../store/actions/shoppingCart";
import {
  useAppDispatch,
  useAppSelector,
  useUser,
} from "../../../../../store/hooks";
import Engineer from "../../../../../store/models/engineer";
import { RecordingService } from "../../../../../store/models/recording";
import { StudioRoom } from "../../../../../store/models/studio";
import { selectIsPredefinedSession } from "../../../../../store/selectors/shoppingCart";
import { getDisplayableNameForUser } from "../../../../../store/utils/entityUtils";
import { getLeastRecentlyBookedEngineer } from "../../../../../utils/recordingUtils";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import {
  SelectEngineerOption,
  SelectEngineerOptionComponent,
} from "../../../SelectAvailableTrackingEngineer/SelectAvailableTrackingEngineer";
import {
  GenerateBookingDropdown,
  GenerateBookingDropdownRoundBorder,
} from "../../GenerateBookingDropdown";

export interface GenerateBookingSessionSelectEngineerProps {
  selectedTime: Date | undefined;
  duration: number | undefined;
  isSelected: boolean | undefined;
  index: number | undefined;
  engineer?: Engineer;
  dateISOString?: string;
  startAndEndTimeText: string | undefined;
  isMobile: boolean;
  hasNoTrackingEngineer?: boolean;
  studioRoom?: StudioRoom;
  recordingService: RecordingService | null;
  autoSelectedTrackingEngineer?: Engineer;
  selectAnyEngineer?: boolean;
}

export const GenerateBookingSessionSelectEngineer: FC<
  GenerateBookingSessionSelectEngineerProps
> = ({
  selectedTime,
  duration,
  isSelected,
  index,
  engineer,
  dateISOString,
  startAndEndTimeText,
  isMobile,
  hasNoTrackingEngineer,
  studioRoom,
  recordingService,
  autoSelectedTrackingEngineer,
  selectAnyEngineer,
}) => {
  const { activeStudioRoomId, activeStudioId } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const {
    availableRecordingEngineers,
    loading: engineerRecommendationLoading,
  } = useAppSelector((state) => state.engineerRecommendationStore);
  const activeStudio = useAppSelector(
    (state) => state.studiosSlice[activeStudioId ?? -1],
  );
  const loggedInEngineer = useAppSelector(
    (state) => state.accountInfo.user?.engineer,
  );
  const isStudioManager = useIsAffiliatedManagerWithStudio(activeStudio);
  const isAffiliatedEngineer = useIsAffiliatedEngineerWithStudio(activeStudio);

  const { trackingEngineers, loading: trackingEngineersLoading } =
    useAvailableTrackingEngineers(
      activeStudioRoomId,
      selectedTime,
      duration,
      dateISOString,
      startAndEndTimeText,
      isStudioManager,
    );
  const isPredefined = useAppSelector(selectIsPredefinedSession(studioRoom));
  const affiliatedEngineerBookingLinksEnabled = Boolean(
    studioRoom?.studio?.affiliated_engineer_booking_links_enabled,
  );
  const generateBookingStoreEngineerId = useAppSelector(
    (state) => state.generateBookingStore.engineerId,
  );
  const preselectedEngineer = useGetEngineer(generateBookingStoreEngineerId);

  const preselectedEngineerIsAffiliatedWithActiveStudio = Boolean(
    preselectedEngineer &&
      activeStudio?.studio_team.members.some(
        (member) => member.id === preselectedEngineer.user_id,
      ),
  );

  const trackingEngineerPriceIncluded = Boolean(
    studioRoom?.studio?.tracking_engineer_price_included,
  );

  // If recording service belongs to an engineer, update the engineer in the shopping cart
  useEffect(() => {
    if (recordingService?.engineer) {
      if (index === undefined) return;
      if (recordingService.engineer.id === engineer?.id) return;
      dispatch(
        updateEngineerAtIndex({
          index,
          engineer: recordingService.engineer,
        }),
      );
    }
  }, [recordingService]);

  // If the selected engineer at booking is an affiliated engineer, select them as the tracking engineer
  // If the current logged in user is an affiliated engineer, select them as the tracking engineer
  useEffect(() => {
    if (recordingService?.engineer) return;
    if (!activeStudio) return;
    if (index === undefined) return;
    const currentEngineer = preselectedEngineer ?? loggedInEngineer;
    if (!currentEngineer?.has_active_recording_service) {
      return;
    }
    if (
      preselectedEngineerIsAffiliatedWithActiveStudio ||
      isAffiliatedEngineer
    ) {
      dispatch(
        updateEngineerAtIndex({
          index,
          engineer: currentEngineer,
        }),
      );
    }
  }, [
    recordingService,
    preselectedEngineer,
    preselectedEngineerIsAffiliatedWithActiveStudio,
    loggedInEngineer,
  ]);

  // Disable the option to select a tracking engineer under the following circumstances:
  // 1. The session is predefined, Booking link, affiliated engineers are enabled, User is not a studio manager but an affiliated engineer,
  // 2. Or the recording service already has an assigned engineer (not a studio room recording service), hence negating the need to select another engineer.
  // 3. Pre-selected engineer is affiliated with the active studio
  const disableSelectTrackingEngineer = Boolean(
    (isPredefined &&
      affiliatedEngineerBookingLinksEnabled &&
      !isStudioManager &&
      isAffiliatedEngineer) ||
      recordingService?.engineer ||
      preselectedEngineerIsAffiliatedWithActiveStudio,
  );

  const dispatch = useAppDispatch();
  const engineerUser = useUser(engineer?.user_id);
  const isCurrentUserSameAsSelectedEngineer =
    useIsCurrentUserSameAsSelectedEngineer(recordingService, studioRoom);
  const allowsBookingWithoutEngineer =
    studioRoom?.studio?.allow_bookings_without_engineer;

  const selectedEngineerOption = () => {
    if (engineerUser) {
      return {
        label: getDisplayableNameForUser(engineerUser),
        value: engineerUser.id,
      };
    }
    if (hasNoTrackingEngineer) {
      return noTrackingEngineerOption;
    }

    if (
      studioRoom?.studio?.tracking_engineer_price_included &&
      autoSelectedTrackingEngineer
    ) {
      return anyEngineerOption;
    }

    return null;
  };

  useEffect(() => {
    if (index === undefined) return;
    if (!engineer) return;
    if (trackingEngineersLoading) return;
    if (trackingEngineers.length === 0) return;
    const engineerAvailable = trackingEngineers?.find(
      (engineerOption) => engineerOption.engineer?.id === engineer.id,
    );
    if (
      !engineerAvailable &&
      !isCurrentUserSameAsSelectedEngineer &&
      !isStudioManager &&
      !preselectedEngineerIsAffiliatedWithActiveStudio
    ) {
      dispatch(
        updateEngineerAtIndex({
          index,
          engineer: undefined,
          skipPrefill: true,
        }),
      );
    }
  }, [
    trackingEngineers,
    engineer,
    index,
    isCurrentUserSameAsSelectedEngineer,
    isStudioManager,
    dispatch,
    trackingEngineersLoading,
    preselectedEngineerIsAffiliatedWithActiveStudio,
  ]);

  useEffect(() => {
    if (
      index == null ||
      engineerRecommendationLoading ||
      trackingEngineersLoading ||
      !selectAnyEngineer
    )
      return;

    // This is needed to prevent unncessary callback when we change the datetime
    // Everytime we change the datetime, we generate a new map key to access the `availableRecordingEngineer` Redux store
    // But the data might not be available yet, resulting in `undefined`, resulting in `trackingEngineers` being an []
    // Since `trackingEngineers` is included in the deps array, it will trigger unnecessary callback
    if (selectedTime && duration) {
      const availableRecordingEngineerMapKey =
        getAvailableEngineersDateTimeMapKey(selectedTime, duration);

      if (
        availableRecordingEngineers[availableRecordingEngineerMapKey] ===
        undefined
      ) {
        return;
      }
    }

    // If there are available engineers and the user choose `Any provided engineer`,
    // We find the engineer that is the least recently booked and assign him/her to the session
    if (
      trackingEngineers.length > 0 &&
      selectedTime &&
      duration &&
      dateISOString
    ) {
      const leastRecentlyBookedEngineer =
        getLeastRecentlyBookedEngineer(trackingEngineers);
      if (leastRecentlyBookedEngineer) {
        dispatch(
          updateEngineerAtIndex({
            index,
            autoSelectedTrackingEngineer: leastRecentlyBookedEngineer.engineer,
            skipPrefill: true,
          }),
        );
      }

      return;
    }

    // If the user changes the date/time and no engineer is available, we mark the `markAnyTrackingEngineer` as false
    if (trackingEngineers.length === 0 && autoSelectedTrackingEngineer) {
      dispatch(
        updateEngineerAtIndex({
          index,
          markAnyTrackingEngineer: false,
          autoSelectedTrackingEngineer: undefined,
          skipPrefill: true,
        }),
      );
    }
  }, [
    dispatch,
    index,
    selectAnyEngineer,
    selectedTime,
    duration,
    dateISOString,
    trackingEngineers,
    trackingEngineersLoading,
    autoSelectedTrackingEngineer,
    availableRecordingEngineers,
    engineerRecommendationLoading,
  ]);

  const allOptions = () => {
    const options = [...trackingEngineers];

    if (allowsBookingWithoutEngineer) {
      options.push(noTrackingEngineerOption);
    }

    // Only an artist booking should have this option
    if (
      trackingEngineerPriceIncluded &&
      trackingEngineers.length > 0 &&
      !isStudioManager &&
      !isAffiliatedEngineer
    ) {
      options.unshift(anyEngineerOption);
    }

    return options;
  };

  return (
    <Button
      fullWidth
      onClick={() => {
        if (isMobile && index !== undefined) {
          dispatch(setSelectedIndex(index));
          return dispatch(toggleSelectEngineerModal());
        }
      }}
      disabled={disableSelectTrackingEngineer}
      variant={ButtonVariant.UNSTYLED}
    >
      <GenerateBookingDropdown
        isSelected={isSelected}
        roundBorder={[
          GenerateBookingDropdownRoundBorder.BOTTOM_LEFT,
          GenerateBookingDropdownRoundBorder.BOTTOM_RIGHT,
        ]}
        hideArrow={isMobile || disableSelectTrackingEngineer}
        isDisabled={
          isMobile || disableSelectTrackingEngineer || trackingEngineersLoading
        }
        options={allOptions()}
        placeholder={
          hasNoTrackingEngineer
            ? "No tracking engineer"
            : "Select recording engineer"
        }
        value={selectedEngineerOption()}
        customComponents={(engineerOption) => (
          <SelectEngineerOptionComponent
            engineer={(engineerOption as SelectEngineerOption).engineer}
            serviceRate={
              trackingEngineerPriceIncluded
                ? undefined
                : (engineerOption as SelectEngineerOption).serviceRate
            }
            user={(engineerOption as SelectEngineerOption).user}
            studioAffiliated={true}
            value={engineerOption.value}
            label={engineerOption.label}
          />
        )}
        onChange={(engineerOption) => {
          if (index === undefined) return;
          if (engineerOption.value === anyEngineerOption.value) {
            dispatch(
              updateEngineerAtIndex({
                index,
                markAnyTrackingEngineer: true,
                skipPrefill: true,
              }),
            );
            return;
          }
          dispatch(
            updateEngineerAtIndex({
              index,
              engineer: (engineerOption as SelectEngineerOption).engineer,
              markNoTrackingEngineer:
                engineerOption.value === noTrackingEngineerOption.value,
            }),
          );
        }}
        isLoading={trackingEngineersLoading}
        noOptionsMessage="No engineers available"
      />
    </Button>
  );
};

const anyEngineerOption = {
  value: 0.5,
  label: "Any provided tracking engineer",
  user: null,
  studioAffiliated: false,
};

const noTrackingEngineerOption = {
  value: 0,
  label: "Session without Tracking Engineer",
  user: null,
  studioAffiliated: false,
};
