import styled from "styled-components";
import { Text } from "../../core-ui/components/Text/Text";
import { Breakpoint } from "../../../utils/breakpoints";

export const TableWrapper = styled.div<{ $loading: boolean }>`
  opacity: ${({ $loading }) => ($loading ? 0.6 : 1)};
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table<{ $loading?: boolean; $isFixed?: boolean }>`
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: ${({ $isFixed }) => ($isFixed ? "fixed" : "auto")};

  td,
  th {
    color: ${({ $loading, theme }) =>
      $loading ? theme.textTertiaryColor : theme.textPrimaryColor};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }

  th {
    cursor: ${({ $loading }) => ($loading ? "not-allowed" : "auto")};
  }

  @media (max-width: 375px) {
    // iPhone SE width
    td,
    th {
      font-size: ${({ theme }) => theme.textSizeSm};
  }
`;

export const CollaboratorCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;

  @media ${Breakpoint.Mobile} {
    width: 115px;
  }

  @media (max-width: 375px) {
    // iPhone SE width
    width: 100px;
  }
`;

export const CollaboratorImage = styled.img`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  object-fit: cover;
`;

export const ProjectName = styled(Text)`
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
