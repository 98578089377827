import { ToggleButtonContainer } from "./ToggleButton.styles";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWifi,
  faLock,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material";

interface ToggleButtonProps {
  enabled: boolean;
  onClick: (value: boolean) => void;
  enabledLabel: string;
  disabledLabel: string;
  enabledIcon?: IconDefinition;
  disabledIcon?: IconDefinition;
  disableOnClick: boolean;
}

const ToggleButton = ({
  enabled,
  onClick,
  enabledLabel,
  disabledLabel,
  enabledIcon = faWifi,
  disabledIcon = faLock,
  disableOnClick = false,
}: ToggleButtonProps) => {
  const theme = useTheme();
  return (
    <ToggleButtonContainer $enabled={enabled}>
      <button disabled={disableOnClick} onClick={() => onClick(false)}>
        <FontAwesomeIcon
          color={
            !enabled
              ? theme.palette.standardColor.BoomyOrange["500"]
              : theme.palette.text.secondary
          }
          size={"xs"}
          icon={disabledIcon}
        />
        <Text
          bold={true}
          variant={TextStyleVariant.P2}
          style={{
            color: !enabled
              ? theme.palette.standardColor.BoomyOrange["500"]
              : theme.palette.text.secondary,
          }}
        >
          {disabledLabel}
        </Text>
      </button>
      <button disabled={disableOnClick} onClick={() => onClick(true)}>
        <FontAwesomeIcon
          color={
            enabled
              ? theme.palette.standardColor.BoomyOrange["500"]
              : theme.palette.text.secondary
          }
          size={"xs"}
          icon={enabledIcon}
        />
        <Text
          bold={true}
          variant={TextStyleVariant.P2}
          style={{
            color: enabled
              ? theme.palette.standardColor.BoomyOrange["500"]
              : theme.palette.text.secondary,
          }}
        >
          {enabledLabel}
        </Text>
      </button>
    </ToggleButtonContainer>
  );
};

export default ToggleButton;
