import { faPartyHorn } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useAtomValue } from "jotai";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { activeChecklistAtom, phaseChangeAtom } from "./atoms";
import ChecklistView from "./ChecklistView";

const ChecklistContent = () => {
  const activeChecklist = useAtomValue(activeChecklistAtom);
  const phaseChange = useAtomValue(phaseChangeAtom);

  if (!activeChecklist) return <SoundWaveLoader width={100} height={100} />;

  if (phaseChange) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "8px",
        }}
        gap={4}
        height={300}
      >
        <Text variant={TextStyleVariant.H6}>
          Congratulations!
          <br />
          This checklist is complete
        </Text>
        <FontAwesomeIcon size="4x" icon={faPartyHorn} />
      </Box>
    );
  }

  return <ChecklistView onboardingProgress={activeChecklist} />;
};

export default ChecklistContent;
