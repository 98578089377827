import "./StudioScreen.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan, faEdit } from "@fortawesome/free-solid-svg-icons";
import { host } from "../../../store/utils/utils";
import { Studio, StudioRoom } from "../../../store/models/studio";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useUserReviews } from "../../../hooks/useUserReviews";
import { getDisplayableNameForStudio } from "../../../store/utils/entityUtils";
import { PrimaryEntityImage } from "../../components/PrimaryEntityImage/PrimaryEntityImage";
import { EntityPhotoGrid } from "../../components/EnitityPhotoGrid/EntityPhotoGrid";
import StudioHeaderMetadata from "./StudioHeaderMetadata";
import {
  getStudioScreenRoute,
  getStudioRoomScreenRoute,
} from "../../../store/utils/routeGetters";
import { Trophy } from "../../../store/models/trophy";

interface StudioRoomHeaderProps {
  studio: Studio;
  studioRoom: StudioRoom;
  canEditStudio: boolean;
  showEditStudioRoomModal: boolean;
  setShowEditStudioRoomModal: (_: boolean) => void;
  setShowStudioPhotos: (_: boolean) => void;
  trophies: Trophy[] | undefined;
}

const StudioRoomHeader = ({
  studio,
  studioRoom,
  canEditStudio,
  showEditStudioRoomModal,
  setShowEditStudioRoomModal,
  setShowStudioPhotos,
  trophies,
}: StudioRoomHeaderProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();
  const studioUrl = getStudioScreenRoute(studio.username);
  const studioRoomUrl = getStudioRoomScreenRoute(
    studio.username,
    studioRoom.id,
  );
  const shareUrl = `${host}${studioRoomUrl}`;
  const stats = useUserReviews(undefined, undefined, undefined, studioRoom);

  return (
    <>
      {!isDesktop && (
        <div className={"container-fluid"}>
          <PrimaryEntityImage
            studioRoom={studioRoom}
            primaryPhoto={studioRoom?.photo ?? null}
            editMode={false}
            imageClassName={"studio-hero-image"}
            canManageStudio={canEditStudio}
            onClickViewAll={() => setShowStudioPhotos(true)}
          />
        </div>
      )}
      <div className={"container mt-3"}>
        <div className="bread-crumb-container">
          <Link className="bread-crumb-link label" to={studioUrl}>
            {getDisplayableNameForStudio(studio)}
          </Link>
          <FontAwesomeIcon
            color="var(--medium-grey)"
            icon={faGreaterThan}
            className={"back-button"}
            size="2xs"
          />
          <Link
            className="bread-crumb-link label selected-link"
            to={studioRoomUrl}
          >
            {studioRoom.room_name}
          </Link>
        </div>
        <div className="studio-name-header">
          <p className={"h2 h2-semi-bold mx-1"}>
            {studioRoom?.room_name ?? ""}
          </p>
          {canEditStudio && (
            <FontAwesomeIcon
              icon={faEdit}
              size={!isDesktop ? "sm" : "lg"}
              className={"edit-studio-button"}
              onClick={() => {
                if (!showEditStudioRoomModal) setShowEditStudioRoomModal(true);
              }}
            />
          )}
        </div>
        <StudioHeaderMetadata
          studio={studio}
          studioRoom={studioRoom}
          stats={stats}
          trophies={trophies}
          shareUrl={shareUrl}
        />
        {isDesktop && (
          <EntityPhotoGrid
            studioRoomId={studioRoom.id}
            handlePhotoClick={() => setShowStudioPhotos(true)}
          />
        )}
      </div>
    </>
  );
};

export default StudioRoomHeader;
