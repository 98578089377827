import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LOAD_TROPHIES } from "../utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { receiveErrors } from "./errorStore";
import { Trophy } from "../models/trophy";
import { MockUser } from "../models/user";

export interface loadTrophiesParams {
  userId?: number;
  studioId?: number;
  studioRoomId?: number;
}

export const loadTrophies = createAsyncThunk(
  LOAD_TROPHIES,
  async (args: loadTrophiesParams, thunkAPI) => {
    let params = "";
    if (args.userId) {
      params = `?user_id=${args.userId}`;
    }
    if (args.studioId) {
      params = `?studio_id=${args.studioId}`;
    }
    if (args.studioRoomId) {
      params = `?studio_room_id=${args.studioRoomId}`;
    }
    const result = await makeBackendGetCallWithJsonResponse<Trophy[]>(
      LOAD_TROPHIES,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface loadSubLabelParams {
  aandr_id: number;
}

interface UserTrophies {
  [user_id: number]: Trophy[] | undefined;
}

interface StudioTrophies {
  [studio_id: number]: Trophy[] | undefined;
}

interface StudioRoomTrophies {
  [studio_room_id: number]: Trophy[] | undefined;
}
interface TrophyState {
  userTrophies: UserTrophies;
  studioTrophies: StudioTrophies;
  studioRoomTrophies: StudioRoomTrophies;
}

const initialState: TrophyState = {
  userTrophies: {},
  studioTrophies: {},
  studioRoomTrophies: {},
};

export const trophySlice = createSlice({
  name: "statStateSlice",
  initialState,
  reducers: {
    clearTrophyState: () => {
      return initialState;
    },
    uploadMockTrophies: (state) => {
      state.userTrophies[MockUser.id] = [
        { badgeEnum: 1, text: "EngineEars Verified" },
      ];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadTrophies.fulfilled, (state, action) => {
      const { meta, payload } = action;
      if (meta.arg.userId) {
        state.userTrophies[meta.arg.userId] = payload;
      }
      if (meta.arg.studioId) {
        state.studioTrophies[meta.arg.studioId] = payload;
      }
      if (meta.arg.studioRoomId) {
        state.studioRoomTrophies[meta.arg.studioRoomId] = payload;
      }
    });
  },
});

export const { uploadMockTrophies, clearTrophyState } = trophySlice.actions;
export default trophySlice.reducer;
