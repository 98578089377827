import { atomWithLocation } from "jotai-location";
import { atom, Setter } from "jotai/vanilla";
import {
  CHANNEL_ID_PREFIX,
  SESSION_MESSAGE_PREFIXES,
} from "../../../constants/chat";

const _searchQueryAtom = atomWithLocation({ replace: true });
_searchQueryAtom.debugPrivate = false;

interface ExpectedSearchParams {
  status?: string;
  page?: string;
  channel?: string;
  [x: string]: unknown;
}

const setSearchParamsHelper = (
  set: Setter,
  params: Partial<ExpectedSearchParams>,
) => {
  set(_searchQueryAtom, (prev) => {
    const newParams = new URLSearchParams(prev.searchParams);
    const keys = Object.keys(params) as (keyof ExpectedSearchParams)[];
    keys.forEach((key) => {
      if (params[key] === undefined) {
        newParams.delete(String(key));
      } else {
        newParams.set(String(key), String(params[key]));
      }
    });
    return { ...prev, searchParams: newParams };
  });
};

const statusAtom = atom(
  (get) => {
    const value = parseInt(
      get(_searchQueryAtom).searchParams?.get("status") || "",
    );
    if (Number.isNaN(value)) {
      return 0;
    }
    return value;
  },
  (_, set, value: number) => {
    setSearchParamsHelper(set, { status: String(value) });
  },
);

statusAtom.debugLabel = "Status Atom";

interface ChannelData {
  channelId?: string;
  channelType?: string;
}

const channelAtom = atom(
  (get) => {
    const value = get(_searchQueryAtom).searchParams?.get("channel") || "";
    const parts = value.split(":", 2);
    if (parts.length == 2) {
      return {
        channelType: parts[0],
        channelId: parts[1],
      };
    }
    try {
      return JSON.parse(value) as ChannelData;
    } catch {
      /* empty */
    }
    return {} as ChannelData;
  },
  (_, set, value: ChannelData) => {
    setSearchParamsHelper(set, {
      channel: value ? `${value.channelType}:${value.channelId}` : "",
    });
  },
);

channelAtom.debugLabel = "Channel Atom";

export enum PAGE_VALUES {
  PROJECT_MESSAGES = 0,
  SESSION_MESSAGES = 1,
  STATUS = 2,
}

const QUERY_VALUES_TO_PAGE_VALUES = {
  "project-message": PAGE_VALUES.PROJECT_MESSAGES,
  "session-message": PAGE_VALUES.SESSION_MESSAGES,
  status: PAGE_VALUES.STATUS,
};

export const PAGE_VALUES_TO_QUERY_VALUES: Record<number, string> = {};
Object.keys(QUERY_VALUES_TO_PAGE_VALUES).forEach((k) => {
  PAGE_VALUES_TO_QUERY_VALUES[
    QUERY_VALUES_TO_PAGE_VALUES[k as keyof typeof QUERY_VALUES_TO_PAGE_VALUES]
  ] = k;
});

const pageAtom = atom(
  async (get) => {
    if (get(statusAtom) != 0) {
      return PAGE_VALUES.STATUS;
    }
    const channelParam = get(channelAtom);
    if (channelParam.channelId) {
      const prefix = channelParam.channelId.split("-")[0];
      if (prefix) {
        if (SESSION_MESSAGE_PREFIXES.includes(prefix as CHANNEL_ID_PREFIX)) {
          return PAGE_VALUES.SESSION_MESSAGES;
        } else {
          return PAGE_VALUES.PROJECT_MESSAGES;
        }
      }
    }
    return (
      QUERY_VALUES_TO_PAGE_VALUES[
        get(_searchQueryAtom).searchParams?.get(
          "page",
        ) as keyof typeof QUERY_VALUES_TO_PAGE_VALUES
      ] || undefined
    );
  },
  (_, set, value: number) => {
    setSearchParamsHelper(set, {
      status: undefined,
      channel: undefined,
      page:
        PAGE_VALUES_TO_QUERY_VALUES[value] ||
        String(PAGE_VALUES.PROJECT_MESSAGES),
    });
  },
);

pageAtom.debugLabel = "Page Atom";

const emptyMessagesAtom = atom(false);
emptyMessagesAtom.debugLabel = "Empty Messages";

export { channelAtom, pageAtom, emptyMessagesAtom };
