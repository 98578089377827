import { Card, Box, styled } from "@mui/material";

export const SubscriptionCardContainer = styled(Card)(({ theme }) => ({
  borderRadius: "16px",
  boxShadow: "0px 2px 30px 0px rgba(0, 0, 0, 0.10)",
  width: "100%",
  maxWidth: "300px",
  flex: 1,
}));

export const SubscriptionCardHeader = styled(Box)<{ $isPlatinum: boolean }>(
  ({ theme, $isPlatinum }) => ({
    backgroundColor: $isPlatinum
      ? theme.palette.standardColor.DeepBlue[50]
      : theme.palette.standardColor.EchoOrange[100],
    display: "flex",
    padding: "24px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
  }),
);

export const SubscriptionCardContent = styled(Box)(() => ({
  display: "flex",
  padding: "24px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "24px",
  alignSelf: "stretch",
}));

export const SubscriptionCardPricing = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
}));

export const SubscriptionCardKeyFeatures = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
}));
