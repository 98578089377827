import { FileVersionTableContainer } from "./FileVersionTable.styles";
import {
  Text,
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import { faSortDown, faSortUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonVariant } from "../../core-ui/components/Button/Button";

export interface FileVersionSortHeaderProps {
  name: string;
  order: "asc" | "desc";
  selected: boolean;
  onClick: () => void;
}

export const FileVersionSortHeader = ({
  order,
  name,
  selected,
  onClick,
}: FileVersionSortHeaderProps) => {
  return (
    <FileVersionTableContainer
      variant={ButtonVariant.UNSTYLED}
      onClick={onClick}
    >
      <Text
        weight={TEXT_WEIGHT.BOLD}
        size={TEXT_SIZE.MEDIUM}
        color={selected ? TEXT_COLOR.PRIMARY : TEXT_COLOR.SECONDARY}
      >
        {name}
      </Text>
      <FontAwesomeIcon
        color={selected ? TEXT_COLOR.PRIMARY : TEXT_COLOR.SECONDARY}
        icon={order === "asc" ? faSortUp : faSortDown}
      />
    </FileVersionTableContainer>
  );
};
