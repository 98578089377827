import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../constants/queryKeys";
import { useAppDispatch } from "../../store/hooks";
import { loadGenres } from "../../store/actions/accountInfo";

export const useGetUserGenres = (userId = 0) => {
  const dispatch = useAppDispatch();

  // create a useQuery for getUserGenres using the user_id and LoadGenres dispatch
  return useQuery({
    queryKey: [QUERY_KEYS.GET_USER_GENRES, userId],
    enabled: Boolean(userId),
    initialData: [],
    // Make queryFn always return an array instead of undefined
    queryFn: async () => {
      const result = await dispatch(loadGenres({ user_id: userId })).unwrap();
      return result.genres ? result.genres : [];
    },
  });
};
