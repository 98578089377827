import styled from "styled-components";
import { Text } from "../../core-ui/components/Text/Text";

export const RequestedPanelActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > * {
    flex: 1;
  }
`;

export const RequestedPanelDescriptionText = styled(Text)`
  margin-top: 8px;
  margin-bottom: 24px;
`;
