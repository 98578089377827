import { ColumnDef } from "@tanstack/react-table";
import {
  PaginatedScheduledProject,
  ProjectAcceptance,
  ScheduledProject,
} from "../../../store/models/scheduledproject";
import User from "../../../store/models/user";

export const getDisabledRowText = (
  projectTitle: string,
  isSharedProjectDisabled?: boolean,
) => {
  if (isSharedProjectDisabled) {
    return "This project is only accessible after the engineer uploads the necessary files.";
  }
  return `This project is only accessible after the mixing for "${projectTitle}" is completed.`;
};

export type ScheduledProjectColumnDef = ColumnDef<PaginatedScheduledProject> & {
  canSort?: boolean;
};

export const hasUserAcceptedProject = (
  projectAcceptances: ProjectAcceptance[],
  user: User | undefined,
) => {
  if (!user) {
    return false;
  }
  return projectAcceptances.some(
    (acceptance) => acceptance.user_id === user.id && acceptance.accepted,
  );
};

export const isUserOnProject = (
  scheduledProject: PaginatedScheduledProject | ScheduledProject | null,
  user: User | undefined,
) => {
  if (!user || !scheduledProject) {
    return false;
  }

  return scheduledProject.users.some(
    (projectUser) => projectUser.id === user.id,
  );
};
