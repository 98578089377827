import { useState, useEffect, useCallback } from "react";
import "./UnauthenticatedModal.css";
import { SignInForm } from "../SignInForm/SignInForm";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  updateUserArtist,
  updateUserEngineer,
} from "../../../store/actions/accountInfo";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import classNames from "classnames";
import GoogleLogoIcon from "../../assets/google-logo-icon.svg";
import { SignUpSocialButton } from "../Auth/AuthModal.styles";
import useGoogleAuth from "../../../hooks/authHooks/useGoogleAuth";

export interface UnauthenticatedModalProps {
  showModal?: boolean;
  closeModal: () => void;
  showCloseButton?: boolean;
  addArtistAccountType?: boolean;
  addEngineerAccountType?: boolean;
  message?: string;
}

export const UnauthenticatedModal = ({
  closeModal = () => {},
  showModal = false,
  addArtistAccountType = false,
  addEngineerAccountType = false,
  message = "Please sign in or sign up to book.",
  showCloseButton = true,
}: UnauthenticatedModalProps) => {
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const userArtistProfile = useAppSelector(
    (state) => state.accountInfo?.user?.artist,
  );
  const userEngineerProfile = useAppSelector(
    (state) => state.accountInfo?.user?.engineer,
  );
  const dispatch = useAppDispatch();
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [renderAuthForm, setRenderForm] = useState(false);
  const unauthenticatedModalClassname = classNames("unauthenticated-modal", {
    "show-login": renderAuthForm,
    "hide-login": !renderAuthForm,
    "sign-up-form": showSignup,
  });
  const { handleGoogleClick, isGoogleLoginOpen } = useGoogleAuth();

  const handleClose = useCallback(() => {
    setShowLogin(false);
    setShowSignup(false);
    setRenderForm(false);
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    if (!showLogin) return;
    setTimeout(() => {
      setRenderForm(true);
    }, 1000);
  }, [showLogin, showSignup]);

  useEffect(() => {
    if (!showSignup) return;
    setTimeout(() => {
      setRenderForm(true);
    }, 1000);
  }, [showSignup]);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (userArtistProfile == null && addArtistAccountType) {
      void dispatch(updateUserArtist({ is_primary_type: true, deleted: false }))
        .unwrap()
        .then(() => {
          handleClose();
        });
      return;
    }
    if (userEngineerProfile == null && addEngineerAccountType) {
      void dispatch(
        updateUserEngineer({ is_primary_type: true, deleted: false }),
      )
        .unwrap()
        .then(() => {
          handleClose();
        });
      return;
    }
    handleClose();
  }, [
    isAuthenticated,
    userArtistProfile,
    addArtistAccountType,
    userEngineerProfile,
    addEngineerAccountType,
    dispatch,
    handleClose,
  ]);

  return (
    <BaseModal
      open={showModal}
      setOpen={handleClose}
      header={showLogin ? "Log in" : "Register"}
      showCloseButton={showCloseButton}
    >
      <div className={unauthenticatedModalClassname}>
        {!renderAuthForm && (
          <p className="h7-semi-bold unauthenticated-modal-header mb-3">
            {message}
          </p>
        )}
        {!renderAuthForm && (
          <div
            className={"unauthenticated-modal-button-container ".concat(
              showLogin || showSignup ? "hide" : "show",
            )}
          >
            <Button
              fullWidth={true}
              onClick={() => {
                setShowLogin(true);
              }}
              variant={ButtonVariant.PRIMARY}
            >
              Login
            </Button>
            <Button
              fullWidth={true}
              variant={ButtonVariant.OUTLINED}
              onClick={() => {
                setShowSignup(true);
              }}
            >
              Signup
            </Button>
          </div>
        )}
        {renderAuthForm && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <SignUpSocialButton
              loading={isGoogleLoginOpen}
              onClick={handleGoogleClick}
              fullWidth={true}
              variant={ButtonVariant.OUTLINED}
            >
              <img
                alt={"google logo icon"}
                src={GoogleLogoIcon}
                width={18}
                height={18}
              />
              Continue with Google
            </SignUpSocialButton>
            <SignInForm
              showWarning={true}
              primary={true}
              register={showSignup}
              skipFlow={true}
              hideGoogle={true}
            />
          </div>
        )}
      </div>
    </BaseModal>
  );
};
