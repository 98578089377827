import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { SIDE_PANEL_BOTTOM_DRAWER_CONTAINER_ID } from "../Navigation/BottomNav/useBottomTabBarOverlayView";
import SidePanelCloseButton from "../ProjectFilters/SidePanelFilters/SidePanelCloseButton";
import "./SidePanel.css";

export enum SIDE_PANEL_ANCHOR {
  LEFT = "left",
  RIGHT = "right",
}

export interface SidePanelProps {
  anchor?: SIDE_PANEL_ANCHOR;
  children: React.ReactNode;
  isOpen: boolean;
  fillScreen?: boolean;
  onClose: () => void;
  noPadding?: boolean;
  roundedBorder?: boolean;
  sidePanelStyle?: React.CSSProperties;
  hideCloseButton?: boolean;
}

export const SidePanel = ({
  anchor = SIDE_PANEL_ANCHOR.RIGHT,
  children,
  isOpen,
  fillScreen = false,
  onClose,
  noPadding = false,
  roundedBorder = false,
  sidePanelStyle,
  hideCloseButton = false,
}: SidePanelProps) => {
  const [savedScrollPosition, setSavedScrollPosition] = useState<number>(0);
  const { updateContainerMapCb } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );
  const { isMobile } = useMediaQueryBreakpoint();

  useEffect(() => {
    if (isOpen) {
      setSavedScrollPosition(window.scrollY);
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
      window.scrollTo(0, savedScrollPosition);
    }
  }, [isOpen]);

  const handleRefChange = useCallback(
    (node: HTMLDivElement | null) => {
      if (isOpen) {
        updateContainerMapCb(node);
      }
    },
    [isOpen, updateContainerMapCb],
  );

  return (
    <>
      <div className={`side-panel-overlay--${isOpen ? "open" : "closed"}`} />
      <div
        className={classNames(
          "side-panel-background",
          `side-panel-closed-${anchor}`,
          isOpen ? "open" : "",
        )}
        onClick={onClose}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            ...(fillScreen && { width: "100%" }),
            ...sidePanelStyle,
          }}
          className={classNames(
            "side-panel-container",
            `side-panel-closed-${anchor}`,
            isOpen ? "side-panel-open" : "",
            noPadding ? "side-panel--no-padding" : "",
            roundedBorder ? "side-panel--rounded-border" : "",
          )}
          ref={handleRefChange}
        >
          {!hideCloseButton && <SidePanelCloseButton onClick={onClose} />}
          {children}
        </div>
      </div>
      {/* This is to contain the bottom drawer */}
      {isOpen && isMobile && <div id={SIDE_PANEL_BOTTOM_DRAWER_CONTAINER_ID} />}
    </>
  );
};
