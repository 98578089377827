import createFilter from "redux-persist-transform-filter";

const abPlayerSubsetFilter = createFilter("abPlayerStore", [
  "footerFileTrackType",
  "playlistId",
  "currentTrackIndex",
  "currentPosition",
]);

export default abPlayerSubsetFilter;
