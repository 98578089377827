import { useMemo } from "react";
import { useAppSelector } from "../store/hooks";
import { Alt } from "../store/models/alts";
import {
  FILE_STATUS,
  GENERATED_MP3_UPLOAD_TYPE,
  LINK_UPLOAD_TYPE,
  MP4_UPLOAD_TYPE,
  ZIP_UPLOAD_TYPE,
} from "../store/models/fileVersion";

export const useLatestReference = (projectId: number) => {
  const fileStore = useAppSelector((state) => state.fileVersionStore);
  return useMemo(() => {
    const files = fileStore.storedFileVersions[projectId];
    if (!files) return undefined;
    const uploaded = files[FILE_STATUS.FILE_UPLOADED];
    const references = uploaded
      .filter((f) => f.reference)
      .filter(
        (f) =>
          f.upload_type !== ZIP_UPLOAD_TYPE &&
          f.upload_type !== GENERATED_MP3_UPLOAD_TYPE &&
          f.upload_type !== LINK_UPLOAD_TYPE,
      );
    if (references.length === 1) {
      return references[0];
    }
    let latestVersion = 0;
    references.forEach((ref) => {
      if (ref.version > latestVersion) {
        latestVersion = ref.version;
      }
    });
    return references.find((ref) => ref.version === latestVersion);
  }, [fileStore.storedFileVersions, projectId]);
};

export const useLatestNonReferenceAudioFileVersion = (
  projectId: number,
  altType: Alt = Alt.MAIN,
  uploadType = "",
  filterOutType = "",
) => {
  const fileStore = useAppSelector((state) => state.fileVersionStore);

  return useMemo(() => {
    const files = fileStore.storedFileVersions[projectId];
    if (!files) return undefined;
    let uploaded = files[FILE_STATUS.FILE_UPLOADED];
    if (altType !== Alt.ALL) {
      uploaded = uploaded.filter((file) => file.alt === altType);
    }

    uploaded = uploaded.filter(
      (file) =>
        file.upload_type !== ZIP_UPLOAD_TYPE &&
        file.upload_type !== GENERATED_MP3_UPLOAD_TYPE &&
        file.upload_type !== LINK_UPLOAD_TYPE,
    );

    if (uploadType) {
      uploaded = uploaded.filter((file) => file.upload_type === uploadType);
    }
    if (filterOutType) {
      uploaded = uploaded.filter((file) => file.upload_type !== filterOutType);
    }
    const mixOrMaster = uploaded.filter((f) => !f.reference);
    if (mixOrMaster.length === 1) {
      return mixOrMaster[0];
    }
    let largestVersion = 0;
    mixOrMaster.forEach((ref) => {
      if (ref.version > largestVersion) {
        largestVersion = ref.version;
      }
    });
    const latestMix = mixOrMaster.find(
      (file) => file.version === largestVersion,
    );
    return latestMix;
  }, [fileStore, projectId, altType]);
};

export const useProjectMains = (projectId: number, altType: Alt = Alt.MAIN) => {
  const latestVersion = useLatestNonReferenceAudioFileVersion(
    projectId,
    altType,
    "",
    MP4_UPLOAD_TYPE,
  );
  const fileStore = useAppSelector((state) => state.fileVersionStore);
  return useMemo(() => {
    const files = fileStore.storedFileVersions[projectId];
    if (!files) return [];
    if (!latestVersion) return [];
    let uploadedMains = files[FILE_STATUS.FILE_UPLOADED].filter(
      (f) => f.reference === false,
    );
    if (altType !== Alt.ALL) {
      uploadedMains = uploadedMains.filter((f) => f.alt === altType);
    }
    return uploadedMains
      .filter(
        (file) =>
          file.upload_type !== ZIP_UPLOAD_TYPE &&
          file.upload_type !== GENERATED_MP3_UPLOAD_TYPE &&
          file.upload_type !== LINK_UPLOAD_TYPE,
      )
      .sort((a, b) => b.version - a.version);
  }, [fileStore, latestVersion, projectId, altType]);
};

export const useLatestMP4 = (
  projectId: number,
  mp4Type: Alt.MP4 | Alt.CLEAN_MP4,
) => {
  const latestMp4 = useLatestNonReferenceAudioFileVersion(
    projectId,
    mp4Type,
    MP4_UPLOAD_TYPE,
  );
  return useMemo(() => {
    return latestMp4;
  }, [latestMp4]);
};

export const useProjectHasCleansUploaded = (projectId: number) => {
  const fileStore = useAppSelector((state) => state.fileVersionStore);
  return useMemo(() => {
    const files = fileStore.storedFileVersions[projectId];
    if (!files) return false;
    const uploaded = files[FILE_STATUS.FILE_UPLOADED];
    if (!uploaded) return false;
    const mains = uploaded.filter(
      (f) => f.reference === false && f.upload_type !== LINK_UPLOAD_TYPE,
    );
    return mains.some((main) => main.alt === Alt.CLEAN);
  }, [fileStore, projectId]);
};

/**
 * @TODO Can this be deleted?
 */
export const useProjectReferences = (projectId: number) => {
  const latestVersion = useLatestReference(projectId);
  const fileStore = useAppSelector((state) => state.fileVersionStore);
  return useMemo(() => {
    const files = fileStore.storedFileVersions[projectId];
    if (!files) return [];
    if (!latestVersion) return [];
    const letUploadedRefs = files[FILE_STATUS.FILE_UPLOADED].filter(
      (f) => f.reference && !f.archived,
    );
    return letUploadedRefs
      .filter(
        (file) =>
          file.upload_type !== ZIP_UPLOAD_TYPE &&
          file.upload_type !== GENERATED_MP3_UPLOAD_TYPE &&
          file.upload_type !== LINK_UPLOAD_TYPE,
      )
      .sort((a, b) => b.version - a.version);
  }, [fileStore, latestVersion, projectId]);
};
