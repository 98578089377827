import {
  StyledSwitchButtonAB,
  StyledSwitchButtonABP,
} from "./SwitchButtonAB.styles";

export interface SwitchButtonProps {
  isSelected: boolean;
  label: string;
  loading?: boolean;
  onClick: () => void;
}

export const SwitchButtonAB = ({
  isSelected,
  label,
  loading = false,
  onClick,
}: SwitchButtonProps) => {
  return (
    <StyledSwitchButtonAB
      disabled={loading}
      $isSelected={isSelected}
      onClick={onClick}
      $isProjectView={label.length === 1}
    >
      <StyledSwitchButtonABP $isSelected={isSelected}>
        {label}
      </StyledSwitchButtonABP>
    </StyledSwitchButtonAB>
  );
};
