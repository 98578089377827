import { isSameDay } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useOverlappingBookingAvailability } from "../../../../../hooks/bookingHooks/useAllPossibleBookingAvailability";
import { useAvailableDateTimeOptions } from "../../../../../hooks/bookingHooks/useAvailableDateTimeOptions";
import { useFilterTimeOptions } from "../../../../../hooks/bookingHooks/useFilterTimeOptions";
import { useIsCurrentUserSameAsSelectedEngineer } from "../../../../../hooks/bookingHooks/useStudioEngineerUser";
import { useIsAffiliatedManagerWithStudio } from "../../../../../hooks/studioHooks";
import {
  StudioWorkingHoursOptionType,
  useAvailabilityForMostAvailableDay,
  WorkingHoursOptionType,
} from "../../../../../hooks/useGeneralWorkingHoursStringForDay";
import { useMediaQueryBreakpoint } from "../../../../../hooks/useMediaQuery";
import useModal from "../../../../../hooks/useModal";
import {
  StartTimeOption,
  toggleDateTimeModal,
} from "../../../../../store/actions/recordingBookingMobileState";
import {
  setGeneralWorkingHourIntervalLabelAtIndex,
  updateSelectedDateOptionAtIndex,
} from "../../../../../store/actions/shoppingCart";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import Engineer from "../../../../../store/models/engineer";
import { RecordingService } from "../../../../../store/models/recording";
import { StudioRoom } from "../../../../../store/models/studio";
import {
  selectShoppingCartLatestSessionDuration,
  selectShoppingCartStudioRoomAvailabilities,
} from "../../../../../store/selectors/shoppingCart";
import { convertAvailabilityForDateToMap } from "../../../../../store/utils/utils";
import { BaseModal } from "../../../../core-ui/components/BaseModal/BaseModal";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import { Text, TEXT_COLOR } from "../../../../core-ui/components/Text/Text";
import { OptionType } from "../../../../elements/DropDownSelector/DropdownSelector";
import {
  GenerateBookingDropdown,
  GenerateBookingDropdownNoBorder,
  GenerateBookingDropdownRoundBorder,
} from "../../GenerateBookingDropdown";

export interface GenerateBookingSessionSelectTimeProps {
  preselectedDateOption?: OptionType;
  sessionDuration?: number;
  isSelected?: boolean;
  index?: number;
  sessionISODateString?: string;
  engineer?: Engineer;
  generalWorkingHourIntervalLabel?: string;
  isMobile?: boolean;
  recordingDetailWithoutStudio?: boolean;
  transactionDateOption?: number;
  recordingService: RecordingService | null;
  studioRoom: StudioRoom | undefined;
}

export const GenerateBookingSessionSelectTime = ({
  preselectedDateOption,
  sessionDuration,
  isSelected,
  index,
  sessionISODateString,
  engineer,
  generalWorkingHourIntervalLabel,
  isMobile,
  recordingDetailWithoutStudio = false,
  transactionDateOption,
  recordingService,
  studioRoom,
}: GenerateBookingSessionSelectTimeProps) => {
  const pendingSessions = useAppSelector(
    (state) => state.shoppingCart.pendingSessionData ?? [],
  );
  const dispatch = useAppDispatch();
  const { isDesktop } = useMediaQueryBreakpoint();
  const {
    openModal: openConfirmModal,
    isOpen: isConfirmModalOpen,
    closeModal: closeConfirmModal,
  } = useModal();
  const { activeStudioRoomId } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const [pendingTimeOption, setPendingTimeOption] =
    useState<WorkingHoursOptionType | null>(null);
  const roundBorderStyle = isDesktop
    ? [GenerateBookingDropdownRoundBorder.TOP_RIGHT]
    : [];
  const initialDurationMinutes = useAppSelector(
    selectShoppingCartLatestSessionDuration(recordingService),
  );
  const activeStudio = useAppSelector(
    (state) => state.studiosSlice[studioRoom?.studio?.id ?? -1],
  );
  const isStudioManager = useIsAffiliatedManagerWithStudio(activeStudio);
  const isCurrentUserSameAsSelectedEngineer =
    useIsCurrentUserSameAsSelectedEngineer(recordingService, studioRoom);

  const selectedDate = useMemo(() => {
    const selectedDate = sessionISODateString ?? preselectedDateOption?.value;
    return selectedDate ? new Date(selectedDate) : undefined;
  }, [preselectedDateOption, sessionISODateString]);

  const durationMinutes = useMemo(() => {
    return sessionDuration || initialDurationMinutes;
  }, [sessionDuration, initialDurationMinutes]);

  const studioDateMap = useAppSelector(
    selectShoppingCartStudioRoomAvailabilities(studioRoom),
  );
  const engineerAvailability = useAppSelector(
    (state) => state.availability.engineer_user[engineer?.user_id ?? -1],
  );
  const engineerDateMap = useMemo(
    () => convertAvailabilityForDateToMap(engineerAvailability),
    [engineerAvailability],
  );

  const studioTimezone = useMemo(() => {
    return studioRoom?.studio?.timezone;
  }, [studioRoom]);

  /**
   * This is an WorkingHoursOptionType[] that contains only the available times for an engineer/studio on the selected date
   */

  const availableTimeOptions = useAvailableDateTimeOptions(
    selectedDate,
    durationMinutes,
    engineer,
    studioDateMap,
    engineerDateMap,
    recordingDetailWithoutStudio,
    studioTimezone,
  );

  /**
   * This is an WorkingHoursOptionType[] that contains every type of start time range for an engineer/studio on the selected date
   */
  const allTimeOptions = useOverlappingBookingAvailability(
    durationMinutes,
    availableTimeOptions,
    selectedDate,
    studioTimezone,
  );

  /**
   * If date hasn't been selected, then the actual availability cannot be known
   * This returns the general working availability as a placeholder until actual availability can be determined
   */
  const availabilityForMostAvailableDay = useAvailabilityForMostAvailableDay(
    durationMinutes,
    activeStudioRoomId,
    engineer,
    studioTimezone,
  );

  const displayedOption = useMemo(() => {
    // useEffect automatically finds corresponding date option with correct value
    const generalWorkingHourOption = generalWorkingHourIntervalLabel
      ? { label: generalWorkingHourIntervalLabel, value: 123 }
      : null;
    return preselectedDateOption
      ? preselectedDateOption
      : generalWorkingHourOption;
  }, [preselectedDateOption, generalWorkingHourIntervalLabel]);

  const timeOptions = useFilterTimeOptions(
    allTimeOptions,
    availableTimeOptions.length > 0,
    isCurrentUserSameAsSelectedEngineer,
    isStudioManager,
    recordingDetailWithoutStudio,
    availabilityForMostAvailableDay,
  );

  const handleSelectTime = (option: WorkingHoursOptionType, index: number) => {
    if (!availableTimeOptions.length) {
      dispatch(
        setGeneralWorkingHourIntervalLabelAtIndex({
          index,
          selectedDateOption: option,
          skipPrefill: true,
        }),
      );
    } else {
      dispatch(
        updateSelectedDateOptionAtIndex({
          index,
          selectedDateOption: option,
          skipPrefill: true,
        }),
      );
    }
  };

  useEffect(() => {
    if (index === undefined) return;
    if (!transactionDateOption) return;

    const option = allTimeOptions.find(
      (option) => option.value === transactionDateOption,
    );
    if (option) {
      dispatch(
        updateSelectedDateOptionAtIndex({
          index: index,
          selectedDateOption: option,
          skipPrefill: true,
        }),
      );
    }
  }, [allTimeOptions, transactionDateOption, index, pendingTimeOption]);

  useEffect(() => {
    // if no pending session exists or no availability, return
    if (index === undefined) return;
    if (!availableTimeOptions?.length && !isCurrentUserSameAsSelectedEngineer)
      return;

    // if pending session has a label, but no time selected
    // find corresponding time [as OptionType]
    if (generalWorkingHourIntervalLabel && !preselectedDateOption) {
      const selectedDateOptions = pendingSessions.reduce((acc, curr) => {
        if (curr.selectedDateOption) {
          acc.push(curr.selectedDateOption);
        }
        return acc;
      }, [] as OptionType[]);
      const availableTimeOptionsWhoseDateIsNotSelected =
        availableTimeOptions.filter((currentDateOption) => {
          return !selectedDateOptions.some((selectedDateOption) => {
            return isSameDay(
              new Date(selectedDateOption.value),
              new Date(currentDateOption.value),
            );
          });
        });
      const option = availableTimeOptionsWhoseDateIsNotSelected.find(
        (option) => {
          return option.label === generalWorkingHourIntervalLabel;
        },
      );
      if (option) {
        dispatch(
          updateSelectedDateOptionAtIndex({
            index: index,
            selectedDateOption: option,
            skipPrefill: true,
          }),
        );
        return;
      }

      // if pending session has a label and date, but no time selected
      // find corresponding time [as OptionType] (even if it's outside working hours)
      // having both a label and date indicates that another pending session has the time selected
      if (sessionISODateString) {
        const found = allTimeOptions.find(
          (option) => option.label === generalWorkingHourIntervalLabel,
        );
        if (found) {
          dispatch(
            updateSelectedDateOptionAtIndex({
              index: index,
              selectedDateOption: found,
              skipPrefill: true,
            }),
          );
        }
      }
      return;
    }

    // if pending session has a time selected
    // find the corresponding time value and make sure it matches the selected value
    if (preselectedDateOption) {
      const found = allTimeOptions.find(
        (option) => option.label === preselectedDateOption.label,
      );
      // if corresponding time cannot be found by matching the label, then try to match the startTime
      if (!found) {
        const optionWithMatchingStartTime = allTimeOptions.find(
          (option) =>
            option.label.split(" - ")[0] ===
            preselectedDateOption.label.split(" - ")[0],
        );
        // update selected time to be the corresponding time found or undefined
        dispatch(
          updateSelectedDateOptionAtIndex({
            index: index,
            selectedDateOption: optionWithMatchingStartTime,
            skipPrefill: true,
          }),
        );
        return;
      }

      // if corresponding time label was found, but the time values do not match
      // update the time value
      if (found && preselectedDateOption.value !== found.value) {
        dispatch(
          updateSelectedDateOptionAtIndex({
            index: index,
            selectedDateOption: found,
            skipPrefill: true,
          }),
        );
        return;
      }

      // If duration changes and new time range is not within working hours
      // Or if a date is selected and the time is not within working hours
      // update selectedDateOption and GeneralWorkingHourLabel to undefined
      if (
        found &&
        !found.isWorkingHour &&
        !isCurrentUserSameAsSelectedEngineer &&
        !isStudioManager
      ) {
        dispatch(
          updateSelectedDateOptionAtIndex({
            index: index,
            selectedDateOption: undefined,
            skipPrefill: true,
          }),
        );
        dispatch(
          setGeneralWorkingHourIntervalLabelAtIndex({
            index,
            selectedDateOption: undefined,
            skipPrefill: true,
          }),
        );
        return;
      }
    }
  }, [
    allTimeOptions,
    availableTimeOptions,
    durationMinutes,
    preselectedDateOption,
    dispatch,
    index,
    generalWorkingHourIntervalLabel,
    sessionISODateString,
    pendingTimeOption,
    isCurrentUserSameAsSelectedEngineer,
    isStudioManager,
    pendingSessions,
  ]);

  return (
    <Button
      fullWidth
      onClick={() => {
        if (isMobile) {
          dispatch(toggleDateTimeModal(StartTimeOption.startEndTime));
          return;
        }
      }}
      variant={ButtonVariant.UNSTYLED}
    >
      <GenerateBookingDropdown
        isSelected={isSelected}
        noBorder={[GenerateBookingDropdownNoBorder.BOTTOM]}
        roundBorder={roundBorderStyle}
        isDisabled={isMobile}
        options={timeOptions}
        placeholder="Select time"
        value={displayedOption}
        customComponents={(option) => (
          <Text
            color={
              (option as StudioWorkingHoursOptionType).isWorkingHour
                ? TEXT_COLOR.PRIMARY
                : TEXT_COLOR.ERROR
            }
          >
            {option.label}
          </Text>
        )}
        onChange={(option) => {
          if (index === undefined) return;
          if (!(option as StudioWorkingHoursOptionType).isWorkingHour) {
            setPendingTimeOption(option as WorkingHoursOptionType);
            openConfirmModal();
            return;
          }
          handleSelectTime(option as StudioWorkingHoursOptionType, index);
        }}
      />
      <BaseModal
        open={isConfirmModalOpen}
        header="Are you sure?"
        setOpen={closeConfirmModal}
        showModalFooter
        onCancel={closeConfirmModal}
        onConfirm={() => {
          if (!pendingTimeOption || index === undefined) {
            toast.error("Whoops! Something went wrong. Please try again.");
            closeConfirmModal();
            return;
          }
          handleSelectTime(pendingTimeOption, index);
          closeConfirmModal();
        }}
      >
        <Text>
          This time is outside your normal working hours. Are you sure you want
          to select this?
        </Text>
      </BaseModal>
    </Button>
  );
};
