import { faCloudArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useUserProfileImageURL } from "../../../../../hooks/useUserProfileImage";
import { downloadTrack } from "../../../../../store/actions/audioService";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { FileVersion } from "../../../../../store/models/fileVersion";
import {
  ProjectById,
  ProjectUserType,
} from "../../../../../store/models/project";
import { UserLite } from "../../../../../store/models/user";
import { downloadFile } from "../../../../../store/utils/utils";
import defaultUser from "../../../../../stories/assets/defaultuser.jpg";
import {
  canArtistDownloadDeliverables,
  getUserTypeFromProject,
} from "../../../../../utils/projectUtils";
import { ButtonVariant } from "../../../../core-ui/components/Button/Button";
import { TEXT_COLOR, Text } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import {
  ProjectHistoryDownloadFileButton,
  ProjectHistoryImageLineContainer,
  ProjectHistoryItemContainer,
  ProjectHistoryItemHeader,
  ProjectHistoryItemTextContainer,
  ProjectHistoryUserImage,
  ProjectHistoryVerticalRule,
} from "./ProjectHistory.styles";

interface ProjectHistoryItemProps {
  action: string;
  attachedFile: FileVersion | null;
  datetime: Date;
  hideLine?: boolean;
  project: ProjectById;
  user: UserLite | undefined;
  code?: string;
}

export const ProjectHistoryItem = ({
  action,
  attachedFile,
  datetime,
  hideLine = false,
  project,
  user,
  code,
}: ProjectHistoryItemProps) => {
  const [loadFailedImage, setLoadFailedImage] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { isAuthenticated, user: currentUser } = useAppSelector(
    (state) => state.accountInfo,
  );
  const imagePath = useUserProfileImageURL(user);
  const dispatch = useAppDispatch();

  const fileName = useMemo(() => {
    if (!attachedFile) return "";
    if (attachedFile.file_name && isAuthenticated) {
      return attachedFile.file_name;
    }

    const fileType = attachedFile.reference ? "reference" : "main";
    return `${fileType} ${attachedFile.upload_type} version: ${attachedFile.version}`;
  }, [attachedFile, isAuthenticated]);

  const isDownloadDisabled = (file: FileVersion) => {
    if (file.archived) return true;
    const currentUserType = getUserTypeFromProject(currentUser, project);
    if (currentUserType !== ProjectUserType.ENGINEER) {
      const artistAllowedToDownload = canArtistDownloadDeliverables(project);
      const isDeliverable = !file.reference;
      return isDeliverable && !artistAllowedToDownload;
    }
    return false;
  };

  const handleDownloadFile = () => {
    if (!attachedFile) return;
    setIsDownloading(true);
    void dispatch(
      downloadTrack({
        fileVersionId: attachedFile.id,
        code: code,
      }),
    )
      .unwrap()
      .then((response) => {
        downloadFile(response, attachedFile?.file_name ?? "no file name");
        setIsDownloading(false);
      })
      .catch(() => {
        setIsDownloading(false);
        toast.error("Failed to download file");
      });
  };

  return (
    <ProjectHistoryItemContainer>
      <ProjectHistoryImageLineContainer>
        <ProjectHistoryUserImage
          src={loadFailedImage ? defaultUser : imagePath}
          alt="user who performed the action"
          onError={() => setLoadFailedImage(true)}
        />
        {!hideLine && <ProjectHistoryVerticalRule />}
      </ProjectHistoryImageLineContainer>
      <ProjectHistoryItemTextContainer $isLastItem={hideLine}>
        <ProjectHistoryItemHeader>
          <Text bold variant={TextStyleVariant.P2}>
            {action}
          </Text>
          <Text bold color={TEXT_COLOR.TERTIARY} variant={TextStyleVariant.P2}>
            by {user?.username || "unknown"}
          </Text>
        </ProjectHistoryItemHeader>
        <Text color={TEXT_COLOR.SECONDARY} variant={TextStyleVariant.P3}>
          {datetime.toLocaleDateString()} at{" "}
          {datetime.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
          })}
        </Text>
        {attachedFile && (
          <ProjectHistoryDownloadFileButton
            disabled={isDownloading || isDownloadDisabled(attachedFile)}
            loading={isDownloading}
            onClick={handleDownloadFile}
            variant={ButtonVariant.OUTLINED}
          >
            <FontAwesomeIcon icon={faCloudArrowUp} />
            {fileName}
          </ProjectHistoryDownloadFileButton>
        )}
      </ProjectHistoryItemTextContainer>
    </ProjectHistoryItemContainer>
  );
};
