import { MutableRefObject } from "react";
import WaveSurferRef from "wavesurfer.js";
import { ColorPalette } from "../../stories/theme";

export const enum SkipTimes {
  BACKWARD_FAST = -15,
  LOCKSCREEN_BACKWARD_STEP = -10,
  BACKWARD_STEP = -5,
  FORWARD_STEP = 5,
  LOCKSCREEN_FORWARD_STEP = 10,
  FORWARD_FAST = 15,
}

export const toggleWaveFormColors = (
  currentlyPlayingWaveForm: WaveSurferRef | null,
  isSelected?: boolean,
) => {
  const waveOptions = isSelected
    ? {
        waveColor: ColorPalette.Gray400,
        progressColor: ColorPalette.BoomyOrange400,
      }
    : {
        waveColor: ColorPalette.Gray200,
        progressColor: ColorPalette.BoomyOrange200,
      };
  currentlyPlayingWaveForm?.setOptions(waveOptions);
};

export const convertWaveformDurationToReadableTime = (duration: number) => {
  return `${Math.floor(duration / 60)}:${Math.floor(duration % 60)
    .toString()
    .padStart(2, "0")}`;
};

export const skipSeconds = (
  seconds: SkipTimes,
  waveformRef: MutableRefObject<WaveSurferRef | null>,
) => {
  if (waveformRef.current) {
    waveformRef.current.skip(seconds);
  }
};
