import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum StartTimeOption {
  startTime,
  endTime,
  startEndTime,
}

export interface RecordingBookingMobileState {
  showDurationModal: boolean;
  showDateTimeModal: boolean;
  showSelectEngineerModal: boolean;
  startTimeOption: StartTimeOption;
}

const initialState: RecordingBookingMobileState = {
  showDurationModal: false,
  showDateTimeModal: false,
  showSelectEngineerModal: false,
  startTimeOption: StartTimeOption.startEndTime,
};
export const recordingBookingMobileSlice = createSlice({
  name: "recordingBookingMobile",
  initialState,
  reducers: {
    clearRecordingBookingMobileState: () => initialState,
    toggleDurationModal: (state) => {
      state.showDateTimeModal = false;
      state.showSelectEngineerModal = false;
      state.showDurationModal = !state.showDurationModal;
    },
    toggleDateTimeModal: (
      state,
      action: PayloadAction<StartTimeOption | undefined>,
    ) => {
      state.showDurationModal = false;
      state.showSelectEngineerModal = false;
      if (action.payload !== undefined) {
        state.startTimeOption = action.payload;
      }
      state.showDateTimeModal = !state.showDateTimeModal;
    },
    toggleSelectEngineerModal: (state) => {
      state.showDurationModal = false;
      state.showDateTimeModal = false;
      state.showSelectEngineerModal = !state.showSelectEngineerModal;
    },
  },
});

export const {
  clearRecordingBookingMobileState,
  toggleDurationModal,
  toggleDateTimeModal,
  toggleSelectEngineerModal,
} = recordingBookingMobileSlice.actions;

export default recordingBookingMobileSlice.reducer;
