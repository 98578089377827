import { useGetTeams } from "../../../hooks/useTeam";
import { DashboardCard } from "../../components/Dashboard/DashboardCard/DashboardCard";
import { PlatformActivityTable } from "../../components/PlatformActivityTable/PlatformActivityTable";

export interface AdminDashboardActivityCardProps {
  cardHeight?: number;
  onClick?: () => void;
}

const AdminDashboardActivityCard = ({
  cardHeight = 320,
  onClick = () => {},
}: AdminDashboardActivityCardProps) => {
  const { selectedTeam: currentTeam } = useGetTeams();
  return (
    <DashboardCard
      cardHeight={cardHeight}
      headerText={"Team Activity"}
      handleHeaderClick={onClick}
      className="no-overflow-content h-full"
      actionButtonWrapperProps={{
        style: { display: "flex", alignItems: "center" },
      }}
    >
      <PlatformActivityTable
        pageSize={3}
        onSidePanel={false}
        teamId={currentTeam?.id}
      />
    </DashboardCard>
  );
};

export default AdminDashboardActivityCard;
