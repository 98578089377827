import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const InProgressButtonRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const ApproveMixPopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
  max-width: 550px;
  min-width: 100%;
  // This helps avoid scrollbar always being displayed
  // It happens because the Stripe Form has -4px margin
  padding: 4px 0;
`;

export const SendForReviewPopoverCheckBoxRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
`;

export const SendForReviewPopoverToolTipRow = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  margin: 8px 0;
`;

export const InstagramInputCard = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundColor};
  border: solid 1px ${({ theme }) => theme.separatorColor};
  border-radius: ${({ theme }) => theme.border.radius.md};
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px 0;
  padding: 16px;
`;

export const InstagramInputContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
`;

export const InstagramLogo = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.textPrimaryColor};
  height: 40px;
  width: 40px;
`;
