import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import {
  OnApproveData,
  CreateOrderActions,
  OnApproveActions,
} from "@paypal/paypal-js/types/components/buttons";
import { Dispatch, ReactNode, SetStateAction, useEffect } from "react";
import { SoundWaveLoader } from "../SoundWaveLoader/SoundWaveLoader";
import { defaultPageLoaderSize } from "../../theme";
import { useAppDispatch } from "../../../store/hooks";
import { updateTransactionPayment } from "../../../store/actions/transactions";
import {
  FinancialMethod,
  Transaction,
} from "../../../store/models/transaction";

const currency = "USD";
export interface PayPalButtonProps {
  showPaypalButton: boolean;
  totalPrice: number;
  transaction: Transaction;
  onPaymentSuccess?: (alternativePaymentMethod: number | null) => void;
  setProcessingPayment?: Dispatch<SetStateAction<boolean>>;
  handleErr?: Dispatch<SetStateAction<string>>;
  isDisabled?: boolean;
  children?: ReactNode;
}

export const PayPalButton = ({
  showPaypalButton,
  totalPrice,
  transaction,
  setProcessingPayment,
  handleErr,
  onPaymentSuccess,
  isDisabled,
  children,
}: PayPalButtonProps) => {
  const totalPriceString = totalPrice.toFixed(2);
  const dispatch = useAppDispatch();
  const createOrder = (actions: CreateOrderActions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: totalPriceString,
            currency_code: currency,
          },
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    });
  };

  const onApprove = async (data: OnApproveData, actions: OnApproveActions) => {
    return actions.order!.capture().then(async () => {
      await dispatch(
        updateTransactionPayment({
          transaction_id: transaction.id,
          payment_id: data.orderID,
          financial_method: FinancialMethod.PAYPAL,
        }),
      )
        .unwrap()
        .then(() => {
          if (setProcessingPayment) {
            setProcessingPayment(true);
          }
          if (onPaymentSuccess) {
            onPaymentSuccess(FinancialMethod.PAYPAL);
          }
        });
    });
  };

  const onCancel = () => {
    if (setProcessingPayment) {
      setProcessingPayment(false);
    }
  };

  const onError = (err: Record<string, unknown>): void => {
    if (setProcessingPayment) {
      setProcessingPayment(false);
    }
    if (handleErr) {
      handleErr(JSON.stringify(err));
    }
  };

  const ButtonWrapper = () => {
    const [{ options, isPending, isResolved }, dispatch] =
      usePayPalScriptReducer();
    useEffect(() => {
      dispatch({
        type: "resetOptions",
        value: {
          ...options,
        },
      });
    }, [dispatch]);
    return (
      <div className={"paypal-button"}>
        {isPending && !isResolved && (
          <SoundWaveLoader
            width={defaultPageLoaderSize}
            height={defaultPageLoaderSize}
          />
        )}
        <PayPalButtons
          style={{
            layout: "vertical",
            color: "gold",
            shape: "rect",
            label: "checkout",
          }}
          disabled={!isResolved || isDisabled}
          fundingSource={"paypal"}
          createOrder={(_: any, actions: CreateOrderActions) =>
            createOrder(actions)
          }
          onApprove={(data: OnApproveData, actions: OnApproveActions) =>
            onApprove(data, actions)
          }
          onError={(err: Record<string, unknown>) => onError(err)}
          onCancel={onCancel}
        />
      </div>
    );
  };
  if (!showPaypalButton) {
    return null;
  }
  return (
    <div>
      <ButtonWrapper />
      {children}
    </div>
  );
};
