import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useTransactionTotalAsPennies } from "../../../hooks/transactionHook";
import { postSessionModification } from "../../../store/actions/recording";
import {
  checkoutRecordingSessionExtension,
  CheckoutRecordingSessionExtensionParams,
  markRecordingSessionExtensionPaid,
} from "../../../store/actions/transactions";
import { useAppDispatch } from "../../../store/hooks";
import { RecordingSession } from "../../../store/models/recordingSession";
import { Transaction } from "../../../store/models/transaction";
import {
  convertUTCDateToLocalDate,
  getFormattedDateTime,
} from "../../../store/utils/dateTimeUtils";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { StripePaymentFormHandles } from "../StripePaymentForm/StripePaymentForm";
import { Checkout } from "./Checkout";
import { DurationSelection } from "./DurationSelection";

export enum ExtendRecordingSessionActionStep {
  SELECT_DURATION,
  PAYMENT,
}

export interface ExtendSessionModalProps {
  recordingSession: RecordingSession;
  showModal: boolean;
  onClose: () => void;
}

export const ExtendSessionModal = ({
  recordingSession,
  showModal,
  onClose,
}: ExtendSessionModalProps) => {
  const {
    actionStep,
    durationSelected,
    pricesLoading,
    amountInDollars,
    createTransactionLoading,
    submittingPayment,
    setDurationSelected,
    setExtensionDuration,
    params,
    setParams,
    pendingTransaction,
    stripePaymentFormRef,
    handleSubmit,
    originalDuration,
    originalSessionEndTime,
  } = useExtendSession(recordingSession, onClose);

  return (
    <BaseModal
      header={"Extend Recording Session"}
      open={showModal}
      setOpen={onClose}
      showModalFooter={true}
      onConfirm={handleSubmit}
      confirmText={
        actionStep === ExtendRecordingSessionActionStep.SELECT_DURATION
          ? "Extend Session"
          : `Checkout (${PennyDollarFormatter().format(amountInDollars)})`
      }
      loading={createTransactionLoading || submittingPayment}
      onCancel={onClose}
    >
      <ExtendSessionContent
        recordingSession={recordingSession}
        actionStep={actionStep}
        pendingTransaction={pendingTransaction}
        setExtensionDuration={setExtensionDuration}
        stripePaymentFormRef={stripePaymentFormRef}
        durationSelected={durationSelected}
        setDurationSelected={setDurationSelected}
        params={params}
        setParams={setParams}
        pricesLoading={pricesLoading}
        originalDuration={originalDuration}
        originalSessionEndTime={originalSessionEndTime}
      />
    </BaseModal>
  );
};

export const useExtendSession = (
  recordingSession: RecordingSession,
  onClose: () => void,
) => {
  const dispatch = useAppDispatch();

  const [createTransactionLoading, setCreateTransactionLoading] =
    useState(false);

  const projectId = recordingSession.project.id;
  const [actionStep, setActionStep] =
    useState<ExtendRecordingSessionActionStep>(
      ExtendRecordingSessionActionStep.SELECT_DURATION,
    );
  const [pendingTransaction, setPendingTransaction] =
    useState<Transaction | null>(null);

  const originalDuration = recordingSession.session_duration_minutes;

  const [durationSelected, setDurationSelected] = useState<OptionType>();
  const [extensionDuration, setExtensionDuration] = useState<number>(0);
  const [pricesLoading] = useState<boolean>(false);

  const originalSessionEndTime = useMemo(() => {
    const first_choice_datetime = recordingSession.first_choice_datetime;
    const end_time = new Date(first_choice_datetime);
    end_time.setMinutes(end_time.getMinutes() + originalDuration);
    return end_time;
  }, [recordingSession?.first_choice_datetime]);
  const originalSessionEndTimeLocalDate = new Date(
    convertUTCDateToLocalDate(originalSessionEndTime),
  );

  const projectTitle = recordingSession.project.title;
  const [params, setParams] = useState<CheckoutRecordingSessionExtensionParams>(
    {
      project_id: projectId,
      recording_session_id: recordingSession.id,
      recording_data: [
        {
          recording_service_id: 0,
          service_type: 1,
          first_choice_datetime: getFormattedDateTime(
            originalSessionEndTimeLocalDate,
          ),
          session_duration_minutes: 0,
          title: projectTitle + " - Extend Session",
        },
      ],
    },
  );

  const handleExtendSession = useCallback(
    async (extensionDuration: number) => {
      if (!projectId) return false;

      return dispatch(
        postSessionModification({
          project_id: +projectId,
          requested_data: {
            new_session_duration_minutes: extensionDuration,
          },
        }),
      )
        .unwrap()
        .catch(() => {
          toast.error(
            "There was an error requesting a extension. Please try again.",
          );
        });
    },
    [dispatch],
  );

  const appendSessionModificationToTransaction = useCallback(
    async (transaction: Transaction) => {
      return dispatch(
        markRecordingSessionExtensionPaid({
          transaction_id: transaction.id,
          append_session_modification: true,
        }),
      )
        .unwrap()
        .catch(() => {
          toast.error("Something went wrong, reach out to customer support");
        });
    },
    [dispatch],
  );

  const createExtendSessionTransaction = useCallback(() => {
    if (!params) return;
    setCreateTransactionLoading(true);
    dispatch(checkoutRecordingSessionExtension(params))
      .unwrap()
      .then((response) => {
        const transaction = response;
        setPendingTransaction(transaction);
        setCreateTransactionLoading(false);
      })
      .catch(() => {
        toast.error("Something went wrong, reach out to customer support");
        setCreateTransactionLoading(false);
      });
  }, [dispatch, params]);

  const handlePaymentSuccess = async () => {
    if (pendingTransaction) {
      onClose();
      await handleExtendSession(extensionDuration);
      await appendSessionModificationToTransaction(pendingTransaction);
    }
  };
  const [submittingPayment, setSubmittingPayment] = useState(false);
  const amount = useTransactionTotalAsPennies(pendingTransaction);
  const amountInDollars = amount / 100;
  const stripePaymentFormRef = useRef<StripePaymentFormHandles>(null);
  const handleConfirmClick = async () => {
    try {
      setSubmittingPayment(true);
      await stripePaymentFormRef.current?.handleSubmit();
      if (handlePaymentSuccess) handlePaymentSuccess();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred while processing your payment");
      }
    } finally {
      setSubmittingPayment(false);
    }
  };

  const handleSubmit = () => {
    if (actionStep === ExtendRecordingSessionActionStep.SELECT_DURATION) {
      if (durationSelected && !pricesLoading) {
        createExtendSessionTransaction();
        setActionStep(ExtendRecordingSessionActionStep.PAYMENT);
      }
    } else {
      void handleConfirmClick();
    }
  };

  return {
    actionStep,
    setActionStep,
    durationSelected,
    pricesLoading,
    amountInDollars,
    createTransactionLoading,
    submittingPayment,
    setDurationSelected,
    setExtensionDuration,
    params,
    setParams,
    pendingTransaction,
    stripePaymentFormRef,
    handleSubmit,
    originalDuration,
    originalSessionEndTime,
  };
};

interface ExtendSessionContentProps {
  recordingSession: RecordingSession;
  actionStep: ExtendRecordingSessionActionStep;
  pendingTransaction: Transaction | null;
  setExtensionDuration: Dispatch<SetStateAction<number>>;
  stripePaymentFormRef: RefObject<StripePaymentFormHandles>;
  durationSelected?: OptionType<number>;
  setDurationSelected: Dispatch<SetStateAction<OptionType<number> | undefined>>;
  params: CheckoutRecordingSessionExtensionParams;
  setParams: Dispatch<SetStateAction<CheckoutRecordingSessionExtensionParams>>;
  pricesLoading: boolean;
  originalDuration: number;
  originalSessionEndTime: Date;
}

export const ExtendSessionContent = ({
  recordingSession,
  actionStep,
  pendingTransaction,
  setExtensionDuration,
  stripePaymentFormRef,
  durationSelected,
  setDurationSelected,
  params,
  setParams,
  pricesLoading,
  originalDuration,
  originalSessionEndTime,
}: ExtendSessionContentProps) => {
  return [
    <DurationSelection
      recordingSession={recordingSession}
      originalDuration={originalDuration}
      originalSessionEndTime={originalSessionEndTime}
      durationSelected={durationSelected}
      setDurationSelected={setDurationSelected}
      setExtensionDuration={setExtensionDuration}
      params={params}
      setParams={setParams}
      pricesLoading={pricesLoading}
      key={"extend-recording-session-selection-modal"}
    />,
    <Checkout
      transaction={pendingTransaction}
      stripePaymentFormRef={stripePaymentFormRef}
      key={"extend-recording-session-checkout-modal"}
    />,
  ][actionStep];
};
