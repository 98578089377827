import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { createRecordingServiceParams } from "../../store/actions/recording";
import { workingHoursParam } from "../../store/actions/workingHours";
import {
  SelectAMPMOptions,
  SelectHourInDayOptions,
} from "../../store/models/alts";
import { RecordingService } from "../../store/models/recording";
import User from "../../store/models/user";
import {
  convertBinaryArrayToBinaryString,
  convertTimeToHour,
  FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE,
  fillInAvailability,
  fillInUnavailability,
  INVALID_AM_PM,
  NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
  WEEKDAYS,
} from "../../store/models/workingHours";
import {
  convertDiscountRatesToDisplayableRates,
  convertMinutesToHourOptionType,
} from "../../store/utils/serviceUtils";
import { DiscountRateService } from "../../stories/components/AddRecordingService/AddRecordingService";
import { OptionType } from "../../stories/elements/DropDownSelector/DropdownSelector";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { useCreateRecordingService } from "./useCreateRecordingService";
import { useCreateWorkingHours } from "./useCreateWorkingHours";

interface useRecordingServiceFormProps {
  existingService: RecordingService | undefined;
  pendingFormData: createRecordingServiceParams | undefined;
  isCreatingNewService: boolean;
  userData: User;
}

export const useRecordingServiceForm = ({
  existingService,
  pendingFormData,
  isCreatingNewService,
  userData,
}: useRecordingServiceFormProps) => {
  const queryClient = useQueryClient();
  const [weeklyWorkDates, setWeeklyWorkDates] = useState<WEEKDAYS[]>([]);
  const [startTime, setStartTime] = useState<OptionType>(
    SelectHourInDayOptions[0],
  );
  const [endTime, setEndTime] = useState<OptionType>(SelectHourInDayOptions[0]);
  const [startAMPM, setStartAMPM] = useState<OptionType>(SelectAMPMOptions[0]);
  const [endAMPM, setEndAMPM] = useState<OptionType>(SelectAMPMOptions[0]);
  const [recordingLocation, setRecordingLocation] = useState<
    undefined | google.maps.places.PlaceResult
  >(undefined);
  const [discountRates, setDiscountRate] = useState<DiscountRateService[]>(
    existingService
      ? convertDiscountRatesToDisplayableRates(existingService)
      : [],
  );

  const {
    isPending: isCreatingRecordingService,
    mutateAsync: createRecordingService,
  } = useCreateRecordingService();
  const { isPending: isCreatingWorkingHours, mutateAsync: createWorkingHours } =
    useCreateWorkingHours();
  const hasWorkingHoursBeenFetched =
    queryClient.getQueryState([QUERY_KEYS.GET_WORKING_HOURS_USER, userData.id])
      ?.status === "success";

  const handleCreateWorkingHours = async () => {
    const workingHours: workingHoursParam = {
      [WEEKDAYS.MONDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.TUESDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.WEDNESDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.THURSDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.FRIDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.SATURDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.SUNDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
    };

    for (const weekday of weeklyWorkDates) {
      const startHour = convertTimeToHour(startTime.value, startAMPM.value);
      const endHour = convertTimeToHour(endTime.value, endAMPM.value);
      if (startHour === INVALID_AM_PM || endHour === INVALID_AM_PM) {
        toast.error('Please select a valid start and end time."');
        return;
      }
      if (startHour < endHour) {
        workingHours[weekday] = convertBinaryArrayToBinaryString(
          fillInUnavailability(startHour, endHour),
        );
      } else {
        workingHours[weekday] = convertBinaryArrayToBinaryString(
          fillInAvailability(endHour, startHour),
        );
      }
    }

    try {
      await createWorkingHours({ working_hours: workingHours });
    } catch (error) {
      // The error has already been handled
    }
  };

  const handleCreateRecordingService = async () => {
    if (!pendingFormData) return;

    const pendingDiscountRates = discountRates.map(
      (discountRate) => discountRate.discount,
    );

    emitAnalyticsTrackingEvent(
      !isCreatingNewService
        ? "recording_service_updated"
        : "recording_service_created",
      {
        serviceType: "Recording",
        recordingLocation: recordingLocation?.formatted_address,
        minimumSessionTime: convertMinutesToHourOptionType(
          pendingFormData.minimum_session_time_minutes,
        ).value,
        maximumSessionTime: convertMinutesToHourOptionType(
          pendingFormData.maximum_session_time_minutes,
        ).value,
        minimumGapBetweenSessions:
          pendingFormData.minimum_gap_between_sessions_in_minutes,
        numberHoursNotice: pendingFormData.number_of_hours_notice,
        discountRates: pendingDiscountRates,
      },
      userData.id,
    );

    const serviceFormData = {
      ...pendingFormData,
      recording_location: recordingLocation,
      discount_rates: pendingDiscountRates,
    };

    try {
      const service = await createRecordingService(serviceFormData);

      return service;
    } catch (error) {
      // The error has already been handled
    }
  };

  const isSubmitting = isCreatingRecordingService || isCreatingWorkingHours;

  return {
    weeklyWorkDates,
    setWeeklyWorkDates,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    startAMPM,
    setStartAMPM,
    endAMPM,
    setEndAMPM,
    recordingLocation,
    setRecordingLocation,
    discountRates,
    setDiscountRate,
    hasWorkingHoursBeenFetched,
    handleCreateWorkingHours,
    handleCreateRecordingService,
    isSubmitting,
  };
};
