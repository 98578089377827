import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { PaymentRedirectType } from "../../../store/models/transaction";
import { StripePaymentRedirectPage } from "./StripePaymentRedirectPage";

const KlarnaPaymentRedirectScreen = () => {
  useSetPageTitle("Klarna Payment");

  return (
    <StripePaymentRedirectPage
      paymentRedirectType={PaymentRedirectType.KLARNA}
    />
  );
};

export default KlarnaPaymentRedirectScreen;
