import { styled, Box } from "@mui/material";

export const SubscriptionComparisonTableColumn = styled(Box)<{
  $isRoundedCorner?: boolean;
}>(({ theme, $isRoundedCorner }) => ({
  display: "flex",
  maxWidth: "326px",
  paddingTop: "24px",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  backgroundColor: $isRoundedCorner
    ? theme.palette.background.paper
    : theme.palette.background.default,
  borderRadius: $isRoundedCorner ? theme.border.radius.lg : "0px",
  boxShadow: $isRoundedCorner ? "0px 2px 30px 0px rgba(0, 0, 0, 0.15)" : "none",

  [theme.breakpoints.down("md")]: {
    maxWidth: "140px",
  },
}));

export const SubscriptionFeatureList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "10px",
  margin: "10px",
  maxWidth: "400px",

  [theme.breakpoints.down("md")]: {
    maxWidth: "150px",
    padding: "8px",
  },
}));

export const SubscriptionFeatureItem = styled(Box)(({ theme }) => ({
  display: "flex",
  minHeight: "72px",
  padding: "16px",
  alignItems: "center",
  alignSelf: "stretch",

  // Using custom breakpoint temporarily until table is redone for better responsiveness
  [theme.breakpoints.down(992)]: {
    padding: "8px",
  },

  [theme.breakpoints.down("md")]: {
    height: "160px",
  },
}));

export const SubscriptionFeatureItemDivider = styled(Box)(({ theme }) => ({
  width: "calc(100% + 40px)",
  height: "1px",
  backgroundColor: theme.palette.divider,
  marginLeft: "-20px",
  marginRight: "-20px",
}));
