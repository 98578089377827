import styled from "styled-components";

export const ShareEmailInputContainer = styled.div`
  .share-booking-link-multi-email {
    width: 400px;
    align-items: flex-start;
  }

  .share-booking-link-multi-email.empty > span[data-placeholder] {
    // This makes the input container become 1 line,
    // making it look like an input to enter emails,
    // despite react-multi-email having 2 elements
    position: absolute;
  }
`;
