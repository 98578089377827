import { useGetReadableAlts } from "../../../hooks/checkoutHooks/useGetProjectTypeToProjectTypeMetadata";
import { AltItem } from "../../../store/models/alts";
import {
  ProjectType,
  projectTypeReadableName,
} from "../../../store/models/project";
import { ToolTipTextArea } from "../../components/ToolTipTextArea/ToolTipTextArea";
import {
  AltListContainer,
  AltListTitleContainer,
  AltListText,
} from "./AltsList.styles";

export interface AltsListProps {
  projectType: ProjectType;
  alts?: AltItem[] | null;
  showContent: boolean;
}

export const AltsList = ({ projectType, alts, showContent }: AltsListProps) => {
  const altsString = useGetReadableAlts(alts, projectType);

  if (!showContent) {
    return null;
  }

  return (
    <AltListContainer>
      <AltListTitleContainer>
        <AltListText
          weight
        >{`${projectTypeReadableName.get(projectType)} versions`}</AltListText>
        <ToolTipTextArea text={"Track alt versions included in the booking"} />
      </AltListTitleContainer>
      <AltListText style={{ maxWidth: "75%", marginTop: "9px" }}>
        {altsString}
      </AltListText>
    </AltListContainer>
  );
};
