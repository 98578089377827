import styled from "styled-components";

export const SimpleTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const SimpleTableTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 4px;
  margin-bottom: 12px;
`;

export const SimpleTableHeader = styled.div`
  display: flex;
  padding-bottom: 6px;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
`;

export const SimpleTableBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SimpleTableText = styled.p<{
  weight?: boolean;
  isLarge?: boolean;
}>`
  font-weight: ${({ weight }) => (weight ? "600" : "400")};
  font-size: ${({ theme, isLarge }) =>
    isLarge ? theme.textSizeLg : theme.textSizeMd};
  color: ${({ theme }) => theme.textPrimaryColor};
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow-x: hidden;
`;
