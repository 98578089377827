import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useGetUserProfile } from "../../../../hooks/profileScreenHooks/useGetUserProfile";
import { useIsVisitingOwnProfile } from "../../../../hooks/profileScreenHooks/useIsVisitingOwnProfile";
import { useEngineerCanHostServices } from "../../../../hooks/useEngineerCanHostServices";
import { useGetEngineerServices } from "../../../../hooks/useGetEngineerServices";
import { useQueryParam } from "../../../../hooks/useQueryParam";
import {
  usePaginatedReviews,
  useUserReviews,
} from "../../../../hooks/useUserReviews";
import { SCREENS } from "../../../../routes";
import {
  downloadReviewsArgs,
  downloadReviewStats,
} from "../../../../store/actions/stats";
import { createTransaction } from "../../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getTransactionBookingScreenRoute } from "../../../../store/utils/routeGetters";
import { BookingParameters } from "../../../../store/utils/transactions";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { SoundWaveLoader } from "../../../elements/SoundWaveLoader/SoundWaveLoader";
import { ProfileScreenParamKeys } from "../../../screens/ProfileScreen/utils";
import { NoReviewsButton } from "./NoReviewsButton";
import { ReviewsListV2 } from "./ReviewsListV2";

export const ReviewsListV2Wrapper = () => {
  const order_by = "-created";
  const dispatch = useAppDispatch();
  const { username } = useParams<{ username: string }>();
  const { data: userDataForProfileScreen, isPending: isLoadingUserData } =
    useGetUserProfile(username);
  const { user: userMe } = useAppSelector((state) => state.accountInfo);
  const [isGeneratingTransaction, setIsGeneratingTransaction] = useState(false);
  const history = useHistory();
  const userOnOwnProfile = useMemo(() => {
    return userMe?.username === username;
  }, [userMe?.username, username]);
  const { set: setSelectedTab } = useQueryParam(
    ProfileScreenParamKeys.SelectedTab,
  );

  const reviews = usePaginatedReviews(
    userDataForProfileScreen?.artist,
    userDataForProfileScreen?.engineer,
  );

  const reviewsState = useUserReviews(
    userDataForProfileScreen?.artist,
    userDataForProfileScreen?.engineer,
  );

  const isVisitingOwnProfile = useIsVisitingOwnProfile();

  const { data: engineerServicesData, isLoading: isFetchingServices } =
    useGetEngineerServices(userDataForProfileScreen?.engineer?.id ?? 0);

  const engineerHasServices = Boolean(engineerServicesData?.length);

  const isEngineer = useMemo(() => {
    return Boolean(
      userDataForProfileScreen?.engineer &&
        !userDataForProfileScreen?.engineer?.deleted,
    );
  }, [userDataForProfileScreen?.engineer]);

  const engineerCanHostServices = useEngineerCanHostServices(
    userDataForProfileScreen?.engineer,
  );

  const {
    loading: loading,
    paginating: paginating,
    current_page: currentPage,
    total_pages: totalPages,
    ratings_count: ratingsCount,
    average_rating: averageRating,
  } = reviewsState || {
    paginating: false,
    loading: false,
    current_page: 0,
    total_pages: 0,
    average_rating: 0,
  };

  useEffect(() => {
    if (!userDataForProfileScreen) return;
    const reviewObject: downloadReviewsArgs = {
      page: 1,
      order_by,
    };
    if (isEngineer) {
      reviewObject.engineer_id = userDataForProfileScreen.engineer?.id;
    } else if (
      userDataForProfileScreen.artist &&
      !userDataForProfileScreen.artist.deleted
    ) {
      reviewObject.artist_id = userDataForProfileScreen.artist.id;
    }
    void dispatch(downloadReviewStats(reviewObject));
  }, [
    dispatch,
    userDataForProfileScreen?.artist?.id,
    userDataForProfileScreen?.engineer?.id,
  ]);

  const loadMore = useCallback(() => {
    if (currentPage >= totalPages) return;
    if (!userDataForProfileScreen) return;
    const reviewObject: downloadReviewsArgs = {
      page: currentPage + 1,
      order_by,
    };
    if (isEngineer) {
      reviewObject.engineer_id = userDataForProfileScreen?.engineer?.id;
    } else if (
      userDataForProfileScreen.artist &&
      !userDataForProfileScreen.artist.deleted
    ) {
      reviewObject.artist_id = userDataForProfileScreen.artist.id;
    }
    if (!reviewObject.artist_id && !reviewObject.engineer_id) {
      return;
    }
    emitAnalyticsTrackingEvent("clicked_loading_more_user_reviews", {
      user_profile: userDataForProfileScreen?.username,
    });

    void dispatch(downloadReviewStats(reviewObject));
  }, [currentPage, totalPages, userDataForProfileScreen, dispatch]);

  const generateBookingClick = async () => {
    if (!isEngineer || !userOnOwnProfile) {
      return;
    }
    setIsGeneratingTransaction(true);
    const transaction = await dispatch(createTransaction()).unwrap();
    const bookingParameters: BookingParameters = {
      transactionId: +transaction.id,
      engineerId: userDataForProfileScreen?.engineer?.id,
      activeServiceTypeProjectIds: [],
      engineerUserId: userDataForProfileScreen?.id,
    };

    emitAnalyticsTrackingEvent(
      "clicked_generate_booking_from_review",
      {
        transaction_id: bookingParameters.transactionId,
        engineer_user_id: bookingParameters.engineerUserId,
        service_type: bookingParameters.activeServiceType,
      },
      userMe?.id,
    );
    setIsGeneratingTransaction(false);

    history.push(
      getTransactionBookingScreenRoute(
        transaction.code,
        dispatch,
        bookingParameters,
      ),
    );
  };
  const buttonText = () => {
    if (!isEngineer) {
      return "Explore profiles";
    }

    if (engineerHasServices && reviews.length === 0) {
      return "Generate your first booking";
    }

    if (engineerCanHostServices && !engineerHasServices) {
      return "Add a service first to begin booking";
    }
    return "";
  };

  const handleNoReviewsButtonClick = () => {
    if (!isEngineer) {
      emitAnalyticsTrackingEvent(
        "clicked_explore_profiles_from_review",
        {},
        userMe?.id,
      );

      history.push(SCREENS.SEARCH);
      return;
    }
    if (engineerHasServices && reviews.length === 0) {
      void generateBookingClick();
      return;
    }
    addServicesClick();
  };

  const addServicesClick = () => {
    if (!isEngineer || !userOnOwnProfile) {
      return;
    }
    setSelectedTab("services");
  };

  if (isLoadingUserData || loading || isFetchingServices) {
    return <SoundWaveLoader height={100} width={100} />;
  }

  if (reviews.length === 0 && isVisitingOwnProfile && engineerCanHostServices) {
    return (
      <NoReviewsButton
        isLoading={isGeneratingTransaction}
        onClick={handleNoReviewsButtonClick}
        buttonText={buttonText()}
      />
    );
  }
  return (
    <ReviewsListV2
      averageRating={averageRating}
      ratingsCount={ratingsCount}
      reviews={reviews}
      showMore={loadMore}
      disabled={currentPage >= totalPages}
      loading={loading || paginating}
      totalPages={totalPages}
    />
  );
};
