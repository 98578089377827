import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { useDispatch } from "react-redux";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { useAppSelector } from "../../store/hooks";
import { toast } from "react-toastify";
import { addCreatedPromoCode } from "../../store/actions/marketing";
import { getDebugEventUserIdPrefix } from "../../utils/analyticsUtils";
import createPromoCode, {
  CreatePromoCodeArgs,
} from "../../api/marketing/createPromoCode";

const useCreatePromoCodeMutation = () => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  return useMutation({
    mutationKey: [QUERY_KEYS.CREATE_PROMO_CODE],
    mutationFn: async (params: CreatePromoCodeArgs) => {
      emitAnalyticsTrackingEvent(
        "create_custom_promo_code",
        params.studio_id
          ? {
              studio_id: `${getDebugEventUserIdPrefix}${params.studio_id}`,
            }
          : {},
        user?.id,
      );
      return createPromoCode(params)
        .then(async (promoCode) => {
          dispatch(addCreatedPromoCode(promoCode));
          return promoCode;
        })
        .catch(() => {
          toast("Something went wrong. Please try again.");
        });
    },
    retry: 0,
  });
};

export default useCreatePromoCodeMutation;
