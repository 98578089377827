import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RecordingSession } from "../models/recordingSession";
import { getFormattedDate } from "../utils/dateTimeUtils";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { SCHEDULE_OVERVIEW } from "../utils/routes";
import { receiveErrors } from "./errorStore";
import {
  acceptBookingRecordingSession,
  acceptSessionModification,
  cancelSessionAndIssueAssociatedRefund,
  postSessionModification,
  rejectBookingRecordingSession,
  rejectSessionModification,
} from "./recording";

interface EntitySessions {
  [id: number]: RecordingSession[];
}

export interface ScheduledSessionsState {
  studioSessions: EntitySessions;
  userSessions: EntitySessions;
}

const initialState: ScheduledSessionsState = {
  studioSessions: {},
  userSessions: {},
};

interface loadScheduleOverviewParams {
  startDate: Date;
  endDate: Date;
  studioId?: number;
  userId: number;
}

export interface ScheduleOverviewResponse {
  recording_sessions: RecordingSession[];
}

const addSession = (
  session: RecordingSession,
  id: number,
  state: EntitySessions,
) => {
  const data = state[id] || [];
  if (!data.some((s) => s.id === session.id)) {
    state[id] = [...data, session];
  } else {
    state[id] = data.map((s) => (s.id === session.id ? session : s));
  }
};

const updateCachedScheduledSession = (
  recordingSession: RecordingSession,
  scheduledSessionsRootState: ScheduledSessionsState,
) => {
  if (recordingSession.studio_room?.studio) {
    addSession(
      recordingSession,
      recordingSession.studio_room.studio.id,
      scheduledSessionsRootState.studioSessions,
    );
  }
  if (recordingSession.engineer?.user_id) {
    addSession(
      recordingSession,
      recordingSession.engineer.user_id,
      scheduledSessionsRootState.userSessions,
    );
  }
  if (recordingSession.artist?.user_id) {
    addSession(
      recordingSession,
      recordingSession.artist.user_id,
      scheduledSessionsRootState.userSessions,
    );
  }
};

const removeCachedScheduledSession = (
  session: RecordingSession,
  scheduledSessionsRootState: ScheduledSessionsState,
) => {
  const { studioSessions, userSessions } = scheduledSessionsRootState;
  if (
    session.studio_room?.studio &&
    studioSessions[session.studio_room.studio.id]?.length > 0
  ) {
    studioSessions[session.studio_room.studio.id] = studioSessions[
      session.studio_room.studio.id
    ].filter((cachedSession) => cachedSession.id !== session.id);
  }
  if (
    session.engineer?.user_id &&
    userSessions[session.engineer.user_id]?.length > 0
  ) {
    userSessions[session.engineer.user_id] = userSessions[
      session.engineer.user_id
    ].filter((cachedSession) => cachedSession.id !== session.id);
  }
  if (
    session.artist?.user_id &&
    userSessions[session.artist.user_id]?.length > 0
  ) {
    userSessions[session.artist.user_id] = userSessions[
      session.artist.user_id
    ].filter((cachedSession) => cachedSession.id !== session.id);
  }
};

export const loadScheduleSessions = createAsyncThunk(
  SCHEDULE_OVERVIEW,
  async (args: loadScheduleOverviewParams, thunkAPI) => {
    const { startDate, endDate, studioId } = args;
    const formattedStartDate = getFormattedDate(startDate);
    const formattedEndDate = getFormattedDate(endDate);
    let params = `?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
    if (studioId) {
      params += `&studio_id=${studioId}`;
    }
    const response =
      await makeBackendGetCallWithJsonResponse<ScheduleOverviewResponse>(
        SCHEDULE_OVERVIEW,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

const scheduledSessionsSlice = createSlice({
  name: "scheduledSessions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadScheduleSessions.fulfilled, (state, action) => {
      const userId = action.meta.arg.userId;
      const sessions = action.payload.recording_sessions;
      for (const session of sessions) {
        if (session.studio_room?.studio) {
          addSession(
            session,
            session.studio_room.studio.id,
            state.studioSessions,
          );
        }
        addSession(session, userId, state.userSessions);
      }
    });
    builder.addCase(
      rejectBookingRecordingSession.fulfilled,
      (state, action) => {
        for (const session of action.payload.recording_sessions) {
          removeCachedScheduledSession(session, state);
        }
      },
    );
    builder.addCase(
      cancelSessionAndIssueAssociatedRefund.fulfilled,
      (state, action) => {
        removeCachedScheduledSession(action.payload, state);
      },
    );
    builder.addCase(
      acceptBookingRecordingSession.fulfilled,
      (state, action) => {
        for (const session of action.payload.recording_sessions) {
          updateCachedScheduledSession(session, state);
        }
      },
    );

    builder.addMatcher(
      isAnyOf(
        acceptSessionModification.fulfilled,
        rejectSessionModification.fulfilled,
        postSessionModification.fulfilled,
      ),
      (state, action) => {
        updateCachedScheduledSession(action.payload, state);
      },
    );
  },
});

export default scheduledSessionsSlice.reducer;
