import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { localServiceCoverPhotoAtom } from "../../../atoms/profileScreenEdit";
import { DEFAULT_SERVICE_COVER_IMAGE_SRC } from "../../../constants/googleStorage";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import {
  useGetServicePhotoSignedUrl,
  useUploadServicePhoto,
} from "../../../hooks/profileScreenHooks/useUploadServicePhoto";
import { ProjectType } from "../../../store/models/project";
import { RecordingService } from "../../../store/models/recording";
import Service from "../../../store/models/service";
import { BucketName, getImageProps } from "../../../store/utils";
import { getServiceFromServiceType } from "../../../store/utils/serviceUtils";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { getBase64 } from "../../elements/UserProfileImage/UserProfileImage";
import {
  getCoverPhotoContainerSx,
  getOverlayLayerSx,
  StyledImage,
} from "./ServiceCoverPhoto.styles";

const accept = [".jpg", ".jpeg"];
const MAXIMUM_SERVICE_COVER_PHOTO_SIZE = 10 * 1024 * 1024;

interface ServiceCoverPhotoProps {
  service?: Service | RecordingService;
  engineerId: number;
  serviceType: ProjectType;
}

export const ServiceCoverPhoto = ({
  service,
  engineerId,
  serviceType,
}: ServiceCoverPhotoProps) => {
  const queryClient = useQueryClient();
  const { mutateAsync: getSignedUrl, isPending: isGetSignedUrlLoading } =
    useGetServicePhotoSignedUrl();
  const { mutateAsync: uploadPhoto, isPending: isUploadPhotoLoading } =
    useUploadServicePhoto();
  const [localServiceCoverPhoto, setLocalServiceCoverPhoto] = useAtom(
    localServiceCoverPhotoAtom,
  );
  const [localBase64CoverPhoto, setLocalBase64CoverPhoto] = useState("");

  useEffect(() => {
    const getBase64FromFile = async () => {
      if (localServiceCoverPhoto) {
        const base64 = (await getBase64(localServiceCoverPhoto)) as string;
        setLocalBase64CoverPhoto(base64);
      }
    };

    void getBase64FromFile();
  }, [localServiceCoverPhoto]);

  const handleUploadPhoto = async (file: File) => {
    // If there is no existing service, it means we're creating one
    // So we need to store the photo on the FE, before uploading it when we create the service
    if (!service) {
      setLocalServiceCoverPhoto(file);
      return;
    }

    try {
      const { signed_url, photo_path } = await getSignedUrl({
        service_id: service.id!,
        service_type: service.service_type,
        content_type: file.type,
        file_size: file.size,
      });

      await uploadPhoto({ url: signed_url, file });

      queryClient.setQueryData(
        [QUERY_KEYS.GET_ENGINEER_SERVICES, engineerId],
        (oldData: (Service | RecordingService)[] | undefined) => {
          if (!oldData) {
            return undefined;
          }

          return oldData.map((oldService) => {
            if (oldService.service_type === service.service_type) {
              return {
                ...oldService,
                cover_photo: {
                  ...oldService.cover_photo,
                  path: photo_path,
                },
              };
            }
            return oldService;
          });
        },
      );
    } catch (error) {
      // Error has been handled inside Query hooks
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      toast.error(
        "There was an error uploading the file! Please try again with another file!",
      );
      return;
    }

    const selectedFile = acceptedFiles[0];
    if (selectedFile.size > MAXIMUM_SERVICE_COVER_PHOTO_SIZE) {
      toast.error("The uploaded cover photo exceeds maximum size (10MB)");
      return;
    }
    void handleUploadPhoto(selectedFile);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept,
    multiple: false,
    disabled: isGetSignedUrlLoading || isUploadPhotoLoading,
  });

  const imageProps = service?.cover_photo?.path
    ? getImageProps(
        BucketName.SERVICE_COVER_PHOTOS,
        service.cover_photo.path,
        DEFAULT_SERVICE_COVER_IMAGE_SRC,
      )
    : {};

  const renderServiceCoverPhotoContent = () => {
    if (isGetSignedUrlLoading || isUploadPhotoLoading) {
      return <SoundWaveLoader width={100} height={100} />;
    }

    return (
      <>
        <StyledImage
          alt={`${getServiceFromServiceType(serviceType, true)} cover photo`}
          src={localBase64CoverPhoto || DEFAULT_SERVICE_COVER_IMAGE_SRC}
          {...imageProps}
        />
        <Box
          sx={(theme) => ({
            ...getOverlayLayerSx(theme),
            opacity: 0,
          })}
          className="hover-overlay"
        >
          <Text
            variant={TextStyleVariant.P2}
            weight={TEXT_WEIGHT.SEMI_BOLD}
            style={{ color: "white" }}
          >
            <FontAwesomeIcon icon={faPencil} />
            <span style={{ marginLeft: "8px" }}>
              {service?.cover_photo?.path || localServiceCoverPhoto
                ? "Update media"
                : "Add media"}
            </span>
          </Text>
        </Box>
      </>
    );
  };

  return (
    <Box sx={getCoverPhotoContainerSx} {...getRootProps()}>
      {renderServiceCoverPhotoContent()}

      {isDragActive && (
        <Box sx={getOverlayLayerSx}>
          <Text
            variant={TextStyleVariant.P2}
            weight={TEXT_WEIGHT.SEMI_BOLD}
            style={{ color: "white" }}
          >
            Drop it here
          </Text>
        </Box>
      )}

      <input {...getInputProps()} />
    </Box>
  );
};
