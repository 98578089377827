import { fetchReleaseDetails, ReleaseDetails } from "../releaseAPI";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { EngineEarsError } from "../../../store/actions/errorStore";

export const useFetchReleaseDetailsQuery = (releaseId = 0) => {
  return useQuery({
    enabled: Boolean(releaseId),
    queryKey: [QUERY_KEYS.FETCH_ARTIST_RELEASE_DETAILS, releaseId],
    queryFn: async () => {
      const response = await fetchReleaseDetails(releaseId);
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return response.resultJson as ReleaseDetails;
    },
  });
};
