import { useParams } from "react-router-dom";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { TrendingReleaseTrackList } from "./ReleaseTrackList";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Text } from "../../core-ui/components/Text/Text";
import { ReleaseTabViewSectionProps } from "../ArtistReleaseTabView/ArtistReleaseTabView";
import { useFetchTrendingArtistReleaseTracksQuery } from "../../../api/releases/hooks/useFetchTrendingArtistReleaseTracks";

interface TrendingArtistReleaseTracksProps extends ReleaseTabViewSectionProps {
  onReleaseClick: (releaseId?: number) => void;
}

export const TrendingArtistReleaseTracks = ({
  shouldShowDesktop,
  onReleaseClick,
}: TrendingArtistReleaseTracksProps) => {
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const { data, isPending } = useFetchTrendingArtistReleaseTracksQuery(
    userData?.id,
  );
  const releaseTracks = data?.pages.map((page) => page.data).flat() || [];

  return (
    <div>
      <Text
        style={{
          marginLeft: shouldShowDesktop ? 0 : "16px",
          marginRight: shouldShowDesktop ? 0 : "16px",
        }}
        bold
        variant={TextStyleVariant.S2}
      >
        Top Songs
      </Text>
      <TrendingReleaseTrackList
        isPending={isPending}
        onReleaseClick={onReleaseClick}
        releaseTracks={releaseTracks}
      />
    </div>
  );
};
