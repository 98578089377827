import { useSetAtom } from "jotai";

import { dashboardSidePanelAtom } from "./atoms";
import { useCallback, useMemo } from "react";
import { TeamPanel } from "../../components/TeamPanel/TeamPanel";
import { useGetTeams } from "../../../hooks/useTeam";
import { useAppSelector } from "../../../store/hooks";
import { DashboardCard } from "../../components/Dashboard/DashboardCard/DashboardCard";
import { selectUserCanEnableStudioServices } from "../../../store/selectors/userInfoSelectors";
import { useIsAdmin } from "../../../hooks/useIsAdmin";
import InviteTeammateButton from "../../components/InviteTeammate/InviteTeammateButton";

const ModularDashboardStudioTeamCard = () => {
  const { associatedTeams, loading: isLoadingTeam } = useGetTeams();
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const setSidePanelChildren = useSetAtom(dashboardSidePanelAtom);
  const user = useAppSelector((state) => state.accountInfo.user);
  const canManageStudios = useAppSelector(selectUserCanEnableStudioServices);
  const isAnAdmin = useIsAdmin(user);

  const panelChildren = useMemo(() => {
    return (
      <TeamPanel
        currentTeam={associatedTeams.find(
          (t) => t.studio_id === selectedProfile?.studio?.id,
        )}
        isLoading={isLoadingTeam}
        onSidePanel={true}
      />
    );
  }, [isLoadingTeam, associatedTeams, selectedProfile?.studio?.id]);

  const handleClick = useCallback(() => {
    setSidePanelChildren(panelChildren, "Your Team");
  }, [setSidePanelChildren, panelChildren]);
  const currentTeam = useMemo(
    () =>
      associatedTeams.find((t) => t.studio_id === selectedProfile?.studio?.id),
    [associatedTeams, selectedProfile?.studio?.id],
  );
  const isLabel = useMemo(() => {
    return Boolean(currentTeam?.label);
  }, [currentTeam]);

  return (
    <DashboardCard
      cardHeight={320}
      headerText={`Your Team (${(currentTeam?.managers.length || 0) + (currentTeam?.members.length || 0)})`}
      handleHeaderClick={handleClick}
      className="no-overflow-content"
      extraActionButtons={
        (canManageStudios || isAnAdmin) && (
          <InviteTeammateButton
            inPanel={false}
            team={currentTeam}
            isLabel={isLabel}
          />
        )
      }
      actionButtonWrapperProps={{
        style: { display: "flex", alignItems: "center" },
      }}
    >
      <TeamPanel
        currentTeam={currentTeam}
        isLoading={isLoadingTeam}
        onSidePanel={false}
        rowLimit={currentTeam?.pending_invites.length ? 2 : 4} // This magic number keeps the card size non-scrolling
        pendingRowLimit={1}
      />
    </DashboardCard>
  );
};

export default ModularDashboardStudioTeamCard;
