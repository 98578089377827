import { isProd } from "../store/utils";

// TODO: remove and use the method in src/utils/analyticsUtils instead

export const getDebugEventUserIdPrefix = isProd ? "" : "-"; // TODO: remove and use the method in src/utils/analyticsUtils instead

export const getDebugEventPrefix = isProd ? "" : "debug_";

export const emitAnalyticsTrackingEvent = (
  name: string,
  data: object,
  userId?: number,
) => {
  if (!window.analytics) {
    return;
  }

  try {
    window.analytics.track(`${getDebugEventPrefix}${name}`, {
      ...data,
      ...(userId ? { user_id: `${getDebugEventUserIdPrefix}${userId}` } : {}),
    });
  } catch {
    return;
  }
};

export const identifyAuthenticatedUser = (userId: number, data: object) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.identify(`${getDebugEventUserIdPrefix}${userId}`, data);
};

export const identifyUnauthenticatedUser = (data: object) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.identify(data);
};
