import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { MusoAssociation } from "../../../store/models/muso";
import { SelectMusoCredit } from "../../components/SelectMusoCredit/SelectMusoCredit";

interface MusoCreditsScreenProps {
  musoAssociation: MusoAssociation;
}

const MusoCreditsScreen = ({ musoAssociation }: MusoCreditsScreenProps) => {
  useSetPageTitle("Select Muso Credits");

  return (
    <SelectMusoCredit
      musoProfileId={musoAssociation.muso_id}
      musoEntityId={musoAssociation.id}
    />
  );
};

export default MusoCreditsScreen;
