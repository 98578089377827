import { useMutation } from "@tanstack/react-query";
import { sendEmailVerification } from "./sendEmailVerification";

const useSendEmailVerificationCodeMutation = () => {
  return useMutation({
    mutationKey: ["send_email_verification_code"],
    mutationFn: () => {
      return sendEmailVerification({ send_verification_code: true });
    },
  });
};

export default useSendEmailVerificationCodeMutation;
