import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { receiveErrors } from "../../store/actions/errorStore";
import { useAppDispatch } from "../../store/hooks";
import { FrequentlyAskedQuestion } from "../../store/models/frequentlyAskedQuestion";
import {
  makeBackendPostCallWithJsonResponse,
  makeBackendPutCallWithJsonResponse,
} from "../../store/utils/fetch";
import { FREQUENTLY_ASKED_QUESTION } from "../../store/utils/routes";

export interface DeleteFAQ {
  faq_id: number;
  deleted: boolean;
}

export type UpdateFAQ = Pick<FrequentlyAskedQuestion, "question" | "answer"> & {
  faq_id: number;
};
export type CreateFAQ = Pick<FrequentlyAskedQuestion, "question" | "answer">;
export interface UpdateFAQOrder {
  faq_ids: number[];
}

interface UseMutateFrequentlyAskedQuestionProps {
  userId?: number;
}

function isCreateFAQ(
  arg: DeleteFAQ | UpdateFAQ | CreateFAQ | UpdateFAQOrder,
): arg is CreateFAQ {
  return (
    "question" in arg &&
    "answer" in arg &&
    !("faq_id" in arg) &&
    !("deleted" in arg) &&
    !("faq_ids" in arg)
  );
}

export const useMutateFrequentlyAskedQuestion = ({
  userId,
}: UseMutateFrequentlyAskedQuestionProps) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const postMutationFn = async (data: CreateFAQ) => {
    return await makeBackendPostCallWithJsonResponse<FrequentlyAskedQuestion>(
      FREQUENTLY_ASKED_QUESTION,
      data,
    );
  };

  const putMutationFn = async (
    data: UpdateFAQ | UpdateFAQOrder | DeleteFAQ,
  ) => {
    return await makeBackendPutCallWithJsonResponse<FrequentlyAskedQuestion>(
      FREQUENTLY_ASKED_QUESTION,
      data,
    );
  };

  return useMutation({
    mutationFn: async (
      arg: DeleteFAQ | UpdateFAQ | CreateFAQ | UpdateFAQOrder,
    ) => {
      if (isCreateFAQ(arg)) {
        const result = await postMutationFn(arg as CreateFAQ);
        if (result.success) {
          return result.resultJson;
        }
        const errors = { errors: result.resultJson };
        dispatch(receiveErrors(errors));
        return;
      }
      const result = await putMutationFn(arg);
      if (result.success) {
        return result.resultJson;
      }
      const errors = { errors: result.resultJson };
      dispatch(receiveErrors(errors));
      return;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_FREQUENTLY_ASKED_QUESTIONS, userId],
      });
    },
  });
};
