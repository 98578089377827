import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MockEngineer } from "../models/engineer";
import { ProjectType } from "../models/project";
import {
  ReviewState,
  ReviewStore,
  Review,
  DownloadReviewStatsResponse,
} from "../models/review";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  DOWNLOAD_STATS,
  GET_PROJECT_REVIEW,
  LOAD_PROJECT_STATS,
  LOAD_SESSION_STATS,
} from "../utils/routes";
import { receiveErrors } from "./errorStore";

interface getProjectReviewParams {
  project_id: string;
  project_type: ProjectType;
}

export const getProjectReview = createAsyncThunk(
  GET_PROJECT_REVIEW,
  async (args: getProjectReviewParams, thunkAPI) => {
    const { project_id, project_type } = args;
    let params: string;
    if (project_type === ProjectType.MASTERING) {
      params = "?mastering_project_id=" + project_id;
    } else if (project_type === ProjectType.RECORDING) {
      params = "?recording_session_id=" + project_id;
    } else {
      params = "?mixing_project_id=" + project_id;
    }
    const result = await makeBackendGetCallWithJsonResponse<Review>(
      GET_PROJECT_REVIEW,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface updateProjectReviewParams extends getProjectReviewParams {
  rating: number;
  review: string;
  is_public: boolean;
  deleted: boolean;
  sessionReview?: {
    cleanliness?: number;
    communication?: number;
    hospitality?: number;
    location?: number;
    sound_quality?: number;
    acceptance_rate?: number;
  };
}

export const updateProjectReview = createAsyncThunk(
  GET_PROJECT_REVIEW + "update",
  async (args: updateProjectReviewParams, thunkAPI) => {
    const {
      project_id,
      project_type,
      rating,
      review,
      deleted,
      is_public,
      sessionReview,
    } = args;
    let body;
    if (project_type === ProjectType.MASTERING) {
      body = {
        mastering_project: project_id,
        rating,
        review,
        deleted,
        is_public,
      };
    } else if (project_type === ProjectType.RECORDING) {
      body = {
        recording_session: project_id,
        rating,
        review,
        deleted,
        is_public,
        sessionReview,
      };
    } else {
      body = {
        mixing_project: project_id,
        rating,
        review,
        deleted,
        is_public,
      };
    }
    const result = await makeBackendPostCallWithJsonResponse<Review>(
      GET_PROJECT_REVIEW,
      body,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const loadProjectStats = createAsyncThunk(
  LOAD_PROJECT_STATS,
  async (args: object, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<ProjectStats>(
      LOAD_PROJECT_STATS,
      "",
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface loadSessionStatsParams {
  studio_username: string;
}

export const loadSessionStats = createAsyncThunk(
  LOAD_SESSION_STATS,
  async (args: loadSessionStatsParams, thunkAPI) => {
    const params = `?studio_username=${args.studio_username}`;
    const result = await makeBackendGetCallWithJsonResponse<SessionStats>(
      LOAD_SESSION_STATS,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface downloadReviewsArgs {
  artist_id?: number;
  engineer_id?: number;
  studio_id?: number;
  studio_room_id?: number;
  page: number;
  order_by?: string;
}

export const downloadReviewStats = createAsyncThunk(
  DOWNLOAD_STATS,
  async (args: downloadReviewsArgs, thunkAPI) => {
    let params = "";
    if (args.artist_id) {
      params = `?artist_id=${args.artist_id.toString()}`;
    } else if (args.engineer_id) {
      params = `?engineer_id=${args.engineer_id.toString()}`;
    } else if (args.studio_id) {
      params = `?studio_id=${args.studio_id.toString()}`;
    } else if (args.studio_room_id) {
      params = `?studio_room_id=${args.studio_room_id.toString()}`;
    }
    params = params.concat(`&page=${args.page}`);
    params = params.concat(`&order_by=${args.order_by}`);
    const result =
      await makeBackendGetCallWithJsonResponse<DownloadReviewStatsResponse>(
        DOWNLOAD_STATS,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);
interface ProjectStats {
  scheduled_projects_count: number;
  num_active_projects: number;
  num_completed_projects: number;
  total_earned: number;
  two_track_earned: number;
  full_stems_earned: number;
  mastered_earned: number;
  atmos_earned: number;
  recording_earned: number;
  last_completed_project?: string;
}

interface SessionStats {
  num_sessions_today: number;
  num_upcoming_sessions: number;
  num_completed_sessions: number;
  total_earned: number;
}

export interface OnboardingCompetitor {
  users__username: string;
  users__username__count: number;
}

export interface ScoreboardStats {
  [onboardingDate: string]: OnboardingCompetitor[];
}

interface StatState {
  engineerReviews: { [engineer_id: number]: ReviewState | undefined };
  artistReviews: { [artist_id: number]: ReviewState | undefined };
  studioReviews: { [studio_id: number]: ReviewState | undefined };
  studioRoomReviews: { [studio_room_id: number]: ReviewState | undefined };
  loadingProjectStats: boolean;
  projectStats?: ProjectStats;
  loadingSessionStats: boolean;
  sessionStats?: SessionStats;
  loadingOnboardingScoreboardStats: boolean;
  scoreboardStats: ScoreboardStats;
}

const InitialReviewState: ReviewState = {
  loading: false,
  recent_reviews: {},
  ratings_count: 0,
  average_rating: 0,
  paginating: false,
  current_page: 1,
  total_pages: 0,
  count: 0,
  order_by: "-created",
};

const initialState: StatState = {
  loadingProjectStats: false,
  loadingSessionStats: false,
  artistReviews: {},
  engineerReviews: {},
  studioReviews: {},
  studioRoomReviews: {},
  loadingOnboardingScoreboardStats: false,
  scoreboardStats: {},
};

export const statStateSlice = createSlice({
  name: "statStateSlice",
  initialState,
  reducers: {
    clearStatState: () => {
      return initialState;
    },
    uploadMockStats: (state) => {
      state.engineerReviews[MockEngineer.id] =
        MockStatState.engineerReviews[MockEngineer.id];
      state.projectStats = MockStatState.projectStats;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadReviewStats.pending, (state, action) => {
      const { meta } = action;
      const page = meta.arg.page;
      const engineer_id = meta.arg.engineer_id;
      const artist_id = meta.arg.artist_id;
      const studio_id = meta.arg.studio_id;
      const studio_room_id = meta.arg.studio_room_id;
      if (engineer_id) {
        const currentState =
          state.engineerReviews[engineer_id] ?? InitialReviewState;
        state.engineerReviews[engineer_id] = {
          ...currentState,
          loading: page === 1,
          paginating: page > 1,
        };
      } else if (artist_id) {
        const currentState =
          state.artistReviews[artist_id] ?? InitialReviewState;
        state.artistReviews[artist_id] = {
          ...currentState,
          loading: page === 1,
          paginating: page > 1,
        };
      } else if (studio_id) {
        const currentState =
          state.studioReviews[studio_id] ?? InitialReviewState;
        state.studioReviews[studio_id] = {
          ...currentState,
          loading: page === 1,
          paginating: page > 1,
        };
      } else if (studio_room_id) {
        const currentState =
          state.studioRoomReviews[studio_room_id] ?? InitialReviewState;
        state.studioRoomReviews[studio_room_id] = {
          ...currentState,
          loading: page === 1,
          paginating: page > 1,
        };
      }
    });
    builder.addCase(downloadReviewStats.rejected, (state, action) => {
      const { meta } = action;
      const engineer_id = meta.arg.engineer_id;
      const artist_id = meta.arg.artist_id;
      const studio_id = meta.arg.studio_id;
      const studio_room_id = meta.arg.studio_room_id;
      if (engineer_id) {
        state.engineerReviews[engineer_id] = InitialReviewState;
      }
      if (artist_id) {
        state.artistReviews[artist_id] = InitialReviewState;
      }
      if (studio_id) {
        state.studioReviews[studio_id] = InitialReviewState;
      }
      if (studio_room_id) {
        state.studioRoomReviews[studio_room_id] = InitialReviewState;
      }
    });
    builder.addCase(downloadReviewStats.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const engineer_id = meta.arg.engineer_id;
      const artist_id = meta.arg.artist_id;
      const studio_id = meta.arg.studio_id;
      const studio_room_id = meta.arg.studio_room_id;
      const {
        average_rating,
        ratings_count,
        current_page,
        total_pages,
        count,
        order_by,
        recent_reviews: review_data,
      } = payload;
      const reviewsByPage: ReviewStore = {};
      reviewsByPage[current_page] = review_data;
      if (engineer_id) {
        if (current_page === 1) {
          state.engineerReviews[engineer_id] = {
            recent_reviews: meta.arg.order_by
              ? {
                  ...reviewsByPage,
                }
              : {
                  ...state.engineerReviews[engineer_id]?.recent_reviews,
                },
            loading: false,
            paginating: false,
            ratings_count,
            average_rating,
            total_pages,
            current_page,
            count,
            order_by,
          };
        } else {
          state.engineerReviews[engineer_id] = {
            recent_reviews: {
              ...state.engineerReviews[engineer_id]?.recent_reviews,
              ...reviewsByPage,
            },
            loading: false,
            paginating: false,
            ratings_count,
            average_rating,
            total_pages,
            current_page,
            count,
            order_by,
          };
        }
      } else if (artist_id) {
        if (current_page === 1) {
          state.artistReviews[artist_id] = {
            recent_reviews: meta.arg.order_by
              ? {
                  ...reviewsByPage,
                }
              : {
                  ...state.artistReviews[artist_id]?.recent_reviews,
                },
            loading: false,
            paginating: false,
            ratings_count,
            average_rating,
            total_pages,
            current_page,
            count,
            order_by,
          };
        } else {
          state.artistReviews[artist_id] = {
            recent_reviews: {
              ...state.artistReviews[artist_id]?.recent_reviews,
              ...reviewsByPage,
            },
            loading: false,
            paginating: false,
            ratings_count,
            average_rating,
            total_pages,
            current_page,
            count,
            order_by,
          };
        }
      } else if (studio_id) {
        if (current_page === 1) {
          state.studioReviews[studio_id] = {
            recent_reviews: meta.arg.order_by
              ? {
                  ...reviewsByPage,
                }
              : {
                  ...state.studioReviews[studio_id]?.recent_reviews,
                },
            loading: false,
            paginating: false,
            ratings_count,
            average_rating,
            total_pages,
            current_page,
            count,
            order_by,
          };
        } else {
          state.studioReviews[studio_id] = {
            recent_reviews: {
              ...state.studioReviews[studio_id]?.recent_reviews,
              ...reviewsByPage,
            },
            loading: false,
            paginating: false,
            ratings_count,
            average_rating,
            total_pages,
            current_page,
            count,
            order_by,
          };
        }
      } else if (studio_room_id) {
        if (current_page === 1) {
          state.studioRoomReviews[studio_room_id] = {
            recent_reviews: meta.arg.order_by
              ? {
                  ...reviewsByPage,
                }
              : {
                  ...state.studioRoomReviews[studio_room_id]?.recent_reviews,
                },
            loading: false,
            paginating: false,
            ratings_count,
            average_rating,
            total_pages,
            current_page,
            count,
            order_by,
          };
        } else {
          state.studioRoomReviews[studio_room_id] = {
            recent_reviews: {
              ...state.studioRoomReviews[studio_room_id]?.recent_reviews,
              ...reviewsByPage,
            },
            loading: false,
            paginating: false,
            ratings_count,
            average_rating,
            total_pages,
            current_page,
            count,
            order_by,
          };
        }
      }
    });
    builder.addCase(loadProjectStats.pending, (state) => {
      state.loadingProjectStats = true;
    });
    builder.addCase(loadProjectStats.rejected, (state) => {
      state.loadingProjectStats = false;
      state.projectStats = undefined;
    });
    builder.addCase(loadProjectStats.fulfilled, (state, action) => {
      state.projectStats = action.payload;
      state.loadingProjectStats = false;
    });
    builder.addCase(loadSessionStats.pending, (state) => {
      state.loadingSessionStats = true;
    });
    builder.addCase(loadSessionStats.rejected, (state) => {
      state.loadingSessionStats = false;
      state.sessionStats = undefined;
    });
    builder.addCase(loadSessionStats.fulfilled, (state, action) => {
      state.sessionStats = action.payload;
      state.loadingSessionStats = false;
    });
  },
});

export const { uploadMockStats, clearStatState } = statStateSlice.actions;

export default statStateSlice.reducer;

export const MockStatState: StatState = {
  loadingProjectStats: false,
  loadingSessionStats: false,
  loadingOnboardingScoreboardStats: false,
  engineerReviews: {},
  artistReviews: {},
  studioReviews: {},
  studioRoomReviews: {},
  projectStats: {
    scheduled_projects_count: 3,
    num_active_projects: 5,
    num_completed_projects: 10,
    total_earned: 12000,
    two_track_earned: 4000,
    full_stems_earned: 3000,
    mastered_earned: 5000,
    atmos_earned: 7000,
    recording_earned: 6000,
  },
  sessionStats: {
    num_sessions_today: 3,
    num_upcoming_sessions: 5,
    num_completed_sessions: 10,
    total_earned: 5,
  },
  [MockEngineer.id]: {
    loading: false,
    average_rating: 4.947368421052632,
    ratings_count: 19,
    recent_reviews: [
      {
        id: 58,
        mixing_project: 438,
        mastering_project: null,
        created: "2021-12-21T23:42:35.952512",
        deleted: null,
        rating: 5,
        review: "Test",
        reviewee_user: 4,
        reviewer_user: null,
        is_public: null,
      },
      {
        id: 54,
        mixing_project: null,
        mastering_project: 347,
        created: "2021-12-14T07:57:32.324982",
        deleted: null,
        rating: 5,
        review: "",
        reviewee_user: 4,
        reviewer_user: null,
        is_public: null,
      },
      {
        id: 53,
        mixing_project: 436,
        mastering_project: null,
        created: "2021-12-14T07:43:03.491935",
        deleted: null,
        rating: 5,
        review: "",
        reviewee_user: 4,
        reviewer_user: null,
        is_public: null,
      },
      {
        id: 50,
        mixing_project: 412,
        mastering_project: null,
        created: "2021-12-05T07:52:14.745239",
        deleted: null,
        rating: 5,
        review: "",
        reviewee_user: 4,
        reviewer_user: null,
        is_public: null,
      },
      {
        id: 46,
        mixing_project: null,
        mastering_project: 312,
        created: "2021-11-16T02:14:24.777019",
        deleted: null,
        rating: 5,
        review: "",
        reviewee_user: 4,
        reviewer_user: null,
        is_public: null,
      },
      {
        id: 44,
        mixing_project: null,
        mastering_project: 311,
        created: "2021-11-09T20:31:32.179027",
        deleted: null,
        rating: 5,
        review: "",
        reviewee_user: 4,
        reviewer_user: null,
        is_public: null,
      },
      {
        id: 40,
        mixing_project: 301,
        mastering_project: null,
        created: "2021-11-02T07:07:07.070710",
        deleted: null,
        rating: 5,
        review: "sfdasf",
        reviewee_user: 4,
        reviewer_user: null,
        is_public: null,
      },
      {
        id: 38,
        mixing_project: null,
        mastering_project: 279,
        created: "2021-11-02T06:41:01.191637",
        deleted: null,
        rating: 5,
        review: "sadf",
        reviewee_user: 4,
        reviewer_user: null,
        is_public: null,
      },
      {
        id: 34,
        mixing_project: null,
        mastering_project: 254,
        created: "2021-10-20T21:53:50.016950",
        deleted: null,
        rating: 5,
        review: "Thanks!",
        reviewee_user: 4,
        reviewer_user: null,
        is_public: null,
      },
    ],
  },
  scoreboardStats: {
    "2022-06-17T08:00:00Z": [
      {
        users__username: "mixedbyali",
        users__username__count: 12,
      },
      {
        users__username: "lukesoren",
        users__username__count: 7,
      },
      {
        users__username: "testuser",
        users__username__count: 3,
      },
    ],
  },
};
