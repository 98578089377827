import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { editMode } from "../../../atoms/profileScreenEdit";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { useUpdateFeaturedTrack } from "../../../hooks/profileScreenHooks/useUpdateFeaturedTrack";
import { PortfolioFeatureData } from "../../../store/models/portfolio";
import { FeaturedTrack } from "./FeaturedTrack";

interface FeaturedTrackWrapperProps {
  portfolioFeatureData: PortfolioFeatureData;
}

export const FeaturedTrackWrapper = ({
  portfolioFeatureData,
}: FeaturedTrackWrapperProps) => {
  const [isEditMode] = useAtom(editMode);
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const { mutateAsync: updateFeaturedTrack, isPending: isUpdatingTrack } =
    useUpdateFeaturedTrack({
      userId: userData!.id,
    });

  const deleteFeatureData = async () => {
    await updateFeaturedTrack({
      featured_track_id: portfolioFeatureData.id,
      deleted: true,
    });
  };

  return (
    <FeaturedTrack
      portfolioFeatureData={portfolioFeatureData}
      isEditMode={isEditMode}
      isUpdatingTrack={isUpdatingTrack}
      deleteFeatureData={deleteFeatureData}
    />
  );
};
