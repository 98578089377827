import { useEffect, useState } from "react";
import useModal from "../../../hooks/useModal";
import {
  setActiveFieldsForEngineerRecordingService,
  setActiveStudioIds,
} from "../../../store/actions/generateBookingStore";
import { resetSessionData } from "../../../store/actions/shoppingCart";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RecordingService } from "../../../store/models/recording";
import { StudioRoom } from "../../../store/models/studio";
import {
  StudioRoomOptionType,
  getRecordingServiceOptionsForDropDown,
  getStudioRoomOptionFromRecordingService,
} from "../../../store/utils/recordingUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import "./TransactionBookingServiceTypeDropdown.css";

interface TransactionBookingStudioRoomDropdownProps {
  recordingServiceStudioRooms: StudioRoom[];
  engineerRecordingService?: RecordingService;
}

const TransactionBookingStudioRoomDropdown = ({
  recordingServiceStudioRooms,
  engineerRecordingService,
}: TransactionBookingStudioRoomDropdownProps) => {
  const [pendingRoom, setPendingRoom] = useState<OptionType | null>(null);
  const dispatch = useAppDispatch();
  const { openModal, isOpen, closeModal } = useModal();

  const handleCloseModal = () => {
    closeModal();
    setPendingRoom(null);
  };

  const pendingSessionData = useAppSelector(
    (state) => state.shoppingCart.pendingSessionData ?? [],
  );
  const { activeServiceId, activeTransactionId, activeStudioId } =
    useAppSelector((state) => state.generateBookingStore);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const options = getRecordingServiceOptionsForDropDown(
    recordingServiceStudioRooms,
    engineerRecordingService,
  );

  const selectedRoom = recordingServiceStudioRooms.find(
    (studioRoom) => studioRoom.recording_service?.id === activeServiceId,
  );

  const selectedOption = selectedRoom
    ? getStudioRoomOptionFromRecordingService(selectedRoom)
    : options?.find((option) => option.value === engineerRecordingService?.id);

  // If no activeStudioId is set in GenerateBookingStore (Redux),
  // then set it to the currently selected studio from the dropdown.
  // This is to ensure that the Add Session button is not falsely disabled.
  useEffect(() => {
    const selectedStudioId = selectedOption?.studioId;

    if (!activeStudioId && selectedStudioId) {
      dispatch(
        setActiveStudioIds({
          studioId: selectedStudioId,
          studioRoomId: selectedOption.value,
          serviceId: selectedOption.serviceId,
        }),
      );
    }
  }, [
    activeStudioId,
    dispatch,
    selectedOption?.serviceId,
    selectedOption?.studioId,
    selectedOption?.value,
  ]);

  const handleChangeRoom = (studioRoomOption: StudioRoomOptionType) => {
    emitAnalyticsTrackingEvent(
      "booking_flow-click_studio_room_dropdown",
      {
        transactionId: activeTransactionId,
        studioRoom: studioRoomOption.value,
      },
      loggedInUser?.id,
    );
    if (studioRoomOption.value === engineerRecordingService?.id) {
      dispatch(
        setActiveFieldsForEngineerRecordingService({
          activeServiceId: engineerRecordingService.id,
        }),
      );
      return;
    }
    dispatch(
      setActiveStudioIds({
        studioRoomId: studioRoomOption.value,
        studioId: studioRoomOption.studioId,
        serviceId: studioRoomOption.serviceId,
      }),
    );
    return;
  };

  const handleSelectOption = (option: StudioRoomOptionType) => {
    if (option.value === selectedOption?.value) {
      return;
    }
    if (pendingSessionData.length) {
      setPendingRoom(option);
      openModal();
    } else {
      handleChangeRoom(option);
    }
  };
  const cssPrefix = "transaction-booking-service-type-dropdown_";
  return (
    <>
      <DropdownSelector
        options={options}
        placeholder="Select affiliated room"
        onChange={handleSelectOption}
        value={selectedOption}
        customClassNames={{
          selectContainer: `${cssPrefix}select-container`,
          labelText: `${cssPrefix}label-text`,
          arrowContainer: `${cssPrefix}arrow-container`,
          container: `${cssPrefix}container`,
        }}
      />
      <BaseModal
        open={isOpen}
        setOpen={handleCloseModal}
        header="Are you sure?"
        onCancel={handleCloseModal}
        onConfirm={() => {
          dispatch(resetSessionData());
          handleChangeRoom(pendingRoom!);
          handleCloseModal();
        }}
        showModalFooter
      >
        Selecting a different studio room will reset your current booking.
        <br />
        Are you sure you want to change the room to {pendingRoom?.label}?
      </BaseModal>
    </>
  );
};

export default TransactionBookingStudioRoomDropdown;
