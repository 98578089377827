import Profile from "../store/models/profile";
import { SocialMediaListEnum, SocialMediaMap } from "../stories/types/types";
import { useMemo } from "react";
import { MutateSocialMediaProps } from "./profileScreenHooks/useUpdateProfile";

export const useSocialMediaMap = (
  profile?: Profile | null,
): Map<SocialMediaListEnum, string> => {
  return useMemo(() => {
    const map = new Map<SocialMediaListEnum, string>();
    if (profile?.twitter_username) {
      map.set(SocialMediaListEnum.twitter, profile.twitter_username);
    }
    if (profile?.instagram_username) {
      map.set(SocialMediaListEnum.instagram, profile.instagram_username);
    }
    if (profile?.soundcloud_username) {
      map.set(SocialMediaListEnum.soundcloud, profile.soundcloud_username);
    }
    if (profile?.facebook_username) {
      map.set(SocialMediaListEnum.facebook, profile.facebook_username);
    }
    if (profile?.twitch_username) {
      map.set(SocialMediaListEnum.twitch, profile?.twitch_username);
    }
    if (profile?.tiktok_username) {
      map.set(SocialMediaListEnum.tiktok, profile.tiktok_username);
    }
    if (profile?.youtube_username) {
      map.set(SocialMediaListEnum.youtube, profile.youtube_username);
    }
    if (profile?.spotify_username) {
      map.set(SocialMediaListEnum.spotify, profile.spotify_username);
    }
    if (profile?.apple_username) {
      map.set(SocialMediaListEnum.apple, profile.apple_username);
    }
    if (profile?.tidal_username) {
      map.set(SocialMediaListEnum.tidal, profile.tidal_username);
    }
    if (profile?.amazon_username) {
      map.set(SocialMediaListEnum.amazon, profile.amazon_username);
    }
    return map;
  }, [profile]);
};

export const convertSocialMediaMapToSocialMediaParams = (
  userSocialMediaMap: Map<SocialMediaListEnum, string>,
): MutateSocialMediaProps => {
  const socialMediaJSON: MutateSocialMediaProps = {};

  SocialMediaMap.forEach((_, key) => {
    switch (key) {
      case SocialMediaListEnum.twitter:
        socialMediaJSON.twitter_username = userSocialMediaMap.get(key) ?? "";
        break;
      case SocialMediaListEnum.instagram:
        socialMediaJSON.instagram_username = userSocialMediaMap.get(key) ?? "";
        break;
      case SocialMediaListEnum.soundcloud:
        socialMediaJSON.soundcloud_username = userSocialMediaMap.get(key) ?? "";
        break;
      case SocialMediaListEnum.facebook:
        socialMediaJSON.facebook_username = userSocialMediaMap.get(key) ?? "";
        break;
      case SocialMediaListEnum.twitch:
        socialMediaJSON.twitch_username = userSocialMediaMap.get(key) ?? "";
        break;
      case SocialMediaListEnum.tiktok:
        socialMediaJSON.tiktok_username = userSocialMediaMap.get(key) ?? "";
        break;
      case SocialMediaListEnum.youtube:
        socialMediaJSON.youtube_username = userSocialMediaMap.get(key) ?? "";
        break;
      case SocialMediaListEnum.spotify:
        socialMediaJSON.spotify_username = userSocialMediaMap.get(key) ?? "";
        break;
      case SocialMediaListEnum.apple:
        socialMediaJSON.apple_username = userSocialMediaMap.get(key) ?? "";
        break;
      case SocialMediaListEnum.tidal:
        socialMediaJSON.tidal_username = userSocialMediaMap.get(key) ?? "";
        break;
      case SocialMediaListEnum.amazon:
        socialMediaJSON.amazon_username = userSocialMediaMap.get(key) ?? "";
        break;
      default:
        break;
    }
  });
  return socialMediaJSON;
};
