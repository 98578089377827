import {
  BudgetRow,
  ProgressBlock,
  ProgressBlockKeyText,
  ProgressBlockValueText,
} from "./ProjectHeader.styles";

interface ProgressBlockComponentProps {
  serviceTypeSet: Set<string>;
  numberOfCompletedTracks: number;
  totalTracks: number;
  refunded: boolean;
  completedTracksPercentage: number;
  estimatedCompletionDate: string;
  projectDuration: string;
}

const ProgressBlockComponent = ({
  serviceTypeSet,
  numberOfCompletedTracks,
  totalTracks,
  refunded,
  completedTracksPercentage,
  estimatedCompletionDate,
  projectDuration,
}: ProgressBlockComponentProps) => {
  if (refunded) return null;

  return (
    <ProgressBlock>
      <BudgetRow>
        <ProgressBlockKeyText>Services:</ProgressBlockKeyText>
        <ProgressBlockValueText>
          {Array.from(serviceTypeSet).join(", ")}
        </ProgressBlockValueText>
      </BudgetRow>
      <BudgetRow>
        <ProgressBlockKeyText>Completed tracks:</ProgressBlockKeyText>
        <ProgressBlockValueText>
          {numberOfCompletedTracks}/{totalTracks} (
          {Math.round(completedTracksPercentage)}%)
        </ProgressBlockValueText>
      </BudgetRow>
      <BudgetRow>
        <ProgressBlockKeyText>Est. completion date:</ProgressBlockKeyText>
        <ProgressBlockValueText>
          {estimatedCompletionDate}
        </ProgressBlockValueText>
      </BudgetRow>
      <BudgetRow>
        <ProgressBlockKeyText>Project Duration:</ProgressBlockKeyText>
        <ProgressBlockValueText>{projectDuration}</ProgressBlockValueText>
      </BudgetRow>
    </ProgressBlock>
  );
};

export default ProgressBlockComponent;
