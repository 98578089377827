import { ReactNode } from "react";
import Drawer from "react-bottom-drawer";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import {
  SIDE_PANEL_BOTTOM_DRAWER_CONTAINER_ID,
  useBottomTabBarOverlayView,
} from "../Navigation/BottomNav/useBottomTabBarOverlayView";

interface SidePanelBottomDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const SidePanelBottomDrawer = ({
  isOpen,
  onClose,
  children,
}: SidePanelBottomDrawerProps) => {
  const { isMobile } = useMediaQueryBreakpoint();

  return useBottomTabBarOverlayView(
    isMobile,
    <Drawer
      isVisible={isOpen}
      onClose={onClose}
      className="side-panel-bottom-drawer"
    >
      {children}
    </Drawer>,
    undefined,
    SIDE_PANEL_BOTTOM_DRAWER_CONTAINER_ID,
  );
};
