import { useState } from "react";
import { PendingPurchaseOrderApprover } from "../store/actions/transactions";
import { toast } from "react-toastify";
import { emailRegex } from "../store/utils/signUpUtils";

export const usePendingPurchaseOrderApprovers = (
  initialBudgetApprovers: PendingPurchaseOrderApprover[] = [],
) => {
  const [budgetApprovers, setBudgetApprovers] = useState<
    Array<PendingPurchaseOrderApprover>
  >(initialBudgetApprovers);

  const removeApprover = (email: string) => {
    const newApprovers = budgetApprovers.filter(
      (approver) => approver.email !== email,
    );
    setBudgetApprovers(newApprovers);
  };

  const toggleApprovalRequired = (email: string) => {
    const newApprovers = budgetApprovers.map((approver) => {
      if (approver.email === email) {
        return {
          ...approver,
          approval_required: !approver.approval_required,
        };
      }
      return approver;
    });

    if (
      newApprovers.filter((approver) => approver.approval_required).length === 0
    ) {
      return toast.error("At least one approver must be required");
    }
    setBudgetApprovers(newApprovers);
  };

  const onChange = (emails: string[]) => {
    const cleanedEmails = emails.map((email) => email.trim().toLowerCase());
    if (cleanedEmails.some((email) => !emailRegex.test(email))) {
      const invalidEmail = cleanedEmails.find(
        (email) => !emailRegex.test(email),
      );
      return toast.error(`${invalidEmail} is not a valid email`);
    }
    const newApprovers = cleanedEmails.map((email) => {
      const existingApprover = budgetApprovers.find(
        (approver: PendingPurchaseOrderApprover) => approver.email === email,
      );
      if (existingApprover) {
        return existingApprover;
      }
      return {
        email,
        approval_required: true,
      };
    });

    const uniqueEmails = new Set(
      newApprovers.map((approver) => approver.email),
    );
    if (uniqueEmails.size !== newApprovers.length) {
      return toast.error("Duplicate emails are not allowed");
    }
    setBudgetApprovers(newApprovers);
  };

  return {
    budgetApprovers,
    toggleApprovalRequired,
    onChange,
    removeApprover,
  };
};
