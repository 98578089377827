import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSetAtom } from "jotai";
import { useGetSessionBookingDetails } from "../../../hooks/recordingSessionHooks/useGetSessionBookingDetails";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { SessionRequestedPanelDetails } from "../SessionRequestedPanel/SessionRequestedPanelDetails";
import { SessionDetailsPanelView, sessionDetailsPanelViewAtom } from "./atoms";

export const SessionDetailsPanelBookingDetailsView = ({
  bookingRequestId,
}: {
  bookingRequestId: number | null;
}) => {
  const setSessionDetailsView = useSetAtom(sessionDetailsPanelViewAtom);
  // Fetch session booking details
  const { isLoading, data: sessionBookingDetails } =
    useGetSessionBookingDetails({ bookingRequestId });

  if (isLoading && !sessionBookingDetails) {
    return <SoundWaveLoader height={100} width={100} />;
  }

  if (!sessionBookingDetails) {
    return null;
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={() => setSessionDetailsView(SessionDetailsPanelView.MAIN)}
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            style={{ marginRight: "0.5rem" }}
          />
          Back
        </Button>
      </div>
      <SessionRequestedPanelDetails
        hideMap
        sessionBookingDetails={sessionBookingDetails}
      />
    </>
  );
};
