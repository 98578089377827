import { useCallback, useEffect, useMemo, useState } from "react";
import { fetchAvailableRecordingEngineers } from "../../store/actions/engineerRecommendation";
import {
  getRecordingServiceAvailability,
  GetRecordingServiceAvailabilityResponse,
} from "../../store/actions/recording";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getAvailabilityFormattedDateString,
  getCurrentDate,
  getFormattedDateTime,
} from "../../store/utils/dateTimeUtils";
import { getDisplayableNameForUser } from "../../store/utils/entityUtils";
import {
  getStartTimesForContiguousAvailableBlocks,
  getValidDiscountRate,
} from "../../store/utils/recordingUtils";
import { convertDatesToOptionType } from "../../store/utils/serviceUtils";
import { hoursFromMinutes } from "../../store/utils/shoppingCartUtils";
import { convertAvailabilityForDateToMap } from "../../store/utils/utils";
import { SelectEngineerOption } from "../../stories/components/SelectAvailableTrackingEngineer/SelectAvailableTrackingEngineer";

export const useAvailableTrackingEngineers = (
  activeStudioRoomId: number | undefined,
  dateTime: Date | undefined,
  duration: number | undefined,
  dateISOString: string | undefined,
  startAndEndTimeText: string | undefined,
  isStudioManager: boolean,
) => {
  const { availableRecordingEngineers } = useAppSelector(
    (state) => state.engineerRecommendationStore,
  );
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const getSelectedDateTime = useCallback(() => {
    const isoDate = dateISOString ? new Date(dateISOString) : null;
    const selectedDateTime = dateTime ?? isoDate;
    return selectedDateTime ? new Date(selectedDateTime?.toISOString()) : null;
  }, [dateTime, duration, dateISOString]);

  const engineerAvailabilities = useAppSelector(
    (state) => state.availability.engineer_user,
  );

  const mapKey = useMemo(() => {
    if (dateTime === undefined || duration === undefined) {
      return activeStudioRoomId;
    }

    return getAvailableEngineersDateTimeMapKey(dateTime, duration);
  }, [dateTime, duration, activeStudioRoomId]);

  const trackingEngineers = useMemo(() => {
    if (mapKey === undefined) {
      return [];
    }
    return availableRecordingEngineers[mapKey] || [];
  }, [availableRecordingEngineers, mapKey]);

  const memoizedAvailableRecordingEngineers: SelectEngineerOption[] =
    useMemo(() => {
      const date = getSelectedDateTime();
      const now = getCurrentDate();
      const hasPermissionToBookPastEngineer =
        isStudioManager && date && date.getTime() < now.getTime();

      // if the user is a studio manager, they can book any engineer for past dated sessions
      // otherwise, filter out engineers that are not available for the selected date
      const filteredEngineers = hasPermissionToBookPastEngineer
        ? trackingEngineers
        : trackingEngineers.filter((recording_service) => {
            const engineer = recording_service.engineer;
            if (!engineer) {
              return false;
            }
            if (!date) {
              return true;
            }
            const engineerAvailability =
              engineerAvailabilities[engineer.user_id ?? -1];
            if (!engineerAvailability) return true;
            const dateMap =
              convertAvailabilityForDateToMap(engineerAvailability);
            date.setHours(0, 0, 0, 0);
            if (duration) {
              const currentDateAvailability =
                getStartTimesForContiguousAvailableBlocks(
                  date,
                  dateMap,
                  duration,
                );
              if (startAndEndTimeText) {
                const optionTypes = convertDatesToOptionType(
                  currentDateAvailability,
                  hoursFromMinutes(duration),
                );
                return optionTypes.some(
                  (optionType) => optionType.label === startAndEndTimeText,
                );
              }
              return currentDateAvailability.length > 0;
            }
            const availability: string | undefined = dateMap.get(
              getAvailabilityFormattedDateString(date),
            );
            if (availability === undefined) {
              return false;
            }
            return availability.includes("1");
          });

      return filteredEngineers
        .map((recording_service) => {
          const service_rate = recording_service.service_rate;
          let serviceRate = undefined;
          if (service_rate.price) {
            serviceRate = +service_rate.price;
          }
          const appliedDiscountRate = getValidDiscountRate(
            recording_service?.recording_service_discount_rate,
            duration,
          );
          const engineer = recording_service?.engineer;
          const user = service_rate.user;
          const display_name = getDisplayableNameForUser(user ?? undefined);
          return {
            value: user?.id,
            label: display_name,
            serviceRate: appliedDiscountRate
              ? appliedDiscountRate.service_rate.price
              : serviceRate,
            user: user ?? null,
            engineer: engineer,
            studioAffiliated: recording_service.studioAffiliated,
          } as SelectEngineerOption;
        })
        .filter(
          (engineerOption) =>
            engineerOption?.engineer?.has_active_recording_service,
        );
    }, [
      trackingEngineers,
      activeStudioRoomId,
      dateTime,
      getSelectedDateTime,
      engineerAvailabilities,
      dateISOString,
      duration,
      startAndEndTimeText,
    ]);

  // fetch available engineers
  // TODO: Refactor this to filter out engineers based on date (instead of just time)
  useEffect(() => {
    if (!activeStudioRoomId) return;
    setLoading(true);
    void dispatch(
      fetchAvailableRecordingEngineers({
        studio_room_id: activeStudioRoomId,
        start_datetime: dateTime ? getFormattedDateTime(dateTime) : undefined,
        session_duration_minutes: dateTime ? duration : undefined,
      }),
    ).finally(() => {
      setLoading(false);
    });
  }, [activeStudioRoomId, dateTime, duration, dispatch]);

  // fetch availabilities for each engineer (to filter out engineers based on date)
  useEffect(() => {
    if (!activeStudioRoomId) return;
    if (!trackingEngineers) return;

    setLoading(true);
    const selectedDateTime = getSelectedDateTime();
    const promises: Promise<GetRecordingServiceAvailabilityResponse[]>[] = [];

    trackingEngineers.forEach((recording_service) => {
      if (!recording_service.engineer) return;

      promises.push(
        dispatch(
          getRecordingServiceAvailability({
            userId: recording_service.engineer.user_id,
            timezoneShiftMinutes: new Date().getTimezoneOffset(),
            startDate: selectedDateTime ?? getCurrentDate(),
          }),
        ).unwrap(),
      );
    });

    if (promises.length === 0) return;

    void Promise.allSettled(promises).finally(() => {
      setLoading(false);
    });
  }, [activeStudioRoomId, getSelectedDateTime, dispatch]);

  return useMemo(() => {
    return {
      trackingEngineers: memoizedAvailableRecordingEngineers,
      loading,
    };
  }, [memoizedAvailableRecordingEngineers, loading]);
};

export const getAvailableEngineersDateTimeMapKey = (
  dateTime: Date,
  duration: number,
) => {
  return getFormattedDateTime(dateTime) + duration;
};
