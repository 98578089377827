import React, { useEffect, useState } from "react";
import { CirclePicker } from "react-color";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { BannerColor, BannerColorMap } from "../../../store/models/profile";
import boomyOrangeBanner from "../../assets/boomy-orange-banner.svg";
import boxyYellowBanner from "../../assets/boxy-yellow-banner.svg";
import deepBlueBanner from "../../assets/deep-blue-banner.svg";
import deepGreenBanner from "../../assets/deep-green-banner.svg";
import deepPinkBanner from "../../assets/deep-pink-banner.svg";
import echoOrangeBanner from "../../assets/echo-orange-banner.svg";
import editProfileIcon from "../../assets/edit-profile-icon.svg";
import mintGreenBanner from "../../assets/mint-green-banner.svg";
import mobileBoomyOrangeBanner from "../../assets/mobile-boomy-orange-banner.svg";
import mobileBoxyYellowBanner from "../../assets/mobile-boxy-yellow-banner.svg";
import mobileDeepBlueBanner from "../../assets/mobile-deep-blue-banner.svg";
import mobileDeepGreenBanner from "../../assets/mobile-deep-green-banner.svg";
import mobileDeepPinkBanner from "../../assets/mobile-deep-pink-banner.svg";
import mobileMintGreenBanner from "../../assets/mobile-mint-green-banner.svg";
import mobileMuddyBrownBanner from "../../assets/mobile-muddy-brown-banner.svg";
import mobileSkylightBlueBanner from "../../assets/mobile-skylight-blue-banner.svg";
import muddyBrownBanner from "../../assets/muddy-brown-banner.svg";
import skylightBlueBanner from "../../assets/skylight-blue-banner.svg";
import "./BannerImage.css";

export interface BannerImageProps {
  color: BannerColor;
  editMode: boolean;
  onChange?: (selected: BannerColor) => void;
  isMobile?: boolean;
}

export const BannerImage = ({
  color,
  editMode,
  onChange,
  isMobile,
}: BannerImageProps) => {
  const detectedMobile = useMediaQuery("(max-width: 1040px)");
  const showMobile = detectedMobile || isMobile;
  const [bannerColor, setBannerColor] = useState<BannerColor>(color);
  const [choosingColor, setChoosingColor] = useState<boolean>(false);

  useEffect(() => {
    setBannerColor(color);
  }, [color]);

  const getBannerImage = (colorChoice: BannerColor) => {
    let color = colorChoice;

    // Fix to make the Storybook input param work.
    if (typeof colorChoice === "string") {
      color = parseInt(colorChoice);
    }

    switch (color) {
      case BannerColor.DEEP_BLUE:
        if (showMobile) return mobileDeepBlueBanner;
        return deepBlueBanner;
      case BannerColor.DEEP_PINK:
        if (showMobile) return mobileDeepPinkBanner;
        return deepPinkBanner;
      case BannerColor.ECHO_ORANGE:
        return echoOrangeBanner;
      case BannerColor.DEEP_GREEN:
        if (showMobile) return mobileDeepGreenBanner;
        return deepGreenBanner;
      case BannerColor.BOXY_YELLOW:
        if (showMobile) return mobileBoxyYellowBanner;
        return boxyYellowBanner;
      case BannerColor.BOOMY_ORANGE:
        if (showMobile) return mobileBoomyOrangeBanner;
        return boomyOrangeBanner;
      case BannerColor.SKYLIGHT_BLUE:
        if (showMobile) return mobileSkylightBlueBanner;
        return skylightBlueBanner;
      case BannerColor.MINT_GREEN:
        if (showMobile) return mobileMintGreenBanner;
        return mintGreenBanner;
      case BannerColor.MUDDY_BROWN:
        if (showMobile) return mobileMuddyBrownBanner;
        return muddyBrownBanner;
    }
    // Default is the boomy orange color.
    if (showMobile) return mobileBoomyOrangeBanner;
    return boomyOrangeBanner;
  };

  return (
    <div
      className={editMode ? "banner editmode" : " banner"}
      onClick={
        editMode
          ? () => {
              if (!choosingColor) setChoosingColor(true);
            }
          : undefined
      }
      style={{ backgroundImage: `url(${getBannerImage(bannerColor)})` }}
    >
      {editMode && !choosingColor && (
        <div className="over-lay-container">
          <p className="label-button">Choose Banner Color</p>
          <img
            className="banner-edit-icon"
            alt={"Edit Profile Icon"}
            src={editProfileIcon}
          />
        </div>
      )}
      {editMode && choosingColor && (
        <CirclePicker
          circleSpacing={22}
          colors={Array.from(BannerColorMap.keys())}
          onChange={(value) => {
            const chosen = BannerColorMap.get(value.hex)!;
            setBannerColor(chosen);
            setChoosingColor(false);
            if (onChange) onChange(chosen);
          }}
        />
      )}
    </div>
  );
};
