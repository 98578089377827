import { createDraftSafeSelector, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { Project, ProjectById, ProjectType } from "../models/project";
import { selectCurrentUser } from "./userInfoSelectors";

const selectProjectStore = (state: RootState) => state.projectsStore;
const selectRecordingStore = (state: RootState) =>
  state.recordingSessionReducer;
export const getProject = (project_id: number) =>
  createDraftSafeSelector(selectProjectStore, (projectStore) => {
    const masteringProject = projectStore.masteringProjects.find(
      (master) => master.id === project_id,
    );
    if (masteringProject) {
      return masteringProject;
    }
    return projectStore.mixingProjects.find((mix) => {
      return project_id === mix.id;
    });
  });

export const isCurrentUserProjectEngineer = (project_id: number) =>
  createDraftSafeSelector(
    selectCurrentUser,
    getProject(project_id),
    (user, project) => {
      return user?.id === project?.engineer?.id;
    },
  );

export const isCurrentUserProjectArtist = (project_id: number) =>
  createDraftSafeSelector(
    selectCurrentUser,
    getProject(project_id),
    (user, project) => {
      if (!user) return false;
      return user?.id === project?.artist?.id;
    },
  );

export const getProjectCollaborator = (project_id: number) =>
  createDraftSafeSelector(
    selectCurrentUser,
    getProject(project_id),
    (user, project) =>
      user?.id === project?.artist?.id ? project?.engineer : project?.artist,
  );

export const getProjectMP3DownloadPreference = (project_id: number) =>
  createSelector(getProject(project_id), (project) => {
    if (!project) {
      return false;
    }

    if (project.service_type === ProjectType.MASTERING) {
      return (
        project.mastering_project?.enable_artist_review_mp3_file_download ??
        false
      );
    }

    return (
      project.mixing_project?.enable_artist_review_mp3_file_download ?? false
    );
  });

export const getProjectWAVDownloadPreference = (project_id: number) =>
  createSelector(getProject(project_id), (project) => {
    if (!project) {
      return false;
    }

    if (project.service_type === ProjectType.MASTERING) {
      return (
        project.mastering_project?.enable_artist_review_wav_file_download ??
        false
      );
    }

    return (
      project.mixing_project?.enable_artist_review_wav_file_download ?? false
    );
  });

export const isCurrentProjectClosed = (project_id: number) =>
  createDraftSafeSelector(
    getProject(project_id),
    (project) => project?.closed !== undefined && project.closed !== null,
  );

export const getRecordingSessionIfNotMixMaster = (
  mixOrMaster: Project | ProjectById | undefined,
) =>
  createDraftSafeSelector(selectRecordingStore, (recordingStore) => {
    if (mixOrMaster) return null;
    return recordingStore.recordingSession;
  });
