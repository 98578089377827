import { ReactNode, useMemo } from "react";
import useModal from "../../../hooks/useModal";
import classNames from "classnames";
import {
  ContactInfoModal,
  MISSING_PHONE_NUMBER_MESSAGE,
} from "../ContactInfoModal/ContactInfoModal";
import { useAccountStatus } from "../../../hooks/accountHooks";
import {
  InvisibleButton,
  InvisibleButtonProps,
} from "./PrerequisiteButtons.styles";
import { createPortal } from "react-dom";

export interface ContactInfoRequiredButtonProps {
  children: ReactNode;
  buttonProps?: Partial<InvisibleButtonProps>;
  message?: string;
}

export const ContactInfoRequiredButton = ({
  children,
  buttonProps = {},
  message = MISSING_PHONE_NUMBER_MESSAGE,
}: ContactInfoRequiredButtonProps) => {
  const { className: buttonClassName } = buttonProps;
  const { missingPhoneNumber } = useAccountStatus();
  const { isOpen, openModal, closeModal } = useModal();
  const modal = useMemo(
    () =>
      createPortal(
        <ContactInfoModal
          onClose={closeModal}
          overwriteShowModal={isOpen}
          customHeading={message}
        />,
        document.body,
      ),
    [closeModal, isOpen, message],
  );
  return (
    <>
      <InvisibleButton
        className={classNames(["contact-info-required", buttonClassName])}
        onClick={() => {
          // Only open the modal if we lack the user's number
          if (missingPhoneNumber) {
            openModal();
          }
        }}
        $disabled={missingPhoneNumber}
        {...buttonProps}
      >
        {children}
      </InvisibleButton>
      {isOpen && modal}
    </>
  );
};
