import { getImageURLFromPath } from "../../../../hooks/useImageURLFromPath";
import User from "../../../../store/models/user";
import {
  UserIconLabelContainer,
  UserIconLabelIcon,
  UserIconLabelText,
} from "./UserIconLabel.styles";

export interface UserIconLabelProps {
  user?: User | null;
}

export const UserIconLabel: React.FC<UserIconLabelProps> = ({
  user,
}: UserIconLabelProps) => {
  const imagePath = getImageURLFromPath(false, user?.photo?.path);
  const fullName = user
    ? `${user.first_name} ${user.last_name}`
    : "Not on Platform";
  return (
    <UserIconLabelContainer>
      <UserIconLabelIcon src={imagePath} alt="User Avatar" />
      <UserIconLabelText>{fullName}</UserIconLabelText>
    </UserIconLabelContainer>
  );
};
