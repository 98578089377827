import { Button } from "../../core-ui/components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { useState } from "react";

import { getReleaseType, Release } from "../../../store/models/release";

import { EditReleaseModal } from "./EditReleaseModal";
import { useReleaseBulkCreate } from "../../../api/releases/hooks/useReleaseBulkCreate";
import ToggleButton from "../ToggleButton/ToggleButton";
import { useUpdateRelease } from "../../../api/releases/hooks/useUpdateRelease";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../constants/queryKeys";

interface ProjectOverviewHeaderReleaseButtonProps {
  numOfSongs: number;
  scheduledProjectId?: number;
  release?: Release | null;
}

const ProjectOverviewHeaderReleaseButton = ({
  numOfSongs,
  scheduledProjectId = 0,
  release,
}: ProjectOverviewHeaderReleaseButtonProps) => {
  const [showReleaseModal, setShowReleaseModal] = useState(false);
  const releaseType = getReleaseType(numOfSongs);
  const { mutateAsync: createRelease } = useReleaseBulkCreate();
  const { mutate: updateRelease, isPending } = useUpdateRelease(
    release?.id,
    scheduledProjectId,
  );
  const queryClient = useQueryClient();

  const renderReleaseButton = () => {
    if (release) {
      return (
        <ToggleButton
          disableOnClick={isPending}
          enabled={Boolean(release.published_at)}
          onClick={(value) => {
            queryClient.setQueryData(
              [QUERY_KEYS.FETCH_SCHEDULED_PROJECT_RELEASE, scheduledProjectId],
              {
                ...release,
                published_at: value,
              },
            );
            updateRelease({
              published_at: value,
              featured: Boolean(release?.featured),
            });
          }}
          enabledLabel={"Public"}
          disabledLabel={"Private"}
        />
      );
    } else {
      return (
        <Button
          onClick={async () => {
            if (!scheduledProjectId) return;
            await createRelease({
              releaseType,
              scheduledProjectId,
            });
            setShowReleaseModal(true);
          }}
          variant={ButtonVariant.OUTLINED}
          reverseIconOrder
          labelIcon={<FontAwesomeIcon icon={faShare} />}
        >
          Publish this Project
        </Button>
      );
    }
  };

  return (
    <>
      {renderReleaseButton()}
      {release && (
        <EditReleaseModal
          release={release}
          open={showReleaseModal}
          scheduledProjectId={scheduledProjectId}
          setOpen={setShowReleaseModal}
        />
      )}
    </>
  );
};

export default ProjectOverviewHeaderReleaseButton;
