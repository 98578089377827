import { PopoverTrigger } from "@radix-ui/react-popover";
import { useEffect } from "react";
import { usePaymentPlanStatus } from "../../../../hooks/partialPaymentHooks";
import { useAppSelector } from "../../../../store/hooks";
import {
  ProjectById,
  projectTypeReadableName,
} from "../../../../store/models/project";
import { BasePopover } from "../../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../core-ui/components/BasePopover/PopoverContainerContext";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { Text } from "../../../core-ui/components/Text/Text";
import { ApproveMixPopoverContent } from "../InProgressTransitionView.styles";
import { PennyDollarFormatter } from "../../../../store/utils/formatUtils";

interface CompletePaymentButtonProps {
  project: ProjectById;
  code?: string;
  isPartiallyPaid: boolean;
  isCompletePaymentPopoverOpen: boolean;
  setIsCompletePaymentPopoverOpen: (status: boolean) => void;
  closeCompletePaymentPopover: () => void;
  outstandingBalance?: number;
}

export const CompletePaymentButton = ({
  project,
  code,
  isPartiallyPaid,
  isCompletePaymentPopoverOpen,
  setIsCompletePaymentPopoverOpen,
  closeCompletePaymentPopover,
  outstandingBalance,
}: CompletePaymentButtonProps) => {
  const readableBalance = PennyDollarFormatter().format(
    outstandingBalance || 0,
  );
  const { paymentPlanPaid } = useAppSelector(
    (state) => state.scheduledProjectsStore,
  );
  const projectTypeText = projectTypeReadableName.get(project.service_type);
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );
  const getButtonText = () => {
    let buttonText = "Complete payment";
    if (isPartiallyPaid && outstandingBalance) {
      buttonText += ` (${readableBalance})`;
    }
    return buttonText;
  };

  const {
    redirectToPurchaseProject,
    loading,
    fetchingPaymentStatus,
    cancelFetchingPaymentStatus,
  } = usePaymentPlanStatus(code, undefined, project.id, isPartiallyPaid);

  const awaitingPaymentText = "Waiting for payment completion in new tab...";

  const content = () => (
    <ApproveMixPopoverContent>
      <Text style={{ marginTop: "10px", marginBottom: "20px" }}>
        {fetchingPaymentStatus
          ? awaitingPaymentText
          : `Payment is required in order to finish the project.`}
      </Text>
    </ApproveMixPopoverContent>
  );

  const handleCloseCompletePaymentPopover = () => {
    cancelFetchingPaymentStatus();
    closeCompletePaymentPopover();
  };

  const setCompletePaymentPopoverOpen = () => {
    cancelFetchingPaymentStatus();
    setIsCompletePaymentPopoverOpen(!isCompletePaymentPopoverOpen);
  };

  useEffect(() => {
    if (paymentPlanPaid) {
      window.location.reload();
    }
  }, [paymentPlanPaid]);

  return (
    <BasePopover
      disableAutoClose
      isOpen={isCompletePaymentPopoverOpen}
      setIsPopoverOpen={setCompletePaymentPopoverOpen}
      closePopover={handleCloseCompletePaymentPopover}
      side="top"
      title={
        isPartiallyPaid ? "Payment Completion Required" : "Payment Required"
      }
      description=""
      okText="Complete payment"
      okButtonProps={{
        disabled: loading,
        loading: loading || fetchingPaymentStatus,
      }}
      cancelButtonProps={{
        loading: loading || fetchingPaymentStatus,
      }}
      additionalContent={content()}
      onConfirm={redirectToPurchaseProject}
      wrapperElement={containerElement}
    >
      <PopoverTrigger asChild>
        <Button fullWidth variant={ButtonVariant.PRIMARY}>
          {getButtonText()}
        </Button>
      </PopoverTrigger>
    </BasePopover>
  );
};
