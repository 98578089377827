import { useEffect, useState } from "react";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { loadEngineerServices } from "../../../store/actions/services";
import {
  createStripeCustomer,
  getStripeCheckoutSession,
} from "../../../store/actions/subscriptions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import { isEngineerVerifiedSelector } from "../../../store/selectors/userInfoSelectors";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { SelectSubPaymentPlanScreen } from "../SelectSubPaymentPlanScreen/SelectSubPaymentPlanScreen";
import { SelectSubscriptionScreen } from "../SelectSubscriptionScreen/SelectSubscriptionScreen";
import "./AddSubscriptionScreen.css";
import subscriptionLoggedOutBanner from "../../../stories/assets/subsciption-logged-out-banner.png";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { Text } from "../../core-ui/components/Text/Text";
import {
  LoggedOutBannerContainer,
  LoggedOutBannerOverlay,
} from "./AddSubscriptionScreen.styles";
import { useGetSubscriptionStatus } from "../../../hooks/useGetSubscriptionStatus";
import { useQuery } from "../../../hooks/useQuery";

const SELECT_SUBSCRIPTION_PACKAGE_STEP = 0;
const SELECT_PAYMENT_PLAN_STEP = 1;

export const AddSubscriptionScreen = () => {
  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);

  const [currentStep, setCurrentStep] = useState(
    SELECT_SUBSCRIPTION_PACKAGE_STEP,
  );
  const [numberOfSongs, setNumberOfSongs] = useState(10);
  const [averageProjectPrice, setAverageProjectPrice] = useState(150);
  const [selectedSubscription, setSelectedSubscription] =
    useState<SUBSCRIPTION_PLAN>(SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN);

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const query = useQuery();
  const [refundFees, setRefundFees] = useState(false);
  const engineer = useAppSelector((state) => state.accountInfo.user?.engineer);
  const loading = useAppSelector((state) => state.engineerServices.isLoading);
  const meetsStripeRequirements = useAppSelector(
    (state) => state.accountInfo.meetsStripeRequirements,
  );
  const isVerifiedEngineer = useAppSelector(isEngineerVerifiedSelector);

  useSetPageTitle("Subscriptions");

  useEffect(() => {
    if (!engineer) return;
    dispatch(loadEngineerServices({ engineer_id: engineer?.id }));
  }, [dispatch, engineer]);

  useEffect(() => {
    setRefundFees(query.get("refundfees") === "true");
  }, [query]);

  useEffect(() => {
    if (!user) return;
    if (!engineer) return;
    dispatch(
      createStripeCustomer({
        billing_email: user.email,
        name: `${user.first_name} ${user.last_name}`,
      }),
    );
  }, [dispatch, user, engineer]);

  const selectSubscriptionScreenProps = {
    numberOfSongs,
    averageProjectPrice,
    refundFees,
    onClickPreviousStep: () => {},
    onSubscriptionSelected: (selected: SUBSCRIPTION_PLAN) => {
      setSelectedSubscription(selected);
      if (
        selected !== SUBSCRIPTION_PLAN.LEGACY_BASIC_PLAN &&
        selected !== SUBSCRIPTION_PLAN.ESSENTIALS_PLAN
      )
        setCurrentStep(SELECT_PAYMENT_PLAN_STEP);
    },
  };

  const selectSubPaymentPlanScreenProps = {
    selectedSubscription,
    setSelectedSubscription,
    refundFees,
    onClickPreviousStep: () => setCurrentStep(SELECT_SUBSCRIPTION_PACKAGE_STEP),
    onGoToCheckout: () => {
      window.analytics.track(
        getDebugEventPrefix + "start_stripe_subscription_checkout",
        {
          user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
        },
      );
      dispatch(
        getStripeCheckoutSession({
          plan: selectedSubscription,
          refund_fees: refundFees,
        }),
      )
        .unwrap()
        .then((result) => {
          window.location.href = result.checkout_url;
        });
    },
  };
  const bannerColor = useAppSelector(
    (state) => state.accountInfo.user?.profile?.banner_color,
  );

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {!isAuthenticated && (
        <LoggedOutBannerContainer>
          <LoggedOutBannerOverlay />
          <img
            src={subscriptionLoggedOutBanner}
            alt={"Subscription banner"}
            width={"100%"}
            height={"100%"}
            style={{ position: "relative" }}
          />
          <Text
            color={TextColor.WHITE}
            variant={TextStyleVariant.H3}
            style={{
              maxWidth: "724px",
              zIndex: 4,
              textAlign: "center",
              position: "absolute",
            }}
          >
            For music professionals, artists, studio owners, and labels
          </Text>
        </LoggedOutBannerContainer>
      )}
      <div className="container-fluid add-subscription-container">
        <div className="container">
          <div className="justify-content-md-center">
            {
              [
                <SelectSubscriptionScreen
                  {...selectSubscriptionScreenProps}
                  key={0}
                />,
                <SelectSubPaymentPlanScreen
                  {...selectSubPaymentPlanScreenProps}
                  key={1}
                />,
              ][currentStep]
            }
          </div>
        </div>
      </div>
    </>
  );
};
