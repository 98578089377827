import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "styled-components";
import { Text, TEXT_WEIGHT } from "../Text/Text";
import { InfoBadgeContainer } from "./InfoBadge.styles";
import { PennyDollarFormatter } from "../../../../store/utils/formatUtils";
import { MouseEventHandler } from "react";
import { ToolTipTextArea } from "../../../components/ToolTipTextArea/ToolTipTextArea";
import {
  paywallTooltipDescription,
  ScheduledProjectPaywallTypes,
} from "../../../../store/models/project";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";

export enum InfoBadgeType {
  NOT_FUNDED,
  PARTIALLY_PAID,
}

export interface InfoBadgeProps {
  type: InfoBadgeType;
  outstandingBalance?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
  showToolTip?: boolean;
  userIsEngineerOnProject?: boolean;
  paywallOption?: ScheduledProjectPaywallTypes;
}

/**
 * InfoBadge component displays a badge with an icon and a message.
 * Currently it only supports a single type of badge, but in the future this can be extended to support more types.
 */
export const InfoBadge = ({
  type,
  outstandingBalance = 0,
  onClick,
  showToolTip = false,
  userIsEngineerOnProject,
  paywallOption,
}: InfoBadgeProps) => {
  const theme = useTheme();
  const { isMobile } = useMediaQueryBreakpoint();
  const readableBalance = PennyDollarFormatter().format(outstandingBalance);

  const getTooltipText = () => {
    if (paywallOption === undefined) return "";
    const paywallDescription = paywallTooltipDescription.get(paywallOption);
    if (userIsEngineerOnProject) {
      if (type === InfoBadgeType.NOT_FUNDED) {
        return `This project is not yet funded. The artist must complete payment before ${paywallDescription} is allowed.`;
      }
      if (type === InfoBadgeType.PARTIALLY_PAID) {
        return `This project has a remaining balance of ${readableBalance}. The remaining balance must be paid before ${paywallDescription} is allowed.`;
      }
    } else {
      if (type === InfoBadgeType.NOT_FUNDED) {
        return `This project is not yet funded. You must complete payment before ${paywallDescription} is allowed.`;
      }
      if (type === InfoBadgeType.PARTIALLY_PAID) {
        return `This project has a remaining balance of ${readableBalance}. You must complete payment before ${paywallDescription} is allowed.`;
      }
    }
    return "This project is not yet fully funded.";
  };

  // The theme colors aren't aliased for now - this can be done in the future if needed
  const badgeThemes = {
    [InfoBadgeType.NOT_FUNDED]: {
      backgroundColor: theme.colorPalette.BoxyYellow100,
      icon: faExclamationTriangle,
      message: "Not funded",
      textColor: theme.colorPalette.BoxyYellow700,
    },
    [InfoBadgeType.PARTIALLY_PAID]: {
      backgroundColor: theme.colorPalette.BoxyYellow100,
      icon: faExclamationTriangle,
      message:
        outstandingBalance > 0
          ? `Pending balance: ${readableBalance}`
          : `Pending balance`,
      textColor: theme.colorPalette.BoxyYellow700,
    },
  };

  const badge = badgeThemes[type];

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <InfoBadgeContainer
        onClick={onClick}
        $clickable={typeof onClick !== "undefined"}
        $backgroundColor={badge.backgroundColor}
      >
        <FontAwesomeIcon
          icon={badge.icon}
          size="sm"
          style={{ color: badge.textColor }}
        />
        <Text weight={TEXT_WEIGHT.BOLD} style={{ color: badge.textColor }}>
          {badge.message}
        </Text>
      </InfoBadgeContainer>
      {showToolTip && paywallOption !== undefined && (
        <div style={{ marginLeft: "8px" }}>
          <ToolTipTextArea
            text={getTooltipText()}
            tooltipTextStyle={{
              width: "300px",
              maxWidth: "300px",
            }}
          />
        </div>
      )}
    </div>
  );
};
