import { ReactElement, useMemo } from "react";
import { Alt, altToDisplayString } from "../../../store/models/alts";
import {
  Project,
  ProjectType,
  projectTypeReadableName,
} from "../../../store/models/project";
import Service from "../../../store/models/service";
import { Transaction } from "../../../store/models/transaction";
import User from "../../../store/models/user";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { getIncludedIncentiveByProjectType } from "../../../store/utils/serviceUtils";
import { Pill } from "../../elements/Pill/Pill";
import { ColorPalette } from "../../theme";
import { CheckoutAltsForm } from "../CheckoutAltsForm/CheckoutAltsForm";
import "./OrderSummaryCard.css";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { Link } from "react-router-dom";
import { CollapsableComponent } from "../CollapsableComponent/CollapsableComponent";

interface AltMetadata {
  title: string;
  alts: string[];
  totalPrice: number;
}

interface ProjectTypeMetadata {
  count: number;
  subTotal: number;
  firstProject: Project;
  altMetadata: AltMetadata[];
}
export interface OrderSummaryCardProps {
  engineerUser?: User;
  transactionData?: Transaction;
  alts?: Set<Alt>;
  service?: Service;
  showPromoField: boolean;
  children?: React.ReactNode;
  scheduledProjectId?: number;
}

export const OrderSummaryCard = ({
  transactionData,
  alts,
  service,
  children,
  scheduledProjectId,
}: OrderSummaryCardProps) => {
  let firstProject: Project | undefined = undefined;
  if (
    transactionData?.items !== undefined &&
    transactionData?.items.length > 0
  ) {
    firstProject = transactionData?.items[0]?.project;
  }

  const artistName = firstProject?.artist_name;

  const projectSongTitles: string[] = useMemo(() => {
    const projectSongTitles = [];
    let untitledCount = 0;
    transactionData?.items?.forEach((transactionItem) => {
      if (!transactionItem.project) return;
      if (transactionItem.project.title.indexOf("Untitled") > -1) {
        untitledCount++;
      } else {
        projectSongTitles.push(transactionItem.project.title);
      }
    });
    if (untitledCount > 0) {
      projectSongTitles.push(`Untitled x${untitledCount}`);
    }
    return projectSongTitles;
  }, [transactionData]);

  const projectsByTypeMap: Map<ProjectType, ProjectTypeMetadata> =
    useMemo(() => {
      const projectsByTypeMap = new Map<ProjectType, ProjectTypeMetadata>();
      transactionData?.items?.forEach((transactionItem) => {
        if (!transactionItem.project) return;
        const serviceType = transactionItem.project.service_type;
        if (!projectsByTypeMap.has(serviceType)) {
          projectsByTypeMap.set(serviceType, {
            count: 1,
            subTotal: +transactionItem.amount,
            firstProject: transactionItem.project,
            altMetadata: [],
          });
        } else {
          projectsByTypeMap.get(transactionItem.project.service_type)!.count +=
            1;
          projectsByTypeMap.get(
            transactionItem.project.service_type,
          )!.subTotal += +transactionItem.amount;
        }
        if (
          transactionItem.project.alts &&
          transactionItem.project.alts.length > 0
        ) {
          const projectAlts: AltMetadata = {
            title: transactionItem.project.title,
            alts: ["Main"],
            totalPrice: 0,
          };
          transactionItem.project.alts.forEach(({ alt, alt_price }) => {
            projectAlts.alts.push(altToDisplayString[alt]);
            projectAlts.totalPrice += +alt_price;
          });
          projectsByTypeMap
            .get(transactionItem.project.service_type)!
            .altMetadata.push(projectAlts);
        }
      });
      return projectsByTypeMap;
    }, [transactionData]);

  const getOrderSubtitle = () => {
    if (firstProject) {
      const usersNames = firstProject?.users.map((user) => {
        return (
          <Link to={"/" + user.username} key={user.id}>
            <strong>{getDisplayableNameForUser(user)}</strong>
          </Link>
        );
      });
      return (
        <div>
          <p className="my-3">
            {projectTypeReadableName.get(
              firstProject?.service_type ?? ProjectType.NO_TYPE,
            ) + " project with "}
            {usersNames.map((jsxObject) => {
              return <>{jsxObject} </>;
            })}
            {artistName && " for " + artistName}
          </p>
        </div>
      );
    }
    return null;
  };

  const getOrderTotal = () => {
    if (transactionData) {
      return PennyDollarFormatter().format(
        (transactionData?.total_price ?? 0) +
          (transactionData?.fees_collected ?? 0),
      );
    }
  };

  const getOrderDetails = (project: Project) => {
    return (
      <div className="order-details">
        <ul className="included-list b2" style={{ marginLeft: "0px" }}>
          {getIncludedIncentiveByProjectType(project).map((benefit, index) => (
            <li className={"b2"} key={index}>
              <div className="checkmark" />
              {benefit}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const getOrderTaxesAndFees = () => {
    if (transactionData) {
      return PennyDollarFormatter().format(
        +(transactionData?.fees_collected ?? NaN),
      );
    }
  };

  const getAltsBreakdown = (altsData: AltMetadata[]) => {
    return altsData.map(({ title, alts, totalPrice }) => {
      const altCount = alts.length;
      const altList = alts.join(", ");
      return (
        <tr className="order-summary-card-alt-breakdown" key={title}>
          <td />
          <td>
            {title} - # of alts: {altCount} ({altList})
          </td>
          <td style={{ textAlign: "right" }}>
            {PennyDollarFormatter().format(totalPrice)}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="d-flex flex-column">
      <h3 className="summary-card-header my-3">Order Summary</h3>
      {scheduledProjectId && (
        <p className={"mb-2"}>
          <strong>{`Project ID: ${scheduledProjectId}`}</strong>
        </p>
      )}
      {getOrderSubtitle()}
      {projectSongTitles.length > 0 && (
        <p>
          <strong>Song titles:</strong> {projectSongTitles.join(", ")}
        </p>
      )}
      <table className="order-summary-table">
        {[...projectsByTypeMap.entries()].map(([projectType, projectData]) => {
          return (
            <div className={"my-2"} key={projectData.firstProject.id}>
              <CollapsableComponent
                hintText={`${
                  projectData.count ?? 0
                } ${projectTypeReadableName.get(
                  projectType,
                )} ${PennyDollarFormatter().format(projectData.subTotal)}`}
              >
                {getOrderDetails(projectData.firstProject)}
              </CollapsableComponent>
              {getAltsBreakdown(projectData.altMetadata)}
            </div>
          );
        })}
      </table>
      {alts && alts.size > 0 && service && (
        <CheckoutAltsForm disabled={true} alts={alts} service={service} />
      )}
      <table
        className="order-summary-table"
        style={{ borderBottom: "solid 1px black" }}
      >
        <tbody>
          <tr style={{ marginBottom: "20px" }}>
            <th>{"Service Fees"}</th>
            <td style={{ textAlign: "right" }}>{getOrderTaxesAndFees()}</td>
          </tr>
        </tbody>
      </table>
      <table className="order-summary-table">
        <tbody>
          <tr>
            <td>
              <div style={{ width: "20px" }}>
                <Pill
                  label={"SUBTOTAL"}
                  backgroundColor={ColorPalette.TimbreTan}
                  color="black"
                />
              </div>
            </td>
            <th style={{ textAlign: "right" }}>{getOrderTotal()}</th>
          </tr>
        </tbody>
      </table>
      {children}
    </div>
  );
};
