import { ReactElement } from "react";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import Notification from "../../../store/models/notifications";
import { notificationIconList } from "../../assets/notificationIcons";
import { UserProfileImage } from "../UserProfileImage/UserProfileImage";
import "./NotificationRow.css";
import { ColorPalette } from "../../theme";
import {
  removeTimeFromDate,
  getFormattedTimeStringWithoutDateFromLocalDate,
} from "../../../store/utils/dateTimeUtils";

export interface NotificationRowProps {
  notification: Notification;
}

export const NotificationRow = ({
  notification,
}: NotificationRowProps): ReactElement => {
  const { isDesktop } = useMediaQueryBreakpoint();

  return (
    <div className="notification-div">
      {notification.generating_user && (
        <UserProfileImage
          isCircle={true}
          hideBorder={false}
          username={notification.generating_user?.username}
          source={notification.generating_user?.photo?.path}
          height={!isDesktop ? 30 : 40}
          width={!isDesktop ? 30 : 40}
        />
      )}
      <img
        alt="trophy badge"
        className={`badge--img`}
        src={notificationIconList[notification.type!]}
      />
      <div>
        <p className="notification-date-time-stamp notification-text">
          {removeTimeFromDate(new Date(notification.created)) +
            " " +
            getFormattedTimeStringWithoutDateFromLocalDate(
              new Date(notification.created),
            )}
        </p>
        <p className="notification-text">{notification.text}</p>
      </div>
      {!notification.read && (
        <div
          className="read-key"
          style={{ backgroundColor: ColorPalette.BoomyOrange400 }}
        />
      )}
    </div>
  );
};
