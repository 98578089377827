import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { addHours, addMinutes } from "date-fns";
import { FC, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import { useAppSelector } from "../../../../store/hooks";
import { RecordingSession } from "../../../../store/models/recordingSession";
import { NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY } from "../../../../store/models/workingHours";
import { getFormattedTimeStringWithoutDateFromLocalDate } from "../../../../store/utils/dateTimeUtils";
import { getDisplayableNameForUser } from "../../../../store/utils/entityUtils";
import { getRecordingSessionRoute } from "../../../../store/utils/routeGetters";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import {
  Text,
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../../core-ui/components/Text/Text";
import { useSessionTableViewContext } from "../../../screens/BookingsScreen/components/SessionTableView/useSessionTableViewContext";
import "./SessionEventBubble.css";

const MINIMUM_DISPLAYABLE_WIDTH = 47;

export interface SessionEventBubbleProps {
  session: RecordingSession;
  width?: number;
  offset: number;
}

export const SessionEventBubble: FC<SessionEventBubbleProps> = ({
  session,
  width,
  offset,
}) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const { isTablet } = useMediaQueryBreakpoint();
  const history = useHistory();
  const timeDetails = useMemo(() => {
    const date = new Date(session.first_choice_datetime);
    const endDate = addMinutes(
      addHours(date, session.session_duration_minutes / 60),
      session.session_duration_minutes % 60,
    );

    if (!width || width > MINIMUM_DISPLAYABLE_WIDTH) {
      return `${getFormattedTimeStringWithoutDateFromLocalDate(
        date,
      )}-${getFormattedTimeStringWithoutDateFromLocalDate(endDate)}`;
    }
    // for small sessions we don't need the end time, and we don't show am/pm
    return `${getFormattedTimeStringWithoutDateFromLocalDate(date)}`.replace(
      /[a-zA-Z]/g,
      "",
    );
  }, [session, width]);

  const nonSelfUserNames = useMemo(() => {
    return session.users
      .filter(
        (u) =>
          u.id !== user?.id &&
          (u.id === session.artist.user_id ||
            u.id == session.engineer?.user_id),
      )
      .map((u) => getDisplayableNameForUser(u))
      .join(" & ");
  }, [session.users, user, session.artist, session.engineer?.user_id]);

  const eventTitle = useMemo(() => {
    if (session.studio_room) {
      return `${session.studio_room.room_name} | ${nonSelfUserNames}`;
    }
    return nonSelfUserNames;
  }, [session, nonSelfUserNames]);

  const sessionTableViewContextValue = useSessionTableViewContext();

  const handleNavigateToSession = useCallback(() => {
    if (!session) {
      return;
    }
    if (sessionTableViewContextValue) {
      if (session.pending_my_acceptance_or_rejection) {
        sessionTableViewContextValue.handleOpenSessionBookingRequestedPanel(
          session.recording_session_request_id,
        );
      } else {
        sessionTableViewContextValue.handleOpenSessionDetailsPanel(
          session.project.id,
        );
      }
    } else {
      history.push(getRecordingSessionRoute(session.project.id));
    }
  }, [history, session, sessionTableViewContextValue]);

  const sessionEventBubbleClassnames = classNames("session-event-bubble", {
    "pending-acceptance": session.pending_booking_acceptance,
    "session-event-bubble__tablet": isTablet,
  });
  const eventBubbleTextClassnames = classNames("b3 session_event_title", {
    session_event_title__small: !width || width <= MINIMUM_DISPLAYABLE_WIDTH,
  });
  const sessionEventBubbleContainerClassnames = classNames(
    "session-event-bubble-container",
    {
      "render-as-block": !width,
    },
  );

  return (
    <div
      className={sessionEventBubbleContainerClassnames}
      style={
        width
          ? {
              width: `${width}px`,
              left: `${offset}px`,
            }
          : {}
      }
    >
      <div
        className={sessionEventBubbleClassnames}
        onClick={handleNavigateToSession}
      >
        {(!width || width > MINIMUM_DISPLAYABLE_WIDTH) && (
          <p className={"b3 session_event_title "}>{eventTitle}</p>
        )}
        <p className={eventBubbleTextClassnames}>{timeDetails}</p>
      </div>
    </div>
  );
};

interface BookableBubbleProps {
  startIndex: number;
  endIndex: number;
  width: number;
  onClick?: () => void;
  showOnHover?: boolean;
}

export const BookableBubble: FC<BookableBubbleProps> = ({
  startIndex,
  endIndex,
  width,
  onClick,
  showOnHover = false,
}) => {
  // | x | x | x | x |
  //   0   1   2   3
  // Assuming each slot is 10px
  // Then total width is (3 - 0 + 1) * 10 = 40px
  const bubbleWidth = useMemo(() => {
    return (
      (endIndex - startIndex + 1) *
      (width / NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
    );
  }, [endIndex, startIndex, width]);
  const offset = useMemo(() => {
    return (startIndex / 96) * width;
  }, [startIndex, width]);
  const theme = useTheme();
  const { isTablet } = useMediaQueryBreakpoint();

  const bookableBubbleContainerClassnames = classNames(
    "bookable-bubble-container",
    {
      "hide-bookable-bubble-container": showOnHover,
      "bookable-bubble-container__tablet": isTablet,
    },
  );

  const bookableBubbleClassnames = classNames("bookable-bubble", {
    "bookable-bubble__tablet": isTablet,
  });

  return (
    <div
      className={bookableBubbleContainerClassnames}
      style={{
        width: `${bubbleWidth}px`,
        left: `${offset}px`,
      }}
    >
      <Button
        variant={ButtonVariant.UNSTYLED}
        className={bookableBubbleClassnames}
        onClick={onClick}
      >
        <div className="bookable-bubble-backdrop">
          {bubbleWidth > 35 && (
            <>
              <FontAwesomeIcon
                width={12}
                height={12}
                className={"mx-2"}
                color={theme.textPrimaryColor}
                icon={faPlus}
              />
              {!isTablet && (
                <Text
                  size={TEXT_SIZE.MEDIUM}
                  color={TEXT_COLOR.PRIMARY}
                  weight={TEXT_WEIGHT.BOLD}
                >
                  Create Session
                </Text>
              )}
            </>
          )}
        </div>
      </Button>
    </div>
  );
};
