import styled from "styled-components";

export const LivestreamWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const LivestreamOverlay = styled.div<{ $blur?: string }>`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  z-index: var(--livestream-overlay-z-index);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(${({ $blur = "15px" }) => $blur});
`;
