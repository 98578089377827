import { useCallback, useEffect, useMemo } from "react";
import { getTeams } from "../store/actions/team";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { Team, TeamRole } from "../store/models/admins";
import User from "../store/models/user";
import { selectTeamFields } from "../store/selectors/teamSelectors";
import { getDisplayableNameForUser } from "../store/utils/entityUtils";
import { OptionType } from "../stories/elements/DropDownSelector/DropdownSelector";
import { useIsStudioManager } from "./bookingHooks/useStudioEngineerUser";
import { useIsAdmin } from "./useIsAdmin";

export const useGetTeams = (): {
  associatedTeams: Team[];
  selectedTeam: Team | undefined;
  loading: boolean;
  updating: boolean;
  refetch: () => Promise<void>;
} => {
  const dispatch = useAppDispatch();
  const loggedInUser: undefined | User = useAppSelector(
    (state) => state.accountInfo.user,
  );
  const { associatedTeams, selectedTeam, loading, updating } =
    useAppSelector(selectTeamFields);
  const userIsAdmin = useIsAdmin(loggedInUser);
  const userIsStudioManager = useIsStudioManager(loggedInUser);
  const isGetTeamsEnabled = useMemo(
    () => userIsAdmin || userIsStudioManager,
    [userIsAdmin, userIsStudioManager],
  );
  const fetchTeams = useCallback(async () => {
    // Only Admins and studio managers have teams for now.
    if (!isGetTeamsEnabled) {
      return;
    }
    await dispatch(getTeams()).unwrap();
  }, [dispatch, isGetTeamsEnabled]);

  useEffect(() => {
    fetchTeams().catch(() => {});
  }, [dispatch, fetchTeams]);

  return {
    associatedTeams,
    selectedTeam,
    loading,
    updating,
    refetch: fetchTeams,
  };
};

export const useGetTeamOrg = (team: Team | undefined, isLabel: boolean) => {
  return useMemo(() => {
    if (!team) return [];
    const org: { user: User; role: TeamRole }[] = [];
    team.managers.forEach((manager: User) => {
      org.push({
        user: manager,
        role: isLabel ? TeamRole.ADMIN : TeamRole.STUDIO_MANAGER,
      });
    });
    team.members.forEach((member: User) => {
      org.push({
        user: member,
        role: isLabel ? TeamRole.MEMBER : TeamRole.ENGINEER,
      });
    });
    return org;
  }, [team, isLabel]);
};

/**
 * Returns an array of options for a dropdown, where the options are the
 * members of the currently selected team that are not already in the
 * selectedMembers array.
 *
 * @param selectedMembers An array of user IDs already selected.
 * @returns An array of OptionType objects, where each object has a
 *   value property (the user ID) and a label property (the user's
 *   display name).
 */
export const useGetTeamMemberOptions = (
  selectedMembers: (number | undefined)[],
) => {
  const { selectedTeam } = useAppSelector(selectTeamFields);
  const teamMembers = selectedTeam?.members ?? [];
  const availableMembers: OptionType[] = [];
  teamMembers.forEach((member) => {
    if (selectedMembers.includes(member.id)) {
      return;
    }

    availableMembers.push({
      value: member.id,
      label: getDisplayableNameForUser(member),
    });
  });

  return availableMembers;
};

/**
 * Returns an object where the keys are user IDs and the values are the
 * corresponding User objects from the currently selected team.
 *
 * @returns An object with user IDs as keys and User objects as values.
 */
export const useGetTeamMembersById = () => {
  const { selectedTeam } = useAppSelector(selectTeamFields);
  const teamMembers = selectedTeam?.members ?? [];
  const teamMembersById: Record<number, User> = {};

  teamMembers.forEach((member) => {
    teamMembersById[member.id] = member;
  });
  return teamMembersById;
};
