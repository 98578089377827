import { Link } from "react-router-dom";
import { useGetRecordingSessionTimeData } from "../../../hooks/useRecordingSessionsHooks";
import { RecordingSession } from "../../../store/models/recordingSession";
import { getDisplayableNameForStudio } from "../../../store/utils/entityUtils";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import {
  getProfileScreenRoute,
  getStudioRoomScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import {
  StyledRecordingBreakdownContainer,
  StyledRecordingBreakdownRow,
  StyledRecordingBreakdownRowFlexStart,
  StyledRecordingBreakdownText,
  StyledRecordingBreakdownTitle,
} from "./RecordingBreakdown.styles";

export interface RecordingBreakdownProps {
  recordingSession: RecordingSession;
  index: number;
}

export const RecordingBreakdown = ({
  recordingSession,
  index,
}: RecordingBreakdownProps) => {
  const {
    studio_room,
    engineer,
    engineer_payment_amount_split,
    studio_manager_payment_amount_split,
  } = recordingSession;

  const { recordingDate, endTime, startTime, sessionDurationHours } =
    useGetRecordingSessionTimeData(recordingSession);

  const engineerUser = recordingSession.project.users.find(
    (user) => user.id === engineer?.user_id,
  );

  return (
    <StyledRecordingBreakdownContainer>
      <StyledRecordingBreakdownRow>
        <StyledRecordingBreakdownTitle>
          Session {index + 1}
        </StyledRecordingBreakdownTitle>
        <StyledRecordingBreakdownText secondary>
          {recordingDate.toLocaleDateString()}
        </StyledRecordingBreakdownText>
      </StyledRecordingBreakdownRow>
      {studio_room && (
        <>
          <StyledRecordingBreakdownText weight="600" textDecoration="underline">
            <Link to={getStudioScreenRoute(studio_room.studio?.username)}>
              {getDisplayableNameForStudio(studio_room?.studio)}
            </Link>
          </StyledRecordingBreakdownText>
          <StyledRecordingBreakdownRow>
            <StyledRecordingBreakdownText textDecoration="underline">
              <Link
                to={getStudioRoomScreenRoute(
                  studio_room.studio?.username,
                  studio_room.id,
                )}
              >
                {studio_room.room_name}
              </Link>
            </StyledRecordingBreakdownText>
            <StyledRecordingBreakdownText>{`${PennyDollarFormatter().format(studio_manager_payment_amount_split / sessionDurationHours)}/hr`}</StyledRecordingBreakdownText>
          </StyledRecordingBreakdownRow>
        </>
      )}
      {engineerUser && (
        <StyledRecordingBreakdownRow>
          <StyledRecordingBreakdownRowFlexStart>
            <StyledRecordingBreakdownText>{`Recording engineer: `}</StyledRecordingBreakdownText>
            <StyledRecordingBreakdownText
              weight="600"
              textDecoration="underline"
            >
              <Link to={getProfileScreenRoute(engineerUser.username)}>
                @{engineerUser.username}
              </Link>
            </StyledRecordingBreakdownText>
          </StyledRecordingBreakdownRowFlexStart>
          <StyledRecordingBreakdownText>{`${PennyDollarFormatter().format(engineer_payment_amount_split / sessionDurationHours)}/hr`}</StyledRecordingBreakdownText>
        </StyledRecordingBreakdownRow>
      )}
      <StyledRecordingBreakdownRow>
        <StyledRecordingBreakdownText>{`Start time: ${startTime} - ${endTime}`}</StyledRecordingBreakdownText>
        <StyledRecordingBreakdownText>
          {`${sessionDurationHours} hrs`}
        </StyledRecordingBreakdownText>
      </StyledRecordingBreakdownRow>
    </StyledRecordingBreakdownContainer>
  );
};
