import { useMutation } from "@tanstack/react-query";
import { displayEngineEarsError } from "../../api/helpers";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError } from "../../store/actions/errorStore";
import { createWorkingHoursParams } from "../../store/actions/workingHours";
import { WorkingHours } from "../../store/models/workingHours";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { WORKING_HOURS_API } from "../../store/utils/routes";

export const useCreateWorkingHours = () => {
  return useMutation({
    mutationKey: [QUERY_KEYS.CREATE_WORKING_HOURS],
    mutationFn: async (args: createWorkingHoursParams) => {
      const result = await makeBackendPostCallWithJsonResponse<WorkingHours[]>(
        WORKING_HOURS_API,
        args,
      );
      if (result.success) {
        return result.resultJson;
      }

      throw result.resultJson;
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      return error;
    },
  });
};
