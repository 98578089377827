import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FAVORITES } from "../utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { receiveErrors } from "./errorStore";
import { FavoritedAccountEntity } from "../models/favoritedAccount";

interface fetchFavoritesParams {
  page: number;
}

interface FetchFavoritesResponse {
  data: FavoritedAccountEntity[];
  current_page: number;
  total_pages: number;
  count: number;
}

export const fetchFavorites = createAsyncThunk(
  "favorites/fetchFavorites",
  async ({ page }: fetchFavoritesParams, thunkAPI) => {
    const params = `?page=${page}`;
    const response =
      await makeBackendGetCallWithJsonResponse<FetchFavoritesResponse>(
        FAVORITES,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface paginatedFavorites {
  [page: number]: FavoritedAccountEntity[];
}

interface favoritesState {
  paginatedFavorites: paginatedFavorites;
  loading: boolean;
  page: number;
  totalPages: number;
  count: number;
}

const initialState: favoritesState = {
  paginatedFavorites: {},
  loading: false,
  page: 1,
  totalPages: 1,
  count: 0,
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFavorites.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFavorites.fulfilled, (state, action) => {
      state.loading = false;
      state.totalPages = action.payload.total_pages;
      state.count = action.payload.count;
      state.page = action.payload.current_page;
      state.paginatedFavorites[action.payload.current_page] =
        action.payload.data;
    });
    builder.addCase(fetchFavorites.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default favoritesSlice.reducer;
