import { atom, SetStateAction } from "jotai";
import { ReactNode } from "react";

const dashboardSidePanelOpenAtom = atom(false);
const dashboardSidePanelTitleAtom = atom("");
const dashboardSidePanelChildrenAtom = atom(undefined as ReactNode);
enum DASHBOARD_SIDE_PANEL_OPEN_DELAY {
  default = 100,
}

export const dashboardSidePanelAtom = atom(
  (get) => {
    return {
      isOpen: get(dashboardSidePanelOpenAtom),
      title: get(dashboardSidePanelTitleAtom),
      children: get(dashboardSidePanelChildrenAtom),
    };
  },
  (
    _get,
    set,
    children: ReactNode | SetStateAction<ReactNode>,
    title: string | SetStateAction<string> = "",
  ) => {
    // We delay opening the drawer to make sure the content can render smoothly
    if (typeof children === "undefined") {
      set(dashboardSidePanelOpenAtom, false);
    } else {
      setTimeout(() => {
        set(dashboardSidePanelOpenAtom, true);
      }, DASHBOARD_SIDE_PANEL_OPEN_DELAY.default);
    }
    set(dashboardSidePanelTitleAtom, title);
    set(dashboardSidePanelChildrenAtom, children);
  },
);
dashboardSidePanelAtom.debugLabel = "Dashboard Side Panel";
