import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { useCopyToClipboard } from "usehooks-ts";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { AnchorTag, FileLinkRowContainer } from "./FileLinkRow.styles";

export interface FileLinkRowProps {
  url: string;
}

export const FileLinkRow = ({ url }: FileLinkRowProps) => {
  const theme = useTheme();
  const [, copy] = useCopyToClipboard();

  const handleCopy = () => {
    copy(url)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy!");
      });
  };
  return (
    <FileLinkRowContainer>
      <AnchorTag href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </AnchorTag>
      <Button onClick={handleCopy} variant={ButtonVariant.UNSTYLED}>
        <FontAwesomeIcon color={theme.textSecondaryColor} icon={faCopy} />
      </Button>
    </FileLinkRowContainer>
  );
};
