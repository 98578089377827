import Engineer from "../models/engineer";
import { createSlice } from "@reduxjs/toolkit";
import { getUserEngineer } from "./accountInfo";

interface Engineers {
  [engineer_id: number]: Engineer | undefined;
}

const initialState: Engineers = {};

export const engineersSlice = createSlice({
  name: "engineers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserEngineer.fulfilled, (state, action) => {
      const engineer = action.payload;
      state[engineer.id] = engineer;
    });
  },
});

export default engineersSlice.reducer;
