import { useFetchReleaseDetailsQuery } from "./useFetchReleaseDetailsQuery";

export const useReleaseDetails = (releaseId = 0) => {
  const { data: releaseDetails, isPending } =
    useFetchReleaseDetailsQuery(releaseId);
  return {
    recordingEngineerCollaborators:
      releaseDetails?.recording_engineer_users || [],
    mixingEngineerCollaborators: releaseDetails?.mixing_engineer_users ?? [],
    masteringEngineerCollaborators:
      releaseDetails?.mastering_engineer_users ?? [],
    dolbyAtmosMixingEngineerCollaborators:
      releaseDetails?.dolby_atmos_mixing_engineer_users ?? [],
    isReleaseDetailsInitialLoading: isPending,
    // Temporarily hide play count
    // playCount: releaseDetails?.total_play ?? 0,
  };
};
