import { useMemo } from "react";
import User from "../store/models/user";

export const isAdmin = (user: User | undefined): boolean => {
  if (!user) {
    return false;
  }
  return Boolean(user.admin && !user.admin?.deleted);
};

export const useIsAdmin = (user: User | undefined): boolean => {
  return useMemo(() => {
    return isAdmin(user);
  }, [user]);
};
