import { styled } from "styled-components";

export const StyledPlayBackRowContainer = styled.div`
  position: relative;
  background-color: inherit;
  flex: 4;
`;

export const StyledPlayBackRowColumn = styled.div<{ $isSnippet?: boolean }>`
  display: flex;
  flex-direction: ${({ $isSnippet }) => ($isSnippet ? "row" : "column")};
  width: 100%;
  gap: 8px;
`;

export const StyledPlayBackRowRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const StyledPlayBackRowNoFileContainer = styled.div`
  width: 100%;
  height: 12px;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  color: ${({ theme }) => theme.textSecondaryColor};
  font-size: ${({ theme }) => theme.textSizeXs};
`;

interface StyledPlaybackRowCommentProps {
  $showComment: boolean;
  $commentToShow: boolean;
}

export const StyledPlayBackRowCommentContainer = styled.div<StyledPlaybackRowCommentProps>`
  position: absolute;
  bottom: 30px;
  padding: 5px;
  height: 55px;
  width: calc(100% - 10px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: visible;
  align-items: center;
  opacity: 0;
  z-index: var(--playback-row-comment-container-z-index);
  pointer-events: none;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  ${({ $showComment, $commentToShow }) => {
    if (!$commentToShow) {
      return "";
    }
    return `animation: ${$showComment ? "fadeIn" : "fadeOut"} 2s forwards;`;
  }};
`;

export const StyledPlayBackRowCommentIconContainer = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: solid thin ${({ theme }) => theme.colorPalette.BoomyOrange400};
  background-color: ${({ theme }) => theme.colorPalette.BoomyOrange400};
  display: inline-block;
`;

export const StyledPlayBackRowCommentBubble = styled.p`
  max-width: 70%;
  margin-left: calc(var(--project-screen-element-margin) / 2);
  background-color: ${({ theme }) => theme.colorPalette.BoomyOrange400};
  color: ${({ theme }) => theme.textPrimaryColor};
  opacity: 0.95;
  border-radius: 15px;
  text-align: left;
  padding: 10px;
  overflow-wrap: anywhere;
`;

export const StyledPlayBackRowSpan = styled.span`
  display: inline-block;
`;
