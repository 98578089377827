import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  FooterFileTrackType,
  setIndex,
  setSeekTo,
} from "../../store/actions/abPlayerStore";
import { usePlayPauseOnFooter, useSetPlayList } from "./useGetFooterPlayerRef";
import { useAppSelector } from "../../store/hooks";
import { PlaylistTrack } from "../../store/models/playListTrack";

export const useReleaseTrackPlayButtonPressed = (
  releaseId: number,
  isPending: boolean,
  tracks: PlaylistTrack[],
  releaseTrackIndex: number,
) => {
  const { handleClick } = usePlayPauseOnFooter();
  const dispatch = useDispatch();
  const setReleasePlaylist = useSetPlayList();
  const { playlistId, currentTrackIndex } = useAppSelector(
    (state) => state.abPlayerStore,
  );

  return useCallback(() => {
    if (isPending) return;
    if (releaseId !== playlistId) {
      setReleasePlaylist({
        tracks: tracks,
        index: releaseTrackIndex < 0 ? 0 : releaseTrackIndex,
        playOnLoad: true,
        playlistId: releaseId,
        footerFileTrackType: FooterFileTrackType.RELEASE,
      });
      return;
    }
    void handleClick();
    if (currentTrackIndex !== releaseTrackIndex) {
      dispatch(setSeekTo(0));
      dispatch(setIndex(releaseTrackIndex));
    }
  }, [
    isPending,
    releaseId,
    playlistId,
    currentTrackIndex,
    releaseTrackIndex,
    dispatch,
    setReleasePlaylist,
    tracks,
    handleClick,
  ]);
};
