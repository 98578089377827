import { FileVersion, FileVersionComment } from "../models/fileVersion";
import { altToDisplayString } from "../models/alts";
import { ProjectType } from "../models/project";

export const REGION_IDENTIFIER_LENGTH = 10;

export const generateRegionIdentifier = (length: number) => {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  return Array.from(
    { length },
    () => characters[Math.floor(Math.random() * characters.length)],
  ).join("");
};

export const getTrackVersionText = (
  fileVersion: FileVersion,
  projectType?: ProjectType,
) => {
  if (fileVersion.reference) {
    if (projectType === ProjectType.MASTERING) {
      return "Mix";
    } else {
      return "Reference";
    }
  } else {
    const altDisplayString = altToDisplayString[fileVersion.alt];
    const version = fileVersion.version + 1;
    return `${altDisplayString} v${version}`;
  }
};

export const getTotalTrackCommentsCount = (
  comments: FileVersionComment[],
): number => {
  let count = 0;
  for (const comment of comments) {
    count++;
    if (comment.replies) {
      count += getTotalTrackCommentsCount(comment.replies);
    }
  }
  return count;
};
