import { ADD_PROJECT_COMMENT, LOAD_PROJECT_COMMENT } from "../utils/routes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import { receiveErrors } from "./errorStore";
import { ProjectComment } from "../models/projectComment";

export interface postProjectCommentParams {
  project_id: string;
  name: string;
  approved: boolean;
  comment: string;
}

interface ProjectCommentResponse {
  comments: ProjectComment[];
}

export const postProjectComment = createAsyncThunk(
  ADD_PROJECT_COMMENT,
  async (args: postProjectCommentParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<ProjectCommentResponse>(
        ADD_PROJECT_COMMENT,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const fetchProjectComments = createAsyncThunk(
  LOAD_PROJECT_COMMENT,
  async (args: { project_id: string }, thunkAPI) => {
    const { project_id } = args;
    const params = "?project_id=".concat(project_id);
    const result =
      await makeBackendGetCallWithJsonResponse<ProjectCommentResponse>(
        LOAD_PROJECT_COMMENT,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface ProjectComments {
  [project_id: string]: undefined | ProjectComment[];
}

interface ProjectCommentsState {
  comments: ProjectComments;
  loading: boolean;
}

const initialProjectComments: ProjectComments = {};

const initialState: ProjectCommentsState = {
  comments: initialProjectComments,
  loading: false,
};

const projectCommentsSlice = createSlice({
  name: "projectCommentsSlice",
  initialState,
  reducers: {
    clearComments: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postProjectComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postProjectComment.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(postProjectComment.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { comments } = payload;
      const { project_id } = meta.arg;
      state.loading = false;
      state.comments[project_id] = comments;
    });
  },
});

export const { clearComments } = projectCommentsSlice.actions;

export default projectCommentsSlice.reducer;
