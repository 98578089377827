import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { assertEngineEarsErrorType } from "../../api/helpers";
import { fetchPromotionEngineers } from "../../api/accounts/user/engineer/unverified/fetchPromotionEngineers";
import { useAtomValue } from "jotai";
import { displayEngineerPromoModalAtom } from "../../atoms/modals/promoModals";

const useOptedInUnverifiedEngineersQuery = () => {
  const showModal = useAtomValue(displayEngineerPromoModalAtom);
  return useQuery({
    queryKey: [QUERY_KEYS.GET_OPTED_IN_UNVERIFIED_ENGINEERS],
    queryFn: async () => {
      return fetchPromotionEngineers()
        .then((engineers) => {
          if (assertEngineEarsErrorType(engineers)) {
            return Promise.reject(engineers);
          }
          return engineers;
        })
        .catch(() => {
          return [];
        });
    },
    enabled: showModal,
  });
};

export default useOptedInUnverifiedEngineersQuery;
