import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Event } from "stream-chat";
import { useCreateChatClient } from "../../../../../../hooks/useCreateChatClient";
import { useUserProfileImageURL } from "../../../../../../hooks/useUserProfileImage";
import { SCREENS } from "../../../../../../routes";
import { useAppSelector } from "../../../../../../store/hooks";
import User from "../../../../../../store/models/user";
import { streamApiKey } from "../../../../../../store/utils";
import { getDisplayableNameForUser } from "../../../../../../store/utils/entityUtils";
import {
  NAV_BUTTON_VARIANT,
  NavButton,
  NavButtonProps,
} from "../NavButton/NavButton";
import { useChatContext } from "stream-chat-react";
import { useAtomValue } from "jotai";
import { connectionEstablishedAtom } from "../../../../../../hooks/chatHooks/atoms";

export type NavMessageButtonProps = {
  iconColor?: string;
  onClick?: () => void;
  user: User | undefined;
} & Pick<NavButtonProps, "showIconOnly">;

export const NavMessagebutton: React.FC<NavMessageButtonProps> = ({
  iconColor = "var(--text-primary-color)",
  onClick,
  showIconOnly,
}: NavMessageButtonProps) => {
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [clickedMessages, setClickedMessages] = useState<boolean>(false);
  const history = useHistory();

  const connectionEstablished = useAtomValue(connectionEstablishedAtom);

  const { client } = useChatContext("NavMessageButton");

  const handleEvent = useCallback((ev: Event) => {
    if (ev.total_unread_count !== undefined) {
      setClickedMessages(false);
      setUnreadMessages(ev.total_unread_count);
    }
  }, []);

  useEffect(() => {
    if (!connectionEstablished) {
      return;
    }
    if (!client) return;
    client.on(handleEvent);
    return () => {
      client.off(handleEvent);
    };
  }, [handleEvent, connectionEstablished, client]);

  const showNotification = !clickedMessages && unreadMessages > 0;

  return (
    <NavButton
      variant={NAV_BUTTON_VARIANT.BUTTON}
      frontIcon={
        <FontAwesomeIcon color={iconColor} icon={faEnvelope} size="xl" />
      }
      color={iconColor}
      onClick={() => {
        setClickedMessages(true);
        if (onClick) {
          onClick();
        }
        history.push(SCREENS.MESSAGES);
      }}
      message="Messages"
      showIconOnly={showIconOnly}
      showNotification={showNotification}
    />
  );
};
