import React, { useMemo, useState } from "react";
import {
  PendingBudgetManager,
  PendingPurchaseOrderApprover,
  resendBillingLink,
} from "../../../store/actions/transactions";
import { ReactMultiEmail } from "react-multi-email";
import "./ManageBudgetApprovers.css";
import { useAppDispatch } from "../../../store/hooks";
import { updatePurchaseOrderAdmins } from "../../../store/actions/scheduledProjectPurchaseOrders";
import { toast } from "react-toastify";
import { useBillingInfoSubmitters } from "../../../hooks/useBillingInfoSubmitters";
import { usePendingPurchaseOrderApprovers } from "../../../hooks/usePendingPurchaseOrderApprover";
import classNames from "classnames";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";

export interface ManageBudgetApproversViewProps {
  scheduledProjectId: number;
  purchaseOrderId: number;
  budgetSubmitterEmails: string[];
  budgetApprovers: PendingPurchaseOrderApprover[];
  approvalRequired: boolean;
  billingInfoRequired: boolean;
  children?: React.ReactNode;
}

export const ManageBudgetApproversView = ({
  scheduledProjectId,
  purchaseOrderId,
  budgetSubmitterEmails,
  budgetApprovers,
  approvalRequired,
  billingInfoRequired,
  children,
}: ManageBudgetApproversViewProps) => {
  const [updating, setUpdating] = useState(false);
  const dispatch = useAppDispatch();
  const {
    budgetApprovers: budgetApproversState,
    removeApprover,
    toggleApprovalRequired,
    onChange,
  } = usePendingPurchaseOrderApprovers(budgetApprovers);
  const {
    billingInfoSubmitters: budgetSubmitterEmailsState,
    handleRemoveBillingInfoSubmitterEmail,
    handleBillingInfoSubmitterEmailChange,
  } = useBillingInfoSubmitters(budgetSubmitterEmails);

  const budgetApproversChanged = useMemo(() => {
    const differenceInEmails =
      budgetApproversState.length !== budgetApprovers.length ||
      budgetApproversState.some(
        (budgetApproverInState) =>
          !budgetApprovers.find(
            (budgetApprover) =>
              budgetApprover.email === budgetApproverInState.email,
          ),
      );
    if (!differenceInEmails) {
      return budgetApproversState.some((budgetApproverInState) =>
        budgetApprovers.find(
          (budgetApprover) =>
            budgetApprover.email === budgetApproverInState.email &&
            budgetApprover.approval_required !==
              budgetApproverInState.approval_required,
        ),
      );
    }
    return differenceInEmails;
  }, [budgetApproversState, budgetApprovers]);

  const budgetSubmitterEmailsChanged = useMemo(() => {
    // Check if emails got added or removed from the state vs the props.
    return (
      budgetSubmitterEmailsState.length !== budgetSubmitterEmails.length ||
      budgetSubmitterEmailsState.some(
        (email) => !budgetSubmitterEmails.includes(email),
      )
    );
  }, [budgetSubmitterEmailsState, budgetSubmitterEmails]);

  const resendBillingLinks = () => {
    setUpdating(true);
    dispatch(
      resendBillingLink({
        purchase_order_id: purchaseOrderId,
      }),
    )
      .then(() => toast.success("Billing links sent."))
      .catch(() => toast("Something went wrong. Please try again."))
      .finally(() => setUpdating(false));
  };

  const handleUpdateBudgetManagers = () => {
    setUpdating(true);
    const budgetApprovers: PendingBudgetManager[] = budgetApproversState.reduce(
      (acc, curr) => {
        const approver = {
          email: curr.email,
          can_approve_budget: true,
          can_submit_billing_info: false,
          approval_required: curr.approval_required,
        };
        return [...acc, approver];
      },
      [] as PendingBudgetManager[],
    );

    budgetSubmitterEmailsState.forEach((email) => {
      const budgetManager = budgetApprovers.find(
        (budgetManager) => budgetManager.email === email,
      );
      if (budgetManager) {
        budgetManager.can_submit_billing_info = true;
      } else {
        budgetApprovers.push({
          email,
          can_approve_budget: false,
          can_submit_billing_info: true,
          approval_required: false,
        });
      }
    });
    dispatch(
      updatePurchaseOrderAdmins({
        budget_managers: budgetApprovers,
        purchase_order_id: purchaseOrderId,
        scheduledProjectId,
      }),
    ).finally(() => setUpdating(false));
  };

  const containerClassnames = classNames(
    "my-2 manage-budget-approvers-container",
  );

  if (approvalRequired) {
    return (
      <div className={containerClassnames}>
        <p className={"b1-semi-bold"}>Budget Approvers</p>
        <p className={"b1 my-2"}>
          Your project budget is still missing required approvals
        </p>
        {children}
        <ReactMultiEmail
          className="purchase-order-form-multi-email"
          placeholder="Enter email addresses"
          emails={budgetApproversState.map((approver) => approver.email)}
          onChange={onChange}
          getLabel={(
            email: string,
            index: number,
            removeEmail: (index: number) => void,
          ) => {
            const approver = budgetApproversState?.find(
              (approver) => approver.email === email,
            );
            return (
              <div
                data-tag
                key={index}
                className={
                  !approver?.approval_required
                    ? "approval-not-required-email budget-approval-email-field"
                    : "budget-approval-email-field"
                }
                onClick={() => {
                  toggleApprovalRequired?.(email);
                }}
              >
                {email}
                <span
                  data-tag-handle
                  onClick={(e) => {
                    if (budgetApproversState.length === 1) {
                      return toast.error(
                        "You must have at least one budget approver",
                      );
                    }
                    e.stopPropagation();
                    removeApprover(email);
                    removeEmail(index);
                  }}
                >
                  ×
                </span>
              </div>
            );
          }}
        />
        {budgetApproversChanged && (
          <PopConfirm
            title={"Update billing approvers"}
            description={""}
            onConfirm={handleUpdateBudgetManagers}
          >
            <Button
              className={"mb-2 update-admin-emails-button"}
              variant={ButtonVariant.OUTLINED}
              loading={updating}
              disabled={!budgetApproversChanged}
            >
              Update Approvers
            </Button>
          </PopConfirm>
        )}
        <PopConfirm
          title={"Send billing approval links"}
          description={""}
          onConfirm={resendBillingLinks}
        >
          <Button
            className={"mb-4 update-admin-emails-button"}
            variant={ButtonVariant.PRIMARY}
            loading={updating}
          >
            Resend budget approval link
          </Button>
        </PopConfirm>
      </div>
    );
  }
  if (billingInfoRequired) {
    return (
      <div className={"my-2 manage-budget-approvers-container"}>
        <p className={"b1-semi-bold mb-2"}>Billing info submitters</p>
        <ReactMultiEmail
          className="purchase-order-form-multi-email"
          placeholder="Enter email addresses"
          emails={budgetSubmitterEmailsState}
          onChange={(_emails: string[]) => {
            handleBillingInfoSubmitterEmailChange(_emails);
          }}
          getLabel={(
            email: string,
            index: number,
            removeEmail: (index: number) => void,
          ) => {
            return (
              <div data-tag key={index}>
                {email}
                <span
                  data-tag-handle
                  onClick={() => {
                    if (budgetSubmitterEmailsState.length === 1) {
                      return toast.error(
                        "You must have at least one budget submitter",
                      );
                    }
                    handleRemoveBillingInfoSubmitterEmail(email);
                    removeEmail(index);
                  }}
                >
                  ×
                </span>
              </div>
            );
          }}
        />
        {budgetSubmitterEmailsChanged && (
          <PopConfirm
            title={"Update billing info submitters"}
            description={""}
            onConfirm={handleUpdateBudgetManagers}
          >
            <Button
              className={"mb-2 update-admin-emails-button"}
              variant={ButtonVariant.OUTLINED}
              disabled={!budgetSubmitterEmailsChanged}
              loading={updating}
            >
              Update Billing Info Submitters
            </Button>
          </PopConfirm>
        )}
        <PopConfirm
          title={"Send billing info submission links"}
          description={""}
          onConfirm={resendBillingLinks}
        >
          <Button
            className={"mb-4 update-admin-emails-button"}
            variant={ButtonVariant.PRIMARY}
            loading={updating}
          >
            Resend Billing Link
          </Button>
        </PopConfirm>
        {children}
      </div>
    );
  }
  return null;
};
