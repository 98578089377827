import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError } from "../../store/actions/errorStore";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../api/helpers";
import { fetchStudioDetails } from "../../api/recording/studio/fetchStudioDetails";
import { useAppDispatch } from "../../store/hooks";
import { addStudio } from "../../store/actions/studio";
import useInvalidateOnboardingProgress from "../onboardingHooks/useInvalidateOnboardingProgress";

interface UseStudioQueryParams {
  username?: string;
}

const useStudioQuery = ({ username }: UseStudioQueryParams) => {
  const dispatch = useAppDispatch();
  const { invalidateOnboardingProgress } = useInvalidateOnboardingProgress();
  return useQuery({
    queryKey: [QUERY_KEYS.GET_STUDIO, username],
    queryFn: async () => {
      return fetchStudioDetails(username!)
        .then(async (studioDetails) => {
          if (assertEngineEarsErrorType(studioDetails)) {
            return Promise.reject(studioDetails);
          }
          dispatch(addStudio(studioDetails));
          await invalidateOnboardingProgress();
          return studioDetails;
        })
        .catch((e: EngineEarsError) => {
          displayEngineEarsError(e);
          return undefined;
        });
    },
    retry: false,
    enabled: Boolean(username),
  });
};

export default useStudioQuery;
