import "./MapSearchDisplay.css";
import {
  getStudioProfileCard,
  getUserProfileCard,
} from "../../components/ProfileCard/ProfileCard";
import { Studio } from "../../../store/models/studio";
import User from "../../../store/models/user";
import { useHistory } from "react-router-dom";
import {
  getStudioScreenRoute,
  getProfileScreenRoute,
} from "../../../store/utils/routeGetters";
import { MapQuadrantEnum } from "../../../store/actions/mapSearch";

interface MapProfileCardProps {
  studio?: Studio;
  engineer?: User;
  mapQuadrant: MapQuadrantEnum;
  index?: number;
}

export const MapProfileCard = ({
  studio,
  engineer,
  index = 0,
  mapQuadrant,
}: MapProfileCardProps) => {
  const history = useHistory();
  const location = history.location;
  const searchParams = new URLSearchParams(location.search);
  const promoCode = searchParams.get("promocode");
  const path = studio
    ? getStudioScreenRoute(studio.username).concat(
        promoCode ? `?promocode=${promoCode}` : "",
      )
    : getProfileScreenRoute(engineer?.username ?? "").concat(
        promoCode ? `?promocode=${promoCode}` : "",
      );

  return (
    <div
      className={"map-search-display-profile-container".concat(
        ` map-quadrant-${MapQuadrantEnum[mapQuadrant]}`,
      )}
    >
      {studio && getStudioProfileCard(studio, path)}
      {engineer && getUserProfileCard(engineer, index, path)}
    </div>
  );
};
