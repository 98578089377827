import { useMemo } from "react";
import { RecordingSession } from "../../store/models/recordingSession";

export const useGetStudioRoomFromRecordingSession = (
  recordingSession: RecordingSession,
) => {
  return useMemo(() => {
    return recordingSession.studio_room;
  }, [recordingSession]);
};
