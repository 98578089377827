import { useState } from "react";
import { Icon, IconType } from "../../../../../core-ui/components/Icon/Icon";
import { SearchFilterContainer } from "../../../../SearchFilters/SearchFilters";
import "../../NavSearchBar.css";

export const NavSearchFilterButton = () => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  return (
    <>
      <button
        className="nav-search-button nav-filter-button"
        name="searchFilters"
        onClick={() => setShowFilterModal(true)}
        style={{ display: "flex" }}
        type="button"
      >
        <div
          className="nav-filter-button"
          style={{ width: "22px", height: "16px", marginRight: "0.5rem" }}
        >
          <Icon
            height="16"
            type={IconType.Sliders}
            viewBox="0 0 22 16"
            width="22"
          />
        </div>
        <p className="nav-filter-button">Filter</p>
      </button>
      <SearchFilterContainer
        isVisible={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        showStudioFilterOptions
        showEngineerFilterOptions
      />
    </>
  );
};
