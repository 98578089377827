import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import Notification from "../../store/models/notifications";

const selectNotificationStore = (state: RootState) => state.notificationsStore;

export const selectNotificationData = () =>
  createDraftSafeSelector(selectNotificationStore, (store) => {
    const notifications = new Array<Array<Notification> | undefined>(
      store.currentPage,
    );
    Object.keys(store.notifications).forEach((key) => {
      const index = +key;
      notifications[index - 1] = store.notifications[index];
    });

    let flattenedNotifications = notifications.reduce((acc, curr) => {
      if (!curr) return acc;
      if (!acc) return [];
      return [...acc, ...curr];
    }, []);
    if (!flattenedNotifications) {
      flattenedNotifications = [];
    }

    return {
      loading: store.loading,
      hasNextPage: store.currentPage < store.numPages,
      notifications: flattenedNotifications,
      currentPage: store.currentPage,
      unreadCount: store.unreadCount,
    };
  });
