import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useRecordingSessionUserType } from "../../../hooks/bookingHooks/useRecordingSessionUserType";
import { useAddTimeToAvailabilityMap } from "../../../hooks/recordingSessionHooks/useAddTimeToAvailabilityMap";
import {
  useGetAvailabilityOnThisDate,
  useGetRecordingServiceAvailabilities,
} from "../../../hooks/recordingSessionHooks/useGetAvailabilityOnThisDate";
import {
  RecordingSessionAvailability,
  useAvailabilitiesForDatesMap,
} from "../../../hooks/recordingSessionHooks/useGetWorkHoursMap";
import { useIsStudioBooking } from "../../../hooks/recordingSessionHooks/useIsStudioBooking";
import { WorkingHoursOptionType } from "../../../hooks/useGeneralWorkingHoursStringForDay";
import {
  getPendingRecordingSessionBookings,
  postSessionModification,
} from "../../../store/actions/recording";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  RecordingSession,
  SummarizedRecordingSession,
} from "../../../store/models/recordingSession";
import { getFormattedDateTime } from "../../../store/utils/dateTimeUtils";
import { isSummarizedRecordingSession } from "../../../store/utils/recordingUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import {
  Text,
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import "./../RecordingBookingCalendarWidget/RecordingBookingCalendarWidget.css";
import { RescheduleSessionCalendar } from "./components/RescheduleSessionCalendar/RescheduleSessionCalendar";
import { RescheduleSessionTimeInput } from "./components/RescheduleSessionTimeInput/RescheduleSessionTimeInput";
import "./RescheduleSessionModal.css";

export interface RescheduleSessionModalProps {
  recordingSession: RecordingSession;
  showRescheduleModal: boolean;
  onHide: () => void;
}

export const RescheduleSessionModal = ({
  recordingSession,
  showRescheduleModal,
  onHide,
}: RescheduleSessionModalProps) => {
  const {
    handleRescheduleSession,
    isDisabled,
    rescheduleLoading,
    ...restProps
  } = useRescheduleSession(recordingSession, onHide);

  return (
    <BaseModal
      open={showRescheduleModal}
      setOpen={onHide}
      header={"Reschedule Recording Session"}
      onConfirm={handleRescheduleSession}
      confirmButtonDisabled={isDisabled}
      confirmText={"Confirm Reschedule"}
      onCancel={onHide}
      loading={rescheduleLoading}
      showModalFooter={true}
    >
      <RescheduleSessionContent
        recordingSession={recordingSession}
        {...restProps}
      />
    </BaseModal>
  );
};

export const useRescheduleSession = (
  recordingSession: RecordingSession | SummarizedRecordingSession,
  onHide: () => void,
  onUpdateStaleData?: (session: RecordingSession) => Promise<void>,
) => {
  const [rescheduleLoading, setRescheduleLoading] = useState(false);
  const [selectedDatetime, setSelectedDatetime] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<OptionType | null>(null);

  const isDisabled = !selectedDatetime || !selectedTime || rescheduleLoading;
  const user = useAppSelector((state) => state.accountInfo?.user);
  const dispatch = useAppDispatch();
  const onClickDate = (date: Date) => {
    setSelectedTime(null);
    setSelectedDatetime(date);
  };
  const {
    session_duration_minutes,
    studio_room,
    first_choice_datetime,
    engineer,
  } = recordingSession;
  const projectId = isSummarizedRecordingSession(recordingSession)
    ? recordingSession.project_id
    : recordingSession.project.id;
  const studio = studio_room?.studio;

  // since engineer is not in the recordingSession object on Pending Projects page
  // engineer cannot be used to determine if user is the same as engineer
  const { isStudioManager: isUserSameAsStudioManager } =
    useRecordingSessionUserType(studio, engineer);
  const isStudioBooking = useIsStudioBooking(recordingSession);
  const engineerUserId = recordingSession.engineer?.user_id;
  const isUserSameAsEngineer = user?.id === engineerUserId;
  const currentStudioDateMap = useAvailabilitiesForDatesMap(
    studio_room?.id,
    RecordingSessionAvailability.STUDIO,
  );
  const currentEngineerDateMap = useAvailabilitiesForDatesMap(
    engineerUserId,
    RecordingSessionAvailability.ENGINEER,
  );

  // Add the current session to the availability maps
  const studioDateMap = useAddTimeToAvailabilityMap(
    currentStudioDateMap,
    first_choice_datetime,
    session_duration_minutes,
  );
  const engineerDateMap = useAddTimeToAvailabilityMap(
    currentEngineerDateMap,
    first_choice_datetime,
    session_duration_minutes,
  );

  const {
    getStudioAvailability,
    getEngineerAvailability,
    getOverlappingAvailability,
  } = useGetAvailabilityOnThisDate(
    studioDateMap,
    engineerDateMap,
    session_duration_minutes,
  );

  const { isLoadingStudioAvailabilities, isLoadingEngAvailabilities } =
    useGetRecordingServiceAvailabilities(engineerUserId, studio_room?.id, true);

  const availabilitiesLoading =
    isLoadingEngAvailabilities || isLoadingStudioAvailabilities;

  const requestedDate = useMemo(() => {
    if (!selectedDatetime) {
      return null;
    }
    const timezone = studio_room?.studio?.timezone ?? null;
    if (!timezone) {
      return selectedDatetime.toLocaleDateString();
    }

    const startTime = new Date(
      selectedDatetime.toLocaleString("en-US", {
        timeZone: timezone,
      }),
    );
    return startTime.toLocaleDateString();
  }, [selectedDatetime]);

  const handleTimeSelected = (time: WorkingHoursOptionType | OptionType) => {
    if (!selectedDatetime) {
      return;
    }
    setSelectedTime(time);
    const date = new Date(selectedDatetime);
    date.setTime(time.value);
    setSelectedDatetime(date);
  };

  const handleRescheduleSession = useCallback(async () => {
    if (!selectedDatetime || !projectId || !recordingSession) return;
    setRescheduleLoading(true);
    const formattedDatetime = getFormattedDateTime(selectedDatetime);

    await dispatch(
      postSessionModification({
        project_id: projectId,
        requested_data: {
          new_datetime: formattedDatetime,
        },
      }),
    )
      .unwrap()
      .then(async (recordingSession) => {
        toast.success(
          "You have requested a reschedule. The involved clients will be notified and will need to approve the request.",
        );

        if (onUpdateStaleData) {
          await onUpdateStaleData(recordingSession);
        } else {
          await dispatch(getPendingRecordingSessionBookings());
        }

        setRescheduleLoading(false);
        onHide();
        // TODO: Propagate the result to the pending requests list, if necessary.
      })
      .catch(() => {
        toast.error(
          "There was an error requesting a reschedule. Please try again.",
        );
        setRescheduleLoading(false);
      });
  }, [recordingSession, projectId, dispatch, selectedDatetime]);

  return {
    handleRescheduleSession,
    isDisabled,
    rescheduleLoading,
    engineerDateMap,
    isUserSameAsEngineer,
    isUserSameAsStudioManager,
    isStudioBooking,
    selectedDatetime,
    selectedTime,
    studioDateMap,
    getEngineerAvailability,
    getStudioAvailability,
    getOverlappingAvailability,
    onClickDate,
    handleTimeSelected,
    requestedDate,
    availabilitiesLoading,
  };
};

interface RescheduleSessionContentProps {
  recordingSession: RecordingSession | SummarizedRecordingSession;
  engineerDateMap: Map<string, string>;
  isUserSameAsEngineer: boolean;
  isUserSameAsStudioManager: boolean;
  isStudioBooking: boolean;
  selectedDatetime: Date | null;
  selectedTime: OptionType | null;
  studioDateMap: Map<string, string>;
  getEngineerAvailability: (date: Date) => Date[];
  getStudioAvailability: (date: Date) => Date[];
  getOverlappingAvailability: (date: Date) => Date[];
  onClickDate: (date: Date) => void;
  handleTimeSelected: (time: WorkingHoursOptionType | OptionType) => void;
  requestedDate: string | null;
  availabilitiesLoading: boolean;
}

export const RescheduleSessionContent = ({
  engineerDateMap,
  isUserSameAsEngineer,
  isUserSameAsStudioManager,
  isStudioBooking,
  selectedDatetime,
  selectedTime,
  studioDateMap,
  getEngineerAvailability,
  getStudioAvailability,
  getOverlappingAvailability,
  onClickDate,
  handleTimeSelected,
  requestedDate,
  availabilitiesLoading,
  recordingSession,
}: RescheduleSessionContentProps) => {
  if (availabilitiesLoading) {
    return <SoundWaveLoader width={100} height={100} />;
  }

  return (
    <div className="rescheduler-modal-body">
      <Text
        className="card-subtitle"
        size={TEXT_SIZE.SMALL}
        color={TEXT_COLOR.SECONDARY}
      >
        Select a date and time to reschedule your recording session. Once
        selected, all parties will be notified.
      </Text>
      <RescheduleSessionCalendar
        durationMinutes={recordingSession.session_duration_minutes}
        engineerDateMap={engineerDateMap}
        isUserSameAsEngineer={isUserSameAsEngineer}
        isUserSameAsStudioManager={isUserSameAsStudioManager}
        isStudioBooking={isStudioBooking}
        selectedDateTime={selectedDatetime}
        selectedTime={selectedTime ? new Date(selectedTime.value) : null}
        studioDateMap={studioDateMap}
        trackingEngineer={recordingSession?.engineer}
        getEngineerAvailability={getEngineerAvailability}
        getStudioAvailability={getStudioAvailability}
        getOverlappingAvailability={getOverlappingAvailability}
        onClickDate={onClickDate}
      />
      {selectedDatetime && (
        <div className="rescheduler-modal-selector-container">
          <RescheduleSessionTimeInput
            engineerDateMap={engineerDateMap}
            isEngineer={isUserSameAsEngineer}
            isStudioManager={isUserSameAsStudioManager}
            recordingSession={recordingSession}
            selectedDateTime={selectedDatetime}
            selectedTime={selectedTime}
            handleTimeSelected={handleTimeSelected}
            studioDateMap={studioDateMap}
          />
        </div>
      )}
      <div className="rescheduler-modal-requested-datetime-container">
        {selectedDatetime && selectedTime && (
          <Text
            weight={TEXT_WEIGHT.BOLD}
          >{`Requested Date: ${requestedDate} ${selectedTime.label}`}</Text>
        )}
      </div>
    </div>
  );
};
