import { DefaultGenerics, StreamChat, AscDesc } from "stream-chat";
import {
  PaginatedScheduledProject,
  ScheduledProject,
} from "../../../store/models/scheduledproject";
import { RecordingSession } from "../../../store/models/recordingSession";
import { CHANNEL_ID_PREFIX } from "../../../constants/chat";

const getScheduledProjectStreamFilter = (
  scheduledProject?: ScheduledProject | PaginatedScheduledProject,
  loadGroupChat = false,
) => {
  if (!scheduledProject) return {};
  const users = scheduledProject.users;
  const chatID =
    users.length === 2 && !loadGroupChat
      ? `${CHANNEL_ID_PREFIX.DIRECT_MESSAGE}-${users
          .map((user) => user.id)
          .sort(function (a, b) {
            return a - b;
          })
          .join("-")}`
      : `${CHANNEL_ID_PREFIX.SCHEDULED_PROJECT}-${scheduledProject?.id}`;
  return {
    id: chatID,
    members: {
      $in: scheduledProject.users.map((user) => user.id.toString()),
    },
  };
};

const getRecordingSessionStreamFilter = (
  recordingSessionRequestId?: number | null,
  userIds?: number[],
  loadGroupChat = false,
) => {
  let chatID = ``;
  if (recordingSessionRequestId) {
    chatID = `${CHANNEL_ID_PREFIX.SESSION_BOOKING}-${recordingSessionRequestId}`;
  }
  if (userIds?.length === 2 && !loadGroupChat) {
    chatID = `${CHANNEL_ID_PREFIX.DIRECT_MESSAGE}-${userIds
      .map((userId) => userId)
      .sort(function (a, b) {
        return a - b;
      })
      .join("-")}`;
  }
  if (!chatID) {
    return {};
  }
  return {
    id: chatID,
    members: {
      $in: userIds?.map((userId) => userId.toString()) || [],
    },
  };
};

const queryChannelForProjectOrSession = async (
  client: StreamChat<DefaultGenerics>,
  recordingSession?: Pick<
    RecordingSession,
    "recording_session_request_id" | "users"
  >,
  scheduledProject?: ScheduledProject,
) => {
  const sort: { last_message_at: AscDesc } = { last_message_at: -1 };
  let filters = getRecordingSessionStreamFilter(
    recordingSession?.recording_session_request_id,
    recordingSession?.users.map((user) => user.id),
  );
  if (Object.keys(filters).length == 0) {
    filters = getScheduledProjectStreamFilter(scheduledProject);
  }
  return client
    .queryChannels(filters, [sort], {
      watch: true,
      state: true,
    })
    .then((data) => {
      if (data.length > 0) {
        return data[0];
      } else {
        return client
          .queryChannels(filters, [sort], { watch: true, state: true })
          .then((data) => {
            if (data.length > 0) {
              return data[0];
            } else {
              return undefined;
            }
          });
      }
    });
};

export default queryChannelForProjectOrSession;
