import { styled, Box } from "@mui/material";

export const AddSubscriptionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  alignSelf: "center",
  marginLeft: "auto",
  padding: "16px",
  borderRadius: theme.border.radius.md,
  background: theme.palette.standardColor.DeepBlue[50],

  [theme.breakpoints.down("md")]: {
    alignSelf: "flex-start",
    marginLeft: 0,
  },
}));
