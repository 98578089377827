import { UniqueIdentifier } from "@dnd-kit/core";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { useGetUserGenres } from "../../../../hooks/profileScreenHooks/useGetUserGenres";
import { UserMusicGenre } from "../../../../store/actions/accountInfo";
import { genreToStringMap, Genre } from "../../../../store/models/genres";

import { editMode } from "../../../../atoms/profileScreenEdit";
import { ChipsListDisplay } from "../ChipsList/ChipsListDisplay";
import usePostGenresMutation from "../../../../hooks/profileScreenHooks/usePostGenres";
import { Box, Theme, useMediaQuery } from "@mui/material";

interface GenresListProps {
  userId?: number;
}

export const GenresList = ({ userId }: GenresListProps) => {
  const isTabletOrBelow = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );
  const {
    data: userGenres,
    isError: isUserGenresError,
    isLoading: isUserGenresLoading,
  } = useGetUserGenres(userId);
  const [isEditMode] = useAtom(editMode);
  const { mutateAsync, isPending: isPostingGenre } = usePostGenresMutation({
    userId: userId,
  });
  const genres = useMemo(() => {
    if (
      !userGenres ||
      isUserGenresError ||
      isUserGenresLoading ||
      userGenres.length === 0
    ) {
      return [];
    }

    return userGenres.map((userMusicGenre: UserMusicGenre) => {
      return {
        value: userMusicGenre.genre,
        label: genreToStringMap.get(userMusicGenre.genre) ?? "",
      };
    });
  }, [isUserGenresError, isUserGenresLoading, userGenres]);

  const genreOptions = Array.from(genreToStringMap)
    .filter(([id]) => id !== Genre.NO_GENRE)
    .map(([id, name]) => ({
      value: id,
      label: name,
    }));

  const createGenrePostObject = (
    selectedGenre: UniqueIdentifier,
    isAdd: boolean,
  ) => {
    const foundItem = genreOptions.find((opt) => opt.value === selectedGenre);
    if (!foundItem) {
      return null;
    }
    let requestObject = userGenres?.map((userGenre) => userGenre.genre);
    if (!requestObject) {
      return null;
    }

    isAdd
      ? requestObject.push(foundItem.value)
      : (requestObject = requestObject.filter(
          (genre) => genre !== foundItem.value,
        ));
    return requestObject;
  };

  return (
    <Box style={{ marginTop: isTabletOrBelow ? "16px" : "0px" }}>
      <ChipsListDisplay
        title="GENRES"
        dataSource={genres}
        isEditMode={isEditMode}
        loading={isUserGenresLoading || isPostingGenre}
        onAddItem={async (selectedGenre: UniqueIdentifier) => {
          const requestData = createGenrePostObject(selectedGenre, true);
          if (!requestData) {
            return;
          }
          await mutateAsync({ genres: requestData });
        }}
        onDeleteItem={async (selectedGenre: UniqueIdentifier) => {
          const requestData = createGenrePostObject(selectedGenre, false);
          if (!requestData) {
            return;
          }
          await mutateAsync({ genres: requestData });
        }}
        optionsToAdd={genreOptions}
      />
    </Box>
  );
};
