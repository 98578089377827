import { useMemo } from "react";
import { NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY } from "../../store/models/workingHours";
import { getDateTimeFrom15MinuteDayIndex } from "../../store/utils/dateTimeUtils";
import { convertDatesToOptionType } from "../../store/utils/serviceUtils";
import { hoursFromMinutes } from "../../store/utils/shoppingCartUtils";
import { StudioWorkingHoursOptionType } from "../useGeneralWorkingHoursStringForDay";

/**
 * Generates an array of all possible times and labels that can be displayed in a booking dropdown.
 * @param durationMinutes number of minutes expected for the booking session.
 * This is used to calculate the end time shown in the returned label field.
 * @param selectedDate Optional Date object used to calculate the time number in the returned
 * value field. If no date is provided, the time will be calculated based on Date.now().
 * @returns OptionType[] - { value: Date.getTime(), label: "5:00 pm - 6:00 pm"  }
 */
export const useAllPossibleBookingAvailability = (
  durationMinutes: number,
  selectedDate?: Date,
  timezone?: string,
) => {
  const allPossibleHoursReadable = useMemo(() => {
    const startTimes: Date[] = [];

    for (
      let index = 0;
      index <= NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY;
      index += 2
    ) {
      const startTime = getDateTimeFrom15MinuteDayIndex(index, selectedDate);
      if (startTime) {
        startTimes.push(startTime);
      }
    }

    return convertDatesToOptionType(
      startTimes,
      hoursFromMinutes(durationMinutes),
      timezone,
    );
  }, [durationMinutes, selectedDate, timezone]);

  return allPossibleHoursReadable;
};

/**
 * Generates an array of all possible times and labels that can be displayed in a booking dropdown.
 * Uses the `bookingAvailability` param to determine which times are available.
 * Available times are have a boolean field `isWorkingHour` marked as `true`.
 * @param duration number of minutes expected for the booking session.
 * This is used to calculate the end time shown in the returned label field.
 * @param bookingAvailability OptionType[] an array of times and labels that have been determined as available.
 * @param selectedDate Optional Date object used to calculate the time number in the returned
 * value field. If no date is provided, the time will be calculated based on Date.now().
 * @returns DateTimeOptionsReturned[] - { isWorkingHour: true, label: "5:00 pm - 6:00pm", value: Date.getTime() }
 */
export const useOverlappingBookingAvailability = (
  duration: number,
  bookingAvailability: StudioWorkingHoursOptionType[] | undefined,
  selectedDate?: Date,
  timezone?: string,
) => {
  // get array of all possible booking hour ranges
  const allPossibleBookingAvailability = useAllPossibleBookingAvailability(
    duration,
    selectedDate,
    timezone,
  );

  // fill object with all possible booking hour ranges
  const map: Record<string, StudioWorkingHoursOptionType> = {};

  allPossibleBookingAvailability.forEach(({ label, value }) => {
    map[label] = { isStudioHour: false, isWorkingHour: false, value, label };
  });

  // mark which booking hour ranges are within working hours and which are within studio hours
  bookingAvailability?.forEach(
    ({ label, value, isWorkingHour, isStudioHour }) => {
      map[label].isStudioHour = isStudioHour;
      map[label].isWorkingHour = isWorkingHour;
      map[label].value = value;
    },
  );

  // create an array of all possible booking hours and include whether it's within working hours
  const overlappingBookingAvailability = useMemo(() => {
    return allPossibleBookingAvailability.map(({ label }) => {
      const isStudioHour = map[label].isStudioHour;
      const isWorkingHour = map[label].isWorkingHour;
      const value = map[label].value;

      return {
        isStudioHour,
        isWorkingHour,
        label,
        value,
      };
    });
  }, [duration, bookingAvailability, selectedDate]);

  return overlappingBookingAvailability;
};
