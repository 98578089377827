import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useMemo, useState } from "react";
import { changeServiceTypeAndId } from "../../../store/actions/generateBookingStore";
import {
  clearAlts,
  decrementQuantity,
  incrementQuantity,
  resetAddOns,
  resetMixMasterCart,
  selectAlt,
  setQuantity,
  toggleAddAtmos,
  toggleAddMaster,
  toggleEngineerHasFiles,
  unselectAlt,
} from "../../../store/actions/mixMasterCartsStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  Alt,
  altToDisplayString,
  projectTypeToAltList,
} from "../../../store/models/alts";
import {
  ProjectType,
  getProjectTypeOptionFromServiceType,
} from "../../../store/models/project";
import {
  canAddDolbyMixingService,
  canAddMasteringService,
} from "../../../store/utils/serviceUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  CheckBox,
  CheckBoxVariant,
} from "../../core-ui/components/CheckBox/CheckBox";
import {
  TEXT_COLOR,
  TEXT_SIZE,
  Text,
} from "../../core-ui/components/Text/Text";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import {
  TOGGLE_SWITCH_SIZE,
  ToggleSwitch,
} from "../../elements/ToggleSwitch/ToggleSwitch";
import "./GenerateBookingSelectService.css";
import GenerateBookingSelectServiceContainer, {
  GenerateBookingContainerVariant,
} from "./GenerateBookingSelectServiceContainer";
import GenerateBookingSelectServiceDropdown from "./GenerateBookingSelectServiceDropdown";
import { GenerateBookingUtilityButton } from "./GenerateBookingUtilityButton";
import { selectEligibleServices } from "../../../store/selectors/servicesSelectors";

export interface GenerateBookingSelectServiceProps {
  index: number;
  isLoggedInUserGeneratingBooking: boolean;
}

const GenerateBookingSelectService = ({
  index,
  isLoggedInUserGeneratingBooking,
}: GenerateBookingSelectServiceProps) => {
  const dispatch = useAppDispatch();
  const eligibleServices = useAppSelector(selectEligibleServices);
  const { activeServiceType, activeTransactionId } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const { isLoading: isUpdatingTransaction } = useAppSelector(
    (state) => state.mixMasterCartsStore,
  );
  const { engineerHasFiles, quantity, alts, addMaster, addAtmos } =
    useAppSelector((state) => state.mixMasterCartsStore.cart);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);

  const options = eligibleServices.map(
    (service) => getProjectTypeOptionFromServiceType(service.service_type)!,
  );

  const selectedOption = activeServiceType
    ? getProjectTypeOptionFromServiceType(activeServiceType) ?? null
    : null;

  const possibleAlts = useMemo(() => {
    if (!selectedOption) {
      return [];
    }
    const projectType = selectedOption.value;
    return projectTypeToAltList[projectType as ProjectType];
  }, [selectedOption]);

  const defaultAlts = useMemo(() => {
    if (!selectedOption) {
      return [];
    }
    if (selectedOption.value === ProjectType.MASTERING) {
      return [Alt.MAIN];
    }
    return [Alt.MAIN, Alt.CLEAN];
  }, [selectedOption]);

  // Controls whether the "Add alts" toggle is enabled on the UI.
  const [isAltSelectionEnabled, setIsAltSelectionEnabled] = useState(
    Boolean(activeServiceType && alts.length > defaultAlts.length - 1),
  );
  const isDefaultAlt = useCallback(
    (alt: Alt) => {
      return defaultAlts.includes(alt);
    },
    [defaultAlts],
  );

  const clearSelections = () => {
    setIsAltSelectionEnabled(false);
  };

  const onSelectOption = (option: OptionType) => {
    if (option.value === activeServiceType) {
      return;
    }

    emitAnalyticsTrackingEvent(
      "booking_flow-select_service_dropdown_option",
      {
        transactionId: activeTransactionId,
        serviceType: option.value,
      },
      loggedInUser?.id,
    );

    if (option.value === ProjectType.RECORDING) {
      dispatch(resetMixMasterCart());
    }

    const selectedService = eligibleServices.find(
      (service) => service.service_type === option.value,
    )!;
    dispatch(resetAddOns());
    dispatch(
      changeServiceTypeAndId({
        serviceType: option.value,
        serviceId: selectedService.id!,
      }),
    );
  };

  const onClickClear = () => {
    emitAnalyticsTrackingEvent(
      "booking_flow-select_service_dropdown_clear",
      {
        transactionId: activeTransactionId,
      },
      loggedInUser?.id,
    );
    clearSelections();
    dispatch(resetMixMasterCart());
    dispatch(
      changeServiceTypeAndId({
        serviceType: undefined,
        serviceId: undefined,
      }),
    );
  };

  const getAltOption = (alt: Alt) => {
    return (
      <div
        style={{
          display: "flex",
          minWidth: "45%",
          alignItems: "center",
          gap: "4px",
          justifyContent: "flex-start",
        }}
      >
        <label style={{ display: "inline-flex", alignItems: "center" }}>
          <CheckBox
            onChange={(e) => {
              if (e.target.checked) {
                dispatch(selectAlt(alt));
              } else {
                dispatch(unselectAlt(alt));
              }
            }}
            variant={CheckBoxVariant.OUTLINED}
            checked={isDefaultAlt(alt) || alts.includes(alt)}
            disabled={isDefaultAlt(alt) || isUpdatingTransaction}
            className={`${cssPrefix}alts-checkmark`}
          />
          <p className={`${cssPrefix}alts-checkmark-text`}>
            {altToDisplayString[alt]}
          </p>
        </label>
      </div>
    );
  };

  const canAddMaster = useMemo(
    () => canAddMasteringService(selectedOption?.value, eligibleServices),
    [selectedOption?.value, eligibleServices],
  );

  const canAddDolbyMix = useMemo(
    () => canAddDolbyMixingService(selectedOption?.value, eligibleServices),
    [selectedOption?.value, eligibleServices],
  );

  const canAddAlts = useMemo(
    () => selectedOption?.value !== ProjectType.ATMOS_MIXING,
    [selectedOption?.value],
  );

  const toggleAltsSelection = (isChecked: boolean) => {
    if (!isChecked) {
      dispatch(clearAlts());
    }
    setIsAltSelectionEnabled(isChecked);
  };

  const shouldHideToggles = !selectedOption;
  const cssPrefix = "generate-booking-select-service_";
  return (
    <GenerateBookingSelectServiceContainer
      index={index}
      variant={GenerateBookingContainerVariant.MIX_MASTER}
      showIndexCounter={false}
    >
      <GenerateBookingSelectServiceDropdown
        quantity={quantity}
        incrementQuantity={() => dispatch(incrementQuantity())}
        decrementQuantity={() => dispatch(decrementQuantity())}
        options={options}
        selectedOption={selectedOption}
        onSelectOption={onSelectOption}
        setQuantity={(value) => dispatch(setQuantity(value))}
        disabled={isUpdatingTransaction}
      />
      <div className={`${cssPrefix}controls-container`}>
        {!shouldHideToggles && (
          <>
            {canAddAlts && (
              <ToggleSwitch
                id="add-alts-toggle"
                label={
                  <Text color={TEXT_COLOR.SECONDARY} size={TEXT_SIZE.SMALL}>
                    Add alts.
                  </Text>
                }
                checked={isAltSelectionEnabled}
                onChange={toggleAltsSelection}
                size={TOGGLE_SWITCH_SIZE.SMALL}
                labelClassName={`${cssPrefix}toggle-label`}
                updateCheckedLocally={false}
              />
            )}
            {canAddMaster && (
              <ToggleSwitch
                id="add-master-toggle"
                label={
                  <Text color={TEXT_COLOR.SECONDARY} size={TEXT_SIZE.SMALL}>
                    Add mastering
                  </Text>
                }
                checked={addMaster}
                onChange={() => dispatch(toggleAddMaster())}
                disabled={isUpdatingTransaction}
                size={TOGGLE_SWITCH_SIZE.SMALL}
                labelClassName={`${cssPrefix}toggle-label`}
                updateCheckedLocally={false}
              />
            )}
            {canAddDolbyMix && (
              <ToggleSwitch
                id="add-atmos-toggle"
                label={
                  <Text color={TEXT_COLOR.SECONDARY} size={TEXT_SIZE.SMALL}>
                    Add Dolby Atmos
                  </Text>
                }
                checked={addAtmos}
                onChange={() => dispatch(toggleAddAtmos())}
                disabled={isUpdatingTransaction}
                size={TOGGLE_SWITCH_SIZE.SMALL}
                labelClassName={`${cssPrefix}toggle-label`}
                updateCheckedLocally={false}
              />
            )}
            <ToggleSwitch
              id="engineer-has-files-toggle"
              label={
                <Text color={TEXT_COLOR.SECONDARY} size={TEXT_SIZE.SMALL}>
                  {isLoggedInUserGeneratingBooking
                    ? "Have files"
                    : "Engineer has files"}
                </Text>
              }
              checked={engineerHasFiles}
              onChange={() => dispatch(toggleEngineerHasFiles())}
              disabled={isUpdatingTransaction}
              size={TOGGLE_SWITCH_SIZE.SMALL}
              labelClassName={`${cssPrefix}toggle-label`}
              updateCheckedLocally={false}
            />
          </>
        )}
        <GenerateBookingUtilityButton
          icon={faXmark}
          text={"Clear"}
          onClick={onClickClear}
          disabled={isUpdatingTransaction}
        />
      </div>
      {isAltSelectionEnabled && (
        <div className={`${cssPrefix}alts-container`}>
          {possibleAlts.map((alt) => {
            return getAltOption(alt);
          })}
        </div>
      )}
    </GenerateBookingSelectServiceContainer>
  );
};

export default GenerateBookingSelectService;
