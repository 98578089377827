import { ScheduledProject } from "../../../../store/models/scheduledproject";
import {
  DetailsBlock,
  ProjectIdContainer,
  SecondaryDetailedBlockKeyText,
  SecondaryDetailedBlockValueText,
} from "./ProjectHeader.styles";
import { ProjectListRow } from "../../../elements/ProjectListRow/ProjectListRow";
import { formatDateToShort } from "../../../../utils/formatDateToShort";
import { convertUTCDateToLocalDate } from "../../../../store/utils/dateTimeUtils";
import { ReleaseListItemCoverPhoto } from "../../ReleaseList/ReleaseListItemCoverPhoto";
import { Box } from "@mui/material";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import { useRef, useState } from "react";
import { useDebounceCallback, useResizeObserver } from "usehooks-ts";
import { useFetchScheduledProjectReleaseQuery } from "../../../../api/releases/hooks/useFetchReleaseByScheduledProjectIdQuery";

const DetailsBlockComponent = ({
  isProjectCancelled,
  scheduledProject,
  userIsCollaborator,
}: {
  isProjectCancelled: boolean;
  scheduledProject: ScheduledProject;
  userIsCollaborator: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ width }, setSize] = useState<{
    width: number | undefined;
  }>({
    width: undefined,
  });
  const onResize = useDebounceCallback(setSize, 50);
  const { data: release } = useFetchScheduledProjectReleaseQuery(
    scheduledProject.id,
  );
  useResizeObserver({
    ref: ref,
    onResize,
  });

  const { isDesktop } = useMediaQueryBreakpoint();

  return (
    <Box
      ref={ref}
      style={{
        display: "flex",
        flexDirection: !isDesktop ? "column" : "row",
        flex: 1,
        justifyContent: !isDesktop ? "center" : "flex-start",
        alignItems: !isDesktop ? "center" : "flex-start",
        flexWrap: "wrap",
        gap: "16px",
      }}
    >
      {release && (
        <ReleaseListItemCoverPhoto
          width={!isDesktop && width ? width - 5 : 260}
          height={!isDesktop && width ? width - 5 : 260}
          releaseCover={release?.release_cover ?? null}
        />
      )}
      <DetailsBlock>
        <ProjectIdContainer>
          <SecondaryDetailedBlockKeyText>
            Project Name
          </SecondaryDetailedBlockKeyText>
          <div style={{ marginBottom: "12px" }}></div>
          <ProjectListRow
            allowEdit={!isProjectCancelled && userIsCollaborator}
            paginatedScheduledProject={scheduledProject}
          />
        </ProjectIdContainer>
        <span style={{ marginBottom: "16px" }}></span>
        <ProjectIdContainer>
          <SecondaryDetailedBlockKeyText>
            Project ID:{" "}
          </SecondaryDetailedBlockKeyText>
          <SecondaryDetailedBlockValueText>
            {scheduledProject.id}
          </SecondaryDetailedBlockValueText>
        </ProjectIdContainer>
        <ProjectIdContainer>
          <SecondaryDetailedBlockKeyText>
            Booking date:{" "}
          </SecondaryDetailedBlockKeyText>
          <SecondaryDetailedBlockValueText>
            {formatDateToShort(
              convertUTCDateToLocalDate(new Date(scheduledProject.created)),
            )}
          </SecondaryDetailedBlockValueText>
        </ProjectIdContainer>
        {scheduledProject.refunded && (
          <ProjectIdContainer>
            <SecondaryDetailedBlockKeyText>
              Refunded:{" "}
            </SecondaryDetailedBlockKeyText>
            <SecondaryDetailedBlockValueText>
              {formatDateToShort(
                convertUTCDateToLocalDate(new Date(scheduledProject.refunded)),
              )}
            </SecondaryDetailedBlockValueText>
          </ProjectIdContainer>
        )}
      </DetailsBlock>
    </Box>
  );
};

export default DetailsBlockComponent;
