import { MutableRefObject, useCallback, useEffect } from "react";
import { default as WaveSurferRef } from "wavesurfer.js";
import { getFooterWaveformRef } from "./waveformHooks";

export const useSetupOnClick = (
  ref: MutableRefObject<WaveSurferRef | null>,
  notReady: boolean,
) => {
  const footerPlayerRef = getFooterWaveformRef();
  const handleWaveformClick = useCallback(
    (position: number) => {
      if (!footerPlayerRef || notReady) return;
      footerPlayerRef.seekTo(position);
    },
    [footerPlayerRef, notReady],
  );

  useEffect(() => {
    if (notReady || !ref.current) return;
    ref.current.on("click", handleWaveformClick);
  }, [handleWaveformClick, ref]);
};
