import { Project } from "../models/project";
import { determineIfProject } from "./projectTableUtils";
import { ScheduledProject } from "../models/scheduledproject";

enum sortByFields {
  title = "title",
  created = "created",
}

export enum ArrowDirection {
  None = "none",
  Up = "ascending",
  Down = "descending",
}

const sortByTitle = (
  a: Project | ScheduledProject,
  b: Project | ScheduledProject,
) => {
  return a.title.localeCompare(b.title);
};

const sortByCreated = (
  a: Project | ScheduledProject,
  b: Project | ScheduledProject,
) => {
  if (!determineIfProject(a) || !determineIfProject(b)) {
    return 0;
  }

  const projectA = a as Project;
  const projectB = b as Project;

  if (!projectA.first_pass_date || !projectB.first_pass_date) {
    return 0;
  }

  const dateA = new Date(projectA.first_pass_date);
  const dateB = new Date(projectB.first_pass_date);
  return dateA.getTime() - dateB.getTime();
};

export const sortProjectData = (
  projectData: Array<ScheduledProject | Project>,
  scheduledProjectSortBy: string,
): Array<ScheduledProject | Project> => {
  if (!scheduledProjectSortBy.length) {
    return projectData;
  }
  let sortedData: Array<ScheduledProject | Project> = projectData;
  if (scheduledProjectSortBy.includes(sortByFields.title)) {
    sortedData = projectData.slice().sort(sortByTitle);
  }
  if (scheduledProjectSortBy.includes(sortByFields.created)) {
    sortedData = projectData.slice().sort(sortByCreated);
  }

  if (scheduledProjectSortBy.includes("-")) {
    sortedData = sortedData.slice().reverse();
  }

  return sortedData;
};
