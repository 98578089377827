import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Button } from "../../core-ui/components/Button/MUIButton";
import { useCallback } from "react";
import { useAtom } from "jotai";
import { powerUserControlsHelpModalAtom } from "../../../atoms/modals/powerUserControlsHelpModalAtom";
import { ControlKeyList } from "./ControlKeyList";

const PowerUserControlsHelpModal = () => {
  const [open, setOpen] = useAtom(powerUserControlsHelpModalAtom);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      maxWidth={"lg"}
      fullWidth={true}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        sx={{ color: theme.palette.text.primary }}
        id="responsive-dialog-title"
      >
        Keyboard Shortcuts
      </DialogTitle>
      <DialogContent>
        <ControlKeyList />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PowerUserControlsHelpModal;
