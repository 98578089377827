import {
  SimpleTableBody,
  SimpleTableContainer,
  SimpleTableHeader,
  SimpleTableText,
  SimpleTableTitle,
} from "./SimpleTable.styles";
import {
  IconDefinition,
  faCheck,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { ToolTipTextArea } from "../../../components/ToolTipTextArea/ToolTipTextArea";
import {
  SimpleTableRow,
  SimpleTableRowData,
} from "../../elements/UserIconLabel/SimpleTableRow/SimpleTableRow";

export enum ActionStatus {
  COMPLETED,
  REJECTED,
  PENDING,
}

export const ActionStatusColorMap = new Map<ActionStatus, string>([
  [ActionStatus.COMPLETED, "var(--success-color)"],
  [ActionStatus.REJECTED, "var(--failed-color)"],
  [ActionStatus.PENDING, "var(--pending-color)"],
]);

export const ActionStatusIconMap = new Map<ActionStatus, IconDefinition>([
  [ActionStatus.COMPLETED, faCheck],
  [ActionStatus.REJECTED, faX],
  [ActionStatus.PENDING, faCheck],
]);

export interface SimpleTableProps {
  rows: SimpleTableRowData[];
  title: string;
  headers: string[];
  hint?: string;
}

export const SimpleTable: React.FC<SimpleTableProps> = ({
  rows,
  title,
  headers,
  hint,
}: SimpleTableProps) => {
  return (
    <SimpleTableContainer>
      <SimpleTableTitle>
        <SimpleTableText weight isLarge>
          {title}
        </SimpleTableText>
        {hint && <ToolTipTextArea text={hint} />}
      </SimpleTableTitle>
      <SimpleTableHeader>
        {headers.map((header, index) => (
          <SimpleTableText style={{ flex: 1 }} key={index}>
            {header}
          </SimpleTableText>
        ))}
      </SimpleTableHeader>
      <SimpleTableBody>
        {rows.map((row, index) => (
          <SimpleTableRow key={index} row={row} />
        ))}
      </SimpleTableBody>
    </SimpleTableContainer>
  );
};
