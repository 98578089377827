import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../store/hooks";
import { AltItem, altToDisplayString } from "../../../store/models/alts";
import { ScheduledProject } from "../../../store/models/scheduledproject";
import Service from "../../../store/models/service";
import {
  basePricePerSong,
  generateSongDatesMap,
} from "../../../store/utils/scheduledprojects";
import { CheckoutScreenHeader } from "../../components/CheckoutScreenHeader/CheckoutScreenHeader";
import {
  availability_multiplier,
  FirstPassDate,
  ProjectBookingCalendar,
} from "../../components/ProjectBookingCalendar/ProjectBookingCalendar";
import { ProjectBookingRescheduler } from "../../components/ProjectBookingRescheduler/ProjectBookingRescheduler";
import { Button } from "../../elements/Button/button";

export interface RescheduleBookingScreenProps {
  loading: boolean;
  service: Service;
  scheduledProject?: ScheduledProject;
  alts: AltItem[];
  availabilities: Map<string, availability_multiplier>;
  onSubmitProjectDates: (songDatesMap: Map<string, FirstPassDate>) => void;
  onAcceptProjectSchedule: () => void;
  onRejectProject: () => void;
}

export const RescheduleBookingScreen = ({
  loading,
  service,
  scheduledProject,
  alts,
  availabilities,
  onSubmitProjectDates,
  onAcceptProjectSchedule,
  onRejectProject,
}: RescheduleBookingScreenProps) => {
  const firstProject = scheduledProject!.projects[0];
  const songList = scheduledProject!.projects.map((project) => project.title);
  const altsList = alts.map((altItem) => altItem.alt);
  const songDatesMap = generateSongDatesMap(scheduledProject!, availabilities);
  const artistUser = firstProject.artist!;
  const engineerUser = firstProject.engineer!;
  // TODO: Fix this for label rates, discounted rates.
  const basePrice = basePricePerSong(service, altsList);
  const selectionMap = generateSongDatesMap(scheduledProject!, availabilities);

  const userMe = useAppSelector((state) => state.accountInfo.user);
  const userIsMe = engineerUser.id === userMe?.id;
  const [selectedSong, setSelectedSong] = useState(-1);
  const [rescheduleMode, setRescheduleMode] = useState(false);
  const [selectionQueue, setSelectionQueue] = useState([
    ...Array(songList.length).keys(),
  ]);
  const [rescheduleDates, setRescheduleDates] = useState(
    new Map<string, FirstPassDate>(),
  );

  const isNewSchedule = useMemo(
    () =>
      Array.from(rescheduleDates).some(([k, v]) => songDatesMap.get(k) !== v),
    [rescheduleDates, songDatesMap],
  );

  const getSelectedDays = () => {
    return rescheduleMode || rescheduleDates.size === songDatesMap.size
      ? Array.from(rescheduleDates.values()).map((date) => date.date)
      : Array.from(songDatesMap.values()).map((date) => date.date);
  };

  const onSaveSchedule = () => {
    setSelectedSong(-1);
    setRescheduleMode(false);
    if (!isNewSchedule) setRescheduleDates(new Map<string, FirstPassDate>());
  };

  const onResetSchedule = () => {
    setSelectedSong(-1);
    setRescheduleMode(false);
    setRescheduleDates(new Map<string, FirstPassDate>());
  };

  const onSetRescheduleMode = () => {
    setSelectedSong(0);
    setRescheduleMode(true);
    if (rescheduleDates.size === 0)
      setRescheduleDates(new Map<string, FirstPassDate>(songDatesMap));
  };

  const onClickDateHandler = (date: FirstPassDate) => {
    if (rescheduleMode) {
      const newSongDatesMap = new Map<string, FirstPassDate>(rescheduleDates);
      let newSelectedSong = selectedSong;
      let newSelectionQueue = [...selectionQueue];

      // Handle Deselecting Date
      const isDeselecting = Array.from(newSongDatesMap).some(
        ([songName, firstPassDate]) => {
          const songIndex = songList.indexOf(songName);
          if (
            firstPassDate.date.getTime() === date.date.getTime() &&
            selectedSong === songIndex
          ) {
            newSongDatesMap.delete(songName);
            newSelectedSong = songIndex;
            newSelectionQueue.push(newSelectedSong);
            // TODO: Resolve sorting logic in re-selecting first pass dates
            newSelectionQueue.sort();
            return true;
          } else {
            return false;
          }
        },
      );

      // Handle Selecting Date
      if (!isDeselecting) {
        // Check that we can actually select this date or if we've hit the limit.
        let countSongsForDate = 0;
        Array.from(newSongDatesMap).forEach(([, firstPassDate]) => {
          if (firstPassDate.date.getTime() === date.date.getTime()) {
            countSongsForDate += 1;
          }
        });

        if (date.availability[2] <= countSongsForDate) {
          toast.error(
            "Limit reached for the number of songs that can be selected for this date.",
          );
          return;
        }

        // Check if date exists in original songDatesMap
        // If so use, the existing Completion Date rather the new one
        if (
          !Array.from(songDatesMap).some(([, v]) => {
            if (date.date.getTime() === v.date.getTime()) {
              newSongDatesMap.set(songList[selectedSong], v);
              return true;
            } else {
              return false;
            }
          })
        ) {
          newSongDatesMap.set(songList[selectedSong], date);
        }

        newSelectionQueue = newSelectionQueue.filter(
          (index) => index !== selectedSong,
        );
        newSelectedSong = newSelectionQueue[0] || 0;
      }

      setSelectionQueue(newSelectionQueue);
      setSelectedSong(newSelectedSong);
      setRescheduleDates(newSongDatesMap);
    }
  };

  const AltsComponent = () => {
    return (
      <div className="reschedule-screen-alts ">
        <p className="b1-semi-bold mt-3">
          Note that the following alts were requested with this project
        </p>
        <ul className="alt-lists-reschedule">
          {alts?.map((currAlt) => (
            <li
              style={{ justifyContent: "flex-start" }}
              key={currAlt.id}
              className="b2 my-1"
            >
              {altToDisplayString[currAlt.alt]}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="checkout-screen">
      <CheckoutScreenHeader
        engineerUser={userIsMe ? artistUser : engineerUser}
        service={service}
        step="Review"
      />
      <div className="checkout-card-row-container">
        <div className={"calendar-checkout-container"}>
          <ProjectBookingCalendar
            availabilities={availabilities}
            basePrice={basePrice}
            selectedDays={getSelectedDays()}
            onClickDate={onClickDateHandler}
          />
        </div>
        <div className={"calendar-checkout-container"}>
          <ProjectBookingRescheduler
            scheduledProject={scheduledProject!}
            rescheduleDates={rescheduleDates}
            rescheduleMode={rescheduleMode}
            selectionMap={selectionMap}
          >
            {AltsComponent()}
          </ProjectBookingRescheduler>
        </div>
        <div className="checkout-card-row-container ">
          <div className="reschedule-button-container">
            <Button
              label={userIsMe ? "Reject Project" : "Cancel Project"}
              primary={false}
              onClick={onRejectProject}
              disabled={loading}
              loading={loading}
            />
            <Button
              label={
                rescheduleDates.size > 0
                  ? "Request Reschedule"
                  : "Accept Project"
              }
              disabled={rescheduleMode || loading}
              primary={true}
              onClick={() =>
                rescheduleDates.size > 0
                  ? onSubmitProjectDates(rescheduleDates)
                  : onAcceptProjectSchedule()
              }
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
