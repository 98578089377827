import { useCallback, useMemo } from "react";
import { useAppDispatch } from "../store/hooks";
import { FileType } from "../store/models/fileVersion";
import {
  AtmosProjectSteps,
  MasteringProjectSteps,
  MixingProjectSteps,
  ProjectType,
} from "../store/models/project";

export const useAcceptedFileTypes = (
  projectType: ProjectType | null,
  step: number,
  hasAlts: boolean,
) => {
  const dispatch = useAppDispatch();
  const masteringAcceptedFileTypes = useCallback(
    (currentStep: number) => {
      switch (currentStep) {
        case MasteringProjectSteps.MASTERING_UPLOAD_ALTS:
          return [FileType.ZIP, FileType.WAV];
        case MasteringProjectSteps.MASTER_REVISION:
        case MasteringProjectSteps.IN_MASTER:
          return [FileType.WAV];
        case MasteringProjectSteps.MASTERING_FILE_NEEDS_REUPLOAD:
        case MasteringProjectSteps.MASTERING_ENG_FILE_REVIEW:
        case MasteringProjectSteps.MASTERING_BOOKING_REQUESTED:
          if (hasAlts) return [FileType.ZIP, FileType.WAV];
          return [FileType.WAV];
        default:
          return [];
      }
    },
    [hasAlts],
  );

  const mixingAcceptedFileTypes = useCallback((currentStep: number) => {
    switch (currentStep) {
      case MixingProjectSteps.IN_MIX:
      case MixingProjectSteps.MIX_REVISION:
        return [FileType.WAV];
      case MixingProjectSteps.MIX_FINISHED:
      case MixingProjectSteps.REFERENCE_UPLOADED:
      case MixingProjectSteps.FILES_NEED_REUPLOAD:
      case MixingProjectSteps.PROJECT_ACCEPTED:
      case MixingProjectSteps.BOOKING_REQUESTED:
      case MixingProjectSteps.ENG_FILE_REVIEW:
        return [FileType.ZIP, FileType.WAV];

      default:
        return [];
    }
  }, []);

  const dolbyAtmosAcceptedFileTypes = useCallback((currentStep: number) => {
    switch (currentStep) {
      case AtmosProjectSteps.IN_MIX:
      case AtmosProjectSteps.MIX_REVISION:
      case AtmosProjectSteps.IN_ATMOS_MIX:
      case AtmosProjectSteps.ATMOS_MIX_REVISION:
        return [FileType.WAV, FileType.MP4];
      case AtmosProjectSteps.MIX_FINISHED:
        return [FileType.ZIP, FileType.WAV, FileType.MP4];
      case AtmosProjectSteps.REFERENCE_UPLOADED:
      case AtmosProjectSteps.FILES_NEED_REUPLOAD:
      case AtmosProjectSteps.PROJECT_ACCEPTED:
      case AtmosProjectSteps.BOOKING_REQUESTED:
      case AtmosProjectSteps.ENG_FILE_REVIEW:
        return [FileType.WAV, FileType.ZIP];
      default:
        return [];
    }
  }, []);

  return useMemo(() => {
    if (!projectType) return [];
    let types: string[];
    if (projectType === ProjectType.MASTERING) {
      types = masteringAcceptedFileTypes(step);
    } else if (projectType === ProjectType.ATMOS_MIXING) {
      types = dolbyAtmosAcceptedFileTypes(step);
    } else if (projectType === ProjectType.RECORDING) {
      types = [FileType.ZIP];
    } else {
      types = mixingAcceptedFileTypes(step);
    }

    return types;
  }, [
    step,
    dispatch,
    projectType,
    mixingAcceptedFileTypes,
    masteringAcceptedFileTypes,
    dolbyAtmosAcceptedFileTypes,
  ]);
};
