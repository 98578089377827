import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";

export const selectScheduledSessions = (state: RootState) => {
  return state.scheduledSessions;
};

export const selectSelectedProfile = (state: RootState) => {
  return state.selectedProfileSlice;
};

export const selectProfileSessions = createSelector(
  selectScheduledSessions,
  selectSelectedProfile,
  (scheduledSessions, selectedProfile) => {
    if (selectedProfile.studio) {
      return scheduledSessions.studioSessions[selectedProfile.studio.id] || [];
    }
    if (selectedProfile.user) {
      return scheduledSessions.userSessions[selectedProfile.user.id] || [];
    }
    return [];
  },
);
