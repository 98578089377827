import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MASTERING_ALPHA_PROJECTS } from "../utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { receiveErrors } from "./errorStore";
import {
  defaultPaginationDetails,
  PaginatedAlphaProjects,
} from "./mixingAlphaProjects";
import { transformRawData, APIMasteringProject } from "../models/project";

interface AlphaMasteringProjectsResponse {
  data: APIMasteringProject[];
  num_pages: number;
  page: number;
  count: number;
}

export const getAlphaMasteringProjects = createAsyncThunk(
  MASTERING_ALPHA_PROJECTS,
  async (args: { page: number }, thunkAPI) => {
    const { page } = args;
    const params = `?mastering_page=${page}`;
    const result =
      await makeBackendGetCallWithJsonResponse<AlphaMasteringProjectsResponse>(
        MASTERING_ALPHA_PROJECTS,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

const initialState: PaginatedAlphaProjects = {
  active: [],
  activePaginationDetails: defaultPaginationDetails,
  loading: false,
};

const alphaMasteringProjectsSlice = createSlice({
  name: "alphaMasteringProjects",
  initialState,
  reducers: {
    clearAlphaMastering: (state) => {
      state.active = initialState.active;
      state.activePaginationDetails = initialState.activePaginationDetails;
      state.loading = false;
    },
    setAlphaMasteringPage: (state, action: PayloadAction<number>) => {
      state.activePaginationDetails.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAlphaMasteringProjects.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAlphaMasteringProjects.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAlphaMasteringProjects.fulfilled, (state, action) => {
      const { count, data, num_pages, page } = action.payload;
      state.active[page] = data.map(transformRawData);
      state.activePaginationDetails = {
        currentPage: page,
        totalPages: num_pages,
        count: count,
      };
      state.loading = false;
    });
  },
});

export const { clearAlphaMastering, setAlphaMasteringPage } =
  alphaMasteringProjectsSlice.actions;

export default alphaMasteringProjectsSlice.reducer;
