import { useMemo, useState } from "react";
import { useIsArtistUploadStep } from "../../../hooks/useIsUploadStep";
import { useShowUploadInstructions } from "../../../hooks/useShowUploadIntructions";
import {
  FileStorageLocation,
  Project,
  ProjectType,
} from "../../../store/models/project";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { getDocUrl } from "../../../constants/googleStorage";

export enum UploadInstruction {
  PRO_TOOLS,
  ABLETON,
  LOGIC_PRO_X,
  ALREADY_EXPORTED,
  UMG_FILE_DELIVERY,
}

export const uploadInstructionMap = new Map<
  ProjectType,
  Map<UploadInstruction, string>
>([
  [
    ProjectType.MIXING,
    new Map<UploadInstruction, string>([
      [
        UploadInstruction.PRO_TOOLS,
        getDocUrl("ProToolsArtistExportandUploadInstructionsFullStems.pdf"),
      ],
      [
        UploadInstruction.ABLETON,
        getDocUrl("AbletonArtistExportandUploadInstructionsFullStems.pdf"),
      ],
      [
        UploadInstruction.LOGIC_PRO_X,
        getDocUrl("LogicProXArtistExportandUploadInstructionsFullStems.pdf"),
      ],
      [
        UploadInstruction.ALREADY_EXPORTED,
        getDocUrl("StemsAlreadyExportedUploadInstructions.pdf"),
      ],
    ]),
  ],
  [
    ProjectType.TWO_TRACK_MIXING,
    new Map<UploadInstruction, string>([
      [
        UploadInstruction.PRO_TOOLS,
        getDocUrl("ProToolsArtistExportandUploadInstructionsTwoTrack.pdf"),
      ],
      [
        UploadInstruction.ABLETON,
        getDocUrl("AbletonArtistExportandUploadInstructionsTwoTrack.pdf"),
      ],
      [
        UploadInstruction.LOGIC_PRO_X,
        getDocUrl("LogicProXArtistExportandUploadInstructionsTwoTrack.pdf"),
      ],
      [
        UploadInstruction.ALREADY_EXPORTED,
        getDocUrl("StemsAlreadyExportedUploadInstructions.pdf"),
      ],
    ]),
  ],
]);

export const engineerInstructionMap = new Map<
  ProjectType,
  Map<UploadInstruction, string>
>([
  [
    ProjectType.ATMOS_MIXING,
    new Map<UploadInstruction, string>([
      [
        UploadInstruction.PRO_TOOLS,
        getDocUrl("ProToolsEngineerUploadInstructions.pdf"),
      ],
    ]),
  ],
  [
    ProjectType.MIXING,
    new Map<UploadInstruction, string>([
      [
        UploadInstruction.PRO_TOOLS,
        getDocUrl("ProToolsEngineerUploadInstructions.pdf"),
      ],
    ]),
  ],
  [
    ProjectType.TWO_TRACK_MIXING,
    new Map<UploadInstruction, string>([
      [
        UploadInstruction.PRO_TOOLS,
        getDocUrl("ProToolsEngineerUploadInstructions.pdf"),
      ],
    ]),
  ],
]);

export const engineerUmgInstructions = new Map<
  ProjectType,
  Map<UploadInstruction, string>
>([
  [
    ProjectType.MIXING,
    new Map<UploadInstruction, string>([
      [
        UploadInstruction.UMG_FILE_DELIVERY,
        "http://www.umghelpguides.com/guides/audio-delivery-requirements/",
      ],
    ]),
  ],
  [
    ProjectType.TWO_TRACK_MIXING,
    new Map<UploadInstruction, string>([
      [
        UploadInstruction.UMG_FILE_DELIVERY,
        "http://www.umghelpguides.com/guides/audio-delivery-requirements/",
      ],
    ]),
  ],
  [
    ProjectType.ATMOS_MIXING,
    new Map<UploadInstruction, string>([
      [
        UploadInstruction.UMG_FILE_DELIVERY,
        "http://www.umghelpguides.com/guides/dolby-atmos-music/",
      ],
    ]),
  ],
]);

export const uploadInstructionOptions: OptionType[] = [
  {
    label: "Pro Tools",
    value: UploadInstruction.PRO_TOOLS,
  },
  {
    label: "Ableton",
    value: UploadInstruction.ABLETON,
  },
  {
    label: "Logic Pro",
    value: UploadInstruction.LOGIC_PRO_X,
  },
  {
    label: "Already Exported",
    value: UploadInstruction.ALREADY_EXPORTED,
  },
];

export const engineerOption: OptionType[] = [
  {
    label: "Pro Tools",
    value: UploadInstruction.PRO_TOOLS,
  },
];

export const umgEngineerOption: OptionType[] = [
  {
    label: "UMG File Delivery",
    value: UploadInstruction.UMG_FILE_DELIVERY,
  },
];

export interface UploadInstructionDropDownProps {
  project?: Project;
  engineerIsUploadingForArtist?: boolean;
}

export const UploadInstructionDropDown = ({
  project,
  engineerIsUploadingForArtist = false,
}: UploadInstructionDropDownProps) => {
  const [selected, setSelected] = useState(UploadInstruction.PRO_TOOLS);
  const projectType = useMemo(() => {
    return project?.service_type ?? ProjectType.NO_TYPE;
  }, [project]);
  const showInstructions = useShowUploadInstructions(
    engineerIsUploadingForArtist,
    project,
  );
  const isArtistUploadStep = useIsArtistUploadStep(project);
  if (!showInstructions) return null;
  if (
    projectType === ProjectType.NO_TYPE ||
    projectType === ProjectType.MASTERING
  ) {
    return null;
  }

  const fileUploadInstructionOptionsForEngineer =
    project?.file_storage_location === FileStorageLocation.UMG
      ? umgEngineerOption
      : engineerOption;
  const engineerInstructionsMap =
    project?.file_storage_location === FileStorageLocation.UMG
      ? engineerUmgInstructions
      : engineerInstructionMap;

  if (isArtistUploadStep || engineerIsUploadingForArtist) {
    return (
      <div className="form-input mb-4">
        <p className="label-semi-bold">Upload Instructions</p>
        <DropdownSelector
          options={uploadInstructionOptions}
          value={uploadInstructionOptions[selected]}
          onChange={(val) => {
            const option = val as OptionType;
            const { value } = option;
            uploadInstructionOptions.forEach((opt, index) => {
              if (opt.value === value) {
                if (projectType !== undefined && uploadInstructionMap) {
                  const url = uploadInstructionMap
                    ?.get(projectType)
                    ?.get(value);
                  window.open(url, "_blank");
                }
                setSelected(index);
              }
            });
          }}
        />
      </div>
    );
  }
  return (
    <div className="form-input mb-4">
      <p className="label-semi-bold">Upload Instructions</p>
      <DropdownSelector
        options={fileUploadInstructionOptionsForEngineer}
        value={fileUploadInstructionOptionsForEngineer[selected]}
        onChange={(val) => {
          const option = val as OptionType;
          const { value } = option;
          fileUploadInstructionOptionsForEngineer.forEach((opt, index) => {
            if (opt.value === value) {
              if (projectType !== undefined && engineerInstructionsMap) {
                const url = engineerInstructionsMap
                  ?.get(projectType)
                  ?.get(value);
                window.open(url, "_blank");
              }
              setSelected(index);
            }
          });
        }}
        placeholder={"select tool"}
      />
    </div>
  );
};
