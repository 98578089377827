import { faFlag, faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useModal from "../../../hooks/useModal";
import { useSocialMediaMap } from "../../../hooks/useSocialMediaMap";
import { Studio } from "../../../store/models/studio";
import { DirectMessageButtonController } from "../../components/DirectMessageButton/DirectMessageButtonController";
import { FeedbackModal } from "../../components/FeedbackModal/FeedbackModal";
import { SocialMediaButtonsList } from "../../components/SocialMediaButtonsList/SocialMediaButtonsList";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  Text,
  TEXT_COLOR,
  TEXT_LINE_HEIGHT,
} from "../../core-ui/components/Text/Text";
import {
  StudioActionsSection,
  VerifiedIdentityHeader,
} from "./StudioBenefitsAndConnection.styles";
import "./StudioScreen.css";

interface StudioBenefitsAndConnectionProps {
  studio: Studio;
  userCanEditStudio: boolean;
  showMessageButton: boolean;
}

const StudioBenefitsAndConnection = ({
  studio,
  userCanEditStudio,
  showMessageButton,
}: StudioBenefitsAndConnectionProps) => {
  const studioSocials = useSocialMediaMap(studio.studio_profile);
  const {
    isOpen: isFeedbackModalOpen,
    openModal: openFeedbackModal,
    closeModal: closeFeedbackModal,
  } = useModal();

  return (
    <>
      <div className="studio-facilities-section">
        <VerifiedIdentityHeader>
          <FontAwesomeIcon icon={faShieldHalved} />
          <Text>Verified Identity</Text>
        </VerifiedIdentityHeader>
        <Text
          color={TEXT_COLOR.TERTIARY}
          lineHeight={TEXT_LINE_HEIGHT.LONG}
          style={{ maxWidth: "366px" }}
        >
          Protect your payment. Do not transfer money or communicate outside of
          the EngineEars app.
        </Text>
      </div>
      <StudioActionsSection>
        {showMessageButton && !userCanEditStudio && (
          <DirectMessageButtonController
            studioId={studio.id}
            customLabel="Message Studio Manager"
          />
        )}
        {!userCanEditStudio && (
          <>
            <Button
              variant={ButtonVariant.TEXT}
              labelIcon={
                <FontAwesomeIcon icon={faFlag} color="var(--red-700)" />
              }
              onClick={openFeedbackModal}
            >
              Report Studio
            </Button>
            {isFeedbackModalOpen && (
              <FeedbackModal onClose={closeFeedbackModal} />
            )}
          </>
        )}
      </StudioActionsSection>
      <div style={{ flex: 2 }}>
        <p className={"label-semi-bold studio-section-header"}>
          Connect with us
        </p>
        <SocialMediaButtonsList
          editMode={false}
          usernamesMap={studioSocials}
          isCurrentUserPage={false}
        />
      </div>
    </>
  );
};

export default StudioBenefitsAndConnection;
