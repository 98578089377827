import { atom } from "jotai";

export enum SessionDetailsPanelView {
  MAIN,
  SESSION_REQUESTED_DETAILS,
}

// Handles which view to display in the SessionDetailsPanel ViewSlider
export const sessionDetailsPanelViewAtom = atom<SessionDetailsPanelView>(
  SessionDetailsPanelView.MAIN,
);
