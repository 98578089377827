import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import useCreateInviteMutation from "../../../hooks/teamHooks/useCreateInviteMutation";
import { useGetTeams } from "../../../hooks/useTeam";
import { TeamRole } from "../../../store/models/admins";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  BuildTeamEmailInputContainer,
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import FooterContent from "./FooterContent";

export const BuildTeam = () => {
  const theme = useTheme();
  const authNavigation = useAuthNavigationSteps();
  const { nextStep } = authNavigation();
  const { mutateAsync, isPending } = useCreateInviteMutation();
  const { selectedTeam, loading: isLoadingTeams } = useGetTeams();
  const [emails, setEmails] = useState<string[]>([]);

  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <Text variant={TextStyleVariant.H6}>Build your team</Text>
        <Text>
          Invite your associated{" "}
          <strong style={{ color: theme.palette.text.primary }}>A&amp;R</strong>{" "}
          and{" "}
          <strong style={{ color: theme.palette.text.primary }}>
            A&amp;R Administrators
          </strong>{" "}
          by adding their UMusic email address below.
        </Text>
        <Text variant={TextStyleVariant.P1} weight={TEXT_WEIGHT.SEMI_BOLD}>
          Add emails:
        </Text>
        <BuildTeamEmailInputContainer
          emails={emails}
          validateEmail={(email: string) =>
            email.match(/^[\w-]+(\.[\w-]+)*@(umusic|umgconsult)\.com$/) !== null
          }
          onChange={(emails: string[]) => {
            setEmails(emails);
          }}
          getLabel={(
            email: string,
            index: number,
            removeEmail: (index: number) => void,
          ) => {
            return (
              <Box data-tag key={index}>
                {email}
                <FontAwesomeIcon
                  icon={faXmark}
                  color={"var(--medium-grey)"}
                  style={{
                    cursor: "pointer",
                    marginLeft: "8px",
                  }}
                  onClick={() => removeEmail(index)}
                />
              </Box>
            );
          }}
        />
        <Button
          fullWidth={true}
          loading={isPending || isLoadingTeams}
          disabled={!emails.length}
          onClick={async () => {
            await mutateAsync({
              team_id: selectedTeam!.id,
              role: TeamRole.AANDR,
              emails: emails,
            })
              .then(() => {
                nextStep();
              })
              .catch(() => {
                toast("Something went wrong. Please try again.");
              });
          }}
        >
          Send invite
        </Button>
        <Button
          variant={ButtonVariant.UNSTYLED}
          style={{
            textDecoration: "underline",
            alignSelf: "flex-end",
            color: theme.palette.text.primary,
          }}
          onClick={() => nextStep()}
        >
          Skip
        </Button>
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
