import { useMutation } from "@tanstack/react-query";
import postStudioDirectMessageRequest, {
  StudioDirectMessageParams,
} from "../../api/stream/messaging/postStudioDirectMessageRequest";

/*
 * Creates a studio direct message request from the backend on behalf of the requesting user
 */
const useStudioDirectMessageRequestMutation = () => {
  return useMutation({
    mutationKey: ["studio_direct_message_request"],
    mutationFn: async (args: StudioDirectMessageParams) => {
      return postStudioDirectMessageRequest(args);
    },
  });
};

export default useStudioDirectMessageRequestMutation;
