import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "./useQuery";
import { useQueryParamDelete } from "./useQueryParamDelete";

/**
 * A hook to manage query parameters.
 * This can be replaced whenever we upgrade React Router to v6.
 * https://reactrouter.com/en/main/hooks/use-search-params
 * @param key The key of the query parameter.
 * @returns An object with get, set, and remove functions.
 */
export const useQueryParam = (key: string) => {
  const query = useQuery();
  const history = useHistory();
  const removeQueryParam = useQueryParamDelete();

  const get = useCallback(() => {
    return query.get(key);
  }, [query, key]);

  const set = useCallback(
    (value: string | (string | number | boolean)[]) => {
      const valueArray = Array.isArray(value) ? value : [value];
      // turn commas and other special characters into encoded values to prevent being mistaken as a comma-separated list
      const encodedValues = valueArray.map((v) => encodeURIComponent(v));
      const encodedValuesString = encodedValues.join(",");
      // using location ensures we get the URL as expected without any transformations
      const searchParams = history.location.search
        ? location.search.replace("?", "").split("&")
        : [];
      const newSearchParams: string[] = [];
      // add all query parameters except the one we're updating
      searchParams.forEach((searchParam) => {
        const [k, v] = searchParam.split("=");
        if (k !== key) {
          newSearchParams.push(`${k}=${v}`);
        }
      });
      // add the updated query parameter last
      newSearchParams.push(`${key}=${encodedValuesString}`);
      const searchString = newSearchParams.length
        ? `?${newSearchParams.join("&")}`
        : "";
      history.push({ search: searchString });
    },
    [key, history],
  );

  const remove = useCallback(
    (value?: string) => {
      removeQueryParam(key, value);
    },
    [key, removeQueryParam],
  );

  return useMemo(() => ({ get, set, remove }), [get, set, remove]);
};
