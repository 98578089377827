import { Box, styled } from "@mui/material";

export const FeaturedABTrackContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "16px",
  alignItems: "flex-start",
});

export const FeaturedABTrackWaveContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  width: "100%",
});
