import { useCallback } from "react";
import { useHistory } from "react-router-dom";

/**
 * A hook to delete a query parameter from the URL.
 * It handles removing query parameters that are single values or comma-separated lists.
 * @returns A function to delete a query parameter.
 */
export const useQueryParamDelete = () => {
  const history = useHistory();

  return useCallback(
    (key: string, value?: string) => {
      // using location ensures we get the URL as expected without any transformations
      const searchParams = location.search
        ? history.location.search.replace("?", "").split("&")
        : [];
      const newSearchParams: string[] = [];

      searchParams.forEach((searchParam) => {
        const [k, v] = searchParam.split("=");
        // if search param is not the target key, add it to the list
        if (k !== key) {
          newSearchParams.push(`${k}=${v}`);
          return;
        }

        // if no value is provided, remove the entire query parameter (by not adding it to newSearchParams)
        // if value is provided, remove only that value from the comma-separated list
        if (value) {
          const values = v.split(",");
          const updatedValues: string[] = [];
          values.forEach((val) => {
            if (val !== encodeURIComponent(value)) {
              updatedValues.push(val);
            }
          });
          if (updatedValues.length) {
            newSearchParams.push(`${key}=${updatedValues.join(",")}`);
          }
        }
      });
      // add the updated query parameter last
      const searchString = newSearchParams.length
        ? `?${newSearchParams.join("&")}`
        : "";
      history.push({ search: searchString });
    },
    [history],
  );
};
