import { Box, styled } from "@mui/material";

export const ReleaseListItemContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  max-width: 300px;
  width: 100%;
  cursor: pointer;
`;

export const ReleaseItemDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
`;

export const ReleaseCoverPhotoViewContainer = styled(Box)<{
  $width: number;
  $height: number;
}>(({ theme, $width, $height }) => ({
  height: `${$height}px`,
  width: `${$width}px`,
  borderRadius: theme.border.radius.md,
  overflow: "hidden",
  position: "relative",
}));

export const ReleaseListContainerStyle = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: "24px",
  minHeight: "242px",
}));
