import { SelectChangeEvent } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useGetWorkingHours } from "../../../hooks/profileScreenHooks/useGetWorkingHours";
import {
  SelectAMPMOptions,
  SelectHourInDayOptions,
} from "../../../store/models/alts";
import User from "../../../store/models/user";
import { parseWeekdays, WEEKDAYS } from "../../../store/models/workingHours";
import { OptionsDropdown } from "../../core-ui/components/MUIOptionsDropdown/MUIOptionsDropdown";
import { Text } from "../../core-ui/components/Text/Text";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { CenteredSoundWaveLoader } from "../CenteredSoundWaveLoader/CenteredSoundWaveLoader";
import {
  ServiceFormColumn,
  ServiceFormContainer,
  ServiceFormLabel,
} from "../ManageEngineeringService/ManageEngineeringService.styles";
import { WeekdayPicker } from "../WeekdayPicker/WeekdayPicker";
import { WorkingHourContainer } from "./SelectWorkingHourComponent.style";
import "./SelectWorkingHoursComponent.css";

// eslint-disable-next-line import/no-unused-modules
export interface SelectWorkingHoursComponentProps {
  studioRoomId?: number;
  studioRoomName?: string;
  weeklyWorkDates: WEEKDAYS[];
  setWeeklyWorkDates: Dispatch<SetStateAction<WEEKDAYS[]>>;
  startTime: OptionType<number>;
  setStartTime: Dispatch<SetStateAction<OptionType<number>>>;
  endTime: OptionType<number>;
  setEndTime: Dispatch<SetStateAction<OptionType<number>>>;
  startAMPM: OptionType<number>;
  setStartAMPM: Dispatch<SetStateAction<OptionType<number>>>;
  endAMPM: OptionType<number>;
  setEndAMPM: Dispatch<SetStateAction<OptionType<number>>>;
  userData: User;
}

export const SelectWorkingHoursComponent = ({
  studioRoomId,
  studioRoomName,
  weeklyWorkDates,
  setWeeklyWorkDates,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  startAMPM,
  setStartAMPM,
  endAMPM,
  setEndAMPM,
  userData,
}: SelectWorkingHoursComponentProps) => {
  const [nextDay, setNextDay] = useState(false);
  const { isFetching: isLoading } = useGetWorkingHours({
    onSuccess: (result) => {
      const workingDays = parseWeekdays(result);
      setWeeklyWorkDates(workingDays);
    },
    staleTime: Infinity,
    user_id: userData.id,
  });

  useEffect(() => {
    if (startAMPM.value === 0 && endAMPM.value === 0) {
      if (startTime.value >= endTime.value) {
        setNextDay(true);
      } else {
        setNextDay(false);
      }
    } else if (startAMPM.value === 1 && endAMPM.value === 1) {
      if (startTime.value >= endTime.value) {
        setNextDay(true);
      } else {
        setNextDay(false);
      }
    } else if (startAMPM.value === 1 && endAMPM.value === 0) {
      setNextDay(true);
    } else {
      setNextDay(false);
    }
  }, [startAMPM, endAMPM, startTime, endTime]);

  if (isLoading) return <CenteredSoundWaveLoader />;

  return (
    <ServiceFormContainer>
      <ServiceFormColumn>
        <ServiceFormLabel>Select your days of operation</ServiceFormLabel>
        <WeekdayPicker
          weekdaysInput={weeklyWorkDates}
          onChange={setWeeklyWorkDates}
        />
      </ServiceFormColumn>
      <ServiceFormColumn>
        <ServiceFormLabel>
          {studioRoomId
            ? `Select ${studioRoomName} Hours`
            : "Generally Available Recording Hours"}
        </ServiceFormLabel>
        <WorkingHourContainer>
          <SelectTimeInDayDropdownComponent
            onHourChange={(option) => setStartTime(option)}
            hour={startTime}
            onTimeChange={setStartAMPM}
            time={startAMPM}
          />
          <Text style={{ textAlign: "center" }}>to</Text>

          <SelectTimeInDayDropdownComponent
            onHourChange={(option) => setEndTime(option)}
            hour={endTime}
            onTimeChange={setEndAMPM}
            time={endAMPM}
          />
        </WorkingHourContainer>
      </ServiceFormColumn>
    </ServiceFormContainer>
  );
};

interface SelectHourInDayDropdownComponentProps {
  onHourChange?: (option: OptionType) => void;
  hour: OptionType;
  onTimeChange?: (option: OptionType) => void;
  time: OptionType;
}

const SelectTimeInDayDropdownComponent = ({
  onHourChange,
  onTimeChange,
  hour,
  time,
}: SelectHourInDayDropdownComponentProps) => {
  return (
    <div className="select-time-drop-down-container">
      <OptionsDropdown
        style={{ flex: 1 }}
        options={SelectHourInDayOptions}
        value={hour.value}
        onChange={(e: SelectChangeEvent<number>) => {
          const {
            target: { value },
          } = e;
          const foundOption = SelectHourInDayOptions.find(
            (option) => option.value === value,
          );
          if (foundOption) {
            onHourChange?.(foundOption);
          }
        }}
      />

      <OptionsDropdown
        style={{ flex: 1 }}
        options={SelectAMPMOptions}
        value={time.value}
        onChange={(e: SelectChangeEvent<number>) => {
          const {
            target: { value },
          } = e;
          const foundOption = SelectAMPMOptions.find(
            (option) => option.value === value,
          );
          if (foundOption) {
            onTimeChange?.(foundOption);
          }
        }}
      />
    </div>
  );
};
