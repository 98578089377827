import { format, parseJSON } from "date-fns";
import React from "react";
import {
  useGetProjectTypeToProjectTypeMetadataFromScheduledProject,
  useGetReadableServices,
} from "../../../hooks/checkoutHooks/useGetProjectTypeToProjectTypeMetadata";
import { useFetchScheduledProjectOverview } from "../../../hooks/scheduledProjectHooks/useFetchScheduledProjectOverview";
import { useGetItemizedTransaction } from "../../../hooks/scheduledProjectHooks/useGetItemizedTransaction";
import { useAppSelector } from "../../../store/hooks";
import {
  projectTypeReadableName,
  ProjectUserType,
} from "../../../store/models/project";
import { PaginatedScheduledProject } from "../../../store/models/scheduledproject";
import { FETCH_STATES } from "../../../store/utils/fetch";
import {
  Text,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import { ProjectListRow } from "../../elements/ProjectListRow/ProjectListRow";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { OrderBreakdown } from "../OrderBreakdown/OrderBreakdown";
import { ProjectListItem } from "../ProjectList/ProjectList.styles";
import { RescheduleEstimatedDeliveryDatePicker } from "../RescheduledEstimatedDeliveryDatePicker/RescheduleEstimatedDeliveryDatePicker";
import { isUserOnProject } from "../ScheduledProjectTable/utils";
import { BreakdownRow } from "../TransactionBreakdown/TransactionBreakdown";
import {
  BookingDetailsContainer,
  BookingDetailsContent,
  BookingDetailsDate,
  BookingDetailsHeader,
  BookingDetailsMetadata,
  BookingDetailsMetadataContainer,
  BookingDetailsOverview,
  BookingDetailsOverviewTitle,
  BookingDetailsProps,
  BookingDetailsStats,
  ContentBody,
  ContentHeader,
  Divider,
  PropsLabel,
  PropsValue,
} from "./BookingDetails.styles";

export const FORMAT_DATE_STRING = "eee, LLL d, yyyy";

interface BookingDetailsProps {
  scheduledProjectId: number;
  scheduledProject?: PaginatedScheduledProject;
  userType?: ProjectUserType | null;
}

export const BookingDetails = ({
  scheduledProjectId,
  scheduledProject,
  userType,
}: BookingDetailsProps) => {
  // Since ScheduledProjectOverview is only being used in this component, we can fetch it directly from here
  // This enables the component to be used in isolation i.e. on the ScheduledOverview page or in the Workflow Panel
  // This can be refactored to use data directly from useQuery, but for now we'll keep the changes minimal
  const { isLoading: isScheduledProjectOverviewLoading } =
    useFetchScheduledProjectOverview({
      scheduledProjectId,
    });
  const user = useAppSelector((state) => state.accountInfo.user);
  const {
    simplifiedScheduledProjectDetails,
    scheduledProject: storedScheduledProject,
  } = useAppSelector((state) => state.scheduledProjectsStore);
  // sometimes the scheduled project is not in the redux store
  // in the BookingRequestedPanel, the scheduled project exists in the parent component, so just pass it in as props.
  const currentScheduledProject = scheduledProject ?? storedScheduledProject;
  const isUserCollaborator = isUserOnProject(currentScheduledProject, user);

  // Fetch order summary for the mix/master project
  const { isLoading: mixMasterOrderSummaryIsLoading } =
    useGetItemizedTransaction({
      scheduledProjectId,
      transactionCode: "",
      enabled: isUserCollaborator, // only fetch if user is on the project
      filterByUser: true,
    });
  const { orderSummary: mixMasterOrderSummary } = useAppSelector(
    (state) => state.mixMasterCartsStore,
  );

  const scheduledProjectOverview =
    simplifiedScheduledProjectDetails[scheduledProjectId];
  const projectTypeToProjectTypeMetadata =
    useGetProjectTypeToProjectTypeMetadataFromScheduledProject(
      scheduledProjectOverview?.data,
    );
  const readableServices = useGetReadableServices(
    projectTypeToProjectTypeMetadata,
  );
  const isEngineer = userType === ProjectUserType.ENGINEER;

  if (
    isScheduledProjectOverviewLoading ||
    !scheduledProjectOverview ||
    scheduledProjectOverview.status === FETCH_STATES.LOADING
  ) {
    return <SoundWaveLoader width={100} height={100} />;
  }

  if (
    scheduledProjectOverview.status === FETCH_STATES.FAILED ||
    scheduledProjectOverview.status === FETCH_STATES.IDLE ||
    !scheduledProjectOverview.data
  ) {
    return null;
  }

  const details = scheduledProjectOverview.data;

  return (
    <BookingDetailsContainer>
      <BookingDetailsHeader>
        <Text size={TEXT_SIZE.LARGE} weight={TEXT_WEIGHT.SEMI_BOLD}>
          Booking Details
        </Text>
        <BookingDetailsMetadata>
          <BookingDetailsOverview>
            <div>
              <BookingDetailsOverviewTitle
                weight={TEXT_WEIGHT.SEMI_BOLD}
                size={TEXT_SIZE.MEDIUM}
              >
                Services
              </BookingDetailsOverviewTitle>
              <Text>{readableServices}</Text>
            </div>
            <div>
              <BookingDetailsOverviewTitle
                weight={TEXT_WEIGHT.SEMI_BOLD}
                size={TEXT_SIZE.MEDIUM}
              >
                Estimated Delivery Date
              </BookingDetailsOverviewTitle>
              <BookingDetailsDate>
                <RescheduleEstimatedDeliveryDatePicker
                  scheduleProjectId={scheduledProjectId}
                  estimatedDeliveryDate={details.estimated_delivery_date}
                />
              </BookingDetailsDate>
            </div>
          </BookingDetailsOverview>
          <Divider $light />
          <BookingDetailsMetadataContainer>
            <div>
              <BookingDetailsProps>
                <BookingDetailsPropsField
                  label="Request Date"
                  value={format(parseJSON(details.created), FORMAT_DATE_STRING)}
                />
                <BookingDetailsPropsField
                  label="Project ID"
                  value={details.id}
                />
                <BookingDetailsPropsField
                  label="Project Title"
                  value={details.title}
                />
              </BookingDetailsProps>
            </div>
            <BookingDetailsStats>
              {[...projectTypeToProjectTypeMetadata.entries()].map(
                ([projectType, projectData]) => {
                  return (
                    <React.Fragment key={projectType}>
                      <BreakdownRow
                        label={`Total ${projectTypeReadableName.get(projectType)} tracks`}
                        value={projectData.count.toString()}
                      />
                      <BreakdownRow
                        label={`Total ${projectTypeReadableName.get(projectType)} alt tracks`}
                        value={projectData.altCount.toString()}
                      />
                    </React.Fragment>
                  );
                },
              )}
            </BookingDetailsStats>
          </BookingDetailsMetadataContainer>
        </BookingDetailsMetadata>
      </BookingDetailsHeader>
      <BookingDetailsContent>
        <ContentHeader>
          <Text size={TEXT_SIZE.LARGE} weight={TEXT_WEIGHT.SEMI_BOLD}>
            Song(s) list:
          </Text>
        </ContentHeader>
        <ContentBody>
          {details.projects.map((project, index) => (
            <React.Fragment key={project.id}>
              <ProjectListItem>
                <Text
                  weight={TEXT_WEIGHT.SEMI_BOLD}
                  size={TEXT_SIZE.SMALL}
                >{`${index + 1}.`}</Text>
                <ProjectListRow project={project} allowEdit={false} />
              </ProjectListItem>
              {index < details.projects.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </ContentBody>
      </BookingDetailsContent>
      <OrderBreakdown
        orderSummary={mixMasterOrderSummary}
        isLoading={mixMasterOrderSummaryIsLoading}
        showLabelToggle={false}
        hideNonEngineerFees={isEngineer}
      />
    </BookingDetailsContainer>
  );
};

interface IBookingDetailsPropsField {
  label: string;
  value: string | number;
}

const BookingDetailsPropsField = ({
  label,
  value,
}: IBookingDetailsPropsField) => {
  return (
    <>
      <PropsLabel>
        <Text weight={TEXT_WEIGHT.SEMI_BOLD} size={TEXT_SIZE.SMALL}>
          {label}
        </Text>
      </PropsLabel>
      <PropsValue>
        <Text size={TEXT_SIZE.SMALL}>{value}</Text>
      </PropsValue>
    </>
  );
};
