import { useQuery } from "@tanstack/react-query";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { receiveErrors } from "../../store/actions/errorStore";
import { useAppDispatch } from "../../store/hooks";
import {
  checklistAtom,
  checklistPhaseAtom,
  checklistPhaseTitleAtom,
  completedChecklistStepsAtom,
  totalChecklistStepsAtom,
} from "../../stories/components/Onboarding/atoms";
import {
  getOnboardingProgress,
  OnboardingProgressResponse,
} from "./getOnboardingProgress";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { useAtomValue } from "jotai";
import { activeUserIdAtom } from "../../atoms/user/activeUserAtom";

const useGetOnboardingProgressQuery = () => {
  const userId = useAtomValue(activeUserIdAtom);
  const dispatch = useAppDispatch();

  const updateAtoms = useAtomCallback(
    useCallback((_, set, checklistResponse: OnboardingProgressResponse) => {
      const completedSteps = checklistResponse.checklist.filter(
        (step) => step.completed,
      );
      set(completedChecklistStepsAtom, completedSteps.length);
      set(totalChecklistStepsAtom, checklistResponse.checklist.length);
      let didPhaseChange = false;
      set(checklistPhaseAtom, (oldState) => {
        if (oldState && oldState !== checklistResponse.phase) {
          didPhaseChange = true;
        }
        return checklistResponse.phase;
      });
      set(checklistPhaseTitleAtom, checklistResponse.title);
      set(checklistAtom, (oldState) => {
        const returnValue = {
          oldState: didPhaseChange ? oldState.currentState : oldState.oldState,
          currentState: checklistResponse.checklist,
        };
        // Determine what event was completed, and fire off the appropriate analytic events
        const changedItems: Record<string, boolean> = {};
        if (oldState.currentState) {
          // Add items to dictionary that were not completed last we knew
          oldState.currentState.forEach((item) => {
            if (!item.completed) {
              changedItems[item.title] = item.completed;
            }
          });
        }
        if (didPhaseChange) {
          Object.keys(changedItems).forEach((title) => {
            emitAnalyticsTrackingEvent(
              "checklist_completion_" + title.toLowerCase().replace(/ /g, "_"),
              {},
            );
          });
        } else {
          returnValue.currentState.forEach((item) => {
            if (item.completed) {
              if (
                typeof changedItems[item.title] !== "undefined" &&
                !changedItems[item.title]
              ) {
                emitAnalyticsTrackingEvent(
                  "checklist_completion_" +
                    item.title.toLowerCase().replace(/ /g, "_"),
                  {},
                );
              }
            }
          });
        }
        return returnValue;
      });
    }, []),
  );

  return useQuery({
    queryKey: [QUERY_KEYS.GET_ONBOARDING_PROGRESS, userId],
    queryFn: async () => {
      const result = await getOnboardingProgress();
      if (result.success) {
        const jsonResult = result.resultJson;
        updateAtoms(jsonResult);
        return jsonResult;
      }
      const errors = { errors: result.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(errors);
    },
    enabled: Boolean(userId),
  });
};

export default useGetOnboardingProgressQuery;
