import { useCallback } from "react";
import {
  engMasteringTransition,
  engMixTransition,
} from "../../../store/actions/projects";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  MasteringTransitions,
  MixingTransitions,
  ProjectType,
  ProjectWorkflowSteps,
} from "../../../store/models/project";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  getMixingMasteringProject,
  getProjectWorkflowStep,
} from "../../../utils/projectUtils";

/**
 * Hook to transition to the "In Progress" step of the project workflow
 * if engineer uploads mix during "File Transfer" step.
 */
export const useTransitionToInProgress = (
  projectId: number,
  isCurrentProjectEngineer: boolean,
  engineerIsUploadingOnBehalfOfArtist: boolean,
) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const project = useAppSelector(
    (state) => state.projectsMapStore.projects[projectId] ?? null,
  );
  const projectType = project?.service_type;
  const mixMasterProject = getMixingMasteringProject(project);
  const step = mixMasterProject?.step ?? -1;
  const projectWorkflowStep = getProjectWorkflowStep(projectType, step);

  return useCallback(async () => {
    // if engineer is uploading a main file during the File Transfer step,
    // transition to next step (In Progress)
    if (
      isCurrentProjectEngineer &&
      !engineerIsUploadingOnBehalfOfArtist &&
      projectType &&
      projectType !== ProjectType.RECORDING &&
      projectWorkflowStep === ProjectWorkflowSteps.FILE_TRANSFER
    ) {
      const isMaster = projectType === ProjectType.MASTERING;
      emitAnalyticsTrackingEvent(
        "engineer_upload_main_mix_button",
        {
          project_id: projectId,
        },
        user?.id,
      );

      if (isMaster) {
        await dispatch(
          engMasteringTransition({
            project_id: projectId.toString(),
            transition: MasteringTransitions.ENGINEER_UPLOAD_INITIAL_MAIN,
          }),
        );
      } else {
        await dispatch(
          engMixTransition({
            project_id: projectId.toString(),
            transition: MixingTransitions.ENGINEER_UPLOAD_INITIAL_MAIN,
          }),
        );
      }
    }
  }, [
    dispatch,
    engineerIsUploadingOnBehalfOfArtist,
    isCurrentProjectEngineer,
    projectId,
    projectType,
    projectWorkflowStep,
    user?.id,
  ]);
};
