import {
  useGetEarliestStartDateAndLatestEndDateFromRecordingSessions,
  UseGetEarliestStartDateAndLatestEndDateFromRecordingSessionsProps,
  useGetTotalRecordingSessionsHours,
  UseGetTotalRecordingSessionsHoursProps,
} from "../../../hooks/useRecordingSessionsHooks";
import { convertUTCDateToLocalDate } from "../../../store/utils/dateTimeUtils";
import {
  StyledSessionsOverviewBoldText,
  StyledSessionsOverviewContainer,
  StyledSessionsOverviewDefaultText,
  StyledSessionsOverviewRow,
} from "./SessionsOverview.styles";

export interface SessionsOverviewRowProps {
  label: string;
  value: string;
}
const SessionsOverviewRow = ({ label, value }: SessionsOverviewRowProps) => {
  return (
    <StyledSessionsOverviewRow>
      <StyledSessionsOverviewDefaultText>
        {label}
      </StyledSessionsOverviewDefaultText>
      <StyledSessionsOverviewDefaultText>
        {value}
      </StyledSessionsOverviewDefaultText>
    </StyledSessionsOverviewRow>
  );
};

export interface SessionsOverviewProps {
  recordingSessions: (UseGetTotalRecordingSessionsHoursProps &
    UseGetEarliestStartDateAndLatestEndDateFromRecordingSessionsProps)[];
}

export const SessionsOverview = ({
  recordingSessions,
}: SessionsOverviewProps) => {
  const { studioDuration, engineerDuration } =
    useGetTotalRecordingSessionsHours(recordingSessions);

  const { earliestStartDate, latestEndDate } =
    useGetEarliestStartDateAndLatestEndDateFromRecordingSessions(
      recordingSessions,
    );

  const formattedStartDateStr =
    convertUTCDateToLocalDate(earliestStartDate).toLocaleDateString();
  const formattedEndDateStr =
    convertUTCDateToLocalDate(latestEndDate).toLocaleDateString();
  return (
    <StyledSessionsOverviewContainer>
      <StyledSessionsOverviewBoldText>
        Session Overview:
      </StyledSessionsOverviewBoldText>
      <SessionsOverviewRow
        label={"Dates"}
        value={`${formattedStartDateStr} - ${formattedEndDateStr}`}
      />
      <SessionsOverviewRow
        label={"Total sessions"}
        value={`${recordingSessions.length}`}
      />
      {Boolean(studioDuration) && (
        <SessionsOverviewRow
          label={"Total studio hours"}
          value={`${studioDuration}`}
        />
      )}{" "}
      {Boolean(engineerDuration) && (
        <SessionsOverviewRow
          label={"Total engineer hours"}
          value={`${engineerDuration}`}
        />
      )}
    </StyledSessionsOverviewContainer>
  );
};
