import User from "../../../store/models/user";
import { VERIFY_PHONE } from "../../../store/utils/routes";
import { EngineEarsError } from "../../../store/actions/errorStore";
import { makeBackendPostCallWithJsonResponse } from "../../../store/utils/fetch";

export interface VerifyPhoneParams {
  verification: string;
}

export const postVerifyPhone = async (
  params: VerifyPhoneParams,
): Promise<User | EngineEarsError> => {
  return makeBackendPostCallWithJsonResponse<User>(VERIFY_PHONE, params).then(
    (res) => {
      if (res.success) {
        return res.resultJson;
      }
      return res.resultJson;
    },
  );
};
