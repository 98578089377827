import { SlimPrivateActiveUser } from "../../store/models/user";
import { LOAD_ACTIVE_USER } from "../../store/utils/routes";
import { ProfileType } from "../../store/models/base";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { EngineEarsError } from "../../store/actions/errorStore";

export const fetchActiveUser = async (): Promise<
  SlimPrivateActiveUser | null | EngineEarsError
> => {
  return makeBackendGetCallWithJsonResponse<SlimPrivateActiveUser | null>(
    LOAD_ACTIVE_USER,
    "",
  ).then((res) => {
    if (res.statusCode === 202) {
      return null;
    }
    if (res.success && res.resultJson) {
      const transformedData: SlimPrivateActiveUser = {
        ...res.resultJson,
        type: ProfileType.USER,
      };
      return transformedData;
    }
    return res.resultJson;
  });
};
