import queryString from "query-string";

import User from "../models/user";
import { ProjectType } from "../models/project";
import { RootState, QueryParamsType } from "../index";
import { Genre } from "../models/genres";
import {
  ArtistSuggestion,
  AutocompleteSuggestion,
  AutoCompleteTypeEnum,
  LocationSuggestion,
} from "../models/autocomplete";
import { SEARCH_USER_API } from "../utils/routes";
import { receiveErrors } from "./errorStore";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";

interface searchUserParams {
  search: string;
  searchOrigin?: SearchOrigin;
  promoCode: string | null;
  paymentDeposits: string | null;
  abortController?: AbortController;
}

export enum SearchOrigin {
  UNDEFINED_SEARCH_ORIGIN,
  EXPLORE_SCREEN,
  ENGINEER_SEARCH_SCREEN,
  STUDIO_ROOM_SEARCH_SCREEN,
}

interface SearchUsersResponse {
  data: User[];
  page: number;
  num_pages: number;
  limit: number;
  count: number;
}

export const searchUsers = createAsyncThunk(
  SEARCH_USER_API,
  async (
    {
      search,
      searchOrigin,
      promoCode,
      paymentDeposits,
      abortController,
    }: searchUserParams,
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;
    const {
      maxRate,
      minRate,
      page,
      simpleBudgetSelected,
      serviceTypes,
      maxDistance,
      latitude,
      longitude,
      genres,
      autocompleteSuggestions,
      upAndComingEng,
    } = state.userSearch;
    const anonymousId = state.accountInfo.anonymousId;

    const queryParamObj: QueryParamsType = {};

    queryParamObj.search = search;
    queryParamObj.page = page.toString();

    if (maxRate > 0) {
      queryParamObj.min_rate = minRate;
      queryParamObj.max_rate = maxRate;
    } else if (simpleBudgetSelected.length > 0) {
      queryParamObj.simple_budget_selected = simpleBudgetSelected;
    }

    if (
      !serviceTypes.includes(ProjectType.NO_TYPE) &&
      serviceTypes.length > 0
    ) {
      queryParamObj.service_types = serviceTypes;
    }
    if (maxDistance) {
      queryParamObj.max_distance = maxDistance;
    }
    if (!genres.includes(Genre.NO_GENRE) && genres.length > 0) {
      queryParamObj.genres = genres;
    }
    const checkIfArtistCredit = autocompleteSuggestions.find(
      (suggestion) => suggestion.type === AutoCompleteTypeEnum.ARTIST_CREDIT,
    );
    if (checkIfArtistCredit) {
      const artistCredit = checkIfArtistCredit as ArtistSuggestion;
      queryParamObj.artist_credit = artistCredit.label;
    }
    const checkIfLocation = autocompleteSuggestions.find(
      (suggestion) => suggestion.type === AutoCompleteTypeEnum.LOCATION,
    );
    if (checkIfLocation) {
      const location = checkIfLocation as LocationSuggestion;
      const { latitude, longitude, location_string } = location;
      queryParamObj.latitude = latitude;
      queryParamObj.longitude = longitude;
      queryParamObj.location_string = location_string;
    } else if (latitude && longitude) {
      queryParamObj.latitude = latitude;
      queryParamObj.longitude = longitude;
    }
    if (searchOrigin) {
      queryParamObj.search_origin = searchOrigin;
    }
    if (promoCode) {
      queryParamObj.promocode = promoCode;
    }
    if (paymentDeposits) {
      queryParamObj.payment_deposits = true;
    }
    if (anonymousId) {
      queryParamObj.anonymous_id = anonymousId;
    }
    if (upAndComingEng) {
      queryParamObj.up_and_coming_eng = true;
    }

    const params = `?${queryString.stringify(queryParamObj, {
      arrayFormat: "comma",
    })}`;

    emitAnalyticsTrackingEvent("user_search", {
      query_params: params,
    });
    const response =
      await makeBackendGetCallWithJsonResponse<SearchUsersResponse>(
        SEARCH_USER_API,
        params,
        abortController ? { signal: abortController.signal } : undefined,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface paginatedUsers {
  [page: number]: User[];
}

interface userSearchState {
  paginatedUsers: paginatedUsers;
  page: number;
  num_pages: number;
  limit: number;
  count: number;
  simpleBudgetSelected: string[];
  maxRate: number;
  minRate: number;
  serviceTypes: ProjectType[];
  upAndComingEng: boolean;
  maxDistance: number;
  genres: Genre[];
  latitude: number;
  longitude: number;
  fetching: boolean;
  autocompleteSuggestions: AutocompleteSuggestion[];
  requestingLatLong: boolean;
}

export const initialState: userSearchState = {
  paginatedUsers: {},
  page: 1,
  num_pages: 1,
  limit: 12,
  count: 0,
  simpleBudgetSelected: [],
  maxRate: 0,
  minRate: 0,
  serviceTypes: [ProjectType.NO_TYPE],
  upAndComingEng: false,
  maxDistance: 0,
  genres: [Genre.NO_GENRE],
  latitude: 0,
  longitude: 0,
  fetching: false,
  autocompleteSuggestions: [],
  requestingLatLong: false,
};

const updateGenres = (
  genres: Genre[],
  index: number,
  allGenresIndex: number,
  genre: Genre,
) => {
  if (index === -1) {
    // If "All" is selected as a genre filter, remove it
    if (allGenresIndex >= 0) {
      genres.splice(allGenresIndex, 1);
    }
    genres.push(genre);
  } else {
    genres.splice(index, 1);
    if (genres.length === 0) {
      // Add "All" as a selected genre filter if no other filters active
      genres.push(Genre.NO_GENRE);
    }
  }
};

const updateServiceTypes = (
  serviceTypes: ProjectType[],
  index: number,
  allServiceTypesIndex: number,
  projectType: ProjectType,
) => {
  if (index === -1) {
    // If "All" is selected as a service type filter, remove it
    if (allServiceTypesIndex >= 0) {
      serviceTypes.splice(allServiceTypesIndex, 1);
    }
    serviceTypes.push(projectType);
  } else {
    serviceTypes.splice(index, 1);
    if (serviceTypes.length === 0) {
      // Add "All" as a selected service type filter if no other filters active
      serviceTypes.push(ProjectType.NO_TYPE);
    }
  }
};

const userSearchSlice = createSlice({
  name: "userSearch",
  initialState,
  reducers: {
    toggleRequestingLatLong: (state) => {
      state.requestingLatLong = !state.requestingLatLong;
    },
    updateUserSearchPage: (state, action: PayloadAction<number>) => {
      if (state.num_pages < action.payload) {
        state.page = 1;
        return;
      }
      state.page = action.payload;
    },
    removeAutoCompleteSuggestions: (
      state,
      action: PayloadAction<AutocompleteSuggestion>,
    ) => {
      state.page = 1;
      const suggestion = action.payload;
      switch (suggestion.type) {
        case AutoCompleteTypeEnum.ARTIST_CREDIT:
          const artistIndex = state.autocompleteSuggestions.findIndex(
            (suggestion) =>
              suggestion.type === AutoCompleteTypeEnum.ARTIST_CREDIT,
          );
          if (artistIndex !== -1) {
            state.autocompleteSuggestions.splice(artistIndex, 1);
          }
          break;
        case AutoCompleteTypeEnum.LOCATION:
          const locationIndex = state.autocompleteSuggestions.findIndex(
            (suggestion) => suggestion.type === AutoCompleteTypeEnum.LOCATION,
          );
          if (locationIndex !== -1) {
            state.autocompleteSuggestions.splice(locationIndex, 1);
          }
          break;
        default:
          break;
      }
    },
    setAutoCompleteSuggestions: (
      state,
      action: PayloadAction<AutocompleteSuggestion>,
    ) => {
      state.page = 1;
      const suggestion = action.payload;
      switch (suggestion.type) {
        case AutoCompleteTypeEnum.ARTIST_CREDIT:
          const artistSuggestions = suggestion;
          const artistIndex = state.autocompleteSuggestions.findIndex(
            (suggestion) =>
              suggestion.type === AutoCompleteTypeEnum.ARTIST_CREDIT,
          );
          if (artistIndex === -1) {
            state.autocompleteSuggestions.push(artistSuggestions);
          } else {
            const currentArtist = state.autocompleteSuggestions[
              artistIndex
            ] as ArtistSuggestion;
            if (currentArtist.label === artistSuggestions.label) {
              state.autocompleteSuggestions.splice(artistIndex, 1);
            } else {
              state.autocompleteSuggestions.splice(artistIndex, 1);
              state.autocompleteSuggestions.push(artistSuggestions);
            }
          }
          break;
        case AutoCompleteTypeEnum.LOCATION:
          const locationSuggestions = suggestion;
          const locationIndex = state.autocompleteSuggestions.findIndex(
            (suggestion) => suggestion.type === AutoCompleteTypeEnum.LOCATION,
          );
          if (locationIndex === -1) {
            state.autocompleteSuggestions.push(locationSuggestions);
          } else {
            const currentLocation = state.autocompleteSuggestions[
              locationIndex
            ] as LocationSuggestion;
            if (
              currentLocation.latitude === locationSuggestions.latitude &&
              currentLocation.longitude === locationSuggestions.longitude
            ) {
              state.autocompleteSuggestions.splice(locationIndex, 1);
            } else {
              state.autocompleteSuggestions.splice(locationIndex, 1);
              state.autocompleteSuggestions.push(locationSuggestions);
            }
          }
          break;
        case AutoCompleteTypeEnum.GENRE:
          const genreSuggestions = suggestion;
          const genreIndex = state.genres.findIndex(
            (genre) => genre === genreSuggestions.value,
          );
          const allGenresIndex = state.genres.findIndex(
            (genre) => genre === Genre.NO_GENRE,
          );
          const genresCopy = [...state.genres];
          updateGenres(
            genresCopy,
            genreIndex,
            allGenresIndex,
            genreSuggestions.value,
          );
          state.genres = genresCopy;
          break;
        case AutoCompleteTypeEnum.SERVICE_TYPE:
          const serviceTypeSuggestions = suggestion;
          const allServiceTypesIndex = state.serviceTypes.findIndex(
            (serviceType) => serviceType === ProjectType.NO_TYPE,
          );

          let serviceTypesCopy = [...state.serviceTypes];
          switch (serviceTypeSuggestions.value) {
            case ProjectType.NO_TYPE:
              serviceTypesCopy = [ProjectType.NO_TYPE];
              break;
            case ProjectType.MIXING:
              const mixingIndex = state.serviceTypes.findIndex(
                (serviceType) => serviceType === ProjectType.MIXING,
              );
              updateServiceTypes(
                serviceTypesCopy,
                mixingIndex,
                allServiceTypesIndex,
                ProjectType.MIXING,
              );
              break;
            case ProjectType.MASTERING:
              const masteringIndex = state.serviceTypes.findIndex(
                (serviceType) => serviceType === ProjectType.MASTERING,
              );
              updateServiceTypes(
                serviceTypesCopy,
                masteringIndex,
                allServiceTypesIndex,
                ProjectType.MASTERING,
              );
              break;
            case ProjectType.TWO_TRACK_MIXING:
              const twoTrackMixingIndex = state.serviceTypes.findIndex(
                (serviceType) => serviceType === ProjectType.TWO_TRACK_MIXING,
              );
              updateServiceTypes(
                serviceTypesCopy,
                twoTrackMixingIndex,
                allServiceTypesIndex,
                ProjectType.TWO_TRACK_MIXING,
              );
              break;
            case ProjectType.ATMOS_MIXING:
              const atmosMixingIndex = state.serviceTypes.findIndex(
                (serviceType) => serviceType === ProjectType.ATMOS_MIXING,
              );
              updateServiceTypes(
                serviceTypesCopy,
                atmosMixingIndex,
                allServiceTypesIndex,
                ProjectType.ATMOS_MIXING,
              );
              break;
            case ProjectType.RECORDING:
              const recordingIndex = state.serviceTypes.findIndex(
                (serviceType) => serviceType === ProjectType.RECORDING,
              );
              updateServiceTypes(
                serviceTypesCopy,
                recordingIndex,
                allServiceTypesIndex,
                ProjectType.RECORDING,
              );
              break;
            default:
              break;
          }
          state.serviceTypes = serviceTypesCopy;
          break;
        default:
          break;
      }
    },
    clearFiltersForUserSearch: (state) => {
      state.page = 1;
      state.simpleBudgetSelected = [];
      state.maxRate = 0;
      state.minRate = 0;
      state.serviceTypes = [ProjectType.NO_TYPE];
      state.maxDistance = 0;
      state.genres = [Genre.NO_GENRE];
      state.autocompleteSuggestions = [];
      state.upAndComingEng = false;
    },
    setFiltersForUserSearch: (
      state,
      action: PayloadAction<{
        simpleBudgetSelected: string[];
        maxRate: number;
        minRate: number;
        serviceTypes: ProjectType[];
        maxDistance: number;
        genres: Genre[];
        upAndComingEng: boolean;
      }>,
    ) => {
      if (action.payload.simpleBudgetSelected.length === 0) {
        state.simpleBudgetSelected = [];
      } else {
        state.simpleBudgetSelected = action.payload.simpleBudgetSelected;
      }
      if (action.payload.minRate > action.payload.maxRate) {
        state.minRate = 0;
        state.maxRate = 0;
      }
      if (action.payload.maxDistance === state.maxDistance) {
        state.maxDistance = 0;
      } else {
        state.maxDistance = action.payload.maxDistance;
      }
      state.maxRate = action.payload.maxRate;
      state.minRate = action.payload.minRate;
      state.page = 1;
      state.genres = action.payload.genres;
      state.serviceTypes = action.payload.serviceTypes;
      state.upAndComingEng = action.payload.upAndComingEng;
      if (state.serviceTypes.length === 5) {
        state.serviceTypes = [ProjectType.NO_TYPE];
      }
      if (
        !state.latitude &&
        !state.longitude &&
        state.serviceTypes.includes(ProjectType.RECORDING)
      ) {
        state.fetching = true;
        state.requestingLatLong = true;
      }
    },
    setUserLatLngForUserSearch: (
      state,
      action: PayloadAction<{ latitude: number; longitude: number }>,
    ) => {
      state.page = 1;
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
    setFetchingUsers: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    setUpAndComingEngFilter: (state, action: PayloadAction<boolean>) => {
      state.upAndComingEng = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchUsers.fulfilled, (state, action) => {
      state.page = action.payload.page;
      state.paginatedUsers[state.page] = action.payload.data;
      state.num_pages = action.payload.num_pages;
      state.limit = action.payload.limit;
      state.fetching = false;
      state.count = action.payload.count;
    });
    builder.addCase(searchUsers.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(searchUsers.rejected, (state, action) => {
      const { meta } = action;
      if (meta.aborted) return;
      state.fetching = false;
      state.page = 1;
      state.paginatedUsers = {};
      state.num_pages = 1;
      state.limit = 12;
      state.count = 0;
    });
  },
});

export default userSearchSlice.reducer;
export const {
  clearFiltersForUserSearch,
  setUserLatLngForUserSearch,
  setFiltersForUserSearch,
  setAutoCompleteSuggestions,
  removeAutoCompleteSuggestions,
  updateUserSearchPage,
  toggleRequestingLatLong,
  setFetchingUsers,
  setUpAndComingEngFilter,
} = userSearchSlice.actions;
