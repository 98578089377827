import { styled, Box } from "@mui/material";

export const ToggleButtonContainer = styled(Box)<{ $enabled: boolean }>(
  ({ theme, $enabled }) => ({
    display: "flex",
    borderRadius: "5px",
    overflow: "hidden",
    background: theme.palette.background.default,
    height: 44,
    width: 190,
    border: "1px solid #1E1E1E",
    position: "relative",
    "&::after": {
      content: '""',
      width: "50%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: !$enabled ? "0" : "50%",
      background: "rgba(255, 232, 225, 0.70)",
      transition: "left 0.3s",
      zIndex: 1,
    },
    button: {
      width: "50%",
      border: "none",
      padding: "0.75rem 1.5rem",
      cursor: "pointer",
      background: "transparent",
      zIndex: 2,
      gap: "6px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      "&:disabled": {
        cursor: "not-allowed",
        opacity: 0.5,
      },
    },
  }),
);
