import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  bottomNavContainerHeightAtom,
  showBottomNavAtom,
} from "../../../atoms/navAtoms";
import useGetOnboardingProgressQuery from "../../../hooks/onboardingHooks/useGetOnboardingProgressQuery";
import { DISCIPLINE_TYPE, getUserDisciplines } from "../../../hooks/user";
import { useAppSelector } from "../../../store/hooks";
import StickyOnboardingChecklist from "./StickyOnboardingChecklist";
import {
  ChecklistPhase,
  checklistPhaseAtom,
  showOnboardingChecklistAtom,
} from "./atoms";

const ChecklistWrapper = () => {
  const theme = useTheme();
  const currentUser = useAppSelector((state) => state.accountInfo?.user);
  const { isLoading } = useGetOnboardingProgressQuery();
  const bottomNavContainerHeight = useAtomValue(bottomNavContainerHeightAtom);
  const showBottomNav = useAtomValue(showBottomNavAtom);
  const [showOnboardingChecklist, setShowOnboardingChecklist] = useAtom(
    showOnboardingChecklistAtom,
  );
  const checklistPhase = useAtomValue(checklistPhaseAtom);
  const location = useLocation();
  const isTabletOrBelow = useMediaQuery(theme.breakpoints.down("md"));
  const userDisciplines = getUserDisciplines(currentUser);

  useEffect(() => {
    if (checklistPhase === ChecklistPhase.COMPLETE) {
      setShowOnboardingChecklist(false);
      return;
    }
    const isProjectWorkflowPanelOpen =
      location.pathname.includes("project-overview") &&
      location.search.includes("project=");

    const isBookingRequestPanelOpen =
      location.pathname.includes("projects") &&
      location.search.includes("scheduled_project_id=");

    const isMessagesPage = location.pathname.includes("messages");

    const isEngineer = userDisciplines.includes(DISCIPLINE_TYPE.ENGINEER);
    const bookingRegex =
      /booking\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
    const isBookingPath = bookingRegex.test(location.pathname);
    const isAuthPath = location.pathname.includes("auth");
    const isSubscriptionRoute =
      location.pathname.includes("add-subscription") ||
      location.pathname.includes("subscription-success");
    const shouldHide =
      ((!isEngineer || isTabletOrBelow) && isBookingPath) ||
      isAuthPath ||
      isSubscriptionRoute ||
      (isTabletOrBelow &&
        (isProjectWorkflowPanelOpen || isBookingRequestPanelOpen)) ||
      isMessagesPage;
    setShowOnboardingChecklist(!shouldHide);
  }, [
    location.search,
    location.pathname,
    showOnboardingChecklist,
    setShowOnboardingChecklist,
    isTabletOrBelow,
    checklistPhase,
    userDisciplines,
  ]);

  if (!showOnboardingChecklist) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        position: "fixed",
        bottom: `${bottomNavContainerHeight}px`,
        zIndex: "var(--bottom-nav-z-index) !important",
        [theme.breakpoints.up("sm")]: {
          zIndex: "var(--side-panel-z-index) !important",
        },
        [theme.breakpoints.down("md")]: {
          bottom: showBottomNav ? `${bottomNavContainerHeight}px` : "0",
          width: "100%",
        },
      })}
    >
      {isLoading ? <></> : <StickyOnboardingChecklist />}
    </Box>
  );
};

export default ChecklistWrapper;
