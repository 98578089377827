import { useAppSelector } from "../../../store/hooks";
import useModal from "../../../hooks/useModal";
import { UnauthenticatedModal } from "../UnauthenticatedModal/UnauthenticatedModal";
import classNames from "classnames";
import {
  InvisibleButton,
  InvisibleButtonProps,
} from "./PrerequisiteButtons.styles";

export interface LoginRequiredButtonProps {
  children: React.ReactNode;
  buttonProps?: Partial<InvisibleButtonProps>;
  message?: string;
}

export const LoginRequiredButton = ({
  children,
  buttonProps = {},
  message = "Please login or sign up",
}: LoginRequiredButtonProps) => {
  const { className: buttonClassName } = buttonProps;
  const user = useAppSelector((state) => state.accountInfo.user);
  const { isOpen, openModal, closeModal } = useModal();

  return (
    <>
      <InvisibleButton
        className={classNames(["login-required", buttonClassName])}
        onClick={openModal}
        $disabled={!user}
        {...buttonProps}
      >
        {children}
      </InvisibleButton>
      {isOpen && (
        <UnauthenticatedModal
          closeModal={closeModal}
          showModal={isOpen}
          message={message}
        />
      )}
    </>
  );
};
