import { Dispatch, MutableRefObject, SetStateAction, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useGetFooterPlayerRef } from "./useGetFooterPlayerRef";
import RegionsPlugin, {
  Region as RegionType,
} from "wavesurfer.js/dist/plugins/regions";
import { setIsLooping } from "../../store/actions/abPlayerStore";

/**
 * @description
 * Create a loop region using the ref plugin when isLooping is toggled to active on the track table.
 */
export const useCreateLoopRegions = (
  regionsPluginRef: MutableRefObject<RegionsPlugin | null>,
  notAllowed: boolean,
  setSelectedCommentRegion: Dispatch<SetStateAction<RegionType | undefined>>,
  selectedRegion?: RegionType,
) => {
  const { loopStartTime, loopEndTime, isLooping, loopRegionId } =
    useAppSelector((state) => state.abPlayerStore);
  const footerPlayerRef = useGetFooterPlayerRef();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (notAllowed || !footerPlayerRef) return;
    if (!regionsPluginRef.current) return;
    if (!isLooping) return;
    if (!loopStartTime || !loopEndTime) return;
    if (selectedRegion) {
      dispatch(
        setIsLooping({
          isLooping: true,
          loopStartTime: selectedRegion.start,
          loopEndTime: selectedRegion.end,
          loopRegionId: selectedRegion.id,
        }),
      );
      return;
    }

    // Check if the region already exists.
    // This is for the use case when a region was already created via comment and toggling between A & B.
    // It will track the region and set the looping start and end times.
    const existingRegion = regionsPluginRef.current
      .getRegions()
      .find((region) => region.id === loopRegionId);
    if (existingRegion) {
      dispatch(
        setIsLooping({
          isLooping: true,
          loopStartTime: existingRegion.start,
          loopEndTime: existingRegion.end,
          loopRegionId: existingRegion.id,
        }),
      );
      setSelectedCommentRegion(existingRegion);
      return;
    }
    regionsPluginRef.current.addRegion({
      start: loopStartTime,
      end: loopEndTime,
    });
  }, [isLooping, notAllowed]);
};
