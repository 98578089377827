import { styled } from "styled-components";
import { Content } from "@radix-ui/react-dropdown-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledDropdownSelectionButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.midgroundColor};
  padding: 8px;
  border-radius: var(--border-radius-md);
  min-height: 26px;
  border: unset;
  gap: 4px;
`;

export const StyledDropdownP = styled.p<{
  selected: boolean;
}>`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-weight: ${({ theme, selected }) =>
    selected ? theme.textWeightSemibold : theme.textWeightNormal};
  font-size: ${({ theme }) => theme.textSizeSm};
  text-wrap: wrap;
  word-break: break-word;
  word-wrap: break-word;
  text-transform: capitalize;
`;

export const StyledDropDownContainer = styled(Content).attrs({
  align: "start",
  sideOffset: 4,
})`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.midgroundColor};
  gap: 4px;
  min-width: 125px;
  border-radius: var(--border-radius-md);
  position: absolute;
  z-index: 10000;
`;

export const StyledDropdownFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.textPrimaryColor};
`;
