import { useEffect, useState } from "react";
import User from "../store/models/user";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  PlatformActivityResponse,
  getPaginatedPlatformActivities,
} from "../store/actions/adminDashboardStore";

export const useGetPlatformActivity = (
  teamId?: number | undefined,
  pageSize?: number,
) => {
  const [paginatedPlatformActivityData, setPaginatedPlatformActivityData] =
    useState<PlatformActivityResponse>({
      data: [],
      num_pages: 1,
      count: 1,
    });
  const [page, setPage] = useState<number>(1);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const loggedInUser: undefined | User = useAppSelector(
    (state) => state.accountInfo.user,
  );

  useEffect(() => {
    if (!loggedInUser) {
      return;
    }

    const fetchPlatformActivity = async () => {
      setIsLoading(true);
      const response: PlatformActivityResponse = await dispatch(
        getPaginatedPlatformActivities({
          page: page,
          page_size: pageSize,
          team_id: teamId,
        }),
      ).unwrap();

      if (response) {
        setPaginatedPlatformActivityData({
          ...paginatedPlatformActivityData,
          data: response.data,
          num_pages: response.num_pages,
          count: response.count,
        });
      }

      return () => {
        setPaginatedPlatformActivityData({
          data: [],
          num_pages: 1,
          count: 1,
        });
      };
    };

    fetchPlatformActivity().finally(() => {
      setIsLoading(false);
    });
  }, [dispatch, loggedInUser, teamId, page]);

  return {
    paginatedPlatformActivityData,
    isLoading,
    page,
    setPage,
  };
};
