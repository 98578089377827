import { useAtomValue } from "jotai";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import leftArrowButton from "../../assets/left-arrow-button.svg";
import "./PrevButton.css";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
export interface PrevButtonProps {
  onClickPreviousStep: () => void;
  hideText?: boolean;
}

export const PrevButton = ({
  onClickPreviousStep,
  hideText = false,
}: PrevButtonProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();
  const darkMode = useAtomValue(darkModeAtom);

  return (
    <div
      className={"d-flex align-items-center left-arrow-button".concat(
        darkMode ? " dark-color" : "",
      )}
      onClick={onClickPreviousStep}
    >
      <img src={leftArrowButton} alt={"left arrow"} height={35} width={35} />
      <label>{!isDesktop || hideText ? "" : "PREVIOUS STEP"}</label>
    </div>
  );
};
