import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import { SCREENS } from "../../../../routes";
import User from "../../../../store/models/user";
import logo from "../../../assets/header-logo.svg";
import whiteLogo from "../../../assets/header-white.svg";
import { SIDE_PANEL_ANCHOR, SidePanel } from "../../SidePanel/SidePanel";
import { NavProfileButton } from "../NavProfileButton/NavProfileButton";
import { NavProfileContent } from "../NavProfileContent/NavProfileContent";
import "./NavProfileSidePanel.css";
import { NavLoginContainer } from "../NavLoginContainer/NavLoginContainer";
import styled from "styled-components";

const StyledDiv = styled.div`
  position: absolute;
  left: 25px;
`;

export interface NavProfileSidePanelProps {
  darkMode: boolean | string | null;
  user: User | undefined;
}

export const NavProfileSidePanel = ({
  darkMode,
  user,
}: NavProfileSidePanelProps) => {
  const [showSidePanel, setShowSidePanel] = useState(false);

  const handleClose = () => setShowSidePanel(false);
  return (
    <StyledDiv>
      <button
        className="nav-profile-button-mobile"
        onClick={() => {
          setShowSidePanel((currentValue) => !currentValue);
        }}
      >
        <FontAwesomeIcon
          icon={faBars}
          color="var(--text-primary-color)"
          size="lg"
        />
      </button>
      <SidePanel
        anchor={SIDE_PANEL_ANCHOR.LEFT}
        isOpen={showSidePanel}
        onClose={handleClose}
      >
        <div className="nav-profile-side-bar-container">
          <div>
            {user ? (
              <NavProfileButton
                onClick={handleClose}
                isMobile={true}
                expanded
              />
            ) : (
              <Link to={SCREENS.SIGNED_OUT_HOME_SCREEN} onClick={handleClose}>
                <img
                  className="nav-profile-side-panel-logo"
                  alt="engineears"
                  src={darkMode ? whiteLogo : logo}
                />
              </Link>
            )}
            <NavProfileContent user={user} onClose={handleClose} />
          </div>
          {!user && <NavLoginContainer column onClick={handleClose} />}
        </div>
      </SidePanel>
    </StyledDiv>
  );
};
