import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { fetchPaginatedScheduledProjects } from "../../store/actions/paginatedScheduledProjects";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface FetchPaginatedScheduledProjectParams {
  enabled?: boolean;
  queryKey?: string[];
}

/**
 * Used to fetch scheduled projects on the ScheduledProjects Table and Admin Dashboard
 */
export const useFetchPaginatedScheduledProject = ({
  enabled = true,
  queryKey = [],
}: FetchPaginatedScheduledProjectParams) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_PAGINATED_SCHEDULED_PROJECTS, ...queryKey],
    queryFn: async () => {
      return dispatch(fetchPaginatedScheduledProjects()).unwrap();
    },
    enabled: isAuthenticated && enabled,
  });
};
