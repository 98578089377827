import { Box, styled, TextField } from "@mui/material";

export const FieldInput = styled(TextField)`
  border-radius: 8px;

  & .MuiOutlinedInput-root {
    & fieldset {
      border-radius: 8px;
    }
  }
`;

export const ForgotPasswordContainer = styled(Box)`
  width: 100%;
  cursor: pointer;
  transition: all 400ms linear;

  &:hover {
    transform: scale(0.98);
  }
`;
