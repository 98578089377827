import React, { ReactElement } from "react";
import { checkIsMobile } from "../../../store/utils/utils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { ColorPalette } from "../../theme";
import { Card } from "../Card/Card";
import { SoundWaveLoader } from "../SoundWaveLoader/SoundWaveLoader";
import "./DashboardNumberCard.css";

export interface DashboardNumberCardProps {
  titleToShow: string;
  buttonText: string;
  numberToDisplay: number;
  numberColor?: DashboardNumberColor | ColorPalette | string;
  onClick?: () => void;
  isLoadingData?: boolean;
}

export enum DashboardNumberColor {
  DEFAULT = "var(--dashboard-number-card--default)",
  PENDING = "var(--dashboard-number-card--pending-projects)",
  COMPLETED = "var(--dashboard-number-card--completed-projects)",
}

export const DashboardNumberCard = ({
  titleToShow,
  buttonText,
  numberToDisplay = 0,
  numberColor,
  onClick,
  isLoadingData = false,
}: DashboardNumberCardProps): ReactElement => {
  const color = numberColor ? numberColor : DashboardNumberColor.DEFAULT;
  return (
    <Card customClassName={"dashboard-number-card"}>
      <div onClick={() => (onClick && checkIsMobile() ? onClick() : "")}>
        <React.Fragment>
          {titleToShow.split(" ").map((val, index) => {
            return (
              <p key={index} className="b1-semi-bold dashboard-number-text">
                {val}
              </p>
            );
          })}
        </React.Fragment>

        <h2
          className={"h2 h2-semi-bold dashboard-number ".concat(
            isLoadingData ? "" : "animating-fade-in-number",
          )}
          style={{ color: color }}
        >
          {isLoadingData ? (
            <SoundWaveLoader width={100} height={58} />
          ) : (
            numberToDisplay
          )}
        </h2>
        <Button
          fullWidth
          className="view-projects-button"
          onClick={() => (onClick ? onClick() : "")}
          variant={ButtonVariant.OUTLINED}
        >
          {buttonText}
        </Button>
      </div>
    </Card>
  );
};
