import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";

export const getTrackComments = (page: number, projectId?: number) =>
  createSelector(
    (state: RootState) => state.fileVersionCommentsSlice,
    (fileVersionCommentsSlice) => {
      if (projectId === undefined) {
        return [];
      }
      return fileVersionCommentsSlice[projectId]?.[page] || [];
    },
  );
