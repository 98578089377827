import { atomWithStorage } from "jotai/utils";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";
import { atom } from "jotai";

const streamTokenAtom = atomWithStorage(LOCAL_STORAGE_KEYS.STREAM_TOKEN, "");
streamTokenAtom.debugLabel = "Stream Token";

const connectionEstablishedAtom = atom(false);
connectionEstablishedAtom.debugLabel = "Stream Connection Established";

const channelUnreadMessagesCountAtom = atom({} as Record<string, number>);

export {
  connectionEstablishedAtom,
  streamTokenAtom,
  channelUnreadMessagesCountAtom,
};
