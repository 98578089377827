import classNames from "classnames";
import { ReactNode } from "react";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  TEXT_COLOR,
  TEXT_WEIGHT,
  Text,
} from "../../core-ui/components/Text/Text";
import "./GenerateBookingSelectService.css";

export enum GenerateBookingContainerVariant {
  MIX_MASTER = "mix-master",
  RECORDING = "recording",
}

export interface GenerateBookingSelectServiceContainerProps {
  children: ReactNode;
  index: number;
  isSelected?: boolean;
  onClick?: () => void;
  variant: GenerateBookingContainerVariant;
  showIndexCounter?: boolean;
}

const GenerateBookingSelectServiceContainer = ({
  children,
  index,
  isSelected,
  onClick,
  variant,
  showIndexCounter = true,
}: GenerateBookingSelectServiceContainerProps) => {
  const cssPrefix = "generate-booking-select-service_";
  return (
    <Button
      className={classNames({
        [`${cssPrefix}container`]: true,
        [`${cssPrefix}container--selected`]: Boolean(isSelected),
      })}
      variant={ButtonVariant.UNSTYLED}
      onClick={onClick}
      type="button"
    >
      {/* TODO: Can remove this prop once we have full add to cart for mix/master */}
      {showIndexCounter && (
        <div
          className={classNames(
            `${cssPrefix}index`,
            `${cssPrefix}index-${variant}`,
          )}
        >
          <Text
            className={`${cssPrefix}index-number`}
            color={TEXT_COLOR.SECONDARY}
            weight={TEXT_WEIGHT.BOLD}
          >
            {index}
          </Text>
        </div>
      )}
      <div className={`${cssPrefix}children`}>{children}</div>
    </Button>
  );
};

export default GenerateBookingSelectServiceContainer;
