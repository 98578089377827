import { format } from "date-fns";
import { ReactNode, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SessionModificationDetails } from "../../../hooks/recordingSessionHooks/useGetSessionModificationDetails";
import { useIsStudioBooking } from "../../../hooks/recordingSessionHooks/useIsStudioBooking";
import {
  Location,
  useGetLatLongFromRecordingLocation,
} from "../../../hooks/useMapHooks";
import { useUploadedFileSinceLastTransition } from "../../../hooks/useProjectFilesUploaded";
import { useGetRecordingSessionTimeData } from "../../../hooks/useRecordingSessionsHooks";
import { useUserProfileImageURL } from "../../../hooks/useUserProfileImage";
import { downloadFinalFiles } from "../../../store/actions/projects";
import { engineerRecordingSessionTransition } from "../../../store/actions/recording";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType, ProjectUserType } from "../../../store/models/project";
import {
  RecordingSession,
  RecordingSessionStep,
  SessionWorkflowSteps,
} from "../../../store/models/recordingSession";
import User from "../../../store/models/user";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import {
  checkIfSessionIsOver,
  getSessionStudioArrivalInformation,
  getSessionStudioUnitNumber,
} from "../../../store/utils/recordingUtils";
import {
  getProfileScreenRoute,
  getStudioRoomScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import { getCollaboratorsFromRecordingSession } from "../../../utils/recordingUtils";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";
import {
  Text,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import { GMapElement } from "../../elements/GMapElement/GMapElement";
import { Divider } from "../BookingRequestedPanel/BookingDetails.styles";
import { FileUploader } from "../FileUploader/FileUploader";
import { MainProjectWorkflowButtonRow } from "../MainProjectWorkflowPanel/MainProjectWorkflowButtonRow";
import { ProjectReview } from "../ProjectReview/ProjectReview";
import {
  StudioName,
  StudioRoomName,
} from "../SessionRequestedPanel/SessionRequestedPanel.styles";
import {
  ActionButtonsContainer,
  AddressAnchor,
  CollaboratorPillContainer,
  CollaboratorPills,
  ProfileImage,
  SessionOverviewGrid,
  SessionOverviewGridItem,
  StyledSessionDetailsPanelBody,
} from "./SessionDetailsPanel.styles";
import { SessionModificationPopover } from "./SessionModificationPopover";

interface SessionDetailsPanelBodyProps {
  recordingSession: RecordingSession;
  workflowStep?: SessionWorkflowSteps;
  sessionModificationDetails: SessionModificationDetails;
  currentUser?: User;
  currentUserType?: ProjectUserType | null;
  isAccessedViaShareLink: boolean;
  isArtist: boolean;
  isEngineer: boolean;
  isManager: boolean;
}

export const SessionDetailsPanelBody = ({
  recordingSession,
  workflowStep,
  sessionModificationDetails,
  currentUser,
  currentUserType,
  isAccessedViaShareLink,
  isArtist,
  isEngineer,
  isManager,
}: SessionDetailsPanelBodyProps) => {
  const dispatch = useAppDispatch();
  const isTransitioning = useAppSelector(
    (state) => state.recordingSessionReducer.updating,
  );
  const [downloading, setDownloading] = useState(false);
  const collaborators = getCollaboratorsFromRecordingSession(
    recordingSession,
    currentUser,
  );
  const { sessionDurationHours, recordingDate, endTime, startTime } =
    useGetRecordingSessionTimeData(recordingSession);
  const location: Location | undefined = useGetLatLongFromRecordingLocation(
    recordingSession.recording_location,
  );

  const recordingSessionTotal =
    // @ts-expect-error: TS type is `number`, but what we get from the API is string
    parseInt(recordingSession.engineer_payment_amount_split) +
    // @ts-expect-error: TS type is `number`, but what we get from the API is string
    parseInt(recordingSession.studio_manager_payment_amount_split);

  const recordingSessionRateText = PennyDollarFormatter().format(
    recordingSessionTotal / sessionDurationHours,
  );
  const recordingSessionTotalText = PennyDollarFormatter().format(
    recordingSessionTotal,
  );

  const uploadedFiles = useUploadedFileSinceLastTransition(
    recordingSession.project,
  );
  const isAtFilesUploadedStep =
    recordingSession.step === RecordingSessionStep.SESSION_FILES_UPLOADED;
  const isAtSessionUpcomingWorkflowStep =
    workflowStep === SessionWorkflowSteps.SESSION_UPCOMING;
  const shouldShowExactLocation = Boolean(
    recordingSession.recording_location.formatted,
  );
  const isStudioSession = useIsStudioBooking(recordingSession);
  const isCancelledSession = Boolean(recordingSession.refunded);
  const shouldShowFileUploader =
    !isAtFilesUploadedStep &&
    (isEngineer || isManager) &&
    !isAtSessionUpcomingWorkflowStep &&
    !isAccessedViaShareLink &&
    !isCancelledSession;
  const sessionHasPendingRescheduledRequest =
    Boolean(sessionModificationDetails.sessionRescheduledRequest) &&
    sessionModificationDetails.sessionHasPendingModificationRequest;
  const [isSessionNotOver, setIsSessionNotOver] = useState(
    !checkIfSessionIsOver({
      first_choice_datetime: recordingSession.first_choice_datetime,
      session_duration_minutes: recordingSession.session_duration_minutes,
    }),
  );
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (
      !checkIfSessionIsOver({
        first_choice_datetime: recordingSession.first_choice_datetime,
        session_duration_minutes: recordingSession.session_duration_minutes,
      })
    ) {
      interval = setInterval(() => {
        if (
          checkIfSessionIsOver({
            first_choice_datetime: recordingSession.first_choice_datetime,
            session_duration_minutes: recordingSession.session_duration_minutes,
          })
        ) {
          setIsSessionNotOver(false);
          clearInterval(interval);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [
    recordingSession.first_choice_datetime,
    recordingSession.session_duration_minutes,
  ]);

  const transferFileTransition = () => {
    void dispatch(
      engineerRecordingSessionTransition({
        project_id: recordingSession.project.id,
        transition: "eng_upload_files",
      }),
    )
      .unwrap()
      .then(() => {
        toast.success("Successfully transitioned the recording session");
      })
      .catch(() => {}); // Empty catch to avoid crashing the app on request error
  };

  const handleDownload = () => {
    toast.info(
      "Your download request has been initiated. Please wait a moment",
    );
    setDownloading(true);
    void dispatch(
      downloadFinalFiles({
        project_id: recordingSession.project.id.toString(),
        get_zip_only: true,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success(
          "Successfully downloaded all files associated with this recording session",
        );
      })
      .catch(() => {})
      .finally(() => {
        setDownloading(false);
      });
  };

  const renderRateFieldValue = () => {
    let rateFieldValue = `${sessionDurationHours} hours · `;
    if (currentUserType === ProjectUserType.ARTIST) {
      rateFieldValue += `${recordingSessionRateText}/hr`;
    } else {
      rateFieldValue += recordingSessionTotalText;
    }

    return rateFieldValue;
  };

  const renderFilesUploadSection = () => {
    if (!shouldShowFileUploader) {
      return null;
    }

    return (
      <>
        <FileUploader
          project={recordingSession.project}
          projectStep={recordingSession.step}
          isCurrentProjectEngineer={true}
          recordingSessionBookingId={
            recordingSession.recording_session_request_id
          }
        />
        <Divider />
        <Text style={{ textAlign: "center" }}>
          Once you&apos;re finished, click on the transfer files button.
        </Text>
      </>
    );
  };

  const renderActionButtons = () => {
    let PrimaryActionButton = null;

    if (isAtFilesUploadedStep) {
      PrimaryActionButton = (
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={handleDownload}
          loading={downloading}
        >
          Download
        </Button>
      );
    }

    if (shouldShowFileUploader) {
      PrimaryActionButton = (
        <PopConfirm
          side={"top"}
          title={"Are you sure you want to transfer the files?"}
          description={
            "Once you confirm, the files will be transferred to artist."
          }
          onConfirm={transferFileTransition}
          wrapperElement={containerElement}
        >
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={uploadedFiles.length === 0 || isTransitioning}
            disableText="Please upload a file before proceeding"
            loading={isTransitioning}
          >
            Transfer Files
          </Button>
        </PopConfirm>
      );
    }

    if (isCancelledSession) {
      if (!isStudioSession) {
        const engineerUser = collaborators?.find(
          (collaborator) => collaborator?.engineer?.id === 2,
        );

        if (!engineerUser) {
          return null;
        }

        PrimaryActionButton = (
          <Button
            variant={ButtonVariant.PRIMARY}
            href={getProfileScreenRoute(engineerUser.username)}
          >
            Rebook with this engineer
          </Button>
        );
      } else {
        if (!recordingSession?.studio_room?.studio?.username) {
          return null;
        }

        PrimaryActionButton = (
          <Button
            variant={ButtonVariant.PRIMARY}
            href={getStudioScreenRoute(
              recordingSession.studio_room.studio.username,
            )}
          >
            Rebook with this studio
          </Button>
        );
      }
    }

    const allowSessionModification =
      currentUser &&
      isSessionNotOver &&
      !isAtFilesUploadedStep &&
      !isAccessedViaShareLink &&
      !isCancelledSession;

    if (!PrimaryActionButton && !allowSessionModification) {
      return null;
    }

    return (
      <MainProjectWorkflowButtonRow>
        <ActionButtonsContainer>
          {allowSessionModification && (
            <SessionModificationPopover
              recordingSession={recordingSession}
              currentUser={currentUser}
              isArtist={isArtist}
              sessionModificationDetails={sessionModificationDetails}
            />
          )}

          {PrimaryActionButton}
        </ActionButtonsContainer>
      </MainProjectWorkflowButtonRow>
    );
  };

  const renderReviewModule = () => {
    if (
      (recordingSession.completed ||
        workflowStep === SessionWorkflowSteps.SESSION_COMPLETE) &&
      currentUser &&
      (recordingSession.artist.user_id === currentUser.id ||
        recordingSession.engineer?.user_id === currentUser.id)
    ) {
      return (
        <ProjectReview
          projectId={recordingSession.project.id}
          project_type={ProjectType.RECORDING}
          fullComponentLoading={false}
        />
      );
    }

    return null;
  };

  const renderLocationSection = () => {
    return (
      <>
        <GMapElement
          latitude={location?.lat}
          longitude={location?.lng}
          revealLocation={Boolean(
            recordingSession.recording_location.formatted,
          )}
        />
        <div style={{ marginBottom: "4px" }}></div>
      </>
    );
  };

  return (
    <StyledSessionDetailsPanelBody>
      {collaborators && collaborators.length > 0 ? (
        <div>
          <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Collaborators</Text>
          <CollaboratorPills>
            {collaborators.map((collaborator) => {
              return (
                <CollatoratorPill
                  collaborator={collaborator}
                  key={collaborator.id}
                />
              );
            })}
          </CollaboratorPills>
        </div>
      ) : (
        <Text>No collaborators on this session</Text>
      )}
      <div>
        <Text size={TEXT_SIZE.LARGE} weight={TEXT_WEIGHT.SEMI_BOLD}>
          Session overview:
        </Text>
        <SessionOverviewGrid>
          {recordingSession.studio_room && (
            <SessionOverviewGridItem>
              <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Location</Text>
              <div>
                <StudioRoomName
                  to={getStudioRoomScreenRoute(
                    recordingSession.studio_room.studio?.username,
                    recordingSession.studio_room.id,
                  )}
                >
                  {recordingSession.studio_room.room_name || "N/A"}
                </StudioRoomName>{" "}
                at{" "}
                <StudioName
                  to={getStudioScreenRoute(
                    recordingSession.studio_room.studio?.username,
                  )}
                >
                  {recordingSession.studio_room.studio?.studio_profile
                    ?.display_name || "N/A"}
                </StudioName>
              </div>
            </SessionOverviewGridItem>
          )}
          <SessionOverviewField
            label={
              "Date" +
              (sessionHasPendingRescheduledRequest
                ? " (Proposed Reschedule)"
                : "")
            }
            value={`${format(recordingDate, "EEEE, MMMM dd, y")}`}
          />
          <SessionOverviewField
            label={
              currentUserType === ProjectUserType.ARTIST ? "Rate" : "Total"
            }
            value={renderRateFieldValue()}
          />
          <SessionOverviewField
            label={
              "Time" +
              (sessionHasPendingRescheduledRequest
                ? " (Proposed Reschedule)"
                : "")
            }
            value={`${startTime} - ${endTime}`}
          />
          <SessionOverviewAddressField
            label="Address"
            value={
              recordingSession.recording_location.formatted ||
              recordingSession.recording_location.city_location ||
              "N/A"
            }
            coordinates={{
              lat: recordingSession.recording_location.latitude,
              lng: recordingSession.recording_location.longitude,
            }}
          />
          <SessionOverviewField
            label="Unit Number"
            value={
              shouldShowExactLocation
                ? getSessionStudioUnitNumber(recordingSession)
                : "N/A"
            }
          />
          <SessionOverviewField
            label="Arrival Information"
            value={
              shouldShowExactLocation
                ? getSessionStudioArrivalInformation(recordingSession)
                : "Parking, transit and access information will be revealed after the session is confirmed."
            }
          />
        </SessionOverviewGrid>
      </div>
      <Divider />
      {renderFilesUploadSection()}
      {renderReviewModule()}
      {renderLocationSection()}
      {renderActionButtons()}
    </StyledSessionDetailsPanelBody>
  );
};

const SessionOverviewField = ({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) => {
  return (
    <SessionOverviewGridItem>
      <Text weight={TEXT_WEIGHT.SEMI_BOLD}>{label}</Text>
      {typeof value !== "string" ? value : <Text>{value}</Text>}
    </SessionOverviewGridItem>
  );
};

const SessionOverviewAddressField = ({
  label,
  value,
  coordinates,
}: {
  label: ReactNode;
  value: ReactNode;
  coordinates?: { lat: number | undefined; lng: number | undefined };
}) => {
  let mapsUrl = "#";
  if (coordinates) {
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      mapsUrl = `https://maps.apple.com/?q=${coordinates.lat},${coordinates.lng}`;
    } else {
      mapsUrl = `https://maps.google.com?q=${coordinates.lat},${coordinates.lng}`;
    }
  }

  return (
    <SessionOverviewGridItem>
      <Text weight={TEXT_WEIGHT.SEMI_BOLD}>{label}</Text>
      <AddressAnchor href={mapsUrl} target="_blank" rel="noreferrer">
        <Text>{value}</Text>
      </AddressAnchor>
    </SessionOverviewGridItem>
  );
};

const CollatoratorPill = ({ collaborator }: { collaborator: User }) => {
  const imageURL = useUserProfileImageURL(collaborator);

  return (
    <CollaboratorPillContainer
      to={getProfileScreenRoute(collaborator.username)}
    >
      <ProfileImage
        src={imageURL}
        alt={collaborator.username}
        width={24}
        height={24}
      />
      <Text weight={TEXT_WEIGHT.SEMI_BOLD}>
        {getDisplayableNameForUser(collaborator)}
      </Text>
    </CollaboratorPillContainer>
  );
};
