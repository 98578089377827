import styled from "styled-components";

// Acts a ternary - true (online) | false (offline) | undefined (never signed up)
export const OnlineIconDiv = styled.div<{ $isOnline?: boolean }>`
  background-color: ${({ theme, $isOnline }) =>
    $isOnline
      ? theme.colorPalette.DeepGreen
      : typeof $isOnline === "undefined"
        ? theme.colorPalette.White
        : theme.colorPalette.Red700};
`;
