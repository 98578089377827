import { useEffect, useMemo, useState } from "react";
import { useUserProfileImageURL } from "../../../hooks/useUserProfileImage";
import { fetchAvailableRecordingEngineers } from "../../../store/actions/engineerRecommendation";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Engineer from "../../../store/models/engineer";
import { StudioRoom } from "../../../store/models/studio";
import User from "../../../store/models/user";
import { getFormattedDateTime } from "../../../store/utils/dateTimeUtils";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { DollarFormatter } from "../../../store/utils/formatUtils";
import { getValidDiscountRate } from "../../../store/utils/recordingUtils";
import defaultUser from "../../../stories/assets/defaultuser.jpg";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import "./SelectAvailableTrackingEngineer.css";

interface SelectAvailableTrackingEngineerProps {
  datetime: Date;
  duration?: number;
  selectEngineer: (datetime: Date, engineer: User | null) => void;
  studioRoom?: StudioRoom;
  engineerOptional: boolean;
}

export interface SelectEngineerOption extends OptionType {
  serviceRate?: number;
  user: User | null;
  studioAffiliated: boolean;
  engineer?: Engineer;
}

export const SelectEngineerOptionComponent = (props: SelectEngineerOption) => {
  const imagePath = useUserProfileImageURL(props.user ?? undefined);
  const [loadFailedImage, setLoadFailedImage] = useState<boolean>(false);
  const label = (
    <div className="select-tracking-engineer-option">
      <div className="select-tracking-engineer-option-left">
        {props.user && (
          <img
            src={loadFailedImage ? defaultUser : imagePath}
            alt={props.label}
            onError={() => {
              setLoadFailedImage(true);
            }}
          />
        )}
        <span style={{ paddingLeft: "15px", paddingRight: "8px" }}>
          {props.label}
        </span>
      </div>
      {props?.serviceRate && (
        <span>{`${DollarFormatter().format(props.serviceRate)}/hr`}</span>
      )}
    </div>
  );

  return (
    <div>
      <label>{label}</label>
    </div>
  );
};

export const SelectAvailableTrackingEngineer = ({
  datetime,
  duration,
  selectEngineer,
  studioRoom,
  engineerOptional,
}: SelectAvailableTrackingEngineerProps) => {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<boolean>(false);

  const { availableRecordingEngineers, loading } = useAppSelector(
    (state) => state.engineerRecommendationStore,
  );

  const selectEngineerOptions: SelectEngineerOption[] = useMemo(() => {
    const engineerOptions: SelectEngineerOption[] = [];
    if (loading) return engineerOptions;
    if (engineerOptional) {
      engineerOptions.push({
        value: 0,
        label: "Session without tracking engineer",
        user: null,
        studioAffiliated: false,
      });
    }
    const engineers =
      availableRecordingEngineers[
        `${getFormattedDateTime(datetime)}${duration}`
      ] || [];
    engineers.forEach((service) => {
      const service_rate = service.service_rate;
      const user = service_rate?.user;
      const displayName = getDisplayableNameForUser(user ?? undefined);
      const appliedDiscountRate =
        duration !== undefined
          ? undefined
          : getValidDiscountRate(
              service?.recording_service_discount_rate,
              duration,
            );
      const price = appliedDiscountRate
        ? appliedDiscountRate.service_rate.price
        : service_rate?.price;
      engineerOptions.push({
        value: user!.id,
        label: displayName,
        serviceRate: price ?? 0,
        user: user!,
        studioAffiliated: service.studioAffiliated,
      });
    });
    return engineerOptions;
  }, [
    availableRecordingEngineers,
    engineerOptional,
    loading,
    datetime,
    duration,
  ]);

  useEffect(() => {
    if (!duration) {
      return;
    }
    if (!studioRoom) {
      return;
    }
    dispatch(
      fetchAvailableRecordingEngineers({
        studio_room_id: studioRoom.id,
        start_datetime: getFormattedDateTime(datetime),
        session_duration_minutes: duration,
      }),
    );
  }, [datetime, duration, studioRoom, dispatch]);

  return (
    <div>
      <DropdownSelector
        options={selectEngineerOptions}
        isLoading={!selected && loading}
        placeholder="select a recording engineer"
        onChange={(option) => {
          const selectEngineerOptions = option as SelectEngineerOption;
          setSelected(true);
          selectEngineer(datetime, selectEngineerOptions.user);
        }}
        customComponents={(option) => {
          return (
            <SelectEngineerOptionComponent
              {...(option as SelectEngineerOption)}
            />
          );
        }}
      />
    </div>
  );
};
