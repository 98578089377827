import AccountTypeBase from "./base";

export default interface StudioManager extends AccountTypeBase {
  can_manage_studios?: string;
}

export const MockStudioManager: StudioManager = {
  id: 35,
  user_id: 60,
  created: "2021-11-16T22:28:38.351243",
  deleted: null,
  is_primary_type: null,
  can_manage_studios: "2021-11-16T22:28:38.351243",
};
