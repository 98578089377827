import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactElement, useMemo } from "react";
import { Calendar, CalendarTileProperties } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { EXPANDED_DAYS_TO_DISPLAY } from "../../../store/models/scheduledproject";
import { useManagerCanEditStudio } from "../../../store/selectors/teamSelectors";
import {
  getCurrentDate,
  getNDaysFromNow,
} from "../../../store/utils/dateTimeUtils";
import { getStartTimesForContiguousAvailableBlocks } from "../../../store/utils/recordingUtils";
import { ColorPalette } from "../../theme";
import "./BookingCalendar.css";
import { useSessionsDateMap } from "../../../hooks/recordingSessionHooks/useSessionsDateMap";

export interface BookingCalendarProps {
  selectedDays: Date[];
  availabilities: Map<string, string>;
  durationMinutes: number;
  disabled?: boolean;
  clickedDay?: Date | null;
  overWriteGetTileClass?: (date: Date) => string;
  overWriteGetDisabledTiles?: (props: CalendarTileProperties) => boolean;
  studioId?: number;
  onClickDate?: (date: Date) => void;
}

export const BookingCalendar: FC<BookingCalendarProps> = ({
  selectedDays,
  availabilities,
  durationMinutes,
  clickedDay,
  disabled = false,
  overWriteGetTileClass,
  overWriteGetDisabledTiles,
  studioId,
  onClickDate,
}): ReactElement => {
  const canEditStudioService = useManagerCanEditStudio(studioId);
  const isDateSelected = (date: Date) =>
    selectedDays.some(
      (day) =>
        day.getDate() === date.getDate() && day.getMonth() === date.getMonth(),
    );

  const getTileClass = ({ view, date }: CalendarTileProperties) => {
    if (overWriteGetTileClass) return overWriteGetTileClass(date);
    if (
      clickedDay &&
      date.getTime() === clickedDay.getTime() &&
      !isDateSelected(clickedDay)
    ) {
      return isDateSelected(date)
        ? "deselect-now-tile-calendar"
        : "deselect-tile-calendar";
    }
    return view === "month" && isDateSelected(date)
      ? "selected-tiles-calendar"
      : "";
  };

  const { startDate, endDate } = useMemo(
    () => ({
      startDate: getCurrentDate(),
      endDate: getNDaysFromNow(EXPANDED_DAYS_TO_DISPLAY),
    }),
    [],
  );

  const recordingSessions = useSessionsDateMap({ startDate, endDate });

  const getDisabledTiles = (calendarProps: CalendarTileProperties) => {
    if (disabled) return true;
    if (overWriteGetDisabledTiles) {
      return overWriteGetDisabledTiles(calendarProps);
    }
    const { date, view } = calendarProps;
    const currentDateAvailability = getStartTimesForContiguousAvailableBlocks(
      date,
      availabilities,
      durationMinutes,
    );
    if (view === "month" && date.getTime() < getCurrentDate().getTime()) {
      return true;
    } else return currentDateAvailability.length === 0;
  };

  const getTileContent = ({ date }: CalendarTileProperties) => {
    if (!canEditStudioService) {
      return null;
    }
    if (isDateSelected(date)) {
      return null;
    }
    if (recordingSessions.get(date.toISOString())) {
      const sessions = recordingSessions.get(date.toISOString());
      if (!sessions) {
        return null;
      }
      let sessionDots = sessions.map((_, index) => (
        <div
          key={index}
          style={{
            backgroundColor: ColorPalette.CrushOrange,
            width: 5,
            height: 5,
            borderRadius: 2.5,
            marginLeft: 1.5,
            marginRight: 1.5,
          }}
        ></div>
      ));

      if (sessionDots.length > 3) {
        sessionDots = sessionDots.slice(0, 3);
      }

      return (
        <div
          style={{
            marginTop: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            flex: 5,
          }}
        >
          {sessionDots}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="booking-calendar-container">
      <Calendar
        prevLabel={<FontAwesomeIcon icon={faChevronLeft} size="1x" />}
        nextLabel={<FontAwesomeIcon icon={faChevronRight} size="1x" />}
        tileClassName={getTileClass}
        onClickDay={onClickDate}
        tileDisabled={getDisabledTiles}
        tileContent={getTileContent}
        view="month"
      />
    </div>
  );
};
