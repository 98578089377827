import { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  AvailableSessionEngineersPayload,
  fetchAvailableRecordingEngineers,
} from "../../../store/actions/engineerRecommendation";
import {
  getRecordingServiceAvailability,
  GetRecordingServiceAvailabilityResponse,
} from "../../../store/actions/recording";
import { LoadEngineeringServicesResponse } from "../../../store/actions/services";
import { fetchStudioRooms } from "../../../store/actions/studio";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RecordingService } from "../../../store/models/recording";
import { StudioRoom } from "../../../store/models/studio";
import { WorkingHours } from "../../../store/models/workingHours";
import { getCurrentDate } from "../../../store/utils/dateTimeUtils";
import { GenerateBookingSessionDetail } from "../../components/GenerateBooking/GenerateBookingSessionDetail/GenerateBookingSessionDetail";
import { Text, TEXT_SIZE } from "../../core-ui/components/Text/Text";
import TransactionBookingDetailsHeader from "./TransactionBookingDetailsHeader";
import { GenerateBookingSessionPlaceholder } from "./TransactionBookingScreen.styles";

const TransactionBookingRecordingDetails = ({
  recordingService,
  studioRoom,
}: {
  recordingService: RecordingService | null;
  studioRoom: StudioRoom | undefined;
}) => {
  const { engineerUserId, engineerId, activeStudioRoomId, activeStudioId } =
    useAppSelector((state) => state.generateBookingStore);
  const { recordingService: engineerRecordingService } = useAppSelector(
    (state) => state.engineerServices,
  );
  const [isLoading, setIsLoading] = useState(false);

  const pendingSessionData = useAppSelector(
    (state) => state.shoppingCart.pendingSessionData ?? [],
  );
  const dispatch = useAppDispatch();
  const getRecordingServicesAndAvailabilities = useCallback(
    async (
      studioId: number | undefined,
      studioRoomId: number | undefined,
      engineerUserId: number | undefined,
      engineerRecordingService: RecordingService | undefined,
    ) => {
      const promises: Promise<
        | StudioRoom[]
        | GetRecordingServiceAvailabilityResponse[]
        | WorkingHours[]
        | AvailableSessionEngineersPayload
        | LoadEngineeringServicesResponse
      >[] = [];

      if (studioId) {
        promises.push(
          dispatch(
            fetchStudioRooms({
              studio_id: studioId,
            }),
          ).unwrap(),
        );
      }
      if (studioRoomId) {
        promises.push(
          dispatch(
            getRecordingServiceAvailability({
              timezoneShiftMinutes: new Date().getTimezoneOffset(),
              studioRoomId: studioRoomId,
              startDate: getCurrentDate(),
            }),
          ).unwrap(),
          dispatch(
            fetchAvailableRecordingEngineers({
              studio_room_id: studioRoomId,
            }),
          ).unwrap(),
        );
      }
      if (engineerUserId && engineerId && engineerRecordingService) {
        promises.push(
          dispatch(
            getRecordingServiceAvailability({
              userId: engineerUserId,
              timezoneShiftMinutes: new Date().getTimezoneOffset(),
              startDate: getCurrentDate(),
            }),
          ).unwrap(),
        );
      }

      await Promise.all(promises);
    },
    [dispatch, engineerId],
  );

  useEffect(() => {
    const setUpInitialRecordingSessionDetails = async () => {
      setIsLoading(true);
      await getRecordingServicesAndAvailabilities(
        activeStudioId,
        activeStudioRoomId,
        engineerUserId,
        engineerRecordingService,
      ).catch(() => {
        // Todo: handle this error. It can occur when trying to create a booking without logging in, causing a google error on the backend.
      });
      setIsLoading(false);
    };
    void setUpInitialRecordingSessionDetails();
  }, [
    activeStudioRoomId,
    activeStudioId,
    engineerUserId,
    engineerRecordingService?.id,
    getRecordingServicesAndAvailabilities,
    engineerRecordingService,
  ]);

  const cssPrefix = "transaction-booking-screen_";
  return (
    <div className={`${cssPrefix}select-service-container`}>
      <TransactionBookingDetailsHeader recordingService={recordingService} />
      {isLoading && (
        <Skeleton width="100%" count={1} height={SESSION_PLACEHOLDER_HEIGHT} />
      )}
      {!isLoading &&
        (!pendingSessionData || pendingSessionData.length === 0) && (
          <GenerateBookingSessionPlaceholder
            $height={SESSION_PLACEHOLDER_HEIGHT}
          >
            <Text size={TEXT_SIZE.SMALL}>
              Please click on the &quot;+ Add session&quot; button!
            </Text>
          </GenerateBookingSessionPlaceholder>
        )}
      {!isLoading &&
        pendingSessionData?.map((session, index) => (
          <GenerateBookingSessionDetail
            index={index}
            pendingSession={session}
            key={index}
            recordingService={recordingService}
            studioRoom={studioRoom}
          />
        ))}
    </div>
  );
};

export default TransactionBookingRecordingDetails;

const SESSION_PLACEHOLDER_HEIGHT = 130;
