import React, { useMemo } from "react";
// @ts-ignore
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import "./TimeLineProgressBar.css";
import { ColorPalette } from "../../theme";
import Lottie from "react-lottie";
import alert from "../../lotties/alert.json";
import { useDueDateInterval } from "../../../hooks/useDueDateInterval";
import { getStepDescription, ProjectType } from "../../../store/models/project";

export interface TimeLineProgressBarProps {
  height?: number;
  currentStep: number;
  totalSteps: number;
  barColor?: ColorPalette;
  showSteps?: boolean;
  dueDate?: Date;
  isPastDue?: boolean;
  showCountDown?: boolean;
  pastDueText?: string;
  projectType?: ProjectType;
  taskDue?: string;
}

const options = {
  loop: false,
  autoplay: true,
  duration: 1000,
  animationData: alert,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const TimeLineProgressBar = ({
  height = 15,
  currentStep,
  totalSteps,
  barColor = ColorPalette.BoomyOrange400,
  showSteps = true,
  dueDate,
  isPastDue,
  showCountDown,
  pastDueText = "Past Due",
  projectType,
  taskDue,
}: TimeLineProgressBarProps) => {
  const progress = useMemo(() => {
    return (currentStep / totalSteps) * 100;
  }, [totalSteps, currentStep]);

  return (
    <div style={{ width: "100%" }}>
      <div className="d-flex justify-content-between flex-row">
        {showSteps && (
          <p className="b3 progress-bar-label">{`${currentStep} / ${totalSteps}`}</p>
        )}
        {isPastDue && showCountDown && (
          <div className="past-due-container">
            <Lottie options={options} height={10} width={10} />
            <p className={`b3 progress-bar-label count-down-label`}>
              {pastDueText}
            </p>
          </div>
        )}
      </div>
      <Progress
        theme={{
          engineears: {
            symbol: " ",
            color: barColor,
            trailColor: ColorPalette.TimbreTan,
          },
        }}
        style={{ width: "100%", height }}
        percent={progress}
        status="engineears"
      />
    </div>
  );
};
