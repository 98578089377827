import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { setSubstituteLocation } from "../../../store/actions/recordingCartsStore";
import { LocationInput } from "../../components/LocationInput/LocationInput";
import {
  Container,
  DropdownContainer,
  HeaderText,
  LocationInputContainer,
} from "./TransactionBookingServiceDropdowns.styles";
import TransactionBookingServiceTypeDropdown from "./TransactionBookingServiceTypeDropdown";
import TransactionBookingStudioRoomDropdown from "./TransactionBookingStudioRoomDropdown";
import { selectEligibleServices } from "../../../store/selectors/servicesSelectors";

// Currently only visible if the active service type is RECORDING.
const TransactionBookingServiceDropdowns = () => {
  const { engineerId, activeServiceId } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const { recordingService, studioRecordingServices } = useAppSelector(
    (state) => state.engineerServices,
  );

  const { activeStudioId } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const dispatch = useAppDispatch();
  const studio = useAppSelector(
    (state) => state.studiosSlice[activeStudioId ?? ""],
  );
  const eligibleServices = useAppSelector(selectEligibleServices);

  const recordingServiceStudioRooms = useMemo(() => {
    // If engineerId, show any affiliated studio rooms w/a recording service in dropdown.
    // The child component will append the "Engineer Only option" to this list.
    if (engineerId) {
      return studioRecordingServices.map(
        (recordingService) => recordingService.studio_room!,
      );
    }

    // If direct studio booking, show studio.studio_rooms with a recording service in dropdown
    return (
      studio?.studio_rooms?.filter((room) => Boolean(room.recording_service)) ??
      []
    );
  }, [engineerId, studio, studioRecordingServices]);

  // If a specific engineer is being booked, and the engineer offers more than one service type,
  // show the service selection dropdown.
  const canSwitchServices = engineerId && eligibleServices.length > 1;

  const selectedCustomLocation =
    recordingService !== undefined &&
    activeServiceId === recordingService.id &&
    recordingService.will_come_to_you !== null;

  return (
    <Container>
      <HeaderText>
        {canSwitchServices ? "Select a service:" : "Select a room:"}
      </HeaderText>
      <DropdownContainer>
        {canSwitchServices && (
          <TransactionBookingServiceTypeDropdown
            eligibleServices={eligibleServices}
          />
        )}
        <TransactionBookingStudioRoomDropdown
          engineerRecordingService={recordingService}
          recordingServiceStudioRooms={recordingServiceStudioRooms}
        />
      </DropdownContainer>
      {selectedCustomLocation && (
        <LocationInputContainer>
          <LocationInput
            onPlaceSelected={(location) =>
              dispatch(setSubstituteLocation(location))
            }
            placeholder="Enter a Location"
          />
        </LocationInputContainer>
      )}
    </Container>
  );
};

export default TransactionBookingServiceDropdowns;
