import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { PROJECT_SEARCH } from "../utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { receiveErrors } from "./errorStore";
import { BookingSuggestion } from "../models/autocomplete";

export interface BookingsScreenSearchState {
  searchQuery: string;
  collaboratorFilter: string[];
  studioRoomFilter: string[];
  studioFilter: string[];
}

export const bookingsSearch = createAsyncThunk(
  PROJECT_SEARCH,
  async (searchQuery: string, thunkAPI) => {
    const param = `?search=${searchQuery}`;
    const result = await makeBackendGetCallWithJsonResponse<
      BookingSuggestion[]
    >(PROJECT_SEARCH, param);
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const initialState: BookingsScreenSearchState = {
  searchQuery: "",
  collaboratorFilter: [],
  studioRoomFilter: [],
  studioFilter: [],
};

export const setSearchQuery = createAction<string>("setSearchQuery");
export const setCollaboratorFilter = createAction<string[]>(
  "setCollaboratorFilter",
);
export const setStudioRoomFilter = createAction<string[]>(
  "setStudioRoomFilter",
);
export const setStudioFilter = createAction<string[]>("setStudioFilter");
