import { styled, Box } from "@mui/material";

export const SelectSubPaymentPlanContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "64px",
}));

export const SelectSubPaymentPlanHeader = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  textAlign: "center",
}));

export const SelectSubPaymentPlanCardsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  alignItems: "center",
  gap: "24px",
  width: "100%",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const SelectSubPaymentPlanFooter = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "16px",
}));

export const SelectSubPaymentPlanInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "12px",
  alignItems: "center",
  textAlign: "center",
  borderRadius: theme.border.radius.md,
  background: theme.palette.standardColor.BoxyYellow[100],
}));
