import { atom } from "jotai";
import { activeUserAtom } from "./activeUserAtom";
import { SlimProfileBase } from "../../store/models/base";
import { atomWithStorage, RESET } from "jotai/utils";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";
import { activeUserStudiosAtom } from "./activeUserStudiosAtom";
import { matchPath } from "react-router-dom";
import { SCREENS } from "../../routes";
import pathnameAtom from "../location/pathnameAtom";

export const displayPersonalProfileAtom = atom((get) => {
  const activeUser = get(activeUserAtom);
  // If we don't have a user, they can see their own profile
  if (!activeUser) return true;
  // If the user does not have studio manager experience, they can see their own profile
  if (!activeUser.has_studio_manager_experience) return true;
  const pathname = get(pathnameAtom);
  if (pathname) {
    const matchProfileScreen = matchPath<{ username: string }>(pathname, {
      path: SCREENS.PROFILE_SCREEN,
      exact: true,
      strict: false,
    });
    // If we don't match the username, then every path is a match
    if (matchProfileScreen?.params?.username === activeUser.username)
      return true;
    const matchStudioScreen = matchPath(pathname, {
      path: SCREENS.STUDIO_SCREEN,
      exact: true,
      strict: false,
    });
    if (matchStudioScreen) return true;
    const matchSettingsScreen = matchPath(pathname, {
      path: SCREENS.SETTINGS_SCREEN,
      exact: true,
      strict: false,
    });
    if (matchSettingsScreen) return true;
  }
  return false;
});

type StudioProfileIdState = string | undefined;
// This atom holds just the desired studio id for the active profile, and keeps it in local storage.
// We don't trust that this ID is the active studio.
// If it does not exist, or is not part of the active user's studios, we default to the active user.
// If the value is "0", we default to the active user as an explicit choice.
const _activeStudioProfileIdAtom = atomWithStorage(
  LOCAL_STORAGE_KEYS.ACTIVE_STUDIO_PROFILE_ID,
  "",
);

// This buffer atom exists so we can create side effects
// We know these will run when a developer interacts with the active user
export const activeProfileAtom = atom(
  (get) => {
    // Here we fetch the stored studio ID that a user is requesting to be
    const profileId = get(_activeStudioProfileIdAtom);
    const activeUser = get(activeUserAtom);
    const userStudios = get(activeUserStudiosAtom);
    const displayPersonalProfile = get(displayPersonalProfileAtom);
    if (profileId === "0" && displayPersonalProfile) {
      return activeUser as SlimProfileBase | undefined;
    }
    if (profileId) {
      // Find the studio profile from the active user's known studios
      const studio = userStudios.find(
        (studio) => studio.id.toString() === profileId,
      );
      // If we found a studio, return it as the Profile Type to help the interpreter
      if (studio) {
        return studio as SlimProfileBase;
      }
    }
    // If the user has not selected their own profile and receives the studio manager experience
    // we return their first studio as the active profile
    if (activeUser?.has_studio_manager_experience && userStudios.length > 0) {
      return userStudios[0] as SlimProfileBase;
    }
    // If we didn't return a studio, we return the active user, which may be undefined
    return activeUser as SlimProfileBase | undefined;
  },
  (
    get,
    set,
    studioProfileId:
      | StudioProfileIdState
      | ((oldState: StudioProfileIdState) => StudioProfileIdState),
  ) => {
    let result: StudioProfileIdState = undefined;
    // This is a helper function to capture the result from the set state action
    set(_activeStudioProfileIdAtom, (oldState) => {
      if (typeof studioProfileId === "function") {
        result = studioProfileId(oldState);
      } else {
        result = studioProfileId;
      }
      // If we are setting the profile ID to 0, we are going back to the personal profile
      if (result === "0") return result;
      // If we are setting the profile ID to undefined, we are going back to our active user
      if (
        !result ||
        // If the ID is being set, we need to confirm its part of the user's studios
        get(activeUserStudiosAtom).findIndex(
          (s) => s.id.toString() === result,
        ) === -1
      ) {
        // By resetting the value, we go back to being the active user if signed in
        return RESET;
      }
      return result;
    });
  },
);
