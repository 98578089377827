import { EXTERNAL_LINKS, SCREENS } from "../../../../routes";
import User from "../../../../store/models/user";
import "./AnnouncementHeader.css";
import { AnnouncementHeaderLink } from "./components/AnnouncementHeaderLink/AnnouncementHeaderLink";
import { AnnouncementHelpButton } from "./components/AnnouncementHelpButton/AnnouncementHelpButton";
import { AnnouncementText } from "./components/AnnouncementText/AnnouncementText";

interface AnnouncementHeaderProps {
  userId: User["id"] | undefined;
}

export const AnnouncementHeader: React.FC<AnnouncementHeaderProps> = ({
  userId,
}: AnnouncementHeaderProps) => {
  const announcementLinks = [
    { text: "Community", path: SCREENS.COMMUNITY },
    { text: "FAQ", path: EXTERNAL_LINKS.FAQ },
  ];

  return (
    <div className="announcement-header-container">
      <div className="announcement-header-content">
        {/* this empty div allows AnnouncementText to appear in the center of the banner */}
        <div style={{ width: "100%" }} />
        <AnnouncementText />
        <div className="announcement-header-links-container">
          {announcementLinks.map(({ text, path }, index) => (
            <AnnouncementHeaderLink
              key={`announcement-link-${index}`}
              text={text}
              path={path}
              target={path.match(/^http/) ? "_blank" : undefined}
              rel={path.match(/^http/) ? "noreferrer" : undefined}
            />
          ))}
          <AnnouncementHelpButton userId={userId} />
        </div>
      </div>
    </div>
  );
};
