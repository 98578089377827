import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import { receiveErrors } from "../store/actions/errorStore";
import { useAppDispatch } from "../store/hooks";
import { RecordingService } from "../store/models/recording";
import { makeBackendPostCallWithJsonResponse } from "../store/utils/fetch";
import { DELETE_RECORDING_SERVICE } from "../store/utils/routes";

interface useDeleteEngineerServiceProps {
  onSuccess: (data: RecordingService) => void;
}

interface mutationFnProps {
  recording_service_id: number;
}

export const useDeleteRecordingService = ({
  onSuccess,
}: useDeleteEngineerServiceProps) => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationKey: [QUERY_KEYS.DELETE_RECORDING_SERVICE],
    mutationFn: async (args: mutationFnProps) => {
      const response =
        await makeBackendPostCallWithJsonResponse<RecordingService>(
          DELETE_RECORDING_SERVICE,
          {
            recording_service_id: args.recording_service_id,
          },
        );
      if (response.success) {
        return response.resultJson;
      }

      const errors = { errors: response.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(response.resultJson);
    },
    onSuccess,
  });
};
