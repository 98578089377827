import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createMusoCredit, getMusoCredit } from "../../../store/actions/muso";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { MusoCredit } from "../../../store/models/muso";
import { MusoSelectedCreditsTable } from "../MusoSelectedCreditsTable/MusoSelectedCreditsTable";
import "./SelectMusoCredit.css";
import { useHistory } from "react-router-dom";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { CreditTablerRowSkeletonLoader } from "../../elements/CreditTablerRowSkeletonLoader/CreditTablerRowSkeletonLoader";
import { MusoCreditTable } from "../MusoCreditTable/MusoCreditTable";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import {
  fetchAllMusoCreditsById,
  setKeyword,
} from "../../../store/actions/searchMusoCredits";
import { useMusoSearchStateOffsets } from "../../../hooks/useMusoSearchStateOffsets";
import { getProfileScreenRoute } from "../../../store/utils/routeGetters";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Text } from "../../core-ui/components/Text/Text";
import { Box, Grid, Theme, useMediaQuery } from "@mui/material";
import {
  SelectMusoCreditSearchContainer,
  MusoBottomRowButtons,
  MusoTableBottomRow,
  MusoTableButtonGroup,
  MusoSearchCreditCount,
} from "./SelectMusoCredit.styles";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  BOTTOM_TAB_BAR_OVERLAY_ID,
  DEFAULT_TAB_OVERLAY_CLASS,
  useBottomTabBarOverlayView,
} from "../Navigation/BottomNav/useBottomTabBarOverlayView";

const MAX_SELECTABLE_CREDITS = 10;
const MAX_NUM_CREDITS_DISPLAY = 10;

export interface SelectMusoCreditProps {
  musoProfileId: string;
  musoEntityId: number;
}

// TODO: Update with new SSO API
export const SelectMusoCredit = ({
  musoProfileId,
  musoEntityId,
}: SelectMusoCreditProps) => {
  const [localKeyWord, setLocalKeyWord] = useState<string>("");
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const [disableCheckmarks, setDisableCheckmarks] = useState<boolean>(false);
  const [selectedCredits, setSelectedCredits] = useState(
    new Map<string, MusoCredit>(),
  );
  const [credits, setCredits] = useState<MusoCredit[]>([]);
  const [fetchingCredits, setFetchingCredits] = useState<boolean>(true);
  const { currentOffset, nextOffset, previousOffset, hasMore } =
    useMusoSearchStateOffsets();
  const { data, total, keyword } = useAppSelector(
    (state) => state.searchMusoCredits,
  );
  const creditList = data[currentOffset] ?? [];

  const [savingCredits, setSavingCredits] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string[]>();
  const { isDesktop } = useMediaQueryBreakpoint();
  const { user } = useAppSelector((state) => state.accountInfo);
  const topCredit = useAppSelector((state) => state?.musoSearchStore.credits);

  useEffect(() => {
    if (!musoProfileId) return;
    fetchCredits(0, keyword);
  }, [musoProfileId]);

  useEffect(() => {
    fetchCredits(0, localKeyWord);
  }, [keyword, musoProfileId]);

  // Fetch user's currently selected credits from the backend.
  useEffect(() => {
    if (!topCredit && user) {
      dispatch(
        getMusoCredit({
          user_id: user.id,
        }),
      )
        .unwrap()
        .then((res) => {
          setCredits(res.data);
        })
        .catch((error) => console.log("error, ", error));
    } else {
      topCredit.map((credit) => onChecked(credit));
    }
  }, [dispatch, user]);

  const history = useHistory();
  const navigateToDashboard = useCallback(() => {
    history.push({
      pathname: getProfileScreenRoute(user?.username ?? ""),
    });
  }, [history]);

  const onChecked = (data: MusoCredit) => {
    const key = data.muso_credit_id + data.track_title;
    if (selectedCredits.has(key)) {
      selectedCredits.delete(key);
    } else {
      selectedCredits.set(key, data);
    }

    if (selectedCredits.size === MAX_SELECTABLE_CREDITS) {
      setDisableCheckmarks(true);
    } else {
      setDisableCheckmarks(false);
    }
    setSelectedCredits(selectedCredits);
    setCredits(Array.from(selectedCredits.values()));
  };

  useEffect(() => {
    setSelectedId(
      credits.map((credit) => credit.muso_credit_id + credit.track_title),
    );
  }, [credits]);

  const saveMusoCredits = () => {
    setSavingCredits(true);
    if (!musoEntityId || !credits) return;
    dispatch(
      createMusoCredit({
        muso_entity_id: musoEntityId,
        muso_id: musoProfileId,
        credits: credits,
      }),
    )
      .unwrap()
      .then(() => {
        setSavingCredits(false);
        navigateToDashboard();
      })
      .catch((error) => console.log("error, ", error));
  };

  const fetchCredits = (offset: number, searchKeyword?: string) => {
    if (!musoProfileId) return;
    setFetchingCredits(true);
    dispatch(
      fetchAllMusoCreditsById({
        musoProfileId,
        offset: offset,
        keyword: searchKeyword,
      }),
    )
      .unwrap()
      .then((data) => {
        console.log(data);
        setFetchingCredits(false);
      })
      .catch(() => {
        setFetchingCredits(false);
        toast.error("Error fetching credits. Please reach out to support.");
      });
  };

  const loadNext = () => {
    if (!hasMore) return;
    fetchCredits(nextOffset, localKeyWord);
  };

  const loadPrev = () => {
    fetchCredits(previousOffset, localKeyWord);
  };

  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.target.value;
    setLocalKeyWord(value);
    const searchStateUpdate = debounce(() => {
      dispatch(setKeyword(value));
    }, 500);
    searchStateUpdate();
  };

  const actionButtons = (
    <>
      <Button
        fullWidth={isMobile}
        onClick={navigateToDashboard}
        variant={ButtonVariant.OUTLINED}
        disabled={savingCredits}
      >
        Cancel
      </Button>
      <Button
        fullWidth={isMobile}
        variant={ButtonVariant.PRIMARY}
        loading={savingCredits}
        onClick={saveMusoCredits}
      >
        Save credits
      </Button>
    </>
  );

  const mobileActionButtons = useBottomTabBarOverlayView(
    isMobile,
    <MusoBottomRowButtons>{actionButtons}</MusoBottomRowButtons>,
    DEFAULT_TAB_OVERLAY_CLASS,
    BOTTOM_TAB_BAR_OVERLAY_ID,
  );

  const skeletonLoader = useMemo(() => {
    const loadersList = [];
    for (let i = 0; i < MAX_NUM_CREDITS_DISPLAY; i++) {
      loadersList.push(<CreditTablerRowSkeletonLoader isMobile={!isDesktop} />);
    }
    return loadersList;
  }, [isDesktop]);

  return (
    <div className="container muso-credits-container">
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Text variant={TextStyleVariant.H4}>Add your Muso credits</Text>
        <Text variant={TextStyleVariant.P1}>
          Choose up to 10 credits that you want displayed on your profile.{""}
          {isDesktop && <br />}
          You can always edit your list at a later time.
        </Text>
      </Box>
      {selectedCredits.size > 0 && (
        <>
          <div className="selected-credits">
            <Text variant={TextStyleVariant.S3}>
              Selected Credits:{" "}
              {selectedCredits.size + "/" + MAX_SELECTABLE_CREDITS}
            </Text>
          </div>
          <MusoSelectedCreditsTable
            selectedCredits={credits}
            disabled={false}
            visibleCheckMarks={true}
            onChecked={onChecked}
            checkedList={selectedId}
          />
        </>
      )}
      &nbsp;
      <SelectMusoCreditSearchContainer container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            value={localKeyWord}
            onChange={handleKeywordChange}
            style={{ width: "100%" }}
            placeholder="Search your Muso credits"
            type={"text"}
            className="textfield"
          />
        </Grid>
        <MusoSearchCreditCount
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text variant={TextStyleVariant.P2}>Showing: {total}</Text>
        </MusoSearchCreditCount>
      </SelectMusoCreditSearchContainer>
      {!fetchingCredits ? (
        <MusoCreditTable
          selectedCredits={creditList}
          disabled={disableCheckmarks}
          onChecked={onChecked}
          checkedList={selectedId}
        />
      ) : (
        <div className="muso-credit-table">{skeletonLoader}</div>
      )}
      <MusoTableBottomRow>
        <div className="submit-selected-muso-credits-button">
          <div>
            <button
              className="paginate-button"
              value={"<"}
              onClick={() => loadPrev()}
            >
              {"<"}
            </button>
            <button
              className="paginate-button"
              value={">"}
              disabled={!hasMore}
              onClick={() => loadNext()}
            >
              {">"}
            </button>
          </div>
          {!isMobile && (
            <MusoTableButtonGroup>{actionButtons}</MusoTableButtonGroup>
          )}
        </div>
      </MusoTableBottomRow>
      {mobileActionButtons}
    </div>
  );
};
