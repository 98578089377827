import { FC, useMemo } from "react";
import { PromoCode } from "../../../store/models/promoCode";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import "./InvoiceTotal.css";

export interface InvoiceTotalProps {
  serviceFee: number;
  subtotal: number;
  studioRoomTotal?: number;
  engineerTotal?: number;
  promoCode?: PromoCode;
}

export const InvoiceTotal: FC<InvoiceTotalProps> = ({
  serviceFee,
  subtotal,
  engineerTotal,
  studioRoomTotal,
  promoCode,
}) => {
  const studioRoomPromoCodeDiscount: number = useMemo(() => {
    if (!studioRoomTotal) {
      return 0;
    }
    if (!promoCode) {
      return 0;
    }
    if (promoCode?.studio || !promoCode?.owner) {
      return studioRoomTotal * promoCode.discount_percentage;
    }
    return 0;
  }, [promoCode, studioRoomTotal]);

  const engineerPromoCodeDiscount: number = useMemo(() => {
    if (studioRoomTotal) {
      return 0;
    }
    if (!engineerTotal) {
      return 0;
    }
    if (!promoCode) {
      return 0;
    }
    if (!promoCode?.studio) {
      return engineerTotal * promoCode.discount_percentage;
    }
    return 0;
  }, [studioRoomTotal, engineerTotal, promoCode]);

  return (
    <div className="invoice-container">
      <div className="invoice-breakdown">
        {Boolean(studioRoomTotal) && studioRoomTotal !== undefined && (
          <div className="invoice-service">
            <p className="breakdown-text">Studio Room Total</p>
            <p className="invoice-subtotal-text">
              {PennyDollarFormatter().format(studioRoomTotal)}
            </p>
          </div>
        )}
        {Boolean(studioRoomPromoCodeDiscount) && (
          <div className="invoice-service">
            <p className="breakdown-text">{`Promo Code Discount ${
              Number(promoCode?.discount_percentage) * 100
            }%`}</p>
            <p className="invoice-subtotal-text">
              {PennyDollarFormatter().format(studioRoomPromoCodeDiscount * -1)}
            </p>
          </div>
        )}
        {Boolean(engineerTotal) && engineerTotal !== undefined && (
          <div className="invoice-service">
            <p className="breakdown-text">Engineer Total</p>
            <p className="invoice-subtotal-text">
              {PennyDollarFormatter().format(engineerTotal)}
            </p>
          </div>
        )}
        {Boolean(engineerPromoCodeDiscount) && (
          <div className="invoice-service">
            <p className="breakdown-text">{`Engineer Promo Code Discount ${
              Number(promoCode?.discount_percentage) * 100
            }%`}</p>
            <p className="invoice-subtotal-text">
              {PennyDollarFormatter().format(engineerPromoCodeDiscount * -1)}
            </p>
          </div>
        )}
        <div className="invoice-service">
          <p className="breakdown-text">Subtotal</p>
          <p className="invoice-subtotal-text">
            {PennyDollarFormatter().format(subtotal)}
          </p>
        </div>
        <div className="invoice-service">
          <p className="breakdown-text">Service Fee</p>
          <p className="invoice-subtotal-text">
            {PennyDollarFormatter().format(serviceFee)}
          </p>
        </div>
      </div>
      <div className="invoice-total">
        <div className="invoice-calculated">
          <p>
            <strong className="invoice-total-text">Invoice Total</strong>
          </p>
          <p className="invoice-total-text">
            {PennyDollarFormatter().format(serviceFee + subtotal)}
          </p>
        </div>
      </div>
    </div>
  );
};
