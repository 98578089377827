import { Box, styled } from "@mui/material";

export const ReviewsListV2Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "30px",
});

export const ReviewsListV2Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const ReviewsListReviewsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexWrap: "wrap",
  paddingTop: theme.spacing(1.25),
  flexDirection: "column",
  gap: "36px",
}));

export const ReviewsListVerticalBar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.grey[300],
  strokeWidth: "1px",
  width: "1px",
  height: "28px",
  stroke: theme.palette.grey[200],
}));

export const ReviewListReviewElementContainer = styled(Box)({
  width: "100%",
  display: "flex",
});
