import { LongBioContainer, BioInput } from "./LongBio.styles";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { useAtomValue } from "jotai";
import { editMode } from "../../../atoms/profileScreenEdit";
import useUpdateProfile from "../../../hooks/profileScreenHooks/useUpdateProfile";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { Box } from "@mui/material";
import { faAddressCard, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  EmptyContainer,
  EmptyContainerFaIcon,
} from "../../screens/ProfileScreen/ProfileScreen.styles";

const MAX_LONG_BIO_LENGTH = 3000;

export const LongBio = () => {
  const { username } = useParams<{ username: string }>();
  const isEditMode = useAtomValue(editMode);
  const { data: userData, isPending: isLoadingUserData } =
    useGetUserProfile(username);

  const [localLongBio, setLocalLongBio] = useState(
    userData?.profile?.long_bio ?? "",
  );
  const [localEdit, setLocalEdit] = useState(false);

  const { mutateAsync, isPending: isUpdatingProfile } = useUpdateProfile(
    (data) => {
      setLocalLongBio(data.profile?.long_bio ?? "");
      setLocalEdit(false);
    },
  );

  useEffect(() => {
    if (!isEditMode) {
      setLocalEdit(false);
    }
  }, [isEditMode]);

  const editClick = async () => {
    if (isEditMode) {
      await mutateAsync({ long_bio: localLongBio });
      return;
    }
  };

  const renderLongBioBody = () => {
    if (isLoadingUserData) {
      return <SoundWaveLoader height={100} width={100} />;
    }
    if (!userData?.profile?.long_bio && !isEditMode) {
      return (
        <EmptyContainer>
          <EmptyContainerFaIcon icon={faAddressCard} />
          <Text>No bio submitted yet</Text>
        </EmptyContainer>
      );
    }

    if (localEdit) {
      return (
        <BioInput
          maxLength={MAX_LONG_BIO_LENGTH}
          inputMode="text"
          rows={8}
          isEditMode={true}
          disabled={isUpdatingProfile}
          placeholder="Tell us little bit about yourself..."
          onChange={(e) => setLocalLongBio(e.target.value)}
          value={localLongBio}
        />
      );
    }
    return (
      <Text
        variant={TextStyleVariant.P2}
        style={{
          whiteSpace: "pre-wrap",
          lineHeight: "normal",
        }}
      >
        {userData?.profile?.long_bio}
      </Text>
    );
  };

  return (
    <LongBioContainer>
      <Box
        sx={{
          display: "flex",
          gap: "24px",
          alignItems: "center",
        }}
      >
        <Text variant={TextStyleVariant.S2}>Bio</Text>
        {!localEdit && isEditMode && (
          <Button
            variant={ButtonVariant.UNSTYLED}
            onClick={() => setLocalEdit(!localEdit)}
            labelIcon={<FontAwesomeIcon icon={faPencil} />}
          >
            Edit
          </Button>
        )}
      </Box>
      {renderLongBioBody()}{" "}
      {localEdit && (
        <Box sx={{ marginLeft: "auto", display: "flex", gap: "16px" }}>
          <Button
            loading={isUpdatingProfile}
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              setLocalLongBio(userData?.profile?.long_bio ?? "");
              setLocalEdit(false);
            }}
          >
            Cancel
          </Button>
          <Button
            loading={isUpdatingProfile}
            onClick={editClick}
            style={{ marginLeft: "auto", minWidth: "140px" }}
          >
            Save Changes
          </Button>
        </Box>
      )}
    </LongBioContainer>
  );
};
