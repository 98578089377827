import { addDays, isPast } from "date-fns";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import useInvalidateOnboardingProgress from "../../../hooks/onboardingHooks/useInvalidateOnboardingProgress";
import {
  getProjectReview,
  updateProjectReview,
} from "../../../store/actions/stats";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectById, ProjectType } from "../../../store/models/project";
import { getRecordingSessionIfNotMixMaster } from "../../../store/selectors/projectSelector";
import { convertUTCDateToLocalDate } from "../../../store/utils/dateTimeUtils";
import { formatDateToShort } from "../../../utils/formatDateToShort";
import { getMixingMasteringProject } from "../../../utils/projectUtils";
import { Button } from "../../core-ui/components/Button/Button";
import { CheckBox } from "../../core-ui/components/CheckBox/CheckBox";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { StarRatings } from "../../elements/StarRatings/StarRatings";
import "./ProjectReview.css";
import {
  ProjectReviewButtonContainer,
  ProjectReviewContainer,
  ProjectReviewRatingContainer,
  ProjectReviewTextArea,
  ProjectReviewTitle,
} from "./ProjectReview.styles";

export interface ProjectReviewProps {
  projectId: number;
  project_type: ProjectType;
  tenDaysSinceProjectCompletion?: boolean;
  fullComponentLoading?: boolean;
}

export const ProjectReview = ({
  projectId,
  project_type,
  tenDaysSinceProjectCompletion = false,
  fullComponentLoading = true,
}: ProjectReviewProps) => {
  const project: ProjectById | undefined = useAppSelector(
    (state) => state.projectsMapStore.projects[projectId],
  );
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [userRating, setUserRating] = useState(0);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [reviewComment, setComment] = useState("");
  const [hasReview, showReview] = useState(false);
  const isRecordingSession = project?.service_type === ProjectType.RECORDING;
  const recordingSession = useAppSelector(
    getRecordingSessionIfNotMixMaster(isRecordingSession ? project : undefined),
  );
  const mixingMasteringProject = getMixingMasteringProject(project);
  const relatedProjectId = mixingMasteringProject?.id ?? recordingSession?.id;

  const completed = project?.completed ? `${project.completed}Z` : "";
  const tenDaysSinceCompleted = addDays(new Date(completed), 10);
  const isTenDaysSinceCompletion =
    tenDaysSinceProjectCompletion || isPast(tenDaysSinceCompleted);

  const { invalidateOnboardingProgress } = useInvalidateOnboardingProgress();

  const handleReviewPost = useCallback(() => {
    if (!relatedProjectId) return;
    setLoading(true);
    dispatch(
      updateProjectReview({
        project_id: relatedProjectId.toString(),
        project_type,
        review: reviewComment,
        rating: userRating,
        deleted: false,
        is_public: !isAnonymous,
      }),
    )
      .unwrap()
      .then((data) => {
        if (!Object.keys(data).length) {
          return setLoading(false);
        }
        showReview(true);
        const { rating, review } = data;
        setComment(review ?? "");
        setUserRating(rating);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(async () => {
        await invalidateOnboardingProgress();
      });
  }, [
    dispatch,
    relatedProjectId,
    project_type,
    reviewComment,
    userRating,
    isAnonymous,
  ]);

  const headerText = useMemo(() => {
    if (project) {
      return hasReview
        ? "Your review for ".concat(project.title)
        : "Review your collaboration on ".concat(project.title);
    } else if (recordingSession) {
      return hasReview
        ? "Your review on ".concat(
            formatDateToShort(
              convertUTCDateToLocalDate(
                new Date(recordingSession.first_choice_datetime),
              ),
            ),
          )
        : "Review your collaboration on ".concat(
            formatDateToShort(
              convertUTCDateToLocalDate(
                new Date(recordingSession.first_choice_datetime),
              ),
            ),
          );
    }
  }, [hasReview, recordingSession, project]);

  const submitButtonLabel = () => {
    if (isTenDaysSinceCompletion) {
      return "Reviews must be submitted within 10 days of project completion";
    }
    return hasReview ? "Edit Review" : "Submit";
  };

  useEffect(() => {
    if (!relatedProjectId) return;
    setLoading(true);
    void dispatch(
      getProjectReview({
        project_id: relatedProjectId.toString(),
        project_type,
      }),
    )
      .unwrap()
      .then((data) => {
        if (!Object.keys(data).length) {
          setLoading(false);
          return;
        }
        showReview(true);
        const { rating, review } = data;
        setComment(review ?? "");
        setUserRating(rating);
        setLoading(false);
      });
  }, [dispatch, project_type, relatedProjectId]);

  if (!project && !recordingSession) return null;
  if (loading && fullComponentLoading) {
    return <SoundWaveLoader width={100} height={100} />;
  }

  return (
    <ProjectReviewContainer>
      <ProjectReviewTitle>
        <Text variant={TextStyleVariant.S3}>{headerText}</Text>
        {!hasReview && (
          <CheckBox
            checked={isAnonymous}
            onClick={() => setIsAnonymous(!isAnonymous)}
            label={
              <Text variant={TextStyleVariant.P2}>Make review anonymous</Text>
            }
            disabled={loading}
          />
        )}
      </ProjectReviewTitle>
      <ProjectReviewRatingContainer $hasReview={hasReview}>
        <StarRatings
          currentRating={userRating}
          numberOfReview={0}
          isEditable={!hasReview}
          onClick={(arg) => {
            setUserRating(arg);
          }}
        />
      </ProjectReviewRatingContainer>
      <ProjectReviewTextArea
        disabled={hasReview || loading}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setComment(e.target.value)
        }
        placeholder="comments"
        value={reviewComment}
      />
      <ProjectReviewButtonContainer>
        <Button
          disabled={
            (!hasReview && userRating === 0) ||
            isTenDaysSinceCompletion ||
            loading
          }
          onClick={() => (hasReview ? showReview(false) : handleReviewPost())}
          style={{ minWidth: "150px" }}
          loading={loading}
        >
          {submitButtonLabel()}
        </Button>
      </ProjectReviewButtonContainer>
    </ProjectReviewContainer>
  );
};
