import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  navElementRefAtom,
  preventScrollOnNavigationAtom,
} from "../../../atoms/navAtoms";

const ScrollToTop = () => {
  const history = useHistory();
  const elementRef = useAtomValue(navElementRefAtom);
  const preventScroll = useAtomValue(preventScrollOnNavigationAtom);

  useEffect(() => {
    const scrollToTop = () => elementRef?.current?.scrollTo(0, 0);
    const listener = history.listen(() => {
      if (!preventScroll) {
        scrollToTop();
      }
    });
    return () => {
      listener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preventScroll]);
  return null;
};

export default ScrollToTop;
