import styled from "styled-components";

export const DetailHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colorPalette.BoomyOrange50};
  flex: 1;
  min-height: 79px;
  height: auto;
`;

export const DetailHeaderTitle = styled.h1`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 28px;
  color: ${({ theme }) => theme.colorPalette.BoomyOrange950};
  text-align: center;
  white-space: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 16px;
`;

export const DetailHeaderSubtitle = styled.h2`
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  color: ${({ theme }) => theme.colorPalette.BoomyOrange800};
  text-align: center;
  white-space: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 16px;
`;
