import TransactionsTable from "../TransactionsTable/TransactionsTable";
import "./TransactionHistoryCard.css";

interface TransactionHistoryCardProps {
  startDate?: Date;
  endDate?: Date;
  setStartDate?: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate?: React.Dispatch<React.SetStateAction<Date>>;
}

export const TransactionHistoryCard = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: TransactionHistoryCardProps) => {
  return (
    <div className="transaction-table-container">
      <div className="earnings-title transactions-history-card-header mb-3">
        <h3>Transaction History</h3>
      </div>
      <TransactionsTable
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    </div>
  );
};
