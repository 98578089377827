import { ProjectUserType } from "../../../../store/models/project";
import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { TransferFileReuploadMessageContainer } from "../TransferFileTransitionView.styles";

export interface TransferFileReuploadMessageProps {
  message: string;
  requestingUser?: ProjectUserType;
}

export const TransferFileReuploadMessage = ({
  message,
  requestingUser = ProjectUserType.ENGINEER,
}: TransferFileReuploadMessageProps) => {
  const isEngineer = requestingUser === ProjectUserType.ENGINEER;
  return (
    <TransferFileReuploadMessageContainer>
      <Text variant={TextStyleVariant.P2}>
        {isEngineer ? "Engineer" : "Client"} requested re-upload:
      </Text>
      <Text bold>{message}</Text>
    </TransferFileReuploadMessageContainer>
  );
};
