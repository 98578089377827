import { Box } from "@mui/material";
import { useMemo } from "react";
import { useAppSelector } from "../../../store/hooks";
import { FileVersion } from "../../../store/models/fileVersion";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { SwitchButtonAB } from "../../elements/SwitchButton/SwitchButtonAB/SwitchButtonAB";
import { FeaturedTrackPlayer } from "../FeaturedTrackPlayer/FeaturedTrackPlayer";
import { FeaturedABTrackWaveContainer } from "./FeaturedABTrack.styles";

interface FeaturedABTrackProps {
  url: string;
  fileVersion: FileVersion;
  readableProjectType: string;
  isRef: boolean;
  isFeaturedTrackPlaying: boolean;
  onClick: (loadedUrl: string, trackedPlayerId: number) => void;
}

export const FeaturedABTrack = ({
  url,
  fileVersion,
  readableProjectType,
  isRef,
  isFeaturedTrackPlaying,
  onClick,
}: FeaturedABTrackProps) => {
  const { trackedPlayerId } = useAppSelector((state) => state.abPlayerStore);

  const label = isRef ? "Before" : "After";
  const isPlayingOnFooter = useMemo(() => {
    return Boolean(trackedPlayerId === fileVersion.id);
  }, [fileVersion.id, trackedPlayerId]);

  if (!url) {
    return <SoundWaveLoader height={50} width={50} />;
  }

  return (
    <FeaturedABTrackWaveContainer>
      <Box
        sx={{ display: "flex", gap: "4px" }}
        onClick={() => onClick(url, fileVersion.id)}
      >
        <SwitchButtonAB
          isSelected={isPlayingOnFooter}
          label={isRef ? "A" : "B"}
          onClick={() => {}}
        />
        <SwitchButtonAB
          isSelected={false}
          label={`${label} ${readableProjectType}`}
          onClick={() => {}}
        />
      </Box>
      <FeaturedTrackPlayer
        source={url}
        isPlayingOnFooter={isPlayingOnFooter}
        isSameProject={isFeaturedTrackPlaying}
        id={fileVersion.id}
      />
    </FeaturedABTrackWaveContainer>
  );
};
