import { Dispatch, SetStateAction, useEffect } from "react";
import { usePaymentPlanStatus } from "../../../hooks/partialPaymentHooks";
import { useAppSelector } from "../../../store/hooks";
import {
  ProjectType,
  ScheduledProjectPaywallTypes,
} from "../../../store/models/project";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { TEXT_WEIGHT, Text } from "../../core-ui/components/Text/Text";

export interface RedirectToPaymentButtonProps {
  scheduledProjectId?: number;
  projectId?: number;
  shareLink?: string;
  setParentLoading?: (state: boolean) => void;
  isPartiallyPaid: boolean;
  totalPrice?: number;
}

export const RedirectToPaymentButton = ({
  scheduledProjectId,
  projectId,
  shareLink,
  setParentLoading,
  isPartiallyPaid,
  totalPrice,
}: RedirectToPaymentButtonProps) => {
  const { redirectToPurchaseProject, loading } = usePaymentPlanStatus(
    shareLink,
    scheduledProjectId,
    projectId,
    isPartiallyPaid,
  );

  useEffect(() => {
    if (setParentLoading) {
      setParentLoading(loading);
    }
  }, [setParentLoading, loading]);

  const formattedPrice = totalPrice
    ? ` (${PennyDollarFormatter().format(totalPrice)})`
    : "";
  const buttonCta = isPartiallyPaid
    ? "Complete Payment"
    : "Review Order Details";
  const buttonText = `${buttonCta}${formattedPrice}`;

  return (
    <Button
      loading={loading}
      onClick={redirectToPurchaseProject}
      variant={ButtonVariant.PRIMARY}
    >
      {buttonText}
    </Button>
  );
};

export interface RedirectToPaymentModalProps {
  scheduledProjectId: number;
  projectId: number;
  modalIsOpen: boolean;
  shareLink: undefined | string;
  isPartiallyPaid: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  projectType: ProjectType;
  paywallOption?: ScheduledProjectPaywallTypes;
  totalPrice?: number;
}

export const RedirectToPaymentModal = ({
  scheduledProjectId,
  projectId,
  shareLink,
  modalIsOpen,
  isPartiallyPaid,
  setModalIsOpen,
  projectType,
  paywallOption,
  totalPrice,
}: RedirectToPaymentModalProps) => {
  const {
    redirectToPurchaseProject,
    loading,
    fetchingPaymentStatus,
    cancelFetchingPaymentStatus,
  } = usePaymentPlanStatus(
    shareLink,
    scheduledProjectId,
    projectId,
    isPartiallyPaid,
  );
  const { paymentPlanPaid } = useAppSelector(
    (state) => state.scheduledProjectsStore,
  );

  const getAccessTypeText = () => {
    switch (paywallOption) {
      case ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED ||
        ScheduledProjectPaywallTypes.TRACK_PREVIEW_NOT_ALLOWED:
        return `access the ${projectType === ProjectType.MASTERING ? "master" : "mix"}`;
      case ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED:
        return "leave revision notes or request final files";
      case ScheduledProjectPaywallTypes.REVIEWS_AND_REVISIONS_ALLOWED:
        return "access final delivered files";
      default:
        return "proceed with the project";
    }
  };

  const paymentTextPrefix = isPartiallyPaid
    ? "Completion of payment is required to "
    : "Payment is required to ";
  const paymentText = `${paymentTextPrefix}${getAccessTypeText()}.`;
  const awaitingPaymentText = "Waiting for payment completion in new tab...";

  const updateModalState = () => {
    cancelFetchingPaymentStatus();
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (paymentPlanPaid) {
      window.location.reload();
    }
  }, [paymentPlanPaid]);

  return (
    <BaseModal
      open={modalIsOpen}
      header={
        isPartiallyPaid ? "Payment Completion Required" : "Payment Required"
      }
      setOpen={updateModalState}
      onConfirm={redirectToPurchaseProject}
      onCancel={updateModalState}
      loading={loading || fetchingPaymentStatus}
      confirmText={`Submit Payment${totalPrice && ` (${PennyDollarFormatter().format(totalPrice)})`}`}
      showModalFooter
    >
      <Text weight={TEXT_WEIGHT.BOLD} className="redirect-to-payment-label">
        {fetchingPaymentStatus ? awaitingPaymentText : paymentText}
      </Text>
    </BaseModal>
  );
};
