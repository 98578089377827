import React from "react";
import {
  Location,
  useGetLatLongFromRecordingLocation,
} from "../../../hooks/useMapHooks";
import {
  CancellationPolicy,
  RecordingLocation,
} from "../../../store/models/recording";
import { BasicStudioRoom } from "../../../store/models/studio";
import { getDisplayableNameForStudioRoom } from "../../../store/utils/entityUtils";
import { CancellationPolicyText } from "../../elements/CancellationPolicyText/CancellationPolicyText";
import { GMapElement } from "../../elements/GMapElement/GMapElement";
import {
  StyledTransactionBreakdownMiniPSecondary,
  StyledTransactionBreakdownSpanBold,
  TransactionBreakdownText,
} from "../TransactionBreakdown/TransactionBreakdown.styles";

interface SessionBookingStudioInfoProps {
  revealLocation?: boolean;
  studioRoom?: BasicStudioRoom | null;
  recordingLocation?: RecordingLocation | null;
  cancellationPolicy?: CancellationPolicy | null;
  customMapStyle?: React.CSSProperties;
}

export const SessionBookingStudioInfo = ({
  revealLocation = false,
  studioRoom,
  recordingLocation,
  cancellationPolicy,
  customMapStyle,
}: SessionBookingStudioInfoProps) => {
  const getStudioRoomName = () => {
    if (!studioRoom) return "This session";
    return getDisplayableNameForStudioRoom(studioRoom);
  };

  const location: Location | undefined =
    useGetLatLongFromRecordingLocation(recordingLocation);

  return (
    <>
      {recordingLocation && (
        <>
          <GMapElement
            latitude={location?.lat}
            longitude={location?.lng}
            revealLocation={revealLocation}
            mapStyle={customMapStyle}
          />
          <StyledTransactionBreakdownMiniPSecondary>
            <StyledTransactionBreakdownSpanBold>
              {getStudioRoomName()}
            </StyledTransactionBreakdownSpanBold>{" "}
            is located in{" "}
            <StyledTransactionBreakdownSpanBold>
              {recordingLocation.city_location}
            </StyledTransactionBreakdownSpanBold>
            . Driving Directions, contact details, and related information will
            be sent to all parties involved once booking is complete.
          </StyledTransactionBreakdownMiniPSecondary>
        </>
      )}
      {cancellationPolicy && (
        <>
          <TransactionBreakdownText $weight>
            Cancellation Policy
          </TransactionBreakdownText>
          <CancellationPolicyText cancellationPolicy={cancellationPolicy} />
        </>
      )}
    </>
  );
};
