import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import copyToClipboard from "copy-to-clipboard";
import { useState } from "react";
import { toast } from "react-toastify";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { setLinkShareStatus } from "../../../store/actions/generateBookingStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import "./ShareBookingLink.css";

export interface CopyLinkButtonProps {
  fullWidth?: boolean;
  cartHasContent: boolean;
  disabled?: boolean;
  disabledText?: string;
  transactionId?: number;
  inProgressProject?: boolean;
  inProgressOnClick?: () => Promise<string>;
  onClick?: () => void;
}

export const CopyLinkButton = ({
  cartHasContent,
  disabled,
  disabledText,
  transactionId,
  inProgressProject = false,
  inProgressOnClick,
  fullWidth,
  onClick,
}: CopyLinkButtonProps) => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useMediaQueryBreakpoint();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const url = window.location.href;
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const handleCopyToClipboard = async () => {
    emitAnalyticsTrackingEvent(
      "on_click_copy_link",
      { transaction_id: transactionId },
      loggedInUser?.id,
    );
    let shareLink = url;
    if (inProgressProject && inProgressOnClick) {
      shareLink = await inProgressOnClick();
    }
    copyToClipboard(shareLink);
    toast.success("Link copied to clipboard");
    dispatch(setLinkShareStatus("copy"));
    setCopiedToClipboard(true);
    onClick?.();
  };

  return (
    <Button
      fullWidth={fullWidth ?? !isDesktop}
      variant={ButtonVariant.OUTLINED}
      onClick={handleCopyToClipboard}
      disabled={!transactionId || !cartHasContent || disabled}
      disableText={disabledText || "Please select a service"}
    >
      <FontAwesomeIcon icon={faCopy} style={{ marginRight: "8px" }} />
      {copiedToClipboard && isDesktop ? "Copied to Clipboard" : "Copy Link"}
    </Button>
  );
};

export default CopyLinkButton;
