import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Alt } from "../../../store/models/alts";
import { FileType } from "../../../store/models/fileVersion";
import { handleFileUpload } from "../../../utils/fileUtils";
import { getFileExtension } from "./FileUploader";
import { useTransitionToInProgress } from "./useTransitionToInProgress";

interface Deps {
  code: string | null;
  isCurrentProjectEngineer: boolean;
  engineerIsUploadingOnBehalfOfArtist: boolean;
  projectId: number;
  uploadAltValue: Alt;
  recordingSessionBookingId?: number | null;
}

interface FileUploadInfo {
  progress: number;
}

const useOnDrop = (
  deps: Deps,
): [(acceptedFiles: File[]) => Promise<void>, FileUploadInfo] => {
  const dispatch = useDispatch();
  const transitionToInProgress = useTransitionToInProgress(
    deps.projectId,
    deps.isCurrentProjectEngineer,
    deps.engineerIsUploadingOnBehalfOfArtist,
  );

  const [progress, setProgress] = useState(0);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const file = acceptedFiles[0];
        let altValue = deps.uploadAltValue;
        if (getFileExtension(file) === FileType.MP4) {
          altValue = altValue === Alt.CLEAN ? Alt.CLEAN_MP4 : Alt.MP4;
        }

        await handleFileUpload(
          file,
          deps.projectId,
          deps.code,
          setProgress,
          () => {},
          dispatch,
          deps.isCurrentProjectEngineer,
          deps.engineerIsUploadingOnBehalfOfArtist,
          altValue,
          deps.recordingSessionBookingId,
        );

        // if engineer is uploading main during File Transfer, transition to InProgress
        await transitionToInProgress();
      } catch (e) {
        toast.error(
          "Something went wrong with your upload. Reach out for support.",
        );
      }
    },
    [deps, dispatch, transitionToInProgress],
  );

  return [onDrop, { progress }];
};

export default useOnDrop;
