import { useEffect, useMemo } from "react";
import {
  useStudioSubscriptionSubTypeBenefits,
  useSubscriptionMonthlyPrice,
  useSubscriptionPrice,
  useSubscriptionSubTypeBenefits,
} from "../../../hooks/useSubscriptionTrophy";
import {
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_STATUS,
} from "../../../store/models/subscription";
import { getAnnualVersionOfSubscription } from "../../screens/SelectSubPaymentPlanScreen/SelectSubPaymentPlanScreen";
import {
  PaymentPlanCheckboxContainer,
  PaymentPlanCheckboxIcon,
  PaymentPlanList,
} from "./PaymentPlanCheckbox.styles";
import { Box, CardContent, CardHeader, useTheme } from "@mui/material";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { faCircle, faCircleCheck } from "@fortawesome/pro-duotone-svg-icons";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getStripePortalSession } from "../../../store/actions/subscriptions";

export interface PaymentPlanCheckboxProps {
  subscriptionType: SUBSCRIPTION_PLAN; // Type of subscription plan being rendered.
  selected: boolean; // Indicates whether this particular plan is the selected on currently.
  setSelected?: (plan: SUBSCRIPTION_PLAN) => void;
  useStudioBenefits?: boolean;
  onGoToCheckout: () => void;
}

export const PaymentPlanCheckbox = ({
  subscriptionType,
  selected,
  setSelected,
  useStudioBenefits = false,
  onGoToCheckout,
}: PaymentPlanCheckboxProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const subtypeBenefits = useSubscriptionSubTypeBenefits(subscriptionType);
  const studioSubtypeBenefits =
    useStudioSubscriptionSubTypeBenefits(subscriptionType);
  const isAnnualPlan =
    subscriptionType === SUBSCRIPTION_PLAN.LEGACY_GOLD_YEARLY_PLAN ||
    subscriptionType === SUBSCRIPTION_PLAN.LEGACY_PLATINUM_YEARLY_PLAN ||
    subscriptionType === SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY;
  const isQuarterlyPlan =
    subscriptionType === SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY;
  const price = useSubscriptionPrice(subscriptionType);
  const monthlyPrice = useSubscriptionMonthlyPrice(subscriptionType);
  const subscription = useAppSelector((state) => state.subscriptionStore);

  const hasActiveSubscription = useMemo(() => {
    return (
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.LEGACY_BASIC_PLAN &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.ESSENTIALS_PLAN &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN &&
      subscription?.stripe_subscription_status === SUBSCRIPTION_STATUS.ACTIVE
    );
  }, [subscription]);

  // On first load, set the selected plan to be the annual version of the plan.
  useEffect(() => {
    if (!setSelected) return;
    setSelected(getAnnualVersionOfSubscription(subscriptionType));
  }, []);

  const onClick = () => {
    if (!setSelected) return;
    if (selected) {
      if (hasActiveSubscription) {
        void dispatch(getStripePortalSession())
          .unwrap()
          .then(({ portal_url }) => {
            window.location.href = portal_url;
          });
      } else {
        onGoToCheckout();
      }
      return;
    }
    setSelected(subscriptionType);
  };

  const checkboxTitle = () => {
    if (isAnnualPlan) {
      return "Annual";
    } else if (isQuarterlyPlan) {
      return "Quarterly";
    }
    return "Monthly";
  };

  return (
    <PaymentPlanCheckboxContainer $isSelected={selected} onClick={onClick}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "8px",
          position: "relative",
          padding: "0px",

          "&:last-child": {
            paddingBottom: "0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          <CardHeader
            sx={{
              flex: "1",
              textAlign: "center",
              padding: "0px",
              position: "relative",
            }}
            title={<Text variant={TextStyleVariant.H6}>{checkboxTitle()}</Text>}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              transform: "translate(20%, -30%)",
            }}
            onClick={onClick}
          >
            <PaymentPlanCheckboxIcon
              icon={selected ? faCircleCheck : faCircle}
              $isSelected={selected}
            />
          </Box>
        </Box>
        <PaymentPlanList>
          {(useStudioBenefits ? studioSubtypeBenefits : subtypeBenefits).map(
            (benefit, index) => {
              return <li key={`${benefit}-${index}`}>{benefit}</li>;
            },
          )}
        </PaymentPlanList>
        <Box
          sx={{
            display: "flex",
            padding: "16px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            alignSelf: "stretch",
          }}
        >
          <Text variant={TextStyleVariant.H6}>${monthlyPrice + "/month"}</Text>
          <Text
            variant={TextStyleVariant.P2}
            style={{
              color: theme.palette.customColor.unselectedTextPrimaryColor,
            }}
          >
            *${price} paid {checkboxTitle()}
          </Text>
        </Box>
        {setSelected && (
          <Button
            variant={selected ? ButtonVariant.PRIMARY : ButtonVariant.OUTLINED}
            onClick={onClick}
            fullWidth
          >
            {`${isAnnualPlan ? "Save with this Plan" : `Choose ${checkboxTitle()}`}`}
          </Button>
        )}
      </CardContent>
    </PaymentPlanCheckboxContainer>
  );
};
