import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { useEffect, useMemo, useState } from "react";
import { useIsMounted } from "usehooks-ts";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { ArtistReleaseTrackTable } from "../ArtistReleaseTrackTable/ArtistReleaseTrackTable";
import { FeaturedArtistRelease } from "../FeaturedArtistRelease/FeaturedArtistRelease";
import { ReleaseTabViewSectionProps } from "./ArtistReleaseTabView";
import { useFetchReleaseTracksQuery } from "../../../api/releases/hooks/useFetchReleaseTracksQuery";
import { useFetchReleaseQuery } from "../../../api/releases/hooks/useFetchReleaseQuery";
import { useAppSelector } from "../../../store/hooks";
import { useSetPlayList } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { convertArtistReleaseToPlaylistTrack } from "../../../store/models/playListTrack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../core-ui/components/Button/Button";
import { Box } from "@mui/material";
import { FooterFileTrackType } from "../../../store/actions/abPlayerStore";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useFetchProductsByReleaseIdQuery } from "../../../api/products/hooks/usefetchProductsByReleaseIdQuery";
import { toast } from "react-toastify";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";

interface ReleaseDetailViewProps extends ReleaseTabViewSectionProps {
  releaseId: number;
  navigateBack?: () => void;
}

export const ReleaseDetailView = ({
  releaseId,
  shouldShowDesktop,
  navigateBack,
  size,
}: ReleaseDetailViewProps) => {
  const [autoSetPlayList, setAutoSetPlayList] = useState(false);
  const isMounted = useIsMounted();
  const { data: release, isPending } = useFetchReleaseQuery(releaseId);
  const { data: releaseTracks } = useFetchReleaseTracksQuery(releaseId);
  const { playlistId } = useAppSelector((state) => state.abPlayerStore);
  const { data: products } = useFetchProductsByReleaseIdQuery(releaseId);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const product = useMemo(
    () => products?.[selectedProductIndex],
    [products, selectedProductIndex],
  );
  const setPlayList = useSetPlayList();

  const addProductToCart = async () => {
    if (!product) {
      toast.error("Please select a product to purchase");
      return;
    }
    toast.error("implement add to cart");
  };

  useEffect(() => {
    if (!isMounted) return;
    if (autoSetPlayList) return;
    if (!releaseId) {
      return;
    }
    if (!release) {
      return;
    }
    if (playlistId === releaseId) {
      return;
    }
    if (releaseTracks.length === 0) {
      return;
    }
    setAutoSetPlayList(true);
    setPlayList({
      tracks: convertArtistReleaseToPlaylistTrack(releaseTracks),
      index: 0,
      playOnLoad: false,
      playlistId: releaseId,
      footerFileTrackType: FooterFileTrackType.RELEASE,
    });
  }, [
    release,
    releaseTracks,
    releaseId,
    playlistId,
    setPlayList,
    isMounted,
    autoSetPlayList,
  ]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "54px" }}>
      <FeaturedArtistRelease
        release={release}
        isReleaseLoading={isPending}
        shouldShowDesktop={shouldShowDesktop}
        size={size}
        products={products}
        ctaText={`${PennyDollarFormatter().format(Number(product?.total_price))} | Add project to cart`}
        selectedProductIndex={selectedProductIndex}
        setSelectedProductIndex={setSelectedProductIndex}
        onClick={product ? addProductToCart : undefined}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text bold variant={TextStyleVariant.S2}>
            Project Songs
          </Text>
          {navigateBack && (
            <Button onClick={navigateBack} variant={ButtonVariant.UNSTYLED}>
              <FontAwesomeIcon
                style={{
                  marginRight: "4px",
                }}
                icon={faAngleLeft}
              />
              <Text>Back</Text>
            </Button>
          )}
        </Box>
        <ArtistReleaseTrackTable
          artistUserId={release?.artist_user_id}
          releaseTracks={releaseTracks}
          unitPrice={product?.unit_price}
        />
      </Box>
    </Box>
  );
};
