import { useMemo } from "react";
import { useAppSelector } from "../../store/hooks";
import { RecordingService } from "../../store/models/recording";
import { StudioRoom } from "../../store/models/studio";
import User from "../../store/models/user";

export const useIsStudioManager = (user: User | undefined): boolean => {
  return useMemo(() => {
    if (!user) {
      return false;
    }
    return Boolean(user.studio_manager && !user.studio_manager?.deleted);
  }, [user]);
};

export const useIsCurrentUserSameAsSelectedEngineer = (
  recordingService: RecordingService | null,
  studioRoom: StudioRoom | undefined,
) => {
  const currentUserId = useAppSelector((state) => state.accountInfo?.user?.id);
  const { selectedIndex, pendingSessionData } = useAppSelector(
    (state) => state.shoppingCart,
  );

  const isEngineerBooking =
    !recordingService?.studio_room && recordingService?.engineer;

  const hasFullPermissions =
    !isEngineerBooking && studioRoom?.studio
      ? Boolean(studioRoom.studio.affiliated_engineer_booking_links_enabled)
      : true;

  const selectedSessionEngineer = useMemo(() => {
    if (selectedIndex === undefined) {
      return undefined;
    }
    return pendingSessionData?.[selectedIndex]?.trackingEngineer;
  }, [selectedIndex, pendingSessionData]);

  return (
    Boolean(currentUserId) &&
    currentUserId === selectedSessionEngineer?.user_id &&
    hasFullPermissions
  );
};
