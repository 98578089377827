import { useSetAtom } from "jotai";
import { useCallback } from "react";
import useOnScrollCallback from "./useOnScrollCallback";
import { showBottomAndTopNavAtom, ShowState } from "../atoms/navAtoms";

export const useHideNavElementsCallback = () => {
  const setShowNav = useSetAtom(showBottomAndTopNavAtom);
  const handleToggle = useCallback(
    (show: ShowState) => {
      setShowNav((oldShow) => {
        if (typeof oldShow === "undefined") {
          return oldShow;
        }
        return show;
      });
    },
    [setShowNav],
  );

  return useOnScrollCallback({ onToggle: handleToggle });
};
