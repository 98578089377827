import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { EngineEarsError } from "../../../store/actions/errorStore";
import { Release } from "../../../store/models/release";
import { fetchReleaseByScheduledProjectId } from "../releaseAPI";
import { StatusCodes } from "http-status-codes";

export const useFetchScheduledProjectReleaseQuery = (
  scheduledProjectId: number,
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_SCHEDULED_PROJECT_RELEASE, scheduledProjectId],
    queryFn: async () => {
      const response =
        await fetchReleaseByScheduledProjectId(scheduledProjectId);
      if (response.statusCode === StatusCodes.NO_CONTENT) {
        return null;
      }
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return response.resultJson as Release;
    },
    retry: 0,
    enabled: Boolean(scheduledProjectId),
  });
};
