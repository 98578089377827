import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { postGenres, postGenresParams } from "../../store/actions/accountInfo";
import { useAppDispatch } from "../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";

interface UsePostGenresMutationProps {
  userId?: number;
}

const usePostGenresMutation = ({ userId }: UsePostGenresMutationProps) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (selectedGenres: postGenresParams) => {
      if (!userId) return { genres: [] };

      emitAnalyticsTrackingEvent("update_genres", {
        genres: `${JSON.stringify(selectedGenres)}`,
      });
      const result = await dispatch(postGenres(selectedGenres)).unwrap();
      return result;
    },
    onSuccess: (newData) => {
      const { genres } = newData;
      queryClient.setQueryData([QUERY_KEYS.GET_USER_GENRES, userId], genres);
    },
    retry: 0,
  });
};

export default usePostGenresMutation;
