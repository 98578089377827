import { useCallback } from "react";
import { OverlayView } from "@react-google-maps/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComputer, faEarListen } from "@fortawesome/free-solid-svg-icons";

import "./MapSearchDisplay.css";
import { useAppSelector } from "../../../store/hooks";
import { MapQuadrantEnum } from "../../../store/actions/mapSearch";
import { Studio } from "../../../store/models/studio";
import User from "../../../store/models/user";
import { MapProfileCard } from "./MapProfileCard";

interface CustomMapMarkerProps {
  position?: google.maps.LatLngLiteral;
  price: string;
  id: number;
  studio?: Studio;
  engineer?: User;
  activeProfileId: number | null;
  setActiveProfile: (id: number) => void;
}

export const CustomMapMarker = ({
  position,
  price,
  id,
  studio,
  engineer,
  activeProfileId,
  setActiveProfile,
}: CustomMapMarkerProps) => {
  const { currentMapBounds } = useAppSelector((state) => state.mapSearch);

  const getMapQuadrantEnum = useCallback(() => {
    let top = false;
    let left = false;
    if (!position || !currentMapBounds) return MapQuadrantEnum.TopLeft;
    const midLatitude =
      (currentMapBounds.maxLatitude - currentMapBounds.minLatitude) / 2 +
      currentMapBounds.minLatitude;
    const midLongitude =
      (currentMapBounds.maxLongitude - currentMapBounds.minLongitude) / 2 +
      currentMapBounds.minLongitude;

    if (position.lat > midLatitude) {
      top = true;
    }
    if (position.lng < midLongitude) {
      left = true;
    }
    if (top && left) {
      return MapQuadrantEnum.TopLeft;
    }
    if (!top && !left) {
      return MapQuadrantEnum.BottomRight;
    }
    if (!top) {
      return MapQuadrantEnum.BottomLeft;
    }
    return MapQuadrantEnum.TopRight;
  }, [currentMapBounds, position]);

  // Center marker over position.
  const getPixelOffset = (width: number, height: number) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  if (!position) return null;

  const styleClassName = "custom-map-marker b3".concat(
    studio ? " custom-map-marker-studio" : " custom-map-marker-user",
  );

  return (
    <OverlayView
      key={id}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={position}
      getPixelPositionOffset={getPixelOffset}
      zIndex={studio ? 1000 : 100}
    >
      <>
        <div
          className={styleClassName}
          style={
            activeProfileId === id
              ? {
                  backgroundColor: "var(--black-no-dark-theme)",
                  color: "var(--white-no-dark-theme)",
                  borderColor: "var(--black-no-dark-theme)",
                  zIndex: 20,
                }
              : {}
          }
          onClick={(e) => {
            e.stopPropagation();
            setActiveProfile(id);
          }}
        >
          <FontAwesomeIcon
            icon={studio ? faComputer : faEarListen}
            className="map-search-pill-icon"
          />
          {price}
        </div>
        {activeProfileId === id && (
          <MapProfileCard
            studio={studio}
            engineer={engineer}
            mapQuadrant={getMapQuadrantEnum()}
          />
        )}
      </>
    </OverlayView>
  );
};
