import confetti from "canvas-confetti";

export const bottomLeftConfetti = () => {
  void confetti({
    angle: 55,
    spread: 70,
    origin: { y: 1, x: 0 },
    zIndex: 2000,
  });
};
