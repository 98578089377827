import styled from "styled-components";

export const StyledPlaylistElementContainer = styled.div`
  display: flex;
  padding: 8px;
  width: 100%;
  height: 54px;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const StyledPlaylistElementImg = styled.img`
  border-radius: var(--border-radius-md);
  background-color: ${({ theme }) => theme.colorPalette.White};
`;

export const StyledPlaylistElementTitle = styled.p`
  color: ${({ theme }) => theme.colorPalette.White};
  font-size: ${({ theme }) => theme.textSizeMd};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  line-height: 20px;
`;

export const StyledPlaylistElementText = styled.p`
  color: ${({ theme }) => theme.colorPalette.Gray400};
  font-size: ${({ theme }) => theme.textSizeSm};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  line-height: 14px;
`;

export const StyledPlaylistElementIndex = styled(StyledPlaylistElementTitle)<{
  $isPlaying?: boolean;
}>`
  text-align: center;
  font-weight: 500;
  color: ${({ theme, $isPlaying }) =>
    $isPlaying ? theme.colorPalette.Green600 : theme.colorPalette.White};
`;

export const StyledPlaylistElementTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
`;
