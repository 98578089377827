import styled from "styled-components";
import { Text } from "../../core-ui/components/Text/Text";

export const PaymentGatewayModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PaymentGatewayModuleH6 = styled(Text)`
  margin-bottom: 8px;
`;

export const PaymentGatewayModuleP = styled.p`
  color: ${({ theme }) => theme.textSecondaryColor};
  font-family: "Roobert", sans-serif;
  font-size: ${({ theme }) => theme.textSizeMd};
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 16px;
`;

export const PaymentGatewayModuleRefundPolicy = styled.p`
  color: ${({ theme }) => theme.textSecondaryColor};
  font-family: "Roobert", sans-serif;
  font-size: ${({ theme }) => theme.textSizeXs};
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 16px;
`;

export const PaymentGatewayModuleBoldP = styled(PaymentGatewayModuleP)`
  font-weight: 600;
  color: ${({ theme }) => theme.textPrimaryColor};
`;

export const PaymentGatewayModuleFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 480px) {
    &.footer-actions {
      flex-wrap: wrap;
    }
  }
`;

export const PaymentGatewayModuleFooterActionsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  gap: 16px;
`;

export const PaymentPlanInProgressContainer = styled.div`
  button,
  a {
    margin-bottom: 20px;
  }
`;

export const PaymentGatewayModuleDrawer = styled.div<{
  $navHeight?: number;
}>`
  & .drawer {
    background: ${({ theme }) => theme.foregroundColor} !important;
  }
  & .drawer__content {
    max-height: calc(99vh - ${({ $navHeight = 0 }) => $navHeight + 25}px);
    @supports (max-height: 100dvh) {
      max-height: calc(99dvh - ${({ $navHeight = 0 }) => $navHeight + 25}px);
    }
  }
`;
