import useUserLocation from "./hooks/useUserLocation";
import useUTM from "./hooks/useUTM";
import useErrorToasts from "./hooks/useErrorToasts";
import useUserFavorites from "./hooks/useUserFavorites";
import useLoadUser from "./hooks/useLoadUser";
import ChatSuspense from "../ChatInjector/ChatSuspense";
import { AppBarHeader } from "../AppBarHeader/AppBarHeader";
import UiContent from "../../../UiContent";
import useBodyDarkMode from "./hooks/useBodyDarkMode";
import useIdentifyUser from "./hooks/useIdentifyUser";
import { memo, useEffect, useMemo } from "react";
import { atom, useAtomValue, useSetAtom } from "jotai";
import OnlineHeartbeat from "../ErrorBoundary/OnlineHeartbeat";
import useSyncProfileState from "./hooks/useSyncProfileState";
import usePowerUserControls from "./hooks/usePowerUserControls";

// Each component in this file encompasses a hook that needs to be memoized to prevent re-rendering on every state change
// While there may be a way to combined these memoized components into a single component, it's easier to keep them separate for now
const IdentifyUser = () => {
  useIdentifyUser();
  return null;
};

const MemoizedIdentifyUser = memo(IdentifyUser);

const BodyDarkMode = () => {
  useBodyDarkMode();
  return null;
};

const MemoizedBodyDarkMode = memo(BodyDarkMode);

const ErrorToasts = () => {
  useErrorToasts();
  return null;
};

const MemoizedErrorToasts = memo(ErrorToasts);

const UserLocation = () => {
  useUserLocation();
  return null;
};

const MemoizedUserLocation = memo(UserLocation);

const UTM = () => {
  useUTM();
  return null;
};

const MemoizedUTM = memo(UTM);

const UserFavorites = () => {
  useUserFavorites();
  return null;
};

const MemoizedUserFavorites = memo(UserFavorites);

const SyncProfileState = () => {
  useSyncProfileState();
  return null;
};

const MemoizedSyncProfileState = memo(SyncProfileState);

// This atom is used to prevent the page from flashing when the app state changes
const isInitialLoadAtom = atom(true);

// By setting the atom in this component only when the value of useLoadUser changes, we prevent unnecessary re-renders whenever the useLoadUser hook recalculates
const LoadUser = () => {
  const setIsInitialLoad = useSetAtom(isInitialLoadAtom);
  const hideMainContent = useLoadUser();
  useEffect(() => {
    setIsInitialLoad(hideMainContent);
  }, [setIsInitialLoad, hideMainContent]);
  return null;
};

const MemoizedLoadUser = memo(LoadUser);

const PowerUserControls = () => {
  usePowerUserControls();
  return null;
};

const MemoizedPowerUserControls = memo(PowerUserControls);

// App State itself is also memoized to prevent unnecessary re-renders. This prevents the page from flashing when the app state changes
const AppState = () => {
  const hideMainContent = useAtomValue(isInitialLoadAtom);
  const pageContent = useMemo(() => {
    return (
      <>
        <AppBarHeader isEmptyHeader={hideMainContent} />
        {!hideMainContent && <UiContent />}
      </>
    );
  }, [hideMainContent]);
  return (
    <OnlineHeartbeat>
      <MemoizedSyncProfileState />
      <MemoizedIdentifyUser />
      <MemoizedErrorToasts />
      <MemoizedUTM />
      <MemoizedUserLocation />
      <MemoizedBodyDarkMode />
      <MemoizedUserFavorites />
      <MemoizedLoadUser />
      <MemoizedPowerUserControls />
      <ChatSuspense>{pageContent}</ChatSuspense>
    </OnlineHeartbeat>
  );
};

export default memo(AppState);
