import {
  MasteringTransitions,
  MixingTransitions,
} from "../../../../../store/models/project";

/**
 * Defines the action messages that are shared between Mixing and Mastering
 */
export const projectHistoryCommonMessages = {
  ["no_transition"]: "Booking created",
  ["booking_requested"]: "Booking requested",
  ["project_file_uploaded"]: "File uploaded",
  ["project_file_deleted"]: "File deleted",
  [MixingTransitions.ENGINEER_REJECT_FILES]: "File re-upload requested",
  [MixingTransitions.ENGINEER_APPROVE_FILES]: "Files approved",
  [MixingTransitions.ENGINEER_ACCEPT_PROJECT]: "Project accepted",
  [MixingTransitions.ENGINEER_UPLOAD_PROJECT_FILES]: "Project files uploaded",
  [MixingTransitions.TOGGLE_PROJECT_FILES]: "File uploader opened",
  [MixingTransitions.ENGINEER_SKIP_UPLOAD_FILES]: "File upload skipped",
  [MixingTransitions.REQUEST_ASSET_REUPLOAD]: "New final stems requested",
};

/**
 * Defines the action messages for Mixing Projects
 */
export const projectHistoryMixMessages: Record<string, string> = {
  ...projectHistoryCommonMessages,
  [MixingTransitions.ARTIST_UPLOAD_REFERENCE]: "Reference uploaded",
  [MixingTransitions.ARTIST_SKIP_REFERENCE_UPLOAD]: "Reference upload skipped",
  [MixingTransitions.ENGINEER_REJECT_REFERENCE]:
    "Reference re-upload requested",
  [MixingTransitions.ENGINEER_UPLOAD_REFERENCE]: "Reference uploaded",
  [MixingTransitions.GO_BACK_TO_REF_UPLOAD]: "Revert to reference upload",
  [MixingTransitions.ARTIST_UPLOAD_FILES]: "Files transferred",
  [MixingTransitions.ARTIST_SKIP_FILE_UPLOADS]: "File upload skipped",
  [MixingTransitions.ARTIST_ACCEPT_MIX]: "Mix accepted",
  [MixingTransitions.ARTIST_REJECT_MIX]: "Mix revision requested",
  [MixingTransitions.ENGINEER_UPLOAD_INITIAL_MAIN]: "Mix uploaded",
  [MixingTransitions.ENGINEER_UPLOAD_MIX]: "Mix sent for review",
  [MixingTransitions.ENGINEER_UPLOAD_STEMS]: "Stems transferred",
  [MixingTransitions.ADD_MIX_NOTES]: "Mix notes added",
  [MixingTransitions.ENGINEER_SKIP_UPLOAD_REFERENCE]:
    "Reference upload skipped",
};

/**
 * Defines the action messages for Mastering Projects
 */
export const projectHistoryMasterMessages: Record<string, string> = {
  ...projectHistoryCommonMessages,
  [MasteringTransitions.ARTIST_UPLOAD_FILE]: "Files transferred",
  [MasteringTransitions.ARTIST_SKIP_UPLOAD_FILES]: "File upload skipped",
  [MasteringTransitions.ENGINEER_UPLOAD_INITIAL_MAIN]: "Master uploaded",
  [MasteringTransitions.ARTIST_ACCEPTING_MASTER]: "Master accepted",
  [MasteringTransitions.ARTIST_REJECTING_MASTER]: "Master revision requested",
  [MasteringTransitions.ENGINEER_REFUND_PROJECT]: "Project cancelled",
  [MasteringTransitions.ENGINEER_ACCEPT_AND_UPLOAD_FILES]: "Files uploaded",
  [MasteringTransitions.ENGINEER_UPLOAD_MAIN]: "Master sent for review",
  [MasteringTransitions.ENG_UPLOADING_ALTS_TRANSITION]: "Alts transferred",
  [MasteringTransitions.ADD_ARTIST_MASTER_NOTES]: "Master notes added",
};
