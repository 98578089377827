import React, { useState, FC, ReactElement, useEffect, useMemo } from "react";
import { Rating } from "react-simple-star-rating";

import "./star-ratings.css";
import { ColorPalette } from "../../theme";

export interface IStarRatingProps {
  currentRating: number;
  numberOfReview?: number;
  isEditable?: boolean;
  color?: string;
  showText?: boolean;
  onClick?: (value: number) => void;
  isMobile?: boolean;
  textTemplate?: StarRatingTextTemplate;
}

export enum StarRatingTextTemplate {
  numberOfReviews = "(numberOfReview)",
  currentRating = "[current_rating]/[number_of_stars]   ",
}
const NUMBER_OF_ICONS = 5;

export const StarRatings: FC<IStarRatingProps> = ({
  currentRating = 0,
  numberOfReview = 0,
  isEditable = false,
  color = ColorPalette.BoomyOrange400,
  showText = true,
  onClick = () => {},
  isMobile = false,
  textTemplate = StarRatingTextTemplate.numberOfReviews,
}): ReactElement => {
  const [rating, setRating] = useState(currentRating);

  useEffect(() => {
    setRating(currentRating);
  }, [currentRating]);

  const startRatingText = useMemo(() => {
    switch (textTemplate) {
      case StarRatingTextTemplate.currentRating:
        if (isMobile) {
          return StarRatingTextTemplate.numberOfReviews.replace(
            "numberOfReview",
            numberOfReview.toString(),
          );
        }
        return textTemplate
          .replace("[number_of_stars]", NUMBER_OF_ICONS.toString())
          .replace("[current_rating]", String(currentRating.toFixed(1)));
      case StarRatingTextTemplate.numberOfReviews:
        return textTemplate.replace(
          "numberOfReview",
          numberOfReview.toString(),
        );
    }
  }, [textTemplate, numberOfReview, currentRating, isMobile]);

  const handleRating = (value: number) => {
    if (onClick) onClick(value / 20);
  };

  return (
    <div className={"star-rating ".concat(isMobile ? "mobile" : "")}>
      <span
        className={"star-rating-text ".concat(isMobile ? "mobile" : "")}
        style={{ display: !showText || isEditable ? "none" : "inline" }}
      >
        {startRatingText}
      </span>
      <div className="star-on">
        <Rating
          onClick={handleRating}
          // @ts-ignore
          ratingValue={(rating / NUMBER_OF_ICONS) * 100}
          iconsCount={NUMBER_OF_ICONS}
          readonly={!isEditable}
          allowHalfIcon={false}
          size={isMobile ? 16 : 24.28}
          fillColor={color}
          showTooltip={false}
          transition={true}
        />
      </div>
    </div>
  );
};
