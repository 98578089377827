import * as Popover from "@radix-ui/react-popover";
import { useState } from "react";
import { SessionModificationDetails } from "../../../hooks/recordingSessionHooks/useGetSessionModificationDetails";
import useModal from "../../../hooks/useModal";
import { RecordingSession } from "../../../store/models/recordingSession";
import User from "../../../store/models/user";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  SessionModificationPopoverContent,
  SESSION_MODIFICATION_OPTION,
} from "./SessionModificationPopoverContent";

interface SessionModificationPopoverInterface {
  recordingSession: RecordingSession;
  isArtist: boolean;
  currentUser: User;
  sessionModificationDetails: SessionModificationDetails;
}

export const SessionModificationPopover = ({
  recordingSession,
  isArtist,
  currentUser,
  sessionModificationDetails,
}: SessionModificationPopoverInterface) => {
  const {
    isOpen: isPopoverOpen,
    closeModal: closePopover,
    setIsOpen: setIsPopoverOpen,
  } = useModal();

  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  const [selectedOption, setSelectedOption] =
    useState<SESSION_MODIFICATION_OPTION>(
      SESSION_MODIFICATION_OPTION.NO_OPTION,
    );

  const titleMapping: Record<SESSION_MODIFICATION_OPTION, string> = {
    [SESSION_MODIFICATION_OPTION.NO_OPTION]: "Modify Recording Session",
    [SESSION_MODIFICATION_OPTION.EXTEND_SESSION]: "Extend Recording Session",
    [SESSION_MODIFICATION_OPTION.RESCHEDULE_SESSION]:
      "Reschedule Recording Session",
    [SESSION_MODIFICATION_OPTION.CANCEL_SESSION]: "Cancel Recording Session",
  };

  const hasPendingModificationRequest =
    sessionModificationDetails.userHasPendingRequest ||
    sessionModificationDetails.sessionHasPendingModificationRequest;

  const getPopoverDescription = () => {
    if (
      selectedOption === SESSION_MODIFICATION_OPTION.NO_OPTION &&
      !hasPendingModificationRequest
    ) {
      return "Request a change in your session. All parties will be notified";
    }

    return "";
  };

  // Since the value from useExtendSession() is used in both footer and body,
  // if we place that hook here, the states inside will never be reset on popover close
  // We need to place that hook inside the body, but since it's shared with the footer,
  // we need to create our own footer, and not relying on the one provided by BasePopover
  return (
    <BasePopover
      isOpen={isPopoverOpen}
      setIsPopoverOpen={(isOpen) => {
        setSelectedOption(SESSION_MODIFICATION_OPTION.NO_OPTION);
        setIsPopoverOpen(isOpen);
      }}
      side="top"
      title={titleMapping[selectedOption]}
      description={getPopoverDescription()}
      additionalContent={
        <SessionModificationPopoverContent
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          recordingSession={recordingSession}
          closePopover={closePopover}
          isArtist={isArtist}
          currentUser={currentUser}
          sessionModificationDetails={sessionModificationDetails}
        />
      }
      hideFooter
      wrapperElement={containerElement}
    >
      <Popover.Trigger asChild>
        <Button variant={ButtonVariant.OUTLINED}>
          {hasPendingModificationRequest
            ? "View request details"
            : "Modify Session"}
        </Button>
      </Popover.Trigger>
    </BasePopover>
  );
};
