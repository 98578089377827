import { Channel, Event } from "stream-chat";
import { Dispatch, SetStateAction } from "react";

export const updateChannelUnreadMessagesCount = (
  channel: Channel,
  setChannelUnreadMessagesCount: Dispatch<
    SetStateAction<Record<string, number>>
  >,
) => {
  const updateMessageReadCount = (event: Event) => {
    if (!["message.read", "message.new"].includes(event.type)) {
      return;
    }
    setChannelUnreadMessagesCount((old) => {
      const newData = { ...old };
      if (event.unread_count) {
        newData[channel.cid] = event.unread_count;
      } else if (typeof newData[channel.cid] !== "undefined") {
        delete newData[channel.cid];
      }
      return newData;
    });
  };
  channel.on("message.read", updateMessageReadCount);
  channel.on("message.new", updateMessageReadCount);
  setChannelUnreadMessagesCount((old) => {
    const newData = { ...old };
    const messageCount = channel.countUnread();
    if (messageCount) {
      newData[channel.cid] = messageCount;
    } else if (typeof newData[channel.cid] !== "undefined") {
      delete newData[channel.cid];
    }
    return newData;
  });
};
