import { SCREENS } from "../../routes";

// regex to ensure valid email format
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// TODO: Figure out why this regex is filtering out usernames with dashes and underscores.
// regex to filter out usernames with invalid characters
// export const usernameRegex = /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*$/;

interface SignUpParams {
  register?: boolean;
  email?: string;
  password: string;
}

export const getSubmitDisabled = ({
  register,
  email,
  password,
}: SignUpParams) => {
  if (password.length === 0) {
    return true;
  }
  if (register && !(email && emailRegex.test(email))) {
    return true;
  }
  return false;
};

export const getSignUpEndPoint = (isEngineer: boolean) =>
  isEngineer
    ? SCREENS.SIGN_UP_SCREEN + "?engineer"
    : SCREENS.SIGN_UP_SCREEN + "?artist";
