import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useEffect } from "react";
import { getUserEngineer } from "../store/actions/accountInfo";

export const useGetEngineer = (engineerId: number | undefined) => {
  const engineers = useAppSelector((state) => state.engineers);
  const engineer = engineers[engineerId ?? -1];
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!engineerId) {
      return;
    }
    if (engineer) {
      return;
    }
    void dispatch(getUserEngineer({ engineer_id: engineerId }));
  }, [engineer, engineerId, dispatch]);
  return engineer;
};
