import React, { FC, HTMLAttributes, ReactElement } from "react";
import { toast } from "react-toastify";
import { SoundWaveLoader } from "../SoundWaveLoader/SoundWaveLoader";
import "./button.css";

export type ButtonProps = {
  primary?: boolean;
  backgroundColor?: string;
  size?: "small" | "medium" | "large";
  label: string;
  customClassName?: string;
  onClick?: (e: any) => void;
  disabled?: boolean;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  gradient?: boolean;
  labelIcon?: JSX.Element;
  disableText?: string;
} & Pick<HTMLAttributes<HTMLButtonElement>, "style">;

/**
 * @deprecated
 * Please use `Button` component from `/core-ui/components/Button/Button.tsx` instead.
 */
export const Button: FC<ButtonProps> = ({
  primary = false,
  size = "medium",
  backgroundColor,
  style,
  label,
  disabled = false,
  loading = false,
  customClassName = "",
  type = "button",
  gradient = false,
  labelIcon,
  ...props
}): ReactElement => {
  const mode = primary ? "v1-button-primary" : "v1-button-secondary";
  const gradientBackground = gradient ? "v1-button-gradient" : "";
  const toastId = React.useRef<string | number>("");

  const labelElement = labelIcon ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {label}
      <span style={{ marginLeft: "4px" }}>{labelIcon}</span>
    </div>
  ) : (
    label
  );

  const showDisableToast = () => {
    if (!disabled || !props.disableText) return;
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(props.disableText);
    }
  };

  return (
    <button
      onMouseOver={() => showDisableToast()}
      disabled={disabled || loading}
      type={type}
      className={[
        "v1-button",
        `v1-button--${size}`,
        mode,
        gradientBackground,
        customClassName,
      ].join(" ")}
      style={{ backgroundColor, ...style }}
      {...props}
    >
      {loading ? (
        <SoundWaveLoader whiteLoader={primary} width={50} height={12} />
      ) : (
        labelElement
      )}
    </button>
  );
};
