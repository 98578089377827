import styled from "styled-components";

export const VerifyEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5rem;
  text-align: center;
  box-sizing: border-box;
`;
