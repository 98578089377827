import { Review } from "../../../store/models/review";
import { UserProfile } from "../../elements/UserProfile/UserProfile";
import { StarRatings } from "../../elements/StarRatings/StarRatings";
import "./ReviewComponent.css";
import { getMonthAndYearFromDate } from "../../../store/utils/dateTimeUtils";
import { ExpandableTextArea } from "../../elements/ExpandableTextArea/ExpandableTextArea";

export interface ReviewComponentProps {
  review: Review;
}

export const ReviewComponent = ({ review }: ReviewComponentProps) => {
  return (
    <div className="review-component-container">
      <div>
        {review.reviewer_user && <UserProfile user={review.reviewer_user} />}
      </div>
      <div className="review-component-rating">
        <StarRatings
          showText={false}
          isMobile={true}
          currentRating={review.rating}
          color={"var(--black)"}
        />
        <div className="review-component-date">
          {getMonthAndYearFromDate(new Date(review.created))}
        </div>
      </div>
      {review.review && (
        <ExpandableTextArea body={review.review} maxHeight={75} />
      )}
    </div>
  );
};
