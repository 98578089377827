import CircularProgress from "@mui/material/CircularProgress";

import { MouseEventHandler, useCallback, useRef, useState } from "react";
import {
  FOOTER_WAVESURFER_REF_ID,
  useGenerateWaveformForFooter,
} from "../../../../hooks/audioPlayerHooks/waveformHooks";
import { useAppSelector } from "../../../../store/hooks";
import {
  getCurrentTrackBlobUrlIsLoading,
  getCurrentTrackDownloadedProjectBlob,
  getCurrentTrackDownloadedProjectBlobMp3s,
} from "../../../../store/selectors/abPlayerSelectors";
import { Text } from "../../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../../core-ui/components/Text/TextUtils";
import { useAddStyleToShadowRoot } from "../../../../hooks/audioPlayerHooks/useAddStyleToShadowRoot";
import {
  StyledFooterWaveformContainer,
  StyledFooterWaveformP,
  StyledWaveform,
  StyledWaveformLoadingLosslessAudioContainer,
} from "./FooterWaveform.styles";
import { useResizeObserver } from "usehooks-ts";
import { Size } from "../../SessionScheduleGuide/SessionScheduleGuide";

export const FooterWaveform = () => {
  const footerWaveformParentRef = useRef<HTMLDivElement>(null);
  const { waveformRef, currentSeekTime, duration } =
    useGenerateWaveformForFooter();
  const bloblUrlMp3 = useAppSelector(
    getCurrentTrackDownloadedProjectBlobMp3s(),
  );
  const url = useAppSelector((state) => state.abPlayerStore.url);
  const blobUrl = useAppSelector(getCurrentTrackDownloadedProjectBlob());
  const isCurrentTrackBlobUrlIsLoading = useAppSelector(
    getCurrentTrackBlobUrlIsLoading(),
  );

  const stopBubble: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const [width, setWidth] = useState(0);
  const onResize = useCallback(
    (size: Size) => {
      setTimeout(() => {
        setWidth(size.width || 0);
      }, 0);
    },
    [setWidth],
  );
  useResizeObserver({
    ref: footerWaveformParentRef,
    box: "border-box",
    onResize,
  });

  useAddStyleToShadowRoot(
    FOOTER_WAVESURFER_REF_ID,
    width ? `${width}px` : undefined,
  );

  return (
    <StyledFooterWaveformContainer onClick={stopBubble}>
      {waveformRef.current && (
        <StyledFooterWaveformP>{currentSeekTime.current}</StyledFooterWaveformP>
      )}
      <StyledWaveform
        ref={footerWaveformParentRef}
        id={FOOTER_WAVESURFER_REF_ID}
      />

      {waveformRef.current && (
        <StyledFooterWaveformP>{duration}</StyledFooterWaveformP>
      )}
      {url === bloblUrlMp3 && (
        <StyledWaveformLoadingLosslessAudioContainer>
          {" "}
          {isCurrentTrackBlobUrlIsLoading ? (
            <>
              <Text variant={TextStyleVariant.P1} color={TextColor.WHITE}>
                Loading Lossless Audio
              </Text>
              <CircularProgress
                size={16}
                className="lossless-audio-loading-circle"
              />
            </>
          ) : blobUrl === null ? (
            <Text variant={TextStyleVariant.P1} color={TextColor.WHITE}>
              Could not load lossless audio. Playing MP3 version
            </Text>
          ) : null}
        </StyledWaveformLoadingLosslessAudioContainer>
      )}
    </StyledFooterWaveformContainer>
  );
};
