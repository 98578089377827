import { formatDistanceToNow } from "date-fns";

export const formatDateToShort = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${month}/${day}/${year.toString().slice(2)}`;
};

const formatTime = (date: Date) => {
  const minutes = date.getMinutes();

  // Check if minutes are greater than 0
  const includeMinutes = minutes > 0;

  const time = date.toLocaleString("en-US", {
    hour12: true,
    hour: "numeric",
    ...(includeMinutes && { minute: "numeric" }),
  });

  return time;
};

export const formatDateToShortWithTime = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")}/${year.toString().slice(2)} ${formatTime(date)}`;
};

export const formatDateToTime = (date: Date) => {
  return formatTime(date);
};

export const formatDistanceToNowShort = (date: Date | number) => {
  const distance = formatDistanceToNow(date, { addSuffix: true });
  return distance.replace(
    /\b(about|minutes?|hours?|days?|months?|years?|less than a minute ago?)\b/g,
    (match) => {
      switch (match) {
        case "about":
          return "";
        case "minutes":
        case "minute":
          return "min";
        case "hours":
        case "hour":
          return "hr";
        case "days":
        case "day":
          return "d";
        case "months":
        case "month":
          return "mo";
        case "years":
        case "year":
          return "yr";
        case "less than a minute ago":
          return "just now";
        default:
          return match;
      }
    },
  );
};
