import "./GenerateBookingSelectServiceDropdown.css";

import { Text, TEXT_COLOR } from "../../core-ui/components/Text/Text";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { FixedRangeNumberField } from "../../elements/FixedRangeNumberField/FixedRangeNumberField";
import { useAppDispatch } from "../../../store/hooks";
import { MAX_SONGS_PER_SCHEDULED_PROJECT } from "../../../store/utils/serviceUtils";

export interface GenerateBookingSelectServiceDropdownProps {
  options: OptionType[];
  selectedOption: OptionType | null;
  onSelectOption: (_: OptionType) => void;
  quantity: number;
  incrementQuantity: () => void;
  decrementQuantity: () => void;
  disabled: boolean;
  setQuantity: (value: number) => void;
}

const GenerateBookingSelectServiceDropdown = ({
  options,
  selectedOption,
  onSelectOption,
  quantity,
  decrementQuantity,
  incrementQuantity,
  disabled,
  setQuantity,
}: GenerateBookingSelectServiceDropdownProps) => {
  const cssPrefix = "generate-booking-select-service-dropdown_";
  const dispatch = useAppDispatch();

  const handleQuantitySet = (updatedValue: number) => {
    if (Number.isNaN(updatedValue)) return;
    if (updatedValue === quantity) return;
    dispatch(setQuantity(updatedValue));
  };

  return (
    <div className={`${cssPrefix}container`}>
      <div className={`${cssPrefix}quantity-container`}>
        <button
          className={`${cssPrefix}quantity-selector`}
          onClick={decrementQuantity}
          disabled={disabled}
        >
          <Text color={TEXT_COLOR.SECONDARY}>-</Text>
        </button>
        <FixedRangeNumberField
          initialValue={quantity}
          onChange={(value) => handleQuantitySet(value)}
          min={1}
          disabled={disabled}
          isCurrentValueAsync={true}
          max={MAX_SONGS_PER_SCHEDULED_PROJECT}
          className={`${cssPrefix}number-quantity-container `}
          style={{ width: "20px" }}
        />
        <button
          className={`${cssPrefix}quantity-selector`}
          onClick={incrementQuantity}
          disabled={disabled}
        >
          <Text color={TEXT_COLOR.SECONDARY}>+</Text>
        </button>
      </div>
      <div className={`${cssPrefix}dropdown-container`}>
        <DropdownSelector
          options={options}
          customClassNames={{
            container: `${cssPrefix}dropdown-element-container`,
            selectContainer: `${cssPrefix}dropdown-element-select-container`,
            labelText: `${cssPrefix}element-label-text`,
            arrowContainer: `${cssPrefix}element-arrow-container`,
          }}
          placeholder="Select a service"
          onChange={onSelectOption}
          value={selectedOption}
          isDisabled={disabled}
        />
      </div>
    </div>
  );
};

export default GenerateBookingSelectServiceDropdown;
