import { Grid } from "@mui/material";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useIsStudioManager } from "../../../../../hooks/bookingHooks/useStudioEngineerUser";
import { useGetAffiliatedStudiosForUser } from "../../../../../hooks/profileScreenHooks/useGetAffiliatedStudiosForUser";
import { useGetUserProfile } from "../../../../../hooks/profileScreenHooks/useGetUserProfile";
import { getStudioScreenRoute } from "../../../../../store/utils/routeGetters";
import { getStudioProfileCard } from "../../../../components/ProfileCard/ProfileCard";
import { Text } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import {
  StudioBadgeSkeleton,
  StudioImageSkeleton,
  StudioSkeletonBox,
  StudiosSectionBox,
  StudioTextSkeleton,
} from "./StudioSection.styles";

const ProfileCardLoadingState = () => {
  return (
    <StudioSkeletonBox>
      <StudioImageSkeleton variant="rounded" />
      <StudioTextSkeleton />
      <StudioTextSkeleton />
      <StudioBadgeSkeleton />
    </StudioSkeletonBox>
  );
};

export const StudiosSection = () => {
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const isUserEngineer = Boolean(
    userData?.engineer && !userData?.engineer.deleted,
  );
  const isStudioManager = useIsStudioManager(userData);
  const isEnabled = Boolean(userData) && (isUserEngineer || isStudioManager);
  const {
    data: affiliatedStudios,
    isLoading: isLoadingAffiliatedStudios,
    isSuccess,
  } = useGetAffiliatedStudiosForUser({
    userId: userData!.id,
    enabled: isEnabled,
  });

  if (!isEnabled || (isSuccess && !affiliatedStudios?.length)) return null;

  return (
    <StudiosSectionBox>
      <Text variant={TextStyleVariant.S2}>Studios</Text>
      {isLoadingAffiliatedStudios ? (
        <Grid container>
          <Grid item md={4} xs={12}>
            <ProfileCardLoadingState />
          </Grid>
        </Grid>
      ) : (
        <div className="verified-list-container">
          {affiliatedStudios?.map((studio, index) => (
            <Fragment key={index}>
              {getStudioProfileCard(
                studio,
                getStudioScreenRoute(studio.username),
                false,
              )}
            </Fragment>
          ))}
        </div>
      )}
    </StudiosSectionBox>
  );
};
