import React from "react";
import { Meta, Story } from "@storybook/react";
import { Button, ButtonProps } from "./button";
import "../../../index.css";

export default {
  component: Button,
  title: "elements/Button",
} as Meta;

const Template: Story<ButtonProps> = (args) => <Button {...args} />;

export const PrimaryButton = Template.bind({});
PrimaryButton.args = {
  primary: true,
  label: "Sign in",
};

export const NonPrimaryButton = Template.bind({});
NonPrimaryButton.args = {
  primary: false,
  label: "Sign up",
};

export const GradientButton = Template.bind({});
GradientButton.args = {
  primary: true,
  label: "Sign up",
  gradient: true,
};

export const ButtonWithOptionalParams = Template.bind({});
ButtonWithOptionalParams.args = {
  primary: false,
  label: "Sign up",
  backgroundColor: "red",
  size: "small",
};

export const DisabledButton = Template.bind({});
DisabledButton.args = {
  primary: false,
  label: "Sign up",
  disabled: true,
  disableText:
    "This sentence will be long so that we can see how big the text displayed",
};

const RowTemplate: Story<ButtonProps> = (args) => (
  <div>
    <Button {...args} />
    <Button primary={false} label="sign up" />
  </div>
);

export const UseMarginOnButton = RowTemplate.bind({});
UseMarginOnButton.args = {
  primary: false,
  label: "Sign up",
  backgroundColor: "red",
  size: "small",
  style: {
    marginRight: 20,
  },
};
