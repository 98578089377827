import { useGoogleLogin } from "@react-oauth/google";
import React, { FC } from "react";
import { toast } from "react-toastify";
import {
  connectGoogleTokens,
  getCalendarIntegrationStatus,
} from "../../../store/actions/calendarService";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import GoogleCalendarIcon from "../../assets/google-calendar-icon.png";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { defaultPageLoaderSize } from "../../theme";
import "./CalendarSetupView.css";

export interface CalendarSetUpViewPropsV1 {
  studioID?: number;
}
export const CalendarSetUpViewV1: FC<CalendarSetUpViewPropsV1> = ({
  studioID,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoadingStatus] = React.useState(true);
  const calendarIntegrated: boolean = useAppSelector(
    (state) => state.calendarService.calendarIntegrated,
  );

  const login = useGoogleLogin({
    onError: () => {
      toast.error(
        "Something went wrong. Please try again or reach out to customer support.",
      );
      setLoadingStatus(false);
    },
    onSuccess: async (codeResponse) => {
      const { code } = codeResponse;
      dispatch(connectGoogleTokens({ code, studio_id: studioID }))
        .unwrap()
        .then((data) => {
          if (data.calendar_integrated) {
            toast.success("Your google calendar has been linked!");
          } else {
            toast.error(
              "Something went wrong. Make sure to allow Engineears to access your google calendar.",
            );
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoadingStatus(false);
        });
    },
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/calendar",
  });

  const attemptToConnectGoogle = () => {
    setLoadingStatus(true);
    login();
  };

  React.useEffect(() => {
    setLoadingStatus(true);
    dispatch(
      getCalendarIntegrationStatus({
        studio_id: studioID,
      }),
    )
      .unwrap()
      .then(({ calendar_integrated }) => {
        if (!calendar_integrated) {
          setLoadingStatus(false);
        }
      })
      .catch(() => {
        setLoadingStatus(false);
      });
  }, [dispatch, studioID]);

  if (loading) {
    return (
      <div className="calendar-setup-loading-container">
        <SoundWaveLoader
          width={defaultPageLoaderSize}
          height={defaultPageLoaderSize}
        />
      </div>
    );
  }
  if (!calendarIntegrated)
    return (
      <div className="calendar-setup-container">
        <p className="b1">
          We use{" "}
          <span className="b1-semi-bold google-product-span">
            Google Calendar
          </span>{" "}
          to schedule your sessions and save them to your calendar.
        </p>
        <button
          className="calendar-setup-button"
          onClick={attemptToConnectGoogle}
        >
          <img src={GoogleCalendarIcon} width={20} height={20} />
          <p className="ml-2">
            Link{" "}
            <span className="b1-semi-bold google-product-span">
              Google Calendar
            </span>
          </p>
        </button>
      </div>
    );
  return <div className="calendar-setup-container"></div>;
};
