import { useMemo } from "react";
import { PurchaseOrderBillingInfoSteps } from "./utils";

interface BillingInfoHookArgs {
  step: PurchaseOrderBillingInfoSteps;
}

export const usePurchaseOrderHeader = ({ step }: BillingInfoHookArgs) => {
  return useMemo(() => {
    switch (step) {
      case PurchaseOrderBillingInfoSteps.SUBMIT_BILLING_INFO:
        return "Submit Billing Information";
      case PurchaseOrderBillingInfoSteps.CONFIRM_BILLING_INFO:
        return "Confirm Billing Information";
      case PurchaseOrderBillingInfoSteps.BILLING_INFO_SUBMITTED:
        return "Billing Information Submitted";
    }
  }, [step]);
};

export const useConfirmTextForPurchaseOrderModal = ({
  step,
}: BillingInfoHookArgs) => {
  return useMemo(() => {
    switch (step) {
      case PurchaseOrderBillingInfoSteps.SUBMIT_BILLING_INFO:
        return "Submit Billing Information";
      case PurchaseOrderBillingInfoSteps.CONFIRM_BILLING_INFO:
        return "Confirm Billing Information";
      case PurchaseOrderBillingInfoSteps.BILLING_INFO_SUBMITTED:
        return "Close";
    }
  }, [step]);
};

export const useCancelTextForPurchaseOrderModal = ({
  step,
}: BillingInfoHookArgs) => {
  return useMemo(() => {
    switch (step) {
      case PurchaseOrderBillingInfoSteps.SUBMIT_BILLING_INFO:
        return "Cancel";
      case PurchaseOrderBillingInfoSteps.CONFIRM_BILLING_INFO:
        return "Edit Billing Information";
      case PurchaseOrderBillingInfoSteps.BILLING_INFO_SUBMITTED:
        return undefined;
    }
  }, [step]);
};
