import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

import { createStudioRoom } from "../../../store/actions/studio";
import { useAppDispatch } from "../../../store/hooks";
import {
  MINIMUM_NUMBER_OF_GUESTS_FOR_STUDIO_ROOM,
  Studio,
  StudioRoom,
} from "../../../store/models/studio";
import { getStudioRoomScreenRoute } from "../../../store/utils/routeGetters";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { EditableTextField } from "../../elements/EditableTextField/EditableTextField";
import { BaseModal } from "../BaseModal/BaseModal";
import "./StudioModals.css";

export interface CreateStudioRoomProps {
  studio?: Studio;
  show: boolean;
  showXButton: boolean;
  onHide: () => void;
}

export const CreateStudioRoomModal = ({
  onHide,
  studio,
  show,
  showXButton,
}: CreateStudioRoomProps) => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useMediaQueryBreakpoint();
  const closeIconSize = useMemo(() => (!isDesktop ? "lg" : "2xl"), [isDesktop]);
  const [loading, setLoading] = useState(false);
  const [roomName, setRoomName] = useState<string>("");
  const history = useHistory();

  const handleCreateStudioRoom = useCallback(async () => {
    if (!studio?.id || !studio?.username) {
      return;
    }
    try {
      setLoading(true);
      const result = await dispatch(
        createStudioRoom({
          studio_id: studio.id,
          room_name: roomName,
          equipment_highlights: "",
          username: studio?.username,
          max_number_of_guests: MINIMUM_NUMBER_OF_GUESTS_FOR_STUDIO_ROOM,
        }),
      );
      const studio_room = result.payload as StudioRoom;
      history.push(getStudioRoomScreenRoute(studio.username, studio_room.id));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to create studio room");
    }
  }, [dispatch, history, roomName, studio]);

  return (
    <BaseModal
      hideCloseButton={true}
      modalIsOpen={show}
      label={"create studio room modal"}
    >
      <div className={"studio-modal studio-room-modal"}>
        <div className={"modal-header"}>
          <h2 className={"h3 mx-4"}>Add a Room</h2>
          {showXButton && (
            <FontAwesomeIcon
              color={"var(--black)"}
              width={30}
              height={30}
              icon={faTimes}
              size={closeIconSize}
              className={"close-modal-button mx-2"}
              onClick={onHide}
            />
          )}
        </div>
        <div className={"modal-body"}>
          <p className={"b1-semi-bold"}>Room Name</p>
          <EditableTextField
            initialValue={""}
            label={"room name (ex. Studio A)"}
            onChange={(val) => {
              setRoomName(val);
            }}
            editMode={true}
          />
        </div>
        <div className={"modal-header"}>
          <Button
            loading={loading}
            className={"mx-2 studio-save-button"}
            onClick={handleCreateStudioRoom}
            variant={ButtonVariant.PRIMARY}
          >
            Create Room
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
