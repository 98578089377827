import { useMemo } from "react";
import { useAppSelector } from "../../../store/hooks";
import { getImageURLFromPath } from "../../../hooks/useImageURLFromPath";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";

export const useChatUserData = () => {
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  return useMemo(() => {
    if (!loggedInUser) return undefined;
    let image: string | undefined = undefined;
    if (loggedInUser.photo)
      image = getImageURLFromPath(false, loggedInUser.photo.path);
    const name = getDisplayableNameForUser(loggedInUser);
    return {
      id: String(loggedInUser.id),
      name,
      username: loggedInUser.username,
      image: image || "",
    };
  }, [loggedInUser]);
};

export default useChatUserData;
