import { faStarSharp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { ReviewState } from "../../../store/models/review";
import { Studio, StudioRoom } from "../../../store/models/studio";
import { BadgeEnum, Trophy } from "../../../store/models/trophy";
import { SaveProfileButton } from "../../components/FavoriteHeart/FavoriteHeart";
import { TrophyListItem } from "../../components/TrophyRoomList/TrophyRoomList";
import { ShareButton } from "../../elements/ShareButton/ShareButton";
import "./StudioScreen.css";
import styled from "styled-components";

interface StudioHeaderMetadataProps {
  studio: Studio;
  studioRoom?: StudioRoom;
  stats: ReviewState | undefined;
  shareUrl: string;
  trophies: Trophy[] | undefined;
}

const NotVerified = styled.div`
  padding-top: 8px;
  a {
    color: black;
  }
`;

const StudioHeaderMetadata = ({
  studio,
  studioRoom,
  stats,
  shareUrl,
  trophies,
}: StudioHeaderMetadataProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();

  const dolbyTrophy = useMemo(() => {
    return trophies?.find(
      (trophy) =>
        trophy.badgeEnum === BadgeEnum.DOLBY_ATMOS_CERTIFIED ||
        trophy.badgeEnum === BadgeEnum.DOLBY_ENABLED,
    );
  }, [trophies]);
  const trendingTrophy = useMemo(() => {
    return trophies?.find((trophy) => trophy.badgeEnum === BadgeEnum.TRENDING);
  }, [trophies]);

  const verifiedTrophy = useMemo(() => {
    return trophies?.find((trophy) => trophy.badgeEnum === BadgeEnum.VERIFIED);
  }, [trophies]);

  const sessionsBookedTrophy = useMemo(() => {
    return trophies?.find(
      (trophy) => trophy.badgeEnum === BadgeEnum.N_SESSIONS_BOOKED,
    );
  }, [trophies]);

  const displayTrophies = [
    dolbyTrophy,
    trendingTrophy,
    verifiedTrophy,
    sessionsBookedTrophy,
  ].filter(
    (trophy): trophy is Trophy =>
      trophy !== undefined && trophy.progress === 100,
  );

  return (
    <div className="studio-header-metadata">
      <div>
        <div className="studio-header-metadata-child">
          {stats && (
            <div className="studio-screen-reviews-score-container">
              <FontAwesomeIcon icon={faStarSharp} size="sm" />
              <p className="b1-semi-bold studio-screen-reviews-score">
                {stats.average_rating}
              </p>
              <div className="studio-screen-reviews-score-container__vertical-separator" />
              <p className="b1-semi-bold studio-reviews-count">
                {stats.ratings_count} Reviews{" "}
              </p>
            </div>
          )}
          <p className={"studio-room-location-text"}>
            {studio.location
              ? studio.location.city_location ?? "Location"
              : "Location"}
          </p>
        </div>
        {displayTrophies.length > 0 && (
          <div className="studio-subheader-badges-container">
            {displayTrophies.map((trophy, index) => (
              <>
                {index !== 0 && (
                  <div className="studio-subheader-badges-container__vertical-separator" />
                )}
                <TrophyListItem
                  trophy={trophy}
                  hideText={!isDesktop}
                  idx={index}
                  key={index}
                  showInfo={false}
                  showStatus={false}
                />
              </>
            ))}
          </div>
        )}
        {!verifiedTrophy?.isCompleted && (
          <NotVerified>
            Studio Not Verified.{" "}
            <a
              href="https://support.engineears.com/en/knowledge-base/studio-verification-requirments"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
          </NotVerified>
        )}
      </div>
      <div className="studio-header-metadata-action-btns">
        <ShareButton url={shareUrl} />
        <SaveProfileButton
          liked_studio_room={studioRoom?.id}
          liked_studio={studioRoom ? undefined : studio?.id}
        />
      </div>
    </div>
  );
};

export default StudioHeaderMetadata;
