import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  activated?: boolean;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    console.error("error is", _);
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    // TODO: Fix this. Emergency disable for prod. Figure out the conditions upon which this error boundary hits.
    // if (this.props.activated === true) {
    //   return (
    //     <MaintenanceScreen
    //       title={<h5>You are running an outdated version of the site.</h5>}
    //       subtitle={
    //         <>
    //           <p className="h6">
    //             Refresh the page to load the latest experience.
    //           </p>
    //           {datadogRum.getInternalContext()?.session_id && (
    //             <p>Session ID: {datadogRum.getInternalContext()?.session_id}</p>
    //           )}
    //         </>
    //       }
    //     />
    //   );
    // }

    // if (this.state.hasError) {
    //   return (
    //     <MaintenanceScreen
    //       title={<h5>An error has occurred on the site.</h5>}
    //       subtitle={
    //         <>
    //           <p className="h6">
    //             Please refresh the page. Contact customer service at
    //             info@engineears.com if you require direct assistance.{" "}
    //           </p>
    //           {datadogRum.getInternalContext()?.session_id && (
    //             <p>Session ID: {datadogRum.getInternalContext()?.session_id}</p>
    //           )}
    //         </>
    //       }
    //     />
    //   );
    // }

    return this.props.children;
  }
}

export default ErrorBoundary;
