import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { StudioRoom } from "../store/models/studio";
import { fetchStudioRooms } from "../store/actions/studio";

export const useGetStudioRooms = () => {
  const dispatch = useAppDispatch();
  const [loadingStudioRooms, setLoadingStudioRooms] = useState<boolean>(false);
  const [studioRooms, setStudioRooms] = useState<StudioRoom[]>([]);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);

  useEffect(() => {
    const getStudioRooms = async () => {
      if (!selectedProfile.studio?.username) return;
      setLoadingStudioRooms(true);
      const response = await dispatch(
        fetchStudioRooms({ studio_username: selectedProfile.studio?.username }),
      ).unwrap();
      setStudioRooms(response);
    };
    getStudioRooms()
      .catch(() => {
        setStudioRooms([]);
      })
      .finally(() => {
        setLoadingStudioRooms(false);
      });
    return () => {
      setStudioRooms([]);
      setLoadingStudioRooms(false);
    };
  }, [selectedProfile, dispatch]);
  return {
    loadingStudioRooms,
    studioRooms,
  };
};
