import { useCallback, useMemo } from "react";
import { AvailabilityForDate } from "../store/actions/availability";
import { useAppSelector } from "../store/hooks";
import Engineer from "../store/models/engineer";
import {
  NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
  WEEKDAYS,
} from "../store/models/workingHours";
import {
  getDateTimeFrom15MinuteDayIndex,
  getDisplayableTimeFrom15MinuteDayIndex,
} from "../store/utils/dateTimeUtils";
import { convertAvailabilityForDateToMap } from "../store/utils/utils";
import { OptionType } from "../stories/elements/DropDownSelector/DropdownSelector";
import { useGetRecordingServiceAvailabilities } from "./recordingSessionHooks/useGetAvailabilityOnThisDate";

interface UseGeneralWorkingHoursStringForDayArg {
  user_id?: number;
  studio_room_id?: number;
  day: WEEKDAYS;
}

export interface WorkingHoursOptionType extends OptionType {
  isWorkingHour: boolean;
}
export interface StudioWorkingHoursOptionType extends WorkingHoursOptionType {
  isStudioHour: boolean;
}

export const useAvailabilityForMostAvailableDay = (
  durationMinutes: number,
  studioRoomId?: number,
  engineer?: Engineer,
  timezone?: string,
) => {
  const {
    isLoadingEngAvailabilities,
    isLoadingStudioAvailabilities,
    engineerAvailability,
    studioRoomAvailability,
  } = useGetRecordingServiceAvailabilities(engineer?.user_id, studioRoomId);

  // a function to find the day with the most available hours
  const getMostAvailableDay = useCallback(
    (availabilities: AvailabilityForDate | undefined) => {
      if (!availabilities) {
        return null;
      }

      const availabilityMap = convertAvailabilityForDateToMap(availabilities);
      const values = Array.from(availabilityMap.values());

      return values.reduce((acc: string, curr) => {
        if (!acc) {
          return curr;
        }
        return curr.split("").filter((c) => c === "1").length >
          acc.split("").filter((c) => c === "1").length
          ? curr
          : acc;
      });
    },
    [],
  );

  const mostAvailableStudioHours = useMemo(
    () => getMostAvailableDay(studioRoomAvailability),
    [getMostAvailableDay, studioRoomAvailability],
  );

  // find the most most available hours for the studio room or engineer, or both
  const mostAvailableHours = useMemo(() => {
    // if both studio and engineer have availabilities, return overlapping times.
    if (
      studioRoomAvailability &&
      engineerAvailability &&
      !isLoadingEngAvailabilities &&
      !isLoadingStudioAvailabilities
    ) {
      const overlappingWorkingHours: AvailabilityForDate = {};
      Object.keys(studioRoomAvailability).map((dateString) => {
        // TODO: Update this temporary fix for error where undefined cannot be split.
        const studioHours = studioRoomAvailability[dateString]?.split("") ?? [];
        const userHours = engineerAvailability[dateString]?.split("") ?? [];

        const availability = userHours
          .map((userTime, i) => {
            if (userTime === "1" && studioHours[i] === "1") {
              return "1";
            }
            return "0";
          })
          .join("");

        overlappingWorkingHours[dateString] = availability;
      });
      return getMostAvailableDay(overlappingWorkingHours);
    }

    const mostAvailableUserDay = getMostAvailableDay(engineerAvailability);

    // otherwise, only show studio or engineer availability
    return mostAvailableUserDay
      ? mostAvailableUserDay
      : mostAvailableStudioHours;
  }, [
    studioRoomAvailability,
    engineerAvailability,
    isLoadingEngAvailabilities,
    isLoadingStudioAvailabilities,
    getMostAvailableDay,
    mostAvailableStudioHours,
  ]);

  return useMemo(() => {
    if (!mostAvailableHours) {
      return [];
    }
    const startTimes: StudioWorkingHoursOptionType[] = [];
    const numberOf15MinuteIntervals = durationMinutes / 15;

    for (
      let index = 0;
      index <= NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY;
      index += 2 // increment by 2 to get 30 minute intervals
    ) {
      const currentEndIndex = index + numberOf15MinuteIntervals;
      startTimes.push({
        value: getDateTimeFrom15MinuteDayIndex(index)?.getTime() ?? 0,
        label: `${getDisplayableTimeFrom15MinuteDayIndex(index)} - ${getDisplayableTimeFrom15MinuteDayIndex(currentEndIndex)}`,
        isWorkingHour: mostAvailableHours[index] === "1",
        isStudioHour: mostAvailableStudioHours?.[index] === "1",
      });
    }

    return startTimes;
  }, [mostAvailableHours, durationMinutes, mostAvailableStudioHours]);
};

export const useGeneralWorkingHoursStringForDay = (
  arg: UseGeneralWorkingHoursStringForDayArg,
) => {
  const { user_id, studio_room_id, day } = arg;
  const workingHoursState = useAppSelector((state) => state.workingHours);
  const userWorkingHours = workingHoursState.userWorkingHours[user_id || -1];
  const studioRoomWorkingHours =
    workingHoursState.studioRoomWorkingHours[studio_room_id || -1];

  const workingHoursForSelectedDay = useMemo(() => {
    if (userWorkingHours) {
      return userWorkingHours.find((wh) => wh.day_of_week === day);
    }
    if (studioRoomWorkingHours) {
      return studioRoomWorkingHours.find((wh) => wh.day_of_week === day);
    }
    return null;
  }, [userWorkingHours, studioRoomWorkingHours, day]);

  const startIndex = useMemo(() => {
    if (!workingHoursForSelectedDay) {
      return -1;
    }
    return workingHoursForSelectedDay.availability.indexOf("1");
  }, [workingHoursForSelectedDay]);

  const endIndex = useMemo(() => {
    if (!workingHoursForSelectedDay) {
      return -1;
    }
    return workingHoursForSelectedDay.availability.lastIndexOf("1");
  }, [workingHoursForSelectedDay]);

  return useMemo(() => {
    if (startIndex === -1 || endIndex === -1) {
      return "";
    }
    if (user_id && studio_room_id) {
      throw new Error(
        "You should only pass in either user_id or studio_room_id.",
      );
    }
    return (
      getDisplayableTimeFrom15MinuteDayIndex(startIndex) +
      " - " +
      getDisplayableTimeFrom15MinuteDayIndex(endIndex)
    );
  }, [user_id, studio_room_id, startIndex, endIndex]);
};
