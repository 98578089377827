import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Button } from "../../../../core-ui/components/Button/Button";

export const ProjectTaskRowContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-top: 8px;
`;

export const ProjectTaskMessageContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.projectWorkflowMessageBackgroundColor};
  border-radius: ${({ theme }) => theme.border.radius.xs};
  border: solid 1px ${({ theme }) => theme.projectWorkflowMessageBorderColor};
  display: flex;
  gap: 12px;
  padding: 8px 12px 8px 8px;
`;

export const ProjectTaskIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.textPrimaryColor};
`;

export const ProjectActionsDropdownButton = styled(Button)`
  border: solid 1px ${({ theme }) => theme.separatorColor};
  border-radius: ${({ theme }) => theme.border.radius.xs};
  min-height: unset;
  min-width: 32px;

  &:hover {
    background-color: ${({ theme }) => theme.midgroundColor};
  }
`;

export const ProjectExportInstructionsButton = styled(Button)`
  display: flex;
  gap: 4px;
  min-height: unset;
`;

export const ProjectExportActionsContainer = styled.div`
  display: flex;
  gap: 16px;
`;
