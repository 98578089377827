import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import _ from "lodash";
import queryString from "query-string";
import { ProjectById } from "../models/project";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { getProjectAPIRoute } from "../utils/routeGetters";
import {
  GET_LISTEN_LINK_PROJECT,
  GET_PROJECT,
  GET_UPLOAD_LINK_PROJECT,
} from "../utils/routes";
import { receiveErrors } from "./errorStore";
import {
  artistMasteringTransitions,
  artistMixingTransitions,
  engMasteringTransition,
  engMixTransition,
  markFinalAssetsApproved,
  renameProjectOrScheduledProject,
} from "./projects";
import { markRevisionTransactionPaid } from "./transactions";

interface ProjectsMap {
  isLoading: boolean;
  projects: Record<string, ProjectById>;
}

const initialState: ProjectsMap = {
  isLoading: false,
  projects: {},
};

export interface getProjectParams {
  code?: string;
  project_id: number;
}

export const getProjectById = createAsyncThunk(
  GET_PROJECT,
  async ({ project_id, code }: getProjectParams, thunkAPI) => {
    const getProjectRoute = getProjectAPIRoute(project_id, code);
    const response = await makeBackendGetCallWithJsonResponse<ProjectById>(
      getProjectRoute,
      "",
    );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface ShareLinkParams {
  code: string;
  projectId?: number; // Passed if accessed via share link code.
}

export const getUploadLinkProject = createAsyncThunk(
  GET_UPLOAD_LINK_PROJECT,
  async ({ code, projectId }: ShareLinkParams, thunkAPI) => {
    const args = {
      code,
      project_id: projectId,
    };
    const params = `?${queryString.stringify(args)}`;
    const response = await makeBackendGetCallWithJsonResponse<ProjectById>(
      GET_UPLOAD_LINK_PROJECT,
      params,
    );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface ReviewShareLinkParams extends ShareLinkParams {
  password?: string;
}

export const getReviewLinkProject = createAsyncThunk(
  GET_LISTEN_LINK_PROJECT,
  async ({ code, projectId, password }: ReviewShareLinkParams, thunkAPI) => {
    let params = "?code=".concat(code);
    if (projectId) {
      params += "&project_id=" + projectId;
    }
    if (password) {
      params = params.concat("&password=" + password);
    }
    const response = await makeBackendGetCallWithJsonResponse<ProjectById>(
      GET_LISTEN_LINK_PROJECT,
      params,
    );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

/**
 * This slice is used to store the projects in an object (hashmap).
 * This should remain simple and not be used for complex state management.
 */
export const projectsMapSlice = createSlice({
  name: "projectsMap",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      markRevisionTransactionPaid.fulfilled,
      (state: ProjectsMap, action) => {
        const projectId = action.payload.id;
        const project = state.projects[projectId];
        if (project) {
          project.revisions_available = action.payload.revisions_available;
          state.projects[projectId] = project;
        }
      },
    );
    builder.addCase(
      renameProjectOrScheduledProject.fulfilled,
      (state, action) => {
        if (action.meta.arg?.scheduled_project_id) return;
        const project_id = action.meta?.arg.project_id;
        if (!project_id) return;
        const project = state.projects[project_id];
        if (!project) return;
        const title = action.meta.arg.title;
        project.title = title;
        state.projects[project_id] = project;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getProjectById.pending,
        getReviewLinkProject.pending,
        getUploadLinkProject.pending,
        markFinalAssetsApproved.pending,
      ),
      (state: ProjectsMap) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getProjectById.rejected,
        getReviewLinkProject.rejected,
        getUploadLinkProject.rejected,
        markFinalAssetsApproved.rejected,
      ),
      (state: ProjectsMap) => {
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getProjectById.fulfilled,
        getReviewLinkProject.fulfilled,
        getUploadLinkProject.fulfilled,
        markFinalAssetsApproved.fulfilled,
      ),
      (state: ProjectsMap, action) => {
        const { payload } = action;
        state.isLoading = false;
        if (!_.isEqual(state.projects[payload.id], payload)) {
          state.projects[payload.id] = payload;
        }
      },
    );
    builder.addMatcher(
      isAnyOf(
        engMasteringTransition.fulfilled,
        artistMasteringTransitions.fulfilled,
      ),
      (state: ProjectsMap, action) => {
        const { project_id } = action.meta.arg;
        const project = state.projects[project_id];
        if (project) {
          project.mastering_project = action.payload;
          state.projects[project_id] = project;
        }
      },
    );
    builder.addMatcher(
      isAnyOf(artistMixingTransitions.fulfilled, engMixTransition.fulfilled),
      (state: ProjectsMap, action) => {
        const { project_id } = action.meta.arg;
        const project = state.projects[project_id];
        if (project) {
          project.mixing_project = action.payload;
          state.projects[project_id] = project;
        }
      },
    );
  },
});

export default projectsMapSlice.reducer;
