import { LOGIN_UMG } from "../../store/utils/routes";
import { AuthResponse } from "../../store/actions/accountInfo";
import { Error as EngineEarsError } from "../../store/actions/errorStore";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";

export interface LoginUMGParams {
  access_token: string;
}

export const postLoginUMG = async (
  params: LoginUMGParams,
): Promise<AuthResponse | EngineEarsError> => {
  return makeBackendPostCallWithJsonResponse<AuthResponse>(
    LOGIN_UMG,
    params,
  ).then((res) => {
    if (res.success) {
      return res.resultJson;
    }
    return res.resultJson;
  });
};
