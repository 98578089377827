import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export enum PlaybackControlButtonSize {
  SMALL = "sm",
  REGULAR = "lg",
  LARGE = "2xl",
}

export const StyledPlaybackControlButtonFontAwesomeIcon = styled(
  FontAwesomeIcon,
)<{ $isToggled: boolean; $isPrimary?: boolean; $disabled?: boolean }>`
  cursor: pointer;
  color: ${({ theme, $isToggled, $isPrimary, $disabled }) => {
    if ($disabled) return theme.colorPalette.Gray100;
    if ($isToggled) return theme.colorPalette.Gray300;
    return $isPrimary ? theme.textPrimaryColor : theme.textSecondaryColor;
  }};
  @media (pointer: fine) {
    &:hover {
      color: ${({ theme }) => theme.colorPalette.Gray50};
    }
  }
`;

export const StyledPlaybackControlButtonRepeatActiveIcon = styled.div<{
  $isToggled: boolean;
  $isPrimary: boolean;
  size: PlaybackControlButtonSize;
}>`
  z-index: var(--footer-player-z-index);
  position: absolute;
  width: ${({ size }) =>
    size === PlaybackControlButtonSize.SMALL ? "2px" : "4px"};
  height: ${({ size }) =>
    size === PlaybackControlButtonSize.SMALL ? "2px" : "4px"};
  left: 40%;
  top: 40%;
  border-radius: 50%;
  background-color: ${({ theme, $isToggled, $isPrimary }) => {
    if ($isToggled) return theme.colorPalette.Gray300;
    return $isPrimary ? theme.textPrimaryColor : theme.textSecondaryColor;
  }};
  @media (pointer: fine) {
    &:hover {
      background-color: ${({ theme }) => theme.colorPalette.Gray50};
    }
  }
`;
