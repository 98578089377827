import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProjectType, ScheduledProjectPaywallTypes } from "../models/project";
import { updateTransaction } from "./transactions";
import { BookingParameters } from "../utils/transactions";

interface GenerateBookingState {
  isLoading: boolean;
  engineerId?: number;
  engineerUserId?: number;
  activeStudioId?: number;
  activeStudioRoomId?: number;
  activeServiceType?: ProjectType;
  activeTransactionId?: number;
  activeServiceId?: number;
  activeServiceTypeProjectIds: number[];
  linkShareStatus: null | "copy" | "email";
  scheduledProjectId?: number;
  paywallOption: ScheduledProjectPaywallTypes | undefined;
}

const initialState: GenerateBookingState = {
  isLoading: true,
  activeServiceTypeProjectIds: [],
  linkShareStatus: null,
  paywallOption: undefined,
};

const generateBookingSlice = createSlice({
  name: "generateBooking",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(updateTransaction.fulfilled, (state, action) => {
      const transaction = action.payload.transaction_data;
      const activeServiceTypeProjectIds: number[] = [];
      if (
        state.activeServiceType &&
        state.activeServiceType !== ProjectType.RECORDING
      ) {
        const transactionItems = transaction.items;
        transactionItems.forEach((transactionItem) => {
          if (
            transactionItem.project?.service_type === state.activeServiceType
          ) {
            activeServiceTypeProjectIds.push(transactionItem.project!.id);
          }
        });
      } else if (state.activeServiceType === ProjectType.RECORDING) {
        const recordingSessions = transaction.recording_sessions;
        recordingSessions?.forEach((session) => {
          activeServiceTypeProjectIds.push(session.project.id);
        });
      }
      state.activeServiceTypeProjectIds = activeServiceTypeProjectIds;
      if (transaction.scheduled_project_id) {
        state.scheduledProjectId = transaction.scheduled_project_id;
      }
    });
  },
  reducers: {
    resetGenerateBookingState: () => {
      return initialState;
    },
    setGenerateBookingLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setInitialBookingParameters: (
      state,
      action: PayloadAction<BookingParameters>,
    ) => {
      state.engineerId = action.payload.engineerId;
      state.engineerUserId = action.payload.engineerUserId;
      state.activeServiceType = action.payload.activeServiceType;
      state.activeTransactionId = action.payload.transactionId;
      state.activeServiceTypeProjectIds =
        action.payload.activeServiceTypeProjectIds;
      state.activeStudioId = action.payload.activeStudioId;
      state.activeStudioRoomId = action.payload.activeStudioRoomId;
    },
    // This function is used to restore the generate booking state to the point before it was incorrectly reset
    // We need this because our whole app is re-rendered after logging in from UnauthenticatedModal,
    // which caused the useEffect cleanup to run, which incorrectly restore the state it its initial state
    // Thanks to closure, we still have the reference to the previous state, so we could restore it here
    restoreGenerateBookingState: (
      _,
      action: PayloadAction<GenerateBookingState>,
    ) => {
      return action.payload;
    },
    setActiveServiceId: (state, action: PayloadAction<number>) => {
      state.activeServiceId = action.payload;
    },
    changeServiceTypeAndId: (
      state,
      action: PayloadAction<{
        serviceType?: ProjectType;
        serviceId?: number;
      }>,
    ) => {
      state.activeServiceId = action.payload.serviceId;
      state.activeServiceType = action.payload.serviceType;
      state.activeServiceTypeProjectIds = [];
    },
    setActiveStudioIds: (
      state,
      action: PayloadAction<{
        studioId?: number;
        studioRoomId?: number;
        serviceId?: number;
      }>,
    ) => {
      state.activeStudioRoomId = action.payload.studioRoomId;
      state.activeStudioId = action.payload.studioId;
      state.activeServiceId = action.payload.serviceId;
    },
    setActiveFieldsForEngineerRecordingService: (
      state,
      action: PayloadAction<{
        activeServiceId: number;
      }>,
    ) => {
      state.activeServiceId = action.payload.activeServiceId;
      state.activeServiceType = ProjectType.RECORDING;
      state.activeStudioId = undefined;
      state.activeStudioRoomId = undefined;
      state.activeServiceTypeProjectIds = [];
    },
    setLinkShareStatus: (
      state,
      action: PayloadAction<null | "copy" | "email">,
    ) => {
      state.linkShareStatus = action.payload;
    },
    setPaywallOption: (
      state,
      action: PayloadAction<ScheduledProjectPaywallTypes>,
    ) => {
      state.paywallOption = action.payload;
    },
  },
});

export const {
  resetGenerateBookingState,
  setGenerateBookingLoading,
  restoreGenerateBookingState,
  setInitialBookingParameters,
  setActiveServiceId,
  changeServiceTypeAndId,
  setActiveStudioIds,
  setActiveFieldsForEngineerRecordingService,
  setLinkShareStatus,
  setPaywallOption,
} = generateBookingSlice.actions;
export default generateBookingSlice.reducer;
