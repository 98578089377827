import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { BaseModal as OldModal } from "../BaseModal/BaseModal";
import useModal from "../../../hooks/useModal";
import { ShareLinkModal } from "../ShareLinkModal/ShareLinkModal";
import { faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ShareToClientButtonProps {
  scheduledProjectId: number;
}

// Only used for sharing in-progress projects
export const ShareToClientButton = ({
  scheduledProjectId,
}: ShareToClientButtonProps) => {
  const {
    isOpen: isPageSharingModalOpen,
    closeModal: closePageSharingModal,
    openModal: openPageSharingModal,
  } = useModal();

  return (
    <>
      <Button
        onClick={openPageSharingModal}
        labelIcon={<FontAwesomeIcon icon={faShareFromSquare} />}
        variant={ButtonVariant.OUTLINED}
      >
        Share to client
      </Button>
      <OldModal
        modalIsOpen={isPageSharingModalOpen}
        closeModal={closePageSharingModal}
        label={"share mix modal"}
      >
        <ShareLinkModal
          hidePassword
          closeModal={closePageSharingModal}
          inProgressScheduledProjectId={scheduledProjectId}
        />
      </OldModal>
    </>
  );
};
