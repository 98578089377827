import { styled } from "styled-components";

export const StyledGMapElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: inherit;
  height: 100%;
  flex: 1;
  gap: 16px;
`;

export const StyledGMapElementBoldTitle = styled.p`
  font-size: ${({ theme }) => theme.textSizeLg};
  color: ${({ theme }) => theme.textPrimaryColor};
  line-height: 1.75rem;
  font-weight: 600;
  margin-top: 16px;
`;
