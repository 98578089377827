import { MouseEvent, useMemo } from "react";
import { useAppSelector, useUser } from "../../../../store/hooks";
import { RecordingSession } from "../../../../store/models/recordingSession";
import { getFormattedTimeStringWithoutDateFromLocalDate } from "../../../../store/utils/dateTimeUtils";
import { getDisplayableNameForUser } from "../../../../store/utils/entityUtils";
import { useSessionTableViewContext } from "../../../screens/BookingsScreen/components/SessionTableView/useSessionTableViewContext";
import {
  CalendarDayCardRecordingSessionContainer,
  CalendarDayCardRecordingSessionText,
} from "./CalendarDayCard.styles";
import { toast } from "react-toastify";
import { useSessionHasPendingModificationRequest } from "../../../../hooks/recordingSessionHooks/useSessionHasPendingModificationRequest";

export interface CalendarDayCardRecordingSessionProps {
  recordingSession: RecordingSession;
}

export const CalendarDayCardRecordingSession = ({
  recordingSession,
}: CalendarDayCardRecordingSessionProps) => {
  const isPendingAcceptance = Boolean(
    recordingSession.pending_booking_acceptance,
  );
  const isPendingMyAcceptanceOrRejection = Boolean(
    recordingSession.pending_my_acceptance_or_rejection,
  );
  const user = useAppSelector((state) => state.accountInfo.user);
  const isPendingModificationAcceptanceOrRejection =
    useSessionHasPendingModificationRequest(recordingSession);
  const isSessionCancelled = Boolean(recordingSession.refunded);
  const collaborator_user =
    recordingSession.artist?.user_id !== user?.id
      ? recordingSession.artist?.user_id
      : recordingSession.engineer?.user_id;
  const collaboratorUser = useUser(collaborator_user);
  const collaboratorUsername = getDisplayableNameForUser(collaboratorUser);
  const startTime = useMemo(() => {
    const timeString = getFormattedTimeStringWithoutDateFromLocalDate(
      new Date(recordingSession.first_choice_datetime),
    );
    const timeParts = timeString.split(":");
    if (timeParts[1].includes("00")) {
      return timeString.replace(":00", "");
    }

    return timeString;
  }, [recordingSession]);

  const sessionTableViewContextValue = useSessionTableViewContext();

  return (
    <CalendarDayCardRecordingSessionContainer
      $pending={isPendingAcceptance}
      $modificationPending={isPendingModificationAcceptanceOrRejection}
      $cancelled={isSessionCancelled}
      onClick={(e: MouseEvent) => {
        if (sessionTableViewContextValue) {
          e.stopPropagation();
          if (isSessionCancelled) {
            toast.error("This session has been cancelled and refunded.");
          } else if (isPendingMyAcceptanceOrRejection) {
            sessionTableViewContextValue.handleOpenSessionBookingRequestedPanel(
              recordingSession.recording_session_request_id,
            );
          } else {
            sessionTableViewContextValue.handleOpenSessionDetailsPanel(
              recordingSession.project.id,
            );
          }
        }
      }}
    >
      <CalendarDayCardRecordingSessionText $pending={isPendingAcceptance}>
        {collaboratorUsername}
      </CalendarDayCardRecordingSessionText>
      <CalendarDayCardRecordingSessionText $pending={isPendingAcceptance}>
        {startTime}
      </CalendarDayCardRecordingSessionText>
    </CalendarDayCardRecordingSessionContainer>
  );
};
