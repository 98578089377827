import { useAtom } from "jotai/react";
import { useEffect, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { editMode } from "../../../atoms/profileScreenEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/sharp-solid-svg-icons";
import { Box, useTheme } from "@mui/material";

import { getPaginatedMusoCredit } from "../../../store/actions/muso";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { MusoCreditsTable } from "./MusoCreditsTable";
import { useIsVisitingOwnProfile } from "../../../hooks/profileScreenHooks/useIsVisitingOwnProfile";
import {
  getMusoAuthorizationURL,
  MusoAssociationStatus,
  MusoSelectionTab,
  MUSO_ASSOCIATION_ACCEPTED_STATUSES,
} from "../../../store/models/muso";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  AddSubscriptionContainer,
  MusoLinkButton,
} from "./MusoCreditsTable.styles";
import musoCard from "./../../assets/muso-card.svg";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  getAddSubscriptionRoute,
  getMusoSelectionScreenRoute,
} from "../../../store/utils/routeGetters";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { useGetMusoAuthQuery } from "../../../hooks/profileScreenHooks/useGetMusoAuth";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { useGetSubscriptionStatus } from "../../../hooks/useGetSubscriptionStatus";
import {
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_STATUS,
} from "../../../store/models/subscription";
import { SCREENS } from "../../../routes";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import Link from "../../elements/Link/Link";

export const MusoCreditsTableWrapper = () => {
  const theme = useTheme();
  const { userCredits, totalPages, fetchingPaginatedMusoCredits, count } =
    useAppSelector((state) => state.musoSearchStore);
  const { username } = useParams<{ username: string }>();
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isVisitingOwnProfile = useIsVisitingOwnProfile();
  const { data: userDataForProfileScreen } = useGetUserProfile(username);
  const isUserProfileEngineer = useMemo(() => {
    return Boolean(
      userDataForProfileScreen?.engineer &&
        !userDataForProfileScreen.engineer.deleted,
    );
  }, [userDataForProfileScreen?.engineer]);

  const [isEditMode] = useAtom(editMode);
  const {
    data: { muso_user_id: activeMusoUserId, muso_association: musoAssociation },
  } = useGetMusoAuthQuery({
    userProfile: userDataForProfileScreen,
    refresh: true,
  });
  const { data: subscription } = useGetSubscriptionStatus({
    user: userDataForProfileScreen,
    refetchSubsStatus: true,
  });

  const hasPlatinumSubscription = Boolean(
    subscription?.subscription_plan_choice ===
      SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY ||
      subscription?.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY ||
      subscription?.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY,
  );

  const addSubscriptionClick = () => {
    emitAnalyticsTrackingEvent(
      "clicked_manage_subscription_cta_on_profile",
      {},
    );
    if (
      subscription?.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN ||
      subscription?.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.LEGACY_BASIC_PLAN ||
      subscription?.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.ESSENTIALS_PLAN
    ) {
      history.push(SCREENS.ADD_SUBSCRIPTION);
      return;
    }
  };

  useEffect(() => {
    if (!userDataForProfileScreen?.id) return;
    if (!isUserProfileEngineer) return;
    void dispatch(
      getPaginatedMusoCredit({
        user_id: userDataForProfileScreen?.id,
        page: page,
      }),
    );
  }, [userDataForProfileScreen?.id, page, dispatch, isUserProfileEngineer]);

  if (!isUserProfileEngineer) {
    return null;
  }

  const onClick = () => {
    if (page >= totalPages) {
      return;
    }
    setPage(page + 1);
  };

  const onClickHandler = (editCredits = false) => {
    const redirectPath = getMusoSelectionScreenRoute(
      musoAssociation &&
        editCredits &&
        MUSO_ASSOCIATION_ACCEPTED_STATUSES.includes(musoAssociation.status)
        ? MusoSelectionTab.CREDITS
        : MusoSelectionTab.ROSTER,
    );

    if (!activeMusoUserId) {
      emitAnalyticsTrackingEvent("click_muso_link_profile_button", {
        musoAssociationId: musoAssociation?.id,
      });
      window.location.assign(getMusoAuthorizationURL(redirectPath));
      return;
    }

    emitAnalyticsTrackingEvent("click_muso_credits_button", {
      musoAssociationId: musoAssociation?.id,
      redirectPath: redirectPath,
    });
    history.push(redirectPath);
  };

  const hasActiveSubscription = Boolean(
    subscription?.stripe_subscription_status == SUBSCRIPTION_STATUS.ACTIVE &&
      subscription.subscription_plan_choice >=
        SUBSCRIPTION_PLAN.LEGACY_GOLD_PLAN &&
      subscription.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.ESSENTIALS_PLAN,
  );

  const MusoControls = () => {
    if (
      !activeMusoUserId &&
      isEditMode &&
      isVisitingOwnProfile &&
      isUserProfileEngineer &&
      hasActiveSubscription
    ) {
      return (
        <MusoLinkButton
          labelIcon={<FontAwesomeIcon icon={faChevronRight} />}
          onClick={() => onClickHandler()}
          variant={ButtonVariant.GHOST}
        >
          <Box sx={{ display: "flex", alignItems: "end", gap: "25px" }}>
            <img src={musoCard} alt={"MUSO.AI"} height={20} />
            Connect your Muso.ai account
          </Box>
        </MusoLinkButton>
      );
    }
    if (
      activeMusoUserId &&
      musoAssociation?.status ===
        MusoAssociationStatus.MUSO_ASSOC_STATUS_CONFIRMED_WITH_SSO &&
      isEditMode &&
      isVisitingOwnProfile &&
      isUserProfileEngineer &&
      hasActiveSubscription
    ) {
      return (
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            onClick={() => onClickHandler(true)}
            variant={ButtonVariant.OUTLINED}
          >
            Update your credits
          </Button>{" "}
          <Button
            onClick={() => onClickHandler()}
            variant={ButtonVariant.OUTLINED}
          >
            Edit Profile
          </Button>
        </Box>
      );
    }
    if (activeMusoUserId && !musoAssociation && hasActiveSubscription) {
      return (
        <Button
          onClick={() => onClickHandler()}
          variant={ButtonVariant.OUTLINED}
        >
          Edit Profile
        </Button>
      );
    }
    if (
      isEditMode &&
      isUserProfileEngineer &&
      (subscription?.stripe_subscription_status ===
        SUBSCRIPTION_STATUS.INACTIVE ||
        !hasPlatinumSubscription)
    ) {
      return (
        <AddSubscriptionContainer>
          <Text
            variant={TextStyleVariant.P1}
            weight={TEXT_WEIGHT.BOLD}
            style={{ color: theme.palette.standardColor.DeepBlue[800] }}
          >
            {" "}
            To add your Muso Credits, you must upgrade to Platinum!{" "}
          </Text>
          <Link
            to={getAddSubscriptionRoute()}
            style={{ textDecoration: "none" }}
          >
            <Button
              onClick={addSubscriptionClick}
              style={{
                background: theme.palette.standardColor.DeepBlue[800],
              }}
            >
              Upgrade now
            </Button>
          </Link>
        </AddSubscriptionContainer>
      );
    }
    return null;
  };

  return (
    <MusoCreditsTable
      onClick={onClick}
      credits={userCredits}
      count={count}
      editMode={isEditMode}
      showMoreDisabled={page >= totalPages || fetchingPaginatedMusoCredits}
      isLoading={fetchingPaginatedMusoCredits}
    >
      <MusoControls />
    </MusoCreditsTable>
  );
};
