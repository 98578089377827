import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import "./AmenityList.css";
import {
  Amenity,
  AmenityType,
  amenitiesList,
} from "../../../store/models/amenity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faWifi,
  faParking,
  faSmoking,
  faSnowflake,
  faUtensils,
  faShield,
  faPaw,
  faRestroom,
  faUserSecret,
  faQuestion,
  faVideoCamera,
  faRunning,
  faGamepad,
  faMicrophone,
  faParagraph,
  faGear,
  faPersonBooth,
  faLock,
  faKitchenSet,
} from "@fortawesome/free-solid-svg-icons";
import { updateAmenities } from "../../../store/actions/studio";
import { useAppDispatch } from "../../../store/hooks";

export interface AmenityCardProps {
  amenity: Amenity;
  editMode: boolean;
  isSelected: boolean;
  onClick: (selectedAmenity: Amenity) => void;
  useCardForm?: boolean;
}

const AmenityToIconMap = new Map<AmenityType, IconDefinition>([
  [AmenityType.NO_AMENITY, faQuestion],
  [AmenityType.FREE_PARKING, faParking],
  [AmenityType.FREE_WIFI, faWifi],
  [AmenityType.AIR_CONDITIONING, faSnowflake],
  [AmenityType.FOOD_AND_DRINKS, faUtensils],
  [AmenityType.SECURITY, faShield],
  [AmenityType.RESTROOM, faRestroom],
  [AmenityType.PRIVATE_LOUNGE, faUserSecret],
  [AmenityType.SMOKING_AREA, faSmoking],
  [AmenityType.PET_FRIENDLY, faPaw],
  [AmenityType.RUNNER_AVAILABLE, faRunning],
  [AmenityType.SECURE_ENTRANCE, faLock],
  [AmenityType.SECURITY_CAMERAS, faVideoCamera],
  [AmenityType.OPEN_KITCHEN, faKitchenSet],
  [AmenityType.STUDIO_MANAGER_ON_PREMISE, faPersonBooth],
  [AmenityType.GEAR_RENTALS, faGear],
  [AmenityType.WRITING_MATERIALS, faParagraph],
  [AmenityType.ENTERTAINMENT, faGamepad],
  [AmenityType.VOCAL_BOOTH, faMicrophone],
]);

const AmenityToDescriptionMap = new Map<AmenityType, string>([
  [AmenityType.NO_AMENITY, "No Amenity"],
  [AmenityType.FREE_PARKING, "Parking"],
  [AmenityType.FREE_WIFI, "Free Wifi"],
  [AmenityType.AIR_CONDITIONING, "Air Conditioning"],
  [AmenityType.FOOD_AND_DRINKS, "Food and Drinks"],
  [AmenityType.SECURITY, "Security"],
  [AmenityType.RESTROOM, "Restroom"],
  [AmenityType.PRIVATE_LOUNGE, "Private Lounge"],
  [AmenityType.SMOKING_AREA, "Smoking Area"],
  [AmenityType.PET_FRIENDLY, "Pet Friendly"],
  [AmenityType.RUNNER_AVAILABLE, "Runner Available"],
  [AmenityType.SECURE_ENTRANCE, "Secure Entrance"],
  [AmenityType.SECURITY_CAMERAS, "Security Cameras"],
  [AmenityType.OPEN_KITCHEN, "Open Kitchen"],
  [AmenityType.STUDIO_MANAGER_ON_PREMISE, "Studio Manager"],
  [AmenityType.GEAR_RENTALS, "Gear Rentals"],
  [AmenityType.WRITING_MATERIALS, "Writing Materials"],
  [AmenityType.ENTERTAINMENT, "Video Games"],
  [AmenityType.VOCAL_BOOTH, "Vocal Booth"],
]);

export const AmenityCard: React.FC<AmenityCardProps> = ({
  amenity,
  isSelected,
  editMode,
  onClick,
  useCardForm = true,
}) => {
  const icon = AmenityToIconMap.get(amenity.amenity_type);
  const description = AmenityToDescriptionMap.get(amenity.amenity_type);
  if (!icon || !description) {
    return null;
  }

  const amenityClassNames = classNames({
    "amenity-card": true,
    selected: editMode && isSelected,
    "edit-mode": editMode,
    "no-card": !useCardForm,
  });

  return (
    <div
      onClick={() => {
        if (editMode) {
          onClick(amenity);
        }
      }}
      className={amenityClassNames}
    >
      <FontAwesomeIcon
        width={25}
        height={25}
        icon={icon}
        className={"amenity-icon"}
      />
      <p className={"amenity-label"}>{description}</p>
    </div>
  );
};

export interface AmenityListProps {
  amenities: Amenity[];
  editMode: boolean;
  username?: string;
  studioId?: number;
  studioRoomId?: number;
  useCardForm?: boolean;
}

export const AmenityList: React.FC<AmenityListProps> = ({
  amenities,
  editMode,
  username,
  studioId,
  studioRoomId,
  useCardForm = true,
}) => {
  const dispatch = useAppDispatch();
  const [updating, setUpdating] = useState(false);
  const [selectedAmenities, setSelectedAmenities] = React.useState<
    AmenityType[]
  >(
    amenities.reduce((acc, amenity) => {
      acc.push(amenity.amenity_type);
      return acc;
    }, [] as AmenityType[]),
  );

  const filteredAmenitiesList: AmenityType[] = useMemo(() => {
    if (studioId) {
      const studioRoomAmenities = [
        AmenityType.WRITING_MATERIALS,
        AmenityType.PRIVATE_LOUNGE,
        AmenityType.VOCAL_BOOTH,
        AmenityType.ENTERTAINMENT,
      ];
      return amenitiesList.filter(
        (amenity) => !studioRoomAmenities.includes(amenity),
      );
    } else if (studioRoomId) {
      const studioAmenities = [
        AmenityType.RUNNER_AVAILABLE,
        AmenityType.SECURE_ENTRANCE,
        AmenityType.SECURITY_CAMERAS,
        AmenityType.STUDIO_MANAGER_ON_PREMISE,
        AmenityType.GEAR_RENTALS,
        AmenityType.OPEN_KITCHEN,
        AmenityType.FREE_PARKING,
        AmenityType.RESTROOM,
      ];
      return amenitiesList.filter(
        (amenity) => !studioAmenities.includes(amenity),
      );
    }
    return [];
  }, [studioId, studioRoomId]);

  const data = useMemo(() => {
    if (!editMode && !updating) {
      return [];
    }
    const missingAmenities = filteredAmenitiesList.filter((amenity) => {
      const amenityType = amenity as unknown as AmenityType;
      return !amenities.some((amenity) => amenity.amenity_type === amenityType);
    });
    const amenitiesMissingFromSource: Amenity[] = missingAmenities.reduce(
      (acc, amenity, currentIndex) => {
        const index = currentIndex + amenities.length;
        acc.push({
          id: index,
          amenity_type: amenity as unknown as AmenityType,
          created: new Date().toDateString(),
          deleted: null,
        });
        return acc;
      },
      [] as Amenity[],
    );
    return amenitiesMissingFromSource;
  }, [amenities, editMode, selectedAmenities, updating, filteredAmenitiesList]);

  const handleAmenityClick = (selectedAmenity: Amenity) => {
    if (selectedAmenities.includes(selectedAmenity.amenity_type)) {
      setSelectedAmenities(
        selectedAmenities.filter(
          (amenity) => amenity !== selectedAmenity.amenity_type,
        ),
      );
    } else {
      setSelectedAmenities([
        ...selectedAmenities,
        selectedAmenity.amenity_type,
      ]);
    }
  };

  useEffect(() => {
    if (editMode) {
      return;
    }
    if (selectedAmenities.length !== amenities.length) {
      setUpdating(true);
      if (studioId) {
        dispatch(
          updateAmenities({
            amenities: selectedAmenities,
            studio_id: studioId,
            username: username,
          }),
        )
          .unwrap()
          .finally(() => {
            setUpdating(false);
          });
      }
      if (studioRoomId) {
        dispatch(
          updateAmenities({
            amenities: selectedAmenities,
            studio_room_id: studioRoomId,
            username: username,
          }),
        )
          .unwrap()
          .finally(() => {
            setUpdating(false);
          });
      }
    }
  }, [selectedAmenities, editMode, studioId, studioRoomId]);

  return (
    <div className={"amenity-list-container"}>
      {amenities.map((amenity) => (
        <AmenityCard
          onClick={handleAmenityClick}
          key={amenity.id}
          amenity={amenity}
          editMode={editMode}
          isSelected={selectedAmenities.includes(amenity.amenity_type)}
          useCardForm={useCardForm}
        />
      ))}
      {data.map((amenity) => (
        <AmenityCard
          onClick={handleAmenityClick}
          key={amenity.id}
          amenity={amenity}
          editMode={editMode}
          isSelected={selectedAmenities.includes(amenity.amenity_type)}
          useCardForm={useCardForm}
        />
      ))}
    </div>
  );
};
