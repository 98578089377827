import { useMemo } from "react";
import {
  AtmosProjectSteps,
  MasteringProjectSteps,
  MixingProjectSteps,
  ProjectType,
} from "../store/models/project";

export const useIsArtistReviewStep = (
  projectId: number | null | undefined,
  step: number | undefined,
  serviceType: ProjectType | undefined,
): boolean => {
  return useMemo(() => {
    if (!projectId || !step || !serviceType) return false;
    switch (serviceType) {
      case ProjectType.MASTERING:
        return step === MasteringProjectSteps.MASTER_REVIEW;
      case ProjectType.ATMOS_MIXING:
        return (
          step === AtmosProjectSteps.MIX_REVIEW ||
          step === AtmosProjectSteps.ATMOS_MIX_REVIEW
        );
      case ProjectType.TWO_TRACK_MIXING:
      case ProjectType.MIXING:
        return step === MixingProjectSteps.MIX_REVIEW;
      default:
        return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, step]);
};

export const useIsProjectInMaster = (
  projectId: number | null | undefined,
  step: number | undefined,
  serviceType: ProjectType | undefined,
) => {
  return useMemo(() => {
    if (!projectId || !step || !serviceType) return false;
    if (serviceType !== ProjectType.MASTERING) return false;
    return (
      step === MasteringProjectSteps.MASTER_REVISION ||
      step === MasteringProjectSteps.IN_MASTER
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, step]);
};

export const useIsProjectInMix = (
  projectId: number | null | undefined,
  step: number | undefined,
  serviceType: ProjectType | undefined,
) => {
  return useMemo(() => {
    if (!projectId || !step || !serviceType) return false;
    switch (serviceType) {
      case ProjectType.ATMOS_MIXING:
        return (
          step === AtmosProjectSteps.IN_MIX ||
          step === AtmosProjectSteps.IN_ATMOS_MIX ||
          step === AtmosProjectSteps.MIX_REVISION ||
          step === AtmosProjectSteps.ATMOS_MIX_REVISION
        );
      case ProjectType.TWO_TRACK_MIXING:
      case ProjectType.MIXING:
        return (
          step === MixingProjectSteps.MIX_REVISION ||
          step === MixingProjectSteps.IN_MIX
        );
      default:
        return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, step]);
};

export const useIsProjectInMixOrMaster = (
  projectId: number | null | undefined,
  step: number | undefined,
  serviceType: ProjectType | undefined,
) => {
  const isInMix = useIsProjectInMix(projectId, step, serviceType);
  const isInMaster = useIsProjectInMaster(projectId, step, serviceType);
  return useMemo(() => {
    return isInMix || isInMaster;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInMix, isInMaster]);
};

export const useIsArtistReviewStepOrInMixOrInMaster = (
  projectId: number | null | undefined,
  step: number | undefined,
  serviceType: ProjectType | undefined,
) => {
  const isInArtistReviewStep = useIsArtistReviewStep(
    projectId,
    step,
    serviceType,
  );
  const isProjectInMixOrMaster = useIsProjectInMixOrMaster(
    projectId,
    step,
    serviceType,
  );

  return useMemo(() => {
    return isInArtistReviewStep || isProjectInMixOrMaster;
  }, [isInArtistReviewStep, isProjectInMixOrMaster]);
};
