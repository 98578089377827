import styled from "styled-components";

export const BudgetManagerCTARow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 76px;
  gap: 8px;
`;

export const TransactionOverviewTextHeader = styled.h4`
  color: ${({ theme }) => theme.textPrimaryColor};
  text-align: center;
  font-size: ${({ theme }) => theme.textSizeXLg};
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.72px;
  width: 100%;
`;

export const TransactionOverviewContentContainer = styled.div`
  display: flex;
  margin: 42px auto;
  flex-direction: column;
  max-width: 1020px;
  width: 95%;
  gap: 15px;
`;

export const TransactionOverviewStackedDivs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

export const TransactionOverviewStackedDiv = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  margin-bottom: 42px;
`;

export const TransactionOverviewHeader = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  margin-bottom: 42px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TransactionOverviewCols = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  @media (max-width: 1002px) {
    flex-direction: column;
  }
`;

export const TransactionOverviewCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 300px;
`;
