import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { EXTERNAL_LINKS, SCREENS } from "../../../../routes";
import { useAppSelector, useLogout } from "../../../../store/hooks";
import User from "../../../../store/models/user";
import {
  isCurrentUserEngineerOrStudioManagerSelector,
  isUserVerifiedAdminSelector,
} from "../../../../store/selectors/userInfoSelectors";
import {
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { NavAddStudioProfileButton } from "./components/NavAddStudioProfileButton/NavAddStudioProfileButton";
import { NavDropDownHelpButton } from "./components/NavDropDownHelpButton/NavDropDownHelpButton";
import {
  NAV_LIST_ITEM_VARIANT,
  NavDropDownListItem,
} from "./components/NavDropDownListItem/NavDropDownListItem";
import { NavEditServicesButton } from "./components/NavEditServicesButton/NavEditServicesButton";
import { ProfileRow } from "./components/ProfileRow/ProfileRow";
import "./NavProfileContent.css";
import { AccountSettingsModal } from "../../AccountSettingsModal/AccountSettingsModal";
import { useAtom, useAtomValue } from "jotai";
import { accountSettingsModalOpenAtom } from "../../AccountSettingsModal/atoms";
import { displayPersonalProfileAtom } from "../../../../atoms/user/activeProfileAtom";

enum NavProfileContentItem {
  ADMIN_DASHBOARD,
  DASHBOARD,
  BOOKINGS,
  PROFILE,
  SETTINGS,
  SEARCH,
  COMMUNITY,
  FAQ,
  FAVORITES,
}

export interface NavProfileContentProps {
  user: User | undefined;
  onClose: () => void;
}

const loggedInItems = [
  NavProfileContentItem.BOOKINGS,
  NavProfileContentItem.PROFILE,
  NavProfileContentItem.SETTINGS,
  NavProfileContentItem.FAVORITES,
];

const engineerAndStudioManagerItems = [
  NavProfileContentItem.DASHBOARD,
  ...loggedInItems,
];

const adminItems = [NavProfileContentItem.ADMIN_DASHBOARD, ...loggedInItems];

export const NavProfileContent = ({
  user,
  onClose,
}: NavProfileContentProps) => {
  const [isAccountSettingsModalOpen, setIsAccountSettingsModalOpen] = useAtom(
    accountSettingsModalOpenAtom,
  );
  const { userStudios } = useAppSelector((state) => state.accountInfo);
  const isEngineerOrStudioManager = useAppSelector(
    isCurrentUserEngineerOrStudioManagerSelector,
  );
  const isUserAdmin = useAppSelector(isUserVerifiedAdminSelector);
  const { pathname } = useLocation();
  const logout = useLogout(SCREENS.SIGNED_OUT_HOME_SCREEN);

  const { studio } = useAppSelector((state) => state.selectedProfileSlice);

  const username = studio ? studio.username : user?.username;
  const profilePath = studio
    ? getStudioScreenRoute(username ?? "")
    : user
      ? getProfileScreenRoute(username ?? "")
      : "";

  const displayPersonalProfile = useAtomValue(displayPersonalProfileAtom);
  const hidePersonalProfile = useMemo(() => {
    return (
      user?.studio_manager &&
      (!user.engineer || user.engineer.deleted) &&
      pathname !== profilePath &&
      pathname !== SCREENS.SETTINGS_SCREEN &&
      Array.isArray(userStudios) &&
      userStudios.length === 1
    );
  }, [pathname, user, userStudios, profilePath]);

  const navProfileContentOptions = useMemo(
    () => ({
      [NavProfileContentItem.ADMIN_DASHBOARD]: {
        title: "Dashboard",
        path: SCREENS.ADMIN_DASHBOARD,
        trackingEvent: "clicked_admin_dashboard",
      },
      [NavProfileContentItem.DASHBOARD]: {
        title: "Dashboard",
        path: SCREENS.DASHBOARD,
        trackingEvent: "clicked_dashboard",
      },
      [NavProfileContentItem.BOOKINGS]: {
        title: "Bookings",
        path: SCREENS.PROJECTS_NO_TAB,
        trackingEvent: "clicked_bookings",
      },
      [NavProfileContentItem.PROFILE]: {
        title: "My Profile",
        path: profilePath,
        trackingEvent: "clicked_profile",
      },
      [NavProfileContentItem.SETTINGS]: {
        title: "Settings",
        path: SCREENS.SETTINGS_SCREEN,
        trackingEvent: "clicked_settings",
      },
      [NavProfileContentItem.SEARCH]: {
        title: "Explore Profiles",
        path: SCREENS.SEARCH,
        trackingEvent: "clicked_search",
      },
      [NavProfileContentItem.COMMUNITY]: {
        title: "Community",
        path: SCREENS.COMMUNITY,
        trackingEvent: "clicked_community",
      },
      [NavProfileContentItem.FAQ]: {
        title: "FAQ",
        path: EXTERNAL_LINKS.FAQ,
        trackingEvent: "clicked_faq",
      },
      [NavProfileContentItem.FAVORITES]: {
        title: "Favorites",
        path: SCREENS.DASHBOARD_FAVORITES,
        trackingEvent: "clicked_favorites",
      },
    }),
    [profilePath],
  );

  const defaultItems = [
    NavProfileContentItem.SEARCH,
    NavProfileContentItem.COMMUNITY,
    NavProfileContentItem.FAQ,
  ];

  const profileListItems = useMemo(() => {
    if (isUserAdmin) {
      return adminItems;
    }

    if (isEngineerOrStudioManager) {
      return engineerAndStudioManagerItems;
    }

    return loggedInItems;
  }, [isUserAdmin, isEngineerOrStudioManager]);

  if (isAccountSettingsModalOpen) {
    return (
      <AccountSettingsModal
        onClose={() => {
          setIsAccountSettingsModalOpen(false);
        }}
      />
    );
  }

  return (
    <ul className="nav-profile-content">
      {user && (
        <>
          {displayPersonalProfile && (
            <li className="profile-row-li">
              <ProfileRow onClick={onClose} />
            </li>
          )}
          {Array.isArray(userStudios) &&
            userStudios.map((studio) => {
              return (
                <li className="profile-row-li" key={`studio-${studio.id}`}>
                  <ProfileRow studio={studio} onClick={onClose} />
                </li>
              );
            })}
          <hr />
          {profileListItems.map((listItem) => {
            const { title, path, trackingEvent } =
              navProfileContentOptions[listItem];
            return listItem === NavProfileContentItem.SETTINGS ? (
              <NavDropDownListItem
                text={title}
                key={`profile-list-item-${title}`}
                variant={NAV_LIST_ITEM_VARIANT.BUTTON}
                onClick={() => {
                  emitAnalyticsTrackingEvent(trackingEvent, {}, user?.id);
                  onClose();
                  setIsAccountSettingsModalOpen(true);
                }}
              />
            ) : (
              <NavDropDownListItem
                key={`profile-list-item-${title}`}
                text={title}
                variant={NAV_LIST_ITEM_VARIANT.LINK}
                path={path}
                currentPath={pathname}
                onClick={() => {
                  emitAnalyticsTrackingEvent(trackingEvent, {}, user?.id);
                  onClose();
                }}
              />
            );
          })}
        </>
      )}
      <hr />
      {user && (
        <>
          {user.engineer && !user.engineer.deleted && (
            <NavEditServicesButton engineer={user.engineer} user={user} />
          )}
          <NavAddStudioProfileButton userId={user.id} onClose={onClose} />
        </>
      )}
      <hr />
      {defaultItems.map((listItem) => {
        const { title, path, trackingEvent } =
          navProfileContentOptions[listItem];
        return (
          <NavDropDownListItem
            key={`default-list-item-${listItem}`}
            text={title}
            variant={NAV_LIST_ITEM_VARIANT.LINK}
            path={path}
            currentPath={pathname}
            onClick={() => {
              emitAnalyticsTrackingEvent(trackingEvent, {}, user?.id);
              onClose();
            }}
          />
        );
      })}
      <NavDropDownHelpButton onClose={onClose} userId={user?.id} />
      {user && (
        <>
          <hr />
          <NavDropDownListItem
            text="Log Out"
            variant={NAV_LIST_ITEM_VARIANT.BUTTON}
            onClick={logout}
          />
        </>
      )}
    </ul>
  );
};
