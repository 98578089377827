import { Box } from "@mui/material";
import { useGetTeamMembersById } from "../../../../hooks/useTeam";
import { PennyDollarFormatter } from "../../../../store/utils/formatUtils";
import { getProfileScreenRoute } from "../../../../store/utils/routeGetters";
import { Text } from "../../../core-ui/components/Text/Text";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";
import Link from "../../../elements/Link/Link";

interface AdminAssignProjectConfirmationProps {
  primaryEngineer: OptionType | null;
  primaryEngineerRate: number;
  secondaryEngineer: OptionType | null;
  secondaryEngineerRate: number;
  studioRoom: string | undefined;
}

export const AdminAssignProjectConfirmation = ({
  primaryEngineer,
  primaryEngineerRate,
  secondaryEngineer,
  secondaryEngineerRate,
  studioRoom,
}: AdminAssignProjectConfirmationProps) => {
  const numberFormatter = PennyDollarFormatter();
  const teamMembersById = useGetTeamMembersById();
  const primaryEngineerUserName =
    teamMembersById[primaryEngineer?.value || -1]?.username;
  const secondaryEngineerUserName =
    teamMembersById[secondaryEngineer?.value || -1]?.username;
  return (
    <>
      <Text>
        Please confirm that these engineers are available and have agreed to
        take on this project.
      </Text>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          border: `solid 1px ${theme.palette.divider}`,
          borderRadius: theme.border.radius.md,
          gap: theme.spacing(2),
          marginTop: theme.spacing(2),
          padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
        })}
      >
        <Box>
          <Text>Mixing Engineer:</Text>
          <Link
            to={getProfileScreenRoute(primaryEngineerUserName)}
            target="_blank"
            rel="noreferrer"
            style={{
              color: "unset",
              textDecorationColor: "unset",
            }}
          >
            <Text bold>
              {primaryEngineer?.label || "None"} -{" "}
              {numberFormatter.format(primaryEngineerRate)}
            </Text>
          </Link>
        </Box>
        {secondaryEngineer && (
          <Box>
            <Text>Assistant Engineer:</Text>
            <Link
              to={getProfileScreenRoute(secondaryEngineerUserName)}
              target="_blank"
              rel="noreferrer"
              style={{
                color: "unset",
                textDecorationColor: "unset",
              }}
            >
              <Text bold>
                {secondaryEngineer.label} -{" "}
                {numberFormatter.format(secondaryEngineerRate)}
              </Text>
            </Link>
          </Box>
        )}
        <Box>
          <Text>Studio:</Text>
          <Text bold>{studioRoom || "None"}</Text>
        </Box>
      </Box>
    </>
  );
};
