import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
      opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const CalendarDayCardContainer = styled.div<{
  $isMobile?: boolean;
}>`
  display: flex;
  height: ${({ $isMobile }) => ($isMobile ? "80px" : "158px")};
  max-width: 142px;
  padding: ${({ $isMobile }) => ($isMobile ? "8px 4px" : "8px")};
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--button-border-radius);
  border: solid thin var(--border-color);
  margin: 0 auto;
  background: inherit;
  min-width: 30px;
`;

export const CalendarDayText = styled.h5<{
  $isMobile?: boolean;
}>`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme }) => theme.textSizeLg};
  font-style: normal;
  font-weight: ${({ theme }) => theme.textWeightBold};
  line-height: 34px;
  letter-spacing: -0.6px;
  align-self: ${({ $isMobile }) => ($isMobile ? "center" : "flex-start")};
`;

export const CalendarDayCardRecordingSessionColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: hidden;
  width: 100%;
`;

export const CalendarDayCardRecordingSessionContainer = styled.div<{
  $pending?: boolean;
  $cancelled?: boolean;
  $modificationPending?: boolean;
  $isMobile?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${({ $pending, $cancelled, $modificationPending, theme }) => {
    if ($cancelled) {
      return theme.colorPalette.Red400;
    }
    if ($pending) {
      return theme.colorPalette.BoomyOrange500;
    }
    if ($modificationPending) {
      return theme.colorPalette.BoxyYellow200;
    }
    return theme.colorPalette.DeepGreen;
  }};
  width: ${({ $isMobile }) => ($isMobile ? "auto" : "100%")};
  border-radius: 8px;
  border: ${({ $isMobile }) => ($isMobile ? "solid thin black;" : "none")};
  padding: ${({ $isMobile }) => ($isMobile ? "2px 4px;" : "4px 8px;")};
  animation: ${slideIn} 0.5s ease-out;

  &:hover {
    background: ${({ $pending, $cancelled, $modificationPending, theme }) => {
      if ($cancelled) {
        return theme.colorPalette.Red500;
      }
      if ($pending) {
        return theme.colorPalette.BoomyOrange800;
      }
      if ($modificationPending) {
        return theme.colorPalette.BoxyYellow400;
      }
      return theme.colorPalette.DeepGreen;
    }};
  }
`;

export const MobileCalendarDayCardRecordingSessionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1px;
  width: 100%;
`;

export const CalendarDayCardRecordingSessionText = styled.p<{
  $pending?: boolean;
}>`
  color: ${({ $pending, theme }) =>
    $pending ? theme.colorPalette.White : theme.colorPalette.Black};
  text-transform: capitalize;
  line-height: 14px;
  font-size: ${({ theme }) => theme.textSizeXs};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
`;
