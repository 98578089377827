import classNames from "classnames";
import { WorkingHoursOptionType } from "../../../../hooks/useGeneralWorkingHoursStringForDay";
import {
  setGeneralWorkingHourIntervalLabelAtIndex,
  updateSelectedDateOptionAtIndex,
} from "../../../../store/actions/shoppingCart";
import { useAppDispatch } from "../../../../store/hooks";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";
import "../RecordingBookingDetails.css";

export enum RecordingDetailTimeDropdownVariant {
  START_END = "startEnd",
  GENERAL = "general",
  START = "start",
  END = "end",
}

interface RecordingDetailSelectTimeOptionProps {
  className?: string;
  index: number | undefined;
  isCurrentUserSameAsSelectedEngineer: boolean;
  onClick?: () => void;
  option: WorkingHoursOptionType;
  preselectedDateOption: OptionType | undefined;
  variant: RecordingDetailTimeDropdownVariant;
}

export const RecordingDetailSelectTimeOption = ({
  className,
  index,
  isCurrentUserSameAsSelectedEngineer,
  onClick,
  option,
  preselectedDateOption,
  variant,
}: RecordingDetailSelectTimeOptionProps) => {
  const dispatch = useAppDispatch();

  const label = {
    [RecordingDetailTimeDropdownVariant.START_END]: option.label,
    [RecordingDetailTimeDropdownVariant.GENERAL]: option.label,
    [RecordingDetailTimeDropdownVariant.START]: option.label.split("-")[0],
    [RecordingDetailTimeDropdownVariant.END]: option.label.split("-")[1],
  };

  const optionClassName = classNames(
    preselectedDateOption?.value === option.value
      ? "selected-recording-date-option"
      : "",
    option.isWorkingHour
      ? "selected-recording-date-option--valid"
      : "selected-recording-date-option--invalid",
    className,
  );

  // If an Engineer is generating a booking, they should see all times
  // otherwise don't render unavailable times
  if (!isCurrentUserSameAsSelectedEngineer && !option.isWorkingHour) {
    return null;
  }

  return (
    <p
      key={option.label}
      className={optionClassName}
      onClick={(e) => {
        e.stopPropagation();
        if (index === undefined) return;
        if (variant === RecordingDetailTimeDropdownVariant.GENERAL) {
          dispatch(
            setGeneralWorkingHourIntervalLabelAtIndex({
              index,
              selectedDateOption: option,
            }),
          );
        } else {
          dispatch(
            updateSelectedDateOptionAtIndex({
              index,
              selectedDateOption: option,
            }),
          );
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      {label[variant]}
    </p>
  );
};
