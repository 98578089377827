import { styled } from "styled-components";
import { Button } from "../../core-ui/components/Button/Button";

export const StyledTrackTableHeaderContainer = styled.div`
  text-align: left;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTrackTableTitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const StyledTrackTableContainer = styled.div<{
  $simplifiedView?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: calc(100% - 34px);
  padding: 16px;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  border: ${({ theme, $simplifiedView }) =>
    $simplifiedView ? "unset" : `1px solid ${theme.separatorColor}`};

  background: ${({ theme, $simplifiedView }) =>
    $simplifiedView ? "inherit" : theme.foregroundColor};
`;

export const StyledTrackTableFooter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledTrackTablePlaybackContainerWrapper = styled.div`
  position: absolute;
  transform: translateX(-50%);
  display: flex;
  gap: 16px;
  left: 50%;
`;

export const StyledTrackTableABToggleSwitchContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  flex: 1 0 0;
  font-size: ${({ theme }) => theme.textSizeSm};
  color: ${({ theme }) => theme.textSecondaryColor};
  cursor: pointer;
`;

export const StyledTrackTableABP = styled.p<{ selected?: boolean }>`
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  font-size: ${({ theme }) => theme.textSizeSm};
  color: ${({ theme, selected }) =>
    selected ? theme.colorPalette.BoomyOrange400 : theme.textSecondaryColor};
`;

export const StyledTrackTablePlayBackRowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
  flex: 4;
`;

export const StyledTrackTableCommentCountContainer = styled(Button)`
  white-space: nowrap;
  gap: 4px;
  color: ${({ theme }) => theme.textSecondaryColor};
`;

export const StyledTrackTableSeparator = styled.hr`
  border: none;
  border-bottom: solid 1px ${({ theme }) => theme.separatorColor};
  width: 100%;
`;

export const StyledTrackTableCommentContainer = styled.div`
  width: 100%;
`;
