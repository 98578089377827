import {
  fetchTrendingArtistReleaseTracks,
  ArtistTrendingReleaseTracksResponse,
} from "../releaseAPI";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { useInfiniteQuery } from "@tanstack/react-query";
import { EngineEarsError } from "../../../store/actions/errorStore";

export const useFetchTrendingArtistReleaseTracksQuery = (artistUserId = 0) => {
  return useInfiniteQuery({
    initialPageParam: 1,
    enabled: Boolean(artistUserId),
    queryKey: [QUERY_KEYS.FETCH_TRENDING_ARTIST_RELEASE_TRACKS, artistUserId],
    queryFn: async ({ pageParam }) => {
      const response = await fetchTrendingArtistReleaseTracks(
        pageParam,
        artistUserId,
      );
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return response.resultJson as ArtistTrendingReleaseTracksResponse;
    },
    getNextPageParam: (lastPage: ArtistTrendingReleaseTracksResponse) => {
      if (lastPage.current_page < lastPage.total_pages) {
        return lastPage.current_page + 1;
      }
      return undefined;
    },
  });
};
