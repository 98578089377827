import { useMemo } from "react";
import {
  AtmosProjectSteps,
  MixingProjectSteps,
  MasteringProjectSteps,
  ProjectType,
} from "../store/models/project";

export const useIsInMixorInMaster = (
  projectType: ProjectType | null,
  step: number,
) => {
  return useMemo(() => {
    if (!projectType) return false;
    if (projectType === ProjectType.MASTERING) {
      return (
        MasteringProjectSteps.IN_MASTER === step ||
        MasteringProjectSteps.MASTER_REVISION === step ||
        MasteringProjectSteps.MASTERING_UPLOAD_ALTS === step
      );
    }
    if (projectType === ProjectType.ATMOS_MIXING) {
      return (
        AtmosProjectSteps.IN_MIX === step ||
        AtmosProjectSteps.MIX_REVISION === step ||
        AtmosProjectSteps.ATMOS_MIX_REVISION === step ||
        AtmosProjectSteps.IN_ATMOS_MIX === step ||
        AtmosProjectSteps.MIX_FINISHED === step
      );
    }
    return (
      MixingProjectSteps.IN_MIX === step ||
      MixingProjectSteps.MIX_REVISION === step ||
      MixingProjectSteps.MIX_FINISHED === step
    );
  }, [projectType, step]);
};
