import { PRODUCTS_BY_RELEASE_ROUTE } from "../../store/utils/routes";
import { replaceRoutePlaceholders } from "../helpers";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { Product } from "./models";
import { EngineEarsError } from "../../store/actions/errorStore";

export const fetchProductsByReleaseId = async (releaseId: number) => {
  const route = replaceRoutePlaceholders(
    `${PRODUCTS_BY_RELEASE_ROUTE}{releaseId}/`,
    {
      releaseId,
    },
  );
  return await makeBackendGetCallWithJsonResponse<Product[] | EngineEarsError>(
    route,
    "",
  );
};
