import { useMemo } from "react";
import Engineer from "../store/models/engineer";

export const useEngineerCanHostServices = (engineer?: Engineer) => {
  const engineerAccountTypeActive = useMemo(() => {
    return Boolean(engineer?.deleted) === false;
  }, [engineer]);
  const isVerified = useMemo(() => {
    return engineer && engineer.verified !== null;
  }, [engineer]);

  const engineerStartedOnBoarding = useMemo(() => {
    return engineer?.started_onboarding !== null;
  }, [engineer]);

  return useMemo(() => {
    if (!engineerAccountTypeActive) return false;
    return isVerified || engineerStartedOnBoarding;
  }, [isVerified, engineerStartedOnBoarding, engineerAccountTypeActive]);
};

export const useEngineerIsBooking = (engineer?: Engineer) => {
  return useMemo(() => {
    return engineer && !engineer?.not_booking;
  }, [engineer]);
};
