import { HTMLAttributes, ReactNode } from "react";
import { TEXT_COMPONENT, TextComponent, TextStyleVariant } from "./TextUtils";

export type BaseTextProps = {
  children: ReactNode;
  component?: TextComponent;
  variant?: TextStyleVariant;
} & Pick<HTMLAttributes<HTMLParagraphElement>, "className" | "style">;

/**
 * This is a base text component intended to be used by styled-components.
 * It is used to render text as a paragraph <p> or heading <h1> depending on the variant or component prop.
 */
export const BaseText = ({
  children,
  className,
  component,
  style,
  variant = TextStyleVariant.P1,
}: BaseTextProps) => {
  // Use the component prop if it is provided, otherwise use the right HTML tag based on the variant
  const componentType = component ?? TEXT_COMPONENT[variant];
  switch (componentType) {
    case TextComponent.H1:
      return (
        <h1 className={className} style={style}>
          {children}
        </h1>
      );
    case TextComponent.H2:
      return (
        <h2 className={className} style={style}>
          {children}
        </h2>
      );
    case TextComponent.H3:
      return (
        <h3 className={className} style={style}>
          {children}
        </h3>
      );
    case TextComponent.H4:
      return (
        <h4 className={className} style={style}>
          {children}
        </h4>
      );
    case TextComponent.H5:
      return (
        <h5 className={className} style={style}>
          {children}
        </h5>
      );
    case TextComponent.H6:
      return (
        <h6 className={className} style={style}>
          {children}
        </h6>
      );
    case TextComponent.P:
      return (
        <p className={className} style={style}>
          {children}
        </p>
      );
    default:
      return (
        <p className={className} style={style}>
          {children}
        </p>
      );
  }
};
