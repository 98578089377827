import React, { ReactEventHandler, useCallback, useState } from "react";
import { CheckBox } from "../../../../../core-ui/components/CheckBox/CheckBox";
import {
  Text,
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../../../../core-ui/components/Text/Text";
import "./ProfileRow.css";
import { DEFAULT_USER_IMAGE_SRC } from "../../../../../../constants/googleStorage";

export interface ProfileRowComponentProps {
  imageSrc?: string;
  onLoadImageFailed?: ReactEventHandler<HTMLImageElement>;
  onClick: ReactEventHandler<HTMLDivElement>;
  username: string;
  selected?: boolean;
}

export const ProfileRowComponent = ({
  imageSrc = DEFAULT_USER_IMAGE_SRC,
  onClick,
  username,
  selected = false,
  onLoadImageFailed = () => {},
}: ProfileRowComponentProps) => {
  const [loadImageFailed, setLoadImageFailed] = useState(false);
  const handleLoadImageFailed: ReactEventHandler<HTMLImageElement> =
    useCallback(
      (e) => {
        try {
          onLoadImageFailed(e);
        } catch {
          return;
        }
        setLoadImageFailed(true);
      },
      [setLoadImageFailed, onLoadImageFailed],
    );

  const handleClick: ReactEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      onClick(e);
    },
    [onClick],
  );
  return (
    <div className="profile-row" onClick={handleClick}>
      <div className="profile-row-right">
        <div className="profile-row__image">
          <img
            src={
              loadImageFailed || !imageSrc ? DEFAULT_USER_IMAGE_SRC : imageSrc
            }
            onError={handleLoadImageFailed}
            alt="profile"
          />
        </div>
        <div className="profile-row__info__name">
          <Text
            color={selected ? TEXT_COLOR.PRIMARY : TEXT_COLOR.SECONDARY}
            size={TEXT_SIZE.XS}
            weight={selected ? TEXT_WEIGHT.BOLD : TEXT_WEIGHT.MEDIUM}
          >
            @{username}
          </Text>
        </div>
      </div>
      <CheckBox className="profile-row__info__selected" checked={selected} />
    </div>
  );
};
