import User from "../../../../../../store/models/user";
import { emitAnalyticsTrackingEvent } from "../../../../../../utils/analyticsUtils";
import {
  Text,
  TEXT_COLOR,
  TEXT_LINE_HEIGHT,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../../../../core-ui/components/Text/Text";
import { HUBSPOT_SUPPORT_URL } from "../../../../HubspotFormModal/constants";
import Link from "../../../../../elements/Link/Link";

interface AnnouncementHelpProps {
  userId: User["id"] | undefined;
}

export const AnnouncementHelpButton: React.FC<AnnouncementHelpProps> = ({
  userId,
}: AnnouncementHelpProps) => {
  return (
    <Link
      style={{
        backgroundColor: "transparent",
        border: "none",
        marginLeft: "1rem",
        padding: "0",
        display: "flex",
      }}
      to={HUBSPOT_SUPPORT_URL}
      target={"_blank"}
      rel={"noreferrer"}
    >
      <button
        className="announcement-header-link"
        style={{
          backgroundColor: "transparent",
          border: "none",
          padding: "0",
          cursor: "pointer",
        }}
        type="button"
        onClick={() => {
          emitAnalyticsTrackingEvent("logged_in_open_feedback", {}, userId);
        }}
      >
        <Text
          color={TEXT_COLOR.INVERT}
          lineHeight={TEXT_LINE_HEIGHT.SHORT}
          size={TEXT_SIZE.SMALL}
          weight={TEXT_WEIGHT.BOLD}
        >
          Help
        </Text>
      </button>
    </Link>
  );
};
