import AccountTypeBase from "./base";
import loadingBar from "../../stories/assets/loading_bar.svg";
import orangeFind from "../../stories/assets/orange_find.svg";
import calendarDetails from "../../stories/assets/calendar_details.svg";
import artistFirstIcon from "../../stories/assets/artist_first_icon.svg";
import artistSecondIcon from "../../stories/assets/artist_second_icon.svg";
import artistThirdIcon from "../../stories/assets/artist_third_icon.svg";

export default interface Artist extends AccountTypeBase {}

export const MockArtist: Artist = {
  id: 35,
  user_id: 60,
  created: "2021-11-16T22:28:38.351243",
  deleted: null,
  is_primary_type: null,
};

export const hearDifferenceDetails = [
  {
    image: loadingBar,
    text: "Upload Your Song",
  },
  {
    image: orangeFind,
    text: "Select a Verified Audio Engineer",
  },
  {
    image: calendarDetails,
    text: "Start Collaborating",
  },
];

export const artistPlatformDetails = [
  {
    image: artistFirstIcon,
    headerText: "Book Verified Engineers & Studios",
    bodyText:
      "All of our audio engineers and studios are vetted for quality and professionalism.",
  },
  {
    image: artistSecondIcon,
    headerText: "Improve Sound Quality",
    bodyText:
      "Compare your EngineEars mix to the original reference track to hear how your music gets better.",
  },
  {
    image: artistThirdIcon,
    headerText: "End-to-End Project Management",
    bodyText:
      "EngineEars is a one-stop solution with file sharing, messaging, payments, and more.",
  },
];
