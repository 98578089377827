import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Popover from "@radix-ui/react-popover";
import { Fragment } from "react";
import { useTheme } from "styled-components";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import useModal from "../../../hooks/useModal";
import { useGetRecordingSessionTimeData } from "../../../hooks/useRecordingSessionsHooks";
import { updateAvailabilityCache } from "../../../store/actions/availability";
import { fetchPaginatedRecordingSessions } from "../../../store/actions/paginatedRecordingSessions";
import { updateSessionBookingDetailsAfterModification } from "../../../store/actions/recording";
import { useAppDispatch } from "../../../store/hooks";
import { SummarizedRecordingSession } from "../../../store/models/recordingSession";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import {
  getProfileScreenRoute,
  getStudioRoomScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { InfoBadgeContainer } from "../../core-ui/components/InfoBadge/InfoBadge.styles";
import { PopoverFooter } from "../../core-ui/components/PopConfirm/PopConfirm.styles";
import {
  Text,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import { Divider } from "../BookingRequestedPanel/BookingDetails.styles";
import {
  RescheduleSessionContent,
  useRescheduleSession,
} from "../RescheduleSessionModal/RescheduleSessionModal";
import { SessionsOverview } from "../SessionsOverview/SessionsOverview";
import { SidePanelBottomDrawer } from "../SidePanel/SidePanelBottomDrawer";
import {
  BookingDetailsData,
  RescheduleSessionContainer,
  SessionBookingDetailsContainer,
  SessionBookingDetailsRow,
  SessionDetailsTitleContainer,
  StudioName,
  StudioRoomName,
  StyledEditButton,
  StyledSessionDetailsTitleHeaderContainer,
} from "./SessionRequestedPanel.styles";

interface SessionBookingDetailsStatsProps {
  recordingSessions: SummarizedRecordingSession[];
  bookingId: number;
  hasSessionBookingBeenCancelled: boolean;
  isSessionBookingPendingMyAcceptance: boolean;
}

export const SessionBookingDetailsStats = ({
  recordingSessions,
  bookingId,
  hasSessionBookingBeenCancelled,
  isSessionBookingPendingMyAcceptance,
}: SessionBookingDetailsStatsProps) => {
  return (
    <SessionBookingDetailsContainer>
      <Text size={TEXT_SIZE.LARGE} weight={TEXT_WEIGHT.SEMI_BOLD}>
        Booking details
      </Text>
      {recordingSessions.map((session, index) => {
        return (
          <Fragment key={session.id}>
            <SessionBookingSessionDetails
              session={session}
              index={index}
              bookingId={bookingId}
              hasSessionBookingBeenCancelled={hasSessionBookingBeenCancelled}
              isSessionBookingPendingMyAcceptance={
                isSessionBookingPendingMyAcceptance
              }
            />
            <Divider />
          </Fragment>
        );
      })}
      <SessionsOverview recordingSessions={recordingSessions} />
      <Divider />
    </SessionBookingDetailsContainer>
  );
};

// This component is coupled with the component above, so it makes sense to keep them together
interface SessionBookingSessionDetailsProps {
  session: SummarizedRecordingSession;
  index: number;
  bookingId: number;
  hasSessionBookingBeenCancelled: boolean;
  isSessionBookingPendingMyAcceptance: boolean;
}

const SessionBookingSessionDetails = ({
  session,
  index,
  bookingId,
  hasSessionBookingBeenCancelled,
  isSessionBookingPendingMyAcceptance,
}: SessionBookingSessionDetailsProps) => {
  const { sessionDurationHours, recordingDate, endTime, startTime } =
    useGetRecordingSessionTimeData(session);
  const theme = useTheme();
  const hasSessionBeenCancelled = Boolean(session.refunded);

  return (
    <div>
      <SessionDetailsTitleContainer>
        <StyledSessionDetailsTitleHeaderContainer>
          <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Session {index + 1}</Text>

          {(hasSessionBeenCancelled || hasSessionBookingBeenCancelled) && (
            <InfoBadgeContainer $backgroundColor={theme.darkerMidgroundColor}>
              <FontAwesomeIcon icon={faWarning} size="sm" />
              <Text>Session cancelled</Text>
            </InfoBadgeContainer>
          )}
        </StyledSessionDetailsTitleHeaderContainer>
        {!hasSessionBeenCancelled &&
          !hasSessionBookingBeenCancelled &&
          isSessionBookingPendingMyAcceptance && (
            <SessionRescheduleDropdown
              session={session}
              bookingId={bookingId}
            />
          )}
      </SessionDetailsTitleContainer>
      <BookingDetailsData>
        {session.studio_room && (
          <SessionBookingDetailsRow>
            <div>
              <StudioRoomName
                to={getStudioRoomScreenRoute(
                  session.studio_room.studio?.username,
                  session.studio_room.id,
                )}
              >
                {session.studio_room.room_name || "N/A"}
              </StudioRoomName>{" "}
              at{" "}
              <StudioName
                to={getStudioScreenRoute(session.studio_room.studio?.username)}
              >
                {session.studio_room.studio?.studio_profile?.display_name ||
                  "N/A"}
              </StudioName>
            </div>
            <Text
              size={TEXT_SIZE.SMALL}
            >{`${PennyDollarFormatter().format(session.studio_manager_payment_amount_split / sessionDurationHours)}/hr`}</Text>
          </SessionBookingDetailsRow>
        )}
        {session.engineer && session.engineer_username && (
          <SessionBookingDetailsRow>
            <div>
              <span>Recording engineer: </span>
              <StudioRoomName
                to={getProfileScreenRoute(session.engineer_username)}
              >
                @{session.engineer_username}
              </StudioRoomName>
            </div>
            <Text
              size={TEXT_SIZE.SMALL}
            >{`${PennyDollarFormatter().format(session.engineer_payment_amount_split / sessionDurationHours)}/hr`}</Text>
          </SessionBookingDetailsRow>
        )}
        <SessionBookingDetailsRow>
          <Text size={TEXT_SIZE.SMALL}>
            Session day: {recordingDate.toLocaleDateString()}
          </Text>
        </SessionBookingDetailsRow>
        <SessionBookingDetailsRow>
          <Text size={TEXT_SIZE.SMALL}>
            Start time: {startTime} - {endTime}
          </Text>
          <Text size={TEXT_SIZE.SMALL}>{sessionDurationHours} hrs</Text>
        </SessionBookingDetailsRow>
      </BookingDetailsData>
    </div>
  );
};

interface SessionRescheduleDropdownProps {
  session: SummarizedRecordingSession;
  bookingId: number;
}

const SessionRescheduleDropdown = ({
  session,
  bookingId,
}: SessionRescheduleDropdownProps) => {
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );
  const {
    isOpen: isRescheduleSessionPopoverOpen,
    setIsOpen: setIsRescheduleSessionPopoverOpen,
    closeModal: closeRescheduleSessionPopover,
  } = useModal();

  const { isMobile } = useMediaQueryBreakpoint();

  if (isMobile) {
    return (
      <>
        <SidePanelBottomDrawer
          isOpen={isRescheduleSessionPopoverOpen}
          onClose={closeRescheduleSessionPopover}
        >
          <PopoverContent
            bookingId={bookingId}
            closePopover={closeRescheduleSessionPopover}
            session={session}
          />
        </SidePanelBottomDrawer>
        <StyledEditButton
          onClick={() => {
            setIsRescheduleSessionPopoverOpen(true);
          }}
        >
          <Text style={{ textDecoration: "underline" }}>Modify Session</Text>
        </StyledEditButton>
      </>
    );
  }

  return (
    <BasePopover
      isOpen={isRescheduleSessionPopoverOpen}
      setIsPopoverOpen={setIsRescheduleSessionPopoverOpen}
      side="bottom"
      title="Reschedule Recording Session"
      additionalContent={
        isRescheduleSessionPopoverOpen && (
          <PopoverContent
            session={session}
            closePopover={closeRescheduleSessionPopover}
            bookingId={bookingId}
          />
        )
      }
      hideFooter
      wrapperElement={containerElement}
    >
      <Popover.Trigger asChild>
        <StyledEditButton>
          <Text style={{ textDecoration: "underline" }}>Modify Session</Text>
        </StyledEditButton>
      </Popover.Trigger>
    </BasePopover>
  );
};

interface PopoverContentProps {
  session: SummarizedRecordingSession;
  closePopover: () => void;
  bookingId: number;
}

const PopoverContent = ({
  session,
  closePopover,
  bookingId,
}: PopoverContentProps) => {
  const dispatch = useAppDispatch();
  const {
    handleRescheduleSession,
    isDisabled: isRescheduleOkButtonDisabled,
    rescheduleLoading,
    ...restSessionRescheduleProps
  } = useRescheduleSession(
    session,
    closePopover,
    async (updatedRecordingSession) => {
      dispatch(
        updateSessionBookingDetailsAfterModification({
          recordingSession: updatedRecordingSession,
          bookingId,
        }),
      );
      dispatch(
        updateAvailabilityCache([
          {
            ...session,
            markAsAvailable: true,
          },
          {
            ...updatedRecordingSession,
            markAsAvailable: false,
          },
        ]),
      );
      void dispatch(fetchPaginatedRecordingSessions());
    },
  );

  return (
    <RescheduleSessionContainer>
      <RescheduleSessionContent
        recordingSession={session}
        {...restSessionRescheduleProps}
      />
      <PopoverFooter>
        <Button variant={ButtonVariant.OUTLINED} onClick={closePopover}>
          Cancel
        </Button>
        <Button
          onClick={handleRescheduleSession}
          loading={rescheduleLoading}
          disabled={isRescheduleOkButtonDisabled}
        >
          Confirm
        </Button>
      </PopoverFooter>
    </RescheduleSessionContainer>
  );
};
