import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";

const selectPortfolioService = (state: RootState) => {
  return state.portfolioService;
};

export const getPaginatedCompletedProjectData = (currentPage: number) =>
  createSelector(selectPortfolioService, (portfolioStore) => {
    return portfolioStore.completedProjects[currentPage] ?? [];
  });

export const getPaginatorDetails = () =>
  createSelector(selectPortfolioService, (portfolioStore) => {
    const {
      totalPagesCompletedProjects,
      currentPageCompletedProjects,
      loadingCompletedProjects,
      countCompletedProjects,
    } = portfolioStore;
    return {
      totalPages: totalPagesCompletedProjects,
      currentPage: currentPageCompletedProjects,
      loading: loadingCompletedProjects,
      count: countCompletedProjects,
    };
  });

export const getPortfolioFeatureDataFromProjectId = (projectId?: number) =>
  createSelector(selectPortfolioService, (portfolioStore) => {
    if (!projectId) return undefined;
    return portfolioStore.featuredTracks.find(
      (track) => track.track_details?.project?.id === projectId,
    );
  });
