import { Box, Theme, useMediaQuery } from "@mui/material";
import { UniqueIdentifier } from "@dnd-kit/core";

import { TextStyleVariant } from "../Text/TextUtils";
import { Text } from "../Text/Text";
import { ChipsList, ISingleItem } from "./ChipsList";
import { BaseAccordion } from "../BaseAccordion/BaseAccordion";
import { ChipsListDisplayContainer } from "./ChipsListDisplay.styles";

interface ChipsListDisplayProps {
  title: string;
  dataSource: ISingleItem[];
  isEditMode: boolean;
  loading: boolean;
  onAddItem?: (newItem: UniqueIdentifier) => void;
  onDeleteItem?: (item: UniqueIdentifier) => void;
  optionsToAdd?: ISingleItem[];
  children?: React.ReactNode;
  handleElementClick?: (value: string | number) => void;
}

export const ChipsListDisplay = ({
  title,
  dataSource,
  isEditMode,
  loading,
  onAddItem = () => {},
  onDeleteItem = () => {},
  optionsToAdd = [],
  children = null,
  handleElementClick,
}: ChipsListDisplayProps) => {
  const isTabletOrBelow = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );

  if (dataSource.length === 0 && !isEditMode) {
    return null;
  }

  if (isTabletOrBelow) {
    return (
      <BaseAccordion
        renderHeader={() => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              bold
              variant={TextStyleVariant.P2}
            >{`${title} (${dataSource.length})`}</Text>
          </Box>
        )}
      >
        {children ? (
          children
        ) : (
          <ChipsList
            dataSource={dataSource}
            onDeleteItem={onDeleteItem}
            editMode={isEditMode}
            onAddItem={onAddItem}
            loading={loading}
            optionsToAdd={optionsToAdd}
            handleElementClick={handleElementClick}
          />
        )}
      </BaseAccordion>
    );
  }
  return (
    <ChipsListDisplayContainer>
      <Text bold variant={TextStyleVariant.P2}>
        {`${title} (${dataSource.length})`}
      </Text>
      {children ? (
        children
      ) : (
        <ChipsList
          dataSource={dataSource}
          onDeleteItem={onDeleteItem}
          editMode={isEditMode}
          onAddItem={onAddItem}
          loading={loading}
          optionsToAdd={optionsToAdd}
          handleElementClick={handleElementClick}
        />
      )}
    </ChipsListDisplayContainer>
  );
};
