import { useMemo } from "react";
import { ProjectType } from "../store/models/project";
import Service from "../store/models/service";

export const useHasMasteringService = (services: Array<Service>) => {
  return useMemo(() => {
    return services.some(
      (service) => service.service_type === ProjectType.MASTERING,
    );
  }, [services]);
};
