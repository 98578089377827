import styled from "styled-components";

export const EnterPasswordViewWrapper = styled.div<{ $navHeight: number }>`
  background-color: ${({ theme }) => theme.foregroundColor};
  border-radius: 17px;
  align-self: center;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  box-shadow: 0 0 6px rgba(109, 109, 109, 0.18);
  margin-top: ${({ $navHeight }) => $navHeight}px;

  & > .editable-text-field {
    margin-top: 1em;
  }

  & > .v1-button {
    width: 100%;
    margin-top: 1em;
  }

  & > p {
    text-align: center;
  }
`;
