import { useState } from "react";
import TabPanel from "../../elements/TabPanel/TabPanel";
import Tabs from "../../elements/Tabs/Tabs";
import { Text } from "../../core-ui/components/Text/Text";
import AdminDashboardActivityCard from "./AdminDashboardActivityCard";
import AdminDashboardStudioTeamCard from "./AdminDashboardStudioTeamCard";
import { ActionItems } from "../../components/ActionItems/ActionItems";
import { AdminDashboardMobileScreenContainer } from "./AdminDashboardScreen.styles";
import { DisplayOptions, togglePanelAtom } from "./atoms";
import { useSetAtom } from "jotai";

const displayOptionsMap = {
  [DisplayOptions.Action_Items]: <Text>Action Items</Text>,
  [DisplayOptions.Activity]: <Text>Activity</Text>,
  [DisplayOptions.Team]: <Text>Team</Text>,
};

export const AdminDashboardMobileScreen = () => {
  const [tabValue, setTabValue] = useState(DisplayOptions.Action_Items);
  const handlePanelToggle = useSetAtom(togglePanelAtom);
  return (
    <AdminDashboardMobileScreenContainer>
      <Tabs
        onChange={(_e, index: number) => {
          setTabValue(index);
        }}
        value={tabValue}
        tabTitles={displayOptionsMap}
      />
      <TabPanel value={tabValue} index={DisplayOptions.Action_Items}>
        <ActionItems
          onClick={() => {
            handlePanelToggle(DisplayOptions.Action_Items);
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={DisplayOptions.Activity}>
        <AdminDashboardActivityCard
          onClick={() => {
            handlePanelToggle(DisplayOptions.Activity);
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={DisplayOptions.Team}>
        <AdminDashboardStudioTeamCard
          onClick={() => {
            handlePanelToggle(DisplayOptions.Team);
          }}
        />
      </TabPanel>
    </AdminDashboardMobileScreenContainer>
  );
};
