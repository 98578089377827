import {
  faArrowTrendDown,
  faArrowTrendUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./../../components/PerformanceChart/PerformanceChart.css";
import "./TrendingPercentageText.css";

import classNames from "classnames";

export interface TrendingPercentageTextProps {
  percentage: number;
  label: string;
}

export const TrendingPercentage = ({
  percentage,
  label,
}: TrendingPercentageTextProps) => {
  const trendArrowClassName = classNames({
    "chart-header-button-icon": true,
    "negative-icon": percentage < 0,
  });

  const trendPercentageClassName = classNames({
    "chart-header-button-percentage": true,
    "negative-percentage": percentage < 0,
  });
  return (
    <div className="trending-percentage-container">
      {percentage !== 0 || null ? (
        <>
          <FontAwesomeIcon
            icon={percentage < 0 ? faArrowTrendDown : faArrowTrendUp}
            className={trendArrowClassName}
          />
          <span className={trendPercentageClassName}>{percentage}%</span>
        </>
      ) : (
        "--"
      )}
      <p className="chart-header-button-text"> {label}</p>
    </div>
  );
};
