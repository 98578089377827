import debounce from "lodash.debounce";
import { ReactNode, useEffect, useState, useMemo, useCallback } from "react";
import {
  fetchAutocomplete,
  fetchMapAutocomplete,
} from "../../../store/actions/autocomplete";
import { setSearchQuery } from "../../../store/actions/search";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  AutoCompleteTypeEnum,
  AutocompleteSuggestion,
} from "../../../store/models/autocomplete";
import { ProjectType } from "../../../store/models/project";
import { EntitySearchResultList } from "../../components/EntitySearchResultList/EntitySearchResultList";
import { SearchResultOverlay } from "../../components/SearchResultOverlay/SearchResultOverlay";
import "./SearchResultHOCScreen.css";
import useSearch from "../../../hooks/searchHooks/useSearch";

interface SearchResultsHOCScreenProps {
  autoCompleteTypeFilter?: AutoCompleteTypeEnum;
  toggleFilters?: () => void;
  children?: ReactNode;
  placeholder?: string;
  showQuickFilters?: boolean;
  searchTerm?: string;
  updateSearchView?: () => void;
  mapView?: boolean;
  showResultsViewOptions?: boolean;
}

export const SearchResultsHOCScreen = ({
  children,
  autoCompleteTypeFilter,
  toggleFilters,
  placeholder,
  showQuickFilters = true,
  updateSearchView,
  mapView,
  showResultsViewOptions = true,
}: SearchResultsHOCScreenProps) => {
  const { isFetchingAutocomplete, autocompleteSuggestions, setSearchTerm } =
    useSearch({
      enabled: true,
      mapView,
    });
  const [isActive, setIsActive] = useState(false);

  const onListItemClick = useCallback(() => {
    setSearchTerm("");
  }, [setSearchTerm]);

  const userQuickFilters = [
    AutoCompleteTypeEnum.GENRE,
    AutoCompleteTypeEnum.ARTIST_CREDIT,
    AutoCompleteTypeEnum.USER,
    AutoCompleteTypeEnum.SERVICE_TYPE,
  ];

  return (
    <>
      <SearchResultOverlay
        placeholder={placeholder}
        inputfieldActive={isActive}
        toggleFilters={toggleFilters}
        setInputfieldActive={setIsActive}
        isEngineerSearch={autoCompleteTypeFilter === AutoCompleteTypeEnum.USER}
        isStudioSearch={autoCompleteTypeFilter === AutoCompleteTypeEnum.STUDIO}
        showQuickFilters={showQuickFilters}
        updateSearchView={updateSearchView}
        mapView={mapView}
        showResultViewOptions={showResultsViewOptions}
      >
        <EntitySearchResultList
          loading={isFetchingAutocomplete}
          data={autocompleteSuggestions.filter((result) => {
            switch (autoCompleteTypeFilter) {
              case AutoCompleteTypeEnum.STUDIO:
                if (
                  result.type === AutoCompleteTypeEnum.SERVICE_TYPE &&
                  result.value === ProjectType.RECORDING
                ) {
                  return true;
                }
                return !userQuickFilters.includes(result.type);
              case AutoCompleteTypeEnum.USER:
                return result.type !== AutoCompleteTypeEnum.STUDIO;
              default:
                return true;
            }
          })}
          onListItemClick={onListItemClick}
        />
      </SearchResultOverlay>
      <div className="screen-wrapper">
        {children}
        <div
          className="screen-overlay"
          style={{
            display:
              isActive &&
              (Boolean(autocompleteSuggestions.length) ||
                isFetchingAutocomplete)
                ? "block"
                : "none",
          }}
        />
      </div>
    </>
  );
};
