import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { ProjectType } from "../../../../../store/models/project";
import { Text } from "../../../../core-ui/components/Text/Text";
import { ProjectWorkflowStageVariant } from "./ProjectWorkflowStage";

interface ProjectWorkflowStagesRowProps {
  $selectedIndex: number;
}
interface ProjectWorkflowStageContainerProps {
  $isDarkMode?: boolean;
  $variant: ProjectWorkflowStageVariant;
  $width?: number;
  $isCancelledSession?: boolean;
  $modificationPending?: boolean;
}

export const STAGE_CONTAINER_WIDTH = 118;
export const MASTER_STAGE_CONTAINER_WIDTH = 160;
export const SESSION_STAGE_CONTAINER_WIDTH = 145;

export const stageContainerWidth: Record<ProjectType, number> = {
  [ProjectType.NO_TYPE]: STAGE_CONTAINER_WIDTH,
  [ProjectType.RECORDING]: SESSION_STAGE_CONTAINER_WIDTH,
  [ProjectType.MIXING]: STAGE_CONTAINER_WIDTH,
  [ProjectType.MASTERING]: MASTER_STAGE_CONTAINER_WIDTH,
  [ProjectType.TWO_TRACK_MIXING]: STAGE_CONTAINER_WIDTH,
  [ProjectType.ATMOS_MIXING]: STAGE_CONTAINER_WIDTH,
};

export const ProjectWorkflowStagesRow = styled.div<ProjectWorkflowStagesRowProps>`
  align-items: center;
  display: flex;
  margin: 24px 0;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const ProjectWorkflowStageContainer = styled.div<ProjectWorkflowStageContainerProps>`
  align-items: center;
  background-color: ${({
    theme,
    $isDarkMode,
    $variant,
    $isCancelledSession,
    $modificationPending,
  }) => {
    if ($isCancelledSession) {
      return theme.darkerMidgroundColor;
    }
    if ($variant !== ProjectWorkflowStageVariant.CURRENT) {
      return "transparent";
    }
    if ($modificationPending) {
      return theme.colorPalette.BoxyYellow100;
    }
    return $isDarkMode
      ? theme.colorPalette.Green950
      : theme.colorPalette.Green100;
  }};
  border-radius: ${({ theme }) => theme.border.radius.xs};
  border: solid 1px
    ${({ theme, $variant, $modificationPending, $isCancelledSession }) => {
      if ($isCancelledSession) {
        return theme.darkerMidgroundColor;
      }
      if (
        $variant === ProjectWorkflowStageVariant.NEXT ||
        $variant === ProjectWorkflowStageVariant.INCOMPLETE
      ) {
        return theme.textTertiaryColor;
      }
      if ($modificationPending) {
        return theme.colorPalette.BoxyYellow400;
      }
      return theme.colorPalette.Green600;
    }};
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  height: 24px;
  justify-content: center;
  min-width: ${({ $width = STAGE_CONTAINER_WIDTH }) => $width}px;
  padding: 8px;
`;

export const ProjectWorkflowStageSeparator = styled.hr<ProjectWorkflowStageContainerProps>`
  border: none;
  border-bottom: solid 1px
    ${({ theme, $variant, $modificationPending, $isCancelledSession }) => {
      if ($isCancelledSession) {
        return theme.textTertiaryColor;
      }
      if ($variant === ProjectWorkflowStageVariant.NEXT) {
        return theme.textTertiaryColor;
      }
      return $modificationPending
        ? theme.colorPalette.BoxyYellow400
        : theme.colorPalette.Green600;
    }};
  margin: 0;
  min-width: 16px;
  width: 32px;
`;

export const ProjectWorkflowStageIcon = styled(FontAwesomeIcon)<{
  $isCancelledSession: boolean;
}>`
  color: ${({ theme, $isCancelledSession }) =>
    $isCancelledSession ? theme.textPrimaryColor : theme.colorPalette.Green600};
`;

export const ProjectWorkflowStageText = styled(
  Text,
)<ProjectWorkflowStageContainerProps>`
  color: ${({ theme, $isDarkMode, $variant, $modificationPending }) => {
    switch ($variant) {
      case ProjectWorkflowStageVariant.NEXT:
        return theme.textTertiaryColor;
      case ProjectWorkflowStageVariant.CURRENT:
        if ($isDarkMode) {
          return $modificationPending
            ? theme.colorPalette.Black
            : theme.colorPalette.Green100;
        }
        return theme.colorPalette.Green950;
      case ProjectWorkflowStageVariant.COMPLETE:
        return theme.colorPalette.Green600;
      default:
        return theme.textPrimaryColor;
    }
  }} !important;
  white-space: nowrap;
`;
