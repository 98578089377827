import { type ReactNode } from "react";
import { DEFAULT_TAB_WIDTH, TabContainer } from "./Tab.styles";

export interface TabProps {
  children: ReactNode;
  selected: boolean;
  index: number;
  disabled?: boolean;
  onClick?: () => void;
  tabWidth?: number;
}

export const Tab = ({
  children,
  selected,
  onClick,
  disabled = false,
  tabWidth = DEFAULT_TAB_WIDTH,
}: TabProps) => {
  return (
    <TabContainer
      $tabWidth={tabWidth}
      $selected={selected}
      $disabled={disabled}
      onClick={onClick}
    >
      {children}
    </TabContainer>
  );
};
