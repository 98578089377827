import {
  availability_multiplier,
  FirstPassDate,
} from "../../stories/components/ProjectBookingCalendar/ProjectBookingCalendar";
import { Alt, ProjectTypeToDefaultAltsMap } from "../models/alts";
import { ProjectType } from "../models/project";
import {
  ALBUM_DISCOUNT,
  BASE_RATE_MULTIPLIER,
  EP_DISCOUNT,
  EP_LENGTH,
  FULL_ALBUM_LENGTH,
  ScheduledProject,
} from "../models/scheduledproject";
import Service from "../models/service";
import { getDateFromString, getFormattedDate } from "./dateTimeUtils";
import { PennyDollarFormatter } from "./formatUtils";

export const getScheduledProjectsThatMatchType = (
  scheduledProjects: ScheduledProject[],
  projectType: ProjectType,
) => {
  return scheduledProjects.filter((scheduledProject) => {
    let hasProjectType = false;
    scheduledProject.projects.forEach((project) => {
      if (!hasProjectType && project.service_type === projectType) {
        hasProjectType = true;
      }
    });
    return hasProjectType;
  });
};

export const getSongRateMultiplier = (
  numSongs: number,
  bulkDiscountDisabled: boolean = false,
) => {
  if (bulkDiscountDisabled) {
    return BASE_RATE_MULTIPLIER;
  }
  if (numSongs >= FULL_ALBUM_LENGTH) {
    return ALBUM_DISCOUNT;
  }

  if (numSongs >= EP_LENGTH) {
    return EP_DISCOUNT;
  }

  return BASE_RATE_MULTIPLIER;
};

export const generateSongDatesMap = (
  scheduledProject: ScheduledProject,
  availabilities: Map<string, availability_multiplier>,
) =>
  new Map<string, FirstPassDate>(
    [...scheduledProject?.projects]
      .sort((a, b) => a.id - b.id)
      .map((project) => {
        const availability = availabilities.get(
          getFormattedDate(new Date(project.first_pass_date!)),
        );
        if (availability && availability?.length < 3) availability!.push(3);

        return [
          project.title,
          {
            date: getDateFromString(project.first_pass_date as string),
            availability: availability,
          } as FirstPassDate,
        ];
      }),
  );

export const basePricePerSong = (service: Service, altsList: Alt[]) => {
  const servicePrice = +service.service_rate!.price;
  return (
    servicePrice +
    (altsList.length -
      ProjectTypeToDefaultAltsMap.get(service.service_type!)!.size) *
      +service.extra_alt_price!
  );
};

export const getSongMapTotal = (
  basePrice: number,
  songMap: Map<string, FirstPassDate>,
  bulkDiscountDisabled: boolean = false,
) =>
  Array.from(songMap).reduce(
    (total, [k, v], i) =>
      total +
      v.availability[1] *
        basePrice *
        getSongRateMultiplier(i + 1, bulkDiscountDisabled),
    0,
  );

export const getFormattedSongMapTotal = (
  basePrice: number,
  songMap: Map<string, FirstPassDate>,
  bulkDiscountDisabled: boolean = false,
) =>
  PennyDollarFormatter().format(
    getSongMapTotal(basePrice, songMap, bulkDiscountDisabled),
  );
