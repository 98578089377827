import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import useInvalidateOnboardingProgress from "../../../../hooks/onboardingHooks/useInvalidateOnboardingProgress";
import { useIsAdmin } from "../../../../hooks/useIsAdmin";
import useModal from "../../../../hooks/useModal";
import { useQueryParam } from "../../../../hooks/useQueryParam";
import {
  artistMasteringTransitions,
  artistMixingTransitions,
} from "../../../../store/actions/projects";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  MasteringTransitions,
  MixingTransitions,
  ProjectById,
  ProjectType,
  projectTypeReadableName,
  projectTypeToEventStringMap,
} from "../../../../store/models/project";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../core-ui/components/BasePopover/PopoverContainerContext";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { CheckBox } from "../../../core-ui/components/CheckBox/CheckBox";
import { LoginRequiredPopover } from "../../../core-ui/components/LoginRequiredPopover/LoginRequiredPopover";
import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { EditableTextField } from "../../../elements/EditableTextField/EditableTextField";
import {
  ApproveMixPopoverContent,
  InstagramInputCard,
  InstagramInputContainer,
  InstagramLogo,
} from "../InProgressTransitionView.styles";

interface ApproveMixButtonProps {
  isInProgressStep: boolean;
  isArtist: boolean;
  project: ProjectById;
  isInProgressProject?: boolean;
}

/**
 * Updated version of the `ArtistAcceptRejectMix` component.
 * Updated version of the `ArtistAcceptRejectProjectModal` component.
 */
export const ApproveMixButton = ({
  isInProgressStep,
  isArtist,
  project,
  isInProgressProject = false,
}: ApproveMixButtonProps) => {
  const dispatch = useAppDispatch();
  const codeQuery = useQueryParam("code");
  const code = codeQuery.get();
  const [isLoading, setIsLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [socialMediaSharingApproval, setSocialMediaSharingApproval] =
    useState(true);
  const [instagramUsername, setInstagramUsername] = useState("");
  const user = useAppSelector((state) => state.accountInfo.user);
  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();
  const projectType = project.service_type;
  const projectTypeText =
    projectType === ProjectType.MASTERING ? "master" : "mix";
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );
  const { invalidateOnboardingProgress } = useInvalidateOnboardingProgress();
  const isAdminOnlyUser = useIsAdmin(user) && !isArtist;

  const handleAcceptMix = useCallback(() => {
    const projectTypeName = projectTypeToEventStringMap.get(projectType);
    emitAnalyticsTrackingEvent(
      `clicked_accept_song_${projectTypeName}`,
      {},
      user?.id,
    );
    const transitionParams = {
      project_id: project.id.toString(),
      code: code,
      instagram_username: instagramUsername,
      // If this is an admin assigned project and the admin is approving the mix,
      // it should never approve social media sharing.
      social_media_sharing_approval: isAdminOnlyUser
        ? false
        : socialMediaSharingApproval,
    };
    setIsLoading(true);
    if (projectType === ProjectType.MASTERING) {
      void dispatch(
        artistMasteringTransitions({
          ...transitionParams,
          transition: MasteringTransitions.ARTIST_ACCEPTING_MASTER,
        }),
      )
        .unwrap()
        .finally(async () => {
          setIsLoading(false);
          await invalidateOnboardingProgress();
        });
    } else {
      void dispatch(
        artistMixingTransitions({
          ...transitionParams,
          transition: MixingTransitions.ARTIST_ACCEPT_MIX,
          is_in_progress_project: isInProgressProject,
        }),
      )
        .unwrap()
        .finally(async () => {
          setIsLoading(false);
          await invalidateOnboardingProgress();
        });
    }
  }, [
    dispatch,
    project.id,
    socialMediaSharingApproval,
    code,
    instagramUsername,
    projectType,
    user?.id,
    isInProgressProject,
    isAdminOnlyUser,
  ]);

  const popoverContent = () => (
    <ApproveMixPopoverContent>
      <Text>
        Once submitted, your engineer will upload the final project steps to
        complete this project.
      </Text>
      <CheckBox
        checked={acceptTerms}
        label={
          <Text variant={TextStyleVariant.P2}>
            I accept that I am waiving a right to a refund and any further
            requests for revisions may be subject to additional fees.
          </Text>
        }
        onClick={() => setAcceptTerms(!acceptTerms)}
      />
      {!isAdminOnlyUser && (
        <>
          <CheckBox
            checked={socialMediaSharingApproval}
            label={
              <Text variant={TextStyleVariant.P2}>
                EngineEars has my permission to share a 15-second snippet of my{" "}
                {projectTypeReadableName.get(projectType)} and demo track on
                socials.
              </Text>
            }
            onClick={() =>
              setSocialMediaSharingApproval(!socialMediaSharingApproval)
            }
          />
          {socialMediaSharingApproval &&
            user &&
            !user.profile?.instagram_username && (
              <InstagramInputCard>
                <Text variant={TextStyleVariant.P2}>
                  Update your Instagram username to be notified if you&apos;re
                  selected!
                </Text>
                <InstagramInputContainer>
                  <InstagramLogo icon={faInstagram} />
                  <EditableTextField
                    editMode
                    initialValue={instagramUsername}
                    label="Instagram username"
                    onChange={setInstagramUsername}
                  />
                </InstagramInputContainer>
              </InstagramInputCard>
            )}
        </>
      )}
    </ApproveMixPopoverContent>
  );

  if (user && !isArtist && !isInProgressProject && !isAdminOnlyUser) {
    return (
      <Button
        variant={ButtonVariant.DISABLED}
        fullWidth
        onClick={() => {
          toast.error(
            `Only the account that booked the project can officially approve the ${projectTypeText}`,
          );
        }}
      >
        Approve {projectTypeText}
      </Button>
    );
  }

  return (
    <LoginRequiredPopover
      authConfirmDescription={`In order to officially approve the ${projectTypeText}, please sign in to the account that originally booked the project.`}
      isOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      closePopover={closePopover}
      side="top"
      title="Are you sure?"
      description=""
      okButtonProps={{ disabled: !acceptTerms }}
      okText="Approve"
      additionalContent={popoverContent()}
      onConfirm={handleAcceptMix}
      wrapperElement={containerElement}
      ignoreAuth={isInProgressProject}
    >
      <Button
        fullWidth
        disabled={isLoading || isInProgressStep}
        loading={isLoading}
        variant={ButtonVariant.PRIMARY}
      >
        Approve {projectTypeText}
      </Button>
    </LoginRequiredPopover>
  );
};
