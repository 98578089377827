import { ContentType } from "../../../store/models/fileVersion";

/**
 * File size limit for WAV files in bytes (200 MB)
 * Since WAV files are processed on our servers, large files can cause issues
 */
const WAV_SIZE_LIMIT = 200_000_000;
export const WAV_SIZE_LIMIT_MB = 200;

/**
 * Formats the file size to a more familiar format (megabytes)
 * This can be refactored to handle different formats, but this works for now.
 */
export const formatFileSizeToMegaBytes = (bytes: number) => {
  return (bytes / 1024 / 1024).toFixed(2);
};

/**
 * Checks if the file is a WAV that exceeds the size limit and if it's an atmos project
 * Since WAV files are processed on our servers, large files can cause issues
 */
export const isWavTooBig = (file: File | undefined, isAtmos: boolean) => {
  if (!file) return false;
  const isWav = file.type === ContentType.WAV;
  return file.size > WAV_SIZE_LIMIT && isWav && isAtmos;
};
