import { GET_OPTED_IN_UNVERIFIED_ENGINEERS } from "../../../../../store/utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../../../../../store/utils/fetch";
import User from "../../../../../store/models/user";

export const fetchPromotionEngineers = async (): Promise<User[]> => {
  const result = await makeBackendGetCallWithJsonResponse<User[]>(
    GET_OPTED_IN_UNVERIFIED_ENGINEERS,
    "",
  );
  if (result.success) {
    return result.resultJson;
  }
  return Promise.reject(result);
};
