import React, { useEffect, useMemo, useState } from "react";
import {
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_STATUS,
} from "../../../store/models/subscription";
import { PaymentPlanCheckbox } from "../../components/PaymentPlanCheckbox/PaymentPlanCheckbox";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import "./SelectSubPaymentPlanScreen.css";
import {
  SelectSubPaymentPlanCardsContainer,
  SelectSubPaymentPlanContainer,
  SelectSubPaymentPlanFooter,
  SelectSubPaymentPlanHeader,
  SelectSubPaymentPlanInfoBox,
} from "./SelectSubPaymentPlanScreen.styles";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useGetSubscriptionStatus } from "../../../hooks/useGetSubscriptionStatus";
import { getStripePortalSession } from "../../../store/actions/subscriptions";

export interface SelectSubPaymentPlanScreenProps {
  selectedSubscription: SUBSCRIPTION_PLAN;
  setSelectedSubscription: (plan: SUBSCRIPTION_PLAN) => void;
  onClickPreviousStep: () => void;
  onGoToCheckout: () => void;
  useStudioBenefits?: boolean;
}

export function getAnnualVersionOfSubscription(plan: SUBSCRIPTION_PLAN) {
  switch (plan) {
    case SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY:
    case SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY:
    case SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY:
      return SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY;
    case SUBSCRIPTION_PLAN.LEGACY_GOLD_PLAN:
    case SUBSCRIPTION_PLAN.LEGACY_GOLD_QUARTERLY_PLAN:
    case SUBSCRIPTION_PLAN.LEGACY_GOLD_YEARLY_PLAN:
      return SUBSCRIPTION_PLAN.LEGACY_GOLD_YEARLY_PLAN;
    case SUBSCRIPTION_PLAN.LEGACY_PLATINUM_PLAN:
    case SUBSCRIPTION_PLAN.LEGACY_PLATINUM_YEARLY_PLAN:
      return SUBSCRIPTION_PLAN.LEGACY_PLATINUM_YEARLY_PLAN;
    case SUBSCRIPTION_PLAN.LEGACY_DIAMOND_PLAN:
      return SUBSCRIPTION_PLAN.LEGACY_DIAMOND_PLAN;
  }
  return SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN;
}

export function getDefaultVersionOfSubscription(plan: SUBSCRIPTION_PLAN) {
  switch (plan) {
    case SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY:
    case SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY:
    case SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY:
      return SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY;
    case SUBSCRIPTION_PLAN.LEGACY_GOLD_PLAN:
    case SUBSCRIPTION_PLAN.LEGACY_GOLD_QUARTERLY_PLAN:
    case SUBSCRIPTION_PLAN.LEGACY_GOLD_YEARLY_PLAN:
      return SUBSCRIPTION_PLAN.LEGACY_GOLD_PLAN;
    case SUBSCRIPTION_PLAN.LEGACY_PLATINUM_PLAN:
    case SUBSCRIPTION_PLAN.LEGACY_PLATINUM_YEARLY_PLAN:
      return SUBSCRIPTION_PLAN.LEGACY_PLATINUM_PLAN;
    case SUBSCRIPTION_PLAN.LEGACY_DIAMOND_PLAN:
      return SUBSCRIPTION_PLAN.LEGACY_DIAMOND_PLAN;
  }
  return SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN;
}

export function getQuarterlyVersionOfSubscription(plan: SUBSCRIPTION_PLAN) {
  switch (plan) {
    case SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY:
    case SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY:
    case SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY:
      return SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY;
  }
  return SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN;
}

export const SelectSubPaymentPlanScreen = ({
  selectedSubscription,
  setSelectedSubscription,
  onClickPreviousStep,
  onGoToCheckout,
  useStudioBenefits = false,
}: SelectSubPaymentPlanScreenProps) => {
  const dispatch = useAppDispatch();
  const [checkingOut, setCheckingOut] = useState(false);
  const annualPlan = getAnnualVersionOfSubscription(selectedSubscription);
  const quarterlyPlan = getQuarterlyVersionOfSubscription(selectedSubscription);
  const monthlyPlan = getDefaultVersionOfSubscription(selectedSubscription);
  const userData = useAppSelector((state) => state.accountInfo.user);
  const { data: subscription } = useGetSubscriptionStatus({
    user: userData,
    refetchSubsStatus: true,
    force: true,
  });

  const hasActiveSubscription = useMemo(() => {
    return (
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.LEGACY_BASIC_PLAN &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.ESSENTIALS_PLAN &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN &&
      subscription?.stripe_subscription_status === SUBSCRIPTION_STATUS.ACTIVE
    );
  }, [subscription]);

  return (
    <SelectSubPaymentPlanContainer>
      <SelectSubPaymentPlanHeader>
        <Text variant={TextStyleVariant.H4}>Payment Plan</Text>
        <Text variant={TextStyleVariant.P1}>
          {useStudioBenefits
            ? "Choose your payment plan. You'll have a free trial period with each plan."
            : "Choose your payment plan. You'll have a free trial period with each plan."}
        </Text>
      </SelectSubPaymentPlanHeader>
      <SelectSubPaymentPlanCardsContainer>
        {annualPlan !== SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN && (
          <PaymentPlanCheckbox
            subscriptionType={getAnnualVersionOfSubscription(
              selectedSubscription,
            )}
            selected={
              selectedSubscription ===
              getAnnualVersionOfSubscription(selectedSubscription)
            }
            setSelected={setSelectedSubscription}
            useStudioBenefits={useStudioBenefits}
            onGoToCheckout={onGoToCheckout}
          />
        )}
        {quarterlyPlan !== SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN && (
          <PaymentPlanCheckbox
            subscriptionType={getQuarterlyVersionOfSubscription(
              selectedSubscription,
            )}
            selected={
              selectedSubscription ===
              getQuarterlyVersionOfSubscription(selectedSubscription)
            }
            setSelected={setSelectedSubscription}
            useStudioBenefits={useStudioBenefits}
            onGoToCheckout={onGoToCheckout}
          />
        )}
        {monthlyPlan !== SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN && (
          <PaymentPlanCheckbox
            subscriptionType={getDefaultVersionOfSubscription(
              selectedSubscription,
            )}
            selected={
              selectedSubscription ===
              getDefaultVersionOfSubscription(selectedSubscription)
            }
            setSelected={setSelectedSubscription}
            useStudioBenefits={useStudioBenefits}
            onGoToCheckout={onGoToCheckout}
          />
        )}
      </SelectSubPaymentPlanCardsContainer>
      <SelectSubPaymentPlanFooter>
        <SelectSubPaymentPlanInfoBox>
          <Text variant={TextStyleVariant.P2} weight={TEXT_WEIGHT.BOLD}>
            Members get priority customer service support and early access to
            new features.
          </Text>
        </SelectSubPaymentPlanInfoBox>
        <Button
          loading={checkingOut}
          onClick={() => {
            setCheckingOut(true);
            if (hasActiveSubscription) {
              void dispatch(getStripePortalSession())
                .unwrap()
                .then(({ portal_url }) => {
                  window.location.href = portal_url;
                });
            } else {
              onGoToCheckout();
            }
          }}
          disabled={selectedSubscription === undefined}
        >
          Continue
        </Button>
        <Button
          onClick={onClickPreviousStep}
          variant={ButtonVariant.TEXT}
          style={{ marginBottom: 120 }}
        >
          Previous step
        </Button>
      </SelectSubPaymentPlanFooter>
    </SelectSubPaymentPlanContainer>
  );
};
