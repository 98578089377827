import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useIsUMGAandR } from "../../../hooks/useIsAandR";
import {
  PostPurchaseOrderArgs,
  submitBillingInfo,
} from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  DetailedPurchaseOrder,
  DetailedPurchaseOrderWithTransaction,
  PurchaseOrder,
} from "../../../store/models/project";
import { ScheduledProject } from "../../../store/models/scheduledproject";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { EditableTextField } from "../../elements/EditableTextField/EditableTextField";
import { BillingInfoForm } from "../BillingInfoForm/BillingInfoForm";
import "./PurchaseOrderRequestView.css";

export interface PurchaseOrderRequestViewProps {
  scheduledProject: ScheduledProject;
  hasBackDrop?: boolean;
  onUpdate?: (updatedProject: DetailedPurchaseOrderWithTransaction) => void;
  isAsideView?: boolean;
  purchaseOrder?: PurchaseOrder | DetailedPurchaseOrder;
  transactionId?: number;
}

export const PurchaseOrderRequestView: FC<PurchaseOrderRequestViewProps> = ({
  scheduledProject,
  hasBackDrop = false,
  onUpdate,
  isAsideView = false,
  purchaseOrder,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [postPurchaseArgs, setPostPurchaseArgs] =
    useState<PostPurchaseOrderArgs>({});
  const user = useAppSelector((state) => state.accountInfo.user);
  const isUMGAandR = useIsUMGAandR(user);
  const copy = useMemo(() => {
    let introText = "Please generate a purchase order (PO) number";
    if (isUMGAandR) {
      introText = "Please enter billing information";
    }
    return `${introText} for the amount ${PennyDollarFormatter().format(
      scheduledProject.outstanding_purchase_order_balance,
    )}, the price of the full project, in order to download files.`;
  }, [scheduledProject, isUMGAandR]);

  useEffect(() => {
    if (!purchaseOrder) return;
    setPostPurchaseArgs((currentPostPurchaseArgs) => {
      return {
        ...currentPostPurchaseArgs,
        order_number: purchaseOrder.order_number,
        cost_center: purchaseOrder.cost_center,
        work_breakdown_structure: purchaseOrder.work_breakdown_structure,
        general_ledger: purchaseOrder.general_ledger,
        major_label: purchaseOrder.purchase_order_label[0]?.major_label,
        umg_sub_label: purchaseOrder.purchase_order_label[0]?.umg_sub_label,
      };
    });
  }, [purchaseOrder]);

  const isEngineer = useMemo(() => {
    if (!user) return false;
    if (!scheduledProject.projects.length) return false;
    return scheduledProject.projects[0].engineer?.id === user?.id;
  }, [user, scheduledProject]);

  const handleSubmit = useCallback(() => {
    if (!purchaseOrder) return;
    setLoading(true);
    emitAnalyticsTrackingEvent(
      "add_purchase_order",
      {
        scheduled_project_id: `${scheduledProject.id}`,
      },
      user?.id,
    );
    dispatch(
      submitBillingInfo([
        {
          purchase_order_id: purchaseOrder.id,
          order_number: postPurchaseArgs?.order_number,
          cost_center: postPurchaseArgs?.cost_center,
          work_breakdown_structure: postPurchaseArgs?.work_breakdown_structure,
          general_ledger: postPurchaseArgs?.general_ledger,
        },
      ]),
    )
      .unwrap()
      .then((updatedPurchaseOrder) => {
        toast.success("Billing info submitted");
        setLoading(false);
        if (onUpdate) {
          onUpdate(updatedPurchaseOrder);
        }
      })
      .catch(() => {
        toast.error("Error submitting billing info");
        setLoading(false);
      });
  }, [
    user?.id,
    scheduledProject.id,
    dispatch,
    postPurchaseArgs?.order_number,
    postPurchaseArgs?.cost_center,
    postPurchaseArgs?.work_breakdown_structure,
    postPurchaseArgs?.general_ledger,
    onUpdate,
  ]);

  if (isEngineer) {
    return null;
  }

  return (
    <div
      className={"purchase-order-request-view "
        .concat(hasBackDrop ? "has-back-drop" : "")
        .concat(isAsideView ? "aside" : "")}
    >
      {isUMGAandR ? (
        <div
          style={{
            width: "100%",
          }}
        >
          <h6 className="h7-semi-bold mb-2">Billing Info Required</h6>
          <p className="b2">
            {copy} <br />
            <br /> Payment will not be processed and unlocked to the service
            provider until final delivered assets are approved.
          </p>
          <div className="purchase-order-form-container">
            <BillingInfoForm
              postPurchaseArgs={postPurchaseArgs}
              setPostPurchaseArgs={setPostPurchaseArgs}
            />
            {Boolean(user?.is_umg_po_aandr) && (
              <>
                <h2 className={"no-studio-option-divider label"}>
                  <span>OR</span>
                </h2>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <p className={"b2-semi-bold mb-2"}>
                    Enter PO number if not submitting Cost Center, WBS and GL
                  </p>
                </div>
                <EditableTextField
                  initialValue={""}
                  className="my-2"
                  label={"enter purchase order"}
                  onChange={(text) => {
                    setPostPurchaseArgs({
                      ...postPurchaseArgs,
                      order_number: text,
                    });
                  }}
                  editMode={true}
                />
              </>
            )}
            <Button
              fullWidth
              loading={loading}
              onClick={handleSubmit}
              variant={ButtonVariant.PRIMARY}
            >
              Submit Billing Info
            </Button>
          </div>
        </div>
      ) : (
        <>
          <h1 className="b1-semi-bold">Purchase Order Required</h1>
          <p className="b2">
            {copy} <br />
            <br /> Payment will not be processed and unlocked to the service
            provider until final delivered assets are approved.
          </p>
          <div className="purchase-order-form-container">
            <EditableTextField
              initialValue={""}
              className="my-2"
              label={"enter purchase order"}
              onChange={(text) => {
                setPostPurchaseArgs({
                  ...postPurchaseArgs,
                  order_number: text,
                });
              }}
              editMode={true}
            />
            <Button
              fullWidth
              loading={loading}
              disabled={loading || !postPurchaseArgs.order_number}
              variant={ButtonVariant.PRIMARY}
              onClick={handleSubmit}
            >
              submit purchase order
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
