import styled from "styled-components";
import { Text } from "../../core-ui/components/Text/Text";

export const ShareLinkContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShareLinkModalContainer = styled.div`
  background-color: ${({ theme }) => theme.foregroundColor};
  max-width: 460px;
  border-radius: ${({ theme }) => theme.border.radius.lg};
  position: relative;
  padding: 18px 26px;
  /* box-shadow copied from CSS file */
  box-shadow: 0px 0px 6px rgba(109, 109, 109, 0.18);
`;

export const ShareLinkText = styled(Text)`
  margin: 16px 0 8px 0;
`;

export const ShareLinkButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 24px;
`;

export const ShareLinkInput = styled.input`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.separatorColor};
  border-radius: ${({ theme }) => theme.border.radius.md};
  margin-top: 16px;
  padding: 10px;
  width: 100%;
`;
