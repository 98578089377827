import { useParams } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";

export const useIsVisitingOwnProfile = () => {
  const { user: userMe } = useAppSelector((state) => state.accountInfo);
  const { username } = useParams<{ username: string }>();

  if (!userMe || !username) {
    return false;
  }

  return userMe.username === username;
};
