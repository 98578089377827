import { useEffect, useMemo } from "react";

/**
 * @description Adds a style to the shadow root created by waveform library preventing overflow
 */
export const useAddStyleToShadowRoot = (
  id: string,
  width?: string,
  styleId = "waveformStyle",
) => {
  const shadowRoot = document.getElementById(id)?.firstElementChild?.shadowRoot;
  const style = document.createElement("style");
  style.innerHTML = ":host .canvases > div > canvas {width: 100% !important;}";

  const styleHtml = useMemo(() => {
    return `:host .canvases > div > canvas {width: 100% !important;} ${width ? `:host .progress > div > canvas {width: ${width} !important;}` : ""}`;
  }, [width]);

  useEffect(() => {
    if (shadowRoot) {
      const style = document.createElement("style");
      style.id = styleId;
      style.innerHTML =
        ":host .canvases > div > canvas {width: 100% !important;}";
      shadowRoot.appendChild(style);
    }
  }, [shadowRoot, styleId]);

  useEffect(() => {
    if (shadowRoot) {
      const element = shadowRoot.getElementById(styleId);
      if (element) {
        element.innerHTML = styleHtml;
      }
    }
  }, [shadowRoot, styleHtml, styleId]);
};
