import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import {
  getProjectOverviewLink,
  getScheduledProject,
} from "../../../store/actions/scheduledprojects";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { SignInModal } from "../../components/SignInModal/SignInModal";
import { Button } from "../../elements/Button/button";
import { EditableTextField } from "../../elements/EditableTextField/EditableTextField";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { EnterPasswordViewWrapper } from "../LoggedOutReviewScreen/LoggedOutReviewScreen.styles";
import { useAtomValue } from "jotai";
import { topNavSpacerHeightAtom } from "../../../atoms/navAtoms";
import { ProjectOverviewScreen } from "../ProjectOverviewScreen/ProjectOverviewScreen";

export const ScheduledProjectOverviewScreen = () => {
  const navHeight = useAtomValue(topNavSpacerHeightAtom);
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const code: null | string = query.get("code");
  const [password, setPassword] = useState<string>("");
  const [localPassword, setLocalPassword] = useState<string>("");
  const { isAuthenticated, isLoading } = useAppSelector(
    (state) => state.accountInfo,
  );
  const [scheduledProjectId, setScheduledProjectId] = useState<number>(0);

  useSetPageTitle("Project Overview");

  const isLoadingProjectOverviewScheduledProject = useAppSelector(
    (state) =>
      state.scheduledProjectsStore.isLoadingProjectOverviewScheduledProject,
  );
  const isLoadingScheduledProject = useAppSelector(
    (state) => state.scheduledProjectsStore.isLoadingScheduledProject,
  );
  const scheduledProject = useAppSelector(
    (state) => state.scheduledProjectsStore.scheduledProject,
  );

  const { scheduled_project_id } = useParams<{
    scheduled_project_id: string;
  }>();

  useEffect(() => {
    setScheduledProjectId(+scheduled_project_id);
  }, [scheduled_project_id]);

  useEffect(() => {
    if (!code) return; // Return early if there's no code.
    void dispatch(getProjectOverviewLink({ code, password }));
  }, [code, password, dispatch]);

  useEffect(() => {
    if (!scheduledProjectId || code) return; // Return early if there's a code.
    void dispatch(
      getScheduledProject({ scheduledProjectId: scheduledProjectId }),
    );
  }, [code, dispatch, scheduledProjectId]);

  if (!code && !isAuthenticated && !isLoading) {
    return <SignInModal label={"Sign In"} />;
  }

  if (
    isLoadingProjectOverviewScheduledProject ||
    isLoadingScheduledProject ||
    isLoading
  )
    return <LoadingScreen />;

  if (
    !isLoadingProjectOverviewScheduledProject &&
    !scheduledProject &&
    !isLoading &&
    code
  ) {
    return (
      <div className="container-fluid">
        <div className="container d-flex flex-column">
          <EnterPasswordViewWrapper
            $navHeight={navHeight}
            className="enter-password-view"
          >
            <p>This page is password protected</p>
            <EditableTextField
              isSecureText={true}
              onKeyPressed={(event) => {
                if (event.key === "Enter") setPassword(localPassword);
              }}
              initialValue={password}
              label={"enter password"}
              onChange={(value) => setLocalPassword(value)}
              editMode={true}
            />
            <Button
              label={"submit"}
              primary={true}
              onClick={() => setPassword(localPassword)}
            />
          </EnterPasswordViewWrapper>
        </div>
      </div>
    );
  }

  return (
    <ProjectOverviewScreen
      scheduledProject={scheduledProject}
      shareLinkCode={code ? code : undefined}
    />
  );
};
