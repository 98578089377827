import { faChevronDown, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {
  ChangeEvent,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import { WorkingHoursOptionType } from "../../../hooks/useGeneralWorkingHoursStringForDay";
import { LabelOption } from "../../components/LabelAssociationDropdown/LabelAssociationDropdown";
import { SelectEngineerOption } from "../../components/SelectAvailableTrackingEngineer/SelectAvailableTrackingEngineer";
import { DefaultSoundWaveLoader } from "../DefaultSoundWaveLoader/DefaultSoundWaveLoader";
import "./dropdown-selector.css";

export interface OptionType<T = number> {
  value: T;
  label: string;
}

interface CustomClassNames {
  [key: string]: string;
}

export interface IDropdownSelectorProps {
  options?:
    | OptionType[]
    | SelectEngineerOption[]
    | LabelOption[]
    | WorkingHoursOptionType[]
    | null;
  value?:
    | OptionType
    | SelectEngineerOption
    | LabelOption
    | WorkingHoursOptionType
    | null;
  onChange?: (
    value: OptionType | SelectEngineerOption | WorkingHoursOptionType,
  ) => void;
  placeholder?: string;
  hideArrow?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  onInputChange?: (value: string) => void;
  noOptionsMessage?: string;
  isLoading?: boolean;
  customComponents?: (
    option:
      | OptionType
      | SelectEngineerOption
      | LabelOption
      | WorkingHoursOptionType,
  ) => ReactNode;
  customFilter?: (candidate: any, input: string) => boolean;
  isClearable?: boolean;
  appendText?: string;
  customClassNames?: CustomClassNames;
}

export const DropdownSelector: FC<IDropdownSelectorProps> = ({
  options,
  value,
  placeholder = "select option",
  onChange,
  customComponents,
  noOptionsMessage,
  onInputChange,
  hideArrow = false,
  isSearchable = false,
  isLoading = false,
  isDisabled = false,
  appendText = "",
  customClassNames = {},
}) => {
  const [searchValue, setSearchValue] = useState("");
  const selectedValue = useMemo(() => {
    if (value && value.value > -1) {
      return value.value;
    }
    return undefined;
  }, [value]);

  const selectedLabel = useMemo(() => {
    if (value && value.value > -1) {
      return value.label + " " + appendText;
    }
    return undefined;
  }, [appendText, value]);
  const [localLabel, setLocalLabel] = useState<string | undefined>(
    selectedLabel,
  );

  useEffect(() => {
    setLocalLabel(selectedLabel);
  }, [selectedLabel]);

  const [showDropDown, setShowDropDown] = useState(false);
  const [hideSelectedValue, setHideSelectedValue] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    if (onInputChange) {
      onInputChange(event.target.value);
    }
  };

  useClickOutside(containerRef, () => setShowDropDown(false));

  const handleSelectChange = (value: number) => {
    setSearchValue("");
    setShowDropDown(false);
    if (onChange) {
      const selectedOption = options?.find((option) => {
        return option.value === value;
      });
      if (!selectedOption) {
        setLocalLabel(undefined);
        setSearchValue("");
        onChange({
          value: -1,
          label: "",
        });
      }
      if (selectedOption) {
        setLocalLabel(selectedOption.label);
        onChange(selectedOption);
      }
    }
  };

  return (
    <div
      className={classNames(
        "drop-down-selector-container",
        customClassNames.container,
      )}
      ref={containerRef}
    >
      <div
        className={classNames(
          "drop-down-select-content",
          customClassNames.selectContent,
        )}
      >
        <div
          onClick={() => {
            setShowDropDown(!showDropDown);
          }}
          className={classNames(
            "select-container",
            { "select-container-active": showDropDown },
            customClassNames.selectContainer,
          )}
        >
          {!isSearchable ? (
            <p
              className={classNames("b1", customClassNames.labelText)}
              style={customComponents ? { marginLeft: 5 } : {}}
            >
              {selectedLabel ?? localLabel ?? placeholder}
            </p>
          ) : (
            <>
              <input
                onFocus={() => {
                  setHideSelectedValue(true);
                }}
                onBlur={() => {
                  setHideSelectedValue(false);
                }}
                className={"select-container-search-input b1 ".concat(
                  !hideSelectedValue &&
                    selectedValue !== undefined &&
                    options &&
                    options?.length > 0
                    ? "hide-select-container-search-input"
                    : "",
                )}
                value={searchValue}
                onChange={handleSearchChange}
                placeholder={placeholder}
              />
              {selectedValue !== undefined &&
                !hideSelectedValue &&
                options &&
                options?.length > 0 && (
                  <div className="single-value-pill-container">
                    <span className="search-selector-pill">
                      {options?.find((option) => option.value === selectedValue)
                        ?.label ?? ""}
                    </span>
                  </div>
                )}
              {!isLoading && selectedValue !== undefined && (
                <div
                  className="drop-down-selected-clear-search"
                  onClick={() => {
                    handleSelectChange(-1);
                  }}
                >
                  <FontAwesomeIcon icon={faX} className="check-icon" />
                </div>
              )}
            </>
          )}
          {isLoading && (
            <div className="drop-down-slector-loading-indicator">
              <DefaultSoundWaveLoader />
            </div>
          )}
          <div
            className={classNames(
              "select-arrow-container",
              customClassNames.arrowContainer,
            )}
          >
            {!hideArrow && <FontAwesomeIcon icon={faChevronDown} />}
          </div>
        </div>
        <div
          className={"select-dropdown-menu ".concat(
            showDropDown && !isDisabled
              ? "show-select-menu"
              : "hide-select-menu",
          )}
        >
          {options?.length === 0 && (
            <div className="drop-down-option drop-down-no-options-message">
              <p className="b1">{noOptionsMessage}</p>
            </div>
          )}
          {options?.map(({ value, label }, index) => (
            <div
              onClick={() => handleSelectChange(value)}
              key={index}
              style={
                customComponents
                  ? {
                      paddingLeft: 10,
                    }
                  : {}
              }
              className={classNames(
                "drop-down-option",
                selectedValue === value ? "drop-down-option-active" : "",
                customClassNames.dropdownOption,
              )}
            >
              {customComponents ? (
                customComponents(options[index])
              ) : (
                <p className="b1">{`${label} ${appendText ?? ""}`}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
