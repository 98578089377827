import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import { loadEngineerServices } from "../store/actions/services";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { combineServicesFromResponse } from "../stories/screens/ProfileScreen/utils";

export const useGetEngineerServices = (engineerId = 0) => {
  const { user: userMe } = useAppSelector((state) => state.accountInfo);
  const dispatch = useAppDispatch();
  return useQuery({
    staleTime: userMe?.engineer?.id === engineerId ? Infinity : undefined,
    enabled: Boolean(engineerId),
    queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES, engineerId],
    queryFn: async () => {
      return dispatch(loadEngineerServices({ engineer_id: engineerId }))
        .unwrap()
        .then((res) => combineServicesFromResponse(engineerId, res, userMe));
    },
  });
};
