import React, {
  MutableRefObject,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { default as WaveSurferRef } from "wavesurfer.js";
import { useIsFileSelected } from "../../../hooks/useIsFileSelected";
import { selectFile } from "../../../store/actions/abPlayerStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  FileType,
  FileVersion,
  FileVersionComment,
} from "../../../store/models/fileVersion";
import {
  Project,
  ProjectById,
  ProjectType,
} from "../../../store/models/project";
import User from "../../../store/models/user";
import { getTrackComments } from "../../../store/selectors/trackComments";
import { formatTime } from "../../../store/utils/utils";
import { SwitchButtonAB } from "../../elements/SwitchButton/SwitchButtonAB/SwitchButtonAB";
import { UserProfileImage } from "../../elements/UserProfileImage/UserProfileImage";
import { FileDropDownSelection } from "../FileDropdownSelection/FileDropdownSelection";
import { WaveFormComponent } from "../WaveformComponent/WaveFormComponent";
import "./PlayBackRow.css";
import {
  StyledPlayBackRowColumn,
  StyledPlayBackRowCommentBubble,
  StyledPlayBackRowCommentContainer,
  StyledPlayBackRowCommentIconContainer,
  StyledPlayBackRowContainer,
  StyledPlayBackRowNoFileContainer,
  StyledPlayBackRowRow,
  StyledPlayBackRowSpan,
} from "./PlayBackRow.styles";

export interface PlayBackRowProps {
  footerPlayerRef: MutableRefObject<WaveSurferRef | null>;
  file?: FileVersion;
  storedFiles?: FileVersion[];
  showDropdown?: boolean;
  isReference: boolean;
  isLatestMain: boolean;
  canAddComment: boolean;
  isCurrentEngineer: boolean;
  loadComments: boolean;
  isSnippet?: boolean;
  labelBeforeAfter?: boolean;
  allowTrackPreview?: boolean;
  project?: Project | ProjectById;
  setMediaSessionTitle?: (_: string) => void;
  handleUpload?: (
    reference: boolean,
    uploadType: FileType.MP4 | FileType.WAV,
  ) => void;
  handleStateFileChange?: (File: FileVersion) => void;
  dolbyIcon?: ReactNode;
}

export const PlayBackRow = ({
  file,
  storedFiles = [],
  isReference,
  showDropdown = false,
  isLatestMain,
  canAddComment,
  isCurrentEngineer,
  loadComments = true,
  isSnippet = false,
  labelBeforeAfter = false,
  allowTrackPreview = true,
  project,
  footerPlayerRef,
  handleUpload,
  handleStateFileChange,
  dolbyIcon,
}: PlayBackRowProps) => {
  const ref = React.useRef<WaveSurferRef | null>(null);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const code: null | string = query.get("code");
  const dispatch = useAppDispatch();
  const { selectedTrack, isFooterReady } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const isSelected = useIsFileSelected(file);
  const [isAudioReady, setIsReady] = useState<boolean>(false);
  const { page } = useAppSelector((state) => state.fileVersionCommentsSlice);
  const fileComments = useAppSelector(getTrackComments(page, project?.id));
  const selectedCommentIdentifier: string | undefined = useAppSelector(
    (state) => state.selectedComment.commentIdentifier,
  );
  const showComment: boolean = useAppSelector(
    (state) => state.selectedComment.show,
  );

  const commentToShow: FileVersionComment | undefined = useMemo(() => {
    return fileComments.find(
      (comment) => comment.unique_css_identifier === selectedCommentIdentifier,
    );
  }, [fileComments, selectedCommentIdentifier]);

  const handleSelectFile = useCallback(
    (selectedFile?: FileVersion) => {
      if (!selectedFile) return;
      if (!isAudioReady) return;
      if (selectedTrack?.id === selectedFile.id) return;
      if (!allowTrackPreview) return;
      if (!ref.current) return;
      if (footerPlayerRef.current && !isFooterReady) return;
      if (handleStateFileChange) {
        handleStateFileChange(selectedFile);
      }
      const currentTime = ref.current.getCurrentTime();
      const duration = ref.current.getDuration();
      if (currentTime && duration && Math.abs(currentTime - duration) < 0.5) {
        footerPlayerRef.current?.seekTo(0);
      }
      dispatch(
        selectFile({
          track: selectedFile,
          isFocused: true,
          playOnLoad: true,
        }),
      );
    },
    [
      handleStateFileChange,
      isAudioReady,
      selectedTrack?.id,
      allowTrackPreview,
      footerPlayerRef,
      isFooterReady,
      dispatch,
    ],
  );

  const handleOnReady = useCallback((isReady: boolean) => {
    setIsReady(isReady);
  }, []);

  const label = useMemo(() => {
    if (isReference) return labelBeforeAfter ? "Before" : "A";
    if (isLatestMain) return labelBeforeAfter ? "After" : "B";
    if (!file) return "...";
    return `v1.${file?.version}`;
  }, [isReference, labelBeforeAfter, isLatestMain, file]);

  const commentAuthor = useMemo(() => {
    if (!commentToShow) return "";
    if (commentToShow.author_user) {
      return commentToShow.author_user.username;
    }
    if (commentToShow.author_name) {
      return commentToShow.author_name;
    }
    return "anonymous";
  }, [commentToShow]);

  const time = useMemo(() => {
    if (!commentToShow) return "";
    const { start_timestamp_in_seconds, end_timestamp_in_seconds } =
      commentToShow;
    if (!start_timestamp_in_seconds || !end_timestamp_in_seconds) return "";
    return formatTime(start_timestamp_in_seconds, end_timestamp_in_seconds);
  }, [commentToShow]);

  const commentUser: null | User = useMemo(() => {
    if (!commentToShow) return null;
    return commentToShow?.author_user;
  }, [commentToShow]);

  const emptyPlaybackRowText = useMemo(() => {
    if (
      project?.service_type === ProjectType.MIXING ||
      project?.service_type === ProjectType.TWO_TRACK_MIXING ||
      project?.service_type === ProjectType.ATMOS_MIXING
    )
      return "mix";
    if (project?.service_type === ProjectType.MASTERING) return "master";
    return "file";
  }, [project]);

  return (
    <StyledPlayBackRowContainer>
      {isSelected && (
        <StyledPlayBackRowCommentContainer
          $commentToShow={showComment}
          $showComment={Boolean(commentToShow)}
        >
          {commentUser && (
            <StyledPlayBackRowCommentIconContainer>
              <UserProfileImage
                isCircle={true}
                width={30}
                height={30}
                source={commentUser?.photo?.path}
              />
            </StyledPlayBackRowCommentIconContainer>
          )}
          {commentAuthor !== "" && (
            <StyledPlayBackRowCommentBubble className="b2">
              <StyledPlayBackRowSpan className="b1-semi-bold">{`${commentAuthor}: ${time}`}</StyledPlayBackRowSpan>
              <br />
              {commentToShow?.comment ?? ""}
            </StyledPlayBackRowCommentBubble>
          )}
        </StyledPlayBackRowCommentContainer>
      )}
      <StyledPlayBackRowColumn $isSnippet={isSnippet}>
        <StyledPlayBackRowRow>
          <SwitchButtonAB
            isSelected={isSelected}
            label={label}
            onClick={() => handleSelectFile(file)}
          />
          {showDropdown && (
            <FileDropDownSelection
              selectedFile={file}
              files={storedFiles}
              onClick={handleSelectFile}
              isReferenceUpload={isReference}
              project={project}
              projectTypeString={emptyPlaybackRowText}
              handleUpload={handleUpload}
            />
          )}
          {dolbyIcon}
        </StyledPlayBackRowRow>
        {file ? (
          <WaveFormComponent
            footerPlayerRef={footerPlayerRef}
            waveformReference={ref}
            onReady={handleOnReady}
            isCurrentEngineer={isCurrentEngineer}
            fileVersionId={file.id}
            isSelected={isSelected}
            code={code}
            loadComments={loadComments}
            canAddComment={canAddComment}
            isSnippet={isSnippet}
            allowTrackPreview={allowTrackPreview}
            project={project}
            isReference={isReference}
            isLatestMain={isLatestMain}
          />
        ) : (
          <StyledPlayBackRowNoFileContainer>
            {isReference
              ? `Reference not available`
              : `Waiting for ${emptyPlaybackRowText}`}
          </StyledPlayBackRowNoFileContainer>
        )}
      </StyledPlayBackRowColumn>
    </StyledPlayBackRowContainer>
  );
};
