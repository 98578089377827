import { useState } from "react";
import { useHistory } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { addMusoAssociation } from "../../../store/actions/musoProfile";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  MusoProfileEntity,
  MusoSelectionTab,
} from "../../../store/models/muso";
import {
  getMusoSelectionScreenRoute,
  getProfileScreenRoute,
} from "../../../store/utils/routeGetters";
import defaultUser from "../../assets/defaultuser.jpg";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { TEXT_WEIGHT, Text } from "../../core-ui/components/Text/Text";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import {
  MusoRosterContainer,
  MusoRosterCreditCount,
  MusoRosterDataContainer,
  MusoRosterInfoContainer,
  MusoRosterProfileData,
  MusoRosterProfileImage,
  MusoRosterCheck,
  MusoRosterMobileButtonGroup,
} from "./MusoRosterScreen.styles";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { useGetMusoRoster } from "../../../hooks/musoHooks/getMusoRoster";
import { Box, Grid, Theme, useMediaQuery } from "@mui/material";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  BOTTOM_TAB_BAR_OVERLAY_ID,
  DEFAULT_TAB_OVERLAY_CLASS,
  useBottomTabBarOverlayView,
} from "../../components/Navigation/BottomNav/useBottomTabBarOverlayView";

interface MusoRosterProfileProps {
  activeEntity: MusoProfileEntity | null;
  entity: MusoProfileEntity;
  onProfileClick?: (_: MusoProfileEntity) => void;
}

const MusoRosterProfile = ({
  activeEntity,
  entity,
  onProfileClick,
}: MusoRosterProfileProps) => {
  return (
    <MusoRosterProfileData
      $activeEntity={activeEntity?.id === entity.id}
      onClick={onProfileClick ? () => onProfileClick(entity) : undefined}
    >
      {activeEntity?.id === entity.id && <MusoRosterCheck icon={faCheck} />}
      <MusoRosterProfileImage src={entity.avatar_url ?? defaultUser} />
      <MusoRosterInfoContainer>
        <Text variant={TextStyleVariant.P4}>{entity.name}</Text>
        <MusoRosterCreditCount>
          {" "}
          <Text
            bold
            variant={TextStyleVariant.P4}
          >{`${entity.credit_count} credits`}</Text>
        </MusoRosterCreditCount>
      </MusoRosterInfoContainer>
    </MusoRosterProfileData>
  );
};

const MusoRosterScreen = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const history = useHistory();

  const loggedInUser = useAppSelector((state) => state.accountInfo.user);

  const [activeEntity, setActiveEntity] = useState<MusoProfileEntity | null>(
    null,
  );
  const dispatch = useAppDispatch();
  const { openModal, isOpen, closeModal } = useModal();
  const [isSettingMusoProfile, setIsSettingMusoProfile] = useState(false);
  useSetPageTitle("Select Muso Profile");

  const { data: profileData, isLoading: isLoadingProfileData } =
    useGetMusoRoster();
  const onProfileClick = (entity: MusoProfileEntity) => {
    setActiveEntity(entity);
  };

  const onProfileConfirm = () => {
    if (!activeEntity) {
      return;
    }
    emitAnalyticsTrackingEvent("click_confirm_add_muso_association", {
      musoProfileId: activeEntity.id,
    });
    setIsSettingMusoProfile(true);
    void dispatch(
      addMusoAssociation({
        muso_profile_id: activeEntity.id,
      }),
    )
      .unwrap()
      .then(() => {
        history.push(getMusoSelectionScreenRoute(MusoSelectionTab.CREDITS));
      })
      .finally(() => {
        setIsSettingMusoProfile(false);
      });
  };
  const UserProfiles = (
    <>
      {profileData?.map((entity) => {
        return (
          <Grid
            md={3}
            sx={{ justifyContent: "center", display: "flex" }}
            key={entity.id}
          >
            <MusoRosterProfile
              activeEntity={activeEntity}
              entity={entity}
              onProfileClick={onProfileClick}
              key={entity.id}
            />
          </Grid>
        );
      })}
    </>
  );

  const onCancel = () => {
    closeModal();
    if (!loggedInUser?.username) {
      return;
    }
    history.push(getProfileScreenRoute(loggedInUser.username));
  };

  const ButtonGroup = (
    <>
      <Button
        fullWidth={isMobile}
        variant={ButtonVariant.OUTLINED}
        disabled={isSettingMusoProfile}
        onClick={onCancel}
        style={{ minWidth: "140px" }}
      >
        Cancel
      </Button>
      <Button
        fullWidth={isMobile}
        variant={ButtonVariant.PRIMARY}
        disabled={isSettingMusoProfile || !activeEntity}
        onClick={onProfileConfirm}
        style={{ minWidth: "140px" }}
      >
        Confirm
      </Button>
    </>
  );

  const mobileActionButtons = useBottomTabBarOverlayView(
    isMobile,
    <MusoRosterMobileButtonGroup>{ButtonGroup}</MusoRosterMobileButtonGroup>,
    DEFAULT_TAB_OVERLAY_CLASS,
    BOTTOM_TAB_BAR_OVERLAY_ID,
  );

  if (isMobile) {
    return (
      <MusoRosterContainer>
        <Box
          sx={{
            textAlign: "left",
            width: "100%",
            padding: "24px",
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          <Text variant={TextStyleVariant.H4} weight={TEXT_WEIGHT.SEMI_BOLD}>
            Select a Muso Profile
          </Text>
        </Box>
        {isLoadingProfileData && <SoundWaveLoader width={100} height={100} />}
        <MusoRosterDataContainer>{UserProfiles}</MusoRosterDataContainer>
        {mobileActionButtons}
      </MusoRosterContainer>
    );
  }

  return (
    <BaseModal
      open={isOpen || !isLoadingProfileData}
      setOpen={openModal}
      onConfirm={onProfileConfirm}
      showCloseButton={false}
      header="Select a Muso profile"
    >
      <Grid
        sx={{
          justifyContent: "center",
          rowGap: "24px",
          overflow: "auto",
          maxHeight: "400px",
        }}
        container
      >
        {UserProfiles}
      </Grid>
      <Box
        sx={{
          display: "flex",
          marginLeft: "auto",
          marginRight: "auto",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          marginTop: "26px",
        }}
      >
        {ButtonGroup}
      </Box>
    </BaseModal>
  );
};

export default MusoRosterScreen;
