import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";

const selectBookingData = (state: RootState) => state.bookingStore;

export const getApplicableDiscountBookingLinkData = (
  serviceId: number | undefined,
) =>
  createSelector(selectBookingData, (bookingData) => {
    if (!bookingData.engUniqueBookingLink || !serviceId) {
      return;
    }

    const bookingLinkData = bookingData.engUniqueBookingLink;
    if (bookingLinkData.used || bookingLinkData.deleted) {
      return;
    }

    if (bookingLinkData.engineering_service.id !== serviceId) {
      return;
    }

    return bookingLinkData;
  });
