import { faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  checklistPhaseTitleAtom,
  completedChecklistStepsAtom,
  totalChecklistStepsAtom,
} from "./atoms";
import ChecklistContent from "./ChecklistContent";
import ProgressBar from "./ProgressBar";
import { atomWithStorage } from "jotai/utils";

export const showChecklistAtom = atomWithStorage("showChecklist", true);

const StickyOnboardingChecklist = () => {
  const [showChecklist, setShowChecklist] = useAtom(showChecklistAtom);
  const theme = useTheme();
  const completedChecklistSteps = useAtomValue(completedChecklistStepsAtom);
  const totalChecklistSteps = useAtomValue(totalChecklistStepsAtom);
  const checklistPhaseTitle = useAtomValue(checklistPhaseTitleAtom);
  const isTabletOrBelow = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Accordion
      sx={(theme) => ({
        marginLeft: "80px !important",
        width: "284px",
        boxShadow: theme.palette.customColor.checklistBoxShadow,
        [theme.breakpoints.down("md")]: {
          marginLeft: "unset !important",
          width: "100%",
        },
      })}
      expanded={showChecklist}
      onChange={(_, expanded) => {
        setShowChecklist(expanded);
      }}
    >
      <AccordionSummary
        sx={(theme) => ({
          "& .MuiAccordionSummary-content": {
            gap: "16px",
            flexDirection: "column",
            height: "calc(100% - 12px)",
            padding: "12px 4px 0 0",
            margin: "unset",
            "&.Mui-expanded": {
              margin: "unset",
            },
          },
          borderRadius: "8px 8px 0 0",
          backgroundColor: theme.palette.standardColor.BoomyOrange[50],
          height: "77px",
          [theme.breakpoints.down("md")]: {
            borderRadius: "unset",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            alignItems: "start",
            height: "calc(100% - 14px)",
            paddingTop: "14px",
            "&.Mui-expanded": {
              alignItems: "end",
              paddingBottom: "14px",
              paddingTop: "unset",
            },
          },
        })}
        expandIcon={<FontAwesomeIcon icon={faChevronUp} />}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            variant={TextStyleVariant.P1}
            weight={TEXT_WEIGHT.BOLD}
            style={{ color: theme.palette.standardColor.Gray[950] }}
          >
            {checklistPhaseTitle}
          </Text>
          {isTabletOrBelow && (
            <Text
              variant={TextStyleVariant.P4}
              style={{ color: theme.palette.standardColor.BoomyOrange[500] }}
            >
              Step {completedChecklistSteps + 1} of {totalChecklistSteps}
            </Text>
          )}
        </Box>
        <ProgressBar
          totalSteps={totalChecklistSteps}
          completedSteps={completedChecklistSteps}
        />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
          backgroundColor: theme.palette.standardColor.BoomyOrange[50],
        }}
      >
        <ChecklistContent />
      </AccordionDetails>
    </Accordion>
  );
};

export default StickyOnboardingChecklist;
