import { atom } from "jotai";

export enum DisplayOptions {
  Action_Items,
  Activity,
  Team,
}

// Holds the enum that controls the display of the admin panel
export const displayOptionAtom = atom(DisplayOptions.Action_Items);
displayOptionAtom.debugLabel = "Admin Dashboard Display Option";

// Opens or closes the admin panel. Used to close the panel when the page changes.
export const adminSidePanelAtom = atom(false);
adminSidePanelAtom.debugLabel = "Admin Side Panel Open";

// handles which content to display in the admin panel while toggling the admin panel open/closed
export const togglePanelAtom = atom(
  null,
  (_get, set, displayOption: DisplayOptions) => {
    set(displayOptionAtom, displayOption);
    set(adminSidePanelAtom, (show) => !show);
  },
);
