import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComputerSpeaker } from "@fortawesome/sharp-regular-svg-icons";
import {
  AddServiceCardAddButton,
  ServiceCardV2Container,
} from "../../../components/ServiceCard/ServiceCardV2/ServiceCardV2.styles";
import { ButtonVariant } from "../../../core-ui/components/Button/Button";

interface AddServiceCard {
  onAddService: () => void;
  aspectRatio?: string;
}

export const AddServiceCard = ({
  onAddService,
  aspectRatio,
}: AddServiceCard) => {
  return (
    <ServiceCardV2Container
      sx={(theme) => ({
        alignItems: "center",
        justifyContent: "center",
        rowGap: "24px",
        textAlign: "center",
        color: theme.palette.text.disabled,
        background: theme.palette.customColor.midgroundColor,
        aspectRatio: aspectRatio,
      })}
      $isEmpty
    >
      <FontAwesomeIcon icon={faComputerSpeaker} fontSize="40px" />

      <AddServiceCardAddButton
        variant={ButtonVariant.BLACK_OUTLINED_BUTTON_BLACK_HOVER}
        onClick={onAddService}
      >
        Add service
      </AddServiceCardAddButton>
    </ServiceCardV2Container>
  );
};
