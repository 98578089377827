import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { RefObject } from "react";

export type ShowState = boolean | undefined;

export const bottomNavHeightAtom = atom(0);
export const bottomNavContainerHeightAtom = atom(0);
export const showBottomNavAtom = atomWithReset(true as ShowState);
export const bottomNavSpacerHeightAtom = atom((get) => {
  const bottomContainerHeight = get(bottomNavContainerHeightAtom);
  const bottomHeight = get(bottomNavHeightAtom);
  const show = get(showBottomNavAtom);
  return show
    ? bottomContainerHeight
    : Math.abs(bottomContainerHeight - bottomHeight);
});

export const topNavHeightAtom = atom(0);
export const topNavContainerHeightAtom = atom(0);
export const showTopNavAtom = atomWithReset(true as ShowState);
export const topNavSpacerHeightAtom = atom((get) => {
  const topHeight = get(topNavHeightAtom);
  const show = get(showTopNavAtom);
  return show ? topHeight : 0;
});

export const showBottomAndTopNavAtom = atom(
  (get) => {
    return get(showTopNavAtom) && get(showBottomNavAtom);
  },
  (_get, set, show: ShowState | ((prev: ShowState) => ShowState)) => {
    set(showTopNavAtom, show);
    set(showBottomNavAtom, show);
  },
);

export const navElementRefAtom = atom(null as RefObject<HTMLDivElement> | null);

// Sometimes we don't want to scroll to the top on navigation
// This atom is used for such purpose!
export const preventScrollOnNavigationAtom = atom(false);
