import { useQuery } from "@tanstack/react-query";
import { displayEngineEarsError } from "../../api/helpers";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { ProjectType } from "../../store/models/project";
import { EngineerSchedulingSurvey } from "../../store/models/service";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { SCHEDULING_SURVEY } from "../../store/utils/routes";

interface useGetSchedulingSurveyAnswersProps {
  serviceType: ProjectType;
  userId: number;
  onSuccess: (workingHours: EngineerSchedulingSurvey) => void;
  staleTime?: number | undefined;
  enabled?: boolean;
}

export const useGetSchedulingSurveyAnswers = ({
  serviceType,
  userId,
  onSuccess,
  staleTime,
  enabled = true,
}: useGetSchedulingSurveyAnswersProps) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_SCHEDULING_SURVEY_ANSWERS, userId, serviceType],
    queryFn: async () => {
      const params = `?service_type=${serviceType.toString()}`;

      const response =
        await makeBackendGetCallWithJsonResponse<EngineerSchedulingSurvey>(
          SCHEDULING_SURVEY,
          params,
        );
      if (response.success) {
        onSuccess(response.resultJson);
        return response.resultJson;
      }
      displayEngineEarsError(response.resultJson);
      return Promise.reject(response.resultJson);
    },
    staleTime,
    enabled,
  });
};
