import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { loadUser, setUser } from "../../../../store/actions/accountInfo";
import useActiveUserQuery from "../../../../hooks/userHooks/useActiveUserQuery";
import { tokenAtom } from "../../../../atoms/auth/tokenAtom";
import { useAtomValue } from "jotai";

const useLoadUser = () => {
  const dispatch = useAppDispatch();
  const [initialAppLoad, setInitLoad] = useState(true);
  useActiveUserQuery();
  const token = useAtomValue(tokenAtom);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  // Load the logged-in user information on initial app load.
  const initialLoadUser = useCallback(() => {
    setInitLoad(true);
    dispatch(loadUser())
      .unwrap()
      .then(() => {
        setInitLoad(false);
      })
      .catch(() => {
        setInitLoad(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (!initialAppLoad) {
      return;
    }
    initialLoadUser();
  }, [initialAppLoad, initialLoadUser]);

  useEffect(() => {
    if (initialAppLoad) {
      return;
    }
    if (!token && loggedInUser) {
      dispatch(setUser(undefined));
    } else if (token && !loggedInUser) {
      dispatch(loadUser());
    }
  }, [loggedInUser, token, dispatch, initialAppLoad]);

  return initialAppLoad;
};

export default useLoadUser;
