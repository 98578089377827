import hdate from "human-date";
import { useState } from "react";
import { useMediaQueryBreakpoint } from "../../../../../hooks/useMediaQuery";
import { useAppSelector } from "../../../../../store/hooks";
import { RecordingService } from "../../../../../store/models/recording";
import { StudioRoom } from "../../../../../store/models/studio";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import { SessionDateModal } from "../../../SessionDateModal/SessionDateModal";
import {
  GenerateBookingDropdown,
  GenerateBookingDropdownNoBorder,
  GenerateBookingDropdownRoundBorder,
} from "../../GenerateBookingDropdown";

interface GenerateBookingSessionSelectDateProps {
  selectedDate: Date | undefined;
  selectedTime: Date | undefined;
  studioRoom: StudioRoom | undefined;
  engineerAvailabilityOnly: boolean;
  index: number | undefined;
  isSelected: boolean;
  onClick?: () => void;
  recordingService: RecordingService | null;
}

export const GenerateBookingSessionSelectDate = ({
  selectedDate,
  selectedTime,
  studioRoom,
  engineerAvailabilityOnly,
  index,
  isSelected,
  onClick,
  recordingService,
}: GenerateBookingSessionSelectDateProps) => {
  const { activeStudioId } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const [showAddSessionDateModal, setAddSessionDateModal] = useState(false);
  const { isDesktop } = useMediaQueryBreakpoint();
  const noBorderStyle = isDesktop
    ? [
        GenerateBookingDropdownNoBorder.BOTTOM,
        GenerateBookingDropdownNoBorder.RIGHT,
      ]
    : [GenerateBookingDropdownNoBorder.BOTTOM];

  const roundBorderStyle = isDesktop
    ? [GenerateBookingDropdownRoundBorder.TOP_LEFT]
    : [
        GenerateBookingDropdownRoundBorder.TOP_LEFT,
        GenerateBookingDropdownRoundBorder.TOP_RIGHT,
      ];
  return (
    <Button
      fullWidth
      onClick={() => {
        if (onClick) {
          onClick();
        }
        if (!showAddSessionDateModal) {
          setAddSessionDateModal(true);
        }
      }}
      variant={ButtonVariant.UNSTYLED}
    >
      <GenerateBookingDropdown
        isSelected={isSelected}
        noBorder={noBorderStyle}
        roundBorder={roundBorderStyle}
        placeholder="Select Date"
        value={
          selectedDate
            ? {
                label: hdate.prettyPrint(selectedDate),
                value: selectedDate.getTime(),
              }
            : null
        }
      />
      <SessionDateModal
        studioId={activeStudioId}
        studioRoom={studioRoom}
        sessionIndex={index}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        engineerAvailabilityOnly={engineerAvailabilityOnly}
        showAddSessionDateModal={showAddSessionDateModal}
        onClose={() => {
          setAddSessionDateModal(false);
        }}
        recordingService={recordingService}
      />
    </Button>
  );
};
