import Drawer from "react-bottom-drawer";
import { FC, ReactNode } from "react";
import "./RecordingDetailsMobileMenu.css";

interface RecordingDetailsMobileMenuProps {
  children?: ReactNode;
  isVisible: boolean;
  onClose: () => void;
}

export const RecordingDetailsMobileMenu: FC<
  RecordingDetailsMobileMenuProps
> = ({ children, isVisible, onClose }) => {
  return (
    <Drawer
      className={"recording-detail-mobile-menu"}
      isVisible={isVisible}
      onClose={onClose}
    >
      {children}
    </Drawer>
  );
};
