import { selectProfileSessions } from "../../store/selectors/recordingSessionSelector";
import { useAppSelector } from "../../store/hooks";
import { useMemo } from "react";
import { RecordingSession } from "../../store/models/recordingSession";
import { convertUTCDateToLocalDate } from "../../store/utils/dateTimeUtils";
import { isSameDay } from "date-fns";

interface args {
  startDate: Date;
  endDate: Date;
}

/**
 * The 'useSessionGuideData' hook generates a list of recording session lists where each embedded list contains
 * the sessions for a particular day in order.
 * It takes 'startDate' and 'endDate,' generates an array of dates ('headers'), and maps each date to
 *  recording sessions that occurred on that date ('data'). Both 'headers' and 'data' are memoized for performance.
 */
export const useSessionGuideData = ({ startDate, endDate }: args) => {
  const sessions = useAppSelector(selectProfileSessions);
  const headers = useMemo(() => {
    const startDateRef = new Date(startDate);
    const endDateRef = new Date(endDate);
    const headers: string[] = [];
    for (
      let currentDate = startDateRef;
      currentDate <= endDateRef;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      headers.push(currentDate.toISOString());
    }
    return headers;
  }, [startDate, endDate]);
  const data = useMemo(() => {
    return headers.reduce((acc: RecordingSession[][], currentDate: string) => {
      const recordingSessionsForDate = sessions.filter((recordingSession) => {
        return isSameDay(
          convertUTCDateToLocalDate(
            new Date(recordingSession.first_choice_datetime),
          ),
          new Date(currentDate),
        );
      });
      return [...acc, recordingSessionsForDate];
    }, [] as RecordingSession[][]);
  }, [sessions, headers]);

  return { headers, data };
};
/**
 * The 'useSessionsDateMap' hook generates a map of recording session lists. Each key in the map is a date and
 * the value is a list of recording sessions that occurred on that date.
 */
export const useSessionsDateMap = ({ startDate, endDate }: args) => {
  const sessions = useAppSelector(selectProfileSessions);

  return useMemo(() => {
    const startDateRef = new Date(startDate);
    startDateRef.setHours(0, 0, 0, 0);
    const endDateRef = new Date(endDate);
    const map: Map<string, RecordingSession[]> = new Map<
      string,
      RecordingSession[]
    >();

    for (
      let currentDate = startDateRef;
      currentDate <= endDateRef;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const sessionsForDate = sessions.filter((recordingSession) => {
        return isSameDay(
          convertUTCDateToLocalDate(
            new Date(recordingSession.first_choice_datetime),
          ),
          currentDate,
        );
      });

      map.set(currentDate.toISOString(), sessionsForDate);
    }

    return map;
  }, [sessions]);
};
