import classNames from "classnames";
import { CSSProperties, InputHTMLAttributes, useEffect } from "react";
import "./PercentageTextfield.css";

export interface PercentageTextfieldProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "value" | "min" | "max" | "step"
  > {
  label?: string;
  value?: number;
  min?: number;
  max?: number;
  onChangePercentage: (value: number) => void;
  step?: number;
  containerStyle?: CSSProperties;
  offsetForPercentSpace?: boolean;
}

export const PercentageTextfield = ({
  label = "",
  className,
  value,
  min = 0,
  max = 100,
  onChangePercentage,
  step = 1,
  containerStyle = {},
  offsetForPercentSpace = false,
  ...props
}: PercentageTextfieldProps) => {
  // Whenever the value is changed to be > max, set as max
  useEffect(() => {
    if (value && value * 100 > max) {
      onChangePercentage(max / 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, max]);

  // On first mount, if the value is < min, we set it the min
  useEffect(() => {
    if (value != null && value * 100 < min) {
      onChangePercentage(min / 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        width: "100%",
        marginRight: offsetForPercentSpace ? "15px" : undefined,
        ...containerStyle,
      }}
    >
      <input
        className={classNames("textfield", className)}
        style={{
          width: 80,
        }}
        name="text"
        type="number"
        value={value ? Math.round(value * 100) : ""}
        {...props}
        placeholder={label}
        onChange={(e) => {
          const valueAsInt = parseInt(e.target.value || "0");
          onChangePercentage(valueAsInt / 100);
        }}
        onBlur={() => {
          if (!value || value < min / 100) {
            onChangePercentage(min / 100);
            return;
          }

          onChangePercentage((Math.round((value * 100) / step) * step) / 100);
        }}
      />
      <span className="b1" style={{ marginLeft: "-25px" }}>
        %
      </span>
    </div>
  );
};
