import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { updateProfile } from "../../store/actions/accountInfo";
import { useAppDispatch } from "../../store/hooks";
import User from "../../store/models/user";
import { callAllHandlersWithData } from "../../utils/utils";

export interface MutateProfileProps {
  username?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  display_name?: string;
  birth_date?: string;
  bio?: string;
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  utm_content?: string | null;
  utm_term?: string | null;
  timezone_shift_minutes?: number;
  timezone?: string;
  phone_number?: string;
  soundcloud_username?: string;
  instagram_username?: string;
  twitter_username?: string;
  facebook_username?: string;
  twitch_username?: string;
  tiktok_username?: string;
  youtube_username?: string;
  spotify_username?: string;
  apple_username?: string;
  amazon_username?: string;
  tidal_username?: string;
  long_bio?: string;
  country?: string;
  city?: string;
  region?: string;
  banner_color?: number;
  disable_sms_notifications?: boolean;
  tab_order_preferences?: string;
}

export type MutateSocialMediaProps = Pick<
  MutateProfileProps,
  | "soundcloud_username"
  | "instagram_username"
  | "twitter_username"
  | "facebook_username"
  | "twitch_username"
  | "tiktok_username"
  | "youtube_username"
  | "spotify_username"
  | "apple_username"
  | "amazon_username"
  | "tidal_username"
>;

export type MutateLocationProps = Pick<
  MutateProfileProps,
  "country" | "region" | "city"
>;

// When updating the username, we need to update the urlParam username to prevent useGetUserProfile from erroring
// We also need to update the queryKey to the new username
const useUpdateProfile = (additionalOnSuccess?: (data: User) => void) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const defaultOnSuccess = (data: User) => {
    void queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_USER_PROFILE, data.username],
    });
  };

  return useMutation({
    mutationFn: async ({ ...data }: MutateProfileProps) => {
      return dispatch(updateProfile(data)).unwrap();
    },
    retry: 0,
    onSuccess: callAllHandlersWithData(defaultOnSuccess, additionalOnSuccess),
  });
};

export default useUpdateProfile;
