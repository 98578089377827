import React, { useEffect } from "react";
import { PlatformActivity } from "../../../store/models/platformActivity";
import { useUserProfileImageURL } from "../../../hooks/useUserProfileImage";
import "./PlatformActivityTable.css";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import humanizeDuration from "humanize-duration";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { convertUTCDateToLocalDate } from "../../../store/utils/dateTimeUtils";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

export interface PlatformActivityNotificationRowProps {
  platformActivityNotification: PlatformActivity;
}

export const PlatformActivityNotificationRow = ({
  platformActivityNotification,
}: PlatformActivityNotificationRowProps) => {
  const darkMode = useAtomValue(darkModeAtom);
  const user = useAppSelector((state) => state.accountInfo.user);
  const { created, generating_user, text } = platformActivityNotification;
  const userIsMe = user?.id === generating_user.id;
  const username = generating_user.username;
  const userDisplayName = getDisplayableNameForUser(generating_user);
  const imagePath = useUserProfileImageURL(generating_user);
  const [timeAgo, setTimeAgo] = React.useState<string>("");
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(
        `${humanizeDuration(
          new Date().getTime() -
            convertUTCDateToLocalDate(new Date(created)).getTime(),
          {
            largest: 1,
          },
        )} ago`,
      );
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [created]);

  const handleClick = () => {
    if (!platformActivityNotification.link) {
      return;
    }
    history.push(platformActivityNotification.link);
  };

  return (
    <div
      className={"platform-activity-notification-row"}
      data-theme={darkMode ? "dark" : ""}
      onClick={handleClick}
    >
      <img
        className={"platform-activity-notification-row__user-image"}
        width={24}
        height={24}
        src={imagePath}
        alt={"User generating a platform notification"}
      />
      {userIsMe ? (
        <p className={"platform-activity-notification-row-bolded-text"}>You</p>
      ) : (
        <>
          <p className={"platform-activity-notification-row-bolded-text"}>
            {userDisplayName}
          </p>
          {`@${username}` !== userDisplayName && (
            <p className={"platform-activity-notification-row__user-username"}>
              @{username}
            </p>
          )}
        </>
      )}
      <p
        style={{
          marginRight: 0,
        }}
        className={"platform-activity-notification-row-bolded-text"}
      >
        {text}
      </p>
      <div className={"platform-activity-notification-row__text-container"}>
        <div className={"left-gradient-edge".concat(darkMode ? " dark" : "")} />
        <p
          className={
            "platform-activity-notification-row__text-container__created"
          }
        >
          {timeAgo}
        </p>
      </div>
    </div>
  );
};
