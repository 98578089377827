import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  selectEmailVerified,
  selectMissingAccountInfo,
  selectMissingPhoneNumberSelector,
  selectPhoneVerified,
} from "../store/selectors/userInfoSelectors";
import { useEffect } from "react";
import { fetchProfile } from "../store/actions/users";

export const useAccountStatus = (pollStatus = false) => {
  const username = useAppSelector((state) => state.accountInfo.user?.username);
  const emailVerified = useAppSelector(selectEmailVerified);
  const missingPhoneNumber = useAppSelector(selectMissingPhoneNumberSelector);
  const phoneVerified = useAppSelector(selectPhoneVerified);
  const missingAccountInfo = useAppSelector(selectMissingAccountInfo);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!pollStatus) {
      return;
    }
    const intervalId = setInterval(() => {
      // If both are verified, clear the interval
      if (emailVerified && phoneVerified) {
        clearInterval(intervalId);
        return;
      }

      if (!username) {
        return;
      }

      void dispatch(
        fetchProfile({
          username,
        }),
      );
    }, 3000); // Every 3 seconds

    // Cleanup on component unmount or dependencies change
    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, emailVerified, phoneVerified, username, pollStatus]);

  return {
    emailVerified,
    phoneVerified,
    missingAccountInfo,
    missingPhoneNumber,
  };
};
