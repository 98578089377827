import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { Select } from "../../../core-ui/components/Select/Select";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";
import { DropdownMenuItem } from "../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";

interface AdminModalDropdownProps {
  dropdownWidth?: string;
  options: OptionType[];
  placeholder?: string;
  selectedOption: OptionType | null;
  setSelectedOption: Dispatch<SetStateAction<OptionType | null>>;
}

export const AdminModalDropdown = ({
  dropdownWidth,
  options,
  placeholder,
  selectedOption,
  setSelectedOption,
}: AdminModalDropdownProps) => {
  const theme = useTheme();
  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Select
        dropdownZIndex={theme.zIndex.customZIndex.modalIndex}
        overrideDropdownMenuStyle={[{ minWidth: dropdownWidth }]}
        triggerButton={
          <Button
            labelIcon={
              <FontAwesomeIcon
                color={theme.palette.text.secondary}
                icon={faAngleDown}
              />
            }
            variant={ButtonVariant.OUTLINED}
            style={{
              borderColor: theme.palette.divider,
              color: theme.palette.text.secondary,
            }}
          >
            {selectedOption?.label ?? placeholder}
          </Button>
        }
      >
        {options.map((option) => (
          <DropdownMenuItem
            key={option.value}
            onClick={() => setSelectedOption(option)}
          >
            {option.label}
          </DropdownMenuItem>
        ))}
      </Select>
      <Button
        onClick={() => setSelectedOption(null)}
        variant={ButtonVariant.GHOST}
      >
        <FontAwesomeIcon icon={faXmark} />
      </Button>
    </Box>
  );
};
