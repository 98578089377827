import { useMemo } from "react";
import { OptionType } from "../../stories/elements/DropDownSelector/DropdownSelector";
import EntityPhoto from "./entityPhoto";
import { ScheduledProject } from "./scheduledproject";
import { EP_SONG_COUNT } from "./service";
import { UserLite } from "./user";

export enum ReleaseType {
  NO_RELEASE_TYPE,
  SINGLE,
  EP,
  ALBUM,
}

export const ReleaseTypeOptions: OptionType[] = [
  {
    value: ReleaseType.NO_RELEASE_TYPE,
    label: "All",
  },
  {
    value: ReleaseType.SINGLE,
    label: "Single",
  },
  {
    value: ReleaseType.EP,
    label: "EP",
  },
  {
    value: ReleaseType.ALBUM,
    label: "Album",
  },
];

export interface Release {
  id: number;
  title: string;
  artist_user_id: number;
  artist_user: null | UserLite;
  artist_display_name: string | null;
  description: null | string;
  featured: null | string;
  release_type: ReleaseType;
  release_cover: null | EntityPhoto;
  created_at: string;
  published_at: string | null;
}

export const getReleaseType = (numOfSongs: number) => {
  if (numOfSongs === 0) {
    return ReleaseType.NO_RELEASE_TYPE;
  }
  if (numOfSongs === 1) {
    return ReleaseType.SINGLE;
  }
  if (numOfSongs <= EP_SONG_COUNT) {
    return ReleaseType.EP;
  }
  return ReleaseType.ALBUM;
};

export const useTotalNumberOfSongsToDetermineReleaseTypeNotCountingPrerequisiteProjects =
  (scheduledProject: ScheduledProject) => {
    return useMemo(() => {
      const projects = scheduledProject.projects;
      const prereqProjectIds = projects.reduce((acc, project) => {
        if (project.prereq_project_id) {
          return [...acc, project.prereq_project_id];
        }
        return acc;
      }, [] as number[]);
      return projects.reduce((acc, project) => {
        if (prereqProjectIds.includes(project.id)) {
          return acc;
        }
        return ++acc;
      }, 0);
    }, [scheduledProject]);
  };

export interface ReleaseTrack {
  id: number;
  title: string;
  file_version_id: number | null;
  track_number: number;
  // Temporarily hide play count
  // play_count: number;
  release: Release;
}
