import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { updateStudioRoom } from "../../../store/actions/studio";
import { useAppDispatch } from "../../../store/hooks";
import {
  MINIMUM_NUMBER_OF_GUESTS_FOR_STUDIO_ROOM,
  Studio,
  StudioRoom,
} from "../../../store/models/studio";

import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";
import { EditableTextArea } from "../../elements/EditableTextArea/EditableTextArea";
import { EditableTextField } from "../../elements/EditableTextField/EditableTextField";
import { FixedRangeNumberField } from "../../elements/FixedRangeNumberField/FixedRangeNumberField";

import { useHistory } from "react-router-dom";
import { getStudioScreenRoute } from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Text } from "../../core-ui/components/Text/Text";
import { AmenityList } from "../AmenityList/AmenityList";
import { BaseModal } from "../BaseModal/BaseModal";
import { PrimaryEntityImage } from "../PrimaryEntityImage/PrimaryEntityImage";
import "./StudioModals.css";

export interface EditStudioRoomModalProps {
  onHide: () => void;
  show: boolean;
  showXButton: boolean;
  studioRoom: StudioRoom;
  username: string; // studio username
  studio: Studio;
}

export const EditStudioRoomModal = ({
  onHide,
  show,
  showXButton,
  studioRoom,
  username,
  studio,
}: EditStudioRoomModalProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [roomName, setRoomName] = useState<string>(studioRoom.room_name ?? "");
  const [aboutThisRoom, setAboutThisRoom] = useState<string>(
    studioRoom.about_this_room ?? "",
  );
  const [maxNumberOfGuests, setMaxNumberOfGuests] = useState<number>(
    studioRoom.max_number_of_guests ?? MINIMUM_NUMBER_OF_GUESTS_FOR_STUDIO_ROOM,
  );
  const [equipmentHighlights, setEquipmentHighlights] = useState<string>(
    studioRoom.equipment_highlights ?? "",
  );
  const [updatingData, setUpdatingData] = useState(false);
  const [editMode, setEditMode] = useState(true);

  const handleUpdateStudioRoom = useCallback(
    async (shouldDelete = false, callback = () => {}) => {
      if (!studioRoom) {
        return;
      }
      if (maxNumberOfGuests < MINIMUM_NUMBER_OF_GUESTS_FOR_STUDIO_ROOM) {
        toast.error(
          `Maximum number of guests must be at least ${MINIMUM_NUMBER_OF_GUESTS_FOR_STUDIO_ROOM}`,
        );
        return;
      }
      setUpdatingData(true);
      setEditMode(false);
      dispatch(
        updateStudioRoom({
          studio_room_id: studioRoom.id,
          room_name: roomName,
          about_this_room: aboutThisRoom,
          equipment_highlights: equipmentHighlights,
          username: username,
          max_number_of_guests: maxNumberOfGuests,
          ...(shouldDelete
            ? {
                delete: true,
              }
            : {}),
        }),
      )
        .unwrap()
        .then(() => {
          setEditMode(true);
          setUpdatingData(false);
          callback?.();
        })
        .catch(() => {
          setEditMode(true);
          setUpdatingData(false);
        });
    },
    [
      dispatch,
      equipmentHighlights,
      roomName,
      aboutThisRoom,
      studioRoom,
      username,
      maxNumberOfGuests,
    ],
  );

  const onClickDelete = async () => {
    await handleUpdateStudioRoom(true, () => {
      emitAnalyticsTrackingEvent("studio_room_deleted", {
        studioId: studio.id,
        studioRoomId: studioRoom.id,
      });
      toast.success("Successfully deleted studio room.");
      history.replace(getStudioScreenRoute(username));
    });
  };

  return (
    <BaseModal
      hideCloseButton={true}
      modalIsOpen={show}
      label={"edit studio room modal"}
    >
      <div className={"studio-modal"}>
        <div className={"modal-header"}>
          <h2 className={"h3 mx-4"}>Edit Studio Room</h2>
          {showXButton && (
            <FontAwesomeIcon
              width={30}
              height={30}
              color={"var(--black)"}
              icon={faTimes}
              size={"2xl"}
              className={"close-modal-button mx-2"}
              onClick={onHide}
            />
          )}
        </div>
        <div className={"modal-body"}>
          <p className={"b1-semi-bold"}>Room Name</p>
          <EditableTextField
            initialValue={studioRoom.room_name}
            label={"room name (ex. Studio A)"}
            onChange={(val) => {
              setRoomName(val);
            }}
            editMode={true}
          />
          <p className={"b1-semi-bold"}>About this room</p>
          <EditableTextArea
            initialValue={studioRoom.about_this_room ?? ""}
            editMode={true}
            placeholder={"Add more info about this room"}
            onChange={(val) => {
              setAboutThisRoom(val);
            }}
            width={"100%"}
            characterCount={2000}
            numberOfLines={3}
          />

          <p className={"b1-semi-bold"}>Maximum Number of Session Attendees</p>
          <FixedRangeNumberField
            min={MINIMUM_NUMBER_OF_GUESTS_FOR_STUDIO_ROOM}
            max={50}
            initialValue={studioRoom.max_number_of_guests}
            placeholder="Service Rate"
            onChange={(val) => {
              setMaxNumberOfGuests(val);
            }}
          />
          <p className={"b1-semi-bold"}>Equipment Highlights</p>
          <EditableTextArea
            initialValue={studioRoom.equipment_highlights ?? ""}
            editMode={true}
            placeholder={"Add equipment highlights"}
            onChange={(val) => {
              setEquipmentHighlights(val);
            }}
            width={"100%"}
            characterCount={2000}
            numberOfLines={3}
          />
          <p className={"b1-semi-bold"}>Primary Studio Room Photo</p>
          <div className="edit-modal-primary-image">
            <PrimaryEntityImage
              studioRoom={studioRoom}
              primaryPhoto={studioRoom.photo}
              editMode={true}
              canManageStudio={true}
            />
          </div>
          <p className={"b1-semi-bold"}>Studio Room Amenities</p>
          <AmenityList
            username={username}
            studioRoomId={studioRoom.id}
            amenities={studioRoom.amenities ?? []}
            editMode={editMode}
          />
        </div>
        <div className={"modal-header modal-footer"}>
          <PopConfirm
            side="top"
            title="Confirm Studio Room Deletion"
            description={
              <Text>Are you sure you want to delete this studio room?</Text>
            }
            onConfirm={onClickDelete}
            okButtonProps={{
              loading: updatingData,
              disabled: updatingData,
            }}
          >
            <Button loading={updatingData} variant={ButtonVariant.OUTLINED}>
              Delete Room
            </Button>
          </PopConfirm>
          <Button
            loading={updatingData}
            onClick={() => handleUpdateStudioRoom(false, onHide)}
            variant={ButtonVariant.PRIMARY}
          >
            Update Room
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
