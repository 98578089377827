import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor } from "@radix-ui/react-popover";
import { format } from "date-fns";
import { useMemo, useRef, useState } from "react";
import { Calendar, CalendarTileProperties } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import useModal from "../../../hooks/useModal";
import {
  ProjectManagementActions,
  manageScheduledProject,
} from "../../../store/actions/booking";
import { useAppDispatch } from "../../../store/hooks";
import {
  convertLocalDateToUTCDate,
  getFormattedDateString,
} from "../../../store/utils/dateTimeUtils";
import "../../components/GenerateBooking/EstimatedDeliveryDatePicker.css";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";

interface RescheduleEstimatedDeliveryDatePickerProps {
  estimatedDeliveryDate: string | null;
  onSuccess?: () => void;
  scheduleProjectId: number;
}

export const RescheduleEstimatedDeliveryDatePicker = ({
  estimatedDeliveryDate,
  onSuccess,
  scheduleProjectId,
}: RescheduleEstimatedDeliveryDatePickerProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { setIsOpen, isOpen, closeModal } = useModal();
  const [updating, setUpdating] = useState(false);
  const [controlledEstimatedDeliveryDate, setControlledEstimatedDeliveryDate] =
    useState<string | null>(
      estimatedDeliveryDate ? estimatedDeliveryDate : null,
    );
  // This prevents the popover from spilling over the SidePanel container
  // A different workaround may be needed if the SidePanel is not the container
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );
  const utcEstimatedDeliveryDate = useMemo(() => {
    if (!controlledEstimatedDeliveryDate) {
      return null;
    }
    return convertLocalDateToUTCDate(new Date(controlledEstimatedDeliveryDate));
  }, [controlledEstimatedDeliveryDate]);

  const estimatedDeliveryDateString = useMemo(() => {
    if (!utcEstimatedDeliveryDate) {
      return "N/A";
    }
    return format(utcEstimatedDeliveryDate, "MMM dd, yyyy");
  }, [utcEstimatedDeliveryDate]);

  const onClickDate = (date: Date) => {
    const formattedDate = getFormattedDateString(date);
    ref.current?.click();
    setControlledEstimatedDeliveryDate(formattedDate);
  };

  const getTileClass = ({ date }: CalendarTileProperties) => {
    if (!utcEstimatedDeliveryDate) {
      return "";
    }
    if (
      date.getFullYear() === utcEstimatedDeliveryDate.getFullYear() &&
      date.getMonth() === utcEstimatedDeliveryDate.getMonth() &&
      date.getDate() === utcEstimatedDeliveryDate.getDate()
    ) {
      if (ref.current) {
        // The dom updates with the correct styling for some reason the selected date is not reflected on the calendar
        // until after the focus is outside of the calendar component itself
        // to have the update style reflected we focus on the popover content
        ref.current.focus();
      }
      return "estimated-delivery-date-picker-selected";
    }
    return "";
  };

  return (
    <BasePopover
      ref={ref}
      isOpen={isOpen}
      setIsPopoverOpen={setIsOpen}
      closePopover={closeModal}
      cancelButtonProps={{
        disabled: updating,
      }}
      additionalContent={
        <Calendar
          prevLabel={
            <FontAwesomeIcon
              icon={faChevronLeft}
              size="1x"
              color={theme.textPrimaryColor}
            />
          }
          nextLabel={
            <FontAwesomeIcon
              icon={faChevronRight}
              size="1x"
              color={theme.textPrimaryColor}
            />
          }
          tileClassName={getTileClass}
          onClickDay={onClickDate}
          view="month"
          value={utcEstimatedDeliveryDate}
        />
      }
      onPointerDownOutside={() => {
        setControlledEstimatedDeliveryDate(estimatedDeliveryDate);
      }}
      onCancel={() => {
        setControlledEstimatedDeliveryDate(estimatedDeliveryDate);
      }}
      onConfirm={() => {
        if (!controlledEstimatedDeliveryDate) {
          setIsOpen(false);
          return;
        }
        setUpdating(true);
        void dispatch(
          manageScheduledProject({
            action: ProjectManagementActions.RescheduleProject,
            scheduled_project_id: scheduleProjectId,
            requested_date: controlledEstimatedDeliveryDate,
          }),
        )
          .unwrap()
          .then(() => {
            toast.success("The reschedule request was sent for approval.");
            onSuccess?.();
          })
          .catch(() => toast.error("Something went wrong with the reschedule."))
          .finally(() => {
            setIsOpen(false);
            setUpdating(false);
          });
      }}
      side={"top"}
      title={"Are you sure you want to reschedule?"}
      disableAutoClose={true}
      okButtonProps={{
        loading: updating,
      }}
      wrapperElement={containerElement}
    >
      <Anchor>
        <Button
          labelIcon={<FontAwesomeIcon icon={faCalendar} />}
          fullWidth
          onClick={() => {
            setIsOpen(true);
          }}
          variant={ButtonVariant.UNSTYLED}
        >
          {estimatedDeliveryDateString}
        </Button>
      </Anchor>
    </BasePopover>
  );
};
