import { useState } from "react";
import {
  useBillingInfoOrPurchaseOrderSubmitted,
  useIsBudgetApproved,
  useIsPurchaseOrderAandR,
  useShowBillingInfoCTA,
  useShowBudgetApprovalCTA,
} from "../../../hooks/purchaseOrderWithTransactionHooks";
import useModal from "../../../hooks/useModal";
import {
  useBudgetManagerPurchaseOrderSubmittedSimpleTableRowData,
  useBudgetManagersApprovalSimpleTableRowData,
  useEmailListSimpleTableRowData,
} from "../../../hooks/useTableHooks";
import {
  BudgetManager,
  DetailedPurchaseOrder,
} from "../../../store/models/project";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { SimpleTable } from "../../core-ui/components/SimpleTable/SimpleTable";
import { BudgetManagerCTARow } from "../../screens/TransactionOverviewScreen/TransactionOverviewScreen.styles";
import { SubmitBillingInfoModal } from "../BillingInfoModals/BillingInfoModals";

import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { ItemizedPurchaseOrder } from "../../../store/actions/transactions";
import {
  ApproveBudgeModal,
  RejectBudgetModal,
} from "../BudgetManagerModals/BudgetManagerModal";
import {
  BOTTOM_TAB_BAR_OVERLAY_ID,
  DEFAULT_TAB_OVERLAY_CLASS,
  useBottomTabBarOverlayView,
} from "../Navigation/BottomNav/useBottomTabBarOverlayView";
import {
  LabelSummaryAssociatedUsersOverviewBodyContainer,
  LabelSummaryAssociatedUsersOverviewContainer,
  LabelSummaryAssociatedUsersTitle,
} from "./LabelSummaryAssociatedUsersOverview.styles";
import { Transaction } from "../../../store/models/transaction";

interface LabelSummaryAssociatedUsersOverviewProps {
  budgetManagers: BudgetManager[];
  code: string | null;
  setPurchaseOrder: (purchaseOrder: DetailedPurchaseOrder) => void;
  purchaseOrder?: DetailedPurchaseOrder; // legacy purchase order
  purchaseOrders: ItemizedPurchaseOrder[]; // purchase orders fetched from getItemizedTransaction
  transaction?: Transaction;
}

const APPROVE_HEADERS = ["Name", "Email", "Budget approved"];
const APPROVE_TITLE = "Budget Approvers";
const COLLABORATORS_HEADERS = ["Name", "Email", "Invite sent"];
const COLLABORATORS_TITLE = "Collaborators";
const BUDGET_HEADERS = ["Name", "Email", "Billing info submitted"];
const BUDGET_TITLE = "Billing Information";

export const LabelSummaryAssociatedUsersOverview = ({
  budgetManagers,
  code,
  setPurchaseOrder,
  purchaseOrder,
  purchaseOrders,
  transaction,
}: LabelSummaryAssociatedUsersOverviewProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();
  const approversRowData =
    useBudgetManagersApprovalSimpleTableRowData(budgetManagers);
  const showBillingInfoSubmissionCTA = useShowBillingInfoCTA(purchaseOrder);
  const collaboratorsRowData = useEmailListSimpleTableRowData(purchaseOrder);
  const billingInfoRowData =
    useBudgetManagerPurchaseOrderSubmittedSimpleTableRowData(
      budgetManagers,
      purchaseOrder,
    );
  const showBudgetApprovalCTA = useShowBudgetApprovalCTA(purchaseOrder);
  const showPurchaseOrderBillingOption = useIsPurchaseOrderAandR(transaction);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const {
    isOpen: budgetApprovalModalOpen,
    closeModal: closeBudgetApproveModal,
    openModal: openBudgetApproveModal,
  } = useModal();
  const {
    isOpen: submitBillingInfoModalOpen,
    closeModal: closeSubmitBillingInfoModal,
    openModal: openSubmitBillingInfoModal,
  } = useModal();
  const { billingInfoSubmitted, purchaseOrderSubmitted } =
    useBillingInfoOrPurchaseOrderSubmitted(purchaseOrder);
  const isBudgetApproved = useIsBudgetApproved(purchaseOrder);

  const renderBudgetApproval = () => {
    if (!showBudgetApprovalCTA) return null;
    return (
      <BudgetManagerCTARow>
        <Button
          fullWidth={!isDesktop}
          variant={ButtonVariant.OUTLINED}
          onClick={() => setShowRejectModal(true)}
        >
          Reject Budget
        </Button>
        <Button
          fullWidth={!isDesktop}
          onClick={openBudgetApproveModal}
          variant={ButtonVariant.PRIMARY}
        >
          Approve Budget
        </Button>
      </BudgetManagerCTARow>
    );
  };

  const renderBillingInfoSubmission = () => {
    if (!showBillingInfoSubmissionCTA) return null;
    return (
      <BudgetManagerCTARow>
        {showPurchaseOrderBillingOption && (
          <Button
            fullWidth={!isDesktop}
            onClick={openSubmitBillingInfoModal}
            variant={ButtonVariant.PRIMARY}
          >
            {purchaseOrderSubmitted || billingInfoSubmitted
              ? "Update Billing Information"
              : "Submit Billing Information"}
          </Button>
        )}
      </BudgetManagerCTARow>
    );
  };

  const stickyBudgetManagementActionItems = useBottomTabBarOverlayView(
    !isDesktop,
    <>
      {renderBudgetApproval()}
      {isBudgetApproved && renderBillingInfoSubmission()}
    </>,
    DEFAULT_TAB_OVERLAY_CLASS,
    BOTTOM_TAB_BAR_OVERLAY_ID,
  );

  if (!purchaseOrder) return null;

  return (
    <>
      <LabelSummaryAssociatedUsersOverviewContainer>
        <LabelSummaryAssociatedUsersTitle>
          Team Overview
        </LabelSummaryAssociatedUsersTitle>
        <LabelSummaryAssociatedUsersOverviewBodyContainer>
          <SimpleTable
            rows={approversRowData}
            title={APPROVE_TITLE}
            headers={APPROVE_HEADERS}
          />
          {Boolean(collaboratorsRowData.length) && (
            <SimpleTable
              rows={collaboratorsRowData}
              title={COLLABORATORS_TITLE}
              headers={COLLABORATORS_HEADERS}
            />
          )}
          <SimpleTable
            rows={billingInfoRowData}
            title={BUDGET_TITLE}
            headers={BUDGET_HEADERS}
          />
        </LabelSummaryAssociatedUsersOverviewBodyContainer>
      </LabelSummaryAssociatedUsersOverviewContainer>
      {isDesktop ? (
        <>
          {renderBudgetApproval()}
          {isBudgetApproved && renderBillingInfoSubmission()}
        </>
      ) : (
        stickyBudgetManagementActionItems
      )}
      <RejectBudgetModal
        isOpen={showRejectModal}
        openModal={() => setShowRejectModal(true)}
        closeModal={() => setShowRejectModal(false)}
        code={code}
        onBudgetUpdated={(updatedPurchaseOrder) => {
          setPurchaseOrder(updatedPurchaseOrder);
        }}
      />
      <ApproveBudgeModal
        code={code}
        isOpen={budgetApprovalModalOpen}
        closeModal={closeBudgetApproveModal}
        openModal={openBudgetApproveModal}
        onBudgetUpdated={(updatedPurchaseOrder) => {
          setPurchaseOrder(updatedPurchaseOrder);
        }}
      />
      <SubmitBillingInfoModal
        code={code}
        purchaseOrder={purchaseOrder}
        purchaseOrders={purchaseOrders}
        isOpen={submitBillingInfoModalOpen}
        openModal={openSubmitBillingInfoModal}
        closeModal={closeSubmitBillingInfoModal}
        onBudgetUpdated={(updatedPurchaseOrder) => {
          setPurchaseOrder(updatedPurchaseOrder);
        }}
      />
    </>
  );
};
