import {
  MasteringProjectSteps,
  MixingProjectSteps,
  ProjectType,
} from "../../../../store/models/project";

// TODO: Update the methods here and below to factor ATMOS Mixing separately from
// regular Two Track or Full Stems Mixing.
export const isProjectOnAssetReviewStep = (
  step: number,
  projectType: ProjectType,
): boolean => {
  if (projectType === ProjectType.MASTERING) {
    return step === MasteringProjectSteps.MASTER_FINISHED;
  } else if (
    projectType === ProjectType.TWO_TRACK_MIXING ||
    projectType === ProjectType.MIXING ||
    projectType === ProjectType.ATMOS_MIXING
  ) {
    return step === MixingProjectSteps.STEMS_UPLOADED;
  }
  return false;
};
