import styled from "styled-components";
import { Button } from "../../../core-ui/components/Button/Button";

export const DashboardCardActionButton = styled(Button)`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 0;
  min-height: unset;
`;
