import { css, styled } from "styled-components";
import { ColorPalette } from "../../theme";

export const ProjectListRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const ProjectListRowAltInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ProjectListRowButtonChildrenContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  white-space: nowrap;
  gap: 2px;
`;

export const StyledProjectListInput = styled.input`
  background-color: transparent;
  font-family: "Roobert", sans-serif;
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme }) => theme.textSizeMd};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding: 0;
  ${(props) => {
    switch (props.disabled) {
      case true:
        return css`
          border: none;
        `;
      default:
        return css`
          border: 1px solid ${ColorPalette.Blue300}};
        `;
    }
  }};
`;

export const ProjectListRowText = styled.p<{
  $small?: boolean;
}>`
  font-family: "Roobert", sans-serif;
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme, $small }) =>
    $small ? theme.textSizeSm : theme.textSizeMd};
`;

export const ProjectListButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  justify-content: center;
  padding: 0;
  cursor: pointer;
`;

export const ProjectListRowEditIconDiv = styled.div``;
