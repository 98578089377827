import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, SelectChangeEvent } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { useMemo, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { localServiceCoverPhotoAtom } from "../../../atoms/profileScreenEdit";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { useAccountStatus } from "../../../hooks/accountHooks";
import { useDeleteEngineerService } from "../../../hooks/useDeleteEngineeringService";
import { useDeleteRecordingService } from "../../../hooks/useDeleteRecordingService";
import { useEngineerCanHostServices } from "../../../hooks/useEngineerCanHostServices";
import { useGetEngineerServices } from "../../../hooks/useGetEngineerServices";
import Engineer from "../../../store/models/engineer";
import {
  getRemainingServiceOptions,
  ProjectType,
  ProjectTypeOptions,
} from "../../../store/models/project";
import User from "../../../store/models/user";
import { getServiceFromServiceType } from "../../../store/utils/serviceUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { OptionsDropdown } from "../../core-ui/components/MUIOptionsDropdown/MUIOptionsDropdown";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";
import { Text } from "../../core-ui/components/Text/Text";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { ServicesFormModalContent } from "../../screens/ProfileScreen/components/ServicesFormModalContent";
import { FormType } from "../../screens/ProfileScreen/constants";
import { CenteredSoundWaveLoader } from "../CenteredSoundWaveLoader/CenteredSoundWaveLoader";
import { ContactInfoModal } from "../ContactInfoModal/ContactInfoModal";
import { EngineerServicesWrapper } from "../EngineerServicesWrapper/EngineerServicesWrapper";
import {
  AddServiceSelectionContainer,
  ServiceRow,
  ServiceRowButtons,
  ServicesEditModalContainer,
  StyledEditServicesFormModalBody,
} from "./EditServicesModal.styles";

interface EditServicesModalProps {
  engineer: Engineer;
  user: User;
  isOpen: boolean;
  closeModal: () => void;
}

export const EditServicesModal = ({
  engineer,
  user,
  isOpen,
  closeModal,
}: EditServicesModalProps) => {
  const { emailVerified, phoneVerified } = useAccountStatus();
  const [editServiceType, setEditServiceType] = useState<
    ProjectType | undefined
  >();
  const [addServiceType, setAddServiceType] = useState<
    ProjectType | undefined
  >();
  const [showAddServiceSelectionStep, setShowAddServiceSelectionStep] =
    useState(false);

  const queryClient = useQueryClient();
  const setLocalServiceCoverPhoto = useSetAtom(localServiceCoverPhotoAtom);
  const { data: engineerServicesData, isFetching: isEngineerServicesLoading } =
    useGetEngineerServices(engineer?.id);
  const canHostServices = useEngineerCanHostServices(engineer);

  const {
    mutate: deleteEngineerService,
    isPending: isDeleteEngineerServiceLoading,
  } = useDeleteEngineerService({
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES, engineer?.id],
      });
    },
  });

  const {
    mutate: deleteRecordingService,
    isPending: isDeleteRecordingServiceLoading,
  } = useDeleteRecordingService({
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES, engineer?.id],
      });
    },
  });

  const isLoading =
    isDeleteEngineerServiceLoading || isDeleteRecordingServiceLoading;

  const handleExitServiceForm = () => {
    setEditServiceType(undefined);
    setAddServiceType(undefined);
    setShowAddServiceSelectionStep(false);
  };

  const renderModalContent = () => {
    if (isEngineerServicesLoading && !editServiceType && !addServiceType) {
      return <CenteredSoundWaveLoader />;
    }

    if (!engineerServicesData) {
      return <Redirect to="/" />;
    }

    const remainingServiceOptions =
      getRemainingServiceOptions(engineerServicesData);

    if (editServiceType) {
      return (
        <ServicesFormModalContent
          engineer={engineer}
          onChangeService={(selectedProjectType: OptionType) => {
            setEditServiceType(selectedProjectType.value);
          }}
          selectedServiceType={editServiceType}
          existingServices={engineerServicesData}
          formType={FormType.EDIT}
          userData={user}
          onCancel={handleExitServiceForm}
          canHostServices={Boolean(canHostServices)}
        />
      );
    }

    if (addServiceType) {
      return (
        <ServicesFormModalContent
          engineer={engineer}
          onChangeService={(selectedProjectType: OptionType) => {
            setAddServiceType(selectedProjectType.value);
          }}
          selectedServiceType={addServiceType}
          existingServices={engineerServicesData}
          formType={FormType.CREATE}
          userData={user}
          onCancel={() => {
            handleExitServiceForm();
            setLocalServiceCoverPhoto(undefined);
          }}
          canHostServices={Boolean(canHostServices)}
        />
      );
    }

    if (showAddServiceSelectionStep || engineerServicesData.length === 0) {
      return (
        <AddServiceSelectionContainer>
          <Text>Your services:</Text>

          <OptionsDropdown
            value={undefined}
            onChange={(e: SelectChangeEvent<ProjectType>) => {
              const {
                target: { value },
              } = e;
              // On autofill we get a stringified value.
              // That's actually not what we want
              if (typeof value !== "string") {
                setAddServiceType(value);
                setShowAddServiceSelectionStep(false);
              }
            }}
            options={ProjectTypeOptions}
            placeholder="Choose a service"
          />
        </AddServiceSelectionContainer>
      );
    }

    return (
      <ServicesEditModalContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text>Your services:</Text>
          <Button
            disabled={remainingServiceOptions.length === 0}
            variant={ButtonVariant.TEXT}
            onClick={() => {
              setShowAddServiceSelectionStep(true);
            }}
            style={{ fontWeight: 600 }}
          >
            Add new service
          </Button>
        </Box>

        {engineerServicesData.length > 0 && (
          <EngineerServicesWrapper
            combinedServices={engineerServicesData}
            userData={user}
            engineer={engineer}
            userOnOwnProfile={true}
            editServiceType={editServiceType}
            addServiceType={addServiceType}
            setAddServiceType={setAddServiceType}
            remainingServiceOptions={remainingServiceOptions}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "16px",
              }}
            >
              {engineerServicesData.map((service) => {
                return (
                  <ServiceRow key={service.id}>
                    <Text>
                      {getServiceFromServiceType(service.service_type, true)}
                    </Text>
                    <ServiceRowButtons>
                      <Button
                        variant={ButtonVariant.ICON}
                        loading={isLoading}
                        disabled={isLoading}
                        labelIcon={
                          <FontAwesomeIcon
                            icon={faPencil}
                            width={12}
                            height={12}
                          />
                        }
                        onClick={() => {
                          setEditServiceType(service.service_type);
                        }}
                      />
                      <PopConfirm
                        title="Are you sure?"
                        description="You are about to remove this service. Are you sure?"
                        onConfirm={() => {
                          if (!service.id) {
                            toast.error("Something went wrong!");
                            return;
                          }

                          if (service.service_type === ProjectType.RECORDING) {
                            deleteRecordingService({
                              recording_service_id: service.id,
                            });
                          } else {
                            deleteEngineerService({
                              id: service.id,
                            });
                          }
                        }}
                      >
                        <Button
                          variant={ButtonVariant.ICON}
                          loading={isLoading}
                          disabled={isLoading}
                          labelIcon={
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              width={12}
                              height={12}
                            />
                          }
                        />
                      </PopConfirm>
                    </ServiceRowButtons>
                  </ServiceRow>
                );
              })}
            </Box>
          </EngineerServicesWrapper>
        )}
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ alignSelf: "center", minWidth: "140px" }}
          onClick={() => {
            handleExitServiceForm();
            setShowAddServiceSelectionStep(false);
            setLocalServiceCoverPhoto(undefined);
            closeModal();
          }}
        >
          Close
        </Button>
      </ServicesEditModalContainer>
    );
  };

  const modalHeader = useMemo(() => {
    if (addServiceType) {
      return `Add Services: ${getServiceFromServiceType(addServiceType, true)}`;
    }

    if (editServiceType) {
      return `Edit Services: ${getServiceFromServiceType(editServiceType, true)}`;
    }

    if (
      showAddServiceSelectionStep ||
      !engineerServicesData ||
      engineerServicesData.length === 0
    ) {
      return `Add services`;
    }

    return `Edit services`;
  }, [
    addServiceType,
    editServiceType,
    showAddServiceSelectionStep,
    engineerServicesData,
  ]);

  if (isOpen && (!emailVerified || !phoneVerified)) {
    return (
      <ContactInfoModal
        onClose={() => {
          closeModal();
        }}
      />
    );
  }

  return (
    <BaseModal
      header={modalHeader}
      open={isOpen}
      setOpen={() => {
        handleExitServiceForm();
        setLocalServiceCoverPhoto(undefined);
        setShowAddServiceSelectionStep(false);
        closeModal();
      }}
      style={{ width: "100%", maxWidth: "unset", height: "100%" }}
      customBodyStyle={StyledEditServicesFormModalBody}
      modalBodyStyle={{
        minHeight:
          showAddServiceSelectionStep ||
          (engineerServicesData &&
            engineerServicesData.length === 0 &&
            !addServiceType)
            ? "unset"
            : "350px",
      }}
      customModalStyle={{
        boxSizing: "border-box",
        width: "90%",
        maxWidth: "968px", // As per design
      }}
      fullScreenOnMobile
    >
      {renderModalContent()}
    </BaseModal>
  );
};
