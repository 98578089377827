import styled, { css } from "styled-components";
import { BaseText } from "./BaseText";
import { TextColor, TextStyleVariant } from "./TextUtils";

// Define style variants for different text components
const textStyle = {
  [TextStyleVariant.H1]: css`
    font-size: 4.625rem;
    font-weight: 600;
    line-height: 4.875rem;
    letter-spacing: -0.0925rem;

    @media (max-width: 768px) {
      font-size: 3.5rem;
      line-height: 3.75rem;
      letter-spacing: -0.07rem;
    }

    @media (max-width: 480px) {
      font-size: 2.5rem;
      line-height: 2.75rem;
      letter-spacing: -0.05rem;
    }
  `,
  [TextStyleVariant.H2]: css`
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 3.75rem;
    letter-spacing: -0.07rem;

    @media (max-width: 768px) {
      font-size: 2.5rem;
      line-height: 2.75rem;
      letter-spacing: -0.05rem;
    }

    @media (max-width: 480px) {
      font-size: 2.25rem;
      line-height: 2.5rem;
      letter-spacing: -0.045rem;
    }
  `,
  [TextStyleVariant.H3]: css`
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 2.75rem;
    letter-spacing: -0.05rem;

    @media (max-width: 768px) {
      font-size: 2.25rem;
      line-height: 2.5rem;
      letter-spacing: -0.045rem;
    }

    @media (max-width: 480px) {
      font-size: 1.875rem;
      line-height: 2.125rem;
      letter-spacing: -0.0375rem;
    }
  `,
  [TextStyleVariant.H4]: css`
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 2.5rem;
    letter-spacing: -0.045rem;

    @media (max-width: 768px) {
      font-size: 1.875rem;
      line-height: 2.125rem;
      letter-spacing: -0.0375rem;
    }

    @media (max-width: 480px) {
      font-size: 1.5rem;
      line-height: 1.75rem;
      letter-spacing: -0.03rem;
    }
  `,
  [TextStyleVariant.H5]: css`
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 2.125rem;
    letter-spacing: -0.0375rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      line-height: 1.75rem;
      letter-spacing: -0.03rem;
    }

    @media (max-width: 480px) {
      font-size: 1.125rem;
      line-height: 1.375rem;
      letter-spacing: -0.0225rem;
    }
  `,
  [TextStyleVariant.H6]: css`
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.75rem;
    letter-spacing: -0.03rem;

    @media (max-width: 768px) {
      font-size: 1.125rem;
      line-height: 1.375rem;
      letter-spacing: -0.0225rem;
    }

    @media (max-width: 480px) {
      font-size: 1rem;
      line-height: 1.25rem;
      letter-spacing: -0.02rem;
    }
  `,
  [TextStyleVariant.S1]: css`
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 2.125rem;
    letter-spacing: -0.0375rem;
  `,
  [TextStyleVariant.S2]: css`
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.75rem;
    letter-spacing: -0.03rem;
  `,
  [TextStyleVariant.S2_REGULAR]: css`
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.75rem;
  `,
  [TextStyleVariant.S3]: css`
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.375rem;
    letter-spacing: -0.0225rem;
  `,
  [TextStyleVariant.S4]: css`
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: -0.02rem;
  `,
  [TextStyleVariant.P1]: css`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
  `,
  [TextStyleVariant.P2]: css`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
  `,
  [TextStyleVariant.P3]: css`
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  `,
  [TextStyleVariant.P4]: css`
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.875rem;
  `,
};

export const StyledText = styled(BaseText)<{
  $bold: boolean;
  $color?: TextColor;
  $styleVariant: TextStyleVariant;
}>`
  font-family: Roobert;
  font-style: normal;
  color: ${({ $color, theme }) => {
    switch ($color) {
      case TextColor.PRIMARY:
        return theme.textPrimaryColor;
      case TextColor.SECONDARY:
        return theme.textSecondaryColor;
      case TextColor.TERTIARY:
        return theme.textTertiaryColor;
      case TextColor.INVERT:
        return theme.textInvertColor;
      case TextColor.WHITE:
        return theme.colorPalette.White;
      case TextColor.BLACK:
        return theme.colorPalette.Black;
      case TextColor.LIGHT_GRAY:
        return theme.colorPalette.Gray200;
      case TextColor.ERROR:
        return theme.textErrorColor;
      case TextColor.PRIMARY_NO_DARK_THEME:
        return theme.colorPalette.Gray950;
      case TextColor.SUCCESS:
        return theme.colorPalette.Green600;
      case TextColor.FAILED:
        return theme.colorPalette.Red600;
      default:
        return theme.textPrimaryColor;
    }
  }};
  ${({ $styleVariant }) => textStyle[$styleVariant]}
  ${({ $bold }) => $bold && `font-weight: 600;`}
`;
