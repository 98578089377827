import { useCallback, useEffect, useRef } from "react";
import { setIndex } from "../../store/actions/abPlayerStore";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useGetFooterPlayerRef } from "./useGetFooterPlayerRef";

/**
 * @description Append an event listener after creation of the waveform via WaveSurfer events.
 * The event listener will be triggered when the track is finished playing.
 * Because finish event is dynamic, it will be reset and set when dependencies change.
 */
export const useSetupOnFinish = (isLooping: boolean) => {
  const dispatch = useAppDispatch();
  const footerRef = useGetFooterPlayerRef();
  const { playlist, currentTrackIndex } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const disableOnFinish = useRef<(() => void) | null>(null);

  const useLoopTrack = useCallback(() => {
    if (!footerRef.current) return;
    if (isLooping) {
      footerRef.current.seekTo(0);
      if (!footerRef.current.isPlaying())
        footerRef.current.play().catch(() => {});
    } else if (playlist.length > 1) {
      footerRef.current.pause();
      dispatch(setIndex(currentTrackIndex + 1));
    }
  }, [footerRef, isLooping, playlist.length, dispatch, currentTrackIndex]);

  useEffect(() => {
    if (!footerRef.current) return;
    disableOnFinish.current?.();
    disableOnFinish.current = footerRef.current.on("finish", useLoopTrack);
  }, [footerRef.current, useLoopTrack]);
};
