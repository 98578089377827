import { Box, Divider, styled } from "@mui/material";
import { QueryObserverResult } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import useAdminAssignProjectsMutation from "../../../hooks/adminDashboard/useAdminAssignProjects";
import { useFetchScheduledProjectOverview } from "../../../hooks/scheduledProjectHooks/useFetchScheduledProjectOverview";
import { useMinAndMaxServiceRates } from "../../../hooks/useMinAndMaxServiceRates";
import { useGetTeamMemberOptions } from "../../../hooks/useTeam";
import { PaginatedAdminActionsResponse } from "../../../store/actions/adminDashboardStore";
import { ProjectType } from "../../../store/models/project";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import {
  BaseModal,
  BaseModalProps,
} from "../../core-ui/components/BaseModal/BaseModal";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { RescheduleEstimatedDeliveryDatePicker } from "../RescheduledEstimatedDeliveryDatePicker/RescheduleEstimatedDeliveryDatePicker";
import { AdminAssignEngineerInput } from "./components/AdminAssignEngineerInput";
import { AdminAssignProjectConfirmation } from "./components/AdminAssignProjectConfirmation";
import { AdminModalDropdown } from "./components/AdminModalDropdown";

interface AdminAssignProjectModalProps
  extends Omit<BaseModalProps, "children" | "header"> {
  scheduledProjectId: number | null;
  originalBookingPrice: number;
  refetchAdminActionItems:
    | (() => Promise<
        QueryObserverResult<PaginatedAdminActionsResponse, unknown>
      >)
    | null;
}

const InputLabel = styled(Text)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const AdminAssignProjectModal = ({
  setOpen,
  open,
  originalBookingPrice,
  refetchAdminActionItems,
  scheduledProjectId,
  ...props
}: AdminAssignProjectModalProps) => {
  const { data: scheduledProjectDetails, refetch: refetchScheduledProject } =
    useFetchScheduledProjectOverview({
      scheduledProjectId,
    });
  // TODO: fetch studio options
  const defaultStudioRoomOption = { label: "Atmos Room", value: 132 };
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [minServiceRate] = useMinAndMaxServiceRates(ProjectType.MIXING);
  const [primaryEngineer, setPrimaryEngineer] = useState<OptionType | null>(
    null,
  );
  const [primaryEngineerRate, setPrimaryEngineerRate] =
    useState(minServiceRate);
  const [secondaryEngineer, setSecondaryEngineer] = useState<OptionType | null>(
    null,
  );
  const [secondaryEngineerRate, setSecondaryEngineerRate] = useState(0);
  const [selectedStudio, setSelectedStudio] = useState<OptionType | null>(
    defaultStudioRoomOption,
  );
  const [isLoading, setIsLoading] = useState(false);
  const teamMemberOptions = useGetTeamMemberOptions([
    primaryEngineer?.value,
    secondaryEngineer?.value,
  ]);
  const numberFormatter = PennyDollarFormatter();

  const rateIsTooHigh = useMemo(() => {
    const primaryRate = primaryEngineer ? primaryEngineerRate : 0;
    const secondaryRate = secondaryEngineer ? secondaryEngineerRate : 0;
    const totalPrice = primaryRate + secondaryRate;
    return totalPrice > originalBookingPrice;
  }, [
    primaryEngineer,
    primaryEngineerRate,
    secondaryEngineer,
    secondaryEngineerRate,
    originalBookingPrice,
  ]);

  const { mutateAsync: adminAssignProjects } = useAdminAssignProjectsMutation();

  const dueDate = scheduledProjectDetails?.estimated_delivery_date;
  const formIsValid = Boolean(
    scheduledProjectId &&
      dueDate &&
      primaryEngineer &&
      selectedStudio &&
      !rateIsTooHigh,
  );

  const handleAssignProject = async () => {
    if (
      !scheduledProjectId ||
      !dueDate ||
      !primaryEngineer ||
      !selectedStudio
    ) {
      toast.error("Missing project details");
      return;
    }
    return adminAssignProjects({
      scheduled_project_id: scheduledProjectId,
      primary_engineer_user_id: primaryEngineer.value,
      primary_engineer_rate: primaryEngineerRate,
      ...(secondaryEngineer && {
        secondary_engineer_user_id: secondaryEngineer.value,
        secondary_engineer_rate: secondaryEngineerRate ?? 0,
      }),
      studio_room_id: selectedStudio.value,
      due_date: dueDate,
    }).then(() =>
      toast.success(
        `Project reassigned to ${primaryEngineer.label}${secondaryEngineer ? ` & ${secondaryEngineer.label}` : ""}`,
      ),
    );
  };

  return (
    <BaseModal
      header="Assign Engineer(s) & Studio"
      setOpen={setOpen}
      open={open}
      confirmButtonDisabled={isLoading || !formIsValid}
      confirmText={showConfirmation ? "Confirm" : "Assign"}
      loading={isLoading}
      onConfirm={() => {
        if (!showConfirmation) {
          setShowConfirmation(true);
          return;
        }
        setIsLoading(true);
        handleAssignProject()
          .then(() => refetchAdminActionItems?.())
          .then(() => setOpen(false))
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }}
      onCancel={() => {
        if (showConfirmation) {
          setShowConfirmation(false);
          return;
        }
        setPrimaryEngineer(null);
        setPrimaryEngineerRate(minServiceRate);
        setSecondaryEngineer(null);
        setSecondaryEngineerRate(0);
        setOpen(false);
      }}
      showModalFooter
      {...props}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {showConfirmation && (
          <AdminAssignProjectConfirmation
            primaryEngineer={primaryEngineer}
            primaryEngineerRate={primaryEngineerRate}
            secondaryEngineer={secondaryEngineer}
            secondaryEngineerRate={secondaryEngineerRate}
            studioRoom={selectedStudio?.label}
          />
        )}
        {!showConfirmation && (
          <>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                gap: 1,
                marginBottom: 3,
              }}
            >
              <Text bold>Project due date:</Text>
              <RescheduleEstimatedDeliveryDatePicker
                scheduleProjectId={scheduledProjectId ?? 0}
                estimatedDeliveryDate={
                  scheduledProjectDetails?.estimated_delivery_date ?? null
                }
                onSuccess={refetchScheduledProject}
              />
            </Box>
            <AdminAssignEngineerInput
              engineerOptions={teamMemberOptions}
              labelText="Mixing engineer"
              rate={primaryEngineerRate}
              setRate={setPrimaryEngineerRate}
              selectedEngineer={primaryEngineer}
              setSelectedEngineer={setPrimaryEngineer}
            />
            <AdminAssignEngineerInput
              engineerOptions={teamMemberOptions}
              labelText="Assistant engineer"
              minRate={0}
              rate={secondaryEngineerRate}
              setRate={setSecondaryEngineerRate}
              selectedEngineer={secondaryEngineer}
              setSelectedEngineer={setSecondaryEngineer}
            />
            <Box>
              <InputLabel variant={TextStyleVariant.S3}>Studio</InputLabel>
              <AdminModalDropdown
                dropdownWidth="148px"
                options={[defaultStudioRoomOption]}
                placeholder="Select studio"
                selectedOption={selectedStudio}
                setSelectedOption={setSelectedStudio}
              />
            </Box>
            <Divider sx={{ marginTop: 3, marginBottom: 0, width: "100%" }} />
          </>
        )}
        {primaryEngineer &&
          !scheduledProjectDetails?.estimated_delivery_date && (
            <Text color={TextColor.ERROR} style={{ marginTop: "16px" }}>
              Select an estimated project due date.
            </Text>
          )}
        {rateIsTooHigh && (
          <Text color={TextColor.ERROR} style={{ marginTop: "16px" }}>
            This rate is over the original booking rate:{" "}
            {numberFormatter.format(originalBookingPrice)}
          </Text>
        )}
      </Box>
    </BaseModal>
  );
};
