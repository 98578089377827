import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Studio } from "../models/studio";
import User from "../models/user";

export interface SelectedProfileState {
  studio?: Studio;
  user?: User;
}

const initialState: SelectedProfileState = {
  studio: undefined,
  user: undefined,
};

export const selectedProfileSlice = createSlice({
  name: "selectedProfile",
  initialState,
  reducers: {
    setStudio: (state, action: PayloadAction<Studio>) => {
      state.studio = action.payload;
      state.user = undefined;
      const serializedState = JSON.stringify(state);
      localStorage.setItem("selectedProfile", serializedState);
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.studio = undefined;
      state.user = action.payload;
      const serializedState = JSON.stringify(state);
      localStorage.setItem("selectedProfile", serializedState);
    },
    setSelectedProfile: (
      state,
      action: PayloadAction<SelectedProfileState>,
    ) => {
      state.studio = action.payload.studio;
      state.user = action.payload.user;
      const serializedState = JSON.stringify(state);
      localStorage.setItem("selectedProfile", serializedState);
    },
    clearSelectedProfile: (state) => {
      state.studio = undefined;
      state.user = undefined;
      localStorage.removeItem("selectedProfile");
    },
  },
});

export const { setStudio, setUser, clearSelectedProfile, setSelectedProfile } =
  selectedProfileSlice.actions;
export default selectedProfileSlice.reducer;
