import styled from "styled-components";
import { Button } from "../../core-ui/components/Button/Button";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Heading = styled.p`
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  font-size: ${({ theme }) => theme.textSizeMd};
  line-height: 20px;
  color: ${({ theme }) => theme.textPrimaryColor};
  margin-right: 8px;
`;

export const DisplayDate = styled(Button)`
  font-weight: ${({ theme }) => theme.textWeightNormal};
  font-size: ${({ theme }) => theme.textSizeMd};
  line-height: 20px;
  color: ${({ theme }) => theme.textPrimaryColor};
  margin-left: 16px;
  cursor: pointer;
`;

export const DisplayDateSpan = styled.span`
  text-decoration: underline;
`;
