import { useContext, useState } from "react";
import ViewSlider, { ViewProps } from "react-view-slider";
import {
  MasteringProjectSteps,
  MixingProjectSteps,
  ProjectById,
  ProjectType,
  ProjectUserType,
  ProjectWorkflowSteps,
} from "../../../store/models/project";
import { SessionSteps } from "../../../store/models/recordingSession";
import { getProjectWorkflowStep } from "../../../utils/projectUtils";
import { DownloadFilesView } from "../DownloadFilesView/DownloadFilesView";
import { TrackTable } from "../TrackTable/TrackTable";
import { InProgressArtistButtonRow } from "./components/InProgressArtistButtonRow";
import { InProgressEngineerButtonRow } from "./components/InProgressEngineerButtonRow";

import { useIsInProgressProject } from "../../../hooks/partialPaymentHooks";
import useModal from "../../../hooks/useModal";
import { FileType } from "../../../store/models/fileVersion";
import {
  InProgressUploader,
  UploadSettings,
} from "../FileUploader/InProgressUploader/InProgressUploader";
import { MainProjectWorkflowContext } from "../MainProjectWorkflowPanel/MainProjectWorkflowPanelContext";

export interface InProgressTransitionViewProps {
  code?: string;
  project: ProjectById;
  projectStep: MixingProjectSteps | MasteringProjectSteps | SessionSteps;
  projectType: ProjectType;
  userType?: ProjectUserType | null;
  allowTrackPreview?: boolean;
  allowTrackApproval?: boolean;
  isPartiallyPaid?: boolean;
  outstandingBalance?: number;
}

export enum InProgressTransitionViewIndex {
  TRACKS,
  IN_REVIEW_UPLOAD,
  FILES,
}

export const InProgressTransitionView = ({
  code,
  project,
  projectStep,
  projectType,
  userType,
  allowTrackPreview = true,
  allowTrackApproval = true,
  isPartiallyPaid = false,
  outstandingBalance,
}: InProgressTransitionViewProps) => {
  const isEngineer = userType === ProjectUserType.ENGINEER;
  const isArtist = userType === ProjectUserType.ARTIST;
  const projectWorkflowStep = getProjectWorkflowStep(projectType, projectStep);
  const isInProgress = projectWorkflowStep === ProjectWorkflowSteps.IN_MIX;
  const isInReview = projectWorkflowStep === ProjectWorkflowSteps.MIX_REVIEW;
  const [uploadSettings, setUploadSettings] = useState<
    UploadSettings | undefined
  >(undefined);
  const mainProjectWorkflowContext = useContext(MainProjectWorkflowContext);
  const viewIndex =
    mainProjectWorkflowContext?.inProgressViewIndex ||
    InProgressTransitionViewIndex.TRACKS;
  const setViewIndex = mainProjectWorkflowContext?.setInProgressViewIndex;
  const isAtmos = projectType === ProjectType.ATMOS_MIXING;
  const atmosFileTypes = [FileType.WAV, FileType.MP4, FileType.ZIP];

  const { isInProgressProject } = useIsInProgressProject(project);
  const showArtistButtonRow =
    isArtist || code || (!isEngineer && isInProgressProject);
  const {
    isOpen: isCompletePaymentPopoverOpen,
    setIsOpen: setIsCompletePaymentPopoverOpen,
    closeModal: closeCompletePaymentPopover,
  } = useModal();

  const renderView = ({ index }: ViewProps) => {
    if (index === InProgressTransitionViewIndex.TRACKS)
      return (
        <>
          <TrackTable
            allowTrackPreview={allowTrackPreview}
            allowTrackComments={allowTrackApproval}
            displayComments
            showHeader
            showFileNames
            isCurrentEngineer={userType === ProjectUserType.ENGINEER}
            project={project}
            projectId={project.id}
            projectName={project.title}
            projectType={projectType}
            step={projectStep}
            userType={userType}
            handleUpload={(reference, uploadType) => {
              const uploadFileType = [uploadType];
              setUploadSettings({
                reference,
                uploadType:
                  isAtmos && !reference ? atmosFileTypes : uploadFileType,
              });
              setViewIndex?.(InProgressTransitionViewIndex.IN_REVIEW_UPLOAD);
            }}
            handlePaymentFlow={() => {
              setIsCompletePaymentPopoverOpen(true);
            }}
          />
          {isEngineer && (
            <InProgressEngineerButtonRow
              handleViewTransitionNavigation={() => {
                setUploadSettings({
                  reference: false,
                  uploadType: isAtmos ? atmosFileTypes : [FileType.WAV],
                });
                setViewIndex?.(InProgressTransitionViewIndex.IN_REVIEW_UPLOAD);
              }}
              isInProgressStep={isInProgress}
              project={project}
            />
          )}
          {showArtistButtonRow && (
            <InProgressArtistButtonRow
              isInProgressStep={isInProgress}
              project={project}
              allowTrackApproval={allowTrackApproval}
              code={code}
              isPartiallyPaid={isPartiallyPaid}
              isCompletePaymentPopoverOpen={isCompletePaymentPopoverOpen}
              setIsCompletePaymentPopoverOpen={setIsCompletePaymentPopoverOpen}
              closeCompletePaymentPopover={closeCompletePaymentPopover}
              outstandingBalance={outstandingBalance}
              isInProgressProject={isInProgressProject}
            />
          )}
        </>
      );
    if (index === InProgressTransitionViewIndex.IN_REVIEW_UPLOAD) {
      return (
        <InProgressUploader
          handleBackButton={() => {
            setViewIndex?.(InProgressTransitionViewIndex.TRACKS);
          }}
          code={code ?? null}
          projectId={project.id}
          isEngineerOnProject={isEngineer}
          uploadSettings={uploadSettings}
          isInReview={isInReview}
        />
      );
    }
    if (index === InProgressTransitionViewIndex.FILES) {
      return (
        <DownloadFilesView
          project={project}
          projectWorkflowStep={projectWorkflowStep}
          isArtist={isArtist}
          isEngineer={isEngineer}
          code={code}
          handleDismissDownloadFiles={() => {
            setViewIndex?.(InProgressTransitionViewIndex.TRACKS);
          }}
        />
      );
    }
    return null;
  };

  if (!isInProgress && !isInReview) return null;

  return (
    <ViewSlider
      keepViewsMounted={false}
      renderView={renderView}
      numViews={3}
      activeView={viewIndex}
      animateHeight
      style={{ overflow: "visible" }} // allows TrackTableOptions to be visible
    />
  );
};
