import React from "react";
import { useIsDolbySupported } from "../../../hooks/useIsDolbySupported";
import { Button } from "../../elements/Button/button";
import "./AtmosBookingScreen.css";

export interface AtmosBookingScreenProps {
  onClickNextStep: () => void;
}

export const AtmosBookingScreen = ({ ...props }: AtmosBookingScreenProps) => {
  const isDolbySupported = useIsDolbySupported();

  return (
    <div className="container checkout-screen" style={{ maxWidth: "550px" }}>
      <div className="row">
        <h4 style={{ width: "unset" }}>
          Book a Dolby ATMOS Mix with EngineEars
        </h4>
        <div>
          <div className="d-flex flex-column py-3" style={{ gap: "20px" }}>
            <p>
              EngineEars is proud to provide high quality Dolby ATMOS mixes
              completed by engineers who have been certified through our
              platform.
            </p>
            <p style={{ fontWeight: 600 }}>
              In order to review a Dolby ATMOS mix on EngineEars, you need to
              use a compatible device and browser that enables playback of the
              Dolby ATMOS mix in headphones.
            </p>
            <p>
              <a
                className="atmos-review-link"
                href="https://professional.dolby.com/content-creation/Dolby-Atmos-for-content-creators/"
                target="_blank"
              >
                Click here
              </a>{" "}
              to learn more about Dolby ATMOS.
            </p>
            {!isDolbySupported ? (
              <p className="b2">
                * We detected that your current browser does not yet support
                Dolby ATMOS playback.
                <br></br>
                Safari 10+/9+ on Mac OS/iOS devices or Microsoft Edge for
                Windows 10+ devices is required.
              </p>
            ) : (
              <p className="b2">
                * Your current OS and browser are compatible for Dolby ATMOS
                playback.
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-2 dolby-button-footer">
          <Button
            onClick={props.onClickNextStep}
            label={
              isDolbySupported
                ? "Continue to Booking"
                : "I understand the device requirements"
            }
            primary={true}
          />
        </div>
      </div>
    </div>
  );
};
