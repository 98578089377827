import { RefObject } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { Transaction } from "../../../store/models/transaction";
import { stripePromise } from "../../../store/utils/stripe";
import { useTransactionTotalAsPennies } from "../../../hooks/transactionHook";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import {
  StripePaymentForm,
  StripePaymentFormHandles,
} from "../StripePaymentForm/StripePaymentForm";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

export interface CheckoutProps {
  transaction: Transaction | null;
  stripePaymentFormRef: RefObject<StripePaymentFormHandles>;
}

export const Checkout = ({
  transaction,
  stripePaymentFormRef,
}: CheckoutProps) => {
  const darkMode = useAtomValue(darkModeAtom);
  const amount = useTransactionTotalAsPennies(transaction);

  if (!transaction) return <SoundWaveLoader width={100} height={100} />;

  return (
    <div className="session-extension-checkout-screen">
      <div className="session-extension-stripe-checkout">
        <Elements
          stripe={stripePromise}
          options={{
            mode: "payment",
            currency: "usd",
            amount,
            paymentMethodTypes: ["card"],
            appearance: {
              theme: darkMode ? "night" : "stripe",
            },
          }}
        >
          <StripePaymentForm
            ref={stripePaymentFormRef}
            transaction={transaction}
            isSessionExtensionPurchase={true}
          />
        </Elements>
      </div>
    </div>
  );
};
