import { PendingSessionData } from "../actions/shoppingCart";
export const hoursFromMinutes = (minutes: number) => minutes / 60;
export const minutesFromHours = (hours: number) => hours * 60;

export const generateSessionList = (
  numberOfDays = 0,
  selectedDates: Date[] = [],
  initialDuration = 0,
): PendingSessionData[] => {
  const sessions: PendingSessionData[] = [];
  for (let i = 0; i < numberOfDays; i++) {
    const date = selectedDates[i];
    if (!date) {
      sessions.push({
        selectedISODate: undefined,
        duration: initialDuration,
      });
      continue;
    }

    sessions.push({
      selectedISODate: date.toISOString(),
      duration: initialDuration,
    });
  }
  return sessions;
};
