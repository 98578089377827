export const MOBILE_MAX = 480;
export const TABLET_MAX = 768;
// This comes from the CSS of .transaction-booking-screen_content-container
export const TRANSACTION_SCREEN_LAYOUT_CHANGE_BREAKPOINT = 970;

export enum Breakpoint {
  Mobile = "(min-width: 0) and (max-width: 480px)",
  Tablet = "(min-width: 481px) and (max-width: 768px)",
  Desktop = "(min-width: 769px)",
}

export enum UniqueBreakpoints {
  DashboardMobile = "(max-width: 1011px)",
  DashboardTablet = "(max-width: 1200px)",
}

export const MUI_CONTAINER_NO_PADDING_CUT_OFF = 1372;
