import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Service from "../store/models/service";
import { OptionType } from "../stories/elements/DropDownSelector/DropdownSelector";
import { useGetServiceOriginalRate } from "./profileScreenHooks/useGetServiceOriginalRate";

export const MINIMUM_DEPOSIT = 25;
export const MAXIMUM_DEPOSIT = 75;
export const DEFAULT_DEPOSIT = 100;

interface useManageEngineeringServiceProps {
  onUpdatePrice?: (newPrice: number) => void;
  pendingService: Service;
  setPendingService: Dispatch<SetStateAction<Service>>;
}

const useManageEngineeringService = ({
  onUpdatePrice,
  pendingService,
  setPendingService,
}: useManageEngineeringServiceProps) => {
  const [allowPartialPayments, setAllowPartialPayments] = useState<boolean>(
    pendingService.minimum_deposit_percentage < 1,
  );
  const [addBulkDiscount, setAddBulkDiscount] = useState<boolean>(
    Boolean(
      pendingService.add_master_discount_percentage ||
        pendingService.add_atmos_mix_discount_percentage,
    ),
  );
  const { isLabelPriceApplied } = useGetServiceOriginalRate(
    pendingService,
    true,
  );

  useEffect(() => {
    if (!allowPartialPayments) {
      handleDepositPercentage(1);
    }
  }, [allowPartialPayments]);

  const updateMP3DownloadPreference = (value: boolean) => {
    setPendingService({
      ...pendingService,
      enable_artist_review_mp3_file_download: value,
    });
  };

  const updateWAVDownloadPreference = (value: boolean) => {
    setPendingService({
      ...pendingService,
      enable_artist_review_wav_file_download: value,
    });
  };

  const updateCostPerExtraAlt = useCallback(
    (value: number) => {
      setPendingService({
        ...pendingService,
        extra_alt_price: value,
      });
    },
    [pendingService],
  );

  const updateExtraRevisionPrice = useCallback((value: number) => {
    setPendingService((prev) => ({
      ...prev,
      extra_revision_price: value,
    }));
  }, []);

  const updateNumberOfFreeRevisions = useCallback(
    (updatedNumber: OptionType) => {
      setPendingService((prevState) => ({
        ...prevState,
        free_revisions: updatedNumber.value,
      }));
    },
    [],
  );

  const changeServiceRatePrice = useCallback((updatedPrice: number) => {
    setPendingService((prevState: Service) => {
      return {
        ...prevState,
        service_rate: {
          ...prevState.service_rate,
          price: updatedPrice,
        },
      } as Service;
    });
    if (!isLabelPriceApplied) {
      onUpdatePrice?.(updatedPrice);
    }
  }, []);

  const changeServiceRateForLabelPrice = useCallback((updatedPrice: number) => {
    setPendingService((prevState: Service) => {
      return {
        ...prevState,
        service_rate: {
          ...prevState.service_rate,
          label_price: updatedPrice,
        },
      } as Service;
    });
    if (isLabelPriceApplied) {
      onUpdatePrice?.(updatedPrice);
    }
  }, []);

  const handleDepositPercentage = useCallback((depositPercentage: number) => {
    setPendingService((prevState: Service) => ({
      ...prevState,
      minimum_deposit_percentage: depositPercentage,
    }));
  }, []);

  const handlePartialPaymentPaywallOption = useCallback(
    (option: OptionType) => {
      setPendingService((prevState: Service) => {
        return {
          ...prevState,
          partial_payment_paywall_option: option.value,
        } as Service;
      });
    },
    [],
  );

  return {
    allowPartialPayments,
    setAllowPartialPayments,
    pendingService,
    setPendingService,
    handleDepositPercentage,
    updateCostPerExtraAlt,
    updateExtraRevisionPrice,
    changeServiceRateForLabelPrice,
    updateNumberOfFreeRevisions,
    changeServiceRatePrice,
    updateWAVDownloadPreference,
    updateMP3DownloadPreference,
    handlePartialPaymentPaywallOption,
    addBulkDiscount,
    setAddBulkDiscount,
  };
};

export default useManageEngineeringService;
