import { styled } from "@mui/material";

export const ColoredSpan = styled("span")<{ $backgroundColor: string }>(
  ({ theme, $backgroundColor }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    padding: 8,
    height: 14,
    borderRadius: theme.border.radius.md,
    backgroundColor: $backgroundColor,
    minWidth: 91,
  }),
);
