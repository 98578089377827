import { ColoredSpan } from "./styles";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import logo from "../../assets/header-logo.svg";
import { useTheme } from "@mui/material";
import whiteLogo from "../../assets/header-white.svg";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

export const MadeOnEngineers = () => {
  const theme = useTheme();
  const darkMode = useAtomValue(darkModeAtom);
  return (
    <ColoredSpan
      $backgroundColor={theme.palette.customColor.monochromeBackgroundColor}
    >
      <Text
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        color={TextColor.SECONDARY}
        variant={TextStyleVariant.P3}
      >
        Made on
      </Text>
      <img
        style={{
          opacity: darkMode ? 1 : 0.5,
        }}
        width={80}
        height={14}
        alt={"logo"}
        src={darkMode ? whiteLogo : logo}
      />
    </ColoredSpan>
  );
};
