import { addPendingSession } from "../../../store/actions/shoppingCart";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { RecordingService } from "../../../store/models/recording";
import { selectShoppingCartLatestSessionDuration } from "../../../store/selectors/shoppingCart";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  Text,
  TEXT_COLOR,
  TEXT_SIZE,
} from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import "./TransactionBookingScreen.css";

const TransactionBookingDetailsHeader = ({
  recordingService,
}: {
  recordingService: RecordingService | null;
}) => {
  const dispatch = useAppDispatch();
  const { activeServiceType, activeTransactionId } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);

  const isRecordingServiceTypeActive =
    activeServiceType == ProjectType.RECORDING;

  const sessions = useAppSelector(
    (state) => state.shoppingCart.pendingSessionData ?? [],
  );
  const minimumRecordingSessionDuration = useAppSelector(
    selectShoppingCartLatestSessionDuration(recordingService),
  );
  const { activeStudioId } = useAppSelector(
    (state) => state.generateBookingStore,
  );

  const onAddSession = () => {
    emitAnalyticsTrackingEvent(
      "booking_flow-click_add_session",
      {
        transactionId: activeTransactionId,
      },
      loggedInUser?.id,
    );

    const lastSession = sessions
      .slice()
      .reverse()
      .find(
        (session) =>
          session.generalWorkingHourIntervalLabel || session.selectedDateOption,
      );
    const generalWorkingHourIntervalLabel =
      lastSession?.selectedDateOption?.label ??
      lastSession?.generalWorkingHourIntervalLabel;
    dispatch(
      addPendingSession({
        duration: minimumRecordingSessionDuration,
        generalWorkingHourIntervalLabel,
        engineer: lastSession?.trackingEngineer,
      }),
    );
  };

  const cssPrefix = "transaction-booking-screen_";
  return (
    <>
      <div className={`${cssPrefix}select-service-header`}>
        <Text variant={TextStyleVariant.H6}>
          {isRecordingServiceTypeActive ? "Choose your day" : "Select service"}
        </Text>
        {isRecordingServiceTypeActive && (
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={onAddSession}
            disabled={
              recordingService?.will_come_to_you === null && !activeStudioId
            }
          >
            + Add session
          </Button>
        )}
      </div>
      <div className={`${cssPrefix}select-services-subtext`}>
        <Text color={TEXT_COLOR.SECONDARY} size={TEXT_SIZE.SMALL}>
          {isRecordingServiceTypeActive
            ? "Enter your session details below"
            : "Select your service and set quantity of songs"}
        </Text>
      </div>
    </>
  );
};

export default TransactionBookingDetailsHeader;
