import { Box } from "@mui/material";
import { TrendingArtistReleaseTracks } from "../TrendingArtistReleaseTracks/TrendingArtistReleaseTracks";
import { ArtistReleases } from "../ArtistReleases/ArtistReleases";
import { ReactNode } from "react";
import { ReleaseType } from "../../../store/models/release";
import { useParams } from "react-router-dom";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { useFetchArtistReleasesQuery } from "../../../api/releases/hooks/useFetchArtistReleasesQuery";
import {
  EmptyContainer,
  EmptyContainerFaIcon,
} from "../../screens/ProfileScreen/ProfileScreen.styles";
import { faMusic } from "@fortawesome/free-solid-svg-icons";
import { Text } from "../../core-ui/components/Text/Text";
import { useAtomValue } from "jotai";
import { editMode } from "../../../atoms/profileScreenEdit";

interface ReleaseOverviewProps {
  shouldShowDesktop: boolean;
  onReleaseClick: (releaseId?: number) => void;
  children?: ReactNode;
}

export const ReleaseOverview = ({
  children,
  onReleaseClick,
  shouldShowDesktop,
}: ReleaseOverviewProps) => {
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const isEditMode = useAtomValue(editMode);
  const { data, isPending } = useFetchArtistReleasesQuery(
    userData?.id || 0,
    ReleaseType.NO_RELEASE_TYPE,
    1,
  );
  if (!isPending && (!data || data.count === 0)) {
    return (
      <EmptyContainer>
        <EmptyContainerFaIcon icon={faMusic} />
        <Text>
          {isEditMode
            ? "Completed projects on EngineEars can be featured on your profile here"
            : "No releases for this artist available"}
        </Text>
      </EmptyContainer>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "54px" }}>
      {children}
      <TrendingArtistReleaseTracks
        onReleaseClick={onReleaseClick}
        shouldShowDesktop={shouldShowDesktop}
      />
      <ArtistReleases
        onReleaseClick={onReleaseClick}
        shouldShowDesktop={shouldShowDesktop}
      />
    </Box>
  );
};
