import {
  faAngleDown,
  faCloudArrowUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Popover from "@radix-ui/react-popover";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { useCurrentProjectHasAlts } from "../../../hooks/useCurrentProjectHasAlts";
import useModal from "../../../hooks/useModal";
import { Alt, UploadTypeOptions } from "../../../store/models/alts";
import { FileType } from "../../../store/models/fileVersion";
import {
  BaseProject,
  Project,
  ProjectById,
  ProjectType,
} from "../../../store/models/project";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { CheckboxGroup, Select } from "../../core-ui/components/Select/Select";
import {
  Text,
  TEXT_COLOR,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import { TextColor } from "../../core-ui/components/Text/TextUtils";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import "./Uploader.css";
import { UploaderContainer } from "./Uploader.styles";
import {
  formatFileSizeToMegaBytes,
  isWavTooBig,
  WAV_SIZE_LIMIT_MB,
} from "./utils";

export interface UploaderProps {
  headerText: string;
  instructionText: string;
  acceptedFiles: string[];
  uploaderDisabled: boolean;
  onDrop: (acceptedFiles: File[]) => void;
  project?: Project | ProjectById | BaseProject;
  uploadAlt?: OptionType;
  setUploadType?: Dispatch<SetStateAction<OptionType>>;
  additionalPopoverContent?: ReactNode;
  isEngineer?: boolean;
}

export const Uploader = ({
  acceptedFiles,
  uploaderDisabled,
  onDrop,
  headerText,
  instructionText,
  project,
  uploadAlt = UploadTypeOptions[0],
  setUploadType,
  additionalPopoverContent,
  isEngineer = false,
}: UploaderProps) => {
  const [, alts] = useCurrentProjectHasAlts(project);
  const [files, setFiles] = useState<File[]>([]);
  const [wavIsTooBig, setWavIsTooBig] = useState(false);
  const { setIsOpen, isOpen, closeModal } = useModal();
  const theme = useTheme();
  const isAtmos = project?.service_type === ProjectType.ATMOS_MIXING;

  const file = files[0];

  const altSelector =
    alts.some((altItem) => altItem.alt === Alt.CLEAN) &&
    (!file?.name.includes(FileType.ZIP) || (isAtmos && isEngineer)) ? (
      <Select
        dropdownZIndex={5000}
        triggerButton={
          <Button
            labelIcon={<FontAwesomeIcon icon={faAngleDown} />}
            variant={ButtonVariant.OUTLINED}
          >
            {uploadAlt.label}
          </Button>
        }
      >
        <CheckboxGroup
          options={UploadTypeOptions}
          selected={uploadAlt.value}
          onSelect={(option) => {
            const selectedOption = UploadTypeOptions.find(
              (opt) => opt.value === option,
            );
            if (selectedOption && setUploadType) {
              setUploadType(selectedOption);
            }
          }}
          multiple={false}
        />
      </Select>
    ) : undefined;

  const standardPopoverContent = (files: File[]) => {
    const getDescription = (files: File[]) => {
      const file = files[0];
      if (!file) return "this file";
      const megabytes = formatFileSizeToMegaBytes(file.size);
      return `File: ${file.name} - Size: ${megabytes} MB`;
    };

    return {
      title: "Confirm file upload",
      description: `Are you sure you want upload ${getDescription(files)}?`,
      onCancel: () => setFiles([]),
      okText: "Confirm Upload",
      cancelText: "Cancel",
    };
  };

  const fileTooBigPopoverContent = (files: File[]) => ({
    title: "File is too large",
    description: `Files larger than ${WAV_SIZE_LIMIT_MB} MB are not allowed. Please upload a ZIP file instead. File: ${files[0]?.name} - Size: ${formatFileSizeToMegaBytes(files[0]?.size)} MB`,
    onCancel: () => setFiles([]),
    okText: "Confirm",
    cancelText: "Cancel",
  });

  const [popoverContent, setPopoverContent] = useState(
    standardPopoverContent(files),
  );

  const handleOnDrop = (selectedFiles: File[]) => {
    const file = selectedFiles[0];
    if (!selectedFiles.length || !file) {
      toast.error(
        `File type not supported. Please upload: ${acceptedFiles.join(" ")}.`,
      );
      return;
    }
    setFiles(selectedFiles);

    if (file.name.includes(FileType.ZIP) && setUploadType) {
      setUploadType(UploadTypeOptions[0]);
    }

    const wavIsTooBig = isWavTooBig(file, isAtmos);
    setWavIsTooBig(wavIsTooBig);
    setPopoverContent(
      wavIsTooBig
        ? fileTooBigPopoverContent(selectedFiles)
        : standardPopoverContent(selectedFiles),
    );
    setIsOpen(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleOnDrop,
    accept: acceptedFiles,
    multiple: false,
    disabled: uploaderDisabled,
  });

  // this keeps the popover within the SidePanel - we may need to adjust this for other use cases
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  return (
    <BasePopover
      side={"top"}
      isOpen={isOpen}
      setIsPopoverOpen={setIsOpen}
      closePopover={closeModal}
      title={popoverContent.title}
      description={popoverContent.description}
      onConfirm={() => (wavIsTooBig ? setFiles([]) : onDrop(files))}
      onCancel={popoverContent.onCancel}
      okText={popoverContent.okText}
      additionalContent={
        wavIsTooBig ? (
          <></>
        ) : (
          <>
            {additionalPopoverContent && additionalPopoverContent}
            {altSelector}
          </>
        )
      }
      wrapperElement={containerElement}
    >
      <Popover.Anchor>
        <UploaderContainer {...getRootProps()} $disabled={uploaderDisabled}>
          <input {...getInputProps()} disabled={uploaderDisabled} />
          <Text
            color={uploaderDisabled ? TEXT_COLOR.TERTIARY : TextColor.BLACK}
            weight={TEXT_WEIGHT.BOLD}
          >
            {headerText}
          </Text>
          <FontAwesomeIcon
            color={theme.colorPalette.Gray200}
            icon={faCloudArrowUp}
            size="2x"
          />
          <Text
            color={uploaderDisabled ? TEXT_COLOR.TERTIARY : TextColor.BLACK}
          >
            {instructionText}
          </Text>
        </UploaderContainer>
      </Popover.Anchor>
    </BasePopover>
  );
};
