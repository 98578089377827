import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, styled } from "@mui/material";

export const AboutMeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    rowGap: "8px",
  },
}));

export const NoDataContainer = styled(Box)(({ theme }) => ({
  height: "190px",
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  background: theme.palette.customColor.midgroundColor,
  alignItems: "center",
  gap: theme.spacing(4),
  alignSelf: "stretch",
  [theme.breakpoints.down("sm")]: {
    height: "140px",
    gap: theme.spacing(3),
  },
}));

export const StyledEmptyFaIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.standardColor.Gray[300],
  width: "45px",
  height: "40px",
  flexShrink: 0,
}));
