import Lottie, { Options as LottieOptions } from "react-lottie";
import lottieSuccessData from "../../lotties/success.json";

const lottieSuccessOptions = {
  loop: false,
  autoplay: true,
  duration: 10000,
  animationData: lottieSuccessData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface SuccessLottieProps {
  options?: LottieOptions;
}

export const SuccessLottie = ({ options }: SuccessLottieProps) => {
  return (
    <Lottie
      options={{ ...lottieSuccessOptions, ...options }}
      height={150}
      width={150}
    />
  );
};
