import { createContext, useContext } from "react";
import { PaginatedRecordingSessions } from "../../../../../store/models/recordingSession";

interface SessionTableViewContextType {
  handleOpenSessionBookingRequestedPanel: (
    sessionBookingId: PaginatedRecordingSessions["recording_session_request_id"],
  ) => void;
  handleOpenSessionDetailsPanel: (prjectId: number) => void;
}

export const SessionTableViewContext =
  createContext<SessionTableViewContextType | null>(null);

export const useSessionTableViewContext = () => {
  return useContext(SessionTableViewContext);
};
