import { Box, styled } from "@mui/material";

export const NotificationDotWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  "& .notification": {
    "&:after": {
      content: '""',
      position: "absolute",
      top: "7px",
      right: "7px",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: theme.palette.error.main,
    },
  },
}));
