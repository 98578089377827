import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import { useQueryParam } from "../../../../hooks/useQueryParam";
import { useAppSelector } from "../../../../store/hooks";
import {
  ProjectQueryParam,
  ProjectType,
} from "../../../../store/models/project";
import { isUserCollaboratorOnProject } from "../../../../utils/projectUtils";
import { ButtonVariant } from "../../../core-ui/components/Button/Button";
import { Text } from "../../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../../core-ui/components/Text/TextUtils";
import { ProjectListRow } from "../../../elements/ProjectListRow/ProjectListRow";
import {
  ProjectPanelSeparator,
  ProjectPanelTrackButton,
  ProjectPanelTrackButtonContainer,
  ProjectPanelTrackHeaderContainer,
} from "../ProjectWorkflowPanel.styles";

interface ProjectPanelTrackHeaderProps {
  isLoading?: boolean;
  projectId?: number | null;
  hideTrackButtons?: boolean;
}

export const ProjectPanelTrackHeader = ({
  isLoading = false,
  projectId,
  hideTrackButtons = false,
}: ProjectPanelTrackHeaderProps) => {
  const project = useAppSelector((state) =>
    projectId ? state.projectsMapStore.projects[projectId] : null,
  );
  const projectServiceType = project?.service_type ?? ProjectType.NO_TYPE;
  const { user: currentUser } = useAppSelector((state) => state.accountInfo);
  const { scheduledProject } = useAppSelector(
    (state) => state.scheduledProjectsStore,
  );
  const userIsCollaborator = isUserCollaboratorOnProject(
    currentUser,
    project?.users,
  );

  const projectQuery = useQueryParam(ProjectQueryParam.PROJECT_ID);
  const { isMobile } = useMediaQueryBreakpoint();

  const validProjectIds = scheduledProject?.projects
    .filter((project) => project.prereq_project_is_completed)
    .filter((project) => project.service_type === projectServiceType)
    .map((project) => project.id);

  const currentIndex = validProjectIds?.indexOf(projectId ?? -1);
  if (!validProjectIds || currentIndex == null || currentIndex === -1) {
    return null;
  }

  if (!project && !isLoading) {
    return <div style={{ height: "32px" }} />;
  }

  const handleSelectTrack = (trackIndex: number) => {
    const trackProjectId = validProjectIds[trackIndex];
    if (!trackProjectId) {
      toast.error("Track not found");
      return;
    }
    projectQuery.set(trackProjectId.toString());
  };

  return (
    <div style={{ width: "100%" }}>
      <ProjectPanelTrackHeaderContainer>
        <Text
          bold
          style={{ minWidth: "fit-content" }}
          variant={TextStyleVariant.P2}
        >
          Track title:&nbsp;
        </Text>
        {!project && isLoading ? (
          <div style={{ height: 14 }}>
            <Skeleton height={14} width={100} />
          </div>
        ) : (
          <ProjectListRow
            allowEdit={userIsCollaborator}
            hideAlts
            project={project ?? undefined}
          />
        )}
        {!hideTrackButtons && (
          <ProjectPanelTrackButtonContainer>
            <ProjectPanelTrackButton
              disabled={currentIndex === 0}
              onClick={() => handleSelectTrack(currentIndex - 1)}
              variant={ButtonVariant.UNSTYLED}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              {!isMobile && "Previous"}
            </ProjectPanelTrackButton>
            <Text color={TextColor.SECONDARY} variant={TextStyleVariant.P3}>
              {currentIndex + 1} / {validProjectIds.length}
            </Text>
            <ProjectPanelTrackButton
              disabled={currentIndex === validProjectIds.length - 1}
              onClick={() => handleSelectTrack(currentIndex + 1)}
              variant={ButtonVariant.UNSTYLED}
            >
              {!isMobile && "Next"}
              <FontAwesomeIcon icon={faAngleRight} />
            </ProjectPanelTrackButton>
          </ProjectPanelTrackButtonContainer>
        )}
      </ProjectPanelTrackHeaderContainer>
      <ProjectPanelSeparator />
    </div>
  );
};
