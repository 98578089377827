import { useMemo } from "react";
import { RecordingSession } from "../../store/models/recordingSession";

export const useSessionHasPendingModificationRequest = (
  recordingSession: RecordingSession | null,
) => {
  return useMemo(() => {
    if (!recordingSession) return false;
    if (!recordingSession.session_modification) return false;
    const pendingAcceptances =
      recordingSession.session_modification.required_acceptances.filter(
        (acceptance) =>
          acceptance.accepted === null && acceptance.deleted === null,
      ).length;
    return pendingAcceptances > 0;
  }, [recordingSession]);
};
