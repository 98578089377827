import { ProjectType } from "../../../store/models/project";
import Service from "../../../store/models/service";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch";
import "../ManageEngineeringService/ManageEngineeringService.css";
import {
  ServiceFormInputContainer,
  ServiceFormLabel,
  ServiceFormLabelContainer,
  ServiceFormLabelSubtext,
  ServiceFormRow,
} from "./ManageEngineeringService.styles";

interface EngineeringServiceDownloadPreferenceProps {
  pendingService: Service;
  onUpdate: (_: boolean) => void;
}

const EngineeringServiceBulkSongDiscountPreference = ({
  pendingService,
  onUpdate,
}: EngineeringServiceDownloadPreferenceProps) => {
  if (pendingService.service_type === ProjectType.RECORDING) {
    return null;
  }

  return (
    <>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel htmlFor="enabled-bulk-song-discount-toggle">
            Enable bulk discounts
          </ServiceFormLabel>
          <ServiceFormLabelSubtext>
            (15% off for 6 songs and 30% off for 12 songs)
          </ServiceFormLabelSubtext>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <ToggleSwitch
            id="enabled-bulk-song-discount-toggle"
            checked={pendingService.enable_bulk_song_discounts}
            onChange={(checked: boolean) => {
              onUpdate(checked);
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
    </>
  );
};

export default EngineeringServiceBulkSongDiscountPreference;
