import { useMemo } from "react";

// TODO: Link to launchdarkly
export const useMaintenanceMode = () => {
  const isMaintenanceMode = useMemo(() => {
    // TODO: Link to launchdarkly
    return false;
  }, []);
  return isMaintenanceMode;
};
