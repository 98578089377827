import { useState, useEffect } from "react";
import { getUserLocationSupportStatus } from "../store/actions/accountInfo";
import { useAppDispatch } from "../store/hooks";

export const useGeolocationCountryCode = () => {
  const dispatch = useAppDispatch();
  const [countryCode, setCountryCode] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (navigator.permissions) {
      void navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                void dispatch(
                  getUserLocationSupportStatus({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  }),
                )
                  .unwrap()
                  .then((data) => {
                    setCountryCode(data.country_code);
                  })
                  .catch(() => {
                    setCountryCode(undefined);
                    setLoading(false);
                  });
              },
              (error) => {
                console.error(error);
                void dispatch(getUserLocationSupportStatus({}))
                  .unwrap()
                  .then((data) => {
                    setCountryCode(data.country_code);
                  })
                  .catch(() => {
                    setCountryCode(undefined);
                    setLoading(false);
                  });
              },
            );
          } else {
            void dispatch(getUserLocationSupportStatus({}))
              .unwrap()
              .then((data) => {
                setCountryCode(data.country_code);
              })
              .catch(() => {
                setCountryCode(undefined);
                setLoading(false);
              });
          }
        })
        .catch((error) => {
          console.error(error);
          void dispatch(getUserLocationSupportStatus({}))
            .unwrap()
            .then((data) => {
              setCountryCode(data.country_code);
            })
            .catch(() => {
              setCountryCode(undefined);
              setLoading(false);
            });
        });
    } else {
      void dispatch(getUserLocationSupportStatus({}))
        .unwrap()
        .then((data) => {
          setCountryCode(data.country_code);
        })
        .catch(() => {
          setCountryCode(undefined);
          setLoading(false);
        });
    }
    setLoading(false);
  }, [dispatch]);

  return { countryCode, loading };
};

export const useGeolocationGoogleAppEngine = () => {
  const dispatch = useAppDispatch();
  const [countryCode, setCountryCode] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    void dispatch(getUserLocationSupportStatus({}))
      .unwrap()
      .then((data) => {
        setCountryCode(data.country_code);
      })
      .catch(() => {
        setCountryCode(undefined);
        setLoading(false);
      });

    setLoading(false);
  }, [dispatch]);

  return { countryCode, loading };
};
