import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MIXING_ALPHA_PROJECTS } from "../utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { receiveErrors } from "./errorStore";
import { APIMixingProject, Project, transformRawData } from "../models/project";

export interface AlphaProjects {
  [page: number]: Project[];
}

export interface PaginationDetails {
  currentPage: number;
  totalPages: number;
  count: number;
}

export interface PaginatedAlphaProjects {
  active: AlphaProjects;
  activePaginationDetails: PaginationDetails;
  loading: boolean;
}

export const defaultPaginationDetails: PaginationDetails = {
  currentPage: 1,
  totalPages: 0,
  count: 0,
};

const initialState: PaginatedAlphaProjects = {
  active: [],
  activePaginationDetails: defaultPaginationDetails,
  loading: false,
};

interface AlphaMixingProjectsResponse {
  data: APIMixingProject[];
  num_pages: number;
  page: number;
  count: number;
}

export const getAlphaMixingProjects = createAsyncThunk(
  MIXING_ALPHA_PROJECTS,
  async (args: { page: number }, thunkAPI) => {
    const { page } = args;
    const params = `?mixing_page=${page}`;
    const result =
      await makeBackendGetCallWithJsonResponse<AlphaMixingProjectsResponse>(
        MIXING_ALPHA_PROJECTS,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

const alphaMixingProjectsSlice = createSlice({
  name: "alphaMixingProjects",
  initialState,
  reducers: {
    clearAlphaMixing: (state) => {
      state.active = initialState.active;
      state.activePaginationDetails = initialState.activePaginationDetails;
      state.loading = false;
    },
    setAlphaMixingPage: (state, action: PayloadAction<number>) => {
      state.activePaginationDetails.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAlphaMixingProjects.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAlphaMixingProjects.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAlphaMixingProjects.fulfilled, (state, action) => {
      const { count, data, num_pages, page } = action.payload;
      state.active[page] = data.map(transformRawData);
      state.activePaginationDetails = {
        currentPage: page,
        totalPages: num_pages,
        count: count,
      };
      state.loading = false;
    });
  },
});

export const { clearAlphaMixing, setAlphaMixingPage } =
  alphaMixingProjectsSlice.actions;

export default alphaMixingProjectsSlice.reducer;
