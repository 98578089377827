import { useSortable } from "@dnd-kit/sortable";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import useModal from "../../../hooks/useModal";
import { ReleaseTrack } from "../../../store/models/release";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { getReleaseScreenRoute } from "../../../store/utils/routeGetters";
import { shareOrCopyUrl } from "../../../utils/utils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Select } from "../../core-ui/components/Select/Select";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import EditReleaseTrackInput from "../EditReleaseTrackInput/EditReleaseTrackInput";
import { FeedbackModal } from "../FeedbackModal/FeedbackModal";
import { RowDraggerButton } from "../ProjectOverviewTable/NewProjectsTable.styles";
import { ReleaseTrackPlayPauseButton } from "../ReleasePlayPauseButton/ReleasePlayPauseButton";
import { DropdownMenuItem } from "../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";

const RowDragHandleCell = ({ rowId }: { rowId: number }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    <RowDraggerButton {...attributes} {...listeners}>
      <FontAwesomeIcon icon={faArrowUpArrowDown} size="sm" />
    </RowDraggerButton>
  );
};

interface ExtendedCellContext<TData, TValue>
  extends CellContext<TData, TValue> {
  unitPrice?: string | null;
}

export const useColumnDefinitionForReleaseTracks = (
  isArtistOnRelease: boolean,
): ColumnDef<ReleaseTrack>[] => {
  const {
    isOpen: isFeedbackModalOpen,
    openModal: openFeedbackModal,
    closeModal: closeFeedbackModal,
  } = useModal();

  return useMemo(() => {
    return [
      {
        id: "reorder-tab",
        accessorFn: (row: { id: number }) => row.id,
        header: () => null,
        cell: ({ row: { original } }: { row: { original: ReleaseTrack } }) => {
          return <RowDragHandleCell rowId={original.id} />;
        },
      },
      {
        id: "title",
        header: () => (
          <Text
            style={{
              minWidth: "150px",
              textAlign: "left",
            }}
            bold
            variant={TextStyleVariant.P2}
          >
            Track Title
          </Text>
        ),
        accessorFn: (row: ReleaseTrack) => row.title,
        cell: (args: { row: { original: ReleaseTrack } }) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                minWidth: "150px",
                alignItems: "center",
              }}
            >
              <Text>
                {(args.row.original.track_number + 1)
                  .toString()
                  .padStart(2, "0")}
              </Text>
              {isArtistOnRelease ? (
                <EditReleaseTrackInput
                  releaseTrackId={args.row.original.id}
                  title={args.row.original.title}
                />
              ) : (
                <Text
                  style={{
                    maxWidth: "150px",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {args.row.original.title}
                </Text>
              )}
            </Box>
          );
        },
      },
      {
        id: "play_section",
        header: () => (
          <div
            style={{
              width: "100%",
            }}
          />
        ),
        cell: ({ row: { original } }: { row: { original: ReleaseTrack } }) => (
          <ReleaseTrackPlayPauseButton
            size={32}
            releaseId={original.release.id}
            trackId={original.id}
          />
        ),
      },
      {
        id: "price",
        header: () => (
          <Text
            style={{
              width: "60px",
            }}
            bold
            variant={TextStyleVariant.P2}
          >
            Price
          </Text>
        ),
        cell: (args: ExtendedCellContext<ReleaseTrack, any>) => {
          const unitPrice = args.unitPrice;
          if (!unitPrice) return null;
          return (
            <Text>{PennyDollarFormatter().format(Number(unitPrice))}</Text>
          );
        },
      },
      // Temporarily hide play count
      // {
      //   id: "play_count",
      //   header: () => (
      //     <Text bold variant={TextStyleVariant.P2}>
      //       Plays
      //     </Text>
      //   ),
      //   accessorFn: (row: ReleaseTrack) => row.play_count,
      //   cell: (args: { row: { original: { play_count: number } } }) => {
      //     return (
      //       <Box
      //         sx={{
      //           display: "flex",
      //           flexDirection: "row",
      //           alignItems: "center",
      //           gap: "16px",
      //         }}
      //       >
      //         <Text>
      //           {args.row.original.play_count < 10
      //             ? args.row.original.play_count.toString().padStart(2, "0")
      //             : millify(args.row.original.play_count)}
      //         </Text>
      //         <Button variant={ButtonVariant.UNSTYLED}>
      //           <FontAwesomeIcon icon={faEllipsisV} />
      //         </Button>
      //       </Box>
      //     );
      //   },
      // },

      {
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }: { row: { original: ReleaseTrack } }) => {
          return (
            <>
              <Select
                triggerButton={
                  <Button
                    variant={ButtonVariant.ICON}
                    style={{ padding: "12px 16px" }}
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </Button>
                }
              >
                <DropdownMenuItem
                  onSelect={() => {
                    const path = getReleaseScreenRoute(original.release.id);
                    void shareOrCopyUrl(path);
                  }}
                >
                  Share
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => {
                    openFeedbackModal();
                  }}
                >
                  Report
                </DropdownMenuItem>
              </Select>
              {isFeedbackModalOpen && (
                <FeedbackModal onClose={closeFeedbackModal} />
              )}
            </>
          );
        },
        size: 76,
      },
    ];
  }, [
    closeFeedbackModal,
    isArtistOnRelease,
    isFeedbackModalOpen,
    openFeedbackModal,
  ]);
};
