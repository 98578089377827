import { useMemo } from "react";
import Engineer from "../store/models/engineer";
import { RecordingSession } from "../store/models/recordingSession";
import { BasicStudioRoom, StudioRoom } from "../store/models/studio";
import {
  convertUTCDateToLocalDate,
  getFormattedTimeStringWithoutDateFromLocalDate,
} from "../store/utils/dateTimeUtils";
import { getRecordingSessionLocalTimeUsingTimezone } from "./studioHooks";

export interface UseGetTotalRecordingSessionsHoursProps {
  session_duration_minutes: number;
  engineer?: Engineer;
  studio_room?: BasicStudioRoom | StudioRoom;
}

export const useGetTotalRecordingSessionsHours = (
  recordingSessions: UseGetTotalRecordingSessionsHoursProps[],
): { studioDuration: number; engineerDuration: number } => {
  return useMemo(() => {
    return recordingSessions.reduce(
      (acc, session) => {
        const durationHours = session.session_duration_minutes / 60;
        let studioDuration = 0;
        if (session.studio_room) {
          studioDuration = durationHours;
        }
        let engineerDuration = 0;
        if (session.engineer) {
          engineerDuration = durationHours;
        }
        return {
          studioDuration: acc.studioDuration + studioDuration,
          engineerDuration: acc.engineerDuration + engineerDuration,
        };
      },
      { studioDuration: 0, engineerDuration: 0 },
    );
  }, [recordingSessions]);
};

export const useGetStartAndEndDatesFromRecordingSessions = (
  recordingSessions: RecordingSession[],
) => {
  return useMemo(() => {
    const startDates = recordingSessions.map(
      (session) => new Date(session.first_choice_datetime),
    );
    const endDates = recordingSessions.map((session) => {
      const endDate = new Date(session.first_choice_datetime);
      endDate.setMinutes(
        endDate.getMinutes() + session.session_duration_minutes,
      );
      return endDate;
    });
    return { startDates, endDates };
  }, [recordingSessions]);
};

export interface UseGetEarliestStartDateAndLatestEndDateFromRecordingSessionsProps {
  first_choice_datetime: string;
  session_duration_minutes: number;
}

export const useGetEarliestStartDateAndLatestEndDateFromRecordingSessions = (
  recordingSessions: UseGetEarliestStartDateAndLatestEndDateFromRecordingSessionsProps[],
): { earliestStartDate: Date; latestEndDate: Date } => {
  return useMemo(() => {
    const startDates = recordingSessions.map(
      (session) => new Date(session.first_choice_datetime),
    );
    const endDates = recordingSessions.map((session) => {
      const endDate = new Date(session.first_choice_datetime);
      endDate.setMinutes(
        endDate.getMinutes() + session.session_duration_minutes,
      );
      return endDate;
    });
    return {
      earliestStartDate: new Date(
        Math.min.apply(
          null,
          startDates.map((date) => date.getTime()),
        ),
      ),
      latestEndDate: new Date(
        Math.max.apply(
          null,
          endDates.map((date) => date.getTime()),
        ),
      ),
    };
  }, [recordingSessions]);
};

interface UseGetRecordingSessionTimeDataProps {
  first_choice_datetime: string;
  studio_room?: BasicStudioRoom;
  session_duration_minutes: number;
}

export const useGetRecordingSessionTimeData = (
  recordingSession: UseGetRecordingSessionTimeDataProps,
) => {
  return useMemo(() => {
    const { first_choice_datetime, session_duration_minutes } =
      recordingSession;
    const recordingDate = convertUTCDateToLocalDate(
      new Date(first_choice_datetime),
    );

    const startTimeDateObj =
      getRecordingSessionLocalTimeUsingTimezone(recordingSession);
    const startTime =
      getFormattedTimeStringWithoutDateFromLocalDate(startTimeDateObj);

    // Increase the duration to get the end time
    startTimeDateObj.setMinutes(
      startTimeDateObj.getMinutes() + session_duration_minutes,
    );
    const endTime =
      getFormattedTimeStringWithoutDateFromLocalDate(startTimeDateObj);

    const sessionDurationHours =
      Math.round((session_duration_minutes / 60) * 2) / 2;

    return { recordingDate, startTime, endTime, sessionDurationHours };
  }, [recordingSession]);
};
