import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import "./TransactionBookingScreen.css";
import { Header } from "./TransactionBookingScreen.styles";
import TransactionBookingServiceDropdowns from "./TransactionBookingServiceDropdowns";

import { useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";

const TransactionBookingHeader = () => {
  const history = useHistory();

  const { key: canGoBack } = useLocation();
  const cssPrefix = "transaction-booking-screen_";

  const { activeServiceType, linkShareStatus, activeTransactionId } =
    useAppSelector((state) => state.generateBookingStore);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);

  const isRecordingServiceTypeActive =
    activeServiceType === ProjectType.RECORDING;

  const onClickBack = () => {
    emitAnalyticsTrackingEvent(
      "booking_flow-click_x",
      {
        transactionId: activeTransactionId,
      },
      loggedInUser?.id,
    );
    history.goBack();
  };

  return (
    <>
      <Header $isServiceDropdownVisible={isRecordingServiceTypeActive}>
        <Text
          className={`${cssPrefix}header-text`}
          variant={TextStyleVariant.H4}
        >
          Create your booking
        </Text>
        {canGoBack && (
          <Button
            variant={ButtonVariant.UNSTYLED}
            className={`${cssPrefix}header-x`}
            onClick={onClickBack}
          >
            <FontAwesomeIcon icon={faXmark} width={16} height={16} />
          </Button>
        )}
      </Header>
      {isRecordingServiceTypeActive && (
        <div
          className={classNames({
            "transaction-booking-screen_header-disabled":
              Boolean(linkShareStatus),
          })}
        >
          <TransactionBookingServiceDropdowns />
        </div>
      )}
    </>
  );
};

export default TransactionBookingHeader;
