import { useMemo } from "react";
import User, { UserLite } from "../store/models/user";
import { imagePathPrefix } from "../store/utils";
import defaultUser from "../stories/assets/defaultuser.jpg";

export const useUserProfileImageURL = (
  user?: User | UserLite | null,
): string => {
  return useMemo(() => {
    if (!user) return defaultUser;
    if (!user.photo) return defaultUser;
    if (!user.photo.path) return defaultUser;
    return imagePathPrefix + user.photo?.path;
  }, [user]);
};
