import { useAtom } from "jotai";
import { useMemo } from "react";
import { searchTermAtom } from "../../atoms/searchQueryAtom";
import useAutocompleteSuggestions from "../autocompleteHooks/useAutocompleteSuggestions";
import { useDebounce } from "ahooks";

export interface UseSearchParams {
  enabled?: boolean;
  term?: string;
  mapView?: boolean;
  debounceInMs?: number;
}

const useSearch = ({
  enabled = true,
  mapView = false,
  debounceInMs = 600,
}: UseSearchParams) => {
  const [searchTerm, setSearchTerm] = useAtom(searchTermAtom);
  const debouncedSearchTerm = useDebounce(searchTerm, { wait: debounceInMs });
  const { isFetching: fetchingAutocomplete, data: dataAutocomplete } =
    useAutocompleteSuggestions({
      term: debouncedSearchTerm,
      enabled,
      mapView,
    });

  const autocompleteSuggestions = useMemo(() => {
    return dataAutocomplete || [];
  }, [dataAutocomplete]);

  const isFetchingAutocomplete = useMemo(() => {
    return fetchingAutocomplete;
  }, [fetchingAutocomplete]);
  return useMemo(
    () => ({
      autocompleteSuggestions,
      isFetchingAutocomplete,
      debouncedSearchTerm,
      searchTerm: searchTerm || "",
      setSearchTerm,
    }),
    [
      autocompleteSuggestions,
      isFetchingAutocomplete,
      debouncedSearchTerm,
      searchTerm,
      setSearchTerm,
    ],
  );
};

export default useSearch;
