import styled from "styled-components";

export const CalendarWeekViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const CalendarWeekViewRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;

export const CalendarWeekViewHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
