import { PortfolioFeatureData } from "../store/models/portfolio";
import { projectTypeReadableName } from "../store/models/project";

export const useGetRoleOnFeaturedTrack = (feature: PortfolioFeatureData) => {
  const { muso_credit, track_details } = feature;
  if (muso_credit?.role) {
    return muso_credit.role;
  }
  if (track_details?.project) {
    return (
      projectTypeReadableName.get(track_details.project.service_type) ?? ""
    );
  }

  return "";
};
