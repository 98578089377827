import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { fetchScheduledProjectOverview } from "../../store/actions/scheduledprojects";
import { useAppDispatch } from "../../store/hooks";

interface FetchScheduledProjectOverviewParams {
  scheduledProjectId: number | null;
}

/**
 * Used to fetch the scheduled project overview data used in the Booking Details section
 * of the BookingRequestedPanel (for Mix/Master projects)
 */
export const useFetchScheduledProjectOverview = ({
  scheduledProjectId,
}: FetchScheduledProjectOverviewParams) => {
  const dispatch = useAppDispatch();
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_SCHEDULED_PROJECT_OVERVIEW, scheduledProjectId],
    queryFn: async () => {
      if (!scheduledProjectId) return null;
      return dispatch(
        fetchScheduledProjectOverview({
          scheduled_project_id: scheduledProjectId,
        }),
      ).unwrap();
    },
    enabled: Boolean(scheduledProjectId),
  });
};
