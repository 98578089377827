import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "../../../hooks/useQuery";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Alt, ProjectTypeToDefaultAltsMap } from "../../../store/models/alts";
import Service, { ServiceRate } from "../../../store/models/service";
import User from "../../../store/models/user";
import { getFormattedDate } from "../../../store/utils/dateTimeUtils";
import {
  BaseModal,
  defaultModalContent,
} from "../../components/BaseModal/BaseModal";
import { FirstPassDate } from "../../components/ProjectBookingCalendar/ProjectBookingCalendar";
import { AtmosBookingScreen } from "../AtmosBookingScreen/AtmosBookingScreen";
import {
  ProjectCheckoutScreen,
  ProjectCheckoutScreenProps,
} from "../ProjectCheckoutScreen/ProjectCheckoutScreen";
import {
  SelectPackageScreen,
  SelectPackageScreenProps,
} from "../SelectPackageScreen/SelectPackageScreen";
import { StripePaymentScreen } from "../StripePaymentScreen/StripePaymentScreen";
import {
  TitleSongsScreen,
  TitleSongsScreenProps,
} from "../TitleSongsScreen/TitleSongsScreen";
import "./CheckoutModalScreen.css";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import { ProjectHandoffProps } from "../../components/ProjectHandoff/ProjectHandoff";
import { ProjectType } from "../../../store/models/project";
import {
  bookingTransactionParams,
  createTransaction,
  ProjectTransactionData,
} from "../../../store/actions/transactions";

import { FinancialMethod } from "../../../store/models/transaction";
import { useIsAandR } from "../../../hooks/useIsAandR";
import { useMixingMasteringAvailabilities } from "../../../hooks/bookingHooks/useMixingMasteringAvailabilities";
import { getApplicableDiscountBookingLinkData } from "../../../store/selectors/bookingSelectors";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

export interface CheckoutModalScreenProps extends ProjectHandoffProps {
  engineerUser: User;
  service: Service;
  closeModal: () => void;
  renderView?: boolean;
  onStepChange?: (step: CheckoutModalSteps) => void;
}

export enum CheckoutModalSteps {
  SELECT_PACKAGE,
  TITLE_SONGS,
  CHECKOUT,
  STRIPE_PAYMENT,
  ATMOS_BOOKING,
}

export const CheckoutModalScreen = ({
  engineerUser,
  service,
  closeModal,
  renderView = false,
  scheduledProjectMetaData,
  projectToHandoff,
  onStepChange,
}: CheckoutModalScreenProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const aandrUser = useIsAandR(user);
  const originalRate = service.service_rate?.price ?? 0;
  const labelRate = service.service_rate?.label_price ?? originalRate;
  const [competitionSubmission, setCompetitionSubmission] = useState(false);
  const [addMastering, setAddMastering] = useState(false);
  const [addAtmosMixing, setAddAtmosMixing] = useState<boolean>(false);
  const isAtmosProjectAndNotPredefined = useMemo(() => {
    return service.service_type === ProjectType.ATMOS_MIXING;
  }, [service]);
  const promoCode = useAppSelector(
    (state) => state.marketingDataStore.appliedPromoCode,
  );
  const [checkoutStep, setCheckoutStep] = useState<CheckoutModalSteps>(
    isAtmosProjectAndNotPredefined
      ? CheckoutModalSteps.ATMOS_BOOKING
      : CheckoutModalSteps.SELECT_PACKAGE,
  );
  const { availabilities } = useMixingMasteringAvailabilities(service);
  const [numberOfSongs, setNumberOfSongs] = useState(1);
  const queryLocation = useQuery();
  const [songsList, setSongsList] = useState<string[]>([]);
  const [projectTitle, setProjectTitle] = useState<string>(
    scheduledProjectMetaData ? scheduledProjectMetaData.title : "",
  );
  const [artistName, setArtistName] = useState<string>(
    scheduledProjectMetaData ? scheduledProjectMetaData.artist_name ?? "" : "",
  );

  const [initialSongTitle] = useState<string>(
    projectToHandoff ? `${projectToHandoff.title} (Master)` : "",
  );
  const [discountCode, setDiscountCode] = useState<string>("");
  const [discountedPrice, setDiscountedPrice] = useState<number>();
  const [currentService, setCurrentService] = useState<Service>(service);
  const useLabelRate = Boolean(aandrUser);

  const defaultAlts = useMemo(() => {
    if (projectToHandoff) {
      const projectEnumList = projectToHandoff.alts?.reduce((acc, curr) => {
        if (curr) {
          const { alt } = curr;
          return [...acc, alt];
        }
        return acc;
      }, [] as Alt[]);
      return new Set(projectEnumList);
    }
    return new Set<Alt>(ProjectTypeToDefaultAltsMap.get(service.service_type));
  }, [projectToHandoff, service]);
  const [alts, setAlts] = useState(defaultAlts);

  const { scheduledProject } = useAppSelector((state) => state.bookingStore);
  const { transactionData } = useAppSelector((state) => state.transactionStore);
  const localUTMParams = useAppSelector(
    (state) => state.accountInfo.localUTMParams,
  );
  const [songDatesMap, setSongDatesMap] = useState(
    new Map<string, FirstPassDate>(),
  );
  const darkMode = useAtomValue(darkModeAtom);
  const scheduledProjectIdForHandoff: number | undefined = useMemo(() => {
    if (!projectToHandoff) return undefined;
    if (!scheduledProjectMetaData) return undefined;
    return scheduledProjectMetaData.id;
  }, [projectToHandoff, scheduledProjectMetaData]);

  useEffect(() => {
    const code = queryLocation.get("code");
    if (code) {
      setDiscountCode(code);
      setCheckoutStep(CheckoutModalSteps.SELECT_PACKAGE);
    }
  }, [queryLocation, discountCode]);

  useEffect(() => {
    setSongsList(Array.from({ length: numberOfSongs }, () => ""));
  }, [numberOfSongs]);

  const discountedBookingLinkData = useAppSelector(
    getApplicableDiscountBookingLinkData(service.id),
  );

  useMemo(() => {
    if (discountedBookingLinkData) {
      setDiscountedPrice(discountedBookingLinkData.discounted_price);
    }
  }, [discountedBookingLinkData]);

  const currentUser = useAppSelector((state) => state.accountInfo.user);
  const isAandR = useIsAandR(currentUser);

  useEffect(() => {
    setCurrentService((currService) => {
      const userIsAandRAndLabelPrice =
        useLabelRate ||
        Boolean(isAandR && currService.service_rate?.label_price);

      if (currService.service_rate?.price === discountedPrice)
        return currService;
      if (!discountedPrice) {
        // No discount applied.
        // Conditionally set rate.
        const service_rate: ServiceRate = {
          ...currService.service_rate,
          price: Number(userIsAandRAndLabelPrice ? labelRate : originalRate),
        };
        return {
          ...currService,
          service_rate,
        };
      }
      const service_rate: ServiceRate = {
        ...currService.service_rate,
        price: discountedPrice,
      };
      return {
        ...currService,
        service_rate,
      };
    });
  }, [
    discountedPrice,
    currentUser,
    useLabelRate,
    originalRate,
    labelRate,
    isAandR,
  ]);

  const onClickFinishPayment = useCallback(
    (songDatesMap: Map<string, FirstPassDate>, newPromoCode?: string) => {
      setSongDatesMap(songDatesMap);
      const bookingProjectParams: bookingTransactionParams = {
        title: projectTitle,
        artist_name: artistName,
        project_data: [],
        engineer_has_files: false,
        promocode: promoCode ? promoCode.code : newPromoCode ?? "",
        financial_method: FinancialMethod.STRIPE, // Default
      };
      songsList.forEach((song) => {
        const bookingProjectData: ProjectTransactionData = {
          service_id: currentService.id!,
          service_type: currentService.service_type,
          service_provider_user_id: engineerUser.id,
          title: song,
          artist_name: artistName,
          code: discountCode ? discountCode : undefined,
          requested_date: getFormattedDate(songDatesMap.get(song)!.date),
          alts: [...alts],
          prereq_id: projectToHandoff ? projectToHandoff.id : undefined,
          promocode: promoCode?.code
            ? promoCode?.code
            : newPromoCode
              ? newPromoCode
              : undefined,
          add_master: addMastering,
          add_atmos: addAtmosMixing,
        };
        bookingProjectParams.project_data!.push(bookingProjectData);
      });
      void dispatch(
        createTransaction({
          ...bookingProjectParams,
          engineer_has_files: projectToHandoff
            ? true
            : bookingProjectParams.engineer_has_files,
        }),
      )
        .unwrap()
        .then((data) => {
          window.analytics.track(
            getDebugEventPrefix +
              (projectToHandoff
                ? "book_mix_to_master_handoff_project"
                : "book_scheduled_project"),
            {
              user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
              scheduled_project_id: `${data.id}`,
              ...localUTMParams,
            },
          );
        })
        .catch(() => {
          setCheckoutStep(CheckoutModalSteps.CHECKOUT);
        });
      setCheckoutStep(CheckoutModalSteps.STRIPE_PAYMENT);
    },
    [
      alts,
      currentService.id,
      currentService.service_type,
      discountCode,
      dispatch,
      engineerUser.id,
      projectTitle,
      songsList,
      addMastering,
      addAtmosMixing,
      projectToHandoff,
      scheduledProjectMetaData,
      promoCode,
    ],
  );

  const toggleCompetitionSubmission = useCallback(() => {
    setCompetitionSubmission(!competitionSubmission);
  }, [competitionSubmission]);

  const toggleAddMastering = useCallback(() => {
    setAddMastering(!addMastering);
  }, [addMastering]);

  const toggleAddAtmosMixing = useCallback(() => {
    setAddAtmosMixing(!addAtmosMixing);
  }, [addAtmosMixing]);

  const selectPackageScreenProps: SelectPackageScreenProps = useMemo(
    () => ({
      engineerUser: engineerUser,
      service: currentService,
      alts: alts,
      setAlts: setAlts,
      numberOfSongs: numberOfSongs,
      setNumberOfSongs: setNumberOfSongs,
      useLabelPrice: useLabelRate,
      competitionSubmission: competitionSubmission,
      toggleCompetitionSubmission: toggleCompetitionSubmission,
      addMastering: addMastering,
      toggleAddMastering: toggleAddMastering,
      addAtmosMixing: addAtmosMixing,
      toggleAddAtmosMixing: toggleAddAtmosMixing,
      onClickNextStep: () => {
        window.analytics.track(getDebugEventPrefix + "clicked_title_songs", {
          user_id: `${getDebugEventUserIdPrefix}${currentUser?.id}`,
          ...localUTMParams,
        });
        setCheckoutStep(CheckoutModalSteps.TITLE_SONGS);
      },
      projectToHandoff,
      ...(service.service_type === 5 && {
        onClickPreviousStep: () =>
          setCheckoutStep(CheckoutModalSteps.ATMOS_BOOKING),
      }),
    }),
    [
      alts,
      currentService,
      engineerUser,
      numberOfSongs,
      useLabelRate,
      competitionSubmission,
      addMastering,
      addAtmosMixing,
      service.service_type,
      projectToHandoff,
    ],
  );

  const titleSongsScreenProps: TitleSongsScreenProps = useMemo(
    () => ({
      songTitle: initialSongTitle,
      engineerUser: engineerUser,
      service: currentService,
      alts: alts,
      numberOfSongs: numberOfSongs,
      songsList: songsList,
      setSongsList: setSongsList,
      projectTitle: projectTitle,
      artistName: artistName,
      usingLabelPrice: useLabelRate,
      setProjectTitle: setProjectTitle,
      setArtistName: setArtistName,
      onClickNextStep: () => {
        window.analytics.track(getDebugEventPrefix + "clicked_choose_dates", {
          user_id: `${getDebugEventUserIdPrefix}${currentUser?.id}`,
          ...localUTMParams,
        });
        setCheckoutStep(CheckoutModalSteps.CHECKOUT);
      },
      onClickPreviousStep: () =>
        setCheckoutStep(CheckoutModalSteps.SELECT_PACKAGE),
      scheduledProjectMetaData: scheduledProjectMetaData,
    }),
    [
      alts,
      currentService,
      engineerUser,
      numberOfSongs,
      projectTitle,
      artistName,
      useLabelRate,
      songsList,
      projectToHandoff,
    ],
  );

  const projectCheckoutScreenProps: ProjectCheckoutScreenProps = useMemo(
    () => ({
      engineerUser: engineerUser,
      service: currentService,
      projectTitle: projectTitle,
      songList: songsList,
      altsList: [...alts],
      availabilities: availabilities,
      onClickFinishPayment: onClickFinishPayment,
      onClickPreviousStep: () =>
        setCheckoutStep(CheckoutModalSteps.TITLE_SONGS),
      useLabelRate: useLabelRate,
    }),
    [
      alts,
      availabilities,
      currentService,
      onClickFinishPayment,
      projectTitle,
      songsList,
      engineerUser,
      useLabelRate,
    ],
  );

  const stripePaymentScreenProps = useMemo(
    () => ({
      scheduledProjectIdForHandoff,
      projectTitle: projectTitle,
      artistName: artistName,
      engineerUser: engineerUser,
      scheduledProject: scheduledProject,
      transactionData: transactionData,
      totalPrice: 1000,
      alts: alts,
      service: service,
      onClickPreviousStep: () => setCheckoutStep(CheckoutModalSteps.CHECKOUT),
      showPromoField: !(discountCode || isAandR),
      onClickApplyPromoCode: (code?: string) => {
        onClickFinishPayment(songDatesMap, code);
      },
    }),
    [
      onClickFinishPayment,
      songDatesMap,
      engineerUser,
      scheduledProject,
      alts,
      service,
      discountCode,
      projectTitle,
      artistName,
      promoCode,
      transactionData,
      isAandR,
      scheduledProjectIdForHandoff,
    ],
  );

  const AtmosBookingScreenProps = useMemo(
    () => ({
      onClickNextStep: () => setCheckoutStep(CheckoutModalSteps.SELECT_PACKAGE),
    }),
    [],
  );

  useEffect(() => {
    if (onStepChange) {
      onStepChange(checkoutStep);
    }
  }, [checkoutStep]);

  const CurrentView = useMemo(() => {
    switch (checkoutStep) {
      case CheckoutModalSteps.SELECT_PACKAGE:
        return <SelectPackageScreen {...selectPackageScreenProps} />;
      case CheckoutModalSteps.TITLE_SONGS:
        return <TitleSongsScreen {...titleSongsScreenProps} />;
      case CheckoutModalSteps.CHECKOUT:
        return <ProjectCheckoutScreen {...projectCheckoutScreenProps} />;
      case CheckoutModalSteps.STRIPE_PAYMENT:
        return <StripePaymentScreen {...stripePaymentScreenProps} />;
      case CheckoutModalSteps.ATMOS_BOOKING:
        return <AtmosBookingScreen {...AtmosBookingScreenProps} />;
      default:
        return null;
    }
  }, [
    checkoutStep,
    selectPackageScreenProps,
    titleSongsScreenProps,
    stripePaymentScreenProps,
    projectCheckoutScreenProps,
    AtmosBookingScreenProps,
  ]);
  if (renderView) {
    return CurrentView;
  }

  return (
    <BaseModal
      content={{
        ...defaultModalContent,
        backgroundColor: darkMode ? "black" : "white",
        borderRadius: 15,
        padding: 0,
        width: "90%",
        maxWidth: 1100,
        maxHeight: 600,
        overflowY: "scroll",
      }}
      modalIsOpen={true}
      closeModal={closeModal}
      label={"Service Modal"}
    >
      {CurrentView}
    </BaseModal>
  );
};
