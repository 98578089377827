import { useKeyPress } from "ahooks";
import { atom, useSetAtom } from "jotai";
import { toggleDarkModeAtom } from "../../../../atoms/user/darkModeAtom";
import { powerUserControlsHelpModalAtom } from "../../../../atoms/modals/powerUserControlsHelpModalAtom";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import {
  faCommand,
  faD,
  faDiamond,
  faSlashForward,
} from "@fortawesome/pro-solid-svg-icons";
import { useCallback, useEffect } from "react";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { datadogLogs } from "@datadog/browser-logs";

// List of keys https://github.com/alibaba/hooks/blob/master/packages/hooks/src/useKeyPress/index.ts#L21
export enum POWER_USER_CONTROLS {
  META = "meta",
  DARK_MODE = "d",
  HELP = "forwardslash",
}

export enum POWER_USER_CONTROLS_LABEL {
  DARK_MODE = "Dark Mode",
  HELP = "Help",
}

export enum POWER_USER_CONTROLS_CATEGORY {
  GENERAL = "General",
}

/**
 * Gets the user's platform.
 *
 * This function first tries to get the user's platform from the `userAgentData`
 * API, which is supported in newer browsers. If that fails, it falls back to
 * using the `platform` property from the `navigator` object.
 *
 * @returns The user's platform
 */
export const getUserPlatform = () => {
  // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData/platform
  // @ts-expect-error the userAgentData does exist in new browsers
  return window.navigator.userAgentData?.platform || window.navigator.platform;
};

// Basic list of platforms we support for keyboard controls
export enum PLATFORM {
  WINDOWS = "windows",
  MAC = "mac",
  IOS = "ios",
}

// Link a control key to the font awesome icon that should be used in display around the site
export const PlatformOrKeyToIcon: Record<string, IconDefinition> = {
  [PLATFORM.WINDOWS]: faWindows,
  [PLATFORM.MAC]: faCommand,
  [PLATFORM.IOS]: faCommand,
  [POWER_USER_CONTROLS.META]: faDiamond,
  [POWER_USER_CONTROLS.DARK_MODE]: faD,
  [POWER_USER_CONTROLS.HELP]: faSlashForward,
};

export const PlatformToKey: Record<string, string> = {
  [PLATFORM.WINDOWS]: "Win",
  [PLATFORM.MAC]: "Cmd",
  [PLATFORM.IOS]: "Cmd",
};

export interface PowerUserControlHookData {
  keys: POWER_USER_CONTROLS[];
  label: string;
  description?: string;
  category?: POWER_USER_CONTROLS_CATEGORY;
}

type PowerUserControlKeysData = Record<string, PowerUserControlHookData>;

export const powerControlKeysDataAtom = atom({} as PowerUserControlKeysData);
export const powerControlKeysListAtom = atom([] as PowerUserControlHookData[]);
const setPowerControlKeyData = atom(
  null,
  (
    _get,
    set,
    data: { [key: string]: PowerUserControlHookData | undefined },
  ) => {
    set(powerControlKeysDataAtom, (oldState) => {
      const newState = { ...oldState };
      for (const [key, value] of Object.entries(data)) {
        if (!value) {
          delete newState[key];
        } else {
          newState[key] = {
            // Cast the category to a value if not set to be safe.
            category: POWER_USER_CONTROLS_CATEGORY.GENERAL,
            ...value,
          };
        }
      }
      return newState;
    });
  },
);

export const powerControlKeysDataByCategoryAtom = atom((get) => {
  const powerControlKeysData = get(powerControlKeysDataAtom);
  const returnValues: Partial<
    Record<
      POWER_USER_CONTROLS_CATEGORY,
      {
        key: string;
        value: PowerUserControlHookData;
      }[]
    >
  > = {};

  for (const [key, value] of Object.entries(powerControlKeysData)) {
    if (!value.category) {
      value.category = POWER_USER_CONTROLS_CATEGORY.GENERAL;
    }
    if (!returnValues[value.category]) {
      returnValues[value.category] = [];
    }
    returnValues[value.category]!.push({
      key,
      value,
    });
  }
  return returnValues;
});

/**
 * Sets up a power user control.
 *
 * A power user control is a shortcut key combination that triggers a specific
 * action when pressed.
 *
 * The `usePowerUserControl` hook takes two parameters:
 * - `data`: an object with two properties:
 *   - `keys`: an array of strings representing the keys that trigger the shortcut
 *   - `label`: a string that describes the shortcut
 * - `onPress`: a callback function that is called when the shortcut is pressed
 *   The callback function is called with the `KeyboardEvent` that triggered the
 *   shortcut.
 *
 * The hook will automatically log an analytics event when the shortcut is used,
 * and will call the `onPress` callback function when the shortcut is pressed.
 *
 * @returns The `data` parameter, so that it can be easily returned from a
 *          parent hook.
 */
const usePowerUserControl = (
  key: POWER_USER_CONTROLS_LABEL,
  data: PowerUserControlHookData,
  onPress: (e: KeyboardEvent) => void,
) => {
  const setKeyData = useSetAtom(setPowerControlKeyData);
  useKeyPress(
    data.keys.join("."),
    (e) => {
      emitAnalyticsTrackingEvent("keyboard_shortcut_used", { ...data });
      datadogLogs.logger.debug("keyboard_shortcut_used", { ...data });
      onPress(e);
    },
    { exactMatch: true },
  );
  useEffect(() => {
    setKeyData({ [key]: data });
  }, []);
  return data;
};

/**
 * Sets up the power user control that toggles dark mode.
 *
 * When the user presses the shortcut, the dark mode is toggled.
 *
 * @returns The shortcuts data, which is an object with two properties:
 * - `keys`: an array of strings representing the keys that trigger the shortcut
 * - `label`: a string that describes the shortcut
 */
const useDarkModeControls = () => {
  const keys = [POWER_USER_CONTROLS.META, POWER_USER_CONTROLS.DARK_MODE];
  const label = "Toggle Dark Mode";
  const toggleDarkMode = useSetAtom(toggleDarkModeAtom);
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      toggleDarkMode();
    },
    [toggleDarkMode],
  );
  return usePowerUserControl(
    POWER_USER_CONTROLS_LABEL.DARK_MODE,
    { keys, label },
    handleKeyPress,
  );
};

/**
 * Sets up the power user control that shows/hides the shortcuts modal.
 *
 * When the user presses the shortcut, the modal is toggled.
 *
 * @returns The shortcuts data, which is an object with two properties:
 * - `keys`: an array of strings representing the keys that trigger the shortcut
 * - `label`: a string that describes the shortcut
 */
const useHelpControls = () => {
  const label = "Show/Hide Shortcuts";
  const keys = [POWER_USER_CONTROLS.META, POWER_USER_CONTROLS.HELP];
  const setPowerUserControlsHelpModal = useSetAtom(
    powerUserControlsHelpModalAtom,
  );
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      setPowerUserControlsHelpModal((prev) => !prev);
    },
    [setPowerUserControlsHelpModal],
  );
  return usePowerUserControl(
    POWER_USER_CONTROLS_LABEL.HELP,
    { keys, label },
    handleKeyPress,
  );
};

/**
 * Sets up power user controls.
 *
 * This hook sets up two power user controls: a shortcut to toggle dark mode,
 * and a shortcut to show/hide the shortcuts modal.
 *
 * It stores the shortcuts in the `powerControlKeysListAtom` atom, so that
 * other components can access them.
 *
 * The effect is run once when the component mounts, and never again.
 */
const usePowerUserControls = () => {
  useDarkModeControls();
  useHelpControls();
};

export default usePowerUserControls;
