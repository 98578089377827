import styled from "styled-components";

export const Header = styled.div<{ $isServiceDropdownVisible: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ $isServiceDropdownVisible }) =>
    $isServiceDropdownVisible ? "24px" : "48px"};
`;

export const StyledRecordingServiceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const GenerateBookingSessionPlaceholder = styled.div<{
  $height: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.midgroundColor};
  height: ${({ $height }) => `${$height}px`};
  border-radius: ${({ theme }) => theme.border.radius.md};
  color: ${({ theme }) => theme.separatorColor};
  padding: 16px;
  text-align: center;
`;
