import React from "react";
import Lottie from "react-lottie";
import animationData from "../../lotties/icons8-cancel.json";
import "./RemoveIconLottie.css";

export const defaultOptions = {
  loop: false,
  autoplay: true,
  duration: 50,
  initialValue: "50%",
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export interface RemoveIconLottieProps {
  onClick: () => void;
}

export const RemoveIconLottie = ({ onClick }: RemoveIconLottieProps) => (
  <div onClick={onClick} className="delete-icon remove-icon-animation">
    <Lottie options={defaultOptions} height={25} width={25} />
  </div>
);
