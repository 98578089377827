import {
  faCheck,
  faCloudArrowUp,
  faLink,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useMemo } from "react";
import { updateFileVersion } from "../../../../store/actions/fileVersions";
import {
  useAppDispatch,
  useAppSelector,
  useIsFileUploadedByUser,
} from "../../../../store/hooks";
import {
  FILE_STATUS,
  FileVersion,
  LINK_UPLOAD_TYPE,
} from "../../../../store/models/fileVersion";
import { ButtonVariant } from "../../../core-ui/components/Button/Button";
import { PopConfirm } from "../../../core-ui/components/PopConfirm/PopConfirm";
import { Text } from "../../../core-ui/components/Text/Text";
import { AnchorTag } from "../../FileLinkTable/FileLinkRow/FileLinkRow.styles";
import {
  FileStatus,
  PopOverIconButton,
  UploadedFileListContainer,
  UploadedFileListItemContainer,
} from "./UploadedFileList.styles";

export interface UploadedFileListProps {
  uploadedFiles: FileVersion[];
  projectId: number;
  progress: number;
}

export const UploadedFileListItem = ({
  file,
  projectId,
  progress,
}: {
  file: FileVersion;
  projectId: number;
  progress: number;
}) => {
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const uploadedByUser = useIsFileUploadedByUser(file);
  const dispatch = useAppDispatch();
  const archiveFile = () => {
    void dispatch(
      updateFileVersion({
        file_version_id: file.id,
        archive: true,
        project_id: projectId,
      }),
    );
  };

  const uploaded =
    FILE_STATUS.FILE_UPLOADED === file.status || progress === 100;

  const fileName = useMemo(() => {
    if (file.file_name && isAuthenticated) {
      return file.file_name;
    }

    const fileType = file.reference ? "reference" : "main";
    return `${fileType} ${file.upload_type} version: ${file.version}`;
  }, [file, isAuthenticated]);

  const DeleteButton = (disabled = false) => (
    <PopOverIconButton disabled={disabled} variant={ButtonVariant.GHOST}>
      <FontAwesomeIcon icon={faTrashCan} />
    </PopOverIconButton>
  );

  if (file.upload_type === LINK_UPLOAD_TYPE) {
    if (!file.file_link) return null;
    if (!uploaded) return null;
    return (
      <UploadedFileListItemContainer>
        <AnchorTag
          href={file.file_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {file.file_link}
        </AnchorTag>
        {uploadedByUser && (
          <PopConfirm
            title={"Are you sure you want to delete this file?"}
            description={undefined}
            onConfirm={archiveFile}
            okText={"Delete"}
            side={"left"}
          >
            {DeleteButton()}
          </PopConfirm>
        )}
        <FileStatus $uploaded={uploaded}>
          <FontAwesomeIcon icon={faLink} />
          link added
        </FileStatus>
      </UploadedFileListItemContainer>
    );
  }

  return (
    <UploadedFileListItemContainer>
      <Text style={{ width: "100%" }}>{fileName}</Text>
      {uploadedByUser && (
        <PopConfirm
          title={"Are you sure you want to delete this file?"}
          description={undefined}
          onConfirm={archiveFile}
          okText={"Delete"}
          side={"left"}
        >
          {DeleteButton(!uploaded)}
        </PopConfirm>
      )}
      <FileStatus $uploaded={uploaded}>
        {uploaded ? (
          <>
            <FontAwesomeIcon icon={faCheck} />
            uploaded
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faCloudArrowUp} />
            {`${progress.toFixed(2)}%`}
          </>
        )}
      </FileStatus>
    </UploadedFileListItemContainer>
  );
};

export const UploadedFileList = ({
  uploadedFiles,
  projectId,
  progress,
}: UploadedFileListProps) => {
  const orderedFiles = useMemo(
    () => _.orderBy(uploadedFiles, ["id"], ["desc"]),
    [uploadedFiles],
  );

  return (
    <UploadedFileListContainer>
      {orderedFiles.map((file, index) => (
        <UploadedFileListItem
          key={index}
          file={file}
          projectId={projectId}
          progress={progress}
        />
      ))}
    </UploadedFileListContainer>
  );
};
