import { styled, Card } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PaymentPlanCheckboxContainer = styled(Card)<{
  $isSelected: boolean;
}>(({ theme, $isSelected }) => ({
  display: "flex",
  boxShadow: "0px 2px 30px 0px rgba(0, 0, 0, 0.10)",
  width: "100%",
  maxWidth: "300px",
  padding: "24px",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  borderRadius: theme.border.radius.lg,
  border: $isSelected
    ? `2px solid ${theme.palette.standardColor.BoomyOrange[400]}`
    : "none",
}));

export const PaymentPlanCheckboxIcon = styled(FontAwesomeIcon)<{
  $isSelected: boolean;
}>`
  --fa-primary-color: ${({ theme, $isSelected }) =>
    $isSelected
      ? theme.palette.standardColor.BoomyOrange[500]
      : theme.palette.customColor.midgroundColor};
  --fa-secondary-color: ${({ theme, $isSelected }) =>
    $isSelected
      ? theme.palette.standardColor.BoomyOrange[100]
      : theme.palette.customColor.midgroundColor};
  height: 41px;
  width: 41px;
`;

export const PaymentPlanList = styled("ul")({
  display: "flex",
  flexDirection: "column",
  margin: "0px",
  paddingLeft: "15px",
  gap: "16px",
});
