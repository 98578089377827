import { useContext } from "react";
import { useAppSelector, useProjectFromProjectMap } from "../../../store/hooks";
import { FileType } from "../../../store/models/fileVersion";
import {
  APIMasteringProjectBase,
  APIMixingProjectBase,
  ProjectType,
  ProjectUserType,
  ProjectWorkflowSteps,
} from "../../../store/models/project";
import User, { UserLite } from "../../../store/models/user";
import {
  getMixingMasteringProject,
  getProjectWorkflowStep,
  getUserTypeFromProject,
} from "../../../utils/projectUtils";
import { FileUploader } from "../FileUploader/FileUploader";
import { InProgressUploader } from "../FileUploader/InProgressUploader/InProgressUploader";
import { MainProjectWorkflowContext } from "../MainProjectWorkflowPanel/MainProjectWorkflowPanelContext";
import { ProjectPanelSeparator } from "../ProjectWorkflowPanel/ProjectWorkflowPanel.styles";
import { TransferFileTransitionViewContainer } from "./TransferFileTransitionView.styles";
import { TransferFileArtistButtonRow } from "./components/TransferFileArtistButtonRow";
import { TransferFileEngineerButtonRow } from "./components/TransferFileEngineerButtonRow";
import { TransferFileReuploadMessage } from "./components/TransferFileReuploadMessage";

export interface TransferFileTransitionViewProps {
  mixingProject?: APIMixingProjectBase;
  masteringProject?: APIMasteringProjectBase;
  code?: string;
  user?: User | UserLite;
  projectId: number;
}

export const TransferFileTransitionView = ({
  mixingProject,
  masteringProject,
  code,
  user,
  projectId,
}: TransferFileTransitionViewProps) => {
  const currentUser = useAppSelector((state) => state.accountInfo.user);
  const project = useProjectFromProjectMap(projectId);
  const currentUserType = getUserTypeFromProject(currentUser, project);
  const isArtist = currentUserType === ProjectUserType.ARTIST;
  const isEngineer = currentUserType === ProjectUserType.ENGINEER;
  const mainProjectWorkflowContext = useContext(MainProjectWorkflowContext);
  const mixingMasteringProject = getMixingMasteringProject(project);
  const projectStep = mixingMasteringProject?.step ?? 0;
  const projectWorkflowStep = getProjectWorkflowStep(
    project.service_type,
    projectStep,
  );
  const isAtmos = project.service_type === ProjectType.ATMOS_MIXING;
  const atmosFileTypes = [FileType.WAV, FileType.MP4];

  if (!project) {
    return null;
  }

  if (projectWorkflowStep !== ProjectWorkflowSteps.FILE_TRANSFER) {
    return null;
  }

  if (!mixingProject && !masteringProject) {
    return null;
  }
  if (!user && !code) {
    return null;
  }
  if (!project) {
    return null;
  }

  const showProjectFileUploader =
    mainProjectWorkflowContext?.showEngineerProjectFileUploader;

  return (
    <TransferFileTransitionViewContainer>
      {(isArtist || code) && (
        <>
          {mixingMasteringProject?.engineer_file_notes && (
            <TransferFileReuploadMessage
              message={mixingMasteringProject.engineer_file_notes}
            />
          )}
          <FileUploader
            project={project}
            projectStep={projectStep}
            isCurrentProjectEngineer={isEngineer}
            engineerIsUploadingOnBehalfOfArtist={Boolean(
              mixingMasteringProject?.engineer_has_files,
            )}
          />
          <ProjectPanelSeparator $margin="16px 0 24px 0" />
          <TransferFileArtistButtonRow
            mixingProject={mixingProject}
            masteringProject={masteringProject}
            code={code}
            project={project}
          />
        </>
      )}
      {isEngineer && (
        <>
          {showProjectFileUploader ? (
            // engineer project working files uploader
            <>
              <FileUploader
                project={project}
                projectStep={projectStep}
                isCurrentProjectEngineer={true}
                engineerIsUploadingOnBehalfOfArtist={true}
              />
              <TransferFileEngineerButtonRow
                project={project}
                projectType={project.service_type}
              />
            </>
          ) : (
            // engineer main mix/master uploader
            <div style={{ width: "100%" }}>
              <InProgressUploader
                isInFileTransfer
                code={code ?? null}
                projectId={project.id}
                isEngineerOnProject={true}
                uploadSettings={{
                  reference: false,
                  uploadType: isAtmos ? atmosFileTypes : [FileType.WAV],
                }}
              />
            </div>
          )}
        </>
      )}
    </TransferFileTransitionViewContainer>
  );
};
