import { useMemo } from "react";
import { useOverlappingBookingAvailability } from "../../../../hooks/bookingHooks/useAllPossibleBookingAvailability";
import { useAvailableDateTimeOptions } from "../../../../hooks/bookingHooks/useAvailableDateTimeOptions";
import { useFilterTimeOptions } from "../../../../hooks/bookingHooks/useFilterTimeOptions";
import { useRecordingSessionUserType } from "../../../../hooks/bookingHooks/useRecordingSessionUserType";
import { useAvailabilityForMostAvailableDay } from "../../../../hooks/useGeneralWorkingHoursStringForDay";
import {
  StartTimeOption,
  toggleDateTimeModal,
} from "../../../../store/actions/recordingBookingMobileState";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import Engineer from "../../../../store/models/engineer";
import { RecordingService } from "../../../../store/models/recording";
import { StudioRoom } from "../../../../store/models/studio";
import {
  selectCurrentlySelectedSessionData,
  selectShoppingCartLatestSessionDuration,
  selectShoppingCartStudioRoomAvailabilities,
} from "../../../../store/selectors/shoppingCart";
import { convertAvailabilityForDateToMap } from "../../../../store/utils/utils";
import {
  RecordingDetailSelectTimeOption,
  RecordingDetailTimeDropdownVariant,
} from "../RecordingDetailSelectTimeOption/RecordingDetailSelectTimeOption";
import { RecordingDetailsMobileMenu } from "../RecordingDetailsMobileMenu/RecordingDetailsMobileMenu";
import "./RecordingDetailSelectDateTimeInputMobileMenu.css";

interface RecordingDetailSelectDateTimeInputMobileMenuProps {
  engineer?: Engineer;
  engineerOnly?: boolean;
  recordingService: RecordingService | null;
  studioRoom: StudioRoom | undefined;
  studioRoomId: number | undefined;
}
export const RecordingDetailSelectDateTimeInputMobileMenu = ({
  engineer,
  studioRoomId,
  recordingService,
  studioRoom,
  engineerOnly = false,
}: RecordingDetailSelectDateTimeInputMobileMenuProps) => {
  const dispatch = useAppDispatch();
  const currentSession = useAppSelector(selectCurrentlySelectedSessionData);
  const minimumSessionDuration = useAppSelector(
    selectShoppingCartLatestSessionDuration(recordingService),
  );
  const startTimeOption = useAppSelector(
    (state) => state.recordingBookingMobileState.startTimeOption,
  );
  const activeStudioId = studioRoom?.studio?.id;
  const activeStudio = useAppSelector(
    (state) => state.studiosSlice[activeStudioId ?? -1],
  );
  const {
    isEngineerWithPermission: isCurrentUserSameAsSelectedEngineer,
    isStudioManager,
  } = useRecordingSessionUserType(activeStudio, engineer);

  const durationMinutes = currentSession?.duration ?? minimumSessionDuration;
  const selectedSessionDate = useMemo(() => {
    if (!currentSession?.selectedISODate) return undefined;
    return new Date(currentSession?.selectedISODate);
  }, [currentSession?.selectedISODate]);
  const selectedDate = useMemo(() => {
    const selectedDate =
      currentSession?.selectedDateOption?.value ?? selectedSessionDate;
    return selectedDate ? new Date(selectedDate) : undefined;
  }, [currentSession?.selectedDateOption, selectedSessionDate]);
  const selectedIndex = useAppSelector(
    (state) => state.shoppingCart.selectedIndex,
  );
  const studioDateMap = useAppSelector(
    selectShoppingCartStudioRoomAvailabilities(studioRoom),
  );
  const engineerAvailability = useAppSelector(
    (state) => state.availability.engineer_user[engineer?.user_id ?? -1],
  );
  const engineerDateMap = useMemo(
    () => convertAvailabilityForDateToMap(engineerAvailability),
    [engineerAvailability],
  );

  const dateOptions = useAvailableDateTimeOptions(
    selectedDate,
    durationMinutes,
    currentSession?.trackingEngineer,
    studioDateMap,
    engineerDateMap,
    engineerOnly,
  );

  const allDateOptions = useOverlappingBookingAvailability(
    durationMinutes,
    dateOptions,
    selectedDate,
  );

  const availabilityForMostAvailableDay = useAvailabilityForMostAvailableDay(
    durationMinutes,
    studioRoomId,
    engineer,
  );
  const isVisible = useAppSelector(
    (state) => state.recordingBookingMobileState.showDateTimeModal,
  );
  const headerText = useMemo(() => {
    switch (startTimeOption) {
      case StartTimeOption.startEndTime:
        return "Select Start & End Time";
      case StartTimeOption.startTime:
        return "Select Start Time";
      case StartTimeOption.endTime:
        return "Select End Time";
      default:
        return "Select Start & End Time";
    }
  }, [startTimeOption]);

  const timeOptions = useFilterTimeOptions(
    allDateOptions,
    dateOptions.length > 0,
    isCurrentUserSameAsSelectedEngineer,
    isStudioManager,
    engineerOnly,
    availabilityForMostAvailableDay,
  );

  return (
    <RecordingDetailsMobileMenu
      isVisible={isVisible}
      onClose={() => {
        dispatch(toggleDateTimeModal());
      }}
    >
      <div className="recording-detail-mobile-menu-container">
        <header>
          <h1 className="h7-semi-bold">{headerText}</h1>
        </header>
        <span>
          {timeOptions.map((option, i) => {
            return (
              <RecordingDetailSelectTimeOption
                key={`${option.label}-${i}`}
                className="recording-booking-mobile-menu-option"
                index={selectedIndex}
                isCurrentUserSameAsSelectedEngineer={
                  isCurrentUserSameAsSelectedEngineer
                }
                onClick={() => dispatch(toggleDateTimeModal())}
                option={option}
                preselectedDateOption={currentSession?.selectedDateOption}
                variant={
                  !dateOptions.length
                    ? RecordingDetailTimeDropdownVariant.GENERAL
                    : RecordingDetailTimeDropdownVariant.START_END
                }
              />
            );
          })}
        </span>
      </div>
    </RecordingDetailsMobileMenu>
  );
};
