import { HTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";
import "./NavButton.css";

export enum NAV_BUTTON_VARIANT {
  BUTTON = "button",
  LINK = "link",
}

export type NavButtonProps =
  | {
      variant: NAV_BUTTON_VARIANT.LINK;
      path: string;
      color?: string;
      frontIcon?: ReactNode;
      message: string;
      onClick?: Pick<HTMLAttributes<HTMLAnchorElement>, "onClick">["onClick"];
      showIconOnly?: boolean;
      showNotification?: boolean;
    }
  | {
      variant: NAV_BUTTON_VARIANT.BUTTON;
      onClick: Pick<HTMLAttributes<HTMLButtonElement>, "onClick">["onClick"];
      color?: string;
      frontIcon?: ReactNode;
      message: string;
      showIconOnly?: boolean;
      showNotification?: boolean;
    };

export const NavButton: React.FC<NavButtonProps> = (props: NavButtonProps) => {
  const {
    color = "var(--text-primary-color)",
    showIconOnly = false,
    showNotification = false,
    variant,
    frontIcon,
    message,
  } = props;

  const NavButtonContent = (
    <>
      <div style={{ position: "relative" }}>
        {frontIcon}
        {showNotification && <div className="notification-indicator" />}
      </div>
      {!showIconOnly && (
        <p className="nav-button-text" style={{ marginLeft: "0.5rem", color }}>
          {message}
        </p>
      )}
    </>
  );

  return variant === "button" ? (
    <button className="nav-button" onClick={props.onClick}>
      {NavButtonContent}
    </button>
  ) : (
    <Link className="nav-button" onClick={props.onClick} to={props.path}>
      {NavButtonContent}
    </Link>
  );
};
