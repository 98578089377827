import styled from "styled-components";

export const BottomNavContainer = styled.div<{
  $showNav?: boolean;
  $navItemsHeight?: number;
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  transition: 0.3s ease;
  z-index: var(--bottom-nav-z-index) !important;
  bottom: ${({ $navItemsHeight, $showNav }) =>
    $showNav ? 0 : ($navItemsHeight || 0) * -1}px;
`;
