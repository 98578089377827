export enum SUBSCRIPTION_PLAN {
  NO_SUBSCRIPTION_PLAN,
  LEGACY_BASIC_PLAN,
  LEGACY_GOLD_PLAN,
  LEGACY_GOLD_YEARLY_PLAN,
  LEGACY_PLATINUM_PLAN,
  LEGACY_PLATINUM_YEARLY_PLAN,
  LEGACY_DIAMOND_PLAN,
  LEGACY_GOLD_QUARTERLY_PLAN,
  ESSENTIALS_PLAN,
  PLATINUM_PLAN_YEARLY,
  PLATINUM_PLAN_QUARTERLY,
  PLATINUM_PLAN_MONTHLY,
}

export enum SUBSCRIPTION_STATUS {
  INACTIVE = "inactive",
  ACTIVE = "active",
  NOT_DEFINED = "not_defined",
  TRIALING = "trialing",
}

export interface Subscription {
  subscription_plan_choice: SUBSCRIPTION_PLAN;
  stripe_subscription_status: SUBSCRIPTION_STATUS;
}

export interface SubscriptionFaq {
  question: string;
  answer: string;
}

interface SubscriptionFeature {
  text?: string;
  subtext?: string;
  icon?: boolean;
  disabledColor: boolean;
}

export const SUBSCRIPTION_FEE_MAP = new Map<SUBSCRIPTION_PLAN, number>([
  [SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN, 0.1],
  [SUBSCRIPTION_PLAN.LEGACY_BASIC_PLAN, 0.1],
  [SUBSCRIPTION_PLAN.LEGACY_GOLD_PLAN, 0.05],
  [SUBSCRIPTION_PLAN.LEGACY_GOLD_YEARLY_PLAN, 0.05],
  [SUBSCRIPTION_PLAN.LEGACY_GOLD_QUARTERLY_PLAN, 0.05],
  [SUBSCRIPTION_PLAN.LEGACY_PLATINUM_PLAN, 0.0],
  [SUBSCRIPTION_PLAN.LEGACY_PLATINUM_YEARLY_PLAN, 0.0],
  [SUBSCRIPTION_PLAN.ESSENTIALS_PLAN, 0.1],
  [SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY, 0.0],
  [SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY, 0.0],
  [SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY, 0.0],
]);

export const SUBSCRIPTION_FEE_STRING_MAP = new Map<SUBSCRIPTION_PLAN, string>([
  [SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN, "10%"],
  [SUBSCRIPTION_PLAN.LEGACY_BASIC_PLAN, "10%"],
  [SUBSCRIPTION_PLAN.LEGACY_GOLD_PLAN, "5%"],
  [SUBSCRIPTION_PLAN.LEGACY_GOLD_YEARLY_PLAN, "5%"],
  [SUBSCRIPTION_PLAN.LEGACY_GOLD_QUARTERLY_PLAN, "5%"],
  [SUBSCRIPTION_PLAN.LEGACY_PLATINUM_PLAN, "0%"],
  [SUBSCRIPTION_PLAN.LEGACY_PLATINUM_YEARLY_PLAN, "0%"],
  [SUBSCRIPTION_PLAN.LEGACY_DIAMOND_PLAN, "0%"],
  [SUBSCRIPTION_PLAN.ESSENTIALS_PLAN, "10%"],
  [SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY, "0%"],
  [SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY, "0%"],
  [SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY, "0%"],
]);

export const NUM_OFFERABLE_SERVICES = new Map<SUBSCRIPTION_PLAN, string>([
  [SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN, "None"],
  [SUBSCRIPTION_PLAN.LEGACY_BASIC_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.LEGACY_GOLD_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.LEGACY_GOLD_YEARLY_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.LEGACY_GOLD_QUARTERLY_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.LEGACY_PLATINUM_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.LEGACY_PLATINUM_YEARLY_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.LEGACY_DIAMOND_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.ESSENTIALS_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY, " Unlimited"],
  [SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY, "Unlimited"],
  [SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY, "Unlimited"],
]);

export const getSubsPlanFeatureList = (
  plan: SUBSCRIPTION_PLAN,
): SubscriptionFeature[] => {
  const includeEssentials = [
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    {
      text: SUBSCRIPTION_FEE_STRING_MAP.get(plan),
      subtext: "per booking transaction",
      disabledColor: true,
    },
    {
      text: "48 hours",
      subtext: "3% fee for instant upon project completion",
      disabledColor: true,
    },
    {
      text: "+3%",
      subtext: "additional fee",
      disabledColor: true,
    },
    { text: "not included", icon: true, disabledColor: true },
    { text: "not included", icon: true, disabledColor: true },
    { text: "not included", icon: true, disabledColor: true },
    { text: "not included", icon: true, disabledColor: true },
    { text: "not included", icon: true, disabledColor: true },
    {
      subtext: NUM_OFFERABLE_SERVICES.get(plan),
      icon: true,
      disabledColor: false,
    },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { subtext: "1 Included", icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { subtext: "1TB", icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: true },
    { icon: true, disabledColor: false },
    {
      subtext: "See number of profile views",
      icon: true,
      disabledColor: false,
    },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
  ];

  const includePlatinum = [
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    {
      text: SUBSCRIPTION_FEE_STRING_MAP.get(plan),
      subtext: "per booking transaction",
      disabledColor: false,
    },
    {
      text: "Instant",
      subtext: "Instant upon project completion",
      disabledColor: false,
    },
    {
      text: "0% fee",
      subtext: "no additional fees",
      disabledColor: false,
    },
    { icon: true, disabledColor: false },
    { text: "for Annual Members only", icon: true, disabledColor: false },
    { text: "for Annual Members only", icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    {
      subtext: NUM_OFFERABLE_SERVICES.get(plan),
      icon: true,
      disabledColor: false,
    },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    {
      subtext: "2 included (additional rooms at $60/year)",
      icon: true,
      disabledColor: false,
    },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { subtext: "Unlimited", icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
    {
      subtext: "See who’s visited your profile (coming soon)",
      icon: true,
      disabledColor: false,
    },
    { icon: true, disabledColor: false },
    { icon: true, disabledColor: false },
  ];

  if (
    plan === SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY ||
    plan === SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY ||
    plan === SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY
  ) {
    return includePlatinum;
  }
  return includeEssentials;
};

export const subscriptionFeatureList = [
  "Custom Profile with share link (showcase services offered, awards, credits, achievements plus more)",
  "Centralized Booking Management (integrated calendar for automated scheduling, reminders plus more)",
  "Project Workflow Management (tools to track progress, timing, and deliverables)",
  "Streamlined Bookkeeping (automated invoices + receipts, transaction history, payment tracking, file exports plus more)",
  "Service fee",
  "Fund withdrawals to linked bank",
  "Instant payout for completed Record Label projects (net-zero payment terms)",
  "Highlight your verified music credits powered by Muso.ai",
  "Exclusive access to Community Events",
  "Training + Certification Courses by EngineEars School of Music (Dolby Atmos plus more)",
  "Exclusive access to Tutorials + Demo videos (mixing/mastering techniques, plug-ins plus more)",
  "Exclusive access to Content and Live Streams",
  "Booking Limit (number of projects or sessions that can be booked)",
  "Customizable service rate cards and packages",
  "Custom service bundles (coming soon)",
  "Host Studio Rooms for recording session bookings",
  "Easy and flexible payment options for clientele",
  "Team Management (invite teammates, affiliates, collaborators plus more)",
  "Shareable Project links (invite and share project access to collaborators)",
  "Secure File Storage for Projects",
  "Secure real-time Messaging for ease of collaboration",
  "A/B Audio Player with lossless playback to compare versions",
  "Feedback Management (consolidate shared feedback and time-stamped comments per track to streamline edits)",
  "Help Center access",
  "24-hour Customer support",
  "Consolidated tax documents",
  "Project dispute mediation + Fraud prevention",
  "Consideration for EngineEars Pro: increased opportunities with exclusive exposure to Record Labels",
  "Promote your business on EngineEars search page",
  "Profile Visitor Analytics",
  "Portfolio with A/B audio player to show off your featured work",
  "Custom promo codes + social media assets for self-promotion",
];

export const MembershipFaqList: SubscriptionFaq[] = [
  {
    question: "Do I need to sign up to be on the free Essentials plan?",
    answer:
      "Yes, you’ll need to sign up to create a free account to use EngineEars on the Essentials Membership plan.",
  },
  {
    question: "Is there a free trial for the paid Membership plan?",
    answer:
      "Yes, you can try our Platinum Membership plan for free for 14 days at no risk to you. Experience all the benefits of a Platinum Membership before you decide on what plan is best for your needs.",
  },
  {
    question: "What happens when my free trial period ends?",
    answer:
      "At the end of your 14-day free trial period, your payment method on file will be automatically charged for your selected plan. If you decide you want to change your membership plan, you can do so from your account Settings page at any time during or after your free trial.",
  },
  {
    question: "What payment methods are accepted?",
    answer:
      "We currently accept all major credit cards and debit cards. You can be billed monthly, but save 17% if you pay quarterly, or 50% if you pay annually.",
  },
  {
    question: "Which EngineEars Membership plan is best for me?",
    answer:
      "The choice is completely up to you. Compared to the Platinum monthly plan, the Platinum annual plan saves you 50%, and the Platinum quarterly plan saves you 17%. At $30 USD only, you can get the monthly plan and experience all the benefits of a paid Membership subscription before deciding on a longer-term commitment.",
  },
  {
    question:
      "Do I have to renew my EngineEars Membership at the end of each period?",
    answer:
      "Paid subscriptions will automatically renew for the same subscription period (auto-renews for the next month, quarter, or year) unless you downgrade your plan before your renewal date.",
  },
  {
    question:
      "How can I upgrade, downgrade, or change my EngineEars Membership plan?",
    answer:
      "Visit your account Settings and go to the Payment & Billing tab. From there, you can click the Manage Subscription button to make changes to your current Membership plan. In addition to changing the type of Membership plan (e.g. from Essentials to Platinum), you can also make changes to the subscription renewal period (e.g. from monthly to annual, or quarterly to monthly).",
  },
  {
    question: "Can I cancel my EngineEars Membership plan at any time?",
    answer:
      "Yes, you can cancel at any time. Visit your account Settings and goto the Payment & Billing tab. From there, you can click the Manage Subscription button to cancel your current paid Membership plan. If canceled before the next renewal date, you will not be automatically billed for the next renewal period.",
  },
  {
    question:
      "I was on a legacy Membership plan, what happens to my Membership subscription now?",
    answer:
      "The legacy Basic plan is automatically converted to the Essentials plan and will remain free. The legacy Gold/Diamond/Platinum paid plans are automatically converted to the new Platinum Membership plan. You will not be charged any more per subscription period than you were paying under a legacy paid plan, however, any cost savings will be passed on to you!",
  },
  {
    question:
      "Do I need an annual Platinum Membership plan in order to access training courses and events?",
    answer:
      "Yes. Only Platinum Members on the annual subscription plan will have access to exclusive Community Events and EngineEars School of Music training/certification courses such as Dolby Atmos. If your account is on a Platinum Membership but billed monthly or quarterly, you will need to upgrade to an annual billing subscription to gain access.",
  },
  {
    question: "Do I need an account with Muso.AI to have verified credits?",
    answer:
      "Yes, if you have an existing account with Muso.ai you can connect it with your EngineEars account by logging in and linking it. If you do not have an account with Muso.ai you can create one by claiming your Muso.ai profile first, and then linking it afterward from your EngineEars account.",
  },
];
