import React, { FC, ReactElement } from "react";
import "./Pill.css";

export interface PillProps {
  label: string;
  backgroundColor?: string;
  color?: string;
  onClick?: () => void;
  className?: string;
  boldLabel?: boolean;
}

export const Pill: FC<PillProps> = ({
  label,
  backgroundColor,
  color,
  onClick = () => {},
  className = "",
  boldLabel = false,
}): ReactElement => {
  return (
    <div
      style={{ backgroundColor }}
      className={"pill-container ".concat(className)}
      onClick={onClick}
    >
      <p style={{ color, fontWeight: boldLabel ? 600 : 400 }}>{label}</p>
    </div>
  );
};
