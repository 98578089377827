import { Transaction } from "../../store/models/transaction";
import { useMemo } from "react";
import {
  getMyBookingsRoute,
  getProjectOverviewRoute,
} from "../../store/utils/routeGetters";

export const useTransactionRedirect = (
  isRecordingServiceTypeActive: boolean,
  transaction?: Transaction,
) => {
  return useMemo(() => {
    if (isRecordingServiceTypeActive) {
      return getMyBookingsRoute("sessions");
    }
    if (!transaction?.scheduled_project_id) {
      return getMyBookingsRoute("projects");
    }
    return getProjectOverviewRoute(transaction?.scheduled_project_id);
  }, [isRecordingServiceTypeActive, transaction?.scheduled_project_id]);
};
