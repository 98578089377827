import { ReactElement } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { RecordingSession } from "../../../../store/models/recordingSession";
import {
  CalendarDayCardContainer,
  CalendarDayCardRecordingSessionColumn,
  CalendarDayCardRecordingSessionContainer,
  CalendarDayCardRecordingSessionText,
  CalendarDayText,
  MobileCalendarDayCardRecordingSessionContainer,
} from "./CalendarDayCard.styles";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import { CalendarDayCardRecordingSession } from "./CalendarDayCardSession";
import { CalendarDayTaskModal } from "../../ModalCalendarDayTask/CalendarDayTaskModal";
import useModal from "../../../../hooks/useModal";
import { toast } from "react-toastify";
import Service from "../../../../store/models/service";
import { RecordingService } from "../../../../store/models/recording";
import { createTransaction } from "../../../../store/actions/transactions";
import { BookingParameters } from "../../../../store/utils/transactions";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { getTransactionBookingScreenRoute } from "../../../../store/utils/routeGetters";
import { ProjectType } from "../../../../store/models/project";
import {
  PendingSessionData,
  SetUpCartPayload,
} from "../../../../store/actions/shoppingCart";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { RootState } from "../../../../store";
import { useHistory } from "react-router-dom";
import { loadEngineerServices } from "../../../../store/actions/services";
import { PopConfirm } from "../../../core-ui/components/PopConfirm/PopConfirm";

export interface CalendarDayCardProps {
  dateNumber?: number;
  loading?: boolean;
  recordingSessions?: RecordingSession[];
  condensedVersion?: boolean;
  displayMobileView?: boolean;
}

export const CalendarDayCard = ({
  dateNumber,
  recordingSessions,
  displayMobileView = false,
}: CalendarDayCardProps): ReactElement => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isOpen, openModal, closeModal } = useModal();
  const { isMobile } = useMediaQueryBreakpoint();
  const pendingCount =
    recordingSessions?.filter((recordingSession) =>
      Boolean(recordingSession.pending_booking_acceptance),
    ).length ?? 0;
  const confirmedCount =
    recordingSessions?.filter(
      (recordingSession) => !recordingSession.pending_booking_acceptance,
    ).length ?? 0;
  const currentUser = useAppSelector(
    (state: RootState) => state.accountInfo.user,
  );
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);

  const createSessionBooking = async () => {
    if (!currentUser) {
      return;
    }
    if (selectedProfile?.user) {
      const engineerId = currentUser?.engineer?.id;
      if (!engineerId) {
        toast.error("Engineer not found");
        return;
      }
      void dispatch(loadEngineerServices({ engineer_id: engineerId }));
    }

    const transaction = await dispatch(createTransaction()).unwrap();
    let bookingParameters: BookingParameters;

    if (selectedProfile?.studio?.studio_rooms) {
      bookingParameters = {
        activeStudioId: selectedProfile.studio.id,
        activeStudioRoomId: selectedProfile.studio?.studio_rooms[0]?.id,
        activeServiceType: ProjectType.RECORDING,
        transactionId: +transaction.id,
        activeServiceTypeProjectIds: [],
      };
    } else {
      const engineerId = currentUser?.engineer?.id;
      bookingParameters = {
        engineerId: engineerId,
        engineerUserId: currentUser.id,
        activeServiceType: ProjectType.RECORDING,
        transactionId: +transaction.id,
        activeServiceTypeProjectIds: [],
      };
    }

    const pendingSession: PendingSessionData[] = [];
    const shoppingCart: SetUpCartPayload = {
      pendingSessionData: pendingSession,
    };

    history.push(
      getTransactionBookingScreenRoute(
        transaction.code,
        dispatch,
        bookingParameters,
        shoppingCart,
      ),
    );
  };

  const handleOnClick = async () => {
    if (recordingSessions?.length) {
      openModal();
    } else {
      await createSessionBooking();
    }
  };

  if (isMobile || displayMobileView) {
    return (
      <>
        <CalendarDayCardContainer onClick={handleOnClick} $isMobile={isMobile}>
          <MobileCalendarDayCardRecordingSessionContainer>
            {confirmedCount > 0 && (
              <CalendarDayCardRecordingSessionContainer
                $isMobile={isMobile}
                $pending={false}
              >
                <CalendarDayCardRecordingSessionText>
                  {confirmedCount}
                </CalendarDayCardRecordingSessionText>
              </CalendarDayCardRecordingSessionContainer>
            )}
            {pendingCount > 0 && (
              <CalendarDayCardRecordingSessionContainer
                $isMobile={isMobile}
                $pending={true}
              >
                <CalendarDayCardRecordingSessionText>
                  {pendingCount}
                </CalendarDayCardRecordingSessionText>
              </CalendarDayCardRecordingSessionContainer>
            )}
          </MobileCalendarDayCardRecordingSessionContainer>
          <CalendarDayText $isMobile={isMobile}>{dateNumber}</CalendarDayText>
        </CalendarDayCardContainer>
        <CalendarDayTaskModal
          modalIsOpen={isOpen}
          onCloseModal={() => {
            closeModal();
          }}
          recordingSessions={recordingSessions ?? []}
        />
      </>
    );
  }
  return (
    <>
      {recordingSessions?.length ? (
        <CalendarDayCardContainer onClick={handleOnClick}>
          <CalendarDayCardRecordingSessionColumn>
            {recordingSessions?.map((recordingSession) => (
              <CalendarDayCardRecordingSession
                key={recordingSession.id}
                recordingSession={recordingSession}
              />
            ))}
          </CalendarDayCardRecordingSessionColumn>
          <CalendarDayText>{dateNumber}</CalendarDayText>
        </CalendarDayCardContainer>
      ) : (
        <PopConfirm
          title={"Generate booking"}
          description={"Would you like to create a recording session booking?"}
          onConfirm={handleOnClick}
        >
          <CalendarDayCardContainer>
            <CalendarDayCardRecordingSessionColumn>
              {recordingSessions?.map((recordingSession) => (
                <CalendarDayCardRecordingSession
                  key={recordingSession.id}
                  recordingSession={recordingSession}
                />
              ))}
            </CalendarDayCardRecordingSessionColumn>
            <CalendarDayText>{dateNumber}</CalendarDayText>
          </CalendarDayCardContainer>
        </PopConfirm>
      )}
      <CalendarDayTaskModal
        modalIsOpen={isOpen}
        onCloseModal={() => {
          closeModal();
        }}
        recordingSessions={recordingSessions ?? []}
      />
    </>
  );
};
