import { UserLite } from "../../../store/models/user";
import { Skeleton } from "@mui/material";
import { Text } from "../../core-ui/components/Text/Text";
import { Link } from "react-router-dom";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { getProfileScreenRoute } from "../../../store/utils/routeGetters";

interface ReleaseCollaboratorProps {
  title: string;
  collaborators: UserLite[];
  isLoadingCollaborators: boolean;
}

export const ReleaseCollaborator = ({
  title,
  collaborators,
  isLoadingCollaborators,
}: ReleaseCollaboratorProps) => {
  if (isLoadingCollaborators) {
    return <Skeleton width={"30%"} height={10} />;
  }
  if (!collaborators.length) return null;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
      }}
    >
      <Text>{title}</Text>
      {collaborators.map((user) => (
        <Link
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
          key={user.id}
          to={getProfileScreenRoute(user.username)}
        >
          <Text variant={TextStyleVariant.P1} bold>
            {`@${user.username}`}
          </Text>
        </Link>
      ))}
    </div>
  );
};
