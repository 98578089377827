import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError } from "../../store/actions/errorStore";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../api/helpers";
import { useAppDispatch } from "../../store/hooks";
import { addStudioRooms } from "../../store/actions/studio";
import { fetchStudioRooms } from "../../api/recording/studio/fetchStudioRooms";

interface UseStudioRoomsQueryParams {
  username?: string;
  studioId?: number;
}

const useStudioRoomsQuery = ({
  username,
  studioId,
}: UseStudioRoomsQueryParams) => {
  const key = username || studioId;
  const dispatch = useAppDispatch();
  return useQuery({
    queryKey: [QUERY_KEYS.GET_STUDIO_ROOMS, key],
    queryFn: async () => {
      return fetchStudioRooms({ username, studioId })
        .then(async (rooms) => {
          if (assertEngineEarsErrorType(rooms)) {
            return Promise.reject(rooms);
          }
          if (rooms.length > 0) {
            dispatch(
              addStudioRooms({
                studio: { username, id: studioId },
                rooms: rooms,
              }),
            );
          }
          return rooms;
        })
        .catch((e: EngineEarsError) => {
          displayEngineEarsError(e);
          return undefined;
        });
    },
    retry: false,
    enabled: Boolean(username),
  });
};

export default useStudioRoomsQuery;
