import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { fetchActiveUser } from "../../api/auth/fetchActiveUser";
import { activeUserAtom } from "../../atoms/user/activeUserAtom";
import { useAtomValue, useSetAtom } from "jotai";
import { tokenAtom } from "../../atoms/auth/tokenAtom";
import { EngineEarsError } from "../../store/actions/errorStore";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../api/helpers";
import useActiveStudiosQuery from "./useActiveStudiosQuery";
import { activeProfileAtom } from "../../atoms/user/activeProfileAtom";

const useActiveUserQuery = () => {
  const setActiveUser = useSetAtom(activeUserAtom);
  const setProfileId = useSetAtom(activeProfileAtom);
  const token = useAtomValue(tokenAtom);
  useActiveStudiosQuery();
  return useQuery({
    queryKey: [QUERY_KEYS.LOAD_USER, token],
    queryFn: async () => {
      return fetchActiveUser()
        .then(async (activeUser) => {
          if (assertEngineEarsErrorType(activeUser)) {
            return Promise.reject(activeUser);
          }
          setActiveUser(activeUser || undefined);
          return activeUser;
        })
        .catch((e: EngineEarsError) => {
          displayEngineEarsError(e);
          setProfileId(undefined);
          setActiveUser(undefined);
        });
    },
    retry: false,
    enabled: Boolean(token),
  });
};

export default useActiveUserQuery;
