import classNames from "classnames";
import {
  DropdownSelector,
  IDropdownSelectorProps,
} from "../../elements/DropDownSelector/DropdownSelector";
import "./GenerateBookingDropdown.css";

export enum GenerateBookingDropdownRoundBorder {
  TOP_LEFT = "top-left",
  TOP_RIGHT = "top-right",
  BOTTOM_LEFT = "bottom-left",
  BOTTOM_RIGHT = "bottom-right",
}

export enum GenerateBookingDropdownNoBorder {
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom",
}

interface GenerateBookingDropdownProps extends IDropdownSelectorProps {
  isSelected?: boolean;
  noBorder?: GenerateBookingDropdownNoBorder[];
  roundBorder?: GenerateBookingDropdownRoundBorder[];
}

export const GenerateBookingDropdown = (
  props: GenerateBookingDropdownProps,
) => {
  const cssPrefix = "generate-booking-dropdown_";
  const roundBorderStyles =
    props.roundBorder?.map((style) => `${cssPrefix}round-border-${style}`) ??
    [];
  const noBorderStyles =
    props.noBorder?.map((style) => `${cssPrefix}no-border-${style}`) ?? [];

  const getSelectedStyle = () => {
    if (!props.isSelected) return "";
    return props.value ? "--completed" : "--selected";
  };

  return (
    <div
      className={classNames([
        `${cssPrefix}dropdown-container`,
        ...noBorderStyles,
        ...roundBorderStyles,
      ])}
    >
      <DropdownSelector
        hideArrow={props.hideArrow}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        options={props.options}
        noOptionsMessage={props.noOptionsMessage}
        placeholder={props.placeholder}
        value={props.value}
        customComponents={props.customComponents}
        customClassNames={{
          container: `${cssPrefix}dropdown-element-container`,
          selectContainer: `${cssPrefix}dropdown-element-select-container`,
          labelText: `${cssPrefix}dropdown-element-label-text${getSelectedStyle()}`,
          arrowContainer: `${cssPrefix}dropdown-element-arrow-container`,
          dropdownOption: `${cssPrefix}dropdown-element-option`,
        }}
        onChange={props.onChange}
      />
    </div>
  );
};
