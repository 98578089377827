import { Box } from "@mui/material";
import { useAtomValue } from "jotai";
import { ReactNode } from "react";
import { editMode } from "../../../atoms/profileScreenEdit";
import { useEngineerIsBooking } from "../../../hooks/useEngineerCanHostServices";
import { useGetSubscriptionStatus } from "../../../hooks/useGetSubscriptionStatus";
import { useAppSelector } from "../../../store/hooks";
import Engineer from "../../../store/models/engineer";
import { ProjectType } from "../../../store/models/project";
import { RecordingService } from "../../../store/models/recording";
import Service from "../../../store/models/service";
import User from "../../../store/models/user";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { Text } from "../../core-ui/components/Text/Text";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";

interface EngineerServicesWrapperProps {
  combinedServices: (Service | RecordingService)[];
  userData: User;
  engineer: Engineer;
  userOnOwnProfile: boolean;
  editServiceType?: ProjectType;
  addServiceType?: ProjectType;
  setAddServiceType: (serviceType?: ProjectType) => void;
  children: ReactNode;
  remainingServiceOptions: OptionType<ProjectType>[];
}

export const EngineerServicesWrapper = ({
  combinedServices,
  userData,
  engineer,
  userOnOwnProfile,
  editServiceType,
  addServiceType,
  setAddServiceType,
  children,
  remainingServiceOptions,
}: EngineerServicesWrapperProps) => {
  const { meetsStripeRequirements } = useAppSelector(
    (state) => state.accountInfo,
  );
  const engineerIsBooking = useEngineerIsBooking(engineer);
  const { data: subscription } = useGetSubscriptionStatus({
    user: userData,
  });
  const isEditMode = useAtomValue(editMode);

  if (!engineerIsBooking && !isEditMode) {
    return (
      <Text>{`@${userData.username}  is not currently booking projects`}</Text>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}>
      {!engineer?.verified && !userOnOwnProfile && (
        <Text bold>
          {`Note that ${getDisplayableNameForUser(userData)} is still in the verification process and has not yet been verified by the EngineEars team.`}
        </Text>
      )}
      {children}
    </Box>
  );
};
