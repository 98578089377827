import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getCalendarIntegrationStatus } from "../../../../store/actions/calendarService";
import { updateProfile } from "../../../../store/actions/accountInfo";
import { updateStudio } from "../../../../store/actions/studio";
import {
  AccountDetailsBody,
  AccountDetailsColumn,
  AccountDetailsFieldContainer,
  FieldInput,
  SeperatorLine,
} from "../AccountSettingsModal.styles";
import { Text, TEXT_WEIGHT } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { LocationInput } from "../../LocationInput/LocationInput";
import { CalendarManageView } from "../../CalendarManageView/CalendarManageView";
import { PresentationSettings } from "../../PresentationSettings/PresentationSettings";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { useUsernameCheck } from "../../../../hooks/usernameCheck";
import { DISCIPLINE_TYPE, getUserDisciplines } from "../../../../hooks/user";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/pro-solid-svg-icons";
import { Box, Theme, useMediaQuery, useTheme } from "@mui/material";
import { ResetPassword } from "../ResetPassword";
import { UsernameChecksContainer } from "../../SignInForm/SignInForm.styles";
import { loadEngineerServices } from "../../../../store/actions/services";
import { ServicesFormModalFooter } from "../../../screens/ProfileScreen/components/ServicesFormModalFooter";
import {
  createRecordingService,
  createRecordingServiceParams,
} from "../../../../store/actions/recording";
import { BasePopover } from "../../../core-ui/components/BasePopover/BasePopover";
import { PopoverTrigger } from "@radix-ui/react-popover";
import useModal from "../../../../hooks/useModal";
import { useHistory } from "react-router-dom";
import {
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../../store/utils/routeGetters";
import { setStudio } from "../../../../store/actions/selectedProfile";
import { AccountSettingsPhoneNumberField } from "./AccountSettingsPhoneNumberField";

interface updateProfileErrorProps {
  code: number;
  firstName: string;
  lastName: string;
  displayName: string;
  username: string;
  email: string;
  phoneNumber: string;
}

interface AccountSettingsProps {
  onClose: () => void;
}

export const AccountSettings = ({ onClose }: AccountSettingsProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { user } = useAppSelector((state) => state.accountInfo);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const userAccountTypes = getUserDisciplines(selectedProfile?.user);
  const [savingChanges, setSavingChanges] = useState(false);
  const [updateProfileError, setUpdateProfileError] =
    useState<updateProfileErrorProps>();
  const [firstName, setFirstName] = useState(
    user?.id === selectedProfile?.user?.id ? user?.first_name : "",
  );
  const [lastName, setLastName] = useState(
    user?.id === selectedProfile?.user?.id ? user?.last_name : "",
  );
  const [studioName, setStudioName] = useState(
    selectedProfile?.studio?.studio_profile?.display_name ?? "",
  );
  const [userDisplayName, setUserDisplayName] = useState(
    user?.id === selectedProfile.user?.id ? user?.profile?.display_name : "",
  );
  const [originalUsername] = useState(
    (selectedProfile?.user?.username || selectedProfile?.studio?.username) ??
      "",
  );
  const [updatedUsername, setUpdatedUsername] = useState(
    (selectedProfile?.user?.username || selectedProfile?.studio?.username) ??
      "",
  );
  const [usernameFieldFocused, setUsernameFieldFocused] = useState(false);
  const [hasSpace, hasSpecialCharacter] = useUsernameCheck(updatedUsername);
  const [email, setEmail] = useState(user?.email ?? "");
  const [isValidEmail, setIsValidEmail] = useState(Boolean(user?.email));
  const [location, setLocation] = useState<
    undefined | google.maps.places.PlaceResult
  >(undefined);
  const { calendarIntegrated } = useAppSelector(
    (state) => state.calendarService,
  );
  const recordingService = useAppSelector(
    (state) => state.engineerServices.recordingService,
  );
  const [pendingRecordingService, setPendingRecordingService] =
    useState<createRecordingServiceParams>();
  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  useEffect(() => {
    if (selectedProfile?.user?.engineer?.id) {
      void dispatch(
        loadEngineerServices({
          engineer_id: selectedProfile.user.engineer.id,
        }),
      ).unwrap();
    }

    void dispatch(
      getCalendarIntegrationStatus({ studio_id: selectedProfile?.studio?.id }),
    ).unwrap();
  }, [selectedProfile]);

  useEffect(() => {
    if (recordingService) {
      setPendingRecordingService({
        price: recordingService.service_rate?.price || 0,
        label_price: recordingService?.service_rate?.label_price || 0,
        travel_to_artist_price:
          recordingService?.service_rate?.travel_to_artist_price || 0,
        minimum_session_time_minutes:
          recordingService.minimum_session_time_minutes || 0,
        maximum_session_time_minutes:
          recordingService.maximum_session_time_minutes || 0,
        will_come_to_you: Boolean(recordingService.will_come_to_you),
        max_travel_distance_minutes:
          recordingService.max_travel_distance_minutes || 0,
        equipment_highlights: recordingService.equipment_highlights || "",
        arrival_information: recordingService.arrival_information || "",
        recording_location: recordingService.recording_location?.city_location,
        unit_number: recordingService.unit_number || "",
        minimum_gap_between_sessions_in_minutes:
          recordingService.minimum_gap_between_sessions_in_minutes || 0,
        number_of_hours_notice: recordingService.number_of_hours_notice || 0,
        minimum_deposit_percentage:
          recordingService.minimum_deposit_percentage.toString() ?? "1.0",
      });
    }
  }, [recordingService]);

  const handleSavingChanges = () => {
    setSavingChanges(true);
    if (selectedProfile?.studio) {
      void dispatch(
        updateStudio({
          studio_id: selectedProfile?.studio?.id,
          location: location,
          profile: {
            display_name: studioName,
          },
          ...(originalUsername !== updatedUsername && {
            username: updatedUsername,
          }),
        }),
      )
        .unwrap()
        .then((result) => {
          dispatch(setStudio(result));
        })
        .catch(() => {});
      void dispatch(
        updateProfile({
          email: email,
        }),
      )
        .unwrap()
        .then(() => {
          setSavingChanges(false);
          onClose();
          toast.success("Successfully updated account details");
          if (originalUsername !== updatedUsername) {
            history.push(getStudioScreenRoute(updatedUsername));
          }
        });
    } else {
      if (recordingService && location && pendingRecordingService) {
        void dispatch(
          createRecordingService({
            ...pendingRecordingService,
            recording_location: location,
          }),
        ).unwrap();
      }
      void dispatch(
        updateProfile({
          first_name: firstName,
          last_name: lastName,
          display_name: userDisplayName,
          email: email,
          ...(originalUsername !== updatedUsername && {
            username: updatedUsername,
          }),
        }),
      )
        .unwrap()
        .catch((err) => {
          setUpdateProfileError(err.errors);
        })
        .then(() => {
          setSavingChanges(false);
          onClose();
          toast.success("Successfully updated account details");
          if (originalUsername !== updatedUsername) {
            history.push(getProfileScreenRoute(updatedUsername));
          }
        });
    }
  };

  const usernameRequirement = (label: string, isPassing: boolean) => (
    <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <FontAwesomeIcon
        icon={isPassing ? faClose : faCheck}
        color={
          isPassing
            ? theme.palette.standardColor.Green[600]
            : theme.palette.standardColor.Red[700]
        }
      />
      <Text
        variant={TextStyleVariant.P3}
        style={{
          color: isPassing
            ? theme.palette.standardColor.Green[600]
            : theme.palette.standardColor.Red[700],
        }}
      >
        {label}
      </Text>
    </Box>
  );

  const firstNameField = (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        First name
      </Text>
      <FieldInput
        placeholder={"First Name"}
        defaultValue={firstName}
        disabled={savingChanges}
        fullWidth={true}
        size={"small"}
        onChange={(e) => {
          const value = e.target.value;
          setFirstName(value);
        }}
        error={Boolean(updateProfileError?.firstName)}
        helperText={
          updateProfileError?.firstName
            ? updateProfileError?.firstName
            : "Note: not shown publicly"
        }
      />
    </AccountDetailsFieldContainer>
  );

  const lastNameField = (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        Last name
      </Text>
      <FieldInput
        placeholder={"Last Name"}
        defaultValue={lastName}
        disabled={savingChanges}
        fullWidth={true}
        size={"small"}
        onChange={(e) => {
          const value = e.target.value;
          setLastName(value);
        }}
        error={Boolean(updateProfileError?.lastName)}
        helperText={
          updateProfileError?.lastName
            ? updateProfileError?.lastName
            : "Note: not shown publicly"
        }
      />
    </AccountDetailsFieldContainer>
  );

  const displayNameField = (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        Display name
      </Text>
      <FieldInput
        placeholder={"Display name"}
        defaultValue={userDisplayName}
        disabled={savingChanges}
        fullWidth={true}
        size={"small"}
        onChange={(e) => {
          const value = e.target.value;
          setUserDisplayName(value);
        }}
        error={Boolean(updateProfileError?.displayName)}
        helperText={
          updateProfileError?.displayName
            ? updateProfileError?.displayName
            : "Note: shown publicly on your profile page"
        }
      />
    </AccountDetailsFieldContainer>
  );

  const studioNameField = (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        Studio facility name
      </Text>
      <FieldInput
        placeholder={"Studio Name"}
        defaultValue={studioName}
        disabled={savingChanges}
        fullWidth={true}
        size={"small"}
        onChange={(e) => {
          const value = e.target.value;
          setStudioName(value);
        }}
        helperText={"Note: shown publicly on studio page"}
      />
    </AccountDetailsFieldContainer>
  );

  const usernameField = (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        Username
      </Text>
      <FieldInput
        placeholder={"Username"}
        defaultValue={originalUsername}
        disabled={savingChanges}
        fullWidth={true}
        size={"small"}
        onFocus={() => setUsernameFieldFocused(true)}
        onBlur={() => setUsernameFieldFocused(false)}
        onChange={(e) => {
          const value = e.target.value;
          setUpdatedUsername(value);
        }}
        error={Boolean(updateProfileError?.username)}
        helperText={
          updateProfileError?.username
            ? updateProfileError?.username
            : selectedProfile?.user
              ? "Note: used to login"
              : null
        }
      />
      {updatedUsername.length > 0 &&
        usernameFieldFocused &&
        (hasSpace || hasSpecialCharacter) && (
          <UsernameChecksContainer>
            {usernameRequirement("No spaces allowed", hasSpace)}
            {usernameRequirement(
              "No special characters (dashes and underscores allowed)",
              hasSpecialCharacter,
            )}
          </UsernameChecksContainer>
        )}
    </AccountDetailsFieldContainer>
  );

  const emailField = (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        Email
      </Text>
      <FieldInput
        placeholder={"Email"}
        defaultValue={email}
        disabled={savingChanges}
        fullWidth={true}
        size={"small"}
        onChange={(e) => {
          const email = e.target.value;
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          setEmail(email);

          if (emailPattern.test(email)) {
            setIsValidEmail(true);
          } else {
            setIsValidEmail(false);
          }
        }}
        InputProps={{
          endAdornment: isValidEmail ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : null,
        }}
        error={Boolean(updateProfileError?.email)}
        helperText={updateProfileError?.email}
      />
      {user?.email_verified && (
        <Text
          variant={TextStyleVariant.P3}
          style={{ color: theme.palette.standardColor.Green[600] }}
        >
          <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faCheck} />
          verified
        </Text>
      )}
    </AccountDetailsFieldContainer>
  );

  const locationAndCalendarField = (
    <AccountDetailsFieldContainer>
      {(selectedProfile?.studio ||
        (userAccountTypes.includes(DISCIPLINE_TYPE.ENGINEER) &&
          recordingService &&
          pendingRecordingService)) && (
        <>
          <Text
            variant={TextStyleVariant.P1}
            weight={TEXT_WEIGHT.SEMI_BOLD}
            style={{ marginBottom: "8px" }}
          >
            Location
          </Text>
          <LocationInput
            defaultValue={
              selectedProfile?.studio
                ? selectedProfile?.studio.location?.city_location
                : (pendingRecordingService?.recording_location as string)
            }
            placeholder={"Start by typing in your address"}
            onPlaceSelected={(placeResult) => {
              setLocation(placeResult);
            }}
            showBorder
          />
          <Text
            variant={TextStyleVariant.P1}
            weight={TEXT_WEIGHT.SEMI_BOLD}
            style={{ marginBottom: "8px" }}
          >
            {calendarIntegrated ? "Manage" : "Set up"} booking calendar
          </Text>
          <CalendarManageView studioID={selectedProfile?.studio?.id} />
        </>
      )}
    </AccountDetailsFieldContainer>
  );

  const bodyLayout = () => {
    if (isMobile) {
      return (
        <>
          <AccountDetailsColumn>
            {selectedProfile?.user && firstNameField}
            {selectedProfile?.user && lastNameField}
            {selectedProfile?.studio ? studioNameField : displayNameField}
            {usernameField}
            {emailField}
            <AccountSettingsPhoneNumberField />
            <ResetPassword />
            {locationAndCalendarField}
          </AccountDetailsColumn>
        </>
      );
    } else {
      return (
        <>
          <AccountDetailsColumn>
            {selectedProfile?.user && firstNameField}
            {selectedProfile?.studio ? studioNameField : displayNameField}
            {emailField}
            <ResetPassword />
          </AccountDetailsColumn>
          <AccountDetailsColumn>
            {selectedProfile?.user && lastNameField}
            {usernameField}
            <AccountSettingsPhoneNumberField />
            {locationAndCalendarField}
          </AccountDetailsColumn>
        </>
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <PresentationSettings />
        <AccountDetailsBody>{bodyLayout()}</AccountDetailsBody>
      </Box>
      <SeperatorLine />
      <ServicesFormModalFooter>
        <Button
          variant={ButtonVariant.OUTLINED}
          disabled={savingChanges}
          onClick={onClose}
        >
          Cancel
        </Button>
        {originalUsername !== updatedUsername ? (
          <BasePopover
            side="top"
            isOpen={isPopoverOpen}
            setIsPopoverOpen={setIsPopoverOpen}
            closePopover={closePopover}
            title={"Confirm update username"}
            additionalContent={
              <Text>
                Changing your username will affect existing links to your
                {selectedProfile?.studio ? " studio" : "profile"} page. Are you
                sure you want to change your username?
              </Text>
            }
            onConfirm={handleSavingChanges}
            okButtonProps={{
              disabled: savingChanges,
              loading: savingChanges,
            }}
          >
            <PopoverTrigger asChild>
              <Button loading={savingChanges} disabled={savingChanges}>
                Save Settings
              </Button>
            </PopoverTrigger>
          </BasePopover>
        ) : (
          <Button
            loading={savingChanges}
            disabled={savingChanges}
            onClick={handleSavingChanges}
          >
            Save Settings
          </Button>
        )}
      </ServicesFormModalFooter>
    </>
  );
};
