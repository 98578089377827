import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChangeEvent,
  EventHandler,
  SyntheticEvent,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { SCREENS } from "../../../../routes";
import "./NavSearchBar.css";
import { NavSearchFilterButton } from "./components/NavSearchFilterButton/NavSearchFilterButton";
import { NavSearchResultsDropDown } from "./components/NavSearchResultsDropDown/NavSearchResultsDropDown";
import useSearch from "../../../../hooks/searchHooks/useSearch";
import { useAtomValue } from "jotai";
import {
  showTopNavAtom,
  topNavContainerHeightAtom,
} from "../../../../atoms/navAtoms";
import { queryStringAtom } from "../../../../atoms/searchQueryAtom";

export const NavSearchBar = () => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const {
    searchTerm,
    setSearchTerm,
    autocompleteSuggestions,
    isFetchingAutocomplete,
  } = useSearch({
    enabled: isInputFocused,
  });
  const [showResultsDropDown, setShowResultsDropDown] = useState(false);
  const history = useHistory();
  const showHeader = useAtomValue(showTopNavAtom);
  const topNavContainerHeight = useAtomValue(topNavContainerHeightAtom);
  const searchQueryString = useAtomValue(queryStringAtom);
  const isTooNarrowForDropDown = useMediaQuery("(max-width: 920px)");

  const onInputFocus = useCallback(() => {
    setIsInputFocused(true);
    setShowResultsDropDown(true);
  }, [setIsInputFocused, setShowResultsDropDown]);

  const onInputBlur = useCallback(() => {
    setIsInputFocused(true);
    setTimeout(() => setShowResultsDropDown(false), 100);
  }, [setIsInputFocused, setShowResultsDropDown]);

  const handleSearchQuery: EventHandler<SyntheticEvent<HTMLFormElement>> = (
    event,
  ) => {
    event.preventDefault();
    setShowResultsDropDown(false);
    history.push(`${SCREENS.SEARCH}?${searchQueryString}`);
  };

  const handleSearchOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const searchQuery = event.currentTarget.value;
      setSearchTerm(searchQuery);
      if (!searchQuery) {
        setShowResultsDropDown(false);
      }
    },
    [setSearchTerm, setShowResultsDropDown],
  );

  const onListItemClick = useCallback(() => {
    setShowResultsDropDown(false);
  }, [setShowResultsDropDown]);

  useEffect(() => {
    if (showResultsDropDown && !showHeader) {
      setShowResultsDropDown(false);
    }
  }, [showHeader, showResultsDropDown]);

  const showAttachedDropDown = Boolean(
    showResultsDropDown && searchTerm && !isTooNarrowForDropDown,
  );

  return (
    <>
      <div className="nav-search-bar-container">
        <form
          className={`nav-search-bar ${showAttachedDropDown ? "show" : ""}`}
          onSubmit={handleSearchQuery}
        >
          <input
            className="nav-input"
            name="searchInput"
            onBlur={onInputBlur}
            onFocus={onInputFocus}
            onChange={handleSearchOnChange}
            placeholder="Search Recording Studios & Audio Engineers"
            value={searchTerm}
          />
          <NavSearchFilterButton />
          <button
            className={`nav-search-button ${showAttachedDropDown ? "show" : ""}`}
            name="searchSubmit"
            type="submit"
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>
        {!isTooNarrowForDropDown && (
          <NavSearchResultsDropDown
            className="nav-search-results-container--attached"
            data={autocompleteSuggestions}
            loading={isFetchingAutocomplete}
            onListItemClick={onListItemClick}
            show={showResultsDropDown}
          />
        )}
      </div>
      {isTooNarrowForDropDown && (
        <NavSearchResultsDropDown
          className="nav-search-results-container--full-width"
          data={autocompleteSuggestions}
          loading={isFetchingAutocomplete}
          onListItemClick={onListItemClick}
          show={showResultsDropDown}
          style={{ top: topNavContainerHeight }}
        />
      )}
    </>
  );
};
