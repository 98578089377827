import { useCallback, useState } from "react";
import useModal from "../../../hooks/useModal";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { PaginatedScheduledProject } from "../../../store/models/scheduledproject";
import { BookingQueryParamKeys } from "../../screens/BookingsScreen/hooks";

/**
 * Hook to open and close the booking requested panel and manage its state.
 * @returns an object with the following properties:
 * - `isBookingRequestedPanelOpen`: a boolean indicating whether the panel is open
 * - `handleOpenBookingRequestedPanel`: a function to open the panel with a given scheduled project
 * - `handleCloseBookingRequestedPanel`: a function to close the panel
 * - `scheduledProjectQuery`: the query parameter for the scheduled project ID
 * - `selectedScheduledProject`: the currently selected scheduled project
 */
export const useBookingRequestedPanel = () => {
  const [selectedScheduledProject, setSelectedScheduledProject] =
    useState<PaginatedScheduledProject | null>(null);
  const {
    closeModal: closePanel,
    isOpen: isBookingRequestedPanelOpen,
    openModal: openPanel,
  } = useModal();
  const scheduledProjectQuery = useQueryParam(
    BookingQueryParamKeys.ScheduledProjectId,
  );

  const handleOpenBookingRequestedPanel = useCallback(
    (project: PaginatedScheduledProject) => {
      setSelectedScheduledProject(project);
      scheduledProjectQuery.set(project.id.toString());
      openPanel();
    },
    [openPanel, scheduledProjectQuery, setSelectedScheduledProject],
  );

  const handleCloseBookingRequestedPanel = () => {
    setSelectedScheduledProject(null);
    scheduledProjectQuery.remove();
    closePanel();
  };

  const scheduledProjectIdQueryString = scheduledProjectQuery.get() ?? "";
  const selectedScheduledProjectId = scheduledProjectIdQueryString
    ? parseInt(scheduledProjectIdQueryString)
    : null;

  return {
    isBookingRequestedPanelOpen,
    handleOpenBookingRequestedPanel,
    handleCloseBookingRequestedPanel,
    selectedScheduledProjectId,
    selectedScheduledProject,
  };
};
