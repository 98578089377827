import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";

const selectTrophyStore = (state: RootState) => state.trophyService;

/*
 * Use hooks/useFetchTrophies instead
 */
export const selectUserTrophies = (userId?: number) =>
  createDraftSafeSelector(selectTrophyStore, (trophyService) =>
    userId ? trophyService.userTrophies[userId] : undefined,
  );

/*
 * Use hooks/useFetchTrophies instead
 */
export const selectStudioTrophies = (studioId?: number) =>
  createDraftSafeSelector(selectTrophyStore, (trophyService) =>
    studioId ? trophyService.studioTrophies[studioId] : undefined,
  );

/*
 * Use hooks/useFetchTrophies instead
 */
export const selectStudioRoomTrophies = (studioRoomId?: number) =>
  createDraftSafeSelector(selectTrophyStore, (trophyService) =>
    studioRoomId ? trophyService.studioRoomTrophies[studioRoomId] : undefined,
  );
