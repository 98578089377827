import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { handleBudget } from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { DetailedPurchaseOrderWithTransaction } from "../../../store/models/project";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { EditableTextArea } from "../../elements/EditableTextArea/EditableTextArea";
import { OrderBreakdown } from "../OrderBreakdown/OrderBreakdown";
import {
  BudgetManagerModalH5,
  BudgetManagerModalP,
} from "./BudgetManagerModals.styles";

export interface BudgetModalProps {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  code?: string | null;
  onBudgetUpdated: (
    updatedPurchaseOrder: DetailedPurchaseOrderWithTransaction,
  ) => void;
}

export const ApproveBudgeModal = ({
  isOpen,
  openModal,
  closeModal,
  code,
  onBudgetUpdated,
}: BudgetModalProps) => {
  const dispatch = useAppDispatch();
  const [submittingDetails, setSubmittingDetails] = useState(false);
  const {
    orderSummary: recordingOrderSummary,
    isLoading: recordingOrderSummaryIsLoading,
  } = useAppSelector((state) => state.recordingCartsStore);
  const {
    orderSummary: mixMasterOrderSummary,
    isLoading: mixMasterOrderSummaryIsLoading,
  } = useAppSelector((state) => state.mixMasterCartsStore);

  const approveBudget = useCallback(async () => {
    if (!code) {
      toast.error("Missing code");
      return;
    }
    setSubmittingDetails(true);
    try {
      const updatedPurchaseOrder = await dispatch(
        handleBudget({
          code: code,
          approve_budget: true,
        }),
      ).unwrap();
      onBudgetUpdated(updatedPurchaseOrder);
    } finally {
      setSubmittingDetails(false);
      closeModal();
    }
  }, [code, dispatch]);

  return (
    <BaseModal
      header={
        recordingOrderSummary
          ? "Approve studio session budget"
          : "Approve project budget"
      }
      open={isOpen}
      setOpen={isOpen ? closeModal : openModal}
      onConfirm={approveBudget}
      onCancel={closeModal}
      showModalFooter={true}
      loading={submittingDetails}
      confirmText={"Approve Budget"}
      cancelText={"Cancel"}
    >
      <BudgetManagerModalH5>
        Are you sure you want to approve this budget?
      </BudgetManagerModalH5>
      <OrderBreakdown
        isLoading={
          recordingOrderSummaryIsLoading || mixMasterOrderSummaryIsLoading
        }
        showLabelToggle={false}
        showHeader={false}
        orderSummary={recordingOrderSummary ?? mixMasterOrderSummary}
      />
    </BaseModal>
  );
};

export const RejectBudgetModal = ({
  isOpen,
  openModal,
  closeModal,
  code,
  onBudgetUpdated,
}: BudgetModalProps) => {
  const { orderSummary: recordingOrderSummary } = useAppSelector(
    (state) => state.recordingCartsStore,
  );
  const [submittingDetails, setSubmittingDetails] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const dispatch = useAppDispatch();

  const handleReject = useCallback(async () => {
    if (!code) {
      toast.error("Missing code");
      return;
    }

    try {
      setSubmittingDetails(true);
      const updatedPurchaseOrder = await dispatch(
        handleBudget({
          code: code,
          approve_budget: false,
          rejection_reason: rejectionReason,
        }),
      ).unwrap();
      onBudgetUpdated(updatedPurchaseOrder);
    } finally {
      setSubmittingDetails(false);
      closeModal();
    }
  }, [code, dispatch, rejectionReason]);

  return (
    <BaseModal
      header={
        recordingOrderSummary
          ? "Reject studio session budget"
          : "Reject project budget"
      }
      open={isOpen}
      setOpen={isOpen ? closeModal : openModal}
      onConfirm={handleReject}
      onCancel={closeModal}
      showModalFooter={true}
      loading={submittingDetails}
      confirmText={"Reject Budget"}
      cancelText={"Cancel"}
    >
      <BudgetManagerModalH5>
        Are you sure you want to reject this budget?
      </BudgetManagerModalH5>
      <BudgetManagerModalP>
        This action will block this project from proceeding.
      </BudgetManagerModalP>
      <EditableTextArea
        numberOfLines={5}
        placeholder="Provide a reason for rejecting budget."
        initialValue={rejectionReason}
        editMode={true}
        onChange={(value) => setRejectionReason(value)}
      />
    </BaseModal>
  );
};
