import { useAppSelector } from "../../../store/hooks";
import { RegisterUnauthenticatedUserName } from "../RegisterUnauthenticatedUserName/RegisterUnauthenticatedUserName";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";

export interface TrackTableReplyButtonProps {
  isLoading: boolean;
  isDisabled: boolean;
  handleReply: () => void;
}

export const TrackTableReplyButton = ({
  isLoading,
  isDisabled,
  handleReply,
}: TrackTableReplyButtonProps) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const unauthenticatedUserName = useAppSelector(
    (state) => state.unauthenticatedUserStateSlice.name,
  );

  if (!user && !unauthenticatedUserName) {
    return (
      <RegisterUnauthenticatedUserName
        triggerComponent={
          <Button
            loading={isLoading}
            variant={ButtonVariant.OUTLINED}
            disabled={isDisabled}
          >
            Reply
          </Button>
        }
      />
    );
  } else {
    return (
      <Button
        loading={isLoading}
        onClick={handleReply}
        variant={ButtonVariant.OUTLINED}
        disabled={isDisabled}
      >
        Reply
      </Button>
    );
  }
};
