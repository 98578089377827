import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";

const selectMarketingData = (state: RootState) => state.marketingDataStore;

export const selectPromoCodeDiscountMultiplier = createSelector(
  selectMarketingData,
  (data) => {
    const appliedPromoCode = data.appliedPromoCode;
    let discountMultiplier = 1.0;
    if (appliedPromoCode !== undefined) {
      discountMultiplier = 1.0 - appliedPromoCode.discount_percentage;
    }
    return discountMultiplier;
  },
);
