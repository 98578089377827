import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { ProjectType } from "../models/project";
import { RecordingService } from "../models/recording";
import Service from "../models/service";

export const selectServices = (state: RootState) =>
  state.engineerServices.services;

const selectRecordingService = (state: RootState) =>
  state.engineerServices.recordingService;

const selectStudioRecordingServices = (state: RootState) =>
  state.engineerServices.studioRecordingServices;

export const selectEligibleServices = createSelector(
  selectServices,
  selectRecordingService,
  selectStudioRecordingServices,
  (
    services: Service[],
    recordingService: RecordingService | undefined,
    studioRecordingServices: RecordingService[],
  ) => {
    const primaryRecordingService =
      studioRecordingServices[0] ?? recordingService;
    return [...services, primaryRecordingService].filter(Boolean) as (
      | Service
      | RecordingService
    )[];
  },
);

export const selectEngineerHasDolbyServices = createSelector(
  selectServices,
  (services: Service[]): boolean =>
    services.some(
      (service) => service.service_type === ProjectType.ATMOS_MIXING,
    ),
);

export const selectEngineerServicesForCurrentEngineerLoaded = (
  engineerId: number | undefined,
) =>
  createSelector(
    selectServices,
    selectRecordingService,
    (services: Service[], recordingService: RecordingService | undefined) => {
      if (engineerId == null || (services.length === 0 && !recordingService)) {
        return false;
      }

      return (
        services.some((service) => service.engineer_id === engineerId) ||
        recordingService?.engineer?.id === engineerId
      );
    },
  );
