import {
  faChevronDown,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useAppSelector } from "../../../store/hooks";
import { Alt, altToDisplayString } from "../../../store/models/alts";
import { FileType, FileVersion } from "../../../store/models/fileVersion";
import {
  Project,
  ProjectById,
  ProjectUserType,
} from "../../../store/models/project";
import { getTrackVersionText } from "../../../store/utils/trackComments";
import { getUserTypeFromProject } from "../../../utils/projectUtils";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import {
  StyledDropDownContainer,
  StyledDropdownFontAwesomeIcon,
  StyledDropdownP,
  StyledDropdownSelectionButton,
} from "./FileDropdownSelection.styles";

export interface FileDropDownSelectionProps {
  selectedFile?: FileVersion;
  files: FileVersion[];
  onClick: (file: FileVersion) => void;
  project?: Project | ProjectById;
  isReferenceUpload?: boolean;
  projectTypeString: string;
  handleUpload?: (
    reference: boolean,
    uploadType: FileType.MP4 | FileType.WAV,
  ) => void;
}

export interface FilesDropDownProps {
  files: FileVersion[];
  selectedFile?: FileVersion;
  altType?: Alt;
  projectTypeString: string;
  onClick: (file: FileVersion) => void;
}
const FilesDropDown = ({
  files,
  selectedFile,
  altType,
  projectTypeString,
  onClick,
}: FilesDropDownProps) => {
  return (
    <>
      {altType !== undefined && (
        <StyledDropdownP selected={true}>
          {altToDisplayString[altType]}
        </StyledDropdownP>
      )}
      {files.map((file) => (
        <DropdownMenu.Item key={file.id}>
          <StyledDropdownSelectionButton onClick={() => onClick(file)}>
            <StyledDropdownP selected={selectedFile?.id === file.id}>
              {file.file_name ??
                `${file.reference ? `reference ${file.version}` : `${projectTypeString} ${file.version + 1}`}`}
            </StyledDropdownP>
          </StyledDropdownSelectionButton>
        </DropdownMenu.Item>
      ))}
    </>
  );
};
export const FileDropDownSelection = ({
  selectedFile,
  files,
  onClick,
  project,
  isReferenceUpload = false,
  projectTypeString,
  handleUpload,
}: FileDropDownSelectionProps) => {
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const isEngineer =
    getUserTypeFromProject(loggedInUser, project as ProjectById) ===
    ProjectUserType.ENGINEER;
  const isSuperUser = Boolean(loggedInUser?.is_superuser);
  const showUploadOptions = isEngineer || isSuperUser;

  const createFileVersionString = (file: FileVersion) => {
    const fileVersionText = file.file_name
      ? `${file.file_name} (${getTrackVersionText(file, project?.service_type)})`
      : null;

    const referenceText = file.reference
      ? `reference ${file.version + 1}`
      : `${projectTypeString} ${file.version + 1}`;

    return fileVersionText ?? referenceText;
  };

  const determineFileHeader = () => {
    if (!selectedFile && files.length === 0 && isReferenceUpload) {
      return `Waiting for reference upload`;
    }
    if (!selectedFile && files.length === 0) {
      return `${projectTypeString[0].toUpperCase() + projectTypeString.slice(1)}ed version`;
    }
    if (!selectedFile) {
      return <SoundWaveLoader width={50} height={50} />;
    }
    return createFileVersionString(selectedFile);
  };
  if (
    (isReferenceUpload || showUploadOptions) &&
    !selectedFile &&
    project &&
    files.length === 0
  ) {
    return (
      <>
        <StyledDropdownSelectionButton
          onClick={() => {
            if (handleUpload) handleUpload(isReferenceUpload, FileType.WAV);
          }}
        >
          <StyledDropdownFontAwesomeIcon icon={faCloudArrowUp} />
          <StyledDropdownP
            selected={true}
          >{`Upload ${isReferenceUpload ? "reference track" : `completed ${projectTypeString}`}`}</StyledDropdownP>
        </StyledDropdownSelectionButton>
      </>
    );
  }

  const mainAltsFiles = files.filter((file) => file.alt === Alt.MAIN);
  const cleanAltsFiles = files.filter((file) => file.alt === Alt.CLEAN);

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <StyledDropdownSelectionButton>
          <StyledDropdownP selected>{determineFileHeader()}</StyledDropdownP>
          {Boolean(files.length) && (
            <StyledDropdownFontAwesomeIcon icon={faChevronDown} size="2xs" />
          )}
        </StyledDropdownSelectionButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <StyledDropDownContainer>
          {!isReferenceUpload && mainAltsFiles.length > 0 && (
            <FilesDropDown
              files={mainAltsFiles}
              selectedFile={selectedFile}
              altType={Alt.MAIN}
              projectTypeString={projectTypeString}
              onClick={onClick}
            />
          )}
          {!isReferenceUpload && cleanAltsFiles.length > 0 && (
            <FilesDropDown
              files={cleanAltsFiles}
              selectedFile={selectedFile}
              altType={Alt.CLEAN}
              projectTypeString={projectTypeString}
              onClick={onClick}
            />
          )}
          {isReferenceUpload && (
            <FilesDropDown
              files={files}
              selectedFile={selectedFile}
              projectTypeString={projectTypeString}
              onClick={onClick}
            />
          )}
          {handleUpload && (
            <DropdownMenu.Item key={-1}>
              <StyledDropdownSelectionButton
                onClick={() => {
                  if (handleUpload)
                    handleUpload(isReferenceUpload, FileType.WAV);
                }}
              >
                <StyledDropdownP
                  selected={true}
                >{`Upload ${isReferenceUpload ? "Reference" : projectTypeString}`}</StyledDropdownP>
              </StyledDropdownSelectionButton>
            </DropdownMenu.Item>
          )}
        </StyledDropDownContainer>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
