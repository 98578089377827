export const DollarFormatter = function (currency = "usd"): Intl.NumberFormat {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency.toUpperCase(),
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const PennyDollarFormatter = function (
  currency = "usd",
): Intl.NumberFormat {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency.toUpperCase(),
    minimumFractionDigits: 2,
  });
};
