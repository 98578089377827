import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface FormTagComponentProps {
  email: string;
  index: number;
  removeFunc: (email: string, index: number) => void;
  className?: string;
  onClickFunc?: (email: string) => void;
}

const FormTagComponent: FC<FormTagComponentProps> = ({
  email,
  index,
  removeFunc,
  className,
  onClickFunc,
}) => {
  return (
    <div
      data-tag
      key={index}
      className={className || ""}
      onClick={onClickFunc ? () => onClickFunc(email) : undefined}
    >
      <div data-tag-item>{email}</div>
      <span
        data-tag-handle
        onClick={(e) => {
          e.stopPropagation();
          removeFunc(email, index);
        }}
      >
        <FontAwesomeIcon
          icon={faXmark}
          color={"var(--medium-grey)"}
          style={{ cursor: "pointer" }}
        />
      </span>
    </div>
  );
};

export default FormTagComponent;
