import { Button, ButtonProps } from "./Button";

type FixedMinWidthButtonProps = ButtonProps & {
  minWidth?: string;
};
export const FixedMinWidthButton = ({
  minWidth = "140px",
  ...restProps
}: FixedMinWidthButtonProps) => {
  return <Button style={{ minWidth }} {...restProps} />;
};
