import styled from "styled-components";

export const CreatePromoCodeFormWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const PromoCodeFormActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  gap: 0.5rem;
`;
