import { styled, Box } from "@mui/material";

export const ManagePaymentSettingsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

export const ManagePaymentSettingsHeader = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
}));

export const ManagePaymentSettingsContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
}));
