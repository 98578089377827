import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { ErrorsAction } from "../../store/actions/errorStore";
import {
  updateFeaturedTrack,
  updateFeaturedTrackParams,
} from "../../store/actions/portfolio";
import { PortfolioFeatureData } from "../../store/models/portfolio";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { useAppDispatch } from "../../store/hooks";

interface useUpdateFeaturedFeaturedTrackProps
  extends Omit<
    UseMutationOptions<
      PortfolioFeatureData,
      ErrorsAction,
      updateFeaturedTrackParams
    >,
    "onSuccess" | "mutationFn"
  > {
  userId?: number;
  onSuccess?: () => void;
}

export const useUpdateFeaturedTrack = ({
  userId,
  onSuccess,
}: useUpdateFeaturedFeaturedTrackProps) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: async (args: updateFeaturedTrackParams) => {
      if (!userId) return;
      const result = await dispatch(updateFeaturedTrack(args)).unwrap();
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_PAGINATED_FEATURED_TRACKS, userId],
      });
      if (onSuccess) onSuccess();
    },
  });
};
