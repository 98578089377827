import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { Alt } from "../../../store/models/alts";
import Service from "../../../store/models/service";
import User from "../../../store/models/user";
import { OrderSummaryCard } from "../../components/OrderSummaryCard/OrderSummaryCard";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import "./StripePaymentScreen.css";
import { ProjectHandoffProps } from "../../components/ProjectHandoff/ProjectHandoff";
import { PrevButton } from "../../elements/PrevButton/PrevButton";
import { PaymentGatewayModule } from "../../components/PaymentGatewayModule/PaymentGatewayModule";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import {
  getMyBookingsRoute,
  getProjectOverviewRoute,
} from "../../../store/utils/routeGetters";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

export interface StripePaymentScreenProps extends ProjectHandoffProps {
  engineerUser: User;
  alts: Set<Alt>;
  service: Service;
  onClickPreviousStep?: () => void;
  showPromoField: boolean;
  projectTitle?: string;
  artistName?: string;
  onClickApplyPromoCode?: (promoCode?: string) => void;
  scheduledProjectIdForHandoff?: number;
}
/**
 * @deprecated
 * should be removed from codebase
 * https://engineears.atlassian.net/browse/EN-3899
 */
export const StripePaymentScreen = ({
  engineerUser,
  alts,
  service,
  onClickPreviousStep,
  showPromoField,
  projectTitle,
  artistName,
  scheduledProjectIdForHandoff,
}: StripePaymentScreenProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const history = useHistory();
  const { isLoading } = useAppSelector((state) => state.transactionStore);
  const { transactionData } = useAppSelector((state) => state.transactionStore);
  const darkMode = useAtomValue(darkModeAtom);

  // TODO: Handle following logic
  // const amount = useTransactionTotalAsPennies(transactionData);
  // const projects =
  //   transactionData?.items?.reduce((acc, curr) => {
  //     if (curr.project) {
  //       return [...acc, curr.project];
  //     }
  //     return acc;
  //   }, [] as Project[]) ?? [];

  // If true, display button to create payment plan
  // const earliestStartDate = useMemo(() => {
  //   const projectsWithFirstPastDate = projects.filter((project) =>
  //     Boolean(project.first_pass_date),
  //   );
  //   const sortedProjects = projectsWithFirstPastDate.sort((a, b) => {
  //     if (a.first_pass_date && b.first_pass_date) {
  //       return (
  //         new Date(a.first_pass_date).getTime() -
  //         new Date(b.first_pass_date).getTime()
  //       );
  //     }
  //     return 0;
  //   });
  //   const firstSortedProject = sortedProjects.shift();
  //   if (!firstSortedProject || !firstSortedProject.first_pass_date) {
  //     return new Date();
  //   }
  //   return new Date(firstSortedProject.first_pass_date);
  // }, [projects]);

  // const isEarliestStartDateEligibleForPaymentPlan = useMemo(() => {
  //   return isAtLeastNDaysAway(earliestStartDate);
  // }, [earliestStartDate]);

  const { isDesktop } = useMediaQueryBreakpoint();

  if (transactionData?.hosted_invoice_url) {
    return <div></div>;
  }

  return (
    <div
      data-theme={darkMode ? "dark" : ""}
      className="checkout-screen"
      style={{ marginTop: 0 }}
    >
      <div className="checkout-card-row-container payment-screen">
        {isLoading || !transactionData ? (
          <div className="checkout-loading-page">
            <p>Setting up your project... please stand by</p>
            <SoundWaveLoader width={100} height={100} />
          </div>
        ) : (
          <>
            <div className="order-summary-prev-button">
              {onClickPreviousStep && (
                <PrevButton onClickPreviousStep={onClickPreviousStep} />
              )}
            </div>
            <div className="payment-screen-column mb-4">
              <OrderSummaryCard
                engineerUser={engineerUser}
                transactionData={transactionData}
                alts={alts}
                service={service}
                showPromoField={showPromoField}
              ></OrderSummaryCard>
            </div>
            <div className="payment-screen-column">
              <PaymentGatewayModule
                isMobile={!isDesktop}
                transaction={transactionData}
                artistName={artistName}
                projectTitle={projectTitle}
                scheduledProjectIdForHandoff={scheduledProjectIdForHandoff}
              >
                <Button
                  fullWidth={true}
                  onClick={() => {
                    if (scheduledProjectIdForHandoff) {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                      history.push(
                        getProjectOverviewRoute(scheduledProjectIdForHandoff),
                      );
                    } else {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                      history.push(getMyBookingsRoute("projects"));
                    }
                  }}
                  variant={ButtonVariant.PRIMARY}
                >
                  Go to project
                </Button>
              </PaymentGatewayModule>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
