import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import {
  updateProfile,
  verifyAccountPhoneNumber,
} from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import {
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import FooterContent from "./FooterContent";
import VerificationCodeInput from "./VerificationCodeInput";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";

export const VerifyPhone = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.accountInfo);
  const authNavigation = useAuthNavigationSteps();
  const { nextStep, prevStep } = authNavigation();
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [resentVerification, setResentVerification] = useState(false);

  useEffect(() => {
    if (resentVerification) {
      const timer = setTimeout(() => {
        setResentVerification(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [resentVerification]);

  const handleVerified = () => {
    setVerificationError(false);
    setPhoneVerified(true);
  };

  const handleVerificationError = () => {
    setPhoneVerified(false);
    setVerificationError(true);
  };

  const onComplete = (verificationCode: string) => {
    if (!user?.id) return;
    setIsLoading(true);
    dispatch(
      verifyAccountPhoneNumber({
        verification: verificationCode,
      }),
    )
      .unwrap()
      .then(() => {
        emitAnalyticsTrackingEvent("auth_verify_phone_used_code", {}, user?.id);
        handleVerified();
        nextStep();
      })
      .catch(() => {
        emitAnalyticsTrackingEvent(
          "auth_verify_phone_used_code_error",
          {},
          user?.id,
        );
        handleVerificationError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    if (phoneVerified) {
      emitAnalyticsTrackingEvent(
        "auth_verify_phone_clicked_submit",
        {},
        user?.id,
      );
      nextStep();
    }
  };

  const handleResendVerifyPhone = () => {
    setResendLoading(true);
    dispatch(updateProfile({ phone_number: user?.phone_number?.phone_number }))
      .then(() => {
        emitAnalyticsTrackingEvent(
          "auth_verify_phone_clicked_resend",
          {},
          user?.id,
        );
        setResentVerification(true);
      })
      .finally(() => setResendLoading(false));
  };

  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <Text variant={TextStyleVariant.H6}>Confirm your phone</Text>
        {user?.phone_number && (
          <Text variant={TextStyleVariant.P1}>
            Enter the verification code we sent to:
            <br />
            {user.phone_number.phone_number}
          </Text>
        )}
        <Button
          variant={ButtonVariant.TEXT}
          style={{ fontWeight: "600" }}
          onClick={() => {
            emitAnalyticsTrackingEvent(
              "auth_verify_phone_clicked_use_different_phone",
              {},
              user?.id,
            );
            prevStep();
          }}
        >
          (Use a different phone)
        </Button>
        <VerificationCodeInput codeLength={6} onComplete={onComplete} />
        {verificationError && (
          <Text variant={TextStyleVariant.P1} color={TextColor.ERROR}>
            <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faXmark} />
            Invalid verification code
          </Text>
        )}
        {phoneVerified && (
          <Text variant={TextStyleVariant.P1} color={TextColor.SUCCESS}>
            <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faCheck} />
            Verified!
          </Text>
        )}
        {resendLoading ? (
          <SoundWaveLoader width={100} height={100} />
        ) : resentVerification ? (
          <Text variant={TextStyleVariant.P1} color={TextColor.SUCCESS}>
            <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faCheck} />
            Verification code resent
          </Text>
        ) : (
          <Button
            variant={ButtonVariant.TEXT}
            style={{ display: "inline", fontWeight: "600" }}
            onClick={() => handleResendVerifyPhone()}
            loading={isLoading}
          >
            Resend a code
          </Button>
        )}
        <Button
          loading={isLoading}
          fullWidth
          onClick={handleSubmit}
          disabled={!phoneVerified || isLoading}
        >
          Submit
        </Button>
        <Button
          style={{
            alignSelf: "flex-end",
            fontWeight: "600",
            color: theme.palette.text.primary,
          }}
          variant={ButtonVariant.TEXT}
          onClick={() => {
            emitAnalyticsTrackingEvent(
              "auth_verify_phone_clicked_remind_me_later",
              {},
              user?.id,
            );
            nextStep({ skipVerification: true });
          }}
        >
          Remind me later
        </Button>
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
