import styled from "styled-components";

export const NavSpacerWrapper = styled.div<{
  $navItemsHeight?: number;
  $darkMode?: boolean;
}>`
  width: 100%;
  background-color: ${({ theme, $darkMode }) =>
    $darkMode ? theme.colorPalette.Black : theme.backgroundColor};
  min-height: ${({ $navItemsHeight }) => $navItemsHeight}px;
`;
