import iso3311a2 from "iso-3166-1-alpha-2";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { useGetTeams } from "../../../hooks/useTeam";
import { setupStripeAccount } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import {
  getCountriesAsDropdownOptions,
  getCountryIndex,
} from "../../../utils/countryUtils";
import "../../components/ProjectStepper/ProjectStepper.css";
import {
  ENGINEER_SETUP_STEPS,
  EngineerSetupSteps,
} from "../../components/ProjectStepper/Steps";
import { Button } from "../../core-ui/components/Button/Button";
import { DropdownSelector } from "../../elements/DropDownSelector/DropdownSelector";
import "./StripeSetupRedirect.css";

export const StripeSetupRedirect = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  useGetTeams();

  useSetPageTitle(undefined);

  const [onBoardingUrl, setOnBoardingUrl] = useState("");
  const user = useAppSelector((state) => state.accountInfo.user);
  const meetsStripeRequirements = useAppSelector(
    (state) =>
      state.accountInfo.meetsStripeRequirements ||
      state.selectedProfileSlice?.studio?.meets_stripe_requirements,
  );

  const [loading, setLoading] = useState(false);
  const userCountryCode = useAppSelector(
    (state) => state.accountInfo.user?.country_code,
  );
  const [selectedCountry, setSelectedCountry] = useState(
    userCountryCode
      ? iso3311a2.getCountry(userCountryCode) ?? "United States"
      : "United States",
  );

  useEffect(() => {
    if (!onBoardingUrl) return;
    window.location.href = onBoardingUrl;
  }, [onBoardingUrl]);

  let isOnboarding = false;
  if (location.state) {
    const locationState = location.state as {
      isOnboarding: boolean;
    };
    isOnboarding = locationState.isOnboarding;
  }

  return (
    <div className="container-fluid centered-screen stripe-setup-redirect-container">
      {isOnboarding && (
        <>
          <p className="mt-3 mb-3 label2">Steps to get started</p>
          <div className="setup-stepper-container">
            <EngineerSetupSteps step={ENGINEER_SETUP_STEPS.StripeSetup} />
          </div>
        </>
      )}
      {!meetsStripeRequirements ? (
        <h2 className="mb-5 text-center">Let&apos;s get you paid!</h2>
      ) : (
        <h2 className="mb-5 text-center">
          You&apos;re all set to receive payment!
        </h2>
      )}
      <p>
        <strong>
          {!meetsStripeRequirements && user?.stripe_account_id
            ? "You're almost there. Some information is still missing within the Stripe portal. "
            : ""}
        </strong>
      </p>
      {!meetsStripeRequirements && (
        <div>
          <p>Click to redirect to the Stripe account setup portal.</p>
          <p>
            Provide <strong>all</strong> information required for verification.
          </p>
        </div>
      )}
      <Button
        loading={loading}
        className="mt-4"
        onClick={() => {
          setLoading(true);
          void dispatch(
            setupStripeAccount({
              country_code: iso3311a2.getCode(selectedCountry) ?? undefined,
            }),
          )
            .unwrap()
            .then((data) => {
              window.analytics.track(
                getDebugEventPrefix + "setup_stripe_connect",
                {
                  user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
                  country_code: `${iso3311a2.getCode(selectedCountry)}`,
                },
              );
              setOnBoardingUrl(data.url);
            });
        }}
      >
        {user?.stripe_account_id ? "Finish Stripe Setup" : "Set up Stripe"}
      </Button>
      <p className="mt-4 b3 text-center">
        Not in the United States? Select your country below
      </p>
      <div className="country-selector-div mt-2">
        <DropdownSelector
          value={{
            value: getCountryIndex(selectedCountry),
            label: selectedCountry,
          }}
          options={getCountriesAsDropdownOptions()}
          placeholder={"Select One"}
          onChange={(option) => {
            setSelectedCountry(option.label);
          }}
        />
      </div>
    </div>
  );
};
