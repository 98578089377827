import { ScheduledProject } from "../../../../store/models/scheduledproject";
import {
  InfoBadge,
  InfoBadgeType,
} from "../../../core-ui/components/InfoBadge/InfoBadge";

const ProjectHeaderInfoBadge = ({
  isInProgressProject,
  isPartiallyPaid,
  scheduledProject,
  userIsEngineerOnScheduledProject,
  redirectToPurchaseProject,
}: {
  isInProgressProject: boolean;
  isPartiallyPaid: boolean;
  scheduledProject: ScheduledProject;
  userIsEngineerOnScheduledProject: boolean;
  redirectToPurchaseProject: any;
}) => {
  if (!isInProgressProject && !isPartiallyPaid) return null;
  return (
    <InfoBadge
      onClick={
        userIsEngineerOnScheduledProject
          ? undefined
          : () => {
              void redirectToPurchaseProject().catch(() => {});
            }
      }
      type={
        isInProgressProject
          ? InfoBadgeType.NOT_FUNDED
          : InfoBadgeType.PARTIALLY_PAID
      }
      outstandingBalance={scheduledProject.outstanding_balance}
      userIsEngineerOnProject={userIsEngineerOnScheduledProject}
      paywallOption={scheduledProject.default_paywall_option}
      showToolTip
    />
  );
};

export default ProjectHeaderInfoBadge;
