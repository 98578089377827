import { useEffect, useState } from "react";

export const useUsernameCheck = (username: string) => {
  const [hasSpace, setHasSpace] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);

  useEffect(() => {
    if (username.length < 1) {
      setHasSpace(true);
      setHasSpecialCharacter(true);
      return;
    }
    setHasSpace(/\s/.test(username));
    setHasSpecialCharacter(/[^a-zA-Z0-9_-]/.test(username));
  }, [username]);

  return [hasSpace, hasSpecialCharacter];
};
