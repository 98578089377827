import { FETCH_STUDIO_ROOMS } from "../../../store/utils/routes";
import { StudioRoom } from "../../../store/models/studio";
import { makeBackendGetCallWithJsonResponse } from "../../../store/utils/fetch";
import { EngineEarsError } from "../../../store/actions/errorStore";

export const fetchStudioRooms = async ({
  username,
  studioId,
}: {
  username?: string;
  studioId?: number;
}): Promise<StudioRoom[] | EngineEarsError> => {
  const params = `?${studioId ? `studio_id=${studioId}` : `studio_username=${username}`}`;
  return makeBackendGetCallWithJsonResponse<StudioRoom[]>(
    FETCH_STUDIO_ROOMS,
    params,
  ).then((res) => {
    if (res.success) {
      return res.resultJson;
    }
    return res.resultJson;
  });
};
