import React, { useState, useEffect, useMemo } from "react";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { UMGSubLabelEnum, UMGSubLabelMap } from "../../../store/models/trophy";
import "./LabelAssociationDropdown.css";
import { useAppSelector } from "../../../store/hooks";
import { PaymentGatewayModuleBoldP } from "../PaymentGatewayModule/PaymentGatewayModule.styles";

export interface LabelOption extends OptionType {
  source: string;
  umg_sub_label: UMGSubLabelEnum;
}

interface LabelAssociationDropdownProps {
  onChange: (value: LabelOption) => void;
}

const CustomOption = (props: LabelOption) => {
  return (
    <div className="label-association-option">
      <span>{props.label}</span>
      {props.source ? <img src={props.source} alt={props.label} /> : <div />}
    </div>
  );
};

export const LabelAssociationDropdown = ({
  onChange,
}: LabelAssociationDropdownProps) => {
  const labelOptions = useMemo(() => {
    const options: LabelOption[] = [];
    for (const [key, value] of UMGSubLabelMap) {
      options.push({
        value: key,
        label: value.text,
        source: value.source,
        umg_sub_label: key,
      });
    }
    return options;
  }, []);

  const [selectedOption, setSelectedOption] = useState<LabelOption>();

  const labelAssociations = useAppSelector(
    (state) => state.accountInfo.user?.aandr?.label,
  );

  useEffect(() => {
    if (labelAssociations && labelAssociations.length) {
      const userLabelOption = labelOptions.find(
        (option) => option.umg_sub_label === labelAssociations[0].umg_sub_label,
      );
      setSelectedOption(userLabelOption);
    }
  }, [labelAssociations, labelOptions]);

  return (
    <div className="mb-3">
      <PaymentGatewayModuleBoldP>Label</PaymentGatewayModuleBoldP>
      <DropdownSelector
        value={selectedOption}
        options={labelOptions}
        customComponents={(option) => {
          const labelOption = option as LabelOption;
          return <CustomOption {...labelOption} />;
        }}
        onChange={(option) => {
          const labeOption = option as LabelOption;
          setSelectedOption(labeOption);
          onChange(labeOption);
        }}
      />
    </div>
  );
};
