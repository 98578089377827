import { Box, styled } from "@mui/material";

export const LoggedOutBannerContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  maxHeight: "285px",
}));

export const LoggedOutBannerOverlay = styled(Box)(() => ({
  background: `linear-gradient(
              hsla(0, 0%, 0%, 0) 0%,
              hsla(0, 0%, 0%, 0.002) 1.8%,
              hsla(0, 0%, 0%, 0.5) 10%,
              hsla(0, 0%, 0%, 0.738) 31%,
              hsl(0, 0%, 0%) 100%
            )`,
  mixBlendMode: "multiply",
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  zIndex: 3,
}));
