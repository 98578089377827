import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { getMusoCredit } from "../../store/actions/muso";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import User from "../../store/models/user";
import { selectCurrentUser } from "../../store/selectors/userInfoSelectors";

export const useGetVerifiedCredits = (
  userData: User | undefined,
  refresh = false,
) => {
  const user = useAppSelector(selectCurrentUser);
  const isUserEngineer = Boolean(
    userData?.engineer && !userData?.engineer.deleted,
  );
  const dispatch = useAppDispatch();
  return useQuery({
    staleTime:
      !refresh && user && userData && user.id === userData.id
        ? Infinity
        : undefined,
    enabled: Boolean(userData?.id) && isUserEngineer,
    queryKey: [QUERY_KEYS.GET_USER_VERIFIED_MUSO_CREDITS, userData?.id || 0],
    queryFn: async () => {
      if (!userData) return [];
      const response = await dispatch(
        getMusoCredit({
          user_id: userData.id,
        }),
      ).unwrap();
      return response.data;
    },
  });
};
