import { useCallback, useEffect } from "react";
import {
  usePaginatedReviews,
  useUserReviews,
} from "../../../hooks/useUserReviews";
import { downloadReviewStats } from "../../../store/actions/stats";
import { useAppDispatch } from "../../../store/hooks";
import Artist from "../../../store/models/artist";
import Engineer from "../../../store/models/engineer";
import { Studio, StudioRoom } from "../../../store/models/studio";
import blackStar from "../../assets/star_filled.svg";
import whiteStar from "../../assets/star_filled_white.svg";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { ReviewComponent } from "../ReviewComponent/ReviewComponent";
import "./ReviewsList.css";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

export interface ReviewsListProps {
  engineer?: Engineer;
  artist?: Artist;
  studio?: Studio;
  studioRoom?: StudioRoom;
  hideIfNoReviews?: boolean;
}

export const ReviewsList = ({
  engineer,
  artist,
  studio,
  studioRoom,
  hideIfNoReviews = false,
}: ReviewsListProps) => {
  // default order_by.
  const order_by = "-created";
  const isDarkMode = useAtomValue(darkModeAtom);
  const dispatch = useAppDispatch();

  const reviewsState = useUserReviews(artist, engineer, studio, studioRoom);
  const {
    loading: loading,
    paginating: paginating,
    current_page: currentPage,
    total_pages: totalPages,
    ratings_count: ratingsCount,
    average_rating: averageRating,
  } = reviewsState || {
    paginating: false,
    loading: false,
    current_page: 0,
    total_pages: 0,
    average_rating: 0,
  };
  const canLoadMore: boolean = currentPage < totalPages;

  const reviews = usePaginatedReviews(artist, engineer, studio, studioRoom);

  // load more will just get the next paginated set of reviews
  const loadMore = useCallback(() => {
    if (!canLoadMore) return;
    if (engineer) {
      void dispatch(
        downloadReviewStats({
          engineer_id: engineer.id,
          page: currentPage + 1,
          order_by,
        }),
      );
    } else if (artist) {
      void dispatch(
        downloadReviewStats({
          artist_id: artist.id,
          page: currentPage + 1,
        }),
      );
    } else if (studio) {
      void dispatch(
        downloadReviewStats({
          studio_id: studio.id,
          page: currentPage + 1,
          order_by,
        }),
      );
    } else if (studioRoom) {
      void dispatch(
        downloadReviewStats({
          studio_room_id: studioRoom.id,
          page: currentPage + 1,
          order_by,
        }),
      );
    }
  }, [
    dispatch,
    canLoadMore,
    currentPage,
    engineer,
    artist,
    studio,
    studioRoom,
  ]);

  useEffect(() => {
    if (engineer && engineer.deleted === null) {
      void dispatch(
        downloadReviewStats({
          engineer_id: engineer.id,
          page: 1,
          order_by,
        }),
      );
    } else if (artist) {
      void dispatch(
        downloadReviewStats({
          artist_id: artist.id,
          page: 1,
          order_by,
        }),
      );
    } else if (studio) {
      void dispatch(
        downloadReviewStats({
          studio_id: studio.id,
          page: 1,
          order_by,
        }),
      );
    } else if (studioRoom) {
      void dispatch(
        downloadReviewStats({
          studio_room_id: studioRoom.id,
          page: 1,
          order_by,
        }),
      );
    }
  }, [artist, dispatch, engineer, studio, studioRoom]);

  if (
    hideIfNoReviews &&
    ratingsCount != null &&
    ratingsCount <= 0 &&
    !canLoadMore
  ) {
    return null;
  }

  return (
    <div className="reviews-list-container">
      <div className="reviews-list-header">
        <div className="reviews-list-average-rating">
          <img src={isDarkMode ? whiteStar : blackStar} />
          {averageRating}
        </div>
        <div className="reviews-list-ratings-count">
          <span>
            {ratingsCount === 1
              ? `1 Total Review`
              : `${ratingsCount} Total Reviews`}
          </span>
        </div>
      </div>
      <div className="reviews-list-reviews-container">
        {reviews.map((review) => (
          <div key={review.id} className="reviews-list-review-wrapper">
            <ReviewComponent review={review} />
          </div>
        ))}
      </div>
      {loading && <SoundWaveLoader width={100} height={100} />}
      <div className="reviews-list-load-more">
        <Button
          disabled={currentPage >= totalPages}
          onClick={loadMore}
          variant={ButtonVariant.OUTLINED}
          loading={loading || paginating}
        >
          Load More Reviews
        </Button>
      </div>
    </div>
  );
};
