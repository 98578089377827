import { useMemo } from "react";
import "./TeamPanel.css";
import {
  AffiliationInvite,
  Team,
  TeamRole,
} from "../../../store/models/admins";
import User from "../../../store/models/user";
import TeamMember from "../../elements/TeamMember/TeamMember";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { DashboardCardHeader } from "../DashboardCardHeader/DashboardCardHeader";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { useGetTeamOrg } from "../../../hooks/useTeam";
import {
  removeTeamInvite,
  revokeTeamInvite,
} from "../../../store/actions/team";
import { useIsAdmin } from "../../../hooks/useIsAdmin";
import { selectUserCanEnableStudioServices } from "../../../store/selectors/userInfoSelectors";
import InviteTeammateButton from "../InviteTeammate/InviteTeammateButton";

export interface TeamPanelProps {
  currentTeam: Team | undefined;
  isLoading: boolean;
  onSidePanel?: boolean;
  rowLimit?: number;
  pendingRowLimit?: number;
}

export const TeamPanel = ({
  currentTeam,
  isLoading,
  onSidePanel = false,
  rowLimit,
  pendingRowLimit,
}: TeamPanelProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const canManageStudios = useAppSelector(selectUserCanEnableStudioServices);
  const isAnAdmin = useIsAdmin(user);
  const isLabel = useMemo(() => {
    return Boolean(currentTeam?.label);
  }, [currentTeam]);
  const teamOrg = useGetTeamOrg(currentTeam, isLabel);

  const renderPanelContent = () => {
    if (!currentTeam || isLoading) {
      return (
        <div className="team-panel-empty-container">
          {isLoading && <SoundWaveLoader width={100} height={100} />}
          {!isLoading && (
            <p
              style={{
                color: "var(--text-secondary-color)",
                textAlign: "center",
              }}
              className="b1"
            >
              Put your team together! Please start by creating a team on the
              EngineEars platform.{" "}
            </p>
          )}
        </div>
      );
    }
    return (
      <div className={onSidePanel ? "team-panel-scroll-container" : ""}>
        <div className="team-panel-body-container">
          {teamOrg.slice(0, rowLimit).map((teammate, index: number) => {
            return (
              <TeamMember
                key={index}
                user={teammate.user}
                role={teammate.role}
                onClick={() => handleRemove(teammate, currentTeam.id)}
              />
            );
          })}
        </div>
        {currentTeam.pending_invites.length > 0 && (
          <>
            <div className="pending-team-spacer"></div>
            <DashboardCardHeader
              title={`Pending (${currentTeam.pending_invites.length})`}
              actionButton={null}
            />
            <div className="team-panel-body-container">
              {currentTeam.pending_invites
                .slice(0, pendingRowLimit)
                .map((invite: AffiliationInvite, index: number) => (
                  <TeamMember
                    key={index}
                    user={invite.invitee}
                    role={invite.role}
                    email={invite.email}
                    isPending={true}
                    onClick={() => handleRevoke(invite, currentTeam.id)}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    );
  };

  const handleRemove = async (
    teammate: { user: User; role: TeamRole },
    teamId: number,
  ) => {
    await dispatch(
      removeTeamInvite({
        team_id: teamId,
        user_id: teammate.user.id,
        role: teammate.role,
      }),
    );
  };

  const handleRevoke = async (invite: AffiliationInvite, teamId: number) => {
    if (!invite.email) {
      return;
    }
    await dispatch(
      revokeTeamInvite({
        team_id: teamId,
        invite_id: invite.id,
        role: invite.role,
        email: invite.email,
      }),
    );
  };

  return (
    <div
      className={"team-panel-container ".concat(
        onSidePanel ? "team-side-panel-container" : "",
      )}
    >
      {onSidePanel && (canManageStudios || isAnAdmin) && (
        <InviteTeammateButton team={currentTeam} isLabel={isLabel} />
      )}
      {isDesktop && currentTeam && !onSidePanel && (
        <div className="team-panel-body-header">
          <div className="team-panel-info-container">
            <p className="b3-semi-bold team-side-panel-header-col">Status</p>
            <p className="b3-semi-bold team-side-panel-header-col">
              Team Member / Username
            </p>
          </div>
          <p className="b3-semi-bold team-side-panel-header-col">Role</p>
        </div>
      )}
      {renderPanelContent()}
    </div>
  );
};
