import { useMutation } from "@tanstack/react-query";
import { MUTATION_KEYS } from "../../constants/mutationKeys";
import {
  adminAssignProjects,
  AdminAssignProjectsParams,
} from "../../store/actions/adminDashboardStore";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";

const useAdminAssignProjectsMutation = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  return useMutation({
    mutationKey: [MUTATION_KEYS.ADMIN_ASSIGN_PROJECTS],
    mutationFn: async (params: AdminAssignProjectsParams) => {
      emitAnalyticsTrackingEvent("admin_assign_projects", params, user?.id);
      return dispatch(adminAssignProjects(params)).unwrap();
    },
    retry: 0,
  });
};

export default useAdminAssignProjectsMutation;
