import { createTheme } from "@mui/material";

/**
 * breakpoints are based off our current breakpoints,
 * except for xl which based on MUI's default value
 */
const muiBreakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480, // mobile
      md: 769, // tablet - setting to 769 because breakpoints are not inclusive. With 769, it will now go to tablet mode at 768px width
      lg: 1200, // desktop
      xl: 1536,
    },
  },
});

export default muiBreakpointsTheme;
