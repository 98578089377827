import queryString from "query-string";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreditData, MUSO_WEBSITE_BASE_URI, MusoCredit } from "../models/muso";
import { SEARCH_MUSO_CREDITS } from "../utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { receiveErrors } from "./errorStore";
import { QueryParamsType } from "..";

export interface FetchAllMusoCreditsByIdParams {
  musoProfileId: string;
  offset?: number;
  keyword?: string;
}

const generateFetchAllMusoCreditsByIdParams = (
  args: FetchAllMusoCreditsByIdParams,
): string => {
  const params: QueryParamsType = {};
  if (args.musoProfileId) {
    params.muso_profile_id = args.musoProfileId;
  }
  if (args.offset !== undefined) {
    params.offset = args.offset;
  }
  if (args.keyword) {
    params.keyword = args.keyword;
  }
  return `?${queryString.stringify(params)}`;
};

interface FetchMusoCreditsResponse {
  data: {
    items: CreditData[];
  };
  total: number;
}

/*
This action fetches an external Muso API to get all credits related to a Muso profile.
*/
export const fetchAllMusoCreditsById = createAsyncThunk(
  SEARCH_MUSO_CREDITS,
  async (args: FetchAllMusoCreditsByIdParams, thunkAPI) => {
    const params = generateFetchAllMusoCreditsByIdParams(args);
    const result =
      await makeBackendGetCallWithJsonResponse<FetchMusoCreditsResponse>(
        SEARCH_MUSO_CREDITS,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

const parseCreditData = (credit_data: CreditData): MusoCredit => {
  return {
    muso_credit_id: credit_data.track.id,
    track_title: credit_data.track.title,
    album_art: credit_data.album.albumArt,
    album_title: credit_data.album.title,
    spotify_preview_url: credit_data.track.spotifyPreviewUrl,
    spotify_track_id: credit_data.track.spotifyId,
    artist: credit_data.artists
      .map((artist) => artist.name)
      .join(", ")
      .trim()
      .replace(/,$/, ""),
    role: credit_data.credits
      .map((credit) => credit.child)
      .join(", ")
      .trim()
      .replace(/,$/, ""),
    release_date: credit_data.releaseDate,
    link_to_credit: MUSO_WEBSITE_BASE_URI + "track/" + credit_data.track.id,
  } as MusoCredit;
};

export const handleFetchCreditsSuccess = (result: {
  data: { items: CreditData[] };
  total: number;
}) => {
  let creditList: MusoCredit[] = [];
  if (result.data.items) {
    creditList = result.data.items.reduce(
      (creditList: MusoCredit[], creditData: CreditData) => {
        return [...creditList, parseCreditData(creditData)];
      },
      [],
    );
  }
  return {
    total: result.total,
    data: creditList,
  };
};

interface MusoCreditByOffset {
  [offset: number]: MusoCredit[];
}

interface SearchMusoState {
  total: number;
  currentOffset: number;
  data: MusoCreditByOffset;
  keyword: string;
}

const initialState: SearchMusoState = {
  total: 0,
  currentOffset: 0,
  data: {},
  keyword: "",
};

export const searchMusoCreditsSlice = createSlice({
  name: "searchMusoCredits",
  initialState,
  reducers: {
    setKeyword: (state, action: PayloadAction<string>) => {
      state.keyword = action.payload;
    },
    clearSearchMuso: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllMusoCreditsById.fulfilled, (state, action) => {
      const offset = action.meta.arg.offset ?? 0;
      const { total, data } = handleFetchCreditsSuccess(action.payload);
      if (offset === 0) {
        state.data = {};
      }
      state.total = total;
      state.data[offset] = data;
      state.currentOffset = offset;
    });
  },
});

export default searchMusoCreditsSlice.reducer;
export const { setKeyword } = searchMusoCreditsSlice.actions;
