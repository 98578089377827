import { RecordingSession } from "../../../store/models/recordingSession";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { SessionEventBubble } from "../SessionScheduleGuide/SessionEventBubble/SessionEventBubble";

export interface CalendarDayTaskModalProps {
  modalIsOpen: boolean;
  onCloseModal: () => void;
  recordingSessions: RecordingSession[];
}

export const CalendarDayTaskModal = ({
  modalIsOpen,
  onCloseModal,
  recordingSessions,
}: CalendarDayTaskModalProps) => {
  return (
    <BaseModal
      open={modalIsOpen}
      setOpen={onCloseModal}
      header="Recording Sessions"
    >
      {recordingSessions.length > 0 && (
        <div
          style={{
            overflowY: "auto",
            maxHeight: "50vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
          onClick={onCloseModal}
        >
          {recordingSessions.map((recordingSession) => (
            <SessionEventBubble
              offset={0}
              session={recordingSession}
              key={recordingSession.id}
            />
          ))}
        </div>
      )}
    </BaseModal>
  );
};
