import { PopoverTrigger } from "@radix-ui/react-popover";
import { useState } from "react";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import useModal from "../../../../hooks/useModal";
import { useUploadedFileSinceLastTransition } from "../../../../hooks/useProjectFilesUploaded";
import { useAppSelector } from "../../../../store/hooks";
import {
  APIMasteringProjectBase,
  APIMixingProjectBase,
  ProjectById,
} from "../../../../store/models/project";
import { BasePopover } from "../../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../core-ui/components/BasePopover/PopoverContainerContext";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { Text } from "../../../core-ui/components/Text/Text";
import { EditableTextArea } from "../../../elements/EditableTextArea/EditableTextArea";
import { MainProjectWorkflowButtonRow } from "../../MainProjectWorkflowPanel/MainProjectWorkflowButtonRow";
import { TransferFileTransitionViewButtonFooter } from "../TransferFileTransitionView.styles";
import { useArtistTransferFiles } from "../hooks/useArtistTransferFiles";

export interface TransferFileArtistButtonRowProps {
  mixingProject?: APIMixingProjectBase;
  masteringProject?: APIMasteringProjectBase;
  code?: string;
  project: ProjectById;
}

export const TransferFileArtistButtonRow = ({
  mixingProject,
  masteringProject,
  code,
  project,
}: TransferFileArtistButtonRowProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();
  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);
  const initialArtistNotes =
    mixingProject?.artist_mix_notes ?? masteringProject?.artist_master_notes;
  const [artistNotes, setArtistNotes] = useState(initialArtistNotes ?? "");
  const uploadedFiles = useUploadedFileSinceLastTransition(project);
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  const {
    isOpen: isSkipPopoverOpen,
    setIsOpen: setIsSkipPopoverOpen,
    closeModal: closeSkipPopover,
  } = useModal();

  const {
    isOpen: isTransferPopoverOpen,
    setIsOpen: setIsTransferPopoverOpen,
    closeModal: closeTransferPopover,
  } = useModal();

  const {
    transferFileTransition,
    transferLoading,
    skipUploadFileTransition,
    skipUploadLoading,
  } = useArtistTransferFiles({
    masteringProject,
    mixingProject,
    code: code ?? "",
    projectId: project.id,
    artistNotes,
  });

  const artistNotesInput = () => (
    <>
      <Text>
        Please give the engineer a brief description of how you want your
        project to sound.
      </Text>
      <div style={{ margin: "12px 0" }}>
        <EditableTextArea
          placeholder=""
          initialValue={artistNotes}
          editMode={true}
          onChange={setArtistNotes}
        />
      </div>
    </>
  );

  return (
    <MainProjectWorkflowButtonRow>
      {isDesktop && (
        <Text style={{ textAlign: "center", marginBottom: "24px" }}>
          Once you&apos;re finished, click on the transfer files button.
        </Text>
      )}
      <TransferFileTransitionViewButtonFooter>
        <BasePopover
          isOpen={isSkipPopoverOpen}
          setIsPopoverOpen={setIsSkipPopoverOpen}
          closePopover={closeSkipPopover}
          side="top"
          title="Are you sure your engineer has the files?"
          description=""
          additionalContent={
            <>
              {isAuthenticated && artistNotesInput()}
              <Text>
                By clicking confirm, you are indicating that the engineer has
                everything they need to start the project.
              </Text>
            </>
          }
          onConfirm={skipUploadFileTransition}
          wrapperElement={containerElement}
        >
          <PopoverTrigger asChild>
            <Button
              disabled={transferLoading || skipUploadLoading}
              loading={skipUploadLoading}
              variant={ButtonVariant.OUTLINED}
            >
              Engineer Has Files
            </Button>
          </PopoverTrigger>
        </BasePopover>
        <BasePopover
          isOpen={isTransferPopoverOpen}
          setIsPopoverOpen={setIsTransferPopoverOpen}
          closePopover={closeTransferPopover}
          side="top"
          title="Are you sure?"
          description=""
          additionalContent={
            <>
              {isAuthenticated && artistNotesInput()}
              <Text>
                Once you confirm, the files will be transferred to your
                engineer.
              </Text>
            </>
          }
          onConfirm={transferFileTransition}
          wrapperElement={containerElement}
        >
          <PopoverTrigger asChild>
            <Button
              fullWidth
              disabled={
                transferLoading || skipUploadLoading || !uploadedFiles.length
              }
              loading={transferLoading}
              variant={ButtonVariant.PRIMARY}
            >
              Transfer Files
            </Button>
          </PopoverTrigger>
        </BasePopover>
      </TransferFileTransitionViewButtonFooter>
    </MainProjectWorkflowButtonRow>
  );
};
