import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUnmount } from "usehooks-ts";
import { useGetFooterPlayerRef } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import {
  SkipTimes,
  skipSeconds,
} from "../../../hooks/audioPlayerHooks/waveformUtils";
import { useIsArtistReviewStep } from "../../../hooks/projectStepHooks";
import { useIsProjectInMixOrRevision } from "../../../hooks/trackTableHooks/useIsProjectInMixOrRevision";
import {
  useLatestMP4,
  useLatestNonReferenceAudioFileVersion,
  useLatestReference,
  useProjectHasCleansUploaded,
  useProjectMains,
} from "../../../hooks/useFilesFromFileVersionStore";
import { useIsDolbySupported } from "../../../hooks/useIsDolbySupported";
import { useIsInMixorInMaster } from "../../../hooks/useIsInMixorInMaster";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import useModal from "../../../hooks/useModal";
import { useQueryParam } from "../../../hooks/useQueryParam";
import {
  resetAbPlayStore,
  selectFile,
  setIsLooping,
  setNextFile,
  setPreviousFile,
} from "../../../store/actions/abPlayerStore";
import { fetchFileComments } from "../../../store/actions/fileVersionComments";
import {
  clearFileVersionDownload,
  fetchFiles,
} from "../../../store/actions/fileVersions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Alt, ReviewTypeOptions } from "../../../store/models/alts";
import {
  FILE_STATUS,
  FileType,
  FileVersion,
  MP4_UPLOAD_TYPE,
} from "../../../store/models/fileVersion";
import {
  AtmosProjectSteps,
  MasteringProjectSteps,
  MixingProjectSteps,
  Project,
  ProjectById,
  ProjectType,
  ProjectUserType,
} from "../../../store/models/project";
import { getTrackComments } from "../../../store/selectors/trackComments";
import { getTotalTrackCommentsCount } from "../../../store/utils/trackComments";
import {
  isProjectApproved,
  isProjectInReviewStepOrBeyond,
} from "../../../utils/projectUtils";
import dolbySound from "../../assets/dolby-sound.svg";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { TEXT_WEIGHT, Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import {
  PlaybackControlButton,
  PlaybackControlButtonVariant,
} from "../../elements/PlaybackControlButton/PlaybackControlButton";
import { ToggleABSwitch } from "../../elements/ToggleABSwitch/ToggleABSwitch";
import { Mp4PlayBack } from "../Mp4PlayBack/Mp4PlayBack";
import { PlayBackRow } from "../PlayBackRow/PlayBackRow";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import { TrackTableCommentChat } from "../TrackTableComments/TrackTableCommentChat";
import { TrackTableCommentForm } from "../TrackTableComments/TrackTableCommentForm";
import { TrackTableOptionsButtons } from "../TrackTableOptionsButton/TrackTableOptionsButton";
import "./TrackTable.css";
import {
  StyledTrackTableCommentContainer,
  StyledTrackTableCommentCountContainer,
  StyledTrackTableContainer,
  StyledTrackTableFooter,
  StyledTrackTableHeaderContainer,
  StyledTrackTablePlayBackRowContainer,
  StyledTrackTablePlaybackContainerWrapper,
  StyledTrackTableSeparator,
  StyledTrackTableTitleContainer,
} from "./TrackTable.styles";

export const getSelectedTrackRef = (track: FileVersion | undefined) => {
  if (!window.surferidze || !track) {
    return null;
  }

  return window.surferidze[track.id] ?? null;
};

export interface TrackTableProps {
  projectId: number;
  projectType: ProjectType;
  projectName: string;
  step: number;
  isCurrentEngineer: boolean;
  project?: Project | ProjectById;
  showHeader: boolean;
  forceDisplayMain?: boolean;
  showPlaybackTitles?: boolean;
  isSnippet?: boolean;
  loadComments?: boolean;
  enablePreviousVersions?: boolean;
  labelBeforeAfter?: boolean;
  showFileNames?: boolean;
  allowTrackPreview?: boolean;
  allowTrackComments?: boolean;
  displayComments?: boolean;
  userType?: ProjectUserType | null;
  handlePaymentFlow?: () => void;
  handleUpload?: (
    reference: boolean,
    uploadType: FileType.MP4 | FileType.WAV,
  ) => void;
}

enum ReviewTypeSelection {
  All,
  Main,
  Clean,
}

export const TrackTable = ({
  projectId,
  step,
  projectType,
  isCurrentEngineer,
  project,
  showHeader,
  forceDisplayMain,
  showPlaybackTitles = true,
  isSnippet = false,
  loadComments = true,
  labelBeforeAfter = false,
  allowTrackPreview = true,
  allowTrackComments = true,
  displayComments = false,
  handleUpload,
  userType,
  handlePaymentFlow,
}: TrackTableProps) => {
  const codeQuery = useQueryParam("code");
  const code = codeQuery.get();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const isSuperUser = Boolean(loggedInUser?.is_superuser);
  const isDolbySupported = useIsDolbySupported();
  const {
    selectedTrack,
    focusedTrack,
    isFooterPlaying,
    isLooping,
    isFooterReady,
    abState,
    projectId: trackedProjectId,
    mainPlayerId,
    refPlayerId,
  } = useAppSelector((state) => state.abPlayerStore);

  // wrapped in a useMemo to prevent unnecessary re-renders
  const alts = useMemo(() => project?.alts ?? [], [project]);
  const [alt, setUploadAlt] = useState<OptionType>(
    ReviewTypeOptions[ReviewTypeSelection.Main],
  );
  const hasCleanAlt = useMemo(() => {
    return alts.some((alt) => alt.alt === Alt.CLEAN);
  }, [alts]);
  const referenceFileVersion = useLatestReference(projectId);
  const mixOrMasterFileVersion = useLatestNonReferenceAudioFileVersion(
    projectId,
    alt.value,
    "",
    MP4_UPLOAD_TYPE,
  );
  const [selectedRef, setSelectedRef] = useState(referenceFileVersion);
  const [selectedMain, setSelectedMain] = useState(mixOrMasterFileVersion);
  const mains = useProjectMains(projectId, alt.value);
  const inProgress = useIsInMixorInMaster(projectType, step);
  const hasCleans = useProjectHasCleansUploaded(projectId);
  const isArtistInReviewStep = useIsArtistReviewStep(
    projectId,
    step,
    projectType,
  );
  const projectInReviewStepOrBeyond = isProjectInReviewStepOrBeyond(
    project?.service_type,
    step,
  );
  const [showPreviousMixes, setShowPreviousMixes] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const altToDisplay = (function () {
    if (alt.value === Alt.ALL || alt.value === Alt.MAIN) {
      return Alt.MP4;
    }
    return Alt.CLEAN_MP4;
  })();
  const mp4File = useLatestMP4(projectId, altToDisplay);
  const handleServiceTypeSelection = useCallback((option: OptionType) => {
    setUploadAlt(option);
  }, []);
  const footerPlayerRef = useGetFooterPlayerRef();
  const [showComments, setShowComments] = useState(displayComments);
  const selectedTrackDuration =
    getSelectedTrackRef(selectedTrack)?.getDuration();

  const { loading, page, sortBy } = useAppSelector(
    (state) => state.fileVersionCommentsSlice,
  );
  const comments = useAppSelector(getTrackComments(page, projectId));
  const totalComments = getTotalTrackCommentsCount(comments);
  const projectApproved = isProjectApproved(project?.service_type, step);

  useEffect(() => {
    if (!referenceFileVersion) return;
    if (selectedRef?.id === referenceFileVersion?.id) return;
    setSelectedRef(referenceFileVersion);
  }, [referenceFileVersion]);

  useEffect(() => {
    if (!mixOrMasterFileVersion) return;
    if (selectedMain?.id === mixOrMasterFileVersion?.id) return;
    setSelectedMain(mixOrMasterFileVersion);
  }, [mixOrMasterFileVersion, alt.value]);

  useEffect(() => {
    if (!mixOrMasterFileVersion && !referenceFileVersion) return;
    if (
      mixOrMasterFileVersion &&
      (isCurrentEngineer || projectInReviewStepOrBeyond)
    ) {
      dispatch(selectFile({ track: mixOrMasterFileVersion, isFocused: true }));
    } else if (referenceFileVersion) {
      dispatch(selectFile({ track: referenceFileVersion, isFocused: true }));
    }
  }, [
    dispatch,
    projectInReviewStepOrBeyond,
    isCurrentEngineer,
    mixOrMasterFileVersion,
    referenceFileVersion,
  ]);

  useEffect(() => {
    void dispatch(fetchFiles({ projectId, status: FILE_STATUS.FILE_UPLOADED }));
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(clearFileVersionDownload({ fileVersionId: null }));
    dispatch(resetAbPlayStore());
  }, []);

  useUnmount(() => {
    if (!mainPlayerId && !refPlayerId) return;
    mains.forEach((main) => {
      if (main.id === refPlayerId || main.id === mainPlayerId) return;
      dispatch(clearFileVersionDownload({ fileVersionId: main.id }));
    });
  });

  const canAddComment = Boolean(
    (inProgress || isArtistInReviewStep) && allowTrackComments,
  );
  const isMobile = useMediaQuery("(max-width: 992px)");

  const isNextOrPrevDisabled = selectedTrack?.reference || mains.length <= 1;
  const showMain = useMemo(() => {
    if (projectType === ProjectType.MASTERING) {
      return (
        step === MasteringProjectSteps.MASTER_REVIEW ||
        MasteringProjectSteps.MASTERING_ENG_FILE_REVIEW ||
        step === MasteringProjectSteps.MASTER_FINISHED
      );
    } else if (projectType === ProjectType.ATMOS_MIXING) {
      return (
        step === AtmosProjectSteps.ATMOS_MIX_REVIEW ||
        step === AtmosProjectSteps.MIX_REVIEW ||
        step === AtmosProjectSteps.ATMOS_MIX_REVISION ||
        step === AtmosProjectSteps.MIX_REVISION
      );
    } else {
      return (
        step === MixingProjectSteps.MIX_REVIEW ||
        step === MixingProjectSteps.ENG_FILE_REVIEW ||
        step === MixingProjectSteps.MIX_REVISION
      );
    }
  }, [projectType, step]);

  const isAtmosPlayBack = useMemo(() => {
    if (projectType !== ProjectType.ATMOS_MIXING) return false;
    return (
      step === AtmosProjectSteps.ATMOS_MIX_REVIEW ||
      step === AtmosProjectSteps.MIX_REVIEW
    );
  }, [projectType, step]);

  const showTrackTableOptions = useMemo(() => {
    if (isSuperUser) return true;
    if (!userType && !code) return false;
    if (
      projectType === ProjectType.MIXING ||
      projectType === ProjectType.TWO_TRACK_MIXING
    )
      return step >= MixingProjectSteps.IN_MIX;
    if (projectType === ProjectType.MASTERING)
      return step >= MasteringProjectSteps.IN_MASTER;
    if (projectType === ProjectType.ATMOS_MIXING)
      return step >= AtmosProjectSteps.IN_MIX;
  }, [step, projectType, isSuperUser, userType, code]);

  const hideSelectedFiles =
    useIsProjectInMixOrRevision(projectType, step) && !isCurrentEngineer;
  const showMainPlaybackRow =
    isCurrentEngineer ||
    forceDisplayMain ||
    inProgress ||
    projectInReviewStepOrBeyond;
  const showPreviousVersionsPlaybackRows =
    showPreviousMixes && (showMain || isCurrentEngineer);

  useEffect(() => {
    if (isSnippet) {
      return;
    }
    void dispatch(
      fetchFileComments({
        projectId: projectId,
        code,
        refCommentsOnly: hideSelectedFiles,
      }),
    );
  }, [projectId, code, page, sortBy, hideSelectedFiles, isSnippet]);

  const handleMainFileToLoad = () => {
    if (isSnippet) {
      return selectedMain;
    }
    if (hideSelectedFiles) {
      return undefined;
    }
    if (showPreviousMixes) {
      return mixOrMasterFileVersion;
    }
    return selectedMain;
  };

  const handleNextOrPrevTrack = (next = true) => {
    if (isNextOrPrevDisabled) return;
    const index = mains.findIndex((main) => main.id === selectedMain?.id);
    const nextIndex = next ? index + 1 : index - 1;
    if (nextIndex >= 0 && nextIndex < mains.length) {
      footerPlayerRef.current?.pause();
      dispatch(
        selectFile({
          track: mains[nextIndex],
          isFocused: true,
        }),
      );
      setSelectedMain(mains[nextIndex]);
    }
  };

  // sets the previousFile and nextFile attributes
  // for the lock screen media controls. Only allow
  // going previous/next between the reference and a single revision/main.
  useEffect(() => {
    if (!selectedTrack || !focusedTrack || projectId !== trackedProjectId) {
      return;
    }

    dispatch(setPreviousFile(selectedRef ?? null));
    dispatch(setNextFile(selectedMain ?? null));
  }, [
    dispatch,
    selectedMain,
    selectedRef,
    selectedTrack,
    focusedTrack,
    projectId,
    trackedProjectId,
  ]);

  // Handles the toggle between A/B tracks
  // It will handle the edge case for if the user toggles the AB after selecting a previous mix
  // and then toggles back to the main mix if a user closes the mix.
  const handleToggleSwitch = () => {
    if (!selectedTrack || !selectedRef || !selectedMain) return;
    if (selectedRef.id === selectedTrack.id) {
      dispatch(
        selectFile({
          track: selectedMain,
          isFocused: true,
          playOnLoad: true,
        }),
      );
    } else {
      dispatch(
        selectFile({
          track: selectedRef,
          isFocused: true,
          playOnLoad: true,
        }),
      );
      return;
    }
  };

  const {
    isOpen: showMp4Modal,
    openModal: setShowMp4Modal,
    closeModal: setCloseMp4Modal,
  } = useModal();

  const checkForPaywall = () => {
    if (!allowTrackPreview && handlePaymentFlow) {
      handlePaymentFlow();
    }
  };

  const mainWav = getSelectedTrackRef(selectedMain);
  const refWav = getSelectedTrackRef(selectedRef);
  const isPlaybackControlDisabled = Boolean(
    !selectedTrack || projectId !== trackedProjectId || (!mainWav && !refWav),
  );

  const handlePlayClick = () => {
    if (!footerPlayerRef.current) return;
    void footerPlayerRef.current?.playPause().catch(() => {});
  };

  const previousVersionsPlaybackRows = () => {
    return mains.map((mainUpload: FileVersion) => {
      if (mixOrMasterFileVersion?.id === mainUpload.id) return null;
      return (
        <StyledTrackTablePlayBackRowContainer
          key={`${mainUpload.id}-${mainUpload.alt}-${mainUpload.version}`}
        >
          <PlayBackRow
            footerPlayerRef={footerPlayerRef}
            project={project}
            loadComments={loadComments && allowTrackPreview}
            isCurrentEngineer={isCurrentEngineer}
            canAddComment={canAddComment && allowTrackPreview}
            isReference={false}
            isLatestMain={false}
            file={mainUpload}
            handleStateFileChange={setSelectedMain}
            allowTrackPreview={allowTrackPreview || isCurrentEngineer}
          />
        </StyledTrackTablePlayBackRowContainer>
      );
    });
  };

  if (isAtmosPlayBack && !isDolbySupported)
    return (
      <div>
        <p>
          Dolby ATMOS is not supported for this device and browser combination.
          <br />
          <br />
          Safari 10+/9+ on Mac OS/iOS devices or Microsoft Edge for Windows 10+
          devices is required.
        </p>
      </div>
    );

  // We display this icon on both portfolio and sidepanel
  // But the position is different because of the structure of the PlaybackRow
  // If it's in portfolio, it's positioned before the A/B label
  // Otherwise, it's positioned after the track name
  const renderDolbyIcon = () => {
    if (!isAtmosPlayBack) {
      return null;
    }

    return (
      <img
        alt={"dolby"}
        className={classNames({ "dolby-logo": isSnippet })}
        src={dolbySound}
        width={20}
        height={20}
      />
    );
  };

  return (
    <>
      {hasCleans &&
        hasCleanAlt &&
        (isArtistInReviewStep || isCurrentEngineer) && (
          <>
            <DropdownSelector
              placeholder={`Select version (main or clean)`}
              value={alt}
              onChange={handleServiceTypeSelection}
              options={ReviewTypeOptions}
            />
            <div className={"mb-4"} />
          </>
        )}
      <StyledTrackTableContainer
        $simplifiedView={isSnippet}
        onClick={checkForPaywall}
      >
        {showHeader && (
          <StyledTrackTableHeaderContainer>
            <StyledTrackTableTitleContainer>
              <Text variant={TextStyleVariant.H6}>Playback</Text>

              {canAddComment && allowTrackPreview && (
                <ToolTipTextArea
                  text={
                    "Click and drag to add a comment on a track. Double click an existing region to select or unselect."
                  }
                  tooltipTextStyle={{
                    width: "300px",
                    fontSize: "12px",
                    lineHeight: "12px",
                  }}
                />
              )}
            </StyledTrackTableTitleContainer>
            {project && showTrackTableOptions && (
              <TrackTableOptionsButtons
                handleUpload={handleUpload}
                project={project}
                setShowPreviousMixes={() => {
                  setSelectedMain(mixOrMasterFileVersion);
                  setShowPreviousMixes(!showPreviousMixes);
                }}
                showPreviousMixes={showPreviousMixes}
                previousMixesAvailable={Boolean(mains.length > 1)}
                step={step}
                userType={userType}
                code={code}
                canAddComment={canAddComment}
              />
            )}
          </StyledTrackTableHeaderContainer>
        )}
        {(!projectApproved || isCurrentEngineer || isSuperUser) && (
          <StyledTrackTablePlayBackRowContainer>
            <PlayBackRow
              handleUpload={handleUpload}
              footerPlayerRef={footerPlayerRef}
              loadComments={loadComments && allowTrackPreview}
              isCurrentEngineer={isCurrentEngineer}
              canAddComment={canAddComment && allowTrackPreview}
              isReference={true}
              isLatestMain={false}
              project={project}
              file={selectedRef}
              showDropdown={!isSnippet && !showPreviousMixes}
              isSnippet={isSnippet}
              labelBeforeAfter={labelBeforeAfter}
              allowTrackPreview={allowTrackPreview || isCurrentEngineer}
              handleStateFileChange={setSelectedRef}
            />
          </StyledTrackTablePlayBackRowContainer>
        )}
        {showMainPlaybackRow && (
          <StyledTrackTablePlayBackRowContainer>
            {showPlaybackTitles && isSnippet && renderDolbyIcon()}
            <PlayBackRow
              handleUpload={isCurrentEngineer ? handleUpload : undefined}
              footerPlayerRef={footerPlayerRef}
              loadComments={loadComments && allowTrackPreview}
              isCurrentEngineer={isCurrentEngineer}
              canAddComment={canAddComment && allowTrackPreview}
              isReference={false}
              project={project}
              isLatestMain={true}
              file={handleMainFileToLoad()}
              storedFiles={hideSelectedFiles ? [] : mains}
              showDropdown={!isSnippet && !showPreviousMixes}
              isSnippet={isSnippet}
              labelBeforeAfter={labelBeforeAfter}
              allowTrackPreview={allowTrackPreview || isCurrentEngineer}
              handleStateFileChange={setSelectedMain}
              dolbyIcon={!isSnippet && renderDolbyIcon()}
            />
          </StyledTrackTablePlayBackRowContainer>
        )}
        {showPreviousVersionsPlaybackRows && previousVersionsPlaybackRows()}
        <StyledTrackTableFooter>
          {!isSnippet && (
            <StyledTrackTableCommentCountContainer
              onClick={() => setShowComments(!showComments)}
              variant={ButtonVariant.GHOST}
            >
              <FontAwesomeIcon icon={faComment} size={"lg"} />
              {!isMobile && (
                <Text
                  variant={TextStyleVariant.P3}
                  weight={TEXT_WEIGHT.BOLD}
                >{`${totalComments} comment${totalComments === 1 ? `` : `s`}`}</Text>
              )}
            </StyledTrackTableCommentCountContainer>
          )}
          <StyledTrackTablePlaybackContainerWrapper>
            {isArtistInReviewStep && (
              <PlaybackControlButton
                variant={PlaybackControlButtonVariant.BACKWARD_FAST}
                disabled={true}
                onClick={() => {}}
                style={{
                  visibility: "hidden",
                  pointerEvents: "none",
                }}
              />
            )}
            {!isSnippet && (
              <>
                {!isMobile && !isNextOrPrevDisabled && (
                  <PlaybackControlButton
                    variant={PlaybackControlButtonVariant.BACKWARD_STEP}
                    onClick={() => handleNextOrPrevTrack(false)}
                    disabled={isPlaybackControlDisabled}
                  />
                )}
                <PlaybackControlButton
                  variant={PlaybackControlButtonVariant.BACKWARD_FAST}
                  disabled={isPlaybackControlDisabled}
                  onClick={() =>
                    skipSeconds(SkipTimes.BACKWARD_FAST, footerPlayerRef)
                  }
                />
              </>
            )}
            <PlaybackControlButton
              variant={
                isFooterPlaying && projectId === trackedProjectId
                  ? PlaybackControlButtonVariant.PAUSE
                  : PlaybackControlButtonVariant.PLAY_CIRCLE
              }
              style={{ height: "28px", width: "28px" }}
              disabled={isPlaybackControlDisabled}
              isPrimary
              onClick={() => {
                if (!allowTrackPreview && handlePaymentFlow) {
                  handlePaymentFlow();
                } else if (allowTrackPreview) {
                  handlePlayClick();
                }
              }}
            />
            {!isSnippet && (
              <>
                <PlaybackControlButton
                  variant={PlaybackControlButtonVariant.FORWARD_FAST}
                  disabled={isPlaybackControlDisabled}
                  onClick={() =>
                    skipSeconds(SkipTimes.FORWARD_FAST, footerPlayerRef)
                  }
                />
                {!isMobile && !isNextOrPrevDisabled && (
                  <PlaybackControlButton
                    variant={PlaybackControlButtonVariant.FORWARD_STEP}
                    onClick={() => handleNextOrPrevTrack(true)}
                    disabled={isPlaybackControlDisabled}
                  />
                )}
              </>
            )}
            {(inProgress || isArtistInReviewStep) && (
              <PlaybackControlButton
                variant={PlaybackControlButtonVariant.REPEAT}
                isToggled={isLooping}
                disabled={loading || isPlaybackControlDisabled}
                onClick={() => {
                  if (!footerPlayerRef.current) return;
                  dispatch(
                    setIsLooping({
                      isLooping: !isLooping,
                      loopStartTime: footerPlayerRef.current.getCurrentTime(),
                    }),
                  );
                }}
              />
            )}
          </StyledTrackTablePlaybackContainerWrapper>
          <ToggleABSwitch
            referenceSelected={abState}
            showToggle={Boolean(
              (!projectApproved || isCurrentEngineer) &&
                !isSnippet &&
                selectedTrack &&
                referenceFileVersion &&
                mixOrMasterFileVersion,
            )}
            onClick={handleToggleSwitch}
            isReady={isFooterReady}
          />{" "}
        </StyledTrackTableFooter>
        {(inProgress || isAtmosPlayBack) && mp4File !== undefined && (
          <Button
            fullWidth
            variant={ButtonVariant.OUTLINED}
            onClick={setShowMp4Modal}
          >
            Play Dolby Atmos .MP4 file
          </Button>
        )}
        {showComments && (
          <>
            <StyledTrackTableSeparator />
            <StyledTrackTableCommentContainer>
              <TrackTableCommentForm
                selectedTrackDuration={selectedTrackDuration}
                projectId={projectId}
              />
              <TrackTableCommentChat
                projectId={projectId}
                onServiceTypeSelection={handleServiceTypeSelection}
                footerPlayerRef={footerPlayerRef}
                comments={comments}
                projectType={projectType}
                setSelectedRef={setSelectedRef}
                setSelectedMain={setSelectedMain}
              />
            </StyledTrackTableCommentContainer>
          </>
        )}
      </StyledTrackTableContainer>
      <BaseModal
        header="Dolby Atmos audio player"
        setOpen={showMp4Modal ? setCloseMp4Modal : setShowMp4Modal}
        open={showMp4Modal}
      >
        <div className="dolby-mp4-player-container">
          <Mp4PlayBack altToDisplay={altToDisplay} projectId={projectId} />
        </div>
      </BaseModal>
    </>
  );
};
