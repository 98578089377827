import classNames from "classnames";
import { HTMLAttributes, useMemo } from "react";
import { useGetEngineerServices } from "../../../hooks/useGetEngineerServices";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useAppSelector } from "../../../store/hooks";
import {
  getAddSubscriptionRoute,
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { UniqueBreakpoints } from "../../../utils/breakpoints";
import { GenerateBookingButton } from "../../components/GenerateBookingButton/GenerateBookingButton";
import { UserCard } from "../../components/UserCard/UserCard";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { AddSubscriptionContainer } from "./ModularDashboard.styles";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Box, useTheme } from "@mui/material";
import Link from "../../elements/Link/Link";
import { useGetSubscriptionStatus } from "../../../hooks/useGetSubscriptionStatus";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";

export type ModularDashboardHeaderProps = HTMLAttributes<HTMLDivElement>;

const ModularDashboardHeader = ({
  className,
  ...props
}: ModularDashboardHeaderProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(UniqueBreakpoints.DashboardMobile);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const { data: services, isFetching: isLoadingEngineerServices } =
    useGetEngineerServices(loggedInUser?.engineer?.id);
  const userData = useAppSelector((state) => state.accountInfo.user);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const isStudio = useMemo(
    () => selectedProfile.studio !== undefined,
    [selectedProfile.studio],
  );
  const showGenerateBookingButton = useMemo(
    () => services?.length !== 0 || isStudio,
    [isStudio, services],
  );
  const { data: subscription } = useGetSubscriptionStatus({
    user: userData,
    refetchSubsStatus: true,
    force: true,
  });
  const showUpgradeButton = useMemo(() => {
    return (
      subscription?.stripe_subscription_status &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.ESSENTIALS_PLAN &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY
    );
  }, [subscription]);

  if (!loggedInUser) {
    return null;
  }

  return (
    <div className={classNames(["dashboard-header", className])} {...props}>
      <UserCard loggedInUser={loggedInUser} selectedProfile={selectedProfile} />
      {showUpgradeButton && (
        <AddSubscriptionContainer>
          <Text
            variant={TextStyleVariant.P1}
            weight={TEXT_WEIGHT.BOLD}
            style={{ color: theme.palette.standardColor.DeepBlue[800] }}
          >
            Upgrade to{" "}
            <Link
              to={getAddSubscriptionRoute()}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <Text
                variant={TextStyleVariant.P1}
                weight={TEXT_WEIGHT.BOLD}
                style={{
                  display: "inline",
                  color: theme.palette.standardColor.DeepBlue[800],
                  textDecoration: "underline",
                }}
              >
                Platinum!
              </Text>
            </Link>
            <Link
              to={getAddSubscriptionRoute()}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <Text
                variant={TextStyleVariant.P2}
                style={{
                  display: "inline",
                  color: theme.palette.standardColor.DeepBlue[800],
                  textDecoration: "underline",
                }}
              >
                learn more
              </Text>
            </Link>
          </Text>
        </AddSubscriptionContainer>
      )}
      <Box sx={{ display: "flex", marginLeft: "auto" }}>
        <Button
          className="dashboard-view-profile-button"
          href={
            selectedProfile.studio
              ? getStudioScreenRoute(selectedProfile.studio.username)
              : getProfileScreenRoute(selectedProfile.user?.username || "")
          }
          onClick={() => {
            emitAnalyticsTrackingEvent(
              "dashboard_on_click_view_profile",
              {},
              loggedInUser?.id,
            );
          }}
          variant={ButtonVariant.OUTLINED}
        >
          View Profile
        </Button>
        {showGenerateBookingButton && !isMobile && (
          <GenerateBookingButton
            className="dashboard-start-a-project-button"
            disabled={services?.length === 0 && !isStudio}
            isLoadingEngineerServices={Boolean(isLoadingEngineerServices)}
            isMobile={isMobile}
            activeStudio={selectedProfile.studio}
          />
        )}
      </Box>
    </div>
  );
};

export default ModularDashboardHeader;
