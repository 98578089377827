import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { getPurchaseOrderStatus } from "../store/actions/scheduledProjectPurchaseOrders";
import { getInProgressProjectTransactionData } from "../store/actions/scheduledprojects";
import {
  fetchTransactionStatus,
  getTransactionInvoiceUrl,
} from "../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  Project,
  ProjectById,
  ScheduledProjectPaywallTypes,
} from "../store/models/project";
import {
  MinimalScheduledProject,
  PaginatedScheduledProject,
  ScheduledProject,
} from "../store/models/scheduledproject";
import { Transaction, TransactionStatus } from "../store/models/transaction";
import { selectIsUserAandR } from "../store/selectors/userInfoSelectors";
import { getTransactionOverviewRoute } from "../store/utils/routeGetters";
import { useBillingInfoOrPurchaseOrderSubmitted } from "./purchaseOrderWithTransactionHooks";

type InProgressInputOptions =
  | Project
  | ScheduledProject
  | ProjectById
  | PaginatedScheduledProject
  | null
  | undefined;

type PaywallOptionInputOptions = ScheduledProject | null | undefined;

export const useIsInProgressProject = (
  input: InProgressInputOptions,
): {
  isInProgressProject: boolean;
} => {
  const isInProgressProject = useMemo(() => {
    if (!input) {
      return false;
    }

    if ("projects" in input) {
      const scheduledProject = input;
      return scheduledProject?.users?.length === 1;
    }
    const project = input;
    return project.users.length === 1;
  }, [input]);

  return { isInProgressProject };
};

export const useProjectPaywallStatus = (input: PaywallOptionInputOptions) => {
  const { isInProgressProject } = useIsInProgressProject(input);
  const isPartiallyPaid = Boolean(
    input?.outstanding_balance && input.outstanding_balance > 0,
  );

  if (!input || (!isInProgressProject && !isPartiallyPaid))
    return { allowTrackPreview: true, allowTrackApproval: true };

  return {
    allowTrackPreview:
      input.default_paywall_option ===
        ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED ||
      input.default_paywall_option ===
        ScheduledProjectPaywallTypes.REVIEWS_AND_REVISIONS_ALLOWED,
    allowTrackApproval:
      input.default_paywall_option ===
      ScheduledProjectPaywallTypes.REVIEWS_AND_REVISIONS_ALLOWED,
  };
};

export const usePaymentPlanStatus = (
  shareLink: string | undefined,
  scheduledProjectId: number | undefined,
  projectId: number | undefined,
  isPartiallyPaid: boolean,
) => {
  const MAX_FETCH_STATUS_COUNT = 24; // Stop fetching transaction status after 2min.
  const [fetchingPaymentStatus, setFetchingPaymentStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const fetchPaymentStatusRef = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );
  const [statusCount, setStatusCount] = useState(0);

  const redirectToPurchaseProject = async () => {
    if (isPartiallyPaid) {
      try {
        const { hosted_invoice_url: hostedInvoiceUrl } = await dispatch(
          getTransactionInvoiceUrl({
            project_id: projectId,
            scheduled_project_id: scheduledProjectId,
            share_link_code: shareLink,
          }),
        ).unwrap();
        setFetchingPaymentStatus(true);
        window.open(hostedInvoiceUrl, "_blank");
      } finally {
        setLoading(false);
      }
      return;
    }
    if (!shareLink || (!projectId && !scheduledProjectId)) return;
    setLoading(true);
    void dispatch(
      getInProgressProjectTransactionData({
        scheduled_project_id: scheduledProjectId,
        project_id: projectId,
        share_link: shareLink,
      }),
    )
      .unwrap()
      .then(({ transactionId, code }) => {
        setLoading(false);
        history.push(getTransactionOverviewRoute(transactionId, code));
      });
  };

  const cancelFetchingPaymentStatus = () => {
    setFetchingPaymentStatus(false);
  };

  useEffect(() => {
    if (!fetchingPaymentStatus) return;
    fetchPaymentStatusRef.current = setInterval(async () => {
      setStatusCount((prevCount) => prevCount + 1);
      await dispatch(fetchTransactionStatus({ scheduledProjectId, projectId }));
    }, 5000);
    return () => {
      setFetchingPaymentStatus(false);
      if (fetchPaymentStatusRef.current) {
        clearInterval(fetchPaymentStatusRef.current);
      }
    };
  }, [dispatch, fetchingPaymentStatus]);

  useEffect(() => {
    if (statusCount < MAX_FETCH_STATUS_COUNT) return;
    setFetchingPaymentStatus(false);
    setStatusCount(0);
    if (fetchPaymentStatusRef.current) {
      clearInterval(fetchPaymentStatusRef.current);
    }
  }, [statusCount]);

  return {
    redirectToPurchaseProject,
    loading,
    fetchingPaymentStatus,
    cancelFetchingPaymentStatus,
  };
};

export const useIsLabelProject = (scheduledProject?: ScheduledProject) => {
  return Boolean(scheduledProject?.is_label_project);
};

export const useHasPendingPurchaseOrder = (
  scheduledProjectId: undefined | number,
  isLabelProject: boolean,
  isInProgressProject: boolean,
) => {
  const isUserAandR = useAppSelector(selectIsUserAandR);
  const dispatch = useAppDispatch();
  const purchaseOrders = useAppSelector(
    (state) =>
      state.scheduledProjectPurchaseOrdersSlice[scheduledProjectId ?? -1],
  );
  const purchaseOrder = purchaseOrders ? purchaseOrders[0] : undefined;
  const { billingInfoSubmitted, purchaseOrderSubmitted } =
    useBillingInfoOrPurchaseOrderSubmitted(purchaseOrder);

  const purchaseOrderApprovalRequired = purchaseOrders?.find((purchaseOrder) =>
    purchaseOrder.budget_managers.some(
      (bm) => bm.can_approve_budget && !bm.budget_approved,
    ),
  );

  useEffect(() => {
    if (!isUserAandR) return;
    if (!isLabelProject) return;
    if (!scheduledProjectId) return;
    void dispatch(
      getPurchaseOrderStatus({
        scheduled_project_id: scheduledProjectId,
      }),
    );
  }, [
    scheduledProjectId,
    dispatch,
    isInProgressProject,
    isLabelProject,
    isUserAandR,
  ]);

  return {
    isPendingBillingInfo:
      Boolean(purchaseOrder) &&
      !billingInfoSubmitted &&
      !purchaseOrderSubmitted,
    isPendingBudgetApproval: Boolean(purchaseOrderApprovalRequired),
  };
};

/**
 * Uses the ScheduledProject and PurchaseOrder redux store to return PurchaseOrders
 * that are pending budget approval or billing info.
 * This is more consistent compared with checking cost center, general ledger, and work breakdown structure
 */
export const usePendingPurchaseOrders = () => {
  const scheduledProjectId = useAppSelector(
    (state) => state.scheduledProjectsStore?.scheduledProject?.id ?? -1,
  );
  const purchaseOrders = useAppSelector(
    (state) =>
      state.scheduledProjectPurchaseOrdersSlice[scheduledProjectId] ?? [],
  );

  const pendingBudgetApproval = purchaseOrders?.find(
    (purchaseOrder) => purchaseOrder.budget_approved === false,
  );

  const pendingBillingInfo = purchaseOrders?.find(
    (purchaseOrder) =>
      purchaseOrder.purchase_order_status ===
      TransactionStatus.PURCHASE_ORDER_REQUIRED,
  );

  return {
    pendingBillingInfo,
    pendingBudgetApproval,
  };
};

export const isInProgressProjectTransaction = (transaction: Transaction) => {
  const project = transaction.items[0]?.project;

  if (!project) return false;

  return project.deleted === null && project.users.length === 1;
};

export const isStartedInProgressProjectTransaction = (
  transaction: Transaction,
  scheduledProject: MinimalScheduledProject | null,
) => {
  if (!scheduledProject) {
    return false;
  }

  return (
    isInProgressProjectTransaction(transaction) &&
    scheduledProject.default_paywall_option !=
      ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED
  );
};
