import { useMemo } from "react";
import {
  RecordingSession,
  SummarizedRecordingSession,
} from "../../store/models/recordingSession";

export const useIsStudioBooking = (
  recordingSession: RecordingSession | SummarizedRecordingSession,
) => {
  return useMemo(
    () => Boolean(recordingSession?.studio_room),
    [recordingSession],
  );
};
