import { Team } from "./admins";
import { Amenity } from "./amenity";
// import Engineer, { MockEngineer } from "./engineer";
import EntityPhoto, { MockEntityPhoto } from "./entityPhoto";
import Profile, { MockProfile } from "./profile";
import {
  MockRecordingService,
  RecordingLocation,
  RecordingService,
} from "./recording";
// import StudioManager, { MockStudioManager } from "./studiomanager";
import User, { MockUser } from "./user";
import { SlimProfileBase } from "./base";

export interface BasicStudioRoom {
  id: number;
  created: string;
  deleted: string | null;
  about_this_room: string | null;
  room_name: string;
  equipment_highlights?: string | null;
  studio?: BasicStudio;
  max_number_of_guests: number;
}

export const MINIMUM_NUMBER_OF_GUESTS_FOR_STUDIO_ROOM = 1;

export interface StudioRoom extends BasicStudioRoom {
  recording_service: RecordingService | null;
  photo: EntityPhoto;
  number_of_photos: number;
  amenities: Amenity[] | null;
  room_name: string;
  studio?: Studio;
  studio_name: string;
  low_eng_rate?: number;
  high_eng_rate?: number;
}

export interface BasicStudio {
  id: number;
  created: string;
  deleted: string | null;
  studio_profile?: Profile | null;
  username: string;
  location: RecordingLocation | null;
  timezone: string | undefined;
  verified: null | string;
  affiliated_engineer_booking_links_enabled: null | string;
  studio_team: Team;
}

export interface Studio extends BasicStudio {
  equipment_highlights?: string | null;
  unit_number?: string | null;
  arrival_information?: string | null;
  photo: EntityPhoto | null;
  number_of_photos: number;
  studio_amenities: Amenity[] | null;
  studio_rooms: StudioRoom[] | null;
  allow_unaffiliated_engineers: boolean;
  allow_bookings_without_engineer: boolean;
  pending_managers: User[];
  pending_engineers: User[];
  average_engineer_rate: number | null;
  tracking_engineer_price_included: null | string;
  recording_service?: RecordingService;
  campaigns_opted_in: string | null;
  verified: string | null;
  separate_stripe_account_opt_in: boolean;
  stripe_account_id?: string | null;
  meets_stripe_requirements?: string | null | undefined;
}

export interface MapStudio extends Studio {
  photos: EntityPhoto[];
}

const MockRecordingLocation: RecordingLocation = {
  city_location: "1234 Mock Street, Mock City, Mock Province, Mock Country",
};

export const MockStudioRoom: StudioRoom = {
  id: 2,
  photo: MockEntityPhoto,
  number_of_photos: 3,
  about_this_room: "Mock Studio Room About This Room",
  recording_service: MockRecordingService,
  room_name: "Mock Studio Room",
  max_number_of_guests: 5,
  created: new Date().toDateString(),
  deleted: null,
  amenities: [],
  equipment_highlights: "Mock Studio Room Equipment Highlight",
  studio_name: "Mock Studio Name",
};

export const MockStudio: Studio = {
  id: 1,
  verified: new Date().toDateString(),
  created: new Date().toDateString(),
  deleted: null,
  studio_profile: MockProfile,
  username: "nonamestudios",
  equipment_highlights:
    "There is a deep history that lives in the walls of this studio. Great artists and performers have recorded their most legendary projects in this very room. Names such as Michael Jackson, Whitney Houston, and The Beatles.",
  unit_number: "Mock Studio Unit Number",
  arrival_information: "Mock Studio Arrival Information",
  location: MockRecordingLocation,
  photo: MockEntityPhoto,
  number_of_photos: 7,
  studio_amenities: [],
  studio_rooms: [MockStudioRoom],
  allow_unaffiliated_engineers: false,
  allow_bookings_without_engineer: false,
  pending_engineers: [],
  pending_managers: [],
  average_engineer_rate: null,
  timezone: undefined,
  affiliated_engineer_booking_links_enabled: null,
  tracking_engineer_price_included: null,
  campaigns_opted_in: null,
  separate_stripe_account_opt_in: false,
  studio_team: {
    id: 1,
    managers: [MockUser],
    members: [MockUser],
    pending_invites: [],
  },
};

export const MockStudioUnverified: Studio = {
  id: 3,
  verified: null,
  created: new Date().toDateString(),
  deleted: null,
  studio_profile: MockProfile,
  username: "nonamestudios",
  equipment_highlights:
    "Particular highlights are stuido with a view of the ocean, and a recording booth with a view of the ocean.",
  unit_number: "A5",
  arrival_information: "Mock Studio Arrival Information",
  location: MockRecordingLocation,
  photo: MockEntityPhoto,
  number_of_photos: 7,
  studio_amenities: [],
  studio_rooms: [MockStudioRoom],
  allow_unaffiliated_engineers: false,
  allow_bookings_without_engineer: false,
  pending_engineers: [],
  pending_managers: [],
  average_engineer_rate: null,
  timezone: undefined,
  affiliated_engineer_booking_links_enabled: null,
  tracking_engineer_price_included: null,
  campaigns_opted_in: null,
  separate_stripe_account_opt_in: false,
  studio_team: {
    id: 1,
    managers: [MockUser],
    members: [MockUser],
    pending_invites: [],
  },
};

export const MockStudioRoomForRecordingBreakdown: StudioRoom = {
  id: 4,
  photo: MockEntityPhoto,
  number_of_photos: 3,
  about_this_room: "Mock Studio Room About This Room",
  recording_service: MockRecordingService,
  room_name: "Mock Studio Room",
  max_number_of_guests: 5,
  created: new Date().toDateString(),
  deleted: null,
  amenities: [],
  equipment_highlights: "Mock Studio Room Equipment Highlight",
  studio_name: "Mock Studio Name",
  studio: MockStudio,
};

export interface SlimActiveStudio extends SlimProfileBase {
  created: string;
  verified?: string;
}
