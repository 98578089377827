import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { getMusoAuthToken } from "../../store/actions/musoProfile";
import { useAppDispatch } from "../../store/hooks";
import User from "../../store/models/user";
import { useIsVisitingOwnProfile } from "./useIsVisitingOwnProfile";

interface useGetMusoAuthProps {
  userProfile?: User;
  refresh?: boolean;
}

export const useGetMusoAuthQuery = ({
  userProfile,
  refresh = false,
}: useGetMusoAuthProps) => {
  const isVisitingOwnProfile = useIsVisitingOwnProfile();

  const isUserProfileEngineer = Boolean(
    userProfile?.engineer && !userProfile.engineer.deleted,
  );

  const dispatch = useAppDispatch();
  return useQuery({
    staleTime: !refresh && isVisitingOwnProfile ? Infinity : undefined,
    queryKey: [QUERY_KEYS.GET_MUSO_AUTH, userProfile?.id],
    enabled:
      Boolean(userProfile?.id) && isVisitingOwnProfile && isUserProfileEngineer,
    queryFn: async () => {
      if (!userProfile?.id)
        return {
          muso_user_id: null,
          muso_association: null,
        };
      const result = await dispatch(getMusoAuthToken()).unwrap();
      return result;
    },
    initialData: {
      muso_user_id: null,
      muso_association: null,
    },
  });
};
