import * as Checkbox from "@radix-ui/react-checkbox";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled, { RuleSet } from "styled-components";

export const DropdownMenuContent = styled(DropdownMenu.Content)<{
  $zIndex?: number;
  overrideStyle?: RuleSet<object>;
}>`
  border-radius: ${({ theme }) => theme.border.radius.xs};
  border: 1px solid ${({ theme }) => theme.separatorColor};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  color: ${({ theme }) => theme.textPrimaryColor};
  background-color: ${({ theme }) => theme.dropdownBackgroundColor};
  z-index: ${({ $zIndex, theme }) =>
    $zIndex ?? theme.zIndexes.selectDropdownMenuContent};
  ${(props) => props.overrideStyle};
`;

export const DropdownMenuLabel = styled(DropdownMenu.Label)`
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const Separator = styled(DropdownMenu.Separator)`
  height: 1px;
  background-color: ${({ theme }) => theme.separatorColor};
`;

export const CheckboxLabel = styled.label`
  padding: 10px 16px 10px 36px;
  position: relative;
  display: block;
  cursor: pointer;
  color: ${({ theme }) => theme.textPrimaryColor};
  &:hover {
    background-color: ${({ theme }) => theme.midgroundColor};
  }
`;

export const CheckboxRoot = styled(Checkbox.Root)`
  width: 12px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.textPrimaryColor};
`;

export const CheckBoxGroupTitle = styled.p`
  padding: 10px 16px;
  color: ${({ theme }) => theme.textSecondaryColor};
`;
