import { RecordingService } from "../store/models/recording";
import { Studio, StudioRoom } from "../store/models/studio";
import User from "../store/models/user";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const isValidRecordingServiceStudio = (
  studio: Studio | undefined,
  loggedInUser: User | undefined,
  // if this is false, this function will not check whether the studio has any rooms with a recording service.
  checkIfRecordingServiceExists = true,
) => {
  if (!studio || !loggedInUser) {
    return true;
  }
  if (
    checkIfRecordingServiceExists &&
    !studio?.studio_rooms?.some((room) => room.recording_service)
  ) {
    return false;
  }

  const managers = studio.studio_team.managers ?? [];
  if (managers.some((manager) => manager?.id === loggedInUser.id)) {
    return true;
  }
  const engineerUsers = studio.studio_team.members ?? [];
  if (
    engineerUsers.some((engineerUser) => engineerUser?.id === loggedInUser.id)
  ) {
    return Boolean(studio.affiliated_engineer_booking_links_enabled);
  }

  return true;
};

// Returns the studio rooms that have recording services.
// Accepts either a studio or a list of recording services.
export const useValidRecordingStudioRooms = (
  studio: Studio | null | undefined,
  validStudioRecordingServices: RecordingService[],
) => {
  return useMemo(() => {
    if (studio) {
      return (
        studio?.studio_rooms?.filter((studioRoom: StudioRoom) =>
          Boolean(studioRoom.recording_service),
        ) ?? []
      );
    } else {
      return validStudioRecordingServices.map(
        (service: RecordingService) => service.studio_room,
      );
    }
  }, [studio, validStudioRecordingServices]);
};

export const useValidStudioRecordingServices = (
  studioRecordingServices: RecordingService[],
) => {
  const loggedInUser = useSelector(
    (state: RootState) => state.accountInfo.user,
  );
  return useMemo(() => {
    return studioRecordingServices.filter((service: RecordingService) =>
      isValidRecordingServiceStudio(
        service.studio_room?.studio,
        loggedInUser,
        false,
      ),
    );
  }, [studioRecordingServices, loggedInUser]);
};
