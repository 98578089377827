import { useContext } from "react";
import { DropdownContext } from "../../../../../core-ui/components/Select/Select";
import { InProgressTransitionViewIndex } from "../../../../InProgressTransitionView/InProgressTransitionView";
import { MainProjectWorkflowContext } from "../../../../MainProjectWorkflowPanel/MainProjectWorkflowPanelContext";
import { DropdownMenuItem } from "../../../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";

/**
 * Action Option to enable all users to download project files during a Mixing or Mastering project workflow
 */
export const ProjectActionDownloadFiles = () => {
  const mainProjectWorkflowContext = useContext(MainProjectWorkflowContext);
  const dropdownContext = useContext(DropdownContext);

  return (
    <DropdownMenuItem
      onSelect={(e: Event) => {
        e.preventDefault();
        mainProjectWorkflowContext?.setInProgressViewIndex(
          InProgressTransitionViewIndex.FILES,
        );
        dropdownContext?.closeDropdownMenu();
      }}
    >
      Download files
    </DropdownMenuItem>
  );
};
