import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { useAppDispatch } from "../../store/hooks";
import {
  PortfolioFeatureData,
  transformRawPortfolioData,
} from "../../store/models/portfolio";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { GET_FEATURED_TRACKS } from "../../store/utils/routes";
import queryString from "query-string";
import { receiveErrors } from "../../store/actions/errorStore";
import { QueryParamsType } from "../../store";

interface useGetFeaturedTracksProps {
  userId: number;
  page?: number;
}
interface GetPaginatedFeaturedTrackResponse {
  data: PortfolioFeatureData[];
  current_page: number;
  total_pages: number;
  count: number;
}

export const useGetFeaturedTracks = ({
  userId,
  page,
}: useGetFeaturedTracksProps) => {
  const dispatch = useAppDispatch();
  return useQuery({
    queryKey: [QUERY_KEYS.GET_PAGINATED_FEATURED_TRACKS, userId, page],
    queryFn: async () => {
      const queryParamObj: QueryParamsType = {};
      queryParamObj.user_id = userId;
      if (page) {
        queryParamObj.page = page;
      }

      const params = queryString.stringify(queryParamObj);
      const result =
        await makeBackendGetCallWithJsonResponse<GetPaginatedFeaturedTrackResponse>(
          GET_FEATURED_TRACKS,
          `?${params}`,
        );
      if (result.success) {
        result.resultJson.data = result.resultJson.data.map(
          transformRawPortfolioData,
        );
        return result.resultJson;
      }
      const errors = { errors: result.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(errors);
    },
    placeholderData: keepPreviousData,
  });
};
