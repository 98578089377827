import User from "../../../../../store/models/user";
import {
  ActionStatus,
  ActionStatusColorMap,
  ActionStatusIconMap,
} from "../../../components/SimpleTable/SimpleTable";
import { UserIconLabel } from "../UserIconLabel";
import {
  SimpleTableRowContainer,
  SimpleTableRowText,
  SimpleTableRowCell,
} from "./SimpleTableRow.styles";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface SimpleTableRowData {
  user: User | null;
  email?: string;
  actionStatus: ActionStatus;
}
export interface SimpleTableRowProps {
  row: SimpleTableRowData;
}

export const SimpleTableRow: React.FC<SimpleTableRowProps> = ({
  row,
}: SimpleTableRowProps) => {
  return (
    <SimpleTableRowContainer>
      <SimpleTableRowCell>
        <UserIconLabel user={row.user} />
      </SimpleTableRowCell>
      <SimpleTableRowCell>
        <SimpleTableRowText>{row.email}</SimpleTableRowText>
      </SimpleTableRowCell>
      <SimpleTableRowCell>
        <FontAwesomeIcon
          color={ActionStatusColorMap.get(row.actionStatus)}
          icon={ActionStatusIconMap.get(row.actionStatus) ?? faCheck}
        />
      </SimpleTableRowCell>
    </SimpleTableRowContainer>
  );
};
