/**
 * tokenized z-index values for Material UI
 */
const customZIndex = {
  navProfileDropDown: -1,
  modalExitIcon: 1,
  navProfileMenu: 1,
  findMyEngineer: 1,
  customDatePickerPopper: 1,
  searchFilterDrawerBackdrop: 100,
  searchFilterDrawer: 101,
  emptyMessagesScreen: 100,
  cardSteps: 100,
  floatingChatButton: 500,
  livestreamOverlay: 501,
  bottomNav: 1000,
  studioScreenFixedFooterButton: 1000,
  messageScreenContainer: 1001,
  topNav: 1001,
  entityPhotoScreen: 1002,
  entityPhotoScreenButtons: 500,
  sidePanel: 1002,
  totalPageOverlay: 1003,
  modalIndex: 1004,
  locationAutoComplete: 1005,
  projectChatPanel: 10000,
  tooltipText: 10001,
  fileDropdown: 1002,
  chat: 1005,
  playbackRowCommentContainer: 1000,
  footerPlayer: 999,
  popconfirmContent: 1005,
  projectSidePanelContent: 1004,
  selectDropdownMenuContent: 999,
  trackTableCommentsSortDropdown: 1200,
};

export default customZIndex;
