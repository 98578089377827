import "./StudioScreen.css";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { host } from "../../../store/utils/utils";
import { Studio } from "../../../store/models/studio";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useUserReviews } from "../../../hooks/useUserReviews";
import { getDisplayableNameForStudio } from "../../../store/utils/entityUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PrimaryEntityImage } from "../../components/PrimaryEntityImage/PrimaryEntityImage";
import { EntityPhotoGrid } from "../../components/EnitityPhotoGrid/EntityPhotoGrid";
import EntityPhoto from "../../../store/models/entityPhoto";
import StudioHeaderMetadata from "./StudioHeaderMetadata";
import { getStudioScreenRoute } from "../../../store/utils/routeGetters";
import { Trophy } from "../../../store/models/trophy";

interface StudioHeaderProps {
  studio: Studio;
  setShowEditStudio: (_: boolean) => void;
  showEditStudioModal: boolean;
  userCanEditStudio: boolean;
  actionButtons: JSX.Element | null;
  setShowStudioPhotos: (_: boolean) => void;
  setSelectedPhoto: (_: EntityPhoto | undefined) => void;
  trophies: Trophy[] | undefined;
}

const StudioHeader = ({
  studio,
  setShowEditStudio,
  showEditStudioModal,
  userCanEditStudio,
  actionButtons,
  setShowStudioPhotos,
  setSelectedPhoto,
  trophies,
}: StudioHeaderProps) => {
  const shareUrl = `${host}${getStudioScreenRoute(studio.username)}`;
  const { isDesktop } = useMediaQueryBreakpoint();
  const stats = useUserReviews(undefined, undefined, studio);

  const getNameContent = () => {
    const content = (
      <>
        <p className={"h2 h2-semi-bold mx-1"}>
          {getDisplayableNameForStudio(studio)}
        </p>
        {userCanEditStudio && (
          <FontAwesomeIcon
            className={"edit-studio-button"}
            icon={faEdit}
            size={isDesktop ? "lg" : "sm"}
            onClick={() => {
              if (!showEditStudioModal) setShowEditStudio(true);
            }}
          />
        )}
      </>
    );

    if (isDesktop) {
      return <div className="studio-name-header-left">{content}</div>;
    }

    return content;
  };

  return (
    <>
      {!isDesktop && (
        <div className={"container-fluid"}>
          <PrimaryEntityImage
            studio={studio}
            onClickViewAll={() => {
              setShowStudioPhotos(true);
            }}
            primaryPhoto={studio.photo}
            editMode={false}
            imageClassName={"studio-hero-image"}
            canManageStudio={userCanEditStudio}
          />
        </div>
      )}
      <div className={"container"}>
        <div className="studio-name-header">
          {getNameContent()}
          {isDesktop && actionButtons}
        </div>
        <StudioHeaderMetadata
          studio={studio}
          stats={stats}
          trophies={trophies}
          shareUrl={shareUrl}
        />
        {isDesktop && (
          <EntityPhotoGrid
            studioId={studio.id}
            handlePhotoClick={(selectedPhoto) => {
              setSelectedPhoto(selectedPhoto);
              setShowStudioPhotos(true);
            }}
          />
        )}
      </div>
    </>
  );
};

export default StudioHeader;
