import { useCallback, useContext } from "react";
import { toast } from "react-toastify";
import { useQueryParam } from "../../../../../../hooks/useQueryParam";
import {
  artistMasteringTransitions,
  artistMixingTransitions,
  engMasteringTransition,
  engMixTransition,
} from "../../../../../../store/actions/projects";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  MasteringTransitions,
  MixingTransitions,
  ProjectType,
  ProjectUserType,
  ProjectWorkflowSteps,
} from "../../../../../../store/models/project";
import { SessionWorkflowSteps } from "../../../../../../store/models/recordingSession";
import { emitAnalyticsTrackingEvent } from "../../../../../../utils/analyticsUtils";
import { getUserTypeFromProject } from "../../../../../../utils/projectUtils";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../../../core-ui/components/BasePopover/PopoverContainerContext";
import { Button } from "../../../../../core-ui/components/Button/Button";
import { PopConfirm } from "../../../../../core-ui/components/PopConfirm/PopConfirm";
import { DropdownContext } from "../../../../../core-ui/components/Select/Select";
import { MainProjectWorkflowContext } from "../../../../MainProjectWorkflowPanel/MainProjectWorkflowPanelContext";
import { DropdownMenuItem } from "../../../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";

export interface ProjectActionUploadProjectFilesProps {
  isButton?: boolean; // If true, the component will render as a button instead of a dropdown item
  projectId: number | null | undefined;
  projectType: ProjectType;
  workflowStep: ProjectWorkflowSteps | SessionWorkflowSteps;
}

/**
 * Action Option for the Engineer to upload project files during a Mixing or Mastering project workflow
 */
export const ProjectActionUploadProjectFiles = ({
  isButton = false,
  projectId,
  projectType,
  workflowStep,
}: ProjectActionUploadProjectFilesProps) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.accountInfo.user);
  const userId = currentUser?.id;
  const { projects } = useAppSelector((state) => state.projectsMapStore);
  const project = projectId ? projects[projectId] : null;
  const currentUserType = getUserTypeFromProject(currentUser, project);
  const dropdownContext = useContext(DropdownContext);
  const mainProjectWorkflowContext = useContext(MainProjectWorkflowContext);
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );
  const codeQuery = useQueryParam("code");
  const code = codeQuery.get();

  const openFileUploader = useCallback(async () => {
    emitAnalyticsTrackingEvent("engineer_upload_project_files", {}, userId);

    // This shouldn't happen, but just in case
    if (!projectId) {
      toast.error("Project ID is missing");
      return;
    }
    const params = { project_id: projectId.toString(), code };

    // if the project is not in the File Transfer step, move it to that step before togging the file uploader
    if (workflowStep !== ProjectWorkflowSteps.FILE_TRANSFER) {
      const isMastering = projectType === ProjectType.MASTERING;

      if (currentUserType === ProjectUserType.ENGINEER) {
        if (isMastering) {
          await dispatch(
            engMasteringTransition({
              ...params,
              transition: MasteringTransitions.TOGGLE_PROJECT_FILES,
            }),
          ).unwrap();
        } else {
          await dispatch(
            engMixTransition({
              ...params,
              transition: MixingTransitions.TOGGLE_PROJECT_FILES,
            }),
          ).unwrap();
        }
      } else {
        if (isMastering) {
          await dispatch(
            artistMasteringTransitions({
              ...params,
              transition: MasteringTransitions.TOGGLE_PROJECT_FILES,
            }),
          ).unwrap();
        } else {
          await dispatch(
            artistMixingTransitions({
              ...params,
              transition: MixingTransitions.TOGGLE_PROJECT_FILES,
            }),
          ).unwrap();
        }
      }
    }

    mainProjectWorkflowContext?.setShowEngineerProjectFileUploader(true);
    dropdownContext?.closeDropdownMenu();
    return;
  }, [
    currentUserType,
    projectType,
    dispatch,
    projectId,
    userId,
    dropdownContext,
    workflowStep,
    mainProjectWorkflowContext,
  ]);

  const renderTrigger = isButton ? (
    <Button>Upload working files</Button>
  ) : (
    <DropdownMenuItem onSelect={(e: Event) => e.preventDefault()}>
      Upload working files
    </DropdownMenuItem>
  );

  return (
    <PopConfirm
      side="top"
      title="Are you sure?"
      description={`This will notify the ${currentUserType === ProjectUserType.ENGINEER ? "client" : "engineer"} and move the project status to the File Transfer step.`}
      onConfirm={openFileUploader}
      wrapperElement={containerElement}
    >
      {renderTrigger}
    </PopConfirm>
  );
};
