import { useAppSelector } from "../../../store/hooks";
import {
  StyledPlaylistElementContainer,
  StyledPlaylistElementImg,
  StyledPlaylistElementIndex,
  StyledPlaylistElementText,
  StyledPlaylistElementTextContainer,
  StyledPlaylistElementTitle,
} from "./PlaylistElement.styles";
import { getTrackInfo } from "../../../store/selectors/abPlayerSelectors";
import missingFileArt from "../../assets/BlankAlbumArt.png";
import { COLLAPSED_SIZE } from "../FooterPlayer/FooterProjectInfo/FooterProjectInfo";

export interface PlaylistElementProps {
  index: number;
  onClick?: () => void;
}

export const PlaylistElement = ({ index, onClick }: PlaylistElementProps) => {
  const { artist, title, artworkSource } = useAppSelector(getTrackInfo(index));
  const { currentTrackIndex } = useAppSelector((state) => state.abPlayerStore);
  const convertIndexToString = () => {
    const indexStr = (index + 1).toString();
    return indexStr.length === 1 ? `0${indexStr}` : indexStr;
  };

  return (
    <StyledPlaylistElementContainer onClick={onClick}>
      <StyledPlaylistElementIndex $isPlaying={index === currentTrackIndex}>
        {convertIndexToString()}
      </StyledPlaylistElementIndex>
      <StyledPlaylistElementImg
        height={COLLAPSED_SIZE}
        width={COLLAPSED_SIZE}
        src={artworkSource ?? missingFileArt}
      ></StyledPlaylistElementImg>
      <StyledPlaylistElementTextContainer>
        <StyledPlaylistElementTitle>{title}</StyledPlaylistElementTitle>
        <StyledPlaylistElementText>{artist}</StyledPlaylistElementText>
      </StyledPlaylistElementTextContainer>
    </StyledPlaylistElementContainer>
  );
};
