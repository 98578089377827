import queryString from "query-string";

import { STUDIO_ROOM_SEARCH_API } from "../utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { receiveErrors } from "./errorStore";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StudioRoom } from "../models/studio";
import { RootState, QueryParamsType } from "../index";
import { AmenityType } from "../models/amenity";
import { WEEKDAYS } from "../models/workingHours";
import {
  AutoCompleteTypeEnum,
  LocationSuggestion,
} from "../models/autocomplete";
import { SearchOrigin, setFiltersForUserSearch } from "./userSearch";
import { ProjectType } from "../models/project";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";

interface searchStudioRoomParams {
  search: string;
  searchOrigin?: SearchOrigin;
  promoCode: string | null;
  paymentDeposits: string | null;
  abortController?: AbortController;
}

interface StudioRoomSearchResponse {
  data: StudioRoom[];
  page: number;
  num_pages: number;
  limit: number;
  count: number;
  local_studios_present: boolean;
}

export const searchStudioRooms = createAsyncThunk(
  STUDIO_ROOM_SEARCH_API,
  async (
    {
      search,
      searchOrigin,
      promoCode,
      paymentDeposits,
      abortController,
    }: searchStudioRoomParams,
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;
    const autocompleteSuggestions = state.userSearch.autocompleteSuggestions;
    const {
      page,
      selectedAmenities,
      maxDistance,
      allowNoEngineer,
      latitude,
      minRate,
      maxRate,
      longitude,
      daysAvailable,
      durationMinutes,
      simpleBudgetSelected: simpleBudget,
    } = state.studioRoomSearch;
    const anonymousId = state.accountInfo.anonymousId;

    const queryParamObj: QueryParamsType = {};

    queryParamObj.search = search;
    queryParamObj.page = page;

    if (maxRate) {
      queryParamObj.min_rate = minRate;
      queryParamObj.max_rate = maxRate;
    } else if (simpleBudget.length > 0) {
      queryParamObj.simple_budget_selected = simpleBudget;
    }
    if (daysAvailable.length > 0) {
      queryParamObj.days_available = daysAvailable.map((day) => day);
    }
    if (maxDistance) {
      queryParamObj.max_distance = maxDistance;
    }
    if (durationMinutes) {
      queryParamObj.duration = durationMinutes;
    }
    if (promoCode) {
      queryParamObj.promocode = promoCode;
    }
    if (paymentDeposits) {
      queryParamObj.payment_deposits = true;
    }
    queryParamObj.allow_no_engineer = allowNoEngineer;

    const checkIfLocation = autocompleteSuggestions.find(
      (suggestion) => suggestion.type === AutoCompleteTypeEnum.LOCATION,
    );
    if (checkIfLocation) {
      const location = checkIfLocation as LocationSuggestion;
      queryParamObj.latitude = location.latitude;
      queryParamObj.longitude = location.longitude;
    } else if (latitude && longitude) {
      queryParamObj.latitude = latitude;
      queryParamObj.longitude = longitude;
    }
    if (selectedAmenities.length > 0) {
      queryParamObj.amenities = selectedAmenities.map((amenity) => amenity);
    }
    if (searchOrigin) {
      queryParamObj.search_origin = searchOrigin;
    }
    if (anonymousId) {
      queryParamObj.anonymous_id = anonymousId;
    }

    const params = `?${queryString.stringify(queryParamObj, {
      arrayFormat: "comma",
    })}`;

    emitAnalyticsTrackingEvent("studio_room_search", {
      query_params: params,
    });
    const response =
      await makeBackendGetCallWithJsonResponse<StudioRoomSearchResponse>(
        STUDIO_ROOM_SEARCH_API,
        params,
        abortController ? { signal: abortController.signal } : undefined,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface paginatedStudioRooms {
  [page: number]: StudioRoom[];
}

interface studioRoomSearchState {
  paginatedStudioRooms: paginatedStudioRooms;
  page: number;
  num_pages: number;
  limit: number;
  count: number;
  allowNoEngineer: boolean;
  simpleBudgetSelected: string[];
  maxRate: number;
  minRate: number;
  maxDistance: number;
  latitude: number;
  longitude: number;
  selectedAmenities: AmenityType[];
  fetching: boolean;
  daysAvailable: WEEKDAYS[];
  durationMinutes: number;
  localStudiosPresent: boolean;
}

export const initialState: studioRoomSearchState = {
  paginatedStudioRooms: {},
  page: 1,
  num_pages: 1,
  limit: 12,
  count: 0,
  allowNoEngineer: false,
  simpleBudgetSelected: [],
  minRate: 0,
  maxRate: 0,
  maxDistance: 0,
  latitude: 0,
  longitude: 0,
  selectedAmenities: [],
  fetching: false,
  daysAvailable: [],
  durationMinutes: 0,
  localStudiosPresent: true,
};

const studioRoomSearchSlice = createSlice({
  name: "studioRoomSearch",
  initialState,
  reducers: {
    updateStudioRoomSearchPage: (state, action: PayloadAction<number>) => {
      if (state.num_pages < action.payload) {
        state.page = 1;
        return;
      }
      state.page = action.payload;
    },
    clearFiltersForStudioRoomSearch: (state) => {
      state.page = 1;
      state.simpleBudgetSelected = [];
      state.minRate = 0;
      state.maxRate = 0;
      state.maxDistance = 0;
      state.latitude = 0;
      state.longitude = 0;
      state.selectedAmenities = [];
      state.daysAvailable = [];
      state.durationMinutes = 0;
      state.allowNoEngineer = false;
    },
    setFiltersForStudioRoomSearch: (
      state,
      action: PayloadAction<{
        maxRate: number;
        simpleBudgetSelected: string[];
        minRate: number;
        maxDistance: number;
        allowNoEngineer: boolean;
        selectedAmenities: AmenityType[];
        daysAvailable: WEEKDAYS[];
        durationMinutes: number;
      }>,
    ) => {
      if (action.payload.durationMinutes === state.durationMinutes) {
        state.durationMinutes = 0;
      } else {
        state.durationMinutes = action.payload.durationMinutes;
      }
      if (action.payload.maxDistance === state.maxDistance) {
        state.maxDistance = 0;
      } else {
        state.maxDistance = action.payload.maxDistance;
      }
      state.simpleBudgetSelected = action.payload.simpleBudgetSelected;
      state.minRate = action.payload.minRate;
      state.maxRate = action.payload.maxRate;
      state.page = 1;
      state.daysAvailable = action.payload.daysAvailable;
      state.selectedAmenities = action.payload.selectedAmenities;
      state.allowNoEngineer = action.payload.allowNoEngineer;
    },
    setUserLatLngForStudioRoomSearch: (
      state,
      action: PayloadAction<{ latitude: number; longitude: number }>,
    ) => {
      state.page = 1;
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
    setFetchingStudioRooms: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchStudioRooms.fulfilled, (state, action) => {
      state.page = action.payload.page;
      state.paginatedStudioRooms[state.page] = action.payload.data;
      state.num_pages = action.payload.num_pages;
      state.limit = action.payload.limit;
      state.fetching = false;
      state.count = action.payload.count;
      state.localStudiosPresent = action.payload.local_studios_present;
    });
    builder.addCase(searchStudioRooms.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(searchStudioRooms.rejected, (state, action) => {
      const { meta } = action;
      if (meta.aborted) return;
      state.fetching = false;
    });
    builder.addCase(setFiltersForUserSearch, (state, action) => {
      if (action.payload.serviceTypes.includes(ProjectType.RECORDING))
        state.fetching = true;
    });
  },
});

export default studioRoomSearchSlice.reducer;
export const {
  clearFiltersForStudioRoomSearch,
  setFiltersForStudioRoomSearch,
  setUserLatLngForStudioRoomSearch,
  updateStudioRoomSearchPage,
  setFetchingStudioRooms,
} = studioRoomSearchSlice.actions;
