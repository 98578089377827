import { useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../store/hooks";
import { Alt } from "../../../store/models/alts";
import { ProjectType } from "../../../store/models/project";
import Service from "../../../store/models/service";
import User from "../../../store/models/user";
import { selectPromoCodeDiscountMultiplier } from "../../../store/selectors/marketingSelectors";
import { basePricePerSong } from "../../../store/utils/scheduledprojects";
import { CheckoutScreenHeader } from "../../components/CheckoutScreenHeader/CheckoutScreenHeader";
import {
  availability_multiplier,
  FirstPassDate,
  ProjectBookingCalendar,
} from "../../components/ProjectBookingCalendar/ProjectBookingCalendar";
import { ProjectBookingCart } from "../../components/ProjectBookingCart/ProjectBookingCart";
import { PrimaryButton } from "../../elements/Button/button.stories";
import { PrevButton } from "../../elements/PrevButton/PrevButton";
import { CollapsableComponent } from "../../components/CollapsableComponent/CollapsableComponent";
import "./ProjectCheckoutScreen.css";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

export interface ProjectCheckoutScreenProps {
  engineerUser: User;
  service: Service;
  projectTitle: string;
  songList: string[];
  altsList: Alt[];
  onClickFinishPayment: (
    songDatesMap: Map<string, FirstPassDate>,
    code?: string,
  ) => void;
  onClickPreviousStep: () => void;
  availabilities: Map<string, availability_multiplier>;
}

export const ProjectCheckoutScreen = ({
  engineerUser,
  service,
  projectTitle,
  songList,
  altsList,
  onClickFinishPayment,
  onClickPreviousStep,
  availabilities,
}: ProjectCheckoutScreenProps) => {
  const discountMultiplier = useAppSelector(selectPromoCodeDiscountMultiplier);
  const [selectedSong, setSelectedSong] = useState(0);
  const darkMode = useAtomValue(darkModeAtom);

  const [selectionQueue, setSelectionQueue] = useState([
    ...Array(songList.length).keys(),
  ]);
  const [songDatesMap, setSongDatesMap] = useState(
    new Map<string, FirstPassDate>(),
  );

  const onClickDateHandler = (date: FirstPassDate) => {
    const newSongDatesMap = new Map<string, FirstPassDate>(songDatesMap);
    let newSelectedSong = selectedSong;
    let newSelectionQueue = [...selectionQueue];

    const isDeselecting = Array.from(newSongDatesMap).some(
      ([songName, firstPassDate]) => {
        const songIndex = songList.indexOf(songName);
        if (
          firstPassDate.date.getTime() === date.date.getTime() &&
          selectedSong === songIndex
        ) {
          newSongDatesMap.delete(songName);
          newSelectedSong = songIndex;
          newSelectionQueue.push(newSelectedSong);
          newSelectionQueue.sort();
          return true;
        } else {
          return false;
        }
      },
    );

    if (!isDeselecting) {
      // Check that we can actually select this date or if we've hit the limit.
      let countSongsForDate = 0;
      Array.from(newSongDatesMap).forEach(([, firstPassDate]) => {
        if (firstPassDate.date.getTime() === date.date.getTime()) {
          countSongsForDate += 1;
        }
      });

      if (date.availability[2] <= countSongsForDate) {
        toast.error(
          "Limit reached for the number of songs that can be selected for this date.",
        );
        return;
      }
      newSongDatesMap.set(songList[selectedSong], date);
      if (newSelectionQueue.includes(selectedSong)) {
        newSelectionQueue = newSelectionQueue.filter(
          (index) => index !== selectedSong,
        );
        newSelectedSong = newSelectionQueue[0] || 0;
      }
    }

    setSelectionQueue(newSelectionQueue);
    setSelectedSong(newSelectedSong);
    setSongDatesMap(newSongDatesMap);
  };

  const getSelectedDays = () =>
    Array.from(songDatesMap.values()).map((date) => date.date);

  const mixOrMaster =
    service.service_type === ProjectType.MASTERING ? "master" : "mix";

  return (
    <div data-theme={darkMode ? "dark" : ""} className="checkout-screen">
      <CheckoutScreenHeader
        engineerUser={engineerUser}
        service={service}
        step="Schedule"
      >
        <PrevButton onClickPreviousStep={onClickPreviousStep} />
      </CheckoutScreenHeader>
      <div className="checkout-card-row-container">
        <div className="calendar-checkout-container">
          <p className="mb-3">
            Select the dates for each record to be {mixOrMaster}ed.
          </p>
          <ProjectBookingCalendar
            availabilities={availabilities}
            selectedDays={getSelectedDays()}
            basePrice={basePricePerSong(service, altsList) * discountMultiplier}
            onClickDate={onClickDateHandler}
          />
          <div className={"checkout-tooltips mt-1"}>
            <CollapsableComponent
              hintText={"Why are there different booking prices?"}
            >
              <p>
                Certain dates might have higher prices associated with them
                because they include rush and overbook fees based on this
                engineer&apos;s live-updating schedule.
              </p>
            </CollapsableComponent>
          </div>
        </div>
        <ProjectBookingCart
          projectTitle={projectTitle}
          songsList={songList}
          altsList={altsList}
          projectType={service.service_type}
          selectedSong={selectedSong}
          selectionMap={songDatesMap}
          basePrice={basePricePerSong(service, altsList) * discountMultiplier}
          onClickSong={setSelectedSong}
        />
      </div>
      <div className="checkout-card-row-container">
        <PrimaryButton
          label={"Check Out"}
          primary={true}
          disabled={songList.length > songDatesMap.size}
          onClick={() => onClickFinishPayment(songDatesMap, undefined)}
          customClassName={" checkout-footer-next-button"}
        />
      </div>
    </div>
  );
};
