import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { PaymentRedirectType } from "../../../store/models/transaction";
import { StripePaymentRedirectPage } from "./StripePaymentRedirectPage";

const CashAppPaymentRedirectScreen = () => {
  useSetPageTitle("Cash App Payment");

  return (
    <StripePaymentRedirectPage
      paymentRedirectType={PaymentRedirectType.CASH_APP}
    />
  );
};

export default CashAppPaymentRedirectScreen;
