import {
  createContext,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface PopoverContainerContextType {
  containerMap: Map<CONTAINER_NAME, HTMLDivElement | null>;
  setContainerMap: (
    value: SetStateAction<Map<CONTAINER_NAME, HTMLDivElement | null>>,
  ) => void;
}

export const PopoverContainerContext =
  createContext<PopoverContainerContextType | null>(null);

/**
 * A hook for managing container elements that can be used as a popover collision boundary
 * @param containerKey a key associated with a specific container that is stored in the map
 * @returns an object with 2 properties
 * 1. updateContainerMapCb: A callback function that is used to pass in the `ref` property of an HTML element that should be stored as a container associated with the `containerKey`
 * 2. containerElement: the reference to the HTML element associated with the `containerKey`
 */
export const usePopoverContainerContext = (containerKey: CONTAINER_NAME) => {
  const context = useContext(PopoverContainerContext);

  if (!context) {
    throw new Error(
      "Context Provider missing. Please use this usePopoverContainerContext inside PopoverContainerContextProvider",
    );
  }

  const { containerMap, setContainerMap } = context;

  // This should NEVER be exported and used, since it will cause infinite loop
  const _unsafeUpdateContainerMapCb = useCallback(
    (key: CONTAINER_NAME) => {
      return (node: HTMLDivElement | null) => {
        setContainerMap((curRef) => {
          if (node) {
            const copy = new Map(curRef);
            copy.set(key, node);
            return copy;
          }

          return curRef;
        });
      };
    },
    [setContainerMap],
  );

  const updateContainerMapCb = useMemo(
    () => _unsafeUpdateContainerMapCb(containerKey),
    [containerKey, _unsafeUpdateContainerMapCb],
  );

  return {
    updateContainerMapCb,
    containerElement: containerMap.get(containerKey),
  };
};

interface PopoverContainerContextProviderProps {
  children: ReactNode;
}

export const PopoverContainerContextProvider = ({
  children,
}: PopoverContainerContextProviderProps) => {
  const [containerMap, setContainerMap] = useState<
    Map<CONTAINER_NAME, HTMLDivElement | null>
  >(new Map());

  return (
    <PopoverContainerContext.Provider value={{ containerMap, setContainerMap }}>
      {children}
    </PopoverContainerContext.Provider>
  );
};

export enum CONTAINER_NAME {
  SIDE_PANEL = "SIDE_PANEL",
  SIDE_PANEL__FOOTER = "SIDE_PANEL__FOOTER",
}
