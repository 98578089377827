import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ProjectType } from "../../../../store/models/project";
import { useCallback, useEffect } from "react";
import {
  setUserLatLngForUserSearch,
  toggleRequestingLatLong,
} from "../../../../store/actions/userSearch";
import { setUserLatLngForStudioRoomSearch } from "../../../../store/actions/studioRoomSearch";
import {
  updateProfile,
  updateUserLocation,
} from "../../../../store/actions/accountInfo";

const useUserLocation = () => {
  const dispatch = useAppDispatch();
  const {
    latitude,
    longitude,
    serviceTypes: selectedServices,
    requestingLatLong: isRequestingLatLong,
  } = useAppSelector((state) => state.userSearch);

  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );

  useEffect(() => {
    if (latitude > 0 && longitude > 0) {
      return;
    }
    if (
      selectedServices.includes(ProjectType.RECORDING) &&
      isRequestingLatLong
    ) {
      const onSuccess = (position: GeolocationPosition) => {
        const { latitude, longitude } = position.coords;
        dispatch(setUserLatLngForUserSearch({ latitude, longitude }));
        dispatch(setUserLatLngForStudioRoomSearch({ latitude, longitude }));
        dispatch(toggleRequestingLatLong());
      };
      const onFail = () => {
        dispatch(toggleRequestingLatLong());
      };
      window.navigator.geolocation.getCurrentPosition(onSuccess, onFail);
    }
  }, [selectedServices, dispatch, isRequestingLatLong, latitude, longitude]);

  const handleUserLocationUpdate = useCallback(async () => {
    if (navigator.permissions) {
      await navigator.permissions
        .query({ name: "geolocation" })
        .then(async (result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(async (position) => {
              await dispatch(
                updateUserLocation({
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                }),
              );
            });
          } else {
            await dispatch(updateUserLocation({}));
          }
        });
    } else {
      await dispatch(updateUserLocation({}));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated) return;
    const timezoneOffset = new Date().getTimezoneOffset();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(
      updateProfile({
        timezone_shift_minutes: timezoneOffset,
        timezone,
      }),
    )
      .unwrap()
      .then(() => {})
      .catch(() => {});
    handleUserLocationUpdate()
      .then(() => {})
      .catch(() => {});
  }, [dispatch, isAuthenticated, handleUserLocationUpdate]);
};

export default useUserLocation;
