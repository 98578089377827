import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination, { PaginationProps } from "rc-pagination";
import "./StyledPagination.css";

const DEFAULT_PAGE_SIZE = 10;

export interface StyledPaginationProps extends PaginationProps {
  disabled: boolean;
}

export const StyledPagination = (props: StyledPaginationProps) => {
  const { current, onChange, pageSize, total, disabled } = props;

  const actualTotal = total ?? 0;
  const maxSize = pageSize ?? DEFAULT_PAGE_SIZE;
  const maxPage = Math.ceil(actualTotal / maxSize);
  return (
    <Pagination
      current={current}
      total={total}
      pageSize={maxSize}
      prevIcon={
        <FontAwesomeIcon
          color={
            current && current > 1
              ? "var(--text-primary-color)"
              : "var(--text-tertiary-color)"
          }
          icon={faAngleLeft}
          size="lg"
        />
      }
      nextIcon={
        <FontAwesomeIcon
          color={
            current && current < maxPage
              ? "var(--text-primary-color)"
              : "var(--text-tertiary-color)"
          }
          icon={faAngleRight}
          size="lg"
        />
      }
      disabled={disabled}
      showSizeChanger={true}
      onChange={onChange}
    />
  );
};
