import { useMemo } from "react";
import Lottie from "react-lottie";
import whiteWaveLoader from "../../lotties/wave-loading-white.json";
import greyWaveLoader from "../../lotties/wave-loading.json";

export interface SoundWaveLoaderProps {
  width: number;
  height: number;
  whiteLoader?: boolean;
  styles?: any;
}

export const SoundWaveLoader = ({
  width,
  height,
  whiteLoader = false,
  styles,
}: SoundWaveLoaderProps) => {
  const options = useMemo(() => {
    const data = whiteLoader ? whiteWaveLoader : greyWaveLoader;
    return {
      loop: true,
      autoplay: true,
      animationData: data,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
  }, [whiteLoader]);

  return (
    <Lottie options={options} height={height} width={width} style={styles} />
  );
};
