import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { INCLUDED_TEXT_URL_REGEX } from "../../../store/utils/serviceUtils";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  ServiceCardBenefitsItemCheck,
  StyledWhatsIncludedListItem,
} from "./WhatsIncludedListItem.styles";

interface WhatsIncludedListItemProps {
  whatsIncludedBulletPoint: string;
}

export const WhatsIncludedListItem = ({
  whatsIncludedBulletPoint,
}: WhatsIncludedListItemProps) => {
  let LinkComponent = null;
  let parsedText = whatsIncludedBulletPoint;
  const match = whatsIncludedBulletPoint.match(INCLUDED_TEXT_URL_REGEX);

  if (match) {
    LinkComponent = (
      <a
        href={match[1]}
        target="_blank"
        rel="noreferrer"
        style={{ textUnderlineOffset: "2px" }}
      >
        {match[2]}
      </a>
    );
    parsedText = whatsIncludedBulletPoint.replace(INCLUDED_TEXT_URL_REGEX, "");
  }
  return (
    <StyledWhatsIncludedListItem>
      <ServiceCardBenefitsItemCheck icon={faCheck} width={12} height={16} />
      <Text variant={TextStyleVariant.P1}>
        <span style={{ marginRight: "4px" }}>{parsedText}</span>
        {LinkComponent}
      </Text>
    </StyledWhatsIncludedListItem>
  );
};
