import React, { FC, useCallback, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  loadNotifications,
  updateReadNotifications,
} from "../../../store/actions/notifications";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectNotificationData } from "../../../store/selectors/notificationSelectors";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import { convertHttpsLinkToRelativeLinkIfSameDomain } from "../../../utils/utils";
import { NotificationRow } from "../../elements/NotificationRow/NotificationRow";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import "./NotificationsList.css";
import { Text } from "../../core-ui/components/Text/Text";

export interface NotificationsListProps {
  isDisplayed: boolean;
}

export const NotificationsList: FC<NotificationsListProps> = ({
  isDisplayed,
}) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectNotificationData());
  const { notifications, hasNextPage, loading, currentPage } = data;
  const user = useAppSelector((state) => state.accountInfo.user);
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();

  useEffect(() => {
    if (notifications.length === 0) return;
    if (!isDisplayed) return;
    if (!user) return;
    if (window && window.analytics) {
      window.analytics.track(getDebugEventPrefix + "read_notifications", {
        user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
      });
    }

    dispatch(
      updateReadNotifications({ last_notification_read: notifications[0].id }),
    );
  }, [notifications, dispatch, user, isDisplayed]);

  const handleClick = (link: string) => {
    window.analytics.track(getDebugEventPrefix + "click_notification", {
      link: link,
      user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
    });
    if (pathname === link) {
      history.go(0);
    }
  };

  const fetchMore = useCallback(() => {
    if (!hasNextPage) return;
    dispatch(
      loadNotifications({
        page: currentPage + 1,
      }),
    );
  }, [hasNextPage, currentPage, dispatch]);

  const NotificationLink = ({
    children,
    link,
  }: {
    children: React.ReactNode;
    link?: string | null;
  }) => {
    const domain = new URL(window.location.origin);
    const notificationLink = link
      ? convertHttpsLinkToRelativeLinkIfSameDomain(link, domain)
      : null;
    if (!notificationLink) return <>{children}</>;

    if (notificationLink.startsWith("http"))
      return (
        <a href={notificationLink} className="notification-link">
          {children}
        </a>
      );

    return (
      <Link to={notificationLink} className="notification-link">
        {children}
      </Link>
    );
  };

  return (
    <table className="notifications-table">
      <tbody>
        {notifications.map((notification, idx) => (
          <>
            <tr
              key={idx}
              className="notifications-row"
              onClick={() => handleClick(notification.link!)}
            >
              <td>
                <NotificationLink link={notification.link}>
                  <NotificationRow notification={notification} />
                </NotificationLink>
              </td>
            </tr>
          </>
        ))}
        {hasNextPage && !loading && (
          <button
            type="button"
            className="load-more-link"
            disabled={false}
            onClick={fetchMore}
          >
            View More
          </button>
        )}
      </tbody>
      {!loading && notifications.length === 0 && (
        <div className="no-notifications-text">
          <Text>No notifications yet...</Text>
        </div>
      )}
      {loading && (
        <div className="no-notifications-text">
          <SoundWaveLoader width={100} height={100} />
        </div>
      )}
    </table>
  );
};
