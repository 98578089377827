import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";

export const selectFileVersions = (state: RootState) => {
  return state.fileVersionStore;
};

export const getFileVersionBlobUrl = (fileVersionId: number) =>
  createSelector(selectFileVersions, (fileVersionsSlice) => {
    return fileVersionsSlice.downloadedFileVersions[fileVersionId]
      ?.trackBlobUrl;
  });

export const getFileVersionBlobUrlIsLoading = (fileVersionId: number) =>
  createSelector(selectFileVersions, (fileVersionsSlice) => {
    return fileVersionsSlice.downloadedFileVersions[fileVersionId]
      ?.isTrackBlobUrlLoading;
  });

export const getFileVersionGeneratedMP3BlobUrl = (fileVersionId: number) =>
  createSelector(selectFileVersions, (fileVersionsSlice) => {
    return fileVersionsSlice.downloadedFileVersions[fileVersionId]
      ?.trackGeneratedMP3BlobUrl;
  });
