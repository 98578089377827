import { useAtom, useAtomValue } from "jotai";
import { useCallback, useRef } from "react";
import { useResizeObserver } from "usehooks-ts";
import { showTopNavAtom, topNavHeightAtom } from "../../../atoms/navAtoms";
import { NavSpacer } from "../../screens/NavSpacer/NavSpacer";
import { Size } from "../SessionScheduleGuide/SessionScheduleGuide";
import "./AppBarHeader.css";
import { Box, BoxProps } from "@mui/material";

type AppBarHeaderContainerProps = BoxProps;

export const AppBarHeaderContainer = ({
  children,
  ...props
}: AppBarHeaderContainerProps) => {
  const showHeader = useAtomValue(showTopNavAtom);
  const [height, setHeight] = useAtom(topNavHeightAtom);
  const elementRef = useRef<HTMLDivElement>(null);
  const onResize = useCallback(
    (size: Size) => {
      setTimeout(() => {
        setHeight(size.height || 0);
      }, 0);
    },
    [setHeight],
  );
  useResizeObserver({
    ref: elementRef,
    box: "border-box",
    onResize,
  });

  return (
    <>
      <NavSpacer variant="top" />
      <Box
        className="sticky-header-container"
        style={{
          top: showHeader ? "0" : `${height * -1}px`,
        }}
        {...props}
        ref={elementRef}
      >
        {children}
      </Box>
    </>
  );
};
