import React, { ChangeEvent, FC, useCallback, useState } from "react";
import "./StudioSubModal.css";
import { BaseModal } from "../BaseModal/BaseModal";
import { Button } from "../../elements/Button/button";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { validateEmailList } from "../../../utils/utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateProfile } from "../../../store/actions/accountInfo";
import { toggleShowAccountInfoModal } from "../../../store/actions/userInterface";
import { useHistory } from "react-router-dom";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import { on } from "events";

export interface StudioSubModalProps {
  modalIsOpen: boolean;
  explanationText: string;
  onClickContinue?: () => void;
  onClickCancel?: () => void;
}

export const StudioSubModal: FC<StudioSubModalProps> = ({
  modalIsOpen,
  explanationText,
  onClickContinue,
  onClickCancel,
}) => {
  const handleClose = useCallback(() => {
    if (!onClickCancel) return;
    onClickCancel();
  }, [onClickCancel]);

  return (
    <BaseModal
      modalIsOpen={modalIsOpen}
      closeModal={handleClose}
      label={"Upgraded Studio Membership Required"}
    >
      <div className="account-details-container card-container">
        <p className="mt-3">
          <strong>{explanationText}</strong>
        </p>
        <div className="studio-sub-button-row">
          <Button
            label={"cancel"}
            onClick={handleClose}
            primary={false}
            customClassName="studio-sub-cancel-button"
          />
          <Button
            label={"continue"}
            onClick={() => {
              if (!onClickContinue) return;
              onClickContinue();
            }}
            primary={true}
          />
        </div>
      </div>
    </BaseModal>
  );
};
