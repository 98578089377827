import { useEffect, useState } from "react";
import { DetailedPurchaseOrder } from "../store/models/project";
import {
  useIsBudgetApproved,
  useBillingInfoOrPurchaseOrderSubmitted,
} from "./purchaseOrderWithTransactionHooks";

export enum TransactionTitles {
  Pending = "Order Summary",
  BudgetApproval = "Budget Approval Requested",
  BillingInfoRequested = "Submit Purchase Order /  Direct Billing Information",
  ProjectInProgressAndInfoSubmitted = "Project In Progress",
  ProjectComplete = "Project Complete",
}

export enum HeaderPosition {
  First,
  Second,
  Third,
}

export const useGetTransactionOverviewTitle = (
  purchaseOrder?: DetailedPurchaseOrder,
) => {
  const [overviewTitle, setOverviewTitle] = useState<TransactionTitles>(
    TransactionTitles.Pending,
  );
  const isBudgetApproved = useIsBudgetApproved(purchaseOrder);
  const { purchaseOrderSubmitted, billingInfoSubmitted } =
    useBillingInfoOrPurchaseOrderSubmitted(purchaseOrder);
  const isBillingInfoOrPurchaseOrderSubmitted =
    purchaseOrderSubmitted || billingInfoSubmitted;

  useEffect(() => {
    if (!purchaseOrder) return setOverviewTitle(TransactionTitles.Pending);
    if (!isBudgetApproved)
      return setOverviewTitle(TransactionTitles.BudgetApproval);

    if (!isBillingInfoOrPurchaseOrderSubmitted)
      return setOverviewTitle(TransactionTitles.BillingInfoRequested);

    if (!purchaseOrder.all_projects_approved)
      return setOverviewTitle(
        TransactionTitles.ProjectInProgressAndInfoSubmitted,
      );

    setOverviewTitle(TransactionTitles.ProjectComplete);
  }, [purchaseOrder]);

  return overviewTitle;
};
