import { PopoverTrigger } from "@radix-ui/react-popover";
import { useState } from "react";
import useModal from "../../../../hooks/useModal";
import { ProjectById } from "../../../../store/models/project";
import { BasePopover } from "../../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../core-ui/components/BasePopover/PopoverContainerContext";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { Text } from "../../../core-ui/components/Text/Text";
import { EditableTextArea } from "../../../elements/EditableTextArea/EditableTextArea";
import { ApproveMixPopoverContent } from "../../InProgressTransitionView/InProgressTransitionView.styles";
import { useRequestReupload } from "../hooks/useRequestReupload";

interface RequestReuploadButtonProps {
  project: ProjectById;
  isDeliverablesUploaded: boolean;
  transitionView: () => void;
  disabled: boolean;
}

export const RequestReuploadButton = ({
  project,
  isDeliverablesUploaded,
  transitionView,
  disabled,
}: RequestReuploadButtonProps) => {
  const requestReupload = useRequestReupload(project.service_type, project.id);
  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  if (!isDeliverablesUploaded || disabled) {
    return (
      <Button disabled={true} fullWidth variant={ButtonVariant.OUTLINED}>
        Request reupload
      </Button>
    );
  }

  return (
    <BasePopover
      isOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      closePopover={closePopover}
      disableAutoClose
      side="top"
      title="Re-upload request notes"
      description=""
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await requestReupload(comment);
          transitionView();
          closePopover();
        } finally {
          setIsLoading(false);
        }
      }}
      wrapperElement={containerElement}
      additionalContent={
        <ApproveMixPopoverContent>
          <Text>
            Let the engineer know which files need to be re-uploaded and why
          </Text>
          <EditableTextArea
            editMode={true}
            initialValue={comment}
            onChange={setComment}
            placeholder="e.g. 'Upload is missing vocal stems'"
          />
        </ApproveMixPopoverContent>
      }
      okButtonProps={{
        disabled: !comment || isLoading,
        loading: isLoading,
      }}
    >
      <PopoverTrigger asChild>
        <Button
          loading={isLoading}
          disabled={isLoading}
          fullWidth
          variant={ButtonVariant.OUTLINED}
        >
          Request reupload
        </Button>
      </PopoverTrigger>
    </BasePopover>
  );
};
