import React, { FC, useCallback, useEffect, useState } from "react";
import { Alt } from "../../../store/models/alts";
import { downloadTrack } from "../../../store/actions/audioService";
import { useAppDispatch } from "../../../store/hooks";
import { useLocation } from "react-router-dom";
import "./Mp4PlayBack.css";
import dolbyPlayerImage from "../../assets/DAHeadIcon.svg";
import { downloadFilesFromUrls } from "../../../store/utils/downloadFilesFromUrls";
import { fileVersionDownloadURL } from "../../../store/actions/fileVersions";
import Lottie from "react-lottie";
import alert from "../../lotties/alert.json";
import { useLatestMP4 } from "../../../hooks/useFilesFromFileVersionStore";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: alert,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export interface Mp4PlayBackProps {
  altToDisplay: Alt.MP4 | Alt.CLEAN_MP4;
  projectId: number;
}

export const Mp4PlayBack: FC<Mp4PlayBackProps> = ({
  projectId,
  altToDisplay,
}) => {
  const file = useLatestMP4(projectId, altToDisplay);
  const [url, setURL] = useState<string>("");
  const [showErrorMessage, setShowMessage] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const code: null | string = query.get("code");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!file) return;
    setURL("");
    dispatch(downloadTrack({ fileVersionId: file.id, code: code ?? undefined }))
      .unwrap()
      .then((data) => {
        setURL(data);
      });
  }, [file, code, altToDisplay]);

  if (!url) {
    return <SoundWaveLoader width={100} height={100} />;
  }

  if (file?.alt !== Alt.MP4 && file?.alt !== Alt.CLEAN_MP4) return null;
  return (
    <div
      className="mp4-column"
      style={{
        backgroundImage: !showErrorMessage ? `url(${dolbyPlayerImage})` : "",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <p className="b1-semi-bold">Dolby Atmos .mp4 file</p>
      <p className="b1">{`${
        altToDisplay === Alt.CLEAN_MP4 ? "Clean Version" : "Main Version"
      }`}</p>
      {showErrorMessage && (
        <div className="error-message-device-not-supported">
          <div className="error-lottie-container">
            <Lottie options={lottieOptions} width={25} height={25} />
            <p style={{ display: "block" }} className={"label"}>
              device not supported
            </p>
          </div>
          <p className={"b1"}>
            {" "}
            <span>
              Your device or browser does not support Dolby Atmos mp4 audio
              playback
            </span>
            . Safari 10+ on Mac OS/iOS devices or Microsoft Edge for Windows 10+
            devices is required.
            <br />
            <br /> Please switch devices or browser to listen to the Atmos .mp4
            file.
          </p>
        </div>
      )}
      {!showErrorMessage && (
        <div className="mp4-playback-row">
          <audio controls className="audio-playback-mp4">
            <source
              onError={() => {
                setShowMessage(true);
              }}
              src={url}
              type="audio/mp4"
            />
            <a href={url}>Download audio</a>
          </audio>
        </div>
      )}
    </div>
  );
};
