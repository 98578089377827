import { useEffect, useRef, useState } from "react";
import { setCurrentPosition } from "../../store/actions/abPlayerStore";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { getFooterWaveformRef } from "./waveformHooks";
import { getCurrentTrackFromPlaylist } from "../../store/selectors/abPlayerSelectors";

/**
 * @description Setup useEffects to track the current position of the audio player when the screen is locked.
 */
export const useTrackPositionWhenLocked = () => {
  const dispatch = useAppDispatch();
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const [isDocumentHidden, setIsDocumentHidden] = useState(false);
  const currentTrack = useAppSelector(getCurrentTrackFromPlaylist());

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (!currentTrack) {
        return;
      }
      emitAnalyticsTrackingEvent("toggle_ab_player_screen_lock", {
        id: currentTrack.id,
        hidden: document.hidden,
      });
      setIsDocumentHidden(document.hidden);
    });

    return () => {
      document.removeEventListener("visibilitychange", () => {});
    };
  }, [currentTrack?.id]);

  // If the user locks their screen, update the currentPosition in our store
  // with a useInterval function. WaveSurfer.js uses requestAnimationFrame to update the
  // audio playback callback, but that doesn't run if the screen is locked.
  // Once the screen is shown again, clear the created interval.
  useEffect(() => {
    const intervalFn = () => {
      const footerPlayerRef = getFooterWaveformRef();
      if (footerPlayerRef && footerPlayerRef.isPlaying()) {
        dispatch(setCurrentPosition(footerPlayerRef.getCurrentTime()));
      }
    };
    if (isDocumentHidden) {
      intervalRef.current = setInterval(intervalFn, 100);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [dispatch, isDocumentHidden, currentTrack]);
};
