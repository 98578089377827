import React, { FC } from "react";
import "./ToolTipBubble.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

export interface ToolTipBubbleProps {
  children: JSX.Element | JSX.Element[];
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  maxWidth?: string | number;
  text?: string;
  header?: string;
  showToolTip: boolean;
  onClick?: () => void;
}

export enum ToolTipTypes {
  ChatBubbleButton = "ChatBubbleButton",
}

export const ToolTipBubble: FC<ToolTipBubbleProps> = ({
  children,
  showToolTip,
  top,
  left,
  right,
  bottom,
  header,
  text,
  maxWidth,
  onClick,
}) => {
  return (
    <div className="component-wrapper">
      {children}
      {showToolTip && (
        <div
          className="tool-tip-bubble card-container"
          // @ts-ignore
          style={{ top, left, right, bottom, maxWidth }}
        >
          <div className="header-container">
            {header && <header className="header-font">{header}</header>}
            <FontAwesomeIcon
              icon={faX}
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={onClick}
              height={10}
              width={10}
              color={"var(--black)"}
            />
          </div>
          {text && <p className="b1 body-font">{text}</p>}
        </div>
      )}
    </div>
  );
};
