import bell from "../bell.svg";
import message from "../Icon_Messages.svg";
import project from "../listCheckSolid.svg";
import bellRing from "../bell-ring.svg";

export const notificationIconList = [
  bell,
  message,
  project,
  project,
  bell,
  message,
  project,
  message,
  bellRing,
];
