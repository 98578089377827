import { useMemo } from "react";
import { ProjectType } from "../store/models/project";

export const MIN_SERVICE_RATES = {
  [ProjectType.MIXING]: 100,
  [ProjectType.MASTERING]: 50,
  [ProjectType.TWO_TRACK_MIXING]: 100,
  [ProjectType.ATMOS_MIXING]: 100,
  [ProjectType.RECORDING]: 15,
  [ProjectType.NO_TYPE]: 0,
};

const MAX_SERVICE_RATES = {
  [ProjectType.MIXING]: 5000,
  [ProjectType.MASTERING]: 1000,
  [ProjectType.TWO_TRACK_MIXING]: 5000,
  [ProjectType.ATMOS_MIXING]: 5000,
  [ProjectType.RECORDING]: 1000,
  [ProjectType.NO_TYPE]: 0,
};

export const useMinAndMaxServiceRates = (
  serviceType: ProjectType,
): [number, number] => {
  return useMemo(() => {
    return [MIN_SERVICE_RATES[serviceType], MAX_SERVICE_RATES[serviceType]];
  }, [serviceType]);
};
