import { PopoverTrigger } from "@radix-ui/react-popover";
import { useSetAtom } from "jotai";
import { sidePanelBottomDrawerContentAtom } from "../../../../../../atoms/sidePanelAtoms";
import { useMediaQueryBreakpoint } from "../../../../../../hooks/useMediaQuery";
import useModal from "../../../../../../hooks/useModal";
import { useQueryParam } from "../../../../../../hooks/useQueryParam";
import { BasePopover } from "../../../../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../../../core-ui/components/BasePopover/PopoverContainerContext";
import { Text } from "../../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../../core-ui/components/Text/TextUtils";
import { DropdownMenuItem } from "../../../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";
import { ShareLinkModal } from "../../../../ShareLinkModal/ShareLinkModal";

export interface ProjectActionShareListenLinkProps {
  projectId: number | null;
}

/**
 * Action Option to share listen link
 * the ShareLinkModal component
 */
export const ProjectActionShareListenLink = ({
  projectId,
}: ProjectActionShareListenLinkProps) => {
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  const { isMobile } = useMediaQueryBreakpoint();
  const { isOpen, setIsOpen, closeModal } = useModal();
  const setBottomDrawerContent = useSetAtom(sidePanelBottomDrawerContentAtom);
  const codeQuery = useQueryParam("code");
  const code = codeQuery.get();

  // Don't show the "share link" option if already on the listen link page
  if (!projectId || code) return null;

  if (isMobile) {
    return (
      <DropdownMenuItem
        onSelect={() => {
          setBottomDrawerContent(
            <div style={{ padding: "0 8px" }}>
              <Text variant={TextStyleVariant.H5}>Share playback page</Text>
              <ShareLinkModal
                contentOnly
                projectId={projectId.toString()}
                closeModal={closeModal}
              />
            </div>,
          );
        }}
      >
        Share link
      </DropdownMenuItem>
    );
  }

  return (
    <BasePopover
      isOpen={isOpen}
      setIsPopoverOpen={setIsOpen}
      side="top"
      title="Share playback page"
      description={
        <ShareLinkModal
          contentOnly
          projectId={projectId.toString()}
          closeModal={closeModal}
        />
      }
      hideFooter
      wrapperElement={containerElement}
    >
      <PopoverTrigger asChild>
        <DropdownMenuItem onSelect={(e: Event) => e.preventDefault()}>
          Share link
        </DropdownMenuItem>
      </PopoverTrigger>
    </BasePopover>
  );
};
