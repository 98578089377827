import { FC, useEffect, useMemo, useState } from "react";
import "./PercentageSlider.css";
import { ProjectType } from "../../../store/models/project";

export interface PercentageSliderProps {
  handleUpdate: (value: number) => void;
  serviceType?: ProjectType;
  minValue?: number;
  maxValue?: number;
  initialValue?: number;
  minutes?: boolean;
  increment?: number;
  label?: string;
  displayFactor?: number;
  roundingFactor?: number;
}

const MIN_MASTERING_RANGE = 20;
export const MAX_MASTERING_RANGE = 50;
const MIN_MIXING_RANGE = 5;
const MAX_MIXING_RANGE = 15;
const MIN_RECORDING_RANGE = 0;
const MAX_RECORDING_RANGE = 20;

export const PercentageSlider: FC<PercentageSliderProps> = ({
  handleUpdate,
  serviceType = ProjectType.MIXING,
  minValue,
  maxValue,
  initialValue,
  increment,
  label = "%",
  displayFactor = 1,
  roundingFactor = 5,
}) => {
  const [value, setValue] = useState(initialValue || 5);

  useEffect(() => {
    handleUpdate(value);
  }, [value]);

  const [min, max] = useMemo(() => {
    if (minValue !== undefined && maxValue !== undefined) {
      return [minValue, maxValue];
    }
    if (serviceType === ProjectType.RECORDING) {
      setValue(MIN_RECORDING_RANGE);
      return [MIN_RECORDING_RANGE, MAX_RECORDING_RANGE];
    }
    if (serviceType === ProjectType.MASTERING) {
      setValue(MIN_MASTERING_RANGE);
      return [MIN_MASTERING_RANGE, MAX_MASTERING_RANGE];
    }
    setValue((currPercentage) => {
      if (currPercentage < MIN_MIXING_RANGE) {
        return MIN_MIXING_RANGE;
      } else if (currPercentage > MAX_MIXING_RANGE) {
        return MAX_MIXING_RANGE;
      }
      return currPercentage;
    });
    return [MIN_MIXING_RANGE, MAX_MIXING_RANGE];
  }, [serviceType, minValue, maxValue]);

  return (
    <div className="percentage-slider-container">
      <input
        onChange={(e) => {
          let value = +e.target.value;
          value = Math.round(value / roundingFactor) * roundingFactor; // Round to the nearest multiple of roundingFactor
          setValue(value);
        }}
        type="range"
        min={min}
        max={max}
        value={value}
        className="percentage-slider"
      />
      <p className="label2-semi-bold percentage-label">
        {increment
          ? `${value * increment * displayFactor} ${label}`
          : `${value.toFixed(2)} ${label}`}
      </p>
    </div>
  );
};
