import { useRef } from "react";
import { MuiTelInputProps, MuiTelInput } from "mui-tel-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

export interface ValidPhoneInputProps {
  isValidPhoneNumber: boolean;
}

export const PhoneInput = ({
  isValidPhoneNumber,
  ...props
}: MuiTelInputProps & ValidPhoneInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const moveCursorToEnd = () => {
    if (inputRef.current) {
      const length = inputRef.current.value.length;
      inputRef.current.setSelectionRange(length, length);
    }
  };

  const handleFocus = () => {
    setTimeout(moveCursorToEnd, 0);
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    moveCursorToEnd();
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    moveCursorToEnd();
  };

  return (
    <MuiTelInput
      {...props}
      inputRef={inputRef}
      onFocus={handleFocus}
      onMouseUp={handleMouseUp}
      onClick={handleClick}
      InputProps={{
        endAdornment: isValidPhoneNumber ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : null,
      }}
    />
  );
};
