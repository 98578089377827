import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StudioRoom } from "../models/studio";
import {
  GET_OPTED_IN_UNVERIFIED_ENGINEERS,
  GET_TRENDING_ENGINEERS,
  GET_TRENDING_STUDIO_ROOMS,
} from "../utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import User from "../models/user";

interface TrendingHomepage {
  trendingEngineers: User[];
  trendingStudioRooms: StudioRoom[];
  isLoadingEngineers: boolean;
  isLoadingStudioRooms: boolean;
}

const initialState: TrendingHomepage = {
  trendingEngineers: [],
  trendingStudioRooms: [],
  isLoadingEngineers: false,
  isLoadingStudioRooms: false,
};

export const getTrendingStudioRooms = createAsyncThunk(
  GET_TRENDING_STUDIO_ROOMS,
  async (_, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<StudioRoom[]>(
      GET_TRENDING_STUDIO_ROOMS,
      "",
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getTrendingEngineers = createAsyncThunk(
  GET_TRENDING_ENGINEERS,
  async (_, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<User[]>(
      GET_TRENDING_ENGINEERS,
      "",
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getOptedInUnverifiedEngineers = createAsyncThunk(
  GET_OPTED_IN_UNVERIFIED_ENGINEERS,
  async (_, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<User[]>(
      GET_OPTED_IN_UNVERIFIED_ENGINEERS,
      "",
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    return thunkAPI.rejectWithValue(errors);
  },
);

export const homepageStore = createSlice({
  name: "homepageStore",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTrendingEngineers.pending, (state) => {
      state.isLoadingEngineers = true;
    });
    builder.addCase(getTrendingStudioRooms.pending, (state) => {
      state.isLoadingStudioRooms = true;
    });
    builder.addCase(getTrendingEngineers.rejected, (state) => {
      state.isLoadingEngineers = false;
    });
    builder.addCase(getTrendingStudioRooms.rejected, (state) => {
      state.isLoadingStudioRooms = false;
    });
    builder.addCase(getTrendingEngineers.fulfilled, (state, action) => {
      state.trendingEngineers = action.payload;
      state.isLoadingEngineers = false;
    });
    builder.addCase(getTrendingStudioRooms.fulfilled, (state, action) => {
      state.trendingStudioRooms = action.payload;
      state.isLoadingStudioRooms = false;
    });
  },
});

export default homepageStore.reducer;
