import { getDocUrl } from "../../../constants/googleStorage";
import { useAtomValue } from "jotai";
import { ProjectType } from "../../../store/models/project";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";
import {
  TEXT_SIZE,
  TEXT_WEIGHT,
  Text,
} from "../../core-ui/components/Text/Text";
import { MainProjectWorkflowButtonRow } from "../MainProjectWorkflowPanel/MainProjectWorkflowButtonRow";
import {
  RequestedPanelActionButtons,
  RequestedPanelDescriptionText,
} from "./RequestedPanelFooter.styles";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

const RECORDING_SESSION_BUTTON_TEXTS = {
  rejectPopconfirmTitle: "Rejecting the booking",
  rejectPopconfirmDescription: "Are you sure you want to reject this booking?",
  rejectTextContent: "Reject booking",
  approvePopconfirmTitle: "Accepting the booking",
  approvePopconfirmDescription: "Are you sure you want to accept this booking?",
  approveTextContent: "Accept booking",
};

const SCHEDULED_PROJECT_BUTTON_TEXTS = {
  rejectPopconfirmTitle: "Rejecting the project",
  rejectPopconfirmDescription:
    "Are you sure you want to reject this project? You will not be able to undo this action and a refund will be issued to the client.",
  rejectTextContent: "Reject project",
  approvePopconfirmTitle: "Accepting the project",
  approvePopconfirmDescription: "Are you sure you want to accept this project?",
  approveTextContent: "Accept project",
};

interface RequestedPanelFooter {
  onReject: () => void;
  onApprove: () => void;
  isRejecting: boolean;
  isApproving: boolean;
  projectType?: ProjectType;
}

export const RequestedPanelFooter = ({
  onApprove,
  onReject,
  isRejecting,
  isApproving,
  projectType,
}: RequestedPanelFooter) => {
  const isDarkMode = useAtomValue(darkModeAtom);
  const buttonTexts =
    projectType === ProjectType.RECORDING
      ? RECORDING_SESSION_BUTTON_TEXTS
      : SCHEDULED_PROJECT_BUTTON_TEXTS;
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  return (
    <MainProjectWorkflowButtonRow>
      <div>
        <Text weight={TEXT_WEIGHT.SEMI_BOLD} size={TEXT_SIZE.LARGE}>
          By accepting this project
        </Text>
        <RequestedPanelDescriptionText size={TEXT_SIZE.XS}>
          You agree to the{" "}
          <a
            style={{
              color: isDarkMode ? "white" : "black",
              textDecoration: "underline",
            }}
            href={getDocUrl("EngineEarsRefundPolicy.pdf")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Refund Policy
          </a>{" "}
          provided by EngineEars and will abide by our{" "}
          <a
            style={{
              color: isDarkMode ? "white" : "black",
              textDecoration: "underline",
            }}
            href={getDocUrl("EngineEarsTermsOfUse.pdf")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>{" "}
          throughout the collaboration process.
        </RequestedPanelDescriptionText>
        <RequestedPanelActionButtons>
          <PopConfirm
            title={buttonTexts.rejectPopconfirmTitle}
            description={buttonTexts.rejectPopconfirmDescription}
            onConfirm={onReject}
            wrapperElement={containerElement}
          >
            <Button variant={ButtonVariant.OUTLINED} loading={isRejecting}>
              {buttonTexts.rejectTextContent}
            </Button>
          </PopConfirm>
          <PopConfirm
            title={buttonTexts.approvePopconfirmTitle}
            description={buttonTexts.approvePopconfirmDescription}
            onConfirm={onApprove}
            wrapperElement={containerElement}
          >
            <Button variant={ButtonVariant.PRIMARY} loading={isApproving}>
              {buttonTexts.approveTextContent}
            </Button>
          </PopConfirm>
        </RequestedPanelActionButtons>
      </div>
    </MainProjectWorkflowButtonRow>
  );
};
