import { Box, styled } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useMinAndMaxServiceRates } from "../../../../hooks/useMinAndMaxServiceRates";
import { ProjectType } from "../../../../store/models/project";
import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";
import { FixedRangeNumberField } from "../../../elements/FixedRangeNumberField/FixedRangeNumberField";
import { AdminModalDropdown } from "./AdminModalDropdown";

const InputLabel = styled(Text)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

interface AdminAssignEngineerInputProps {
  engineerOptions: OptionType[];
  labelText: string;
  minRate?: number;
  rate: number;
  setRate: Dispatch<SetStateAction<number>>;
  selectedEngineer: OptionType | null;
  setSelectedEngineer: Dispatch<SetStateAction<OptionType | null>>;
}

export const AdminAssignEngineerInput = ({
  engineerOptions,
  labelText,
  minRate,
  rate,
  setRate,
  selectedEngineer,
  setSelectedEngineer,
}: AdminAssignEngineerInputProps) => {
  const [minServiceRate, maxServiceRate] = useMinAndMaxServiceRates(
    ProjectType.MIXING,
  );

  return (
    <Box sx={{ marginBottom: 4 }}>
      <InputLabel variant={TextStyleVariant.S3}>{labelText}</InputLabel>
      <Box sx={{ alignItems: "center", display: "flex", gap: 2 }}>
        <AdminModalDropdown
          dropdownWidth="166px"
          options={engineerOptions}
          placeholder="Select engineer"
          selectedOption={selectedEngineer}
          setSelectedOption={setSelectedEngineer}
        />
        <Text bold>Rate</Text>
        <Box sx={{ alignItems: "center", display: "flex", gap: 0.5 }}>
          <Text bold>$</Text>
          <FixedRangeNumberField
            min={minRate ?? minServiceRate}
            max={maxServiceRate}
            initialValue={rate} // TODO: get initial value from Engineer service price
            onChange={setRate}
          />
        </Box>
      </Box>
    </Box>
  );
};
