import { LOGIN } from "../../store/utils/routes";
import { AuthResponse } from "../../store/actions/accountInfo";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { Error as EngineEarsError } from "../../store/actions/errorStore";

export interface LoginParams {
  username: string;
  password: string;
}

export const postLogin = async (
  params: LoginParams,
): Promise<AuthResponse | EngineEarsError> => {
  const body = {
    username: params.username.toLowerCase().trim(),
    password: params.password,
  };
  return makeBackendPostCallWithJsonResponse<AuthResponse>(LOGIN, body).then(
    (res) => {
      if (res.success) {
        return res.resultJson;
      }
      return res.resultJson;
    },
  );
};
