import styled from "styled-components";

export const BillingInfoInput = styled.input`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.separatorColor};
  background: ${({ theme }) => theme.backgroundColor};
  height: 44px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  align-self: stretch;
  flex: 1;
`;

export const BillingInfoInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
`;

export const SuccessTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const RequestBudgetApprovalModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
`;

export const RequestBudgetApprovalModalBodyContainer = styled(
  RequestBudgetApprovalModalContainer,
)`
  gap: unset;
  width: 100%;
`;

export const RequestBudgetApprovalModalText = styled.p<{
  isBold?: boolean;
}>`
  font-weight: ${({ isBold }) => (isBold ? "600" : "normal")};
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size:  ${({ theme }) => theme.textSizeMd};
  line-height: 20px;
  @media (max-width: 768px) {
    .b1-semi-bold {
      font-size: ${({ theme }) => theme.textSizeXs};
      line-height: 15px;
    }
  
`;
