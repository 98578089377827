import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { CenteredContainer } from "./CenteredSoundWaveLoader.styles";

export const CenteredSoundWaveLoader = () => {
  return (
    <CenteredContainer>
      <SoundWaveLoader width={100} height={100} />
    </CenteredContainer>
  );
};
