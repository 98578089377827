import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../core-ui/components/BasePopover/PopoverContainerContext";
import { Button } from "../../../core-ui/components/Button/Button";
import { PopConfirm } from "../../../core-ui/components/PopConfirm/PopConfirm";
import { useApproveProjectFiles } from "../hooks/useApproveProjectFiles";

export interface ApproveAssetsButtonProps {
  isDeliverablesUploaded: boolean;
  projectId: number;
}

export const ApproveAssetsButton = ({
  isDeliverablesUploaded,
  projectId,
}: ApproveAssetsButtonProps) => {
  const { approving, handleMarkApproved } = useApproveProjectFiles(projectId);
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );
  return (
    <PopConfirm
      title="Are you sure?"
      description="Are you sure you want to mark your approval of these deliverables?"
      onConfirm={handleMarkApproved}
      okButtonProps={{ loading: approving, disabled: approving }}
      wrapperElement={containerElement}
    >
      <Button disabled={approving || !isDeliverablesUploaded} fullWidth>
        {isDeliverablesUploaded
          ? "Approve assets"
          : "Approve Assets (waiting for files)"}
      </Button>
    </PopConfirm>
  );
};
