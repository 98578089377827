import { CSSProperties, useMemo } from "react";
import { ReleaseTrack } from "../../../store/models/release";
import { useColumnDefinitionForReleaseTracks } from "./hooks";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  Row,
} from "@tanstack/react-table";
import {
  ArtistReleaseTableHeader,
  ArtistReleaseTableRow,
  ArtistReleaseTableWrapper,
  ReleaseTableHeaderView,
} from "./styles";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { usePlayPauseOnFooter } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { useQueryClient } from "@tanstack/react-query";
import { updatePlayListOrder } from "../../../store/actions/abPlayerStore";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { convertArtistReleaseToPlaylistTrack } from "../../../store/models/playListTrack";
import { useTrackOrderUpdateMutation } from "../../../api/releases/hooks/useTrackOrderUpdateMutation";

interface ReleaseTrackTableProps {
  releaseTracks: ReleaseTrack[];
  artistUserId?: number;
  unitPrice?: string | null;
}

const DraggableArtistReleaseTrackTableRow = ({
  row,
  unitPrice,
}: {
  row: Row<ReleaseTrack>;
  unitPrice?: string | null;
}) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
  };

  return (
    <ArtistReleaseTableRow ref={setNodeRef} style={style} key={row.id}>
      {row.getVisibleCells().map((cell) => {
        return (
          <td
            key={cell.id}
            style={{
              flex: cell.id.includes("play_section") ? 1 : 0,
            }}
          >
            {flexRender(
              cell.column.columnDef.cell,
              unitPrice
                ? {
                    ...cell.getContext(),
                    unitPrice,
                  }
                : cell.getContext(),
            )}
          </td>
        );
      })}
    </ArtistReleaseTableRow>
  );
};

export const ArtistReleaseTrackTable = ({
  releaseTracks,
  artistUserId = 0,
  unitPrice,
}: ReleaseTrackTableProps) => {
  const queryClient = useQueryClient();
  const { isFooterPlaying, currentTrackIndex, playlist } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const dispatch = useAppDispatch();
  const { handleClick: pauseFooterPlayer } = usePlayPauseOnFooter();
  const { mutate: updateTrackOrder } = useTrackOrderUpdateMutation();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const isArtistOnRelease = useMemo(() => {
    return loggedInUser?.id === artistUserId;
  }, [loggedInUser, artistUserId]);
  const columns = useColumnDefinitionForReleaseTracks(isArtistOnRelease);

  const table = useReactTable({
    data: releaseTracks,
    columns:
      loggedInUser && loggedInUser.id === artistUserId
        ? columns
        : columns.filter((column) => column.id !== "reorder-tab"),
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(),
  });

  const dataIds = useMemo<UniqueIdentifier[]>(
    () => releaseTracks?.map(({ id }) => id),
    [releaseTracks],
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    // Find active and over release tracks
    const activeRelease = releaseTracks.find(
      (releaseTrack) => releaseTrack.id === active.id,
    );
    const overRelease = releaseTracks.find(
      (releaseTrack) => releaseTrack.id === over?.id,
    );

    // Early return if either release is not found
    if (!activeRelease || !overRelease) {
      return;
    }

    const activeId = activeRelease.id;
    const overId = overRelease.id;

    // Find the indexes of active and over releases
    const activeIndex = releaseTracks.findIndex(
      (releaseTrack) => releaseTrack.id === activeId,
    );
    const overIndex = releaseTracks.findIndex(
      (releaseTrack) => releaseTrack.id === overId,
    );

    // Update the track number in the release tracks
    const updatedData = releaseTracks.map((releaseTrack) => {
      if (releaseTrack.id === overId) {
        return { ...releaseTrack, track_number: activeRelease.track_number };
      } else if (releaseTrack.id === activeId) {
        return { ...releaseTrack, track_number: overRelease.track_number };
      }
      return releaseTrack;
    });

    // Move elements in the array to the sorted positions
    const sortedData = arrayMove(updatedData, activeIndex, overIndex);

    // Update query cache with new sorted data
    queryClient.setQueryData(
      [QUERY_KEYS.FETCH_ARTIST_RELEASE_TRACKS, activeRelease.release.id],
      sortedData,
    );

    // Determine new current track index
    let newCurrentIndex;
    if (currentTrackIndex === overIndex) {
      newCurrentIndex = activeIndex;
    } else if (currentTrackIndex === activeIndex) {
      newCurrentIndex = overIndex;
    }

    // Dispatch new playlist order to Redux
    dispatch(
      updatePlayListOrder({
        playlist: convertArtistReleaseToPlaylistTrack(sortedData),
        currentTrackIndex: newCurrentIndex,
      }),
    );

    // Update the track order server-side
    updateTrackOrder({
      releaseId: activeRelease.release.id,
      releaseTrackIds: [activeId, overId],
      //  OnError sets state back to before optimistic updates.
      previousState: {
        currentTrackIndex,
        playlist,
      },
    });
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragStart={() => {
        if (isFooterPlaying) {
          pauseFooterPlayer();
        }
      }}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <ArtistReleaseTableWrapper>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <ArtistReleaseTableRow
              style={{
                paddingTop: 8,
                paddingBottom: 8,
              }}
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => {
                if (header.id === "price" && !unitPrice) {
                  return null;
                }
                return (
                  <ArtistReleaseTableHeader
                    key={header.id}
                    style={{
                      flex: header.id === "play_section" ? 1 : 0,
                    }}
                  >
                    <ReleaseTableHeaderView>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </ReleaseTableHeaderView>
                  </ArtistReleaseTableHeader>
                );
              })}
            </ArtistReleaseTableRow>
          ))}
        </thead>
        <tbody>
          <SortableContext
            items={dataIds}
            strategy={verticalListSortingStrategy}
          >
            {table.getRowModel().rows.map((row) => {
              if (row.id === "price" && !unitPrice) {
                return null;
              }
              return (
                <DraggableArtistReleaseTrackTableRow
                  key={row.id}
                  row={row}
                  unitPrice={unitPrice}
                />
              );
            })}
          </SortableContext>
        </tbody>
      </ArtistReleaseTableWrapper>
    </DndContext>
  );
};
