import { useAtom } from "jotai";
import {
  SidePanel,
  SidePanelProps,
} from "../../components/SidePanel/SidePanel";
import { dashboardSidePanelAtom } from "./atoms";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useCallback } from "react";
import { DashboardCardHeader } from "../../components/DashboardCardHeader/DashboardCardHeader";
import SidePanelCloseButton from "../../components/ProjectFilters/SidePanelFilters/SidePanelCloseButton";
import { UniqueBreakpoints } from "../../../utils/breakpoints";

export interface ModularDashboardSidePanelProps
  extends Omit<SidePanelProps, "children" | "isOpen" | "onClose"> {
  onClose?: () => void;
}

const ModularDashboardSidePanel = ({
  onClose = () => {},
  ...props
}: ModularDashboardSidePanelProps) => {
  const isMobile = useMediaQuery(UniqueBreakpoints.DashboardMobile);
  const [sidePanelData, setSidePanelChildren] = useAtom(dashboardSidePanelAtom);
  const handleClose = useCallback(() => {
    try {
      onClose();
      setSidePanelChildren(undefined);
    } catch {
      /* if there is an error, the overriding onClose probably wants to cancel this event. */
    }
  }, [setSidePanelChildren, onClose]);

  return (
    <SidePanel
      onClose={handleClose}
      fillScreen={isMobile}
      isOpen={sidePanelData.isOpen}
      hideCloseButton={true}
      sidePanelStyle={{ display: "flex", flexDirection: "column" }}
      {...props}
    >
      <DashboardCardHeader
        title={sidePanelData.title}
        showEmptyTitle
        style={{ margin: 0, minHeight: "25px" }}
        actionButton={<SidePanelCloseButton onClick={handleClose} />}
      />
      {sidePanelData.children}
    </SidePanel>
  );
};

export default ModularDashboardSidePanel;
