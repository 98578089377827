import "./MobileCheckoutScreen.css";
import { CheckoutModalScreen } from "../CheckoutModalScreen/CheckoutModalScreen";
import { useLocation } from "react-router-dom";
import User from "../../../store/models/user";
import Service from "../../../store/models/service";
import { Project } from "../../../store/models/project";
import { ScheduledProject } from "../../../store/models/scheduledproject";

// TODO: Update MobileCheckoutScreen.tsx for predefined projects
export const MobileCheckoutScreen = () => {
  const location = useLocation();
  const state = location.state as {
    engineerUser: User;
    service: Service;
    projectToHandoff?: Project;
    scheduledProjectMetaData: ScheduledProject;
  };
  const { engineerUser, service, projectToHandoff, scheduledProjectMetaData } =
    state;

  return (
    <CheckoutModalScreen
      engineerUser={engineerUser}
      service={service}
      renderView={true}
      scheduledProjectMetaData={scheduledProjectMetaData}
      projectToHandoff={projectToHandoff}
      closeModal={() => {}}
    />
  );
};
