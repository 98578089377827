import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { ColoredSpan } from "./styles";

export const FeatureReleasePill = () => {
  return (
    <ColoredSpan $backgroundColor={`var(--mint-green-200)`}>
      <Text
        style={{
          color: `var(--mint-green-600)`,
        }}
        bold
        variant={TextStyleVariant.P3}
      >
        Featured release
      </Text>
    </ColoredSpan>
  );
};
