import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { DefaultGenerics, StreamChat } from "stream-chat";
import { useChatContext } from "stream-chat-react";
import { RecordingSession } from "../../../store/models/recordingSession";
import { ScheduledProject } from "../../../store/models/scheduledproject";
import { ChatBubbleButton } from "../../elements/ChatBubbleButton/ChatBubbleButton";
import { useSetAtom } from "jotai";
import { openChatModalAtom } from "./atoms";
import useChannelForProjectOrSession from "./useChannelForProjectOrSession";

export interface ProjectChatButtonProps {
  scheduledProject?: ScheduledProject;
  recordingSession?: Pick<
    RecordingSession,
    "recording_session_request_id" | "users"
  >;
  projectId?: string;
}

export const ProjectChatButton: FC<ProjectChatButtonProps> = ({
  scheduledProject,
  recordingSession,
  projectId = "",
}) => {
  const { project_id } = useParams<{ project_id: string }>();
  const setModalChannelId = useSetAtom(openChatModalAtom);

  const { client } = useChatContext("ProjectChatPanel") as {
    client: StreamChat<DefaultGenerics>;
  };
  const { data: channel } = useChannelForProjectOrSession({
    client: client,
    project_id: project_id || projectId,
    recordingSession,
    scheduledProject,
  });
  const handleClick = useCallback(() => {
    setModalChannelId(channel!.id!);
  }, [setModalChannelId, channel]);
  if (!channel) {
    return null;
  }

  return <ChatBubbleButton channelId={channel.cid} onClick={handleClick} />;
};
