import "./PlatformActivityTable.css";
import { PlatformActivityNotificationRow } from "./PlatformActivityNotificationRow";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { useGetPlatformActivity } from "../../../hooks/usePlatformActivity";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { PlatformActivityTableRowsContainer } from "./PlatformActivityTable.styles";

export interface PlatformActivityTableProps {
  onSidePanel?: boolean;
  teamId: number | undefined;
  pageSize?: number;
}

const PAGE_SIZE = 4;

export const PlatformActivityTable = ({
  onSidePanel = false,
  teamId,
  pageSize = PAGE_SIZE,
}: PlatformActivityTableProps) => {
  const darkMode = useAtomValue(darkModeAtom);
  const { paginatedPlatformActivityData, isLoading, page, setPage } =
    useGetPlatformActivity(teamId, pageSize);
  const { data, num_pages, count } = paginatedPlatformActivityData;

  return (
    <div
      className={"platform-activity-table ".concat(
        onSidePanel ? "platform-activity-side-panel-container" : "",
      )}
      data-theme={darkMode ? "dark" : ""}
    >
      {isLoading && <SoundWaveLoader width={100} height={100} />}
      <PlatformActivityTableRowsContainer>
        {!isLoading && data.length === 0 && (
          <div className="platform-activity-empty-container">
            <p
              style={{
                color: "var(--text-secondary-color)",
                textAlign: "center",
              }}
              className="b1"
            >
              New recent platform activity will appear here!
            </p>
          </div>
        )}
        {!isLoading &&
          data.map((platformActivity) => (
            <PlatformActivityNotificationRow
              key={platformActivity.id}
              platformActivityNotification={platformActivity}
            />
          ))}
      </PlatformActivityTableRowsContainer>
      <Pagination
        style={{ marginTop: "auto", alignSelf: "flex-start" }}
        current={page}
        total={count}
        onChange={(nextPage) => {
          if (num_pages < nextPage) {
            return;
          }
          setPage(nextPage);
        }}
        pageSize={pageSize}
      />
    </div>
  );
};
