import { Dispatch, SetStateAction, type ReactNode } from "react";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import { TEXT_COLOR, TEXT_SIZE, TEXT_WEIGHT, Text } from "../Text/Text";
import { Tab } from "./Tab/Tab";
import {
  DEFAULT_TAB_WIDTH,
  TabsBottomGreyBar,
  TabsContainer,
  TabsSelectedBar,
} from "./Tab/Tab.styles";

interface TabStyle {
  size: TEXT_SIZE;
  weight: TEXT_WEIGHT;
}

const defaultTabStyle: TabStyle = {
  size: TEXT_SIZE.LARGE,
  weight: TEXT_WEIGHT.SEMI_BOLD,
};

export interface TabsProps {
  tabs: ReactNode[];
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
  tabWidth?: number;
  tabStyle?: TabStyle;
}

export const Tabs = ({
  tabs,
  selectedTab,
  setSelectedTab,
  tabWidth = DEFAULT_TAB_WIDTH,
  tabStyle = defaultTabStyle,
}: TabsProps) => {
  const { isMobile } = useMediaQueryBreakpoint();
  return (
    <TabsContainer $isMobile={isMobile}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          selected={selectedTab === index}
          index={index}
          tabWidth={tabWidth}
          onClick={() => setSelectedTab(index)}
        >
          <Text
            color={
              selectedTab === index ? TEXT_COLOR.PRIMARY : TEXT_COLOR.SECONDARY
            }
            weight={tabStyle.weight}
            size={tabStyle.size}
          >
            {tab}
          </Text>
        </Tab>
      ))}
      <TabsBottomGreyBar $tabWidth={tabWidth} $tabsCount={tabs.length}>
        <TabsSelectedBar
          $tabWidth={tabWidth}
          $selectedTab={selectedTab}
          $tabsCount={tabs.length}
        />
      </TabsBottomGreyBar>
    </TabsContainer>
  );
};
