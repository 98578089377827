import { useMemo } from "react";
import {
  RecordingSession,
  SummarizedRecordingSession,
} from "../store/models/recordingSession";
import { useGetSessionUserRoles } from "./recordingSessionHooks/useGetSessionUserRoles";

export const useSessionUserRoles = (
  recordingSession: RecordingSession | SummarizedRecordingSession | null,
) => {
  const id: number | null = recordingSession?.id ?? null;
  const { data } = useGetSessionUserRoles({
    sessionId: id,
  });
  return useMemo(() => {
    if (!data) {
      return {
        isEngineer: false,
        isArtist: false,
        isManager: false,
      };
    }
    return {
      isEngineer: data.is_engineer,
      isArtist: data.is_artist,
      isManager: data.is_manager,
    };
  }, [data]);
};
