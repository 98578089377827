import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { useGetFooterPlayerRef } from "./useGetFooterPlayerRef";
import {
  getCurrentTrackFromPlaylist,
  determineIfPortfolioFeatureData,
} from "../../store/selectors/abPlayerSelectors";
import { setIndex, setLocalPlayer } from "../../store/actions/abPlayerStore";
import useBrowser from "../useBrowser";
import { skipSeconds, SkipTimes } from "./waveformUtils";
import { datadogRum } from "@datadog/browser-rum";

/**
 * @description This hook sets up the lock screen controls for the audio player via MediaSession API.
 *  It allows the user to control the audio player from the lock screen for iPhone or Android.
 *  When previousFile and nextFile are null, the player determines that it is a playlist
 */
export const useSetupLockScreenControls = () => {
  const footerPlayerRef = useGetFooterPlayerRef();
  const dispatch = useAppDispatch();
  const browser = useBrowser();

  const {
    currentTrackIndex,
    refUrl,
    mainUrl,
    refPlayerId,
    mainPlayerId,
    nextFile,
    previousFile,
    selectedTrack,
    playlist,
  } = useAppSelector((state) => state.abPlayerStore);
  const currentTrack = useAppSelector(getCurrentTrackFromPlaylist());
  const analyticsDetail = useMemo(() => {
    if (!currentTrack) {
      return {};
    }
    if (determineIfPortfolioFeatureData(currentTrack)) {
      return {
        portfolioFeatureDataId: currentTrack.id,
      };
    }
    return {
      projectId: currentTrack.id,
    };
  }, [currentTrack]);

  const trackTitle = useMemo(() => {
    if (selectedTrack?.file_name) {
      return selectedTrack?.file_name ?? "";
    }
    return currentTrack?.title ?? "";
  }, [currentTrack?.title, selectedTrack?.file_name]);

  useEffect(() => {
    if (!("mediaSession" in navigator)) {
      return;
    }
    if (!footerPlayerRef.current) {
      return;
    }
    if (!navigator.mediaSession.metadata) {
      try {
        navigator.mediaSession.metadata = new MediaMetadata({
          artist: "EngineEars",
          artwork: [
            {
              // TODO: change to higher quality image
              // https://engineears.atlassian.net/browse/EN-3318
              src: "https://storage.googleapis.com/engineears-static/images/favicon.ico",
            },
          ],
        });
      } catch (e) {
        if (process.env.NODE_ENV === "production") {
          datadogRum.addError(e, {
            source: "useSetupLockScreenControls",
          });
        }
      }
    }
    if (navigator.mediaSession.metadata) {
      navigator.mediaSession.metadata.title = trackTitle;
    }
    if (footerPlayerRef.current) {
      navigator.mediaSession.setActionHandler("play", () => {
        if (!footerPlayerRef.current?.isPlaying()) {
          emitAnalyticsTrackingEvent(
            "click_media_session_play_button",
            analyticsDetail,
          );
          void footerPlayerRef.current?.play();
        }
      });
      navigator.mediaSession.setActionHandler("pause", () => {
        if (footerPlayerRef.current?.isPlaying()) {
          emitAnalyticsTrackingEvent(
            "click_media_session_pause_button",
            analyticsDetail,
          );
          footerPlayerRef.current?.pause();
        }
      });

      // Scrub controls
      navigator.mediaSession.setActionHandler("seekto", ({ seekTime }) => {
        if (seekTime != null) {
          emitAnalyticsTrackingEvent(
            "click_media_session_seek_to",
            analyticsDetail,
          );
          footerPlayerRef.current?.setTime(seekTime);
        }
      });
    }
    if (previousFile && selectedTrack?.id === nextFile?.id) {
      navigator.mediaSession.setActionHandler("previoustrack", () => {
        emitAnalyticsTrackingEvent(
          "click_media_session_previous_track_button",
          analyticsDetail,
        );
        dispatch(
          setLocalPlayer({
            url: refUrl,
            file: previousFile,
            abState: true,
            trackedPlayerId: refPlayerId,
            keepPosition: true,
            playOnLoad: true,
          }),
        );
      });
    } else if (playlist.length > 1) {
      navigator.mediaSession.setActionHandler("previoustrack", () => {
        emitAnalyticsTrackingEvent(
          "click_media_session_previous_track_button",
          analyticsDetail,
        );
        dispatch(setIndex(currentTrackIndex - 1));
      });
    } else {
      navigator.mediaSession.setActionHandler("previoustrack", null);
    }
    // Support for going to the next track in the A/B player.
    if (nextFile && selectedTrack?.id === previousFile?.id) {
      navigator.mediaSession.setActionHandler("nexttrack", () => {
        emitAnalyticsTrackingEvent(
          "click_media_session_next_track_button",
          analyticsDetail,
        );
        dispatch(
          setLocalPlayer({
            url: mainUrl,
            file: nextFile,
            abState: false,
            trackedPlayerId: mainPlayerId,
            keepPosition: true,
            playOnLoad: true,
          }),
        );
      });
    } else if (playlist.length > 1) {
      navigator.mediaSession.setActionHandler("nexttrack", () => {
        emitAnalyticsTrackingEvent(
          "click_media_session_next_track_button",
          analyticsDetail,
        );
        dispatch(setIndex(currentTrackIndex + 1));
      });
    } else {
      navigator.mediaSession.setActionHandler("nexttrack", null);
    }

    // iOS/safari doesn't support both nexttrack/previoustrack and seekbackward/seekforward simultaneously.
    if (browser?.os === "iOS" || browser?.name === "safari") {
      navigator.mediaSession.setActionHandler("seekbackward", null);
      navigator.mediaSession.setActionHandler("seekforward", null);
    } else {
      navigator.mediaSession.setActionHandler("seekbackward", () => {
        emitAnalyticsTrackingEvent(
          "click_media_session_seek_10s_forward_button",
          analyticsDetail,
        );
        skipSeconds(SkipTimes.LOCKSCREEN_BACKWARD_STEP, footerPlayerRef);
      });
      navigator.mediaSession.setActionHandler("seekforward", () => {
        emitAnalyticsTrackingEvent(
          "click_media_session_seek_10s_backward_button",
          analyticsDetail,
        );
        skipSeconds(SkipTimes.LOCKSCREEN_FORWARD_STEP, footerPlayerRef);
      });
    }

    return () => {
      navigator.mediaSession.setActionHandler("play", null);
      navigator.mediaSession.setActionHandler("pause", null);
      navigator.mediaSession.setActionHandler("seekto", null);
      navigator.mediaSession.setActionHandler("previoustrack", null);
      navigator.mediaSession.setActionHandler("nexttrack", null);
      navigator.mediaSession.setActionHandler("seekbackward", null);
      navigator.mediaSession.setActionHandler("seekforward", null);
    };
  }, [
    currentTrack?.id,
    dispatch,
    browser?.os,
    browser?.name,
    analyticsDetail,
    currentTrackIndex,
    previousFile,
    nextFile,
    footerPlayerRef,
    trackTitle,
    selectedTrack?.id,
    playlist.length,
    refUrl,
    refPlayerId,
    mainUrl,
    mainPlayerId,
  ]);
};
