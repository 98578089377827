import { NavTabButtonEnum } from "./BottomNav/NavItem";
import { AuthFlows } from "../../../constants/authSteps";
import { SCREENS } from "../../../routes";

export const getNavButtonColor = (
  linkPaths: string[],
  pathname: string,
  defaultColor = "var(--text-primary-color)",
  navTabEnum?: NavTabButtonEnum,
  flow?: AuthFlows,
) => {
  if (pathname === SCREENS.AUTH_SCREEN) {
    if (navTabEnum === NavTabButtonEnum.SIGN_IN && flow === AuthFlows.LOGIN) {
      return "var(--nav-button-color--selected)";
    }
    if (navTabEnum === NavTabButtonEnum.SIGN_UP && flow !== AuthFlows.LOGIN) {
      return "var(--nav-button-color--selected)";
    }
    return defaultColor;
  }

  return linkPaths.find((linkPath) => pathname.startsWith(linkPath))
    ? "var(--nav-button-color--selected)"
    : defaultColor;
};
