import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import User from "../../store/models/user";
import { SearchProfileCardProps } from "../../stories/components/SearchProfileCard/SearchProfileCard";
import { Studio, StudioRoom } from "../models/studio";
import StudioManager from "../models/studiomanager";

const studioSliceStore = (state: RootState) => state.studiosSlice;

export const selectStudioRoom = (
  username: string | undefined,
  canManageStudio: boolean,
) =>
  createDraftSafeSelector(studioSliceStore, (store) => {
    if (!username || !store[username]) {
      return {
        studioRooms: [],
        equipmentHighlights: "",
      };
    }
    const studio: Studio | undefined = store[username];
    let studioRooms: StudioRoom[] | null | undefined = [];
    let equipmentHighlights: string = "";
    if (studio && canManageStudio) {
      studioRooms = store[username]?.studio_rooms;
    } else {
      studioRooms = store[username]?.studio_rooms?.filter((room) =>
        Boolean(room.recording_service),
      );
    }
    if (!studioRooms) {
      return {
        studioRooms: [],
        equipmentHighlights: "",
      };
    }
    studioRooms.forEach((room) => {
      if (room.equipment_highlights) {
        const equipment =
          "-" + room.equipment_highlights.replaceAll("\n", "\n-");
        equipmentHighlights += room.room_name + "\n" + equipment + "\n\n";
      }
    });

    return {
      studioRooms: studioRooms,
      equipmentHighlights: equipmentHighlights,
    };
  });
