import { Box, styled } from "@mui/material";
import { ReactNode } from "react";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";

export const ServiceFormLabelContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
  flex: 1,
});

export const ServiceFormLabel = styled("label")({
  fontSize: "16px",
});

export const ServiceFormLabelSubtext = ({
  children,
}: {
  children: ReactNode;
}) => {
  if (typeof children === "string") {
    return (
      <Text variant={TextStyleVariant.P2} color={TextColor.SECONDARY}>
        {children}
      </Text>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        color: theme.palette.text.secondary,
      })}
    >
      {children}
    </Box>
  );
};

export const ServiceFormInputContainer = styled(Box)(({ theme }) => ({
  width: "120px",
  display: "flex",
  alignItems: "flex-end",
  flexDirection: "column",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },

  "input, & > *": {
    width: "100% !important",
  },

  ".switch": {
    width: "40px",
    height: "20px",
    minWidth: "unset",
  },

  ".slider:before": {
    width: "16px",
    height: "16px",
    left: "2px",
    bottom: "2px",
  },

  ".switch > input:checked + .slider:before": {
    transform: "translateX(20px)",
  },

  ".slider.disabled": {
    cursor: "not-allowed",
    backgroundColor: theme.palette.standardColor.Gray[200],
  },

  ".toggle-switch-form": {
    justifyContent: "flex-end",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
}));

export const ServiceFormRow = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  columnGap: "8px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    rowGap: "8px",

    "& > *": {
      width: "100%",
    },
  },
}));

export const ServiceFormColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "100%",
  rowGap: "16px",

  "& > div, & > input": {
    width: "100%",
  },

  ".calendar-setup-button": {
    marginLeft: "unset",
    marginRight: "unset",
    width: "unset",
    padding: "0 16px",
  },
});

export const ServiceFormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
  width: "100%",
});

export const BlockDiscountContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "24px",
  width: "100%",
});
