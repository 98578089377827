import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 16px;
  box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
  margin-top: var(--project-screen-element-margin);
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
