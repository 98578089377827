import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { EngineEarsError } from "../../../store/actions/errorStore";
import { Release } from "../../../store/models/release";
import { fetchReleaseByReleaseId } from "../releaseAPI";
import { StatusCodes } from "http-status-codes";

export const useFetchReleaseQuery = (releaseId = 0) => {
  return useQuery({
    enabled: Boolean(releaseId),
    queryKey: [QUERY_KEYS.FETCH_ARTIST_RELEASE, releaseId],
    queryFn: async () => {
      const response = await fetchReleaseByReleaseId(releaseId);
      if (response.statusCode === StatusCodes.NO_CONTENT) {
        return null;
      }
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return response.resultJson as Release;
    },
  });
};
