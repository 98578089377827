import React, { useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { Textfield } from "../Textfield1/Textfield1";
import "./ValidatedTextfield.css";

export interface TextValidation {
  validated: boolean;
  errorString?: string;
}

export interface ValidatedTextfieldProps {
  id?: string;
  label?: string;
  value?: string | number;
  type?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => TextValidation; // Return whether validation was succesful.
}

export const ValidatedTextfield = ({
  label = "",
  value,
  type = "text",
  onChange,
  ...props
}: ValidatedTextfieldProps) => {
  const [validated, setValidated] = useState<boolean>(false);
  const [errorString, setErrorString] = useState<string>();

  return (
    <div className="validated-text-field">
      <p className="mb-1 caption-semi-bold">{label}</p>
      <Textfield
        label={label}
        value={value}
        onChange={(e) => {
          if (!onChange) return;
          const validationResult = onChange(e);
          setValidated(validationResult.validated);
          setErrorString(validationResult.errorString);
        }}
        {...props}
      />
      {errorString && <p className="mt-1 caption-semi-bold">{errorString}</p>}
    </div>
  );
};
