import { useOverWriteDisabledTiles } from "../../../../../hooks/bookingHooks/useOverWriteDisabledTile";
import { useOverWriteTileClass } from "../../../../../hooks/bookingHooks/useOverWriteTileClass";
import Engineer from "../../../../../store/models/engineer";
import { BookingCalendar } from "../../../BookingCalendar/BookingCalendar";
import { RecordingBookingCalendarWidgetVariant } from "../../../RecordingBookingCalendarWidget/RecordingBookingCalendarWidget";
import "./../../../RecordingBookingCalendarWidget/RecordingBookingCalendarWidget.css";

export interface RescheduleSessionCalendarProps {
  durationMinutes: number;
  engineerDateMap: Map<string, string>;
  isUserSameAsEngineer: boolean;
  isUserSameAsStudioManager: boolean;
  isStudioBooking: boolean;
  selectedDateTime: Date | null;
  selectedTime: Date | null;
  studioDateMap: Map<string, string>;
  trackingEngineer: Engineer | undefined;
  getEngineerAvailability: (date: Date) => Date[];
  getStudioAvailability: (date: Date) => Date[];
  getOverlappingAvailability: (date: Date) => Date[];
  onClickDate: (date: Date) => void;
}

export const RescheduleSessionCalendar = ({
  durationMinutes,
  engineerDateMap,
  isUserSameAsEngineer,
  isUserSameAsStudioManager,
  isStudioBooking,
  selectedDateTime,
  selectedTime,
  studioDateMap,
  trackingEngineer,
  getEngineerAvailability,
  getStudioAvailability,
  getOverlappingAvailability,
  onClickDate,
}: RescheduleSessionCalendarProps) => {
  const handleOverWriteDisabledTiles = useOverWriteDisabledTiles({
    variant: isStudioBooking
      ? RecordingBookingCalendarWidgetVariant.STUDIO
      : RecordingBookingCalendarWidgetVariant.ENGINEER,
    disablePastDates: true,
    selectedTime: selectedTime ?? undefined,
    isEngineerSelected: true,
    isCurrentUserSameAsSelectedEngineer: isUserSameAsEngineer,
    isCurrentUserSameAsStudioManager: isUserSameAsStudioManager,
    getEngineerAvailabilityOnThisDate: getEngineerAvailability,
    getStudioAvailabilityOnThisDate: getStudioAvailability,
    getOverLappingDateAvailabilities: getOverlappingAvailability,
  });

  const handleOverWriteTileClass = useOverWriteTileClass({
    disablePastDates: true,
    isCurrentUserSameAsSelectedEngineer: isUserSameAsEngineer,
    isCurrentUserSameAsStudioManager: isUserSameAsStudioManager,
    isStudioBooking,
    selectedBookingDate: selectedDateTime,
    trackingEngineer,
    getEngineerAvailability,
    getStudioAvailability,
    getOverlappingAvailability,
  });

  return (
    <BookingCalendar
      availabilities={isStudioBooking ? studioDateMap : engineerDateMap}
      durationMinutes={durationMinutes}
      onClickDate={onClickDate}
      overWriteGetDisabledTiles={handleOverWriteDisabledTiles}
      overWriteGetTileClass={handleOverWriteTileClass}
      selectedDays={selectedDateTime ? [selectedDateTime] : []}
    />
  );
};
