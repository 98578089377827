import { faSortDown, faSortUp } from "@fortawesome/pro-regular-svg-icons";
import {
  faSortDown as faSortDownSolid,
  faSortUp as faSortUpSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactElement, useEffect, useState } from "react";
import { ArrowDirection } from "../../../store/utils/localProjectSort";
import "./OrderArrows.css";

export interface OrderArrowsProps {
  colHeader: string;
  orderBy: string;
  onClick: (header: string) => void;
}

export const OrderArrows: FC<OrderArrowsProps> = ({
  colHeader,
  onClick,
  orderBy,
}): ReactElement => {
  const [showArrow, setShowArrow] = useState<ArrowDirection>(
    ArrowDirection.None,
  );

  useEffect(() => {
    if (!orderBy.includes(colHeader)) {
      setShowArrow(ArrowDirection.None);
      return;
    }
  }, [orderBy]);

  const changeDirection = () => {
    switch (showArrow) {
      case ArrowDirection.None:
        onClick(colHeader);
        setShowArrow(ArrowDirection.Up);
        break;
      case ArrowDirection.Up:
        const newHeader = "-" + colHeader;
        onClick(newHeader);
        setShowArrow(ArrowDirection.Down);
        break;
      case ArrowDirection.Down:
        onClick(colHeader);
        setShowArrow(ArrowDirection.Up);
        break;
    }
  };
  const isUp = showArrow === ArrowDirection.Up;
  const isDown = showArrow === ArrowDirection.Down;

  return (
    <div className="order-arrows-container" onClick={changeDirection}>
      <FontAwesomeIcon
        className="order-arrow"
        icon={isUp ? faSortUpSolid : faSortUp}
        size="lg"
      />
      <FontAwesomeIcon
        className="order-arrow"
        icon={isDown ? faSortDownSolid : faSortDown}
        size="lg"
      />
    </div>
  );
};
