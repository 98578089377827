import { MapBounds } from "../store/actions/mapSearch";
import { RecordingLocation } from "../store/models/recording";

export const calculateBoundingBoxOverlap = (
  current: MapBounds,
  updated: MapBounds,
): number => {
  const minLat = Math.max(current.minLatitude, updated.minLatitude);
  const maxLat = Math.min(current.maxLatitude, updated.maxLatitude);
  const minLng = Math.max(current.minLongitude, updated.minLongitude);
  const maxLng = Math.min(current.maxLongitude, updated.maxLongitude);

  if (maxLat < minLat || maxLng < minLng) {
    return 0;
  }

  const intersectionArea = (maxLat - minLat) * (maxLng - minLng);
  const updatedArea =
    (updated.maxLatitude - updated.minLatitude) *
    (updated.maxLongitude - updated.minLongitude);

  return intersectionArea / updatedArea;
};

export const isLocationInView = (
  location: RecordingLocation | undefined | null,
  mapBounds: MapBounds,
): boolean => {
  if (!location) {
    return false;
  }
  if (!location.rough_latitude || !location.rough_longitude) {
    return false;
  }
  if (
    mapBounds.minLatitude <= location.rough_latitude &&
    location.rough_latitude <= mapBounds.maxLatitude &&
    mapBounds.minLongitude <= location.rough_longitude &&
    location.rough_longitude <= mapBounds.maxLongitude
  ) {
    return true;
  }
  return false;
};
