import { ToggleSwitch, TOGGLE_SWITCH_SIZE } from "../ToggleSwitch/ToggleSwitch";
import {
  StyledABP,
  StyledABToggleSwitchContainer,
} from "./ToggleABSwitch.styles";

export interface ToggleABSwitchProps {
  referenceSelected?: boolean;
  showToggle: boolean;
  onClick?: () => void;
  isReady: boolean;
}

export const ToggleABSwitch = ({
  referenceSelected,
  showToggle,
  onClick,
  isReady,
}: ToggleABSwitchProps) => {
  return (
    <StyledABToggleSwitchContainer
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!onClick || !isReady || !showToggle) return;
        e.preventDefault();
        onClick();
      }}
    >
      {showToggle && (
        <>
          <StyledABP selected={referenceSelected}>A</StyledABP>
          <ToggleSwitch
            size={TOGGLE_SWITCH_SIZE.MEDIUM}
            checked={!referenceSelected}
            updateCheckedLocally={false}
            disableBackgroundColor
          />
          <StyledABP selected={!referenceSelected}>B</StyledABP>
        </>
      )}
    </StyledABToggleSwitchContainer>
  );
};
