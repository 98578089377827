import { FC, forwardRef, useEffect, useMemo, useState } from "react";
import { PaginatedEntityPhotoDataPayload } from "../../../store/actions/entityPhotoStore";
import {
  faClose,
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DetailImageCarousel.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  WithStore,
} from "pure-react-carousel";
import { EntityImage } from "../../screens/EntityPhotosScreen/EntityPhotosScreen";
import { Pill } from "../../elements/Pill/Pill";
import { ShareButton } from "../../elements/ShareButton/ShareButton";
import { EntityPhotosScreenHeader } from "../../screens/EntityPhotosScreen/EntityPhotosScreen.styles";
import { useAtomValue } from "jotai";
import { topNavSpacerHeightAtom } from "../../../atoms/navAtoms";

export interface DetailImageCarouselProps {
  photoData?: PaginatedEntityPhotoDataPayload;
  index: number;
  onClose: () => void;
  updateIndex: (index: number) => void;
  entityId?: number;
  isMobile: boolean;
  slideUp?: boolean;
  shareUrl?: string;
}

export const DetailImageCarousel: FC<DetailImageCarouselProps> = ({
  photoData,
  index,
  onClose,
  updateIndex,
  entityId,
  isMobile,
  slideUp = false,
  shareUrl,
}) => {
  const navHeight = useAtomValue(topNavSpacerHeightAtom);
  const [hidePhotoScreen, setHidePhotoScreen] = useState(false);
  const photos = useMemo(() => {
    return photoData?.photos || [];
  }, [photoData]);
  if (!photoData || entityId === undefined) {
    return null;
  }

  const handleOnClose = () => {
    const animationDuration = 500;
    setHidePhotoScreen(true);
    setTimeout(() => {
      onClose();
      setHidePhotoScreen(false);
    }, animationDuration);
  };

  return (
    <div
      style={{ height: window.innerHeight }}
      className={"image-carousel-overlay "
        .concat(slideUp ? " sliding-up " : "")
        .concat(hidePhotoScreen ? " sliding-down " : "")}
    >
      <EntityPhotosScreenHeader
        $navHeight={navHeight}
        className="entity-photos-screen__header"
      >
        {shareUrl && (
          <div className="entity-photos-screen__header__item">
            <ShareButton url={shareUrl} />
          </div>
        )}
        <div className="entity-photos-screen__header__item">
          <p className="label1">{`${index + 1}/${photos.length}`}</p>
        </div>
        <div
          onClick={handleOnClose}
          className="entity-photos-screen__header__item"
        >
          <FontAwesomeIcon
            color="white"
            className={"photo-screen-close-icon"}
            icon={faClose}
            size={isMobile ? "1x" : "2x"}
          />
        </div>
      </EntityPhotosScreenHeader>
      <div className="image-carousel-overlay-body">
        <div className="image-carousel-overlay-carousel-navigator">
          <FontAwesomeIcon
            color="white"
            icon={faCircleChevronLeft}
            size={isMobile ? "1x" : "2x"}
            onClick={() => {
              updateIndex(index - 1);
            }}
            className="image-carousel-arrow"
          />
        </div>
        <div className="detail-image-carousel-container">
          <CarouselProvider
            dragEnabled={isMobile}
            naturalSlideWidth={1025}
            naturalSlideHeight={isMobile ? 800 : 500}
            totalSlides={photos.length}
            touchEnabled={isMobile}
            currentSlide={index}
          >
            <WrappedListener
              onSlideIndexChange={(slideIndexChange) => {
                if (isMobile) {
                  updateIndex(slideIndexChange);
                }
              }}
            />
            <Slider>
              {photos.map((photo, currIndex) => {
                return (
                  <Slide key={photo.id} index={currIndex}>
                    <div className="detail-image-carousel-image-wrapper">
                      <div className="detail-entity-photo">
                        <EntityImage entityPhoto={photo} fullScreen={true} />
                        {photo.room_name && (
                          <Pill
                            label={photo.room_name}
                            className="entity-photo-room-name"
                          />
                        )}
                      </div>
                    </div>
                  </Slide>
                );
              })}
            </Slider>
          </CarouselProvider>
        </div>
        <div className="image-carousel-overlay-carousel-navigator">
          <FontAwesomeIcon
            color="white"
            icon={faCircleChevronRight}
            size={isMobile ? "1x" : "2x"}
            onClick={() => {
              updateIndex(index + 1);
            }}
            className="image-carousel-arrow"
          />
        </div>
      </div>
    </div>
  );
};

const Listener = forwardRef<HTMLDivElement, ListenerProps>((props, _) => {
  const { currentSlide, onSlideIndexChange } = props;
  useEffect(() => {
    onSlideIndexChange(currentSlide || 0);
  }, [currentSlide, onSlideIndexChange]);
  return null;
});

interface ListenerProps {
  currentSlide?: number;
  onSlideIndexChange: (index: number) => void;
}
const WrappedListener = WithStore<ListenerProps>(Listener, (state) => ({
  currentSlide: state.currentSlide,
}));
