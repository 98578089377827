import React, { useRef, useState } from "react";
import "./VideoList.css";
import { faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";

export interface VideoListItemProps {
  title: string;
  description?: string;
  webMFile: string;
  mp4File: string;
  onClick?: () => void;
}

export const VideoListItem = ({
  title,
  description,
  onClick,
}: VideoListItemProps) => {
  return (
    <div className={"video-list-item"} onClick={onClick}>
      <header className={"b1-semi-bold"}>{title}</header>
      {description && <p className={"b1"}>{description}</p>}
    </div>
  );
};

const VIDEO_NOT_SELECTED = -1;

export interface VideoListProps {
  videos: VideoListItemProps[];
}
export const VideoList = ({ videos }: VideoListProps) => {
  const [selectedVideo, setSelectedVideo] = useState(VIDEO_NOT_SELECTED);
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className={"video-list-container"}>
      <div className={"video-list"}>
        {videos.map((video, index) => {
          return (
            <VideoListItem
              onClick={() => {
                setSelectedVideo(index);
                setTimeout(() => {
                  setShowVideo(true);
                }, 200);
              }}
              key={index}
              {...video}
            />
          );
        })}
      </div>
      {selectedVideo > VIDEO_NOT_SELECTED && (
        <VideoDetailViewer
          showVideo={showVideo}
          onClose={() => {
            setShowVideo(false);
            setTimeout(() => {
              setSelectedVideo(VIDEO_NOT_SELECTED);
            }, 500);
          }}
          {...videos[selectedVideo]}
        />
      )}
    </div>
  );
};

export interface VideoDetailViewerProps extends VideoListItemProps {
  showVideo?: boolean;
  onClose?: () => void;
}
export const VideoDetailViewer = ({
  webMFile,
  mp4File,
  showVideo = false,
  onClose = () => {},
}: VideoDetailViewerProps) => {
  const nodeRef = useRef<HTMLVideoElement | null>(null);
  return (
    <div className={"detail-video-view ".concat(showVideo ? "show-video" : "")}>
      <VideoPlayer
        ref={nodeRef}
        width={"100%"}
        webMFile={webMFile}
        mp4File={mp4File}
      />
      <button className={"close-video-button-detail-video"}>
        <FontAwesomeIcon
          onClick={() => {
            if (nodeRef.current) {
              nodeRef.current?.pause();
            }
            onClose();
          }}
          icon={faVideoSlash}
          color={"var(--white)"}
        />
      </button>
    </div>
  );
};
