import { Box, BoxProps, styled } from "@mui/material";
import {
  SoundWaveLoader,
  SoundWaveLoaderProps,
} from "../../elements/SoundWaveLoader/SoundWaveLoader";
import classNames from "classnames";

interface LoadingScreenProps extends BoxProps {
  soundwaveLoaderProps?: Partial<SoundWaveLoaderProps>;
}

const LoadingScreenWrapper = styled(Box)({
  display: "flex",
  margin: "auto",
  flexDirection: "column",
});

const LoadingScreen = ({
  className = "",
  children,
  soundwaveLoaderProps,
  ...props
}: LoadingScreenProps) => {
  return (
    <LoadingScreenWrapper
      className={classNames(["container-fluid", className])}
      {...props}
    >
      {children}
      <SoundWaveLoader width={100} height={100} {...soundwaveLoaderProps} />
    </LoadingScreenWrapper>
  );
};

export default LoadingScreen;
