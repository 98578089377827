import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UnauthenticatedUserState {
  name?: string;
}

const initialState: UnauthenticatedUserState = {
  name: undefined,
};
const unauthenticatedUserStateSlice = createSlice({
  name: "unauthenticatedUserStateSlice",
  initialState,
  reducers: {
    updateName: (state, action: PayloadAction<string | undefined>) => {
      state.name = action.payload;
    },
  },
});

export const { updateName } = unauthenticatedUserStateSlice.actions;
export default unauthenticatedUserStateSlice.reducer;
