import { makeBackendPostCallWithJsonResponse } from "../../../store/utils/fetch";
import { CREATE_STUDIO_DIRECT_MESSAGE_REQUEST } from "../../../store/utils/routes";
import { StreamSuccessResponse } from "./types";

export interface StudioDirectMessageParams {
  studio_id: number;
  message: string;
}

export const postStudioDirectMessageRequest = async (
  args: StudioDirectMessageParams,
) => {
  const response =
    await makeBackendPostCallWithJsonResponse<StreamSuccessResponse>(
      CREATE_STUDIO_DIRECT_MESSAGE_REQUEST,
      args,
    );

  if (response.success) {
    return response.resultJson;
  }
  return Promise.reject(response.resultJson);
};

export default postStudioDirectMessageRequest;
