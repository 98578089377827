import styled from "styled-components";

export const UploaderContainer = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: ${({ theme }) => `1px dashed ${theme.separatorColor}`};
  border-radius: ${({ theme }) => theme.border.radius.md};
  background: ${({ theme }) => theme.colorPalette.Gray50};
  cursor: ${({ $disabled }) => ($disabled ? "no-drop" : "pointer")};
  @media screen and (max-width: 768px) {
    padding: 24px;
  }
`;
