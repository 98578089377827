import { isSameDay } from "date-fns";
import { FC, ReactElement, useState } from "react";
import { toast } from "react-toastify";
import useModal from "../../../hooks/useModal";
import { setIsoDateAtIndex } from "../../../store/actions/shoppingCart";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RecordingService } from "../../../store/models/recording";
import { StudioRoom } from "../../../store/models/studio";
import { selectShoppingCartRecordingBookingDates } from "../../../store/selectors/shoppingCart";
import { getCurrentDate } from "../../../store/utils/dateTimeUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Text } from "../../core-ui/components/Text/Text";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import {
  RecordingBookingCalendarWidget,
  RecordingBookingCalendarWidgetVariant,
} from "../RecordingBookingCalendarWidget/RecordingBookingCalendarWidget";
import "./SessionDateModal.css";

interface SessionDateModalProps {
  studioId?: number;
  studioRoom: StudioRoom | undefined;
  recordingService: RecordingService | null;
  showAddSessionDateModal: boolean;
  onClose: () => void;
  sessionIndex?: number;
  selectedDate?: Date;
  selectedTime?: Date;
  duration?: number;
  dateOptions?: OptionType[];
  engineerAvailabilityOnly?: boolean;
}

const INVALID_FIELD = -1;

export const SessionDateModal: FC<SessionDateModalProps> = ({
  recordingService,
  studioRoom,
  studioId,
  showAddSessionDateModal,
  onClose,
  sessionIndex = INVALID_FIELD,
  selectedDate,
  selectedTime,
  engineerAvailabilityOnly = false,
}): ReactElement => {
  const recordingBookingDates = useAppSelector(
    selectShoppingCartRecordingBookingDates,
  );
  const dispatch = useAppDispatch();
  const [pendingISODate, setPendingISODate] = useState<string | null>(null);
  const {
    openModal: openConfirmModal,
    isOpen: isConfirmModalOpen,
    closeModal: closeConfirmModal,
  } = useModal();

  const isPastDate =
    pendingISODate &&
    new Date(pendingISODate).getTime() < getCurrentDate().getTime();

  const handleClickDate = (date: Date) => {
    if (sessionIndex === INVALID_FIELD) {
      return;
    }
    if (selectedDate && isSameDay(date, selectedDate)) {
      return;
    }
    if (recordingBookingDates.some((d) => isSameDay(d, date))) {
      return;
    }

    const isPastDate = date.getTime() < getCurrentDate().getTime();
    const isoDate = date.toISOString();

    if (isPastDate) {
      setPendingISODate(isoDate);
      openConfirmModal();
      return;
    }

    dispatch(setIsoDateAtIndex({ isoDate, index: sessionIndex }));
    onClose();
  };

  const handleConfirmPastDate = () => {
    if (!pendingISODate || sessionIndex === INVALID_FIELD) {
      toast.error("Whoops! Something went wrong. Please try again.");
      onClose();
      return;
    }

    dispatch(
      setIsoDateAtIndex({ isoDate: pendingISODate, index: sessionIndex }),
    );
    closeConfirmModal();
    onClose();
  };

  const handleCancel = () => {
    closeConfirmModal();
    onClose();
  };

  if (isConfirmModalOpen) {
    return (
      <BaseModal
        open={isConfirmModalOpen}
        header="Are you sure?"
        setOpen={handleCancel}
        showModalFooter
        onCancel={closeConfirmModal}
        onConfirm={handleConfirmPastDate}
      >
        <Text>
          {isPastDate
            ? "This date is in the past. Are you sure you want to select this?"
            : "This date is outside your normal working hours. Are you sure you want to select this?"}
        </Text>
      </BaseModal>
    );
  }

  return (
    <BaseModal
      open={showAddSessionDateModal}
      header="Select a date for your recording session"
      setOpen={onClose}
    >
      <div className="session-date-modal">
        <RecordingBookingCalendarWidget
          recordingService={recordingService}
          studioRoom={studioRoom}
          studioId={studioId}
          enableAddDate={true}
          onAddDate={handleClickDate}
          onInvalidDate={(date) => {
            setPendingISODate(date.toISOString());
            openConfirmModal();
          }}
          selectedTime={selectedTime}
          variant={
            engineerAvailabilityOnly
              ? RecordingBookingCalendarWidgetVariant.ENGINEER
              : RecordingBookingCalendarWidgetVariant.STUDIO
          }
        />
      </div>
    </BaseModal>
  );
};
