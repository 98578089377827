import { useOverlappingBookingAvailability } from "../../../../../hooks/bookingHooks/useAllPossibleBookingAvailability";
import { useAvailableDateTimeOptions } from "../../../../../hooks/bookingHooks/useAvailableDateTimeOptions";
import { useFilterTimeOptions } from "../../../../../hooks/bookingHooks/useFilterTimeOptions";
import { WorkingHoursOptionType } from "../../../../../hooks/useGeneralWorkingHoursStringForDay";
import {
  RecordingSession,
  SummarizedRecordingSession,
} from "../../../../../store/models/recordingSession";
import { Text, TEXT_COLOR } from "../../../../core-ui/components/Text/Text";
import {
  DropdownSelector,
  OptionType,
} from "../../../../elements/DropDownSelector/DropdownSelector";

export interface RescheduleSessionTimeInputProps {
  engineerDateMap: Map<string, string>;
  isEngineer: boolean;
  isStudioManager: boolean;
  recordingSession: RecordingSession | SummarizedRecordingSession;
  selectedDateTime: Date | null;
  selectedTime: WorkingHoursOptionType | OptionType | null;
  studioDateMap: Map<string, string>;
  handleTimeSelected: (time: WorkingHoursOptionType | OptionType) => void;
}

export const RescheduleSessionTimeInput = ({
  engineerDateMap,
  isEngineer,
  isStudioManager,
  recordingSession,
  selectedDateTime,
  selectedTime,
  studioDateMap,
  handleTimeSelected,
}: RescheduleSessionTimeInputProps) => {
  const { session_duration_minutes, studio_room, engineer } = recordingSession;
  const engineerUserId = engineer?.user_id;
  const availableTimeOptions = useAvailableDateTimeOptions(
    selectedDateTime ?? undefined,
    session_duration_minutes,
    engineer,
    studioDateMap,
    engineerDateMap,
    Boolean(engineerUserId && !studio_room),
  );

  const allTimeOptions = useOverlappingBookingAvailability(
    session_duration_minutes,
    availableTimeOptions,
    selectedDateTime ?? undefined,
  );

  const timeOptions = useFilterTimeOptions(
    allTimeOptions,
    availableTimeOptions.length > 0,
    isEngineer,
    isStudioManager,
    !studio_room,
  );

  return (
    <DropdownSelector
      options={timeOptions}
      onChange={(option) => {
        handleTimeSelected(option);
      }}
      value={selectedTime ? selectedTime : null}
      customComponents={(option) => {
        // TODO: figure out why there's a type error when Option is defined as WorkingHoursOptionType
        const textColor = (option as unknown as WorkingHoursOptionType)
          .isWorkingHour
          ? TEXT_COLOR.PRIMARY
          : TEXT_COLOR.ERROR;
        return <Text color={textColor}>{option.label}</Text>;
      }}
    />
  );
};
