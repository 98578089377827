import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../index";
import {
  PaginatedRecordingSessions,
  RecordingSessionStages,
} from "../models/recordingSession";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { PAGINATED_RECORDING_SESSIONS } from "../utils/routes";
import {
  BookingsScreenSearchState,
  initialState as initialSearchState,
  setCollaboratorFilter,
  setSearchQuery,
  setStudioFilter,
  setStudioRoomFilter,
} from "./bookingsSearch";
import { receiveErrors } from "./errorStore";

export interface PaginatedRecordingSessionState {
  [page: number]: PaginatedRecordingSessions[];
}

export type SortByType =
  | "first_choice_datetime"
  | "-first_choice_datetime"
  | "studio_room__room_name"
  | "-studio_room__room_name"
  | "engineer__user__username"
  | "-engineer__user__username"
  | "project__total_price"
  | "-project__total_price"
  | "priority"
  | "-priority";

interface PaginatedRecordingSessionsState {
  data: PaginatedRecordingSessionState;
  page: number;
  stages: RecordingSessionStages[];
  sortBy: SortByType;
  numberOfPages: number;
  count: number;
  loading: boolean;
  search: BookingsScreenSearchState;
  userSessionsOnly: boolean;
}

const initialState: PaginatedRecordingSessionsState = {
  data: {},
  page: 1,
  stages: [],
  sortBy: "-priority",
  numberOfPages: 0,
  count: 0,
  loading: false,
  search: initialSearchState,
  userSessionsOnly: false,
};

export const fetchPaginatedRecordingSessions = createAsyncThunk(
  PAGINATED_RECORDING_SESSIONS,
  async (_, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const userSessionsOnly = Boolean(appState.selectedProfileSlice.user);
    const recordingSessionsState = appState.paginatedRecordingSessions;
    const { page, sortBy, stages, search } = recordingSessionsState;
    let params = `?page=${page}`;
    if (sortBy) {
      params += `&sort_by=${sortBy}`;
    }
    if (stages.length > 0) {
      const filteredStages = stages.filter(
        (stage) => stage !== RecordingSessionStages.ALL,
      );
      if (filteredStages.length > 0) {
        params += `&stages=${filteredStages.join(",")}`;
      }
    }
    if (search.searchQuery.length) {
      params += `&search_query=${search.searchQuery}`;
    }
    if (search.collaboratorFilter.length > 0) {
      params += `&collaborator_filter=${search.collaboratorFilter.join(",")}`;
    }
    if (search.studioFilter) {
      params += `&studio_filter=${search.studioFilter.join(",")}`;
    }
    if (search.studioRoomFilter) {
      params += `&studio_room_filter=${search.studioRoomFilter.join(",")}`;
    }
    if (userSessionsOnly) {
      params += "&user_sessions_only=true";
    }
    const response = await makeBackendGetCallWithJsonResponse<{
      data: PaginatedRecordingSessions[];
      num_of_pages: number;
      count: number;
      page: number;
    }>(PAGINATED_RECORDING_SESSIONS, params);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

const paginatedRecordingSessionsSlice = createSlice({
  name: "paginatedRecordingSessions",
  initialState,
  reducers: {
    setUserSessionsOnly: (state, action: PayloadAction<boolean>) => {
      state.userSessionsOnly = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSortBy: (state, action: PayloadAction<SortByType>) => {
      state.sortBy = action.payload;
    },
    setSessionStages: (
      state,
      action: PayloadAction<RecordingSessionStages[]>,
    ) => {
      state.stages = action.payload;
    },
    resetSortBy: (state) => {
      state.sortBy = "-priority";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSearchQuery, (state, action) => {
      state.search.searchQuery = action.payload;
    });
    builder.addCase(setCollaboratorFilter, (state, action) => {
      state.search.collaboratorFilter = action.payload;
    });
    builder.addCase(setStudioFilter, (state, action) => {
      state.search.studioFilter = action.payload;
    });
    builder.addCase(setStudioRoomFilter, (state, action) => {
      state.search.studioRoomFilter = action.payload;
    });
    builder.addCase(
      fetchPaginatedRecordingSessions.pending,
      (state, action) => {
        state.loading = true;
      },
    );
    builder
      .addCase(fetchPaginatedRecordingSessions.fulfilled, (state, action) => {
        state.data[state.page] = action.payload.data;
        state.numberOfPages = action.payload.num_of_pages;
        state.count = action.payload.count;
        state.page = action.payload.page;
        state.loading = false;
      })
      .addMatcher(
        isAnyOf(fetchPaginatedRecordingSessions.rejected),
        (state) => {
          state.data[state.page] = [];
          state.loading = false;
        },
      );
  },
});

export const {
  setPage,
  setSortBy,
  setSessionStages,
  resetSortBy,
  setUserSessionsOnly,
} = paginatedRecordingSessionsSlice.actions;

export default paginatedRecordingSessionsSlice.reducer;
