import {
  SubscriptionSuccessScreenBody,
  SubscriptionSuccessScreenContainer,
  SubscriptionSuccessScreenFooter,
  SubscriptionSuccessScreenHeader,
} from "./SubscriptionSuccessScreen.styles";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Box } from "@mui/material";
import MarketingImage from "../../assets/how_it_works.png";
import Link from "../../elements/Link/Link";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getCommunityRoute,
  getProfileScreenRoute,
} from "../../../store/utils/routeGetters";
import { useEffect } from "react";
import {
  getSubscriptionStatus,
  handleStripeCheckoutSuccess,
} from "../../../store/actions/subscriptions";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { getSubscriptionAnalyticsName } from "../../../store/utils/subscriptionUtils";
import { isCurrentUserEngineerOrStudioManagerSelector } from "../../../store/selectors/userInfoSelectors";
import { useQuery } from "../../../hooks/useQuery";

export const SubscriptionSuccessScreen = () => {
  const { user: loggedInUser } = useAppSelector((state) => state.accountInfo);
  const isEngineerOrStudioManager = useAppSelector(
    isCurrentUserEngineerOrStudioManagerSelector,
  );
  const query = useQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loggedInUser) return;
    if (!isEngineerOrStudioManager) return;
    const sessionId = query.get("session_id");
    if (sessionId) {
      void dispatch(handleStripeCheckoutSuccess({ session_id: sessionId }))
        .unwrap()
        .then((result) => {
          emitAnalyticsTrackingEvent(
            `stripe_subscription_success_${getSubscriptionAnalyticsName(
              result.subscription_plan_choice,
            )}`,
            {
              plan: `${result.subscription_plan_choice}`,
              status: `${result.subscription_plan_choice}`,
            },
            loggedInUser?.id,
          );
        });
    }
    void dispatch(getSubscriptionStatus({ refetch_subs_status: true })).finally(
      () => {},
    );
  }, [dispatch, query, loggedInUser, isEngineerOrStudioManager]);

  if (!loggedInUser) {
    return null;
  }

  return (
    <SubscriptionSuccessScreenContainer>
      <SubscriptionSuccessScreenHeader>
        <Text variant={TextStyleVariant.H4}>
          Welcome to EngineEars Platinum!
        </Text>
        <Text variant={TextStyleVariant.P1}>
          Get ready to streamline, monetize, and level up your business.
        </Text>
      </SubscriptionSuccessScreenHeader>
      <SubscriptionSuccessScreenBody>
        <img
          src={MarketingImage}
          alt={"subscription success"}
          width={400}
          height={240}
          style={{ position: "relative" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            gap: "16px",
          }}
        >
          <Text variant={TextStyleVariant.S2}>Welcome to 0% fees</Text>
          <Text variant={TextStyleVariant.P1}>
            Keep 100% of your earnings with 0% service fees. With Platinum, you
            can take full advantage of all the benefits of the EngineEars
            platform.
          </Text>
        </Box>
      </SubscriptionSuccessScreenBody>
      <SubscriptionSuccessScreenFooter>
        <Link to={getCommunityRoute()} style={{ textDecoration: "none" }}>
          <Button variant={ButtonVariant.OUTLINED}>Explore community</Button>
        </Link>
        <Link
          to={getProfileScreenRoute(loggedInUser.username)}
          style={{ textDecoration: "none" }}
        >
          <Button variant={ButtonVariant.PRIMARY}>Go to my profile</Button>
        </Link>
      </SubscriptionSuccessScreenFooter>
    </SubscriptionSuccessScreenContainer>
  );
};
