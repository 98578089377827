import { minutesToHours } from "date-fns";
import { useEffect, useState } from "react";
import { CheckoutRecordingSessionExtensionParams } from "../../store/actions/transactions";
import Engineer from "../../store/models/engineer";
import { RecordingService } from "../../store/models/recording";
import { OptionType } from "../../stories/elements/DropDownSelector/DropdownSelector";

export enum RecordingSessionCase {
  BOTH_EXIST,
  ONLY_ENGINEER,
  ONLY_STUDIO_ROOM,
}

export const useGetTotalPriceForExtension = (
  durationSelected: OptionType | undefined,
  availabilitiesLoading: boolean,
  studioRoomService: RecordingService | null | undefined,
  recordingSessionCase: RecordingSessionCase,
  params: CheckoutRecordingSessionExtensionParams,
  engineerService: RecordingService | undefined,
  engineer: Engineer | undefined,
  setParams: React.Dispatch<
    React.SetStateAction<CheckoutRecordingSessionExtensionParams>
  >,
) => {
  const [, setPricesLoading] = useState(false);
  const [studioCost, setStudioCost] = useState(0);
  const [recordingServiceId, setRecordingServiceId] = useState<number | null>(
    null,
  );
  const [engineerCost, setEngineerCost] = useState(0);
  const [serviceProviderUserId, setServiceProviderUserId] = useState<
    number | null | undefined
  >(null);
  const [totalCost, setTotalCost] = useState(0);

  const calculateCost = (
    service: RecordingService,
    setCost: React.Dispatch<React.SetStateAction<number>>,
    setServiceId: React.Dispatch<React.SetStateAction<number | null>>,
    hours: number,
  ) => {
    const rate = service.service_rate.price;
    setCost(rate * hours);
    setServiceId(service.id);
  };

  const updateParams = (
    recordingServiceId: number,
    serviceProviderUserId: number | null | undefined,
    duration: number,
  ) => {
    if (!params.recording_data) {
      return;
    }

    setParams((prevParams: CheckoutRecordingSessionExtensionParams) => {
      if (!prevParams.recording_data?.length) {
        return prevParams;
      }

      const updatedData = {
        ...prevParams.recording_data[0],
        recording_service_id: recordingServiceId,
        session_duration_minutes: duration,
      };

      if (serviceProviderUserId !== null) {
        updatedData.service_provider_user_id = serviceProviderUserId;
      }

      return {
        ...prevParams,
        recording_data: [updatedData],
      };
    });
  };

  useEffect(() => {
    if (durationSelected && !availabilitiesLoading) {
      setPricesLoading(true);
      const hours = minutesToHours(durationSelected.value);
      switch (recordingSessionCase) {
        case RecordingSessionCase.BOTH_EXIST:
          if (studioRoomService) {
            calculateCost(
              studioRoomService,
              setStudioCost,
              setRecordingServiceId,
              hours,
            );
            setRecordingServiceId(studioRoomService.id);
          }
          if (engineerService) {
            calculateCost(engineerService, setEngineerCost, () => {}, hours);
            setServiceProviderUserId(engineer?.user_id);
          }
          if (recordingServiceId == null || serviceProviderUserId == null) {
            return;
          }
          updateParams(
            recordingServiceId,
            serviceProviderUserId,
            durationSelected.value,
          );

          break;
        case RecordingSessionCase.ONLY_ENGINEER:
          if (engineerService && engineer) {
            calculateCost(
              engineerService,
              setEngineerCost,
              setRecordingServiceId,
              hours,
            );
            setServiceProviderUserId(engineer.user_id);
          }
          if (recordingServiceId == null || serviceProviderUserId == null) {
            return;
          }
          updateParams(
            recordingServiceId,
            serviceProviderUserId,
            durationSelected.value,
          );

          break;
        case RecordingSessionCase.ONLY_STUDIO_ROOM:
          if (studioRoomService) {
            calculateCost(
              studioRoomService,
              setStudioCost,
              setRecordingServiceId,
              hours,
            );
          }
          if (!recordingServiceId) {
            return;
          }

          updateParams(recordingServiceId, null, durationSelected.value);
          break;
        default:
          break;
      }
      setTotalCost(studioCost + engineerCost);
      setPricesLoading(false);
    }
  }, [durationSelected, engineerCost, studioCost, recordingServiceId]);
  return { engineerCost, studioCost, totalCost, params };
};
