import { FC, ReactElement } from "react";
import { CancellationPolicy } from "../../../store/models/recording";
import "./CancellationPolicyText.css";

interface CancellationPolicyTextProps {
  cancellationPolicy: CancellationPolicy;
}

export const CancellationPolicyText: FC<CancellationPolicyTextProps> = ({
  cancellationPolicy,
}: CancellationPolicyTextProps): ReactElement => {
  return (
    <div className="card-header-container cancellation-policy-container">
      <p>{`- Full refund if cancelled in the first ${cancellationPolicy.full_refund_cancellation_window_minutes} minutes`}</p>
      <p>{`- Full refund if cancelled up to ${cancellationPolicy.full_refund_prior_to_cancellation_deadline_days} days before session`}</p>
      <p>{`- No refunds within ${cancellationPolicy.partial_refund_prior_to_cancellation_deadline_hours} hours of session`}</p>
      <p>{`- Partial refund of ${
        cancellationPolicy.artist_cancellation_fee_percentage * 100
      }% otherwise`}</p>
    </div>
  );
};
