import { useMemo } from "react";
import { useGetVerifiedCredits } from "../../../../../hooks/profileScreenHooks/useGetVerifiedCredits";
import { useIsVisitingOwnProfile } from "../../../../../hooks/profileScreenHooks/useIsVisitingOwnProfile";
import { useAppSelector } from "../../../../../store/hooks";
import User from "../../../../../store/models/user";
import { ISingleItem } from "../../../../core-ui/components/ChipsList/ChipsList";
import { ChipsListDisplay } from "../../../../core-ui/components/ChipsList/ChipsListDisplay";
import { Text } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";

interface MusoCollaboratorsProps {
  userData?: User;
}

export const MusoCollaborators = ({ userData }: MusoCollaboratorsProps) => {
  const {
    data: userCredits,
    isError: isUserCreditsError,
    isLoading: isUserCreditsLoading,
  } = useGetVerifiedCredits(userData);

  const { musoAssociation, activeMusoUserId } = useAppSelector(
    (state) => state.musoAssociationStore,
  );

  const isVisitingOwnProfile = useIsVisitingOwnProfile();
  const CreditsBody = useMemo(() => {
    if (!activeMusoUserId && !musoAssociation && isVisitingOwnProfile) {
      return (
        <Text variant={TextStyleVariant.P2}>
          {" "}
          Link your Muso account from your profile
        </Text>
      );
    }
    return null;
  }, [isVisitingOwnProfile, activeMusoUserId, musoAssociation]);

  const credits = useMemo(() => {
    if (!userCredits || isUserCreditsLoading || isUserCreditsError) {
      return [];
    }
    const verifiedSet = new Set<string>();
    const uniqueArtists: ISingleItem[] = [];
    userCredits.map((credit) => {
      // Artists are returned as a string with comma separated values.
      // We need to split the string to determine if unique.
      // Muso provides a Profile collaborator API returning objects of collaborator profiles.
      // However it has not been implemented.
      const splitArtist = credit.artist.split(",");
      splitArtist.forEach((artist) => {
        if (!credit.id) {
          return;
        }
        if (verifiedSet.has(artist)) {
          return;
        }
        verifiedSet.add(artist);
        // Because a lot of the featured artists are in the same string and they don't have their own id,
        // We will use the set size to give it a unique key, when mapping them on the ChipList component.
        uniqueArtists.push({ value: verifiedSet.size, label: artist });
      });
    });
    return uniqueArtists;
  }, [userCredits, isUserCreditsLoading, isUserCreditsError]);

  return (
    <ChipsListDisplay
      title="CREDITS"
      dataSource={credits}
      loading={isUserCreditsLoading}
      isEditMode={false}
    >
      {CreditsBody}
    </ChipsListDisplay>
  );
};
