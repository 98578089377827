import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { useAtomValue } from "jotai";
import { activeUserIdAtom } from "../../atoms/user/activeUserAtom";

const useInvalidateOnboardingProgress = () => {
  const queryClient = useQueryClient();
  const activeUserId = useAtomValue(activeUserIdAtom);

  const invalidateOnboardingProgress = useCallback(async () => {
    if (!activeUserId) {
      return;
    }
    await queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_ONBOARDING_PROGRESS, activeUserId],
    });
  }, [activeUserId, queryClient]);

  return { invalidateOnboardingProgress };
};

export default useInvalidateOnboardingProgress;
