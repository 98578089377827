import { useContext } from "react";
import { DropdownContext } from "../../../../../core-ui/components/Select/Select";
import { DropdownMenuItem } from "../../../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";
import { usePaymentPlanStatus } from "../../../../../../hooks/partialPaymentHooks";

export interface SessionActionCompletePaymentProps {
  projectId: number;
}

/**
 * Action Option to be redirect to the project payment page
 */
export const SessionActionCompletePayment = ({
  projectId,
}: SessionActionCompletePaymentProps) => {
  const dropdownContext = useContext(DropdownContext);
  const { redirectToPurchaseProject, loading, fetchingPaymentStatus } =
    usePaymentPlanStatus(undefined, undefined, projectId, true);

  return (
    <DropdownMenuItem
      disabled={fetchingPaymentStatus || loading}
      onSelect={(e: Event) => {
        e.preventDefault();
        void redirectToPurchaseProject().finally(() => {
          dropdownContext?.closeDropdownMenu();
        });
      }}
    >
      Complete Payment
    </DropdownMenuItem>
  );
};
