import { useCallback, useMemo } from "react";
import { useAppSelector } from "../store/hooks";
import { ProjectType } from "../store/models/project";
import Service from "../store/models/service";
import { selectServices } from "../store/selectors/servicesSelectors";
import {
  isEngineerOnboardedSelector,
  isEngineerVerifiedSelector,
  selectIsUserDolbyEnabled,
  selectIsUserRecordingEnabled,
} from "../store/selectors/userInfoSelectors";

export const userAlreadyHasServiceMessage =
  "You have already added a service for this project type. Please edit your existing service if you would like to make changes.";

export const useCanAddEngineerService = (
  service?: Service,
): { canAddService: boolean; message: string } => {
  const currentServices: Service[] = useAppSelector(selectServices);
  const engineerIsOnboarded: boolean = useAppSelector(
    isEngineerOnboardedSelector,
  );
  const engineerIsVerified: boolean = useAppSelector(
    isEngineerVerifiedSelector,
  );
  const isDolbyEnabled: boolean = useAppSelector(selectIsUserDolbyEnabled);
  const isRecordingEnabled: boolean = useAppSelector(
    selectIsUserRecordingEnabled,
  );

  const checkIfServiceAlreadyExists = useCallback(
    (currentEngineerService: Service) => {
      if (!service) return false;
      return currentEngineerService.service_type === service?.service_type;
    },
    [service],
  );

  const serviceAlreadyExists = useMemo(() => {
    return currentServices.some(checkIfServiceAlreadyExists);
  }, [currentServices, checkIfServiceAlreadyExists, service]);

  return useMemo(() => {
    if (!service) return { canAddService: false, message: "" };
    if (currentServices.length === 0) {
      return { canAddService: true, message: "" };
    }
    if (serviceAlreadyExists) {
      return { canAddService: false, message: userAlreadyHasServiceMessage };
    }
    if (!engineerIsOnboarded && !engineerIsVerified) {
      return {
        canAddService: false,
        message: "You must be onboarded to add a service",
      };
    }
    if (service?.service_type === ProjectType.ATMOS_MIXING && !isDolbyEnabled) {
      return {
        canAddService: false,
        message:
          "Dolby Atmos services can only be hosted when certified. Email community@engineears.com for more info.",
      };
    }
    if (
      service?.service_type === ProjectType.RECORDING &&
      !isRecordingEnabled
    ) {
      return {
        canAddService: false,
        message:
          "Recording is in beta. Please reach out to the EngineEars team if you would like to host Recording services.",
      };
    }
    return { canAddService: true, message: "" };
  }, [
    currentServices,
    service,
    serviceAlreadyExists,
    engineerIsOnboarded,
    engineerIsVerified,
    isDolbyEnabled,
    isRecordingEnabled,
  ]);
};
