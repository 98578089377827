import { faX } from "@fortawesome/free-solid-svg-icons";
import { useAtomValue } from "jotai";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { showBottomAndTopNavAtom } from "../../../atoms/navAtoms";
import {
  useDownloadTrack,
  useFetchFiles,
} from "../../../hooks/audioPlayerHooks/fetchTrack";
import { useGetFooterPlayerRef } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { useSetupLockScreenControls } from "../../../hooks/audioPlayerHooks/useSetupLockScreenControls";
import { useSetupOnFinish } from "../../../hooks/audioPlayerHooks/useSetupOnFinish";
import { useTrackPositionWhenLocked } from "../../../hooks/audioPlayerHooks/useTrackPositionWhenLocked";
import {
  SkipTimes,
  skipSeconds,
} from "../../../hooks/audioPlayerHooks/waveformUtils";
import useInvalidateOnboardingProgress from "../../../hooks/onboardingHooks/useInvalidateOnboardingProgress";
import useClickOutside from "../../../hooks/useClickOutside";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { SCREENS } from "../../../routes";
import {
  FooterFileTrackType,
  resetAbPlayStore,
  setIndex,
  setUrl,
} from "../../../store/actions/abPlayerStore";
import { downloadFileVersionTrack } from "../../../store/actions/audioService";
import { clearFileVersionDownload } from "../../../store/actions/fileVersions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { PlaylistTrackType } from "../../../store/models/playListTrack";
import {
  canTrackBeDownloaded,
  determineIfPortfolioFeatureData,
  determineIfTrackIsDownloaded,
  getCurrentTrackDownloadedProjectBlob,
  getCurrentTrackDownloadedProjectBlobMp3s,
  getCurrentTrackFromPlaylist,
  getTrackInfo,
} from "../../../store/selectors/abPlayerSelectors";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  PlaybackControlButton,
  PlaybackControlButtonVariant,
} from "../../elements/PlaybackControlButton/PlaybackControlButton";
import { PlaybackControlButtonSize } from "../../elements/PlaybackControlButton/PlaybackControlButton.styles";
import { ColorPalette } from "../../theme";
import { Playlist } from "../Playlist/Playlist";
import {
  StyledFooterPlayerActionsContainer,
  StyledFooterPlayerButtonsContainer,
  StyledFooterPlayerContainer,
  StyledFooterPlayerInfoFontawesomeIcon,
  StyledFooterPlayerPlaybackControlContainer,
  StyledFooterRowContainer,
  StyledFooterWaveformContainer,
} from "./FooterPlayer.styles";
import { FooterProjectInfo } from "./FooterProjectInfo/FooterProjectInfo";
import { FooterWaveform } from "./FooterWaveform/FooterWaveform";

export const FooterPlayer = () => {
  const {
    showFooterPlayer,
    isFooterPlaying,
    playlist,
    currentTrackIndex,
    url,
    footerFileTrackType,
    isFooterReady,
  } = useAppSelector((state) => state.abPlayerStore);

  const dispatch = useAppDispatch();
  const { isDesktop } = useMediaQueryBreakpoint();
  const isFooterAndHeaderShowing = useAtomValue(showBottomAndTopNavAtom);
  const [expandFooter, setExpandFooter] = useState(false);
  const [showPlaylist, setShowPlaylist] = useState(false);
  const footerPlayerContainerRef = useRef<HTMLDivElement>(null);
  const [isLooping, setIsLooping] = useState(false);
  const trackIsDownloaded = useAppSelector(
    determineIfTrackIsDownloaded(currentTrackIndex),
  );
  const canDownloadTrack = useAppSelector(canTrackBeDownloaded());
  const currentTrack = useAppSelector(getCurrentTrackFromPlaylist());
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const code: null | string = query.get("code");

  const blobUrlMp3 = useAppSelector(getCurrentTrackDownloadedProjectBlobMp3s());
  const blobUrl = useAppSelector(getCurrentTrackDownloadedProjectBlob());
  const location = useLocation();
  const hideFooter = location.pathname.startsWith(
    SCREENS.LOGGED_OUT_REVIEW.replace("/:project_id", ""),
  );
  const { invalidateOnboardingProgress } = useInvalidateOnboardingProgress();

  const {
    artist: artistName,
    title,
    engineer: engineerName,
    artistUsername,
    engineerUsername,
    artworkSource,
    serviceType,
  } = useAppSelector(getTrackInfo(currentTrackIndex));
  const footerPlayerRef = useGetFooterPlayerRef();
  const toggleFooterAndPlaylist = (show: boolean) => {
    setExpandFooter(show);
    setShowPlaylist(show);
  };

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "Space") {
        // Toggle play/pause when space bar is pressed
        const activeElement = (event.target as HTMLElement).nodeName;
        const isTypingInField = Boolean(
          activeElement === "INPUT" || activeElement === "TEXTAREA",
        );

        if (!isFooterReady || isTypingInField) {
          return;
        }
        event.preventDefault();

        if (footerPlayerRef.current) {
          if (footerPlayerRef.current.isPlaying()) {
            footerPlayerRef.current.pause();
          } else {
            footerPlayerRef.current.play();
          }
        }
      }
    },
    [isFooterReady],
  );

  useEffect(() => {
    if (isDesktop) {
      document.addEventListener("keydown", handleKeyPress);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress, isDesktop]);

  const changeIndex = (index: number) => {
    footerPlayerRef.current?.pause();
    dispatch(setIndex(index));
  };

  const handleShowPlaylistButton = () => {
    emitAnalyticsTrackingEvent("footer_player_show_playlist", {
      current_track_id: currentTrack?.id,
    });

    if (!expandFooter) {
      toggleFooterAndPlaylist(true);
    } else {
      setShowPlaylist(!showPlaylist);
    }
  };

  const closeExpandedFooter = () => {
    toggleFooterAndPlaylist(false);
  };

  useClickOutside(footerPlayerContainerRef, () => {
    toggleFooterAndPlaylist(false);
  });

  useFetchFiles();
  useDownloadTrack();
  useSetupOnFinish(isLooping);

  const limitView = !isDesktop && !expandFooter;

  useEffect(() => {
    if (!canDownloadTrack) return;
    const newURL = blobUrl || blobUrlMp3 || "";
    if (newURL && newURL !== url) {
      dispatch(setUrl({ url: newURL }));
    }
  }, [blobUrl, blobUrlMp3, dispatch, url, currentTrackIndex, canDownloadTrack]);

  useTrackPositionWhenLocked();
  useSetupLockScreenControls();

  useEffect(() => {
    if (!currentTrack) return;
    if (!canDownloadTrack) return;
    if (trackIsDownloaded) return;
    if (determineIfPortfolioFeatureData(currentTrack)) {
      return;
    }
    const onlyMp3 = true;
    if (currentTrack.type === PlaylistTrackType.ArtistRelease) {
      downloadFileVersionTrack(
        dispatch,
        onlyMp3,
        currentTrack.fileVersionId,
        code ?? undefined,
        undefined,
        invalidateOnboardingProgress,
      );
      return;
    } else {
      downloadFileVersionTrack(
        dispatch,
        onlyMp3,
        undefined,
        code ?? undefined,
        currentTrack.id,
        invalidateOnboardingProgress,
      );
    }
  }, [
    dispatch,
    code,
    currentTrack?.id,
    canDownloadTrack,
    trackIsDownloaded,
    currentTrack,
    invalidateOnboardingProgress,
  ]);

  const renderPlaylistButton = () => {
    return (
      <PlaybackControlButton
        variant={PlaybackControlButtonVariant.PLAYLIST}
        size={PlaybackControlButtonSize.SMALL}
        onClick={handleShowPlaylistButton}
        isToggled={showPlaylist}
        disabled={Boolean(
          !playlist || playlist.length <= 0 || currentTrackIndex < 0,
        )}
      />
    );
  };

  return (
    <StyledFooterPlayerContainer
      ref={footerPlayerContainerRef}
      $expandFooter={expandFooter}
      $isFooterAndHeaderShowing={isFooterAndHeaderShowing}
      $showPlaylist={showPlaylist}
      $showFooterPlayer={showFooterPlayer && !hideFooter}
      onClick={() => {
        toggleFooterAndPlaylist(true);
      }}
    >
      {showPlaylist && <Playlist onClick={closeExpandedFooter} />}
      {!showPlaylist && (
        <StyledFooterRowContainer style={{ order: 1 }}>
          {!expandFooter && (
            <Button
              variant={ButtonVariant.UNSTYLED}
              onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                emitAnalyticsTrackingEvent("footer_player_close", {
                  current_track_id: currentTrack?.id,
                });
                footerPlayerRef.current?.pause();
                footerPlayerRef.current?.destroy();
                dispatch(clearFileVersionDownload({ fileVersionId: null }));
                dispatch(resetAbPlayStore());
              }}
            >
              <StyledFooterPlayerInfoFontawesomeIcon icon={faX} />
            </Button>
          )}
          <FooterProjectInfo
            artworkSource={artworkSource}
            artistUser={artistName}
            engineerUser={engineerName}
            artistUsername={artistUsername}
            engineerUsername={engineerUsername}
            trackTitle={title}
            serviceType={serviceType}
            expandFooter={expandFooter}
            onClick={(e: SyntheticEvent<HTMLDivElement>) => {
              e.stopPropagation();
              emitAnalyticsTrackingEvent("footer_player_project_info", {
                current_track_id: currentTrack?.id,
              });
              setExpandFooter(!expandFooter);
            }}
          />
        </StyledFooterRowContainer>
      )}

      <StyledFooterPlayerActionsContainer
        $expandFooter={expandFooter || !isDesktop}
        style={{ order: 4 }}
      >
        <StyledFooterPlayerPlaybackControlContainer
          $expandFooter={expandFooter}
          $isFooterAndHeaderShowing={isFooterAndHeaderShowing && !isDesktop}
        >
          {!limitView && (
            <>
              {/* Invisible icon fixes the alignment issue */}
              <PlaybackControlButton
                onClick={() => setIsLooping(!isLooping)}
                variant={PlaybackControlButtonVariant.REPEAT}
                size={PlaybackControlButtonSize.SMALL}
                isToggled={isLooping}
                hidden={true}
              />
              {playlist.length > 1 && (
                <PlaybackControlButton
                  onClick={() => {
                    emitAnalyticsTrackingEvent("footer_player_previous", {
                      current_track_id: currentTrack?.id,
                    });
                    if (
                      footerFileTrackType ===
                        FooterFileTrackType.SCHEDULED_PROJECT ||
                      footerFileTrackType === FooterFileTrackType.RELEASE ||
                      footerFileTrackType ===
                        FooterFileTrackType.SPOTIFY_SNIPPET
                    ) {
                      changeIndex(currentTrackIndex - 1);
                    }
                  }}
                  variant={PlaybackControlButtonVariant.BACKWARD_STEP}
                  size={PlaybackControlButtonSize.SMALL}
                />
              )}{" "}
              <PlaybackControlButton
                onClick={() => {
                  emitAnalyticsTrackingEvent("footer_player_back_step", {
                    current_track_id: currentTrack?.id,
                  });
                  skipSeconds(SkipTimes.BACKWARD_STEP, footerPlayerRef);
                }}
                variant={PlaybackControlButtonVariant.BACKWARD_FAST}
                size={PlaybackControlButtonSize.SMALL}
              />
            </>
          )}
          <PlaybackControlButton
            onClick={() => {
              if (!footerPlayerRef.current) return;
              void footerPlayerRef.current?.playPause().catch(() => {});
            }}
            variant={
              isFooterPlaying
                ? PlaybackControlButtonVariant.PAUSE_CIRCLE
                : PlaybackControlButtonVariant.PLAY_CIRCLE
            }
            style={{ height: "28px", width: "28px", color: ColorPalette.White }}
          />
          {!limitView && (
            <>
              <PlaybackControlButton
                onClick={() => {
                  emitAnalyticsTrackingEvent("footer_player_forward_fast", {
                    current_track_id: currentTrack?.id,
                  });
                  skipSeconds(SkipTimes.FORWARD_STEP, footerPlayerRef);
                }}
                variant={PlaybackControlButtonVariant.FORWARD_FAST}
                size={PlaybackControlButtonSize.SMALL}
              />
              {playlist.length > 1 && (
                <PlaybackControlButton
                  onClick={() => {
                    emitAnalyticsTrackingEvent("footer_player_forward_step", {
                      current_track_id: currentTrack?.id,
                    });
                    if (
                      footerFileTrackType ===
                        FooterFileTrackType.SCHEDULED_PROJECT ||
                      footerFileTrackType === FooterFileTrackType.RELEASE ||
                      footerFileTrackType ===
                        FooterFileTrackType.SPOTIFY_SNIPPET
                    ) {
                      changeIndex(currentTrackIndex + 1);
                    }
                  }}
                  variant={PlaybackControlButtonVariant.FORWARD_STEP}
                  size={PlaybackControlButtonSize.SMALL}
                />
              )}
              <PlaybackControlButton
                onClick={() => {
                  emitAnalyticsTrackingEvent("footer_player_looping", {
                    current_track_id: currentTrack?.id,
                  });

                  setIsLooping(!isLooping);
                }}
                variant={PlaybackControlButtonVariant.REPEAT}
                size={PlaybackControlButtonSize.SMALL}
                isToggled={isLooping}
              />
            </>
          )}
        </StyledFooterPlayerPlaybackControlContainer>
      </StyledFooterPlayerActionsContainer>
      <StyledFooterWaveformContainer
        style={{ order: 3 }}
        $expandFooter={expandFooter}
      >
        <FooterWaveform />
      </StyledFooterWaveformContainer>
      {(isDesktop || expandFooter) && (
        <StyledFooterPlayerButtonsContainer
          style={{ order: expandFooter ? 2 : 4 }}
          $expandFooter={expandFooter}
        >
          {renderPlaylistButton()}
        </StyledFooterPlayerButtonsContainer>
      )}
    </StyledFooterPlayerContainer>
  );
};
