import styled from "styled-components";

export const FileLinkTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FileTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
`;

export const NoFilesView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  border: dashed thin ${({ theme }) => theme.separatorColor};
  border-radius: ${({ theme }) => theme.border.radius.md};
  margin-bottom: 8px;
  text-transform: capitalize;
  background-color: ${({ theme }) => theme.midgroundColor};
`;
