import styled from "styled-components";
import { Button } from "../../../../core-ui/components/Button/Button";

export const ProjectHistoryContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  border: solid 1px ${({ theme }) => theme.separatorColor};
  border-radius: ${({ theme }) => theme.border.radius.md};
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 16px;
`;

export const ProjectHistorySeparator = styled.hr`
  border: none;
  border-bottom: solid 1px ${({ theme }) => theme.separatorColor};
  margin: 16px 0;
  width: 100%;
`;

export const ProjectHistoryItemContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const ProjectHistoryImageLineContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: start;
`;

export const ProjectHistoryUserImage = styled.img`
  border-radius: 50%;
  height: 34px;
  object-fit: cover;
  width: 34px;
`;

export const ProjectHistoryVerticalRule = styled.div`
  border-left: solid 1px ${({ theme }) => theme.separatorColor};
  min-height: 20px;
  height: 100%;
  margin: 3px 0;
`;

export const ProjectHistoryItemTextContainer = styled.div<{
  $isLastItem: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: ${({ $isLastItem }) => ($isLastItem ? 0 : "24px")};
`;

export const ProjectHistoryItemHeader = styled.div`
  display: flex;
  gap: 4px;
`;

export const ProjectHistoryDownloadFileButton = styled(Button)`
  gap: 8px;
  margin-top: 4px;
  padding: 8px 12px;
  min-width: 100px;
  width: fit-content;
`;
