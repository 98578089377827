import { defaultPageLoaderSize } from "../../theme";
import { SoundWaveLoader } from "../SoundWaveLoader/SoundWaveLoader";

export const DefaultSoundWaveLoader = () => {
  return (
    <SoundWaveLoader
      styles={{
        backgroundColor: "transparent",
      }}
      width={defaultPageLoaderSize}
      height={defaultPageLoaderSize}
    />
  );
};
