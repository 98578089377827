import {
  convertUTCDateToLocalDate,
  get15MinuteDayIndexFromDateTime,
  getAvailabilityFormattedDateString,
} from "../../store/utils/dateTimeUtils";

/**
 * A hook to add availability to a given date in a Map of availabilities based on the start time and duration.
 * This hook is used in the reschedule modal to temporarily add availability while rescheduling a session.
 */
export const useAddTimeToAvailabilityMap = (
  availabilities: Map<string, string>,
  startTime: string,
  sessionDurationMinutes: number,
) => {
  const dateTime = convertUTCDateToLocalDate(new Date(startTime));

  // find 15 minute index of startTime for availabilityString
  const startTimeIndex = get15MinuteDayIndexFromDateTime(dateTime);

  // calculate number of 15 minute blocks in sessionDurationMinutes
  const numberOf1s = Math.floor(sessionDurationMinutes / 15);

  // set time to midnight to accurately format date string
  dateTime.setHours(0, 0, 0, 0);
  // format date to yyyy-mm-dd to get availability string from map
  const availabilitiesMapKey = getAvailabilityFormattedDateString(dateTime);
  // get availability string for date i.e. "000011111000"
  const firstDayString = availabilities.get(availabilitiesMapKey);

  // if no availability for date, return original availabilities
  if (!firstDayString) return availabilities;

  // get availability string for next day to cover times that overlap between days
  const tomorrow = new Date();
  tomorrow.setDate(dateTime.getDate() + 1);
  const nextDayString = availabilities.get(
    getAvailabilityFormattedDateString(tomorrow),
  );

  // combine availability strings for both days i.e. "000011111000000011111000"
  const availabilityString = nextDayString
    ? firstDayString + nextDayString.slice(0, numberOf1s)
    : firstDayString;

  const durationMinutesArray = new Array(numberOf1s).fill("1") as string[];

  // replace 0s with 1s in availabilityString
  const availabilityArray = availabilityString.split("");
  availabilityArray.splice(startTimeIndex, numberOf1s, ...durationMinutesArray);
  const updatedAvailabilityString = availabilityArray.join("");

  // update availabilities map with new availabilityString
  const newAvailabilities = new Map(availabilities);
  newAvailabilities.set(availabilitiesMapKey, updatedAvailabilityString);
  return newAvailabilities;
};
