import styled from "styled-components";

export const DEFAULT_TAB_WIDTH = 170;

export const TabContainer = styled.button<{
  $selected: boolean;
  $disabled: boolean;
  $tabWidth: number;
}>`
  background: ${({ theme }) => theme.bodyBackgroundColor};
  border: none;
  width: ${({ $tabWidth }) => `${$tabWidth}px`};
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  padding: 16px;
  margin: 0;
  outline: none;
`;

export const TabsContainer = styled.div<{
  $isMobile: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${({ $isMobile }) => ($isMobile ? "center" : "flex-start")};
  position: relative;
  width: fit-content;
  margin: ${({ $isMobile }) => ($isMobile ? "0 auto" : "0")};
`;

interface TabsBottomBarProps {
  $tabsCount: number;
  $tabWidth: number;
}

interface TabsSelectedBarProps extends TabsBottomBarProps {
  $selectedTab: number;
}

export const TabsBottomGreyBar = styled.div<TabsBottomBarProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: ${({ $tabsCount, $tabWidth }) => `${$tabsCount * $tabWidth}px`};
  background: ${({ theme }) => theme.separatorColor};
  height: 4px;
`;

export const TabsSelectedBar = styled.div<TabsSelectedBarProps>`
  width: ${({ $tabWidth }) => `${$tabWidth}px`};
  transform: translateX(
    ${({ $selectedTab, $tabWidth }) => `${$selectedTab * $tabWidth}px`}
  );
  height: 4px;
  transition: transform 0.3s ease-in-out;
  background: ${({ theme }) => theme.colorPalette.BoomyOrange400};
`;
