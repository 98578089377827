import { FileVersion } from "../../../../store/models/fileVersion";
import { ProjectById, ProjectType } from "../../../../store/models/project";

/**
 * Determines if the user needs to upload stems/alts for the project
 * on the final upload step.
 */
export const isAltUploadRequired = (
  newFiles: FileVersion[],
  project: ProjectById,
) => {
  const isAtmos = project.service_type === ProjectType.ATMOS_MIXING;
  // If it's an atmos project, main and clean are all that are needed
  if (isAtmos) return false;

  // everyone else is required to upload stems and/or alts
  return !newFiles.length;
};
