import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { PaymentRedirectType } from "../../../store/models/transaction";
import { StripePaymentRedirectPage } from "./StripePaymentRedirectPage";

const AffirmPaymentRedirectScreen = () => {
  useSetPageTitle("Affirm Payment");

  return (
    <StripePaymentRedirectPage
      paymentRedirectType={PaymentRedirectType.AFFIRM}
    />
  );
};

export default AffirmPaymentRedirectScreen;
