import {
  faXTwitter,
  faSoundcloud,
  faInstagram,
  faFacebook,
  faTwitch,
  faTiktok,
  faYoutube,
  faSpotify,
  faApple,
  faAmazon,
} from "@fortawesome/free-brands-svg-icons";
import { OptionType } from "../elements/DropDownSelector/DropdownSelector";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IconType } from "../core-ui/components/Icon/Icon";

export enum SocialMediaListEnum {
  soundcloud,
  instagram,
  twitter,
  facebook,
  twitch,
  tiktok,
  youtube,
  spotify,
  apple,
  tidal,
  amazon,
}

export interface SocialMediaLink {
  icon: IconProp | null;
  altIcon?: IconType;
  label: string;
  baseLink: string;
}

export const SocialMediaMap = new Map<SocialMediaListEnum, SocialMediaLink>([
  [
    SocialMediaListEnum.soundcloud,
    {
      icon: faSoundcloud,
      label: "Soundcloud",
      baseLink: "https://soundcloud.com/",
    },
  ],
  [
    SocialMediaListEnum.instagram,
    {
      icon: faInstagram,
      label: "Instagram",
      baseLink: "https://instagram.com/",
    },
  ],
  [
    SocialMediaListEnum.twitter,
    {
      icon: faXTwitter,
      label: "X",
      baseLink: "https://twitter.com/",
    },
  ],
  [
    SocialMediaListEnum.facebook,
    {
      icon: faFacebook,
      label: "Facebook",
      baseLink: "https://facebook.com/",
    },
  ],
  [
    SocialMediaListEnum.twitch,
    {
      icon: faTwitch,
      label: "Twitch",
      baseLink: "https://twitch.com/",
    },
  ],
  [
    SocialMediaListEnum.tiktok,
    {
      icon: faTiktok,
      label: "Tik Tok",
      baseLink: "https://www.tiktok.com/",
    },
  ],
  [
    SocialMediaListEnum.youtube,
    {
      icon: faYoutube,
      label: "Youtube",
      // Prefix the url with @ because youtube uses @ in the url for channels.
      baseLink: "https://www.youtube.com/@",
    },
  ],
  [
    SocialMediaListEnum.spotify,
    {
      icon: faSpotify,
      label: "Spotify",
      baseLink: "https://www.open.spotify.com/",
    },
  ],
  [
    SocialMediaListEnum.apple,
    {
      icon: faApple,
      label: "Apple",
      baseLink: "https://www.music.apple.com/",
    },
  ],
  [
    SocialMediaListEnum.tidal,
    {
      icon: null,
      altIcon: IconType.Tidal,
      label: "Tidal",
      baseLink: "https://www.tidal.com/",
    },
  ],
  [
    SocialMediaListEnum.amazon,
    {
      icon: faAmazon,
      label: "Amazon",
      baseLink: "https://www.amazon.com/",
    },
  ],
]);

export const SocialMediaSelectorOptions: OptionType[] = Array.from(
  SocialMediaMap.entries(),
)
  .filter(
    ([k]) =>
      k !== SocialMediaListEnum.tidal &&
      k !== SocialMediaListEnum.spotify &&
      k !== SocialMediaListEnum.amazon &&
      k !== SocialMediaListEnum.apple,
  )
  .map(([k, v]) => ({ value: k, label: v.label }));
