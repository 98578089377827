import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMixingMasteringAvailabilities } from "../../../hooks/bookingHooks/useMixingMasteringAvailabilities";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { useAppDispatch } from "../../../store/hooks";
import { AltItem } from "../../../store/models/alts";
import { ScheduledProject } from "../../../store/models/scheduledproject";
import Service from "../../../store/models/service";
import {
  dispatchLoadEngineerServices,
  dispatchLoadProject,
  dispatchOnAcceptProjectSchedule,
  dispatchOnRejectProject,
  dispatchOnSubmitProjectDates,
  renderRescheduleBookingScreenContent,
} from "../../../store/utils/reviewProjectDatesUtils";
import { FirstPassDate } from "../../components/ProjectBookingCalendar/ProjectBookingCalendar";
import "./MobileProjectReviewScreen.css";

export const MobileProjectReviewScreen = () => {
  const location = useLocation();
  const { scheduledProject } = location.state as {
    scheduledProject: ScheduledProject;
  };
  const dispatch = useAppDispatch();
  const history = useHistory();

  useSetPageTitle("Project Review");

  const [service, setService] = useState<Service>();
  const { availabilities } = useMixingMasteringAvailabilities(service);
  const [altsList, setAltsList] = useState<AltItem[]>([]);
  const [loading, setLoading] = useState(false);

  const engineerUser = scheduledProject!.projects[0].engineer;
  const projectType = scheduledProject!.projects[0].service_type;

  useEffect(() => {
    dispatchLoadEngineerServices({
      dispatch,
      engineerUser,
      projectType,
      setService,
    });
  }, [dispatch, engineerUser, projectType]);

  useEffect(() => {
    dispatchLoadProject({ dispatch, scheduledProject, setAltsList });
  }, [scheduledProject, dispatch]);

  const onRejectProject = () => {
    dispatchOnRejectProject({
      dispatch,
      history,
      scheduledProject,
      setLoading,
    });
  };

  const onAcceptProjectSchedule = () => {
    dispatchOnAcceptProjectSchedule({
      dispatch,
      history,
      scheduledProject,
      setLoading,
    });
  };

  const onSubmitProjectDates = (songDatesMap: Map<string, FirstPassDate>) => {
    dispatchOnSubmitProjectDates(
      { dispatch, history, scheduledProject, setLoading },
      songDatesMap,
    );
  };

  const rescheduleBookingScreenProps = {
    loading,
    service: service!,
    scheduledProject: scheduledProject,
    alts: altsList,
    availabilities: availabilities!,
    onSubmitProjectDates,
    onAcceptProjectSchedule,
    onRejectProject,
  };

  return renderRescheduleBookingScreenContent({
    service,
    availabilities,
    rescheduleBookingScreenProps,
  });
};
