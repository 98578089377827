import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useBillingInfoSubmitterEmails } from "../../../hooks/purchaseOrderWithTransactionHooks";
import {
  ItemizedPurchaseOrder,
  submitBillingInfo,
  SubmitBillingInfoParams,
} from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { DetailedPurchaseOrder } from "../../../store/models/project";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { BudgetModalProps } from "../BudgetManagerModals/BudgetManagerModal";
import BillingInfoModalBody from "./BillingInfoModalBody";
import {
  useCancelTextForPurchaseOrderModal,
  useConfirmTextForPurchaseOrderModal,
  usePurchaseOrderHeader,
} from "./hooks";
import { PurchaseOrderBillingInfoSteps } from "./utils";

interface SubmitBillingInfoModalProps extends BudgetModalProps {
  purchaseOrder: DetailedPurchaseOrder; // legacy purchase order
  purchaseOrders: ItemizedPurchaseOrder[]; // purchase orders fetched from getItemizedTransaction
}

interface PurchaseOrderBillingInfo extends SubmitBillingInfoParams {
  eventually_owed_to: string | null;
  total: number;
}

export interface BillingInfoParamsMap {
  [purchaseOrderId: string]: PurchaseOrderBillingInfo;
}

export const SubmitBillingInfoModal = ({
  isOpen,
  openModal,
  closeModal,
  code,
  onBudgetUpdated,
  purchaseOrder,
  purchaseOrders,
}: SubmitBillingInfoModalProps) => {
  const [submittingDetails, setSubmittingDetails] = useState(false);
  const dispatch = useAppDispatch();
  const {
    orderSummary: recordingOrderSummary,
    isLoading: recordingOrderSummaryIsLoading,
  } = useAppSelector((state) => state.recordingCartsStore);
  const {
    orderSummary: mixMasterOrderSummary,
    isLoading: mixMasterOrderSummaryIsLoading,
  } = useAppSelector((state) => state.mixMasterCartsStore);
  const [step, setStep] = useState<PurchaseOrderBillingInfoSteps>(
    PurchaseOrderBillingInfoSteps.SUBMIT_BILLING_INFO,
  );

  const billingInfo: BillingInfoParamsMap = {};
  purchaseOrders.forEach((_purchaseOrder) => {
    billingInfo[_purchaseOrder.id] = {
      purchase_order_id: _purchaseOrder.id,
      code: code ?? undefined,
      cost_center: _purchaseOrder.cost_center,
      eventually_owed_to: _purchaseOrder.eventually_owed_to,
      general_ledger: _purchaseOrder.general_ledger,
      order_number: _purchaseOrder.order_number,
      total: _purchaseOrder.total,
      work_breakdown_structure: _purchaseOrder.work_breakdown_structure,
    };
  });

  const [billingInfoParams, setBillingInfoParams] =
    useState<BillingInfoParamsMap>(billingInfo);

  const headerText = usePurchaseOrderHeader({ step });
  const confirmText = useConfirmTextForPurchaseOrderModal({ step });
  const cancelText = useCancelTextForPurchaseOrderModal({ step });

  const projectAdminEmails = useBillingInfoSubmitterEmails(purchaseOrder);

  useEffect(() => {
    return () => {
      if (!isOpen) {
        setStep(PurchaseOrderBillingInfoSteps.SUBMIT_BILLING_INFO);
      }
    };
  }, [isOpen]);

  return (
    <BaseModal
      header={headerText}
      open={isOpen}
      setOpen={isOpen ? closeModal : openModal}
      confirmText={confirmText}
      loading={submittingDetails}
      cancelText={cancelText}
      onConfirm={async () => {
        switch (step) {
          case PurchaseOrderBillingInfoSteps.SUBMIT_BILLING_INFO:
            setStep(PurchaseOrderBillingInfoSteps.CONFIRM_BILLING_INFO);
            break;
          case PurchaseOrderBillingInfoSteps.CONFIRM_BILLING_INFO:
            try {
              setSubmittingDetails(true);
              const updatedBudget = await dispatch(
                submitBillingInfo(Object.values(billingInfoParams)),
              ).unwrap();
              if (onBudgetUpdated) {
                onBudgetUpdated(updatedBudget);
                setStep(PurchaseOrderBillingInfoSteps.BILLING_INFO_SUBMITTED);
              }
            } catch (e) {
              if (e instanceof Error) {
                toast.error(e.message);
                setStep(PurchaseOrderBillingInfoSteps.SUBMIT_BILLING_INFO);
              }
            } finally {
              setSubmittingDetails(false);
            }
            break;
          case PurchaseOrderBillingInfoSteps.BILLING_INFO_SUBMITTED:
            closeModal();
            break;
        }
      }}
      onCancel={
        step === PurchaseOrderBillingInfoSteps.BILLING_INFO_SUBMITTED
          ? undefined
          : () => {
              switch (step) {
                case PurchaseOrderBillingInfoSteps.SUBMIT_BILLING_INFO:
                  closeModal();
                  break;
                case PurchaseOrderBillingInfoSteps.CONFIRM_BILLING_INFO:
                  setStep(PurchaseOrderBillingInfoSteps.SUBMIT_BILLING_INFO);
                  break;
              }
            }
      }
      showModalFooter={true}
    >
      <BillingInfoModalBody
        step={step}
        projectAdminEmails={projectAdminEmails}
        billingInfoParams={billingInfoParams}
        recordingOrderSummaryIsLoading={recordingOrderSummaryIsLoading}
        mixMasterOrderSummaryIsLoading={mixMasterOrderSummaryIsLoading}
        recordingOrderSummary={recordingOrderSummary}
        mixMasterOrderSummary={mixMasterOrderSummary}
        setBillingInfoParams={setBillingInfoParams}
      />
    </BaseModal>
  );
};
