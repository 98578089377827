import { useCallback, useState } from "react";

const useModal = (initialOpenValue = false) => {
  const [isOpen, setIsOpen] = useState(initialOpenValue);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggleModal = useCallback(() => {
    setIsOpen((o) => !o);
  }, []);

  return { isOpen, openModal, closeModal, setIsOpen, toggleModal };
};

export default useModal;
