import { SessionModificationDetails } from "../../../hooks/recordingSessionHooks/useGetSessionModificationDetails";
import { updateAvailabilityCache } from "../../../store/actions/availability";
import { fetchPaginatedRecordingSessions } from "../../../store/actions/paginatedRecordingSessions";
import {
  acceptSessionModification,
  rejectSessionModification,
} from "../../../store/actions/recording";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RecordingSession } from "../../../store/models/recordingSession";
import User from "../../../store/models/user";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import {
  Button,
  ButtonVariant,
  RegularButtonProps,
} from "../../core-ui/components/Button/Button";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";
import { PopoverFooter } from "../../core-ui/components/PopConfirm/PopConfirm.styles";
import { Text } from "../../core-ui/components/Text/Text";
import {
  CancelProjectContent,
  useCancelProject,
} from "../CancelProjectModal/CancelProjectModal";
import {
  ExtendRecordingSessionActionStep,
  ExtendSessionContent,
  useExtendSession,
} from "../ExtendSessionModal/ExtendSessionModal";
import {
  RescheduleSessionContent,
  useRescheduleSession,
} from "../RescheduleSessionModal/RescheduleSessionModal";
import {
  SessionExtensionScreenBody,
  SessionModificationPopoverContentContainer,
  StyledSessionModificationContainer,
} from "./SessionDetailsPanel.styles";

export enum SESSION_MODIFICATION_OPTION {
  NO_OPTION,
  EXTEND_SESSION,
  RESCHEDULE_SESSION,
  CANCEL_SESSION,
}

enum ButtonType {
  OK,
  CANCEL,
}

interface SessionModificationPopoverContentProps {
  selectedOption: SESSION_MODIFICATION_OPTION;
  setSelectedOption: (_: SESSION_MODIFICATION_OPTION) => void;
  recordingSession: RecordingSession;
  closePopover: () => void;
  isArtist: boolean;
  currentUser: User;
  sessionModificationDetails: SessionModificationDetails;
}

export const SessionModificationPopoverContent = ({
  selectedOption,
  setSelectedOption,
  recordingSession,
  closePopover,
  isArtist,
  currentUser,
  sessionModificationDetails,
}: SessionModificationPopoverContentProps) => {
  const cancelSessionModification = () => {
    setSelectedOption(SESSION_MODIFICATION_OPTION.NO_OPTION);
    closePopover();
  };

  const {
    sessionExtensionRequest,
    sessionRescheduledRequest,
    userHasPendingRequest,
    sessionHasPendingModificationRequest,
  } = sessionModificationDetails;

  const acceptRejectSessionModificationLoading = useAppSelector(
    (state) =>
      state.recordingSessionReducer.acceptRejectSessionModificationLoading,
  );

  const dispatch = useAppDispatch();
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  const isRescheduleSessionDisabled = () => {
    if (
      recordingSession.studio_room &&
      (!recordingSession.studio_room.recording_service ||
        recordingSession.studio_room.recording_service.not_accepting_bookings)
    ) {
      return true;
    }

    if (recordingSession.is_engineer_accepting_bookings === false) {
      return true;
    }

    return sessionHasPendingModificationRequest;
  };

  /**
   * Session Extension components
   */
  const {
    actionStep: sessionExtensionStep,
    amountInDollars: sesionExtensionCost,
    createTransactionLoading: createSessionExtensionTransactionLoading,
    submittingPayment: submittingSessionExtensionPayment,
    handleSubmit: handleSubmitSessionExtension,
    ...restSessionExtensionProps
  } = useExtendSession(recordingSession, cancelSessionModification);

  const renderSessionExtensionScreen = () => {
    return (
      <SessionExtensionScreenBody>
        <ExtendSessionContent
          recordingSession={recordingSession}
          actionStep={sessionExtensionStep}
          {...restSessionExtensionProps}
        />
      </SessionExtensionScreenBody>
    );
  };
  /**
    End of Session Extension components
  */

  /**
   * Session Reschedule components
   */
  const {
    handleRescheduleSession,
    isDisabled: isRescheduleOkButtonDisabled,
    rescheduleLoading,
    ...restSessionRescheduleProps
  } = useRescheduleSession(
    recordingSession,
    cancelSessionModification,
    async (updatedRecordingSession) => {
      dispatch(
        updateAvailabilityCache([
          {
            ...recordingSession,
            markAsAvailable: true,
          },
          {
            ...updatedRecordingSession,
            markAsAvailable: false,
          },
        ]),
      );
      void dispatch(fetchPaginatedRecordingSessions());
    },
  );

  const renderSessionRescheduleScreen = () => {
    return (
      <RescheduleSessionContent
        recordingSession={recordingSession}
        {...restSessionRescheduleProps}
      />
    );
  };
  /**
   * End of Session Reschedule components
   */

  /**
   * Session Cancellation components
   */
  const {
    cancelling,
    loading: cancelContentLoading,
    handleCancelSession,
    ...restSessionCancellationProps
  } = useCancelProject(recordingSession);

  const renderSessionCancellationScreen = () => {
    return (
      <CancelProjectContent
        recordingSession={recordingSession}
        loading={cancelContentLoading}
        {...restSessionCancellationProps}
      />
    );
  };
  /**
   * End of Session Cancellation components
   */

  const handleAcceptRejectModification = async (accept: boolean) => {
    const { id: projectId } = recordingSession.project;

    let updatedRecordingSession: RecordingSession;
    try {
      if (accept) {
        updatedRecordingSession = await dispatch(
          acceptSessionModification({
            project_id: projectId,
          }),
        ).unwrap();
      } else {
        updatedRecordingSession = await dispatch(
          rejectSessionModification({
            project_id: projectId,
          }),
        ).unwrap();
      }

      dispatch(
        updateAvailabilityCache([
          {
            ...recordingSession,
            markAsAvailable: true,
          },
          {
            ...updatedRecordingSession,
            markAsAvailable: false,
          },
        ]),
      );

      void dispatch(fetchPaginatedRecordingSessions());

      cancelSessionModification();
    } catch (error) {
      /* Errors from the request are already handled, so we don't need to handle it here */
    }
  };

  const handleConfirmMapping: Record<number, () => void> = {
    [SESSION_MODIFICATION_OPTION.EXTEND_SESSION]: handleSubmitSessionExtension,
    [SESSION_MODIFICATION_OPTION.RESCHEDULE_SESSION]: handleRescheduleSession,
    [SESSION_MODIFICATION_OPTION.CANCEL_SESSION]: handleCancelSession,
  };

  const okButtonTextMapping: Record<number, string> = {
    [SESSION_MODIFICATION_OPTION.EXTEND_SESSION]:
      sessionExtensionStep === ExtendRecordingSessionActionStep.SELECT_DURATION
        ? "Extend Session"
        : `Checkout (${PennyDollarFormatter().format(sesionExtensionCost)})`,
    [SESSION_MODIFICATION_OPTION.RESCHEDULE_SESSION]: isArtist
      ? "Request Reschedule"
      : "Confirm Reschedule",
    [SESSION_MODIFICATION_OPTION.CANCEL_SESSION]: "Confirm",
  };

  const buttonPropsMapping: Record<
    number,
    Record<number, RegularButtonProps>
  > = {
    [SESSION_MODIFICATION_OPTION.EXTEND_SESSION]: {
      [ButtonType.OK]: {
        loading:
          createSessionExtensionTransactionLoading ||
          submittingSessionExtensionPayment,
        disabled:
          createSessionExtensionTransactionLoading ||
          submittingSessionExtensionPayment,
      },
      [ButtonType.CANCEL]: {
        loading:
          createSessionExtensionTransactionLoading ||
          submittingSessionExtensionPayment,
        disabled:
          createSessionExtensionTransactionLoading ||
          submittingSessionExtensionPayment,
      },
    },
    [SESSION_MODIFICATION_OPTION.RESCHEDULE_SESSION]: {
      [ButtonType.OK]: {
        loading: rescheduleLoading,
        disabled: isRescheduleOkButtonDisabled || rescheduleLoading,
      },
      [ButtonType.CANCEL]: {
        loading: rescheduleLoading,
        disabled: rescheduleLoading,
      },
    },
    [SESSION_MODIFICATION_OPTION.CANCEL_SESSION]: {
      [ButtonType.OK]: {
        loading: cancelling || cancelContentLoading,
        disabled: cancelling || cancelContentLoading,
      },
      [ButtonType.CANCEL]: {
        loading: cancelling,
        disabled: cancelling,
      },
    },
  };

  const renderNoStepScreen = () => {
    return (
      <StyledSessionModificationContainer>
        {sessionHasPendingModificationRequest && (
          <div>
            {sessionRescheduledRequest && (
              <Text>
                Proposed Reschedule: {sessionRescheduledRequest.proposedDate} at{" "}
                {sessionRescheduledRequest.proposedTime}
              </Text>
            )}
            {sessionExtensionRequest && (
              <>
                <Text>
                  Extend session by:{" "}
                  {sessionExtensionRequest.proposedExtensionDuration}
                </Text>
                <Text>
                  New session details:{" "}
                  {sessionExtensionRequest.proposedExtensionDate}{" "}
                  {sessionExtensionRequest.proposedExtensionStartTime} -{" "}
                  {sessionExtensionRequest.proposedExtensionEndTime}
                </Text>
              </>
            )}
          </div>
        )}
        {userHasPendingRequest && (
          <>
            <PopConfirm
              title="Rejecting the proposal"
              description="Are you sure you want to reject this proposal?"
              onConfirm={() => handleAcceptRejectModification(false)}
              wrapperElement={containerElement}
            >
              <Button
                loading={acceptRejectSessionModificationLoading}
                disabled={acceptRejectSessionModificationLoading}
                variant={ButtonVariant.OUTLINED}
              >
                {sessionRescheduledRequest
                  ? "Reject proposed date"
                  : sessionExtensionRequest
                    ? "Reject proposed extension"
                    : "Reject"}
              </Button>
            </PopConfirm>
            <Button
              loading={acceptRejectSessionModificationLoading}
              disabled={acceptRejectSessionModificationLoading}
              onClick={() => {
                void handleAcceptRejectModification(true);
              }}
              variant={ButtonVariant.PRIMARY}
            >
              {sessionRescheduledRequest
                ? "Accept proposed date"
                : sessionExtensionRequest
                  ? "Accept proposed extension"
                  : "Accept"}
            </Button>
          </>
        )}
        {!sessionHasPendingModificationRequest && (
          <>
            {isArtist && (
              <Button
                disabled={recordingSession.pending_booking_acceptance}
                disableText="You're only allowed to extend the session if all parties accepted"
                variant={ButtonVariant.OUTLINED}
                onClick={() => {
                  emitAnalyticsTrackingEvent(
                    "extend_session_button_clicked",
                    {},
                    currentUser?.id,
                  );
                  setSelectedOption(SESSION_MODIFICATION_OPTION.EXTEND_SESSION);
                }}
              >
                Extend Session
              </Button>
            )}
            <Button
              variant={ButtonVariant.OUTLINED}
              onClick={() => {
                emitAnalyticsTrackingEvent(
                  "reschedule_session_button_clicked",
                  {},
                  currentUser?.id,
                );
                setSelectedOption(
                  SESSION_MODIFICATION_OPTION.RESCHEDULE_SESSION,
                );
              }}
              disabled={isRescheduleSessionDisabled()}
            >
              Reschedule Session
            </Button>
          </>
        )}

        {recordingSession.cancellation_policy && (
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "cancel_session_button_clicked",
                {},
                currentUser?.id,
              );
              setSelectedOption(SESSION_MODIFICATION_OPTION.CANCEL_SESSION);
            }}
          >
            Cancel Session
          </Button>
        )}
      </StyledSessionModificationContainer>
    );
  };

  const optionScreenMapping = [
    renderNoStepScreen,
    renderSessionExtensionScreen,
    renderSessionRescheduleScreen,
    renderSessionCancellationScreen,
  ];

  return (
    <SessionModificationPopoverContentContainer>
      {optionScreenMapping[selectedOption]()}
      {selectedOption !== SESSION_MODIFICATION_OPTION.NO_OPTION && (
        <PopoverFooter>
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={cancelSessionModification}
            {...buttonPropsMapping[selectedOption]?.[ButtonType.CANCEL]}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmMapping[selectedOption]}
            {...buttonPropsMapping[selectedOption]?.[ButtonType.OK]}
          >
            {okButtonTextMapping[selectedOption]}
          </Button>
        </PopoverFooter>
      )}
    </SessionModificationPopoverContentContainer>
  );
};
