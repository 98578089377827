import { useMemo } from "react";
import { ProjectType } from "../../../../store/models/project";
import { getServiceFromServiceType } from "../../../../store/utils/serviceUtils";
import { EditableTextArea } from "../../../elements/EditableTextArea/EditableTextArea";
import { StyledForm } from "./ServicesTab.styles";

interface ServiceDescriptionTabProps {
  description: string;
  setDescription: (description: string) => void;
  selectedServiceType: ProjectType;
}

export const ServiceDescriptionTab = ({
  setDescription,
  description,
  selectedServiceType,
}: ServiceDescriptionTabProps) => {
  const serviceName = useMemo(() => {
    let suffix = "";
    if (selectedServiceType === ProjectType.RECORDING) {
      suffix = " (hourly)";
    }
    return getServiceFromServiceType(selectedServiceType, true) + suffix;
  }, [selectedServiceType]);

  return (
    <StyledForm>
      <label htmlFor="service-description">
        Write a description for {serviceName}
      </label>
      <EditableTextArea
        initialValue={description ?? ""}
        editMode={true}
        placeholder={`Service description will be shown to clients when they click on "what's included" for this service card`}
        numberOfLines={8}
        textAreaProps={{
          id: "service-description",
        }}
        onChange={setDescription}
      />
    </StyledForm>
  );
};
