import {
  BudgetBlock,
  BudgetRow,
  ProgressBlockKeyText,
  ProgressBlockValueText,
} from "./ProjectHeader.styles";
import { DetailedPurchaseOrder } from "../../../../store/models/project";

interface BudgetBlockComponentProps {
  purchaseOrder: DetailedPurchaseOrder;
}

const BudgetBlockComponent = ({ purchaseOrder }: BudgetBlockComponentProps) => {
  return (
    <BudgetBlock>
      <BudgetRow>
        <ProgressBlockKeyText style={{ textDecoration: "underline" }}>
          Project Team:
        </ProgressBlockKeyText>
      </BudgetRow>
      <BudgetRow>
        <ProgressBlockKeyText>Budget Approvals</ProgressBlockKeyText>
        <ProgressBlockValueText>
          &#40;
          {purchaseOrder?.required_approvals.filter((approval) =>
            Boolean(approval.accepted),
          ).length / purchaseOrder?.required_approvals.length}
          &#41;
        </ProgressBlockValueText>
      </BudgetRow>
      <BudgetRow>
        <ProgressBlockKeyText>Project Administrators</ProgressBlockKeyText>
        <ProgressBlockValueText>
          &#40;{purchaseOrder?.budget_managers.length}&#41;
        </ProgressBlockValueText>
      </BudgetRow>
      <BudgetRow>
        <ProgressBlockKeyText>Collaborators</ProgressBlockKeyText>
        <ProgressBlockValueText>
          &#40;
          {purchaseOrder.collaborators_list.length +
            purchaseOrder.collaborators_users.length}
          &#41;
        </ProgressBlockValueText>
      </BudgetRow>
    </BudgetBlock>
  );
};

export default BudgetBlockComponent;
