import React from "react";
import { useAppSelector } from "../../../store/hooks";
import ChatInjector from "./ChatInjector";
import useChatUserData from "./useChatUserData";

export interface ChatInjectorParams {
  children?: React.ReactNode;
}
const ChatSuspense = ({ children = null }: ChatInjectorParams) => {
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const userData = useChatUserData();
  const token = loggedInUser?.stream_token ?? "";
  return (
    <ChatInjector token={token} userData={userData}>
      {children}
    </ChatInjector>
  );
};

export default ChatSuspense;
