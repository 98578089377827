import { styled } from "styled-components";

export const AltListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 16px);
  background-color: inherit;
  color: ${({ theme }) => theme.textPrimaryColor};
`;

export const AltListTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
`;

export const AltListText = styled.p<{
  weight?: boolean;
}>`
  font-size: ${({ theme }) => theme.textSizeSm};
  font-weight: ${({ weight }) => (weight ? "600" : "400")};
`;
