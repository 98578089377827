import {
  discountValueToLabelMap,
  PredefinedDiscountOptions,
} from "../actions/mixMasterCartsStore";
import { GetItemizedTransactionResponse } from "../actions/transactions";
import { Transaction } from "../models/transaction";

interface DiscountsOptions {
  predefinedDiscountOption: PredefinedDiscountOptions | null;
  providerAssumesFees: boolean;
}

export const getCartDiscountStatusAndValuesFromItemizedTransaction = (
  transaction: GetItemizedTransactionResponse,
) => {
  const {
    subtotal,
    total,
    fees,
    discount_or_surcharge_value: discountOrSurchargeValue,
    discount_or_surcharge_percentage: discountOrSurchargePercentage,
    overwrite_price: overwritePrice,
  } = transaction;
  const discountOptions: DiscountsOptions = {
    predefinedDiscountOption: null,
    providerAssumesFees: false,
  };

  const providerAssumesFeesStatus = overwritePrice
    ? overwritePrice === total
    : subtotal + discountOrSurchargeValue + fees !== total;
  if (discountOrSurchargePercentage) {
    const actualDiscountPercentage = !providerAssumesFeesStatus
      ? discountOrSurchargePercentage
      : ((total - subtotal) / subtotal) * 100;
    const predefinedDiscountOption = discountValueToLabelMap.get(
      actualDiscountPercentage,
    );
    if (predefinedDiscountOption) {
      discountOptions.predefinedDiscountOption = predefinedDiscountOption;
      discountOptions.providerAssumesFees = providerAssumesFeesStatus;
    } else {
      discountOptions.predefinedDiscountOption = null;
      discountOptions.providerAssumesFees = providerAssumesFeesStatus;
    }
  }
  return discountOptions;
};

export const getCartDiscountStatusAndValuesFromTransaction = (
  transaction: Transaction,
): DiscountsOptions => {
  const {
    total_price: totalPrice,
    fees_collected: feesCollected,
    overwrite_price,
    original_price,
  } = transaction;
  const overwritePrice = Number(overwrite_price);
  const originalPrice = Number(original_price);

  let providerAssumesFees =
    totalPrice + (feesCollected ?? 0) === (overwritePrice ?? originalPrice);

  let predefinedDiscountOption = null;
  if (totalPrice < originalPrice) {
    const discountPercentage =
      ((totalPrice - originalPrice) / originalPrice) * 100;
    const option = discountValueToLabelMap.get(discountPercentage);
    if (option) {
      predefinedDiscountOption = option;
    }

    if (!option && !providerAssumesFees) {
      const price = totalPrice + (feesCollected ?? 0);
      const discountPercentage =
        ((price - originalPrice) / originalPrice) * 100;
      const option = discountValueToLabelMap.get(discountPercentage);
      if (option) {
        predefinedDiscountOption = option;
        providerAssumesFees = true;
      }
    }
  }

  return { providerAssumesFees, predefinedDiscountOption };
};
