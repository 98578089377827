import { SelectedProfileState } from "../store/actions/selectedProfile";

export const isStudioSeparateStripeEnabled = (
  selectedProfile: SelectedProfileState,
) => {
  const isStudioStripeOptedIn = Boolean(
    selectedProfile?.studio?.separate_stripe_account_opt_in,
  );
  const isStudioStripeAccountId = Boolean(
    selectedProfile?.studio?.stripe_account_id,
  );
  return isStudioStripeOptedIn && isStudioStripeAccountId;
};
