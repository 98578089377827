import styled from "styled-components";

export const VerifiedIdentityHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.textPrimaryColor};
`;

export const StudioActionsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 30px;
  margin: 15px 0 45px 0;
`;
