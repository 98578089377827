import { ChangeEvent, FormEvent, useState } from "react";
import { uploadFileLink } from "../../../store/actions/fileVersions";
import { useAppDispatch } from "../../../store/hooks";
import { Alt } from "../../../store/models/alts";
import { ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  FileLinkInput,
  FileLinkInputContainer,
  FileLinkSubmitButton,
} from "./FileLinkInput.styles";

export interface FileLinkUploaderProps {
  projectId: number;
  reference: boolean;
  alt?: Alt;
  code?: string;
  disableUpload?: boolean;
}

export const FileLinkUploader = ({
  projectId,
  reference,
  code,
  alt = Alt.MAIN,
  disableUpload = false,
}: FileLinkUploaderProps) => {
  const dispatch = useAppDispatch();
  const [link, setLink] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!disableUpload) {
      setIsLoading(true);
      void dispatch(
        uploadFileLink({
          project_id: projectId,
          alt: alt,
          reference,
          code,
          file_link: link,
        }),
      )
        .unwrap()
        .then(() => setLink(""))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <FileLinkInputContainer onSubmit={handleSubmit}>
      <FileLinkInput
        type="text"
        placeholder={"and/or paste a download link to your files here"}
        value={link}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setLink(e.target.value)}
        disabled={disableUpload}
      />
      <FileLinkSubmitButton
        variant={ButtonVariant.PRIMARY}
        type="submit"
        disabled={disableUpload}
        loading={isLoading}
      >
        Add
      </FileLinkSubmitButton>
    </FileLinkInputContainer>
  );
};
