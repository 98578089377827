import { useState } from "react";
import { toast } from "react-toastify";
import { emailRegex } from "../store/utils/signUpUtils";

export const useBillingInfoSubmitters = (
  initialBillingInfoSubmitter: string[] = [],
) => {
  const [billingInfoSubmitters, setEmails] = useState<string[]>(
    initialBillingInfoSubmitter,
  );

  const handleBillingInfoSubmitterEmailChange = (emails: string[]) => {
    if (emails.some((email) => !emailRegex.test(email))) {
      const invalidEmail = emails.find((email) => !emailRegex.test(email));
      return toast.error(`${invalidEmail} is not a valid email`);
    }
    const uniqueEmails = [...new Set(emails)];
    if (uniqueEmails.length !== emails.length) {
      return toast.error("Duplicate emails are not allowed");
    }
    const cleanedEmails = emails.map((email) => email.trim().toLowerCase());
    setEmails(cleanedEmails);
  };

  const handleRemoveBillingInfoSubmitterEmail = (email: string) => {
    const newEmails = billingInfoSubmitters.filter((e) => e !== email);
    setEmails(newEmails);
  };

  return {
    billingInfoSubmitters,
    handleBillingInfoSubmitterEmailChange,
    handleRemoveBillingInfoSubmitterEmail,
  };
};
