import styled from "styled-components";
import { Button } from "../../../core-ui/components/Button/Button";

export const UploadedFileListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 575px;
  margin-bottom: 1rem;
`;

export const UploadedFileListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const FileStatus = styled.div<{
  $uploaded?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  min-width: 110px;
  gap: 6px;
  font-weight: ${({ theme }) => theme.textWeightBold};
  color: ${({ theme, $uploaded }) =>
    $uploaded ? theme.colorPalette.Green600 : theme.colorPalette.BoxyYellow500};
}`;

export const PopOverIconButton = styled(Button)`
  color: ${({ theme, disabled }) =>
    disabled ? theme.textTertiaryColor : theme.textPrimaryColor};
  min-height: unset;
  padding: 6px;
`;
