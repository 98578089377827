import { useState } from "react";
import {
  fetchFileComments,
  updateFileComment,
} from "../../../store/actions/fileVersionComments";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { FileVersionComment } from "../../../store/models/fileVersion";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { ButtonVariant } from "../../core-ui/components/Button/Button";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";
import { FileCommentChatMessageButton } from "./TrackTableComments.styles";

interface TrackTableDeleteCommentButtonProps {
  comment: FileVersionComment;
  code: string | undefined;
  projectId: number;
}

export const TrackTableDeleteCommentButton = ({
  comment,
  code,
  projectId,
}: TrackTableDeleteCommentButtonProps) => {
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const [loadingMarkDeleted, setLoadingMarkDeleted] = useState(false);
  const dispatch = useAppDispatch();
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  const handleMarkDeleted = async (
    commentId: number,
    fileVersionId: number,
  ) => {
    setLoadingMarkDeleted(true);
    await dispatch(
      updateFileComment({
        code,
        file_version_id: fileVersionId,
        file_version_comment_id: commentId,
        delete: true,
      }),
    )
      .unwrap()
      .then(() => {
        void dispatch(fetchFileComments({ projectId: projectId, code }));
      })
      .finally(() => {
        setLoadingMarkDeleted(false);
      });
  };

  // Only show delete button for logged in comment author or if its not resolved
  if (
    !loggedInUser ||
    comment.author_user?.id !== loggedInUser.id ||
    comment.resolved
  ) {
    return null;
  }

  return (
    <PopConfirm
      side="top"
      title="Are you sure?"
      description="This will permanently delete the comment."
      onConfirm={() => handleMarkDeleted(comment.id, comment.file_version_id)}
      wrapperElement={containerElement}
    >
      <FileCommentChatMessageButton
        disabled={loadingMarkDeleted}
        variant={ButtonVariant.GHOST}
      >
        Delete
      </FileCommentChatMessageButton>
    </PopConfirm>
  );
};
