import { useMutation } from "@tanstack/react-query";
import { displayEngineEarsError } from "../../api/helpers";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError } from "../../store/actions/errorStore";
import { EngineerSchedulingSurvey } from "../../store/models/service";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { SCHEDULING_SURVEY } from "../../store/utils/routes";

export const useUpdateSchedulingSurveyAnswers = () => {
  return useMutation({
    mutationKey: [QUERY_KEYS.UPDATE_SCHEDULING_SURVEY_ANSWERS],
    mutationFn: async (args: EngineerSchedulingSurvey) => {
      const result =
        await makeBackendPostCallWithJsonResponse<EngineerSchedulingSurvey>(
          SCHEDULING_SURVEY,
          args,
        );
      if (result.success) {
        return result.resultJson;
      }

      throw result.resultJson;
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      return error;
    },
  });
};
