import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import {
  chatModalChannelIdAtom,
  chatModalDisplayAtom,
  closeChatModalAtom,
} from "./atoms";
import {
  Channel,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from "stream-chat-react";
import "./ChatModal.css";
import "../ChatRoom/ChatRoom.css";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import useExistingChannel from "../../../hooks/chatHooks/useExistingChannel";

const ChatModal = () => {
  const showModal = useAtomValue(chatModalDisplayAtom);
  const channelId = useAtomValue(chatModalChannelIdAtom);
  const closeModal = useSetAtom(closeChatModalAtom);
  const { data: channel } = useExistingChannel({ channelId });
  const location = useLocation();

  // This effect closes the chat when the page changes
  useEffect(() => {
    closeModal();
  }, [closeModal, location.pathname]);

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const modalTitle = useMemo(() => {
    if (!channel) return "Chat";
    return Object.keys(channel.state.members).length > 2
      ? "Group Message"
      : "Direct Message";
  }, [channel]);

  return (
    <div>
      <div
        className={classNames({
          "project-chat-panel": true,
          show: showModal && Boolean(channel),
        })}
      >
        <Channel channel={channel}>
          <Window>
            <div className="project-chat-header">
              <p className="b2-semi-bold">{modalTitle}</p>
              <div className="x-icon-container" onClick={handleClose}>
                <FontAwesomeIcon icon={faX} />
              </div>
            </div>
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      </div>
    </div>
  );
};

export default ChatModal;
