import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useModal from "../../../../../../hooks/useModal";
import { updateUserStudioManager } from "../../../../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { ProjectType } from "../../../../../../store/models/project";
import User from "../../../../../../store/models/user";
import {
  selectIsArtistPrimaryAccountType,
  selectIsCurrentUserEngineerSelector,
  selectIsUserStudioManager,
} from "../../../../../../store/selectors/userInfoSelectors";
import { getProfileScreenRoute } from "../../../../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../../../../utils/analyticsUtils";
import { BaseModal } from "../../../../../core-ui/components/BaseModal/BaseModal";
import {
  NAV_LIST_ITEM_VARIANT,
  NavDropDownListItem,
} from "../NavDropDownListItem/NavDropDownListItem";
import { useSetAtom } from "jotai";
import { authModalOpenAtom, currentFlowAtom } from "../../../../Auth/atoms";
import { AuthFlows } from "../../../../../../constants/authSteps";
import { useMediaQueryBreakpoint } from "../../../../../../hooks/useMediaQuery";
import { SCREENS } from "../../../../../../routes";
import { useEngineerCanHostServices } from "../../../../../../hooks/useEngineerCanHostServices";

export interface NavAddStudioProfileProps {
  userId: User["id"];
  onClose: () => void;
}

export const NavAddStudioProfileButton = ({
  userId,
  onClose,
}: NavAddStudioProfileProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isArtistPrimaryAccountType = useAppSelector(
    selectIsArtistPrimaryAccountType,
  );
  const isUserStudioManager = useAppSelector(selectIsUserStudioManager);
  const isUserEngineer = useAppSelector(selectIsCurrentUserEngineerSelector);
  const {
    user: loggedInUser,
    meetsStripeRequirements,
    userStudios,
    userStudiosLoading,
  } = useAppSelector((state) => state.accountInfo);
  const {
    isOpen: isStudioOwnerModalOpen,
    closeModal: closeStudioOwnerModal,
    setIsOpen: setStudioOwnerModalIsOpen,
  } = useModal();
  const setFlow = useSetAtom(currentFlowAtom);
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const { isMobile } = useMediaQueryBreakpoint();
  const canHostServices = useEngineerCanHostServices(loggedInUser?.engineer);

  const showAddStudioProfileOption =
    loggedInUser != null &&
    !isArtistPrimaryAccountType &&
    userStudios.length === 0 &&
    !userStudiosLoading;

  if (!showAddStudioProfileOption) {
    return null;
  }

  const isOnProfileScreen =
    history.location.pathname === getProfileScreenRoute(loggedInUser.username);

  const onClickAddStudioProfile = () => {
    if (isUserStudioManager) {
      if (isMobile) history.push(SCREENS.AUTH_SCREEN);
      setFlow(AuthFlows.ADD_STUDIO);
      setAuthModalOpen(true);
      return;
    } else {
      setStudioOwnerModalIsOpen(true);
    }
  };

  const onClickIAmNotStudioOwner = () => {
    if (!isUserEngineer) {
      closeStudioOwnerModal();
      return;
    }

    // logged in user is an engineer. if they have a recording service, open the studio creation modal.
    if (loggedInUser.engineer?.has_active_recording_service) {
      closeStudioOwnerModal();
      setFlow(AuthFlows.ADD_STUDIO);
      setAuthModalOpen(true);
      return;
    }

    if (!canHostServices) {
      toast.info("Adding a studio profile is not available for your account.");
      closeStudioOwnerModal();
      return;
    }

    if (!meetsStripeRequirements) {
      if (!isOnProfileScreen) {
        history.push(getProfileScreenRoute(loggedInUser.username));
      }
      toast.info(
        "Please connect your account with Stripe in order to add a studio profile.",
      );
      closeStudioOwnerModal();
      return;
    }

    // prompt to add recording service
    // redirect to profile screen if necessary
    const navigationMethod = isOnProfileScreen ? history.replace : history.push;
    navigationMethod(
      `${getProfileScreenRoute(loggedInUser.username)}?add_new_service=${ProjectType.RECORDING}`,
    );
    toast.info(
      "Please add a recording service in order to add a studio profile.",
    );
    closeStudioOwnerModal();
  };

  const onClickIAmStudioOwner = () => {
    // If the user indicates that they own or manage a studio, add the studio manager account type
    // to their account and open the add a studio modal.
    setIsLoading(true);
    dispatch(
      updateUserStudioManager({
        is_primary_type: false,
        deleted: false,
      }),
    )
      .unwrap()
      .then(() => {
        closeStudioOwnerModal();
        if (isMobile) history.push(SCREENS.AUTH_SCREEN);
        setFlow(AuthFlows.ADD_STUDIO);
        setAuthModalOpen(true);
      })
      .catch()
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <NavDropDownListItem
        text="Add a Studio Profile"
        variant={NAV_LIST_ITEM_VARIANT.BUTTON}
        onClick={() => {
          emitAnalyticsTrackingEvent("clicked_add_studio_profile", {}, userId);
          onClickAddStudioProfile();
          onClose();
        }}
      />
      <BaseModal
        showModalFooter
        cancelText="No"
        confirmText="Yes"
        setOpen={setStudioOwnerModalIsOpen}
        open={isStudioOwnerModalOpen}
        header="Are you a studio owner?"
        onCancel={onClickIAmNotStudioOwner}
        onConfirm={onClickIAmStudioOwner}
        loading={isLoading}
      >
        Do you manage or own a studio?
      </BaseModal>
    </>
  );
};
