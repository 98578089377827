import { useAppSelector } from "../../store/hooks";
import Engineer from "../../store/models/engineer";
import { BasicStudio, Studio } from "../../store/models/studio";

/**
 * This hook determines how the logged in user is related to the Recording Session
 * based on the `Studio` and `Engineer` models passed in.
 * The user may be a Studio Manager, Engineer, Engineer with Permission, or Not Affiliated.
 */
export const useRecordingSessionUserType = (
  studio: Studio | BasicStudio | undefined,
  engineer: Engineer | undefined,
) => {
  const user = useAppSelector((state) => state.accountInfo?.user);
  if (!user) {
    return {
      isStudioManager: false,
      isEngineer: false,
      isEngineerWithPermission: false,
      isNotAffiliated: true,
    };
  }

  const isStudioManager = Boolean(
    user?.studio_manager && !user.studio_manager.deleted,
  );
  const isUserSameAsStudioManager =
    isStudioManager &&
    Boolean(
      studio?.studio_team?.managers?.some((member) => member.id === user.id),
    );

  const isUserSameAsEngineer = user.id === engineer?.user_id;

  const hasFullPermissions = studio
    ? Boolean(studio.affiliated_engineer_booking_links_enabled)
    : true;

  return {
    isStudioManager: isUserSameAsStudioManager,
    isEngineer: isUserSameAsEngineer,
    isEngineerWithPermission: isUserSameAsEngineer && hasFullPermissions,
    isNotAffiliated: !isUserSameAsStudioManager && !isUserSameAsEngineer,
  };
};
