import { useRef } from "react";
import { Crop } from "react-image-crop";
import { computeCropDimensions } from "../utils/utils";

export const useDrawOnCanvas = (targetDimension: {
  width: number;
  height: number;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>();
  const { width, height } = targetDimension;

  const drawOnCanvas = (sourceImage: HTMLImageElement, crop?: Crop) => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      ctx?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    } else {
      // Create a hidden canvas to draw on so we can perform cropping
      canvasRef.current = document.createElement("canvas");
      canvasRef.current.width = width;
      canvasRef.current.height = height;
      canvasRef.current.style.display = "none";
    }

    const ctx = canvasRef.current.getContext("2d");

    if (!ctx) return;

    ctx.imageSmoothingQuality = "high";
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;

    let cropDimension = crop;
    if (!crop) {
      const { croppingWidth, croppingHeight } = computeCropDimensions(
        sourceImage,
        targetDimension,
      );

      cropDimension = {
        x: 0,
        y: 0,
        width: croppingWidth,
        height: croppingHeight,
        unit: "px",
      };
    }

    ctx.drawImage(
      sourceImage,
      cropDimension!.x * scaleX,
      cropDimension!.y * scaleY,
      cropDimension!.width * scaleX,
      cropDimension!.height * scaleY,
      0,
      0,
      width,
      height,
    );

    return canvasRef.current;
  };

  const generateFileFromCanvas = (): Promise<File | undefined> => {
    return new Promise((resolve, reject) => {
      if (!canvasRef?.current)
        return reject(
          new Error(
            "Canvas not found. Please call `drawOnCanvas` first to generate one!",
          ),
        );
      canvasRef.current.toBlob((blob) => {
        if (!blob) {
          return reject(new Error("There was an error handling the file"));
        }
        const file = new File([blob], "generated-photo.jpeg", {
          type: "image/jpeg",
        });

        resolve(file);
      }, "image/jpeg");
    });
  };

  const generateBase64FromCanvas = () => {
    if (!canvasRef.current) {
      throw new Error(
        "Canvas not found. Please call `drawOnCanvas` first to generate one!",
      );
    }

    return canvasRef.current.toDataURL("image/jpeg", 1);
  };

  return {
    canvasRef,
    drawOnCanvas,
    generateFileFromCanvas,
    generateBase64FromCanvas,
  };
};
