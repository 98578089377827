import { unwrapResult } from "@reduxjs/toolkit";
import { isAfter } from "date-fns";
import { History } from "history";
import React, { useCallback } from "react";
import { isMobile } from "react-device-detect";
import type { AppDispatch } from "../";
import { IsCurrentUserStep } from "../../hooks/useIsCurrentUserStep";
import { IsArtistUploadStep, IsListenStep } from "../../hooks/useIsUploadStep";
import { SCREENS } from "../../routes";
import { createScheduledProjectShareLink } from "../../store/actions/scheduledprojects";
import { getDisplayableNameForUser } from "../../store/utils/entityUtils";
import { isProjectOnAssetReviewStep } from "../../stories/components/CompleteTransitionView/utils/isProjectOnAssetReviewStep";
import { PlayAudioRow } from "../../stories/components/PlayAudioRow/PlayAudioRow";
import { ToolTipTextArea } from "../../stories/components/ToolTipTextArea/ToolTipTextArea";
import { TrackTable } from "../../stories/components/TrackTable/TrackTable";
import {
  Button,
  ButtonVariant,
} from "../../stories/core-ui/components/Button/Button";
import { PopConfirm } from "../../stories/core-ui/components/PopConfirm/PopConfirm";
import { EditableTextField } from "../../stories/elements/EditableTextField/EditableTextField";
import { Pill } from "../../stories/elements/Pill/Pill";
import { TimeLineProgressBar } from "../../stories/elements/TimeLineProgressBar/TimeLineProgressBar";
import { UserProfileImage } from "../../stories/elements/UserProfileImage/UserProfileImage";
import { ColorPalette } from "../../stories/theme";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { formatDateToShort } from "../../utils/formatDateToShort";
import {
  manageScheduledProject,
  ProjectManagementActions,
} from "../actions/booking";
import { downloadFinalFiles } from "../actions/projects";
import {
  removeFromState,
  updateState,
} from "../actions/scheduledProjectUpdateStore";
import { getProjectRequests } from "../actions/scheduledprojects";
import { toggleAssetReviewModal } from "../actions/userInterface";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  getStep,
  getStepActionLabel,
  getStepDescription,
  NUM_ATMOS_STEPS,
  NUM_MASTERING_STEPS,
  NUM_MIXING_STEPS,
  Project,
  ProjectType,
  projectTypeReadableName,
} from "../models/project";
import {
  EP_LENGTH,
  FULL_ALBUM_LENGTH,
  ScheduledProject,
} from "../models/scheduledproject";
import { convertUTCDateToLocalDate } from "./dateTimeUtils";
import { DollarFormatter, PennyDollarFormatter } from "./formatUtils";
import { getInProgressProjectRouteWithCode } from "./routeGetters";
import { checkIsMobile } from "./utils";

export const redirect = (
  id: number,
  history: History,
  isScheduledProject = false,
) => {
  if (isScheduledProject) {
    history.push(
      SCREENS.SCHEDULED_PROJECT_OVERVIEW_SCREEN.replace(
        ":scheduled_project_id",
        id.toString(),
      ),
    );
  } else if (history.location.pathname.includes("projects/sessions")) {
    history.push(
      SCREENS.RECORDING_SESSION.replace(":project_id", id.toString()),
    );
  } else {
    history.push(SCREENS.PROJECT.replace(":project_id", id.toString()));
  }
};

export const redirectToFullProject = (
  id: number,
  history: History,
  code?: string,
) =>
  history.push(
    SCREENS.SCHEDULED_PROJECT_OVERVIEW_SCREEN.replace(
      ":scheduled_project_id",
      id.toString(),
    ) + (code ? `?code=${code}` : ""),
  );

export const redirectToProjectUploadWithCode = (
  id: number,
  code: string,
  history: History,
) =>
  history.push(
    SCREENS.UPLOAD_SCREEN.replace(":project_id", id.toString()) +
      `?code=${code}&project_id=${id.toString()}`,
  );

export const redirectToProjectListenWithCode = (
  id: number,
  code: string,
  history: History,
) =>
  history.push(
    SCREENS.LOGGED_OUT_REVIEW.replace(":project_id", id.toString()) +
      `?code=${code}&project_id=${id.toString()}`,
  );

export const redirectToInProgressProjectWithCode = (
  id: number,
  code: string,
  history: History,
) => {
  history.push(getInProgressProjectRouteWithCode(id, code));
};

const getProjectNameFromNumberOfSongs = (
  scheduledProject: ScheduledProject,
) => {
  if (scheduledProject.projects.length >= FULL_ALBUM_LENGTH) {
    return "Full Album";
  }

  if (scheduledProject.projects.length >= EP_LENGTH) {
    return "EP";
  }

  if (scheduledProject.projects.length === 1) {
    return "Single";
  }

  return "Project";
};

export const getCountOfCompletedSteps = (
  scheduledProject: ScheduledProject,
  isLabelProject?: boolean,
) => {
  let completedSteps = 0;
  scheduledProject.projects.forEach((project) => {
    completedSteps +=
      getStep(project.step, project.service_type, isLabelProject) ?? 0;
  });
  return completedSteps;
};

export const getTotalStepsForProject = (
  isLabelProject: boolean,
  project: Project,
): number => {
  if (project.service_type === ProjectType.MASTERING) {
    return NUM_MASTERING_STEPS;
  } else if (project.service_type === ProjectType.ATMOS_MIXING) {
    return isLabelProject ? NUM_ATMOS_STEPS - 2 : NUM_ATMOS_STEPS;
  }
  return isLabelProject ? NUM_MASTERING_STEPS : NUM_MIXING_STEPS;
};

export const getTotalStepsInProject = (
  scheduledProject: ScheduledProject,
  isLabelProject?: boolean,
) => {
  let totalSteps = 0;
  scheduledProject.projects.forEach((project) => {
    totalSteps += getTotalStepsForProject(isLabelProject ?? false, project);
  });
  return totalSteps;
};

// returns whether a row is a Project or a ScheduledProject
export const determineIfProject = (
  row: Project | ScheduledProject,
): row is Project => {
  const rowAsProject = row as Project;
  if (
    rowAsProject !== undefined &&
    rowAsProject.last_transition !== undefined
  ) {
    return true;
  }
  return false;
};

const ProjectTitleCell: React.FC<any> = (
  props: {
    row: Project | ScheduledProject;
    history: any;
    trackNumber?: number;
  },
  isLabelProject: boolean,
) => {
  const { row } = props;
  const isScheduledProject = !determineIfProject(row);
  if (determineIfProject(row)) {
    isLabelProject = false;
  }
  if (isLabelProject) {
    return (
      <div className="individual-section is-label-project tooltip">
        <ToolTipTextArea text="Label Project">
          <p className="b2">
            {props.trackNumber ? props.trackNumber + ". " : ""}{" "}
            {isScheduledProject && row.artist_name
              ? `${row.artist_name} - `
              : ""}
            {row.title}
          </p>
        </ToolTipTextArea>
      </div>
    );
  }
  return (
    <div className={"individual-section"}>
      <p className="b2">
        {props.trackNumber ? props.trackNumber + ". " : ""}{" "}
        {isScheduledProject && row.artist_name ? `${row.artist_name} - ` : ""}
        {row.title}
      </p>
    </div>
  );
};

const UpdatableProjectTitleCell: React.FC<any> = (
  props: {
    row: Project | ScheduledProject;
    history: History;
    trackNumber?: number;
  },
  isLabelProject: boolean,
) => {
  const { isEditing, isUpdating } = useAppSelector(
    (state) => state.scheduledProjectUpdateStore,
  );
  const dispatch = useAppDispatch();
  const { row } = props;

  const handleEditedScheduledProjectName = useCallback(
    (title: string) => {
      if (isUpdating) return;
      if (row.title === title) {
        dispatch(removeFromState({ project_id: row.id, title }));
      } else {
        dispatch(updateState({ project_id: row.id, title }));
      }
    },
    [row, dispatch, isUpdating],
  );

  if (determineIfProject(row)) {
    isLabelProject = false;
  }
  if (isEditing) {
    return (
      <EditableTextField
        className={"update-project-title-text-field"}
        initialValue={row.title}
        label={"project name"}
        onChange={handleEditedScheduledProjectName}
        editMode={isEditing}
      />
    );
  }
  if (isLabelProject) {
    <div className={"individual-section is-label-project"}>
      <ToolTipTextArea text="Label Project">
        <p className="b2">
          {props.trackNumber ? props.trackNumber + ". " : ""} {row.title}
        </p>
      </ToolTipTextArea>
    </div>;
  }
  return (
    <div className={"individual-section"}>
      <p className="b2">
        {props.trackNumber ? props.trackNumber + ". " : ""} {row.title}
      </p>
    </div>
  );
};

const ProjectTypeCell: React.FC<any> = ({
  row,
  isInProgressProject,
}: {
  row: ScheduledProject | Project;
  isInProgressProject: boolean;
}) => {
  let projectType;
  let outstandingBalance = 0;
  const isProject = determineIfProject(row);
  if (isProject) {
    projectType = projectTypeReadableName.get(row.service_type)!;
  } else {
    outstandingBalance = row.outstanding_balance;
    projectType = getProjectNameFromNumberOfSongs(row);
  }
  const cost = isProject ? row.checkout_price : row.total_price;

  if (!isProject && outstandingBalance > 0) {
    return (
      <div
        className={
          "d-flex flex-column justify-content-center align-content-center"
        }
      >
        <p
          className="b2-semi-bold"
          style={{ marginBottom: "10px", width: "100%", textAlign: "center" }}
        >
          {projectType}
        </p>
        <Pill
          className="payment-status-pill"
          label={`pending: ${PennyDollarFormatter().format(
            outstandingBalance,
          )}`}
          backgroundColor={ColorPalette.BoomyOrange400}
          color={ColorPalette.White}
        />
      </div>
    );
  }

  if (!isProject && isInProgressProject) {
    return (
      <div
        className={
          "d-flex flex-column justify-content-center align-content-center"
        }
      >
        <p
          className="b2-semi-bold"
          style={{ marginBottom: "10px", width: "100%", textAlign: "center" }}
        >
          {projectType}
        </p>
        <Pill
          className="payment-status-pill"
          label="Project Not Funded"
          backgroundColor={ColorPalette.BoomyOrange400}
          color={ColorPalette.White}
        />
      </div>
    );
  }

  return (
    <div className={"individual-section project-type-column"}>
      <p className="b2-semi-bold" style={{ marginBottom: "10px" }}>
        {projectType}
      </p>
      <p className="b2" style={{ marginTop: 0 }}>
        {DollarFormatter().format(parseFloat(cost ? cost.toString() : "0"))}
      </p>
    </div>
  );
};

const CollaboratorCell: React.FC<any> = (
  props: { row: ScheduledProject | Project; history: History },
  userId: number,
) => {
  let projectUsers: number[] = [];
  if (!props.row) return null;
  if ("projects" in props.row) {
    projectUsers =
      props.row.hasOwnProperty("projects") && props.row.projects
        ? props.row.projects.reduce((userIds, project) => {
            const ids: number[] = project.users
              .map((user) => user.id)
              .filter((id) => {
                return !userIds.includes(id) && id !== userId;
              });

            return [...userIds, ...ids];
          }, [] as number[])
        : [];
  }

  let collaborators = props.row.users.filter(
    (project_user) => project_user.id !== userId,
  );

  if (projectUsers.length > 0) {
    collaborators = collaborators.filter((collaborator) =>
      projectUsers.includes(collaborator.id),
    );
  }

  if (!collaborators.length) return null;

  if (collaborators.length > 1 && props.row.hasOwnProperty("projects")) {
    const collaboratorsString =
      collaborators.length == 2
        ? `${collaborators.map((collaborator) => getDisplayableNameForUser(collaborator)).join(", ")}`
        : `${collaborators.length} Collaborators`;

    return (
      <div className={"individual-section"}>
        <p className="b2-semi-bold">{collaboratorsString}</p>
      </div>
    );
  }

  const collaborator = collaborators[0];

  return (
    <div className={"individual-section"}>
      <UserProfileImage
        username={collaborator.username}
        hideBorder={true}
        editMode={false}
        source={collaborator?.photo?.path}
        width={35}
        height={35}
        isCircle={true}
      />
      <p className="b2">{`${getDisplayableNameForUser(collaborator)}`}</p>
    </div>
  );
};

export const checkIfPastDue = (project: Project): [boolean, Date] => {
  if (!project.calculatedDueDate) return [false, new Date()];
  const dueDate = new Date(project.calculatedDueDate);
  return [isAfter(new Date(), dueDate), dueDate];
};

const ScheduledDueDateCell: React.FC<any> = (
  row: Project | ScheduledProject,
) => {
  if (determineIfProject(row)) {
    // determineIfProject is True, so row is a Project type
    // we really shouldn't need the determineIfProject function
    const projectRow = row;
    return (
      <div className="individual-section">
        <p className="b2">
          {formatDateToShort(
            projectRow.first_pass_date
              ? new Date(projectRow.first_pass_date)
              : convertUTCDateToLocalDate(new Date(row.created)),
          )}
        </p>
      </div>
    );
  } else {
    const scheduledProjectRow = row;
    const estimatedDueDate = scheduledProjectRow.estimated_delivery_date
      ? new Date(scheduledProjectRow.estimated_delivery_date)
      : convertUTCDateToLocalDate(new Date(scheduledProjectRow.created));
    return (
      <div className="individual-section">
        <p className="b2">{formatDateToShort(estimatedDueDate)}</p>
      </div>
    );
  }
};

const ProjectDueDateCell: React.FC<any> = (row: Project) => {
  return (
    <div className="individual-section">
      <p className="b2">
        {formatDateToShort(convertUTCDateToLocalDate(new Date(row.created)))}
      </p>
    </div>
  );
};

const ProjectCompletedDateCell: React.FC<any> = (row: Project) => {
  if (!row.completed) {
    return <div className="individual-section" />;
  }
  return (
    <div className="individual-section">
      <p className="b2">
        {formatDateToShort(convertUTCDateToLocalDate(new Date(row.completed)))}
      </p>
    </div>
  );
};

const EmptyColumnCell: React.FC<any> = () => {
  return <div className="individual-section" />;
};

const TimelineCell: React.FC<any> = (
  props: {
    row: Project | ScheduledProject;
    history: History;
  },
  isLabelProject: boolean,
) => {
  const { row } = props;
  if (determineIfProject(row)) {
    // determineIfProject is True, so row is a Project type
    // we really shouldn't need the determineIfProject function
    const projectRow = row;
    const [, dueDate] = checkIfPastDue(projectRow);
    let totalSteps = 0;
    switch (projectRow.service_type) {
      case ProjectType.MASTERING:
        totalSteps = NUM_MASTERING_STEPS;
        break;
      case ProjectType.ATMOS_MIXING:
        if (isLabelProject) {
          totalSteps = NUM_ATMOS_STEPS - 2;
        } else {
          totalSteps = NUM_ATMOS_STEPS;
        }
        break;
      case ProjectType.TWO_TRACK_MIXING:
      case ProjectType.MIXING:
        if (isLabelProject) {
          totalSteps = NUM_MASTERING_STEPS;
        } else {
          totalSteps = NUM_MIXING_STEPS;
        }
    }

    const taskDue = getStepDescription(
      projectRow.step,
      projectRow.service_type,
    )?.toLowerCase();
    const text = taskDue + " task ";

    return (
      <div className={"individual-section time-line-container"}>
        <TimeLineProgressBar
          pastDueText={text}
          showCountDown={true}
          isPastDue={false}
          dueDate={dueDate}
          currentStep={
            getStep(projectRow.step, projectRow.service_type, isLabelProject) ??
            0
          }
          totalSteps={totalSteps}
          projectType={projectRow.service_type}
          taskDue={taskDue}
          barColor={
            projectRow.assets_approved
              ? ColorPalette.DeepGreen
              : ColorPalette.BoomyOrange400
          }
        />
      </div>
    );
  } else {
    const scheduledProjectRow = row;
    return (
      <div className={"individual-section time-line-container"}>
        <TimeLineProgressBar
          currentStep={getCountOfCompletedSteps(
            scheduledProjectRow,
            isLabelProject,
          )}
          totalSteps={getTotalStepsInProject(
            scheduledProjectRow,
            isLabelProject,
          )}
          showSteps={false}
          barColor={ColorPalette.SkylightBlue}
        />
      </div>
    );
  }
};

const StepCell: React.FC<any> = (props: {
  row: Project | ScheduledProject;
  code?: string;
  dispatch?: AppDispatch;
}) => {
  const row = props.row;
  if (determineIfProject(row)) {
    // determineIfProject is True, so row is a Project type
    // we really shouldn't need the determineIfProject function
    const projectRow = row;
    return (
      <div className="individual-section">
        <p className="b2">
          {projectRow.assets_approved
            ? "Approved"
            : isProjectOnAssetReviewStep(
                  projectRow.step,
                  projectRow.service_type,
                )
              ? "Asset Approval"
              : getStepDescription(projectRow.step, projectRow.service_type)}
        </p>
      </div>
    );
  } else {
    return (
      <div className="individual-section">
        <p className="b2">{row.completed ? "Completed" : "In Progress"}</p>
      </div>
    );
  }
};

export function userIsEngineerOnProject(userId: number, project: Project) {
  return userId === project.engineer?.id;
}

const TakeAction: React.FC<any> = (
  props: {
    row: Project | ScheduledProject;
    history: History;
    dispatch: AppDispatch;
    // pass in the loading state value + setter as props,
    // as the current architecture breaks during rendering
    // with internal component state.
    isAdminShareLinkLoading: boolean;
    setIsAdminShareLinkLoading: (_: boolean) => void;
    code?: string;
    isInProgressProject?: boolean;
    handlePaymentFlow?: () => void;
  },
  userId: number,
) => {
  if (determineIfProject(props.row)) {
    // determineIfProject is True, so row is a Project type
    // we really shouldn't need the determineIfProject function
    const projectRow = props.row;
    if (userId === undefined) {
      const isUploadStep = IsArtistUploadStep(projectRow);
      const isListenStep = IsListenStep(projectRow);

      const isAssetReview = isProjectOnAssetReviewStep(
        projectRow.step,
        projectRow.service_type,
      );
      if (isUploadStep || isListenStep) {
        return (
          <div className={"individual-section action-button"}>
            <Button
              className={"table-button"}
              variant={ButtonVariant.PRIMARY}
              onClick={() => {
                if (isUploadStep) {
                  emitAnalyticsTrackingEvent(
                    "redirect_to_project_upload_with_code",
                    {
                      project_id: `${projectRow.id}`,
                    },
                  );
                  redirectToProjectUploadWithCode(
                    projectRow.id,
                    props.code!,
                    props.history,
                  );
                } else {
                  emitAnalyticsTrackingEvent(
                    "redirect_to_project_listen_with_code",
                    {
                      project_id: `${projectRow.id}`,
                    },
                  );
                  redirectToProjectListenWithCode(
                    projectRow.id,
                    props.code!,
                    props.history,
                  );
                }
              }}
            >
              {getStepActionLabel(projectRow.step, projectRow.service_type)!}
            </Button>
          </div>
        );
      } else if (isAssetReview) {
        return (
          <div
            className={"".concat(
              isAssetReview
                ? "asset-approval-action"
                : "individual-section action-button",
            )}
          >
            {isAssetReview && !projectRow.assets_approved ? (
              <PopConfirm
                title={"Asset Approval"}
                description={"Approve or request final asset re-uploads"}
                cancelText={"download files"}
                onCancel={() => {
                  if (props.isInProgressProject && props.handlePaymentFlow) {
                    props.handlePaymentFlow();
                  } else if (props.dispatch) {
                    props.dispatch(
                      downloadFinalFiles({
                        project_id: `${props.row.id}`,
                        code: props.code,
                      }),
                    );
                  }
                }}
                okText={"Submit QC"}
                onConfirm={() => {
                  if (props.isInProgressProject && props.handlePaymentFlow) {
                    props.handlePaymentFlow();
                  } else if (props.dispatch) {
                    props.dispatch(
                      toggleAssetReviewModal({
                        showAssetReviewModal: true,
                        project: props.row as Project,
                      }),
                    );
                  }
                }}
              >
                <Button
                  className={"table-button"}
                  variant={ButtonVariant.OUTLINED}
                >
                  Submit QC Result
                </Button>
              </PopConfirm>
            ) : (
              <Button
                className={"table-button"}
                variant={ButtonVariant.PRIMARY}
                onClick={() => {
                  if (props.isInProgressProject && props.handlePaymentFlow) {
                    props.handlePaymentFlow();
                  } else if (props.dispatch) {
                    props.dispatch(
                      downloadFinalFiles({
                        project_id: `${props.row.id}`,
                        code: props.code,
                      }),
                    );
                  }
                }}
              >
                Download files
              </Button>
            )}
          </div>
        );
      } else {
        return (
          <div className={"individual-section action-button"}>
            <p className="b2">No action to take</p>
          </div>
        );
      }
    }
    if (
      IsCurrentUserStep(
        userIsEngineerOnProject(userId, projectRow),
        projectRow.service_type,
        projectRow.step,
      )
    ) {
      return (
        <div className={"individual-section action-button"}>
          <Button
            className={"table-button"}
            variant={ButtonVariant.PRIMARY}
            style={{
              backgroundColor: projectRow.assets_approved
                ? ColorPalette.MintGreen500
                : ColorPalette.BoomyOrange400,
            }}
            onClick={() => {
              redirect(props.row.id, props.history);
            }}
          >
            {getStepActionLabel(projectRow.step, projectRow.service_type)!}
          </Button>
        </div>
      );
    } else {
      return (
        <div className={"individual-section action-button"}>
          <Button
            className={"table-button"}
            fullWidth={true}
            variant={ButtonVariant.PRIMARY}
            style={{
              backgroundColor: projectRow.assets_approved
                ? ColorPalette.MintGreen500
                : ColorPalette.BoomyOrange400,
            }}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "go_to_project_from_table",
                {
                  project_id: `${props.row.id}`,
                },
                userId,
              );

              redirect(props.row.id, props.history);
            }}
          >
            View Project
          </Button>
        </div>
      );
    }
  } else {
    const scheduledProjectRow = props.row;

    const onClick = async () => {
      emitAnalyticsTrackingEvent(
        "go_to_full_project_overview",
        {
          scheduled_project_id: scheduledProjectRow.id,
        },
        userId,
      );

      // Create a project overview share link if the current user is an admin
      // of the team members associated with this scheduled project.
      if (
        scheduledProjectRow.team_member_ids &&
        scheduledProjectRow.team_member_ids.length > 0
      ) {
        props.setIsAdminShareLinkLoading(true);
        try {
          const response = await props
            .dispatch(
              createScheduledProjectShareLink({
                scheduled_project_id: scheduledProjectRow.id.toString(),
              }),
            )
            .unwrap();
          redirectToFullProject(
            scheduledProjectRow.id,
            props.history,
            response[0].code,
          );
        } finally {
          props.setIsAdminShareLinkLoading(false);
        }
      } else {
        redirectToFullProject(scheduledProjectRow.id, props.history);
      }
    };
    return (
      <div className={"individual-section action-button"}>
        <Button
          className={"table-button"}
          fullWidth={isMobile}
          variant={ButtonVariant.PRIMARY}
          onClick={onClick}
          loading={props.isAdminShareLinkLoading}
        >
          View Full Project
        </Button>
      </div>
    );
  }
};

const TrackReviewCell: React.FC<any> = (row: Project) => {
  const isCompleted = Boolean(row.completed);
  let referenceTrack = true;
  if (isCompleted) {
    referenceTrack = false;
  }
  return (
    <div className={"individual-section track-container"}>
      <PlayAudioRow referenceTrack={referenceTrack} project={row} />
    </div>
  );
};

const ProjectDate: React.FC<any> = (row: Project) => {
  return (
    <div className={"individual-section"}>
      <p className="b2">
        {formatDateToShort(convertUTCDateToLocalDate(new Date(row.created)))}
      </p>
    </div>
  );
};

const DownloadProject: React.FC<any> = (props: {
  row: Project | ScheduledProject;
  history: History;
}) => {
  return (
    <div
      onClick={() => {
        emitAnalyticsTrackingEvent("go_to_project_download", {
          project_id: `${props.row.id}`,
        });
        redirect(props.row.id, props.history);
      }}
      className={"individual-section action-button"}
    >
      <Button className={"table-button"} variant={ButtonVariant.PRIMARY}>
        Download
      </Button>
    </div>
  );
};

const RequestAction: React.FC<any> = (props: {
  row: ScheduledProject;
  history: History;
  isLoadingMarkPaid: boolean;
  dispatch: AppDispatch;
  onClickReviewDates?: (scheduledProject: ScheduledProject) => void;
  isLabelProject?: boolean;
}) => {
  return (
    <div className={"individual-section action-button"}>
      <Button
        className={"mobile-table table-button"}
        loading={props.isLoadingMarkPaid}
        variant={ButtonVariant.PRIMARY}
        fullWidth={isMobile}
        onClick={() => {
          emitAnalyticsTrackingEvent("on_click_accept_project", {
            scheduled_project_id: `${props.row.id}`,
          });
          void props
            .dispatch(
              manageScheduledProject({
                action: ProjectManagementActions.AcceptProject,
                scheduled_project_id: props.row.id,
              }),
            )
            .then(unwrapResult)
            .then(() => {
              void props.dispatch(getProjectRequests({}));
              props.history.push(
                SCREENS.SCHEDULED_PROJECT_OVERVIEW_SCREEN.replace(
                  ":scheduled_project_id",
                  props.row.id.toString(),
                ),
              );
            });
        }}
      >
        Accept Project
      </Button>
      <div
        style={{
          height: "10px",
          width: "10px",
          display: isMobile ? "none" : "block",
        }}
      />
      <Button
        className={"mobile-table table-button"}
        variant={ButtonVariant.OUTLINED}
        fullWidth={checkIsMobile()}
        onClick={() => {
          emitAnalyticsTrackingEvent("on_click_view_details", {
            scheduled_project_id: `${props.row.id}`,
          });
          if (props.onClickReviewDates) props.onClickReviewDates(props.row);
        }}
      >
        View Details
      </Button>
    </div>
  );
};

const ABPlayerCell: React.FC<any> = (props: {
  row: Project;
  allowTrackPreview?: boolean;
  allowTrackComments?: boolean;
  handlePaymentFlow?: () => void;
}) => {
  if (!props.row) return null;

  return (
    <TrackTable
      projectId={props.row.id}
      projectType={props.row.service_type}
      projectName={props.row.title}
      step={props.row.step}
      isCurrentEngineer={false}
      project={props.row}
      showHeader={false}
      showFileNames={true}
      allowTrackPreview={props.allowTrackPreview}
      allowTrackComments={props.allowTrackComments}
      handlePaymentFlow={props.handlePaymentFlow}
    />
  );
};

export const serializeStr = (str: string) => {
  return str.toLocaleLowerCase().replace(" ", "-").trim();
};

export const searchFilter = (data: Project[], searchQuery: string) => {
  const queryTokens = searchQuery
    .toLocaleLowerCase()
    .split(" ")
    .filter((token: string) => token.trim() !== "");

  let filteredList = data;
  if (queryTokens.length) {
    const searchRegex = new RegExp(queryTokens.join("|"), "gim");
    filteredList = data.filter((project: Project) => {
      const serializeProjectStr = JSON.stringify(project);
      return serializeProjectStr.match(searchRegex);
    });
  }
  return filteredList;
};

export enum ProjectTableTab {
  TODAY = "today",
  ACTIVE = "active",
  COMPLETED = "completed",
  PENDING = "pending",
  SESSIONS = "sessions",
}

export enum ProjectStatus {
  COMPLETED = "completed",
  PENDING = "pending",
  ACTIVE = "active",
}

export enum ProjectTableCellType {
  TEAM_MEMBERS = "team-members",
  PROJECT_TYPE = "project-type",
  PROJECT_TITLE = "project-title",
  UPDATABLE_PROJECT_TITLE = "updatable-project-title",
  TRACK_NAME = "track-name",
  COLLABORATOR = "collaborators",
  REQUESTOR = "requestor",
  START_DATE = "start-date",
  COMPLETED_DATE = "completed-date",
  RESPOND_BY = "respond-by",
  PROGRESS = "progress",
  STAGE = "status",
  ACTION_TO_TAKE = "action-to-take",
  TRACK_REVIEW = "track-review",
  PROJECT_DATE = "project-date",
  REQUEST_DATE = "request-date",
  DOWNLOAD_PROJECT = "download-project",
  REQUEST_ACTION = "request-action",
  EMPTY_CELL = "empty-cell",
  AB_TRACK_REVIEW = "ab-track-review",
  SESSION_DATE = "session-date",
  TIME_AND_DURATION = "time-and-duration",
}

export const headerToTableCellMap = new Map<ProjectTableCellType, React.FC>([
  [ProjectTableCellType.PROJECT_TYPE, ProjectTypeCell],
  [ProjectTableCellType.PROJECT_TITLE, ProjectTitleCell],
  [ProjectTableCellType.UPDATABLE_PROJECT_TITLE, UpdatableProjectTitleCell],
  [ProjectTableCellType.TRACK_NAME, ProjectTitleCell],
  [ProjectTableCellType.COLLABORATOR, CollaboratorCell],
  [ProjectTableCellType.REQUESTOR, CollaboratorCell],
  [ProjectTableCellType.START_DATE, ScheduledDueDateCell],
  [ProjectTableCellType.COMPLETED_DATE, ProjectCompletedDateCell],
  [ProjectTableCellType.RESPOND_BY, ProjectDueDateCell],
  [ProjectTableCellType.PROGRESS, TimelineCell],
  [ProjectTableCellType.STAGE, StepCell],
  [ProjectTableCellType.ACTION_TO_TAKE, TakeAction],
  [ProjectTableCellType.TRACK_REVIEW, TrackReviewCell],
  [ProjectTableCellType.PROJECT_DATE, ProjectDate],
  [ProjectTableCellType.REQUEST_DATE, ProjectDate],
  [ProjectTableCellType.DOWNLOAD_PROJECT, DownloadProject],
  [ProjectTableCellType.REQUEST_ACTION, RequestAction],
  [ProjectTableCellType.EMPTY_CELL, EmptyColumnCell],
  [ProjectTableCellType.AB_TRACK_REVIEW, ABPlayerCell],
  [ProjectTableCellType.SESSION_DATE, ProjectDate],
  [ProjectTableCellType.TIME_AND_DURATION, ProjectDate],
]);
