import { useMemo } from "react";
import { Alt, AltItem, altToDisplayString } from "../../store/models/alts";
import {
  Project,
  ProjectType,
  projectTypeReadableName,
} from "../../store/models/project";
import { ScheduledProjectOverview } from "../../store/models/scheduledproject";
import { Transaction } from "../../store/models/transaction";

interface AltMetadata {
  title: string;
  alts: string[];
  totalPrice: number;
}

interface ProjectTypeMetadata {
  count: number;
  subTotal: number;
  firstProject: Project;
  altMetadata: AltMetadata[];
}

export const useGetProjectTypeToProjectTypeMetadata = (
  transaction?: Transaction,
) => {
  return useMemo(() => {
    const projectsByTypeMap = new Map<ProjectType, ProjectTypeMetadata>();
    transaction?.items?.forEach((transactionItem) => {
      if (!transactionItem.project) return;
      const serviceType = transactionItem.project.service_type;
      if (!projectsByTypeMap.has(serviceType)) {
        projectsByTypeMap.set(serviceType, {
          count: 1,
          subTotal: +transactionItem.amount,
          firstProject: transactionItem.project,
          altMetadata: [],
        });
      } else {
        projectsByTypeMap.get(transactionItem.project.service_type)!.count += 1;
        projectsByTypeMap.get(transactionItem.project.service_type)!.subTotal +=
          +transactionItem.amount;
      }
      if (
        transactionItem.project.alts &&
        transactionItem.project.alts.length > 0
      ) {
        const projectAlts: AltMetadata = {
          title: transactionItem.project.title,
          alts: ["Main"],
          totalPrice: 0,
        };
        transactionItem.project.alts.forEach(({ alt, alt_price }) => {
          projectAlts.alts.push(altToDisplayString[alt]);
          projectAlts.totalPrice += +alt_price;
        });
        projectsByTypeMap
          .get(transactionItem.project.service_type)!
          .altMetadata.push(projectAlts);
      }
    });
    return projectsByTypeMap;
  }, [transaction]);
};

export const useGetReadableServices = (
  projectTypeToProjectTypeMetedata: Map<ProjectType, unknown>,
) => {
  return useMemo(() => {
    const keys = Array.from(projectTypeToProjectTypeMetedata.keys());
    return keys.map((key) => projectTypeReadableName.get(key)).join(", ");
  }, [projectTypeToProjectTypeMetedata]);
};

export const useGetReadableAlts = (
  alts: AltItem[] | undefined | null,
  projectType: ProjectType,
) => {
  return useMemo(() => {
    if (projectType === ProjectType.RECORDING) return "";
    // All projects are guaranteed to have a main but we do not create the alt on the backend.
    const altsStr = altToDisplayString[Alt.MAIN];
    if (!alts) return altsStr;
    if (alts.length === 0) return altsStr;
    const altsFromTransaction = alts
      .map((alt) => altToDisplayString[alt.alt])
      .join(", ");
    if (altsFromTransaction) return altsStr + ", " + altsFromTransaction;
    return altsStr;
  }, [alts, projectType]);
};

interface MinimalProjectTypeMetadata {
  count: number;
  altCount: number;
}

export const useGetProjectTypeToProjectTypeMetadataFromScheduledProject = (
  scheduledProject: ScheduledProjectOverview | null,
) => {
  return useMemo(() => {
    const projectsByTypeMap = new Map<
      ProjectType,
      MinimalProjectTypeMetadata
    >();
    scheduledProject?.projects?.forEach((project) => {
      const serviceType = project.service_type;

      // All projects are guaranteed to have a main but we do not create the alt on the backend.
      let currentAltCount = 1;
      if (project.alts) {
        currentAltCount = project.alts.length + 1;
      }

      if (!projectsByTypeMap.has(serviceType)) {
        projectsByTypeMap.set(serviceType, {
          count: 1,
          altCount: currentAltCount,
        });
      } else {
        projectsByTypeMap.get(serviceType)!.count += 1;
        projectsByTypeMap.get(serviceType)!.altCount += currentAltCount;
      }
    });
    return projectsByTypeMap;
  }, [scheduledProject]);
};
