import styled from "styled-components";

export const InfoBadgeContainer = styled.div.attrs<{
  $backgroundColor: string;
  $clickable?: boolean;
}>((props) => ({ $backgroundColor: props.$backgroundColor }))`
  align-items: center;
  background-color: ${(props) => props.$backgroundColor};
  border-radius: var(--border-radius-xs);
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 2px 4px;
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "unset")};
`;
