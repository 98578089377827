import {
  ToggleSwitch,
  TOGGLE_SWITCH_SIZE,
} from "../../elements/ToggleSwitch/ToggleSwitch";
import { useAtom } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Box } from "@mui/material";

export const PresentationSettings = () => {
  const [darkMode, setDarkMode] = useAtom(darkModeAtom);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
      }}
    >
      <Text variant={TextStyleVariant.P1} weight={TEXT_WEIGHT.SEMI_BOLD}>
        Enable dark mode
      </Text>
      <ToggleSwitch
        size={TOGGLE_SWITCH_SIZE.MEDIUM}
        checked={darkMode}
        updateCheckedLocally={false}
        onChange={() => {
          setDarkMode(!darkMode);
        }}
      />
    </Box>
  );
};
