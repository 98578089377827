import { Dispatch, ReactNode, SetStateAction } from "react";
import { ReactMultiEmail } from "react-multi-email";
import { useIsUMGAandR } from "../../../hooks/useIsAandR";
import {
  PendingPurchaseOrderApprover,
  PostPurchaseOrderArgs,
} from "../../../store/actions/transactions";
import { useAppSelector } from "../../../store/hooks";
import { MajorLabelEnum } from "../../../store/models/trophy";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { BillingInfoForm } from "../BillingInfoForm/BillingInfoForm";
import { CollapsableComponent } from "../CollapsableComponent/CollapsableComponent";
import { LabelAssociationDropdown } from "../LabelAssociationDropdown/LabelAssociationDropdown";
import {
  PaymentGatewayModuleBoldP,
  PaymentGatewayModuleFooter,
  PaymentGatewayModuleH6,
  PaymentGatewayModuleP,
} from "../PaymentGatewayModule/PaymentGatewayModule.styles";
import FormTagComponent from "./FormTagComponent";
import { MyBudgetManagersFormContainer } from "./MyBudgetManagersForm.styles";

export interface MyBudgetManagersFormProps {
  budgetManagerFormUsedInCheckout: boolean;
  setPostPurchaseArgs: Dispatch<SetStateAction<PostPurchaseOrderArgs>>;
  setCollaboratorEmails: Dispatch<SetStateAction<string[]>>;
  postPurchaseArgs: PostPurchaseOrderArgs;
  billingInfoSubmitters: string[];
  handleRemoveBillingInfoSubmitterEmail: (email: string) => void;
  handleBillingInfoSubmitterEmailChange: (emails: string[]) => void;
  budgetApprovers: PendingPurchaseOrderApprover[];
  removeApprover: (email: string) => void;
  onChangeBudgetApprover: (emails: string[]) => void;
  collaboratorEmails: string[];
  hasMixingOrMastering: boolean;
  children: ReactNode;
  toggleApprovalRequired: (email: string) => void;
}

export const MyBudgetManagersForm = ({
  budgetManagerFormUsedInCheckout,
  setPostPurchaseArgs,
  postPurchaseArgs,
  billingInfoSubmitters,
  setCollaboratorEmails,
  onChangeBudgetApprover,
  handleRemoveBillingInfoSubmitterEmail,
  handleBillingInfoSubmitterEmailChange,
  budgetApprovers,
  collaboratorEmails,
  hasMixingOrMastering,
  children,
  toggleApprovalRequired,
  removeApprover,
}: MyBudgetManagersFormProps) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const isUMGAandR = useIsUMGAandR(user);

  return (
    <MyBudgetManagersFormContainer>
      <PaymentGatewayModuleH6 variant={TextStyleVariant.H6}>
        My Team
      </PaymentGatewayModuleH6>
      {budgetManagerFormUsedInCheckout && isUMGAandR && (
        <LabelAssociationDropdown
          onChange={(option) => {
            setPostPurchaseArgs((curr) => ({
              ...curr,
              major_label: MajorLabelEnum.UMG,
              umg_sub_label: option.umg_sub_label,
            }));
          }}
        />
      )}
      <PaymentGatewayModuleBoldP>
        Who is approving the budget for this project?
      </PaymentGatewayModuleBoldP>
      <PaymentGatewayModuleP>
        The budget for this project must be approved to download final files.
      </PaymentGatewayModuleP>
      <ReactMultiEmail
        className="purchase-order-form-multi-email"
        placeholder={"Budget approver emails"}
        emails={budgetApprovers?.map((approver) => approver.email) || []}
        onChange={onChangeBudgetApprover}
        getLabel={(email, index, removeEmail) => {
          const approver = budgetApprovers?.find(
            (approver) => approver.email === email,
          );
          return (
            <FormTagComponent
              key={index}
              className={
                !approver?.approval_required
                  ? "approval-not-required-email budget-approval-email-field"
                  : "budget-approval-email-field"
              }
              onClickFunc={toggleApprovalRequired}
              email={email}
              index={index}
              removeFunc={(email, index) => {
                removeApprover(email);
                removeEmail(index);
              }}
            />
          );
        }}
      />
      <PaymentGatewayModuleBoldP>
        Who is submitting the billing information for this project?
      </PaymentGatewayModuleBoldP>
      <PaymentGatewayModuleP>
        Note: Billing information for this project must be submitted in order to
        download final stems and included alts.
      </PaymentGatewayModuleP>
      <ReactMultiEmail
        className="purchase-order-form-multi-email"
        placeholder={"Enter admin emails"}
        emails={billingInfoSubmitters || []}
        onChange={(emails: string[]) => {
          if (
            !handleBillingInfoSubmitterEmailChange ||
            billingInfoSubmitters === undefined
          )
            return;
          handleBillingInfoSubmitterEmailChange(emails);
        }}
        getLabel={(email, index, removeEmail) => {
          return (
            <FormTagComponent
              email={email}
              index={index}
              removeFunc={(email, index) => {
                handleRemoveBillingInfoSubmitterEmail(email);
                removeEmail(index);
              }}
            />
          );
        }}
      />
      {hasMixingOrMastering && (
        <>
          <CollapsableComponent
            hintText="Add Collaborators for Mix/Master Approval"
            primary={true}
            bold={true}
          >
            <p>
              Optionally, you can add the emails of any collaborators associated
              with this project to notifying them to review files.
            </p>
            <ReactMultiEmail
              className="purchase-order-form-multi-email"
              placeholder={"Provide a list of emails"}
              emails={collaboratorEmails}
              onChange={(emails: string[]) => {
                setCollaboratorEmails(emails);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <FormTagComponent
                    email={email}
                    index={index}
                    removeFunc={(_, index) => removeEmail(index)}
                  />
                );
              }}
            />
          </CollapsableComponent>
        </>
      )}
      {isUMGAandR && budgetManagerFormUsedInCheckout && (
        <CollapsableComponent hintText="(optional, required later) Fill in UMG Financial Billing Info">
          <BillingInfoForm
            postPurchaseArgs={postPurchaseArgs}
            setPostPurchaseArgs={setPostPurchaseArgs}
          />
        </CollapsableComponent>
      )}
      <PaymentGatewayModuleFooter
        style={{
          marginTop: "1rem",
        }}
      >
        {children}
      </PaymentGatewayModuleFooter>
    </MyBudgetManagersFormContainer>
  );
};
