import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

const selectTransactionStore = (state: RootState) => state.transactionStore;

export const selectInvoiceTransactionFields = createDraftSafeSelector(
  selectTransactionStore,
  (transactionStore) => {
    if (!transactionStore?.transactionData)
      return {
        transaction_status: null,
        hosted_invoice_url: null,
        id: null,
      };
    const { transaction_status, hosted_invoice_url, id } =
      transactionStore.transactionData;
    return {
      transaction_status,
      hosted_invoice_url,
      id,
    };
  },
);
