import { compareAsc, isAfter } from "date-fns";
import { useMemo } from "react";
import { useAppSelector } from "../store/hooks";
import { Alt } from "../store/models/alts";
import {
  AUDIO_UPLOAD_TYPE,
  FILE_STATUS,
  FileType,
  FileVersion,
  GENERATED_MP3_UPLOAD_TYPE,
  MP4_UPLOAD_TYPE,
  UploadType,
  ZIP_UPLOAD_TYPE,
} from "../store/models/fileVersion";
import {
  AtmosProjectSteps,
  BaseProject,
  MasteringProjectSteps,
  MixingProjectSteps,
  Project,
  ProjectById,
  ProjectType,
} from "../store/models/project";
import {
  RecordingSession,
  SessionSteps,
} from "../store/models/recordingSession";
import { setAreEqual } from "../utils/utils";
import { useAcceptedFileTypes } from "./useAcceptedFileTypes";
import { useCurrentProjectHasAlts } from "./useCurrentProjectHasAlts";
import { useIsArtistUploadStep } from "./useIsUploadStep";

const INVALID_PROJECT_ID = -1;

export const useWaveUploadIsOptional = (
  serviceType: ProjectType | undefined,
  step: number,
) => {
  return useMemo(() => {
    if (!serviceType) return false;
    switch (serviceType) {
      case ProjectType.MIXING:
      case ProjectType.TWO_TRACK_MIXING:
        return step === MixingProjectSteps.MIX_FINISHED;
      case ProjectType.ATMOS_MIXING:
        return step === AtmosProjectSteps.MIX_FINISHED;
      case ProjectType.MASTERING:
        return (
          step === MasteringProjectSteps.MASTERING_UPLOAD_ALTS ||
          step === MasteringProjectSteps.MASTERING_FILE_NEEDS_REUPLOAD
        );
      default:
        return false;
    }
  }, [serviceType, step]);
};

export const useCheckIfWavPreviouslyUploaded = (
  projectType: ProjectType | undefined,
  projectStep:
    | MixingProjectSteps
    | MasteringProjectSteps
    | SessionSteps
    | AtmosProjectSteps
    | undefined = 0,
) => {
  return useMemo(() => {
    const step = projectStep ?? 0;
    const serviceType = projectType ?? ProjectType.NO_TYPE;
    switch (serviceType) {
      case ProjectType.MIXING:
      case ProjectType.TWO_TRACK_MIXING:
        return step === MixingProjectSteps.PROJECT_ACCEPTED;
      case ProjectType.ATMOS_MIXING:
        return step === AtmosProjectSteps.PROJECT_ACCEPTED;
      default:
        return false;
    }
  }, [projectType, projectStep]);
};

const useLastTransition = (
  project: Project | ProjectById | BaseProject | undefined,
) => {
  return useMemo(() => {
    if (!project) return new Date();
    const lastTransition = project.last_transition ?? project.created;
    return new Date(lastTransition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.created, project?.last_transition]);
};

export const useUploadedFiles = (
  projectId: number | undefined = INVALID_PROJECT_ID,
) => {
  const fileVersionStore = useAppSelector(
    (state) => state.fileVersionStore.storedFileVersions,
  );
  const filesForProject = fileVersionStore[projectId];

  const uploaded = useMemo(() => {
    if (!filesForProject) return [];
    return filesForProject[FILE_STATUS.FILE_UPLOADED] ?? [];
  }, [filesForProject]);

  return useMemo(() => {
    if (!uploaded) return [];
    return uploaded;
  }, [uploaded]);
};

export const useLatestUploadedFile = (
  projectId: number | undefined = INVALID_PROJECT_ID,
) => {
  const projectFiles = useUploadedFiles(projectId);
  const sortedFiles = [...projectFiles].sort((a, b) =>
    compareAsc(new Date(a.created), new Date(b.created)),
  );
  return sortedFiles.pop();
};

export const usePendingFiles = (
  project: Pick<Project | ProjectById | RecordingSession, "id"> | undefined,
) => {
  const projectId = project?.id ?? INVALID_PROJECT_ID;
  const fileVersionStore = useAppSelector(
    (state) => state.fileVersionStore.storedFileVersions,
  );
  const filesForProject = fileVersionStore[projectId];

  return useMemo(() => {
    if (!filesForProject) return [];
    return filesForProject[FILE_STATUS.FILE_PENDING] ?? [];
  }, [filesForProject]);
};

export const useUploadedFileSinceLastTransition = (
  project: Project | ProjectById | BaseProject | undefined,
) => {
  const uploadedFiles = useUploadedFiles(project?.id);
  const lastTransitionDate = useLastTransition(project);
  return useMemo(() => {
    return uploadedFiles
      .filter((file) => file.upload_type !== GENERATED_MP3_UPLOAD_TYPE)
      .filter((file: FileVersion) => {
        return isAfter(new Date(file.created), lastTransitionDate);
      })
      .sort((a, b) => {
        return a.created.localeCompare(b.created);
      });
  }, [uploadedFiles, lastTransitionDate]);
};

export const useLatestUploadedReferenceFile = (
  project: Project | ProjectById | undefined,
) => {
  const uploadedFiles = useUploadedFileSinceLastTransition(project);
  return useMemo(() => {
    if (uploadedFiles.length === 0) return undefined;
    const referenceFiles = uploadedFiles.filter(
      (file: FileVersion) =>
        file.reference && file.upload_type === AUDIO_UPLOAD_TYPE,
    );
    if (referenceFiles.length === 0) return undefined;
    const latestFile = referenceFiles.reduce((prev, current) =>
      prev.id > current.id ? prev : current,
    );
    return latestFile;
  }, [uploadedFiles]);
};

export const useLatestUploadedDeliverableFile = (
  project: Project | ProjectById | undefined,
) => {
  const uploadedFiles = useUploadedFileSinceLastTransition(project);
  const isAtmos = project?.service_type === ProjectType.ATMOS_MIXING;
  return useMemo(() => {
    if (uploadedFiles.length === 0) return undefined;
    const deliverableFiles = uploadedFiles.filter((file: FileVersion) => {
      const isWav = file.upload_type === UploadType.AUDIO;
      const isAtmosFile =
        isAtmos &&
        (file.upload_type === UploadType.AUDIO ||
          file.upload_type === UploadType.ZIP);
      // filter out non-deliverable files
      return !file.reference && (isWav || isAtmosFile) && !file.archived;
    });
    if (deliverableFiles.length === 0) return undefined;
    const latestFile = deliverableFiles.reduce((prev, current) =>
      prev.id > current.id ? prev : current,
    );
    return latestFile;
  }, [uploadedFiles, isAtmos]);
};

export const useCleanAltsUploaded = (project: Project | undefined) => {
  const uploadedFiles = useUploadedFiles(project?.id);
  return useMemo(() => {
    if (!project) return false;
    return uploadedFiles.some((file: FileVersion) => {
      return file.alt === Alt.CLEAN && !file.reference;
    });
  }, [uploadedFiles, project]);
};

export const useNeedsCleanAltUploaded = (
  isLabelProject: boolean,
  project: Project | undefined,
) => {
  const [, alts] = useCurrentProjectHasAlts(project);
  const cleanAltsUploaded = useCleanAltsUploaded(project);
  const isFinalUploadStep = useMemo(() => {
    switch (project?.service_type) {
      case ProjectType.MIXING:
      case ProjectType.TWO_TRACK_MIXING:
        return project?.step === MixingProjectSteps.MIX_FINISHED;
      case ProjectType.ATMOS_MIXING:
        return project?.step === AtmosProjectSteps.MIX_FINISHED;
      case ProjectType.MASTERING:
        return project?.step === MasteringProjectSteps.MASTERING_UPLOAD_ALTS;
      default:
        return false;
    }
  }, [project?.step, project?.service_type]);

  return useMemo(() => {
    if (!alts.some((alt) => alt.alt === Alt.CLEAN)) return false;
    if (!isLabelProject) return false;
    if (!project) return false;
    if (!isFinalUploadStep) return false;
    return !cleanAltsUploaded;
  }, [isLabelProject, alts, project, cleanAltsUploaded, isFinalUploadStep]);
};

export const useProjectFilesUploaded = (
  project: Project | ProjectById | undefined,
  projectStep:
    | MixingProjectSteps
    | MasteringProjectSteps
    | SessionSteps
    | undefined = 0,
  previouslyUploadedRefFileName: string | undefined,
) => {
  const recentFileVersions = useUploadedFileSinceLastTransition(project);
  const checkIfWavPreviouslyUploaded = useCheckIfWavPreviouslyUploaded(
    project?.service_type,
    projectStep,
  );
  const serviceType = project?.service_type ?? ProjectType.NO_TYPE;
  const [hasAlts] = useCurrentProjectHasAlts(project);
  const acceptedFileTypes = useAcceptedFileTypes(
    serviceType,
    projectStep,
    hasAlts,
  );

  const wavIsOptional = useWaveUploadIsOptional(serviceType, projectStep);

  return useMemo(() => {
    if (recentFileVersions.length === 0) {
      return false;
    }
    const acceptedFilesSet = new Set(acceptedFileTypes);
    const audioFileUploads = new Set([FileType.WAV, FileType.MP3]);

    if (setAreEqual(acceptedFilesSet, audioFileUploads)) {
      return recentFileVersions.some((fileVersion: FileVersion) => {
        return fileVersion.upload_type === AUDIO_UPLOAD_TYPE;
      });
    }

    for (const extension of acceptedFilesSet) {
      if (
        extension === FileType.ZIP &&
        recentFileVersions.some((fileVersion: FileVersion) => {
          return fileVersion.upload_type === ZIP_UPLOAD_TYPE;
        })
      ) {
        continue;
      }
      if (extension.includes(FileType.WAV) && wavIsOptional) {
        continue;
      }
      if (extension.includes(FileType.WAV) && checkIfWavPreviouslyUploaded) {
        if (previouslyUploadedRefFileName?.includes(extension)) {
          continue;
        }
      }
      if (
        extension.includes(FileType.WAV) ||
        extension.includes(FileType.MP3)
      ) {
        if (
          recentFileVersions.some((fileVersion: FileVersion) => {
            return fileVersion.upload_type === AUDIO_UPLOAD_TYPE;
          })
        ) {
          continue;
        }
        return false;
      }
    }
    return true;
  }, [
    recentFileVersions,
    acceptedFileTypes,
    checkIfWavPreviouslyUploaded,
    previouslyUploadedRefFileName,
    wavIsOptional,
  ]);
};

export const useFileTypeUploaded = (
  extension: string,
  project: Project | undefined,
  previouslyUploadedRefFileName: string | undefined,
) => {
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const checkIfWavPreviouslyUploaded = useCheckIfWavPreviouslyUploaded(
    project?.service_type,
    project?.step,
  );
  const isArtistUploadStep = useIsArtistUploadStep(project);
  const recentFileVersions = useUploadedFileSinceLastTransition(project);
  const latestAudioFileVersion = useMemo(() => {
    let sortedAudioFileVersions = recentFileVersions.sort((a, b) => {
      return b.version - a.version;
    });
    sortedAudioFileVersions = sortedAudioFileVersions.filter((fileVersion) => {
      return fileVersion.upload_type === AUDIO_UPLOAD_TYPE;
    });
    if (sortedAudioFileVersions.length === 0) return undefined;
    return sortedAudioFileVersions[0];
  }, [recentFileVersions]);

  return useMemo(() => {
    if (!project) return false;
    if (checkIfWavPreviouslyUploaded) {
      if (
        extension === FileType.WAV &&
        previouslyUploadedRefFileName?.endsWith(FileType.WAV)
      ) {
        return true;
      }
    }
    if (extension.includes(FileType.ZIP)) {
      return recentFileVersions.some((fileVersion) => {
        return fileVersion.upload_type === ZIP_UPLOAD_TYPE;
      });
    }
    if (extension.includes(FileType.MP4)) {
      return recentFileVersions.some((fileVersion) => {
        return fileVersion.upload_type === MP4_UPLOAD_TYPE;
      });
    }
    if (isAuthenticated) {
      if (extension.includes(FileType.WAV)) {
        return (
          latestAudioFileVersion?.file_name?.endsWith(FileType.WAV) ?? false
        );
      }
      if (extension.includes(FileType.MP3)) {
        return (
          latestAudioFileVersion?.file_name?.endsWith(FileType.MP3) ?? false
        );
      }
    }
    if (extension.includes(FileType.WAV) && !isAuthenticated) {
      return recentFileVersions.some((fileVersion) => {
        return fileVersion.upload_type === AUDIO_UPLOAD_TYPE;
      });
    }
    return false;
  }, [
    extension,
    project,
    recentFileVersions,
    isArtistUploadStep,
    previouslyUploadedRefFileName,
    latestAudioFileVersion,
    isAuthenticated,
    checkIfWavPreviouslyUploaded,
  ]);
};
