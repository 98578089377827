import { isStartedInProgressProjectTransaction } from "../../hooks/partialPaymentHooks";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { getCookie, setCookie } from "../../utils/cookieUtils";
import {
  setActiveServiceId,
  setActiveStudioIds,
  setInitialBookingParameters,
} from "../actions/generateBookingStore";
import {
  addMixMasterCart,
  MixMasterCart,
} from "../actions/mixMasterCartsStore";
import {
  addRecordingCart,
  RecordingCart,
} from "../actions/recordingCartsStore";
import { loadEngineerServices } from "../actions/services";
import { PendingSessionData, setUpCart } from "../actions/shoppingCart";
import { fetchStudioRooms } from "../actions/studio";
import { getTransaction } from "../actions/transactions";
import type { AppDispatch } from "../index";
import { ProjectType } from "../models/project";
import { RecordingSession } from "../models/recordingSession";
import { MinimalScheduledProject } from "../models/scheduledproject";
import { Transaction, TransactionStatus } from "../models/transaction";
import User from "../models/user";
import { getDateFromString } from "./dateTimeUtils";
import { BookingParameters, formatTransactionForBooking } from "./transactions";

const loadServices = async (
  engineerId: number,
  dispatch: AppDispatch,
  serviceType?: ProjectType,
  activeServiceId?: number,
) => {
  const { services, recording_services } = await dispatch(
    loadEngineerServices({ engineer_id: engineerId }),
  ).unwrap();
  if (serviceType === ProjectType.RECORDING) {
    const engineerRecordingService = recording_services.find(
      (service) => service.engineer?.id === engineerId,
    );
    const studioRecordingServices = recording_services.filter(
      (service) => !service.engineer,
    );
    let studioRecordingService;
    if (activeServiceId) {
      studioRecordingService = studioRecordingServices.find(
        (service) => service.id === activeServiceId,
      );
    }
    if (!studioRecordingService) {
      studioRecordingService = studioRecordingServices[0];
    }
    if (studioRecordingService?.studio_room?.studio) {
      dispatch(
        setActiveStudioIds({
          studioId: studioRecordingService.studio_room.studio.id,
          studioRoomId: studioRecordingService.studio_room.id,
          serviceId: studioRecordingService.id,
        }),
      );
    } else if (engineerRecordingService) {
      dispatch(setActiveServiceId(engineerRecordingService.id));
      return;
    }
  }
  const activeService = services.find(
    (service) => service.service_type === serviceType,
  );
  if (activeService) {
    dispatch(setActiveServiceId(activeService.id));
  }
};

export const handleSettingUpMixMasterCart = async (
  mixMasterCart: MixMasterCart | undefined,
  dispatch: AppDispatch,
  bookingParameters: BookingParameters,
) => {
  dispatch(addMixMasterCart(mixMasterCart));
  if (!bookingParameters.engineerId) {
    return;
  }
  await loadServices(
    bookingParameters.engineerId,
    dispatch,
    bookingParameters.activeServiceType,
  );
};

const makePendingSessionsFromRecordingSessions = (
  recordingSessions?: RecordingSession[],
): PendingSessionData[] => {
  const pendingSessions: PendingSessionData[] = [];
  if (!recordingSessions) {
    return pendingSessions;
  }
  return recordingSessions?.reduce((acc, session) => {
    const localDate = new Date(
      session.first_choice_datetime + "Z",
    ).toLocaleDateString();
    const selectedISODate = getDateFromString(localDate).toISOString();

    const currentPendingSession = {
      duration: session.session_duration_minutes,
      id: session.project.id,
      selectedISODate: selectedISODate,
      trackingEngineer: session.engineer?.has_active_recording_service
        ? session.engineer
        : undefined,
      hasNoTrackingEngineer: !session.engineer,
      preselectedDateTime: new Date(
        session.first_choice_datetime + "Z",
      ).getTime(),
      //TODO: convert session.recording_location to google.maps.places.PlaceResult
    };
    return [...acc, currentPendingSession];
  }, pendingSessions);
};

export const handleSettingUpRecordingCart = async (
  recordingCart: RecordingCart | undefined,
  dispatch: AppDispatch,
  bookingParameters: BookingParameters,
  recordingSessions?: RecordingSession[],
  activeServiceId?: number,
) => {
  dispatch(addRecordingCart(recordingCart));
  const activeStudioId = bookingParameters.activeStudioId;
  const activeStudioRoomId = bookingParameters.activeStudioRoomId;

  if (bookingParameters.engineerId) {
    await loadServices(
      bookingParameters.engineerId,
      dispatch,
      bookingParameters.activeServiceType,
      activeServiceId,
    );
  }
  if (activeStudioId && activeStudioRoomId) {
    const rooms = await dispatch(
      fetchStudioRooms({ studio_id: activeStudioId }),
    ).unwrap();
    const room = rooms.find((room) => room.id === activeStudioRoomId);
    if (room?.recording_service) {
      dispatch(setActiveServiceId(room.recording_service.id));
      dispatch(
        setUpCart({
          pendingSessionData:
            makePendingSessionsFromRecordingSessions(recordingSessions),
        }),
      );
    }
  }
};

export const shouldRedirectToTransactionOrderSummary = (
  transaction: Transaction,
  scheduledProject: MinimalScheduledProject | null,
  loggedInUser: User | undefined,
) => {
  // Redirect to the order summary page in the following scenarios:
  // - If the transaction is not pending
  // - If the logged-in user viewing this page != transaction.created_by_user
  // - If the project is an in-progress project that has started
  return (
    transaction.transaction_status !== TransactionStatus.PENDING ||
    (transaction.created_by_user &&
      transaction.created_by_user.id !== loggedInUser?.id) ||
    isStartedInProgressProjectTransaction(transaction, scheduledProject)
  );
};

export const setUpInitialBookingParamsFromTransactionCode = async (
  transactionCode: string,
  redirectToOrderSummary: (transactionId: number) => void,
  redirectToHomePage: () => void,
  dispatch: AppDispatch,
  loggedInUser: User | undefined,
  activeServiceId: number | undefined,
) => {
  const {
    transaction,
    scheduled_project: scheduledProject,
    overwrite_price: overwritePrice,
  } = await dispatch(getTransaction({ transactionCode })).unwrap();

  if (
    shouldRedirectToTransactionOrderSummary(
      transaction,
      scheduledProject,
      loggedInUser,
    )
  ) {
    redirectToOrderSummary(+transaction.id);
    return;
  }

  const formattedTransaction = formatTransactionForBooking(
    transaction,
    scheduledProject,
    overwritePrice,
    redirectToHomePage,
  );
  if (!formattedTransaction) {
    return;
  }

  const { bookingParameters, mixMasterCart, recordingCart } =
    formattedTransaction;

  dispatch(setInitialBookingParameters(bookingParameters));

  if (mixMasterCart) {
    await handleSettingUpMixMasterCart(
      mixMasterCart,
      dispatch,
      bookingParameters,
    );
  } else if (recordingCart) {
    await handleSettingUpRecordingCart(
      recordingCart,
      dispatch,
      bookingParameters,
      transaction.recording_sessions,
      activeServiceId,
    );
  }

  emitAnalyticsTrackingEvent(
    "booking_flow-init",
    {
      transactionId: transaction.id,
    },
    loggedInUser?.id,
  );
};

export const setInitialBookingParametersCookie = (
  transactionCode: string,
  bookingParameters: BookingParameters,
) => {
  setCookie(
    `${transactionCode}-initial-booking-parameters`,
    JSON.stringify(bookingParameters),
    {
      expires: 1, // expires 1 day after creation
    },
  );
};

export const getInitialBookingParametersCookie = (transactionCode: string) => {
  return getCookie(`${transactionCode}-initial-booking-parameters`);
};
