import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { add } from "date-fns";
import { RecordingSession } from "../models/recordingSession";
import { BasicStudioRoom, StudioRoom } from "../models/studio";
import {
  convertUTCDateToLocalDate,
  get15MinuteDayIndexFromDateTime,
  getAvailabilityFormattedDateString,
} from "../utils/dateTimeUtils";
import { getRecordingServiceAvailability } from "./recording";

const LAST_BLOCK_OF_THE_DAY = 95;

export interface AvailabilityForDate {
  [utc_date: string]: string;
}

export interface WorkingHoursForDate {
  [id: number]: AvailabilityForDate;
}

interface Availability {
  studio_room: WorkingHoursForDate;
  engineer_user: WorkingHoursForDate;
}

const initialState: Availability = {
  studio_room: {},
  engineer_user: {},
};

const markTimeBlocks = (
  dateKey: string,
  startIndex: number,
  endIndex: number,
  markAsAvailable = false,
  engineerAvailabilities?: AvailabilityForDate,
  studioAvailabilities?: AvailabilityForDate,
) => {
  const replacingString = (markAsAvailable ? "1" : "0").repeat(
    endIndex - startIndex,
  );

  if (engineerAvailabilities?.[dateKey]) {
    const timeBlocks = engineerAvailabilities[dateKey];
    engineerAvailabilities[dateKey] =
      timeBlocks.substring(0, startIndex) +
      replacingString +
      timeBlocks.substring(endIndex);
  }

  if (studioAvailabilities?.[dateKey]) {
    const timeBlocks = studioAvailabilities[dateKey];
    studioAvailabilities[dateKey] =
      timeBlocks.substring(0, startIndex) +
      replacingString +
      timeBlocks.substring(endIndex);
  }
};

const availabilitySlice = createSlice({
  name: "availabilityStateSlice",
  initialState,
  reducers: {
    updateAvailabilityCache: (
      state,
      action: PayloadAction<
        (Pick<
          RecordingSession,
          "first_choice_datetime" | "session_duration_minutes" | "engineer"
        > & {
          studio_room?: BasicStudioRoom | StudioRoom;
          markAsAvailable?: boolean;
        })[]
      >,
    ) => {
      action.payload.forEach(
        ({
          first_choice_datetime: startTime,
          session_duration_minutes: durationMinutes,
          engineer,
          studio_room: studioRoom,
          markAsAvailable,
        }) => {
          const engineerUserId = engineer?.user_id;
          const studioRoomId = studioRoom?.id;

          let engineerAvailabilities: AvailabilityForDate | undefined;
          let studioAvailabilities: AvailabilityForDate | undefined;

          if (engineerUserId) {
            engineerAvailabilities = state.engineer_user[engineerUserId];
          }

          if (studioRoomId) {
            studioAvailabilities = state.studio_room[studioRoomId];
          }

          if (!studioAvailabilities && !engineerAvailabilities) {
            return state;
          }

          const localFirstChoiceDateTime = convertUTCDateToLocalDate(
            new Date(startTime),
          );

          // find 15 minute index of startTime for availabilityString
          const startTimeIndex = get15MinuteDayIndexFromDateTime(
            localFirstChoiceDateTime,
          );

          // calculate number of 15 minute blocks in sessionDurationMinutes
          const numberOfBlocks = Math.ceil(durationMinutes / 15);

          // set time to midnight to accurately format date string
          localFirstChoiceDateTime.setHours(0, 0, 0, 0);

          // format date to yyyy-mm-dd to get availability string from the object
          const firstDayObjectKey = getAvailabilityFormattedDateString(
            localFirstChoiceDateTime,
          );

          let firstDayEndTimeIndex = startTimeIndex + numberOfBlocks - 1;
          let remainingBlocks = 0;

          if (firstDayEndTimeIndex > LAST_BLOCK_OF_THE_DAY) {
            remainingBlocks = firstDayEndTimeIndex - LAST_BLOCK_OF_THE_DAY;
            firstDayEndTimeIndex = LAST_BLOCK_OF_THE_DAY;

            // get availability string for next day to cover times that overlap between days
            const nextDay = add(localFirstChoiceDateTime, { days: 1 });
            const nextDayObjectKey =
              getAvailabilityFormattedDateString(nextDay);

            markTimeBlocks(
              nextDayObjectKey,
              0,
              remainingBlocks,
              markAsAvailable,
              engineerAvailabilities,
              studioAvailabilities,
            );
          }

          markTimeBlocks(
            firstDayObjectKey,
            startTimeIndex,
            firstDayEndTimeIndex + 1,
            markAsAvailable,
            engineerAvailabilities,
            studioAvailabilities,
          );
        },
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getRecordingServiceAvailability.fulfilled,
      (state, action) => {
        const { studioRoomId, userId } = action.meta.arg;
        if (userId) {
          const results = action.payload;
          results.forEach(
            // Even though the name is utc_date, the value from the response is actually in local time zone
            (result: { utc_date: string; availability: string }) => {
              state.engineer_user[userId] = {
                ...state.engineer_user[userId],
                [result.utc_date.split("T")[0]]: result.availability,
              };
            },
          );
        }
        if (studioRoomId) {
          const results = action.payload;
          results.forEach(
            // Even though the name is utc_date, the value from the response is actually in local time zone
            (result: { utc_date: string; availability: string }) => {
              state.studio_room[studioRoomId] = {
                ...state.studio_room[studioRoomId],
                [result.utc_date.split("T")[0]]: result.availability,
              };
            },
          );
        }
      },
    );
  },
});

export const { updateAvailabilityCache } = availabilitySlice.actions;
export default availabilitySlice.reducer;
