import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import {
  Text,
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../../core-ui/components/Text/Text";
import "./NavProfileButton.css";
import { useAtomValue } from "jotai";
import { activeProfileAtom } from "../../../../atoms/user/activeProfileAtom";
import { DEFAULT_USER_IMAGE_SRC } from "../../../../constants/googleStorage";

export interface NavProfileButtonProps {
  onClick?: () => void;
  isMobile: boolean;
  expanded: boolean;
}

export const NavProfileButton: React.FC<NavProfileButtonProps> = ({
  onClick,
  isMobile,
  expanded,
}) => {
  const activeProfile = useAtomValue(activeProfileAtom);

  const [loadImageFailed, setLoadImageFailed] = React.useState(false);

  const { username, displayName, path } = useMemo(() => {
    return {
      username: activeProfile?.username,
      displayName: activeProfile?.display_name,
      path: activeProfile?.profile_picture,
    };
  }, [activeProfile]);

  return (
    <button
      className={`profile-nav-header ${expanded ? "show" : ""}`}
      name="profile-menu"
      onClick={onClick}
      type="button"
    >
      <div className="profile-nav-header-left">
        <img
          className="profile-nav-header-image"
          src={loadImageFailed || !path ? DEFAULT_USER_IMAGE_SRC : path}
          onError={() => setLoadImageFailed(true)}
          alt="profile"
        />
        <div
          className={`profile-nav-header-text-container ${expanded ? "show" : ""}`}
        >
          <Text
            className="profile-nav-header-text"
            color={TEXT_COLOR.TERTIARY}
            size={TEXT_SIZE.XS}
          >
            @{username}
          </Text>
          {displayName && (
            <Text
              className="profile-nav-header-text"
              color={TEXT_COLOR.SECONDARY}
              size={TEXT_SIZE.SMALL}
              weight={TEXT_WEIGHT.BOLD}
            >
              {displayName}
            </Text>
          )}
        </div>
      </div>
      {!isMobile && (
        <FontAwesomeIcon
          icon={faChevronDown}
          color="var(--text-primary-color)"
          className={"profile-nav-header-icon ".concat(
            expanded ? "expanded" : "",
          )}
        />
      )}
    </button>
  );
};
