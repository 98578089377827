import { STUDIO_DETAILS } from "../../../store/utils/routes";
import { Studio } from "../../../store/models/studio";
import { makeBackendGetCallWithJsonResponse } from "../../../store/utils/fetch";
import { EngineEarsError } from "../../../store/actions/errorStore";

export const fetchStudioDetails = async (
  username: string,
): Promise<Studio | EngineEarsError> => {
  const params = `?username=${username}`;
  return makeBackendGetCallWithJsonResponse<Studio>(
    STUDIO_DETAILS,
    params,
  ).then((res) => {
    if (res.success) {
      return res.resultJson;
    }
    return res.resultJson;
  });
};
