import isSameDay from "date-fns/isSameDay";
import { FC, useMemo } from "react";
import { useIsPendingSessionDataBookable } from "../../../hooks/bookingHooks/useIsPendingSessionDataBookable";
import { useOverWriteDisabledTiles } from "../../../hooks/bookingHooks/useOverWriteDisabledTile";
import { useOverWriteTileClass } from "../../../hooks/bookingHooks/useOverWriteTileClass";
import { useRecordingSessionUserType } from "../../../hooks/bookingHooks/useRecordingSessionUserType";
import { useGetAvailabilityOnThisDate } from "../../../hooks/recordingSessionHooks/useGetAvailabilityOnThisDate";
import { setSelectedIndex } from "../../../store/actions/shoppingCart";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RecordingService } from "../../../store/models/recording";
import { StudioRoom } from "../../../store/models/studio";
import {
  selectShoppingCartRecordingBookingDates,
  selectShoppingCartStudioRoomAvailabilities,
} from "../../../store/selectors/shoppingCart";
import { convertAvailabilityForDateToMap } from "../../../store/utils/utils";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { BookingCalendar } from "../BookingCalendar/BookingCalendar";
import "./RecordingBookingCalendarWidget.css";

export enum RecordingBookingCalendarWidgetVariant {
  ENGINEER = "engineer",
  STUDIO = "studio",
}

export interface RecordingBookingCalendarWidgetProps {
  enableAddDate?: boolean;
  onAddDate?: (date: Date) => void;
  onInvalidDate?: (date: Date) => void;
  selectedTime: Date | undefined;
  variant?: RecordingBookingCalendarWidgetVariant;
  recordingService: RecordingService | null;
  studioRoom: StudioRoom | undefined;
  studioId: number | undefined;
}

export const RecordingBookingCalendarWidget: FC<
  RecordingBookingCalendarWidgetProps
> = ({
  enableAddDate = false,
  onAddDate,
  onInvalidDate, // this is a janky way to handle opening a modal for invalid dates, but it works for now
  selectedTime,
  recordingService,
  studioRoom,
  studioId,
  variant = RecordingBookingCalendarWidgetVariant.STUDIO,
}) => {
  const dispatch = useAppDispatch();
  const { selectedIndex, pendingSessionData } = useAppSelector(
    (state) => state.shoppingCart,
  );
  const recordingBookingDates = useAppSelector(
    selectShoppingCartRecordingBookingDates,
  );
  const isStudioVariant =
    variant === RecordingBookingCalendarWidgetVariant.STUDIO;

  const { minimum_session_time_minutes } = useMemo(() => {
    return recordingService
      ? recordingService
      : {
          minimum_session_time_minutes: 30,
          maximum_session_time_minutes: 120,
        };
  }, [recordingService]);

  const selectedSession = useMemo(() => {
    if (selectedIndex === undefined) {
      return undefined;
    }
    return pendingSessionData?.[selectedIndex];
  }, [selectedIndex, pendingSessionData]);
  const sessionDurationMinutes =
    selectedSession?.duration ?? minimum_session_time_minutes;
  const isBookable = useIsPendingSessionDataBookable(
    selectedSession,
    studioRoom,
  );

  const allowsBookingWithoutEngineer =
    studioRoom?.studio?.allow_bookings_without_engineer;
  const selectedSessionEngineer = useMemo(() => {
    if (selectedIndex === undefined) {
      return undefined;
    }
    return pendingSessionData?.[selectedIndex]?.trackingEngineer;
  }, [selectedIndex, pendingSessionData]);

  const {
    isStudioManager: isCurrentUserSameAsStudioManager,
    isEngineerWithPermission: isCurrentUserSameAsSelectedEngineer,
  } = useRecordingSessionUserType(studioRoom?.studio, selectedSessionEngineer);

  const studioDateMap = useAppSelector(
    selectShoppingCartStudioRoomAvailabilities(studioRoom),
  );

  const engineerAvailabilities = useAppSelector(
    (state) => state.availability.engineer_user,
  );
  const engineerAvailability =
    engineerAvailabilities[selectedSessionEngineer?.user_id ?? -1];
  const engineerDateMap = convertAvailabilityForDateToMap(engineerAvailability);

  const selectedBookingDate = useMemo(() => {
    if (selectedIndex === undefined || !selectedSession?.selectedISODate) {
      return undefined;
    }
    return new Date(selectedSession.selectedISODate);
  }, [selectedIndex, selectedSession]);

  const {
    getEngineerAvailability: getEngineerAvailabilityOnThisDate,
    getStudioAvailability: getStudioAvailabilityOnThisDate,
    getOverlappingAvailability: getOverLappingDateAvailabilities,
  } = useGetAvailabilityOnThisDate(
    studioDateMap,
    engineerDateMap,
    sessionDurationMinutes,
  );

  const handleOverWriteDisabledTiles = useOverWriteDisabledTiles({
    variant,
    isCurrentUserSameAsSelectedEngineer,
    isCurrentUserSameAsStudioManager,
    isEngineerSelected: Boolean(selectedSessionEngineer),
    selectedTime,
    getEngineerAvailabilityOnThisDate,
    getStudioAvailabilityOnThisDate,
    getOverLappingDateAvailabilities,
  });

  const handleOverWriteTileClass = useOverWriteTileClass({
    allowsBookingWithoutEngineer,
    enableAddDate,
    isBookable,
    isCurrentUserSameAsSelectedEngineer,
    isCurrentUserSameAsStudioManager,
    isStudioBooking: isStudioVariant,
    recordingBookingDates,
    selectedBookingDate,
    trackingEngineer: selectedSessionEngineer,
    getEngineerAvailability: getEngineerAvailabilityOnThisDate,
    getStudioAvailability: getStudioAvailabilityOnThisDate,
    getOverlappingAvailability: getOverLappingDateAvailabilities,
  });

  const isLoading = !studioDateMap && !engineerDateMap;

  return (
    <div className="recording-booking-calendar-container">
      {isLoading && <SoundWaveLoader width={100} height={100} />}
      {!isLoading && (
        <BookingCalendar
          studioId={studioId}
          overWriteGetDisabledTiles={handleOverWriteDisabledTiles}
          overWriteGetTileClass={handleOverWriteTileClass}
          onClickDate={(date) => {
            if (enableAddDate && onAddDate) {
              const tileClassName = handleOverWriteTileClass(date);

              // this is a janky way to handle opening a modal for invalid dates, but it works for now
              if (tileClassName === "invalid-session-date" && onInvalidDate) {
                onInvalidDate(date);
                return;
              }
              return onAddDate(date);
            }
            const clickedIndex = recordingBookingDates.findIndex((d) =>
              isSameDay(d, date),
            );
            if (clickedIndex === -1) return;
            if (
              clickedIndex < recordingBookingDates.length &&
              selectedIndex !== clickedIndex
            ) {
              dispatch(setSelectedIndex(clickedIndex));
            }
          }}
          clickedDay={null}
          selectedDays={recordingBookingDates}
          availabilities={isStudioVariant ? studioDateMap : engineerDateMap}
          durationMinutes={sessionDurationMinutes}
        />
      )}
    </div>
  );
};
