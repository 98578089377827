import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  isAnyOf,
} from "@reduxjs/toolkit";
import {
  PROMO_CODE_API,
  MARKETING_OPT_IN,
  APPLY_PROMO_CODE,
  UPDATE_PROMO_CODE,
} from "../utils/routes";
import { applyTransactionPromoCode } from "./transactions";
import { PromoCode } from "../models/promoCode";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import { receiveErrors } from "./errorStore";

interface MarketingState {
  promoCodes: PromoCode[];
  loading: boolean;
  campaignsOptedIn: boolean;
  competitionsOptedIn: boolean;
  optedIntoUnverifiedEngineerPromo: boolean;
  updating: boolean;
  appliedPromoCode?: PromoCode;
  storedPromoCode?: string;
  bulkDiscountDisabled?: boolean;
}

const initialState: MarketingState = {
  promoCodes: [],
  loading: true,
  campaignsOptedIn: false,
  competitionsOptedIn: false,
  optedIntoUnverifiedEngineerPromo: false,
  updating: false,
  appliedPromoCode: undefined,
  storedPromoCode: undefined,
  bulkDiscountDisabled: false,
};

interface OptInResponse {
  campaigns_opted_in: boolean;
  competitions_opted_in: boolean;
  opted_into_unverified_engineer_promotion: boolean;
}
interface GetUserPromoCodesResponse extends OptInResponse {
  data: PromoCode[];
  page: number;
  num_pages: number;
}

export const getProfilePromoCodes = createAsyncThunk(
  PROMO_CODE_API + "list",
  async (args: { studio_id?: number }, thunkAPI) => {
    let params = "";
    if (args.studio_id) {
      params = `?studio_id=${args.studio_id}`;
    }
    const result =
      await makeBackendGetCallWithJsonResponse<GetUserPromoCodesResponse>(
        PROMO_CODE_API,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface OptInArgs {
  campaigns_opted_in?: boolean;
  competitions_opted_in?: boolean;
  opted_into_unverified_engineer_promotion?: boolean;
  studio_id?: number;
}

export const optInToPlatformPromotion = createAsyncThunk(
  MARKETING_OPT_IN,
  async (args: OptInArgs, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<OptInResponse>(
      MARKETING_OPT_IN,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface applyPromoCodeParams {
  promocode: string;
  user_id?: number;
  studio_id?: number;
}
export const applyPromoCode = createAsyncThunk(
  APPLY_PROMO_CODE,
  async (args: applyPromoCodeParams, thunkAPI) => {
    let params = `?promocode=${args.promocode}`;
    params += args.user_id ? `&user_id=${args.user_id}` : ``;
    params += args.studio_id ? `&studio_id=${args.studio_id}` : ``;
    const result = await makeBackendGetCallWithJsonResponse<PromoCode>(
      APPLY_PROMO_CODE,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export enum PromoCodeUpdates {
  delete = "delete",
}

interface UpdatePromoCodeArgs {
  action: PromoCodeUpdates;
  code: string;
  studio_id?: number;
}

export const deletePromoCode = createAsyncThunk(
  UPDATE_PROMO_CODE,
  async (args: UpdatePromoCodeArgs, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<PromoCode>(
      UPDATE_PROMO_CODE,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const marketing = createSlice({
  name: "marketing",
  initialState: initialState,
  reducers: {
    clearPromoCodes: (state) => {
      state.promoCodes = [];
      state.loading = false;
      state.appliedPromoCode = undefined;
      state.storedPromoCode = undefined;
    },
    clearAppliedPromoCode: (state) => {
      state.appliedPromoCode = undefined;
    },
    storePromoCode: (state, action: PayloadAction<string | undefined>) => {
      state.storedPromoCode = action.payload;
    },
    addCreatedPromoCode: (state, action: PayloadAction<PromoCode>) => {
      state.promoCodes = [...state.promoCodes, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(optInToPlatformPromotion.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(optInToPlatformPromotion.rejected, (state) => {
      state.updating = false;
    });
    builder.addCase(getProfilePromoCodes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProfilePromoCodes.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePromoCode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePromoCode.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(
      deletePromoCode.fulfilled,
      (state, action: PayloadAction<PromoCode>) => {
        state.loading = false;
        state.promoCodes = state.promoCodes.filter(
          (code) => code.id != action.payload.id,
        );
      },
    );
    builder.addCase(getProfilePromoCodes.fulfilled, (state, action) => {
      state.loading = false;
      state.promoCodes = action.payload.data;
      state.campaignsOptedIn = action.payload.campaigns_opted_in;
      state.competitionsOptedIn = action.payload.competitions_opted_in;
      state.optedIntoUnverifiedEngineerPromo =
        action.payload.opted_into_unverified_engineer_promotion;
    });
    builder.addCase(optInToPlatformPromotion.fulfilled, (state, action) => {
      state.campaignsOptedIn = action.payload.campaigns_opted_in;
      state.competitionsOptedIn = action.payload.competitions_opted_in;
      state.optedIntoUnverifiedEngineerPromo =
        action.payload.opted_into_unverified_engineer_promotion;
      state.updating = false;
    });
    builder.addMatcher(
      isAnyOf(applyPromoCode.pending, applyTransactionPromoCode.pending),
      (state) => {
        state.loading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(applyPromoCode.rejected, applyTransactionPromoCode.rejected),
      (state) => {
        state.loading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(applyPromoCode.fulfilled, applyTransactionPromoCode.fulfilled),
      (state, action) => {
        state.loading = false;
        state.appliedPromoCode = action.payload;
      },
    );
  },
});

export const {
  clearPromoCodes,
  clearAppliedPromoCode,
  storePromoCode,
  addCreatedPromoCode,
} = marketing.actions;

export default marketing.reducer;
