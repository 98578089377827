import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { GET_ONBOARDING_PROGRESS } from "../../store/utils/routes";

export interface ChecklistItemResponse {
  title: string;
  description: string;
  expandable: boolean;
  completed: boolean;
  button_content?: {
    label: string;
    link: string;
  };
  guide?: {
    title: string;
    link: string;
  };
}

export interface OnboardingProgressResponse {
  phase: number;
  title: string;
  checklist: ChecklistItemResponse[];
}
export const getOnboardingProgress = async () => {
  const result =
    await makeBackendGetCallWithJsonResponse<OnboardingProgressResponse>(
      GET_ONBOARDING_PROGRESS,
      "",
    );
  return result;
};
