import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { LoginParams, postLogin } from "../../api/auth/postLogin";
import { tokenAtom } from "../../atoms/auth/tokenAtom";
import { useSetAtom } from "jotai";
import { useAppDispatch } from "../../store/hooks";
import { setUser } from "../../store/actions/accountInfo";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../api/helpers";
import { EngineEarsError } from "../../store/actions/errorStore";

const useLoginMutation = () => {
  const setToken = useSetAtom(tokenAtom);
  const dispatch = useAppDispatch();
  const clearUserData = () => {
    setToken("");
    dispatch(setUser());
  };
  return useMutation({
    mutationKey: [QUERY_KEYS.LOGIN],
    mutationFn: async (params: LoginParams) => {
      emitAnalyticsTrackingEvent("login_attempt", {
        username: params.username,
        passwordLength: params.password.length,
      });
      return postLogin(params);
    },
    // If we handle the error as part of the mutation function, it can show the toast numerous times.
    // Our custom errors are technically valid responses
    onSuccess: (result) => {
      if (assertEngineEarsErrorType(result)) {
        return Promise.reject(result);
      }
      setToken(result.token);
      dispatch(setUser(result.user));
      return result;
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      clearUserData();
      return error;
    },
  });
};

export default useLoginMutation;
