import { createGlobalStyle, DefaultTheme } from "styled-components";

export enum ColorPalette {
  Black = "#000000",
  BoomyOrange50 = "#fff4f1",
  BoomyOrange100 = "#FFE8E1",
  BoomyOrange200 = "#ffd4c7",
  BoomyOrange400 = "#ff8966",
  BoomyOrange500 = "#f8663b",
  BoomyOrange600 = "#e54b1d",
  BoomyOrange700 = "#c13c14",
  BoomyOrange800 = "#9e2e0d",
  BoomyOrange900 = "#843118",
  BoomyOrange950 = "#481607",
  BoxyYellow = "#f6d378",
  BoxyYellow100 = "#FBEDCA",
  BoxyYellow200 = "#F6D378",
  BoxyYellow300 = "#F4C355",
  BoxyYellow400 = "#F1AC2E",
  BoxyYellow500 = "#EA8B16",
  BoxyYellow600 = "#CF6710",
  BoxyYellow700 = "#AC4811",
  CrushOrange = "#ff6c3e",
  DarkGrey = "#6d6d6d",
  DeepBlue300 = "#6ec3f2",
  DeepBlue500 = "#1C9ADB",
  DeepBlue950 = "#0C2C45",
  DeepGreen = "#c4d0a1",
  DeepPink = "#ffcef4",
  EchoOrange = "#f3ba99",
  FrostedRose = "#f2e5f9",
  Gray100 = "#ECECEC",
  Gray200 = "#D2D2D2",
  Gray25 = "#FCFCFC",
  Gray300 = "#A9A9A9",
  Gray400 = "#7C7C7D",
  Gray50 = "#F2F2F2",
  Gray500 = "#5E5E5E",
  Gray600 = "#484848",
  Gray700 = "#393939",
  Gray800 = "#313131",
  Gray900 = "#2C2C2C",
  Gray950 = "#1E1E1E",
  Gray1000 = "#111111",
  Green100 = "#DFF9E4",
  Green50 = "#F1FCF2",
  Green600 = "#28A745",
  Green950 = "#092A12",
  LightGray = "#c9c9c9",
  MintGreen500 = "#e0efd1",
  MintGreen600 = "#588230",
  MuddyBrown = "#d9bba5",
  Red400 = "#ff5959",
  Red500 = "#ff2626",
  Red600 = "#fc0606",
  Red700 = "#d40000",
  SkylightBlue = "#ccedff",
  SmoothIvory = "#fcfcfc",
  SmoothIvoryDark = "#212121",
  TimbreTan = "#ecdfd3",
  WarmLightGrey = "#f5f4f4",
  White = "#ffffff",
  Blue300 = "#75c3f1",
}

const zIndexes: Record<string, number> = {
  popconfirmContent: 1005,
  projectSidePanelContent: 1004,
  selectDropdownMenuContent: 999,
  trackTableCommentsSortDropdown: 1200,
};

interface Size {
  xxs: string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
}

export interface Border {
  radius: Pick<Size, "xs" | "sm" | "md" | "lg" | "xl">;
}

const border: Border = {
  radius: {
    xs: "4px",
    sm: "6px",
    md: "8px",
    lg: "16px",
    xl: "32px",
  },
};

const popoverBoxShadow = "0px 2px 30px 0px rgba(0, 0, 0, 0.15)";
const regionDefault = "rgba(0, 0, 0, 0.1)";
const regionSelected = "rgba(40, 167, 69, 0.5)";

type BaseTheme = Pick<
  DefaultTheme,
  | "border"
  | "popoverBoxShadow"
  | "regionDefault"
  | "regionSelected"
  | "colorPalette"
  | "textSizeXxs"
  | "textSizeXs"
  | "textSizeSm"
  | "textSizeMd"
  | "textSizeLg"
  | "textSizeXLg"
  | "textSizeXXLg"
  | "textWeightNormal"
  | "textWeightSemibold"
  | "textWeightBold"
  | "maxScreenWidth"
  | "zIndexes"
>;

export const baseTheme: BaseTheme = {
  border,
  popoverBoxShadow,
  regionDefault,
  regionSelected,
  colorPalette: ColorPalette,
  textSizeXxs: "0.625rem",
  textSizeXs: "0.75rem",
  textSizeSm: "0.875rem",
  textSizeMd: "1rem",
  textSizeLg: "1.5rem",
  textSizeXLg: "2rem",
  textSizeXXLg: "3.5rem",
  textWeightNormal: 400,
  textWeightSemibold: 600,
  textWeightBold: 700,
  maxScreenWidth: "83rem",
  zIndexes,
};

export const lightTheme: DefaultTheme = {
  ...baseTheme,
  buttonColorHover: ColorPalette.Gray200,
  bodyBackgroundColor: ColorPalette.SmoothIvory,
  textPrimaryColor: ColorPalette.Gray950,
  textSecondaryColor: ColorPalette.Gray500,
  textTertiaryColor: ColorPalette.Gray300,
  textInvertColor: ColorPalette.White,
  textErrorColor: ColorPalette.Red700,
  backgroundColor: ColorPalette.Gray25,
  midgroundColor: ColorPalette.Gray50,
  darkerMidgroundColor: ColorPalette.Gray100,
  foregroundColor: ColorPalette.White,
  separatorColor: ColorPalette.Gray200,
  dropdownBackgroundColor: ColorPalette.White,
  disabledTextPrimaryColor: ColorPalette.Gray300,
  projectWorkflowMessageBackgroundColor: ColorPalette.BoomyOrange100,
  projectWorkflowMessageBorderColor: ColorPalette.Gray25,
};

export const darkTheme: DefaultTheme = {
  ...baseTheme,
  buttonColorHover: ColorPalette.Gray600,
  bodyBackgroundColor: ColorPalette.SmoothIvoryDark,
  textPrimaryColor: ColorPalette.Gray25,
  textSecondaryColor: ColorPalette.Gray200,
  textTertiaryColor: ColorPalette.Gray300,
  textInvertColor: ColorPalette.Gray800,
  textErrorColor: ColorPalette.Red400,
  backgroundColor: ColorPalette.Gray950,
  midgroundColor: ColorPalette.Gray800,
  darkerMidgroundColor: ColorPalette.Gray900,
  foregroundColor: ColorPalette.Gray800,
  separatorColor: ColorPalette.Gray700,
  dropdownBackgroundColor: ColorPalette.Gray950,
  disabledTextPrimaryColor: ColorPalette.Gray300,
  projectWorkflowMessageBackgroundColor: ColorPalette.BoomyOrange950,
  projectWorkflowMessageBorderColor: ColorPalette.BoomyOrange700,
};

/**
 * @TODO - Remove this global styles when migrating off styled-components.
 * Logic has been moved to MUI ThemeProvider
 */
export const GlobalStyles = createGlobalStyle``;

export const defaultPageLoaderSize = 100;
