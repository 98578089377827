import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Popover from "@radix-ui/react-popover";
import styled from "styled-components";
import { CloseButton } from "../../../components/ProjectFilters/SidePanelFilters/SidePanelFilters.styles";

export const StyledTitleIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colorPalette.BoxyYellow200};
`;

export const PopoverContent = styled(Popover.Content)`
  border: 1px solid ${({ theme }) => theme.separatorColor};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.dropdownBackgroundColor};
  color: ${({ theme }) => theme.textPrimaryColor};
  border-radius: ${({ theme }) => theme.border.radius.md};
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: ${({ theme }) => theme.popoverBoxShadow};
  // Available width and height exposed by radix
  max-height: var(--radix-popover-content-available-height);
  max-width: var(--radix-popper-available-width);
  z-index: ${({ theme }) => theme.zIndexes.popconfirmContent};
  overflow-y: hidden;
  padding: 24px 0;

  .button-default {
    min-width: 80px;
  }

  ${CloseButton} {
    top: 18px;
    right: 18px;
    z-index: inherit;
  }
`;

export const PopoverHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: ${({ theme }) => theme.textPrimaryColor};
  padding: 0 18px;
`;

export const PopoverFooter = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  gap: 8px;
  padding: 0 18px;
`;

export const PopoverContentBody = styled.div`
  overflow-y: auto;
  // keeps width consistent whether scrollbar exists or not
  scrollbar-gutter: stable both-edges;
  padding: 0 18px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
