import { useSetAtom } from "jotai";
import { useCallback, useRef } from "react";
import { useResizeObserver } from "usehooks-ts";
import { topNavContainerHeightAtom } from "../../../atoms/navAtoms";
import { Size } from "../SessionScheduleGuide/SessionScheduleGuide";
import "./AppBarHeader.css";
import { Box, BoxProps } from "@mui/material";

type AppBarHeaderContentContainerProps = BoxProps;

export const AppBarHeaderContentContainer = ({
  children,
  ...props
}: AppBarHeaderContentContainerProps) => {
  const setHeight = useSetAtom(topNavContainerHeightAtom);
  const elementRef = useRef<HTMLDivElement>(null);
  const onResize = useCallback(
    (size: Size) => {
      setTimeout(() => {
        setHeight(size.height || 0);
      }, 0);
    },
    [setHeight],
  );
  useResizeObserver({
    ref: elementRef,
    box: "border-box",
    onResize,
  });
  return (
    <Box className="app-bar-main-content-container" {...props} ref={elementRef}>
      {children}
    </Box>
  );
};
