import { FC, ReactElement } from "react";
import "./VerifiedIcon.css";
import "../../components/ToolTipTextArea/ToolTipTextArea.css";
import onlineBadge from "../../assets/badgeIcons/verified.svg";
import { ToolTipTextArea } from "../../components/ToolTipTextArea/ToolTipTextArea";

export interface VerifiedIconProps {
  verified: boolean;
}

export const VerifiedIcon: FC<VerifiedIconProps> = ({
  verified,
}): ReactElement => {
  return (
    <div className={"verified-user-container tooltip"}>
      {verified && (
        <ToolTipTextArea text="Verified">
          <object
            data={onlineBadge}
            type="image/svg+xml"
            className={"verified-icon-image"}
          />
        </ToolTipTextArea>
      )}
    </div>
  );
};
