import { unwrapResult } from "@reduxjs/toolkit";
import { SCREENS } from "../../routes";
import { FirstPassDate } from "../../stories/components/ProjectBookingCalendar/ProjectBookingCalendar";
import { RescheduleBookingScreen } from "../../stories/screens/RescheduleBookingScreen/RescheduleBookingScreen";
import {
  manageProjectParams,
  manageScheduledProject,
  ProjectManagementActions,
  RescheduleProjectData,
} from "../actions/booking";
import { loadProject } from "../actions/projects";
import { loadEngineerServices } from "../actions/services";
import Service from "../models/service";
import { getFormattedDate } from "./dateTimeUtils";
import LoadingScreen from "../../stories/components/LoadingScreen/LoadingScreen";

const dispatchLoadEngineerServices = ({
  dispatch,
  engineerUser,
  projectType,
  setService,
}: any) => {
  dispatch(loadEngineerServices({ engineer_id: engineerUser!.engineer!.id }))
    .then(unwrapResult)
    .then((data: any) => {
      data.services.forEach((service: Service) => {
        if (service.service_type === projectType) {
          setService(service);
        }
      });
    });
};

const dispatchLoadProject = ({
  dispatch,
  scheduledProject,
  setAltsList,
}: any) => {
  dispatch(
    loadProject({
      project_id: scheduledProject!.projects[0].id.toString(),
    }),
  )
    .then(unwrapResult)
    .then((result: any) => {
      setAltsList([...result.alts]);
    });
};

const dispatchOnRejectProject = ({
  dispatch,
  history,
  scheduledProject,
  setLoading,
}: any) => {
  setLoading(true);
  dispatch(
    manageScheduledProject({
      action: ProjectManagementActions.RejectProject,
      scheduled_project_id: scheduledProject?.id,
    }),
  )
    .then(unwrapResult)
    .then(() => {
      history.push(SCREENS.PROJECTS.replace(":tab", "active"));
    });
};

const dispatchOnAcceptProjectSchedule = ({
  dispatch,
  history,
  scheduledProject,
  setLoading,
}: any) => {
  setLoading(true);
  dispatch(
    manageScheduledProject({
      action: ProjectManagementActions.AcceptProject,
      scheduled_project_id: scheduledProject?.id,
    }),
  )
    .then(unwrapResult)
    .then(() => {
      history.push(
        SCREENS.SCHEDULED_PROJECT_OVERVIEW_SCREEN.replace(
          ":scheduled_project_id",
          scheduledProject?.id,
        ),
      );
    });
};

const dispatchOnSubmitProjectDates = (
  { dispatch, history, scheduledProject, setLoading }: any,
  songDatesMap: Map<string, FirstPassDate>,
) => {
  setLoading(true);
  const bookingProjectParams: manageProjectParams = {
    action: ProjectManagementActions.RescheduleProject,
    reschedule_data: [],
    scheduled_project_id: scheduledProject!.id,
  };
  Array.from(songDatesMap.entries()).forEach(([song]) => {
    const reschedulingProjectData: RescheduleProjectData = {
      requested_date: getFormattedDate(songDatesMap.get(song)!.date),
    };
    bookingProjectParams.reschedule_data!.push(reschedulingProjectData);
  });
  dispatch(manageScheduledProject(bookingProjectParams)).then(() => {
    history.push(SCREENS.PROJECTS.replace(":tab", "active"));
  });
};

const renderRescheduleBookingScreenContent = ({
  service,
  availabilities,
  rescheduleBookingScreenProps,
}: any) => {
  if (service !== undefined && availabilities !== undefined) {
    return <RescheduleBookingScreen {...rescheduleBookingScreenProps} />;
  } else {
    return (
      <LoadingScreen className="checkout-screen">
        <p>Loading project and live scheduling information</p>
      </LoadingScreen>
    );
  }
};

export {
  dispatchLoadEngineerServices,
  dispatchLoadProject,
  dispatchOnRejectProject,
  dispatchOnAcceptProjectSchedule,
  dispatchOnSubmitProjectDates,
  renderRescheduleBookingScreenContent,
};
