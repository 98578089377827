import {
  DetailedPurchaseOrderWithTransaction,
  BudgetManager,
  DetailedPurchaseOrder,
} from "../models/project";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GET_PURCHASE_ORDER_STATUS,
  UPDATE_PURCHASE_ORDER_ADMINS,
} from "../utils/routes";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import { receiveErrors } from "./errorStore";
import { PendingBudgetManager } from "./transactions";

export const updatePurchaseOrderAdmins = createAsyncThunk(
  UPDATE_PURCHASE_ORDER_ADMINS,
  async (
    args: {
      purchase_order_id: number;
      budget_managers: PendingBudgetManager[];
      scheduledProjectId?: number;
    },
    thunkAPI,
  ) => {
    const result = await makeBackendPostCallWithJsonResponse<BudgetManager[]>(
      UPDATE_PURCHASE_ORDER_ADMINS,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getPurchaseOrderStatus = createAsyncThunk(
  GET_PURCHASE_ORDER_STATUS,
  async (args: { scheduled_project_id: number }, thunkAPI) => {
    const params = `?scheduled_project_id=${args.scheduled_project_id}`;
    const result = await makeBackendGetCallWithJsonResponse<
      DetailedPurchaseOrderWithTransaction[]
    >(GET_PURCHASE_ORDER_STATUS, params);
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface ScheduledProjectPurchaseOrdersState {
  [scheduledProjectId: number]: DetailedPurchaseOrder[] | undefined;
}

const initialState: ScheduledProjectPurchaseOrdersState = {};

export const scheduledProjectPurchaseOrdersSlice = createSlice({
  name: "scheduledProjectPurchaseOrdersStateSlice",
  initialState,
  reducers: {
    updatePurchaseOrder: (
      state,
      action: PayloadAction<{
        purchaseOrder: DetailedPurchaseOrderWithTransaction;
        scheduledProjectId: number;
      }>,
    ) => {
      const { purchaseOrder, scheduledProjectId } = action.payload;
      state[scheduledProjectId] = state[scheduledProjectId]?.map((po) => {
        if (po.id === purchaseOrder.id) {
          return purchaseOrder;
        }
        return po;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePurchaseOrderAdmins.fulfilled, (state, action) => {
      const { purchase_order_id, scheduledProjectId } = action.meta.arg;
      if (!scheduledProjectId) return;
      const budgetManagers = action.payload;
      state[scheduledProjectId] = state[scheduledProjectId]?.map((po) => {
        if (po.id === purchase_order_id) {
          return { ...po, budget_managers: budgetManagers };
        }
        return po;
      });
    });
    builder.addCase(getPurchaseOrderStatus.rejected, (state, action) => {
      const scheduled_project_id = action.meta.arg.scheduled_project_id;
      state[scheduled_project_id] = [];
    });
    builder.addCase(getPurchaseOrderStatus.fulfilled, (state, action) => {
      const { scheduled_project_id } = action.meta.arg;
      state[scheduled_project_id] = action.payload;
    });
  },
});

export const { updatePurchaseOrder } =
  scheduledProjectPurchaseOrdersSlice.actions;

export default scheduledProjectPurchaseOrdersSlice.reducer;
