import { createAsyncThunk } from "@reduxjs/toolkit";
import queryString from "query-string";
import { AdminActionPurchaseOrder } from "../models/PurchaseOrderWithTransactions";
import { PlatformActivity } from "../models/platformActivity";
import { ScheduledProject } from "../models/scheduledproject";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  DASHBOARD_ADMIN_ASSIGN_API,
  PAGINATED_ADMIN_ACTIONS,
  PAGINATED_PLATFORM_ACTIVITY_API,
} from "../utils/routes";
import { receiveErrors } from "./errorStore";

export interface PaginatedAdminActionItemsArgs {
  page: number;
  completed: boolean;
  searchQuery?: string;
  page_size?: number;
}

export interface PaginatedAdminActionsResponse {
  data: AdminActionPurchaseOrder[];
  num_pages: number;
  count: number;
}

export const getPaginatedAdminActionItems = createAsyncThunk(
  PAGINATED_ADMIN_ACTIONS,
  async (args: PaginatedAdminActionItemsArgs, thunkAPI) => {
    const params = `?${queryString.stringify({ ...args })}`;
    const response =
      await makeBackendGetCallWithJsonResponse<PaginatedAdminActionsResponse>(
        PAGINATED_ADMIN_ACTIONS,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface AdminAssignProjectsParams {
  scheduled_project_id: number;
  primary_engineer_user_id: number;
  primary_engineer_rate: number;
  secondary_engineer_user_id?: number;
  secondary_engineer_rate?: number;
  studio_room_id: number;
  due_date: string;
}

export const adminAssignProjects = createAsyncThunk(
  DASHBOARD_ADMIN_ASSIGN_API,
  async (params: AdminAssignProjectsParams, thunkAPI) => {
    const response =
      await makeBackendPostCallWithJsonResponse<ScheduledProject>(
        DASHBOARD_ADMIN_ASSIGN_API,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface PaginatedPlatformActivity {
  page: number;
  page_size?: number;
  team_id?: number;
}

export interface PlatformActivityResponse {
  data: PlatformActivity[];
  num_pages: number;
  count: number;
}

export const getPaginatedPlatformActivities = createAsyncThunk(
  PAGINATED_PLATFORM_ACTIVITY_API,
  async (args: PaginatedPlatformActivity, thunkAPI) => {
    const params = `?${queryString.stringify({ ...args })}`;
    const response =
      await makeBackendGetCallWithJsonResponse<PlatformActivityResponse>(
        PAGINATED_PLATFORM_ACTIVITY_API,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);
