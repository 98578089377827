import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled from "styled-components";

export const DropdownMenuItem = styled(DropdownMenu.Item)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  height: 45px;
  cursor: pointer;
  color: ${({ theme }) => theme.textPrimaryColor};
  font-weight: ${({ theme }) => theme.textWeightNormal};

  &:hover {
    background-color: ${({ theme }) => theme.midgroundColor};
  }

  &:focus-visible {
    outline: none;
  }

  &[data-disabled] {
    color: ${({ theme }) => theme.disabledTextPrimaryColor};
    cursor: not-allowed;
  }
`;
