import { useEffect, useState } from "react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "../../utils/umgAuthConfig";
import { assertEngineEarsErrorType } from "../../api/helpers";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { AuthFlows } from "../../constants/authSteps";
import { isAdmin } from "../useIsAdmin";
import {
  getAdminDashboardRoute,
  getDashboardRoute,
} from "../../store/utils/routeGetters";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import useLoginUMGMutation from "./useLoginUMGMutation";
import { useMediaQueryBreakpoint } from "../useMediaQuery";
import { useHistory } from "react-router-dom";
import { isUmgPortalOpenAtom } from "../../stories/components/SignInForm/SignInForm";
import { useCheckUserLoginFlow } from "./useCheckUserLoginFlow";
import { currentFlowAtom } from "../../stories/components/Auth/atoms";
import { useAppSelector } from "../../store/hooks";
import { useSetAtom } from "jotai";

/**
 * Originally located in the `SignInForm` component.
 * This hook is used to authenticate the user after they are redirected back
 * to EngineEars from the UMG portal.
 */
export const useAuthenticateUMG = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isUmgAuthenticated = useIsAuthenticated();
  const [umgAccessToken, setUmgAccessToken] = useState("");
  const setIsUmgPortalOpen = useSetAtom(isUmgPortalOpenAtom);
  const { mutateAsync: loginUMGAsync } = useLoginUMGMutation();
  const { isMobile } = useMediaQueryBreakpoint();
  const history = useHistory();
  const { checkUserAndSetFlow } = useCheckUserLoginFlow();
  const setFlow = useSetAtom(currentFlowAtom);
  const { localUTMParams, user } = useAppSelector((state) => state.accountInfo);

  useEffect(() => {
    if (!isUmgAuthenticated) return;
    if (!accounts.length) return;
    if (inProgress !== InteractionStatus.None) return;
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setUmgAccessToken(response.accessToken);
        setIsUmgPortalOpen(false);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          void instance.acquireTokenRedirect(request);
        }
      });
  }, [isUmgAuthenticated, accounts, inProgress]);

  useEffect(() => {
    if (!umgAccessToken) return;
    void loginUMGAsync({ access_token: umgAccessToken }).then((response) => {
      if (!assertEngineEarsErrorType(response)) {
        emitAnalyticsTrackingEvent(
          "login_umg",
          {
            username: `${user?.username}`,
            ...localUTMParams,
          },
          response.user.id,
        );

        if (response.created) {
          setFlow(AuthFlows.STANDARD_SIGNUP);
        } else {
          const dashboardRoute = isAdmin(response.user)
            ? getAdminDashboardRoute()
            : getDashboardRoute();
          if (isMobile) history.push(dashboardRoute);
          checkUserAndSetFlow(response.user);
        }
      }
    });
  }, [umgAccessToken]);
};
