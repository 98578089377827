import {
  HTMLAttributes,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import "./EditableTextArea.css";
import { useParams } from "react-router-dom";

export interface EditableTextAreaProps {
  initialValue: string;
  editMode: boolean;
  placeholder: string;
  onChange?: (newValue: string) => void;
  width?: string | number;
  height?: string | number;
  characterCount?: number;
  numberOfLines?: number;
  handleSubmit?: () => void;
  containerProps?: Partial<HTMLAttributes<HTMLDivElement>>;
  spanProps?: Partial<HTMLAttributes<HTMLSpanElement>>;
  textAreaProps?: Partial<HTMLAttributes<HTMLTextAreaElement>>;
}

export const EditableTextArea = ({
  height,
  width,
  initialValue,
  editMode,
  placeholder,
  onChange = () => {},
  characterCount = 2000,
  numberOfLines,
  handleSubmit = () => {},
  containerProps = {},
  textAreaProps = {},
  spanProps = {},
}: EditableTextAreaProps) => {
  const [value, setValue] = useState<string>(initialValue);
  const { username } = useParams<{ username: string | undefined }>();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && event.metaKey) {
        handleSubmit();
      }
    },
    [handleSubmit],
  );
  useEffect(() => {
    setValue(initialValue);
  }, [username, initialValue]);

  return (
    <div {...containerProps} className="editable-text-area-container">
      <textarea
        rows={numberOfLines}
        maxLength={characterCount}
        style={{ height, width }}
        className="editable-text-area"
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={placeholder}
        disabled={!editMode}
        value={value}
        onKeyDown={handleKeyDown}
        {...textAreaProps}
      />
      {editMode && (
        <span
          {...spanProps}
          className="b3 character-count"
        >{`${value.length}/${characterCount}`}</span>
      )}
    </div>
  );
};
