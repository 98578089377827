import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { receiveErrors } from "../../store/actions/errorStore";
import { useAppDispatch } from "../../store/hooks";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { GET_COLLABORATORS } from "../../store/utils/routes";
import queryString from "query-string";
import User from "../../store/models/user";

interface UseGetUserCollaboratorsProps {
  userId?: number;
}

export const useGetUserCollaborators = ({
  userId,
}: UseGetUserCollaboratorsProps) => {
  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: [QUERY_KEYS.GET_USER_COLLABORATOR, userId],
    queryFn: async () => {
      const queryParams = queryString.stringify({
        user_id: userId,
      });
      const result = await makeBackendGetCallWithJsonResponse<User[]>(
        GET_COLLABORATORS,
        `?${queryParams}`,
      );
      if (result.success) {
        return result.resultJson;
      }

      const errors = { errors: result.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(errors);
    },
    enabled: Boolean(userId),
  });
};
