import { FC, ReactElement, useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { WEEKDAYS } from "../../../store/models/workingHours";
import { Pill } from "../../elements/Pill/Pill";
import "./WeekdayPicker.css";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { useAtomValue } from "jotai";

function getWeekdayStringFromIndex(index: WEEKDAYS): string {
  switch (index) {
    case 0:
      return "Mon";
    case 1:
      return "Tue";
    case 2:
      return "Wed";
    case 3:
      return "Thu";
    case 4:
      return "Fri";
    case 5:
      return "Sat";
    case 6:
      return "Sun";
  }
  return "Not found";
}

export interface WeekdayPickerProps {
  weekdaysInput: Array<number>;
  onChange?: (selected: Array<number>) => void;
}

export const WeekdayPicker: FC<WeekdayPickerProps> = ({
  weekdaysInput,
  onChange,
}): ReactElement => {
  const [weekdays, setWeekdays] = useState<Array<number>>(weekdaysInput);
  const darkMode = useAtomValue(darkModeAtom);
  const isLoggedIn = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );

  useEffect(() => {
    if (onChange) onChange(weekdays);
  }, [weekdays, onChange]);

  const add = (weekdayIndex: number) => {
    setWeekdays([...weekdays, weekdayIndex].sort());
  };

  const remove = (weekdayIndex: number) => {
    const index = weekdays.indexOf(weekdayIndex);
    if (index > -1)
      setWeekdays(
        weekdays.filter((val) => {
          return val !== weekdayIndex;
        }),
      );
  };

  const allWeekdays = [0, 1, 2, 3, 4, 5, 6];

  return (
    <div
      className={"weekdays-row-container ".concat(
        isLoggedIn && darkMode ? "dark-mode-weekday-picker" : "",
      )}
    >
      {allWeekdays.map((g, index) => (
        <div key={index}>
          <Pill
            className={
              weekdays.indexOf(index) === -1
                ? " weekday-pill "
                : " weekday-pill selected "
            }
            key={g}
            label={getWeekdayStringFromIndex(index)}
            onClick={() => {
              if (weekdays.indexOf(index) > -1) {
                remove(index);
              } else {
                add(index);
              }
            }}
          />
        </div>
      ))}
    </div>
  );
};
