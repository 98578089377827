import { PopoverTrigger } from "@radix-ui/react-popover";
import copyToClipboard from "copy-to-clipboard";
import { useCallback, useState } from "react";
import { useMediaQueryBreakpoint } from "../../../../../../hooks/useMediaQuery";
import useModal from "../../../../../../hooks/useModal";
import { useQueryParam } from "../../../../../../hooks/useQueryParam";
import { createUploadLink } from "../../../../../../store/actions/projects";
import { useAppDispatch } from "../../../../../../store/hooks";
import { host } from "../../../../../../store/utils/utils";
import { BasePopover } from "../../../../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../../../core-ui/components/BasePopover/PopoverContainerContext";
import { Text } from "../../../../../core-ui/components/Text/Text";
import { DropdownMenuItem } from "../../../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";
import {
  ProjectActionShareLinkButton,
  ProjectActionShareLinkContainer,
  ProjectActionShareLinkInput,
} from "./ProjectActionShareUploadLink.styles";

export interface ProjectActionShareUploadLinkProps {
  projectId: number | null;
}

/**
 * Action Option to share upload link
 * Replaces the UploadLinkGenerator component
 */
export const ProjectActionShareUploadLink = ({
  projectId,
}: ProjectActionShareUploadLinkProps) => {
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const dispatch = useAppDispatch();
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );
  const { isOpen, setIsOpen } = useModal();
  const { isMobile } = useMediaQueryBreakpoint();

  const getLink = useCallback(async () => {
    if (!projectId) return;
    setLoading(true);
    await dispatch(createUploadLink({ project_id: projectId.toString() }))
      .unwrap()
      .then((linkResponse) => {
        const generatedLink = `${host}/upload/${projectId}/?code=${linkResponse.code}`;
        setLink(generatedLink);
        if (navigator.share && isMobile) {
          navigator.share({ url: generatedLink }).catch(() => {});
        } else {
          setCopiedToClipboard(copyToClipboard(generatedLink));
        }
        setTimeout(() => {
          setCopiedToClipboard(false);
        }, 3000);
      })
      .finally(() => setLoading(false));
  }, [projectId, dispatch, isMobile]);

  const codeQuery = useQueryParam("code");
  const code = codeQuery.get();

  if (!projectId || code) return null;

  return (
    <BasePopover
      isOpen={isOpen}
      setIsPopoverOpen={setIsOpen}
      side="top"
      title="Share upload page"
      description={
        <ProjectActionShareLinkContainer>
          <Text>
            Sharing this page will enable a third party to upload files for this
            project on your behalf.
          </Text>

          <ProjectActionShareLinkButton
            onClick={getLink}
            disabled={loading || copiedToClipboard}
            loading={loading}
          >
            {copiedToClipboard ? "Copied to clipboard" : "Get upload link"}
          </ProjectActionShareLinkButton>
          {link && <ProjectActionShareLinkInput readOnly value={link} />}
        </ProjectActionShareLinkContainer>
      }
      hideFooter
      wrapperElement={containerElement}
    >
      <PopoverTrigger asChild>
        <DropdownMenuItem onSelect={(e: Event) => e.preventDefault()}>
          Share link
        </DropdownMenuItem>
      </PopoverTrigger>
    </BasePopover>
  );
};
