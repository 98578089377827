/**
 * Colors as defined in Figma.
 * Does not include Frosted Rose, Deep Pink, Muddy Brown, or Echo Orange
 */
export const standardColor = {
  White: {
    [0]: "#ffffff",
  },
  Black: {
    [0]: "#000000",
  },
  BoomyOrange: {
    [25]: "#fffbfa",
    [50]: "#fff4f1",
    [100]: "#ffe8e1",
    [200]: "#ffd4c7",
    [300]: "#ffB6a0",
    [400]: "#ff8966",
    [500]: "#f8663b",
    [600]: "#e54b1d",
    [700]: "#c13c14",
    [800]: "#9e2e0d",
    [900]: "#843118",
    [950]: "#481607",
  },
  BoxyYellow: {
    [50]: "#FEF9EC",
    [100]: "#FBEDCA",
    [200]: "#F6D378",
    [300]: "#F4C355",
    [400]: "#F1AC2E",
    [500]: "#EA8B16",
    [600]: "#CF6710",
    [700]: "#AC4811",
    [800]: "#8C3914",
    [900]: "#732F14",
    [950]: "#421606",
  },
  DeepBlue: {
    [50]: "#f1f9fe",
    [100]: "#E2F1FC",
    [200]: "#bee3f9",
    [300]: "#6ec3f2",
    [400]: "#44B2EC",
    [500]: "#1C9ADB",
    [600]: "#0E7ABB",
    [700]: "#0D6197",
    [800]: "#0F537D",
    [900]: "#124568",
    [950]: "#0C2C45",
  },
  DeepGreen: {
    [50]: "#F5F7EE",
    [100]: "#E9EDDA",
    [200]: "#D5DDB9",
    [300]: "#c4d0a1",
    [400]: "#9EB06B",
    [500]: "#80954D",
    [600]: "#64763A",
    [700]: "#4D5B30",
    [800]: "#404A2A",
    [900]: "#374027",
    [950]: "#1C2211",
  },
  Gray: {
    [25]: "#fcfcfc",
    [50]: "#f2f2f2",
    [100]: "#ececec",
    [200]: "#d2d2d2",
    [300]: "#a9a9a9",
    [400]: "#7c7c7d",
    [500]: "#5e5e5e",
    [600]: "#484848",
    [700]: "#393939",
    [800]: "#313131",
    [900]: "#2c2c2c",
    [950]: "#1e1e1e",
    [1000]: "#111111",
  },
  Green: {
    [50]: "#F1FCF2",
    [100]: "#DFF9E4",
    [200]: "#C1F1CB",
    [300]: "#90e5a3",
    [400]: "#58D073",
    [500]: "#32B550",
    [600]: "#28a745",
    [700]: "#1f7634",
    [800]: "#1E5D2D",
    [900]: "#1A4D27",
    [950]: "#092A12",
  },
  Red: {
    [50]: "#FFF0F0",
    [100]: "#FFDDDD",
    [200]: "#FFC1C1",
    [300]: "#FF9595",
    [400]: "#ff5959",
    [500]: "#ff2626",
    [600]: "#fc0606",
    [700]: "#d40000",
    [800]: "#AF0505",
    [900]: "#900C0C",
    [950]: "#500000",
  },
  Blue: {
    [50]: "#e3f2fd",
    [100]: "#bbdefb",
    [200]: "#90caf9",
    [300]: "#64b5f6",
    [400]: "#42a5f5",
    [500]: "#0866ff",
    [600]: "#1e88e5",
    [700]: "#1976d2",
    [800]: "#1565c0",
    [900]: "#0d47a1",
    [950]: "#0a326e",
  },
  EchoOrange: {
    [50]: "#FDF5EF",
    [100]: "#FBE7D9",
    [200]: "#F6CCB2",
    [300]: "#F3BA99",
    [400]: "#E97E4E",
    [500]: "#E45C2B",
    [600]: "#D54521",
    [700]: "#B1331D",
    [800]: "#8D2A1F",
    [900]: "#72261C",
    [950]: "#3D100D",
  },
};
