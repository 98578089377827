import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { Project } from "../models/project";
import {
  artistMasteringTransitions,
  artistMixingTransitions,
} from "./projects";

interface AssetReviewState {
  showAssetReviewModal: boolean;
  project?: Project;
}

interface UserInterfaceState {
  showAccountInfoModal: boolean;
  assetReview: AssetReviewState;
  showLoginModal: boolean;
}

const initialState: UserInterfaceState = {
  showAccountInfoModal: false,
  showLoginModal: false,
  assetReview: {
    showAssetReviewModal: false,
    project: undefined,
  },
};

interface AssetReviewModalAction {
  showAssetReviewModal: boolean;
  project?: Project;
}

export const userInterfaceSlice = createSlice({
  name: "userInterfaceSlice",
  initialState,
  reducers: {
    toggleLoginModal: (state) => {
      state.showLoginModal = !state.showLoginModal;
    },
    toggleAssetReviewModal: (
      state,
      action: PayloadAction<AssetReviewModalAction>,
    ) => {
      const payload = action.payload;
      const { showAssetReviewModal, project } = payload;
      state.assetReview.project = showAssetReviewModal ? project : undefined;
      state.assetReview.showAssetReviewModal =
        showAssetReviewModal && Boolean(project);
    },
    toggleShowAccountInfoModal: (state) => {
      state.showAccountInfoModal = !state.showAccountInfoModal;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        artistMasteringTransitions.fulfilled,
        artistMixingTransitions.fulfilled,
      ),
      (state) => {
        state.assetReview.showAssetReviewModal = false;
        state.assetReview.project = undefined;
      },
    );
  },
});

export const {
  toggleShowAccountInfoModal,
  toggleAssetReviewModal,
  toggleLoginModal,
} = userInterfaceSlice.actions;
export default userInterfaceSlice.reducer;
