import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { ChecklistItemResponse } from "../../../hooks/onboardingHooks/getOnboardingProgress";
import ChecklistItem from "./ChecklistItem";

export interface ChecklistViewProps {
  onboardingProgress: ChecklistItemResponse[];
}

const ChecklistView = ({ onboardingProgress }: ChecklistViewProps) => {
  const theme = useTheme();
  const firstExpandableIndex = onboardingProgress.findIndex(
    (item) => item.expandable === true && item.completed === false,
  );
  const [currentExpandedItem, setCurrentExpandedItem] = useState<
    number | boolean
  >(firstExpandableIndex);

  useEffect(() => {
    setCurrentExpandedItem(firstExpandableIndex);
  }, [firstExpandableIndex]);

  const handleChecklistItemExpand =
    (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setCurrentExpandedItem(isExpanded ? index : false);
    };

  return (
    <Box>
      {onboardingProgress.map((item, index) => (
        <ChecklistItem
          {...item}
          key={index}
          {...(index === firstExpandableIndex && { defaultExpanded: true })}
          currentExpandedItem={currentExpandedItem}
          handleChecklistItemExpand={handleChecklistItemExpand}
          itemIndex={index}
        />
      ))}
    </Box>
  );
};

export default ChecklistView;
