// @ts-ignore-next-line
import iso3311a2 from "iso-3166-1-alpha-2";
import { OptionType } from "../stories/elements/DropDownSelector/DropdownSelector";

export const getCountryIndex = (selectedCountry: string) => {
  const countries = iso3311a2.getCountries();
  for (let i = 0; i < countries.length; i++) {
    if (countries[i] === selectedCountry) {
      return i;
    }
  }
  return 0;
};

export const getCountriesAsDropdownOptions = () => {
  const countries = iso3311a2.getCountries();
  const options: Array<OptionType> = [];
  for (let i = 0; i < countries.length; i++) {
    options.push({ value: i, label: countries[i] });
  }
  return options;
};
