import styled from "styled-components";

export const TransferFileTransitionViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.backgroundColor};
`;

export const TransferFileTransitionViewButtonFooter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  > * {
    flex: 1;
  }
`;

export const TransferFileReuploadMessageContainer = styled.div`
  background: ${({ theme }) => theme.projectWorkflowMessageBackgroundColor};
  border: solid 1px ${({ theme }) => theme.projectWorkflowMessageBorderColor};
  border-radius: ${({ theme }) => theme.border.radius.md};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
  padding: 16px;
  width: 100%;
`;
