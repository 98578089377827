import _ from "lodash";
import { useMemo, useState } from "react";
import {
  AUDIO_UPLOAD_TYPE,
  FileVersion,
  GENERATED_MP3_UPLOAD_TYPE,
  MP4_UPLOAD_TYPE,
  UploadType,
  ZIP_UPLOAD_TYPE,
} from "../../../store/models/fileVersion";
import { ProjectById } from "../../../store/models/project";
import { Text } from "../../core-ui/components/Text/Text";
import { useDisabledDownloadFiles } from "../DownloadFilesView/useDisabledDownloads";
import {
  FileTableHeader,
  NoFilesView,
} from "../FileLinkTable/FileLinkTable.styles";
import { FileVersionRow } from "./FileVersionRow/FileVersionRow";
import { FileVersionSortHeader } from "./FileVersionSortHeader";

export interface FileVersionTableProps {
  fileVersions: FileVersion[];
  code?: string;
  project: ProjectById;
}

export const FileVersionTable = ({
  fileVersions,
  code,
  project,
}: FileVersionTableProps) => {
  const [field, setField] = useState<"file_name" | "upload_type">("file_name");
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const files = fileVersions.filter(
    (file) =>
      file.file_name &&
      [
        ZIP_UPLOAD_TYPE,
        AUDIO_UPLOAD_TYPE,
        MP4_UPLOAD_TYPE,
        GENERATED_MP3_UPLOAD_TYPE,
      ].includes(file?.upload_type ?? ""),
  );
  const orderedFiles = useMemo(
    () => _.orderBy(files, [field], [order]),
    [files, field, order],
  );
  const disabledFiles = useDisabledDownloadFiles(project, orderedFiles);

  return (
    <div>
      <FileTableHeader>
        <FileVersionSortHeader
          selected={field === "file_name"}
          name={"Files"}
          order={order}
          onClick={() => {
            if (field === "file_name") {
              setOrder(order === "asc" ? "desc" : "asc");
            } else {
              setField("file_name");
            }
          }}
        />
        <FileVersionSortHeader
          onClick={() => {
            if (field === "upload_type") {
              setOrder(order === "asc" ? "desc" : "asc");
            } else {
              setField("upload_type");
            }
          }}
          selected={field === "upload_type"}
          name={"File Type"}
          order={order}
        />
      </FileTableHeader>
      {orderedFiles.length === 0 && (
        <NoFilesView>
          <Text bold>Uploaded files will appear here</Text>
        </NoFilesView>
      )}
      {orderedFiles.map((file, index) => {
        // This assumes that a disabled zip file is a deliverable that requires billing info
        // The more comprehensive check is in the `useDisabledDownloadFiles` hook
        // That logic can be moved here if need be
        const isDisabledZip =
          file.upload_type === UploadType.ZIP && disabledFiles[index];
        return (
          <FileVersionRow
            disabled={disabledFiles[index]}
            disabledText={
              isDisabledZip
                ? "Billing information required to download final deliverables."
                : undefined
            }
            key={file.id}
            fileVersion={file}
            code={code}
          />
        );
      })}
    </div>
  );
};
