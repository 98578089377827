import { faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetProjectTypeToProjectTypeMetadata,
  useGetReadableServices,
} from "../../../hooks/checkoutHooks/useGetProjectTypeToProjectTypeMetadata";
import { useTransactionProjects } from "../../../hooks/transactionHook";
import useClickOutside from "../../../hooks/useClickOutside";
import { renameProjectOrScheduledProject } from "../../../store/actions/projects";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { projectTypeReadableName } from "../../../store/models/project";
import {
  Transaction,
  TransactionStatus,
} from "../../../store/models/transaction";
import User from "../../../store/models/user";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { getProfileScreenRoute } from "../../../store/utils/routeGetters";
import { ProjectListRowEditIconDiv } from "../../elements/ProjectListRow/ProjectListRow.styles";
import { ProjectList } from "../ProjectList/ProjectList";
import { RecordingBreakdown } from "../RecordingBreakdown/RecordingBreakdown";
import { SessionBookingStudioInfo } from "../SessionBookingStudioInfo/SessionBookingStudioInfo";
import { SessionsOverview } from "../SessionsOverview/SessionsOverview";
import {
  StyledTransactionBreakdownInput,
  StyledTransactionBreakdownRecordingContainer,
  StyledTransactionBreakdownSubRowContainer,
  TransactionBreakdownBody,
  TransactionBreakdownContainer,
  TransactionBreakdownDivider,
  TransactionBreakdownRow,
  TransactionBreakdownText,
  TransactionBreakdownTitle,
} from "./TransactionBreakdown.styles";

interface TransactionBreakdownProps {
  engineerUser?: User;
  transaction: Transaction;
}

interface BreakdownRowProps {
  label: string;
  value?: string | null;
  link?: string;
}

export const BreakdownRow = ({ label, value, link }: BreakdownRowProps) => (
  <TransactionBreakdownRow>
    <TransactionBreakdownText $weight>{label}:</TransactionBreakdownText>
    {value &&
      (link ? (
        <Link to={link}>
          <TransactionBreakdownText>{value}</TransactionBreakdownText>
        </Link>
      ) : (
        <TransactionBreakdownText>{value}</TransactionBreakdownText>
      ))}
  </TransactionBreakdownRow>
);

export const TransactionBreakdown = ({
  engineerUser,
  transaction,
}: TransactionBreakdownProps) => {
  const date = new Date();
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const minimalScheduledProject = useAppSelector(
    (state) => state.transactionStore.scheduledProjectData,
  );
  const dispatch = useAppDispatch();
  const [scheduledProjectTitle, setScheduledProjectTitle] = useState(
    minimalScheduledProject?.title ?? "Untitled",
  );
  const userIsEngineerUser = transaction?.user?.id === engineerUser?.id;
  const [isEditing, setIsEditing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const artistNameToDisplay = minimalScheduledProject?.artist_name
    ? minimalScheduledProject?.artist_name
    : userIsEngineerUser
      ? ""
      : getDisplayableNameForUser(transaction?.user);

  const providerUsername = getDisplayableNameForUser(engineerUser);
  const projectTypeToProjectTypeMetadata =
    useGetProjectTypeToProjectTypeMetadata(transaction);
  const readableServices = useGetReadableServices(
    projectTypeToProjectTypeMetadata,
  );
  const recordingSessions = transaction.recording_sessions ?? [];
  const firstRecordingSession = recordingSessions.length
    ? recordingSessions[0]
    : undefined;

  const projects = useTransactionProjects(transaction);

  useClickOutside(containerRef, () => setIsEditing(false));

  useEffect(() => {
    if (isEditing) return;
    if (!minimalScheduledProject) return;
    if (minimalScheduledProject.title === scheduledProjectTitle) return;
    dispatch(
      renameProjectOrScheduledProject({
        scheduled_project_id: minimalScheduledProject.id,
        title: scheduledProjectTitle,
        transaction_code: transaction.code,
      }),
    )
      .catch(() => toast.error("Failed to update project title"))
      .finally(() => {
        setIsEditing(false);
      });
  }, [isEditing, minimalScheduledProject, scheduledProjectTitle]);
  const hideScheduledProjectTitleForHandoff = Boolean(
    transaction.transaction_status === TransactionStatus.PENDING &&
      projects[0]?.is_project_handoff,
  );
  // TODO: Extract this into a separate component
  const scheduledProjectBreakdown = () => {
    return (
      <>
        <TransactionBreakdownRow>
          <TransactionBreakdownText>{`Project ID: ${minimalScheduledProject?.id ?? transaction.id}`}</TransactionBreakdownText>
          <TransactionBreakdownText $secondary>
            {formattedDate}
          </TransactionBreakdownText>
        </TransactionBreakdownRow>
        <TransactionBreakdownBody>
          <BreakdownRow label="Artist" value={artistNameToDisplay} />
          {!(
            hideScheduledProjectTitleForHandoff &&
            transaction.transaction_status === TransactionStatus.PENDING
          ) && (
            <TransactionBreakdownRow>
              <TransactionBreakdownText $weight>
                Project:
              </TransactionBreakdownText>
              <StyledTransactionBreakdownSubRowContainer ref={containerRef}>
                <StyledTransactionBreakdownInput
                  type="text"
                  disabled={!isEditing}
                  value={scheduledProjectTitle}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setScheduledProjectTitle(e.target.value)
                  }
                />
                <ProjectListRowEditIconDiv>
                  <FontAwesomeIcon
                    icon={isEditing ? faCheck : faPen}
                    size="sm"
                    color={
                      isEditing
                        ? "var(--success-color)"
                        : "var(--text-primary-color)"
                    }
                    onClick={() => setIsEditing(!isEditing)}
                  />
                </ProjectListRowEditIconDiv>
              </StyledTransactionBreakdownSubRowContainer>
            </TransactionBreakdownRow>
          )}
          <BreakdownRow label="Services" value={readableServices} />
          <BreakdownRow
            label="Service provider"
            value={providerUsername}
            link={getProfileScreenRoute(engineerUser?.username ?? "")}
          />
          {[...projectTypeToProjectTypeMetadata.entries()].map(
            ([projectType, projectData], key) => {
              return (
                <>
                  <BreakdownRow
                    key={key}
                    label={`Total ${projectTypeReadableName.get(projectType)} tracks`}
                    value={projectData.count.toString()}
                  />
                  {Boolean(projectData.altMetadata.length) && (
                    <BreakdownRow
                      label={`Total ${projectTypeReadableName.get(projectType)} alt tracks`}
                      value={(
                        projectData.altMetadata[0].alts.length *
                        projectData.count
                      ).toString()}
                    />
                  )}
                </>
              );
            },
          )}
        </TransactionBreakdownBody>
      </>
    );
  };

  return (
    <TransactionBreakdownContainer>
      <TransactionBreakdownTitle>Booking details</TransactionBreakdownTitle>
      {!recordingSessions.length && scheduledProjectBreakdown()}
      {!recordingSessions.length && (
        <ProjectList projects={projects} transactionCode={transaction.code} />
      )}
      {Boolean(recordingSessions.length) &&
        recordingSessions?.map((session, index) => (
          <>
            <RecordingBreakdown
              key={index}
              recordingSession={session}
              index={index}
            />
            <TransactionBreakdownDivider />
          </>
        ))}
      {firstRecordingSession && (
        <StyledTransactionBreakdownRecordingContainer>
          <SessionsOverview recordingSessions={recordingSessions} />
          <SessionBookingStudioInfo
            recordingLocation={firstRecordingSession.recording_location}
            cancellationPolicy={firstRecordingSession.cancellation_policy}
            studioRoom={firstRecordingSession.studio_room}
            revealLocation={
              TransactionStatus.PAID === transaction.transaction_status
            }
          />
        </StyledTransactionBreakdownRecordingContainer>
      )}
    </TransactionBreakdownContainer>
  );
};
