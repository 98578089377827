import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  makeBackendDeleteCallWithJsonResponse,
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
  makeBackendPutCallWithJsonResponse,
} from "../utils/fetch";
import { GET_ENTITY_PHOTOS, ENTITY_PHOTO_API } from "../utils/routes";
import { receiveErrors } from "./errorStore";
import EntityPhoto from "../models/entityPhoto";
import { SupportedEntityTypes } from "../../hooks/useEntityPhotos";
import queryString from "query-string";

export interface getEntityPhotosParams {
  id: number;
  entity_photo_type: SupportedEntityTypes;
  page?: number;
}

export interface PaginatedEntityPhotoDataPayload {
  page: number;
  total_pages: number;
  total_photos: number;
  photos: EntityPhoto[];
}

export const getEntityPhotos = createAsyncThunk(
  GET_ENTITY_PHOTOS,
  async (args: getEntityPhotosParams, thunkAPI) => {
    const queryParams = queryString.stringify(
      {
        id: args.id,
        entity_type: args.entity_photo_type,
        page: args.page,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );

    const result =
      await makeBackendGetCallWithJsonResponse<PaginatedEntityPhotoDataPayload>(
        GET_ENTITY_PHOTOS,
        `?${queryParams}`,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface updateEntityPhotosParam {
  photo_id?: number;
  entity_id: number;
  entity_type: SupportedEntityTypes;
  data?: string;
}

export const updateEntityPhoto = createAsyncThunk(
  ENTITY_PHOTO_API.concat("/update"),
  async (args: updateEntityPhotosParam, thunkAPI) => {
    const result = await makeBackendPutCallWithJsonResponse<EntityPhoto>(
      ENTITY_PHOTO_API.concat(`${args.photo_id}`),
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const createEntityPhoto = createAsyncThunk(
  ENTITY_PHOTO_API.concat("/create"),
  async (args: updateEntityPhotosParam, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<EntityPhoto>(
      ENTITY_PHOTO_API,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const deleteEntityPhoto = createAsyncThunk(
  ENTITY_PHOTO_API.concat("/delete"),
  async (args: updateEntityPhotosParam, thunkAPI) => {
    const result = await makeBackendDeleteCallWithJsonResponse<EntityPhoto>(
      ENTITY_PHOTO_API.concat(`${args.photo_id}`),
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface PhotosByPage {
  [page: number]: EntityPhoto[];
}

export interface PaginatedEntityPhotoData {
  page: number;
  total_pages: number;
  total_photos: number;
  photos: PhotosByPage;
}

export interface EntityPhotoResults {
  [key: number]: PaginatedEntityPhotoData;
}

interface EntityPhotoState {
  studioPhotos: EntityPhotoResults;
  studioRoomPhotos: EntityPhotoResults;
}

const initialState: EntityPhotoState = {
  studioPhotos: {},
  studioRoomPhotos: {},
};

const entityPhotoSlice = createSlice({
  name: "entityPhotos",
  initialState,
  reducers: {
    removeEntityPhoto(
      state,
      action: PayloadAction<{
        photoId: number;
        entityId: number;
        entityType: SupportedEntityTypes;
      }>,
    ) {
      const { photoId: photo_id } = action.payload;
      const { entityId, entityType } = action.payload;
      const entityPhotos =
        entityType === SupportedEntityTypes.Studio
          ? state.studioPhotos
          : state.studioRoomPhotos;
      const entityPhotosData = entityPhotos[entityId];
      if (entityPhotosData) {
        const indices = Object.keys(entityPhotosData.photos)
          .reduce((acc, curr) => {
            acc.push(+curr);
            return acc;
          }, [] as number[])
          .sort();
        indices.forEach((index) => {
          const pagePhotos = entityPhotosData.photos[index];
          if (pagePhotos.some((photo) => photo.id === photo_id)) {
            entityPhotosData.total_photos -= 1;
            entityPhotosData.photos[index] = entityPhotosData.photos[
              index
            ].filter((photo) => photo.id !== photo_id);
          }
        });
      }
      if (entityType === SupportedEntityTypes.StudioRoom) {
        const studioState = state.studioPhotos;
        const studioIndices = Object.keys(studioState).reduce((acc, curr) => {
          acc.push(+curr);
          return acc;
        }, [] as number[]);

        studioIndices.forEach((studio_id_index) => {
          if (studioState[studio_id_index]) {
            const studioData = studioState[studio_id_index];
            const pages = Object.keys(studioData.photos).reduce(
              (acc, page_index) => {
                acc.push(+page_index);
                return acc;
              },
              [] as number[],
            );
            pages.forEach((pageIndex) => {
              if (studioData.photos[pageIndex]) {
                studioData.photos[pageIndex] = studioData.photos[
                  pageIndex
                ].filter((pagePhoto) => pagePhoto.id !== photo_id);
              }
            });
          }
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateEntityPhoto.fulfilled, (state, action) => {
      const {
        entity_id: entityId,
        entity_type: entityType,
        photo_id: photoId,
      } = action.meta.arg;
      const updatedPhoto = action.payload;
      const entityPhotosState =
        entityType === SupportedEntityTypes.Studio
          ? state.studioPhotos
          : state.studioRoomPhotos;
      const photos = entityPhotosState[entityId]?.photos;
      if (!photos) return;
      Object.keys(photos).forEach((page: string) => {
        const pageKey = +page;
        photos[pageKey] = photos[pageKey].map((photo) => {
          if (photo.id === photoId) {
            return updatedPhoto;
          }
          return photo;
        });
      });
    });
    builder.addCase(deleteEntityPhoto.fulfilled, (state, action) => {
      const {
        entity_id: entityId,
        entity_type: entityType,
        photo_id: photoId,
      } = action.meta.arg;
      const entityPhotosState =
        entityType === SupportedEntityTypes.Studio
          ? state.studioPhotos
          : state.studioRoomPhotos;
      const photos = entityPhotosState[entityId]?.photos;
      if (!photos) return;
      Object.keys(photos).forEach((page: string) => {
        const pageKey = +page;
        photos[pageKey] = photos[pageKey].filter(
          (photo) => photo.id !== photoId,
        );
      });
    });
    builder.addCase(createEntityPhoto.fulfilled, (state, action) => {
      const { entity_id: entityId, entity_type: entityType } = action.meta.arg;
      const entityPhotosState =
        entityType === SupportedEntityTypes.Studio
          ? state.studioPhotos
          : state.studioRoomPhotos;
      const photos = entityPhotosState[entityId]?.photos;
      if (!photos) return;
      Object.keys(photos).forEach((page: string) => {
        const pageKey = +page;
        photos[pageKey] = [...photos[pageKey], action.payload];
      });
    });
    builder.addCase(getEntityPhotos.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const { id, entity_photo_type: entity_type } = meta.arg;
      const page = payload.page;
      const entityPhotosState =
        entity_type === SupportedEntityTypes.Studio
          ? state.studioPhotos
          : state.studioRoomPhotos;

      const existingPhotos = entityPhotosState[id]?.photos || [];
      entityPhotosState[id] = {
        photos: existingPhotos ?? {},
        page: page,
        total_pages: payload.total_pages,
        total_photos: payload.total_photos,
      };
      const photosByPage = entityPhotosState[id].photos;
      if (page === 1) {
        entityPhotosState[id].photos = {};
        entityPhotosState[id].photos[page] = payload.photos;
      } else {
        photosByPage[page] = payload.photos;
      }
    });
  },
});
export const { removeEntityPhoto } = entityPhotoSlice.actions;
export default entityPhotoSlice.reducer;
