import { useMutation } from "@tanstack/react-query";
import revokeStreamToken from "./revokeStreamToken";
import { useSetAtom } from "jotai";
import { streamTokenAtom } from "./atoms";

const useRevokeTokenMutation = () => {
  const setStreamToken = useSetAtom(streamTokenAtom);
  return useMutation({
    mutationKey: ["revoke_token"],
    mutationFn: async () => {
      setStreamToken("");
      return revokeStreamToken()
        .then(async (r) => {
          localStorage.removeItem("token");
          localStorage.removeItem("darkMode");
          localStorage.removeItem("selectedProfile");
          return r.success;
        })
        .catch(() => {
          return false;
        });
    },
  });
};

export default useRevokeTokenMutation;
