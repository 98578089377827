import React, { ForwardedRef, forwardRef } from "react";
import "./VideoPlayer.css";

export interface VideoPlayerProps {
  mp4File?: string;
  webMFile?: string;
  width?: string;
}

export const VideoPlayer = forwardRef(
  (
    { mp4File, webMFile, width = "250" }: VideoPlayerProps,
    ref: ForwardedRef<HTMLVideoElement>,
  ) => (
    <video
      ref={ref}
      controls
      width={width}
      height={"100%"}
      controlsList="nodownload"
      className="video-player"
    >
      {webMFile && <source src={webMFile} type="video/webm" />}
      {mp4File && <source src={mp4File} type="video/mp4" />}
      Sorry, your browser doesn't support embedded videos.
    </video>
  ),
);
