import { createSlice, PayloadAction, Draft } from "@reduxjs/toolkit";
interface SelectedCommentState {
  commentIdentifier?: string;
  show: boolean;
  start?: number;
  end?: number;
  isUpdating?: boolean;
}

const initialState: SelectedCommentState = {
  commentIdentifier: undefined,
  show: false,
  isUpdating: false,
};

export const SelectedCommentStateSlice = createSlice({
  name: "SelectedCommentStateSlice",
  initialState,
  reducers: {
    selectComment: (
      state: Draft<SelectedCommentState>,
      action: PayloadAction<SelectedCommentState>,
    ) => {
      state.commentIdentifier = action.payload.commentIdentifier;
      state.show = action.payload.show;
      state.start = action.payload.start;
      state.end = action.payload.end;
      state.isUpdating = action.payload.isUpdating;
    },
    resetSelectCommentState: () => initialState,
  },
});

export const { selectComment, resetSelectCommentState } =
  SelectedCommentStateSlice.actions;

export default SelectedCommentStateSlice.reducer;
