import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProjectType } from "../models/project";
import { RecordingService } from "../models/recording";
import Service from "../models/service";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import {
  AVAILABLE_SESSION_ENGINEERS,
  LIST_ENGINEER_RECOMMENDATIONS,
  SEARCH_ENGINEER_BY_SERVICE,
} from "../utils/routes";
import { receiveErrors } from "./errorStore";

interface AvailableEngineerService extends RecordingService {
  studioAffiliated: boolean;
}

interface AvailableEngineersMap {
  [datetimeAndDuration: string]: AvailableEngineerService[];
}

export interface EngineerRecommendationState {
  recommendedServices: Service[];
  availableRecordingEngineers: AvailableEngineersMap;
  loading: boolean;
}

const initialState: EngineerRecommendationState = {
  recommendedServices: [],
  availableRecordingEngineers: {},
  loading: false,
};

interface FetchEngineerRecommendationParam {
  project_id: number;
}

interface RecommendedServicesPayload {
  results: Service[];
}

export const fetchEngineerRecommendations = createAsyncThunk(
  LIST_ENGINEER_RECOMMENDATIONS,
  async ({ project_id }: FetchEngineerRecommendationParam, thunkAPI) => {
    const params = `?project_id=${project_id}`;
    const result =
      await makeBackendGetCallWithJsonResponse<RecommendedServicesPayload>(
        LIST_ENGINEER_RECOMMENDATIONS,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface AvailableSessionEngineersPayload {
  affiliated: RecordingService[];
  unaffiliated: RecordingService[];
}

export interface FetchAvailableRecordingEngineersParam {
  studio_room_id: number;
  start_datetime?: string;
  session_duration_minutes?: number;
}

export const fetchAvailableRecordingEngineers = createAsyncThunk(
  AVAILABLE_SESSION_ENGINEERS,
  async (args: FetchAvailableRecordingEngineersParam, thunkAPI) => {
    let params = `?studio_room_id=${args.studio_room_id}`;
    if (args.start_datetime) {
      params += `&start_datetime=${args.start_datetime}`;
    }
    if (args.session_duration_minutes) {
      params += `&session_duration_minutes=${args.session_duration_minutes}`;
    }
    const result =
      await makeBackendGetCallWithJsonResponse<AvailableSessionEngineersPayload>(
        AVAILABLE_SESSION_ENGINEERS,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface SearchEngineerByServiceParam {
  search_term: string;
  service_type: ProjectType;
}

export const searchEngineerByService = createAsyncThunk(
  SEARCH_ENGINEER_BY_SERVICE,
  async (
    { search_term, service_type }: SearchEngineerByServiceParam,
    thunkAPI,
  ) => {
    const params = `?search_term=${search_term}&service_type=${service_type}`;
    const result = await makeBackendGetCallWithJsonResponse<Service[]>(
      SEARCH_ENGINEER_BY_SERVICE,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const engineerRecommendationStore = createSlice({
  name: "engineerRecommendationStore",
  initialState,
  reducers: {
    clearRecommendations: (state) => {
      state.loading = false;
      state.recommendedServices = [];
    },
    clearAvailableEngineers: (state) => {
      state.loading = false;
      state.availableRecordingEngineers = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAvailableRecordingEngineers.pending,
      (state, action) => {
        if (
          !action.meta.arg.start_datetime ||
          !action.meta.arg.session_duration_minutes
        ) {
          const studioRoomId = action.meta.arg.studio_room_id;
          state.loading = true;
          if (!state.availableRecordingEngineers[studioRoomId]) {
            state.availableRecordingEngineers = {
              ...state.availableRecordingEngineers,
              [studioRoomId]: [],
            };
          }
          return;
        }
        const key =
          action.meta.arg?.start_datetime +
          action.meta.arg?.session_duration_minutes;
        state.loading = true;
        if (!state.availableRecordingEngineers[key]) {
          state.availableRecordingEngineers = {
            ...state.availableRecordingEngineers,
            [key]: [],
          };
        }
      },
    );
    builder.addCase(fetchEngineerRecommendations.pending, (state) => {
      state.loading = true;
      state.recommendedServices = [];
    });
    builder.addCase(
      fetchAvailableRecordingEngineers.rejected,
      (state, action) => {
        if (
          !action.meta.arg.start_datetime ||
          !action.meta.arg.session_duration_minutes
        ) {
          const studioRoomId = action.meta.arg.studio_room_id;
          state.loading = false;
          state.availableRecordingEngineers = {
            ...state.availableRecordingEngineers,
            [studioRoomId]: [],
          };
          return;
        }
        const key =
          action.meta.arg.start_datetime +
          action.meta.arg.session_duration_minutes;
        state.loading = false;
        state.availableRecordingEngineers = {
          ...state.availableRecordingEngineers,
          [key]: [],
        };
      },
    );
    builder.addCase(fetchEngineerRecommendations.rejected, (state) => {
      state.loading = false;
      state.recommendedServices = [];
    });
    builder.addCase(searchEngineerByService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchEngineerByService.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(searchEngineerByService.fulfilled, (state, action) => {
      state.loading = false;
      state.recommendedServices = action.payload;
    });
    builder.addCase(fetchEngineerRecommendations.fulfilled, (state, action) => {
      state.loading = false;
      state.recommendedServices = action.payload.results;
    });
    builder.addCase(
      fetchAvailableRecordingEngineers.fulfilled,
      (state, action) => {
        state.loading = false;
        const payload = action.payload;
        if (
          !action.meta.arg.start_datetime ||
          !action.meta.arg.session_duration_minutes
        ) {
          const studioRoomId = action.meta.arg.studio_room_id;
          state.loading = false;
          state.availableRecordingEngineers[studioRoomId] = [
            ...payload.affiliated.map((service) => ({
              ...service,
              studioAffiliated: true,
            })),
            ...payload.unaffiliated.map((service) => ({
              ...service,
              studioAffiliated: false,
            })),
          ];
          return;
        }

        const key =
          action.meta.arg.start_datetime +
          action.meta.arg.session_duration_minutes;
        state.availableRecordingEngineers[key] = [
          ...payload.affiliated.map((service) => ({
            ...service,
            studioAffiliated: true,
          })),
          ...payload.unaffiliated.map((service) => ({
            ...service,
            studioAffiliated: false,
          })),
        ];
      },
    );
  },
});

export const { clearRecommendations, clearAvailableEngineers } =
  engineerRecommendationStore.actions;
export default engineerRecommendationStore.reducer;
