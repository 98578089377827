import {
  SUBSCRIPTION_PLAN,
  subscriptionFeatureList,
} from "../../../store/models/subscription";
import { SubsPlanFeatureList } from "../../elements/SubsPlanFeatureList/SubsPlanFeatureList";
import {
  SubscriptionComparisonTableColumn,
  SubscriptionFeatureItem,
  SubscriptionFeatureItemDivider,
  SubscriptionFeatureList,
} from "./SubscriptionComparisonTable.styles";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Box } from "@mui/material";
import { forwardRef } from "react";

interface SubscriptionComparisonTableProps {
  refundFees?: boolean;
  onSubscriptionSelected: (selected: SUBSCRIPTION_PLAN) => void;
}

export const SubscriptionComparisonTable = forwardRef<
  HTMLDivElement,
  SubscriptionComparisonTableProps
>(({ refundFees, onSubscriptionSelected }, ref) => (
  <Box ref={ref} sx={{ display: "flex", alignItems: "flex-start" }}>
    <SubscriptionComparisonTableColumn>
      <SubscriptionFeatureList>
        <SubscriptionFeatureItem>
          <Text variant={TextStyleVariant.S2}>Feature</Text>
        </SubscriptionFeatureItem>
        <SubscriptionFeatureItemDivider />
        {subscriptionFeatureList.map((feature: string) => {
          return (
            <>
              <SubscriptionFeatureItem key={feature}>
                <Text variant={TextStyleVariant.P2}>{feature}</Text>
              </SubscriptionFeatureItem>
              <SubscriptionFeatureItemDivider />
            </>
          );
        })}
      </SubscriptionFeatureList>
    </SubscriptionComparisonTableColumn>
    <SubsPlanFeatureList
      plan={SUBSCRIPTION_PLAN.ESSENTIALS_PLAN}
      refundFees={refundFees}
      onSubscriptionSelected={onSubscriptionSelected}
    />
    <SubsPlanFeatureList
      plan={SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY}
      refundFees={refundFees}
      onSubscriptionSelected={onSubscriptionSelected}
    />
  </Box>
));

SubscriptionComparisonTable.displayName = "SubscriptionComparisonTable";
