import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAcceptedFileTypes } from "../../../hooks/useAcceptedFileTypes";
import { useCurrentProjectHasAlts } from "../../../hooks/useCurrentProjectHasAlts";
import {
  usePendingFiles,
  useUploadedFileSinceLastTransition,
} from "../../../hooks/useProjectFilesUploaded";
import { useUploadInstructionText } from "../../../hooks/useUploadInstructionText";
import { fetchFiles } from "../../../store/actions/fileVersions";
import { useAppDispatch } from "../../../store/hooks";
import { UploadTypeOptions } from "../../../store/models/alts";
import { FILE_STATUS } from "../../../store/models/fileVersion";
import {
  BaseProject,
  MasteringProjectSteps,
  MixingProjectSteps,
  Project,
  ProjectById,
} from "../../../store/models/project";
import { SessionSteps } from "../../../store/models/recordingSession";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { FileLinkUploader } from "../FileLinkInput/FileLinkUploader";
import { Uploader } from "../Uploader/Uploader";
import { FileUploaderContainer } from "./FileUploader.styles";
import { UploadedFileList } from "./UploadedFileList/UploadedFileList";
import useOnDrop from "./useOnDrop";

export interface FileUploaderProps {
  isCurrentProjectEngineer: boolean;
  engineerIsUploadingOnBehalfOfArtist?: boolean;
  showHeader?: boolean;
  showLinkUploader?: boolean;
  isLabelProject?: boolean;
  isPaymentPending?: boolean;
  project: Project | ProjectById | BaseProject;
  projectStep: MixingProjectSteps | MasteringProjectSteps | SessionSteps;
  disableUpload?: boolean;
  recordingSessionBookingId?: number | null;
}

export const getFileExtension = (file: File) => {
  const extensionIdx = file.name.lastIndexOf(".");
  return file.name.slice(extensionIdx);
};

export const FileUploader = ({
  isCurrentProjectEngineer,
  engineerIsUploadingOnBehalfOfArtist = false,
  project,
  projectStep,
  showLinkUploader = true,
  disableUpload = false,
  recordingSessionBookingId,
}: FileUploaderProps) => {
  const { service_type: projectType, id: projectId } = project;
  const uploadedFiles = useUploadedFileSinceLastTransition(project);
  const pendingFiles = usePendingFiles(project);
  const dispatch = useAppDispatch();
  const [hasAlts] = useCurrentProjectHasAlts(project);
  const uploadInstructionText = useUploadInstructionText(
    projectStep,
    projectType,
    hasAlts,
  );
  const [currentFileUploadName, setCurrentFileUploadName] = useState("");
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const code: null | string = query.get("code");
  const [uploadAlt, setUploadType] = useState<OptionType>(UploadTypeOptions[0]);

  const acceptedFileTypes: string[] = useAcceptedFileTypes(
    projectType,
    projectStep,
    hasAlts,
  );

  const [onDrop, { progress }] = useOnDrop({
    code: code ?? "",
    isCurrentProjectEngineer,
    engineerIsUploadingOnBehalfOfArtist,
    projectId,
    uploadAltValue: uploadAlt.value,
    recordingSessionBookingId,
  });

  const handleOnDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const file = acceptedFiles[0];
        setCurrentFileUploadName(file.name);
        await onDrop(acceptedFiles);
      } catch (e) {
        toast.error(
          "Something went wrong with your upload. Reach out for support.",
        );
      }
    },
    [onDrop],
  );

  useEffect(() => {
    void dispatch(
      fetchFiles({
        projectId,
        status: FILE_STATUS.FILE_UPLOADED,
      }),
    );
  }, [dispatch, projectId]);

  const getUploadInProgress = () => progress > 0 && progress < 100;

  if (!project) return null;

  return (
    <FileUploaderContainer>
      <Uploader
        uploadAlt={uploadAlt}
        setUploadType={setUploadType}
        project={project}
        headerText={
          getUploadInProgress()
            ? `Uploading ${currentFileUploadName}...`
            : "Browse for files"
        }
        instructionText={
          getUploadInProgress()
            ? "Please wait for your upload to complete."
            : uploadInstructionText
        }
        acceptedFiles={acceptedFileTypes}
        uploaderDisabled={disableUpload || getUploadInProgress()}
        onDrop={handleOnDrop}
        isEngineer={isCurrentProjectEngineer}
      />
      {showLinkUploader && !isCurrentProjectEngineer && (
        <FileLinkUploader
          projectId={projectId}
          reference={
            !isCurrentProjectEngineer || engineerIsUploadingOnBehalfOfArtist
          }
          code={code ?? undefined}
          disableUpload={disableUpload}
        />
      )}
      <UploadedFileList
        projectId={projectId}
        uploadedFiles={[...uploadedFiles, ...pendingFiles]}
        progress={progress}
      />
    </FileUploaderContainer>
  );
};
