import { ReactNode } from "react";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { Text } from "../../core-ui/components/Text/Text";
import {
  AlbumIconContainer,
  AlbumIconImg,
  AlbumIconText,
  ColumnContainer,
  FeaturedTrackRoleContainer,
  MusoCreditInfoColumnContainer,
  MusoCreditInfoRoleContainer,
  AlbumIconImageContainer,
} from "./AlbumIcon.styles";
import { formatDateToShortStringFormat } from "../../../store/utils/dateTimeUtils";
import { useCurrentProgressForPlaylistTrackId } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { getPaddingForPlayPauseButton } from "../../components/ReleasePlayPauseButton/ReleasePlayPauseButton";
import { PlayAudioButton } from "../../components/PlayAudioButton/PlayAudioButton";
import { useAppSelector } from "../../../store/hooks";
import { Box } from "@mui/material";
import { isCurrentTrackPlaying } from "../../../store/selectors/abPlayerSelectors";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

interface AlbumIconPlayPauseButtonProps {
  id: number;
  onClick?: () => void;
  size?: number;
}

const AlbumIconPlayPauseButton = ({
  id,
  onClick,
  size = 24,
}: AlbumIconPlayPauseButtonProps) => {
  const isTrackPlaying = useAppSelector(isCurrentTrackPlaying(id));
  const darkMode = useAtomValue(darkModeAtom);
  const padding = getPaddingForPlayPauseButton(size);
  const progress = useCurrentProgressForPlaylistTrackId(id);

  return (
    <PlayAudioButton
      progress={progress}
      isPlaying={isTrackPlaying}
      handleClick={onClick}
      size={size}
      padding={padding}
      invertColor={darkMode}
    />
  );
};

interface FeaturedTrackInfoProps {
  trackTitle: string;
  albumTitle: string;
  readableProjectType: string;
}

export const FeaturedTrackInfo = ({
  trackTitle,
  albumTitle,
  readableProjectType,
}: FeaturedTrackInfoProps) => {
  return (
    <ColumnContainer>
      <AlbumIconText bold variant={TextStyleVariant.P2}>
        {trackTitle}
      </AlbumIconText>
      {albumTitle && (
        <AlbumIconText variant={TextStyleVariant.P3}>
          {albumTitle}
        </AlbumIconText>
      )}
      <FeaturedTrackRoleContainer>
        {readableProjectType && (
          <AlbumIconText bold variant={TextStyleVariant.P4}>
            {readableProjectType}
          </AlbumIconText>
        )}
      </FeaturedTrackRoleContainer>
    </ColumnContainer>
  );
};

interface MusoCreditInfoProps {
  trackTitle: string;
  artistName: string;
  releaseDate: string;
  role: string;
}

export const MusoCreditInfo = ({
  trackTitle,
  artistName,
  releaseDate,
  role,
}: MusoCreditInfoProps) => {
  return (
    <MusoCreditInfoColumnContainer>
      <AlbumIconText
        style={{ lineHeight: "normal" }}
        bold
        variant={TextStyleVariant.P1}
      >
        {trackTitle}
      </AlbumIconText>
      <ColumnContainer>
        <AlbumIconText
          style={{ lineHeight: "normal" }}
          variant={TextStyleVariant.P1}
        >
          {artistName}
        </AlbumIconText>
        <Text
          variant={TextStyleVariant.P2}
          style={{ lineHeight: "normal" }}
          color={TextColor.SECONDARY}
        >
          {formatDateToShortStringFormat(releaseDate)}
        </Text>
        <MusoCreditInfoRoleContainer>
          <Text
            style={{ lineHeight: "normal" }}
            bold
            variant={TextStyleVariant.P4}
          >
            {role}
          </Text>
        </MusoCreditInfoRoleContainer>
      </ColumnContainer>
    </MusoCreditInfoColumnContainer>
  );
};

interface AlbumIconProps {
  id: number;
  children: ReactNode;
  imageSource: string;
  albumName: string;
  isPortfolio?: boolean;
  onClick?: () => void;
  showPlay?: boolean;
}

export const AlbumIcon = ({
  id,
  children,
  imageSource,
  albumName,
  isPortfolio = false,
  onClick,
  showPlay = true,
}: AlbumIconProps) => {
  return (
    <AlbumIconContainer $isPortfolio={isPortfolio}>
      <AlbumIconImageContainer onClick={onClick}>
        {showPlay && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <AlbumIconPlayPauseButton onClick={onClick} id={id} />
          </Box>
        )}
        <AlbumIconImg src={imageSource} alt={`Album cover for ${albumName}`} />
      </AlbumIconImageContainer>
      {children}
    </AlbumIconContainer>
  );
};
