import { styled, Grid, Box } from "@mui/material";

export const SelectMusoCreditSearchContainer = styled(Grid)({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 15px",
  position: "relative",
  rowGap: "16px",
});

export const MusoTableButtonGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));

export const MusoTableBottomRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: "100%",
  alignItems: "center",
}));

export const MusoBottomRowButtons = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  justifyContent: "center",

  alignItems: "center",
}));

export const MusoSearchCreditCount = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: 0,
  right: 0,
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    position: "unset",
  },
}));
