import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { MixMasterOrderSummary } from "../../../store/actions/mixMasterCartsStore";
import { RecordingOrderSummary } from "../../../store/actions/recordingCartsStore";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { Text } from "../../core-ui/components/Text/Text";
import { ColorPalette } from "../../theme";
import {
  BillingInfoVerificationView,
  BudgetManagerModalBoldP,
  BudgetManagerModalP,
  BudgetManagerRow,
} from "../BudgetManagerModals/BudgetManagerModals.styles";
import { OrderBreakdown } from "../OrderBreakdown/OrderBreakdown";
import { BillingInfoParamsMap } from "./BillingInfoModals";
import {
  BillingInfoInput,
  BillingInfoInputContainer,
  RequestBudgetApprovalModalBodyContainer,
  RequestBudgetApprovalModalText,
  SuccessTextContainer,
} from "./BillingInfoModals.styles";
import { PurchaseOrderBillingInfoSteps } from "./utils";
import { useAppSelector } from "../../../store/hooks";
import { useIsUMGAandR } from "../../../hooks/purchaseOrderWithTransactionHooks";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";

interface BillingInfoModalBodyProps {
  step: PurchaseOrderBillingInfoSteps;
  projectAdminEmails: string[];
  billingInfoParams: BillingInfoParamsMap;
  recordingOrderSummaryIsLoading: boolean;
  mixMasterOrderSummaryIsLoading: boolean;
  recordingOrderSummary: RecordingOrderSummary | null;
  mixMasterOrderSummary: MixMasterOrderSummary | null;
  setBillingInfoParams: Dispatch<SetStateAction<BillingInfoParamsMap>>;
}

const BillingInfoModalBody = ({
  step,
  projectAdminEmails,
  billingInfoParams,
  recordingOrderSummaryIsLoading,
  mixMasterOrderSummaryIsLoading,
  recordingOrderSummary,
  mixMasterOrderSummary,
  setBillingInfoParams,
}: BillingInfoModalBodyProps) => {
  const billingInfoArray = Object.values(billingInfoParams);

  // create object to show PO or Billing Info inputs based on PO ID
  const showInputsByPurchaseOrderId: Record<number, boolean> = {};
  billingInfoArray.forEach((purchaseOrder) => {
    const hasBillingInfo = Boolean(
      purchaseOrder.cost_center &&
        purchaseOrder.work_breakdown_structure &&
        purchaseOrder.general_ledger,
    );
    showInputsByPurchaseOrderId[purchaseOrder.purchase_order_id] =
      !hasBillingInfo;
  });
  // stores which inputs are being shown by PO ID
  const [showPurchaseOrderInputs, setShowPurchaseOrderInputs] = useState(
    showInputsByPurchaseOrderId,
  );

  const { transactionData: transaction } = useAppSelector(
    (state) => state.transactionStore,
  );
  const showBillingInfoOption = useIsUMGAandR(transaction);
  if (step === PurchaseOrderBillingInfoSteps.BILLING_INFO_SUBMITTED) {
    return (
      <>
        <SuccessTextContainer>
          <FontAwesomeIcon icon={faCheck} color={ColorPalette.Green600} />
          <RequestBudgetApprovalModalText isBold>
            Billing information submitted.
          </RequestBudgetApprovalModalText>
        </SuccessTextContainer>
        <RequestBudgetApprovalModalBodyContainer>
          <RequestBudgetApprovalModalText isBold>
            Project Administrators:
          </RequestBudgetApprovalModalText>
          {projectAdminEmails.map((email, index) => (
            <RequestBudgetApprovalModalText key={index}>
              {email}
            </RequestBudgetApprovalModalText>
          ))}
        </RequestBudgetApprovalModalBodyContainer>
        <BudgetManagerModalP>No further actions required.</BudgetManagerModalP>
      </>
    );
  }
  if (step === PurchaseOrderBillingInfoSteps.CONFIRM_BILLING_INFO) {
    return (
      <>
        <BudgetManagerModalP>
          Is the following billing information correct?
        </BudgetManagerModalP>
        {billingInfoArray.map((billingInfoParam) => {
          const purchaseOrderId = billingInfoParam.purchase_order_id;
          return (
            <BillingInfoVerificationView key={purchaseOrderId}>
              <Text bold>
                {billingInfoParam.eventually_owed_to}:{" "}
                {PennyDollarFormatter().format(billingInfoParam.total)}
              </Text>
              {billingInfoParam.order_number && (
                <BudgetManagerRow>
                  <BudgetManagerModalP>Purchase Order:</BudgetManagerModalP>
                  <BudgetManagerModalBoldP>
                    {billingInfoParam.order_number}
                  </BudgetManagerModalBoldP>
                </BudgetManagerRow>
              )}
              {billingInfoParam.cost_center && (
                <BudgetManagerRow>
                  <BudgetManagerModalP>Cost Center:</BudgetManagerModalP>
                  <BudgetManagerModalBoldP>
                    {billingInfoParam.cost_center}
                  </BudgetManagerModalBoldP>
                </BudgetManagerRow>
              )}
              {billingInfoParam.work_breakdown_structure && (
                <BudgetManagerRow>
                  <BudgetManagerModalP>
                    Work Breakdown Structure:
                  </BudgetManagerModalP>
                  <BudgetManagerModalBoldP>
                    {billingInfoParam.work_breakdown_structure}
                  </BudgetManagerModalBoldP>
                </BudgetManagerRow>
              )}
              {billingInfoParam.general_ledger && (
                <BudgetManagerRow>
                  <BudgetManagerModalP>General Ledger:</BudgetManagerModalP>
                  <BudgetManagerModalBoldP>
                    {billingInfoParam.general_ledger}
                  </BudgetManagerModalBoldP>
                </BudgetManagerRow>
              )}
            </BillingInfoVerificationView>
          );
        })}
      </>
    );
  }

  return (
    <>
      <BudgetManagerModalBoldP>
        Please review the project price and enter the billing information below.
      </BudgetManagerModalBoldP>
      <OrderBreakdown
        showLabelToggle={false}
        isLoading={
          recordingOrderSummaryIsLoading || mixMasterOrderSummaryIsLoading
        }
        showHeader={false}
        orderSummary={recordingOrderSummary ?? mixMasterOrderSummary}
      />
      <BudgetManagerModalP>
        Input direct billing information below:
      </BudgetManagerModalP>
      {billingInfoArray.map((billingInfoParam) => {
        const purchaseOrderId = billingInfoParam.purchase_order_id;
        const showPurchaseOrder = showPurchaseOrderInputs[purchaseOrderId];
        return (
          <Box key={purchaseOrderId} sx={{ width: "100%" }}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                gap: 1.5,
                marginBottom: 1,
                marginTop: 1,
              }}
            >
              <Text bold>
                {billingInfoParam.eventually_owed_to}:{" "}
                {PennyDollarFormatter().format(billingInfoParam.total)}
              </Text>
              {showBillingInfoOption && (
                <Button
                  onClick={() =>
                    setShowPurchaseOrderInputs((prev) => ({
                      ...prev,
                      [purchaseOrderId]: !prev[purchaseOrderId],
                    }))
                  }
                  variant={ButtonVariant.OUTLINED}
                  style={{ padding: "4px 8px", minHeight: "unset" }}
                >
                  {showPurchaseOrder ? "Billing Inputs" : "PO Input"}
                </Button>
              )}
            </Box>
            {showPurchaseOrder ? (
              <BillingInfoInputContainer>
                <BillingInfoInput
                  placeholder={"Purchase order"}
                  value={billingInfoParam.order_number}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setBillingInfoParams((currBillingInfo) => ({
                      ...currBillingInfo,
                      [purchaseOrderId]: {
                        ...currBillingInfo[purchaseOrderId],
                        order_number: e.target.value,
                      },
                    }));
                  }}
                />
              </BillingInfoInputContainer>
            ) : (
              <BillingInfoInputContainer>
                <BillingInfoInput
                  placeholder="Cost center"
                  value={billingInfoParam.cost_center}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setBillingInfoParams((currBillingInfo) => ({
                      ...currBillingInfo,
                      [purchaseOrderId]: {
                        ...currBillingInfo[purchaseOrderId],
                        cost_center: e.target.value,
                      },
                    }));
                  }}
                />
                <BillingInfoInput
                  placeholder="Work Breakdown Structure"
                  value={billingInfoParam.work_breakdown_structure}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setBillingInfoParams((currBillingInfo) => ({
                      ...currBillingInfo,
                      [purchaseOrderId]: {
                        ...currBillingInfo[purchaseOrderId],
                        work_breakdown_structure: e.target.value,
                      },
                    }));
                  }}
                />
                <BillingInfoInput
                  placeholder="General Ledger"
                  value={billingInfoParam.general_ledger}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setBillingInfoParams((currBillingInfo) => ({
                      ...currBillingInfo,
                      [purchaseOrderId]: {
                        ...currBillingInfo[purchaseOrderId],
                        general_ledger: e.target.value,
                      },
                    }));
                  }}
                />
              </BillingInfoInputContainer>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default BillingInfoModalBody;
