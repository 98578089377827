import { CheckboxGroup, Select } from "../../core-ui/components/Select/Select";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  COMMENTS_SORT,
  SortByTypeValue,
} from "../../../store/models/fileVersion";
import { setSortBy } from "../../../store/actions/fileVersionComments";
import { useTheme } from "styled-components";

export const TrackTableSortDropdown = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { sortBy } = useAppSelector((state) => state.fileVersionCommentsSlice);

  const handleSelected = (selected: SortByTypeValue) => {
    dispatch(setSortBy(selected));
  };

  return (
    <Select
      triggerLabel="Sort Comments"
      dropdownZIndex={theme.zIndexes.trackTableCommentsSortDropdown}
      preventCloseDropdownOnSelect
    >
      <CheckboxGroup
        options={COMMENTS_SORT}
        selected={sortBy}
        onSelect={(selected) => {
          if (!selected) return;
          handleSelected(selected);
        }}
      />
    </Select>
  );
};
