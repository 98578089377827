import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import {
  getPaginatedAdminActionItems,
  PaginatedAdminActionItemsArgs,
} from "../../store/actions/adminDashboardStore";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useIsAdmin } from "../useIsAdmin";

const PAGE_SIZE = 4;

interface GetPaginatedAdminActionItemsParams
  extends PaginatedAdminActionItemsArgs {
  enabled?: boolean;
}

export const useGetPaginatedAdminActionItems = ({
  completed,
  enabled = true,
  page,
  page_size = PAGE_SIZE,
  searchQuery,
}: GetPaginatedAdminActionItemsParams) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.accountInfo);
  const isAdmin = useIsAdmin(user);

  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_PAGINATED_ADMIN_ACTION_ITEMS,
      page,
      completed,
      page_size,
      searchQuery,
    ],
    queryFn: async () => {
      return dispatch(
        getPaginatedAdminActionItems({
          completed,
          page,
          page_size,
          searchQuery,
        }),
      ).unwrap();
    },
    enabled: isAdmin && enabled,
  });
};
