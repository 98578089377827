import { atom, WritableAtom } from "jotai";

export function profileEditMode(
  initialValue: boolean,
): WritableAtom<boolean, [boolean], void> {
  const anAtom = atom(initialValue, (get, set, nextValue: boolean) => {
    const update = nextValue ?? !get(anAtom);
    set(anAtom, update);
  });

  return anAtom as WritableAtom<boolean, [boolean?], void>;
}

export const editMode = profileEditMode(false);

// When we want to upload a cover photo to be used for Service | RecordingService,
// we need to have the service ID. However, when we want to also upload the photo during service creation time,
// that ID doesn't exist yet. Thus, this atom is used to keep track of the user's selected file, so we can upload it right
// after the service creation
export const localServiceCoverPhotoAtom = atom<undefined | File>(undefined);
