import { useMemo } from "react";
import { DetailedPurchaseOrder } from "../store/models/project";
import { Transaction } from "../store/models/transaction";

// Why do we need a hook just to grab a single field off of an object?
const useBudgetManagerForLink = (purchaseOrder?: DetailedPurchaseOrder) => {
  return useMemo(() => purchaseOrder?.budget_manager_for_link, [purchaseOrder]);
};

export const usePurchaseOrderBudgetManagers = (
  purchaseOrder?: DetailedPurchaseOrder,
) => {
  return useMemo(() => purchaseOrder?.budget_managers ?? [], [purchaseOrder]);
};

export const useIsBudgetApproved = (purchaseOrder?: DetailedPurchaseOrder) => {
  return useMemo(
    () => Boolean(purchaseOrder?.budget_approved),
    [purchaseOrder],
  );
};

export const useShowBudgetApprovalCTA = (
  purchaseOrder?: DetailedPurchaseOrder,
) => {
  const budgetManager = useBudgetManagerForLink(purchaseOrder);
  const isBudgetApproved = useIsBudgetApproved(purchaseOrder);
  return useMemo(() => {
    if (isBudgetApproved) return false;
    if (!budgetManager) return false;
    const submittedApproval =
      Boolean(budgetManager.budget_approved) ||
      Boolean(budgetManager.budget_rejected);
    return budgetManager.can_approve_budget && !submittedApproval;
  }, [budgetManager, isBudgetApproved]);
};

export const useShowBillingInfoCTA = (
  purchaseOrder?: DetailedPurchaseOrder,
) => {
  const budgetManager = useBudgetManagerForLink(purchaseOrder);
  const showBudgetApprovalCTA = useShowBudgetApprovalCTA(purchaseOrder);
  return useMemo(() => {
    return budgetManager?.can_submit_billing_info && !showBudgetApprovalCTA;
  }, [budgetManager]);
};

export const useIsUMGAandR = (transaction?: Transaction) => {
  return useMemo(() => {
    const isUmgAandR = transaction?.user?.is_umg_aandr;
    return Boolean(isUmgAandR);
  }, [transaction]);
};

// I do not understand the logic of this hook
// It will always return true
export const useIsPurchaseOrderAandR = (transaction?: Transaction) => {
  const isUmgAandR = useIsUMGAandR(transaction);
  return useMemo(() => {
    return Boolean(transaction?.user?.is_umg_aandr) || !isUmgAandR;
  }, [transaction]);
};

export const useBillingInfoOrPurchaseOrderSubmitted = (
  purchaseOrder?: DetailedPurchaseOrder,
) => {
  return useMemo(() => {
    const purchaseOrderSubmitted = Boolean(purchaseOrder?.order_number);
    const billingInfoSubmitted = Boolean(
      purchaseOrder?.cost_center &&
        purchaseOrder?.general_ledger &&
        purchaseOrder?.work_breakdown_structure,
    );
    return {
      purchaseOrderSubmitted,
      billingInfoSubmitted,
    };
  }, [purchaseOrder]);
};

export const useBillingInfoSubmitterEmails = (
  purchaseOrder?: DetailedPurchaseOrder,
) => {
  return useMemo(() => {
    return (
      purchaseOrder?.budget_managers
        .filter((bm) => bm.can_submit_billing_info)
        .reduce((acc, bm) => {
          return acc.concat(bm.email);
        }, [] as string[]) ?? []
    );
  }, [purchaseOrder]);
};
