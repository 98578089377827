import { faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopoverTrigger } from "@radix-ui/react-popover";
import { useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { sidePanelBottomDrawerContentAtom } from "../../../atoms/sidePanelAtoms";
import { useIsArtistReviewStepOrInMixOrInMaster } from "../../../hooks/projectStepHooks";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import useModal from "../../../hooks/useModal";
import { useAppSelector } from "../../../store/hooks";
import {
  MasteringProjectSteps,
  MixingProjectSteps,
  Project,
  ProjectById,
  ProjectType,
} from "../../../store/models/project";
import { SessionSteps } from "../../../store/models/recordingSession";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  isEngineerForProject,
  isUserArtistForProject,
} from "../../../utils/projectUtils";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { ShareLinkModal } from "../ShareLinkModal/ShareLinkModal";
import "./ListenLinkGenerator.css";

export interface ListenLinkGeneratorProps {
  project?: Project | ProjectById;
  step?: MixingProjectSteps | MasteringProjectSteps | SessionSteps;
}

export const ListenLinkGenerator = ({
  project,
  step,
}: ListenLinkGeneratorProps) => {
  const { isOpen, setIsOpen, closeModal } = useModal();
  const isArtistReviewStepOrIsInMixOrMaster =
    useIsArtistReviewStepOrInMixOrInMaster(
      project?.id,
      step,
      project?.service_type,
    );
  const location = useLocation();
  const { pathname } = location;
  const user = useAppSelector((state) => state.accountInfo.user);
  const isEngineer = isEngineerForProject(project, user);
  const isArtist = isUserArtistForProject(project, user);
  const projectId = project ? project.id.toString() : "";

  const buttonTitle = useMemo(() => {
    if (!project) return "";
    return project.service_type === ProjectType.MASTERING
      ? "Share Master"
      : "Share Mix";
  }, [project]);
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  // This bottom drawer will only work on the Project/Session Workflow Sidepanel
  // It will not work on the Listen Link Page (this button is not shown on the Listen Link Page)
  const setBottomDrawerContent = useSetAtom(sidePanelBottomDrawerContentAtom);
  const { isMobile } = useMediaQueryBreakpoint();

  const renderShareButton = useCallback(
    (callback?: () => void) => (
      <Button
        labelIcon={
          <FontAwesomeIcon
            icon={faShare}
            color="var(--medium-grey)"
            style={{ width: 16, height: 16 }}
          />
        }
        onClick={() => {
          emitAnalyticsTrackingEvent(
            "open_listen_link_generator_modal",
            {},
            user?.id,
          );
          callback?.();
          if (!isOpen) setIsOpen(true);
        }}
        variant={ButtonVariant.GHOST}
        style={{ minHeight: "unset", padding: "4px 8px" }}
      >
        <p className="b2-semi-bold">{buttonTitle}</p>
      </Button>
    ),
    [buttonTitle, isOpen, setIsOpen, user?.id],
  );

  if (pathname.includes("listen")) return null;
  if (!isArtistReviewStepOrIsInMixOrMaster) return null;
  if (!isEngineer && !isArtist) return null;

  if (isMobile) {
    return renderShareButton(() => {
      setBottomDrawerContent(
        <div style={{ padding: "0 8px" }}>
          <Text variant={TextStyleVariant.H5}>Share playback page</Text>
          <ShareLinkModal
            contentOnly
            projectId={projectId}
            closeModal={closeModal}
          />
        </div>,
      );
    });
  }

  return (
    <BasePopover
      isOpen={isOpen}
      setIsPopoverOpen={setIsOpen}
      side="top"
      title="Share playback page"
      description={
        <ShareLinkModal
          contentOnly
          projectId={projectId}
          closeModal={closeModal}
        />
      }
      hideFooter
      wrapperElement={containerElement}
    >
      <PopoverTrigger asChild>{renderShareButton()}</PopoverTrigger>
    </BasePopover>
  );
};
