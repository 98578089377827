import {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./PlayAudioRow.css";
import { WaveForm, WaveSurfer } from "wavesurfer-react";
import { default as WaveSurferRef } from "wavesurfer.js";
import { ColorPalette, defaultPageLoaderSize } from "../../theme";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { downloadTrack } from "../../../store/actions/audioService";
import { useParams } from "react-router-dom";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { fetchFiles } from "../../../store/actions/fileVersions";
import { FILE_STATUS } from "../../../store/models/fileVersion";
import {
  useLatestNonReferenceAudioFileVersion,
  useLatestReference,
} from "../../../hooks/useFilesFromFileVersionStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePause, faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  FooterFileTrackType,
  setLocalPlayer,
} from "../../../store/actions/abPlayerStore";
import { useMoveToSeekTime } from "../../../hooks/audioPlayerHooks/useMoveToSeekTime";
import { useSetupOnClick } from "../../../hooks/audioPlayerHooks/UseSetupOnClick";
import { useGetFooterPlayerRef } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { Project } from "../../../store/models/project";
import {
  convertProjectToPlayListTrack,
  convertProjectsToPlayListTracks,
} from "../../../store/models/playListTrack";

export interface PlayAudioRowProps {
  source?: string;
  label?: string;
  referenceTrack?: boolean;
  errorMessage?: string;
  project: Project;
}

export interface PlayAudioRowRef {
  pause: () => void;
}

export const PlayAudioRow = forwardRef<PlayAudioRowRef, PlayAudioRowProps>(
  ({ label = "", source = "", referenceTrack, errorMessage, project }, ref) => {
    const currentTrack = useRef<WaveSurferRef | null>(null);
    const [url, setUrl] = useState<string>(source);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(source === "");
    const [fetching, setFetching] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const referenceFileVersion = useLatestReference(project.id ?? -1);
    const mixOrMasterFileVersion = useLatestNonReferenceAudioFileVersion(
      project.id ?? -1,
    );
    const { isFooterReady, trackedPlayerId, isFooterPlaying } = useAppSelector(
      (state) => state.abPlayerStore,
    );
    const refId = useMemo(() => {
      return project.id;
    }, [project]);

    const footerPlayerRef = useGetFooterPlayerRef();

    const isPlayingOnFooter = useMemo(() => {
      return trackedPlayerId === refId;
    }, [refId, trackedPlayerId]);

    const onPlayPause = useCallback(() => {
      if (loading) return;
      if (!isPlayingOnFooter) {
        dispatch(
          setLocalPlayer({
            url,
            trackedPlayerId: refId,
            keepPosition: false,
            footerFileTrackType: FooterFileTrackType.COMPLETED_PROJECT,
            loadedTrack: convertProjectToPlayListTrack(project),
          }),
        );
        return;
      }
      void footerPlayerRef.current?.playPause();
    }, [dispatch, isPlayingOnFooter, loading, project.id, refId, url]);

    useImperativeHandle(ref, () => ({
      pause: () => {
        currentTrack.current?.pause();
      },
    }));

    const { tab } = useParams<{ tab: string }>();

    const handleError = useCallback(() => {
      setLoading(false);
      setHasError(true);
    }, []);

    const handleReady = useCallback(() => {
      setLoading(false);
    }, []);

    useSetupOnClick(currentTrack, Boolean(loading || !isPlayingOnFooter));

    useMoveToSeekTime(currentTrack, isPlayingOnFooter);

    const handleWSMount = useCallback(
      async (waveSurfer: WaveSurferRef | null) => {
        if (waveSurfer === null) return;
        currentTrack.current = waveSurfer;
        void currentTrack.current?.load(url);
        currentTrack.current?.on("ready", handleReady);
        // @ts-ignore
        currentTrack.current.on("error", handleError);
        currentTrack.current.on("destroy", () => {
          currentTrack.current?.unAll();
          currentTrack.current?.destroy();
        });
        if (window && refId) {
          if (!window.surferidze) {
            window.surferidze = {};
          }
          window.surferidze[refId] = currentTrack.current;
        }
      },
      [url, handleReady, handleError, refId],
    );

    useEffect(() => {
      if (!project) return;
      if (source) return;
      void dispatch(
        fetchFiles({
          projectId: project.id,
          status: FILE_STATUS.FILE_UPLOADED,
        }),
      );
    }, [dispatch, project, source]);

    useEffect(() => {
      if (currentTrack.current !== null) {
        currentTrack.current?.pause();
      }
    }, [tab, currentTrack]);

    useEffect(() => {
      if (source) return;
      if (referenceTrack === undefined) return;
      setLoading(true);
      setFetching(true);
      if (referenceTrack && referenceFileVersion) {
        void dispatch(downloadTrack({ fileVersionId: referenceFileVersion.id }))
          .unwrap()
          .then((data) => {
            setFetching(false);
            setLoading(false);
            setUrl(data);
          });
      } else if (!referenceTrack && mixOrMasterFileVersion) {
        void dispatch(
          downloadTrack({ fileVersionId: mixOrMasterFileVersion.id }),
        )
          .unwrap()
          .then((data) => {
            setFetching(false);
            setLoading(false);
            setUrl(data);
          });
      }
    }, [
      referenceTrack,
      dispatch,
      referenceFileVersion,
      mixOrMasterFileVersion,
      source,
    ]);

    const waveformId = `waveform-${refId}`;
    return (
      <div className="play-audio-container">
        {Boolean(label) && (
          <div className="label-container">
            <p>{label}</p>
          </div>
        )}
        <Button variant={ButtonVariant.UNSTYLED} onClick={onPlayPause}>
          <FontAwesomeIcon
            icon={
              isPlayingOnFooter && isFooterPlaying
                ? faCirclePause
                : faCirclePlay
            }
            className={"play-audio-button ".concat(
              loading ? "disable-button" : "",
            )}
            color={ColorPalette.Gray950}
          />
        </Button>
        <div className={"play-audio-section"}>
          {!fetching && url !== "" && (
            <WaveSurfer
              plugins={[]}
              onMount={handleWSMount}
              container={`#${waveformId}`}
              waveColor={ColorPalette.LightGray}
              progressColor={ColorPalette.BoomyOrange400}
              height={56}
              cursorColor={ColorPalette.Gray950}
            >
              <WaveForm id={waveformId} />
            </WaveSurfer>
          )}
          <div
            className={"play-audio-section-loader ".concat(
              hasError ? "loading" : "",
            )}
          >
            <div>
              {errorMessage ? (
                <p>{errorMessage}</p>
              ) : (
                <p>Audio not yet uploaded.</p>
              )}
            </div>
          </div>
          <div
            className={"play-audio-section-loader ".concat(
              loading ? "loading" : "",
            )}
          >
            {loading && (
              <SoundWaveLoader
                width={defaultPageLoaderSize}
                height={defaultPageLoaderSize}
              />
            )}
          </div>
        </div>
      </div>
    );
  },
);

PlayAudioRow.displayName = "PlayAudioRow";
