import { useCallback, useEffect, useState } from "react";
import "./RequestModal.css";
import { BaseModal } from "../BaseModal/BaseModal";
import engineearsLogo from "../../assets/engineears-logo.svg";
import { Button } from "../../elements/Button/button";
import { Textfield } from "../../elements/Textfield1/Textfield1";
import { CheckBox } from "../../elements/CheckBox/CheckBox";
import { useAppDispatch } from "../../../store/hooks";
import { addStudioToWaitList } from "../../../store/actions/studio";
import Lottie from "react-lottie";
import { defaultOptions } from "../ContactInfoModal/ContactInfoModal";
import { DropdownSelector } from "../../elements/DropDownSelector/DropdownSelector";
import { getNumberedOptions } from "../../../store/utils/serviceUtils";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { matchIsValidTel } from "mui-tel-input";
import { PhoneInput } from "../../core-ui/components/PhoneNumber/PhoneInput";

export interface RequestModalProps {
  show: boolean;
  onClose: () => void;
  isSettingUpProfile?: boolean;
  onExit?: () => void;
  onSuccess?: () => void;
}

export const RequestModal = ({
  show,
  onClose,
  onExit,
  isSettingUpProfile = false,
  onSuccess,
}: RequestModalProps) => {
  const dispatch = useAppDispatch();
  const [studioName, setStudioName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [managesStudio, setManagesStudio] = useState<boolean>(false);
  const [showSuccessScreen, setShowSuccess] = useState<boolean>(false);
  const [lottiePause, setLottiePause] = useState<boolean>(true);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("United States");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(false);
  const [numberOfRooms, setNumberOfRooms] = useState<number>(1);

  useEffect(() => {
    if (!showSuccessScreen) return;
    setTimeout(() => {
      setLottiePause(false);
    }, 500);
  }, [showSuccessScreen]);

  useEffect(() => {
    if (!lottiePause) {
      setTimeout(() => {
        setLottiePause(true);
        setShowSuccess(false);
        onClose();
      }, 4500);
    }
  }, [lottiePause]);

  useEffect(() => {
    if (
      !studioName.length ||
      !city ||
      !region ||
      !email.length ||
      !selectedCountry
    ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [studioName, email, city, region, selectedCountry, managesStudio]);

  useEffect(() => {
    setRegion("");
  }, [selectedCountry]);

  const handleSubmit = useCallback(() => {
    if (loading) return;
    setLoading(true);
    dispatch(
      addStudioToWaitList({
        studio_name: studioName,
        number_of_rooms: numberOfRooms,
        country: selectedCountry,
        region: region,
        city: city,
        email: email,
        manages_studio: managesStudio,
        phone_number: phoneNumber.length ? phoneNumber : undefined,
      }),
    )
      .unwrap()
      .then(() => {
        setShowSuccess(true);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    studioName,
    email,
    city,
    region,
    selectedCountry,
    managesStudio,
  ]);

  const handleExit = () => {
    if (onExit) {
      onExit();
    }
    onClose();
  };

  return (
    <BaseModal
      label="request"
      modalIsOpen={show}
      closeModal={handleExit}
      hideCloseButton={isSettingUpProfile}
    >
      <div className="request-modal-container">
        {isSettingUpProfile && (
          <div className="request-modal-setup-profile">
            <p
              onClick={handleExit}
              className=" b1-semi-bold request-modal-setup-profile-alt-exit-button"
            >
              Go Back
            </p>
          </div>
        )}
        <div className="request-modal-header">
          <img
            className="studio-screen-request-logo"
            src={engineearsLogo}
            alt="engineears-logo"
          />
          <p className="h6-semi-bold ">Request a Studio</p>
          <p className="b1">Submit a Studio to the Waitlist</p>
        </div>
        <div className="request-modal-body">
          <div className="request-body-element">
            <p className="b3-semi-bold">Studio Name</p>
            <Textfield onChange={(e) => setStudioName(e.target.value)} />
          </div>
          <div className="request-body-element">
            <p className="b3-semi-bold">Phone number</p>
            <PhoneInput
              style={{
                height: "45px",
                width: "100%",
              }}
              sx={{
                "& .MuiOutlinedInput-root": { height: "45px", width: "100%" },
              }}
              isValidPhoneNumber={isValidPhoneNumber}
              defaultCountry={"US"}
              value={phoneNumber}
              onChange={(value: string) => {
                setPhoneNumber(value);
                setIsValidPhoneNumber(matchIsValidTel(value));
              }}
            />
          </div>
          <div className="request-body-element">
            <p className="b3-semi-bold">Number of Rooms</p>
            <DropdownSelector
              value={{
                value: numberOfRooms,
                label: numberOfRooms.toString(),
              }}
              options={getNumberedOptions(1, 10)}
              placeholder={"Select One"}
              onChange={(option) => {
                setNumberOfRooms(option.value);
              }}
            />
          </div>

          <div className="request-body-element">
            <p className="b3-semi-bold">Country</p>
            <CountryDropdown
              value={selectedCountry}
              onChange={(val: string) => setSelectedCountry(val)}
            />
          </div>
          <div className="request-body-element">
            <p className="b3-semi-bold">Region</p>
            <RegionDropdown
              country={selectedCountry}
              value={region}
              onChange={(val: string) => setRegion(val)}
            />
          </div>
          <div className="request-body-element">
            <p className="b3-semi-bold">City</p>
            <Textfield onChange={(e) => setCity(e.target.value)} />
          </div>
          <div className="request-body-element">
            <p className="b3-semi-bold">Email</p>
            <Textfield onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className="request-modal-checkbox">
            <CheckBox
              toggle={() => setManagesStudio(!managesStudio)}
              label={"I own or manage bookings at this studio"}
              isSelected={managesStudio || isSettingUpProfile}
            />
          </div>
          <Button
            gradient={true}
            disabled={submitDisabled}
            label={"Request Studio"}
            size="large"
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
        {showSuccessScreen && (
          <div
            className={"request-modal-success-screen-overlay ".concat(
              showSuccessScreen ? "show" : "hide",
            )}
          >
            <div className={"success-lottie-container"}>
              <Lottie
                isStopped={lottiePause}
                options={defaultOptions}
                height={150}
                width={150}
              />
              <p className="h6-semi-bold">
                Studio submitted! We&apos;ll be in touch soon
              </p>
            </div>
          </div>
        )}
      </div>
    </BaseModal>
  );
};
