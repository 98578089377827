import { useState } from "react";
import Calendar, { CalendarTileProperties } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  getCurrentDate,
  getFormattedDate,
  isDateWhitelisted,
} from "../../../store/utils/dateTimeUtils";
import { DollarFormatter } from "../../../store/utils/formatUtils";
import nextArrow from "../../assets/next-arrow.svg";
import previousArrow from "../../assets/previous-arrow.svg";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import "./ProjectBookingCalendar.css";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const nextArrowIcon = <img src={nextArrow} alt="next button" />;
export const previousArrowIcon = (
  <img src={previousArrow} alt="previous button" />
);

export type availability_multiplier = [number, number, number];

export interface FirstPassDate {
  date: Date;
  availability: availability_multiplier;
}

export interface ProjectBookingCalendarProps {
  availabilities: Map<string, availability_multiplier>;
  onClickDate: (date: FirstPassDate) => void;
  basePrice: number;
  selectedDays: Date[];
}

export const ProjectBookingCalendar = ({
  availabilities,
  onClickDate,
  basePrice,
  selectedDays,
}: ProjectBookingCalendarProps) => {
  const [clickedDay, setClickedDay] = useState(new Date());

  const isDateSelected = (date: Date) =>
    selectedDays.some((day) => day.getTime() === date.getTime());

  const isCurrentDay = (date: Date) =>
    date.getTime() === getCurrentDate().getTime();

  const getAvailability = (formattedDate: string) => {
    let availability = null;
    if (availabilities.has(formattedDate)) {
      const avail = availabilities.get(formattedDate)![1];
      availability = avail === 0 ? null : avail;
    }
    return availability;
  };

  const getTileContent = ({ date, view }: CalendarTileProperties) => {
    const isAheadOfToday = date.getTime() >= getCurrentDate().getTime();
    const dateIsWhitelisted = isDateWhitelisted(date, false);
    if (view === "month" && (isAheadOfToday || dateIsWhitelisted)) {
      const formattedDate = getFormattedDate(date);
      const availability = dateIsWhitelisted
        ? 1
        : getAvailability(formattedDate);
      return (
        <div className={"date-price"}>
          {availability
            ? DollarFormatter().format(basePrice * availability)
            : ""}
        </div>
      );
    } else {
      return <div style={{ display: "none" }}></div>;
    }
  };

  const getDisabledTiles = ({ view, date }: CalendarTileProperties) => {
    if (view === "month" && date.getTime() < getCurrentDate().getTime()) {
      return true;
    } else return !getAvailability(getFormattedDate(date));
  };

  const getTileClass = ({ view, date }: CalendarTileProperties) => {
    if (
      date.getTime() === clickedDay.getTime() &&
      !isDateSelected(clickedDay)
    ) {
      return isCurrentDay(date) ? "deselect-now-tile" : "deselect-tile";
    }
    return view === "month" && isDateSelected(date) ? "selected-tiles" : "";
  };

  const clickDayHandler = (date: Date) => {
    setClickedDay(date);
    const completionDate: FirstPassDate = {
      date: new Date(date.setHours(24, 0, 0, 0)),
      availability: availabilities.get(getFormattedDate(date))!,
    };
    onClickDate(completionDate);
  };

  return (
    <div className="calendar-container">
      {availabilities.size === 0 ? (
        <SoundWaveLoader width={100} height={100} />
      ) : (
        <Calendar
          onClickDay={clickDayHandler}
          tileClassName={getTileClass}
          tileContent={getTileContent}
          tileDisabled={getDisabledTiles}
          prevLabel={<FontAwesomeIcon icon={faChevronLeft} size="1x" />}
          nextLabel={<FontAwesomeIcon icon={faChevronRight} size="1x" />}
        />
      )}
    </div>
  );
};
