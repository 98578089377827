import { useUploadedFiles } from "../../../hooks/useProjectFilesUploaded";
import { FileVersion, UploadType } from "../../../store/models/fileVersion";
import { ProjectById } from "../../../store/models/project";

export const useFilesToDownload = (
  project: ProjectById,
  reference: boolean,
): FileVersion[] => {
  const allFiles = useUploadedFiles(project.id);

  let latestVersion = 0;
  const referenceFiles: FileVersion[] = [];
  const deliverables: FileVersion[] = [];

  // Find reference files and latest version for deliverables
  allFiles.forEach((file) => {
    if (file.reference) {
      referenceFiles.push(file);
    } else if (file.version > latestVersion) {
      latestVersion = file.version;
    }
  });

  // Find deliverables and update reference files based on latest version
  allFiles.forEach((file) => {
    if (file.reference) return;
    // If the file is a non-reference zip file, then it is a deliverable
    if (file.version === latestVersion || file.upload_type === UploadType.ZIP) {
      deliverables.push(file);
    } else {
      referenceFiles.push(file);
    }
  });

  return reference ? referenceFiles : deliverables;
};
