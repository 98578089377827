import { Box } from "@mui/material";
import { ReactNode } from "react";
import {
  SERVICE_FORM_MODAL_FOOTER_ID,
  useBottomTabBarOverlayView,
} from "../../../components/Navigation/BottomNav/useBottomTabBarOverlayView";

interface MainProjectWorkflowButtonRowProps {
  children: ReactNode;
}

export const ServicesFormModalFooter = ({
  children,
}: MainProjectWorkflowButtonRowProps) => {
  return useBottomTabBarOverlayView(
    // Since we want it to appear on all screen, `isMobile` should be true all the time
    true,
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        columnGap: "16px",
        padding: "24px 0",
      }}
    >
      {children}
    </Box>,
    undefined,
    SERVICE_FORM_MODAL_FOOTER_ID,
  );
};
