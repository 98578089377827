import { css, styled } from "styled-components";
import { ColorPalette } from "../../theme";

export const TransactionBreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: inherit;
`;
export const TransactionBreakdownTitle = styled.p`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-family: Roobert;
  font-size: ${({ theme }) => theme.textSizeLg};
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.48px;
  margin-bottom: 16px;
`;

export const TransactionBreakdownRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  a {
    color: ${({ theme }) => theme.textPrimaryColor};
    text-decoration: underline;
  }
`;

export const TransactionBreakdownText = styled.p<{
  $weight?: boolean;
  $secondary?: boolean;
  $underline?: string;
}>`
  color: ${({ theme, $secondary }) =>
    $secondary ? theme.textSecondaryColor : theme.textPrimaryColor};
  font-family: "Roobert", sans-serif;
  font-size: ${({ theme }) => theme.textSizeSm};
  font-style: normal;
  font-weight: ${({ $weight }) => ($weight ? "600" : "400")};
  text-decoration: ${({ $underline }) => $underline};
`;

export const TransactionBreakdownBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  gap: 12px;
  margin-bottom: 32px;
`;

export const TransactionBreakdownDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.separatorColor};
`;

export const StyledTransactionBreakdownMiniPSecondary = styled.p`
  font-size: ${({ theme }) => theme.textSizeXs};
  color: ${({ theme }) => theme.textSecondaryColor};
  line-height: ${({ theme }) => theme.textSizeMd};
`;

export const StyledTransactionBreakdownRecordingContainer = styled(
  TransactionBreakdownBody,
)`
  margin-top: 0;
  margin-bottom: 0;
  gap: 16px;
`;

export const StyledTransactionBreakdownSpanBold = styled.span`
  font-weight: 600;
`;

export const StyledTransactionBreakdownSubRowContainer = styled(
  TransactionBreakdownRow,
)`
  justify-content: flex-end;
  gap: 4px;
`;

export const StyledTransactionBreakdownInput = styled.input`
  background-color: transparent;
  font-family: "Roobert", sans-serif;
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme }) => theme.textSizeMd};
  max-width: 210px;
  text-align: right;
  text-overflow: ellipsis;
  width: auto;
  display: flex;
  ${(props) => {
    switch (props.disabled) {
      case true:
        return css`
          border: none;
        `;
      default:
        return css`
        border: 1px solid ${ColorPalette.Blue300}};
      `;
    }
  }};
`;
