import { atom, useAtomValue } from "jotai";
import { NavSpacerWrapper } from "./NavSpacer.styles";
import { HTMLAttributes, useMemo } from "react";
import {
  bottomNavSpacerHeightAtom,
  topNavSpacerHeightAtom,
} from "../../../atoms/navAtoms";
import classNames from "classnames";

export interface NavSpacerProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  variant?: "top" | "bottom";
}

export const NavSpacer = ({
  variant = "bottom",
  className,
  ...props
}: NavSpacerProps) => {
  const navHeight = useAtomValue(
    useMemo(
      () =>
        atom((get) =>
          variant == "top"
            ? get(topNavSpacerHeightAtom)
            : get(bottomNavSpacerHeightAtom),
        ),
      [variant],
    ),
  );
  return (
    <NavSpacerWrapper
      className={classNames(["nav-spacer", `variant-${variant}`, className])}
      $navItemsHeight={navHeight}
      {...props}
    />
  );
};
