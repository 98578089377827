import { Box, styled } from "@mui/material";

export const EditReleaseInputRowContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
});

export const InputLabel = styled("label")(({ theme }) => ({
  color: theme.palette.text.primary,
  leadingTrim: "both",
  textEdge: "cap",
  fontFamily: "Roobert",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "18px",
}));

export const InputContainer = styled(Box)(({ theme }) => ({
  padding: "0 16px",
  height: "44px",
  justifyContent: "space-between",
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "8px",
  border: `solid 1px ${theme.palette.divider}`,
  background: theme.palette.customColor.backgroundColor,
}));

export const ReleaseFormInput = styled("input")(({ theme }) => ({
  all: "unset",
  flex: 1,
  color: theme.palette.text.primary,
}));

export const ImagePlaceHolder = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  width: "260px",
  height: "260px",
  background: theme.palette.standardColor.Gray[50],
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  flexDirection: "column",
  gap: "28px",
}));
