import { useMemo } from "react";
import { AltItem } from "../store/models/alts";
import { BaseProject, Project, ProjectById } from "../store/models/project";

export const useCurrentProjectHasAlts = (
  project?: Project | ProjectById | BaseProject,
): [boolean, AltItem[]] => {
  return useMemo(() => {
    if (!project) return [false, []];
    const alts = project.alts;
    if (!alts) return [false, []];
    return [alts.length > 0, alts];
  }, [project]);
};
