import { LOGIN_GOOGLE } from "../../store/utils/routes";
import User from "../../store/models/user";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { Error as EngineEarsError } from "../../store/actions/errorStore";

export interface LoginGoogleParams {
  authorization_code: string;
}

interface GoogleAuthResponse {
  user: User;
  token: string;
  created: boolean;
}

export const postLoginGoogle = async (
  params: LoginGoogleParams,
): Promise<GoogleAuthResponse | EngineEarsError> => {
  return makeBackendPostCallWithJsonResponse<GoogleAuthResponse>(
    LOGIN_GOOGLE,
    params,
  ).then((res) => {
    if (res.success) {
      return res.resultJson;
    }
    return res.resultJson;
  });
};
