import { TeamPanel } from "../../components/TeamPanel/TeamPanel";
import { useGetTeams } from "../../../hooks/useTeam";
import { useAppSelector } from "../../../store/hooks";
import { DashboardCard } from "../../components/Dashboard/DashboardCard/DashboardCard";
import { selectUserCanEnableStudioServices } from "../../../store/selectors/userInfoSelectors";
import { useIsAdmin } from "../../../hooks/useIsAdmin";
import InviteTeammateButton from "../../components/InviteTeammate/InviteTeammateButton";

export interface AdminDashboardStudioTeamCardProps {
  cardHeight?: number;
  onClick?: () => void;
}

const AdminDashboardStudioTeamCard = ({
  cardHeight = 320,
  onClick = () => {},
}: AdminDashboardStudioTeamCardProps) => {
  const { loading, selectedTeam: currentTeam } = useGetTeams();
  const user = useAppSelector((state) => state.accountInfo.user);
  const canManageStudios = useAppSelector(selectUserCanEnableStudioServices);
  const isAnAdmin = useIsAdmin(user);
  return (
    <DashboardCard
      cardHeight={cardHeight}
      headerText={`Your Team (${(currentTeam?.managers.length || 0) + (currentTeam?.members.length || 0)})`}
      handleHeaderClick={onClick}
      className="no-overflow-content"
      extraActionButtons={
        (canManageStudios || isAnAdmin) && (
          <InviteTeammateButton
            inPanel={false}
            team={currentTeam}
            isLabel={true}
          />
        )
      }
      actionButtonWrapperProps={{
        style: { display: "flex", alignItems: "center" },
      }}
    >
      <TeamPanel
        currentTeam={currentTeam}
        isLoading={loading}
        onSidePanel={false}
        rowLimit={currentTeam?.pending_invites.length ? 2 : 4} // This magic number keeps the card size non-scrolling
        pendingRowLimit={1}
      />
    </DashboardCard>
  );
};

export default AdminDashboardStudioTeamCard;
