import { AccountDetailsFieldContainer } from "../AccountSettingsModal.styles";
import { Text, TEXT_WEIGHT } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { PhoneInput } from "../../../core-ui/components/PhoneNumber/PhoneInput";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { matchIsValidTel, MuiTelInputCountry } from "mui-tel-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Box, useTheme } from "@mui/material";
import VerificationCodeInput from "../../Auth/VerificationCodeInput";
import { useCallback, useMemo, useState } from "react";
import useVerifyPhoneMutation from "../../../../hooks/userHooks/useVerifyPhoneMutation";
import { toast } from "react-toastify";
import { useUpdateUserProfile } from "../../../../hooks/userHooks/useUpdateUserProfile";
import { useAtomValue } from "jotai";
import { activeUserAtom } from "../../../../atoms/user/activeUserAtom";
import { useGeolocationGoogleAppEngine } from "../../../../hooks/useGeolocationCountryCode";

interface updateProfileErrorProps {
  code: number;
  firstName: string;
  lastName: string;
  displayName: string;
  username: string;
  email: string;
  phoneNumber: string;
}

export const AccountSettingsPhoneNumberField = () => {
  const activeUser = useAtomValue(activeUserAtom);
  const activeUserId = activeUser?.id;
  const originalNumber = activeUser?.phone_number;
  const [verificationMode, setVerificationMode] = useState(false);
  const [hasResentVerification, setHasResentVerification] = useState(false);
  const {
    mutateAsync: verifyPhone,
    isPending: isVerifyingPhone,
    isSuccess: didVerifyPhone,
  } = useVerifyPhoneMutation();
  const { isPending: isUpdateProfileLoading, mutateAsync: updateUserProfile } =
    useUpdateUserProfile({ userId: activeUserId });
  const [phoneNumber, setPhoneNumber] = useState(
    originalNumber?.phone_number || "",
  );
  const [updateProfileError, setUpdateProfileError] =
    useState<updateProfileErrorProps>();
  const { countryCode } = useGeolocationGoogleAppEngine();
  const theme = useTheme();
  const isVerifiedNumber = useMemo(() => {
    return Boolean(
      Boolean(originalNumber?.verified) &&
        phoneNumber.replaceAll(" ", "") === originalNumber?.phone_number,
    );
  }, [originalNumber, phoneNumber]);
  const isValidPhoneNumber = useMemo(() => {
    return matchIsValidTel(phoneNumber);
  }, [phoneNumber]);
  const handleComplete = useCallback(
    async (verification: string) => {
      return verifyPhone(verification).then(() => {
        toast.success("Verified phone number");
      });
    },
    [verifyPhone],
  );

  const handleResendCode = useCallback(async () => {
    if (originalNumber?.phone_number) {
      return updateUserProfile({
        phone_number: originalNumber?.phone_number,
      })
        .then(() => {
          toast.success("Verification code resent");
        })
        .catch((err) => {
          setUpdateProfileError(err.errors);
        })
        .finally(() => {
          setTimeout(() => {
            setHasResentVerification(false);
          }, 5000);
        });
    }
  }, [
    updateUserProfile,
    originalNumber?.phone_number,
    setUpdateProfileError,
    setHasResentVerification,
  ]);

  const handleSavePhoneNumber = useCallback(async () => {
    return updateUserProfile({
      phone_number: phoneNumber,
    })
      .then(() => {
        setVerificationMode(true);
        toast.success("Verification code sent");
      })
      .catch((err) => {
        setUpdateProfileError(err.errors);
      });
  }, [
    updateUserProfile,
    phoneNumber,
    setUpdateProfileError,
    setVerificationMode,
  ]);

  return (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        {verificationMode ? "Enter verification code" : "Phone number"}
      </Text>
      {verificationMode ? (
        <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <VerificationCodeInput
            disabled={isVerifyingPhone || didVerifyPhone}
            codeLength={6}
            onComplete={handleComplete}
          />
          <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
            <Button
              variant={ButtonVariant.GHOST}
              onClick={handleResendCode}
              loading={isUpdateProfileLoading}
              disabled={isUpdateProfileLoading || hasResentVerification}
              style={{ padding: "0px" }}
            >
              Resend code
            </Button>
            <Button
              variant={ButtonVariant.GHOST}
              onClick={() => setVerificationMode(false)}
              disabled={
                typeof originalNumber?.phone_number !== "undefined" &&
                phoneNumber === originalNumber?.phone_number
              }
              style={{ padding: "0px" }}
            >
              Update phone
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <PhoneInput
            disabled={isUpdateProfileLoading}
            isValidPhoneNumber={isValidPhoneNumber}
            defaultCountry={(countryCode as MuiTelInputCountry) || "US"}
            value={phoneNumber}
            onChange={(value: string) => {
              setPhoneNumber(value);
            }}
            size={"small"}
            style={{ width: "100%" }}
            error={Boolean(updateProfileError?.phoneNumber)}
            helperText={updateProfileError?.phoneNumber}
          />
          {isVerifiedNumber ? (
            <Text
              variant={TextStyleVariant.P1}
              style={{ color: theme.palette.standardColor.Green[600] }}
            >
              <FontAwesomeIcon
                style={{ marginRight: "4px" }}
                icon={faCheck}
                width={12}
                height={12}
              />
              verified
            </Text>
          ) : (
            <Button
              variant={ButtonVariant.GHOST}
              onClick={handleSavePhoneNumber}
              disabled={!phoneNumber}
            >
              Verify phone
            </Button>
          )}
        </>
      )}
      {}
    </AccountDetailsFieldContainer>
  );
};
