import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FileVersionComment,
  SortByTypeEnum,
  SortByTypeValue,
} from "../models/fileVersion";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  CREATE_FILE_COMMENT,
  LIST_FILE_COMMENTS,
  UPDATE_FILE_COMMENT,
} from "../utils/routes";
import { receiveErrors } from "./errorStore";
import { QueryParamsType, RootState } from "../index";
import queryString from "query-string";

const MOST_RECENT_ACTIVITY_SORT = "-most_recent_activity";

interface fetchFileCommentsParams {
  projectId: number;
  code: string | null | undefined;
  refCommentsOnly?: boolean;
}

export const fetchFileComments = createAsyncThunk(
  LIST_FILE_COMMENTS,
  async (args: fetchFileCommentsParams, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const fileVersionCommentsState = appState.fileVersionCommentsSlice;
    const { page, sortBy } = fileVersionCommentsState;
    const fetchFileCommentsArgs: QueryParamsType = {
      page,
      project_id: args.projectId,
    };
    if (sortBy) {
      fetchFileCommentsArgs.sort_by = sortBy;
    }
    if (args.code) {
      fetchFileCommentsArgs.code = args.code;
    }
    if (args.refCommentsOnly) {
      fetchFileCommentsArgs.ref_comments_only = args.refCommentsOnly;
    }
    const params = `?${queryString.stringify(fetchFileCommentsArgs)}`;
    const response = await makeBackendGetCallWithJsonResponse<{
      data: FileVersionComment[];
      num_of_pages: number;
      count: number;
      page: number;
    }>(LIST_FILE_COMMENTS, params);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface CreateFileCommentParams {
  comment: string;
  start_timestamp_in_seconds?: number;
  end_timestamp_in_seconds?: number;
  unique_css_identifier?: string;
  file_version_id: number;
  author_name?: string;
  code?: string;
  reply_to?: number;
  project_id: number;
}

export const createFileComment = createAsyncThunk(
  CREATE_FILE_COMMENT,
  async (args: CreateFileCommentParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<FileVersionComment>(
        CREATE_FILE_COMMENT,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface UpdateFileCommentParams {
  file_version_comment_id: number;
  comment?: string;
  start_timestamp_in_seconds?: number;
  end_timestamp_in_seconds?: number;
  unique_css_identifier?: string;
  file_version_id: number;
  code?: string;
  author_name?: string;
  resolved?: string | null;
  resolver_name?: string;
  delete?: boolean;
}

export const updateFileComment = createAsyncThunk(
  UPDATE_FILE_COMMENT,
  async (args: UpdateFileCommentParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<FileVersionComment>(
        UPDATE_FILE_COMMENT,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface FileVersionCommentState {
  [project_id: number]: {
    [page: number]: FileVersionComment[];
  };
  page: number;
  sortBy: SortByTypeValue;
  numberOfPages: number;
  count: number;
  loading: boolean;
}

const initialState: FileVersionCommentState = {
  page: 1,
  sortBy: MOST_RECENT_ACTIVITY_SORT,
  numberOfPages: 0,
  count: 0,
  loading: false,
};

export const fileVersionCommentsSlice = createSlice({
  name: "fileVersionCommentsSlice",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSortBy: (state, action: PayloadAction<SortByTypeValue>) => {
      state.sortBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFileComments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFileComments.fulfilled, (state, action) => {
      const projectId = action.meta.arg.projectId;
      if (!state[projectId]) {
        state[projectId] = {};
      }
      state[projectId][state.page] = action.payload.data;
      state.numberOfPages = action.payload.num_of_pages;
      state.count = action.payload.count;
      state.page = action.payload.page;
      state.loading = false;
    });
    builder.addCase(createFileComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createFileComment.fulfilled, (state, action) => {
      state.loading = false;

      const projectId = action.meta.arg.project_id;
      const commentList = state[projectId][state.page];
      if (commentList) {
        state[projectId][state.page] = [...commentList, action.payload];
      } else {
        state[projectId][state.page] = [action.payload];
      }

      // Set the page to show the new comment after its posted
      if (
        state.sortBy === SortByTypeEnum.MOST_RECENT_ACTIVITY ||
        state.sortBy === SortByTypeEnum.NEWEST_FIRST
      ) {
        state.page = 1;
      }
    });
    builder.addCase(updateFileComment.fulfilled, (state, action) => {
      const projectId = action.payload.project_id;
      const isDeleted = action.payload.deleted;
      let commentList = state[projectId][state.page];
      if (commentList) {
        commentList = commentList.filter(
          (comment: FileVersionComment) => comment.id !== action.payload.id,
        );
        if (!isDeleted) {
          state[projectId][state.page] = [...commentList, action.payload];
        } else {
          state[projectId][state.page] = commentList;
        }
      } else {
        if (!isDeleted) {
          state[projectId][state.page] = [action.payload];
        } else {
          state[projectId] = [];
        }
      }
    });
  },
});

export const { setPage, setSortBy } = fileVersionCommentsSlice.actions;

export default fileVersionCommentsSlice.reducer;
