import { useMemo } from "react";
import {
  AtmosProjectSteps,
  MasteringProjectSteps,
  MixingProjectSteps,
  ProjectType,
} from "../store/models/project";
import { SessionWorkflowSteps } from "../store/models/recordingSession";

const mixingInstructions = (step: MixingProjectSteps): string => {
  switch (step) {
    case MixingProjectSteps.REFERENCE_UPLOADED:
      return "Artist indicated that you have their reference (demo) track already. Drag and drop required files here so that you can start mixing.";
    case MixingProjectSteps.MIX_REVISION:
    case MixingProjectSteps.IN_MIX:
      return "Upload your mix as a .wav file";
    case MixingProjectSteps.BOOKING_REQUESTED:
    case MixingProjectSteps.PROJECT_ACCEPTED:
    case MixingProjectSteps.FILES_NEED_REUPLOAD:
      return "Upload all project stems in a .zip folder. Upload a reference (demo) track in .wav format.";
    case MixingProjectSteps.MIX_FINISHED:
      return "Upload all project stems and requested alts into a .zip file.";
    case MixingProjectSteps.ENG_FILE_REVIEW:
      return "Artist indicated that you have their files. Please click to upload required files.";
    default:
      return "";
  }
};

const masteringInstructions = (
  step: MasteringProjectSteps,
  hasAlts: boolean,
): string => {
  switch (step) {
    case MasteringProjectSteps.MASTERING_UPLOAD_ALTS:
      return "Upload a .zip folder containing all of the mastered alts.";
    case MasteringProjectSteps.MASTER_REVISION:
      return "Artist requested a master revision. Upload your main master in .wav format after your revision.";
    case MasteringProjectSteps.IN_MASTER:
      return "Upload a main master .wav for review by the artist.";
    case MasteringProjectSteps.MASTERING_ENG_FILE_REVIEW:
      return "Please upload a .wav file of the song to keep as a reference track. Additionally submit a zip file with the project alts.";
    case MasteringProjectSteps.MASTERING_BOOKING_REQUESTED:
    case MasteringProjectSteps.MASTERING_FILE_NEEDS_REUPLOAD:
      if (hasAlts) {
        return "Please upload your main mix as a .wav file. Additionally submit a folder with any requested alts in .zip format.";
      }
      return "Please upload your main mix as a .wav file.";
    default:
      return "";
  }
};

const recordingInstructions = (step: SessionWorkflowSteps): string => {
  switch (step) {
    case SessionWorkflowSteps.SESSION_UPCOMING:
      return "Once your session has begun, you can start uploading files.";
    default:
      return "Upload the session files for your artist to be able to access their recordings.";
  }
};

export const useUploadInstructionText = (
  step: number,
  projectType: ProjectType | null,
  hasAlts: boolean,
) => {
  return useMemo(() => {
    switch (projectType) {
      case ProjectType.MASTERING:
        return masteringInstructions(step as MasteringProjectSteps, hasAlts);
      case ProjectType.MIXING:
      case ProjectType.TWO_TRACK_MIXING:
        return mixingInstructions(step as MixingProjectSteps);
      case ProjectType.ATMOS_MIXING:
        if (AtmosProjectSteps.IN_MIX === step) {
          return "For this Dolby Atmos mix, please upload your mix both as a binaural render .wav and an .MP4";
        }
        return mixingInstructions(step as MixingProjectSteps);
      case ProjectType.RECORDING:
        return recordingInstructions(step);
      default:
        return "";
    }
  }, [step, projectType]);
};
