import { useCallback } from "react";
import { DefaultGenerics, StreamChat } from "stream-chat";
import { useChatContext } from "stream-chat-react";
import { CHANNEL_ID_PREFIX, CHANNEL_TYPE } from "../../constants/chat";
import { getChannelIdAndMembers } from "../../utils/chatUtils";

interface ChannelFactoryParams {
  creatorId?: number;
  prefix: CHANNEL_ID_PREFIX;
  relatedId?: number;
  extraMembers?: number[];
}

/*
 * useChannelFactory - takes the necessary parameters to create a channel from scratch using `getChannelIdAndMembers`
 * returns a function that will create an uninitailized stream-chat channel when invoked
 */
const useChannelFactory = ({
  creatorId,
  prefix,
  relatedId,
  extraMembers = [],
}: ChannelFactoryParams) => {
  const { client } = useChatContext("useExistingChannel") as {
    client: StreamChat<DefaultGenerics>;
  };
  const getChannel = useCallback(() => {
    if (!creatorId || !relatedId) return undefined;
    const { channelId, members } = getChannelIdAndMembers({
      creatorId,
      prefix,
      relatedId,
      extraMembers,
    });
    return client.channel(CHANNEL_TYPE.MESSAGING, channelId, {
      members: members.map((item) => String(item)),
      prefix,
    });
  }, [client, prefix, relatedId, creatorId, extraMembers]);

  return getChannel;
};

export default useChannelFactory;
