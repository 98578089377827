import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useCallback, useLayoutEffect } from "react";
import { useAtom } from "jotai";
import { toast } from "react-toastify";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import Grid2 from "@mui/material/Unstable_Grid2";
import { hubSpotFormIDModalAtom } from "../../../atoms/hubspotAtoms";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";

export const HubspotFormModal = () => {
  const [hubSpotFormID, setHubSpotFormID] = useAtom(hubSpotFormIDModalAtom);
  const open = Boolean(hubSpotFormID);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = useCallback(() => {
    setHubSpotFormID("");
  }, [setHubSpotFormID]);
  const handleCompleted = useCallback(() => {
    emitAnalyticsTrackingEvent("hubspot_form_submitted", {
      form_id: hubSpotFormID,
    });
    handleClose();
  }, [handleClose]);

  const handleCanceled = useCallback(() => {
    emitAnalyticsTrackingEvent("hubspot_form_canceled", {
      form_id: hubSpotFormID,
    });
    handleClose();
  }, [handleClose]);
  useLayoutEffect(() => {
    if (window.hbspt && open) {
      // @ts-expect-error We don't know the shape of hbspt
      window.hbspt.forms.create({
        region: "na1",
        portalId: "20049731",
        formId: hubSpotFormID,
        target: "#hubspot-form",
        onFormSubmitted: () => {
          handleCompleted();
          toast.success("Your submission has been received!");
        },
      });
    }
  }, [hubSpotFormID, handleCompleted, open]);
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleCanceled}
      maxWidth={"lg"}
      fullWidth
      aria-labelledby="responsive-dialog-title"
      scroll={"paper"}
      sx={{
        minHeight: "80vh",
      }}
    >
      <DialogTitle component={Grid2} container>
        <Grid2 xs={11}>
          Welcome to EngineEars for Artists
          <br />
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.primary,
            })}
            variant={"body1"}
          >
            Help us get to know you so we can tailor the EngineEars experience
            to you.
          </Typography>
        </Grid2>
        <Grid2 xs={1} display={"flex"} justifyContent={"flex-end"}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCanceled}
            aria-label="close"
          >
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        </Grid2>
      </DialogTitle>
      <DialogContent id={"hubspot-form"}>
        <DialogContentText>
          <SoundWaveLoader width={50} height={50} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
