import { SocialMediaListEnum } from "../../stories/types/types";

export const formatSocialMediaUsernameForHyperlink = (
  changes: Map<SocialMediaListEnum, string>,
): Map<SocialMediaListEnum, string> => {
  const formattedUserNames = new Map<SocialMediaListEnum, string>();
  for (const [key, value] of changes.entries()) {
    switch (key) {
      case SocialMediaListEnum.tiktok:
        if (!value.length) {
          formattedUserNames.set(key, value);
        } else if (value[0] === "@") {
          formattedUserNames.set(key, value);
        } else {
          formattedUserNames.set(key, "@" + value);
        }
        break;
      default:
        if (!value.length) {
          formattedUserNames.set(key, value);
        } else if (value[0] === "@") {
          formattedUserNames.set(key, value.slice(1));
        } else {
          formattedUserNames.set(key, value);
        }
    }
  }
  return formattedUserNames;
};
