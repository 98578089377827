import { createSlice } from "@reduxjs/toolkit";
import { RecordingService } from "../models/recording";
import { fetchAvailableRecordingEngineers } from "./engineerRecommendation";

interface EngineerRecordingServicesState {
  // Recording services keyed by engineer ID.
  [id: number]: RecordingService;
}

const initialState: EngineerRecordingServicesState = {};

export const engineerRecordingServicesSlice = createSlice({
  name: "engineerRecordingServices",
  initialState,
  reducers: {
    clearEngineerRecordingServices: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAvailableRecordingEngineers.fulfilled,
      (state, action) => {
        const updatedState = state;
        const { affiliated, unaffiliated } = action.payload;
        affiliated.forEach((service) => {
          if (service.engineer && !updatedState[service.engineer.id]) {
            updatedState[service.engineer.id] = service;
          }
        });
        unaffiliated.forEach((service) => {
          if (service.engineer && !updatedState[service.engineer.id]) {
            updatedState[service.engineer.id] = service;
          }
        });
        state = updatedState;
      },
    );
  },
});
export const { clearEngineerRecordingServices } =
  engineerRecordingServicesSlice.actions;
export default engineerRecordingServicesSlice.reducer;
