import { useMemo, useState, ChangeEvent } from "react";
import { useAppSelector } from "../../../store/hooks";
import {
  Alt,
  AltStringToAltEnumMap,
  AtmosAltStringToAltEnumMap,
  MasteringAltStringToAltEnumMap,
  ProjectTypeToDefaultAltsMap,
  altToDisplayString,
} from "../../../store/models/alts";
import { ProjectType, Project } from "../../../store/models/project";
import Service from "../../../store/models/service";
import { selectPromoCodeDiscountMultiplier } from "../../../store/selectors/marketingSelectors";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import "./CheckoutAltsForm.css";

export interface CheckoutAltsFormProps {
  alts: Set<Alt>;
  setAlts?: (alts: Set<Alt>) => void;
  service: Service;
  disabled: boolean;
  projectToHandoff?: Project;
}

export const CheckoutAltsForm = ({
  alts,
  service,
  disabled,
  projectToHandoff,
  setAlts,
}: CheckoutAltsFormProps) => {
  const [refresh, setRefresh] = useState(0);
  const discountMultiplier = useAppSelector(selectPromoCodeDiscountMultiplier);

  const onChangeAltsForm = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedAlt = parseInt(e.target.value);
    const altsCopy = alts;
    altsCopy.has(selectedAlt)
      ? altsCopy.delete(selectedAlt)
      : altsCopy.add(selectedAlt);
    if (setAlts) setAlts(altsCopy);
    setRefresh(refresh + 1);
  };

  // For ATMOS, only main and clean mixes make sense.
  const altMap = useMemo(() => {
    const altMapToDisplay =
      service.service_type === ProjectType.ATMOS_MIXING
        ? AtmosAltStringToAltEnumMap
        : service.service_type === ProjectType.MASTERING
          ? MasteringAltStringToAltEnumMap
          : AltStringToAltEnumMap;
    if (projectToHandoff) {
      const projectEnumList = projectToHandoff.alts?.reduce((acc, curr) => {
        if (curr) {
          const { alt } = curr;
          return [...acc, alt];
        }
        return acc;
      }, [] as Alt[]);
      const filteredAltMapToDisplay = new Map<string, Alt>();
      projectEnumList?.forEach((alt) => {
        const key = altToDisplayString[alt];
        if (key && altMapToDisplay.has(key))
          filteredAltMapToDisplay.set(key, alt);
      });
      return filteredAltMapToDisplay;
    }
    return altMapToDisplay;
  }, [service.service_type, projectToHandoff]);

  return (
    <form className="add-alts-form">
      <h1 className="alts-header">ADD-ONS (OPTIONAL)</h1>
      <div className="alts-sub-header">
        <p>Alts</p>
        <p>
          {PennyDollarFormatter().format(
            +service.extra_alt_price * discountMultiplier,
          )}
          /song
        </p>
      </div>
      <div className="alts-checkbox-container">
        <div key={-1} className="alt-checkbox py-2">
          <input
            onChange={onChangeAltsForm}
            type="checkbox"
            name={"Main"}
            value={0}
            disabled={true}
            checked={true}
          />
          <label htmlFor={"Main"}>{"Main ($0)"}</label>
        </div>
        {Array.from(altMap.keys()).map((altLabel, i) => (
          <div key={i} className="alt-checkbox py-2">
            <input
              onChange={onChangeAltsForm}
              type="checkbox"
              id={altLabel}
              disabled={
                disabled ||
                ProjectTypeToDefaultAltsMap.get(service.service_type)?.has(i)
              }
              value={altMap.get(altLabel)}
              checked={alts.has(i as Alt)}
            />
            <label htmlFor={altLabel}>
              {altLabel}{" "}
              {ProjectTypeToDefaultAltsMap.get(service.service_type)?.has(i)
                ? "($0)"
                : ""}
            </label>
          </div>
        ))}
      </div>
    </form>
  );
};
