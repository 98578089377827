import { FC } from "react";
import "./DisplayError.css";

export interface DisplayErrorProps {
  errorMessage: string;
}

export const DisplayError: FC<DisplayErrorProps> = ({ errorMessage }) => {
  return (
    <div
      className="error-message"
      style={{
        display: errorMessage ? "block" : "none",
      }}
    >
      {errorMessage} <br />
    </div>
  );
};
