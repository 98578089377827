import { styled } from "styled-components";

export const ProjectListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: inherit;
`;

export const ProjectListBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-top: 16px;
`;

export const ProjectListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
`;

export const ProjectListText = styled.p<{
  weight?: boolean;
  isTertiary?: boolean;
  hasBorder?: boolean;
}>`
  color: ${({ theme, isTertiary }) =>
    isTertiary ? theme.textTertiaryColor : theme.textPrimaryColor};
  font-family: "Roobert", sans-serif;
  font-size: ${({ theme }) => theme.textSizeMd};
  font-style: normal;
  font-weight: ${({ weight }) => (weight ? "600" : "400")};
  border-bottom: ${({ theme, hasBorder }) =>
    hasBorder ? `1px solid ${theme.seperatorColor}` : ""};
`;
