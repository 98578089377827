import React from "react";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import styled from "styled-components";

// Style defined in the same file because its unique to this screen and the only component
const AppScreenContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export interface AppScreenContentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
}

export const AppScreenContent = ({
  children,
  isLoading,
  ...props
}: AppScreenContentProps) => {
  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <AppScreenContentWrapper {...props}>{children}</AppScreenContentWrapper>
  );
};
