import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Lottie from "react-lottie";
import { isStudioSeparateStripeEnabled } from "../../../hooks/useIsStudioSeperateStripeOptIn";
import { payoutStripeBalance } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { FixedRangeNumberField } from "../../elements/FixedRangeNumberField/FixedRangeNumberField";
import animationData from "../../lotties/success.json";
import "./CashOutModal.css";
import {
  OnboardingChecklistItemEnum,
  useUpdateOnboardingProgressMutation,
} from "../../../hooks/onboardingHooks/useUpdateOnboardingProgressMutation";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import { AddSubscriptionContainer } from "./CashOutModal.styles";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import Link from "../../elements/Link/Link";
import { getAddSubscriptionRoute } from "../../../store/utils/routeGetters";
import { useTheme } from "@mui/material";

export interface CashOutModalProps {
  amountToWithdraw: number;
  currencyToWithdraw: string;
  showModal: boolean;
  cashingOut: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setCashingOut: Dispatch<SetStateAction<boolean>>;
  possibleFeeRefundAmount: number;
}

const defaultOptions = {
  loop: false,
  autoplay: false,
  duration: 1000,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const CashOutModal: FC<CashOutModalProps> = ({
  amountToWithdraw,
  currencyToWithdraw,
  showModal,
  cashingOut,
  setCashingOut,
  setShowModal,
  possibleFeeRefundAmount,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [lottiePause, setLottiePause] = useState(true);
  const [showSuccessScreen, setShowSuccess] = useState(false);
  const [amountToCashOut, setAmountToCashOut] = useState(amountToWithdraw);
  const user = useAppSelector((state) => state.accountInfo.user);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const isStudioSeparateStripeToggled =
    isStudioSeparateStripeEnabled(selectedProfile);
  const { mutateAsync: updateOnboardingProgress } =
    useUpdateOnboardingProgressMutation({
      userId: user?.id,
    });
  const subscription = useAppSelector((state) => state.subscriptionStore);
  const hasPlatinumSubscription = Boolean(
    subscription?.subscription_plan_choice ===
      SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY ||
      subscription?.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY ||
      subscription?.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY,
  );

  useEffect(() => {
    setAmountToCashOut(amountToWithdraw);
  }, [amountToWithdraw]);

  useEffect(() => {
    if (showModal) setShowSuccess(false);
  }, [showModal]);

  useEffect(() => {
    if (showSuccessScreen) {
      setTimeout(() => {
        setLottiePause(false);
      }, 400);
    }
  }, [showSuccessScreen]);

  const onClickCashOut = useCallback(() => {
    setCashingOut(true);
    emitAnalyticsTrackingEvent("cash_out_stripe_balance", {}, user?.id);
    dispatch(
      payoutStripeBalance({
        amount_to_withdraw: amountToCashOut,
        ...(isStudioSeparateStripeToggled && {
          studio_id: selectedProfile?.studio?.id,
        }),
      }),
    )
      .unwrap()
      .then(async ({ transfer }) => {
        if (transfer) {
          setShowSuccess(true);
          await updateOnboardingProgress(
            OnboardingChecklistItemEnum.CASHED_OUT,
          );
        }
        setCashingOut(false);
      })
      .catch(() => {});
  }, [amountToCashOut]);

  return (
    <BaseModal
      open={showModal}
      setOpen={setShowModal}
      header={showSuccessScreen ? "Congratulations" : "Cash Out"}
    >
      <div className="cash-out-modal project-page-modal">
        {showSuccessScreen ? (
          <>
            <Lottie
              isStopped={lottiePause}
              options={defaultOptions}
              height={150}
              width={150}
            />
            <p className="b1">
              Your payout was successful! Please wait up to a couple business
              days for funds to appear in your account.
            </p>
          </>
        ) : (
          <>
            <p className="mb-3">
              {`Enter the amount of money you would like to cash out (${PennyDollarFormatter(
                currencyToWithdraw,
              ).format(amountToWithdraw)} available)`}
              :
            </p>
            <div className="mb-3">
              <FixedRangeNumberField
                parseAsFloat={true}
                initialValue={amountToWithdraw}
                min={1}
                max={amountToWithdraw}
                onChange={(value) => {
                  setAmountToCashOut(value);
                }}
              />
            </div>
            {!hasPlatinumSubscription && possibleFeeRefundAmount > 0 && (
              <AddSubscriptionContainer>
                <Text
                  variant={TextStyleVariant.P1}
                  weight={TEXT_WEIGHT.BOLD}
                  style={{ color: theme.palette.standardColor.DeepBlue[800] }}
                >
                  Get ${possibleFeeRefundAmount} back in service fees today by
                  upgrading to{" "}
                  <Link
                    to={getAddSubscriptionRoute(true)}
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <Text
                      variant={TextStyleVariant.P1}
                      weight={TEXT_WEIGHT.BOLD}
                      style={{
                        display: "inline",
                        color: theme.palette.standardColor.DeepBlue[800],
                        textDecoration: "underline",
                      }}
                    >
                      Platinum!
                    </Text>
                  </Link>
                  <Link
                    to={getAddSubscriptionRoute(true)}
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <Text
                      variant={TextStyleVariant.P2}
                      style={{
                        display: "inline",
                        color: theme.palette.standardColor.DeepBlue[800],
                        textDecoration: "underline",
                      }}
                    >
                      learn more
                    </Text>
                  </Link>
                </Text>
              </AddSubscriptionContainer>
            )}
            <Button
              loading={cashingOut}
              variant={ButtonVariant.PRIMARY}
              onClick={onClickCashOut}
            >
              Cash Out To Bank
            </Button>
          </>
        )}
      </div>
    </BaseModal>
  );
};
