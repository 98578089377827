import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import Engineer from "../models/engineer";
import { STUDIO_PROFILE_FOUND } from "../models/exceptions";
import Photo from "../models/photo";
import User from "../models/user";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { FETCH_USER_PROFILE } from "../utils/routes";
import {
  getUserEngineer,
  GetUserEngineerParams,
  updateUserAAndR,
  updateUserArtist,
  updateUserEngineer,
  updateUserListener,
  updateUserOther,
  updateUserProducer,
  updateUserStudioManager,
  uploadPhoto,
  uploadPhotoParam,
} from "./accountInfo";
import { receiveErrors } from "./errorStore";

interface Users {
  [username: string]: User | undefined;
  [user_id: number]: User | undefined;
}

const initialState: Users = {};

export interface fetchProfileParams {
  username?: string;
  user_id?: number;
  redirectToStudioScreen?: () => void;
}

export const NO_USERNAME_PRESENT = "";

export const fetchProfile = createAsyncThunk(
  FETCH_USER_PROFILE,
  async (
    { username, user_id, redirectToStudioScreen }: fetchProfileParams,
    thunkAPI,
  ) => {
    const result = await makeBackendGetCallWithJsonResponse<User>(
      FETCH_USER_PROFILE,
      `?${username ? `username=${encodeURIComponent(username.toLowerCase())}` : `user_id=${user_id}`}`,
    );
    if (result.success) {
      return result.resultJson;
    }
    if (result.resultJson.code === STUDIO_PROFILE_FOUND) {
      redirectToStudioScreen?.();
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const usersSlice = createSlice({
  name: "usersStateSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getUserEngineer.fulfilled,
      (
        state,
        action: PayloadAction<Engineer, string, { arg: GetUserEngineerParams }>,
      ) => {
        const { username, user_id } = action.meta.arg;
        if (!username) {
          return;
        }
        if (!user_id) {
          return;
        }
        const engineer = action.payload;
        if (state[username]) {
          const user = state[username]!;
          state[username] = {
            ...user,
            engineer,
          };
        }
        if (state[user_id]) {
          const user = state[user_id]!;
          state[user_id] = {
            ...user,
            engineer,
          };
        }
      },
    );
    builder.addCase(
      uploadPhoto.fulfilled,
      (
        state,
        action: PayloadAction<Photo, string, { arg: uploadPhotoParam }>,
      ) => {
        const { username } = action.meta.arg;
        const photo = action.payload;
        if (state[username] !== undefined) {
          const user = state[username] as User;
          state[username] = {
            ...user,
            photo,
          };
          state[user.id] = {
            ...user,
            photo,
          };
        }
      },
    );
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const username = meta.arg.username;
      const userId = meta.arg.user_id;
      if (!username && !userId) {
        return;
      }
      state[username ?? payload.username] = payload;
      state[userId ?? payload.id] = payload;
    });
    builder.addMatcher(
      isAnyOf(
        updateUserEngineer.fulfilled,
        updateUserProducer.fulfilled,
        updateUserStudioManager.fulfilled,
        updateUserArtist.fulfilled,
        updateUserAAndR.fulfilled,
        updateUserOther.fulfilled,
        updateUserListener.fulfilled,
      ),
      (state, action: PayloadAction<User>) => {
        const user = action.payload;
        state[user.username] = user;
        state[user.id] = user;
      },
    );
  },
});

export default usersSlice.reducer;
