import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { MouseEventHandler } from "react";
import { TEXT_SIZE, Text } from "../../core-ui/components/Text/Text";

interface GenerateBookingUtilityButtonProps {
  icon: IconDefinition;
  isSelected?: boolean;
  text: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const GenerateBookingUtilityButton = ({
  icon,
  isSelected = false,
  text,
  onClick,
  disabled = false,
}: GenerateBookingUtilityButtonProps) => {
  const cssPrefix = "generate-booking-select-service_";
  return (
    <button
      className={classNames({
        [`${cssPrefix}delete-container`]: true,
        [`${cssPrefix}delete-container--selected`]: isSelected,
        [`${cssPrefix}delete-container--disabled`]: disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={icon} height={16} width={16} />
      <Text
        className={`${cssPrefix}controls-container-text`}
        size={TEXT_SIZE.SMALL}
      >
        {text}
      </Text>
    </button>
  );
};
