import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseButton } from "./SidePanelFilters.styles";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export interface SidePanelCloseButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const SidePanelCloseButton = ({
  onClick,
}: SidePanelCloseButtonProps) => {
  return (
    <CloseButton className="side-panel-close-button" onClick={onClick}>
      <FontAwesomeIcon
        icon={faClose}
        size="lg"
        color="var(--text-primary-color)"
      />
    </CloseButton>
  );
};

export default SidePanelCloseButton;
