import { useSetAtom } from "jotai";
import {
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import { CHANNEL_ID_PREFIX } from "../../../constants/chat";
import { useAppSelector } from "../../../store/hooks";
import { callAllEventHandlers } from "../../../utils/utils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { openChatModalAtom } from "../ChatModal/atoms";
import { PrerequisiteButtons } from "../PrerequisiteButtons/PrequisiteButtons";
import useQueryChannel from "../../../hooks/chatHooks/useQueryChannel";
import useChannelFactory from "../../../hooks/chatHooks/useChannelFactory";

interface ChatButtonProps {
  buttonVariant?: ButtonVariant;
  buttonText: ReactNode;
  prefix: CHANNEL_ID_PREFIX;
  relatedId?: number;
  extraMembers?: number[];
  customButtonStyle?: CSSProperties;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>; // This event handler can be passed in to do extra stuff beside opening chat panel, such as track events.
  className?: string;
}

export const ChatButton = ({
  buttonText,
  buttonVariant = ButtonVariant.OUTLINED,
  prefix,
  relatedId,
  extraMembers = [],
  customButtonStyle,
  onButtonClick = () => {},
  className,
}: ChatButtonProps) => {
  const currentUser = useAppSelector((state) => state.accountInfo.user);

  const setChatModalOpen = useSetAtom(openChatModalAtom);

  const { data: channel, isFetching } = useQueryChannel({
    creatorId: currentUser?.id || 0,
    prefix,
    relatedId,
    extraMembers,
  });
  const getChannel = useChannelFactory({
    creatorId: currentUser?.id || 0,
    prefix,
    relatedId,
    extraMembers,
  });

  const handleOnClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(async () => {
      let cId = channel?.id;
      if (!cId) {
        try {
          // The channel object is automatically created if no channel exists yet
          const newChannel = getChannel();
          if (newChannel) {
            cId = (await newChannel.create()).channel.id;
          }
        } catch (error) {
          toast.error("Something went wrong. Please contact customer support.");
        }
      }
      if (cId) {
        setChatModalOpen(cId);
      }
    }, [channel, setChatModalOpen, getChannel]);
  return (
    <PrerequisiteButtons
      loginRequiredButtonProps={{
        message: "Please log in or sign up to send a message.",
      }}
      customStyle={customButtonStyle}
    >
      <Button
        style={customButtonStyle}
        variant={buttonVariant}
        loading={isFetching}
        onClick={callAllEventHandlers(handleOnClick, onButtonClick)}
        className={className}
      >
        {buttonText}
      </Button>
    </PrerequisiteButtons>
  );
};
