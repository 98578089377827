import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useAppDispatch } from "../../../store/hooks";
import {
  getEngineerFeesCollected,
  getServiceProviderReadableTransactionType,
  getTotalFromTransaction,
  Transaction,
  TransactionItem,
  TransactionType,
} from "../../../store/models/transaction";
import { getTransactionInvoiceUrl } from "../../../store/actions/transactions";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { formatDateToShort } from "../../../utils/formatDateToShort";
import { UserProfileImage } from "../../elements/UserProfileImage/UserProfileImage";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import "./TransactionRow.css";
import { convertUTCDateToLocalDate } from "../../../store/utils/dateTimeUtils";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { ProjectType } from "../../../store/models/project";

export interface TransactionRowProps {
  // if transaction is undefined, show loading state
  transaction?: Transaction;
  rowIndex: number;
}

function getProjectTitle(
  transaction: Transaction,
  transactionItems: TransactionItem[],
  defaultTitle: string,
): string {
  if (transactionItems.length === 0) {
    return defaultTitle;
  }

  const title = transactionItems[0].project?.title;
  if (title === null || title === undefined) {
    return "";
  }

  if (transaction.items[0].project?.service_type === ProjectType.RECORDING) {
    return `Session: ${title}`;
  }

  return `Song: ${title}`;
}

export const TransactionRow = ({
  transaction,
  rowIndex,
}: TransactionRowProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isLaptop = useMediaQuery("(max-width: 1024px)");
  function getTransactionTitle(transaction: Transaction): string {
    const transactionItems = transaction.items;
    switch (transaction.transaction_type) {
      case TransactionType.PAYMENT:
        // Multi song project
        if (transactionItems.length > 1) {
          if (
            transaction.items[0].project?.service_type === ProjectType.RECORDING
          ) {
            return `Session: ${transaction.items[0].project?.title}`;
          }
          return `Project: ${transaction.scheduled_project_title}`;
        }
        // Single song project
        return getProjectTitle(
          transaction,
          transactionItems,
          `Project Payment`,
        );
      case TransactionType.PAYOUT:
        return getProjectTitle(transaction, transactionItems, `Payout`);
      case TransactionType.REFUND:
        return getProjectTitle(transaction, transactionItems, `Refund`);
      default:
        return "";
    }
  }

  const onClickDownloadInvoice = async () => {
    setIsLoading(true);
    try {
      const { hosted_invoice_url: hostedInvoiceUrl } = await dispatch(
        getTransactionInvoiceUrl({
          transaction_id: +transaction!.id,
        }),
      ).unwrap();
      window.open(hostedInvoiceUrl, "_blank");
    } finally {
      setIsLoading(false);
    }
  };

  const showDownloadInvoiceOption =
    transaction?.transaction_type == TransactionType.PAYMENT &&
    Boolean(transaction?.stripe_invoice_id);
  return (
    <tr
      className={`transactions-table-row ${
        rowIndex % 2 == 0 ? "odd-transaction-row" : ""
      }`}
    >
      {!transaction && <SoundWaveLoader width={50} height={50} />}
      {transaction !== undefined && (
        <>
          <td>
            <p className="b2">
              {formatDateToShort(
                convertUTCDateToLocalDate(new Date(transaction.created)),
              )}
            </p>
          </td>
          <td>
            <p className="b2">
              {getServiceProviderReadableTransactionType(
                transaction.transaction_type,
              )}
            </p>
          </td>
          <td>
            <p className="b2">
              {PennyDollarFormatter().format(
                getTotalFromTransaction(transaction),
              )}
            </p>
          </td>
          <td>
            <p className="b2">
              {PennyDollarFormatter().format(
                getEngineerFeesCollected(transaction),
              )}
            </p>
          </td>
          {!isLaptop && (
            <td>
              {transaction.user && (
                <div className={"transaction-user-row"}>
                  <UserProfileImage
                    username={transaction?.user.username}
                    hideBorder
                    editMode={false}
                    source={transaction?.user?.photo?.path}
                    width={35}
                    height={35}
                    isCircle
                  />
                  <div className="transaction-user-info-column">
                    <p className="b2">{`${getDisplayableNameForUser(
                      transaction.user,
                    )}`}</p>
                    {transaction.items ? (
                      <p className="b2">{getTransactionTitle(transaction)}</p>
                    ) : null}
                  </div>
                </div>
              )}
            </td>
          )}
          <td>
            {showDownloadInvoiceOption &&
              (isLoading ? (
                <SoundWaveLoader width={50} height={50} />
              ) : (
                <div
                  onClick={onClickDownloadInvoice}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faDownload}
                    color="var(--medium-grey)"
                    style={{ width: 16, height: 16 }}
                  />
                </div>
              ))}
          </td>
        </>
      )}
    </tr>
  );
};
