import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";
import { InProgressTransitionViewIndex } from "../InProgressTransitionView/InProgressTransitionView";

export interface MainProjectWorkflowContextType {
  showEngineerProjectFileUploader: boolean;
  setShowEngineerProjectFileUploader: Dispatch<SetStateAction<boolean>>;
  inProgressViewIndex: InProgressTransitionViewIndex;
  setInProgressViewIndex: Dispatch<
    SetStateAction<InProgressTransitionViewIndex>
  >;
}

export const MainProjectWorkflowContext =
  createContext<MainProjectWorkflowContextType | null>(null);

/**
 * Allows local state management for the MainProjectWorkflowPanel component.
 * i.e. Action dropdown should be able to effect various components at various steps
 */
export const MainProjectWorkflowPanelProvider = (props: {
  children: ReactNode;
}) => {
  const [showEngineerProjectFileUploader, setShowEngineerProjectFileUploader] =
    useState(false);
  const [viewIndex, setViewIndex] = useState<InProgressTransitionViewIndex>(
    InProgressTransitionViewIndex.TRACKS,
  );
  return (
    <MainProjectWorkflowContext.Provider
      value={{
        showEngineerProjectFileUploader,
        setShowEngineerProjectFileUploader,
        inProgressViewIndex: viewIndex,
        setInProgressViewIndex: setViewIndex,
      }}
    >
      {props.children}
    </MainProjectWorkflowContext.Provider>
  );
};
