import { useAtom } from "jotai";
import { useContext } from "react";
import { DropdownContext } from "../../../../../core-ui/components/Select/Select";
import {
  MainWorkflowView,
  mainWorkflowViewAtom,
} from "../../../../MainProjectWorkflowPanel/atoms";
import { DropdownMenuItem } from "../../../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";

/**
 * Action Option to toggle open the booking details view
 */
export const ProjectActionViewBookingDetails = () => {
  const dropdownContext = useContext(DropdownContext);
  const [mainWorkflowViewIndex, setMainWorkflowViewIndex] =
    useAtom(mainWorkflowViewAtom);
  const isMainView = mainWorkflowViewIndex === MainWorkflowView.MAIN;

  return (
    <DropdownMenuItem
      onSelect={(e: Event) => {
        e.preventDefault();
        setMainWorkflowViewIndex(
          isMainView ? MainWorkflowView.BOOKING_DETAILS : MainWorkflowView.MAIN,
        );
        dropdownContext?.closeDropdownMenu();
      }}
    >
      View {isMainView ? "booking" : "project"} details
    </DropdownMenuItem>
  );
};
