import styled from "styled-components";

export const StyledABToggleSwitchContainer = styled.div`
  display: flex;
  gap: 8px;
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  font-size: ${({ theme }) => theme.textSizeSm};
  color: ${({ theme }) => theme.textSecondaryColor};
  cursor: pointer;
  align-items: center;
`;

export const StyledABP = styled.p<{ selected?: boolean }>`
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  font-size: ${({ theme }) => theme.textSizeSm};
  color: ${({ theme, selected }) =>
    selected ? theme.colorPalette.BoomyOrange400 : theme.textSecondaryColor};
`;
