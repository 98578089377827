import styled from "styled-components";
import { ColorPalette } from "../../../theme";

export const StyledSwitchButtonAB = styled.button<{
  $isSelected: boolean;
  $isProjectView?: boolean;
}>`
  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? ColorPalette.BoomyOrange400 : theme.midgroundColor};
  border: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 26px;
  min-width: ${({ $isProjectView }) => ($isProjectView ? `26px` : `60px`)};
  padding: 8px;
  border-radius: 8px;
`;

export const StyledSwitchButtonABP = styled.p<{ $isSelected: boolean }>`
  text-wrap: wrap;
  word-break: break-word;
  word-wrap: break-word;
  font-size: ${({ theme }) => theme.textSizeXs};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  color: ${({ $isSelected, theme }) =>
    $isSelected ? ColorPalette.White : theme.textPrimaryColor};
`;
