import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { getSessionBookingDetails } from "../../store/actions/recording";
import { useAppDispatch } from "../../store/hooks";

interface UseGetSessionBookingDetailsParams {
  bookingRequestId: number | null;
  enabled?: boolean;
}

/**
 * Used to fetch Booking Details displayed on Recording Session Booking Request Panel
 */
export const useGetSessionBookingDetails = ({
  bookingRequestId,
  enabled = true,
}: UseGetSessionBookingDetailsParams) => {
  const dispatch = useAppDispatch();
  return useQuery({
    queryKey: [QUERY_KEYS.GET_SESSION_BOOKING_DETAILS, bookingRequestId],
    queryFn: async () => {
      if (!bookingRequestId) return null;
      return dispatch(
        getSessionBookingDetails({
          bookingId: bookingRequestId,
        }),
      ).unwrap();
    },
    enabled: enabled && Boolean(bookingRequestId),
  });
};
