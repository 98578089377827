import { useMemo } from "react";
import { formatDateToShort } from "../../../../../utils/formatDateToShort";

const useProjectCompletion = (
  numberOfCompletedTracks: number,
  scheduledProjectProjectsLength: number,
  estimatedDeliveryDate: string | null,
) => {
  const completedTracksPercentage = useMemo(() => {
    return (numberOfCompletedTracks / scheduledProjectProjectsLength) * 100;
  }, [numberOfCompletedTracks, scheduledProjectProjectsLength]);

  const estimatedCompletionDate = useMemo(
    () =>
      estimatedDeliveryDate
        ? formatDateToShort(new Date(estimatedDeliveryDate))
        : "TBD",
    [estimatedDeliveryDate],
  );

  return { completedTracksPercentage, estimatedCompletionDate };
};

export default useProjectCompletion;
