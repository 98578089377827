import styled from "styled-components";

export const ToggleSwitchText = styled.p`
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  font-size: ${({ theme }) => theme.textSizeMd};
  color: ${({ theme }) => theme.textPrimaryColor};
  margin-right: 8px;
`;

export const DropdownSelectorContainer = styled.div`
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-bottom: 70px;
  }
`;
