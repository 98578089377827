import { faHeart as faHeartEmpty } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartFilled } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useAppSelector } from "../../../store/hooks";
import {
  FavoriteHeartProps,
  useFavoriteHeartLogic,
} from "../../../store/models/favoritedAccount";
import "./FavoriteHeart.css";

export const FavoriteHeart: React.FC<FavoriteHeartProps> = ({
  liked_user,
  liked_studio,
  liked_studio_room,
}) => {
  const { isAuthenticated, user } = useAppSelector(
    (state) => state.accountInfo,
  );

  const { isFilled, handleOnClick, updating } = useFavoriteHeartLogic({
    liked_user,
    liked_studio,
    liked_studio_room,
  });
  if (isAuthenticated && user?.id === liked_user) return null;

  return (
    <FontAwesomeIcon
      style={{
        animation: updating ? "heartBeat 1s linear infinite" : "",
      }}
      className="favorite-heart-container"
      icon={isFilled ? faHeartFilled : faHeartEmpty}
      color={isFilled ? "var(--heart-filled-red)" : "var(--white)"}
      onClick={handleOnClick}
    />
  );
};

export const SaveProfileButton: React.FC<FavoriteHeartProps> = ({
  liked_user,
  liked_studio,
  liked_studio_room,
}: FavoriteHeartProps) => {
  const { isAuthenticated, user } = useAppSelector(
    (state) => state.accountInfo,
  );

  const { isFilled, handleOnClick, updating } = useFavoriteHeartLogic({
    liked_user,
    liked_studio,
    liked_studio_room,
  });

  if (isAuthenticated && user?.id === liked_user) return null;

  return (
    <button onClick={handleOnClick} className="save-profile-button">
      <p className="label2">{isFilled ? "Saved" : "Save"}</p>
      <FontAwesomeIcon
        style={{
          animation: updating ? "heartBeat 1s linear infinite" : "",
        }}
        className="favorite-heart-font-awesome-icon"
        icon={isFilled ? faHeartFilled : faHeartEmpty}
        color={isFilled ? "var(--heart-filled-red)" : "var(--black)"}
      />
    </button>
  );
};
