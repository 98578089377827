import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { fetchFiles } from "../../store/actions/fileVersions";
import { useAppDispatch } from "../../store/hooks";
import { FILE_STATUS } from "../../store/models/fileVersion";

interface useGetFileVersionsProps {
  projectId: number;
  status: FILE_STATUS;
}

export const useGetFileVersions = ({
  projectId,
  status,
}: useGetFileVersionsProps) => {
  const dispatch = useAppDispatch();
  return useQuery({
    queryKey: [QUERY_KEYS.GET_FILE_VERSIONS, projectId, status],
    queryFn: async () => {
      const result = await dispatch(fetchFiles({ projectId, status })).unwrap();
      return result;
    },
  });
};
