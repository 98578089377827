import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled, Box } from "@mui/material";

export const FrequentlyAskedItemBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: `${theme.spacing(1.5)} 0`,
}));

export const FrequentlyAskedResponseBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: `${theme.spacing(1)} 0 ${theme.spacing(2.5)} 0`,
}));

export const FrequentlyAskedQuestionEditContainer = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    width: "100%",
    boxSizing: "border-box",
    gap: theme.spacing(1.5),
    padding: `${theme.spacing(2)} 0`,
    alignSelf: "stretch",
  }),
);

export const ColoredFAIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const FrequentlyAskedQuestionEditField = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  boxSizing: "border-box",
  gap: theme.spacing(1),
}));

export const FaXBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  height: "32px",
  width: "32px",
  background: theme.palette.customColor.midgroundColor,
  borderRadius: theme.shape.borderRadius,
}));

export const FrequentlyAskedItemsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
