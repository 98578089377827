import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledSessionsOverviewContainer } from "../SessionsOverview/SessionsOverview.styles";

export const BookingDetailsData = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 8px;
`;

export const SessionBookingDetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StudioRoomName = styled(Link)`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  text-decoration: underline;
`;

export const StudioName = styled(Link)`
  color: ${({ theme }) => theme.textPrimaryColor};
  text-decoration: underline;
`;

export const SessionBookingDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  font-size: ${({ theme }) => theme.textSizeSm};

  ${StyledSessionsOverviewContainer} {
    padding: 0;
  }
`;

export const SessionBookingStudioInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SessionRequestedPanelDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  font-size: ${({ theme }) => theme.textSizeSm};
  margin-bottom: 24px;
  color: ${({ theme }) => theme.textPrimaryColor};
`;

export const SessionDetailsTitleContainer = styled.div`
  display: flex;
  align-item: center;
  justify-content: space-between;
`;

export const StyledEditIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colorPalette.Gray300};
`;

export const StyledEditButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  border: none;

  &:hover {
    ${StyledEditIcon} {
      color: ${({ theme }) => theme.colorPalette.Gray400};
    }
  }
`;

export const RescheduleSessionContainer = styled.div`
  // Setting this to match the calendar width
  // So that the popover doesn't span too wide
  @media screen and (min-width: 481px) {
    width: 335px;
  }

  @media screen and (min-width: 769px) {
    width: 320px;
  }

  .rescheduler-modal-body {
    overflow: visible;
  }
`;

export const StyledSessionDetailsTitleHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.6rem;
`;
