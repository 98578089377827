import confetti from "canvas-confetti";
import { atom } from "jotai";
import { ChecklistItemResponse } from "../../../hooks/onboardingHooks/getOnboardingProgress";
import { bottomLeftConfetti } from "../../../utils/confettiUtils";
import { atomWithReset, RESET } from "jotai/utils";

export enum ChecklistPhase {
  PHASE_1 = 1,
  PHASE_2 = 2,
  COMPLETE = -1,
}

export const completedChecklistStepsAtom = atomWithReset(0);
export const totalChecklistStepsAtom = atomWithReset(0);

export const phaseChangeAtom = atomWithReset(false);

interface ChecklistState {
  oldState: undefined | ChecklistItemResponse[];
  currentState: undefined | ChecklistItemResponse[];
}

export const checklistAtom = atomWithReset({} as ChecklistState);

const _checklistPhaseAtom = atomWithReset(
  undefined as ChecklistPhase | undefined,
);

export const checklistPhaseAtom = atom(
  (get) => {
    return get(_checklistPhaseAtom);
  },
  (
    get,
    set,
    phase: ChecklistPhase | ((oldPhase: ChecklistPhase) => ChecklistPhase),
  ) => {
    let result: ChecklistPhase | undefined = undefined;
    const showOnboardingChecklist = get(showOnboardingChecklistAtom);
    set(_checklistPhaseAtom, (oldPhase) => {
      if (typeof phase === "function") {
        result = phase(oldPhase || ChecklistPhase.PHASE_1);
      } else {
        result = phase;
      }
      if (typeof result !== "undefined" && result !== ChecklistPhase.COMPLETE) {
        set(showOnboardingChecklistAtom, true);
      }
      if (
        typeof oldPhase !== "undefined" &&
        oldPhase !== result &&
        result !== ChecklistPhase.PHASE_1
      ) {
        set(phaseChangeAtom, true);
        if (showOnboardingChecklist) bottomLeftConfetti();
        bottomLeftConfetti();
        setTimeout(() => {
          set(phaseChangeAtom, false);
          confetti.reset();
          if (result === ChecklistPhase.COMPLETE) {
            set(showOnboardingChecklistAtom, false);
          }
        }, 3000);
      }
      return result;
    });
  },
);

export const checklistPhaseTitleAtom = atomWithReset("");

export const activeChecklistAtom = atom((get) => {
  const checklist = get(checklistAtom);
  const isPhaseChange = get(phaseChangeAtom);
  if (!isPhaseChange) return checklist.currentState;
  const modified = checklist.oldState?.map((e) => ({
    ...e,
    completed: true,
  }));
  return modified;
});

export const showOnboardingChecklistAtom = atomWithReset(false);

export const resetChecklistAtom = atom(null, (_get, set) => {
  set(completedChecklistStepsAtom, RESET);
  set(checklistPhaseTitleAtom, RESET);
  set(totalChecklistStepsAtom, RESET);
  set(_checklistPhaseAtom, RESET);
  set(checklistAtom, RESET);
  set(phaseChangeAtom, RESET);
});
