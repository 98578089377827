import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { Alt, AltItem } from "../models/alts";
import EngineeringUniqueBookingLink, {
  EngineeringUniqueBookingLinkAPIResponse,
} from "../models/engineeringUniqueBookingLink";
import { ProjectType } from "../models/project";
import {
  MockScheduledProject,
  ScheduledProject,
  transformRawScheduledProjectData,
} from "../models/scheduledproject";
import { getFormattedDateString } from "../utils/dateTimeUtils";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import { MANAGE_PROJECT, UNIQUE_BOOKING_LINK } from "../utils/routes";
import { receiveErrors } from "./errorStore";
import Service, { transformRawData } from "../models/service";
import { removeRescheduledProject } from "./scheduledprojects";
import { getTransaction } from "./transactions";

interface BookProjectsState {
  isLoading: boolean;
  scheduledProject?: ScheduledProject;
  engUniqueBookingLink?: EngineeringUniqueBookingLink;
  alts: AltItem[];
  selectedService?: Service;
}

const initialState: BookProjectsState = {
  isLoading: false,
  scheduledProject: undefined,
  engUniqueBookingLink: undefined,
  alts: [],
  selectedService: undefined,
};

interface loadProjectPricesParams {
  engineer_id: number;
  start_date: Date;
  end_date: Date;
  service_type: number;
}

interface EngineerAvailabilityResponse {
  [date: string]: [number, number, number];
}

export const loadEngineerAvailability = createAsyncThunk(
  MANAGE_PROJECT,
  async (args: loadProjectPricesParams, thunkAPI) => {
    const { engineer_id, start_date, end_date, service_type } = args;
    const params = `?engineer_id=${engineer_id}&start_date=${getFormattedDateString(
      start_date,
    )}&end_date=${getFormattedDateString(
      end_date,
    )}&service_type=${service_type}`;
    const result =
      await makeBackendGetCallWithJsonResponse<EngineerAvailabilityResponse>(
        MANAGE_PROJECT,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface BookingProjectData {
  service_id: number;
  service_type: ProjectType;
  service_provider_user_id: number;
  code?: string;
  title?: string;
  artist_name?: string;
  requested_date: string;
}

export interface RescheduleProjectData {
  requested_date: string;
}

export interface manageProjectParams {
  action: ProjectManagementActions;
  title?: string;
  artist_name?: string;
  project_data?: BookingProjectData[];
  reschedule_data?: RescheduleProjectData[];
  alts?: Alt[] | AltItem[];
  scheduled_project_id?: number;
  emailsList?: string[];
  adminEmail?: string;
  engineer_has_files?: boolean;
  prereq_id?: number;
  promocode?: string;
  transaction_type?: 1;
  financial_method?: 1;
  requested_date?: string;
}

export enum ProjectManagementActions {
  AcceptProject = "accept_project",
  RejectProject = "reject_project",
  RescheduleProject = "reschedule_project",
  DeleteInProgressProject = "delete_in_progress_project",
}

export const manageScheduledProject = createAsyncThunk(
  MANAGE_PROJECT + "post/",
  async (args: manageProjectParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<ScheduledProject>(
      MANAGE_PROJECT,
      args,
    );
    if (result.success) {
      if (args.action === ProjectManagementActions.RescheduleProject) {
        thunkAPI.dispatch(removeRescheduledProject(result.resultJson.id));
      }
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface bookingLinkParams {
  service_type: ProjectType;
  discounted_price?: number;
  bulk_discounts_disabled?: boolean;
}

export const generateUniqueBookingLink = createAsyncThunk(
  UNIQUE_BOOKING_LINK + "post/",
  async (args: bookingLinkParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<EngineeringUniqueBookingLinkAPIResponse>(
        UNIQUE_BOOKING_LINK,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface fetchUniqueBookingLinkParams {
  code: string;
}

export const fetchUniqueBookingLink = createAsyncThunk(
  UNIQUE_BOOKING_LINK,
  async (args: fetchUniqueBookingLinkParams, thunkAPI) => {
    const request_params = "?code=" + args.code;
    const result =
      await makeBackendGetCallWithJsonResponse<EngineeringUniqueBookingLinkAPIResponse>(
        UNIQUE_BOOKING_LINK,
        request_params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    clearBookingState: (state) => {
      state.isLoading = false;
      state.scheduledProject = undefined;
      state.engUniqueBookingLink = undefined;
      state.alts = [];
      state.selectedService = undefined;
    },
    addMockScheduledProject: (state) => {
      state.scheduledProject = MockScheduledProject;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadEngineerAvailability.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(loadEngineerAvailability.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loadEngineerAvailability.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(manageScheduledProject.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(manageScheduledProject.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(manageScheduledProject.fulfilled, (state, action) => {
      const { payload } = action;
      state.scheduledProject = transformRawScheduledProjectData(payload);
      state.isLoading = false;
    });
    builder.addCase(generateUniqueBookingLink.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(generateUniqueBookingLink.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTransaction.fulfilled, (state, action) => {
      if (action.payload.engineering_service_unique_link) {
        state.engUniqueBookingLink = {
          ...action.payload.engineering_service_unique_link,
          engineering_service: transformRawData(
            action.payload.engineering_service_unique_link.engineering_service,
          ),
        };
      }
    });
    builder.addMatcher(
      isAnyOf(
        fetchUniqueBookingLink.fulfilled,
        generateUniqueBookingLink.fulfilled,
      ),
      (state, action) => {
        const { payload } = action;
        state.engUniqueBookingLink = {
          ...payload,
          engineering_service: transformRawData(payload.engineering_service),
        };
        state.isLoading = false;
      },
    );
  },
});

export const { addMockScheduledProject, clearBookingState } =
  bookingSlice.actions;
export default bookingSlice.reducer;
