import {
  faChevronDown,
  faCircle,
  faCircleCheck,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as faCircleBorder } from "@fortawesome/sharp-regular-svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChecklistItemResponse } from "../../../hooks/onboardingHooks/getOnboardingProgress";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { useSetAtom } from "jotai";
import { showChecklistAtom } from "./StickyOnboardingChecklist";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";

export interface ChecklistItemProps extends ChecklistItemResponse {
  itemIndex: number;
  currentExpandedItem: number | boolean;
  handleChecklistItemExpand: (
    index: number,
  ) => (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void;
}

const disabledOpacity = 0.38;

const ChecklistItem = ({
  title,
  description,
  expandable,
  completed,
  button_content: buttonContent,
  guide,
  currentExpandedItem,
  handleChecklistItemExpand,
  itemIndex,
}: ChecklistItemProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const link = buttonContent?.link || "";
  const [baseUrl, searchParam] = link.split("?");
  const setShowChecklist = useSetAtom(showChecklistAtom);

  return (
    <Accordion
      sx={{
        "&::before": { display: "none" },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.customColor.foregroundColor,
        },
        boxShadow: "none",
        borderRadius: "0 !important",
        backgroundColor: theme.palette.background.paper,
      }}
      disabled={!expandable}
      disableGutters
      expanded={currentExpandedItem === itemIndex}
      onChange={handleChecklistItemExpand(itemIndex)}
    >
      <AccordionSummary
        sx={{
          opacity: completed ? disabledOpacity : 1,
          "&.Mui-disabled": {
            opacity: completed ? disabledOpacity : 1,
          },
        }}
        {...(expandable && {
          expandIcon: <FontAwesomeIcon icon={faChevronDown} />,
        })}
      >
        <Checkbox
          icon={
            currentExpandedItem === itemIndex ? (
              <FontAwesomeIcon
                color={theme.palette.standardColor.BoomyOrange[50]}
                stroke={theme.palette.standardColor.BoomyOrange[500]}
                strokeWidth={45}
                icon={faCircleBorder}
              />
            ) : (
              <FontAwesomeIcon
                color={theme.palette.standardColor.Gray[50]}
                icon={faCircle}
              />
            )
          }
          checkedIcon={
            <FontAwesomeIcon
              color={theme.palette.standardColor.Green[600]}
              icon={faCircleCheck}
            />
          }
          checked={completed}
          disableRipple
          sx={{ padding: "0 9px 0 0" }}
        />
        <Text variant={TextStyleVariant.P1}>{title}</Text>
      </AccordionSummary>
      <AccordionDetails sx={{ opacity: completed ? disabledOpacity : 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            paddingLeft: "32px",
          }}
        >
          <Text variant={TextStyleVariant.P2}>{description}</Text>
          {buttonContent && (
            <Button
              href={baseUrl}
              search={searchParam}
              variant={ButtonVariant.OUTLINED}
              style={{ width: "inherit" }}
              onClick={() => {
                if (isMobile) {
                  setShowChecklist(false);
                }
                emitAnalyticsTrackingEvent(
                  "checklist_action_" + title.toLowerCase().replace(/ /g, "_"),
                  {},
                );
              }}
            >
              {buttonContent.label}
            </Button>
          )}
          {guide && (
            <Button
              href={guide.link}
              variant={ButtonVariant.UNSTYLED}
              style={{ textDecoration: "underline", gap: "5px" }}
              onClick={() => {
                emitAnalyticsTrackingEvent(
                  "checklist_help_" + title.toLowerCase().replace(/ /g, "_"),
                  {},
                );
              }}
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
              <Text weight={TEXT_WEIGHT.BOLD} variant={TextStyleVariant.P2}>
                {guide.title}
              </Text>
            </Button>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ChecklistItem;
