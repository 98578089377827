import { useAtomValue } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { topNavSpacerHeightAtom } from "../../../atoms/navAtoms";
import { DevLinkProvider, EngineEarsFooter2024 } from "../../../devlink";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { fetchProjectComments } from "../../../store/actions/projectComments";
import { getReviewLinkProject } from "../../../store/actions/projectsMap";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  MixingProjectSteps,
  ProjectType,
  ProjectWorkflowSteps,
} from "../../../store/models/project";
import {
  getMixingMasteringProject,
  getProjectWorkflowStep,
} from "../../../utils/projectUtils";
import { AppScreenContent } from "../../components/AppScreenContent/AppScreenContent";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { MainProjectWorkflowPanel } from "../../components/MainProjectWorkflowPanel/MainProjectWorkflowPanel";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text, TEXT_SIZE } from "../../core-ui/components/Text/Text";
import { EditableTextField } from "../../elements/EditableTextField/EditableTextField";
import { EnterPasswordViewWrapper } from "./LoggedOutReviewScreen.styles";

export const LoggedOutReviewScreen = () => {
  const navHeight = useAtomValue(topNavSpacerHeightAtom);
  const { project_id: projectId } = useParams<{ project_id: string }>();
  const { projects, isLoading } = useAppSelector(
    (state) => state.projectsMapStore,
  );
  const isSuperUser = useAppSelector((state) =>
    Boolean(state.accountInfo.user?.is_superuser),
  );
  const project = projectId ? projects[projectId] : null;
  const mixingMasteringProject = getMixingMasteringProject(project);
  const projectWorkflowStep = getProjectWorkflowStep(
    project?.service_type ?? ProjectType.MIXING,
    mixingMasteringProject?.step ?? MixingProjectSteps.NO_STEP,
  );
  const isInReview = projectWorkflowStep === ProjectWorkflowSteps.MIX_REVIEW;
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const code: null | string = query.get("code");
  const [password, setPassword] = useState<string>("");

  useSetPageTitle("Project Review");

  useEffect(() => {
    if (!code) return;
    if (!projectId) {
      toast.error("Project id is missing");
      return;
    }
    void dispatch(
      getReviewLinkProject({
        code,
        projectId: +projectId,
      }),
    );
  }, [code, dispatch, projectId]);

  useEffect(() => {
    if (!project) return;
    void dispatch(fetchProjectComments({ project_id: projectId }));
  }, [projectId, project, dispatch]);

  const fetchPasswordProtectedProject = useCallback(() => {
    if (!code) return;
    if (!projectId) return;
    if (password !== "") {
      void dispatch(
        getReviewLinkProject({ code, password, projectId: +projectId }),
      );
    } else {
      void dispatch(getReviewLinkProject({ code, projectId: +projectId }));
    }
  }, [code, password, dispatch, projectId]);

  if (!isInReview && !isLoading && project && !isSuperUser) {
    return (
      <AppScreenContent isLoading={false}>
        <Text
          size={TEXT_SIZE.LARGE}
          bold
          style={{ textAlign: "center", marginTop: 20 }}
        >
          No Pending Review, Project Approved
        </Text>
      </AppScreenContent>
    );
  }

  if (!project && !isLoading) {
    return (
      <div className="container-fluid">
        <div className="container d-flex flex-column">
          <EnterPasswordViewWrapper
            $navHeight={navHeight}
            className="enter-password-view"
          >
            <p>This page is password protected</p>
            <EditableTextField
              className={"mb-2"}
              isSecureText={true}
              onKeyPressed={(event) => {
                if (event.key === "Enter") fetchPasswordProtectedProject();
              }}
              initialValue={password}
              label={"enter password"}
              onChange={(value) => setPassword(value)}
              editMode={true}
            />
            <Button
              onClick={fetchPasswordProtectedProject}
              variant={ButtonVariant.PRIMARY}
              fullWidth
            >
              Submit
            </Button>
          </EnterPasswordViewWrapper>
        </div>
      </div>
    );
  }

  if (isLoading && !project) {
    return <LoadingScreen />;
  }
  return (
    <div className={"container-fluid"}>
      {!project ? (
        <Text
          size={TEXT_SIZE.LARGE}
          bold
          style={{ textAlign: "center", marginTop: 20 }}
        >
          Project not found
        </Text>
      ) : (
        <>
          <MainProjectWorkflowPanel
            isOpen={true}
            albumTitleOverride={project.title}
            projectId={project.id}
            noSidePanel={true}
            onClose={() => {}}
            hideTrackButtons={true}
            code={code ?? undefined}
          />
          <DevLinkProvider>
            <EngineEarsFooter2024 />
          </DevLinkProvider>
        </>
      )}
    </div>
  );
};
