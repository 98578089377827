import { ReactElement } from "react";
import "./Card.css";

export interface CardProps {
  children?: ReactElement | ReactElement[];
  backgroundColor?: string;
  width?: number | string;
  height?: number | string;
  borderRadius?: number;
  customClassName?: string;
  hideOverflow?: boolean;
  id?: string;
}

export const Card = ({
  children,
  backgroundColor,
  width,
  height,
  borderRadius,
  customClassName = "",
  hideOverflow = true,
  id,
}: CardProps): ReactElement => {
  return (
    <div
      style={{
        backgroundColor,
        width,
        height,
        borderRadius,
        overflow: hideOverflow ? "hidden" : "scroll",
      }}
      className={"card-container ".concat(customClassName)}
      id={id}
    >
      {children}
    </div>
  );
};
