import { useState } from "react";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import "./ApplyDiscount.css";
import { toast } from "react-toastify";
import { TEXT_WEIGHT, Text } from "../../core-ui/components/Text/Text";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  PredefinedDiscountOptions,
  setMixMasterOverwritePrice,
  setMixMasterPredefinedDiscountOption,
} from "../../../store/actions/mixMasterCartsStore";
import {
  setRecordingOverwritePrice,
  setRecordingPredefinedDiscountOption,
} from "../../../store/actions/recordingCartsStore";
import { ProjectType } from "../../../store/models/project";

export const ApplyDiscount = () => {
  const dispatch = useAppDispatch();
  const { activeServiceType } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const isRecordingServiceTypeActive =
    activeServiceType == ProjectType.RECORDING;
  const {
    isLoading: isUpdatingTransactionMixMaster,
    currentOverwritePrice: currentMixMasterOverwritePrice,
    cart: { predefinedDiscountOption: mixMasterPredefinedDiscountOption },
  } = useAppSelector((state) => state.mixMasterCartsStore);
  const {
    isLoading: isUpdatingTransactionRecording,
    currentOverwritePrice: currentRecordingOverwritePrice,
    cart: { predefinedDiscountOption: recordingPredefinedDiscountOption },
  } = useAppSelector((state) => state.recordingCartsStore);

  const isUpdatingTransaction =
    isUpdatingTransactionMixMaster || isUpdatingTransactionRecording;
  const predefinedDiscountOption = isRecordingServiceTypeActive
    ? recordingPredefinedDiscountOption
    : mixMasterPredefinedDiscountOption;
  const setOverwritePrice = isRecordingServiceTypeActive
    ? setRecordingOverwritePrice
    : setMixMasterOverwritePrice;
  const setPredefinedDiscountOption = isRecordingServiceTypeActive
    ? setRecordingPredefinedDiscountOption
    : setMixMasterPredefinedDiscountOption;
  const overwritePrice = isRecordingServiceTypeActive
    ? currentRecordingOverwritePrice
    : currentMixMasterOverwritePrice;
  const [customDiscount, setCustomDiscount] = useState<string>("");

  const predefinedDiscountOptions = Object.values(PredefinedDiscountOptions);

  const onPredefinedDiscountClick = (option: PredefinedDiscountOptions) => {
    setCustomDiscount("");
    if (predefinedDiscountOption === option) {
      dispatch(setPredefinedDiscountOption(null));
    } else {
      dispatch(setPredefinedDiscountOption(option));
    }
  };

  const onApplyDiscountClick = () => {
    if (customDiscount) {
      const discountAmount = +customDiscount;

      if (Number.isNaN(discountAmount)) {
        setCustomDiscount("");
        return toast.error("Custom discount must be a number.");
      }
      dispatch(setOverwritePrice(discountAmount));
      setCustomDiscount("");
    } else {
      dispatch(setOverwritePrice(null));
    }
  };

  return (
    <div className="apply-discount-container">
      <Text className="apply-discount-header" weight={TEXT_WEIGHT.BOLD}>
        Apply discount:
      </Text>
      <Text>
        Select predefined discounts (%) or override the total price ($):
      </Text>
      <div className="apply-discount-discounts-container">
        {predefinedDiscountOptions.map((option) => {
          return (
            <Button
              key={option}
              variant={ButtonVariant.OUTLINED}
              style={
                predefinedDiscountOption === option
                  ? { backgroundColor: "var(--black)", color: "var(--white)" }
                  : {}
              }
              disabled={isUpdatingTransaction}
              onClick={() => {
                onPredefinedDiscountClick(option);
              }}
            >
              {option}
            </Button>
          );
        })}
        <input
          className="apply-discount-custom-input"
          type="number"
          placeholder="$0"
          value={customDiscount}
          onChange={(event) => {
            setCustomDiscount(event.target.value);
          }}
        />
        <Button
          variant={ButtonVariant.PRIMARY}
          disabled={
            isUpdatingTransaction || (!customDiscount && !overwritePrice)
          }
          onClick={onApplyDiscountClick}
        >
          {overwritePrice && !customDiscount ? "Reset price" : "Override price"}
        </Button>
      </div>
    </div>
  );
};
