import { useEffect } from "react";
import { LocalUTMParams } from "../../../../store/models/user";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useQuery } from "../../../../hooks/useQuery";
import {
  storeLocalUTMParams,
  updateProfile,
} from "../../../../store/actions/accountInfo";
import { identifyUnauthenticatedUser } from "../../../../utils/analyticsUtils";
import { useLocation } from "react-router-dom";

const useUTM = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const query = useQuery();
  const accountInfo = useAppSelector((state) => state.accountInfo);

  const { isAuthenticated, localUTMParams, user } = accountInfo;
  // useEffect for storing UTM parameters.
  useEffect(() => {
    const localUTMParams: LocalUTMParams = {
      utm_source: query.get("utm_source"),
      utm_medium: query.get("utm_medium"),
      utm_campaign: query.get("utm_campaign"),
      utm_content: query.get("utm_content"),
      utm_term: query.get("utm_term"),
    };
    if (!localUTMParams.utm_source) return;
    dispatch(storeLocalUTMParams(localUTMParams));
    // "Soft-register" the user so that an anonymous ID is generated by segment.
    if (!isAuthenticated) {
      identifyUnauthenticatedUser({
        utm_source: `${localUTMParams.utm_source}`,
        utm_medium: `${localUTMParams.utm_medium}`,
        utm_campaign: `${localUTMParams.utm_campaign}`,
        utm_content: `${localUTMParams.utm_content}`,
        utm_term: `${localUTMParams.utm_term}`,
      });
    }
  }, [dispatch, query, isAuthenticated]);

  // Sends local UTM params to the backend on sign in.
  useEffect(() => {
    if (!isAuthenticated) return;
    if (!localUTMParams.utm_source) return;
    // We've already posted this data.
    if (user?.utm_source === localUTMParams.utm_source) return;
    // Post updates to the backend if user is signed in.
    if (!location.pathname.includes("verify-email")) {
      dispatch(
        updateProfile({
          utm_source: localUTMParams.utm_source,
          utm_medium: localUTMParams.utm_medium,
          utm_campaign: localUTMParams.utm_campaign,
          utm_content: localUTMParams.utm_content,
          utm_term: localUTMParams.utm_term,
        }),
      )
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [
    dispatch,
    isAuthenticated,
    localUTMParams,
    location.pathname,
    user?.utm_source,
  ]);
};

export default useUTM;
