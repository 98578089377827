import {
  faArrowUpArrowDown,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { FrequentlyAskedQuestion } from "../../../../../store/models/frequentlyAskedQuestion";
import { BaseAccordion } from "../../../../core-ui/components/BaseAccordion/BaseAccordion";
import { Text } from "../../../../core-ui/components/Text/Text";
import { TextColor } from "../../../../core-ui/components/Text/TextUtils";
import {
  ColoredFAIcon,
  FaXBox,
  FrequentlyAskedItemBox,
  FrequentlyAskedQuestionEditField,
  FrequentlyAskedQuestionEditContainer,
  FrequentlyAskedResponseBox,
} from "./FrequentlyAskedItem.styles";
import { Textfield } from "../../../../elements/Textfield1/Textfield1";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Box, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UpdateFAQ } from "../../../../../hooks/profileScreenHooks/useMutateFrequentlyAskedQuestion";

interface FrequentlyAskedItemProps {
  item: FrequentlyAskedQuestion;
  onClick: (arg: UpdateFAQ) => void;
  onDeleteClick: () => void;
  editMode: boolean;
  loading: boolean;
}

export const FrequentlyAskedItem = ({
  item,
  onClick,
  onDeleteClick,
  editMode,
  loading,
}: FrequentlyAskedItemProps) => {
  const [localQuestion, setLocalQuestion] = useState(item.question);
  const [localAnswer, setLocalAnswer] = useState(item.answer);
  const theme = useTheme();

  if (editMode) {
    return (
      <FrequentlyAskedQuestionEditContainer>
        <Button style={{ height: "fit-content" }} variant={ButtonVariant.ICON}>
          <ColoredFAIcon icon={faArrowUpArrowDown} />
        </Button>
        <FrequentlyAskedQuestionEditField>
          <Textfield
            value={localQuestion}
            onChange={(e) => setLocalQuestion(e.target.value)}
            type="text"
            required
            disabled={loading}
          />
          <Textfield
            value={localAnswer}
            onChange={(e) => setLocalAnswer(e.target.value)}
            type="text"
            required
            disabled={loading}
          />
        </FrequentlyAskedQuestionEditField>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Button
            style={{ height: "fit-content" }}
            variant={ButtonVariant.ICON}
            onClick={onDeleteClick}
            loading={loading}
          >
            <FaXBox>
              <ColoredFAIcon icon={faXmark} />
            </FaXBox>
          </Button>
          <Button
            style={{ height: "fit-content" }}
            variant={ButtonVariant.ICON}
            loading={loading}
            onClick={() =>
              onClick({
                faq_id: item.id,
                question: localQuestion,
                answer: localAnswer,
              } as UpdateFAQ)
            }
          >
            <FaXBox
              sx={{
                background: theme.palette.standardColor.BoomyOrange[400],
              }}
            >
              <FontAwesomeIcon
                color={theme.palette.standardColor.White[0]}
                icon={faCheck}
              />
            </FaXBox>
          </Button>
        </Box>
      </FrequentlyAskedQuestionEditContainer>
    );
  }

  return (
    <BaseAccordion
      renderHeader={() => {
        return (
          <FrequentlyAskedItemBox>
            <Text bold>{item.question}</Text>
          </FrequentlyAskedItemBox>
        );
      }}
    >
      <FrequentlyAskedResponseBox>
        <Text color={TextColor.SECONDARY}>{item.answer}</Text>
      </FrequentlyAskedResponseBox>
    </BaseAccordion>
  );
};
