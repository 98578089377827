import { styled, Box } from "@mui/material";

export const AddSubscriptionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  alignSelf: "center",
  padding: "16px",
  borderRadius: theme.border.radius.md,
  background: theme.palette.standardColor.DeepBlue[50],
}));
