import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useHistory } from "react-router-dom";
import { clearErrors } from "../../../../store/actions/errorStore";
import { displayEngineEarsError } from "../../../../api/helpers";

const useErrorToasts = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const errors = useAppSelector((state) => state.errorStore.errors);
  useEffect(() => {
    errors.forEach((error) => {
      if (error === undefined) return;
      displayEngineEarsError(error);
    });
    dispatch(clearErrors());
  }, [errors, dispatch, history]);
};

export default useErrorToasts;
