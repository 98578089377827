import { useMutation } from "@tanstack/react-query";
import postDirectMessageRequest, {
  DirectMessageParams,
} from "../../api/stream/messaging/postDirectMessageRequest";

/*
 * Creates a direct message request from the backend on behalf of the requesting user
 */
const useDirectMessageRequestMutation = () => {
  return useMutation({
    mutationKey: ["direct_message_request"],
    mutationFn: async (args: DirectMessageParams) => {
      return postDirectMessageRequest(args);
    },
  });
};

export default useDirectMessageRequestMutation;
