import React, { useEffect, useMemo } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { useImageURLFromPath } from "../../../../../../hooks/useImageURLFromPath";
import { SCREENS } from "../../../../../../routes";
import {
  setStudio,
  setUser,
} from "../../../../../../store/actions/selectedProfile";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { Studio } from "../../../../../../store/models/studio";
import {
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../../../../store/utils/routeGetters";
import "./ProfileRow.css";
import { useAtom, useAtomValue } from "jotai";
import { activeProfileAtom } from "../../../../../../atoms/user/activeProfileAtom";
import { ProfileType } from "../../../../../../store/models/base";
import { ProfileRowComponent } from "./ProfileRowComponent";
import { activeUserAtom } from "../../../../../../atoms/user/activeUserAtom";

export interface ProfileRowProps {
  studio?: Studio;
  onClick: () => void;
}

export const ProfileRow = ({ studio, onClick }: ProfileRowProps) => {
  const activeUser = useAtomValue(activeUserAtom);
  const history = useHistory();
  const userImage = useMemo(() => {
    return activeUser?.profile_picture;
  }, [activeUser?.profile_picture]);
  const studioImage = useImageURLFromPath(true, studio?.photo?.path);
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const matchProfileScreen = matchPath(history.location.pathname, {
    path: SCREENS.PROFILE_SCREEN,
    exact: true,
    strict: false,
  });
  const matchStudioScreen = matchPath(history.location.pathname, {
    path: SCREENS.STUDIO_SCREEN,
    exact: true,
    strict: false,
  });
  const [activeProfile, setProfileId] = useAtom(activeProfileAtom);

  const onCurrentProfile =
    matchProfileScreen &&
    // @ts-ignore
    matchProfileScreen.params.username === activeUser?.username;
  const isOnProfileScreen = Boolean(onCurrentProfile || matchStudioScreen);

  const selected = useMemo(() => {
    if (studio) {
      return activeProfile?.id === studio.id;
    }
    return activeProfile?.type === ProfileType.USER;
  }, [activeProfile, studio]);

  useEffect(() => {
    if (
      studio &&
      selectedProfile.studio &&
      selectedProfile.studio.id === studio.id
    ) {
      const cachedStudio = JSON.stringify(selectedProfile.studio);
      const fetchedStudio = JSON.stringify(studio);
      if (cachedStudio !== fetchedStudio) {
        dispatch(setStudio(studio));
        setProfileId(studio.id.toString());
      }
    }
    if (
      loggedInUser &&
      selectedProfile.user &&
      selectedProfile.user.id === loggedInUser.id
    ) {
      const cachedUser = JSON.stringify(selectedProfile.user);
      const fetchedUser = JSON.stringify(loggedInUser);
      if (cachedUser !== fetchedUser) {
        dispatch(setUser(loggedInUser));
        setProfileId("0");
      }
    }
  }, [dispatch, selectedProfile, studio, loggedInUser, setProfileId]);

  const { username, imagePath } = useMemo(() => {
    if (studio) {
      return {
        username: studio?.username,
        imagePath: studioImage,
      };
    }
    return {
      username: activeUser?.username || "",
      imagePath: userImage,
    };
  }, [studio, activeUser, userImage, studioImage]);

  const url = useMemo(() => {
    if (studio) {
      return getStudioScreenRoute(studio.username);
    }
    return getProfileScreenRoute(activeUser?.username || "");
  }, [studio, activeUser]);

  const currentPath = window.location.pathname;

  const handleSettingSelectedProfile = () => {
    if (selected) {
      if (currentPath === url) {
        onClick();
        return;
      }
      history.push(url);
      onClick();
    }
    if (studio) {
      dispatch(setStudio(studio));
      setProfileId(studio.id.toString());
      if (currentPath === url) {
        onClick();
        return;
      }
      if (isOnProfileScreen)
        history.push(getStudioScreenRoute(studio.username));
      onClick();
      return;
    }
    setProfileId("0");
    dispatch(setUser(loggedInUser!));
    if (currentPath === url) {
      onClick();
      return;
    }
    if (isOnProfileScreen)
      history.push(getProfileScreenRoute(activeUser!.username));
    onClick();
  };

  return (
    <ProfileRowComponent
      imageSrc={imagePath}
      onClick={handleSettingSelectedProfile}
      username={username}
      selected={selected}
    />
  );
};
