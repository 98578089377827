import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  max-width: ${({ theme }) => `calc(${theme.maxScreenWidth} / 2)`};
  margin-bottom: 48px;
`;

export const DropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 8px;
`;

export const LocationInputContainer = styled(DropdownContainer)`
  margin-bottom: 0;
  width: 75%;
`;

export const HeaderText = styled.p`
  font-weight: ${({ theme }) => theme.textWeightNormal};
  font-size: ${({ theme }) => theme.textSizeMd};
  line-height: 20px;
  color: ${({ theme }) => theme.textPrimaryColor};
  margin-bottom: 8px;
`;
