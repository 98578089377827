export enum QUERY_KEYS {
  CREATE_TEAM = "create-team",
  CREATE_TEAM_INVITE = "create-team-invite",
  LOGIN = "login",
  LOGIN_UMG = "login-umg",
  LOGIN_GOOGLE = "login-google",
  SIGN_UP = "sign-up",
  CREATE_PROMO_CODE = "create-promo-code",
  FETCH_PAGINATED_SCHEDULED_PROJECTS = "fetch-paginated-scheduled-projects",
  FETCH_SCHEDULED_PROJECT_OVERVIEW = "fetch-scheduled-project-overview",
  GET_ITEMIZED_TRANSACTION = "get-itemized-transaction",
  GET_PAGINATED_ADMIN_ACTION_ITEMS = "get-paginated-admin-action-items",
  GET_SESSION_BOOKING_DETAILS = "get-session-booking-details",
  GET_USER_PROFILE = "get-user-profile",
  GET_REVIEWS = "get-reviews",
  GET_TROPHIES = "get-trophies",
  GET_ENGINEER_SERVICES = "get-engineer-services",
  GET_USER_GENRES = "get-user-genres",
  GET_USER_VERIFIED_MUSO_CREDITS = "get-user-verified-muso-credits",
  GET_MUSO_ASSOCIATION = "get-muso-profile",
  GET_SESSION_USER_ROLES = "get-session-user-roles",
  GET_PAGINATED_FEATURED_TRACKS = "get-paginated-featured-tracks",
  CREATE_RELEASE_FROM_SCHEDULED_PROJECT = "create-release-from-scheduled-project",
  UPLOAD_PROFILE_COVER_PHOTO = "upload-profile-cover-photo",
  GET_FILE_VERSIONS = "get-file-versions",
  FETCH_SCHEDULED_PROJECT_RELEASE = "fetch-scheduled-project-release",
  UPLOAD_ENTITY_PHOTO = "upload-entity-photo",
  FETCH_ARTIST_RELEASES = "fetch-artist-releases",
  GET_MUSO_ROSTER = "get-muso-roster",
  GET_FEATURED_TRACK_REQUEST = "get-featured-track-request",
  FETCH_ARTIST_RELEASE_TRACKS = "fetch-release-tracks",
  FETCH_TRENDING_ARTIST_RELEASE_TRACKS = "fetch-trending-tracks",
  UPLOAD_PROFILE_IMAGE = "upload-profile-image",
  GET_SUBSCRIPTION_STATUS = "get-subscription-status",
  GET_MUSO_AUTH = "get-muso-auth",
  GET_AFFILIATED_STUDIOS = "get-affiliated-studios",
  FETCH_FEATURED_ARTIST_RELEASE = "fetch-featured-artist-release",
  FETCH_ARTIST_RELEASE = "fetch-release",
  FETCH_ARTIST_RELEASE_DETAILS = "fetch-release-details",
  DELETE_ENGINEER_SERVICE = "delete-engineer-service",
  DELETE_RECORDING_SERVICE = "delete-recording-service",
  GET_ONBOARDING_PROGRESS = "get-onboarding-progress",
  GET_OPTED_IN_UNVERIFIED_ENGINEERS = "get-opted-in-unverified-engineers",
  LOAD_USER = "load-user",
  LOAD_STUDIOS = "load-studios",
  GET_STUDIO = "get-studio",
  GET_STUDIO_ROOMS = "get-studio-rooms",
  GET_FREQUENTLY_ASKED_QUESTIONS = "get-frequently-asked-questions",
  GET_USER_COLLABORATOR = "get-user-collaborators",
  UPDATE_RELEASE_TRACK_ORDER = "update-release-track-order",
  UPDATE_RELEASE = "update-release",
  DOWNLOAD_TRACK_BY_FILE_VERSION_ID = "download-track-by-file-version-id",
  UPDATE_RELEASE_TRACK = "update-release-track",
  UPDATE_USER_PROFILE = "update-user-profile",
  CREATE_RECORDING_SERVICE = "create-recording-service",
  CREATE_WORKING_HOURS = "create-working-hours",
  GET_WORKING_HOURS_STUDIO = "get-working-hours-studio",
  GET_WORKING_HOURS_USER = "get-working-hours-user",
  GET_SCHEDULING_SURVEY_ANSWERS = "get-scheduling-survey-answers",
  UPDATE_SCHEDULING_SURVEY_ANSWERS = "update-scheduling-survey-answers",
  VERIFY_PHONE = "verify-phone",
  FETCH_PRODUCTS_BY_RELEASE_ID = "fetch-products-by-release-id",
}
