import { useEffect, useState } from "react";

export const usePasswordCheck = (password: string, requiredLength: number) => {
  const [validLength, setValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);

  useEffect(() => {
    setValidLength(password.length >= requiredLength ? true : false);
    setHasNumber(/\d/.test(password));
    setUpperCase(password.toLowerCase() !== password);
    setLowerCase(password.toUpperCase() !== password);
    setSpecialChar(/[!@#$%^&*]/.test(password));
  }, [password]);

  return [validLength, hasNumber, upperCase, lowerCase, specialChar];
};
