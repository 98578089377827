import { faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import "./ShareButton.css";

export interface ShareButtonProps {
  url: string;
  disabled?: boolean;
}

export const ShareButton: React.FC<ShareButtonProps> = ({
  url,
  disabled = false,
}: ShareButtonProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();
  return (
    <button
      onClick={() => {
        if (disabled) return;
        if (navigator.share && !isDesktop) {
          navigator.share({ url: url });
        } else {
          toast.success("Copied to clipboard!");
          window.navigator.clipboard.writeText(url);
        }
      }}
      className="share-button-container b3"
    >
      {isDesktop ? <p>SHARE</p> : ""}
      <FontAwesomeIcon className="fa-icon" icon={faShare} size={"1x"} />
    </button>
  );
};
