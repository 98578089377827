import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { useUploadedFileSinceLastTransition } from "../../../../../hooks/useProjectFilesUploaded";
import {
  useAppSelector,
  useProjectFromProjectMap,
} from "../../../../../store/hooks";
import {
  PaginatedProjectHistory,
  ProjectHistory as ProjectHistoryType,
  ProjectType,
  ProjectWorkflowSteps,
} from "../../../../../store/models/project";
import { SessionWorkflowSteps } from "../../../../../store/models/recordingSession";
import { makeBackendGetCallWithJsonResponse } from "../../../../../store/utils/fetch";
import { getProjectHistoryRoute } from "../../../../../store/utils/routeGetters";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import { TEXT_COLOR, Text } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import {
  ProjectHistoryContainer,
  ProjectHistorySeparator,
} from "./ProjectHistory.styles";
import { ProjectHistoryItem } from "./ProjectHistoryItem";
import {
  projectHistoryMasterMessages,
  projectHistoryMixMessages,
} from "./ProjectHistoryUtils";

export interface ProjectHistoryProps {
  projectId: number | null | undefined;
  projectWorkflowStep: ProjectWorkflowSteps | SessionWorkflowSteps;
}

export const ProjectHistory = ({
  projectId,
  projectWorkflowStep,
}: ProjectHistoryProps) => {
  const project = useProjectFromProjectMap(projectId ?? -1);
  const projectHistoryMessages =
    project?.service_type === ProjectType.MASTERING
      ? projectHistoryMasterMessages
      : projectHistoryMixMessages;
  const recentlyUploadedFiles = useUploadedFileSinceLastTransition(project);
  const [isLoading, setIsLoading] = useState(false);
  const [paginatedHistory, setPaginatedHistory] =
    useState<PaginatedProjectHistory | null>(null);
  const [projectHistory, setProjectHistory] = useState<ProjectHistoryType[]>(
    [],
  );
  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);
  const query = new URLSearchParams(window.location.search);
  const code = query.get("code") ?? undefined;

  const currentPage = paginatedHistory?.page ?? 0;
  const areMorePagesAvailable =
    (paginatedHistory?.num_pages ?? 0) > currentPage;

  const fetchHistory = (page: number) => {
    if (!projectId || isLoading || !isAuthenticated) return;

    setIsLoading(true);
    const projectHistoryRoute = getProjectHistoryRoute(projectId, page, code);
    makeBackendGetCallWithJsonResponse<PaginatedProjectHistory>(
      projectHistoryRoute,
      "",
    )
      .then((response) => {
        if (response.success) {
          setPaginatedHistory(response.resultJson);
          // in the case of page 1, we want to replace the existing history
          setProjectHistory((prev) =>
            page === 1
              ? response.resultJson.data
              : [...prev, ...response.resultJson.data],
          );
        } else {
          toast.error("Failed to get project history");
        }
      })
      .catch(() => toast.error("Failed to get project history"))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchHistory(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectId,
    projectWorkflowStep,
    isAuthenticated,
    recentlyUploadedFiles.length,
  ]);

  if (!isAuthenticated) return null;

  return (
    <ProjectHistoryContainer>
      <Text variant={TextStyleVariant.S3}>Track History</Text>
      <ProjectHistorySeparator />
      {isLoading && !projectHistory.length && (
        <Skeleton width="100%" height={60} />
      )}
      {Boolean(projectHistory.length) &&
        projectHistory.map((entry, i) => (
          <ProjectHistoryItem
            action={projectHistoryMessages[entry.action] ?? entry.action}
            key={`project-history-item-${entry.id}`}
            attachedFile={entry.file_version}
            datetime={new Date(`${entry.created}Z`)}
            hideLine={i + 1 >= projectHistory.length}
            project={project}
            user={entry.user}
          />
        ))}
      {!isLoading && projectHistory?.length === 0 && (
        <Text color={TEXT_COLOR.TERTIARY}>No history available</Text>
      )}
      {areMorePagesAvailable && (
        <Button
          fullWidth
          disabled={isLoading}
          loading={isLoading}
          onClick={() => fetchHistory(currentPage + 1)}
          variant={ButtonVariant.GHOST}
          style={{ marginTop: "16px" }}
        >
          Load More
        </Button>
      )}
    </ProjectHistoryContainer>
  );
};
