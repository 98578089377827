import { useState } from "react";
import { useParams } from "react-router-dom";
import { useFetchArtistReleasesQuery } from "../../../api/releases/hooks/useFetchArtistReleasesQuery";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { ReleaseType, ReleaseTypeOptions } from "../../../store/models/release";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { ReleaseTabViewSectionProps } from "../ArtistReleaseTabView/ArtistReleaseTabView";
import { ReleaseList, RELEASES_PAGE_SIZE } from "../ReleaseList/ReleaseList";
import { StyledPagination } from "../StyledPagination/StyledPagination";
import {
  ArtistReleaseButtonContainer,
  ArtistReleasesContainer,
  ArtistReleasesHeader,
  ReleaseFilterButton,
} from "./ArtistReleases.styles";

interface ArtistReleasesProps extends ReleaseTabViewSectionProps {
  onReleaseClick: (releaseId?: number) => void;
}

export const ArtistReleases = ({
  shouldShowDesktop,
  onReleaseClick,
}: ArtistReleasesProps) => {
  const [filter, setFilter] = useState(ReleaseType.NO_RELEASE_TYPE);
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isFetching } = useFetchArtistReleasesQuery(
    userData?.id || 0,
    filter,
    currentPage,
  );

  const releasesData = data?.data || [];

  return (
    <ArtistReleasesContainer
      style={{
        marginLeft: shouldShowDesktop ? 0 : "16px",
        marginRight: shouldShowDesktop ? 0 : "16px",
      }}
    >
      <ArtistReleasesHeader>
        <Text bold variant={TextStyleVariant.S2}>
          Catalog
        </Text>
        <ArtistReleaseButtonContainer>
          {ReleaseTypeOptions.map((option) => (
            <ReleaseFilterButton
              key={option.value}
              $selected={filter === option.value}
              onClick={() => {
                setFilter(option.value);
                setCurrentPage(1);
              }}
            >
              <Text
                color={
                  filter === option.value
                    ? TextColor.PRIMARY_NO_DARK_THEME
                    : TextColor.SECONDARY
                }
                variant={TextStyleVariant.P2}
              >
                {option.label}
              </Text>
            </ReleaseFilterButton>
          ))}
        </ArtistReleaseButtonContainer>
      </ArtistReleasesHeader>
      <ReleaseList
        onReleaseClick={onReleaseClick}
        isPending={isFetching}
        releases={releasesData}
      />

      <StyledPagination
        pageSize={RELEASES_PAGE_SIZE}
        current={currentPage}
        total={data?.count || 1}
        disabled={isFetching}
        onChange={(selectedPage) => setCurrentPage(selectedPage)}
      />
    </ArtistReleasesContainer>
  );
};
