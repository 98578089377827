import React, { FC, ReactElement } from "react";
import "./money-sign.css";

export interface IMoneySign {
  numberOfSign: number;
  currency?: string;
}

export const MoneySign: FC<IMoneySign> = ({
  numberOfSign = 0,
  currency = "dollar",
}): ReactElement => {
  const getCurrencySign = (currency: string) => {
    switch (currency) {
      case "dollar":
        return <span>&#36;</span>;
      case "euro":
        return <span>&#128;</span>;
      case "pound":
        return <span>&#163;</span>;
      case "peso":
        return <span>&#8369;</span>;
      case "yen":
        return <span>&#165;</span>;
      case "ruble":
        return <span>&#8381;</span>;
      case "naira":
        return <span>&#x20A6;</span>;
      case "bitcoin":
        return <span>&#8383;</span>;
    }
  };

  const signClass = (index: number) =>
    index <= numberOfSign ? "stereoblack" : "lightgrey";

  return (
    <div className="money">
      {[...Array(4)].map((val, index) => {
        index += 1;
        return (
          <span key={index} className={signClass(index)}>
            {getCurrencySign(currency)}
          </span>
        );
      })}
    </div>
  );
};
