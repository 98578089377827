import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { MAP_AUTOCOMPLETE } from "../../store/utils/routes";
import { AutocompleteSuggestion } from "../../store/models/autocomplete";

export const fetchMapAutocomplete = async (query: string) => {
  const params = `?search=${query}`;
  const response = await makeBackendGetCallWithJsonResponse<
    AutocompleteSuggestion[]
  >(MAP_AUTOCOMPLETE, params);

  if (response.success) {
    return response.resultJson;
  }
  throw new Error(String(JSON.stringify(response.resultJson)));
};

export default fetchMapAutocomplete;
