import { styled, Box } from "@mui/material";

export const SubscriptionSuccessScreenContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "84px",
  padding: "24px",
}));

export const SubscriptionSuccessScreenHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  gap: "16px",
}));

export const SubscriptionSuccessScreenBody = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "24px",
  textAlign: "center",
  maxWidth: "calc(100% - 48px)",
}));

export const SubscriptionSuccessScreenFooter = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  textAlign: "center",
}));
