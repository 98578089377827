export default interface EntityPhoto {
  created: string;
  deleted: null | string;
  id: number;
  path: string;
  order_index: number;
  room_name: string | null;
}

export const MockEntityPhoto: EntityPhoto = {
  created: "2020-01-01T00:00:00.000Z",
  deleted: null,
  id: 1,
  order_index: 1,
  path: "https://picsum.photos/1920/1080",
  room_name: "MockPhoto",
};
