import { useMemo } from "react";
import { PendingSessionData } from "../../store/actions/shoppingCart";
import { useAppSelector } from "../../store/hooks";
import { StudioRoom } from "../../store/models/studio";

const getPendingSessionDataBookable = (
  isBookableWithoutEngineer: boolean,
  pendingSession?: PendingSessionData,
) => {
  if (!pendingSession) {
    return false;
  }
  if (
    !isBookableWithoutEngineer &&
    !pendingSession.trackingEngineer &&
    !pendingSession.autoSelectedTrackingEngineer
  ) {
    return false;
  }
  return Boolean(
    pendingSession.selectedDateOption && pendingSession.selectedISODate,
  );
};

export const useIsPendingSessionDataBookable = (
  pendingSession: PendingSessionData | undefined,
  studioRoom: StudioRoom | undefined,
): boolean => {
  const isBookableWithoutEngineer = Boolean(
    studioRoom?.studio?.allow_bookings_without_engineer,
  );

  return useMemo(() => {
    return getPendingSessionDataBookable(
      isBookableWithoutEngineer,
      pendingSession,
    );
  }, [isBookableWithoutEngineer, pendingSession]);
};

export const useIsEntirePendingSessionDataBookable = (
  pendingSessions: PendingSessionData[] | undefined,
  studioRoom: StudioRoom | undefined,
) => {
  const isBookableWithoutEngineer = Boolean(
    studioRoom?.studio?.allow_bookings_without_engineer,
  );
  return useMemo(() => {
    if (!pendingSessions) {
      return false;
    }
    return pendingSessions.every((pendingSession) =>
      getPendingSessionDataBookable(isBookableWithoutEngineer, pendingSession),
    );
  }, [pendingSessions, isBookableWithoutEngineer]);
};

export enum SessionDetail {
  NONE = "none",
  DATE = "date",
  DURATION = "duration",
  ENGINEER = "engineer",
  TIME = "time",
}

export const emptySessionBookingText: Record<SessionDetail, string> = {
  [SessionDetail.NONE]: "Please add a session",
  [SessionDetail.DATE]: "Please select a date",
  [SessionDetail.DURATION]: "Please select a duration",
  [SessionDetail.ENGINEER]: "Please select an engineer",
  [SessionDetail.TIME]: "Please select a time",
};

/**
 * Iterates through pending session data to determine which session detail is missing.
 */
export const useMissingBookingSessionDetails = () => {
  const pendingSessionData = useAppSelector(
    (state) => state.shoppingCart.pendingSessionData ?? [],
  );

  return useMemo(() => {
    let index = 0;
    while (index < pendingSessionData.length) {
      const session = pendingSessionData[index];
      const sessionDate =
        session.selectedISODate || session.selectedDateOption?.value;
      if (!sessionDate) {
        return SessionDetail.DATE;
      }
      if (!session.duration) {
        return SessionDetail.DURATION;
      }
      if (
        !session.trackingEngineer &&
        !session.hasNoTrackingEngineer &&
        !session.autoSelectedTrackingEngineer
      ) {
        return SessionDetail.ENGINEER;
      }
      if (!session.selectedDateOption?.value) {
        return SessionDetail.TIME;
      }
      index++;
    }
    return SessionDetail.NONE;
  }, [pendingSessionData]);
};
