import { Box, styled } from "@mui/material";

export const OverviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "54px",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(5),
  },
}));

export const OverviewMobileContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  height: "100%",
});
