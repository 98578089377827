import { faBellRing } from "@fortawesome/pro-solid-svg-icons";
import { add, differenceInHours, differenceInMinutes } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  getNudgeEligibility,
  sendNudge,
} from "../../../store/actions/notifications";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectUserType } from "../../../store/models/project";
import { convertUTCDateToLocalDate } from "../../../store/utils/dateTimeUtils";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { ButtonVariant } from "../../core-ui/components/Button/Button";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";
import { NudgeBellButton, NudgeBellIcon } from "./NudgeButton.styles";

export interface NudgeButtonProps {
  iconOnly: boolean;
  projectId: number | null | undefined;
  userType?: ProjectUserType | null | undefined;
  isInProgressProject?: boolean | null;
}

export const NudgeButton = ({
  iconOnly,
  projectId,
  userType,
  isInProgressProject = false,
}: NudgeButtonProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const { nudgeEligibility, loading } = useAppSelector(
    (state) => state.notificationsStore,
  );
  const [nudgePopConfirmDescription, setNudgePopConfirmDescription] =
    useState("");
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  const getButtonContent = () => {
    if (iconOnly) {
      return <NudgeBellIcon icon={faBellRing} />;
    } else if (userType === ProjectUserType.ARTIST) {
      return "Nudge engineer";
    } else if (userType === ProjectUserType.ENGINEER) {
      return "Nudge artist";
    } else {
      return "Nudge";
    }
  };

  const handleSendNudge = () => {
    if (!projectId) return;
    emitAnalyticsTrackingEvent("send_nudge", {}, user?.id);
    void dispatch(
      sendNudge({
        project_id: projectId,
      }),
    ).then((response) => {
      if (response) {
        toast.success("Nudge sent successfully");
      } else {
        toast.error("Failed to send nudge");
      }
      void dispatch(getNudgeEligibility({ project_id: projectId }));
    });
  };

  // Fetch nudge eligibility
  useEffect(() => {
    if (!projectId || isInProgressProject) return;
    void dispatch(getNudgeEligibility({ project_id: projectId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isInProgressProject]);

  // Handle nudge eligibility changes and calculate countdown if not eligible.
  // If eligible, set the description for the pop confirm listing users to nudge.
  const nudgeDescription = useMemo(() => {
    if (nudgeEligibility && !nudgeEligibility?.allowed_to_nudge) {
      const lastNudgeDate = convertUTCDateToLocalDate(
        new Date(nudgeEligibility.last_transition_or_nudge),
      );
      const nextNudgeDate = add(lastNudgeDate, { hours: 24 });
      const now = new Date();
      const diffInHours = differenceInHours(nextNudgeDate, now);
      const diffInMinutes = differenceInMinutes(nextNudgeDate, now);

      if (diffInHours === 1) {
        return `You can nudge in 1 hour`;
      } else if (diffInHours > 1) {
        return `You can nudge in ${diffInHours} hours`;
      } else {
        return `You can nudge in ${diffInMinutes} minutes`;
      }
    } else if (nudgeEligibility?.users_to_nudge) {
      const usersToNudge = nudgeEligibility.users_to_nudge.map((user) =>
        getDisplayableNameForUser(user),
      );
      setNudgePopConfirmDescription(
        `This will notify ${usersToNudge.join(", ")}`,
      );
    }
    return undefined;
  }, [nudgeEligibility, setNudgePopConfirmDescription]);

  const handleClick = useCallback(() => {
    if (nudgeDescription) {
      toast.warning(nudgeDescription);
    }
  }, [nudgeDescription]);

  return loading || !nudgeEligibility?.allowed_to_nudge ? (
    <NudgeBellButton
      $iconOnly={iconOnly}
      $disabled={!nudgeDescription}
      {...(!iconOnly && { fullWidth: true })}
      variant={ButtonVariant.OUTLINED}
      onClick={handleClick}
    >
      {getButtonContent()}
    </NudgeBellButton>
  ) : (
    <PopConfirm
      side="top"
      title="Are you sure?"
      description={nudgePopConfirmDescription}
      onConfirm={handleSendNudge}
      wrapperElement={containerElement}
    >
      <NudgeBellButton
        $iconOnly={iconOnly}
        $disabled={false}
        {...(!iconOnly && { fullWidth: true })}
        variant={ButtonVariant.OUTLINED}
      >
        {getButtonContent()}
      </NudgeBellButton>
    </PopConfirm>
  );
};
