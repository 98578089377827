import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Error {
  code: number;
  detail?: string;
  non_field_errors?: string[];
}

export type EngineEarsError = Error;

// Define a type for the slice state
interface ErrorState {
  errors: Error[];
}

// Define the initial state using that type
const initialState: ErrorState = {
  errors: [],
};

export interface ErrorsAction {
  errors: Error;
}

export const errorStoreSlice = createSlice({
  name: "errorStore",
  initialState,
  reducers: {
    receiveErrors: (state, action: PayloadAction<ErrorsAction>) => {
      state.errors = [...state.errors, action.payload.errors];
    },
    clearErrors: () => {
      return initialState;
    },
  },
});

export const { clearErrors, receiveErrors } = errorStoreSlice.actions;

export default errorStoreSlice.reducer;
