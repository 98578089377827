import { useCallback } from "react";
import {
  artistMasteringTransitions,
  artistMixingTransitions,
} from "../../../../store/actions/projects";
import { useAppDispatch } from "../../../../store/hooks";
import {
  MasteringTransitions,
  MixingTransitions,
  ProjectType,
} from "../../../../store/models/project";

export const useRequestReupload = (
  projectType: ProjectType,
  projectId: number,
) => {
  const dispatch = useAppDispatch();

  return useCallback(
    async (notes: string) => {
      if (projectType === ProjectType.MASTERING) {
        return dispatch(
          artistMasteringTransitions({
            project_id: `${projectId}`,
            transition: MasteringTransitions.REQUEST_ASSET_REUPLOAD,
            final_asset_file_notes: notes,
          }),
        );
      }
      return dispatch(
        artistMixingTransitions({
          project_id: `${projectId}`,
          transition: MixingTransitions.REQUEST_ASSET_REUPLOAD,
          final_asset_file_notes: notes,
        }),
      );
    },
    [projectId, projectType, dispatch],
  );
};
