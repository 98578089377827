import styled from "styled-components";
import { Button } from "../../core-ui/components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NudgeBellButton = styled(Button)<{
  $iconOnly: boolean;
  $disabled?: boolean;
}>`
  border: ${({ $iconOnly, theme }) =>
    $iconOnly ? `solid 1px ${theme.separatorColor}` : ""};
  border-radius: ${({ $iconOnly, theme }) =>
    $iconOnly ? theme.border.radius.xs : ""};
  min-height: unset;
  min-width: 32px;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${({ theme }) => theme.midgroundColor};
  }
`;

export const NudgeBellIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.textPrimaryColor};
`;
