import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  renameProjectOrScheduledProject,
  renameProjectOrScheduledProjectParams,
} from "./projects";
import { RootState } from "../index";
import { ScheduledProject } from "../models/scheduledproject";

interface UpdatedField {
  [id: number]: string;
}

interface ScheduledProjectUpdateStoreState {
  isEditing: boolean;
  isUpdating: boolean;
  projects: UpdatedField;
  scheduledProjects: UpdatedField;
}

const initialState: ScheduledProjectUpdateStoreState = {
  isEditing: false,
  isUpdating: false,
  projects: {},
  scheduledProjects: {},
};

export const updateScheduledProjectNames = createAsyncThunk(
  "",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const scheduledProject: ScheduledProject | null =
      state.scheduledProjectsStore.scheduledProject;
    if (!scheduledProject) {
      thunkAPI.rejectWithValue(new Error("scheduled project not found"));
    }
    thunkAPI.dispatch(toggleUpdating());
    const { scheduledProjects, projects } = state.scheduledProjectUpdateStore;
    const scheduled_project_id = scheduledProject?.id;
    if (scheduled_project_id && scheduledProjects[scheduled_project_id]) {
      const updatedScheduledProjectName =
        scheduledProjects[scheduled_project_id];
      if (updatedScheduledProjectName !== scheduledProject?.title) {
        thunkAPI.dispatch(
          renameProjectOrScheduledProject({
            scheduled_project_id,
            title: updatedScheduledProjectName,
          }),
        );
      }
    }
    const scheduledProjectProjects = scheduledProject?.projects;
    if (scheduledProjectProjects) {
      const projectsToUpdate = scheduledProjectProjects.filter(
        (currProject) => {
          return projects[currProject.id] !== undefined;
        },
      );
      projectsToUpdate.forEach((updatedProject) => {
        thunkAPI.dispatch(
          renameProjectOrScheduledProject({
            project_id: updatedProject.id,
            title: projects[updatedProject.id],
          }),
        );
      });
    }
  },
);

export const scheduledProjectUpdateStore = createSlice({
  name: "scheduledProjectUpdateStore",
  initialState,
  reducers: {
    toggleEditing: (state) => {
      state.isEditing = !state.isEditing;
    },
    toggleUpdating: (state) => {
      state.isUpdating = !state.isUpdating;
    },
    updateState: (
      state,
      action: PayloadAction<renameProjectOrScheduledProjectParams>,
    ) => {
      const payload = action.payload;
      if (payload.scheduled_project_id) {
        state.scheduledProjects[payload.scheduled_project_id] = payload.title;
      } else if (payload.project_id) {
        state.projects[payload.project_id] = payload.title;
      }
    },
    removeFromState: (
      state,
      action: PayloadAction<renameProjectOrScheduledProjectParams>,
    ) => {
      const payload = action.payload;
      if (payload.project_id) {
        const projects = { ...state.projects };
        delete projects[payload.project_id];
        state.projects = projects;
      } else if (payload.scheduled_project_id) {
        const scheduled_projects = { ...state.scheduledProjects };
        delete scheduled_projects[payload.scheduled_project_id];
        state.scheduledProjects = { ...scheduled_projects };
      }
    },
    resetStore: (state) => {
      state.isEditing = false;
      state.isUpdating = false;
      state.projects = {};
      state.scheduledProjects = {};
    },
  },
});

export const {
  resetStore,
  removeFromState,
  toggleUpdating,
  toggleEditing,
  updateState,
} = scheduledProjectUpdateStore.actions;
export default scheduledProjectUpdateStore.reducer;
