import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import type { AppDispatch } from "../store";
import {
  getArtistSignedUrl,
  getEngineerSignedUrl,
  PresignedUrlResponse,
} from "../store/actions/audioService";
import {
  markFileAsUploaded,
  MultipartUploadParams,
  MultiuploadPart,
} from "../store/actions/fileVersions";
import { FileVersion } from "../store/models/fileVersion";
import { uploadFilePart, uploadFilePromise } from "../store/utils/fetch";

export const uploadMultipart = async (
  file: File,
  presignedUrls: string[],
  chunkSize: number,
  setProgressState: (progress: number) => void,
): Promise<MultiuploadPart[]> => {
  const promises: Promise<string>[] = [];
  const progressCache = presignedUrls.reduce(
    (acc, _, i) => {
      acc[i + 1] = 0;
      return acc;
    },
    {} as Record<number, number>,
  );

  presignedUrls.forEach(async (link, i) => {
    const start = i * chunkSize;
    const end = (i + 1) * chunkSize;
    const blob =
      i < presignedUrls.length ? file.slice(start, end) : file.slice(start);

    promises.push(
      uploadFilePart(blob, link, (progress) => {
        progressCache[i + 1] = progress;
        const total = Object.values(progressCache).reduce((acc, val) => {
          return (acc += val);
        }, 0);
        const percent = (total / file.size) * 100;
        setProgressState(percent);
      }),
    );
  });

  const uploadPartsResponse = await Promise.all(promises);
  return uploadPartsResponse.map((etag, index) => ({
    ETag: etag,
    PartNumber: index + 1,
  }));
};

export const handleSingleOrMultipartUpload = async (
  file: File,
  key: string,
  signedUrl: string,
  multipartUploadId: string,
  multipartUploadUrls: string[],
  multipartUploadChunkSize: number,
  bucket: string,
  setProgressState: (progress: number) => void,
) => {
  if (signedUrl !== "") {
    await uploadFilePromise(file, signedUrl, (progress) => {
      setProgressState(progress);
    });
    return null;
  } else {
    const uploadParts = await uploadMultipart(
      file,
      multipartUploadUrls,
      multipartUploadChunkSize,
      setProgressState,
    );

    return {
      bucket,
      key,
      upload_id: multipartUploadId,
      parts: uploadParts,
    };
  }
};

export const handleFileUpload = async (
  file: File,
  projectId: number,
  code: string | null,
  setProgressState: (progress: number) => void,
  onComplete: (file?: FileVersion) => void,
  dispatch: AppDispatch,
  isProjectEngineer: boolean,
  engineerIsUploadingOnBehalfOfArtist: boolean,
  alt: number,
  recordingSessionBookingId?: number | null,
) => {
  const {
    signedUrl,
    file: fileVersion,
    multipartUploadId,
    multipartUploadUrls,
    multipartUploadChunkSize,
    bucket,
  }: PresignedUrlResponse = isProjectEngineer
    ? await dispatch(
        getEngineerSignedUrl({
          file,
          project_id: projectId.toString(),
          reference: engineerIsUploadingOnBehalfOfArtist,
          alt,
        }),
      ).then(unwrapResult)
    : await dispatch(
        getArtistSignedUrl({ file, project_id: projectId.toString(), code }),
      ).then(unwrapResult);

  if (signedUrl !== "") {
    await uploadFilePromise(file, signedUrl, (progress) => {
      setProgressState(progress);
    });
    await dispatch(
      markFileAsUploaded({
        fileVersionId: fileVersion.id,
        projectId,
        code,
        recordingSessionBookingId,
      }),
    );
  } else {
    if (!fileVersion.object_key_path) {
      toast.error("An error occurred with your file upload, please try again.");
      setProgressState(0);
      return;
    }
    const uploadParts = await uploadMultipart(
      file,
      multipartUploadUrls,
      multipartUploadChunkSize,
      setProgressState,
    );

    const multipartUploadParams: MultipartUploadParams = {
      bucket,
      key: fileVersion.object_key_path,
      upload_id: multipartUploadId,
      parts: uploadParts,
    };

    await dispatch(
      markFileAsUploaded({
        fileVersionId: fileVersion.id,
        projectId,
        code,
        multipartUploadParams,
        recordingSessionBookingId,
      }),
    );
  }
  setProgressState(0);
  onComplete();
};
