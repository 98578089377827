import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../../atoms/user/darkModeAtom";
import { useEffect } from "react";
import { useAppSelector } from "../../../../store/hooks";

const useBodyDarkMode = () => {
  const darkMode = useAtomValue(darkModeAtom);
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  useEffect(() => {
    if (!isAuthenticated) {
      document.body.classList.remove("body-dark-mode");
      return;
    }
    if (darkMode) {
      document.body.classList.add("body-dark-mode");
    }
    if (!darkMode) {
      document.body.classList.remove("body-dark-mode");
    }
  }, [darkMode, isAuthenticated]);
};

export default useBodyDarkMode;
