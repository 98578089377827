import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppDispatch } from "../../../store/hooks";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { ErrorsAction } from "../../../store/actions/errorStore";
import {
  PreviousPlaylistState,
  updatePlayListOrder,
} from "../../../store/actions/abPlayerStore";
import { updateReleaseTrackOrder } from "../releaseAPI";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../helpers";

interface UpdateTrackOrderArgs {
  releaseId: number;
  releaseTrackIds: number[];
  previousState: PreviousPlaylistState;
}

export const useTrackOrderUpdateMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: async ({ releaseTrackIds }: UpdateTrackOrderArgs) => {
      const response = await updateReleaseTrackOrder(releaseTrackIds);
      if (!response.success) {
        throw response.resultJson;
      }
      return response.resultJson;
    },
    mutationKey: [QUERY_KEYS.UPDATE_RELEASE_TRACK_ORDER],
    onError: (
      error: ErrorsAction,
      { releaseId, previousState }: UpdateTrackOrderArgs,
    ) => {
      if (assertEngineEarsErrorType(error)) {
        displayEngineEarsError(error);
      }
      // footer player lives in redux so we still need to update the store
      dispatch(
        updatePlayListOrder({
          playlist: previousState.playlist,
          currentTrackIndex: previousState.currentTrackIndex,
        }),
      );
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_ARTIST_RELEASE_TRACKS, releaseId],
      });
    },
  });
};
