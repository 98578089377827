import classNames from "classnames";
import "./CollapsableComponent.css";
import { ReactElement } from "react";

export interface CollapsableComponentProps {
  hintText?: string;
  children?: ReactElement | ReactElement[];
  primary?: boolean;
  bold?: boolean;
}

export const CollapsableComponent = ({
  hintText,
  children,
  primary = true,
  bold = false,
}: CollapsableComponentProps) => {
  const className = classNames("b1", {
    "collapsable-component-hint-primary": primary,
    "collapsable-component-hint-secondary": !primary,
    "collabsable-component-hint-bold": bold,
  });
  return (
    <div>
      <details>
        <summary className={className}>{hintText}</summary>
        <>{children}</>
      </details>
    </div>
  );
};
