import { PopoverTrigger } from "@radix-ui/react-popover";
import { useCallback, useState } from "react";
import useInvalidateOnboardingProgress from "../../../../hooks/onboardingHooks/useInvalidateOnboardingProgress";
import useModal from "../../../../hooks/useModal";
import { useLatestUploadedFile } from "../../../../hooks/useProjectFilesUploaded";
import { fetchFileComments } from "../../../../store/actions/fileVersionComments";
import {
  engMasteringTransition,
  engMixTransition,
} from "../../../../store/actions/projects";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  MasteringTransitions,
  MixingTransitions,
  ProjectById,
  ProjectType,
} from "../../../../store/models/project";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { getMixingMasteringProject } from "../../../../utils/projectUtils";
import { BasePopover } from "../../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../core-ui/components/BasePopover/PopoverContainerContext";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { CheckBox } from "../../../core-ui/components/CheckBox/CheckBox";
import { Text, TEXT_SIZE } from "../../../core-ui/components/Text/Text";
import { ToolTipTextArea } from "../../ToolTipTextArea/ToolTipTextArea";
import {
  SendForReviewPopoverCheckBoxRow,
  SendForReviewPopoverToolTipRow,
} from "../InProgressTransitionView.styles";

interface SendForReviewButtonProps {
  areFilesUploaded: boolean;
  project: ProjectById;
  isPaymentPending?: boolean;
}

/**
 * Updated version of the `UploadMixModal` component.
 */
export const SendForReviewButton = ({
  areFilesUploaded,
  project,
  isPaymentPending = false,
}: SendForReviewButtonProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const [isLoading, setIsLoading] = useState(false);
  const {
    enable_artist_review_mp3_file_download: mp3Preference,
    enable_artist_review_wav_file_download: wavPreference,
  } = getMixingMasteringProject(project) ?? {};
  const mostRecentFile = useLatestUploadedFile(project.id);

  const [enableArtistMP3Download, setEnableArtistMP3Download] = useState(
    Boolean(mp3Preference),
  );
  const [enableArtistWAVDownload, setEnableArtistWAVDownload] = useState(
    Boolean(wavPreference),
  );

  enum SendForReviewPopoverVariant {
    CONFIRMATION,
    STANDARD,
  }

  const [popoverVariant, setPopoverVariant] =
    useState<SendForReviewPopoverVariant>(SendForReviewPopoverVariant.STANDARD);
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  const { invalidateOnboardingProgress } = useInvalidateOnboardingProgress();

  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();

  const showConfirmationPopover =
    isPaymentPending && (enableArtistMP3Download || enableArtistWAVDownload);
  const projectTypeText =
    project.service_type === ProjectType.MASTERING ? "master" : "mix";

  const uploadEngineerMix = useCallback(async () => {
    setPopoverVariant(SendForReviewPopoverVariant.STANDARD);
    closePopover();
    const transitionParams = {
      project_id: project.id.toString(),
      enable_artist_review_mp3_file_download: enableArtistMP3Download,
      enable_artist_review_wav_file_download: enableArtistWAVDownload,
    };

    setIsLoading(true);
    try {
      emitAnalyticsTrackingEvent(
        "click_engineer_upload_main_mix_button",
        {
          enableArtistMP3Download,
          enableArtistWAVDownload,
          project_id: project.id,
        },
        user?.id,
      );

      if (project.service_type === ProjectType.MASTERING) {
        await dispatch(
          engMasteringTransition({
            ...transitionParams,
            transition: MasteringTransitions.ENGINEER_UPLOAD_MAIN,
          }),
        ).unwrap();
      } else {
        await dispatch(
          engMixTransition({
            ...transitionParams,
            transition: MixingTransitions.ENGINEER_UPLOAD_MIX,
          }),
        ).unwrap();
      }

      // fetch comment written by the engineer
      if (mostRecentFile?.id) {
        await dispatch(
          fetchFileComments({
            projectId: project.id,
            code: null,
          }),
        );
      }
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    project.id,
    closePopover,
    mostRecentFile?.id,
    enableArtistMP3Download,
    enableArtistWAVDownload,
  ]);

  const popoverProps = {
    [SendForReviewPopoverVariant.CONFIRMATION]: {
      title: "Are you sure?",
      description:
        "This project still has not received full payment, are you sure you want to allow downloads?",
      content: null,
      onConfirm: uploadEngineerMix,
    },
    [SendForReviewPopoverVariant.STANDARD]: {
      title: `Send your main ${projectTypeText} file for review`,
      description: `Allow client to download your ${projectTypeText} file?`,
      content: (
        <>
          <SendForReviewPopoverCheckBoxRow>
            <CheckBox
              checked={enableArtistMP3Download}
              label={<Text size={TEXT_SIZE.SMALL}>Allow MP3 download</Text>}
              onClick={() =>
                setEnableArtistMP3Download(!enableArtistMP3Download)
              }
            />
            <CheckBox
              checked={enableArtistWAVDownload}
              label={<Text size={TEXT_SIZE.SMALL}>Allow WAV download</Text>}
              onClick={() =>
                setEnableArtistWAVDownload(!enableArtistWAVDownload)
              }
            />
          </SendForReviewPopoverCheckBoxRow>
          <SendForReviewPopoverToolTipRow>
            <Text size={TEXT_SIZE.SMALL}>
              If your client downloads your {projectTypeText}, they waive their
              right to a refund on the project.
            </Text>
            <ToolTipTextArea
              text="EngineEars maintains client download history."
              tooltipTextStyle={{
                width: "200px",
                maxWidth: "200px",
              }}
            />
          </SendForReviewPopoverToolTipRow>
        </>
      ),
      onConfirm: showConfirmationPopover
        ? () => setPopoverVariant(SendForReviewPopoverVariant.CONFIRMATION)
        : uploadEngineerMix,
    },
  };

  const handleForReviewConfirm = async () => {
    await popoverProps[popoverVariant].onConfirm();
    await invalidateOnboardingProgress();
  };

  return (
    <BasePopover
      isOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      closePopover={closePopover}
      disableAutoClose
      side="top"
      title={popoverProps[popoverVariant].title}
      description={popoverProps[popoverVariant].description}
      additionalContent={popoverProps[popoverVariant].content}
      onCancel={() => {
        setPopoverVariant(SendForReviewPopoverVariant.STANDARD);
      }}
      onConfirm={handleForReviewConfirm}
      wrapperElement={containerElement}
    >
      <PopoverTrigger asChild>
        <Button
          fullWidth
          disabled={isLoading || !areFilesUploaded}
          loading={isLoading}
          variant={ButtonVariant.PRIMARY}
        >
          Post {projectTypeText} for review
        </Button>
      </PopoverTrigger>
    </BasePopover>
  );
};
