import styled from "styled-components";
import { Button } from "../../core-ui/components/Button/Button";

export const FileVersionTableContainer = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 6px;
  min-height: unset;
  border-radius: 0;
`;
