import { useAppSelector } from "../../../store/hooks";
import { Alt } from "../../../store/models/alts";
import {
  ProjectType,
  projectTypeReadableName,
} from "../../../store/models/project";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { getSongRateMultiplier } from "../../../store/utils/scheduledprojects";
import { FirstPassDate } from "../ProjectBookingCalendar/ProjectBookingCalendar";
import "./ProjectBookingCart.css";

export interface ProjectBookingCartProps {
  onClickSong: (index: number) => void;
  projectTitle: string;
  songsList: string[];
  altsList: Alt[];
  selectedSong: number;
  selectionMap: Map<string, FirstPassDate>;
  basePrice: number;
  projectType: ProjectType;
}

export const ProjectBookingCart = ({
  onClickSong,
  projectTitle,
  songsList,
  selectedSong,
  selectionMap,
  basePrice,
  projectType,
}: ProjectBookingCartProps) => {
  const getFirstPassDate = (song: string) =>
    selectionMap.get(song)?.date.toDateString();
  const bulkDiscountDisabled = useAppSelector(
    (state) => state.bookingStore.engUniqueBookingLink?.bulk_discounts_disabled,
  );
  const mixOrMaster = projectType === ProjectType.MASTERING ? "Master" : "Mix";

  return (
    <table className="project-alts-cart-table ">
      <thead>
        <tr>
          <th>
            {projectTitle} ({projectTypeReadableName.get(projectType)})
          </th>
          <th>Initial {mixOrMaster} Date</th>
          <td>
            {PennyDollarFormatter().format(basePrice)} x {songsList.length}
          </td>
        </tr>
      </thead>
      <tbody className="project-booking-card-body">
        {songsList.map((song, i) => (
          <tr key={i}>
            <td
              className={
                i === selectedSong
                  ? "song-line-item selected-song"
                  : "song-line-item"
              }
              onClick={() => onClickSong(i)}
            >
              {song}
            </td>
            <td>{getFirstPassDate(song)}</td>
            <td>
              {selectionMap.has(song)
                ? "+" +
                  PennyDollarFormatter().format(
                    selectionMap.get(song)!.availability[1] *
                      basePrice *
                      getSongRateMultiplier(i + 1, bulkDiscountDisabled),
                  )
                : ""}
              {!bulkDiscountDisabled &&
                getSongRateMultiplier(i + 1) !== 1.0 &&
                ` (${Math.round(
                  (1 - getSongRateMultiplier(i + 1)) * 100,
                )}% off)`}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
