import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "styled-components";

export const MusoRosterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  max-width: var(--max-screen-width);
`;

export const MusoRosterDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
`;

export const MusoRosterProfileData = styled.div<{
  $activeEntity: boolean;
}>`
cursor: pointer;
  width: fit-content;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  background-color: inherit;
  border: ${({ theme, $activeEntity }) =>
    $activeEntity ? `1px solid ${theme.colorPalette.Green600}` : ""};
  }};
`;

export const MusoRosterProfileImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;

export const MusoRosterCreditCount = styled.div`
  padding: 2px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.midgroundColor};
`;

export const MusoRosterInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 16px;
  gap: 10px;
`;

export const MusoRosterCheck = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colorPalette.Green600};
  position: absolute;
  top: 6px;
  right: 6px;
`;

export const MusoRosterMobileButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
