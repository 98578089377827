import { ButtonOwnProps } from "@mui/material";

/**
 * We can add more variants as needed (i.e. secondary, error, text, gradient, etc)
 */
export enum ButtonVariant {
  PRIMARY = "primary",
  OUTLINED = "outlined",
  GRADIENT = "gradient",
  UNSTYLED = "unstyled",
  GHOST = "ghost",
  TERTIARY = "tertiary",
  ACTIVE = "active",
  SUCCESS = "success",
  ERROR = "error",
  TEXT = "text",
  DISABLED = "disabled",
  DELETE = "delete",
}

export const muiVariant: Record<ButtonVariant, ButtonOwnProps["variant"]> = {
  [ButtonVariant.PRIMARY]: "contained",
  [ButtonVariant.OUTLINED]: "outlined",
  [ButtonVariant.GRADIENT]: "contained",
  [ButtonVariant.UNSTYLED]: "text",
  [ButtonVariant.GHOST]: "text",
  [ButtonVariant.TERTIARY]: "text",
  [ButtonVariant.ACTIVE]: "text",
  [ButtonVariant.SUCCESS]: "contained",
  [ButtonVariant.ERROR]: "contained",
  [ButtonVariant.TEXT]: "text",
  [ButtonVariant.DISABLED]: "text",
  [ButtonVariant.DELETE]: "contained",
};

export const muiColor: Record<ButtonVariant, ButtonOwnProps["color"]> = {
  [ButtonVariant.PRIMARY]: "primary",
  [ButtonVariant.OUTLINED]: "secondary",
  [ButtonVariant.GRADIENT]: "primary",
  [ButtonVariant.UNSTYLED]: "secondary",
  [ButtonVariant.GHOST]: "secondary",
  [ButtonVariant.TERTIARY]: "secondary",
  [ButtonVariant.ACTIVE]: "primary",
  [ButtonVariant.SUCCESS]: "success",
  [ButtonVariant.ERROR]: "error",
  [ButtonVariant.TEXT]: "secondary",
  [ButtonVariant.DISABLED]: "primary",
  [ButtonVariant.DELETE]: "secondary",
};

export const buttonStyle: Record<ButtonVariant, string> = {
  [ButtonVariant.PRIMARY]: "",
  [ButtonVariant.OUTLINED]: "",
  [ButtonVariant.GRADIENT]: "button-gradient",
  [ButtonVariant.UNSTYLED]: "button-unstyled",
  [ButtonVariant.GHOST]: "button-ghost",
  [ButtonVariant.TERTIARY]: "button-tertiary",
  [ButtonVariant.ACTIVE]: "button-active",
  [ButtonVariant.SUCCESS]: "",
  [ButtonVariant.ERROR]: "",
  [ButtonVariant.TEXT]: "button-text",
  [ButtonVariant.DISABLED]: "button-primary--disabled",
  [ButtonVariant.DELETE]: "button-delete",
};

export const buttonStyleDisabled: Record<ButtonVariant, string> = {
  [ButtonVariant.PRIMARY]: "",
  [ButtonVariant.OUTLINED]: "",
  [ButtonVariant.GRADIENT]: "button-gradient--disabled",
  [ButtonVariant.UNSTYLED]: "button-unstyled--disabled",
  [ButtonVariant.GHOST]: "button-ghost--disabled",
  [ButtonVariant.TERTIARY]: "button-tertiary--disabled",
  [ButtonVariant.ACTIVE]: "button-active--disabled",
  [ButtonVariant.SUCCESS]: "",
  [ButtonVariant.ERROR]: "",
  [ButtonVariant.TEXT]: "button-text",
  [ButtonVariant.DISABLED]: "button-primary--disabled",
  [ButtonVariant.DELETE]: "button-delete--disabled",
};
