import { ReactNode } from "react";
import { useMaintenanceMode } from "../../../hooks/featureFlagHooks/useMaintenanceMode";
import { MaintenanceScreen } from "./MaintenanceScreen";

export interface MaintainenanceWrapperProps {
  children: ReactNode;
}

export const MaintenanceWrapper = ({
  children,
}: MaintainenanceWrapperProps) => {
  const isMaintenanceMode = useMaintenanceMode();
  if (isMaintenanceMode) {
    return <MaintenanceScreen />;
  }
  return <>{children}</>;
};
