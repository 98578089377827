import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { MusoCredit } from "../../../store/models/muso";
import { CreditTableRow } from "../../elements/CreditTableRow/CreditsTableRow";

export interface MusoCreditTableProps {
  selectedCredits: MusoCredit[];
  disabled: boolean;
  onChecked?: (data: MusoCredit) => void;
  loading?: boolean;
  checkedList?: string[];
}

export const MusoCreditTable = ({
  selectedCredits,
  disabled,
  onChecked,
  checkedList,
}: MusoCreditTableProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();

  const desktopTableHeaders = (
    <>
      <th>Album</th>
      <th>Role</th>
      <th>Release Date</th>
    </>
  );

  return (
    <table className="muso-credit-table">
      <thead>
        <tr className="muso-credit-table-row">
          <th>Track Title</th>
          {isDesktop && desktopTableHeaders}
          {!isDesktop && <th>Role</th>}
        </tr>
      </thead>
      <tbody>
        {selectedCredits.map((credit_data, idx) => {
          return (
            <tr key={idx + credit_data.muso_credit_id}>
              <CreditTableRow
                creditData={credit_data}
                visibleCheckMarks={true}
                disabledCheckMarks={disabled}
                onChecked={onChecked}
                selectable={true}
                checkedList={checkedList}
              />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
