import { Box, SelectChangeEvent } from "@mui/material";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useGetSchedulingSurveyAnswers } from "../../../hooks/profileScreenHooks/useGetSchedulingSurveyAnswers";
import { ProjectType } from "../../../store/models/project";
import { EngineerSchedulingSurvey } from "../../../store/models/service";
import User from "../../../store/models/user";
import { getNumberedOptions } from "../../../store/utils/serviceUtils";
import { OptionsDropdown } from "../../core-ui/components/MUIOptionsDropdown/MUIOptionsDropdown";
import { PercentageTextfield } from "../../elements/PercentageTextField/PercentageTextfield";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch";
import { CenteredSoundWaveLoader } from "../CenteredSoundWaveLoader/CenteredSoundWaveLoader";
import "../ManageEngineeringService/ManageEngineeringService.css";
import {
  ServiceFormInputContainer,
  ServiceFormLabel,
  ServiceFormLabelContainer,
  ServiceFormLabelSubtext,
  ServiceFormRow,
} from "../ManageEngineeringService/ManageEngineeringService.styles";
import { WeekdayPicker } from "../WeekdayPicker/WeekdayPicker";
import "./SurveyForm.css";
export interface SurveyFormProps {
  serviceType: ProjectType;
  surveyAnswers: EngineerSchedulingSurvey;
  setSurveyAnswers: Dispatch<SetStateAction<EngineerSchedulingSurvey>>;
  enableRushFees: boolean;
  setEnableRushFees: Dispatch<SetStateAction<boolean>>;
  userData: User;
}

export const SurveyForm = ({
  serviceType,
  surveyAnswers,
  setSurveyAnswers,
  enableRushFees,
  setEnableRushFees,
  userData,
}: SurveyFormProps) => {
  const { isFetching: loadingAnswers } = useGetSchedulingSurveyAnswers({
    onSuccess: (survey) => {
      if (
        survey.per_day_rush_fee_percentage &&
        parseFloat(`${survey.per_day_rush_fee_percentage}`)
      ) {
        setEnableRushFees(true);
      }
      setSurveyAnswers((defaultAnswer) => ({
        ...defaultAnswer,
        ...survey,
      }));
    },
    userId: userData.id,
    serviceType,
    staleTime: Infinity,
  });

  function getNumberArrayFromWeekdayString(weekdays: string) {
    if (!weekdays) return [];

    const weekdaysInput = [];
    for (let i = 0; i < weekdays.length; i++) {
      weekdaysInput.push(parseInt(weekdays.charAt(i)));
    }
    return weekdaysInput;
  }

  const handleWeekdayPickerOnChange = useCallback(
    (values: number[]) => {
      setSurveyAnswers((currentSurveyAnswers) => {
        return { ...currentSurveyAnswers, workdays: values.join("") };
      });
    },
    [setSurveyAnswers],
  );

  const handleNumberOfDayNoticeOnChange = (e: SelectChangeEvent<number>) => {
    const {
      target: { value },
    } = e;
    // On autofill we get a stringified value.
    // That's actually not what we want
    if (typeof value !== "string") {
      setSurveyAnswers((currentSurveyAnswers) => {
        return {
          ...currentSurveyAnswers,
          number_of_days_notice: value,
        };
      });
    }
  };

  const handleUpdateSongsPerDay = (e: SelectChangeEvent<number>) => {
    const {
      target: { value },
    } = e;
    // On autofill we get a stringified value.
    // That's actually not what we want
    if (typeof value !== "string") {
      setSurveyAnswers((currentSurveyAnswers) => {
        return {
          ...currentSurveyAnswers,
          songs_per_day: value,
        };
      });
    }
  };

  const handleSetPerDayRushFee = (value: number) => {
    setSurveyAnswers((currentSurveyAnswers) => {
      return {
        ...currentSurveyAnswers,
        per_day_rush_fee_percentage: value,
      };
    });
  };

  const mixOrMaster = serviceType === ProjectType.MASTERING ? "master" : "mix";

  if (loadingAnswers) return <CenteredSoundWaveLoader />;

  return (
    <div className="survey-form-container">
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
        <ServiceFormLabel>
          Select the days you are available to take projects:
        </ServiceFormLabel>
        <WeekdayPicker
          weekdaysInput={getNumberArrayFromWeekdayString(
            surveyAnswers.workdays!,
          )}
          onChange={handleWeekdayPickerOnChange}
        />
      </Box>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>
            How many songs do you prefer to {mixOrMaster} on a single day?
          </ServiceFormLabel>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <OptionsDropdown
            value={surveyAnswers.songs_per_day}
            options={getNumberedOptions(1, 11)}
            placeholder="Select one"
            onChange={handleUpdateSongsPerDay}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>

      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>
            What is your standard turnaround time for {mixOrMaster}? Number of
            days
          </ServiceFormLabel>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <OptionsDropdown
            value={surveyAnswers.number_of_days_notice}
            options={getNumberedOptions(2, 15)}
            placeholder="Select one"
            onChange={handleNumberOfDayNoticeOnChange}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>

      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <Box>
            <ServiceFormLabel>Turn on automatic rush fees?</ServiceFormLabel>
          </Box>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <ToggleSwitch
            checked={enableRushFees}
            onChange={(checkedState) => {
              setEnableRushFees(checkedState);
              if (!checkedState) {
                handleSetPerDayRushFee(0);
              } else {
                handleSetPerDayRushFee(0.25);
              }
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>

      {enableRushFees && (
        <div className="survey-form-container" style={{ marginLeft: "32px" }}>
          <ServiceFormRow>
            <ServiceFormLabelContainer>
              <ServiceFormLabel>
                If a client is looking for a rushed turnaround time, how much
                more should it cost to do so, as a percentage of typical package
                price? ie what is the per-day rush fee?
              </ServiceFormLabel>
              <ServiceFormLabelSubtext>
                (50% means that the {mixOrMaster} will cost 1.5x the normal
                price for one day of rush)
              </ServiceFormLabelSubtext>
            </ServiceFormLabelContainer>
            <ServiceFormInputContainer>
              <PercentageTextfield
                max={200}
                value={surveyAnswers.per_day_rush_fee_percentage}
                onChangePercentage={handleSetPerDayRushFee}
              />
            </ServiceFormInputContainer>
          </ServiceFormRow>
        </div>
      )}
    </div>
  );
};
