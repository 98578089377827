import Service from "../../store/models/service";
import { useAppDispatch } from "../../store/hooks";
import { useCallback, useEffect, useState } from "react";
import { loadEngineerAvailability } from "../../store/actions/booking";
import {
  getCurrentDate,
  getLastDayOfTwoMonthsFromNow,
} from "../../store/utils/dateTimeUtils";
import { availability_multiplier } from "../../stories/components/ProjectBookingCalendar/ProjectBookingCalendar";

export const useMixingMasteringAvailabilities = (service?: Service) => {
  const dispatch = useAppDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [availabilities, setAvailabilities] = useState<
    Map<string, availability_multiplier>
  >(new Map<string, availability_multiplier>());

  const handleEngineerAvailability = useCallback(
    (data: { [key: string]: availability_multiplier }) => {
      const newSongDatesMap = new Map<string, availability_multiplier>();
      Object.entries(data).forEach((date) => {
        const [k, v] = date;
        newSongDatesMap.set(k, v);
      });
      setAvailabilities(newSongDatesMap);
    },
    [],
  );

  useEffect(() => {
    if (!service) return;
    if (!service.engineer_id) return;
    if (!service.service_type) return;
    setFetching(true);
    dispatch(
      loadEngineerAvailability({
        engineer_id: service.engineer_id,
        start_date: getCurrentDate(),
        end_date: getLastDayOfTwoMonthsFromNow(),
        service_type: service.service_type,
      }),
    )
      .unwrap()
      .then(handleEngineerAvailability)
      .finally(() => setFetching(false));
  }, [dispatch, service, handleEngineerAvailability]);

  return {
    availabilities,
    fetching,
  };
};
