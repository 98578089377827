import { Box, styled } from "@mui/material";

export const PlayAudioButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: relative;
  background: none;
`;

export const PlayAudioButtonView = styled("button")`
  position: absolute;
  /* Remove default button styling */
  background: none;
  border: none;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
