import { useMemo } from "react";
import { useSessionUserRoles } from "../../../hooks/useSessionUsers";
import { SessionBookingDetailsResultData } from "../../../store/actions/recording";
import { OrderBreakdown } from "../OrderBreakdown/OrderBreakdown";
import { SessionBookingStudioInfo } from "../SessionBookingStudioInfo/SessionBookingStudioInfo";
import { SessionBookingDetailsStats } from "./SessionBookingDetailsStats";
import {
  SessionBookingStudioInfoContainer,
  SessionRequestedPanelDetailsContainer,
} from "./SessionRequestedPanel.styles";

interface SessionRequestedPanelDetailsProps {
  hideMap?: boolean;
  sessionBookingDetails: SessionBookingDetailsResultData;
}

export const SessionRequestedPanelDetails = ({
  hideMap = false,
  sessionBookingDetails,
}: SessionRequestedPanelDetailsProps) => {
  const {
    recording_sessions: recordingSessions,
    id,
    cancelled,
    pending_my_acceptance,
  } = sessionBookingDetails;

  const firstRecordingSession = useMemo(() => {
    if (recordingSessions.length === 0) {
      return null;
    }
    return recordingSessions[0];
  }, [recordingSessions]);

  const { isEngineer, isManager } = useSessionUserRoles(firstRecordingSession);

  if (recordingSessions.length === 0) {
    return null;
  }

  return (
    <SessionRequestedPanelDetailsContainer>
      <SessionBookingDetailsStats
        recordingSessions={recordingSessions}
        bookingId={id}
        hasSessionBookingBeenCancelled={Boolean(cancelled)}
        isSessionBookingPendingMyAcceptance={Boolean(pending_my_acceptance)}
      />
      <OrderBreakdown
        orderSummary={sessionBookingDetails.orderSummary}
        isLoading={false}
        showLabelToggle={false}
        hideNonEngineerFees={Boolean(isEngineer)}
        hideNonStudioFees={Boolean(isManager)}
      />
      {!hideMap && (
        <SessionBookingStudioInfoContainer>
          <SessionBookingStudioInfo
            recordingLocation={firstRecordingSession?.recording_location}
            cancellationPolicy={firstRecordingSession?.cancellation_policy}
            studioRoom={firstRecordingSession?.studio_room}
          />
        </SessionBookingStudioInfoContainer>
      )}
    </SessionRequestedPanelDetailsContainer>
  );
};
