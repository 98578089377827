import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import loadingSuccess from "../../lotties/success.json";
import useModal from "../../../hooks/useModal";
import { sendPasswordReset } from "../../../store/actions/accountInfo";
import * as Popover from "@radix-ui/react-popover";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";
import Lottie from "react-lottie";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { FieldInput, ForgotPasswordContainer } from "./ForgotPassword.styles";
import { TextColor } from "../../core-ui/components/Text/TextUtils";

export const defaultOptions = {
  loop: false,
  autoplay: true,
  duration: 100,
  animationData: loadingSuccess,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const localUTMParams = useAppSelector(
    (state) => state.accountInfo.localUTMParams,
  );
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const [lottiePaused, setLottiePaused] = useState<boolean>(true);
  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();

  useEffect(() => {
    return () => {
      if (!isPopoverOpen) return;
      setEmail("");
      setLoading(false);
      setLinkSent(false);
      setLottiePaused(true);
    };
  }, [isPopoverOpen]);

  useEffect(() => {
    if (!linkSent) return;
    setTimeout(() => {
      setLottiePaused(false);
      closePopover();
    }, 3000);
  }, [linkSent]);

  const sendResetCode = useCallback(() => {
    setLoading(true);
    emitAnalyticsTrackingEvent("start_reset_password", {
      email: `${email}`,
      ...localUTMParams,
    });
    dispatch(sendPasswordReset({ email }))
      .unwrap()
      .then(() => {
        setLinkSent(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [email, dispatch]);

  const popoverContent = linkSent ? (
    <>
      <Lottie
        isStopped={lottiePaused}
        options={defaultOptions}
        height={70}
        width={70}
      />
      <Text style={{ textAlign: "center" }}>
        Password reset link sent to email, if the account exists.
      </Text>
    </>
  ) : (
    <FieldInput
      size={"small"}
      label={!linkSent && "Enter email"}
      value={email}
      onChange={(e) => {
        const email = e.target.value;
        setEmail(email);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" && email.length) {
          sendResetCode();
        }
      }}
    />
  );

  return (
    <BasePopover
      side="top"
      isOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      closePopover={closePopover}
      disableAutoClose={true}
      title={"Forgot Password"}
      additionalContent={popoverContent}
      onConfirm={sendResetCode}
      okButtonProps={{
        disabled: !email.length,
        loading: loading,
      }}
      hideFooter={Boolean(linkSent)}
    >
      <Popover.Trigger asChild>
        <ForgotPasswordContainer
          onClick={() => {
            emitAnalyticsTrackingEvent(
              "clicked_forgot_password",
              localUTMParams,
            );
          }}
        >
          <Text color={TextColor.PRIMARY} weight={TEXT_WEIGHT.SEMI_BOLD}>
            Forgot password?
          </Text>
        </ForgotPasswordContainer>
      </Popover.Trigger>
    </BasePopover>
  );
};
