import { styled } from "styled-components";
import { WaveSurfer } from "wavesurfer-react";

export const StyledWaveFormComponentDiv = styled.div`
  width: 100%;
  height: auto;
`;

export const StyledWaveFormComponentP = styled.p`
  color: ${({ theme }) => theme.colorPalette.Gray300};
  font-size: ${({ theme }) => theme.textSizeXs};
  width: 25px;
`;

export const StyledWaveFormComponentWithTimeDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  margin-right: 20px;
  gap: 8px;
`;

export const StyledWaveFormComponentWaveSurfer = styled(WaveSurfer).attrs(
  ({ theme }) => ({
    cursorColor: theme.colorPalette.Gray950,
    height: 50,
  }),
)``;

export const StyledWaveFormComponentContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledWaveFormComponentLoadingLosslessAudioContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 500;
  display: flex;
  font-size: 12px;
  align-items: flex-end;

  & > .lossless-audio-loading-circle {
    margin-left: 8px;
  }
`;
