import { useMemo, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { Alt } from "../../../store/models/alts";
import { projectTypeReadableName } from "../../../store/models/project";
import Service from "../../../store/models/service";
import User from "../../../store/models/user";
import { selectPromoCodeDiscountMultiplier } from "../../../store/selectors/marketingSelectors";
import { DollarFormatter } from "../../../store/utils/formatUtils";
import {
  getDolbyMixingPricePerSong,
  getIncludedIncentiveByServiceType,
  getMasteringPricePerSong,
} from "../../../store/utils/serviceUtils";
import { CheckoutAltsForm } from "../../components/CheckoutAltsForm/CheckoutAltsForm";
import { CheckoutScreenHeader } from "../../components/CheckoutScreenHeader/CheckoutScreenHeader";
import { ProjectHandoffProps } from "../../components/ProjectHandoff/ProjectHandoff";
import { PrimaryButton } from "../../elements/Button/button.stories";
import { Card } from "../../elements/Card/Card";
import { CheckBox } from "../../elements/CheckBox/CheckBox";
import { FixedRangeNumberField } from "../../elements/FixedRangeNumberField/FixedRangeNumberField";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

export interface SelectPackageScreenProps extends ProjectHandoffProps {
  engineerUser: User;
  service: Service;
  onClickNextStep: () => void;
  setNumberOfSongs: (songs: number) => void;
  numberOfSongs: number;
  alts: Set<Alt>;
  setAlts: (alts: Set<Alt>) => void;
  onClickPreviousStep?: () => void;
  useLabelPrice?: boolean;
  competitionSubmission?: boolean;
  toggleCompetitionSubmission?: () => void;
  addMastering?: boolean;
  toggleAddMastering?: () => void;
  addAtmosMixing?: boolean;
  toggleAddAtmosMixing?: () => void;
}

const calculateDiscountMultiplier = (
  discountPercentage: number | undefined,
) => {
  if (discountPercentage === undefined) return 1;
  return (100 - discountPercentage) / 100;
};

export const SelectPackageScreen = ({
  engineerUser,
  service,
  onClickNextStep,
  setNumberOfSongs,
  numberOfSongs,
  alts,
  setAlts,
  onClickPreviousStep,
  useLabelPrice = false,
  addMastering = false,
  toggleAddMastering = () => {},
  addAtmosMixing = false,
  toggleAddAtmosMixing = () => {},
  projectToHandoff,
}: SelectPackageScreenProps) => {
  const [value, setValue] = useState<number>(numberOfSongs);
  const services = useAppSelector((state) => state.engineerServices.services);
  const darkMode = useAtomValue(darkModeAtom);
  const bulkDiscountDisabled = useAppSelector(
    (state) => state.bookingStore.engUniqueBookingLink?.bulk_discounts_disabled,
  );

  const masteringPricePerSong = useMemo(
    () => getMasteringPricePerSong(service, services, useLabelPrice),
    [service, services, useLabelPrice],
  );
  const atmosMixingPricePerSong = useMemo(
    () => getDolbyMixingPricePerSong(service, services, useLabelPrice),
    [service, services, useLabelPrice],
  );

  const onChangeNumberOfSongs = (value: number) => {
    setValue(value);
    setNumberOfSongs(value);
  };

  const addMasteringDiscountMulltiplier = calculateDiscountMultiplier(
    service.add_master_discount_percentage,
  );

  const addAtmosMixingDiscountMultiplier = calculateDiscountMultiplier(
    service.add_atmos_mix_discount_percentage,
  );

  const discountMultiplier = useAppSelector(selectPromoCodeDiscountMultiplier);

  return (
    <div data-theme={darkMode ? "dark" : ""} className="checkout-screen">
      <CheckoutScreenHeader
        engineerUser={engineerUser}
        service={service}
        step={"Book"}
      />
      <div className="checkout-card-row-container">
        <Card customClassName="checkout-card">
          <div className="card-header-row">
            <h1 className="card-header">
              {projectTypeReadableName.get(service.service_type)} Package
            </h1>
            <p>
              {DollarFormatter().format(
                +service.service_rate.price * discountMultiplier,
              )}
              /song
            </p>
          </div>
          <ul className="included-list">
            {getIncludedIncentiveByServiceType(service).map(
              (benefit, index) => (
                <li className={"b2"} key={index}>
                  {benefit}
                </li>
              ),
            )}
          </ul>
          <div>
            {atmosMixingPricePerSong && atmosMixingPricePerSong > 0 && (
              <CheckBox
                label={`Add Dolby Atmos Mixing ${DollarFormatter().format(
                  atmosMixingPricePerSong *
                    addAtmosMixingDiscountMultiplier *
                    discountMultiplier,
                )}/song`}
                isSelected={addAtmosMixing}
                toggle={toggleAddAtmosMixing}
              />
            )}
            {masteringPricePerSong && masteringPricePerSong > 0 && (
              <CheckBox
                label={`Add Mastering ${DollarFormatter().format(
                  masteringPricePerSong *
                    addMasteringDiscountMulltiplier *
                    discountMultiplier,
                )}/song`}
                isSelected={addMastering}
                toggle={toggleAddMastering}
              />
            )}
          </div>
        </Card>
        <Card customClassName={"checkout-card"}>
          <div className="card-header-div">
            <div
              style={projectToHandoff ? { display: "none" } : {}}
              className="card-header-row"
            >
              <h1 className="card-header">Number of Songs *</h1>
              <FixedRangeNumberField
                placeholder="#"
                initialValue={value}
                min={1}
                max={24}
                onChange={onChangeNumberOfSongs}
              />
            </div>
            <p
              style={projectToHandoff ? { display: "none" } : {}}
              className="b2 checkout-header"
            >
              {bulkDiscountDisabled
                ? ""
                : "Discounts apply for projects of 6 songs or more."}
            </p>
          </div>
          <CheckoutAltsForm
            projectToHandoff={projectToHandoff}
            disabled={false}
            alts={alts}
            setAlts={setAlts}
            service={service}
          />
        </Card>
      </div>
      <div className="checkout-card-row-container">
        {onClickPreviousStep && (
          <PrimaryButton
            customClassName={" checkout-footer-previous-button "}
            onClick={onClickPreviousStep}
            label="Previous Step"
          />
        )}
        <PrimaryButton
          customClassName={" checkout-footer-next-button "}
          onClick={onClickNextStep}
          label={projectToHandoff ? "Title Song" : "Title Songs"}
          primary={true}
          disabled
        />
      </div>
    </div>
  );
};
