import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { REVOKE_STREAM_TOKEN } from "../../store/utils/routes";

interface Response {
  success: boolean;
}

export const revokeStreamToken = async () => {
  const response = await makeBackendGetCallWithJsonResponse<Response>(
    REVOKE_STREAM_TOKEN,
    "",
  );

  if (response.success) {
    return response.resultJson;
  }
  return Promise.reject(response.resultJson);
};

export default revokeStreamToken;
