import styled from "styled-components";

export const ProjectReviewContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  border: solid 1px ${({ theme }) => theme.separatorColor};
  border-radius: ${({ theme }) => theme.border.radius.md};
  margin-bottom: 24px;
  padding: 16px;
`;

export const ProjectReviewTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ProjectReviewRatingContainer = styled.div<{
  $hasReview?: boolean;
}>`
  display: flex;
  margin: 12px 0;
  transform: translateX(-18px);
  transform: ${({ $hasReview }) => ($hasReview ? "none" : "translateX(-18px)")};
`;

export const ProjectReviewTextArea = styled.textarea`
  background: ${({ disabled }) => (disabled ? "unset" : "transparent")};
  border: solid 1px
    ${({ theme, disabled }) =>
      disabled ? "transparent" : theme.separatorColor};
  border-radius: ${({ theme }) => theme.border.radius.md};
  box-sizing: border-box;
  color: ${({ theme }) => theme.textPrimaryColor};
  margin-bottom: 18px;
  padding: ${({ disabled }) => (disabled ? "0" : "10px")};
  resize: none;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const ProjectReviewButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
