import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { ProfileType } from "../../Auth/ProfileType";
import { Box } from "@mui/material";

interface AccountTypeProps {
  onClose: () => void;
}

export const AccountType = ({ onClose }: AccountTypeProps) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: "24px",
        gap: "32px",
      }}
    >
      <Text
        variant={TextStyleVariant.P1}
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        Update/select an additional profile type below
      </Text>
      <ProfileType
        showHeader={false}
        showAllTypes={false}
        customContainerStyle={{ padding: 0 }}
        customStudioSelectedHelpText={
          'Click "Add a studio profile" in the navigation menu to create a Studio.'
        }
        skipRegisterAnalytics={true}
        onClose={onClose}
        nextButtonText={"Save Settings"}
        showCancelButton={true}
        showSuccessToast={true}
        skipFlow={true}
      />
    </Box>
  );
};
