import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  NoReviewsButtonContainer,
  ReviewsStarContainer,
  ReviewsTextContainer,
} from "./NoReviewsButton.styles";
import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";

interface NoReviewsButtonProps {
  onClick: () => void;
  buttonText: string;
  isLoading: boolean;
}
export const NoReviewsButton = ({
  onClick,
  buttonText,
  isLoading,
}: NoReviewsButtonProps) => {
  return (
    <NoReviewsButtonContainer>
      <ReviewsTextContainer>
        <ReviewsStarContainer>
          {[...Array(5)].map((_, index) => (
            <FontAwesomeIcon key={index} icon={faStar} />
          ))}
        </ReviewsStarContainer>
        <Text variant={TextStyleVariant.H6}>
          Let&apos;s get some reviews in here.
        </Text>
      </ReviewsTextContainer>
      <Button
        loading={isLoading}
        variant={ButtonVariant.OUTLINED}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </NoReviewsButtonContainer>
  );
};
