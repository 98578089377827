import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";
import { displayEngineEarsError } from "../../api/helpers";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { workingHoursArgs } from "../../store/actions/workingHours";
import { WorkingHours } from "../../store/models/workingHours";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { WORKING_HOURS_API } from "../../store/utils/routes";

interface useGetWorkingHoursProps extends workingHoursArgs {
  onSuccess: (workingHours: WorkingHours[]) => void;
  staleTime?: number | undefined;
  enabled?: boolean;
}

export const useGetWorkingHours = ({
  studio_room_id,
  user_id,
  onSuccess,
  staleTime,
  enabled = true,
}: useGetWorkingHoursProps) => {
  return useQuery({
    queryKey: [
      studio_room_id
        ? QUERY_KEYS.GET_WORKING_HOURS_STUDIO
        : QUERY_KEYS.GET_WORKING_HOURS_USER,
      studio_room_id || user_id,
    ],
    queryFn: async () => {
      const params = `?${queryString.stringify(
        { studio_room_id, user_id },
        { skipNull: true, skipEmptyString: true },
      )}`;

      const result = await makeBackendGetCallWithJsonResponse<WorkingHours[]>(
        WORKING_HOURS_API,
        params,
      );
      if (result.success) {
        onSuccess(result.resultJson);
        return result.resultJson;
      }
      displayEngineEarsError(result.resultJson);
      return Promise.reject(result.resultJson);
    },
    staleTime,
    enabled,
  });
};
