import styled from "styled-components";
import { Text } from "../../core-ui/components/Text/Text";
import { StyledProjectListInput } from "../../elements/ProjectListRow/ProjectListRow.styles";
import {
  TransactionBreakdownRow,
  TransactionBreakdownText,
} from "../TransactionBreakdown/TransactionBreakdown.styles";

export const ContentBody = styled.div`
  padding: 20px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${StyledProjectListInput} {
    font-weight: ${({ theme }) => theme.textWeightSemibold};
    font-size: ${({ theme }) => theme.textSizeSm};
    max-width: 150px;

    @media screen and (min-width: 480px) {
      max-width: 250px;
    }
  }
`;

export const BookingDetailsContent = styled.div`
  background-color: ${({ theme }) => theme.midgroundColor};
  border-radius: ${({ theme }) => theme.border.radius.md};
  margin-bottom: 24px;
`;

export const ContentHeader = styled.div`
  background-color: ${({ theme }) => theme.darkerMidgroundColor};
  padding: 15px 16px;
  border-top-left-radius: ${({ theme }) => theme.border.radius.md};
  border-top-right-radius: ${({ theme }) => theme.border.radius.md};
`;

export const PropsLabel = styled.div`
  grid-column: 1/4;

  @media screen and (min-width: 480px) {
    grid-column: 1/2;
  }
`;

export const PropsValue = styled.div`
  grid-column: 4/-1;

  @media screen and (min-width: 480px) {
    grid-column: 2/-1;
  }
`;

export const BookingDetailsStats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;

  ${TransactionBreakdownRow} {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 16px;
    grid-column-gap: 8px;

    ${TransactionBreakdownText}:nth-child(odd) {
      grid-column: 1/4;
    }

    ${TransactionBreakdownText}:nth-child(even) {
      grid-column: 4/-1;
    }

    @media screen and (min-width: 480px) {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const BookingDetailsMetadataContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1fr);
  grid-column-gap: 8px;
  grid-row-gap: 16px;

  @media screen and (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const BookingDetailsProps = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 16px;
  grid-column-gap: 8px;

  @media screen and (min-width: 480px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Divider = styled.div<{ $light?: boolean }>`
  height: 1px;
  width: 100%;
  background-color: ${({ theme, $light }) =>
    $light ? theme.darkerMidgroundColor : theme.separatorColor};
`;

export const BookingDetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
  margin-top: 24px;
`;

export const BookingDetailsMetadata = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BookingDetailsOverview = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 12px;

  @media screen and (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8px;
  }
`;

export const BookingDetailsDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: ${({ theme }) => theme.textPrimaryColor};
`;

export const BookingDetailsOverviewTitle = styled(Text)`
  margin-bottom: 8px;
`;

export const UnderlinedText = styled(Text)`
  text-decoration: underline;
`;

export const BookingDetailsContainer = styled.div`
  margin-bottom: 24px;
`;
