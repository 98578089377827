import { DebouncedFunc } from "lodash";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useRef } from "react";
import {
  MixMasterCart,
  predefinedDiscountOptionValueMap,
  setIsMixMasterOrderSummaryLoading,
} from "../store/actions/mixMasterCartsStore";
import {
  addRecordingSessionsToCart,
  RecordingCart,
  setIsRecordingOrderSummaryLoading,
} from "../store/actions/recordingCartsStore";
import {
  getItemizedTransaction,
  TransactionUpdateProjectData,
  TransactionUpdateRecordingData,
  updateTransaction,
  UpdateTransactionArgs,
} from "../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { ProjectType } from "../store/models/project";
import { StudioRoom } from "../store/models/studio";
import { getApplicableDiscountBookingLinkData } from "../store/selectors/bookingSelectors";
import { useIsEntirePendingSessionDataBookable } from "./bookingHooks/useIsPendingSessionDataBookable";

const DEBOUNCE_TIME = 2000;

const useUpdateMixMasterOrderSummary = (
  cart: MixMasterCart | null,
  transactionId: number | undefined,
  activeServiceId: number | undefined,
  activeServiceType: ProjectType | undefined,
  transactionCode: string,
  activeServiceTypeProjectIds: number[],
  isLoggedInUserGeneratingBooking: boolean,
) => {
  const debouncedRef = useRef<DebouncedFunc<() => void>>();
  const dispatch = useAppDispatch();

  // discount booking link is only available for mixing/mastering services.
  const discountBookingLinkData = useAppSelector(
    getApplicableDiscountBookingLinkData(activeServiceId),
  );

  const updateOrderSummary = useCallback(async () => {
    if (!transactionId || !cart) return;

    const {
      quantity,
      engineerHasFiles,
      inProgressProject,
      addMaster,
      addAtmos,
      alts,
      title,
      artistName,
      applyLabelRate,
      overwritePrice,
      estimatedDeliveryDateData,
      promocode,
      providerAssumesFees,
      predefinedDiscountOption,
    } = cart;
    const updateArgs: UpdateTransactionArgs = {
      transactionId,
      engineer_has_files: engineerHasFiles,
      in_progress_project: inProgressProject,
      is_predefined: isLoggedInUserGeneratingBooking,
      title,
      artist_name: artistName,
      apply_label_rate: applyLabelRate,
      overwrite_price: overwritePrice,
      estimated_delivery_date:
        estimatedDeliveryDateData != null &&
        !estimatedDeliveryDateData.is_computed
          ? estimatedDeliveryDateData.date
          : null,
      provider_assumes_fees: providerAssumesFees,
      predefined_discount_option: predefinedDiscountOption
        ? predefinedDiscountOptionValueMap.get(predefinedDiscountOption)
        : null,
    };

    const projectData: TransactionUpdateProjectData[] = [];
    if (activeServiceId) {
      for (let i = 0; i < quantity; i++) {
        const data: TransactionUpdateProjectData = {
          service_id: activeServiceId,
          service_type: activeServiceType,
          add_master: addMaster,
          add_atmos: addAtmos,
          alts,
          promocode: promocode ?? undefined,
          code: discountBookingLinkData?.code,
          // if this is undefined, means that we are adding a new project
          // if this exists, means that we are updating an existing project
          id: activeServiceTypeProjectIds[i],
        };
        projectData.push(data);
      }
    }

    dispatch(setIsMixMasterOrderSummaryLoading(true));

    await dispatch(
      updateTransaction({
        ...updateArgs,
        projects_data: projectData,
      }),
    );

    // Clear debounced ref after updateTransaction is completed.
    debouncedRef.current = undefined;

    await dispatch(
      getItemizedTransaction({
        transactionCode,
      }),
    );
  }, [
    cart,
    activeServiceId,
    activeServiceTypeProjectIds,
    activeServiceType,
    discountBookingLinkData,
  ]);

  const debouncedUpdate = debounce(updateOrderSummary, DEBOUNCE_TIME);

  useEffect(() => {
    if (debouncedRef.current) {
      debouncedRef.current.cancel();
    }
    debouncedRef.current = debouncedUpdate;
    debouncedRef.current();
  }, [cart, activeServiceId]);
};

export const useMixMasterCheckoutCarts = (
  transactionCode: string,
  isLoggedInUserGeneratingBooking: boolean,
  canUpdateCart: boolean,
) => {
  const {
    activeServiceId,
    activeTransactionId,
    activeServiceType,
    activeServiceTypeProjectIds,
  } = useAppSelector((state) => state.generateBookingStore);
  const cart = useAppSelector((state) => {
    return state.mixMasterCartsStore.cart;
  });

  useUpdateMixMasterOrderSummary(
    canUpdateCart ? cart : null, // only trigger an update when the FE has loaded all information necessary for an update
    activeTransactionId,
    activeServiceId,
    activeServiceType,
    transactionCode,
    activeServiceTypeProjectIds,
    isLoggedInUserGeneratingBooking,
  );
};

const useUpdateRecordingOrderSummary = (
  cart: RecordingCart | null,
  activeServiceType: ProjectType | undefined,
  transactionId: number | undefined,
  activeServiceId: number | undefined,
  activeStudioRoomId: number | undefined,
  transactionCode: string,
  isLoggedInUserGeneratingBooking: boolean,
  activeServiceTypeProjectIds: number[],
  studioRoom: StudioRoom | undefined,
) => {
  const debouncedRef = useRef<DebouncedFunc<() => void>>();
  const dispatch = useAppDispatch();
  const transactionUpdateAbortControllerRef = useRef<AbortController | null>(
    null,
  );
  const orderSummaryAbortControllerRef = useRef<AbortController | null>(null);
  const { sessionData } = cart || { sessionData: [] };
  const pendingSessions = useAppSelector(
    (state) => state.shoppingCart.pendingSessionData,
  );
  const pendingSessionsAreBookable = useIsEntirePendingSessionDataBookable(
    pendingSessions,
    studioRoom,
  );

  useEffect(() => {
    if (!pendingSessionsAreBookable) return;
    if (!pendingSessions) return;
    dispatch(
      addRecordingSessionsToCart({
        pendingSessions,
        studioRoomId: activeStudioRoomId,
      }),
    );
  }, [
    dispatch,
    pendingSessionsAreBookable,
    activeStudioRoomId,
    pendingSessions,
  ]);

  const updateOrderSummary = useCallback(async () => {
    if (!transactionId || !cart || !activeServiceId) return;
    const {
      title,
      artistName,
      applyLabelRate,
      overwritePrice,
      sessionData,
      promocode,
      substituteLocation,
      providerAssumesFees,
      shareArtistContactInfo,
      predefinedDiscountOption,
    } = cart;
    const updateArgs: UpdateTransactionArgs = {
      transactionId,
      is_predefined: isLoggedInUserGeneratingBooking,
      title,
      artist_name: artistName,
      apply_label_rate: applyLabelRate,
      overwrite_price: overwritePrice,
      provider_assumes_fees: providerAssumesFees,
      share_artist_contact_info: shareArtistContactInfo,
      predefined_discount_option: predefinedDiscountOption
        ? predefinedDiscountOptionValueMap.get(predefinedDiscountOption)
        : null,
    };

    const recordingData = sessionData.map((session, idx) => {
      return {
        id: activeServiceTypeProjectIds[idx],
        service_type: ProjectType.RECORDING,
        session_duration_minutes: session.duration,
        title: title,
        service_provider_user_id: session.engineerUserId,
        recording_service_id: activeServiceId,
        first_choice_datetime: session.dateTime,
        promocode,
        substitute_recording_location: substituteLocation,
      } as TransactionUpdateRecordingData;
    });

    if (transactionUpdateAbortControllerRef.current) {
      transactionUpdateAbortControllerRef.current.abort();
      transactionUpdateAbortControllerRef.current = null;
    }
    const transactionUpdateAbortController = new AbortController();
    transactionUpdateAbortControllerRef.current =
      transactionUpdateAbortController;

    dispatch(setIsRecordingOrderSummaryLoading(true));
    await dispatch(
      updateTransaction({
        ...updateArgs,
        recordings_data: recordingData,
        abortController: transactionUpdateAbortController,
      }),
    );

    // Clear debounced ref after updateTransaction is completed.
    debouncedRef.current = undefined;

    if (orderSummaryAbortControllerRef.current) {
      orderSummaryAbortControllerRef.current.abort();
      orderSummaryAbortControllerRef.current = null;
    }
    const orderSummaryAbortController = new AbortController();
    orderSummaryAbortControllerRef.current = orderSummaryAbortController;
    await dispatch(
      getItemizedTransaction({
        transactionCode,
        abortController: orderSummaryAbortController,
      }),
    );
    orderSummaryAbortControllerRef.current = null;
  }, [cart, transactionId, activeServiceId, activeServiceType]);

  const debouncedUpdate = debounce(updateOrderSummary, DEBOUNCE_TIME);

  useEffect(() => {
    if (pendingSessionsAreBookable) {
      if (debouncedRef.current) {
        debouncedRef.current.cancel();
      }
      debouncedRef.current = debouncedUpdate;
      debouncedRef.current();
    }
    debouncedRef.current = debouncedUpdate;
    debouncedRef.current();
  }, [cart, activeServiceId, sessionData]);
};

export const useRecordingCheckoutCarts = (
  transactionCode: string,
  isLoggedInUserGeneratingBooking: boolean,
  studioRoom: StudioRoom | undefined,
  canUpdateCart: boolean,
) => {
  const {
    activeServiceType,
    activeServiceId,
    activeTransactionId,
    activeStudioRoomId,
    activeServiceTypeProjectIds,
  } = useAppSelector((state) => state.generateBookingStore);
  const cart = useAppSelector((state) => {
    return state.recordingCartsStore.cart;
  });

  useUpdateRecordingOrderSummary(
    canUpdateCart ? cart : null, // only trigger an update when the FE has loaded all information necessary for an update
    activeServiceType,
    activeTransactionId,
    activeServiceId,
    activeStudioRoomId,
    transactionCode,
    isLoggedInUserGeneratingBooking,
    activeServiceTypeProjectIds,
    studioRoom,
  );
};
