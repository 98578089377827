import { isSameDay } from "date-fns";
import { CalendarTileProperties } from "react-calendar";
import { useAppSelector } from "../../store/hooks";
import { selectShoppingCartRecordingBookingDates } from "../../store/selectors/shoppingCart";
import { getCurrentDate } from "../../store/utils/dateTimeUtils";
import { RecordingBookingCalendarWidgetVariant } from "../../stories/components/RecordingBookingCalendarWidget/RecordingBookingCalendarWidget";

/**
 * A utility function that checks if a given timeString is available in the provided dateTimes.
 */
const isTimeAvailableOnThisDate = (
  selectedTime: Date | undefined,
  dateTimes: Date[],
) => {
  // if no timeString is provided, then we only check if there are any dateTimes
  if (!selectedTime) {
    return dateTimes.length > 0;
  }
  const timeString = selectedTime?.toLocaleTimeString();

  // if a timeString is provided, we check if it is available in the dateTimes
  const isTimeAvailable = dateTimes?.some(
    (time) => time.toLocaleTimeString() === timeString,
  );
  return isTimeAvailable;
};

type useOverWriteDisabledTilesParams =
  | {
      variant: RecordingBookingCalendarWidgetVariant.ENGINEER;
      isCurrentUserSameAsSelectedEngineer: boolean;
      disablePastDates?: boolean;
      selectedTime: Date | undefined;
      getEngineerAvailabilityOnThisDate: (date: Date) => Date[];
    }
  | {
      variant: RecordingBookingCalendarWidgetVariant.STUDIO;
      isEngineerSelected: boolean;
      isCurrentUserSameAsSelectedEngineer: boolean;
      isCurrentUserSameAsStudioManager: boolean;
      disablePastDates?: boolean;
      selectedTime: Date | undefined;
      getEngineerAvailabilityOnThisDate: (date: Date) => Date[];
      getStudioAvailabilityOnThisDate: (date: Date) => Date[];
      getOverLappingDateAvailabilities: (date: Date) => Date[];
    };

/**
 * A hook that returns a function to OverWriteDisabledTiles.
 * It returns a different version of the function for Studio Booking and for Engineer Booking.
 */
export const useOverWriteDisabledTiles = (
  params: useOverWriteDisabledTilesParams,
) => {
  const {
    variant,
    isCurrentUserSameAsSelectedEngineer,
    disablePastDates = false, // this allows the hook to be used when rescheduling a session
    selectedTime,
    getEngineerAvailabilityOnThisDate,
  } = params;
  const recordingBookingDates = useAppSelector(
    selectShoppingCartRecordingBookingDates,
  );

  // return studio booking version of OverWriteDisabledTiles
  if (variant === RecordingBookingCalendarWidgetVariant.STUDIO) {
    const {
      isCurrentUserSameAsStudioManager,
      isEngineerSelected,
      getStudioAvailabilityOnThisDate,
      getOverLappingDateAvailabilities,
    } = params;

    return ({ date, view }: CalendarTileProperties) => {
      const isPastDate = date.getTime() < getCurrentDate().getTime();
      const isPastDateDisabled =
        disablePastDates ||
        (!isCurrentUserSameAsSelectedEngineer &&
          !isCurrentUserSameAsStudioManager);

      // past dates are disabled, unless the user is a studio manager
      if (view === "month" && isPastDate && isPastDateDisabled) {
        return true;
      }

      // for Studio Managers, no future dates are disabled
      // dates already selected by the user are not disabled
      if (
        isCurrentUserSameAsStudioManager ||
        recordingBookingDates.some((d) => isSameDay(d, date))
      ) {
        return false;
      }

      // for Affiliated Engineers, only dates outside of Studio Availability are disabled
      // if the Engineer is not selected, then only the Studio Availability is considered
      if (isCurrentUserSameAsSelectedEngineer || !isEngineerSelected) {
        // past dates are not disabled and are considered available
        // if the user is the Studio Manager and the same as the tracking engineer, then all dates are available
        if (isPastDate || isCurrentUserSameAsStudioManager) {
          return false;
        }

        return !isTimeAvailableOnThisDate(
          selectedTime,
          getStudioAvailabilityOnThisDate(date),
        );
      }

      // for all other users, dates outside of Studio and Engineer Availability are disabled
      return !isTimeAvailableOnThisDate(
        selectedTime,
        getOverLappingDateAvailabilities(date),
      );
    };
  }

  // return engineer booking version of OverWriteDisabledTiles
  return ({ date, view }: CalendarTileProperties) => {
    const isPastDate = date.getTime() < getCurrentDate().getTime();
    const isPastDateDisabled =
      disablePastDates || !isCurrentUserSameAsSelectedEngineer;

    // past dates are disabled, unless the user is the selected Engineer
    if (view === "month" && isPastDate && isPastDateDisabled) {
      return true;
    }

    // for selected Engineers, no dates are considered disabled
    if (isCurrentUserSameAsSelectedEngineer) {
      return false;
    }

    // for all other users, dates outside of Engineer Availability are disabled
    return !isTimeAvailableOnThisDate(
      selectedTime,
      getEngineerAvailabilityOnThisDate(date),
    );
  };
};
