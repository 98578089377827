import { Link } from "react-router-dom";
import { getNavButtonColor } from "../../../utils";
import "./NavDropDownListItem.css";

export enum NAV_LIST_ITEM_VARIANT {
  BUTTON = "button",
  LINK = "link",
}

export type NavDropDownListItemProps =
  | {
      text: string;
      variant: NAV_LIST_ITEM_VARIANT.LINK;
      path: string;
      currentPath: string;
      onClick?: () => void;
    }
  | {
      text: string;
      variant: NAV_LIST_ITEM_VARIANT.BUTTON;
      onClick?: () => void;
    };

export const NavDropDownListItem: React.FC<NavDropDownListItemProps> = (
  props: NavDropDownListItemProps,
) => {
  const { variant, text } = props;

  return variant === NAV_LIST_ITEM_VARIANT.LINK ? (
    <li className="nav-list-item-container">
      <Link
        className="nav-list-item-link"
        to={{ pathname: props.path }}
        onClick={props.onClick}
        target={props.path.match(/^http/) ? "_blank" : undefined}
        rel={props.path.match(/^http/) ? "noreferrer" : undefined}
        style={{
          color: getNavButtonColor([props.path], props.currentPath),
        }}
      >
        {text}
      </Link>
    </li>
  ) : (
    <li>
      <button className="nav-list-item-link" onClick={props.onClick}>
        {text}
      </button>
    </li>
  );
};
