import React from "react";
import "./FullWidthHeader.css";
import { ColorPalette } from "../../theme";

export interface FullWidthHeaderProps {
  title: string;
  heading: string;
  subheading?: string;
  color: "orange" | "blue";
  backgroundColor?: ColorPalette;
}

export const FullWidthHeader = ({
  title,
  heading,
  subheading,
  color = "orange",
  backgroundColor = ColorPalette.White,
}: FullWidthHeaderProps) => {
  const highlight =
    color === "blue" ? ColorPalette.DeepBlue300 : ColorPalette.BoomyOrange400;

  return (
    <div className="full-width-header-container" style={{ backgroundColor }}>
      <div className="full-width-header-item">
        <p className="label-semi-bold" style={{ color: highlight }}>
          {title}
        </p>
        <p className="h3">{heading}</p>
        <p className="h7">{subheading}</p>
      </div>
    </div>
  );
};
