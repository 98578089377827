import { useEffect } from "react";
import { getPurchaseOrderStatus } from "../../../store/actions/scheduledProjectPurchaseOrders";
import { PendingPurchaseOrderApprover } from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { BudgetManager } from "../../../store/models/project";
import { ScheduledProject } from "../../../store/models/scheduledproject";
import { TransactionStatus } from "../../../store/models/transaction";
import { ColorPalette } from "../../theme";
import { ManageBudgetApproversView } from "../ManageBudgetApproversView/ManageBudgetApprovers";
import { PurchaseOrderRequestView } from "../PurchaseOrderRequestView/PurchaseOrderRequestView";
import "./ScheduledProjectBudgetManagerView.css";

export const BudgetApproverLegend = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div className="budget-approver-legend">
        <div
          className="circle-icon-small"
          style={{ backgroundColor: ColorPalette.SkylightBlue }}
        />
        <p className="b2 mx-2">approval required</p>
      </div>
      <div className="budget-approver-legend">
        <div
          className="circle-icon-small"
          style={{ backgroundColor: ColorPalette.LightGray }}
        />
        <p className="b2 mx-2">approval optional</p>
      </div>
    </div>
  );
};

export interface ScheduledProjectBudgetManagerViewProps {
  fetching: boolean;
  scheduledProject: ScheduledProject;
  onPurchaseOrderSubmitted?: () => void;
}

export const ScheduledProjectBudgetManagerView = ({
  scheduledProject,
  fetching,
  onPurchaseOrderSubmitted,
}: ScheduledProjectBudgetManagerViewProps) => {
  const dispatch = useAppDispatch();
  const scheduledProjectId = scheduledProject.id;
  const purchaseOrders = useAppSelector(
    (state) => state.scheduledProjectPurchaseOrdersSlice[scheduledProjectId],
  );

  const approvalRequired = purchaseOrders?.find(
    (purchaseOrder) => purchaseOrder.budget_approved === false,
  );

  const billingInfoRequired = purchaseOrders?.find(
    (purchaseOrder) =>
      purchaseOrder.purchase_order_status ===
      TransactionStatus.PURCHASE_ORDER_REQUIRED,
  );

  const budgetRejected = purchaseOrders?.find(
    (purchaseOrder) => purchaseOrder.budget_rejected === true,
  );

  useEffect(() => {
    dispatch(
      getPurchaseOrderStatus({
        scheduled_project_id: scheduledProjectId,
      }),
    );
  }, [scheduledProjectId, dispatch]);

  if (fetching) {
    return (
      <div>
        <p>loading...</p>
      </div>
    );
  }

  if (budgetRejected) {
    return (
      <div>
        <p>Your projects budget has been rejected.</p>
        {budgetRejected.budget_managers
          .filter((budgetManger) => budgetManger.budget_rejected)
          .map((budgetManager: BudgetManager) => {
            return (
              <div className={"my-2"} key={budgetManager.code}>
                <p className={"b1-semi-bold"}>{budgetManager.email}</p>
                <p className={"b2"}>
                  <strong>status: </strong>
                  {budgetManager.budget_rejected ? "rejected" : "pending"}
                </p>
                <p className={"b2"}>
                  <strong>reason: </strong>
                  {budgetManager.rejection_reason ?? "N/A"}
                </p>
              </div>
            );
          })}
      </div>
    );
  }

  if (approvalRequired) {
    return (
      <ManageBudgetApproversView
        billingInfoRequired={false}
        approvalRequired={approvalRequired.budget_approved === false}
        scheduledProjectId={scheduledProjectId}
        purchaseOrderId={approvalRequired.id}
        budgetSubmitterEmails={approvalRequired.budget_managers
          .filter((budgetManager) => budgetManager.can_submit_billing_info)
          .map((budgetManager) => budgetManager.email)}
        budgetApprovers={approvalRequired.budget_managers
          .filter((budgetManager) => budgetManager.can_approve_budget)
          .reduce((acc, budgetManager) => {
            const manager = {
              email: budgetManager.email,
              approval_required: budgetManager.approval_required,
            };
            return [...acc, manager];
          }, [] as PendingPurchaseOrderApprover[])}
      >
        {approvalRequired.budget_managers
          .filter((budgetManager) => budgetManager.can_approve_budget)
          .map((budgetManager: BudgetManager) => {
            return (
              <div className={"my-2"} key={budgetManager.code}>
                <p className={"b1-semi-bold"}>{budgetManager.email}</p>
                <p className={"b2"}>
                  <strong>budget status: </strong>
                  {budgetManager.budget_approved
                    ? "approved"
                    : "pending approval"}
                </p>
              </div>
            );
          })}
        <BudgetApproverLegend />
      </ManageBudgetApproversView>
    );
  }
  if (billingInfoRequired) {
    return (
      <ManageBudgetApproversView
        billingInfoRequired={billingInfoRequired.budget_approved === true}
        approvalRequired={false}
        scheduledProjectId={scheduledProjectId}
        purchaseOrderId={billingInfoRequired.id}
        budgetSubmitterEmails={billingInfoRequired.budget_managers
          .filter((budgetManager) => budgetManager.can_submit_billing_info)
          .map((budgetManager) => budgetManager.email)}
        budgetApprovers={billingInfoRequired.budget_managers
          .filter((budgetManager) => budgetManager.can_approve_budget)
          .reduce((acc, budgetManager) => {
            const manager = {
              email: budgetManager.email,
              approval_required: budgetManager.approval_required,
            };
            return [...acc, manager];
          }, [] as PendingPurchaseOrderApprover[])}
      >
        <PurchaseOrderRequestView
          purchaseOrder={billingInfoRequired}
          scheduledProject={scheduledProject}
          onUpdate={() => {
            if (onPurchaseOrderSubmitted) {
              onPurchaseOrderSubmitted();
            }
          }}
        />
      </ManageBudgetApproversView>
    );
  }

  return (
    <div>
      <p>
        Your project budget has been approved and your billing info has been
        submitted. Once all files have been submitted and marked as QC approved,
        the project will be complete.
      </p>
    </div>
  );
};
