import { Box, styled } from "@mui/material";

export const StyledList = styled("ul")({
  listStyle: "none",
  paddingLeft: 0,
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
  marginTop: "24px",
});

export const EditServiceCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  columnGap: "32px",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    rowGap: "32px",
    alignItems: "center",
  },
}));
