import React, { useCallback, useEffect, useState } from "react";
import {
  FileCommentContainer,
  FileCommentForm,
  FileCommentHeader,
  FileCommentHeaderSort,
  FileCommentHeaderText,
  FileCommentInput,
  FileCommentTextField,
  FileCommentTimeIndicatorText,
  FileCommentTimeInput,
  FileCommentTimeStamps,
} from "./TrackTableComments.styles";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  createFileComment,
  fetchFileComments,
  updateFileComment,
} from "../../../store/actions/fileVersionComments";
import {
  convertSecondsToTimeFormat,
  convertTimeFormatToSeconds,
} from "../../../store/utils/utils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { FileVersionComment } from "../../../store/models/fileVersion";
import { resetSelectCommentState } from "../../../store/actions/selectedComment";
import {
  generateRegionIdentifier,
  REGION_IDENTIFIER_LENGTH,
} from "../../../store/utils/trackComments";
import { getTrackComments } from "../../../store/selectors/trackComments";
import { TrackTableSortDropdown } from "./TrackTableSortDropdown";
import { TrackTableAddButton } from "./TrackTableAddButton";

export interface TrackTableCommentFormProps {
  selectedTrackDuration: number | undefined;
  projectId: number;
}

export const TrackTableCommentForm = ({
  selectedTrackDuration,
  projectId,
}: TrackTableCommentFormProps) => {
  const dispatch = useAppDispatch();

  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedComment, setSelectedComment] = useState<
    FileVersionComment | undefined
  >(undefined);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(5);
  const [startTimeString, setStartTimeString] = useState("0:00");
  const [endTimeString, setEndTimeString] = useState("0:05");

  const DEFAULT_DURATION_LENGTH = 5;

  const query = useQueryParam("code");
  const code = query.get();

  const user = useAppSelector((state) => state.accountInfo.user);
  const unauthenticatedUserName = useAppSelector(
    (state) => state.unauthenticatedUserStateSlice.name,
  );
  const { currentPosition, selectedTrack } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const { page } = useAppSelector((state) => state.fileVersionCommentsSlice);
  const comments = useAppSelector(getTrackComments(page, projectId));
  const selectedRegionComment = useAppSelector(
    (state) => state.selectedComment,
  );

  const isCommentButtonDisabled = (): boolean => {
    return (
      !comment ||
      startTime === null ||
      endTime === null ||
      !selectedTrackDuration ||
      loading
    );
  };

  const resetStates = () => {
    setComment("");
    setStartTime(0);
    setStartTimeString("0:00");
    setEndTime(DEFAULT_DURATION_LENGTH);
    setEndTimeString("0:05");
    setSelectedComment(undefined);
  };

  const handleCreate = useCallback(
    (guestUsername?: string) => {
      if (!selectedTrack) return;
      if (startTime === null) return;
      if (endTime === null) return;
      if (!comment) return;
      setLoading(true);
      emitAnalyticsTrackingEvent(
        "created_file_comment",
        {
          unauthenticated_username: `${unauthenticatedUserName}`,
        },
        user?.id,
      );

      if (typeof guestUsername === "object" && guestUsername !== null) {
        guestUsername = undefined;
      }

      void dispatch(
        createFileComment({
          comment: comment,
          start_timestamp_in_seconds: startTime,
          end_timestamp_in_seconds: endTime,
          unique_css_identifier: `region-${generateRegionIdentifier(REGION_IDENTIFIER_LENGTH)}`,
          file_version_id: selectedTrack.id,
          author_name: guestUsername ? guestUsername : unauthenticatedUserName,
          code: code ? code : undefined,
          project_id: projectId,
        }),
      )
        .unwrap()
        .then(() => {
          void dispatch(fetchFileComments({ projectId, code }));
        })
        .finally(() => {
          setLoading(false);
          setComment("");
        });
    },
    [
      code,
      comment,
      dispatch,
      startTime,
      endTime,
      selectedTrack,
      unauthenticatedUserName,
      user,
    ],
  );

  const handleUpdate = useCallback(() => {
    if (!selectedComment) return;
    setLoading(true);
    emitAnalyticsTrackingEvent(
      "updated_file_comment",
      {
        unauthenticated_username: `${unauthenticatedUserName}`,
      },
      user?.id,
    );

    void dispatch(
      updateFileComment({
        file_version_comment_id: selectedComment.id,
        comment: comment,
        start_timestamp_in_seconds: startTime,
        end_timestamp_in_seconds: endTime,
        file_version_id: selectedComment.file_version_id,
        unique_css_identifier: selectedComment.unique_css_identifier,
        author_name: unauthenticatedUserName,
        code: code ? code : undefined,
      }),
    )
      .unwrap()
      .then(() => {
        void dispatch(fetchFileComments({ projectId, code }));
      })
      .finally(() => {
        dispatch(resetSelectCommentState());
        resetStates();
        setLoading(false);
      });
  }, [
    code,
    comment,
    selectedComment,
    dispatch,
    startTime,
    endTime,
    selectedTrack,
    unauthenticatedUserName,
    user,
  ]);

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleStartTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setStartTimeString(event.target.value);
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndTimeString(event.target.value);
  };

  const setStartAndEndTimes = (start: number, end: number) => {
    setStartTime(start);
    setStartTimeString(convertSecondsToTimeFormat(start));
    setEndTime(end);
    setEndTimeString(convertSecondsToTimeFormat(end));
  };

  const onBlurStartTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const duration = Math.floor(
      selectedTrackDuration ?? DEFAULT_DURATION_LENGTH,
    );
    const inputtedValue = event.target.value;
    const inputtedValueInSeconds = Math.max(
      0,
      convertTimeFormatToSeconds(inputtedValue),
    );
    const formattedTime = convertSecondsToTimeFormat(inputtedValueInSeconds);

    // Handle when the user inputs a time greater than song duration
    if (inputtedValueInSeconds >= duration) {
      setStartAndEndTimes(duration - DEFAULT_DURATION_LENGTH, duration);
      return;
    }

    // Handle when the user inputs a time greater than current end timestamp
    if (inputtedValueInSeconds > endTime) {
      // Since we are going to set the end time to be 5 seconds after the start time
      // We need to check if the new end time we are going to set is greater than the song duration
      if (inputtedValueInSeconds + DEFAULT_DURATION_LENGTH > duration) {
        setStartAndEndTimes(inputtedValueInSeconds, duration);
        return;
      }
      setStartAndEndTimes(
        inputtedValueInSeconds,
        inputtedValueInSeconds + DEFAULT_DURATION_LENGTH,
      );
      return;
    }

    // No issues with the inputted value
    setStartTime(inputtedValueInSeconds);
    setStartTimeString(formattedTime);
  };

  const onBlurEndTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const duration = Math.floor(
      selectedTrackDuration ?? DEFAULT_DURATION_LENGTH,
    );
    const inputtedValue = event.target.value;
    const inputtedValueInSeconds = Math.max(
      0,
      convertTimeFormatToSeconds(inputtedValue),
    );
    const formattedTime = convertSecondsToTimeFormat(inputtedValueInSeconds);

    // Handle when the user inputs an end time greater than song duration
    if (inputtedValueInSeconds > duration) {
      setStartAndEndTimes(startTime, duration);
      return;
    }

    // Handle when the user inputs an end time less than the start time
    if (inputtedValueInSeconds < startTime) {
      // Since we are going to set the start time to be 5 seconds before the end time
      // We need to check if the new start time we are going to set is less than 0
      if (inputtedValueInSeconds - DEFAULT_DURATION_LENGTH < 0) {
        setStartAndEndTimes(0, inputtedValueInSeconds);
        return;
      }
      setStartAndEndTimes(
        inputtedValueInSeconds - DEFAULT_DURATION_LENGTH,
        inputtedValueInSeconds,
      );
      return;
    }

    // No issues with the inputted value
    setEndTime(inputtedValueInSeconds);
    setEndTimeString(formattedTime);
  };

  useEffect(() => {
    if (!selectedRegionComment.commentIdentifier) {
      resetStates();
      return;
    } else if (comments && !selectedRegionComment.show) {
      const comment = comments.find(
        (comment) =>
          comment?.unique_css_identifier ===
          selectedRegionComment.commentIdentifier,
      );
      if (
        comment?.start_timestamp_in_seconds &&
        comment?.end_timestamp_in_seconds
      ) {
        setSelectedComment(comment);
        setComment(comment.comment);
        setStartAndEndTimes(
          comment.start_timestamp_in_seconds,
          comment.end_timestamp_in_seconds,
        );
      } else if (selectedRegionComment.start && selectedRegionComment.end) {
        setComment("");
        setStartAndEndTimes(
          selectedRegionComment.start,
          selectedRegionComment.end,
        );
      }
    }
  }, [selectedRegionComment, comments]);

  useEffect(() => {
    resetStates();
  }, [selectedTrack]);

  useEffect(() => {
    if (
      comment === "" &&
      !loading &&
      (!selectedRegionComment.commentIdentifier || selectedRegionComment.show)
    ) {
      setStartAndEndTimes(
        currentPosition,
        currentPosition + DEFAULT_DURATION_LENGTH,
      );
    }
  }, [comment, currentPosition, loading]);

  return (
    <FileCommentContainer>
      <FileCommentHeader>
        <FileCommentHeaderText>Track comments</FileCommentHeaderText>
        <FileCommentHeaderSort>
          <TrackTableSortDropdown />
        </FileCommentHeaderSort>
      </FileCommentHeader>
      <FileCommentInput>
        <FileCommentForm>
          <FileCommentTextField
            placeholder="Write a comment"
            onChange={handleCommentChange}
            value={comment}
            disabled={selectedRegionComment.isUpdating || loading}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter" && !isCommentButtonDisabled()) {
                event.preventDefault();
                handleCreate();
              }
            }}
          />
          <FileCommentTimeStamps>
            <FileCommentTimeIndicatorText>From:</FileCommentTimeIndicatorText>
            <FileCommentTimeInput
              placeholder="0:00"
              onChange={handleStartTimeChange}
              onBlur={onBlurStartTime}
              value={startTimeString}
              disabled={loading}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Enter" && !isCommentButtonDisabled()) {
                  event.preventDefault();
                  handleCreate();
                }
              }}
            />
            <FileCommentTimeIndicatorText>To:</FileCommentTimeIndicatorText>
            <FileCommentTimeInput
              placeholder="0:05"
              onChange={handleEndTimeChange}
              onBlur={onBlurEndTime}
              value={endTimeString}
              disabled={loading}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Enter" && !isCommentButtonDisabled()) {
                  event.preventDefault();
                  handleCreate();
                }
              }}
            />
          </FileCommentTimeStamps>
        </FileCommentForm>
        {TrackTableAddButton({
          isLoading: loading,
          isDisabled: isCommentButtonDisabled(),
          handleCreate: handleCreate,
          handleUpdate: handleUpdate,
          projectId: projectId,
        })}
      </FileCommentInput>
    </FileCommentContainer>
  );
};
