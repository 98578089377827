import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { createTeam } from "../../store/actions/team";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";

/**
 * Creates a new label team and adds the team to the redux store.
 *
 * @returns The newly created team.
 * @throws The error returned in the response body if the request fails.
 *
 * The mutation will also dispatch {@link addTeam} and {@link setSelectedTeam} actions.
 */
const useCreateTeamMutation = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  return useMutation({
    mutationKey: [QUERY_KEYS.CREATE_TEAM],
    mutationFn: async () => {
      emitAnalyticsTrackingEvent("create_label_team", {}, user?.id);
      return dispatch(createTeam()).unwrap();
    },
    retry: 0,
  });
};

export default useCreateTeamMutation;
