import React from "react";
import Modal from "react-modal";
import "./BaseModal.css";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { useAtomValue } from "jotai";

Modal.setAppElement(document.getElementById("root") ?? "root");

export interface BaseModalProps {
  modalIsOpen: boolean;
  closeModal?: () => void;
  label: string;
  children: React.ReactNode;
  hideCloseButton?: boolean;
  content?: React.CSSProperties;
}

export const defaultModalContent: React.CSSProperties = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  border: "none",
  backgroundColor: "rgba(109, 109, 109, 0)",
  overflow: "unset !important",
  position: "absolute",
};

/**
 * @deprecated
 * Please use `BaseModal` component from `/core-ui/components/BaseModal/BaseModal.tsx.
 */
export const BaseModal = ({
  modalIsOpen,
  closeModal,
  label,
  children,
  hideCloseButton = false,
  content = defaultModalContent,
}: BaseModalProps) => {
  const darkMode = useAtomValue(darkModeAtom);
  return (
    <Modal
      style={{
        content,
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: "var(--modal-index)",
          backgroundColor: "rgba(109, 109, 109, 0.75)",
        },
      }}
      isOpen={modalIsOpen}
      contentLabel={label}
    >
      <p
        data-theme={darkMode ? "dark" : ""}
        className={"exit-icon ".concat(hideCloseButton ? "hide" : "")}
        onClick={closeModal ?? (() => {})}
      >
        X
      </p>
      <div data-theme={darkMode ? "dark" : ""}>{children}</div>
    </Modal>
  );
};
