import { useEffect, useState } from "react";
import {
  getRecordingServiceAvailability,
  getRecordingServiceAvailabilityParams,
} from "../../store/actions/recording";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getCurrentDate } from "../../store/utils/dateTimeUtils";
import { getStartTimesForContiguousAvailableBlocks } from "../../store/utils/recordingUtils";

/**
 * A hook that returns several functions that are useful for getting availabilities for a given date.
 * The functions return an array of start times available for either a studio, engineer, or both for a given date.
 */
export const useGetAvailabilityOnThisDate = (
  studioDateMap: Map<string, string>,
  engineerDateMap: Map<string, string>,
  sessionDurationMinutes: number,
  fifteenMinuteIntervals?: number,
) => {
  // returns an array of startTimes for a given date
  const getStudioAvailabilityOnThisDate = (date: Date) =>
    getStartTimesForContiguousAvailableBlocks(
      date,
      studioDateMap,
      sessionDurationMinutes,
      fifteenMinuteIntervals,
    );

  // returns an array of startTimes for a given date
  const getEngineerAvailabilityOnThisDate = (date: Date) =>
    getStartTimesForContiguousAvailableBlocks(
      date,
      engineerDateMap,
      sessionDurationMinutes,
      fifteenMinuteIntervals,
    );

  // returns an array of startTimes that overlap with both studio and selected engineer
  const getOverLappingDateAvailabilities = (date: Date) => {
    const studioDateAvailability = getStudioAvailabilityOnThisDate(date);
    const engineerDateAvailability = getEngineerAvailabilityOnThisDate(date);

    if (!studioDateAvailability.length || !engineerDateAvailability.length) {
      return [];
    }

    // Check if any times overlap between selected engineer and studio availability
    return studioDateAvailability.filter((date) => {
      return engineerDateAvailability.some((engineerDate) => {
        return engineerDate.getTime() === date.getTime();
      });
    });
  };

  return {
    getStudioAvailability: getStudioAvailabilityOnThisDate,
    getEngineerAvailability: getEngineerAvailabilityOnThisDate,
    getOverlappingAvailability: getOverLappingDateAvailabilities,
  };
};

// We'll fetch the availability if it hasn't been cached
// If it has been cached, we re-fetch if we override it with `forceReFetch` props
export const useGetRecordingServiceAvailabilities = (
  engineerUserId?: number,
  studioRoomId?: number,
  forceRefetch = false,
) => {
  const dispatch = useAppDispatch();
  const [isLoadingEngAvailabilities, setIsLoadingEngAvailabilities] =
    useState<boolean>(false);
  const [isLoadingStudioAvailabilities, setIsLoadingStudioAvailabilities] =
    useState<boolean>(false);
  const engineerAvailabilities = useAppSelector(
    (state) => state.availability.engineer_user,
  );

  const engineerAvailability = engineerAvailabilities[engineerUserId ?? -1];

  const studioRoomAvailabilities = useAppSelector(
    (state) => state.availability.studio_room,
  );

  const studioRoomAvailability = studioRoomAvailabilities[studioRoomId ?? -1];

  const fetchRecordingServiceAvailabilities = async (
    engineerUserId?: number,
    studioRoomId?: number,
  ) => {
    const defaultRecordingServiceParams: getRecordingServiceAvailabilityParams =
      {
        timezoneShiftMinutes: new Date().getTimezoneOffset(),
        startDate: getCurrentDate(),
      };
    if (studioRoomId) defaultRecordingServiceParams.studioRoomId = studioRoomId;

    if (engineerUserId) defaultRecordingServiceParams.userId = engineerUserId;

    await dispatch(
      getRecordingServiceAvailability(defaultRecordingServiceParams),
    );
  };

  useEffect(() => {
    if (engineerUserId) {
      if (engineerAvailability && !forceRefetch) {
        return;
      }

      setIsLoadingEngAvailabilities(true);
      fetchRecordingServiceAvailabilities(engineerUserId)
        .catch(() => {})
        .finally(() => setIsLoadingEngAvailabilities(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engineerUserId]);

  useEffect(() => {
    if (studioRoomId) {
      if (studioRoomAvailability && !forceRefetch) {
        return;
      }

      fetchRecordingServiceAvailabilities(undefined, studioRoomId)
        .catch(() => {})
        .finally(() => setIsLoadingStudioAvailabilities(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioRoomId]);
  return {
    isLoadingEngAvailabilities,
    isLoadingStudioAvailabilities,
    engineerAvailability,
    studioRoomAvailability,
  };
};
