import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";
import { Button } from "../Button/Button";

interface ChipContainerProps {
  $onDelete?: boolean;
  $loading?: boolean;
}

export const ChipCommonStyles = css<ChipContainerProps>`
  padding: ${({ $onDelete }) => ($onDelete ? "5px 8px" : "5px 8px")};
  border-radius: ${({ theme }) => theme.border.radius.md};
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  font-size: ${({ theme }) => theme.textSizeSm};
  border: 1px solid transparent;
  cursor: ${({ $loading }) => ($loading ? "not-allowed" : "unset")};
`;

export const ChipContainer = styled.div<ChipContainerProps>`
  ${ChipCommonStyles}
  height: 16px;
  background: ${({ theme }) => theme.midgroundColor};
  color: ${({ theme }) => theme.textPrimaryColor};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
`;

export const ChipCloseIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme }) => theme.textSizeMd};
  background: inherit;
`;

export const ChipButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.midgroundColor};
  padding: 0.2em 0.3em;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background: ${({ theme }) => theme.buttonColorHover};
  }
  &:focus {
    outline: none;
  }
`;

export const ChipContainerOutlinedStyles = css<ChipContainerProps>`
  ${ChipCommonStyles};
  background-color: ${({ theme }) => theme.backgroundColor};
  border-color: ${({ theme }) => theme.separatorColor};
  font-weight: ${({ theme }) => theme.textWeightNormal};
  color: ${({ theme, $loading }) =>
    $loading ? theme.textTertiaryColor : theme.textPrimaryColor};

  &:hover {
    background-color: ${({ theme }) => theme.darkerMidgroundColor};
  }

  ${ChipButton} {
    background: inherit;
  }

  ${ChipCloseIcon} {
    font-size: ${({ theme }) => theme.textSizeXs};
    color: ${({ theme, $loading }) =>
      $loading ? theme.textTertiaryColor : theme.textPrimaryColor};
  }
`;

export const ChipContainerOutlined = styled.div<ChipContainerProps>`
  ${ChipContainerOutlinedStyles};
`;

export const ChipAsButton = styled(Button)<ChipContainerProps>`
  ${ChipContainerOutlinedStyles};
  min-height: unset;
  line-height: unset;
  cursor: pointer;
`;
