import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import { AuthFlows, AuthSteps, FLOW_STEPS } from "../../../constants/authSteps";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text, TEXT_COLOR } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { currentFlowAtom, currentSignUpStepAtom } from "./atoms";
import { AuthNavigationContainer } from "./AuthModal.styles";

export const AuthNavigation = () => {
  const theme = useTheme();
  const step = useAtomValue(currentSignUpStepAtom);
  const flow = useAtomValue(currentFlowAtom);
  const authNavigation = useAuthNavigationSteps();
  const { prevStep } = authNavigation();
  const groupIndex = FLOW_STEPS[flow].steps.findIndex((group) =>
    group.includes(step),
  );
  const currentStep = groupIndex + 1;
  const totalSteps = FLOW_STEPS[flow].totalSteps;

  return flow === AuthFlows.LOGIN ? (
    <Box />
  ) : (
    <AuthNavigationContainer>
      <Box>
        {currentStep > 1 && (
          <Button variant={ButtonVariant.UNSTYLED} onClick={() => prevStep()}>
            <FontAwesomeIcon
              style={{
                marginRight: "8px",
                color: theme.palette.text.secondary,
              }}
              icon={faChevronLeft}
            />
            <Text variant={TextStyleVariant.P1} color={TEXT_COLOR.SECONDARY}>
              Back
            </Text>
          </Button>
        )}
      </Box>
      <Box>
        {step === AuthSteps.LOGIN || step === AuthSteps.EMAIL_SIGNUP ? null : (
          <Text variant={TextStyleVariant.P1} color={TEXT_COLOR.SECONDARY}>
            Step {currentStep} out of {totalSteps}
          </Text>
        )}
      </Box>
    </AuthNavigationContainer>
  );
};
