import {
  DetailAndStatusRow,
  DetailsAndBudgetRow,
  VerticalSeparator,
} from "./ProjectHeader.styles";
import DetailsBlockComponent from "./DetailsBlockComponent";
import StatusBlockComponent from "./StatusBlockComponent";
import ProgressBlockComponent from "./ProgressBlockComponent";
import BudgetBlockComponent from "./BudgetBlockComponent";
import { ScheduledProject } from "../../../../store/models/scheduledproject";
import { DetailedPurchaseOrder } from "../../../../store/models/project";

interface ProjectContentProps {
  scheduledProject: ScheduledProject;
  purchaseOrder?: DetailedPurchaseOrder;
  isProjectCancelled: boolean;
  isInProgressProject: boolean;
  isPartiallyPaid: boolean;
  code?: string;
  disableDownloadAndSharingActions: boolean;
  userIsCollaborator: boolean;
  userIsArtistOnScheduledProject: boolean;
  serviceTypeSet: Set<string>;
  numberOfCompletedTracks: number;
  completedTracksPercentage: number;
  estimatedCompletionDate: string;
  projectDuration: string;
  isTablet: boolean;
}

const ProjectHeaderContent = ({
  scheduledProject,
  purchaseOrder,
  isProjectCancelled,
  isInProgressProject,
  isPartiallyPaid,
  code,
  disableDownloadAndSharingActions,
  userIsCollaborator,
  userIsArtistOnScheduledProject,
  serviceTypeSet,
  numberOfCompletedTracks,
  completedTracksPercentage,
  estimatedCompletionDate,
  projectDuration,
  isTablet,
}: ProjectContentProps) => {
  return (
    <>
      {isTablet ? (
        <DetailAndStatusRow
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DetailsBlockComponent
            isProjectCancelled={isProjectCancelled}
            scheduledProject={scheduledProject}
            userIsCollaborator={userIsCollaborator}
          />
          <StatusBlockComponent
            userIsArtistOnScheduledProject={userIsArtistOnScheduledProject}
            scheduledProject={scheduledProject}
            isProjectCancelled={isProjectCancelled}
            isInProgressProject={isInProgressProject}
            isPartiallyPaid={isPartiallyPaid}
            code={code}
            disableDownloadAndSharingActions={disableDownloadAndSharingActions}
          />
        </DetailAndStatusRow>
      ) : (
        <DetailsBlockComponent
          isProjectCancelled={isProjectCancelled}
          scheduledProject={scheduledProject}
          userIsCollaborator={userIsCollaborator}
        />
      )}
      <DetailsAndBudgetRow>
        <ProgressBlockComponent
          serviceTypeSet={serviceTypeSet}
          numberOfCompletedTracks={numberOfCompletedTracks}
          totalTracks={scheduledProject.projects.length}
          refunded={Boolean(scheduledProject.refunded)}
          completedTracksPercentage={completedTracksPercentage}
          estimatedCompletionDate={estimatedCompletionDate}
          projectDuration={projectDuration}
        />
        {purchaseOrder && (
          <>
            <VerticalSeparator />
            <BudgetBlockComponent purchaseOrder={purchaseOrder} />
          </>
        )}
      </DetailsAndBudgetRow>

      {!isTablet && (
        <StatusBlockComponent
          userIsArtistOnScheduledProject={userIsArtistOnScheduledProject}
          scheduledProject={scheduledProject}
          isProjectCancelled={isProjectCancelled}
          isInProgressProject={isInProgressProject}
          isPartiallyPaid={isPartiallyPaid}
          code={code}
          disableDownloadAndSharingActions={disableDownloadAndSharingActions}
        />
      )}
    </>
  );
};

export default ProjectHeaderContent;
