import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { DevLinkProvider, EngineEarsFooter2024 } from "../../../devlink";
import { useGetFooterPlayerRef } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import {
  useIsInProgressProject,
  useProjectPaywallStatus,
} from "../../../hooks/partialPaymentHooks";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import {
  FooterFileTrackType,
  setIndex,
  setPlaylist,
} from "../../../store/actions/abPlayerStore";
import { clearFileVersionDownload } from "../../../store/actions/fileVersions";
import { getPurchaseOrderStatus } from "../../../store/actions/scheduledProjectPurchaseOrders";
import {
  resetStore,
  toggleEditing,
  toggleUpdating,
} from "../../../store/actions/scheduledProjectUpdateStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  Project,
  ProjectType,
  tableServiceTypeReadableName,
} from "../../../store/models/project";
import { ScheduledProject } from "../../../store/models/scheduledproject";
import { selectPendingScheduledProjectUpdates } from "../../../store/selectors/scheduledProjectSelector";
import { selectIsUserAandR } from "../../../store/selectors/userInfoSelectors";
import { getMyBookingsRoute } from "../../../store/utils/routeGetters";
import { AssetReviewModal } from "../../components/AssetReviewModal/AssetReviewModal";
import { NewProjectsTable } from "../../components/ProjectOverviewTable/NewProjectsTable";
import { ProjectHeader } from "../../components/ProjectOverviewTable/ProjectHeader/ProjectHeader";
import { RedirectToPaymentModal } from "../../components/RedirectToPayment/RedirectToPayment";
import { ScheduledProjectBudgetManagerView } from "../../components/ScheduledProjectBudgetManagerView/ScheduledProjectBudgetManagerView";
import { ScheduledProjectBudgetManagerContainer } from "../../components/ScheduledProjectBudgetManagerView/ScheduledProjectBudgetManagerView.styles";
import { hasUserAcceptedProject } from "../../components/ScheduledProjectTable/utils";
import { DefaultSoundWaveLoader } from "../../elements/DefaultSoundWaveLoader/DefaultSoundWaveLoader";
import { BookingQueryParamKeys } from "../BookingsScreen/hooks";
import { useProjectOverViewPurchaseOrder } from "./hooks";
import "./ProjectOverviewScreen.css";
import { ProjectOverviewTabs } from "./ProjectOverviewTabs";
import { convertProjectsToPlayListTracks } from "../../../store/models/playListTrack";

export interface ProjectOverviewScreenProps {
  scheduledProject: ScheduledProject | null;
  shareLinkCode?: string;
}

export const ProjectOverviewScreen = ({
  scheduledProject,
  shareLinkCode,
}: ProjectOverviewScreenProps) => {
  const { isMobile } = useMediaQueryBreakpoint();
  const purchaseOrder = useProjectOverViewPurchaseOrder(scheduledProject?.id);
  const [showPaymentRedirectModal, setShowPaymentRedirectModal] =
    useState(false);
  const isUserAandR = useAppSelector(selectIsUserAandR);
  const totalPendingUpdates = useAppSelector(
    selectPendingScheduledProjectUpdates,
  );
  const { isInProgressProject } = useIsInProgressProject(scheduledProject);
  const { allowTrackPreview } = useProjectPaywallStatus(scheduledProject);
  const isPartiallyPaidProject = Boolean(
    scheduledProject && scheduledProject?.outstanding_balance > 0,
  );
  const isLabelProject = Boolean(scheduledProject?.is_label_project);
  const [selectedTab, setSelectedTab] = useState(0);
  const [fetching, setFetching] = useState(false);
  const isUpdating = useAppSelector(
    (state) => state.scheduledProjectUpdateStore.isUpdating,
  );
  const contentContainer = useRef<HTMLDivElement>(null);
  const footerPlayerRef = useGetFooterPlayerRef();
  const { playlistId } = useAppSelector((state) => state.abPlayerStore);
  const showBudgetTab = useMemo(() => {
    return (
      isLabelProject &&
      isUserAandR &&
      purchaseOrder &&
      scheduledProject &&
      !scheduledProject.refunded
    );
  }, [isLabelProject, isUserAandR, purchaseOrder, scheduledProject]);

  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const dataByServiceType = useMemo(() => {
    const projects = scheduledProject?.projects;
    if (!projects) {
      return {};
    }
    return projects.reduce(
      (acc, project) => {
        const { service_type: serviceType } = project;
        const isMixing = [
          ProjectType.MIXING,
          ProjectType.TWO_TRACK_MIXING,
        ].includes(serviceType);

        if (isMixing && !acc[ProjectType.MIXING]) {
          acc[ProjectType.MIXING] = [];
        } else if (!acc[serviceType]) {
          acc[serviceType] = [];
        }

        if (isMixing) {
          acc[ProjectType.MIXING].push(project);
        } else {
          acc[serviceType].push(project);
        }
        return acc;
      },
      {} as Record<ProjectType, Project[]>,
    );
  }, [scheduledProject?.projects]);

  const headers = useMemo(() => {
    const array = Object.keys(dataByServiceType);
    const tempHeaders = array.map((serviceType) =>
      tableServiceTypeReadableName.get(+serviceType),
    );
    if (showBudgetTab) {
      return tempHeaders.concat(["Budget"]);
    }
    return tempHeaders;
  }, [dataByServiceType, showBudgetTab]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isUpdating && totalPendingUpdates === 0) {
      dispatch(toggleUpdating());
      dispatch(toggleEditing());
    }
  }, [isUpdating, totalPendingUpdates, dispatch]);

  useEffect(() => {
    dispatch(resetStore());
    return () => {
      dispatch(resetStore());
    };
  }, [dispatch]);

  // Because we pass it down to the child component
  // This must be memoized to preserve its identity
  const handlePaymentFlow = useCallback(() => {
    setShowPaymentRedirectModal(true);
  }, []);

  useEffect(() => {
    if (!isUserAandR) return;
    if (!scheduledProject?.id) return;
    void dispatch(
      getPurchaseOrderStatus({
        scheduled_project_id: scheduledProject?.id,
      }),
    );
  }, [dispatch, scheduledProject?.id, isUserAandR]);

  if (!scheduledProject) {
    return <DefaultSoundWaveLoader />;
  }

  const handlePlayMusic = (index: number) => {
    if (footerPlayerRef.current) footerPlayerRef.current.pause();
    dispatch(clearFileVersionDownload({ fileVersionId: null }));
    if (playlistId === scheduledProject.id) {
      dispatch(setIndex(index));
      return;
    }
    const tracks = convertProjectsToPlayListTracks(
      Object.values(dataByServiceType)[selectedTab] as Project[],
    );
    dispatch(
      setPlaylist({
        playlistId: scheduledProject.id,
        tracks: tracks,
        index: index,
        playOnLoad: true,
        footerFileTrackType: FooterFileTrackType.SCHEDULED_PROJECT,
      }),
    );
  };

  // If the user hasn't accepted the scheduled project, redirect to the bookings
  // screen and open up the booking requested panel. Send the scheduled project ID
  // in the location state.
  const hasUserAccepted = hasUserAcceptedProject(
    scheduledProject.project_acceptances,
    loggedInUser,
  );
  if (!shareLinkCode && !hasUserAccepted && !scheduledProject.refunded) {
    return (
      <Redirect
        to={getMyBookingsRoute("projects", {
          [BookingQueryParamKeys.ScheduledProjectId]: scheduledProject?.id,
        })}
      />
    );
  }

  return (
    <>
      <div ref={contentContainer} className="container fill">
        <ProjectHeader
          code={shareLinkCode}
          scheduledProject={scheduledProject}
          purchaseOrder={purchaseOrder}
        />
        <ProjectOverviewTabs
          contentContainer={contentContainer}
          headers={headers}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {headers[selectedTab] === "Budget" ? (
          <ScheduledProjectBudgetManagerContainer $isMobile={isMobile}>
            <ScheduledProjectBudgetManagerView
              scheduledProject={scheduledProject}
              fetching={fetching}
              onPurchaseOrderSubmitted={() => {
                setFetching(true);
                void dispatch(
                  getPurchaseOrderStatus({
                    scheduled_project_id: scheduledProject?.id,
                  }),
                ).finally(() => setFetching(false));
              }}
            />
          </ScheduledProjectBudgetManagerContainer>
        ) : (
          <NewProjectsTable
            data={
              (Object.values(dataByServiceType)[selectedTab] as Project[]) ?? []
            }
            scheduledProjectId={scheduledProject.id}
            code={shareLinkCode}
            handlePaymentFlow={handlePaymentFlow}
            handlePlayMusic={(index) => handlePlayMusic(index)}
            refunded={Boolean(scheduledProject.refunded)}
            allowTrackPreview={allowTrackPreview}
          />
        )}

        {shareLinkCode && <AssetReviewModal code={shareLinkCode} />}
        {(isInProgressProject || isPartiallyPaidProject) && (
          <RedirectToPaymentModal
            isPartiallyPaid={isPartiallyPaidProject}
            scheduledProjectId={scheduledProject.id}
            projectId={scheduledProject.projects[0].id}
            shareLink={shareLinkCode}
            modalIsOpen={showPaymentRedirectModal}
            setModalIsOpen={setShowPaymentRedirectModal}
            projectType={scheduledProject.projects[0].service_type}
            paywallOption={scheduledProject.default_paywall_option}
            totalPrice={
              scheduledProject.total_price + scheduledProject.fees_collected
            }
          />
        )}
      </div>
      <DevLinkProvider>
        <EngineEarsFooter2024 />
      </DevLinkProvider>
    </>
  );
};
