import { useEffect, useMemo, useState } from "react";
import Lottie from "react-lottie";
import { toast } from "react-toastify";
import { useQuery } from "../../../hooks/useQuery";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import {
  acceptTeamInvite,
  declineTeamInvite,
  getTeamInvite,
} from "../../../store/actions/team";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  AffiliationInviteForTeam,
  TEAM_ROLES,
} from "../../../store/models/admins";
import { UMGSubLabelMap } from "../../../store/models/trophy";
import {
  getDisplayableNameForStudio,
  getDisplayableNameForUser,
} from "../../../store/utils/entityUtils";
import { SignInModal } from "../../components/SignInModal/SignInModal";
import { Button } from "../../elements/Button/button";
import animationData from "../../lotties/success.json";
import "./TeamInviteScreen.css";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

export const defaultOptions = {
  loop: false,
  autoplay: false,
  duration: 1000,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const TeamInviteScreen = () => {
  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);
  const [inviteData, setInviteData] = useState<AffiliationInviteForTeam | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [declined, setDeclined] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const query = useQuery();
  const inviteId: string | null = query.get("invite_id");

  useSetPageTitle(undefined);

  const handleAcceptInvite = async () => {
    if (inviteData) {
      setLoading(true);
      await dispatch(
        acceptTeamInvite({
          invite_id: inviteData.id,
        }),
      )
        .then(() => {
          setAccepted(true);
        })
        .catch(() => {
          setAccepted(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const status = useMemo(() => {
    if (!inviteData) return "Pending";
    if (inviteData.accepted) {
      return "Accepted";
    }
    if (inviteData.declined) {
      return "Declined";
    }
    if (inviteData.revoked) {
      return "Revoked";
    }
    return "Pending";
  }, [inviteData]);

  const handleDeclineInvite = async () => {
    if (inviteData) {
      setLoading(true);
      await dispatch(
        declineTeamInvite({
          invite_id: inviteData.id,
        }),
      )
        .then(() => {
          setDeclined(true);
        })
        .catch(() => {
          setDeclined(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const headerName = useMemo(() => {
    if (!inviteData) {
      return "";
    }
    if (inviteData.label_team?.label) {
      return UMGSubLabelMap.get(inviteData.label_team.label.umg_sub_label)
        ?.text;
    }
    if (inviteData.studio) {
      return inviteData.studio.studio_profile?.display_name;
    }
    return "";
  }, [inviteData]);

  const displayName = useMemo(() => {
    if (!inviteData) {
      return "";
    }
    if (inviteData.inviter) {
      return getDisplayableNameForUser(inviteData.inviter);
    }
    if (inviteData.studio) {
      return getDisplayableNameForStudio(inviteData.studio);
    }
    return "";
  }, [inviteData]);

  const role = useMemo(() => {
    if (!inviteData) return "Undefined Role";
    return TEAM_ROLES[inviteData.role].label;
  }, [inviteData]);

  useEffect(() => {
    if (inviteId && isAuthenticated) {
      setInviteData(null);

      dispatch(getTeamInvite({ invite_id: +inviteId }))
        .unwrap()
        .then((value: AffiliationInviteForTeam) => {
          if (value.accepted || value.declined || value.revoked) {
            setActive(false);
          }
          setInviteData(value);
        })
        .catch(() => {
          setActive(false);
          toast.error("Error fetching team invite. Please try again later");
        });
    }
  }, [inviteId, dispatch, isAuthenticated]);

  if (!isAuthenticated) {
    return <SignInModal label={"Sign In"} />;
  }

  if (!inviteData) {
    return <LoadingScreen className="team-invite-container" />;
  }

  return (
    <div className="team-invite-container">
      {(accepted || declined) && (
        <div className={"success-lottie-container"}>
          <Lottie options={defaultOptions} height={150} width={150} />
          <p className="h6-semi-bold">
            {accepted ? "Accepted team invite!" : "Declined team invite"}
          </p>
        </div>
      )}
      {!accepted && !declined && (
        <>
          <div
            className="b2-semi-bold mb-4"
            style={{ textTransform: "uppercase", color: "var(--boomy-orange)" }}
          >
            {headerName}
          </div>
          <div className="h6-semi-bold mb-3">Teammates & Affiliates</div>
          <div className="mb-4">
            {`${displayName} wants to add you to their team as an ${role}.`}
          </div>
          {active && (
            <div className="team-invite-button-container">
              <Button
                label="Accept"
                primary
                gradient
                loading={loading}
                onClick={() => handleAcceptInvite()}
                disabled={accepted || declined || !active}
              />
              <Button
                label="Decline"
                loading={loading}
                onClick={() => handleDeclineInvite()}
                disabled={accepted || declined || !active}
              />
            </div>
          )}
          {!active && (
            <div>
              Team invite status: <strong>{status}</strong>
            </div>
          )}
        </>
      )}
    </div>
  );
};
