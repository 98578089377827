import {
  GoogleMap,
  Marker,
  Circle,
  useJsApiLoader,
  Libraries,
} from "@react-google-maps/api";
import { GOOGLE_API } from "../../../store/utils";
import {
  StyledGMapElementBoldTitle,
  StyledGMapElementContainer,
} from "./GMapElement.styles";
import { SoundWaveLoader } from "../SoundWaveLoader/SoundWaveLoader";
import { ColorPalette } from "../../theme";

const LIBRARIES: Libraries = ["places"];

interface APILoader {
  googleMapsApiKey: string;
  preventGoogleFontsLoading: boolean;
  libraries: Libraries;
}

const apiLoader: APILoader = {
  googleMapsApiKey: GOOGLE_API,
  preventGoogleFontsLoading: true,
  libraries: LIBRARIES,
};

export interface GMapElementProps {
  revealLocation: boolean;
  mapStyle?: React.CSSProperties;
  latitude: number | undefined;
  longitude: number | undefined;
  options?: google.maps.MapOptions;
}

const hideLocation = (lat: number, lng: number, revealLocation: boolean) => {
  if (revealLocation) {
    return {
      lat: lat,
      lng: lng,
    };
  }
  return {
    lat: lat + (Math.random() * 0.02 + -0.01),
    lng: lng + (Math.random() * 0.02 + -0.01),
  };
};

export const DEFAULT_MAP_STYLE: React.CSSProperties = {
  width: "100%",
  height: "400px",
  borderRadius: "10px",
};

export const GMapElement = ({
  revealLocation,
  mapStyle = DEFAULT_MAP_STYLE,
  latitude,
  longitude,
  options,
}: GMapElementProps) => {
  const { isLoaded } = useJsApiLoader(apiLoader);
  const stateCenter = {
    lat: latitude ?? 0,
    lng: longitude ?? 0,
  };
  if (!latitude || !longitude || !isLoaded) {
    return <SoundWaveLoader width={110} height={110} />;
  }
  if (!revealLocation) {
    const { lat, lng } = hideLocation(
      stateCenter.lat,
      stateCenter.lng,
      revealLocation,
    );
    stateCenter.lat = lat;
    stateCenter.lng = lng;
  }
  return (
    <StyledGMapElementContainer>
      <StyledGMapElementBoldTitle>Location</StyledGMapElementBoldTitle>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapStyle}
          center={stateCenter}
          zoom={11}
          options={options}
        >
          {revealLocation && <Marker position={stateCenter} zIndex={1} />}
          {!revealLocation && (
            <Circle
              center={stateCenter}
              radius={1000}
              options={{
                fillColor: ColorPalette.Blue300,
                strokeColor: ColorPalette.Blue300,
                strokePosition: google.maps.StrokePosition.CENTER,
                fillOpacity: 0.3,
              }}
            />
          )}
        </GoogleMap>
      )}
    </StyledGMapElementContainer>
  );
};
