import { ChangeEvent } from "react";
import classNames from "classnames";
import "./CheckBox.css";

export interface CheckBoxCircleProps {
  isDisabled?: boolean;
  isSelected: boolean;
  checkboxClassName?: string;
  checkmarkClassName?: string;
  componentStyling?: string;
  labelClassName?: string;
  toggle?: (_: ChangeEvent<HTMLInputElement>) => void;
}

export interface CheckBoxProps extends CheckBoxCircleProps {
  label: string;
}

export const CheckBoxCircle = ({
  isSelected,
  isDisabled,
  checkmarkClassName,
  componentStyling = "default",
  toggle,
}: CheckBoxCircleProps) => {
  const isDefault = componentStyling === "default";
  return (
    <>
      <input
        checked={isSelected}
        onChange={toggle}
        disabled={isDisabled}
        type="checkbox"
      />
      <span
        className={classNames("check-box-checkmark", checkmarkClassName, {
          "sign-up": !isDefault,
          "check-box-checkmark--disabled": isDisabled,
        })}
      />
    </>
  );
};

/**
 * It is recommended to use `CheckBox` in `/core-ui/components/CheckBox/CheckBox.tsx`.
 * This checkbox does not use the latest styles from Figma and the check icon is
 * imported into CSS as a background image rather than being rendered in JSX thereby
 * making it more difficult to customize the styling without a major refactor.
 */
export const CheckBox = ({
  checkboxClassName,
  componentStyling = "default",
  labelClassName = "",
  isDisabled = false,
  ...props
}: CheckBoxProps) => {
  const isDefault = componentStyling === "default";
  return (
    <label
      className={classNames(
        "check-box-container",
        "b2",
        "mt-2",
        checkboxClassName,
        { "check-box-container--disabled": isDisabled },
      )}
    >
      <CheckBoxCircle {...props} isDisabled={isDisabled} />
      {props.label && (
        <p
          className={classNames(
            isDefault ? "b1" : "b3",
            "checkbox-text-color",
            "mx-2",
            labelClassName,
          )}
        >
          {props.label}
        </p>
      )}
    </label>
  );
};
