import React from "react";
import { projectTypeReadableName } from "../../../store/models/project";
import Service from "../../../store/models/service";
import User from "../../../store/models/user";
import waveform from "../../assets/booking-screen-wave-form.svg";
import { UserProfileImage } from "../../elements/UserProfileImage/UserProfileImage";
import { ColorPalette } from "../../theme";
import "./CheckoutScreenHeader.css";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";

export interface CheckoutScreenHeaderProps {
  engineerUser: User;
  service?: Service;
  step?: string;
  children?: React.ReactNode;
}

export const CheckoutScreenHeader = ({
  engineerUser,
  children,
  ...props
}: CheckoutScreenHeaderProps) => {
  const darkMode = useAtomValue(darkModeAtom);
  return (
    <div
      data-theme={darkMode ? "dark" : ""}
      className="checkout-screen-header-container"
    >
      <div className="row justify-content-md-center">
        <div className="d-flex justify-content-center grid-spacing">
          {children}
          <img src={waveform} alt="Waveform" />
          <div style={{ height: 75, width: 75 }}>
            <UserProfileImage
              hideBorder={false}
              username={engineerUser?.username}
              source={engineerUser.photo?.path}
              width={66}
              height={66}
              isCircle={true}
            />
          </div>
          <img src={waveform} alt="waveform" />
        </div>
      </div>
      <div className="row my-4 justify-content-md-center">
        <div className="col-12 d-flex justify-content-center">
          {props.service && (
            <h1 className="checkout-header">
              {props.step} Your{" "}
              <span style={{ color: ColorPalette.BoomyOrange400 }}>
                {projectTypeReadableName.get(props.service.service_type)}
              </span>{" "}
              {"Project with "}
              {engineerUser.profile?.display_name}
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};
