import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useUploadedFileSinceLastTransition } from "../../../../hooks/useProjectFilesUploaded";
import {
  engMasteringTransition,
  engMixTransition,
} from "../../../../store/actions/projects";
import { useAppDispatch } from "../../../../store/hooks";
import {
  MasteringTransitions,
  MixingTransitions,
  ProjectById,
  ProjectType,
} from "../../../../store/models/project";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../core-ui/components/BasePopover/PopoverContainerContext";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { PopConfirm } from "../../../core-ui/components/PopConfirm/PopConfirm";
import { MainProjectWorkflowButtonRow } from "../../MainProjectWorkflowPanel/MainProjectWorkflowButtonRow";
import { TransferFileTransitionViewButtonFooter } from "../TransferFileTransitionView.styles";

export interface TransferFileEngineerButtonRowProps {
  project: ProjectById;
  projectType: ProjectType;
}

export const TransferFileEngineerButtonRow = ({
  project,
  projectType,
}: TransferFileEngineerButtonRowProps) => {
  const dispatch = useAppDispatch();
  const uploadedFiles = useUploadedFileSinceLastTransition(project);
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );
  const [isLoading, setIsLoading] = useState(false);

  const transferFiles = useCallback(async () => {
    // prevent forever loading state
    if (!project) {
      toast.error("Project not found");
      return;
    }
    setIsLoading(true);
    if (projectType === ProjectType.MASTERING) {
      await dispatch(
        engMasteringTransition({
          project_id: project.id.toString(),
          transition: MasteringTransitions.ENGINEER_UPLOAD_PROJECT_FILES,
        }),
      )
        .unwrap()
        .finally(() => setIsLoading(false));
    } else {
      await dispatch(
        engMixTransition({
          project_id: project.id.toString(),
          transition: MixingTransitions.ENGINEER_UPLOAD_PROJECT_FILES,
        }),
      )
        .unwrap()
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, project, projectType]);

  return (
    <MainProjectWorkflowButtonRow>
      <TransferFileTransitionViewButtonFooter>
        <PopConfirm
          side="top"
          title="Are you sure?"
          description="This will notify the client and move the project status to 'In Progress'."
          onConfirm={transferFiles}
          wrapperElement={containerElement}
        >
          <Button
            fullWidth
            disabled={!uploadedFiles.length || isLoading}
            loading={isLoading}
            variant={ButtonVariant.PRIMARY}
          >
            Transfer Files
          </Button>
        </PopConfirm>
      </TransferFileTransitionViewButtonFooter>
    </MainProjectWorkflowButtonRow>
  );
};
