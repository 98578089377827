import { fetchProductsByReleaseId } from "../productsAPI";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { EngineEarsError } from "../../../store/actions/errorStore";
import { Product } from "../models";

export const useFetchProductsByReleaseIdQuery = (releaseId = 0) => {
  return useQuery({
    initialData: [],
    enabled: Boolean(releaseId),
    queryKey: [
      QUERY_KEYS.FETCH_PRODUCTS_BY_RELEASE_ID,
      {
        releaseId,
      },
    ],
    queryFn: async () => {
      const response = await fetchProductsByReleaseId(releaseId);
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return response.resultJson as Product[];
    },
  });
};
