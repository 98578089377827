import {
  DetailHeaderContainer,
  DetailHeaderTitle,
  DetailHeaderSubtitle,
} from "./DetailHeader.styles";

export interface DetailHeaderProps {
  title: string;
  subtitle: string;
}

// Component used in the header section of the Order Summary Page
export const DetailHeader = ({ title, subtitle }: DetailHeaderProps) => {
  return (
    <DetailHeaderContainer>
      <DetailHeaderTitle>{title}</DetailHeaderTitle>
      <DetailHeaderSubtitle>{subtitle}</DetailHeaderSubtitle>
    </DetailHeaderContainer>
  );
};
