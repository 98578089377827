import { useEffect, useState } from "react";
import {
  ARTIST_MASTER_ACTION_STEPS,
  ARTIST_MIXING_ACTION_STEPS,
  ENGINEER_MASTER_ACTION_STEPS,
  ENGINEER_MIXING_ACTION_STEPS,
  MasteringProjectSteps,
  MixingProjectSteps,
  ProjectType,
} from "../store/models/project";

export const IsCurrentUserStep = (
  isCurrentEngineer: boolean,
  projectType: ProjectType,
  step: number,
): boolean => {
  if (projectType === ProjectType.MASTERING) {
    if (isCurrentEngineer) {
      return ENGINEER_MASTER_ACTION_STEPS.includes(
        step as MasteringProjectSteps,
      );
    } else {
      return ARTIST_MASTER_ACTION_STEPS.includes(step as MasteringProjectSteps);
    }
  } else {
    if (isCurrentEngineer) {
      return ENGINEER_MIXING_ACTION_STEPS.includes(step as MixingProjectSteps);
    } else {
      return ARTIST_MIXING_ACTION_STEPS.includes(step as MixingProjectSteps);
    }
  }
};

const useIsCurrentUserStep = (
  isCurrentEngineer: boolean,
  projectType: ProjectType | null,
  step: number,
): boolean => {
  const [isCurrentUserStep, setIsCurrentUserStep] = useState(false);

  useEffect(() => {
    if (projectType === null) {
      setIsCurrentUserStep(false);
    } else {
      setIsCurrentUserStep(
        IsCurrentUserStep(isCurrentEngineer, projectType, step),
      );
    }
  }, [isCurrentEngineer, projectType, step]);

  return isCurrentUserStep;
};

export default useIsCurrentUserStep;
