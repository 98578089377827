import {
  useCurrentProgressForPlaylistId,
  usePlayPauseOnFooter,
  useSetPlayList,
} from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { useAppSelector } from "../../../store/hooks";
import { useFetchReleaseTracksQuery } from "../../../api/releases/hooks/useFetchReleaseTracksQuery";
import { convertArtistReleaseToPlaylistTrack } from "../../../store/models/playListTrack";
import { PlayAudioButton } from "../PlayAudioButton/PlayAudioButton";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { useAtomValue } from "jotai";
import { Box } from "@mui/material";
import { FeaturedTrackPlayer } from "../FeaturedTrackPlayer/FeaturedTrackPlayer";
import { useMemo } from "react";
import { useDownloadTrackFromFileVersionId } from "../../../hooks/audioPlayerHooks/fetchTrack";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

import { useReleaseTrackPlayButtonPressed } from "../../../hooks/audioPlayerHooks/useReleaseTrackPlayPressed";
import { FooterFileTrackType } from "../../../store/actions/abPlayerStore";

interface ReleasePlayPauseButtonProps {
  releaseId: number;
  size?: number;
}

export const getPaddingForPlayPauseButton = (size = 0) => {
  // Magic but gets consistently good progress padding.
  return size > 6 ? size / 6 : 1;
};

export const ReleasePlayPauseButton = ({
  releaseId,
  size = 24,
}: ReleasePlayPauseButtonProps) => {
  const { data, isPending } = useFetchReleaseTracksQuery(releaseId);
  const { isFooterPlaying, playlistId } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const tracks = convertArtistReleaseToPlaylistTrack(data);
  const progress = useCurrentProgressForPlaylistId(releaseId);
  const { handleClick } = usePlayPauseOnFooter();
  // Magic but gets consistently good progress padding.
  const padding = getPaddingForPlayPauseButton(size);
  const setReleasePlaylist = useSetPlayList();

  return (
    <PlayAudioButton
      progress={progress}
      isPlaying={isFooterPlaying && playlistId === releaseId}
      handleClick={() => {
        if (isPending) {
          return;
        }
        if (releaseId !== playlistId) {
          setReleasePlaylist({
            tracks: tracks,
            index: 0,
            playOnLoad: true,
            playlistId: releaseId,
            footerFileTrackType: FooterFileTrackType.RELEASE,
          });
        } else {
          handleClick();
        }
      }}
      size={size}
      padding={padding}
    />
  );
};

interface ReleaseTrackPlayPauseButtonProps {
  releaseId: number;
  trackId: number;
  size?: number;
  invertColor?: boolean;
}

export const ReleaseTrackPlayPauseButton = ({
  releaseId,
  trackId,
  size = 24,
}: ReleaseTrackPlayPauseButtonProps) => {
  const darkMode = useAtomValue(darkModeAtom);
  const { isFooterPlaying, playlistId, currentTrackIndex } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const { data: releaseTracks, isPending } =
    useFetchReleaseTracksQuery(releaseId);
  const releaseTrackIndex = releaseTracks?.findIndex(
    (releaseTrack) => releaseTrack.id === trackId,
  );
  const isPlaying =
    isFooterPlaying &&
    currentTrackIndex === releaseTrackIndex &&
    releaseId == playlistId;
  const tracks = convertArtistReleaseToPlaylistTrack(releaseTracks);
  const track = tracks[releaseTrackIndex];
  const fileVersionId = useMemo(() => {
    return track?.fileVersionId;
  }, [track?.fileVersionId]);

  const { data: url, isFetchedAfterMount } =
    useDownloadTrackFromFileVersionId(fileVersionId);

  const { isMobile } = useMediaQueryBreakpoint();

  const handleButtonClick = useReleaseTrackPlayButtonPressed(
    releaseId,
    isPending,
    tracks,
    releaseTrackIndex,
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <PlayAudioButton
        invertColor={!darkMode}
        progress={0}
        isPlaying={isPlaying}
        handleClick={handleButtonClick}
        size={size}
        padding={0}
        isPending={!isFetchedAfterMount}
      />
      {url && isFetchedAfterMount && !isMobile && (
        <FeaturedTrackPlayer
          isSameProject={currentTrackIndex === releaseTrackIndex}
          id={trackId}
          source={url}
          isPlayingOnFooter={isPlaying}
          showTime={false}
        />
      )}
    </Box>
  );
};
