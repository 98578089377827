import { Box, BoxProps, styled } from "@mui/material";
import { ReactNode } from "react";

export interface BlurredContentProps extends Omit<BoxProps, "children"> {
  blurred?: boolean;
  children: ReactNode;
}

const BlurredContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "blurred",
})(({ unselectable }) => ({
  transition: "filter 0.3s ease-in-out",
  userSelect: unselectable === "on" ? "none" : "auto",
  ...(unselectable === "on"
    ? {
        opacity: 0.5,
        filter: "blur(5px)",
      }
    : {}),
}));
const BlurredContent = ({
  blurred,
  children,
  ...props
}: BlurredContentProps) => {
  return (
    <BlurredContentWrapper {...props} unselectable={blurred ? "on" : "off"}>
      {children}
    </BlurredContentWrapper>
  );
};
export default BlurredContent;
