import "./TransactionBookingServiceTypeDropdown.css";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  getProjectTypeOptionFromServiceType,
  ProjectType,
} from "../../../store/models/project";

import Service from "../../../store/models/service";
import { RecordingService } from "../../../store/models/recording";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import {
  changeServiceTypeAndId,
  setActiveStudioIds,
} from "../../../store/actions/generateBookingStore";
import { clearRecordingCart } from "../../../store/actions/recordingCartsStore";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";

interface TransactionBookingServiceTypeDropdownProps {
  eligibleServices: (Service | RecordingService)[];
}

const TransactionBookingServiceTypeDropdown = ({
  eligibleServices,
}: TransactionBookingServiceTypeDropdownProps) => {
  const dispatch = useAppDispatch();
  const { activeServiceType, activeTransactionId } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const options = eligibleServices.map(
    (service) => getProjectTypeOptionFromServiceType(service.service_type)!,
  );

  const selectedOption = activeServiceType
    ? getProjectTypeOptionFromServiceType(activeServiceType) ?? null
    : null;

  const onSelectOption = (option: OptionType) => {
    // This component will only be shown when recording is the active service type.
    // If recording is selected in the dropdown, do nothing.
    if (option.value === ProjectType.RECORDING) {
      return;
    }

    emitAnalyticsTrackingEvent(
      "booking_flow-click_service_type_dropdown",
      {
        transactionId: activeTransactionId,
        serviceType: option.value,
      },
      loggedInUser?.id,
    );

    const selectedService = eligibleServices.find(
      (service) => service.service_type === option.value,
    )!;
    dispatch(
      setActiveStudioIds({
        studioId: undefined,
        studioRoomId: undefined,
      }),
    );
    dispatch(clearRecordingCart());
    dispatch(
      changeServiceTypeAndId({
        serviceType: option.value,
        serviceId: selectedService.id!,
      }),
    );
  };

  const cssPrefix = "transaction-booking-service-type-dropdown_";
  return (
    <DropdownSelector
      options={options}
      placeholder="Select a service"
      onChange={onSelectOption}
      value={selectedOption}
      customClassNames={{
        selectContainer: `${cssPrefix}select-container`,
        labelText: `${cssPrefix}label-text`,
        arrowContainer: `${cssPrefix}arrow-container`,
        container: `${cssPrefix}container`,
      }}
    />
  );
};

export default TransactionBookingServiceTypeDropdown;
