import { createTheme } from "@mui/material";
import muiBreakpointsTheme from "./breakpoints";

const { breakpoints } = muiBreakpointsTheme;
const fontFamily = [
  "Roobert Woff 2",
  "Roobert",
  "source-code-pro",
  "Menlo",
  "Monaco",
  "Consolas",
  "Courier New",
].join(",");

/**
 * Typography using the breakpoints defined in MUIBreakpointsTheme
 */
const muiTypographyTheme = createTheme(muiBreakpointsTheme, {
  typography: {
    fontFamily,
    fontWeightBold: 600,
    h1: {
      fontFamily,
      fontSize: "4.625rem",
      fontWeight: 600,
      lineHeight: "4.875rem",
      letterSpacing: "-0.0925rem",
      [breakpoints.down("md")]: {
        fontSize: "3.5rem",
        lineHeight: "3.75rem",
        letterSpacing: "-0.07rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "2.5rem",
        lineHeight: "2.75rem",
        letterSpacing: "-0.05rem",
      },
    },
    h2: {
      fontFamily,
      fontSize: "3.5rem",
      fontWeight: 600,
      lineHeight: "3.75rem",
      letterSpacing: "-0.07rem",
      [breakpoints.down("md")]: {
        fontSize: "2.5rem",
        lineHeight: "2.75rem",
        letterSpacing: "-0.05rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "2.25rem",
        lineHeight: "2.5rem",
        letterSpacing: "-0.045rem",
      },
    },
    h3: {
      fontFamily,
      fontSize: "2.5rem",
      fontWeight: 600,
      lineHeight: "2.75rem",
      letterSpacing: "-0.05rem",
      [breakpoints.down("md")]: {
        fontSize: "2.25rem",
        lineHeight: "2.5rem",
        letterSpacing: "-0.045rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.875rem",
        lineHeight: "2.125rem",
        letterSpacing: "-0.0375rem",
      },
    },
    h4: {
      fontFamily,
      fontSize: "2.25rem",
      fontWeight: 600,
      lineHeight: "2.5rem",
      letterSpacing: "-0.045rem",
      [breakpoints.down("md")]: {
        fontSize: "1.875rem",
        lineHeight: "2.125rem",
        letterSpacing: "-0.0375rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.5rem",
        lineHeight: "1.75rem",
        letterSpacing: "-0.03rem",
      },
    },
    h5: {
      fontFamily,
      fontSize: "1.875rem",
      fontWeight: 600,
      lineHeight: "2.125rem",
      letterSpacing: "-0.0375rem",
      [breakpoints.down("md")]: {
        fontSize: "1.5rem",
        lineHeight: "1.75rem",
        letterSpacing: "-0.03rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.125rem",
        lineHeight: "1.375rem",
        letterSpacing: "-0.0225rem",
      },
    },
    h6: {
      fontFamily,
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: "1.75rem",
      letterSpacing: "-0.03rem",
      [breakpoints.down("md")]: {
        fontSize: "1.125rem",
        lineHeight: "1.375rem",
        letterSpacing: "-0.0225rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1rem",
        lineHeight: "1.25rem",
        letterSpacing: "-0.02rem",
      },
    },
    subtitle1: {
      fontFamily,
      fontSize: "1.875rem",
      fontWeight: 600,
      lineHeight: "2.125rem",
      letterSpacing: "-0.0375rem",
    },
    subtitle2: {
      fontFamily,
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: "1.75rem",
      letterSpacing: "-0.03rem",
    },
    subtitle2Regular: {
      fontFamily,
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: "1.75rem",
    },
    subtitle3: {
      fontFamily,
      fontSize: "1.125rem",
      fontWeight: 600,
      lineHeight: "1.375rem",
      letterSpacing: "-0.0225rem",
    },
    subtitle4: {
      fontFamily,
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.25rem",
      letterSpacing: "-0.02rem",
    },
    body1: {
      fontFamily,
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.25rem",
      letterSpacing: 0,
    },
    body2: {
      fontFamily,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.125rem",
      letterSpacing: 0,
    },
    body3: {
      fontFamily,
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "1rem",
    },
    body4: {
      fontFamily,
      fontSize: "0.625rem",
      fontWeight: 400,
      lineHeight: "0.875rem",
    },
    button: {
      fontFamily,
    },
    caption: {
      fontFamily,
    },
    overline: {
      fontFamily,
    },
  },
});

export default muiTypographyTheme;
