import styled from "styled-components";

export const StyledRecordingBreakdownContainer = styled.div`
  padding: 8px 0px 16px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: inherit;
  gap: 12px;

  a {
    color: inherit;
    text-decoration: inherit;
  }
`;
export const StyledRecordingBreakdownTitle = styled.p`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-family: Roobert;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.48px;
`;

export const StyledRecordingBreakdownRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const StyledRecordingBreakdownRowFlexStart = styled(
  StyledRecordingBreakdownRow,
)`
  justify-content: flex-start;
  gap: 4px;
`;
export const StyledRecordingBreakdownText = styled.p<{
  weight?: string;
  secondary?: boolean;
  textDecoration?: string;
}>`
  color: ${({ theme, secondary }) =>
    secondary ? theme.textSecondaryColor : theme.textPrimaryColor};
  font-family: "Roobert", sans-serif;
  font-size: ${({ theme }) => theme.textSizeSm};
  font-style: normal;
  font-weight: ${({ weight }) => (weight ? weight : "normal")};
  line-height: 1.25rem;
  text-decoration: ${({ textDecoration }) => textDecoration};
`;
