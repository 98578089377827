import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { initialState as initialUserSearchState } from "../actions/userSearch";
import { initialState as initialStudioRoomSearchState } from "../actions/studioRoomSearch";
import { AutoCompleteTypeEnum } from "../models/autocomplete";

export const selectUserSearch = (state: RootState) => state.userSearch;
export const selectStudioRoomSearch = (state: RootState) =>
  state.studioRoomSearch;

export const selectUserSearchFiltersApplied = createSelector(
  selectUserSearch,
  (userSearch) => {
    const {
      maxRate,
      minRate,
      genres,
      simpleBudgetSelected,
      autocompleteSuggestions,
      serviceTypes,
    } = userSearch;
    const {
      maxRate: initialMaxRate,
      minRate: initialMinRate,
      genres: initialGenres,
      simpleBudgetSelected: initialSimpleBudgetSelected,
      autocompleteSuggestions: initialAutocompleteSuggestions,
      serviceTypes: initialServiceTypes,
    } = initialUserSearchState;

    return (
      maxRate !== initialMaxRate ||
      minRate !== initialMinRate ||
      JSON.stringify([...genres].sort()) !==
        JSON.stringify([...initialGenres].sort()) ||
      JSON.stringify([...simpleBudgetSelected].sort()) !==
        JSON.stringify([...initialSimpleBudgetSelected].sort()) ||
      JSON.stringify(
        [...autocompleteSuggestions].sort((a, b) =>
          a.type.localeCompare(b.type),
        ),
      ) !==
        JSON.stringify(
          [...initialAutocompleteSuggestions].sort((a, b) =>
            a.type.localeCompare(b.type),
          ),
        ) ||
      JSON.stringify(
        [...serviceTypes].sort((a, b) =>
          a.toString().localeCompare(b.toString()),
        ),
      ) !==
        JSON.stringify(
          [...initialServiceTypes].sort((a, b) =>
            a.toString().localeCompare(b.toString()),
          ),
        )
    );
  },
);

export const selectStudioRoomSearchFiltersApplied = createSelector(
  selectStudioRoomSearch,
  selectUserSearch,
  (studioRoomSearch, userSearch) => {
    const {
      maxRate,
      minRate,
      selectedAmenities,
      daysAvailable,
      simpleBudgetSelected,
      maxDistance,
      durationMinutes,
      allowNoEngineer,
    } = studioRoomSearch;
    const {
      maxRate: initialMaxRate,
      minRate: initialMinRate,
      selectedAmenities: initialSelectedAmenities,
      daysAvailable: initialDaysAvailable,
      simpleBudgetSelected: initialSimpleBudgetSelected,
      maxDistance: initialMaxDistance,
      durationMinutes: initialDurationMinutes,
      allowNoEngineer: initialAllowNoEngineer,
    } = initialStudioRoomSearchState;
    const { autocompleteSuggestions } = userSearch;
    const { autocompleteSuggestions: initialAutocompleteSuggestions } =
      initialUserSearchState;

    return (
      maxRate !== initialMaxRate ||
      minRate !== initialMinRate ||
      JSON.stringify([...selectedAmenities].sort()) !==
        JSON.stringify([...initialSelectedAmenities].sort()) ||
      JSON.stringify([...daysAvailable].sort()) !==
        JSON.stringify([...initialDaysAvailable].sort()) ||
      JSON.stringify([...simpleBudgetSelected].sort()) !==
        JSON.stringify([...initialSimpleBudgetSelected].sort()) ||
      maxDistance !== initialMaxDistance ||
      durationMinutes !== initialDurationMinutes ||
      allowNoEngineer !== initialAllowNoEngineer ||
      JSON.stringify(
        autocompleteSuggestions
          .filter(
            (suggestion) =>
              suggestion.type !== AutoCompleteTypeEnum.ARTIST_CREDIT,
          )
          .sort((a, b) => a.type.localeCompare(b.type)),
      ) !==
        JSON.stringify(
          initialAutocompleteSuggestions
            .filter(
              (suggestion) =>
                suggestion.type !== AutoCompleteTypeEnum.ARTIST_CREDIT,
            )
            .sort((a, b) => a.type.localeCompare(b.type)),
        )
    );
  },
);
