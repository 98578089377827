import { Box, styled } from "@mui/material";

export const WorkingHourContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  columnGap: "24px",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "stretch",
    rowGap: "8px",
  },

  ".select-time-drop-down-container": {
    flex: 1,
    columnGap: "8px",
  },
}));
