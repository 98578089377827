import { styled, Theme } from "@mui/material";

export const getCoverPhotoContainerSx = (theme: Theme) => ({
  aspectRatio: "16/9",
  cursor: "pointer",
  position: "relative",
  background: theme.palette.standardColor.Gray[300],
  borderRadius: theme.border.radius.md,
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "&:hover > .hover-overlay": {
    opacity: 1,
  },
});

export const getOverlayLayerSx = (theme: Theme) => {
  return {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.border.radius.md,
  };
};

export const StyledImage = styled("img")(({ theme }) => ({
  width: "100%",
  objectFit: "cover",
  height: "100%",
  borderRadius: theme.border.radius.md,
}));
