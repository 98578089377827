import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import queryString from "query-string";
import { Alt } from "../models/alts";
import {
  FileVersion,
  FILE_STATUS,
  SonicMatchUpload,
} from "../models/fileVersion";
import { Genre } from "../models/genres";
import User from "../models/user";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  DOWNLOAD_FILE_VERSION_AUDIO,
  FETCH_VERSIONED_FILES,
  MARK_FILE_AS_UPLOADED,
  MARK_SONIC_MATCH_AS_UPLOADED,
  SONIC_MATCH_ANALYSIS,
  UPDATE_FILE_VERSION,
  UPLOAD_FILE_LINK,
} from "../utils/routes";
import {
  downloadGeneratedMP3Track,
  downloadTrack,
  getArtistSignedUrl,
  getEngineerSignedUrl,
} from "./audioService";
import { receiveErrors } from "./errorStore";

export interface FileVersionsRecords {
  [project_id: number]: { [file_status: number]: FileVersion[] };
}

interface DownloadedFileVersions {
  [file_version_id: number]: {
    isTrackBlobUrlLoading?: boolean;
    trackBlobUrl?: string | null;
    isTrackGeneratedMP3BlobUrlLoading?: boolean;
    trackGeneratedMP3BlobUrl?: string | null;
  };
}

interface FileVersionsState {
  storedFileVersions: FileVersionsRecords;
  loading: boolean;
  sonicMatchUpload: SonicMatchUpload | null;
  instruments: [[string, number]];
  keys: [[string, number]];
  genres: Genre[];
  beatsPerMinute: number | undefined;
  upAndComing: User | undefined;
  established: User | undefined;
  industry: User | undefined;
  downloadedFileVersions: DownloadedFileVersions;
}

const initialState: FileVersionsState = {
  storedFileVersions: {},
  loading: false,
  sonicMatchUpload: null,
  instruments: [] as unknown as [[string, number]],
  keys: [] as unknown as [[string, number]],
  genres: [],
  beatsPerMinute: undefined,
  upAndComing: undefined,
  established: undefined,
  industry: undefined,
  downloadedFileVersions: {},
};

export interface fetchFilesParams {
  projectId: number;
  status: FILE_STATUS;
}

export const fetchFiles = createAsyncThunk(
  FETCH_VERSIONED_FILES,
  async ({ projectId, status }: fetchFilesParams, thunkAPI) => {
    const params = `?project_id=${projectId}&status=${status}`;
    const result = await makeBackendGetCallWithJsonResponse<FileVersion[]>(
      FETCH_VERSIONED_FILES,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface MultiuploadPart {
  ETag: string;
  PartNumber: number;
}

export interface MultipartUploadParams {
  bucket: string;
  key: string;
  upload_id: string;
  parts: MultiuploadPart[];
}

interface markFileAsUploadedParams {
  projectId: number;
  fileVersionId: number;
  code: string | null;
  recordingSessionBookingId?: number | null;
  multipartUploadParams?: MultipartUploadParams;
}

export const markFileAsUploaded = createAsyncThunk(
  MARK_FILE_AS_UPLOADED,
  async (
    {
      code,
      projectId,
      fileVersionId,
      multipartUploadParams,
      recordingSessionBookingId,
    }: markFileAsUploadedParams,
    thunkAPI,
  ) => {
    const args = {
      code: code,
      project_id: projectId,
      file_version_id: fileVersionId,
      recording_session_booking_id: recordingSessionBookingId,
    };
    const params = queryString.stringify(args, {
      skipEmptyString: true,
      skipNull: true,
    });
    const path = `${MARK_FILE_AS_UPLOADED}?${params}`;
    const result = await makeBackendPostCallWithJsonResponse<FileVersion>(
      path,
      multipartUploadParams || {},
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface markSonicMatchAsUploadedParams {
  sonicMatchUploadId: number;
  serviceType: string; // "mixing" or "mastering".
}

export const markSonicMatchAsUploaded = createAsyncThunk(
  MARK_SONIC_MATCH_AS_UPLOADED,
  async (args: markSonicMatchAsUploadedParams, thunkAPI) => {
    const params = `?sonic_match_upload_id=${args.sonicMatchUploadId}&service_type=${args.serviceType}`;
    const result = await makeBackendGetCallWithJsonResponse<SonicMatchUpload>(
      MARK_SONIC_MATCH_AS_UPLOADED,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface sonicMatchAnalysisParams {
  dolbyIoJobId: string;
  promoCode?: string;
}

export interface SonicMatchAnalysisSuccessResponse {
  genres: Genre[];
  keys: [[string, number]];
  bpm: number;
  instruments: [[string, number]];
  up_and_coming: User;
  established: User;
  industry: User;
}

interface SonicMatchAnalysisInProgressResponse {
  status: string;
  progress: number;
}

export const isSonicAnalysisComplete = (
  response:
    | SonicMatchAnalysisSuccessResponse
    | SonicMatchAnalysisInProgressResponse,
): response is SonicMatchAnalysisSuccessResponse => {
  return Boolean((response as SonicMatchAnalysisSuccessResponse)?.bpm);
};

export const sonicMatchAnalysis = createAsyncThunk(
  SONIC_MATCH_ANALYSIS,
  async (args: sonicMatchAnalysisParams, thunkAPI) => {
    let params = `?dolby_io_job_id=${args.dolbyIoJobId}`;
    if (args.promoCode) {
      params = `${params}&promocode=${args.promoCode}`;
    }
    const result = await makeBackendGetCallWithJsonResponse<
      SonicMatchAnalysisSuccessResponse | SonicMatchAnalysisInProgressResponse
    >(SONIC_MATCH_ANALYSIS, params);
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface FileDownloadResponse {
  url: string;
}

export const fileVersionDownloadURL = createAsyncThunk(
  DOWNLOAD_FILE_VERSION_AUDIO,
  async (fileVersionID: number, thunkAPI) => {
    const params = `?file_version_id=${fileVersionID}`;
    const result =
      await makeBackendGetCallWithJsonResponse<FileDownloadResponse>(
        DOWNLOAD_FILE_VERSION_AUDIO,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface FileVersionUpdateArgs {
  file_version_id: number;
  archive: boolean;
  project_id: number;
}

export const updateFileVersion = createAsyncThunk(
  UPDATE_FILE_VERSION,
  async (args: FileVersionUpdateArgs, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<FileVersion>(
      UPDATE_FILE_VERSION,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const uploadFileLink = createAsyncThunk(
  UPLOAD_FILE_LINK,
  async (
    args: {
      code?: string;
      reference: boolean;
      alt: Alt;
      file_link: string;
      project_id: number;
    },
    thunkAPI,
  ) => {
    const result = await makeBackendPostCallWithJsonResponse<FileVersion>(
      UPLOAD_FILE_LINK,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const fileVersionSlice = createSlice({
  name: "fileVersionSlice",
  reducers: {
    clearFileVersionDownload: (
      state,
      action: PayloadAction<{ fileVersionId: number | null }>,
    ) => {
      if (!action.payload.fileVersionId) {
        Object.keys(state.downloadedFileVersions).forEach(
          (fileVersionId) =>
            delete state.downloadedFileVersions[parseInt(fileVersionId)],
        );

        return;
      }
      delete state.downloadedFileVersions[action.payload.fileVersionId];
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchFiles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFiles.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateFileVersion.fulfilled, (state, action) => {
      const payload = action.payload;
      const archive = action.meta.arg.archive;
      const project_id = action.meta.arg.project_id;
      if (archive) {
        let projectState = state.storedFileVersions[project_id];
        if (!projectState) {
          projectState = {};
        }
        if (projectState?.[FILE_STATUS.FILE_UPLOADED] !== undefined) {
          projectState[FILE_STATUS.FILE_UPLOADED] = projectState[
            FILE_STATUS.FILE_UPLOADED
          ].filter((file) => file.id !== payload.id);
          state.storedFileVersions[project_id] = projectState;
        }
        delete state.downloadedFileVersions[payload.id];
      }
    });
    builder.addCase(uploadFileLink.fulfilled, (state, action) => {
      const file = action.payload;
      const project_id = action.meta.arg.project_id;
      let projectState = state.storedFileVersions[project_id];
      if (!projectState) {
        projectState = {};
      }
      if (projectState?.[FILE_STATUS.FILE_UPLOADED]) {
        projectState[FILE_STATUS.FILE_UPLOADED].push(file);
      } else {
        projectState[FILE_STATUS.FILE_UPLOADED] = [file];
      }
      state.storedFileVersions[project_id] = projectState;
    });
    builder.addCase(markFileAsUploaded.fulfilled, (state, action) => {
      const file = action.payload;
      const project_id = action.meta.arg.projectId;
      let projectState = state.storedFileVersions[project_id];
      if (!projectState) {
        projectState = {};
      }
      if (projectState?.[FILE_STATUS.FILE_PENDING] !== undefined) {
        projectState[FILE_STATUS.FILE_PENDING] = projectState[
          FILE_STATUS.FILE_PENDING
        ].filter((pendingFile) => pendingFile.id !== file.id);
      }
      if (projectState?.[FILE_STATUS.FILE_UPLOADED]) {
        let uploaded_files = projectState[FILE_STATUS.FILE_UPLOADED];
        uploaded_files = uploaded_files.filter(
          (currFile) => currFile.id !== file.id,
        );
        projectState[FILE_STATUS.FILE_UPLOADED] = uploaded_files.concat(file);
      } else {
        projectState[FILE_STATUS.FILE_UPLOADED] = [file];
      }
      state.storedFileVersions[project_id] = projectState;
    });
    builder.addCase(markSonicMatchAsUploaded.fulfilled, (state, action) => {
      state.sonicMatchUpload = action.payload;
    });
    builder.addCase(fetchFiles.fulfilled, (state, action) => {
      state.loading = false;
      const project_id = action.meta.arg.projectId;
      const fileStatus = action.meta.arg.status;
      const fileVersions: { [file_status: number]: FileVersion[] } = {};
      fileVersions[fileStatus] = action.payload;
      state.storedFileVersions[project_id] = fileVersions;
    });
    builder.addCase(sonicMatchAnalysis.fulfilled, (state, action) => {
      if (isSonicAnalysisComplete(action.payload)) {
        state.beatsPerMinute = action.payload.bpm;
        state.instruments = action.payload.instruments;
        state.keys = action.payload.keys;
        state.genres = action.payload.genres;
        state.upAndComing = action.payload.up_and_coming;
        state.established = action.payload.established;
        state.industry = action.payload.industry;
      }
    });
    builder.addCase(downloadTrack.pending, (state, action) => {
      const fileVersionId = action.meta.arg.fileVersionId;
      if (!fileVersionId) {
        return;
      }
      state.downloadedFileVersions[fileVersionId] = {
        ...state.downloadedFileVersions[fileVersionId],
        isTrackBlobUrlLoading: true,
      };
    });
    builder.addCase(downloadTrack.fulfilled, (state, action) => {
      const fileVersionId = action.meta.arg.fileVersionId;
      const blobUrl = action.payload;
      if (!fileVersionId) {
        return;
      }
      state.downloadedFileVersions[fileVersionId] = {
        ...state.downloadedFileVersions[fileVersionId],
        trackBlobUrl: blobUrl,
        isTrackBlobUrlLoading: false,
      };
    });
    builder.addCase(downloadTrack.rejected, (state, action) => {
      const fileVersionId = action.meta.arg.fileVersionId;
      if (!fileVersionId) {
        return;
      }
      state.downloadedFileVersions[fileVersionId] = {
        ...state.downloadedFileVersions[fileVersionId],
        trackBlobUrl: null,
        isTrackBlobUrlLoading: false,
      };
    });
    builder.addCase(downloadGeneratedMP3Track.pending, (state, action) => {
      const fileVersionId = action.meta.arg.fileVersionId;
      if (!fileVersionId) {
        return;
      }
      state.downloadedFileVersions[fileVersionId] = {
        ...state.downloadedFileVersions[fileVersionId],
        isTrackGeneratedMP3BlobUrlLoading: true,
      };
    });
    builder.addCase(downloadGeneratedMP3Track.fulfilled, (state, action) => {
      const fileVersionId = action.meta.arg.fileVersionId;
      const blobUrl = action.payload;
      if (!fileVersionId) {
        return;
      }
      state.downloadedFileVersions[fileVersionId] = {
        ...state.downloadedFileVersions[fileVersionId],
        trackGeneratedMP3BlobUrl: blobUrl,
        isTrackGeneratedMP3BlobUrlLoading: false,
      };
    });
    builder.addCase(downloadGeneratedMP3Track.rejected, (state, action) => {
      const fileVersionId = action.meta.arg.fileVersionId;
      if (!fileVersionId) {
        return;
      }
      state.downloadedFileVersions[fileVersionId] = {
        ...state.downloadedFileVersions[fileVersionId],
        trackGeneratedMP3BlobUrl: null,
        isTrackGeneratedMP3BlobUrlLoading: false,
      };
    });
    builder.addMatcher(
      isAnyOf(getArtistSignedUrl.fulfilled, getEngineerSignedUrl.fulfilled),
      (state, action) => {
        const file = action.payload.file;
        const project_id = +action.meta.arg.project_id;
        const projectState = state.storedFileVersions[project_id] ?? {};
        if (projectState?.[FILE_STATUS.FILE_PENDING]) {
          projectState[FILE_STATUS.FILE_PENDING].push(file);
        } else {
          projectState[FILE_STATUS.FILE_PENDING] = [file];
        }
        state.storedFileVersions[project_id] = projectState;
      },
    );
  },
});

export const { clearFileVersionDownload } = fileVersionSlice.actions;
export default fileVersionSlice.reducer;
