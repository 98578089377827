import styled from "styled-components";

export const StudioScreenActionButtonWrapper = styled.div<{
  $show?: boolean;
  $navItemsHeight?: number;
}>`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    background-color: var(--background-color);
    -moz-box-shadow: 0 0 6px var(--box-shadow-color);
    -webkit-box-shadow: 0 0 6px var(--box-shadow-color);
    box-shadow: 0 0 6px var(--box-shadow-color);
    padding: 12px 16px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: ${({ $navItemsHeight, $show }) => ($show ? $navItemsHeight : 0)}px;
    transition: 0.3s ease;
  }
`;

export const StudioScreenConstructionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  & > p {
    padding-top: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

export const StudioScreenConstructionLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  height: auto;
  max-height: 75vh;
  max-width: 600px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
