import AandR, { MockAandR } from "./aandr";
import { Admin } from "./admins";
import Artist, { MockArtist } from "./artist";
import Base, { SlimProfileBase } from "./base";
import Engineer, { MockEngineer, MockEngineerForRecording } from "./engineer";
import EntityPhoto from "./entityPhoto";
import Listener from "./listener";
import Other from "./other";
import PhoneNumber from "./phoneNumber";
import Photo from "./photo";
import Producer, { MockProducer } from "./producer";
import Profile, { BannerColor } from "./profile";
import { RecordingLocation } from "./recording";
import { ServiceRate } from "./service";
import StudioManager, { MockStudioManager } from "./studiomanager";

export interface LocalUTMParams {
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  utm_content?: string | null;
  utm_term?: string | null;
}

export default interface User extends Base {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  date_joined: string;
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  utm_content?: string | null;
  utm_term?: string | null;
  profile?: Profile;
  phone_number?: PhoneNumber;
  engineer?: Engineer;
  artist?: Artist;
  producer?: Producer;
  listener?: Listener;
  other?: Other;
  aandr?: AandR;
  admin?: Admin;
  studio_manager?: StudioManager;
  photo?: Photo | null;
  aandr_qualified?: boolean;
  is_umg_aandr?: boolean;
  is_umg_po_aandr?: boolean;
  dollar_signs?: number;
  last_online: string | null;
  is_valid_aandr_email?: boolean;
  email_verified?: string | null;
  show_session_ui?: boolean;
  country_code?: string | null;
  stripe_account_id?: string | null;
  meets_stripe_requirements?: string | null | undefined;
  is_superuser: boolean | null;
  location?: RecordingLocation;
  service_rate?: ServiceRate;
  stream_token?: string;
  cover_photo_desktop?: EntityPhoto | null;
  cover_photo_mobile?: EntityPhoto | null;
}

export interface SlimPrivateActiveUser extends SlimProfileBase {
  date_joined?: string;
  is_active?: boolean; // Represents if the user is soft-deleted
  is_superuser?: boolean; // A Django Field
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: PhoneNumber;
  stream_token?: string;
  is_studio_manager?: boolean;
  has_studio_manager_experience?: boolean;
}

export interface UserLite {
  id: number;
  artist?: Artist;
  engineer?: Engineer;
  profile?: Profile;
  photo: Photo | null;
  username: string;
}

export const MockUser: User = {
  id: 1,
  user_id: 0,
  username: "mixedbyali",
  last_name: "last_name",
  first_name: "first_name",
  is_active: true,
  dollar_signs: 3,
  date_joined: "2021-11-08T21:57:18.303696",
  utm_source: "tiktok",
  utm_medium: "social",
  utm_campaign: "awareness",
  utm_content: "organicvideo",
  utm_term: "paid",
  email: "test@gmail.com",
  engineer: MockEngineer,
  producer: MockProducer,
  artist: MockArtist,
  aandr: MockAandR,
  studio_manager: MockStudioManager,
  last_online: null,
  is_superuser: null,
  photo: {
    created: "2021-11-29T17:34:55.929545",
    deleted: null,
    id: 78,
    path: "m6vyv6/m4xct4.jpg",
  },
  profile: {
    display_name: "Derek 'MixedByAli' Ali",
    long_bio: "Long bio",
    bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n",
    soundcloud_username: "",
    twitch_username: "",
    twitter_username: "",
    facebook_username: "",
    instagram_username: "",
    youtube_username: "",
    tiktok_username: "",
    spotify_username: "",
    apple_username: "",
    tidal_username: "",
    amazon_username: "",
    location: "",
    country: "United States",
    city: "Los Angeles",
    genres_string: "",
    birth_date: "",
    banner_color: BannerColor.SKYLIGHT_BLUE,
    tab_order_preferences: "",
  },
  country_code: "CA",
  meets_stripe_requirements: null,
  cover_photo_desktop: null,
  cover_photo_mobile: null,
};

export const MockUser1: User = {
  is_superuser: null,
  id: 2,
  user_id: 0,
  username: "mixedbyiliass",
  last_name: "Tiendrebeogo",
  first_name: "Iliass",
  is_active: true,
  dollar_signs: 3,
  date_joined: "2021-11-08T21:57:18.303696",
  email: "test@gmail.com",
  engineer: MockEngineer,
  producer: MockProducer,
  studio_manager: MockStudioManager,
  artist: MockArtist,
  aandr: MockAandR,
  last_online: "2021-11-08T21:57:18.303696",
  photo: {
    created: "2021-11-29T17:34:55.929545",
    deleted: null,
    id: 78,
    path: "https://picsum.photos/1920/1080",
  },
  profile: {
    display_name: "Iliass 'MixedByIliass' ",
    long_bio: "Long bio",
    bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n",
    soundcloud_username: "",
    twitch_username: "",
    twitter_username: "",
    facebook_username: "",
    instagram_username: "",
    youtube_username: "",
    tiktok_username: "",
    spotify_username: "",
    apple_username: "",
    tidal_username: "",
    amazon_username: "",
    location: "",
    country: "United States",
    city: "Los Angeles",
    genres_string: "",
    birth_date: "",
    banner_color: BannerColor.SKYLIGHT_BLUE,
    tab_order_preferences: "",
  },
  cover_photo_desktop: null,
  cover_photo_mobile: null,
};

export const MockUser2: User = {
  is_superuser: null,
  id: 2,
  user_id: 0,
  username: "small name",
  last_name: "name",
  first_name: "small",
  is_active: true,
  dollar_signs: 3,
  date_joined: "2021-11-08T21:57:18.303696",
  email: "test@gmail.com",
  engineer: MockEngineer,
  aandr: MockAandR,
  last_online: "2021-11-08T21:57:18.303696",
  photo: {
    created: "2021-11-29T17:34:55.929545",
    deleted: null,
    id: 78,
    path: "https://picsum.photos/1920/1080",
  },
  profile: {
    display_name: "smallname ",
    long_bio: "Long bio",
    bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n",
    soundcloud_username: "",
    twitch_username: "",
    twitter_username: "",
    facebook_username: "",
    instagram_username: "",
    youtube_username: "",
    tiktok_username: "",
    spotify_username: "",
    apple_username: "",
    tidal_username: "",
    amazon_username: "",
    location: "",
    country: "United States",
    city: "Los Angeles",
    genres_string: "",
    birth_date: "",
    banner_color: BannerColor.SKYLIGHT_BLUE,
    tab_order_preferences: "",
  },
  cover_photo_desktop: null,
  cover_photo_mobile: null,
};

export const MockUserForRecordingEngineer: User = {
  is_superuser: null,
  id: 60,
  user_id: 0,
  username: "my_username_is_long",
  last_name: "name",
  first_name: "small",
  is_active: true,
  dollar_signs: 3,
  date_joined: "2021-11-08T21:57:18.303696",
  email: "test@gmail.com",
  engineer: MockEngineerForRecording,
  last_online: "2021-11-08T21:57:18.303696",
  photo: {
    created: "2021-11-29T17:34:55.929545",
    deleted: null,
    id: 78,
    path: "https://picsum.photos/1920/1080",
  },
  profile: {
    display_name: "my username is long",
    long_bio: "Long bio",
    bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n",
    soundcloud_username: "",
    twitch_username: "",
    twitter_username: "",
    facebook_username: "",
    instagram_username: "",
    youtube_username: "",
    tiktok_username: "",
    spotify_username: "",
    apple_username: "",
    tidal_username: "",
    amazon_username: "",
    location: "",
    country: "United States",
    city: "Los Angeles",
    genres_string: "",
    birth_date: "",
    banner_color: BannerColor.SKYLIGHT_BLUE,
    tab_order_preferences: "",
  },
  cover_photo_desktop: null,
  cover_photo_mobile: null,
};
