export enum CHANNEL_ID_PREFIX {
  DIRECT_MESSAGE = "users",
  SCHEDULED_PROJECT = "project",
  SESSION_BOOKING = "sessionbooking",
  STUDIO_DIRECT_MESSAGE = "studiodm",
  CUSTOMER_SERVICE_CHANNEL = "customerservice",
}

export const SESSION_MESSAGE_PREFIXES = [
  CHANNEL_ID_PREFIX.SESSION_BOOKING,
  CHANNEL_ID_PREFIX.STUDIO_DIRECT_MESSAGE,
];

export enum CHANNEL_TYPE {
  MESSAGING = "messaging",
}
