import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Notification, {
  MockNotifications,
  Nudge,
} from "../models/notifications";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  LOAD_NOTIFICATIONS,
  READ_NOTIFICATIONS,
  NUDGE,
  SEND_RATING_FEEDBACK,
} from "../utils/routes";
import { receiveErrors } from "./errorStore";
import { UserLite } from "../models/user";

interface Notifications {
  [page: number]: Notification[] | undefined;
}

interface NotificationsState {
  notifications: Notifications;
  numPages: number;
  currentPage: number;
  loading: boolean;
  unreadCount: number;
  nudgeEligibility: GetNudgeEligibilityResponse | null;
}

const initialState: NotificationsState = {
  notifications: {},
  numPages: 0,
  currentPage: 0,
  loading: false,
  unreadCount: 0,
  nudgeEligibility: null,
};

export interface loadNotificationsParams {
  page: number;
}

interface NotificationsResponse {
  page: number;
  num_pages: number;
  notifications: Notification[];
}

export const loadNotifications = createAsyncThunk(
  LOAD_NOTIFICATIONS,
  async (args: loadNotificationsParams, thunkAPI) => {
    const params = `?page=${args.page.toString()}`;
    const result =
      await makeBackendGetCallWithJsonResponse<NotificationsResponse>(
        LOAD_NOTIFICATIONS,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getUnreadNotificationsCount = createAsyncThunk(
  READ_NOTIFICATIONS + "/get",
  async (_, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<{ unread: number }>(
      READ_NOTIFICATIONS,
      "",
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface updateReadNotificationsParams {
  last_notification_read: number;
}

export const updateReadNotifications = createAsyncThunk(
  READ_NOTIFICATIONS + "/post",
  async (args: updateReadNotificationsParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<{
      marked_read: number;
    }>(READ_NOTIFICATIONS, args);
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const sendRatingFeedback = createAsyncThunk(
  SEND_RATING_FEEDBACK + "/post",
  async (
    args: {
      username: string | null;
      rating: string | null;
      account_type: string | null;
    },
    thunkAPI,
  ) => {
    const result = await makeBackendPostCallWithJsonResponse<object>(
      SEND_RATING_FEEDBACK,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface getNudgeEligibilityParams {
  project_id: number;
}

interface GetNudgeEligibilityResponse {
  users_to_nudge: UserLite[];
  allowed_to_nudge: boolean;
  last_transition_or_nudge: string;
}

export const getNudgeEligibility = createAsyncThunk(
  NUDGE + "/get",
  async (args: getNudgeEligibilityParams, thunkAPI) => {
    const params = `?project_id=${args.project_id}`;
    const result =
      await makeBackendGetCallWithJsonResponse<GetNudgeEligibilityResponse>(
        NUDGE,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface sendNudgeParams {
  project_id: number;
}

export const sendNudge = createAsyncThunk(
  NUDGE + "/post",
  async (args: sendNudgeParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Nudge>(
      NUDGE,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const notificationsSlice = createSlice({
  name: "notificationsSlice",
  initialState,
  reducers: {
    loadMockNotifications: (state) => {
      state.notifications[1] = MockNotifications;
      state.currentPage = 1;
      state.numPages = 1;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadNotifications.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      const { notifications, num_pages, page } = payload;
      state.currentPage = page;
      state.notifications[page] = notifications;
      state.loading = false;
      state.numPages = num_pages;
    });
    builder.addCase(getUnreadNotificationsCount.fulfilled, (state, action) => {
      const { payload } = action;
      state.unreadCount = payload.unread;
    });
    builder.addCase(getNudgeEligibility.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNudgeEligibility.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.nudgeEligibility = payload;
    });
  },
});

export const { loadMockNotifications } = notificationsSlice.actions;
export default notificationsSlice.reducer;
