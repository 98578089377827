import { Link } from "react-router-dom";
import styled from "styled-components";

export const SessionDetailsPanelFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
`;

export const SessionOverviewGridItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  color: ${({ theme }) => theme.textPrimaryColor};
`;

export const SessionOverviewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 16px;
  gap: 24px;
`;

export const StyledSessionDetailsPanelBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const CollaboratorPills = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 8px;
  flex-wrap: wrap;
`;

export const CollaboratorPillContainer = styled(Link)`
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 8px 16px 8px 8px;
  border-radius: ${({ theme }) => theme.border.radius.xl};
  border: 1px solid ${({ theme }) => theme.separatorColor};
  &:-webkit-any-link {
    text-decoration: none;
  }
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;

  & > * {
    flex: 1;
  }
`;

export const StyledSessionModificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0 36px;
  text-align: center;

  & > button {
    width: 100%;
  }
`;

export const SessionExtensionScreenBody = styled.div`
  width: 400px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // This is quite a special screen case because the side panel doesn't have an uniform width
  @media screen and (max-width: 450px) {
    width: calc(var(--radix-popover-content-available-width) - 36px);
  }
`;

export const SessionModificationPopoverContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const AddressAnchor = styled.a`
  color: ${({ theme }) => theme.textPrimaryColor};
`;
