import { ChangeEvent, FC, useCallback, useState } from "react";
import "./AddAStudioModal.css";
import { BaseModal } from "../BaseModal/BaseModal";
import { Button } from "../../elements/Button/button";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { createOrDeleteStudioProfile } from "../../../store/actions/studio";
import { DisplayError } from "../../elements/DisplayError/DisplayError";

export interface AddAStudioModalProps {
  overwriteShowModal?: boolean;
  onClose?: () => void;
}

export const AddAStudioModal: FC<AddAStudioModalProps> = ({
  overwriteShowModal,
  onClose = () => {},
}: AddAStudioModalProps) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const [showModal, setShowModal] = useState(overwriteShowModal ?? true);
  const [studioUsername, setStudioUsername] = useState("");
  const [creatingStudioProfile, setCreatingStudioProfile] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const handleClose = useCallback(() => {
    if (onClose) onClose();
  }, [dispatch]);

  const handleStudioUsernameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const value = event.target.value.replace(/\s/g, "");
      setStudioUsername(value);
    },
    [],
  );

  const handleCreateNewStudioProfile = useCallback(() => {
    if (creatingStudioProfile) return;
    emitAnalyticsTrackingEvent("on_click_create_studio_profile", {}, user?.id);
    if (!studioUsername || /\s/.test(studioUsername)) {
      return setShowErrorMessage(true);
    }
    setCreatingStudioProfile(true);
    dispatch(
      createOrDeleteStudioProfile({
        studio_username: studioUsername,
        deleted: false,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success("Successfully created studio profile.");
        setShowModal(false);
        // Redirect to this new Studio profile.
        setCreatingStudioProfile(false);
        history.push("/studio/" + studioUsername);
      })
      .catch(() => {
        toast.error("Failed to create studio profile.");
      })
      .finally(() => {
        setShowModal(false);
        setCreatingStudioProfile(false);
        handleClose();
      });
  }, [studioUsername, user, dispatch, creatingStudioProfile]);

  if (!user) return null;

  return (
    <BaseModal
      modalIsOpen={showModal}
      closeModal={handleClose}
      label={"update account details"}
    >
      <div className="add-a-studio-container card-container">
        <p className="b1-semi-bold mb-4">Add a New Studio</p>
        <label className="custom-textfield-label">Studio username</label>
        <input
          value={studioUsername}
          onChange={handleStudioUsernameChange}
          placeholder="Studio username"
          type={"text"}
          className="add-a-studio-input"
        />
        <Button
          loading={creatingStudioProfile}
          style={{ width: "100%" }}
          disabled={studioUsername.length === 0}
          label={"Create New Studio Profile"}
          onClick={handleCreateNewStudioProfile}
          primary={true}
          customClassName="add-studio-button"
        />
        {showErrorMessage && (
          <DisplayError
            errorMessage={
              "Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters.."
            }
          />
        )}
      </div>
    </BaseModal>
  );
};
