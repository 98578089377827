import { useState } from "react";
import ViewSlider, { ViewProps } from "react-view-slider";
import {
  useIsInProgressProject,
  usePaymentPlanStatus,
} from "../../../hooks/partialPaymentHooks";
import useModal from "../../../hooks/useModal";
import { getPurchaseOrderStatus } from "../../../store/actions/scheduledProjectPurchaseOrders";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  ProjectById,
  ProjectType,
  ProjectUserType,
  ProjectWorkflowSteps,
} from "../../../store/models/project";
import User, { UserLite } from "../../../store/models/user";
import {
  getMixingMasteringProject,
  getProjectWorkflowStep,
} from "../../../utils/projectUtils";
import { Button } from "../../core-ui/components/Button/Button";
import {
  Text,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import { ProjectListRow } from "../../elements/ProjectListRow/ProjectListRow";
import {
  BookingDetailsContent,
  ContentBody,
  ContentHeader,
} from "../BookingRequestedPanel/BookingDetails.styles";
import { DownloadFilesView } from "../DownloadFilesView/DownloadFilesView";
import { FileUploader } from "../FileUploader/FileUploader";
import { ProjectListItem } from "../ProjectList/ProjectList.styles";
import { ProjectReview } from "../ProjectReview/ProjectReview";
import { ProjectPanelSeparator } from "../ProjectWorkflowPanel/ProjectWorkflowPanel.styles";
import { ScheduledProjectBudgetManagerView } from "../ScheduledProjectBudgetManagerView/ScheduledProjectBudgetManagerView";
import { TrackTable } from "../TrackTable/TrackTable";
import { TransferFileReuploadMessage } from "../TransferFileTransitionView/components/TransferFileReuploadMessage";
import { CompleteArtistButtonRow } from "./components/CompleteArtistButtonRow";
import { CompleteEngineerButtonRow } from "./components/CompleteEngineerButtonRow";
import { isProjectOnAssetReviewStep } from "./utils/isProjectOnAssetReviewStep";

export interface CompleteTransitionViewProps {
  code?: string;
  user?: User | UserLite;
  userType?: ProjectUserType | null;
  project: ProjectById;
  projectType: ProjectType;
  isPartiallyPaid: boolean;
  isPendingBudgetApproval: boolean;
  isPendingBillingInfo: boolean;
  outstandingBalance?: number;
}

export enum CompleteTransitionViewIndex {
  MAIN,
  DOWNLOAD_FILES,
}

export const CompleteTransitionView = ({
  code,
  user,
  project,
  userType,
  projectType,
  isPartiallyPaid,
  isPendingBudgetApproval,
  isPendingBillingInfo,
  outstandingBalance,
}: CompleteTransitionViewProps) => {
  const { isInProgressProject } = useIsInProgressProject(project);
  const { redirectToPurchaseProject } = usePaymentPlanStatus(
    code,
    undefined,
    project.id,
    isPartiallyPaid,
  );
  const [viewIndex, setViewIndex] = useState<CompleteTransitionViewIndex>(
    CompleteTransitionViewIndex.MAIN,
  );
  const { scheduledProject } = useAppSelector(
    (state) => state.scheduledProjectsStore,
  );
  const [fetchingPurchaseOrderStatus, setFetchingPurchaseOrderStatus] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const mixingMasteringProject = getMixingMasteringProject(project);
  const projectStep = mixingMasteringProject?.step ?? 0;
  const projectWorkflowStep = getProjectWorkflowStep(projectType, projectStep);
  const isFinished = projectWorkflowStep === ProjectWorkflowSteps.MIX_FINISHED;
  const isEngineer = userType === ProjectUserType.ENGINEER;
  const isArtist = userType === ProjectUserType.ARTIST;
  const isAdminOrSecondaryEngineer =
    userType === ProjectUserType.SECONDARY_ENG_OR_ADMIN;
  const isDeliverablesUploaded = isProjectOnAssetReviewStep(
    projectStep,
    projectType,
  );
  const {
    isOpen: isCompletePaymentPopoverOpen,
    setIsOpen: setIsCompletePaymentPopoverOpen,
    closeModal: closeCompletePaymentPopover,
  } = useModal();
  const finalAssetNotes = project.final_asset_file_notes;

  const renderView = ({ index }: ViewProps) => {
    switch (index) {
      case CompleteTransitionViewIndex.MAIN:
        return (
          <>
            <TrackTable
              allowTrackPreview
              allowTrackComments
              showHeader
              showFileNames
              isCurrentEngineer={isEngineer}
              project={project}
              projectId={project.id}
              projectName={project.title}
              projectType={projectType}
              step={projectStep}
              userType={userType}
            />
            {isEngineer && (
              <CompleteEngineerButtonRow
                isDeliverablesUploaded={isDeliverablesUploaded}
                project={project}
                setViewIndex={setViewIndex}
              />
            )}
            {(isPendingBudgetApproval || isPendingBillingInfo) &&
              scheduledProject &&
              isArtist && (
                <ScheduledProjectBudgetManagerView
                  scheduledProject={scheduledProject}
                  fetching={fetchingPurchaseOrderStatus}
                  onPurchaseOrderSubmitted={async () => {
                    setFetchingPurchaseOrderStatus(true);
                    await dispatch(
                      getPurchaseOrderStatus({
                        scheduled_project_id: scheduledProject?.id,
                      }),
                    ).finally(() => {
                      setFetchingPurchaseOrderStatus(false);
                    });
                  }}
                />
              )}
            {isPendingBudgetApproval && (
              <Text>
                Your budget must be approved before requesting re-uploads or
                downloading deliverables
              </Text>
            )}
            {isPendingBillingInfo && !isPendingBudgetApproval && (
              <Text>
                Your billing info must be submitted before you can request
                re-uploads
              </Text>
            )}
            {(isArtist ||
              isAdminOrSecondaryEngineer ||
              (isInProgressProject && !isEngineer)) && (
              <CompleteArtistButtonRow
                code={code}
                isAdminOrSecondaryEngineer={isAdminOrSecondaryEngineer}
                isPendingBillingInfo={isPendingBillingInfo}
                isPendingBudgetApproval={isPendingBudgetApproval}
                transitionToMainView={() => {
                  setViewIndex(CompleteTransitionViewIndex.MAIN);
                }}
                isDeliverablesUploaded={isDeliverablesUploaded}
                project={project}
                transitionToDownloadFiles={() => {
                  setViewIndex(CompleteTransitionViewIndex.DOWNLOAD_FILES);
                }}
                allowFileDownload={!isInProgressProject && !isPartiallyPaid}
                isPartiallyPaid={isPartiallyPaid}
                isCompletePaymentPopoverOpen={isCompletePaymentPopoverOpen}
                setIsCompletePaymentPopoverOpen={
                  setIsCompletePaymentPopoverOpen
                }
                closeCompletePaymentPopover={closeCompletePaymentPopover}
                outstandingBalance={outstandingBalance}
                viewIndex={viewIndex}
              />
            )}
          </>
        );
      case CompleteTransitionViewIndex.DOWNLOAD_FILES:
        if (isInProgressProject && isArtist) {
          return (
            <div>
              <Text>
                You must complete the payment process before you can download
                files.
              </Text>
              <Button onClick={redirectToPurchaseProject}>Pay now</Button>
            </div>
          );
        }
        return (
          <DownloadFilesView
            isEngineer={isEngineer}
            isArtist={isArtist}
            project={project}
            projectWorkflowStep={projectWorkflowStep}
            handleDismissDownloadFiles={() => {
              setViewIndex(CompleteTransitionViewIndex.MAIN);
            }}
            code={code}
            disableDownloads={isInProgressProject && isArtist}
          />
        );
      default:
        return null;
    }
  };

  if (!isFinished) {
    return null;
  }
  if (!user && !code) {
    return null;
  }

  return (
    <>
      {isDeliverablesUploaded && !code && (
        <ProjectReview
          projectId={project.id}
          project_type={project.service_type}
        />
      )}
      {isEngineer && !isDeliverablesUploaded && (
        <>
          {finalAssetNotes && (
            <TransferFileReuploadMessage
              message={finalAssetNotes}
              requestingUser={ProjectUserType.ARTIST}
            />
          )}
          <BookingDetailsContent>
            <ContentHeader>
              <Text size={TEXT_SIZE.LARGE} weight={TEXT_WEIGHT.SEMI_BOLD}>
                Alternates requested:
              </Text>
            </ContentHeader>
            <ContentBody>
              <ProjectListItem>
                <Text weight={TEXT_WEIGHT.SEMI_BOLD} size={TEXT_SIZE.SMALL}>
                  1.
                </Text>
                <ProjectListRow
                  defaultShowAlts
                  project={project}
                  allowEdit={false}
                />
              </ProjectListItem>
            </ContentBody>
          </BookingDetailsContent>
          <div style={{ margin: "16px 0 24px 0" }}>
            <FileUploader
              project={project}
              projectStep={projectStep}
              isCurrentProjectEngineer={isEngineer}
              engineerIsUploadingOnBehalfOfArtist={false}
              showLinkUploader={false}
            />
          </div>
        </>
      )}
      <ViewSlider
        keepViewsMounted={true}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        animateHeight
        style={{ overflow: "visible" }} // allows TrackTableOptions to be visible
      />
      <ProjectPanelSeparator $margin="16px 0 24px 0" />
    </>
  );
};
