import useModal from "../../../../../../hooks/useModal";
import Engineer from "../../../../../../store/models/engineer";
import User from "../../../../../../store/models/user";
import { EditServicesModal } from "../../../../EditServicesModal/EditServicesModal";
import {
  NavDropDownListItem,
  NAV_LIST_ITEM_VARIANT,
} from "../NavDropDownListItem/NavDropDownListItem";
interface NavEditServicesButtonProps {
  engineer: Engineer;
  user: User;
}

export const NavEditServicesButton = ({
  engineer,
  user,
}: NavEditServicesButtonProps) => {
  const { isOpen, openModal, closeModal } = useModal();

  return (
    <>
      <NavDropDownListItem
        text="Edit services"
        variant={NAV_LIST_ITEM_VARIANT.BUTTON}
        onClick={() => {
          openModal();
        }}
      />
      {isOpen && (
        <EditServicesModal
          user={user}
          engineer={engineer}
          isOpen={isOpen}
          closeModal={closeModal}
        />
      )}
    </>
  );
};
