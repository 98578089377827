import humanizeDuration from "humanize-duration";
import {
  ModificationType,
  RecordingSession,
} from "../../store/models/recordingSession";
import {
  getFormattedTimeStringWithoutDateFromLocalDate,
  removeTimeFromDate,
} from "../../store/utils/dateTimeUtils";
import { convertMinutesToMilliseconds } from "../../store/utils/serviceUtils";
import { useSessionHasPendingModificationRequest } from "./useSessionHasPendingModificationRequest";
import { useUserHasPendingSessionModificationRequest } from "./useUserHasPendingSessionModificationRequest";

interface SessionExtensionRequest {
  proposedExtensionDuration: string;
  proposedExtensionDate: string;
  proposedExtensionStartTime: string;
  proposedExtensionEndTime: string;
}

interface SessionRescheduleRequest {
  proposedDate: string;
  proposedTime: string;
}

export interface SessionModificationDetails {
  sessionExtensionRequest: SessionExtensionRequest | null;
  sessionRescheduledRequest: SessionRescheduleRequest | null;
  sessionHasPendingModificationRequest: boolean;
  userHasPendingRequest: boolean;
}

export const useGetSessionModificationDetails = (
  recordingSession: RecordingSession | null,
): SessionModificationDetails => {
  const sessionModificationDetails: SessionModificationDetails = {
    sessionExtensionRequest: null,
    sessionRescheduledRequest: null,
    sessionHasPendingModificationRequest:
      useSessionHasPendingModificationRequest(recordingSession),
    userHasPendingRequest:
      useUserHasPendingSessionModificationRequest(recordingSession),
  };

  if (recordingSession) {
    const {
      session_modification: sessionModification,
      first_choice_datetime: firstChoiceDatetime,
    } = recordingSession;

    if (sessionModification) {
      if (
        sessionModification.modification_type === ModificationType.RESCHEDULE
      ) {
        const newDatetime = new Date(sessionModification.new_datetime);
        sessionModificationDetails.sessionRescheduledRequest = {
          proposedDate: removeTimeFromDate(newDatetime).replace(",", ""),
          proposedTime:
            getFormattedTimeStringWithoutDateFromLocalDate(newDatetime),
        };
      } else if (
        sessionModification.modification_type === ModificationType.EXTENSION
      ) {
        const extendByDurationTotal =
          sessionModification.new_session_duration_minutes -
          sessionModification.previous_session_duration_minutes;

        const startDateTime = new Date(firstChoiceDatetime);
        const endDateTime = new Date(firstChoiceDatetime);
        endDateTime.setMinutes(
          endDateTime.getMinutes() +
            sessionModification.new_session_duration_minutes,
        );

        sessionModificationDetails.sessionExtensionRequest = {
          proposedExtensionDate: startDateTime.toLocaleDateString(),
          proposedExtensionDuration: humanizeDuration(
            convertMinutesToMilliseconds(extendByDurationTotal),
          ),
          proposedExtensionEndTime:
            getFormattedTimeStringWithoutDateFromLocalDate(endDateTime),
          proposedExtensionStartTime:
            getFormattedTimeStringWithoutDateFromLocalDate(startDateTime),
        };
      }
    }
  }

  return sessionModificationDetails;
};
