import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { toast } from "react-toastify";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../helpers";
import { ReleaseTrack } from "../../../store/models/release";
import { updateReleaseTrack } from "../releaseAPI";

const useUpdateReleaseTrack = (releaseTrackId = 0) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.UPDATE_RELEASE_TRACK, releaseTrackId],
    mutationFn: async (title: string) => {
      const response = await updateReleaseTrack(releaseTrackId, title);
      if (!response.success) {
        throw response.resultJson;
      }
      return response.resultJson as ReleaseTrack;
    },
    onSuccess: (releaseTrack) => {
      toast.success("Track title updated successfully");
      void queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.FETCH_ARTIST_RELEASE_TRACKS,
          releaseTrack.release.id,
        ],
      });
    },
    onError: (error) => {
      if (assertEngineEarsErrorType(error)) {
        displayEngineEarsError(error);
      }
    },
  });
};

export default useUpdateReleaseTrack;
