import { PopoverTrigger } from "@radix-ui/react-popover";
import { MouseEventHandler, useCallback } from "react";
import useModal from "../../../../hooks/useModal";
import { useAppSelector } from "../../../../store/hooks";
import {
  SignInModal,
  SignInModalProps,
} from "../../../components/SignInModal/SignInModal";
import {
  BasePopover,
  BasePopoverWithFooterProps,
} from "../BasePopover/BasePopover";
import { PopConfirm } from "../PopConfirm/PopConfirm";
export interface LoginRequiredPopoverProps extends BasePopoverWithFooterProps {
  authConfirmTitle?: string;
  authConfirmOkText?: string;
  authConfirmDescription: string;
  authOnConfirm?: MouseEventHandler<HTMLButtonElement>;
  signInModalProps?: Partial<SignInModalProps>;
  children: React.ReactNode;
  ignoreAuth?: boolean;
}

export const LoginRequiredPopover = ({
  authConfirmTitle = "Login Required",
  authConfirmOkText = "Login",
  authOnConfirm = () => {},
  authConfirmDescription = "Please login to continue.",
  signInModalProps = {},
  children,
  ignoreAuth = false,
  ...props
}: LoginRequiredPopoverProps) => {
  const { isOpen, openModal, closeModal } = useModal();
  const user = useAppSelector((state) => state.accountInfo.user);
  const handleConfirm: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      try {
        authOnConfirm(e);
        openModal();
      } catch {
        /* empty */
      }
    },
    [openModal, authOnConfirm],
  );

  if (!user && !ignoreAuth) {
    return (
      <>
        <PopConfirm
          title={authConfirmTitle}
          okText={authConfirmOkText}
          description={authConfirmDescription}
          onConfirm={handleConfirm}
        >
          {children}
        </PopConfirm>
        <SignInModal
          open={isOpen}
          setOpen={isOpen ? closeModal : openModal}
          showCloseButton={true}
          label={signInModalProps.label ?? "Login"}
        />
      </>
    );
  }

  return (
    <BasePopover {...props}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
    </BasePopover>
  );
};
