import styled from "styled-components";

export const LabelSummaryAssociatedUsersOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: inherit;
`;

export const LabelSummaryAssociatedUsersTitle = styled.p`
  font-size: ${({ theme }) => theme.textSizeLg};
  color: ${({ theme }) => theme.textPrimaryColor};
  font-weight: 600;
  margin-bottom: 16px;
`;

export const LabelSummaryAssociatedUsersOverviewBodyContainer = styled(
  LabelSummaryAssociatedUsersOverviewContainer,
)`
  gap: 32px;
  @media screen and (max-width: 768px) {
    gap: 16px;`;
