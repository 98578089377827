import {
  ProjectListContainer,
  ProjectListBody,
  ProjectListItem,
  ProjectListText,
} from "./ProjectList.styles";
import { ProjectListRow } from "../../elements/ProjectListRow/ProjectListRow";
import { Project } from "../../../store/models/project";
import { groupPrereqProjects } from "../../../utils/projectUtils";

export interface ProjectListProps {
  projects: Project[];
  transactionCode: string;
}

export const ProjectList = ({
  projects,
  transactionCode,
}: ProjectListProps) => {
  if (!projects.length) return null;

  const [projectList, attachedProjectMap] = groupPrereqProjects(projects);

  return (
    <ProjectListContainer>
      <ProjectListText style={{ marginBottom: "6px" }} hasBorder weight>
        Songs list:
      </ProjectListText>
      <ProjectListBody>
        {projectList.map((project, index) => (
          <ProjectListItem key={index}>
            <ProjectListText isTertiary>{`${index + 1}.`}</ProjectListText>
            <ProjectListRow
              project={project}
              transactionCode={transactionCode}
              attachedProjects={attachedProjectMap[project.id]}
            />
          </ProjectListItem>
        ))}
      </ProjectListBody>
    </ProjectListContainer>
  );
};
