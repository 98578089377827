import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import { useGetTeams } from "../../../hooks/useTeam";
import { useAppSelector } from "../../../store/hooks";
import User from "../../../store/models/user";
import { imagePathPrefix } from "../../../store/utils";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { getProfileScreenRoute } from "../../../store/utils/routeGetters";
import defaultUser from "../../../stories/assets/defaultuser.jpg";
import { UniqueBreakpoints } from "../../../utils/breakpoints";
import { DashboardCardHeader } from "../DashboardCardHeader/DashboardCardHeader";
import SidePanelCloseButton from "../ProjectFilters/SidePanelFilters/SidePanelCloseButton";
import { SidePanel } from "../SidePanel/SidePanel";
import { StudioTeammatesModal } from "../StudioTeammatesModal/StudioTeammatesModal";
import { TeamPanel } from "../TeamPanel/TeamPanel";
import "./StudioTeammates.css";

export interface StudioTeammatesProps {
  managerUsers: User[];
  engineerUsers: User[];
}

export const StudioTeammates = ({
  managerUsers,
  engineerUsers,
}: StudioTeammatesProps) => {
  const filteredTeammates = managerUsers
    .concat(engineerUsers)
    .filter(
      (val, idx, self) =>
        idx === self.findIndex((t) => t.username === val.username),
    );

  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [useFallback, setUseFallback] = useState<boolean>(false);
  const redirectToUserProfile = (username: string) => {
    history.push(getProfileScreenRoute(username));
  };
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const { associatedTeams, loading: isLoadingTeam } = useGetTeams();
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const isMobile = useMediaQuery(UniqueBreakpoints.DashboardMobile);

  const currentTeam = useMemo(() => {
    return associatedTeams.find(
      (t) => t.studio_id === selectedProfile?.studio?.id,
    );
  }, [associatedTeams, selectedProfile?.studio?.id]);
  return (
    <div className="studio-teammates-container">
      <div className="studio-teammates-label label-semi-bold">
        Our Teammates & Affiliates
      </div>
      <div className="b1">Our recommended affiliates and team members.</div>
      <div className="studio-teammates-images">
        {filteredTeammates.map((user) => {
          return (
            <div
              className="studio-teammates-image"
              key={user.id}
              onClick={() => {
                redirectToUserProfile(user.username);
              }}
            >
              <img
                onError={() => {
                  setUseFallback(true);
                }}
                src={
                  useFallback
                    ? defaultUser
                    : user.photo?.path
                      ? imagePathPrefix + user.photo?.path
                      : defaultUser
                }
                alt="studio teammate profile"
              />
              <div>
                <p className="studio-teammates-images-username">
                  {getDisplayableNameForUser(user)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="studio-teammates-view-all h8-semi-bold"
        onClick={() => setShowModal(true)}
      >
        View All
      </div>
      <StudioTeammatesModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        managerUsers={managerUsers}
        engineerUsers={engineerUsers}
        setIsSidePanelOpen={setIsSidePanelOpen}
      />
      <SidePanel
        isOpen={isSidePanelOpen}
        onClose={() => setIsSidePanelOpen(false)}
        fillScreen={isMobile}
      >
        <DashboardCardHeader
          title={`Your Team (${(currentTeam?.members.length || 0) + (currentTeam?.managers.length || 0)})`}
          showEmptyTitle
          style={{ margin: 0, minHeight: "25px" }}
          actionButton={
            <SidePanelCloseButton
              onClick={() => {
                setIsSidePanelOpen(false);
              }}
            />
          }
        />
        <TeamPanel
          currentTeam={currentTeam}
          isLoading={isLoadingTeam}
          onSidePanel={true}
        />
      </SidePanel>
    </div>
  );
};
