import "./TransactionBookingScreen.css";

import { setLinkShareStatus } from "../../../store/actions/generateBookingStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";

interface TransactionBookingUpdateWarningModalProps {
  transactionId: number | undefined;
  isUpdateWarningModalOpen: boolean;
  closeUpdateWarningModal: () => void;
  linkShareStatus: null | "copy" | "email";
}

const TransactionBookingUpdateWarningModal = ({
  transactionId,
  isUpdateWarningModalOpen,
  closeUpdateWarningModal,
  linkShareStatus,
}: TransactionBookingUpdateWarningModalProps) => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);

  const onConfirm = () => {
    emitAnalyticsTrackingEvent(
      "booking_flow-confirm_update_warning_modal",
      {
        transactionId,
      },
      loggedInUser?.id,
    );
    dispatch(setLinkShareStatus(null));
    closeUpdateWarningModal();
  };

  if (!linkShareStatus) {
    return null;
  }

  return (
    <BaseModal
      open={isUpdateWarningModalOpen}
      setOpen={closeUpdateWarningModal}
      showModalFooter
      onCancel={closeUpdateWarningModal}
      onConfirm={onConfirm}
      header="Confirm Booking Changes"
    >
      {`Are you sure you want to make changes? The details on the link that was
      previously ${linkShareStatus === "copy" ? "copied" : "emailed"} will be updated with your changes.`}
    </BaseModal>
  );
};

export default TransactionBookingUpdateWarningModal;
