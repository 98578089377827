import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSessionUserRoles } from "../../../hooks/useSessionUsers";
import {
  cancelSessionAndIssueAssociatedRefund,
  getCancellationRefundAmount,
} from "../../../store/actions/recording";
import { useAppDispatch } from "../../../store/hooks";
import { RecordingSession } from "../../../store/models/recordingSession";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { getMyBookingsRoute } from "../../../store/utils/routeGetters";
import { CancellationPolicyText } from "../../elements/CancellationPolicyText/CancellationPolicyText";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";

export const useCancelProject = (recordingSession: RecordingSession) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [refundPercentage, setRefundPercentage] = useState(0);
  const [refundAmount, setRefundAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cancelling, setCancelling] = useState<boolean>(false);
  const { project } = recordingSession;
  const projectId = project.id;

  useEffect(() => {
    if (!projectId) return;
    setLoading(true);
    void dispatch(getCancellationRefundAmount({ projectId }))
      .unwrap()
      .then((result) => {
        setLoading(false);
        setRefundPercentage(result.refund_percentage);
        setRefundAmount(result.refund_amount);
      });
  }, [projectId]);

  const getRefundText = (refundPercentage: number, refundAmount: number) => {
    if (refundPercentage === 1) {
      return " there will be a full refund sent back to the original payment method.";
    }
    if (refundPercentage === 0) {
      return " no refund will be issued.";
    }
    return ` there will be a ${
      refundPercentage * 100
    }% refund (${PennyDollarFormatter().format(
      refundAmount,
    )}) back to the original payment method.`;
  };

  const handleCancelSession = useCallback(() => {
    if (!recordingSession) return false;
    if (!projectId) return false;
    setCancelling(true);
    void dispatch(
      cancelSessionAndIssueAssociatedRefund({ project_id: projectId }),
    )
      .unwrap()
      .then(() => {
        setCancelling(false);
        toast.success(
          "Session cancelled. If a refund is owed, it will be issued within 48 hours.",
        );
        history.push(getMyBookingsRoute("sessions"));
      });
  }, [recordingSession, projectId, dispatch, history]);

  return {
    getRefundText,
    handleCancelSession,
    refundPercentage,
    refundAmount,
    loading,
    cancelling,
  };
};

interface CancelProjectContentProps {
  recordingSession: RecordingSession;
  loading: boolean;
  getRefundText: (refundPercentage: number, refundAmount: number) => string;
  refundPercentage: number;
  refundAmount: number;
}

export const CancelProjectContent = ({
  recordingSession,
  loading,
  getRefundText,
  refundAmount,
  refundPercentage,
}: CancelProjectContentProps) => {
  const { isEngineer } = useSessionUserRoles(recordingSession);

  return (
    <div>
      {loading ? (
        <SoundWaveLoader width={100} height={100} />
      ) : (
        <p className="mb-3">
          <strong>
            Are you sure that you want to cancel this recording session?
          </strong>
          <br />
          <br />
          {recordingSession.cancellation_policy && !isEngineer && (
            <div>
              <CancellationPolicyText
                cancellationPolicy={recordingSession.cancellation_policy}
              />
              <br />
            </div>
          )}
          Based on the cancellation policy, if you cancel now,
          {getRefundText(refundPercentage, refundAmount)}
        </p>
      )}
    </div>
  );
};
