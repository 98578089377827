import { useMutation } from "@tanstack/react-query";
import queryString from "query-string";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { exceptionCodeErrorStringMap } from "../../store/models/exceptions";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { GET_PROFILE_COVER_PHOTO_SIGNED_URL } from "../../store/utils/routes";

export interface MutateFunctionProps {
  content_type: string;
  file_size: number;
  is_mobile?: boolean;
}

interface SignedUrlResponseProps {
  signed_url: string;
}

interface useGetProfilePhotoSignedUrlProps {
  screenType: "mobile" | "desktop";
}

export const useGetProfilePhotoSignedUrl = ({
  screenType,
}: useGetProfilePhotoSignedUrlProps) => {
  return useMutation({
    mutationKey: [QUERY_KEYS.UPLOAD_PROFILE_COVER_PHOTO, screenType],
    mutationFn: async ({
      content_type,
      file_size,
      is_mobile = false,
    }: MutateFunctionProps) => {
      return makeBackendGetCallWithJsonResponse<SignedUrlResponseProps>(
        GET_PROFILE_COVER_PHOTO_SIGNED_URL,
        `?${queryString.stringify({
          content_type,
          file_size,
          is_mobile,
        })}`,
      ).then((res) => {
        if (!res.success) {
          const knownError = exceptionCodeErrorStringMap.get(
            res.resultJson.code,
          );
          if (knownError) {
            throw new Error(knownError);
          } else {
            throw new Error(
              "There was an error generating profile cover photo signed URL.",
            );
          }
        }

        return res.resultJson;
      });
    },
  });
};

interface uploadServicePhotoProps {
  url: string;
  file: File;
}

interface useUploadProfileCoverPhotoProps {
  screenType: "mobile" | "desktop";
}

export const useUploadProfileCoverPhoto = ({
  screenType,
}: useUploadProfileCoverPhotoProps) => {
  return useMutation({
    mutationKey: [QUERY_KEYS.UPLOAD_PROFILE_COVER_PHOTO, screenType],
    mutationFn: async ({ url, file }: uploadServicePhotoProps) => {
      return fetch(url, { body: file, method: "PUT" }).then((res) => {
        if (!res.ok) {
          throw new Error("There was an error processing the request");
        }

        return null;
      });
    },
  });
};
