import { useQuery } from "@tanstack/react-query";
import { fetchMyStudios } from "./fetchMyStudios";

const useFetchMyStudiosQuery = () => {
  return useQuery({
    queryKey: ["fetchMyStudios"],
    queryFn: async () => fetchMyStudios(),
  });
};

export default useFetchMyStudiosQuery;
