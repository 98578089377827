import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { MusoProfileEntity } from "../../store/models/muso";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { MUSO_GET_ROSTER } from "../../store/utils/routes";

export const useGetMusoRoster = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_MUSO_ROSTER],
    queryFn: async () => {
      return makeBackendGetCallWithJsonResponse<MusoProfileEntity[]>(
        MUSO_GET_ROSTER,
        "",
      ).then((res) => {
        if (!res.success) {
          throw res.resultJson;
        }
        return res.resultJson;
      });
    },
  });
};
