import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Text } from "../../core-ui/components/Text/Text";
import {
  MusoCreditsContainer,
  MusoCreditsEmptyContainer,
  MusoCreditsMoreContainer,
} from "./MusoCreditsTable.styles";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  Box,
  Grid,
  useMediaQuery,
  Theme,
  useTheme,
  Skeleton,
} from "@mui/material";
import { MusoCredit } from "../../../store/models/muso";
import { AlbumIcon, MusoCreditInfo } from "../../elements/AlbumIcon/AlbumIcon";
import poweredByMuso from "./../../assets/powered_by_muso.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListMusic } from "@fortawesome/pro-solid-svg-icons";
import poweredByMusoDarkTheme from "./../../assets/powered_by_muso_white.svg";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  FooterFileTrackType,
  setIndex,
  setPlaylist,
} from "../../../store/actions/abPlayerStore";
import { convertMusoCreditsToPlaylistTrack } from "../../../store/models/playListTrack";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { useParams } from "react-router-dom";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { usePlayPauseOnFooter } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { useEffect } from "react";

const PAGE_SIZE = 6;

interface MusoCreditsTableProps {
  onClick: () => void;
  credits: MusoCredit[];
  count: number;
  editMode: boolean;
  showMoreDisabled: boolean;
  children: React.ReactNode;
  isLoading: boolean;
}

const MusoCreditSkeleton = () => {
  return (
    <Box sx={{ display: "flex", gap: "16px" }}>
      <Skeleton
        animation="wave"
        height={64}
        width={64}
        sx={{ transform: "none" }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Skeleton animation="wave" width={64} height={16} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Skeleton animation="wave" width={64} />
          <Skeleton animation="wave" width={64} />
        </Box>
      </Box>
    </Box>
  );
};

export const MusoCreditsTable = ({
  onClick,
  credits,
  count,
  editMode,
  showMoreDisabled,
  children,
  isLoading,
}: MusoCreditsTableProps) => {
  const isTabletOrBelow = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );
  const theme = useTheme();
  const darkMode = useAtomValue(darkModeAtom);
  const dispatch = useAppDispatch();
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const {
    playlistId,
    currentTrackIndex,
    playlist,
    isFooterReady,
    isFooterPlaying,
    playOnLoad,
  } = useAppSelector((state) => state.abPlayerStore);

  const { handleClick } = usePlayPauseOnFooter();

  useEffect(() => {
    if (!userData) return;
    if (
      isFooterReady &&
      userData.id === playlistId &&
      !isFooterPlaying &&
      playOnLoad
    ) {
      handleClick();
    }
    // Explictly only rerun if isFooterReady. When it is toggled, we know that footer is ready to play.
  }, [isFooterReady]);

  if (isLoading || !userData) {
    return <MusoCreditSkeleton />;
  }
  if (!editMode && credits.length === 0) {
    return (
      <MusoCreditsEmptyContainer>
        <FontAwesomeIcon
          style={{ height: "27px", width: "27px" }}
          color={theme.palette.standardColor.Gray[300]}
          icon={faListMusic}
        />
        <Text variant={TextStyleVariant.P1}> No credits yet</Text>
      </MusoCreditsEmptyContainer>
    );
  }

  return (
    <MusoCreditsContainer>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Text variant={TextStyleVariant.S2}>Credits</Text>
        <img
          src={darkMode ? poweredByMusoDarkTheme : poweredByMuso}
          alt={"Powered by Muso"}
        />
      </Box>
      {children}
      <Grid container sx={{ rowGap: "16px" }}>
        {credits.map((credit) => (
          <Grid
            sx={{ marginBottom: "16px" }}
            key={credit.id}
            xs={12}
            sm={6}
            item
          >
            <AlbumIcon
              id={credit.id}
              showPlay={Boolean(credit.spotify_preview_url)}
              onClick={() => {
                if (!credit.spotify_preview_url) {
                  return;
                }
                const tracks = convertMusoCreditsToPlaylistTrack(
                  credits,
                  getDisplayableNameForUser(userData),
                );
                let filteredIndex = tracks.findIndex(
                  (track) => track.id === credit.id,
                );
                if (filteredIndex === -1) {
                  filteredIndex = 0;
                }
                if (
                  playlistId !== userData.id ||
                  (tracks.length !== playlist.length &&
                    playlistId === userData.id)
                ) {
                  dispatch(
                    setPlaylist({
                      tracks: tracks,
                      playlistId: userData.id,
                      index: filteredIndex,
                      playOnLoad: true,
                      footerFileTrackType: FooterFileTrackType.SPOTIFY_SNIPPET,
                    }),
                  );
                  return;
                }
                if (filteredIndex !== currentTrackIndex) {
                  dispatch(setIndex(filteredIndex));
                  return;
                }
                handleClick();
              }}
              albumName={credit.album_title}
              imageSource={credit.album_art}
            >
              <MusoCreditInfo
                trackTitle={credit.track_title}
                artistName={credit.artist}
                releaseDate={credit.release_date}
                role={credit.role}
              />
            </AlbumIcon>
          </Grid>
        ))}
      </Grid>
      {count > PAGE_SIZE && (
        <MusoCreditsMoreContainer>
          <Button
            onClick={onClick}
            variant={ButtonVariant.OUTLINED}
            disabled={showMoreDisabled}
          >
            {" "}
            {!isTabletOrBelow ? "View more" : "Show More"}{" "}
          </Button>
        </MusoCreditsMoreContainer>
      )}
    </MusoCreditsContainer>
  );
};
