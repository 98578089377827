import { useMemo } from "react";
import { useIsAffiliatedManagerWithStudio } from "../../../../hooks/studioHooks";
import { useIsAandR } from "../../../../hooks/useIsAandR";
import { toggleDurationModal } from "../../../../store/actions/recordingBookingMobileState";
import { updateDurationAtIndex } from "../../../../store/actions/shoppingCart";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { RecordingService } from "../../../../store/models/recording";
import { selectCurrentlySelectedSessionData } from "../../../../store/selectors/shoppingCart";
import { generatePreferredHoursOptions } from "../../../../store/utils/serviceUtils";
import { minutesFromHours } from "../../../../store/utils/shoppingCartUtils";
import { RecordingDetailsMobileMenu } from "../RecordingDetailsMobileMenu/RecordingDetailsMobileMenu";

export const RecordingDetailSelectDurationMobileMenu = ({
  studioId,
  recordingService,
}: {
  studioId: number | undefined;
  recordingService: RecordingService | null;
}) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const isAnRAccount = useIsAandR(user);
  const activeStudio = useAppSelector(
    (state) => state.studiosSlice[studioId ?? -1],
  );
  const userIsMe = useIsAffiliatedManagerWithStudio(activeStudio);
  const { minimum_session_time_minutes, maximum_session_time_minutes } =
    useMemo(() => {
      return recordingService
        ? recordingService
        : {
            minimum_session_time_minutes: 30,
            maximum_session_time_minutes: 120,
          };
    }, [recordingService]);
  const isVisible = useAppSelector(
    (state) => state.recordingBookingMobileState.showDurationModal,
  );

  const currentSession = useAppSelector(selectCurrentlySelectedSessionData);
  const selectedSessionIndex = useAppSelector(
    (state) => state.shoppingCart.selectedIndex,
  );

  const preferredHourOptions = useMemo(() => {
    if (!recordingService) return [];
    return generatePreferredHoursOptions(
      minimum_session_time_minutes,
      maximum_session_time_minutes,
      isAnRAccount && !userIsMe,
      recordingService?.service_rate,
      recordingService.recording_service_discount_rate,
    );
  }, [
    recordingService,
    isAnRAccount,
    userIsMe,
    minimum_session_time_minutes,
    maximum_session_time_minutes,
  ]);
  const dispatch = useAppDispatch();

  return (
    <RecordingDetailsMobileMenu
      isVisible={isVisible}
      onClose={() => {
        dispatch(toggleDurationModal());
      }}
    >
      <div className="recording-detail-mobile-menu-container">
        <header>
          <h1 className="h7-semi-bold">Select Duration</h1>
        </header>
        {preferredHourOptions.map((option, index) => {
          const optionMinutes = minutesFromHours(option.value);
          const isSelected = currentSession?.duration === optionMinutes;
          return (
            <div
              key={index}
              onClick={() => {
                if (!isSelected && selectedSessionIndex !== undefined) {
                  dispatch(
                    updateDurationAtIndex({
                      index: selectedSessionIndex,
                      duration: optionMinutes,
                    }),
                  );
                }
                dispatch(toggleDurationModal());
              }}
            >
              <p
                className={"b2-semi-bold ".concat(
                  isSelected ? "selected-option-text" : "",
                )}
              >
                {option.label}
              </p>
            </div>
          );
        })}
      </div>
    </RecordingDetailsMobileMenu>
  );
};
