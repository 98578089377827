import { format } from "date-fns-tz";
import { format as formatFns } from "date-fns";
import {
  BasicRecordingSession,
  RecordingSession,
} from "../models/recordingSession";

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const weekdaysFull = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function getWeekdayStringFromDate(date: Date, useFull = false) {
  if (useFull) {
    return weekdaysFull[date.getDay()];
  }
  return weekdays[date.getDay()];
}

function getMonthNameFromDate(date: Date) {
  return monthNames[date.getMonth()];
}

export const getMonthAndYearFromDate = (date: Date) => {
  return getMonthNameFromDate(date) + ` ${date.getFullYear()}`;
};

const getCurrentDate = function (): Date {
  const today = new Date();
  today.setHours(
    0 /* hours */,
    0 /* minutes */,
    0 /* seconds */,
    0 /* milliseconds */,
  );
  return today;
};

const isDateWhitelisted = (date: Date, isPredefined: boolean): boolean => {
  const dateToCheck = new Date(date);
  const today = new Date();
  const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
  const isTomorrow = dateToCheck.getUTCDate() === tomorrow.getUTCDate();
  const isToday = dateToCheck.getUTCDate() === today.getUTCDate();
  return (isToday || isTomorrow) && isPredefined;
};

const getNDaysFromNow = function (N: number): Date {
  const currentDate = getCurrentDate();
  currentDate.setDate(currentDate.getDate() + N - 1);
  return currentDate;
};

const getNDaysFromDate = function (N: number, date: Date): Date {
  date.setDate(date.getDate() + N - 1);
  return date;
};

const getLastDayOfTwoMonthsFromNow = function (): Date {
  const today = getCurrentDate();
  // Get two months from this month and take advantage of zero index to set the date to the last day of the previous month.
  return new Date(today.getFullYear(), today.getMonth() + 3, 0);
};

const getFormattedDate = (date: Date) => {
  date.setUTCHours(0, 0, 0, 0);
  return date.toISOString().substring(0, 19) + "Z";
};

const getFormattedDateTime = (date: Date) => {
  return date.toISOString().substring(0, 19) + "Z";
};

const getFormattedDateString = (date: Date) => {
  date.setUTCHours(0, 0, 0, 0); // Set the date to midnight.
  return date.toISOString().substring(0, 19) + "Z";
};

const getDateFromString = (date: string) =>
  new Date(new Date(date).setHours(0, 0, 0, 0));

const convertUTCDateToLocalDate = (date: Date) => {
  const newDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000,
  );

  return newDate;
};

const convertLocalDateToUTCDate = (date: Date) => {
  const newDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000,
  );

  return newDate;
};

const removeSecondsFromTime = (time: string) => {
  return (
    time.substring(0, time.lastIndexOf(":")) +
    time.substring(time.length - 2).toLocaleLowerCase()
  );
};

const getFormattedTimeStringWithoutDateFromLocalDate = (date: Date) => {
  const formatedDate = convertUTCDateToLocalDate(date);
  return removeSecondsFromTime(formatedDate.toLocaleTimeString());
};

const getFormattedTimeStringWithTimezone = (
  date: Date,
  timezone?: string,
  showTimezone = false,
) => {
  return format(date, `hh:mmaaa ${timezone && showTimezone ? "(z)" : ""}`, {
    timeZone: timezone,
  });
};

const removeTimeFromDate = (date: Date): string => {
  const localDate = convertUTCDateToLocalDate(date);
  const dateString = localDate.toLocaleString();
  return dateString.substring(0, dateString.indexOf(" "));
};

// Returns date in yyyy-mm-dd format
const getAvailabilityFormattedDateString = (date: Date | string): string => {
  const dateString = new Date(date).toISOString().split("T")[0];
  return dateString;
};

// Returns date in yyyy-mm-dd format from the Date Obj
// We need this util because the above function uses `toISOString()`, which converts the date to UTC time zone
// While the keys we use in availability redux store is in local time zone
const getLocalFormattedDateStringFromDateObj = (localDateObj: Date) => {
  return format(localDateObj, "yyyy-MM-dd");
};

const getDateTimeFrom15MinuteDayIndex = (
  index: number,
  selectedDate = new Date(),
) => {
  if (index === undefined || index < 0) {
    return null;
  }
  let totalMinutes = index * 15;
  if (totalMinutes % 30 !== 0) {
    totalMinutes += 15;
  }
  const date = new Date(selectedDate);
  date.setHours(0, 0);
  date.setMinutes(totalMinutes);
  return date;
};

const get15MinuteDayIndexFromDateTime = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return hours * 4 + Math.floor(minutes / 15);
};

const getDisplayableTimeFrom15MinuteDayIndex = (
  index: number,
  timezone?: string,
  showTimezone = false,
) => {
  const date = getDateTimeFrom15MinuteDayIndex(index);
  return date
    ? format(date, `h:mm a${timezone && showTimezone ? ` (z)` : ""}`, {
        timeZone: timezone,
      })
    : "";
};

const getTimeZoneName = (timeZone: string | undefined) => {
  if (!timeZone) {
    return "";
  }
  const opts: Intl.DateTimeFormatOptions = {
    timeZoneName: "short",
    timeZone: timeZone,
  };
  return Intl.DateTimeFormat("en-EN", opts).format(Date.now()).split(",")[1];
};

const TIME_IN_A_DAY_MS = 24 * 60 * 60 * 1000;

const isAtLeastNDaysAway = (date: Date, days = 3): boolean => {
  const today = new Date();
  // Create today's and date's UTC dates at the start of the day
  const todayUtc = new Date(
    Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()),
  );
  const dateUtc = new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
  );
  // Calculate time difference in days
  const timeDifference = dateUtc.getTime() - todayUtc.getTime();
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24) + 1;
  // Check if the difference is at least n days
  return daysDifference >= days;
};

const getSessionFirstChoiceAsUTCDate = (
  session: Pick<RecordingSession, "first_choice_datetime">,
) => {
  return new Date(
    session.first_choice_datetime.endsWith("Z")
      ? session.first_choice_datetime
      : session.first_choice_datetime + "Z",
  );
};

const getSessionStartTime = (
  session: RecordingSession | BasicRecordingSession,
) => {
  const startTimeDate = getSessionFirstChoiceAsUTCDate(session);
  return getFormattedTimeStringWithTimezone(
    startTimeDate,
    session.studio_room?.studio?.timezone,
  );
};

const getSessionEndTime = (
  session: RecordingSession | BasicRecordingSession,
) => {
  const endTime = getSessionFirstChoiceAsUTCDate(session);
  const duration = session.session_duration_minutes;
  endTime.setMinutes(endTime.getMinutes() + duration);
  return getFormattedTimeStringWithTimezone(
    endTime,
    session.studio_room?.studio?.timezone,
    true,
  );
};

const formatDateToShortStringFormat = (dateString: string) => {
  const date = new Date(dateString);
  return formatFns(date, "MMM d, yyyy");
};

export {
  TIME_IN_A_DAY_MS,
  convertLocalDateToUTCDate,
  convertUTCDateToLocalDate,
  get15MinuteDayIndexFromDateTime,
  getAvailabilityFormattedDateString,
  getCurrentDate,
  getDateFromString,
  getDateTimeFrom15MinuteDayIndex,
  getDisplayableTimeFrom15MinuteDayIndex,
  getFormattedDate,
  getFormattedDateString,
  getFormattedDateTime,
  getFormattedTimeStringWithoutDateFromLocalDate,
  getFormattedTimeStringWithTimezone,
  getLastDayOfTwoMonthsFromNow,
  getLocalFormattedDateStringFromDateObj,
  getMonthNameFromDate,
  getNDaysFromDate,
  getNDaysFromNow,
  getSessionFirstChoiceAsUTCDate,
  getSessionStartTime,
  getSessionEndTime,
  getTimeZoneName,
  getWeekdayStringFromDate,
  isDateWhitelisted,
  removeSecondsFromTime,
  isAtLeastNDaysAway,
  removeTimeFromDate,
  formatDateToShortStringFormat,
};
