import { useEffect, useState } from "react";
import { useQuery } from "../../../hooks/useQuery";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import {
  verifyAREmail,
  verifyAccountEmail,
} from "../../../store/actions/accountInfo";
import { useAppDispatch } from "../../../store/hooks";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { VerifyEmailContainer } from "./VerifyEmailScreen.styles";

export const VerifyEmailScreen = () => {
  const dispatch = useAppDispatch();
  const [emailVerified, setEmailVerified] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [isAREmail, setIsAREmail] = useState(false);

  useSetPageTitle("Email Verification");

  const query = useQuery();

  useEffect(() => {
    const verification = query.get("verification");
    const user_id = query.get("user_id");
    const admin = query.get("admin");
    if (admin && user_id && verification) {
      setIsAREmail(true);
      dispatch(
        verifyAREmail({
          verification,
          user_id,
        }),
      )
        .then(() => {
          setEmailVerified(true);
        })
        .catch(() => {
          setVerificationError(true);
        });
    } else if (verification && user_id) {
      dispatch(
        verifyAccountEmail({
          verification,
          user_id,
        }),
      )
        .then(() => {
          setEmailVerified(true);
        })
        .catch(() => {
          setVerificationError(true);
        });
    }
  }, [dispatch, query]);

  if (emailVerified)
    return (
      <VerifyEmailContainer className="container-fluid">
        <h1>Thank you!</h1>
        <p>
          {isAREmail
            ? "Email has been verified."
            : "Your email address has been verified."}
        </p>
      </VerifyEmailContainer>
    );

  if (verificationError)
    return (
      <VerifyEmailContainer className="container-fluid">
        <h1>Email verification failed</h1>
        <p>Please try clicking the link in your email again.</p>
      </VerifyEmailContainer>
    );

  return (
    <LoadingScreen sx={{ padding: 2, boxSizing: "border-box" }}>
      <p className="text-center">
        {isAREmail
          ? "Verifying AR email... Please do not exit or refresh the page."
          : "Verifying your email... Please do not exit or refresh the page."}
      </p>
    </LoadingScreen>
  );
};
