import { VerifiedIcon } from "../../elements/VerifiedIcon/VerifiedIcon";
import reviewStar from "../../assets/orange-rating-star.svg";
import { UserProfileImage } from "../../elements/UserProfileImage/UserProfileImage";
import "./UserCard.css";
import { useAppDispatch } from "../../../store/hooks";
import { SetStateAction, useEffect, useState } from "react";
import { downloadReviewStats } from "../../../store/actions/stats";
import { unwrapResult } from "@reduxjs/toolkit";
import { useUserDisciplinesString } from "../../../hooks/user";
import User from "../../../store/models/user";
import { SelectedProfileState } from "../../../store/actions/selectedProfile";

export interface UserCardProps {
  loggedInUser: User;
  selectedProfile: SelectedProfileState;
}

export const UserCard = ({ loggedInUser, selectedProfile }: UserCardProps) => {
  const { studio, user } = selectedProfile;
  const username = studio ? studio.username : user?.username;
  const source = studio ? studio.photo?.path : user?.photo?.path;
  const isEntityPhoto = !!studio;
  const displayName = studio
    ? studio.studio_profile?.display_name || studio.username
    : user?.profile?.display_name || user?.username;
  const memberSince = studio?.created
    ? new Date(studio.created).getFullYear().toString()
    : user?.date_joined
      ? new Date(user.date_joined).getFullYear().toString()
      : "";
  // TODO: Will need to add a better way to check for any user type verification
  const isVerified = studio
    ? studio?.verified !== null
    : user?.engineer?.verified !== null;

  // Fetch review stats
  const [averageRating, setAverageRating] = useState<number>(0);
  const [reviewCount, setReviewCount] = useState<number>(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (studio) {
      dispatch(
        downloadReviewStats({
          studio_id: studio.id,
          page: 1,
        }),
      )
        .then(unwrapResult)
        .then(
          (result: {
            average_rating: SetStateAction<number>;
            ratings_count: SetStateAction<number>;
          }) => {
            setAverageRating(result.average_rating);
            setReviewCount(result.ratings_count);
          },
        );
    } else if (user) {
      dispatch(
        downloadReviewStats({
          engineer_id: user?.engineer?.id,
          page: 1,
        }),
      )
        .then(unwrapResult)
        .then((result) => {
          setAverageRating(result.average_rating);
          setReviewCount(result.ratings_count);
        });
    }
  }, [studio, user]);

  // Get user disciplines types (engineer, artist, producer, a&r, studio owner)
  // If the selected profile is a user then we get the disciplines from the user object
  // If the selected profile is a studio then we set the disciplines to "Studio Owner"
  let selectedProfileDisciplines: string[] = [];
  const userDisciplines = useUserDisciplinesString(loggedInUser);
  if (studio) {
    selectedProfileDisciplines = ["Studio"];
  }

  return (
    <div className="user-card-container">
      <div className="profile-icon">
        <UserProfileImage
          username={username}
          source={source}
          isEntityPhoto={isEntityPhoto}
          isCircle={true}
          editMode={false}
          height={64}
          width={64}
        />
      </div>
      <div className="profile-info">
        <div className="profile-username-display">
          <p>{displayName}</p>
          {isVerified && <VerifiedIcon verified={isVerified} />}
        </div>
        <div className="account-types">
          {user ? (
            <span>{userDisciplines}</span>
          ) : (
            <span>{selectedProfileDisciplines}</span>
          )}
        </div>
        <div className="profile-stats">
          <span>Member since: {memberSince}</span>
          <div className="profile-rating">
            <img src={reviewStar} alt="rating star" />
            <span>{averageRating}</span>
            <span>({reviewCount})</span>
          </div>
        </div>
      </div>
    </div>
  );
};
