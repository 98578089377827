import { useCallback, useRef, useState } from "react";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useAppSelector } from "../../../store/hooks";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { CampaignHeader } from "../CampaignHeader/CampaignHeader";
import { DashboardCard } from "../Dashboard/DashboardCard/DashboardCard";
import { PromoList } from "../PromoList/PromoList";
import "./CampaignCard.css";
import { Slide } from "@mui/material";
import { CreatePromoCodeForm } from "../CreatePromoCodeForm/CreatePromoCodeForm";
import { CreatePromoCodeFormWrapper } from "../CreatePromoCodeForm/CreatePromoCodeForm.styled";

const timeout = 1000;

export const CampaignCard = () => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const [addingPromoCode, setAddingPromoCode] = useState(false);
  const { isMobile } = useMediaQueryBreakpoint();
  const selectedStudioProfile = useAppSelector(
    (state) => state.selectedProfileSlice.studio,
  );
  const handlePromoCodeSubmitted = useCallback(() => {
    window.analytics.track(getDebugEventPrefix + "updated_custom_promo_code", {
      user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
      studio_id: selectedStudioProfile?.id,
    });
    setAddingPromoCode((currValue) => !currValue);
  }, [user, selectedStudioProfile]);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <DashboardCard
      cardHeight={390}
      contentOnly={isMobile}
      headerText={`Promo Codes ${selectedStudioProfile?.username ? `for ${selectedStudioProfile.username}` : ""}`}
      noHeaderIcon
      flex={true}
      contentRef={ref}
    >
      {addingPromoCode && (
        <Slide
          direction="down"
          in={addingPromoCode}
          unmountOnExit
          timeout={timeout}
          container={ref.current}
        >
          <CreatePromoCodeFormWrapper>
            <CreatePromoCodeForm
              onPromoCodeAdded={handlePromoCodeSubmitted}
              onCancel={() => {
                setAddingPromoCode(false);
              }}
            />
          </CreatePromoCodeFormWrapper>
        </Slide>
      )}
      {!addingPromoCode && (
        <Slide
          direction="down"
          in={!addingPromoCode}
          timeout={timeout}
          unmountOnExit
          container={ref.current}
        >
          <div className="campaign-card-container">
            <CampaignHeader />
            <PromoList />
            <Button
              className="add-promo-code-button"
              fullWidth
              variant={ButtonVariant.PRIMARY}
              onClick={handlePromoCodeSubmitted}
            >
              Add Promo Code
            </Button>
          </div>
        </Slide>
      )}
    </DashboardCard>
  );
};
