import engineerFirstIcon from "../../stories/assets/engineer_first_icon.svg";
import engineerSecondIcon from "../../stories/assets/engineer_second_icon.svg";
import engineerThirdIcon from "../../stories/assets/engineer_third_icon.svg";
import AccountTypeBase from "./base";

export enum EngineerTypeChoice {
  NO_TYPE,
  RECORDING,
  MIXING,
  MASTERING,
}

interface EngineerType {
  created: string;
  deleted?: string | null;
  engineer_type: EngineerTypeChoice;
}

export default interface Engineer extends AccountTypeBase {
  id: number;
  verified?: string | null;
  not_booking?: string | null;
  completed_survey: string | null;
  started_onboarding: string | null;
  engineer_type?: EngineerType[];
  is_dolby_certified?: string | null;
  can_host_dolby_services?: string | null;
  can_host_recording_services?: string | null;
  subscription_plan_choice?: number;
  stripe_subscription_status?: string | null;
  agreed_to_file_handling_requirements?: string | null;
  competitions_opted_in?: string | null;
  has_active_recording_service: boolean;
  last_completed_project?: string | null;
  stripe_subscription_id?: string | null;
}

export const MockEngineer: Engineer = {
  has_active_recording_service: true,
  user_id: 1,
  created: "2021-11-08T21:57:50.861208",
  deleted: undefined,
  verified: "2020-12-29T07:45:26.928778",
  not_booking: undefined,
  completed_survey: "2021-11-08T22:00:44.331927",
  started_onboarding: null,
  id: 3,
  engineer_type: [
    {
      created: "2021-11-08T21:57:59.952312",
      deleted: null,
      engineer_type: 2,
    },
  ],
  is_primary_type: null,
  is_dolby_certified: "2021-11-08T21:57:59.952312",
  can_host_recording_services: "2021-11-08T21:57:59.952312",
};

export const engineerPlatformDetails = [
  {
    image: engineerFirstIcon,
    headerText: "Connect with Artists",
    bodyText: "Music collaboration and project management all in one place.",
  },
  {
    image: engineerSecondIcon,
    headerText: "Take Care of Business",
    bodyText:
      "Business tools built for audio engineers to provide clients with clarity, from booking to project completion.",
  },
  {
    image: engineerThirdIcon,
    headerText: "Get Paid Fast",
    bodyText:
      "Easy and transparent payment options that never leave you waiting.",
  },
];

export const MockEngineerForRecording: Engineer = {
  user_id: 60,
  has_active_recording_service: true,
  created: "2021-11-08T21:57:50.861208",
  deleted: undefined,
  verified: "2020-12-29T07:45:26.928778",
  not_booking: undefined,
  completed_survey: "2021-11-08T22:00:44.331927",
  started_onboarding: null,
  id: 3,
  engineer_type: [
    {
      created: "2021-11-08T21:57:59.952312",
      deleted: null,
      engineer_type: 2,
    },
  ],
  is_primary_type: null,
  is_dolby_certified: "2021-11-08T21:57:59.952312",
  can_host_recording_services: "2021-11-08T21:57:59.952312",
};
