import React from "react";
import leftRequestWave from "../../assets/left-request-wave.svg";
import rightRequestWave from "../../assets/right-request-wave.svg";
import engineearsLogo from "../../assets/engineears-logo.svg";
import { Button } from "../../elements/Button/button";
import { RequestModal } from "../RequestModal/RequestModal";
import "./RequestStudio.css";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

export const RequestStudio = () => {
  const { isDesktop } = useMediaQueryBreakpoint();
  const [showRequestModal, setShowRequestModal] = React.useState(false);

  return (
    <>
      <div className="studio-screen-request-container">
        {isDesktop && (
          <img
            className="studio-screen-request-wave"
            src={leftRequestWave}
            alt="left-request-wave"
          />
        )}
        <div className="studio-screen-request-button">
          <img
            className="studio-screen-request-logo"
            src={engineearsLogo}
            alt="engineears-logo"
          />
          <p className="h6-semi-bold studio-request-centered-text">
            Not Finding Your Studio?
          </p>
          <p className="b1">Submit a Studio to the Waitlist</p>
          <Button
            label={"Request"}
            primary={false}
            onClick={setShowRequestModal}
          />
        </div>
        {isDesktop && (
          <img
            className="studio-screen-request-wave"
            src={rightRequestWave}
            alt="right-request-wave"
          />
        )}
      </div>
      <RequestModal
        show={showRequestModal}
        onClose={() => setShowRequestModal(false)}
      />
    </>
  );
};
