import { ScheduledProject } from "../../../../store/models/scheduledproject";
import {
  BreadCrumbContainer,
  BreadCrumbLink,
  BreadCrumbIcon,
} from "./ProjectHeader.styles";
import {
  getMyBookingsRoute,
  getProjectOverviewRoute,
} from "../../../../store/utils/routeGetters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";

const ProjectHeaderBreadCrumb = ({
  scheduledProject,
  code,
}: {
  scheduledProject: ScheduledProject;
  code?: string;
}) => {
  if (code) return null;
  return (
    <BreadCrumbContainer>
      <BreadCrumbLink to={getMyBookingsRoute("projects")}>
        MY BOOKINGS
      </BreadCrumbLink>
      <BreadCrumbIcon>
        <FontAwesomeIcon
          color="var(--medium-grey)"
          icon={faGreaterThan}
          size="2xs"
        />
      </BreadCrumbIcon>
      <BreadCrumbLink
        to={getProjectOverviewRoute(scheduledProject.id)}
        $isProjectTitle
      >
        {scheduledProject?.title.toUpperCase()}
      </BreadCrumbLink>
    </BreadCrumbContainer>
  );
};

export default ProjectHeaderBreadCrumb;
