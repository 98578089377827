import { useMemo } from "react";
import { useAvailableTrackingEngineers } from "../../../../hooks/bookingHooks/useAvailableTrackingEngineers";
import { useRecordingSessionUserType } from "../../../../hooks/bookingHooks/useRecordingSessionUserType";
import { toggleSelectEngineerModal } from "../../../../store/actions/recordingBookingMobileState";
import { updateEngineerAtIndex } from "../../../../store/actions/shoppingCart";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { RecordingService } from "../../../../store/models/recording";
import { StudioRoom } from "../../../../store/models/studio";
import {
  selectCurrentlySelectedSessionData,
  selectShoppingCartLatestSessionDuration,
} from "../../../../store/selectors/shoppingCart";
import {
  SelectEngineerOption,
  SelectEngineerOptionComponent,
} from "../../SelectAvailableTrackingEngineer/SelectAvailableTrackingEngineer";
import { RecordingDetailsMobileMenu } from "../RecordingDetailsMobileMenu/RecordingDetailsMobileMenu";

export const RecordingDetailsSelectTrackingEngineerMobileMenu = ({
  studioRoomId,
  recordingService,
  studioRoom,
}: {
  studioRoomId: number | undefined;
  recordingService: RecordingService | null;
  studioRoom: StudioRoom | undefined;
}) => {
  const isVisible = useAppSelector(
    (state) => state.recordingBookingMobileState.showSelectEngineerModal,
  );
  const dispatch = useAppDispatch();
  const currentSession = useAppSelector(selectCurrentlySelectedSessionData);
  const startEndTimeText = useMemo(() => {
    return (
      currentSession?.selectedDateOption?.label ||
      currentSession?.generalWorkingHourIntervalLabel
    );
  }, [currentSession]);

  const selectedSessionIndex = useAppSelector(
    (state) => state.shoppingCart.selectedIndex,
  );
  const dateTime = useMemo(() => {
    return currentSession?.selectedDateOption?.value
      ? new Date(currentSession.selectedDateOption?.value)
      : undefined;
  }, [currentSession?.selectedDateOption?.value]);
  const minimumSessionDuration = useAppSelector(
    selectShoppingCartLatestSessionDuration(recordingService),
  );
  const activeStudio = useAppSelector(
    (state) => state.studiosSlice[studioRoom?.studio?.id ?? -1],
  );
  const { isStudioManager } = useRecordingSessionUserType(
    activeStudio,
    currentSession?.trackingEngineer,
  );
  const { trackingEngineers, loading } = useAvailableTrackingEngineers(
    studioRoomId,
    dateTime,
    currentSession?.duration ?? minimumSessionDuration,
    currentSession?.selectedISODate,
    startEndTimeText,
    isStudioManager,
  );
  const allowsBookingWithoutEngineer =
    studioRoom?.studio?.allow_bookings_without_engineer;

  return (
    <RecordingDetailsMobileMenu
      isVisible={isVisible}
      onClose={() => {
        dispatch(toggleSelectEngineerModal());
      }}
    >
      <div className="recording-detail-mobile-menu-container">
        <header>
          <h1 className="h7-semi-bold">Select Engineer</h1>
        </header>

        {loading && (
          <div className="b2-semi-bold drop-down-option booking-detail-drop-down-empty">
            <p>Loading...</p>
          </div>
        )}
        {trackingEngineers.length === 0 &&
          !allowsBookingWithoutEngineer &&
          !loading && (
            <div className="b2-semi-bold drop-down-option booking-detail-drop-down-empty">
              <p>No engineers available</p>
            </div>
          )}
        {allowsBookingWithoutEngineer && !loading && (
          <div
            onClick={() => {
              if (selectedSessionIndex === undefined) return;
              dispatch(
                updateEngineerAtIndex({
                  markNoTrackingEngineer: true,
                  index: selectedSessionIndex,
                  engineer: undefined,
                }),
              );
              dispatch(toggleSelectEngineerModal());
            }}
            className="b2-semi-bold drop-down-option booking-detail-drop-down-empty"
          >
            <p>Session without a Tracking Engineer</p>
          </div>
        )}
        {!loading &&
          trackingEngineers.map(
            (engineerOption: SelectEngineerOption, currIndex: number) => (
              <div
                className={"b2-semi-bold drop-down-option ".concat(
                  engineerOption?.engineer?.id ===
                    currentSession?.trackingEngineer?.id
                    ? " engineer-selected "
                    : "",
                )}
                key={currIndex}
                onClick={() => {
                  if (selectedSessionIndex === undefined) return;
                  dispatch(
                    updateEngineerAtIndex({
                      index: selectedSessionIndex,
                      engineer: engineerOption.engineer,
                    }),
                  );
                  dispatch(toggleSelectEngineerModal());
                }}
              >
                <SelectEngineerOptionComponent
                  engineer={engineerOption.engineer}
                  serviceRate={engineerOption.serviceRate}
                  user={engineerOption.user}
                  studioAffiliated={true}
                  value={engineerOption.value}
                  label={engineerOption.label}
                />
              </div>
            ),
          )}
      </div>
    </RecordingDetailsMobileMenu>
  );
};
