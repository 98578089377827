import { useMemo } from "react";
import {
  AtmosProjectSteps,
  MasteringProjectSteps,
  MixingProjectSteps,
  ProjectType,
} from "../../store/models/project";

export const useIsProjectInMixOrRevision = (
  projectType: ProjectType,
  step: number,
) => {
  return useMemo(() => {
    if (projectType === ProjectType.MASTERING) {
      return (
        MasteringProjectSteps.IN_MASTER === step ||
        MasteringProjectSteps.MASTER_REVISION === step
      );
    } else if (projectType === ProjectType.ATMOS_MIXING) {
      return (
        AtmosProjectSteps.IN_MIX === step ||
        AtmosProjectSteps.MIX_REVISION === step ||
        AtmosProjectSteps.ATMOS_MIX_REVISION === step ||
        AtmosProjectSteps.IN_ATMOS_MIX === step
      );
    }
    return (
      MixingProjectSteps.IN_MIX === step ||
      MixingProjectSteps.MIX_REVISION === step
    );
  }, [projectType, step]);
};
