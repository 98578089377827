import { useHistory } from "react-router-dom";
import { getEntityPhotoProps } from "../../../hooks/useImageURLFromPath";
import { Studio, StudioRoom } from "../../../store/models/studio";
import { getDisplayableNameForStudio } from "../../../store/utils/entityUtils";
import { getStudioScreenRoute } from "../../../store/utils/routeGetters";
import "./StudioRoomHostedBy.css";

interface StudioRoomHostedByProps {
  studio: Studio;
  studioRoom: StudioRoom;
}

const StudioRoomHostedBy = ({
  studio,
  studioRoom,
}: StudioRoomHostedByProps) => {
  const history = useHistory();
  const studioUrl = getStudioScreenRoute(studio.username);
  const isEngineerAvailable = studio.studio_team.members.length > 0;
  const engineerAvailableText = isEngineerAvailable
    ? "Engineer Available"
    : null;
  const cancellationPolicyText = studioRoom.recording_service
    ?.cancellation_policy
    ? `${studioRoom.recording_service?.cancellation_policy.full_refund_cancellation_window_minutes / 60} Hour Cancellation`
    : null;

  const guestText = `${studioRoom.max_number_of_guests} guest(s)`;
  const description = [cancellationPolicyText, engineerAvailableText, guestText]
    .filter(Boolean)
    .join(" • ")
    .trim();

  return (
    <div
      className="studio-room-hosted-by-container"
      onClick={() => {
        history.push(studioUrl);
      }}
    >
      <div>
        <div className="h8-semi-bold studio-room-hosted-by-name">{`Studio Hosted by ${getDisplayableNameForStudio(studio)}`}</div>
        <div className="b1 studio-room-hosted-by-description">
          {description}
        </div>
      </div>
      {studio.photo && (
        <img
          className="studio-room-hosted-by-studio-img"
          {...getEntityPhotoProps({
            path: studio.photo.path,
            isEntityProfilePath: true,
          })}
        />
      )}
    </div>
  );
};

export default StudioRoomHostedBy;
