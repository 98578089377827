import { atom } from "jotai";

// Jotai location does not accurately track the location object from react-router-dom
// This atom is used to track the current pathname from react-router-dom
// The `useSyncProfileState` hook uses this atom to set the pathname
// Other atoms consume this atom to get the pathname, such as determining if the user is viewing their own profile
const pathnameAtom = atom(undefined as string | undefined);
pathnameAtom.debugLabel = "Pathname Atom";

export default pathnameAtom;
