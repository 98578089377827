import { Box, styled } from "@mui/material";

export const ReleaseTrackListContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: auto;
  flex-wrap: nowrap;
  height: 300px;
  /* Hide scrollbar for WebKit browsers */

  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  /* Needed so that the shadow is not cut off on the last item allowing the entire item to slide into view on mobile */
  padding-right: 16px;
  padding-left: 16px;
`;

export const ReleaseTrackCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  alignItems: "flex-end",
  gap: "12px",
  borderRadius: theme.border.radius.md,
  background: theme.palette.customColor.backgroundColor,
  margin: "16px 0 16px 0",
  boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.15)",
  maxWidth: "183px",
  cursor: "pointer",
}));
