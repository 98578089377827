import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";
import { useEffect, useMemo } from "react";
import { loadEngineerServices } from "../../../store/actions/services";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { GenerateBookingButton } from "../../components/GenerateBookingButton/GenerateBookingButton";
import {
  BOTTOM_TAB_BAR_OVERLAY_ID,
  useBottomTabBarOverlayView,
} from "../../components/Navigation/BottomNav/useBottomTabBarOverlayView";

export const AdminGenerateBookingButton = () => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const { services } = useAppSelector((state) => state.engineerServices);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  const isStudio = useMemo(
    () => selectedProfile.studio !== undefined,
    [selectedProfile.studio],
  );

  const showGenerateBookingButton = useMemo(
    () => services.length !== 0 || isStudio,
    [isStudio, services],
  );

  const stickyFooterButton = useBottomTabBarOverlayView(
    showGenerateBookingButton && isMobile,
    <div className="dashboard-start-a-project-button-fixed">
      <GenerateBookingButton
        isLoadingEngineerServices={false}
        isMobile={isMobile}
      />
    </div>,
    BOTTOM_TAB_BAR_OVERLAY_ID,
  );

  useEffect(() => {
    if (!loggedInUser) return;
    const engineer = loggedInUser.engineer;
    if (!engineer) return;
    void dispatch(loadEngineerServices({ engineer_id: engineer.id }));
  }, [dispatch, loggedInUser]);

  if (isMobile && showGenerateBookingButton) {
    return stickyFooterButton;
  }

  return showGenerateBookingButton ? (
    <GenerateBookingButton
      isLoadingEngineerServices={false}
      isMobile={isMobile}
    />
  ) : null;
};
