import styled from "styled-components";
import { Button } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { Breakpoint } from "../../../utils/breakpoints";

/* Form */
export const FileCommentContainer = styled.div`
  background: ${({ theme }) => theme.foregroundColor};
  display: flex;
  flex-direction: column;
`;

export const FileCommentHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 44px;
`;

export const FileCommentHeaderText = styled.div`
  color: ${({ theme }) => theme.textSecondaryColor};
  font-size: ${({ theme }) => theme.textSizeSm};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
`;

export const FileCommentHeaderSort = styled.div`
  color: ${({ theme }) => theme.textSecondaryColor};
  font-size: ${({ theme }) => theme.textSizeSm};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  display: flex;
  gap: 4px;
  cursor: pointer;
`;

export const FileCommentInput = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    align-items: stretch;
  }
`;

export const FileCommentForm = styled.form`
  border: none;
  background-color: ${({ theme }) => theme.darkerMidgroundColor};
  color: ${({ theme }) => theme.textPrimaryColor};
  display: flex;
  border-radius: ${({ theme }) => theme.border.radius.md};
  width: 100%;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const FileCommentTimeStamps = styled.div`
  display: flex;
  align-items: center;
`;

export const FileCommentTextField = styled.input`
  border: none;
  border-radius: ${({ theme }) => theme.border.radius.md};
  outline: none;
  padding: 0.75rem 0 0.75rem 0.75rem;
  width: 100%;
  color: ${({ theme }) => theme.textPrimaryColor};
  background-color: ${({ theme }) => theme.darkerMidgroundColor};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media ${Breakpoint.Mobile} {
    width: calc(100% - 12px);
    border-radius: ${({ theme }) => theme.border.radius.md} 0 0 0;
  }
`;

export const FileCommentTimeIndicatorText = styled(Text)`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme }) => theme.textSizeMd};
  padding: 0.75rem 0.5rem 0.75rem 0.5rem;
`;

export const FileCommentTimeInput = styled.input`
  display: flex;
  border: none;
  outline: none;
  padding: 0.75rem 1rem 0.75rem 0;
  color: ${({ theme }) => theme.textPrimaryColor};
  background-color: ${({ theme }) => theme.darkerMidgroundColor};
  width: 50px;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    border-bottom-left-radius: ${({ theme }) => theme.border.radius.md};
  }
`;

export const FileCommentAddButton = styled(Button)`
  border-radius: 0 12px 12px 0;

  @media ${Breakpoint.Mobile} {
    border-radius: 0 ${({ theme }) => theme.border.radius.md}
      ${({ theme }) => theme.border.radius.md} 0;
    flex-grow: 1;
  }
`;

/* Chat */
export const FileCommentChatContainer = styled.div`
  background: ${({ theme }) => theme.foregroundColor};
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
`;

export const FileCommentChatRow = styled.div<{ $isReply: boolean }>`
  background: ${({ theme }) => theme.foregroundColor};
  display: flex;
  justify-content: ${({ $isReply }) => ($isReply ? "flex-end" : "flex-start")};
`;

export const FileCommentChatBubble = styled.div<{ $isAuthor: boolean }>`
  background: ${({ theme, $isAuthor }) =>
    $isAuthor ? theme.darkerMidgroundColor : theme.foregroundColor};
  border-radius: ${({ theme }) => theme.border.radius.md};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: ${({ $isAuthor }) => ($isAuthor ? "16px" : "0")};
`;

export const FileCommentChatMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-wrap: anywhere;
  color: ${({ theme }) => theme.textPrimaryColor};
  white-space: pre-wrap;
`;

export const FileCommentChatMessageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FileCommentChatMessageCollaborator = styled.div`
  color: ${({ theme }) => theme.textSecondaryColor};
  font-size: ${({ theme }) => theme.textSizeMd};
  font-weight: ${({ theme }) => theme.textWeightSemibold};

  @media (max-width: 1024px) {
    font-size: ${({ theme }) => theme.textSizeXs};
    max-width: 120px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const FileCommentChatMessageTimeStamp = styled.div`
  background: ${({ theme }) => theme.midgroundColor};
  border-radius: ${({ theme }) => theme.border.radius.xs};
  color: ${({ theme }) => theme.colorPalette.DeepBlue500};
  font-size: ${({ theme }) => theme.textSizeXs};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  cursor: pointer;
`;

export const FileCommentChatMessageCreatedAt = styled.div`
  color: ${({ theme }) => theme.textTertiaryColor};
  font-size: ${({ theme }) => theme.textSizeXs};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
`;

export const FileCommentChatMessageVersion = styled(Text)`
  color: ${({ theme }) => theme.textSecondaryColor};
  font-size: ${({ theme }) => theme.textSizeXs};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
`;

export const FileCommentChatMessageOptions = styled.div`
  display: flex;
  align-items: start;
  gap: 4px;
`;

export const FileCommentChatMessageReply = styled.div`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme }) => theme.textSizeXs};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  cursor: pointer;
`;

export const FileCommentChatMessageButton = styled(Button)<{
  $minWidth?: string;
}>`
  border-radius: ${({ theme }) => theme.border.radius.xs};
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme }) => theme.textSizeXs};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  min-height: unset;
  min-width: ${({ $minWidth }) => $minWidth ?? "fit-content"};
  padding: 4px 6px;
  text-align: start;
`;

export const FileCommentChatMessageReplyContainer = styled.div`
  background: ${({ theme }) => theme.foregroundColor};
  border-radius: ${({ theme }) => theme.border.radius.md};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;
  width: 100%;
`;

export const FileCommentChatMessageReplyInput = styled.textarea`
  border: ${({ theme }) => `1px solid ${theme.separatorColor}`};
  border-radius: ${({ theme }) => theme.border.radius.md};
  outline: none;
  display: flex;
  height: 96px;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  resize: none;
`;

export const FileCommentChatMessageReplyButton = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;
