import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  button:not(:first-of-type) {
    margin-left: 20px;
  }
`;

export const DeleteModalText = styled.div`
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  font-size: ${({ theme }) => theme.textSizeMd};
  color: ${({ theme }) => theme.textPrimaryColor};
`;
