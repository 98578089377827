import { MutableRefObject, useEffect } from "react";
import { default as WaveSurferRef } from "wavesurfer.js";
import { useAppSelector } from "../../store/hooks";

export const useMoveToSeekTime = (
  ref: MutableRefObject<WaveSurferRef | null>,
  isPlayingOnFooter: boolean,
) => {
  const { currentPosition } = useAppSelector((state) => state.abPlayerStore);

  useEffect(() => {
    if (!isPlayingOnFooter || !ref.current || currentPosition < 0) return;
    const duration = ref.current.getDuration();
    if (duration < currentPosition) return;
    let seekToValue: number = currentPosition / duration;
    if (seekToValue < 0 || !seekToValue) seekToValue = 0;
    ref.current.seekTo(seekToValue);
  }, [currentPosition, isPlayingOnFooter, ref]);
};
