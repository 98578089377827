import { Box, styled } from "@mui/material";

export const CollaboratorBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

export const IndividualCollaboratorItemBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  alignItems: "center",
  justifyContent: "center",
});

export const CollaboratorItemsBox = styled(Box)({
  display: "flex",
  gap: "12px",
  flexWrap: "wrap",
});
