import { addDays, format, subDays } from "date-fns";
import { Alt, AltItem } from "../alts";
import { Project, ProjectType } from "../project";
import { MockUser } from "../user";

export const mockAltItem: AltItem = {
  alt: Alt.MAIN,
  alt_price: "5",
  deleted: null,
  id: 1,
  project_id: 2,
};

export const mockAltItem1: AltItem = {
  alt: Alt.CLEAN,
  alt_price: "5",
  deleted: null,
  id: 2,
  project_id: 2,
};

export const mockAltItem2: AltItem = {
  alt: Alt.VINYL_MASTERING,
  alt_price: "5",
  deleted: null,
  id: 3,
  project_id: 2,
};

export const mockAltItem3: AltItem = {
  alt: Alt.INSTRUMENTAL,
  alt_price: "5",
  deleted: null,
  id: 4,
  project_id: 2,
};

export const mockAltItem4: AltItem = {
  alt: Alt.PERFORMANCE,
  alt_price: "5",
  deleted: null,
  id: 5,
  project_id: 2,
};

export const mockAltItem5: AltItem = {
  alt: Alt.CLEAN_A_CAPPELLA,
  alt_price: "5",
  deleted: null,
  id: 6,
  project_id: 2,
};

export const mockAltItem6: AltItem = {
  alt: Alt.CLEAN_PERFORMANCE,
  alt_price: "5",
  deleted: null,
  id: 7,
  project_id: 2,
};

export const MockMixingProject: Project = {
  prereq_project_is_completed: true,
  checkout_price: 400000,
  has_pending_purchase_order: false,
  purchase_order_budget_approved: false,
  extra_revision_price: 0,
  refunded: null,
  revision_counter: 0,
  revisions_available: 0,
  deleted: null,
  last_transition: null,
  engineer_reference_notes: null,
  service_type: ProjectType.MIXING,
  stripe_session_id: null,
  title: "Mock Mixing Project",
  total_price: "400.00",
  first_pass_date: addDays(new Date(), 2).toISOString(),
  closed: null,
  created: subDays(new Date(), 2).toISOString(),
  engineer_file_notes: null,
  engineer_has_files: null,
  artist_file_link: null,
  id: 1,
  step: 2,
  engineer: MockUser,
  artist: MockUser,
  completed: null,
  assets_approved: null,
  final_asset_file_notes: null,
  alts: [mockAltItem3, mockAltItem2, mockAltItem4, mockAltItem5, mockAltItem6],
  service_fee: 0.15,
  calculatedDueDate: format(new Date(), "MM/dd/yy"),
  users: [MockUser, { ...MockUser, id: 2 }],
  mixing_project: {
    id: 3,
    engineer_mix_notes: null,
    artist_mix_revision_notes: null,
    artist_mix_notes: "lower the treble",
    engineer_has_reference: null,
  },
  hosted_invoice_url: "https://stripe.com",
  invoice_status: null,
};

export const MockMasteringProject: Project = {
  prereq_project_is_completed: true,
  checkout_price: 400000,
  has_pending_purchase_order: false,
  purchase_order_budget_approved: false,
  extra_revision_price: 0,
  refunded: null,
  revision_counter: 0,
  revisions_available: 0,
  deleted: null,
  last_transition: null,
  engineer_reference_notes: null,
  service_type: ProjectType.MASTERING,
  stripe_session_id: null,
  title: "Mock Mastering Project",
  total_price: "400,000.00",
  first_pass_date: addDays(new Date(), 3).toISOString(),
  closed: null,
  created: subDays(new Date(), 3).toISOString(),
  engineer_file_notes: null,
  engineer_has_files: null,
  artist_file_link: null,
  id: 2,
  step: 2,
  engineer: MockUser,
  artist: MockUser,
  completed: null,
  assets_approved: null,
  final_asset_file_notes: null,
  alts: [],
  service_fee: 0.15,
  calculatedDueDate: format(new Date(), "MM/dd/yy"),
  users: [],
  mastering_project: {
    id: 4,
    artist_master_notes: null,
    artist_master_revision_notes: null,
    engineer_master_notes: "",
  },
  hosted_invoice_url: "https://stripe.com",
  invoice_status: null,
};

export const MockMixingProject1: Project = {
  prereq_project_is_completed: true,
  checkout_price: 400000,
  has_pending_purchase_order: false,
  purchase_order_budget_approved: false,
  extra_revision_price: 0,
  refunded: null,
  revision_counter: 0,
  revisions_available: 0,
  deleted: null,
  last_transition: null,
  engineer_reference_notes: null,
  service_type: ProjectType.MIXING,
  stripe_session_id: null,
  title:
    "Mock Mixing Project Goes so hard that the title needs to be long enough for you to understand the situtation of where I am coming from.",
  total_price: "400.00",
  first_pass_date: addDays(new Date(), 2).toISOString(),
  closed: null,
  created: subDays(new Date(), 2).toISOString(),
  engineer_file_notes: null,
  engineer_has_files: null,
  artist_file_link: null,
  id: 1,
  step: 2,
  engineer: MockUser,
  artist: MockUser,
  completed: null,
  assets_approved: null,
  final_asset_file_notes: null,
  alts: [mockAltItem3, mockAltItem2, mockAltItem4, mockAltItem5, mockAltItem6],
  service_fee: 0.15,
  calculatedDueDate: format(new Date(), "MM/dd/yy"),
  users: [MockUser, { ...MockUser, id: 2 }],
  mixing_project: {
    id: 3,
    engineer_mix_notes: null,
    artist_mix_revision_notes: null,
    artist_mix_notes: "lower the treble",
    engineer_has_reference: null,
  },
  hosted_invoice_url: "https://stripe.com",
  invoice_status: null,
};
