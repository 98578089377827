import styled from "styled-components";
import { Text } from "../../core-ui/components/Text/Text";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.midgroundColor};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 16px;
`;

export const Header = styled(Text)`
  margin-bottom: 16px;
`;
