import styled from "styled-components";
import { Button } from "../../core-ui/components/Button/Button";

export const FileLinkInputContainer = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const FileLinkInput = styled.input`
  border: thin solid ${({ theme }) => theme.separatorColor};
  border-right: none;
  border-radius: 8px 0 0 8px;
  padding: 0 16px 0 16px;
  width: 100%;
  color: ${({ theme }) => theme.textSecondaryColor};
  background: ${({ theme }) => theme.backgroundColor};
`;

export const FileLinkSubmitButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
