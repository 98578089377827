import { Box, styled } from "@mui/material";

export const NoReviewsButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  flexDirection: "column",
  gap: theme.spacing(5),
  boxSizing: "border-box",
  padding: "88px 40px",
  backgroundColor: theme.palette.grey[50],
}));

export const ReviewsTextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(1.5),
}));

export const ReviewsStarContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[300],
  display: "flex",
  gap: theme.spacing(0.5),
  alignItems: "center",
}));
