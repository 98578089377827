import {
  FileVersion,
  LINK_UPLOAD_TYPE,
} from "../../../store/models/fileVersion";
import { FileLinkRow } from "./FileLinkRow/FileLinkRow";
import {
  FileLinkTableContainer,
  FileTableHeader,
} from "./FileLinkTable.styles";
import {
  Text,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";

export interface FileLinkTableProps {
  fileVersions: FileVersion[];
}

export const FileLinkTable = ({ fileVersions }: FileLinkTableProps) => {
  const linkFiles = fileVersions.filter(
    (file) => file.upload_type === LINK_UPLOAD_TYPE && file.file_name,
  );

  if (!linkFiles.length) return null;

  return (
    <FileLinkTableContainer>
      <FileTableHeader>
        <Text weight={TEXT_WEIGHT.BOLD} size={TEXT_SIZE.MEDIUM}>
          Links
        </Text>
      </FileTableHeader>
      {linkFiles.map((linkFile) => {
        return <FileLinkRow key={linkFile.id} url={linkFile.file_name!} />;
      })}
    </FileLinkTableContainer>
  );
};
