import { useMemo } from "react";
import Artist from "../store/models/artist";
import Engineer from "../store/models/engineer";
import { useAppSelector } from "../store/hooks";
import { selectReviews } from "../store/selectors/reviewsSelector";
import { Review, ReviewState } from "../store/models/review";
import { Studio, StudioRoom } from "../store/models/studio";

export const useStudioRoomReviewStats = (
  studioRoomId?: number,
): ReviewState | undefined => {
  const reviewStore = useAppSelector(selectReviews);
  return useMemo(() => {
    if (studioRoomId) {
      return reviewStore.studioRoomReviews[studioRoomId];
    }
    return undefined;
  }, [studioRoomId, reviewStore]);
};

export const useUserReviews = (
  artist?: Artist,
  engineer?: Engineer,
  studio?: Studio,
  studioRoom?: StudioRoom,
): ReviewState | undefined => {
  const reviewStore = useAppSelector(selectReviews);

  return useMemo(() => {
    if (engineer && engineer.deleted === null) {
      return reviewStore.engineerReviews[engineer.id];
    } else if (artist) {
      return reviewStore.artistReviews[artist.id];
    } else if (studio && studio.deleted === null) {
      return reviewStore.studioReviews[studio.id];
    } else if (studioRoom && studioRoom.deleted === null) {
      return reviewStore.studioRoomReviews[studioRoom.id];
    }
    return undefined;
  }, [engineer, artist, studio, studioRoom, reviewStore]);
};

export const usePaginatedReviews = (
  artist?: Artist,
  engineer?: Engineer,
  studio?: Studio,
  studioRoom?: StudioRoom,
  orderBy = "created",
): Review[] => {
  const reviewStore = useUserReviews(artist, engineer, studio, studioRoom);

  return useMemo(() => {
    if (!reviewStore) return [];
    const store = reviewStore.recent_reviews;
    const keys: number[] = Object.keys(store).map((k) => +k);
    const initialValue: Review[] = [];
    const reviews: Review[] | undefined = keys.reduce((acc, key) => {
      const pageData: Review[] | undefined = store[key];

      if (pageData) {
        return acc.concat(pageData);
      }
      return acc;
    }, initialValue);
    if (reviews) return reviews;
    return [];
  }, [reviewStore, orderBy]);
};
