import styled from "styled-components";

export const StyledEngineerPromoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: fit-content;

  @media (max-width: 768px) {
    overflow-y: auto;
    max-height: 600px;
    overflow-x: hidden;
  }
`;

export const StyledEngineerPromoListSubtitle = styled.p`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme }) => theme.textSizeLg};
  font-weight: ${({ theme }) => theme.textWeightNormal};
  text-align: center;
  margin-top: unset;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.textSizeLg};
  }
`;

export const StyledEngineerPromoListCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledEngineerPromoListFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
  margin-top: auto;
`;

export const StyledEngineerPromoListButton = styled.button`
  font-size: ${({ theme }) => theme.textSizeMd};
  border: none;
  background-color: inherit;
  color: ${({ theme }) => theme.textPrimaryColor};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  cursor: pointer;
`;
