import { useState } from "react";
import { updateFileComment } from "../../../store/actions/fileVersionComments";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { FileVersionComment } from "../../../store/models/fileVersion";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { ButtonVariant } from "../../core-ui/components/Button/Button";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";
import { FileCommentChatMessageButton } from "./TrackTableComments.styles";
import { RegisterUnauthenticatedUserName } from "../RegisterUnauthenticatedUserName/RegisterUnauthenticatedUserName";

interface TrackTableResolveCommentButtonProps {
  code: string | undefined;
  comment: FileVersionComment;
}

export const TrackTableResolveCommentButton = ({
  code,
  comment,
}: TrackTableResolveCommentButtonProps) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const unauthenticatedUserName = useAppSelector(
    (state) => state.unauthenticatedUserStateSlice.name,
  );
  const [loadingMarkResolved, setLoadingMarkResolved] = useState(false);
  const dispatch = useAppDispatch();
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  const handleMarkResolved = async (
    commentId: number,
    fileVersionId: number,
    resolve: boolean,
  ) => {
    setLoadingMarkResolved(true);
    await dispatch(
      updateFileComment({
        code,
        file_version_id: fileVersionId,
        file_version_comment_id: commentId,
        resolved: resolve ? new Date().toISOString() : null,
        resolver_name: unauthenticatedUserName,
      }),
    )
      .unwrap()
      .finally(() => setLoadingMarkResolved(false));
  };

  // only show resolve button for top level comments
  if (comment.reply_to) {
    return null;
  }

  if (!comment.resolved && !user && !unauthenticatedUserName) {
    return (
      <RegisterUnauthenticatedUserName
        triggerComponent={
          <FileCommentChatMessageButton
            disabled={loadingMarkResolved}
            variant={ButtonVariant.GHOST}
          >
            Mark resolved
          </FileCommentChatMessageButton>
        }
      />
    );
  }

  if (!comment.resolved) {
    return (
      <FileCommentChatMessageButton
        disabled={loadingMarkResolved}
        onClick={() =>
          handleMarkResolved(
            comment.id,
            comment.file_version_id,
            !comment.resolved,
          )
        }
        variant={ButtonVariant.GHOST}
      >
        Mark resolved
      </FileCommentChatMessageButton>
    );
  }

  const resolvedDate = new Date(`${comment.resolved}Z`);
  const formattedTime = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
  }).format(resolvedDate);
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
  }).format(resolvedDate);

  const resolverName = comment.resolver_name || comment.resolver_user?.username;

  return (
    <PopConfirm
      side="top"
      title="Are you sure?"
      description="This will unresolve the comment."
      onConfirm={() =>
        handleMarkResolved(
          comment.id,
          comment.file_version_id,
          !comment.resolved,
        )
      }
      wrapperElement={containerElement}
    >
      <FileCommentChatMessageButton
        disabled={loadingMarkResolved}
        $minWidth="unset"
        variant={ButtonVariant.GHOST}
      >
        Resolved by {resolverName} at {formattedTime} on {formattedDate}
      </FileCommentChatMessageButton>
    </PopConfirm>
  );
};
