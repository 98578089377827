import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { fetchAllUserFavorites } from "../../../../store/actions/dashboard";

const useUserFavorites = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const { userFavorites } = useAppSelector((state) => state.dashboard);
  useEffect(() => {
    if (!isAuthenticated) return;
    if (userFavorites === null) {
      void dispatch(fetchAllUserFavorites());
    }
  }, [dispatch, isAuthenticated, userFavorites]);
};

export default useUserFavorites;
