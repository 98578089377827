import classNames from "classnames";
import { HTMLAttributes } from "react";
import { TabPanelWrapper } from "./TabPanel.styles";

export interface TabPanelProps extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, className, ...other } = props;

  return (
    <TabPanelWrapper
      className={classNames(["container", className])}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </TabPanelWrapper>
  );
}

export default TabPanel;
