import { useMemo } from "react";
import { BudgetManager, PurchaseOrder } from "../store/models/project";
import User from "../store/models/user";
import { determineIfUser } from "../store/utils/entityUtils";
import { ActionStatus } from "../stories/core-ui/components/SimpleTable/SimpleTable";
import { SimpleTableRowData } from "../stories/core-ui/elements/UserIconLabel/SimpleTableRow/SimpleTableRow";

export const useBudgetManagersApprovalSimpleTableRowData = (
  budgetManagers: BudgetManager[],
): SimpleTableRowData[] => {
  const budgetApprovalStatus = (budgetManager: BudgetManager): ActionStatus => {
    if (budgetManager.budget_approved) {
      return ActionStatus.COMPLETED;
    }
    if (budgetManager.budget_rejected) {
      return ActionStatus.REJECTED;
    }
    return ActionStatus.PENDING;
  };

  return useMemo(() => {
    return budgetManagers
      .filter((budgetManager) => Boolean(budgetManager.can_approve_budget))
      .map((budgetManager) => {
        const user = budgetManager.user;
        let status = ActionStatus.PENDING;
        status = budgetApprovalStatus(budgetManager);
        return {
          user: user,
          email: budgetManager.email,
          actionStatus: status,
        };
      });
  }, [budgetManagers]);
};

export const useBudgetManagerPurchaseOrderSubmittedSimpleTableRowData = (
  budgetManagers: BudgetManager[],
  purchaseOrder?: PurchaseOrder,
): SimpleTableRowData[] => {
  const purchaseOrderBudgetStatus = (
    purchaseOrder?: PurchaseOrder,
  ): ActionStatus => {
    if (!purchaseOrder) return ActionStatus.REJECTED;
    return Boolean(
      purchaseOrder.cost_center &&
        purchaseOrder.work_breakdown_structure &&
        purchaseOrder.general_ledger,
    ) || Boolean(purchaseOrder.order_number)
      ? ActionStatus.COMPLETED
      : ActionStatus.PENDING;
  };
  return useMemo(() => {
    return budgetManagers
      .filter((budgetManager) => Boolean(budgetManager.can_submit_billing_info))
      .map((budgetManager) => {
        const user = budgetManager.user;
        let status = ActionStatus.PENDING;
        status = purchaseOrderBudgetStatus(purchaseOrder);
        return {
          user: user,
          email: budgetManager.email,
          actionStatus: status,
        };
      });
  }, [budgetManagers, purchaseOrder]);
};

export const useEmailListSimpleTableRowData = (
  purchaseOrder?: PurchaseOrder,
): SimpleTableRowData[] => {
  return useMemo(() => {
    if (!purchaseOrder) return [];
    const collabList: (User | string)[] = [];
    purchaseOrder.collaborators_users?.forEach((user) => {
      collabList.push(user);
    });
    purchaseOrder.collaborators_list?.forEach((email) => {
      collabList.push(email);
    });
    if (!collabList) return [];
    return collabList.map((collaborator) => {
      const collabUser = determineIfUser(collaborator) ? collaborator : null;
      const email = determineIfUser(collaborator)
        ? collabUser?.email
        : collaborator;
      return {
        user: collabUser,
        email: email,
        actionStatus: ActionStatus.COMPLETED,
      };
    });
  }, [purchaseOrder]);
};
