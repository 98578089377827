import { UniqueIdentifier } from "@dnd-kit/core";
import { ReactNode } from "react";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

interface SortableItem {
  id: UniqueIdentifier;
  children: ReactNode;
  disableDragging?: boolean;
}

export const SortableItem = (props: SortableItem) => {
  const { disableDragging, id, children } = props;

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id, disabled: disableDragging });

  const style = {
    position: "relative",
    opacity: isDragging ? 0.5 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: disableDragging ? "unset" : "pointer",
  } as const;

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};
