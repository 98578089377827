export const LOGIN = "/api/auth/login/";
export const LOGIN_UMG = "/api/auth/login/umg/";
export const LOGIN_GOOGLE = "/api/auth/login/google/";
export const LOAD_USER = "/api/auth/user/";
export const LOAD_ACTIVE_USER = "/api/auth/user/active/";
export const REGISTER = "/api/auth/register/";
export const EMAIL_LEAD = "/api/accounts/email/lead/";
export const UPDATE_PROFILE = "/api/accounts/profile/";
export const GENRES = "/api/accounts/music/genres/";
export const USER_ENGINEER = "/api/accounts/user/engineer/";
export const USER_PRODUCER = "/api/accounts/user/producer/";
export const USER_LISTENER = "/api/accounts/user/listener/";
export const USER_OTHER = "/api/accounts/user/other/";
export const USER_STUDIO_MANAGER = "/api/accounts/user/studiomanager/";
export const USER_ARTIST = "/api/accounts/user/artist/";
export const USER_A_AND_R = "/api/accounts/user/aandr/";
export const USER_ADMIN = "/api/accounts/user/admin/";
export const STRIPE_ACCOUNT_SETUP = "/api/accounts/stripe/account/setup/";
export const STRIPE_ACCOUNT_PAYOUT = "/api/accounts/stripe/account/payout/";
export const BETTERMODE_JWT_TOKEN = "/api/accounts/bettermode/token/";
export const EMPIRE_JWT_TOKEN = "/api/accounts/empire/token/";
export const LOAD_ENGINEER_SERVICES = "/api/projects/engineering/services/";
export const SEARCH_ALL = "/api/accounts/user/search/all/";
export const SEARCH_BY_ROLE = "/api/accounts/user/search/role/";
export const PROJECT_SEARCH = "/api/projects/search/autocomplete/";
export const LOAD_PROJECTS = "/api/projects/projects/";
export const GET_SCHEDULED_PROJECT_OVERVIEW =
  "/api/projects/scheduledprojects/overview/";
export const MANAGE_PROJECT = "/api/projects/project/management/";
export const PROJECT_REQUESTS = "/api/projects/project/requests/";
export const SCHEDULE_OVERVIEW = "/api/projects/schedule/overview/";
export const COMPLETE_TODAY = "/api/projects/complete/today/";
export const LOAD_PROJECT = "/api/projects/project/";
export const GET_PROJECT = "/api/projects/project/:project_id/";
export const GET_PROJECT_HISTORY = "/api/projects/project/:project_id/history/";
export const GET_PROJECT_FILES = "/api/projects/project/:project_id/files/";
export const LOAD_IN_PROGRESS_PROJECT = "/api/projects/project/inprogress/";
export const START_IN_PROGRESS_PROJECT =
  "/api/projects/project/inprogress/start/";
export const RENAME_PROJECT_OR_SCHEDULED_PROJECT =
  "/api/projects/project/rename/";
export const UPLOAD_PHOTO = "/api/accounts/photo/upload/?format=json";
export const SCHEDULING_SURVEY = "/api/projects/engineering/survey/";
export const ARTIST_MIXING_PROJECT = "/api/projects/mixing/project/artist/";
export const ENGINEER_MIXING_PROJECT = "/api/projects/mixing/project/engineer/";
export const MARK_FINAL_ASSETS_APPROVED = "/api/projects/approved/";
export const ENGINEER_SIGNED = "/api/projects/project/engineer/signed/";
export const ARTIST_SIGNED = "/api/projects/project/artist/signed/";
export const SONIC_MATCH_SIGNED = "/api/files/sonic/match/signed/";
export const DOWNLOAD_PROJECT_FILES = "/api/projects/projects/files/reference/";
export const DOWNLOAD_STATS = "/api/dashboard/review/stats/";
export const LOAD_PROJECT_STATS = "/api/dashboard/project/stats/";
export const LOAD_SESSION_STATS = "/api/dashboard/session/stats/";
export const LOAD_TROPHIES = "/api/dashboard/trophies/";
export const FETCH_USER_PROFILE = "/api/accounts/user/";
export const LIST_REFERENCE_DETAILS =
  "/api/projects/projects/listreferencedetails/";
export const DOWNLOAD_FINAL_FILES = "/api/projects/projects/finalfiles/";
export const GET_PROJECT_REVIEW = "/api/dashboard/project/review/";
export const ARTIST_MASTER_PROJECT = "/api/projects/mastering/project/artist/";
export const ENGINEER_MASTER_PROJECT =
  "/api/projects/mastering/project/engineer/";
export const LOAD_NOTIFICATIONS = "/api/notifications/paginated/";
export const READ_NOTIFICATIONS = "/api/notifications/read/";
export const GET_STREAM_TOKEN = "/api/stream/access/token/";
export const REVOKE_STREAM_TOKEN = "/api/stream/revoke/token/";
export const CREATE_DIRECT_MESSAGE_REQUEST = "/api/stream/message/request/";
export const CREATE_STUDIO_DIRECT_MESSAGE_REQUEST =
  "/api/stream/message/request/studio/";
export const VERIFY_PHONE = "/api/accounts/user/verify/phone/";
export const USER_FEEDBACK = "/api/accounts/user/feedback/";
export const CREATE_LISTEN_LINK = "/api/projects/projects/listen/link/create/";
export const CREATE_RECORDING_SESSION_SHARE_LINK =
  "/api/recording/session/share/link/create/";
export const CREATE_UPLOAD_LINK = "/api/projects/projects/upload/link/create/";
export const GET_UPLOAD_LINK_PROJECT =
  "/api/projects/projects/upload/link/get/";
export const GET_LISTEN_LINK_PROJECT =
  "/api/projects/projects/listen/link/get/";
export const GET_RECORDING_SESSION_DETAILS_FROM_SHARE_LINK =
  "/api/recording/session/share/link/get/";
export const VERIFY_EMAIL = "/api/accounts/email/verify/";
export const ADD_PROJECT_COMMENT = "/api/projects/project/review/comment/";
export const LOAD_PROJECT_COMMENT = "/api/projects/project/review/comment/";
export const FORGOT_PASSWORD = "/api/accounts/forgot/";
export const RESET_PASSWORD = "/api/accounts/reset/password/";
export const UNIQUE_BOOKING_LINK =
  "/api/projects/engineering/services/unique/link/";
export const MIXING_ALPHA_PROJECTS = "/api/projects/mixing/alpha/";
export const MASTERING_ALPHA_PROJECTS = "/api/projects/mastering/alpha/";
export const GET_SCHEDULED_PROJECT_FOR_PROJECT =
  "/api/projects/project/scheduled/project/";
export const GET_SUBSCRIPTION_STATUS =
  "/api/accounts/stripe/engineer/subscription/";
export const GET_STRIPE_CHECKOUT_SESSION =
  "/api/accounts/stripe/account/checkout/";
export const GET_STRIPE_PORTAL_SESSION = "/api/accounts/stripe/account/portal/";
export const STRIPE_SUBSCRIPTION_SUCCESS =
  "/api/account/stripe/subscription/success/";
export const STRIPE_SUBSCRIPTION_CUSTOMER =
  "/api/accounts/stripe/subscription/customer";
export const ADD_ESSENTIALS_SUBSCRIPTION_PLAN =
  "/api/account/subscription/essentials";
export const MUSO_ASSOCIATION = "/api/muso/profile/";
export const SEARCH_MUSO_CREDITS = "/api/muso/search/credits/";
export const MUSO_CREDITS = "/api/muso/credits/";
export const MUSO_AUTH_TOKEN = "/api/muso/auth/token/";
export const MUSO_GET_ROSTER = "/api/muso/roster/";
export const ADD_MUSO_CREDITS = "/api/muso/credits/create/";
export const ADD_MUSO_ASSOCIATION = "/api/muso/profile/create/";
export const CHECK_SUPERUSER = "/api/accounts/user/superuser/";
export const PAGINATED_COMPLETED_PROJECTS =
  "/api/portfolio/completed/projects/";
export const FEATURED_TRACK_REQUEST = "/api/portfolio/track/request/";
export const FETCH_VERSIONED_FILES = "/api/files/version/list/";
export const CREATE_SCHEDULED_PROJECT_SHARE_LINK =
  "/api/projects/scheduled/share/link/create/";
export const MARK_FILE_AS_UPLOADED = "/api/files/file/uploaded/";
export const UPLOAD_FILE_LINK = "/api/files/file/link/upload/";
export const MARK_SONIC_MATCH_AS_UPLOADED = "/api/files/sonic/match/uploaded/";
export const SONIC_MATCH_ANALYSIS = "/api/files/sonic/match/analysis/";
export const FETCH_TRACK = "/api/files/audio/";
export const FETCH_TRACK_SNIPPET = "/api/files/audio/snippet/";
export const GET_SCHEDULED_PROJECT = "/api/projects/scheduled/project/";
export const UPDATE_PROJECTS_ORDER_INDEX = "/api/projects/update-sort-indices/";
export const GET_PROJECT_OVERVIEW_LINK =
  "/api/projects/scheduled/share/link/get/";
export const SHARE_PROJECT_OVERVIEW_LINK =
  "/api/projects/scheduled/share/link/share/";
export const GET_IN_PROGRESS_PROJECT_TRANSACTION_DATA =
  "/api/projects/inprogress/scheduled/project/code/";
export const DOWNLOAD_ALL_FILES = "/api/projects/download/tracks/all/";
export const LIST_FILE_COMMENTS = "/api/files/file/list/comments/";
export const CREATE_FILE_COMMENT = "/api/files/file/create/comment/";
export const UPDATE_FILE_COMMENT = "/api/files/file/update/comment/";
export const LOAD_FEATURED_TRACKS = "/api/portfolio/featured/tracks/";
export const UPDATE_FEATURED_TRACK = "/api/portfolio/featured/update/";
export const UPDATE_FEATURE_COVER_ART = "/api/portfolio/featured/cover/";
export const LIST_ENGINEER_RECOMMENDATIONS =
  "/api/accounts/project/recommendations/";
export const UPDATE_DIRECT_MESSAGE_REQUEST =
  "/api/stream/message/request/update";
export const DOWNLOAD_FILE_VERSION_AUDIO = "/api/files/download/audio";
export const UPDATE_FILE_VERSION = "/api/files/file/version/update/";
export const APPLY_PROMO_CODE = "/api/marketing/promocode/apply/";
export const PROMO_CODE_API = "/api/marketing/promocode/";
export const MARKETING_OPT_IN = "/api/marketing/optin/";
export const UPDATE_PROMO_CODE = "/api/marketing/promocode/update/";
export const CREATE_TRANSACTION = "/api/transactions/transaction/create/";
export const REVISION_CHECKOUT = "/api/transactions/checkout/revisions/";
export const PURCHASE_REVISIONS = "/api/transactions/revision/purchase/";
export const PAID_TRANSACTION = "/api/transactions/transaction/purchase/";
export const GET_PURCHASE_ORDER_BY_CODE =
  "/api/transactions/transaction/link/purchase-order/";
export const DOWNLOAD_SINGLE_TRACK = "/api/projects/project/track/";
export const DOWNLOAD_REVIEW_TRACKS = "/api/projects/project/review/download";
export const UPDATE_TRANSACTION_PAYMENT =
  "/api/transactions/transaction/payment/update/";
export const CREATE_TRANSACTION_PAYMENT_INTENT =
  "/api/transactions/transaction/:transaction_code/payment-intent/";
export const APPLY_TRANSACTION_PROMO_CODE =
  "/api/transactions/transaction/:transaction_code/promocode/apply";
export const UPDATE_TRANSACTION =
  "/api/transactions/transaction/:transaction_id/update/";
export const SUBMIT_PURCHASE_ORDER_BILLING_INFO_API =
  "/api/transactions/transaction/purchase-order/billing-info-submission/";
export const GET_ALL_TRANSACTIONS = "/api/transactions/all/";
export const GET_TRANSACTION_INVOICE_URL =
  "/api/transactions/transaction/invoice-url/";
export const GET_ITEMIZED_TRANSACTION =
  "/api/transactions/transaction/itemized/";
export const GET_TRANSACTION = "/api/transactions/transaction/get/";
export const UMG_BILLING_REPORT = "/api/transactions/umg/billing/report/";
export const WORKING_HOURS_API = "/api/accounts/working/hours/";
export const SEND_RATING_FEEDBACK = "/api/accounts/user/satisfaction/";
export const CALENDAR_INTEGRATION_CHECK =
  "/api/calendar/google/calendar/status/";
export const GOOGLE_TOKEN = "/api/calendar/google/token/";
export const REMOVE_CALENDAR_INTEGRATION =
  "/api/calendar/google/calendar/remove/";
export const RECORDING_SERVICE_API = "/api/recording/service/";
export const DELETE_RECORDING_SERVICE = "/api/recording/service/delete/";
export const GET_RECORDING_SERVICE_AVAILABILITY =
  "/api/recording/service/availability/";
export const GET_SESSION_BOOKING_DETAILS =
  "/api/recording/booking/:booking_id/";
export const ACCEPT_BOOKING_RECORDING_SESSION =
  "/api/recording/booking/session/accept/";
export const REJECT_BOOKING_RECORDING_SESSION =
  "/api/recording/booking/session/reject/";
export const GET_PURCHASE_ORDER_STATUS =
  "/api/transactions/transaction/purchase-order/status/";
export const GET_PENDING_RECORDING_SESSION_BOOKINGS =
  "/api/recording/session/requests/";
export const GET_RECORDING_SESSION = "/api/recording/session/";
export const ENGINEER_RECORDING_SESSION_TRANSITION =
  "/api/recording/session/engineer/transition/";
export const ARTIST_RECORDING_SESSION_TRANSITION =
  "/api/recording/session/artist/transition";
export const VERIFY_AR = "/api/accounts/email/aandr/";
export const SESSION_CANCELLATION = "/api/recording/session/cancellation/";
export const GET_ENTITY_PHOTOS = "/api/accounts/entity/photos/";
export const ENTITY_PHOTO_API = "/api/accounts/entity/photo/";
export const MANAGE_STUDIO_PROFILE = "/api/recording/studio/manage/";
export const UPDATE_STUDIO_PROFILE = "/api/recording/studio/update/";
export const STUDIO_DETAILS = "/api/recording/studio/details/";
export const GET_MY_STUDIOS = "/api/recording/studios/";
export const GET_ACTIVE_STUDIOS = "/api/recording/studios/active/";
export const UPDATE_AMENITIES = "/api/recording/amenity/update/";
export const CREATE_STUDIO_ROOM = "/api/recording/studio/room/create/";
export const UPDATE_STUDIO_ROOM = "/api/recording/studio/room/update/";
export const FETCH_STUDIO_ROOMS = "/api/recording/studio/rooms/";
export const STUDIO_TO_WAIT_LIST = "/api/recording/studio/waitlist/";
export const HANDLE_BUDGET =
  "/api/transactions/transaction/budget-status-update/";
export const UPDATE_PURCHASE_ORDER_ADMINS =
  "/api/transactions/transaction/purchaseorder/admin/update/";
export const RESEND_BILLING_LINK =
  "/api/transactions/transaction/purchase/order/billinglink/";
export const REMOVE_TEAM_MEMBER = "/api/recording/studio/remove/";
export const CREATE_TEAM_INVITE = "/api/recording/studio/invite/create/";
export const REVOKE_TEAM_INVITE = "/api/recording/studio/invite/revoke/";
export const GET_TEAM_INVITE = "/api/recording/studio/invite/";
export const ACCEPT_TEAM_INVITE = "/api/recording/studio/invite/accept/";
export const DECLINE_TEAM_INVITE = "/api/recording/studio/invite/decline/";
export const USER_LOCATION_SUPPORT_STATUS = "/api/accounts/location/";
export const SEARCH_ENGINEER_BY_SERVICE = "/api/projects/service/search/";
export const AVAILABLE_SESSION_ENGINEERS =
  "/api/recording/session/available/engineers/";
export const GET_AFFILIATED_STUDIOS = "/api/recording/studios/affiliated/";
export const UPDATE_STUDIO_ROOM_BOOKING_STATUS =
  "/api/recording/service/bookingstatus/";
export const REQUEST_SESSION_MODIFICATION =
  "/api/recording/session/management/";
export const ACCEPT_SESSION_MODIFICATION =
  "/api/recording/session/management/accept/";
export const REJECT_SESSION_MODIFICATION =
  "/api/recording/session/management/reject/";
export const GET_TRENDING_ENGINEERS = "/api/accounts/engineer/trending/";
export const GET_TRENDING_STUDIO_ROOMS =
  "/api/recording/studios/rooms/trending/";
export const FAVORITED_ACCOUNT_API = "/api/dashboard/favorited_account/";
export const FAVORITES = "/api/dashboard/favorites/";
export const FETCH_ALL_USER_FAVORITES = "/api/dashboard/favorites/all/";
export const AUTOCOMPLETE = "/api/accounts/autocomplete/";
export const MAP_AUTOCOMPLETE = "/api/accounts/map/autocomplete/";
export const SEARCH_USER_API = "/api/accounts/user/searchv2/";
export const STUDIO_ROOM_SEARCH_API = "/api/recording/studio/room/search/";
export const UPDATE_USER_LOCATION = "/api/accounts/user/location/";
export const IMAGE_RESIZE_API = "/api/accounts/image/resize/";
export const MAP_USER_SEARCH = "/api/accounts/user/map/search/";
export const MAP_STUDIO_SEARCH = "/api/recording/studio/map/search/";
export const PAYMENT_PLAN_ENDPOINT =
  "/api/transactions/checkout/invoice/payment-plan/";
export const TRANSACTION_STATUS_API = "/api/transactions/transaction/status/";
export const FETCH_CHART_DATA = "/api/dashboard/performance/chart/";
export const DASHBOARD_TEAM_API = "/api/dashboard/user/team/";
export const PAGINATED_ADMIN_ACTIONS = "/api/dashboard/admin/actions/";
export const DASHBOARD_ADMIN_ASSIGN_API = "/api/dashboard/admin/assign/";
export const PAGINATED_PLATFORM_ACTIVITY_API =
  "/api/dashboard/platform/activity/";
export const RESEND_EMAIL_VERIFICATION =
  "/api/account/email/resend-verification/";
export const SHARE_TRANSACTION =
  "/api/transactions/transaction/:transaction_id/share/";
export const RECORDING_SESSION_EXTENSION_CHECKOUT =
  "/api/transactions/checkout/extensions/";
export const RECORDING_SESSION_EXTENSION_PURCHASE =
  "/api/transactions/extension/purchase/";
export const FETCH_PENDING_PROJECT_COUNTS =
  "/api/dashboard/pending/project/counts/";
export const PAGINATED_SCHEDULED_PROJECTS =
  "/api/projects/paginated/scheduled_projects/";
export const PAGINATED_RECORDING_SESSIONS =
  "/api/recording/sessions/paginated/";
export const GET_OPTED_IN_UNVERIFIED_ENGINEERS =
  "/api/accounts/user/engineer/unverified/promotion/";
export const STUDIO_SEPARATE_STRIPE_OPT_IN =
  "/api/accounts/stripe/studio/optin/";
export const ENGINEER_SERVICE_CRUD = "/api/projects/engineering-service/";
export const NUDGE = "/api/notifications/nudge/";
export const PLAYLIST = "/api/projects/playlist/";
export const PAGINATED_MUSO_CREDITS = "/api/muso/credits/paginated/";
export const SESSION_USER_ROLES = "/api/recording/session/roles/";
export const GET_SERVICE_PHOTO_SIGNED_URL =
  "/api/projects/engineering-service/cover-photo/";
export const GET_PROFILE_COVER_PHOTO_SIGNED_URL =
  "/api/accounts/user/cover-photo/signed";
export const GET_ENTITY_PHOTO_UPLOAD_URL = "/api/accounts/entity/photo/upload/";
export const GET_ONBOARDING_PROGRESS = "/api/onboarding/progress/";
export const GET_FEATURED_TRACKS = "/api/portfolio/featured/track/paginated/";
export const FREQUENTLY_ASKED_QUESTION = "/api/accounts/user/faq/";
export const GET_COLLABORATORS = "/api/accounts/collaborators/";
export const RESET_PASSWORD_LOGGED_IN = "/api/accounts/user/reset-password/";
export const BASE_RELEASE_API = "/api/release/";
export const SCHEDULED_PROJECT_RELEASE_ROUTE = `${BASE_RELEASE_API}scheduledproject/`;
export const FEATURED_RELEASE_ROUTE = `${BASE_RELEASE_API}featured/artist/user/`;
export const TRACKS_ROUTE = `${BASE_RELEASE_API}tracks/`;
export const CREATE_RELEASE_FROM_SCHEDULED_PROJECT = `${BASE_RELEASE_API}bulk/`;
export const SWAP_ORDER_ROUTE = `${TRACKS_ROUTE}swap-order/`;
export const ARTIST_USER_RELEASE_ROUTE = `${BASE_RELEASE_API}artist/user/`;
const PRODUCTS_API = "/api/products/";
export const PRODUCTS_BY_RELEASE_ROUTE = `${PRODUCTS_API}release/`;
