import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createWorkingHours,
  getWorkingHours,
  workingHoursParam,
} from "../../../store/actions/workingHours";
import { useAppDispatch } from "../../../store/hooks";
import {
  SelectAMPMOptions,
  SelectHourInDayOptions,
} from "../../../store/models/alts";
import {
  convertBinaryArrayToBinaryString,
  convertTimeToHour,
  FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE,
  fillInAvailability,
  fillInUnavailability,
  INVALID_AM_PM,
  NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
  WEEKDAYS,
  WorkingHours,
} from "../../../store/models/workingHours";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { WeekdayPicker } from "../WeekdayPicker/WeekdayPicker";
import "./SelectWorkingHoursComponent.css";

export interface SelectWorkingHoursComponentPropsV1 {
  onSuccessfulSubmit?: () => void;
  setShowWorkingHoursSetup?: (show: boolean) => void;
  studioRoomId?: number;
  studioRoomName?: string;
}

export const SelectWorkingHoursComponentV1 = ({
  onSuccessfulSubmit,
  setShowWorkingHoursSetup,
  studioRoomId,
  studioRoomName,
}: SelectWorkingHoursComponentPropsV1) => {
  const [updatingAvailability, setUpdatingAvailability] = useState(false);
  const [nextDay, setNextDay] = useState(false);
  const dispatch = useAppDispatch();
  const [weekdays, setWeekdays] = React.useState<WEEKDAYS[]>([]);
  const [startTime, setStartTime] = React.useState<OptionType>(
    SelectHourInDayOptions[0],
  );
  const [endTime, setEndTime] = React.useState<OptionType>(
    SelectHourInDayOptions[0],
  );
  const [startAMPM, setStartAMPM] = React.useState<OptionType>(
    SelectAMPMOptions[0],
  );
  const [endAMPM, setEndAMPM] = React.useState<OptionType>(
    SelectAMPMOptions[0],
  );
  const [isLoading, setIsLoading] = useState(false);

  const parseWeekdays = (workingHours: WorkingHours[]) => {
    return workingHours
      .filter((weekday) => weekday.availability.includes("1"))
      .map((weekday) => weekday.day_of_week);
  };

  useEffect(() => {
    // On the backend, if studioRoomId is None, we fall back to the user_id,
    // if that is not present, we fallback to the requesting user
    setIsLoading(true);
    void dispatch(getWorkingHours({ studio_room_id: studioRoomId }))
      .unwrap()
      .then((result) => {
        const workingDays = parseWeekdays(result);
        setWeekdays(workingDays);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, studioRoomId]);

  useEffect(() => {
    if (startAMPM.value === 0 && endAMPM.value === 0) {
      if (startTime.value >= endTime.value) {
        setNextDay(true);
      } else {
        setNextDay(false);
      }
    } else if (startAMPM.value === 1 && endAMPM.value === 1) {
      if (startTime.value >= endTime.value) {
        setNextDay(true);
      } else {
        setNextDay(false);
      }
    } else if (startAMPM.value === 1 && endAMPM.value === 0) {
      setNextDay(true);
    } else {
      setNextDay(false);
    }
  }, [startAMPM, endAMPM, startTime, endTime]);

  const saveAvailability = useCallback(() => {
    const workingHours: workingHoursParam = {
      [WEEKDAYS.MONDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.TUESDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.WEDNESDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.THURSDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.FRIDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.SATURDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.SUNDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
    };

    for (const weekday of weekdays) {
      const startHour = convertTimeToHour(startTime.value, startAMPM.value);
      const endHour = convertTimeToHour(endTime.value, endAMPM.value);
      if (startHour === INVALID_AM_PM || endHour === INVALID_AM_PM) {
        return toast.error('Please select a valid start and end time."');
      }
      if (startHour < endHour) {
        workingHours[weekday] = convertBinaryArrayToBinaryString(
          fillInUnavailability(startHour, endHour),
        );
      } else {
        workingHours[weekday] = convertBinaryArrayToBinaryString(
          fillInAvailability(endHour, startHour),
        );
      }
    }
    setUpdatingAvailability(true);
    dispatch(
      createWorkingHours({
        working_hours: workingHours,
        studio_room_id: studioRoomId,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success("Your availability preferences have been saved!");
        setUpdatingAvailability(false);
        if (onSuccessfulSubmit) onSuccessfulSubmit();
      })
      .catch(() => {
        toast.error(
          "There was an error updating your availability. Please try again later.",
        );
        setUpdatingAvailability(false);
      });
  }, [
    dispatch,
    studioRoomId,
    weekdays,
    startTime.label,
    startAMPM.value,
    endTime.label,
    endAMPM.value,
    onSuccessfulSubmit,
  ]);

  if (isLoading)
    return <SoundWaveLoader whiteLoader={false} width={120} height={20} />;

  return (
    <div>
      <p className="label-semi-bold mb-2">Select your days of operation</p>
      <WeekdayPicker weekdaysInput={weekdays} onChange={setWeekdays} />
      <p className="label-semi-bold mb-2">
        {studioRoomId
          ? `Select ${studioRoomName} Hours`
          : "Generally Available Recording Hours"}
      </p>
      <p className="b1-semi-bold mb-2">Start Time:</p>
      <SelectTimeInDayDropdownComponent
        onHourChange={(option) => setStartTime(option)}
        hour={startTime}
        onTimeChange={setStartAMPM}
        time={startAMPM}
      />
      <p className="b1-semi-bold mt-2 mb-2">
        End Time{nextDay ? " (next day)" : ""}:
      </p>
      <SelectTimeInDayDropdownComponent
        onHourChange={(option) => setEndTime(option)}
        hour={endTime}
        onTimeChange={setEndAMPM}
        time={endAMPM}
      />
      {setShowWorkingHoursSetup && (
        <Button
          loading={updatingAvailability}
          className="mt-4 submit-available-hours-button"
          onClick={() => setShowWorkingHoursSetup(false)}
          variant={ButtonVariant.OUTLINED}
          fullWidth
        >
          Go to Previous Step
        </Button>
      )}
      <Button
        disabled={weekdays.length === 0}
        loading={updatingAvailability}
        className="mt-4 submit-available-hours-button"
        onClick={saveAvailability}
        variant={ButtonVariant.PRIMARY}
        fullWidth
      >
        Finish Setup
      </Button>
    </div>
  );
};

export interface SelectHourInDayDropdownComponentProps {
  onHourChange?: (option: OptionType) => void;
  hour: OptionType;
  onTimeChange?: (option: OptionType) => void;
  time: OptionType;
}

export const SelectTimeInDayDropdownComponent = ({
  onHourChange,
  onTimeChange,
  hour,
  time,
}: SelectHourInDayDropdownComponentProps) => {
  return (
    <div className="select-time-drop-down-container">
      <DropdownSelector
        onChange={onHourChange}
        value={hour}
        options={SelectHourInDayOptions}
      />
      <DropdownSelector
        options={SelectAMPMOptions}
        value={time}
        onChange={onTimeChange}
      />
    </div>
  );
};
