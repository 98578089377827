import { Box, useTheme } from "@mui/material";
import { matchIsValidTel, MuiTelInputCountry } from "mui-tel-input";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getDocUrl } from "../../../constants/googleStorage";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { useGeolocationGoogleAppEngine } from "../../../hooks/useGeolocationCountryCode";
import { updateProfile } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { PhoneInput } from "../../core-ui/components/PhoneNumber/PhoneInput";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { FieldContainer } from "../SignInForm/SignInForm.styles";
import {
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";

export const AddPhone = () => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const authNavigation = useAuthNavigationSteps();
  const { nextStep } = authNavigation();
  const { countryCode, loading } = useGeolocationGoogleAppEngine();
  const { isUpdatingProfile } = useAppSelector((state) => state.accountInfo);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

  const handleAddPhoneNumber = () => {
    emitAnalyticsTrackingEvent("auth_add_phone_clicked_submit", {}, user?.id);
    dispatch(updateProfile({ phone_number: phoneNumber }))
      .unwrap()
      .then(() => {
        nextStep();
      });
  };

  const signUpFooter = (
    <Box>
      <Text color={TextColor.SECONDARY}>
        {"By signing up, you are agreeing to the "}
        <Link
          style={{
            color: theme.palette.text.secondary,
            textDecoration: "underline",
          }}
          to={getDocUrl("EngineEarsTermsOfUse.pdf")}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </Link>
        {" and "}
        <Link
          style={{
            color: theme.palette.text.secondary,
            textDecoration: "underline",
          }}
          to={getDocUrl("EngineEarsPrivacyPolicy.pdf")}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
        .
      </Text>
    </Box>
  );

  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <Text variant={TextStyleVariant.H6}>Add your phone number</Text>
        <Text variant={TextStyleVariant.P1}>
          Add your phone number and receive real-time booking notifications and
          project progress through text message. Your digital personal
          assistant.
        </Text>
        <Text variant={TextStyleVariant.P1}>
          *Message and data rates may apply.
        </Text>
        <FieldContainer>
          <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Phone number</Text>
          <PhoneInput
            disabled={isUpdatingProfile || loading}
            isValidPhoneNumber={isValidPhoneNumber}
            defaultCountry={(countryCode as MuiTelInputCountry) || "US"}
            value={phoneNumber}
            onChange={(value: string) => {
              setPhoneNumber(value);
              setIsValidPhoneNumber(matchIsValidTel(value));
            }}
          />
        </FieldContainer>
        <Button
          fullWidth={true}
          loading={isUpdatingProfile}
          variant={ButtonVariant.PRIMARY}
          disabled={
            !phoneNumber || !isValidPhoneNumber || isUpdatingProfile || loading
          }
          onClick={handleAddPhoneNumber}
        >
          Add phone number
        </Button>
        <Button
          variant={ButtonVariant.UNSTYLED}
          style={{
            textDecoration: "underline",
            alignSelf: "flex-end",
            color: theme.palette.text.primary,
          }}
          onClick={() => {
            emitAnalyticsTrackingEvent(
              "auth_add_phone_clicked_remind_me_later",
              {},
              user?.id,
            );
            nextStep({ skipVerification: true });
          }}
        >
          Remind me later
        </Button>
      </MainContentContainer>
      <SignUpFooter>{signUpFooter}</SignUpFooter>
    </FormContainer>
  );
};
