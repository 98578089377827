import { useMemo } from "react";
import { useIsAffiliatedManagerWithStudio } from "../../../../../hooks/studioHooks";
import { useIsAandR } from "../../../../../hooks/useIsAandR";
import { toggleDurationModal } from "../../../../../store/actions/recordingBookingMobileState";
import {
  PendingSessionData,
  setSelectedIndex,
  updateDurationAtIndex,
} from "../../../../../store/actions/shoppingCart";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { RecordingService } from "../../../../../store/models/recording";
import { generatePreferredHoursOptions } from "../../../../../store/utils/serviceUtils";
import {
  hoursFromMinutes,
  minutesFromHours,
} from "../../../../../store/utils/shoppingCartUtils";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import { Text } from "../../../../core-ui/components/Text/Text";
import {
  GenerateBookingDropdown,
  GenerateBookingDropdownNoBorder,
} from "../../GenerateBookingDropdown";

export interface GenerateBookingSessionSelectDurationProps {
  durationFromSession?: number;
  index?: number;
  isSelected?: boolean;
  isMobile?: boolean;
  recordingService: RecordingService | null;
  useTravelPrice?: boolean;
}

export const GenerateBookingSessionSelectDuration = ({
  durationFromSession,
  index,
  isSelected,
  isMobile,
  recordingService,
  useTravelPrice = false,
}: GenerateBookingSessionSelectDurationProps) => {
  const dispatch = useAppDispatch();
  const { activeStudioId } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const activeStudio = useAppSelector(
    (state) => state.studiosSlice[activeStudioId ?? -1],
  );
  const user = useAppSelector((state) => state.accountInfo.user);
  const isAnRAccount = useIsAandR(user);
  const userIsMe = useIsAffiliatedManagerWithStudio(activeStudio);
  const { minimum_session_time_minutes, maximum_session_time_minutes } =
    useMemo(() => {
      return recordingService
        ? recordingService
        : {
            minimum_session_time_minutes: 30,
            maximum_session_time_minutes: 120,
          };
    }, [recordingService]);

  const preferredHourOptions = useMemo(() => {
    if (!recordingService) return [];
    return generatePreferredHoursOptions(
      minimum_session_time_minutes,
      maximum_session_time_minutes,
      isAnRAccount && !userIsMe,
      recordingService?.service_rate,
      recordingService.recording_service_discount_rate,
      useTravelPrice,
    );
  }, [
    recordingService,
    isAnRAccount,
    userIsMe,
    minimum_session_time_minutes,
    maximum_session_time_minutes,
  ]);
  const sessions = useAppSelector(
    (state) => state.shoppingCart.pendingSessionData,
  );

  const selectedSession: PendingSessionData | undefined = sessions
    ? sessions[index ?? 0]
    : undefined;

  const duration = selectedSession?.duration;

  return (
    <Button
      fullWidth
      onClick={() => {
        if (isMobile) {
          if (index) dispatch(setSelectedIndex(index));
          dispatch(toggleDurationModal());
          return;
        }
      }}
      variant={ButtonVariant.UNSTYLED}
    >
      <GenerateBookingDropdown
        isSelected={isSelected}
        noBorder={[
          GenerateBookingDropdownNoBorder.BOTTOM,
          GenerateBookingDropdownNoBorder.RIGHT,
        ]}
        isDisabled={isMobile}
        options={preferredHourOptions}
        placeholder="Hours"
        value={
          duration
            ? {
                label: `${hoursFromMinutes(duration)} hours`,
                value: hoursFromMinutes(duration), // Options are in hours, thus the selected value must be in hour as well!
              }
            : null
        }
        customComponents={(option) => <Text>{option.label}</Text>}
        onChange={(option) => {
          if (index === undefined) return;
          const hours = option.value;
          const minutes = minutesFromHours(hours);
          if (minutes === durationFromSession) return;
          dispatch(
            updateDurationAtIndex({
              index,
              duration: minutes,
            }),
          );
        }}
      />
    </Button>
  );
};
