import React, { FC, ReactElement } from "react";
import "./badge.css";
import {
  badgeListMap,
  BadgeEnum,
  FullWidthBadges,
} from "../../../store/models/trophy";
import { CircularProgress } from "@mui/material";

export interface BadgeProps {
  badgeEnum: BadgeEnum;
  size?: "small" | "medium" | "large";
  progress?: number;
}

export const Badge: FC<BadgeProps> = ({
  badgeEnum = 1,
  size = "medium",
}): ReactElement => {
  if (FullWidthBadges.includes(badgeEnum)) {
    return (
      <div className={`full-width-badge-container`}>
        <img
          alt="badge"
          className={`full-width-badge`}
          src={badgeListMap[badgeEnum]}
        />
      </div>
    );
  }
  return (
    <div className={`badge--${size} badge-component`}>
      <img alt="badge" className={`badge--img`} src={badgeListMap[badgeEnum]} />
    </div>
  );
};

export const BadgeProgress: FC<BadgeProps> = ({
  badgeEnum = 1,
  size = "medium",
  progress = 0,
}): ReactElement => {
  if (FullWidthBadges.includes(badgeEnum)) {
    return (
      <div className={`full-width-badge-container`}>
        <img
          alt="badge"
          className={`full-width-badge`}
          src={badgeListMap[badgeEnum]}
        />
      </div>
    );
  }
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <img
        alt="badge"
        className={`badge--img`}
        src={badgeListMap[badgeEnum]}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          filter: progress !== 100 ? "grayscale(100%)" : "none",
        }}
      />
      <CircularProgress
        variant="determinate"
        value={100}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%) rotate(-90deg)",
          color: "#F5F4F4",
          padding: "13px",
        }}
      />
      <CircularProgress
        variant="determinate"
        value={progress}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%) rotate(-90deg)",
          color: "#6EC3F2",
          padding: "13px",
        }}
      />
    </div>
  );
};
