import { GET_ACTIVE_STUDIOS } from "../../../store/utils/routes";
import { ProfileType } from "../../../store/models/base";
import { SlimActiveStudio } from "../../../store/models/studio";
import { makeBackendGetCallWithJsonResponse } from "../../../store/utils/fetch";
import { EngineEarsError } from "../../../store/actions/errorStore";

export const fetchActiveStudios = async (): Promise<
  SlimActiveStudio[] | EngineEarsError
> => {
  return makeBackendGetCallWithJsonResponse<SlimActiveStudio[]>(
    GET_ACTIVE_STUDIOS,
    "",
  ).then((res) => {
    if (res.success) {
      const transformedData: SlimActiveStudio[] = res.resultJson.map(
        (value) => {
          return { ...value, type: ProfileType.STUDIO };
        },
      );
      return transformedData;
    }
    return res.resultJson;
  });
};
