import { PopoverTrigger } from "@radix-ui/react-popover";
import { useSetAtom } from "jotai";
import { sidePanelBottomDrawerContentAtom } from "../../../../../../atoms/sidePanelAtoms";
import { useIsUMGAandR } from "../../../../../../hooks/useIsAandR";
import { useMediaQueryBreakpoint } from "../../../../../../hooks/useMediaQuery";
import useModal from "../../../../../../hooks/useModal";
import { useAppSelector } from "../../../../../../store/hooks";
import { BasePopover } from "../../../../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../../../core-ui/components/BasePopover/PopoverContainerContext";
import { Text } from "../../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../../core-ui/components/Text/TextUtils";
import {
  FeedbackForm,
  UMGFeedbackForm,
} from "../../../../FeedbackModal/FeedbackModal";
import { DropdownMenuItem } from "../../../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";

/**
 * Action Option to open Feedback Popover
 */
export const ProjectActionReportUser = () => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const isUMGAandR = useIsUMGAandR(user);
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );
  const { isMobile } = useMediaQueryBreakpoint();
  const { isOpen, setIsOpen, closeModal: closePopover } = useModal();
  const setBottomDrawerContent = useSetAtom(sidePanelBottomDrawerContentAtom);

  const feedbackForm = isUMGAandR ? (
    <UMGFeedbackForm onClose={closePopover} />
  ) : (
    <div className="non-umg-form-container">
      <FeedbackForm onClose={closePopover} />
    </div>
  );

  if (isMobile) {
    return (
      <DropdownMenuItem
        onSelect={() => {
          setBottomDrawerContent(
            <>
              <Text variant={TextStyleVariant.H5} style={{ padding: "0 18px" }}>
                Want to provide feedback?
              </Text>
              {feedbackForm}
            </>,
          );
        }}
      >
        Report user
      </DropdownMenuItem>
    );
  }

  return (
    <BasePopover
      isOpen={isOpen}
      setIsPopoverOpen={setIsOpen}
      side="top"
      title="Want to provide feedback?"
      description={feedbackForm}
      hideFooter
      wrapperElement={containerElement}
    >
      <PopoverTrigger asChild>
        <DropdownMenuItem onSelect={(e: Event) => e.preventDefault()}>
          Report user
        </DropdownMenuItem>
      </PopoverTrigger>
    </BasePopover>
  );
};
