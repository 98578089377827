import { useSetAtom } from "jotai";
import { ReactNode, useEffect, useRef } from "react";
import { navElementRefAtom } from "../../../atoms/navAtoms";
import { useStickyCoverPhoto } from "../../../hooks/profileScreenHooks/useStickyCoverPhoto";
import { useHideNavElementsCallback } from "../../../hooks/useHideNavElementsCallback";

interface ThemeDivProps {
  darkMode?: string | null;
  children?: ReactNode;
}

export const ThemeDiv = ({ children, darkMode }: ThemeDivProps) => {
  const { handleScroll } = useHideNavElementsCallback();
  const setElementRef = useSetAtom(navElementRefAtom);
  const elementRef = useRef<HTMLDivElement>(null);
  const handleStickyCoverPhoto = useStickyCoverPhoto();

  useEffect(() => {
    setElementRef(elementRef);
  }, [elementRef, setElementRef]);

  return (
    <div
      ref={elementRef}
      onScrollCapture={(e) => {
        handleScroll(e);
        handleStickyCoverPhoto(e);
      }}
      data-theme={darkMode ? "dark" : ""}
    >
      {children}
    </div>
  );
};
