import { ProjectType } from "../../../store/models/project";
import Service from "../../../store/models/service";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch";
import "../ManageEngineeringService/ManageEngineeringService.css";

interface EngineeringServiceDownloadPreferenceProps {
  show: boolean;
  pendingService: Service;
  onWAVUpdate: (_: boolean) => void;
  onMP3Update: (_: boolean) => void;
}

const EngineeringServiceDownloadPreference = ({
  show,
  pendingService,
  onWAVUpdate,
  onMP3Update,
}: EngineeringServiceDownloadPreferenceProps) => {
  // https://engineears.atlassian.net/browse/EN-4529
  // Temporarily hide the download preference selection during service setup.
  if (!show) {
    return null;
  }

  const showMP3DownloadOption =
    pendingService.service_type !== ProjectType.ATMOS_MIXING;

  const downloadTypeString =
    pendingService.service_type === ProjectType.MASTERING ? "master" : "mix";

  return (
    <>
      {showMP3DownloadOption && (
        <div className="add-engineer-service-form-row">
          <div className="add-engineer-service-form-row-label">
            <p>{`Allow artist to download your main ${downloadTypeString} MP3 file during review. You will be able to set this on a per-project basis as well.`}</p>
          </div>
          <div className="add-engineer-service-form-row-input">
            <ToggleSwitch
              id="mp3-file-download-toggle"
              checked={pendingService.enable_artist_review_mp3_file_download}
              onChange={(checked: boolean) => {
                onMP3Update(checked);
              }}
            />
          </div>
        </div>
      )}
      <div className="add-engineer-service-form-row">
        <div className="add-engineer-service-form-row-label">
          <p>{`Allow artist to download your main ${downloadTypeString} WAV file during review. You will be able to set this on a per-project basis as well.`}</p>
        </div>
        <div className="add-engineer-service-form-row-input">
          <ToggleSwitch
            id="wav-file-download-toggle"
            checked={pendingService.enable_artist_review_wav_file_download}
            onChange={(checked: boolean) => {
              onWAVUpdate(checked);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EngineeringServiceDownloadPreference;
