import { toast } from "react-toastify";
import {
  defaultMixMasterCart,
  discountValueToLabelMap,
  MixMasterCart,
} from "../actions/mixMasterCartsStore";
import {
  defaultRecordingCart,
  RecordingCart,
  SessionData,
} from "../actions/recordingCartsStore";
import { Alt } from "../models/alts";
import { ProjectType } from "../models/project";
import { MinimalScheduledProject } from "../models/scheduledproject";
import { Transaction } from "../models/transaction";
import { getInitialBookingParametersCookie } from "./transactionBookingUtils";
import { getCartDiscountStatusAndValuesFromTransaction } from "./cartUtils";

export interface BookingParameters {
  transactionId: number;
  activeServiceType?: ProjectType;
  engineerId?: number;
  engineerUserId?: number;
  activeServiceTypeProjectIds: number[];
  activeStudioId?: number;
  activeStudioRoomId?: number;
}

export const formatTransactionForBooking = (
  transaction: Transaction,
  scheduledProject: MinimalScheduledProject | null,
  overwritePrice: number | null,
  redirectToHomePage: () => void,
) => {
  let activeServiceType: ProjectType | undefined;
  let quantity = 0;
  let addMaster = false;
  let addAtmos = false;
  let engineerId: number | undefined;
  let engineerUserId: number | undefined;
  let activeStudioId: number | undefined;
  let activeStudioRoomId: number | undefined;
  let alts: Alt[] | undefined;
  let inProgressProject = false;
  let engineerHasFiles = false;
  let projectFirstPassDate: string | null = null;
  const activeServiceTypeProjectIds: number[] = [];
  let promocode: string | undefined;

  const initialBookingParametersJSON = getInitialBookingParametersCookie(
    transaction.code,
  );
  const initialBookingParameters = initialBookingParametersJSON
    ? (JSON.parse(initialBookingParametersJSON) as BookingParameters)
    : null;

  if (!transaction.items?.length) {
    // If the transaction has no items, fetch the initial booking parameters
    // for the transaction from the browser's cookies.

    if (!initialBookingParameters) {
      toast.error("Transaction does not exist.");
      redirectToHomePage();
      return;
    }

    if (initialBookingParameters.activeServiceType === ProjectType.RECORDING) {
      return {
        bookingParameters: initialBookingParameters,
        recordingCart: defaultRecordingCart,
      };
    } else {
      return {
        bookingParameters: initialBookingParameters,
        mixMasterCart: defaultMixMasterCart,
      };
    }
  }

  const { providerAssumesFees, predefinedDiscountOption } =
    getCartDiscountStatusAndValuesFromTransaction(transaction);

  // If transaction includes recording sessions, only return RecordingCart information.
  if (transaction.recording_sessions?.length) {
    const recordingCart: RecordingCart = {
      title: "",
      artistName: "",
      promocode: "",
      applyLabelRate: false,
      sessionData: [] as SessionData[],
      overwritePrice,
      providerAssumesFees,
      shareArtistContactInfo: false,
      predefinedDiscountOption,
    };
    activeServiceType = ProjectType.RECORDING;
    transaction.recording_sessions?.forEach((session) => {
      const sessionData = {};
      (sessionData as SessionData).duration = session.session_duration_minutes;
      if (session.project.id) {
        activeServiceTypeProjectIds.push(session.project.id);
      }
      if (session.engineer) {
        (sessionData as SessionData).engineerUserId = session.engineer.user_id;
      }
      if (session.studio_room) {
        activeStudioRoomId = session.studio_room.id;
        activeStudioId = session.studio_room.studio?.id;
        (sessionData as SessionData).studioRoomId = session.studio_room.id;
      }
      if (!recordingCart.title && session.title) {
        recordingCart.title = session.title;
      }
      if (!recordingCart.artistName && session.artist_name) {
        recordingCart.artistName = session.artist_name;
      }
      recordingCart.sessionData.push(sessionData as SessionData);
    });
    const promocode = transaction.items[0].promo_code?.code ?? null;

    if (promocode) {
      recordingCart.promocode = promocode;
    }

    // Set the engineerId and engineerUserId based on the stored booking parameters cookie,
    // as there's no real reliable way to fetch the information from recording sessions.
    engineerId = initialBookingParameters?.engineerId;
    engineerUserId = initialBookingParameters?.engineerUserId;

    const bookingParameters: BookingParameters = {
      transactionId: +transaction.id,
      activeServiceType,
      engineerId,
      engineerUserId,
      activeServiceTypeProjectIds,
      activeStudioId,
      activeStudioRoomId,
    };
    return { bookingParameters, recordingCart };
    // Otherwise, treat as a MixMaster transaction.
  } else {
    transaction.items.forEach((transactionItem) => {
      if (!transactionItem.project) {
        return;
      }

      const transactionItemProject = transactionItem.project;
      const serviceType = transactionItemProject.service_type;

      // If the project has a prereq_project_id, this means that it's
      // either an add master or add atmos add-on, and not a primary
      // selected project.
      if (!transactionItemProject.prereq_project_id) {
        activeServiceType = serviceType;
        quantity += 1;
        alts = transactionItemProject.alts?.map((altItem) => altItem.alt);
        inProgressProject = !transactionItemProject.deleted;
        engineerId = transactionItemProject.engineer?.id;
        engineerUserId = transactionItemProject.engineer?.user_id;
        engineerHasFiles = Boolean(transactionItemProject.engineer_has_files);
        activeServiceTypeProjectIds.push(transactionItemProject.id);
        promocode = transactionItem.promo_code?.code;
        projectFirstPassDate = transactionItemProject.first_pass_date;
      } else {
        if (transactionItemProject.service_type === ProjectType.MASTERING) {
          addMaster = true;
        } else if (
          transactionItemProject.service_type === ProjectType.ATMOS_MIXING
        ) {
          addAtmos = true;
        }
      }
    });
    const mixMasterCart: MixMasterCart = {
      ...defaultMixMasterCart,
      quantity,
      engineerHasFiles,
      inProgressProject,
      alts: alts ?? [],
      addMaster,
      addAtmos,
      title: scheduledProject?.title ?? "",
      artistName: scheduledProject?.artist_name ?? "",
      applyLabelRate: Boolean(scheduledProject?.label_project),
      estimatedDeliveryDateData: scheduledProject?.estimated_delivery_date
        ? { date: scheduledProject.estimated_delivery_date, is_computed: false }
        : projectFirstPassDate
          ? { date: projectFirstPassDate, is_computed: true }
          : null,
      promocode: promocode ?? null,
      overwritePrice,
      providerAssumesFees,
      predefinedDiscountOption,
    };

    const bookingParameters = {
      transactionId: +transaction.id,
      activeServiceType,
      engineerId,
      engineerUserId,
      activeServiceTypeProjectIds,
      activeStudioId,
      activeStudioRoomId,
    };

    return {
      bookingParameters,
      mixMasterCart,
    };
  }
};
