import { styled, TextField, Box } from "@mui/material";

export const PasswordFieldContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 16px;
`;

export const PasswordFieldInput = styled(TextField)`
  border-radius: 8px;

  & .MuiOutlinedInput-root {
    & fieldset {
      border-radius: 8px;
    }
  }
`;

export const ShowHideIcon = styled(Box)`
  cursor: pointer;
`;

export const PasswordChecksContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 8px;
  gap: 4px;
`;

export const PasswordRequirement = styled(Box)`
  display: flex;
  align-items: center;
  max-height: 20px;
  gap: 8px;
`;
