import { createTheme } from "@mui/material";
import { MuiButtonTheme } from "../../stories/core-ui/components/Button/Button.styles";
import { standardColor } from "../colors";
import muiTypographyTheme from "./typography";
import customZIndex from "./zIndex";

const { Gray } = standardColor;
/**
 * Common theme between light and dark mode
 * @TODO add support for other border radius
 */
const muiBaseTheme = createTheme(muiTypographyTheme, {
  palette: {
    grey: {
      50: Gray[50],
      100: Gray[100],
      200: Gray[200],
      300: Gray[300],
      400: Gray[400],
      500: Gray[500],
      600: Gray[600],
      700: Gray[700],
      800: Gray[800],
      900: Gray[900],
    },
    // all of the EngineEars colors defined in Figma
    standardColor,
    // custom colors from Styled-Components theme
    customColor: {
      checklistBoxShadow: "0px 0px 6px 0px rgba(109, 109, 109, 0.18)",
      popoverBoxShadow: "0px 2px 30px 0px rgba(0, 0, 0, 0.15)",
      regionDefault: "rgba(0, 0, 0, 0.1)",
      regionSelected: "rgba(40, 167, 69, 0.5)",
      buttonBoxShadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -3px 4px 0px rgba(0, 0, 0, 0.04), 0px -1px 2px 0px rgba(0, 0, 0, 0.04),
    0px 1px 2px 0px rgba(0, 0, 0, 0.04)`,
    },
  },
  components: {
    MuiButton: MuiButtonTheme,
  },
  shape: {
    // set the default border radius to 8px
    borderRadius: 8,
  },
  border: {
    radius: {
      xxs: "2px",
      xs: "4px",
      sm: "6px",
      md: "8px",
      semiLg: "12px",
      lg: "16px",
      xl: "32px",
    },
  },
  zIndex: {
    customZIndex,
  },
});

export default muiBaseTheme;
