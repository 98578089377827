import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material";

export const StyledWhatsIncludedListItem = styled("li")({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  columnGap: "8px",
});

export const ServiceCardBenefitsItemCheck = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    transform: "translateY(3px)",
  }),
);
