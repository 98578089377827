import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSetAtom } from "jotai";
import { ProjectUserType } from "../../../store/models/project";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { BookingDetails } from "../BookingRequestedPanel/BookingDetails";
import { MainWorkflowView, mainWorkflowViewAtom } from "./atoms";

interface MainProjectWorkflowBookingDetailsViewProps {
  scheduledProjectId: number | null;
  isInProgressProject?: boolean;
  userType?: ProjectUserType | null;
}

export const MainProjectWorkflowBookingDetailsView = ({
  scheduledProjectId,
  isInProgressProject = false,
  userType,
}: MainProjectWorkflowBookingDetailsViewProps) => {
  const setMainWorkflowViewIndex = useSetAtom(mainWorkflowViewAtom);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={() => setMainWorkflowViewIndex(MainWorkflowView.MAIN)}
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            style={{ marginRight: "0.5rem" }}
          />
          Back
        </Button>
      </div>
      {scheduledProjectId && !isInProgressProject && (
        <BookingDetails
          scheduledProjectId={scheduledProjectId}
          userType={userType}
        />
      )}
    </>
  );
};
