import { styled, Box } from "@mui/material";

export const ArtistReleasesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 320px;
  gap: 24px;
`;

export const ArtistReleasesHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ArtistReleaseButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const ReleaseFilterButton = styled("button")<{
  $selected?: boolean;
}>((props) => ({
  padding: "8px",
  alignItems: "center",
  background: props.$selected ? props.theme.palette.grey[50] : "transparent",
  border: `solid 1px ${props.$selected ? "transparent" : props.theme.palette.grey[200]}`,
  cursor: "pointer",
  textTransform: "capitalize",
  borderRadius: "8px",
}));

export const ReleaseTypeLabel = styled("span")<{
  $selected?: boolean;
}>((props) => ({
  padding: "8px",
  alignItems: "center",
  background: props.$selected ? props.theme.palette.grey[50] : "transparent",
  border: `solid 1px ${props.$selected ? "transparent" : props.theme.palette.grey[200]}`,
  textTransform: "capitalize",
  borderRadius: "8px",
}));
