import styled from "styled-components";
import { Button } from "../../../../../core-ui/components/Button/Button";

export const ProjectActionShareLinkButton = styled(Button)`
  width: 180px;
`;

export const ProjectActionShareLinkInput = styled.input`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.separatorColor};
  border-radius: ${({ theme }) => theme.border.radius.md};
  padding: 10px;
  width: 100%;
`;

export const ProjectActionShareLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
