import { styled, Box, List, ListItem } from "@mui/material";

export const ManageSubscriptionCardContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
}));

export const ManageSubscriptionCardHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  width: "100%",
  alignItems: "center",
}));

export const ManageSubscriptionCardBenefitList = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  listStylePosition: "outside",
  paddingTop: 0,
  paddingBottom: 0,
  marginLeft: "16px",
  boxSizing: "border-box",

  [theme.breakpoints.down("sm")]: {
    marginLeft: "20px",
  },
}));

export const ManageSubscriptionCardBenefitListItem = styled(ListItem)(
  ({ theme }) => ({
    display: "list-item",
    listStyleType: "disc",
    textTransform: "capitalize",
    color: theme.palette.text.secondary,
    padding: "0px",
    boxSizing: "border-box",
  }),
);
