export const useGetContinuousBlockFromTime = (
  availabilities: Date[],
  originalSessionEndTimeLocalDate: Date,
) => {
  const MILLISECONDS_IN_HOUR = 3600000;
  const block: Date[] = [];

  // Function to check if the time is within the block
  const isTimeWithinBlock = (time: number, block: Date[]) =>
    block.length !== 0 &&
    time - block[block.length - 1].getTime() !== MILLISECONDS_IN_HOUR;

  for (const item of availabilities) {
    const itemTime = item.getTime();
    const originalTime = originalSessionEndTimeLocalDate.getTime();

    if (itemTime < originalTime) {
      continue;
    }

    if (isTimeWithinBlock(itemTime, block)) {
      break;
    }

    block.push(item);
  }

  return block;
};
