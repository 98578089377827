import { useAtom } from "jotai";
import { useContext } from "react";
import { DropdownContext } from "../../../../../core-ui/components/Select/Select";
import { DropdownMenuItem } from "../../../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";
import {
  SessionDetailsPanelView,
  sessionDetailsPanelViewAtom,
} from "../../../../SessionDetailsPanel/atoms";

/**
 * Action Option to toggle open the session details view
 */
export const ProjectActionViewSessionDetails = () => {
  const dropdownContext = useContext(DropdownContext);
  const [sessionDetailsView, setSessionDetailsView] = useAtom(
    sessionDetailsPanelViewAtom,
  );
  const isMainView = sessionDetailsView === SessionDetailsPanelView.MAIN;

  return (
    <DropdownMenuItem
      onSelect={(e: Event) => {
        e.preventDefault();
        setSessionDetailsView(
          isMainView
            ? SessionDetailsPanelView.SESSION_REQUESTED_DETAILS
            : SessionDetailsPanelView.MAIN,
        );
        dropdownContext?.closeDropdownMenu();
      }}
    >
      {isMainView ? "View booking details" : "View session details"}
    </DropdownMenuItem>
  );
};
