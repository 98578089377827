import "./ReviewAggregate.css";
import reviewStar from "../../assets/review-star.svg";
export interface ReviewAggregateProps {
  reviews: number;
  average: number;
}

export const ReviewAggregate = ({ reviews, average }: ReviewAggregateProps) => {
  if (reviews === 0) {
    return null;
  }

  return (
    <div className="review-aggregate-container">
      <img
        src={reviewStar}
        height={12}
        width={12}
        alt="review star"
        className="review-aggregate-spacing"
      />
      <p className="b3-semi-bold review-aggregate-spacing review-aggregate-text">
        {average}{" "}
      </p>
      <p className="b3 review-aggregate-text">({reviews})</p>
    </div>
  );
};
