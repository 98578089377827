import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { convertAvailabilityForDateToMap } from "../utils/utils";
import { selectCurrentUser } from "./userInfoSelectors";
import { RecordingService } from "../models/recording";
import { StudioRoom } from "../models/studio";

export const selectShoppingCart = (state: RootState) => state.shoppingCart;

const selectLastSessionInShoppingCart = createSelector(
  selectShoppingCart,
  (shoppingCart) => {
    const pendingSessionData = shoppingCart.pendingSessionData;
    if (!pendingSessionData) {
      return undefined;
    }
    return pendingSessionData[pendingSessionData.length - 1];
  },
);

const selectShoppingCartLastSessionDuration = createSelector(
  selectLastSessionInShoppingCart,
  (lastSession) => {
    if (!lastSession) {
      return 0;
    }
    return lastSession.duration ?? 0;
  },
);

export const selectShoppingCartStudioRoomRecordingAvailability = (
  state: RootState,
) => state.availability.studio_room;

export const selectShoppingCartStudioRoomAvailabilities = (
  studioRoom?: StudioRoom,
) =>
  createSelector(
    selectShoppingCartStudioRoomRecordingAvailability,
    (studioRoomWorkingHoursState) => {
      if (!studioRoom) {
        return convertAvailabilityForDateToMap(undefined);
      }
      const availabilitiesForDate = studioRoomWorkingHoursState[studioRoom.id];
      return convertAvailabilityForDateToMap(availabilitiesForDate);
    },
  );

export const selectShoppingCartRecordingBookingDates = createSelector(
  selectShoppingCart,
  (shoppingCart) => {
    const pendingSessionData = shoppingCart.pendingSessionData;
    if (!pendingSessionData) {
      return [];
    }
    return pendingSessionData
      .filter((sessionData) => sessionData.selectedISODate !== undefined)
      .map((sessionData) => new Date(sessionData.selectedISODate ?? 0));
  },
);

export const selectShoppingCartLatestSessionDuration = (
  recordingService: RecordingService | null,
) =>
  createSelector(
    selectShoppingCartLastSessionDuration,
    (lastSessionDuration) => {
      return Math.max(
        recordingService?.minimum_session_time_minutes ?? 0,
        lastSessionDuration,
      );
    },
  );

export const selectIsPredefinedSession = (studioRoom?: StudioRoom) =>
  createSelector(selectCurrentUser, (currentUser) => {
    if (!currentUser) return false;
    if (!studioRoom?.studio) {
      return false;
    }

    const managers = studioRoom.studio.studio_team.managers;
    const members = studioRoom.studio.studio_team.members;
    if (
      managers.some((manager) => {
        return manager.id === currentUser.id;
      })
    ) {
      return true;
    }
    return Boolean(
      Boolean(studioRoom.studio.affiliated_engineer_booking_links_enabled) &&
        members.some((engineerUser) => {
          return engineerUser.id === currentUser.id;
        }),
    );
  });

export const selectCurrentlySelectedSessionData = createSelector(
  selectShoppingCart,
  (shoppingCart) => {
    const pendingSessionData = shoppingCart.pendingSessionData;
    if (!pendingSessionData) {
      return undefined;
    }
    const index = shoppingCart.selectedIndex;
    if (index === undefined) {
      return undefined;
    }
    return pendingSessionData[index];
  },
);
