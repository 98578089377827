import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FC, useEffect, useMemo, useState } from "react";
import { useIsAffiliatedEngineerWithStudio } from "../../../../hooks/studioHooks";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import { clearRecordingBookingMobileState } from "../../../../store/actions/recordingBookingMobileState";
import {
  clearSessionAtIndex,
  PendingSessionData,
  removeSessionAtIndex,
  setSelectedIndex,
} from "../../../../store/actions/shoppingCart";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { RecordingService } from "../../../../store/models/recording";
import { StudioRoom } from "../../../../store/models/studio";
import { DeleteSessionConfirmationModal } from "../../DeleteSessionConfirmationModal/DeleteSessionConfirmationModal";
import {
  DRAWER_CONTAINER_ID,
  useBottomTabBarOverlayView,
} from "../../Navigation/BottomNav/useBottomTabBarOverlayView";
import { RecordingDetailSelectDateTimeInputMobileMenu } from "../../RecordingBookingDetails/RecordingDetailSelectDateTimeInputMobileMenu/RecordingDetailSelectDateTimeInputMobileMenu";
import { RecordingDetailSelectDurationMobileMenu } from "../../RecordingBookingDetails/RecordingDetailSelectDuration/RecordingDetailSelectDuration";
import { RecordingDetailsSelectTrackingEngineerMobileMenu } from "../../RecordingBookingDetails/RecordingDetailSelectTrackingEngineer/RecordingDetailSelectTrackingEngineer";
import GenerateBookingSelectServiceContainer, {
  GenerateBookingContainerVariant,
} from "../GenerateBookingSelectServiceContainer";
import { GenerateBookingUtilityButton } from "../GenerateBookingUtilityButton";
import { GenerateBookingSessionSelectDate } from "./components/GenerateBookingSessionSelectDate";
import { GenerateBookingSessionSelectDuration } from "./components/GenerateBookingSessionSelectDuration";
import { GenerateBookingSessionSelectEngineer } from "./components/GenerateBookingSessionSelectEngineer";
import { GenerateBookingSessionSelectTime } from "./components/GenerateBookingSessionSelectTime";
import "./GenerateBookingSessionDetail.css";

export interface GenerateBookingSessionDetailProps {
  pendingSession: PendingSessionData;
  index: number;
  recordingService: RecordingService | null;
  studioRoom: StudioRoom | undefined;
}

export const GenerateBookingSessionDetail: FC<
  GenerateBookingSessionDetailProps
> = ({ pendingSession, index, recordingService, studioRoom }) => {
  const { activeStudioId, activeStudioRoomId, engineerUserId, engineerId } =
    useAppSelector((state) => state.generateBookingStore);
  const activeStudio = useAppSelector(
    (state) => state.studiosSlice[activeStudioId ?? -1],
  );
  const isAffiliatedEngineer = useIsAffiliatedEngineerWithStudio(activeStudio);
  const loggedInEngineer = useAppSelector(
    (state) => state.accountInfo.user?.engineer,
  );
  const { isDesktop } = useMediaQueryBreakpoint();

  const preselectedEngineerAffiliatedWithStudio = Boolean(
    studioRoom?.studio?.studio_team?.members?.some(
      (member) => member.id === engineerUserId,
    ),
  );

  const selectedDateOptionDateValue = useMemo(() => {
    return pendingSession.selectedDateOption?.value
      ? new Date(pendingSession.selectedDateOption.value)
      : undefined;
  }, [pendingSession.selectedDateOption?.value]);

  const selectedDate = useMemo(() => {
    const selectedDate =
      pendingSession.selectedISODate ??
      pendingSession.selectedDateOption?.value;
    return selectedDate ? new Date(selectedDate) : undefined;
  }, [pendingSession]);

  const mobileDrawerPortal = useBottomTabBarOverlayView(
    !isDesktop,
    <>
      {" "}
      <RecordingDetailsSelectTrackingEngineerMobileMenu
        studioRoomId={activeStudioRoomId}
        recordingService={recordingService}
        studioRoom={studioRoom}
      />
      <RecordingDetailSelectDurationMobileMenu
        studioId={activeStudioId}
        recordingService={recordingService}
      />
      <RecordingDetailSelectDateTimeInputMobileMenu
        studioRoomId={activeStudioRoomId}
        recordingService={recordingService}
        studioRoom={studioRoom}
        engineerOnly={!studioRoom}
        engineer={pendingSession.trackingEngineer}
      />
    </>,
    "",
    DRAWER_CONTAINER_ID,
  );
  const modifiedByUser = Boolean(pendingSession.modifiedByUser);

  const [showDeleteSessionConfirmationModal, setShowDeleteSessionConfirmation] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { selectedIndex } = useAppSelector((state) => state.shoppingCart);
  const isSelected = useMemo(() => {
    return selectedIndex === index;
  }, [selectedIndex, index]);

  useEffect(() => {
    if (isDesktop) {
      dispatch(clearRecordingBookingMobileState());
    }
  }, [dispatch, isDesktop]);

  const cssPrefix = "generate-booking-session-detail_";

  const loggedInUserIsTrackingEngineerAndAffiliated =
    isAffiliatedEngineer &&
    pendingSession?.trackingEngineer?.id === loggedInEngineer?.id;
  const preselectedEngineerIsTrackingEngineerAndAffiliated =
    preselectedEngineerAffiliatedWithStudio &&
    pendingSession?.trackingEngineer?.user_id === engineerUserId;
  const preselectedEngineerWithoutStudio = Boolean(engineerId && !studioRoom);

  const skipEngineer =
    preselectedEngineerIsTrackingEngineerAndAffiliated ||
    loggedInUserIsTrackingEngineerAndAffiliated ||
    preselectedEngineerWithoutStudio;

  return (
    <GenerateBookingSelectServiceContainer
      index={index + 1}
      isSelected={isSelected}
      onClick={() => {
        if (index !== undefined && selectedIndex !== index) {
          dispatch(setSelectedIndex(index));
        }
      }}
      variant={GenerateBookingContainerVariant.RECORDING}
    >
      <div className={`${cssPrefix}date-time-row`}>
        <div className={`${cssPrefix}date-container`}>
          <GenerateBookingSessionSelectDate
            engineerAvailabilityOnly={
              Boolean(recordingService?.engineer) ||
              (preselectedEngineerAffiliatedWithStudio && !activeStudioRoomId)
            }
            index={index}
            isSelected={isSelected}
            selectedDate={selectedDate}
            selectedTime={selectedDateOptionDateValue}
            recordingService={recordingService}
            studioRoom={studioRoom}
          />
        </div>
        <div className={`${cssPrefix}time-duration-container`}>
          <GenerateBookingSessionSelectDuration
            index={index}
            isSelected={isSelected}
            isMobile={!isDesktop}
            durationFromSession={pendingSession.duration}
            recordingService={recordingService}
          />
          <div className={`${cssPrefix}time`}>
            <GenerateBookingSessionSelectTime
              isSelected={isSelected}
              index={index}
              isMobile={!isDesktop}
              preselectedDateOption={pendingSession.selectedDateOption}
              sessionDuration={pendingSession.duration}
              engineer={pendingSession.trackingEngineer}
              sessionISODateString={pendingSession.selectedISODate}
              generalWorkingHourIntervalLabel={
                pendingSession.generalWorkingHourIntervalLabel
              }
              transactionDateOption={pendingSession.preselectedDateTime}
              recordingService={recordingService}
              studioRoom={studioRoom}
              recordingDetailWithoutStudio={!activeStudioId}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <GenerateBookingSessionSelectEngineer
          index={index}
          isMobile={!isDesktop}
          engineer={pendingSession.trackingEngineer}
          isSelected={isSelected}
          duration={pendingSession.duration}
          selectedTime={selectedDateOptionDateValue}
          dateISOString={pendingSession.selectedISODate}
          startAndEndTimeText={
            pendingSession.selectedDateOption?.label ||
            pendingSession.generalWorkingHourIntervalLabel
          }
          hasNoTrackingEngineer={pendingSession.hasNoTrackingEngineer}
          recordingService={recordingService}
          studioRoom={studioRoom}
          autoSelectedTrackingEngineer={
            pendingSession.autoSelectedTrackingEngineer
          }
          selectAnyEngineer={pendingSession.selectAnyEngineer}
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          marginTop: "16px",
        }}
      >
        {modifiedByUser && (
          <GenerateBookingUtilityButton
            icon={faXmark}
            isSelected={isSelected}
            text={"Clear"}
            onClick={() => {
              if (index !== undefined) {
                dispatch(
                  clearSessionAtIndex({
                    index,
                    options: {
                      recordingService,
                      skipEngineer,
                    },
                  }),
                );
              }
            }}
          />
        )}
        <div style={{ marginLeft: "16px" }}>
          <GenerateBookingUtilityButton
            icon={faTrashCan}
            isSelected={isSelected}
            text={"Remove"}
            onClick={(e) => {
              e.stopPropagation();
              if (modifiedByUser) {
                setShowDeleteSessionConfirmation(true);
              } else {
                if (index !== undefined) dispatch(removeSessionAtIndex(index));
              }
            }}
          />
        </div>
      </div>
      <DeleteSessionConfirmationModal
        showDeleteSessionConfirmationModal={showDeleteSessionConfirmationModal}
        setShowDeleteSessionConfirmationModal={setShowDeleteSessionConfirmation}
        index={index}
      />
      {mobileDrawerPortal}
    </GenerateBookingSelectServiceContainer>
  );
};
