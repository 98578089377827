import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Amenity, AmenityType } from "../models/amenity";
import { RecordingService } from "../models/recording";
import { MockStudio, Studio, StudioRoom } from "../models/studio";
import { StudioWaitlistSubmission } from "../models/studioWaitlistSubmission";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  CREATE_STUDIO_ROOM,
  FETCH_STUDIO_ROOMS,
  GET_AFFILIATED_STUDIOS,
  GET_MY_STUDIOS,
  MANAGE_STUDIO_PROFILE,
  STUDIO_TO_WAIT_LIST,
  UPDATE_AMENITIES,
  UPDATE_STUDIO_PROFILE,
  UPDATE_STUDIO_ROOM,
  UPDATE_STUDIO_ROOM_BOOKING_STATUS,
} from "../utils/routes";
import { receiveErrors } from "./errorStore";
import { setStudio } from "./selectedProfile";

export interface Studios {
  [username: string]: Studio | undefined;

  [studio_id: number]: Studio | undefined;
}

const initialState: Studios = {};

export interface createOrDeleteStudioProfileParams {
  studio_username: string;
  deleted: boolean;
}

export const createOrDeleteStudioProfile = createAsyncThunk(
  MANAGE_STUDIO_PROFILE,
  async (args: createOrDeleteStudioProfileParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Studio>(
      MANAGE_STUDIO_PROFILE,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface getAffiliatedStudiosForUserParams {
  user_id?: number;
}

export const getAffiliatedStudiosForUser = createAsyncThunk(
  GET_AFFILIATED_STUDIOS,
  async (args: getAffiliatedStudiosForUserParams, thunkAPI) => {
    let params = "";
    if (args.user_id) {
      params = `?user_id=${args.user_id}`;
    }
    const result = await makeBackendGetCallWithJsonResponse<Studio[]>(
      GET_AFFILIATED_STUDIOS,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface addStudioToWaitListParams {
  studio_name: string;
  number_of_rooms: number;
  country: string;
  region: string;
  city: string;
  email: string;
  manages_studio: boolean;
  phone_number?: string;
}

export const addStudioToWaitList = createAsyncThunk(
  STUDIO_TO_WAIT_LIST,
  async (args: addStudioToWaitListParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<StudioWaitlistSubmission>(
        STUDIO_TO_WAIT_LIST,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface updateStudioProfileParams {
  studio_id?: number;
  username?: string;
  equipment_highlights?: string;
  location?: google.maps.places.PlaceResult;
  unit_number?: string;
  arrival_information?: string;
  timezone_shift_minutes?: number;
  allow_unaffiliated_engineers?: boolean;
  allow_bookings_without_engineer?: boolean;
  affiliated_engineer_booking_links_enabled?: boolean;
  tracking_engineer_price_included?: boolean;
  profile?: {
    display_name?: string;
    birth_date?: string;
    soundcloud_username?: string;
    instagram_username?: string;
    twitter_username?: string;
    facebook_username?: string;
    twitch_username?: string;
    tiktok_username?: string;
    youtube_username?: string;
    long_bio?: string;
    country?: string;
    city?: string;
    region?: string;
  };
}

export const updateStudio = createAsyncThunk(
  UPDATE_STUDIO_PROFILE,
  async (args: updateStudioProfileParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Studio>(
      UPDATE_STUDIO_PROFILE,
      args,
    );
    if (result.success) {
      thunkAPI.dispatch(setStudio(result.resultJson));
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getMyStudios = createAsyncThunk(
  GET_MY_STUDIOS,
  async (_, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<Studio[]>(
      GET_MY_STUDIOS,
      "",
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface updateAmenitiesParams {
  studio_id?: number;
  studio_room_id?: number;
  amenities: AmenityType[];
  username?: string;
}

export const updateAmenities = createAsyncThunk(
  UPDATE_AMENITIES,
  async (args: updateAmenitiesParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Amenity[]>(
      UPDATE_AMENITIES,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface updateStudioRoomParams {
  studio_room_id: number;
  about_this_room: string;
  equipment_highlights: string;
  room_name: string;
  username: string;
  max_number_of_guests: number;
  delete?: boolean;
}

export const updateStudioRoom = createAsyncThunk(
  UPDATE_STUDIO_ROOM,
  async (args: updateStudioRoomParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<StudioRoom>(
      UPDATE_STUDIO_ROOM,
      args,
    );

    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface createStudioRoomParams {
  studio_id: number;
  room_name: string;
  equipment_highlights: string;
  username: string;
  max_number_of_guests: number;
}

export const createStudioRoom = createAsyncThunk(
  CREATE_STUDIO_ROOM,
  async (args: createStudioRoomParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<StudioRoom>(
      CREATE_STUDIO_ROOM,
      args,
    );

    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface fetchStudioRoomsParams {
  studio_username?: string;
  studio_id?: number;
}

export const fetchStudioRooms = createAsyncThunk(
  FETCH_STUDIO_ROOMS,
  async (args: fetchStudioRoomsParams, thunkAPI) => {
    const params = `?${args.studio_id ? `studio_id=${args.studio_id}` : `studio_username=${args.studio_username}`}`;
    const result = await makeBackendGetCallWithJsonResponse<StudioRoom[]>(
      FETCH_STUDIO_ROOMS,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }

    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface updateBookingStatusParams {
  studioRoomId: number;
  notAcceptingBookings: boolean;
}

export const updateBookingStatus = createAsyncThunk(
  UPDATE_STUDIO_ROOM_BOOKING_STATUS,
  async (args: updateBookingStatusParams, thunkAPI) => {
    const body = {
      studio_room_id: args.studioRoomId,
      not_accepting_bookings: args.notAcceptingBookings,
    };
    const result = await makeBackendPostCallWithJsonResponse<RecordingService>(
      UPDATE_STUDIO_ROOM_BOOKING_STATUS,
      body,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface StudioRoomServiceUpdate {
  studio_room_id: number;
  studio_id: number;
  recording_service: RecordingService;
}

export const studiosSlice = createSlice({
  name: "studiosStateSlice",
  initialState,
  reducers: {
    clearStudiosOnLogout: () => {
      return initialState;
    },
    addMockStudio: (state) => {
      state[MockStudio.username] = MockStudio;
      state[MockStudio.id] = MockStudio;
    },
    addStudio: (state, action: PayloadAction<Studio>) => {
      const { payload } = action;
      const username = payload.username;
      if (!state[username]) {
        state[username] = payload;
        state[payload.id] = payload;
      } else {
        state[username] = {
          ...state[username],
          ...payload,
        };
        state[payload.id] = {
          ...state[username],
          ...payload,
        };
      }
    },
    addStudioRooms: (
      state,
      action: PayloadAction<{
        studio: { username?: string; id?: number };
        rooms: StudioRoom[];
      }>,
    ) => {
      const { payload } = action;
      let { username, id: studioId } = payload.studio;
      const rooms = payload.rooms;

      // if the API was called with a studio_id instead of a studio username,
      // grab the studio username from the response.
      // Ideally, all of these APIs would take in the studio ID as a param, not the studio username.
      if (!username && rooms.length > 0 && rooms[0].studio?.username) {
        username = rooms[0].studio?.username;
      }
      if (!studioId && rooms.length > 0 && rooms[0].studio?.id) {
        studioId = rooms[0].studio?.id;
      }
      if (!username || !studioId) return;
      if (!state[username] && !rooms.length) return;
      if (!state[username]) {
        state[username] = rooms[0].studio;
        state[studioId] = rooms[0].studio;
      }

      state[username] = {
        ...state[username],
        studio_rooms: rooms,
      } as Studio;
      state[studioId] = {
        ...state[username],
        studio_rooms: rooms,
      } as Studio;
    },
    updateStudioRoomService: (
      state,
      action: PayloadAction<StudioRoomServiceUpdate>,
    ) => {
      const { studio_room_id, studio_id, recording_service } = action.payload;
      const studio = Object.values(state).find(
        (studio) => studio?.id === studio_id,
      );
      if (!studio) {
        return;
      }
      const studioRooms = studio.studio_rooms;
      if (!studioRooms) {
        return;
      }
      const studioRoom = studioRooms?.find(
        (studioRoom) => studioRoom.id === studio_room_id,
      );
      if (!studioRoom) {
        return;
      }
      studioRoom.recording_service = recording_service.deleted
        ? null
        : recording_service;

      state[studio.username] = {
        ...state[studio.username],
        studio_rooms: [...studioRooms],
      } as Studio;

      state[studio.id] = {
        ...state[studio.username],
        studio_rooms: [...studioRooms],
      } as Studio;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateStudio.fulfilled, (state, action) => {
      const { payload } = action;
      const username = payload.username;
      if (!username) {
        return;
      }

      /**
       * This is a little silly, but it prevents type errors:
       * "'pending_engineers' is specified more than once, so this usage will be overwritten.""
       * It seems like this is necessary since 'pending_engineers' and 'pending_managers'
       * are defined as User[] in the Studio type - we might want to consider defining them as
       * possibly null.
       * Then we could replace this code block with something more intuitive:
       * state[username] = {
       *   ...state[username],
       *   ...payload,
       * };
       */
      const prevState = {
        pending_engineers: state[username]?.pending_engineers ?? [],
        pending_managers: state[username]?.pending_managers ?? [],
        studio_rooms: state[username]?.studio_rooms,
      };

      state[username] = {
        ...prevState,
        ...payload,
      };
      state[payload.id] = {
        ...prevState,
        ...payload,
      };
    });
    builder.addCase(updateStudioRoom.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const username = meta.arg.username;
      if (!username) {
        return;
      }
      if (!state[username]) {
        return;
      }
      const studioId = state[username]!.id;
      const studioFromPayload = payload.studio ?? undefined;
      const currentStudioRooms = state[username]?.studio_rooms ?? [];
      state[username] = {
        ...state[username],
        ...studioFromPayload,
        studio_rooms: currentStudioRooms
          .map((room) => {
            if (room.id === payload.id) {
              return payload;
            }
            return room;
          })
          .filter((room) => !room.deleted),
      } as Studio;

      state[studioId] = {
        ...state[username],
        ...studioFromPayload,
        studio_rooms: currentStudioRooms
          .map((room) => {
            if (room.id === payload.id) {
              return payload;
            }
            return room;
          })
          .filter((room) => !room.deleted),
      } as Studio;
    });
    builder.addCase(updateAmenities.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const username = meta.arg.username;
      if (!username) {
        return;
      }
      if (!state[username]) {
        return;
      }
      const studioId = state[username]!.id;
      if (meta.arg.studio_room_id) {
        const studioRoomId = meta.arg.studio_room_id;
        const studioRooms = state[username]?.studio_rooms ?? [];
        const studioRoom = studioRooms.find((room) => room.id === studioRoomId);
        if (!studioRoom) {
          return;
        }
        studioRoom.amenities = payload ?? null;
        state[username] = {
          ...state[username],
          studio_rooms: studioRooms.map((room) => {
            if (room.id === studioRoomId) {
              return studioRoom;
            }
            return room;
          }),
        } as Studio;
        state[studioId] = {
          ...state[username],
          studio_rooms: studioRooms.map((room) => {
            if (room.id === studioRoomId) {
              return studioRoom;
            }
            return room;
          }),
        } as Studio;
      } else {
        state[username] = {
          ...state[username],
          studio_amenities: payload ?? null,
        } as Studio;
        state[studioId] = {
          ...state[username],
          studio_amenities: payload ?? null,
        } as Studio;
      }
    });
    builder.addCase(createOrDeleteStudioProfile.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const username = meta.arg.studio_username;
      state[username] = payload;
      state[payload.id] = payload;
    });
    builder.addCase(updateBookingStatus.fulfilled, (state, action) => {
      const { payload } = action;
      if (!payload.studio_room) {
        return;
      }
      const studioUsername = payload.studio_room.studio_name;
      const studioRoomId = payload.studio_room.id;
      if (!state[studioUsername]) return;
      const studioId = state[studioUsername]!.id;
      state[studioUsername] = {
        ...state[studioUsername],
        studio_rooms:
          state[studioUsername]?.studio_rooms?.map((studioRoom) => {
            if (studioRoom.id === studioRoomId) {
              return { ...studioRoom, recording_service: payload };
            } else {
              return studioRoom;
            }
          }) || [],
      } as Studio;
      state[studioId] = {
        ...state[studioUsername],
        studio_rooms:
          state[studioUsername]?.studio_rooms?.map((studioRoom) => {
            if (studioRoom.id === studioRoomId) {
              return { ...studioRoom, recording_service: payload };
            } else {
              return studioRoom;
            }
          }) || [],
      } as Studio;
    });
  },
});

export const { updateStudioRoomService, addStudio, addStudioRooms } =
  studiosSlice.actions;
export default studiosSlice.reducer;
