import AccountTypeBase from "./base";
import { LabelAssociation } from "./project";

export default interface AandR extends AccountTypeBase {
  admin_email?: string;
  label?: LabelAssociation[];
}

export const MockAandR: AandR = {
  id: 35,
  user_id: 60,
  created: "2021-11-16T22:28:38.351243",
  deleted: null,
  is_primary_type: null,
  admin_email: "luke@engineears.com",
};
