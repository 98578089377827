import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { EngineEarsError } from "../../../store/actions/errorStore";
import { ReleaseType } from "../../../store/models/release";
import { ArtistReleaseResponse, fetchArtistReleases } from "../releaseAPI";

export const useFetchArtistReleasesQuery = (
  artistUserId: number,
  releaseType: ReleaseType,
  page: number,
) => {
  const queryClient = useQueryClient();

  return useQuery({
    enabled: Boolean(artistUserId),
    retry: 0,
    // Setting an arbitrary big stale time so when the user navigates quickly
    // the data isn't refetched
    // In addition, we don't need fresh data so frequent.
    staleTime: 10000,
    queryKey: [
      QUERY_KEYS.FETCH_ARTIST_RELEASE,
      {
        artistUserId,
        releaseType,
        page,
      },
    ],
    queryFn: async () => {
      const response = await fetchArtistReleases(
        artistUserId,
        releaseType,
        page,
      );
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      const releasesResponse = response.resultJson as ArtistReleaseResponse;
      releasesResponse.data.forEach((release) => {
        queryClient.setQueryData(
          [QUERY_KEYS.FETCH_ARTIST_RELEASE, release.id],
          release,
        );
      });
      return releasesResponse;
    },
    placeholderData: keepPreviousData,
  });
};
