import { useCallback } from "react";
import useModal from "../../../hooks/useModal";
import { addMixMasterPromoCode } from "../../../store/actions/mixMasterCartsStore";
import { addRecordingPromoCode } from "../../../store/actions/recordingCartsStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { PromoCodeField } from "../../components/PromoCodeField/PromoCodeField";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { UnauthenticatedModal } from "../UnauthenticatedModal/UnauthenticatedModal";
import "./ApplyPromoCode.css";
import { Container, Header } from "./ApplyPromoCode.styles";

interface ApplyPromoCodeProps {
  transactionCode: string;
}

const ApplyPromoCode = ({ transactionCode }: ApplyPromoCodeProps) => {
  const { activeServiceType } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const isRecordingServiceTypeActive =
    activeServiceType == ProjectType.RECORDING;
  const dispatch = useAppDispatch();
  const { engineerUserId, activeStudioId } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);

  const { promocode } = useAppSelector(
    (state) => state.mixMasterCartsStore.cart,
  );

  const {
    isOpen: showUnauthenticatedModal,
    openModal: openUnauthenticatedModal,
    closeModal: closeUnauthenticatedModal,
  } = useModal();

  const onApplyPromo = useCallback(
    (promoCode: string) => {
      if (!loggedInUser) {
        openUnauthenticatedModal();
        return;
      }

      if (isRecordingServiceTypeActive) {
        dispatch(addRecordingPromoCode(promoCode));
      } else {
        dispatch(addMixMasterPromoCode(promoCode));
      }
    },
    [
      loggedInUser,
      isRecordingServiceTypeActive,
      dispatch,
      openUnauthenticatedModal,
    ],
  );

  return (
    <>
      <Container>
        <Header variant={TextStyleVariant.H6}>Apply Promo Code</Header>
        <PromoCodeField
          transactionCode={transactionCode}
          onApplyPromo={onApplyPromo}
          promoCodeStr={promocode ?? undefined}
          userId={isRecordingServiceTypeActive ? undefined : engineerUserId}
          studioId={isRecordingServiceTypeActive ? activeStudioId : undefined}
          autoApplyPromo={false}
          textFieldClassName="apply-promo-code-textfield"
          skipApply={!loggedInUser}
        />
      </Container>
      <UnauthenticatedModal
        showModal={showUnauthenticatedModal}
        closeModal={closeUnauthenticatedModal}
        message={"Please log in to apply a promo code."}
      />
    </>
  );
};

export default ApplyPromoCode;
